import styled from 'styled-components';


export const CustomBadge = styled.div`
    cursor: pointer;
    position: relative;
    .count{
        left: 75%;
        top: -10%;
    }
            
`;