import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { useTracking } from 'react-tracking';
import { MDBBtn, MDBCol, MDBIcon, MDBRow } from "mdbreact";

import DashboardPowerBIGraphQL from "../../../graphql/DashboardPowerBI";
import eventName from '../../../common/events';
import ListGroupsAndItemsBI from "../../components/ListGroupsAndItemsBI";

import TemplatesTable from "../../components/TemplatesTable";

function DataGoPage() {

    const { trackEvent } = useTracking();
    const [dataPreview, setDataPreview] = useState({})
    const [openPreview, setOpenPreview] = useState(false)

    const listGroupsItemsRef = useRef()

    const createTrackingEvent = (event) => {
        return trackEvent({
            name: event,
            createdAt: new Date().toISOString()
        });
    };
    
    const handleBeforeCreateTemplates = (event) => {
        if (!window.COMMON.checkFormValidation('form-powerdashboard', event)) {
            window.COMMON.showMessage('warning', 'MSG_CODE_029', window.I18N('MSG_CODE_029'));
            return;
        }
        const checkItemsSelected = listGroupsItemsRef.current.checkDataItemsSelected()
        if(checkItemsSelected){
            window.COMMON.showMessage('warning', 'MSG_CODE_012', window.I18N('MSG_CODE_012'));
            return;
        }
        window.COMMON.showModal('#modal-save');
        window.saveMethod = handleCreateTemplates;
    }

    const handleGetDataTemplates = () => {
        const data = listGroupsItemsRef.current.getDataTemplates()
        return data
    }

    const handleOpenPreview = () => {
        const data = {
            dataTable: listGroupsItemsRef.current.getDataPreview(),
            dataView: handleGetDataTemplates()
        }
        if(data.dataView.groups.length === 0 || data.dataView.items.length === 0){
            window.COMMON.showMessage('warning', 'MSG_CODE_012', window.I18N('MSG_CODE_012'));
            return
        }
        setDataPreview(data)
        setOpenPreview(true)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const handleCreateTemplates = async () => {
        try {     
            const params = { 
                input : handleGetDataTemplates()
            }
            const result = await window.COMMON.mutation(DashboardPowerBIGraphQL.MUTATION_CREATE_TEMPLATE, params)      
            if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
                createTrackingEvent(eventName.ADD_TEMPLATES);
                handleClosePreview()
                listGroupsItemsRef.current.handleResetData();
            }  
        } catch (error) {
            window.COMMON.showErrorLogs('DashboardPowerBI.createTemplate'); 
        }
    }

    const handleClosePreview = () => {
        setOpenPreview(false)
        setDataPreview({})
    }

  
    return ( 
        <div className="mt-3" style={{width: '100%'}}>
          <form id="form-powerdashboard" className="needs-validation wow fadeInRight animated" data-wow-delay="0.2s" noValidate>
                <ListGroupsAndItemsBI 
                    ref={listGroupsItemsRef}
                />
                <div className="w-full rounded-bottom bg-white">
                    <MDBRow className="py-4">
                        <MDBCol className="flex-center justify-content-end">
                            <MDBBtn color="" type="button" onClick={() => handleOpenPreview()} className="mr-3">
                                <MDBIcon fa="true" icon="eye" className="fa-fw" /> {window.I18N('preview')}
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </div>
          </form>
           {openPreview && <div className="p-2">
                <TemplatesTable
                    configTemplatesTable={[]}
                    dataTemplatesTable={dataPreview}
                    idx='preview'
                    isPreviewMode={true}
                    handleClosePreview={handleClosePreview}
                    templateType={listGroupsItemsRef.current.getTemplateType()}
                    handleSaveTemplate={handleBeforeCreateTemplates}
                />
            </div>}
        </div>
     );
}

const mapStateToProps = state => ({
    language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DataGoPage);