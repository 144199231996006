import React, { useEffect } from 'react';
import { MDBBtn, MDBIcon } from 'mdbreact';
import qs from 'qs';

const ElearningCenter = () => {

  const openElearningCenter = () => {
    const token = localStorage.getItem(window.CONSTANT.AUTHEN_TOKEN);
    const id = localStorage.getItem(window.CONSTANT.ID);

    const queryStr = qs.stringify({
      token,
      user_id: id
    });

    window.open(`${process.env.REACT_APP_ELEARNING}?${ queryStr }`, '_blank');
  };

  useEffect(() => {
    openElearningCenter();
  }, []);

  return (
    <MDBBtn color="primary" className="mr-0" onClick={openElearningCenter}>
      <MDBIcon fa="true" icon="external-link-alt" className="fa-fw" /> Open learning center
    </MDBBtn>
  );
};

export default ElearningCenter;