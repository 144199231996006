import React from 'react'; 
import { NavLink } from 'react-router-dom';
import { MDBCardImage, MDBRow, MDBCol, MDBIcon } from 'mdbreact';

const GridItemBackground = React.memo(function GridItemBackground(props) {

  const handleEdit = (event, index) => {
    event.stopPropagation();
    if (props.handleEdit) {
      props.handleEdit(index);
    }
  };

  const handleDelete = (event, index) => {
    event.stopPropagation();
    if (props.handleDelete) {
      props.handleDelete(index);
    }
  };

  if (!props.data || props.data.length === 0) {
    return <div className="p-3 text-center">{window.I18N('empty_table')}</div>;
  }
  return (
    <MDBRow>
      {
        props.data.map((item, i) => 
          <MDBCol lg="3" md="6" sm="12" key={i} className="p-3">
            <div className="card border" style={{ backgroundColor: '#fbfbfb' }}>
              <div className="text-center">
                <MDBCardImage zoom cascade waves src={item.background} style={{ height: '240px', maxWidth: 'calc(100% - 10px)' }}/>
              </div>
              <div className="d-flex align-items-center p-2">
                <p className="font-weight-bold mb-0 mr-auto">
                  {window.COMMON.getValueFromAttr(item, 'name')}
                </p>
                <div className="dropdown">
                  <NavLink to="#" className="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-boundary="viewport">
                    <MDBIcon fa="true" icon="ellipsis-v" className="fa-fw black-text"></MDBIcon>
                  </NavLink>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" data-boundary="viewport">
                    <li className='dropdown-item' onClick= {(event) => handleEdit(event, i)}>
                      <MDBIcon fa="true" icon="edit" className="fa-fw"></MDBIcon> {window.I18N('btn_edit')}
                    </li>
                    <li className={'dropdown-item red-text'} onClick= {(event) => handleDelete(event, i)}>
                      <MDBIcon fa="true" icon="trash-alt" className="fa-fw"></MDBIcon> {window.I18N('btn_delete')}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="p-2">
                <span className="mr-1 d-inline-block">Hashtag: </span>
                {
                  window.COMMON.getValueFromAttr(item, 'hashtag', []).map((hashtag, j) => <span key={i * j + j} className="text-primary mr-1 d-inline-block">#{hashtag}</span>)
                }
              </div>
              <div className="d-flex align-items-center p-2 pt-0">
                <div className="mr-3">
                  <small className="text-muted">{window.I18N('created_by')}</small>:&nbsp;
                  <MDBCardImage cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, 'createdBy.avatar'))} className="small-icon rounded-circle mr-1"/>
                  <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'createdBy.name')}</small>
                </div>
                <span>
                  <small className="text-muted">{window.I18N('created_date')}</small>:&nbsp;
                  <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'createdDate')}</small>
                </span>
              </div>
            </div>
          </MDBCol>
        )
      }
    </MDBRow>
  );
});

export default GridItemBackground;