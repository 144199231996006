import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from 'mdbreact';
import React, { useEffect, useRef, useState } from 'react';

import { findAnswerHighestScore, handleCalcAverage, handleCalcAverageScoreOfCategory, sortQuestionByDate } from '../../../utils/poolQuestionReport';


const FinalConclusionReport = (props) => {
  const { data, language, dataAnswer } = props;
  const [historyAnswers, setHistoryAnswers] = useState([]);
  const scoreAverages = useRef([]);
  const categoryAverage = useRef(0);;
  const finalConclusionData = useRef(data[0]?.group?.finalConclusion || []);

  const renderFinalConclusion = () => {
    //Get standard of conclusion
    const { min: standardMinConclusion, max: standardMaxConclusion } = data[0].group.standardOfConclusion
    const average = handleCalcAverage(scoreAverages.current);
    if (average <= (standardMinConclusion || 50)) return window.COMMON.getValueWithLanguage(finalConclusionData.current, 'low', language) || 'Bad';
    if (average < (standardMaxConclusion || categoryAverage.current)) return window.COMMON.getValueWithLanguage(finalConclusionData.current, 'medium', language) || 'Medium';
    return window.COMMON.getValueWithLanguage(finalConclusionData.current, 'high', language) || 'Good';
  };
  useEffect(() => {
    scoreAverages.current = [];
    categoryAverage.current = 0;
    const result = [];

    data.forEach(dataItem => {
      const categoryQuestions = [];
      // group question by category
      const sorQuestionsByDate = sortQuestionByDate(dataItem.feedback);
      let categoriesStandardSum = 0;
      sorQuestionsByDate.forEach((item) => {
        const categoryTitle = window.COMMON.getValueWithLanguage(item.question, 'category', language);
        const categoryIndex = categoryQuestions.findIndex(item => item.categoryTitle === categoryTitle);

        const answerHighestScore = findAnswerHighestScore(dataAnswer, item.question._id);
        // remove text question
        if (item.question.type === 'TEXT') return;

        if (categoryIndex === -1) {
          categoryQuestions.push({
            categoryTitle,
            standardLowOfCategory: item.question.standardLowOfCategory,
            standardOfCategory: item.question.standardOfCategory,
            recommend: item.question.recommend,
            historyAnswers: [{
              historyAnswer: item.answers,
              answerHighestScore
            }]
          });
          categoriesStandardSum += item.question.standardOfCategory;
        } else {
          categoryQuestions[categoryIndex] = {
            ...categoryQuestions[categoryIndex],
            historyAnswers: [
              ...categoryQuestions[categoryIndex].historyAnswers,
              {
                historyAnswer: item.answers,
                answerHighestScore
              }
            ]
          };
        }
      });
      categoryAverage.current = Number((categoriesStandardSum / categoryQuestions.length).toFixed(2));
      // calc average score of each category
      categoryQuestions.forEach(item => {
        if (item.historyAnswers.length === 0) return;

        const findCategory = result.findIndex(n => n.categoryTitle === item.categoryTitle);

        const calcAnswerResult = handleCalcAverageScoreOfCategory(item.historyAnswers);
        if (findCategory < 0) {
          result.push({
            categoryTitle: item.categoryTitle,
            standardLowOfCategory: item.standardLowOfCategory,
            standardOfCategory: item.standardOfCategory,
            recommend: item.recommend,
            historyAnswersResult: [calcAnswerResult]
          });
        } else {
          result[findCategory].historyAnswersResult.push(calcAnswerResult);
        }
      });
    });
    setHistoryAnswers(result);

  }, [data, language]);
 
  return (
    <MDBRow className='mt-2 mb-5 w-full'>
      <MDBCol lg="12" className="pr-md-1">
        <MDBCard className="wow fadeInUp animated w-full text-left" data-wow-delay="0.5s">
          <MDBCardHeader className="pb-0"  style={{ fontSize: '18px' }}>{window.I18N('scoring')}</MDBCardHeader>
          <MDBCardBody>
            <table style={{ width: '100%' }}>
              <thead className='w-full'>
                <tr
                  style={{
                    lineHeight: 3
                  }}
                >
                  <th
                    style={{
                      width: '50%'
                    }}
                  >{window.I18N('category_name')}</th>
                  <th
                    style={{
                      width: '25%'
                    }}
                  >{window.I18N('standard')}</th>
                  <th
                    style={{
                      width: '25%'
                    }}
                  >{window.I18N('recommend')}</th>
                </tr>
              </thead>

              <tbody className="w-full mt-3">
                {
                  historyAnswers.map((item, i) => {
                    const { categoryTitle, standardLowOfCategory, standardOfCategory, historyAnswersResult, recommend: recommendData } = item;
                    const average = handleCalcAverage(historyAnswersResult);
                    let recommend;
                    if (average < standardLowOfCategory) {
                      recommend = window.COMMON.getValueWithLanguage(recommendData, 'low', language) || 'Low';
                    } else if (average >= standardLowOfCategory && average <= standardOfCategory) {
                      recommend = window.COMMON.getValueWithLanguage(recommendData, 'medium', language) || 'Medium';
                    } else {
                      recommend = window.COMMON.getValueWithLanguage(recommendData, 'high', language) || 'High';
                    }
                    scoreAverages.current.push(average);
                    return (
                      <tr key={i}
                        style={{
                          lineHeight: 2
                        }}
                      >
                        <td>{(i + 1) + '. ' + categoryTitle} ({standardLowOfCategory} - {standardOfCategory}%)</td>
                        <td>{average}%</td>
                        <td>{recommend}</td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
            <div
              className="mt-3"
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  fontWeight: 600,
                  width: '50%'
                }}
              >
                {window.I18N('average_score')}:
              </div>
              <p style={{ fontSize: '16px', margin: 0 }}>
                {handleCalcAverage(scoreAverages.current)}%
              </p>
            </div>
            <div
              className=""
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  fontWeight: 600,
                  width: '50%'
                }}
              >
                {window.I18N('survey_final_conclusion')}:
              </div>
              <p style={{ fontSize: '16px', margin: 0 }}>
                {renderFinalConclusion()}
              </p>
            </div>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>

    </MDBRow>
  );
};

export default FinalConclusionReport;