import React, { PureComponent } from 'react';
import { SketchPicker } from 'react-color';
import { MDBPopover, MDBBtn } from 'mdbreact';

class ColorPicker extends PureComponent {
  
  constructor(props) {
    super(props);
    this.state = {
      color: {
        r: '0',
        g: '0',
        b: '0',
        a: '1'
      },
      btnColor: '#000000'
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (window.COMMON.checkValue(props.color)) {
      this.setState({ color: this.hexToRGBA(props.color), btnColor: props.color });
    }
  }

  handleChange(color) {
    this.setState({ color: color.rgb, btnColor: color.hex });
    if (this.props.handleChange) {
      this.props.handleChange(color.hex);
    }
  }

  hexToRGBA(hext) {
    let ahex = hext.substring(1, hext.length);
    ahex = ahex.split('');
  
    let r = ahex[0] + ahex[0],
      g = ahex[1] + ahex[1],
      b = ahex[2] + ahex[2],
      a = ahex[3] + ahex[3];
  
    if (ahex.length >= 6) {
      r = ahex[0] + ahex[1];
      g = ahex[2] + ahex[3];
      b = ahex[4] + ahex[5];
      a = ahex[6] + (ahex[7] ? ahex[7] : ahex[6]);
    }
  
    const int_r = parseInt(r, 16),
      int_g = parseInt(g, 16),
      int_b = parseInt(b, 16),
      int_a = parseInt(a, 16);
    return {
      r: int_r,
      g: int_g,
      b: int_b,
      a: int_a
    };
  }

  render() {
    return (
      <MDBPopover placement="bottom" popover clickable id="popper-color-picker">
        <MDBBtn outline className="mt-0 mb-0" type="button" color="" disabled={this.props.disabled} style={{ backgroundColor: this.state.btnColor }}>Color</MDBBtn>
        <div>
          <SketchPicker color={ this.state.color } onChange={ this.handleChange.bind(this) } />
        </div>
      </MDBPopover>
    );
  }
}

export default ColorPicker;