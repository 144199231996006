import React, { useState, useCallback, useLayoutEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Grid, Fab, IconButton } from '@material-ui/core';
import { AddPhotoAlternate, Add, Audiotrack, VideoCall, Gif, PictureInPicture } from '@material-ui/icons';
import {  MDBInput, MDBBtn, MDBIcon } from 'mdbreact';
import ReactPlayer from 'react-player/lazy';

import InputFile from '../../components/InputFile';
import ListOption from '../../components/ListOption';
import ListImage from '../../components/ListImage';
import SelectAccount from '../../components/SelectAccount';
import ItemPost from '../../components/ItemPost';
import ItemShare from '../../components/ItemShare';
import RadioBox from '../../components/RadioBox';
import PushNotification from '../../components/PushNotification';
import BackgroundPost from '../../components/BackgroundPost';

import MetadataGRAPHQL from '../../../graphql/Metadata';
import AccountGRAPHQL from '../../../graphql/Account';
import GroupChatGRAPHQL from '../../../graphql/GroupChat';
import RoleGRAPHQL from '../../../graphql/Role';
import PostGRAPHQL from '../../../graphql/Post';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';

export default function MyPostPage(props) {
  const { trackEvent } = useTracking();
  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const types = [
    { value: 'POST', label: window.I18N('post')},
    { value: 'SURVEY_POST', label: window.I18N('survey_post')},
    { value: 'PUSH_NOTIFICATION', label: window.I18N('push_notification')}
  ];
  const [filter, setFilter] = useState({ sortType: 'UPDATED_DATE' });
  const [object, setObject] = useState({ index: -1, isLoad: false, itemShare: null, isLoadMore: false, isGif: false, background: null, pushPost: null });
  const [media, setMedia] = useState({ audio: null, video: null });
  const [pagination, setPagination] = useState({ pageSize: 10, currentPage: 1 });
  const [data, setData] = useState([]);
  const [dataCompanies, setDataCompanies] = useState([]);
  const [dataConfig, setDataConfig] = useState([]);
  const [dataImage, setDataImage] = useState([]);
  const [dataAnswer, setDataAnswer] = useState([]);
  const [dataCategorySticker, setDataCategorySticker] = useState([]);
  const [select, setSelect] = useState({
    account: []
  });
  const [options, setOptions] = useState({
    account: [],
    role: [],
    groupChats: [],
    group: [],
    companyIndustry: [],
    age: [],
    income: [],
    educationBackground: [],
    occupation: []
  });
  const [dataSave, setDataSave] = useState({
    type: 'POST',
    answer: []
  });
  const [dataShare, setDataShare] = useState({});

  useLayoutEffect(() => {
    setTimeout(() => {
      const dataConfig = localStorage.getItem(window.CONSTANT.DATA_CONFIG);
      setDataConfig(JSON.parse(dataConfig));
      getDataPost(0);
      getDataSticker();
      getDataMetadata();
      getDataCompany();
      getDataRole();
      getDataGroupChat();
      getDataAccount();
    }, 100);
    // eslint-disable-next-line
  }, []);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleRadio = useCallback((value) => {
    dataSave.type = value;
    object.pushPost = null;
    setObject(object);
    setDataSave({ ...dataSave });
  }, [object, dataSave]);

  const handleChange = useCallback((event, attr) => {
    dataSave[attr] = event.target.value;
    if (attr === 'text' && !checkEnableBackground(event.target.value)) {
      dataSave.background = null;
      object.background = null;
      setDataImage([]);
      setObject(object);
    }
    setDataSave({...dataSave});
  }, [object, dataSave]);

  const handleBackground = useCallback((item) => {
    dataSave.background = item._id;
    let dataImage = [];
    if (item.isGif) {
      dataImage = [item.background];
      object.background = null;
    } else {
      object.background = item;
    }
    setDataImage(dataImage);
    setObject(object);
    setDataSave({...dataSave});
    window.COMMON.hideModal('#modal-background-my-post');
  }, [object, dataSave]);

  const handleFile = (files) => {
    dataSave.files = files;
    dataSave.background = null;
    object.background = null;
    media.audio = null;
    media.video = null;
    const dataImage = files.map(file => window.COMMON.createBlobImage(file));
    setDataImage(dataImage);
    setMedia(media);
    setObject(object);
    setDataSave({ ...dataSave });
  };

  const handleFileAudio = (file) => {
    if (!file.type.match(/audio.*/)){
      window.COMMON.showMessage('warning', 'MSG_CODE_064',window.I18N('MSG_CODE_064'));
      return;
    }
    const limit = window.COMMON.getValueFromAttr(window.COMMON.getObjectInArrs('MAX_AUDIO_SIZE', dataConfig, 'name'), 'value') || -1;
    if (file.size > Number(limit) * 1024 * 1024 && limit !== -1) {
      window.COMMON.showMessage('warning', 'MSG_CODE_069', window.MSG.MSG_CODE_069 + limit + 'MB.');
      return;
    }
    dataSave.files = null;
    dataSave.background = null;
    object.background = null;
    media.audio = file;
    media.video = null;
    setDataImage([]);
    setMedia(media);
    setObject(object);
    setDataSave({ ...dataSave });
  };

  const changeFilterPost = useCallback((type) => {
    filter.sortType = type;
    pagination.currentPage = 1;
    setPagination(pagination);
    setFilter({ ...filter });
    resetForm();
    getDataPost(0);
    // eslint-disable-next-line
  }, [filter]);

  const handleFileVideo = (file) => {
    if (!file.type.match(/video.*/)){
      window.COMMON.showMessage('warning', 'MSG_CODE_064', window.I18N('MSG_CODE_064'));
      return;
    }
    const limit = window.COMMON.getValueFromAttr(window.COMMON.getObjectInArrs('MAX_VIDEO_SIZE', dataConfig, 'name'), 'value') || -1;
    if (file.size > Number(limit) * 1024 * 1024 && limit !== -1) {
      window.COMMON.showMessage('warning', 'MSG_CODE_069', window.MSG.MSG_CODE_069 + limit + 'MB.');
      return;
    }
    dataSave.files = null;
    dataSave.background = null;
    object.background = null;
    media.audio = null;
    media.video = file;
    setDataImage([]);
    setObject(object);
    setMedia(media);
    setDataSave({ ...dataSave });
  };

  const captureVideoFrame = (format = 'jpeg') => {
    const videoContainer = document.getElementById('video');
    const video = videoContainer.childNodes.length > 0 ? videoContainer.childNodes[0] : null;
    const canvas = document.createElement('CANVAS');
    canvas.height = video.videoHeight;
    canvas.width = video.videoWidth;
    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
    const dataUri = canvas.toDataURL('image/' + format);
    const data = dataUri.split(',')[1];
    const mimeType = dataUri.split(';')[0].slice(5);

    const bytes = window.atob(data);
    const buf = new ArrayBuffer(bytes.length);
    const arr = new Uint8Array(buf);

    for (let i = 0; i < bytes.length; i++) {
      arr[i] = bytes.charCodeAt(i);
    }
    const blob = new Blob([ arr ], { type: mimeType });
    return new File([blob], window.COMMON.genKey() + '.' + format, { type: blob.type });
  };

  const handleSelectAccount = useCallback((value) => {
    select.account = value;
    setSelect({ ...select });
  }, [select]);

  const checkEnableBackground = (value) => {
    const length = value.split(/\r\n|\r|\n/).length;
    return value.length <= 200 && length <= 5;
  };

  // METADATA
  const getDataMetadata = async () => {
    try {
      const params = {
        type: ['COMPANY_INDUSTRY', 'INDIVIDUAL_AGE', 'INDIVIDUAL_OCCUPATION', 'INDIVIDUAL_EDUCATION_BACKGROUND', 'INDIVIDUAL_INCOME']
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_SHORT_METADATA, params, false);
      if (result && result.data) {
        const dataIndustry = window.COMMON.getArrsInArrs('COMPANY_INDUSTRY', result.data.getMetadatas, 'type');
        options.companyIndustry = window.COMMON.createDataSelect(dataIndustry, '_id', 'value');
        options.companyIndustry.unshift({ value: null, label: window.I18N('all_industries') });
        const dataAge = window.COMMON.getArrsInArrs('INDIVIDUAL_AGE', result.data.getMetadatas, 'type');
        options.age = window.COMMON.createDataSelect(dataAge, '_id', 'value');
        options.age.unshift({ value: null, label: window.I18N('all_age') });
        const dataOccupation = window.COMMON.getArrsInArrs('INDIVIDUAL_OCCUPATION', result.data.getMetadatas, 'type');
        options.occupation = window.COMMON.createDataSelect(dataOccupation, '_id', 'value');
        options.occupation.unshift({ value: null, label: window.I18N('all_occupation') });
        const dataEducationBackground = window.COMMON.getArrsInArrs('INDIVIDUAL_EDUCATION_BACKGROUND', result.data.getMetadatas, 'type');
        options.educationBackground = window.COMMON.createDataSelect(dataEducationBackground, '_id', 'value');
        options.educationBackground.unshift({ value: null, label: window.I18N('all_education_background') });
        const dataIncome = window.COMMON.getArrsInArrs('INDIVIDUAL_INCOME', result.data.getMetadatas, 'type');
        options.income = window.COMMON.createDataSelect(dataIncome, '_id', 'value');
        options.income.unshift({ value: null, label: window.I18N('all_income') });
        setOptions(options);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MyPostPage.getDataMetadata');
    }
  };

  // STICKER
  const getDataSticker = async () => {
    try {
      const params = {
        parentCode: '-1'
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_STICKERS, params, false);
      let data = [];
      if (result && result.data) {
        data = result.data.getStickers;
      }
      setDataCategorySticker(data);
    } catch (error) {
      window.COMMON.showErrorLogs('MyPostPage.getDataSticker');
    }
  };

  // COMPANY
  const getDataCompany = async () => {
    try {
      if (!window.COMMON.checkRoleIsSystem()) {
        if (COMPANY_TYPE === window.CONSTANT.INFLUENCER) {
          await window.COMMON.getDataCompany();
          const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_COMPANY));
          setDataCompanies(dataCompany);
        }
      } else {
        await window.COMMON.getDataAllCompany();
        const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));
        setDataCompanies(dataCompany);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MyPostPage.getDataCompany');
    }
  };

  // ROLE
  const getDataRole = async () => {
    try {
      const params = {
        company: !window.COMMON.checkRoleIsSystem() ? COMPANY_ID : null
      };
      const result = await window.COMMON.query(RoleGRAPHQL.QUERY_ROLE, params, false);
      if (result && result.data) {
        const data = result.data.getRoles;
        if (COMPANY_TYPE === window.CONSTANT.INFLUENCER) {
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.code === window.CONSTANT.INFLUENCER) {
              data.splice(i, 1);
              break;
            }
          }
        }
        options.role = window.COMMON.createDataSelect(data, 'code', 'name');
        if (COMPANY_TYPE === window.CONSTANT.INFLUENCER) {
          options.role.push({ value: window.CONSTANT.COMPANY, label: window.I18N('company') });
          options.role.push({ value: window.CONSTANT.UNION, label: window.I18N('union') });
          setOptions(options);
        }
        options.role.push({ value: window.CONSTANT.INDIVIDUAL, label: window.I18N('individual') });
        setOptions(options);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MyPostPage.getDataRole');
    }
  };

  // GROUP CHAT
  const getDataGroupChat = async () => {
    try {
      const params = {
        type: '-1',
        organization: !window.COMMON.checkRoleIsSystem() ? COMPANY_ID : null
      };
      const result = await window.COMMON.query(GroupChatGRAPHQL.QUERY_GROUP_CHAT, params, false);
      if (result && result.data) {
        options.groupChats = window.COMMON.createDataSelect(result.data.getGroupChats, '_id', 'name');
        setOptions(options);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MyPostPage.getDataGroupChat');
    }
  };

  const getDataAccount = async () => {
    try {
      const params = {
        companyType: COMPANY_TYPE
      };
      const result = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_FOR_POST, params, false);
      if (result && result.data) {
        options.account = window.COMMON.createDataSelect(result.data.findAccountsForPost, '_id', 'nickname', null, 'avatar');
        setOptions({ ...options });
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MyPostPage.getDataAccount');
    }
  };

  const getDataPost = async (mode) => {
    try {
      const params = {
        sortType: filter.sortType,
        page: pagination.currentPage,
        pageSize: pagination.pageSize
      };
      const result = await window.COMMON.query(PostGRAPHQL.QUERY_POST, params);
      if (result && result.data) {
        const dataPost = result.data.getPosts;
        let arrs = data;
        if (mode === 0) {
          arrs = [];
        }
        dataPost.forEach(item => {
          arrs.push(item);
        });
        setData(arrs);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MyPostPage.getDataPost');
    }
    object.isLoad = true;
    object.isLoadMore = false;
    setObject({ ...object });
  };

  const deleteImage = (index) => {
    dataImage.splice(index, 1);
    setDataImage([ ...dataImage ]);
  };

  const resetForm = () => {
    const dataSave = {
      text: '',
      type: 'POST',
      background: null,
      company: window.COMMON.getValue(COMPANY_ID),
      answer: [],
      files: null,
      video: null,
      audio: null
    };
    object.background = null;
    const media = { audio: null, video: null };
    select.account = [];
    document.getElementById('files').value = null;
    window.COMMON.resetFormValidation('form');
    setSelect(select);
    setDataImage([]);
    setDataAnswer([]);
    setMedia(media);
    setObject(object);
    setDataSave({ ...dataSave });
  };

  const loadMore = () => {
    object.isLoadMore = true;
    pagination.currentPage += 1;
    setObject(object);
    setPagination({ ...pagination });
    getDataPost(1);
  };

  const receiveAnswer = useCallback((data) => {
    setDataAnswer([ ...data ]);
  }, []);

  const goToAddPost = async () => {
    window.scrollTo(0, 0);
    document.getElementById('input-text').focus();
  };

  const openBackgroundPost = (value) => {
    object.isGif = value;
    setObject({ ...object });
    window.COMMON.showModal('#modal-background-my-post');
  };

  const openSharePost = useCallback((item) => {
    object.itemShare = item;
    dataShare.text = '';
    dataShare.postId = item._id;
    dataShare.company = COMPANY_TYPE === window.CONSTANT.COMPANY ? window.COMMON.getValue(COMPANY_ID) : null;
    dataShare.union = COMPANY_TYPE === window.CONSTANT.UNION ? window.COMMON.getValue(COMPANY_ID) : null;
    dataShare.influencer = COMPANY_TYPE === window.CONSTANT.INFLUENCER ? window.COMMON.getValue(COMPANY_ID) : null;
    setObject(object);
    setDataShare({...dataShare});
    window.COMMON.showModal('#modal-share');
  }, [COMPANY_TYPE, COMPANY_ID, object, dataShare]);

  const handlePushNotificationPost = useCallback((index) => {
    dataSave.type = 'PUSH_NOTIFICATION';
    object.pushPost = data[index];
    setObject(object);
    setDataSave({ ...dataSave });
    setTimeout(() => {
      document.getElementById('input-push-notification').focus();
    }, 500);
  }, [object, data, dataSave]);

  const openDeletePost = useCallback((index) => {
    object.index = index;
    setObject({ ...object });
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deletePost;
    // eslint-disable-next-line
  }, [object]);

  // const prepareAddPost = async (event) => {
  //   if (media.video) {
  //     const fileImage = captureVideoFrame();
  //     dataSave.files = [fileImage];
  //     setDataSave(dataSave);
  //     // upload video to firebase
  //     await window.uploadFirebase(media.video, (downloadURL) => {
  //       const url = downloadURL;
  //       addPost(event, url);
  //     });
  //   } else if (media.audio) {
  //     // upload audio to firebase
  //     await window.uploadFirebase(media.audio, (downloadURL) => {
  //       const url = downloadURL;
  //       addPost(event, url);
  //     });
  //   } else {
  //     addPost(event, null);
  //   }
  // };

  const addPost = async (event) => {
    try {
      if (!window.COMMON.checkFormValidation('form', event)) {
        return;
      }
      window.showLoading();
      const people = [];
      select.account.forEach(item => {
        people.push(item.value);
      });
      dataSave.company = COMPANY_TYPE === window.CONSTANT.COMPANY ? window.COMMON.getValue(COMPANY_ID) : null;
      dataSave.union = COMPANY_TYPE === window.CONSTANT.UNION ? window.COMMON.getValue(COMPANY_ID) : null;
      dataSave.influencer = COMPANY_TYPE === window.CONSTANT.INFLUENCER ? window.COMMON.getValue(COMPANY_ID) : null;
      dataSave.people = people;
      dataSave.answer = dataAnswer;
      dataSave.isPublic = dataSave.people.length > 0 ? false : true;
      dataSave.isAdmin = COMPANY_TYPE === window.CONSTANT.ADMIN;
      // TODO upload file
      let dataFiles = [];
      let videoUrl = '';
      let audioUrl = '';
      const image = [];
      if (media.video) {
        dataSave.files = [];
        const fileImage = captureVideoFrame();
        dataSave.files = [media.video, fileImage];
      }
      if (media.audio) {
        dataSave.files = [media.audio];
      }
      if (dataSave.files) {
        dataFiles = await window.uploadFile(dataSave.files);
        if (dataFiles.length > 0) {
          dataFiles.forEach(element => {
            const ext = element.ext.toLowerCase();
            if (ext === 'mp4' || ext === 'mkv' || ext === 'ts' || ext === '3gp' || ext === 'mov' || ext === 'avi') {
              videoUrl = window.COMMON.getNewPathFile(element._id);
            } else if (ext === 'mp3' || ext === 'm4a' || ext === 'wav' || ext === 'aac' || ext === 'flac' || ext === 'webm' || ext === 'webm') {
              audioUrl = window.COMMON.getNewPathFile(element._id);
            } else {
              image.push(window.COMMON.getNewPathFile(element._id));
            }
          });
        }
      }
      dataSave.image = image;
      dataSave.files = null;
      dataSave.video = videoUrl || null;
      dataSave.audio = audioUrl || null;
      // TODO save data
      const params = {
        input: dataSave
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(PostGRAPHQL.MUTATION_ADD_POST, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
        createTrackingEvent(eventName.ADD_POST);
        getDataPost(0);
        resetForm();
      }
      window.COMMON.hideModal('#modal-post');
    } catch (error) {
      window.COMMON.showErrorLogs('MyPostPage.addPost');
    }
    window.hideLoading();
  };

  const deletePost = async () =>  {
    try {
      const params = {
        id: data[object.index]._id
      };
      const result = await window.COMMON.mutation(PostGRAPHQL.MUTATION_DEL_POST, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        createTrackingEvent(eventName.DEL_POST);
        getDataPost(0);
        resetForm();
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MyPostPage.deletePost');
    }
  };

  if (!object.isLoad) {
    return <div></div>;
  }

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row" style={{ overflow: 'hidden' }}>
      <Grid container className="mt-3">
        <Grid item lg={3} md={2} xs={1}></Grid>
        <Grid item lg={6} md={8} xs={10}>
          <div className="container-form mb-4 wow zoomIn animated" data-wow-delay="0.5s" style={{ overflow: 'inherit', zIndex: 1 }}>
            <Grid container className="p-3">
              <Grid item xs={12} className="mb-2">
                <h6 className="font-weight-bold black-text">{window.I18N('create_new_post')}</h6>
                <RadioBox data={types} className="mb-3" value={dataSave.type} handleChange={handleRadio}></RadioBox>
              </Grid>
              {
                dataSave.type === 'PUSH_NOTIFICATION' ? <PushNotification options={options} dataCompanies={dataCompanies} pushPost={object.pushPost}></PushNotification> : <Grid item xs={12}>
                  <form id="form" className="needs-validation" noValidate>
                    {
                      object.background ? <div className="d-100 position-relative" style={{ height: '300px' }}>
                        <div className="position-relative w-100 h-100">
                          <img src={window.COMMON.getValueFromAttr(object.background, 'background')} alt="background-post" className="w-100 h-100"/>
                        </div>
                        <div className="position-absolute w-100 h-100" style={{ left: 0, top: '50px' }}>
                          <MDBInput outline id="input-text" className="textarea-post d-flex align-items-center" type="textarea" containerClass="m-0" hint={window.I18N('share_what_you_thinging')} value={dataSave.text} onChange={(event) => handleChange(event, 'text')} maxLength="5000" pattern="\S(.*\S)?" required>
                            <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                          </MDBInput>
                        </div>
                      </div> : <MDBInput outline id="input-text" type="textarea" containerClass="m-0" hint={window.I18N('share_what_you_thinging')} value={dataSave.text} onChange={(event) => handleChange(event, 'text')} maxLength="5000" pattern="\S(.*\S)?" required>
                        <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                      </MDBInput>
                    }
                    {
                      dataImage.length > 0 ? <Grid item xs={12} className="mb-2">
                        <p className="mb-2"><small className="font-weight-bold">{window.I18N('images')}</small></p>
                        <ListImage data={dataImage} handleDeleteItem={deleteImage}></ListImage>
                      </Grid> : <></>
                    }
                    {
                      media.video ? <Grid item xs={12} className="mb-2">
                        <p className="mb-2"><small className="font-weight-bold">Video</small></p>
                        <div className="d-flex justify-content-center">
                          <ReactPlayer id="video" url={URL.createObjectURL(media.video)} controls={true}></ReactPlayer>
                        </div>
                      </Grid> : <></>
                    }
                    {
                      media.audio ? <Grid item xs={12} className="mb-2">
                        <p className="mb-2"><small className="font-weight-bold">Podcast</small></p>
                        <div className="d-flex justify-content-center">
                          <ReactPlayer url={URL.createObjectURL(media.audio)} controls={true} height={60}></ReactPlayer>
                        </div>
                      </Grid> : <></>
                    }
                    <Grid item xs={12} container className="mt-2">
                      <Grid item xs container spacing={2}>
                        <InputFile id="files" icon="upload" handleFile={handleFile} accept="image/*" isMulti={true}>
                          <AddPhotoAlternate color="primary"/>
                        </InputFile>
                        <div className="d-inline-block text-center">
                          <IconButton aria-label="add picture" disabled={!checkEnableBackground(dataSave.text || '')} onClick={!checkEnableBackground(dataSave.text || '') ? () => {} : () => openBackgroundPost(false)}>
                            <PictureInPicture color="primary"/>
                          </IconButton>
                        </div>
                        <div className="d-inline-block text-center">
                          <IconButton aria-label="add picture" onClick={() => openBackgroundPost(true)}>
                            <Gif color="primary"/>
                          </IconButton>
                        </div>
                        <InputFile id="audio-files" icon="upload" handleFile={handleFileAudio} accept="audio/*">
                          <Audiotrack color="primary"/>
                        </InputFile>
                        <InputFile id="video-files" icon="upload" handleFile={handleFileVideo} accept="video/*">
                          <VideoCall color="primary"/>
                        </InputFile>
                        <SelectAccount options={options.account} selected={select.account} handleClose={handleSelectAccount}></SelectAccount>
                      </Grid>
                      <Grid item>
                        <MDBBtn color="" onClick={resetForm}>
                          <MDBIcon fa="true" icon="redo-alt" className="fa-fw" /> {window.I18N('btn_clear')}
                        </MDBBtn>
                        <MDBBtn color="primary" onClick={addPost} className="mr-0">
                          <MDBIcon fa="true" icon="paper-plane" className="fa-fw" /> {window.I18N('btn_post')}
                        </MDBBtn>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      {
                        dataSave.type === 'POST' ? null : <ListOption data={dataAnswer} title={window.I18N('survey_answers')} handleReceiveData={receiveAnswer}></ListOption>
                      }
                    </Grid>
                  </form>
                </Grid>
              }
            </Grid>
          </div>
          <div className="container-post wow fadeIn animated" data-wow-delay="1s">
            <div className="container-filter-post mb-3 text-right" style={{ zIndex: '1001' }}>
              <div className="dropdown ml-2">
                <NavLink to="#" className="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-boundary="viewport">
                  <span className="font-weight-bold black-text">{filter.sortType === 'UPDATED_DATE' ? window.I18N('updated_date') : filter.sortType === 'CREATED_DATE' ? window.I18N('created_date') : window.I18N('internal')}</span>
                  <MDBIcon fa="true" icon="sort" className="fa-fw black-text ml-2"></MDBIcon>
                </NavLink>
                <ul className="dropdown-menu dropdown-menu-right" data-boundary="viewport" style={{ zIndex: '1001' }}>
                  <li className="dropdown-item" onClick= {(event) => changeFilterPost('UPDATED_DATE')}>
                    <MDBIcon fa="true" icon="calendar-alt" className="fa-fw"></MDBIcon> {window.I18N('updated_date')}
                  </li>
                  <li className="dropdown-item" onClick={(event) => changeFilterPost('CREATED_DATE')}>
                    <MDBIcon fa="true" icon="calendar-alt" className="fa-fw"></MDBIcon> {window.I18N('created_date')}
                  </li>
                  <li className="dropdown-item" onClick={(event) => changeFilterPost('INTERNAL')}>
                    <MDBIcon fa="true" icon="building" className="fa-fw"></MDBIcon> {window.I18N('internal')}
                  </li>
                </ul>
              </div>
            </div>
            {
              data.map((item, i) => (
                <ItemPost item={item} index={i} key={item._id} enableGoToAccount={true} isShare={false} className="col-md-12 p-0 mb-4" attr_id="_id" attr_content="text" attr_image="image" attr_avatar="createdBy.avatar" attr_name="createdBy.nickname" attr_time="createdDate"
                  handleDeletePost={openDeletePost} handlePushNotificationPost={handlePushNotificationPost} handleSharePost={openSharePost} categorySticker={dataCategorySticker} ></ItemPost>
              ))
            }
            <div className="text-center">
              <MDBBtn color="" disabled={data.length < (pagination.currentPage * pagination.pageSize)} onClick={loadMore}>
                {window.I18N('load_more')}
                {
                  !object.isLoadMore ? <></> : <>
                    <span className="spinner-grow spinner-grow-sm ml-1" role="status" aria-hidden="true"></span>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  </>
                }
              </MDBBtn>
            </div>
          </div>
          <div className="float-button wow fadeInDown animated" data-wow-delay="1s">
            <Fab color="primary" aria-label="add" size="medium" onClick={goToAddPost}>
              <Add />
            </Fab>
          </div>
        </Grid>
        <Grid item lg={3} md={2} xs={1}></Grid>
      </Grid>

      <ItemShare itemShare={object.itemShare} dataShare={dataShare}></ItemShare>
      <BackgroundPost isGif={object.isGif} handleBackground={handleBackground}></BackgroundPost>
    </div>
  );
}