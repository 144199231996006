import {
  SET_LANGUAGE,
  SET_BREADCRUMB
} from './action';

export default {
  setLanguage: (state = { value: {} }, action) => {
    switch (action.type) {
      case SET_LANGUAGE:
        return { ...state, value: action.payload };
      default:
        return { ...state };
    }
  },
  setBreadcrumb: (state = { value: {} }, action) => {
    switch (action.type) {
      case SET_BREADCRUMB:
        return { ...state, value: action.payload };
      default:
        return { ...state };
    }
  }
};
