import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { MDBCol, MDBIcon, MDBRow } from 'mdbreact'

import TrackingGRAPHQL from '../../../graphql/Tracking'

import CustomChart from '../../components/InsightChart/components/CustomChart'
import UserBehaviorFilters from './components/UserBehaviorFilters'
import TimesFrameStatisticChart from './components/TimesFrameStatisticChart'
import UsersBehaviorPopup from './components/UsersBehaviorPopup'

const EVENT_TRACKING = {
    POST: 'POST',
    LIKE: 'LIKE',
    DISLIKE: 'DISLIKE',
    SHAR: 'SHARE',
    COMM: 'COMMENT',
    LIKE: 'LIKE_COMMENT',
    DISLIKE: 'DISLIKE_COMMENT',
    BOOKMARK_POST: 'BOOKMARK_POST',
    REPO: 'REPORT_POST',
    NOTI: 'VIEW_NOTIFICATION',
    COMPLAINT: 'COMPLAINT',
    LGOU: 'LOGOUT',
    LGIN: 'LOGIN',
    FGPW: 'FORGOT_PASSWORD',
    TAKE: 'TAKE_SURVEY',
    CKCO: 'TAB_FRIEND',
    CKGR: 'TAB_GROUP',
    CKRG: 'TAB_REQUEST',
    VPRFR: 'VIEW_ACCOUNT',
    DFRI: 'DELETE_FRIEND',
    SRCH: 'SEARCH_CONTACT',
    SRCHFR: 'SEARCH_FRIEND',
    SRCHGR: 'SEARCH_GROUP',
    CKCH: 'TAB_CHAT',
    CKFR: 'TAB_CHAT_FRIEND',
    CKGR: 'TAB_CHAT_GROUP',
    ADFR: 'ADD_FRIEND',
    CRGR: 'CREAT_GROUP',
    JOGR: 'JOIN_GROUP',
    LEGR: 'LEAVE_GROUP',
    CKHO: 'TAB_HOME',
    CKTK: 'TAB_TASK',
    NETK: 'TAB_NEW_TASK',
    ITTK: 'TASK_ITEM',
    ITTKST: 'START_TASK',
    STAK: 'COMPLETED_TASK',
    OTTK: 'EXPLORE_TASK',
    OTTK: 'OTHER_ITEM',
    CKRW: 'TAB_REWARD',
    PAYM: 'TAB_PAYMENT',
    EXPL: 'TAB_EXPLORE',
    EXCH: 'EXCHANGE_REWARD',
    PROF: 'VIEW_PROFILE',
    EDPR: 'EDIT_PROFILE',
    ACBL: 'TAB_ACCOUNT_BALANCE',
    GOPI: 'TAB_GOPY',
    SURH: 'TAB_HISTORY_SURVEY',
    BOOK: 'TAB_BOOK_MARK',
    DELPO: 'DELETE_POST',
    STPR: 'TAB_SETTING',
    CHPW: 'CHANGE_PASSWORD',
    CHPHEM: 'CHANGE_PHONE_EMAIL',
    DELACC: 'DELETE_ACCOUNT',
    CHLG: 'CHANGE_LANGUAGE',
    ABOU: 'ABOUT',
    HELP: 'TAB_HELP_CENTER',
    RAIS: 'TAB_COMPLAINT_BOX',
    OTHER: 'TAB_OTHER',
    GUID: 'USER_GUIDE',
    INQU: 'INBOXED_QUESTION',
    FRQU: 'FREQUENTLY_QUESTION',
    TECO: 'TERM_POLICY',
    RHEL: 'REQUEST_HELP',
    CSUP: 'ROBOT_SUPPORT',
    CTUS: 'CONTACT_US',
    LNTA: 'TAB_LEARNING',
    SURLN: 'LEARNING',
    QUIZ: 'QUIZ_SURVEY',
    QZCM: 'QUIZ_COMPLETED',
    MESS: 'VIEW_MESSAGE',
    ACST: 'ACCOUNT_SETTING',
    SERF: 'SEND_REQUEST_FRIEND',
    GTIE: 'SLIDE_CONNECT',
    GGAT: 'GATE_INFO_CONNECT',
    GNUM: 'APP_CONNECT_NUMEROLOGY',
    GLEA: 'APP_CONNECT_LEAVE',
    GOVER: 'APP_CONNECT_OVER_TIME',
    GOLD: 'APP_CONNECT_LONGEVITY',
    GSTL: 'APP_CONNECT_PERSONALITY',
    GPAY: 'APP_CONNECT_PAYSLIP',
    GCON: 'APP_CONNECT_CONTRACT'
}

const DEFAULT_FILTER_EVENTIDS = ['POST', 'COMM', 'LGOU', 'LGIN', 'EXPL']

const paletteColor = [
    'rgba(0, 159, 109, 0.6)',
    'rgba(190, 0, 0, 0.6)',
    'rgba(61, 0, 189, 0.6)',
    'rgba(0, 65, 175, 0.6)',
    'rgba(54, 162, 235, 0.6)',
    'rgba(153, 102, 255, 0.6)',
    'rgba(201, 203, 207, 0.6)',
    'rgba(74, 147, 124, 0.6)',
    'rgba(147, 147, 124, 0.6)',
    'rgba(147, 147, 235, 0.6)',
    'rgba(147, 196, 235, 0.6)',
    'rgba(0, 171, 184, 0.6)',
    'rgba(139, 171, 184, 0.6)',
    'rgba(139, 255, 184, 0.6)',
    'rgba(165, 197, 97, 0.6)',
    'rgba(165, 163, 152, 0.6)',
    'rgba(0, 0, 0, 0.6)',
    'rgba(0, 47, 0, 0.6)',
    'rgba(26, 47, 43, 0.6)',
    'rgba(255, 38, 214, 0.6)'
]

const calcRangeAge = (birthday) => {
    const age = new Date().getFullYear() - new Date(birthday).getFullYear()
    if (age >= 16 && age <= 25) {
        return '16-25'
    } else if (age >= 26 && age <= 40) {
        return '26-40'
    } else if (age >= 41 && age <= 65) {
        return '41-65'
    } else {
        return 'unknown'
    }
}

const countDataWithFilters = (data, filters = { dataKey: '', compareKey: '', compareData: [] }) => {
    const { dataKey, compareKey, compareData } = filters
    const dataCount = {}
    compareData.forEach((item) => {
        const countObj = {}
        const filterWithEvent = data.reduce(
            (acc, el) => (el[compareKey] === item ? [...acc, el[dataKey]] : [...acc]),
            []
        )
        for (let i of filterWithEvent) {
            countObj[i] ? countObj[i]++ : (countObj[i] = 1)
        }

        dataCount[item] = countObj[item] ? countObj[item] : 0
    })

    return dataCount
}

const countOccurrencesByKeys = (data, keysWithDefaultValues = {}) => {
    const result = {}

    // Initialize count objects for each key with default values
    Object.keys(keysWithDefaultValues).forEach((key) => {
        result[key] = {}
        keysWithDefaultValues[key].forEach((value) => {
            result[key][value] = 0
        })
    })

    // Iterate through each item in the data array
    data.forEach((item) => {
        // Iterate through each key to count occurrences
        Object.keys(keysWithDefaultValues).forEach((key) => {
            const value = item[key]
            if (value) {
                // Increment the count for the specific value
                result[key][value] = (result[key][value] || 0) + 1
            }
        })
    })

    return result
}

const UsersBehaviorPage = ({ companyType, companyId, companyInfo, companies, language }) => {
    const [dataCounts, setDataCounts] = useState({
        ios: 0,
        android: 0,
        total: 0
    })
    const [dataTracking, setDataTracking] = useState([])
    const [dataCountsForTracking, setDataCountsForTracking] = useState({})
    const [dataTimesFrames, setDataTimesFrames] = useState({
        countTimeFrame: {},
        countEventWithTimeFrame: {}
    })
    const [filters, setFilters] = useState({
        eventIds: [],
        days: {}
    })
    const [dataChart, setDataChart] = useState({ dataSet: [], labels: [] })

    const chartRef = useRef()
    const titleRef = useRef({})

    const LIST_COUNT = [
        {
            title: window.I18N('ios_platform'),
            count: dataCounts.ios,
            icon: <MDBIcon fab icon="apple" style={{ fontSize: '25px' }} />
        },
        {
            title: window.I18N('android_platform'),
            count: dataCounts.android,
            icon: <MDBIcon fab icon="android" style={{ fontSize: '25px', color: '#0DB134' }} />
        },
        {
            title: window.I18N('total'),
            count: dataCounts.total,
            icon: <MDBIcon fas icon="bullseye" style={{ fontSize: '25px', color: '#DC3434' }} />
        }
    ]

    const KEYS_FOR_COUNTS = useMemo(() => {
        const keys = {
            gender: ['male', 'female'],
            company: companyInfo.reduce(
                (acc, company) => (company.accountType === window.CONSTANT.COMPANY ? [...acc, company.name] : acc),
                []
            ),
            influencer: companyInfo.reduce(
                (acc, company) => (company.accountType === window.CONSTANT.INFLUENCER ? [...acc, company.name] : acc),
                []
            ),
            union: companyInfo.reduce(
                (acc, company) => (company.accountType === window.CONSTANT.UNION ? [...acc, company.name] : acc),
                []
            ),
            age: ['16-25', '26-40', '41-65', 'unknown']
        }
        return keys
    }, [companyId])

    const groupDataforChart = (
        paramData,
        dataSetMapping = { dataSet: Object.values(EVENT_TRACKING), itemKey: 'eventId' }
    ) => {
        const { dataSet, itemKey } = dataSetMapping
        let groupDataChart = window.COMMON.getDataChartInPastDays({
            data: paramData,
            numberDays: +filters.days.value,
            types: dataSet,
            itemKey,
            dateKey: 'createdDate',
            formatDateLabel: 'DD/MM/YYYY'
        })
        const data = {
            labels: groupDataChart.labels,
            datasets: dataSet.map((el, index) => ({
                label: el,
                data: groupDataChart.data[index],
                borderColor: paletteColor[index],
                backgroundColor: 'transparent',
                lineTension: 0.2
            }))
        }
        return data
    }

    const groupAndCountTimes = (data) => {
        const countTimeFrame = {}
        const countEventWithTimeFrame = {}
        const { eventIds } = filters
        const keyForCounts = {
            platform: ['IOS_APP', 'ANDROID_APP'],
            eventId: eventIds.map((eventId) => eventId.label)
        }
        const newEntries = data.map((item) => {
            const [date, time] = item.createdDate.split(' - ')
            const [hours] = time.split(':').map(Number)
            const slot = `${String(Math.floor(hours / 4) * 4).padStart(2, '0')}-${String(
                Math.floor(hours / 4) * 4 + 4
            ).padStart(2, '0')}h`
            const groupKey = `${date} - (${slot})`

            if (!countTimeFrame[groupKey]) {
                countTimeFrame[groupKey] = 0
            }
            countTimeFrame[groupKey]++
            return { ...item, createdDate: groupKey }
        })

        Object.keys(countTimeFrame).forEach((groupTime) => {
            const filterDataWithTimeFrame = newEntries.filter((data) => {
                return data.createdDate === groupTime
            })

            const { platform: platformCount, eventId: eventIdCount } = countOccurrencesByKeys(
                filterDataWithTimeFrame,
                keyForCounts
            )
            if (!countEventWithTimeFrame[groupTime]) {
                countEventWithTimeFrame[groupTime] = {
                    platformCount,
                    eventIdCount
                }
            }
        })
        return {
            countTimeFrame,
            countEventWithTimeFrame
        }
    }

    const createInnerHTMLPopup = ({ hoverLabelIndex, indexDatasetPopup, hoverDatasetIndex }) => {
        return (
            <UsersBehaviorPopup
                companyId={companyId}
                dataChart={dataChart}
                dataCountsForTracking={dataCountsForTracking}
                eventIds={window.COMMON.removeDuplicateElements(dataTracking.map((data) => data.eventId))}
                onSetIndexDatasetPopup={chartRef?.current?.handleSetIndexDatasetPopup}
                hoverLabelIndex={hoverLabelIndex}
                indexDatasetPopup={indexDatasetPopup}
                hoverDatasetIndex={hoverDatasetIndex}
            />
        )
    }

    const getEventTracking = async () => {
        try {
            const { days, eventIds } = filters
            const params = {
                input: {
                    startDate: window.COMMON_DATE.getStrDateFilter(
                        window.COMMON_DATE.addDaysFromNow(-(+days.value - 1)),
                        window.CONSTANT.FROM_FILTER_DATE
                    ),
                    endDate: window.COMMON_DATE.getStrDateFilter(new Date(), window.CONSTANT.TO_FILTER_DATE),
                    companyIds: companyId
                        ? [companyId]
                        : [...companies.companyIds, ...companies.influencerIds, ...companies.unionIds],
                    eventIds: window.COMMON.isEmpty(eventIds)
                        ? DEFAULT_FILTER_EVENTIDS
                        : eventIds.map((eventId) => eventId.value)
                }
            }
            const eventsTracking = await window.COMMON.query(TrackingGRAPHQL.QUERY_EVENTS_TRACKING, params)
            const data = eventsTracking.data.getEventTracking

            const modifiedData = data.map((item) => ({
                ...item,
                eventId: EVENT_TRACKING[item.eventId],
                createdDate: window.COMMON_DATE.formatDate(item.createdDate, 'DD/MM/YYYY'),
                createdBy: {
                    gender: item.createdBy.gender === 1 ? 'male' : 'female',
                    age: calcRangeAge(window.COMMON_DATE.formatDate(item.createdBy.birthday, 'YYYY-MM-DD')),
                    company: item?.company && item?.company?.information?.name,
                    influencer: item?.influencer && item?.influencer?.information?.name,
                    union: item?.union && item?.union?.information?.name
                }
            }))

            const dataForCountsTimes = groupAndCountTimes(
                data.map((item) => ({
                    ...item,
                    eventId: EVENT_TRACKING[item.eventId],
                    createdDate: window.COMMON_DATE.formatDate(item.createdDate, 'DD/MM/YYYY - HH:mm')
                }))
            )

            const removeEventsDuplicate = window.COMMON.removeDuplicateElements(
                modifiedData.map((item) => item.eventId)
            )

            const dataForChart = groupDataforChart(modifiedData, {
                dataSet: removeEventsDuplicate,
                itemKey: 'eventId'
            })

            let dataCountsTracking = {}
            removeEventsDuplicate.forEach((item) => {
                for (let i = 0; i < dataForChart.labels.length; i++) {
                    const day = dataForChart.labels[i]

                    const filterData = modifiedData.reduce(
                        (acc, el) => (el.eventId === item && el.createdDate === day ? [...acc, el.createdBy] : acc),
                        []
                    )

                    const {
                        gender: countGender,
                        company: countCompany,
                        influencer: countInfluencer,
                        union: countUnion,
                        age: countAgeRange
                    } = countOccurrencesByKeys(filterData, KEYS_FOR_COUNTS)

                    if (!dataCountsTracking[item]) {
                        dataCountsTracking[item] = {}
                    }
                    if (!dataCountsTracking[item][day]) {
                        dataCountsTracking[item][day] = {}
                    }
                    dataCountsTracking[item][day] = {
                        countGender,
                        countCompany,
                        countInfluencer,
                        countUnion,
                        countAgeRange
                    }
                }
            })

            const dataForCounts = countDataWithFilters(data, {
                dataKey: 'platform',
                compareKey: 'platform',
                compareData: ['IOS_APP', 'ANDROID_APP']
            })

            setDataCountsForTracking(dataCountsTracking)
            setDataTimesFrames(dataForCountsTimes)
            setDataCounts((prev) => ({
                ...prev,
                ios: dataForCounts.IOS_APP,
                android: dataForCounts.ANDROID_APP,
                total: data.length
            }))
            setDataChart(dataForChart)
            setDataTracking(modifiedData)
        } catch (error) {
            window.COMMON.showErrorLogs(`UserBehaviorPage.getEventTracking::: ${error}`)
        }
    }

    const handleFilters = (dataFilters) => {
        const { days } = dataFilters
        const daysLabel = days?.label ? days.label.toLowerCase() : filters.days.label.toLowerCase()
        titleRef.current['statistic_time_frame'].innerText = `${window.I18N('statistic_time_frame')} ${daysLabel}`
        titleRef.current['statistic_click'].innerText = `${window.I18N('statistic_click')} ${daysLabel}`

        setFilters((prev) => ({ ...prev, ...dataFilters }))
    }

    useEffect(() => {
        const { days, eventIds } = filters
        if (!window.COMMON.isEmpty(days) || !window.COMMON.isEmpty(eventIds)) {
            getEventTracking()
        }
    }, [companyId, companies, filters])

    return (
        <div className="rounded-lg">
            {/* Count start */}
            <div className="mt-2 flex-center justify-content-between" style={{ gap: '8px' }}>
                {LIST_COUNT.map((item, index) => (
                    <div
                        key={index}
                        className="flex-center flex-column align-items-start bg-white p-3 border rounded-lg"
                        style={{
                            width: '33%',
                            height: '100px'
                        }}
                    >
                        <div className="d-flex align-items-center justify-content-start" style={{ gap: '12px' }}>
                            {item.icon}
                            <span className="flex-center">{item.title}</span>
                        </div>
                        <div className="font-weight-bold" style={{ fontSize: '2rem' }}>
                            {item.count}
                        </div>
                    </div>
                ))}
            </div>
            {/* Count end */}
            {/* ============================================================================== */}
            {/* Times range start */}
            <div className="mt-4 mb-2 d-flex align-items-center justify-content-between">
                <div className="flex-center" style={{ gap: '8px' }}>
                    <h6
                        className="font-weight-bold m-0"
                        style={{ fontSize: '0.9rem' }}
                        ref={(el) => (titleRef.current['statistic_time_frame'] = el)}
                    >
                        {`${window.I18N('statistic_time_frame')} ${window.I18N('past_7_days').toLowerCase()}`}
                    </h6>
                    <MDBIcon far icon="clock" style={{ fontSize: '20px' }} />
                </div>
                {/* Filters */}
                <UserBehaviorFilters
                    onFilters={handleFilters}
                    EVENT_TRACKING={EVENT_TRACKING}
                    DEFAULT_FILTER_EVENTIDS={DEFAULT_FILTER_EVENTIDS}
                />
                {/* Header end */}
            </div>
            <MDBRow>
                {/* List time frame counts */}
                <MDBCol className="h-full pr-1 pl-2" md="4">
                    <div className="py-3 bg-white rounded-lg" style={{ height: '100%' }}>
                        {window.COMMON.isEmpty(dataTimesFrames.countTimeFrame) ? (
                            <span className="flex-center">{window.I18N('empty')}</span>
                        ) : (
                            <div>
                                <h6
                                    className="font-weight-bold m-0"
                                    style={{
                                        fontSize: '12px',
                                        color: 'rgb(53 54 56 / 80%)',
                                        padding: '11px 0',
                                        textAlign: 'center'
                                    }}
                                >
                                    {window.I18N('time_frame_list')}
                                </h6>
                                <div style={{ maxHeight: '500px', overflow: 'overlay', overscrollBehavior: 'contain' }}>
                                    {Object.entries(dataTimesFrames.countTimeFrame).map((item, index) => {
                                        const [key, value] = item
                                        return (
                                            <div
                                                key={index}
                                                className="p-2 mb-1 d-flex align-items-center justify-content-between"
                                            >
                                                <span className="font-weight-bold" style={{ fontSize: '10px' }}>
                                                    {key}
                                                </span>
                                                <span
                                                    className="d-flex align-items-center justify-content-center font-weight-bold"
                                                    style={{
                                                        width: '36px',
                                                        height: '18px',
                                                        borderRadius: '8px',
                                                        backgroundColor: '#fc9905',
                                                        padding: '8px',
                                                        color: '#FFFFFF',
                                                        fontSize: '10px'
                                                    }}
                                                >
                                                    {value}
                                                </span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </MDBCol>
                {/* TimesFrameStatisticChart */}
                <MDBCol className="h-full pr-1 pl-2" md="8">
                    <TimesFrameStatisticChart
                        data={dataTimesFrames.countTimeFrame}
                        dataPopup={dataTimesFrames.countEventWithTimeFrame}
                    />
                </MDBCol>
            </MDBRow>
            {/* Times range end */}
            {/* ==================================================================== */}
            {/* Chart start */}
            <MDBRow className="px-2">
                <MDBCol className="p-2" md="12">
                    <div className="bg-white rounded-lg">
                        <div className="flex-center justify-content-start" style={{ gap: '8px' }}>
                            <h6
                                className="font-weight-bold mb-2"
                                style={{ fontSize: '0.9rem' }}
                                ref={(el) => (titleRef.current['statistic_click'] = el)}
                            >
                                {`${window.I18N('statistic_click')} ${window.I18N('past_7_days').toLowerCase()}`}
                            </h6>
                            <MDBIcon fas icon="mouse" style={{ fontSize: '20px' }} />
                        </div>
                        <div className="mt-3">
                            <div
                                className="d-flex align-items-center justify-content-center"
                                style={{ position: 'relative' }}
                            >
                                <CustomChart
                                    typeChart="Line"
                                    dataChart={dataChart}
                                    createInnerHTMLPopup={createInnerHTMLPopup}
                                    ref={chartRef}
                                    displayLegend={true}
                                    options={{
                                        title: {
                                            display: true,
                                            text: window.I18N('statistic_click_chart')
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </MDBCol>
            </MDBRow>
            {/* Chart end */}
        </div>
    )
}

const mapStateToProps = (state) => ({
    language: state.language.value
})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(UsersBehaviorPage)
