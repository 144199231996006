// src/ReminderDiv.js
import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, IconButton, Collapse, Button, TextField, FormControl, Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Timer from '@material-ui/icons/Timer';
import HighlightOff from '@material-ui/icons/HighlightOff';
import DoneOutline from '@material-ui/icons/DoneOutline';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import Select from 'react-select';
import ArrowBack from '@material-ui/icons/ArrowBack'

const reminderStatusMaping = {
    SCHEDULE: { color: '#e8f0fd', title: 'reminder_schedule', icon: <Timer style={{ fontSize: 40, color: '#649af1'}}></Timer> },
    CANCEL: { color: '#fdecea', title: 'reminder_cancel', icon: <HighlightOff style={{ fontSize: 40, color: '#ff7043'}}></HighlightOff> },
    DONE: { color: '#edf4e8', title: 'reminder_done', icon: <DoneOutline style={{ fontSize: 40, color: '#8bc34a'}}></DoneOutline> }
};

const ReminderDiv = ({ reminder, listAdvisors, canModify=false , handleEditReminder = ()=>{}, handleCancelReminder= ()=>{} }) => {
    const [expanded, setExpanded] = useState(false);
    const [editable, setEditable] = useState(false);
    const [messageContent, setMessageContent] = useState(reminder.messageContent);
    const [selectedAdvisors, setSelectedAdvisor] = useState(reminder.assignUsers);
    const [isDataNotValid, setIsDataNotValid] = useState(true)

    const handleExpandClick = () => {
        resetAllState()
        setExpanded(!expanded);
    };
    const resetAllState = ()=>{
        setEditable(false)
        setMessageContent(reminder.messageContent)
        setSelectedAdvisor(reminder.assignUsers)
        
    }
    const handleEditClick = () => {
        if (reminder.status!=='SCHEDULE'){
            return
        }
        if (editable) {
          const updateReminder = {
            _id: reminder._id,
            assignUsers: selectedAdvisors.map(el=>el.value),
            messageContent: messageContent
          }
          handleEditReminder(updateReminder);
        }
        setEditable(!editable);
    };

    const handleDetailsChange = (event) => {
        setMessageContent(event.target.value);
    };

    const handleSelectAdvisorsChange = (event) => {
        setSelectedAdvisor(event);
    };

    useEffect(()=>{
        //check advisors change
        let isNewAssignNotChange = true
        if (selectedAdvisors?.length){
            let numberOfSameAdvisor = 0
            reminder.assignUsers.forEach(oldAdvisor => {
                const index = selectedAdvisors.find(newAdvisor => newAdvisor.value ===oldAdvisor.value)
                if (index!== -1){
                    numberOfSameAdvisor++
                }
            })
            if (numberOfSameAdvisor!==selectedAdvisors.length){
                isNewAssignNotChange = false
            }
        }
        if ((messageContent===reminder.messageContent && isNewAssignNotChange)|| !selectedAdvisors || messageContent==''){
            setIsDataNotValid(true)
        }else {
            setIsDataNotValid(false)
        }
    },[messageContent, selectedAdvisors])

  const onRemoveScheduleClick = ()=>{
    handleCancelReminder(reminder._id)
  }
  return (
    <Card style={{ borderRadius: 10, backgroundColor: reminderStatusMaping[reminder.status]?.color }}>
      <CardContent>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
          {editable ? <FormControl style={{width:"90%"}} variant="outlined">
                <Select
                  className="md-form my-2"
                  placeholder={window.I18N('influencers') + ' *'}
                  value={selectedAdvisors}
                  options={listAdvisors}
                  onChange={handleSelectAdvisorsChange}
                  isSearchable
                  isMulti
                  closeMenuOnSelect={false}
                />
              </FormControl>
            :<Typography variant="subtitle1">{reminder.assignUsers.map(el=>' '+el.label).toString()}</Typography>}
            <Typography variant="body2" color="textSecondary">{window.I18N('date_send_remind')+': '+reminder.reminderDate}</Typography>
          </Box>
          <Box>
            <Tooltip placement="top" arrow title={window.I18N(reminderStatusMaping[reminder.status]?.title)}>
              {reminderStatusMaping[reminder.status]?.icon}
            </Tooltip>
          </Box>
          <IconButton onClick={handleExpandClick}>
            <ExpandMoreIcon className={`${expanded?'rotated-icon':''}`} />
          </IconButton>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box mt={2}>
          {editable ? (
              <TextField
                fullWidth
                variant="outlined"
                value={messageContent}
                onChange={handleDetailsChange}
                multiline
              />
            ) : (
              <Typography variant="body2" paragraph>{messageContent}</Typography>
            )}
            <div className='mt-2 d-flex justify-content-end'>
            {canModify && reminder.status ==='SCHEDULE' &&<Button
              variant="contained"
              color={editable ? "primary" : "default"}
              startIcon={editable ? <SaveIcon /> : <EditIcon />}
              disabled={editable && isDataNotValid}
              onClick={handleEditClick}
              style={{ marginRight: 8 }}
            >
              {editable ? window.I18N("btn_save") : window.I18N("btn_edit")}
            </Button>}
            {canModify && reminder.status ==='SCHEDULE' && !editable && <Button variant="contained" color="secondary" onClick={onRemoveScheduleClick} startIcon={<HighlightOff />}>{window.I18N("btn_remove")}</Button>}
            {editable&&<Button variant="contained" color="secondary" onClick={resetAllState} startIcon={<ArrowBack />}>{window.I18N("btn_back")}</Button>}
            </div>
            
          </Box>
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default ReminderDiv;
