import { MDBCol, MDBInput, MDBRow } from 'mdbreact';
import React from 'react';
import Modal from '../Modal';
import { Fragment } from 'react';
import ItemPreviewQuestion from '../ItemPreviewQuestion';

const PreviewModalCategoryQuestions = (props) => {
  const { questionsGroupByCategory, language } = props;
  return (
    <Modal id="modal-preview-category-questions" title={window.I18N('preview')} className="modal-xl category_questions_container" hideSave={true}>
      <MDBRow>
        <MDBCol>
          {
            questionsGroupByCategory.map((item, i) => {
              const { categoryTitle = '', questions = [] } = item;
              return (
                <Fragment key={i}>
                  {/* title */}
                  <MDBInput
                    type='text'
                    rows='2'
                    value={categoryTitle}
                    className='group-question-header-input'
                    readOnly
                  />
                  {/* questions */}
                  {
                    questions.map((item, j) => {
                      return (
                        <ItemPreviewQuestion
                          key={item.uuid}
                          index={j}
                          id={item.uuid}
                          item={item.question}
                          answers={item.answers}
                          group={item.group}
                          language={language}
                        />
                      );
                    })
                  }
                </Fragment>
              );
            })
          }
        </MDBCol>
      </MDBRow>
    </Modal>
  );
};

export default PreviewModalCategoryQuestions;