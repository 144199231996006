const selfAssessmentEvent = {
  ADD_SELF_ASSESSMENT: 'ADD_SELF_ASSESSMENT',
  EDIT_SELF_ASSESSMENT: 'EDIT_SELF_ASSESSMENT',
  DELETE_SELF_ASSESSMENT: 'DELETE_SELF_ASSESSMENT',
  ADD_ATTRIBUTE_REPORT: 'ADD_ATTRIBUTE_REPORT',
  EDIT_ATTRIBUTE_REPORT: 'EDIT_ATTRIBUTE_REPORT',
  DEL_ATTRIBUTE_REPORT: 'DEL_ATTRIBUTE_REPORT',
  ADD_ATTRIBUTE_REPORT_COMPANY: 'ADD_ATTRIBUTE_REPORT_COMPANY',
  EDIT_ATTRIBUTE_REPORT_COMPANY: 'EDIT_ATTRIBUTE_REPORT_COMPANY',
  ASSIGN_SELF_ASSESSMENT_INFLUENCER: 'ASSIGN_SELF_ASSESSMENT_INFLUENCER',
  SEND_FEEDBACK_TASK_SELF_ASSESSMENT: 'SEND_FEEDBACK_TASK_SELF_ASSESSMENT',
  NOTE_TASK_SELF_ASSESSMENT_FOR_INFLUENCER: 'NOTE_TASK_SELF_ASSESSMENT_FOR_INFLUENCER',
  ASSIGN_TASK_SELF_ASSESSMENT: 'ASSIGN_TASK_SELF_ASSESSMENT',
  ASSIGN_LEADER_TASK_SELF_ASSESSMENT: 'ASSIGN_LEADER_TASK_SELF_ASSESSMENT',
  SUBMIT_TASK_SELF_ASSESSMENT: 'SUBMIT_TASK_SELF_ASSESSMENT',
  REWORK_TASK_SELF_ASSESSMENT: 'REWORK_TASK_SELF_ASSESSMENT',
  COMPLETE_TASK_SELF_ASSESSMENT: 'COMPLETE_TASK_SELF_ASSESSMENT',
  SHARE_SELF_ASSESSMENT: 'SHARE_SELF_ASSESSMENT',
  DELETE_SHARE_SELF_ASSESSMENT: 'DELETE_SHARE_SELF_ASSESSMENT',
  ASSIGN_TASK_SHARE_SELF_ASSESSMENT: 'ASSIGN_TASK_SHARE_SELF_ASSESSMENT',
  SAVE_TASK_SHARE_SELF_ASSESSMENT: 'SAVE_TASK_SHARE_SELF_ASSESSMENT',
  REJECT_TASK_SELF_ASSESSMENT: 'REJECT_TASK_SELF_ASSESSMENT',
  UPDATE_TASK_SHARE_SELF_ASSESSMENT: 'UPDATE_TASK_SHARE_SELF_ASSESSMENT'
};

export default selfAssessmentEvent;