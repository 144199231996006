import React, { useEffect, useState } from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import qs from 'qs';

const ComplaintPage = (props) => {

  const { location } = props;
  const action = location.pathname.slice(1);
  const language = JSON.parse(localStorage.getItem(window.CONSTANT.LANGUAGE))?.code

  const [mainHeight, setMainHeight] = useState(0);
  const [query, setQuery] = useState('');

  const getMainContentHeight = () => {
    const mainEl = window.document.querySelector('main');
    const navbarHeaderEl = window.document.querySelector('#navbar-header');
    setMainHeight(mainEl.clientHeight - navbarHeaderEl.clientHeight - 10);
  };

  useEffect(() => {
    getMainContentHeight();

    const token = localStorage.getItem(window.CONSTANT.AUTHEN_TOKEN);
    const role = localStorage.getItem(window.CONSTANT.ACCOUNT_TYPE);
    const menus = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ROLE));

    const grievanceMenuItem = getMenuInfoByAction(menus, action);

    const queryStr = qs.stringify({
      token,
      embedded: true,
      role,
      menuId: grievanceMenuItem?.code,
      language: language?.toLowerCase()
    });

    setQuery(queryStr);
    // eslint-disable-next-line
  }, [language]);


  const complaintToolUrl = `${process.env.REACT_APP_COM_TOOL}?${query}`;

  useEffect(() => {
    function handleMessage(event) {  
      // check origin
      const url = process.env.REACT_APP_COM_TOOL || '';
      if (!url.includes(event.origin)) return;
      
      // remove event called from extension,...
      if (typeof event.data !== 'object') return;
      if (!event.data.userId) return;

      const data = event.data;
      props.history.push(`/chat/${data.userId}`);
    }

    if (query) {
      // receive from complaint iframe
      window.addEventListener('message', handleMessage);
      return window.hideLoading();
    }
    window.showLoading();
    return () => {
      window.removeEventListener('message', handleMessage);
    }
  }, [query]);

  return (
    <div className="container-row mb-0 d-flex flex-column" style={{ height: mainHeight }}>
      <MDBRow className="wow fadeInUp animated flex-grow-1" data-wow-delay="1s">
        <MDBCol className="overflow-hidden">
          {query && (
            <iframe
              style={{ width: '100%', height: '100%' }}
              src={complaintToolUrl} frameBorder="0"
              title="complaint-tool"
            ></iframe>
          )}
        </MDBCol>
      </MDBRow>
    </div>
  );
};

function getMenuInfoByAction(menus, action) {
  let result;

  for (const item of menus) {
    if (item.action === action) {
      result = item;
      break;
    }
  }

  return result;
}


export default ComplaintPage;
