import React, { useState, useLayoutEffect } from 'react';
import Select from 'react-select';
import { MDBIcon } from 'mdbreact';

import Panel from '../../components/Panel';
import Table from '../../components/Table';

import MedicalGRAPHQL from '../../../graphql/Medical';

export default function CheckQRDashboard(props) {
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const testingType = [
    { value: 'RAPID', label: 'Rapid Test'},
    { value: 'PCR', label: 'RT-PCR test'}
  ];
  const config = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('name'), '20%', 'p-0 pl-1', '', 'createdBy.avatar,createdBy.name', 'IMAGE_TEXT'),
    window.COMMON.createCol(window.I18N('gender'), '10%', '', '', 'genderText', 'TEXT'),
    window.COMMON.createCol(window.I18N('birthday'), '10%', '', '', 'birthday', 'TEXT'),
    window.COMMON.createCol(window.I18N('vaccination'), '25%', '', '', 'vaccine', 'VACCINE'),
    window.COMMON.createCol(window.I18N('testing'), '30%', '', '', 'medical', 'MEDICAL')
  ];
  const [filter, setFilter] = useState({company: null});
  const [data, setData] = useState([]);
  const [options, setOptions] = useState({
    company: []
  });

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataCompany();
    }, 100);
    // eslint-disable-next-line
  }, []);

  const handleFilterSelect = (event) => {
    filter.company = event;
    setFilter({ ...filter });
    getDataCheckQRCode();
  };

  const getDataCompany = async () => {
    try {
      if (COMPANY_TYPE === window.CONSTANT.COMPANY || COMPANY_TYPE === window.CONSTANT.UNION || COMPANY_TYPE === window.CONSTANT.INFLUENCER) {
        filter.company = { value: COMPANY_ID, label: '', code: COMPANY_TYPE };
        setFilter({ ...filter }); 
        getDataCheckQRCode();
        return;
      }
      await window.COMMON.getDataCompany();
      const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_COMPANY));
      options.company = window.COMMON.createDataSelectWithGroup(dataCompany, 'id', 'name', 'accountType', 'accountType');
      filter.company = window.COMMON.getValueFromAttr(options.company, '0.options.0', null);  
      setOptions(options);
      setFilter({ ...filter });
      getDataCheckQRCode();
    } catch (error) {
      window.COMMON.showErrorLogs('CheckQRDashboard.getDataCompany');
    }
  };

  const getDataCheckQRCode = async () => {
    try {
      window.resetDataTable('table-data');
      if (filter.company !== null) {
        const params = {
          company: filter.company.value
        };
        const result = await window.COMMON.query(MedicalGRAPHQL.QUERY_CHECK_QR_CODE, params);
        if (result && result.data && result.data.getCheckQR) {
          const data = result.data.getCheckQR.data;
          data.forEach(item => {
            item.date = window.COMMON_DATE.formatDate(item, 'createdDate');
            item.birthday = window.COMMON_DATE.formatDate(item, 'createdBy.birthday');
            item.genderText = item.createdBy.gender === 0 ? window.I18N('female') : window.I18N('male');
            if (item.medical) {
              const type = window.COMMON.getObjectInArrs(item.medical.type, testingType, 'value');
              item.medical.typeName = type.label;
              item.medical.date = window.COMMON_DATE.formatDate(item.medical.date);
              item.medical.expiredDate = window.COMMON_DATE.formatDate(item.medical.expiredDate);
            }
          });
          setData(data);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('CheckQRDashboard.getDataCheckQRCode');
    }
    window.initDataTable('table-data');
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" icon="list-alt">
        {
          !window.COMMON.checkRoleIsSystem() ? <div></div> : <div className="container-top d-flex align-items-center justify-content-end wow fadeInDown animated" data-wow-delay="0.5s">
            <div className="bd-highlight" style={{ width: '300px' }}>
              <div className="input-group input-group-transparent">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="building" className="fa-fw" />
                  </span>
                </div>
                <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.company} options={options.company} onChange={(event) => handleFilterSelect(event, 'company')} isSearchable />
              </div>
            </div>
          </div>
        }
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <Table id="table-data" className="table" config={config} data={data}></Table>
        </div>
      </Panel>
    </div>
  );
}