import React from "react";
import { connect } from "react-redux";
import { MDBCol, MDBRow } from "mdbreact";


function PaymentDetail({ data }) {
    if(Object.keys(data).length === 0){
        return null
    }

    const TRANSACTION_STATUS = {
            0 : window.I18N('payment_successful'),
            1 : window.I18N('payment_failed'),
            2 : window.I18N('payment_pending'),
            3 : window.I18N('payment_processing')
        }


    const RESPONSE_PAYMENT_CODE = {
        [window.I18N('payment_successful')] : 'label-success',
        [window.I18N('payment_failed')] : 'label-danger',
        [window.I18N('payment_pending')] : 'label-warning',
        [window.I18N('payment_processing')]: 'label-primary'
    }
    return ( 
        <div className="w-full">
            <h6 className="font-weight-bold" style={{fontSize: '1rem'}}>
                {window.I18N('order_info')}
            </h6>
            <div className="p-3">
                <MDBRow>
                    <MDBCol>
                        {window.I18N('orders_code')}
                    </MDBCol>
                    <MDBCol>
                        {data.order._id}
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        Gopies
                    </MDBCol>
                    <MDBCol>
                        {data.order.coin}
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        {window.I18N('amount')}
                    </MDBCol>
                    <MDBCol>
                        {data.amount}
                    </MDBCol>
                </MDBRow>
            </div>
            <h6 className="font-weight-bold" style={{fontSize: '1rem'}}>
                {window.I18N('pay_gate')}
            </h6>
            <div className="p-3">
                <MDBRow>
                    <MDBCol>
                        {window.I18N('name')}
                    </MDBCol>
                    <MDBCol>
                        {data.paygate.name.toUpperCase()}
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        {window.I18N('type_currency')}
                    </MDBCol>
                    <MDBCol>
                        {data.paygate.currency}
                    </MDBCol>
                </MDBRow>
            </div>
            <h6 className="font-weight-bold" style={{fontSize: '1rem'}}>
                {window.I18N('payer_info')}
            </h6>
            <div className="p-3">
                <MDBRow>
                    <MDBCol>
                        {window.I18N('name')}            
                    </MDBCol>
                    <MDBCol>
                        {data?.user?.name || ''}
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        Email
                    </MDBCol>
                    <MDBCol>
                        {data?.user?.email || ''}
                    </MDBCol>
                </MDBRow>
            </div>
                    <h6 className="font-weight-bold" style={{fontSize: '1rem'}}>
                        {window.I18N('transaction_info')}        
                    </h6>
                    <div className="p-3">
                        <MDBRow>
                            <MDBCol>
                                {window.I18N('status')}
                            </MDBCol>
                            <MDBCol>
                            <label className={`label-box ${RESPONSE_PAYMENT_CODE[TRANSACTION_STATUS[+data.status]]} p-1 m-0 font-weight-bold`}>
                                {TRANSACTION_STATUS[data.status]}
                            </label>
                            </MDBCol>
                        </MDBRow>
                        {data.result && data.result.code !== '0' && 
                        <MDBRow>
                            <MDBCol>
                                {window.I18N('reason_pay_failed')}
                            </MDBCol>
                            <MDBCol>                     
                                {data.result.message}
                            </MDBCol>
                        </MDBRow>
                        }
                        <MDBRow>
                            <MDBCol>
                                {window.I18N('transaction_time')}                        
                            </MDBCol>
                            <MDBCol>
                                {data.updatedDate}
                            </MDBCol>
                        </MDBRow>
                    </div>
        </div>
     );
}

const mapStateToProps = state => ({
    language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetail);