import React, { useState, useCallback, useLayoutEffect, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { MDBRow, MDBCol, MDBInputGroup, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';
import { PDFExport } from '@progress/kendo-react-pdf';
import $ from 'jquery'

import Panel from '../../components/Panel';
import ListSelfAssessment from '../../components/ListSelfAssessment';
import Modal from '../../components/Modal';
import GridItemSelfAssessmentSchedule from '../../components/GridItemSelfAssessmentSchedule';
import Pagination from '../../components/Pagination';
import Filter from '../../components/Filter';

import AccountGRAPHQL from '../../../graphql/Account';
import CompanyGRAPHQL from '../../../graphql/Company';
import QuestionGRAPHQL from '../../../graphql/Question';
import SelfAssessmentGRAPHQL from '../../../graphql/SelfAssessment';
import eventName from '../../../common/events';
import { useTracking } from 'react-tracking';
import { useHistory } from 'react-router-dom';
import ItemPreviewAdminSelfAssessment from '../../components/ItemPreviewAdminSelfAssessment';
import ConclusionPdfForm from '../../components/ConclusionPdfForm';
import moment from 'moment';
import Common from '../../../utils/Common';
import CaseAssessmentGRAPHQL from '../../../graphql/CaseAssessment';
import Constant from '../../../utils/Constant';
import SumInfoFilter from '../../components/SumInfoFilter';
import { Grid } from '@material-ui/core';

const viewTabs = {
  assessment: 'ASSESSMENT',
  caseAssessment: 'CASE_ASSESSMENT'
}

const SCHEDULE_ASSESSMENT_PAGE = '/schedule-assessment';
function SelfAssessmentPage(props) {
  const { trackEvent } = useTracking();

  const typeOptions = [
    { value: '1', label: window.I18N('periodic_assessment') },
    { value: '2', label: window.I18N('requested_assessment') }
  ];

  const periodOptions = [
    { value: '1', label: window.I18N('annually') },
    { value: '2', label: window.I18N('half_year') }
  ];

  const typeFilterOptions = [
    { value: null, label: window.I18N('all') },
    { value: '1', label: window.I18N('periodic_assessment') },
    { value: '2', label: window.I18N('requested_assessment') }
  ];

  const periodFilterOptions = [
    { value: null, label: window.I18N('all') },
    { value: '1', label: window.I18N('annually') },
    { value: '2', label: window.I18N('half_year') }
  ];

  const statusFilter = [
    { value: null, label: window.I18N('all_status') },
    { value: 0, label: window.I18N('new') },
    { value: 1, label: window.I18N('running') },
    { value: 3, label: window.I18N('completed') },
    { value: 2, label: window.I18N('expired') },
  ];

  const benchmarksOptions = [{ value: null, label: window.I18N('all') }, ...Object.keys(Constant.GROUP_DIFFERENCE_FEEDBACK).map(el=> ({
    label: el, value: Constant.GROUP_DIFFERENCE_FEEDBACK[el]
  }))]
  const sortFeedbackOptions = [
    { value: -1, label: window.I18N('benmark_increased') },
    { value: 1, label: window.I18N('benmark_decreased') },

  ]

  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const ACCOUNT_TYPE = localStorage.getItem(window.CONSTANT.ACCOUNT_TYPE);
  const history = useHistory();
  const language = useRef();
  const conclusionExportArea = React.useRef(null);
  const [viewTab, setViewTab] = useState(viewTabs.assessment)
  const [filter, setFilter] = useState({ company: null, text: '', type: { value: null, label: window.I18N('all') }, period: { value: null, label: window.I18N('all') }, status: { value: null, label: window.I18N('all_status') } });
  const [pagination, setPagination] = useState({ total: 0, pageSize: 10, currentPage: 0 });
  const [object, setObject] = useState({
    index: -1,
    itemIndex: -1,
    status: 1,
    item: null,
    isShare: false,
    taskId: null,
    taskInfluencerId: null,
    type: 0,
    isDone: false,
    caseItem: null
  });
  const [data, setData] = useState([]);
  const [dataSelected, setDataSelected] = useState([]);
  const [dataInfluencer, setDataInfluencer] = useState([]);
  const [dataShare, setDataShare] = useState([]);
  const [dataTaskShare, setDataTaskShare] = useState([]);
  const [dataTaskCompany, setDataTaskCompany] = useState([]);
  const [dataTaskInfluencer, setDataTaskInfluencer] = useState([]);
  const [dataSelfAssessmentInfluencer, setDataSelfAssessmentInfluencer] = useState([]);
  const [dataAttributeReport, setDataAttributeReport] = useState([]);
  const [dataAttributeReportCompany, setDataAttributeReportCompany] = useState({});
  const [dataSave, setDataSave] = useState({});
  const [dataNote, setDataNote] = useState([]);
  const [dataCompareNote, setDataCompareNote] = useState([]);
  const [date, setDate] = useState({ startDate: new Date(), endDate: window.COMMON_DATE.addDaysFromNow(30) });
  const [select, setSelect] = useState({});
  const [options, setOptions] = useState({
    companies: [],
    groups: [],
    users: [],
    shareGroups: [],
    shareCompanies: [],
    shareInfluencers: []
  });
  const [defaultOptions, setDefaultOptions] = useState({
    shareGroups: [],
    shareCompanies: []
  });
  const [userData, setUserData] = useState([]);
  const [caseData, setCaseData] = useState([]);
  const [conclusionData, setConclusionData] = useState({
    _id: '',
    overview: {
      evaluation: '',
      comment: ''
    },
    stating: {
      evaluation: '',
      comment: ''
    },
    policy: {
      evaluation: '',
      comment: ''
    },
    content: {
      evaluation: '',
      comment: ''
    },
    handling: {
      evaluation: '',
      comment: ''
    }
  });
  const [previewReport, setPreviewReport] = useState({});
  const [assignedCompanyAdvisors, setAssignedCompanyAdvisors] = useState([]);
  const [isShareAbleAssessment, setIsShareAbleAssessment] = useState(true);
  const [isCurrentPreviewTaskConcluded, setIsCurrentPreviewTaskConCluded] = useState(false);
  const [filterForQuery, setFilterForQuery] = useState({
    startDate: window.COMMON_DATE.addDaysFromNow(-91),
    endDate: new Date(),
    status: null,
    radio: 1
  })
  const [dataSum, setDataSum] = useState({total:'',news:'',expired:'',running:'',completed:''})
  const [tabPreviewModal, setTabPreviewModal] = useState(0)
  const [filterFeedback, setFilterFeedback] = useState({sortFeedback: null, benchmark: null, text: '' })
  const [appliedFilterFeedBack, setAppliedFilterFeedBack] = useState({sortFeedback: null, benchmark: null, text:'' })

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {
      getDataAttributeReport();
      getDataAccountInfluencer();
      getDataCompany();
    }, 100);
    // eslint-disable-next-line
  }, [props.language]);

  useEffect(() => {
    if (filterForQuery.startDate && filterForQuery.endDate && filter.company) {
      pagination.currentPage=0
      setFilter({ company: filter.company, text: '', type: { value: null, label: window.I18N('all') }, period: { value: null, label: window.I18N('all') }, status: { value: null, label: window.I18N('all_status') } })
      countData()
      getDataSelfAssessment('refresh');
    } 
  }, [filterForQuery.startDate, filterForQuery.endDate, filterForQuery.status ,filter.company, viewTab])


  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleChange = (event) => {
    dataSave[event.target.name] = event.target.value;
    setDataSave({ ...dataSave });
  };

  const handleFilterChange = (event) => {
    filter.text = event.target.value;
    setFilter({ ...filter });
  };

  const handleFilterFeedbackChange= (event)=>{
    filterFeedback.text = event.target.value;
    setFilterFeedback({ ...filterFeedback });
  }


  const handleFilterSelect = (event, attr) => {
    filter[attr] = event;
    setFilter({ ...filter });
    if (attr === 'company') {
      pagination.currentPage = 0;
      getDataAccount();
      getDataGroupQuestion();
    }
  };

  const handleFilterFeedbackSelect = (event, attr) => {
    filterFeedback[attr] = event;
    setFilterFeedback({ ...filterFeedback });
  };

  const handleSelect = (event, id, attr) => {
    window.COMMON.checkSelectValidation(id, event);
    select[attr] = event;
    setSelect({ ...select });
  };

  const handleDatePicker = (event, attr) => {
    date[attr] = event;
    setDate({ ...date });
  };

  const resetFilterModal= useCallback(() => {
    setAppliedFilterFeedBack({sortFeedback: null, benchmark: null, text: '' })
    setFilterFeedback({sortFeedback: null, benchmark: null, text: '' })
    setTabPreviewModal(0)
  })

  // COMPANY
  const getDataCompany = async () => {
    try {
      const influencer_back = localStorage.getItem(window.CONSTANT.INFLUENCER_BACK);
      localStorage.removeItem(window.CONSTANT.INFLUENCER_BACK);
      if (!window.COMMON.checkRoleIsSystem()) {
        filter.company = { value: COMPANY_ID, label: '', code: COMPANY_TYPE };
        setFilter({ ...filter });
        if (COMPANY_TYPE === window.CONSTANT.INFLUENCER) {
          await window.COMMON.getDataCompany();
          const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_COMPANY));
          if (dataCompany && dataCompany.length > 0) {
            options.companies = window.COMMON.createDataSelectWithGroup(dataCompany, 'id', 'name', 'accountType', 'accountType');
          }
          setOptions(options);
        }
        getDataAccount();
        getDataGroupQuestion();
        return;
      }
      await window.COMMON.getDataAllCompany();
      const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));
      if (dataCompany && dataCompany.length > 0) {
        const dataInfluencer = window.COMMON.getArrsInArrs(window.CONSTANT.INFLUENCER, dataCompany, 'type');
        const dataOrganization = dataCompany.filter(function (item) {
          return item.type !== window.CONSTANT.INFLUENCER;
        });
        const influencers = [...dataInfluencer];
        options.company = window.COMMON.createDataSelect(dataInfluencer, '_id', 'information.name');
        options.companies = window.COMMON.createDataSelectWithGroup(dataOrganization, '_id', 'information.name', 'type', 'type');
        let initIndex = influencers.findIndex((item) => item._id === influencer_back);
        if (initIndex < 0) {
          initIndex = 0;
        }
        filter.company = window.COMMON.getValueFromAttr(options.company, initIndex + '', null);
      }
      setOptions(options);
      setFilter({ ...filter });
      getDataAccount();
      getDataGroupQuestion();
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.getDataCompany');
    }
  };

  // GROUP QUESTION
  const getDataGroupQuestion = async () => {
    try {
      const params = {
        company: window.COMMON.getDataSelect(filter.company, false)
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_GROUP_QUESTION, params);
      if (result && result.data) {
        options.groups = window.COMMON.createDataSelectWithLanguage(result.data.getGroupQuestions, '_id', 'name', language.current, false);
        setOptions(options);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.getDataGroupQuestion');
    }
  };

  // ATTRIBUTE REPORT
  const getDataAttributeReport = async () => {
    try {
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_ATTRIBUTE_REPORT, null);
      if (result != null) {
        let index = 0;
        result.data.getAttributeReports.forEach(item => {
          item.idx = index;
          index++;
        });
        setDataAttributeReport(result.data.getAttributeReports);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MySelfAssessmentPage.getDataAttributeReport');
    }
  };

  // ACCOUNT
  const getDataAccount = async () => {
    try {
      if (filter.company !== null) {
        const params = {
          company: filter.company.value,
          companyType: window.CONSTANT.INFLUENCER,
          isAdmin: false
        };
        const result = await window.COMMON.query(AccountGRAPHQL.QUERY_NOFIFY_ACCOUNT, params);
        if (result && result.data) {
          options.users = window.COMMON.createDataSelectWithGroup(result.data.getNotifyAccounts, '_id', 'name', 'accountType', 'accountType');
          setUserData(result.data.getNotifyAccounts);
        }
        setOptions(options);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.getDataAccount');
    }
  };

  const getSelectedCompaniesAdvisors = async () => {
    try {
      const influencerIds = dataSelected.map(company => company?._id).filter(id => !!id);
      const result = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_BY_INFLUENCER, {
        influencerIds
      });
      const advisors = result.data.getAccountByInfluencers;
      setAssignedCompanyAdvisors(advisors);
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.getSelectedCompaniesAdvisors');
    }
  };

  // ACCOUNT INFLUENCER
  const getDataAccountInfluencer = async () => {
    try {
      const params = {
        type: window.CONSTANT.INFLUENCER
      };
      const result = await window.COMMON.query(CompanyGRAPHQL.QUERY_COMPANY, params);
      let data = [];
      if (result && result.data) {
        data = result.data.getCompanies;
      }
      setDataInfluencer(data);
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.getDataAccountInfluencer');
    }
  };

  // SELF ASSESSMENT
  const getDataSelfAssessment = async (paramMode) => {

    try {
      const params = {
        input: {
          organization: window.COMMON.checkRoleIsSystem() || ACCOUNT_TYPE === window.CONSTANT.INFLUENCER ? window.COMMON.getDataSelect(filter.company, false) : null,
          text: paramMode === 'refresh' ? "" : filter.text,
          type: paramMode === 'refresh' ? null : window.COMMON.getDataSelect(filter.type, false),
          period: paramMode === 'refresh' ? null : window.COMMON.getDataSelect(filter.period, false),
          status: paramMode === 'refresh' && filterForQuery.status === undefined ? null : filterForQuery.status,
          pageSize: pagination.pageSize,
          page: paramMode === 'refresh' ? 1 : pagination.currentPage + 1,
          startDate: window.COMMON_DATE.getStrDateFilter(filterForQuery.startDate, -1),
          endDate: window.COMMON_DATE.getStrDateFilter(filterForQuery.endDate, 1)
        },
        tab: viewTab
      };

      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_SELF_ASSESSMENT_WITH_FILTER_BY_TAB, params);
      let data = [];
      if (result && result.data && result.data.getSelfAssessmentsWithFilterByTab) {
        let resData = result.data.getSelfAssessmentsWithFilterByTab.data;
        const draftData = window.COMMON.getLocalJSONData({ key: window.CONSTANT.DRAFT_SCHEDULE_ASSESSMENT, defaultValue: [] });
        const draftDataToShow = [];
        const userId = localStorage.getItem(window.CONSTANT.ID);
        draftData.forEach((item) => {
          const scheduleAssessment = item.scheduleAssessment;
          const scheduleAssessmentFormData = scheduleAssessment.scheduleAssessmentFormData;
          const createdBy = scheduleAssessmentFormData.createdBy || {};
          const date = scheduleAssessment.date;
          const select = scheduleAssessment.select;
          const selectedGroupQAData = scheduleAssessment.selectedGroupQAData;
          if (createdBy._id === userId && scheduleAssessmentFormData.influencer === filter.company?.value) {
            scheduleAssessmentFormData.createdBy.name = filter.company.label;
            const itemToAdd = {
              ...scheduleAssessmentFormData,
              startDate: new Date(date.startDate).getTime(),
              endDate: new Date(date.endDate).getTime(),
              createdDate: new Date(scheduleAssessmentFormData.createdDate).getTime(),
              groups: selectedGroupQAData,
              period: select.period.value,
              type: select.type.value,
              status: 'draft'
            };
            draftDataToShow.push(itemToAdd);
          }
        });

        resData = [
          ...draftDataToShow,
          ...resData
        ];


        data = window.COMMON.formatMultiDate(resData, 'createdDate,startDate,endDate');
        data.forEach(item => {
          item.typeName = window.COMMON.getObjectInArrs(item.type, typeOptions, 'value').label;
          item.periodName = window.COMMON.getObjectInArrs(item.period, periodOptions, 'value').label;
          item.groups.forEach(group => {
            group.nameLanguage = window.COMMON.getValueWithLanguage(group, 'name', language.current);
          });
        });
        pagination.total = result.data.getSelfAssessmentsWithFilterByTab.total;
      }
      setPagination(pagination)
      setData(data);
      getDataCaseAssessment(data);
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.getDataSelfAssessment');
    }
  };

  const getDataCaseAssessment = async (selfAssessments) => {
    const selfAssessmentIds = selfAssessments.map(item => item._id).filter(i => !!i);

    const result = await Common.query(CaseAssessmentGRAPHQL.QUERY_GET_CASE_BY_SELF_ASSESSMENTS, { selfAssessmentIds });
    let resultData = [];
    if (result && result.data && result.data.getCaseAssessmentsBySelfAssessmentIds) {
      resultData = result.data.getCaseAssessmentsBySelfAssessmentIds;
    }

    setCaseData(resultData);
  };

  const changePage = useCallback((index) => {
    pagination.currentPage = index;
    getDataSelfAssessment();
    // eslint-disable-next-line
  }, [object, pagination, filter]);

  const doSearchData = () => {
    setFilterForQuery((prev) => ({...prev, status: window.COMMON.getDataSelect(filter.status, false)}))
    pagination.currentPage = 0;
    getDataSelfAssessment();
    //window.COMMON.hideFilter()
    $('#btn-filter-assessment').trigger('click')
  };

  const applyFilterFeedback = ()=>{
    setAppliedFilterFeedBack({...filterFeedback})
    //window.COMMON.hideFilter()
    $('#btn-filter-feedback').trigger('click')
  }

  // ATTRIBUTE REPORT COMPANY
  const getDataAttributeReportCompany = async (company) => {
    try {
      const params = { company };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_ATTRIBUTE_REPORT_COMPANY, params);
      if (result && result.data && result.data.findAttributeReportCompany) {
        const data = result.data.findAttributeReportCompany;
        setDataAttributeReportCompany(data);
      } else {
        setDataAttributeReportCompany({});
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.getDataAttributeReportCompany');
    }
  };


  // SELF ASSESSMENT FOR INFLUENCER
  const getDataSelfAssessmentForInfluencer = async () => {
    try {
      const params = {
        selfAssessment: data[object.index]._id
      };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_SELF_ASSESSMENT_FOR_INFLUENCER, params);
      if (result && result.data) {
        setDataSelfAssessmentInfluencer(result.data.getSelfAssessmentInfluencers);
      } else {
        setDataSelfAssessmentInfluencer([]);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.getDataSelfAssessmentForInfluencer');
    }
  };

  // TASK SELF ASSESSMENT
  const getDataTaskSelfAssessment = async () => {
    try {
      const params = {
        selfAssessment: data[object.index]._id
      };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_TASK_SELF_ASSESSMENT_BY_SCHEDULE, params);
      if (result && result.data) {
        const dataTask = window.COMMON.formatDate(result.data.getTaskSelfAssessmentsBySchedule, 'createdDate');
        let dataTaskCompany = window.COMMON.getArrsInArrs('COMPANY', dataTask, 'type');
        dataTaskCompany = window.COMMON.sortData(dataTaskCompany, 'company._id');
        let dataTaskInfluencer = window.COMMON.getArrsInArrs('INFLUENCER', dataTask, 'type');
        dataTaskInfluencer = window.COMMON.sortData(dataTaskInfluencer, 'company._id');
        setDataTaskCompany(dataTaskCompany);
        setDataTaskInfluencer(dataTaskInfluencer);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.getDataTaskSelfAssessment');
    }
  };

  // SHARE SELF ASSESSMENT
  const getDataShareSelfAssessment = async () => {
    try {
      const params = {
        id: data[object.index]._id
      };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_FIND_SELF_ASSESSMENT, params);
      let dataShare = [];
      if (result && result.data && result.data.findSelfAssessment) {
        dataShare = result.data.findSelfAssessment.share;
      }
      setDataShare(dataShare);
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.getDataTaskSelfAssessment');
    }
  };

   // TASK SHARE SELF ASSESSMENT
  const getDataTaskShareSelfAsessment = async () => {
    try {
      const id = data[object.index]._id;
      const getTaskSharePromises = []
      for(const shareItem of dataShare) {
        const { companies, groups } = shareItem;
        if(companies && companies.length) {
          for(const company of companies) {
            if(groups && groups.length) {
              for(const group of groups) {
                let params = {
                  selfAssessment: id,
                  company: company._id,
                  group: group._id
                }
                getTaskSharePromises.push(
                  window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_TASK_SHARE_SELF_ASSESSMENT_BY_SELF_ASSESSMENT, params)
                )
              }
            }
          }
        }
      }
      const [...result] = await Promise.all(getTaskSharePromises);
      if(result && result.length) {
        setDataTaskShare(result.reduce((acc, curr) => {
          return [...acc, ... curr?.data?.getTaskShareSelfAssessmentsBySelfAssessment || []]
        }, []));
      }

    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.getDataTaskShareSelfAsessment');
    }
  };

  const countData = async () => {
    try {
      const params = {
        input: {
          organization: window.COMMON.checkRoleIsSystem() ? window.COMMON.getDataSelect(filter.company, false) : COMPANY_ID,
          startDate: window.COMMON_DATE.getStrDateFilter(filterForQuery.startDate, -1),
          endDate: window.COMMON_DATE.getStrDateFilter(filterForQuery.endDate, 1)
        },
        tab: viewTab
      };  
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_COUNT_SELF_ASSESSMENT_SUM_BY_TAB, params);
      if (result) {
        dataSum.total = result.data.countSelfAssessmentsByTab.total
        dataSum.news = result.data.countSelfAssessmentsByTab.news
        dataSum.expired = result.data.countSelfAssessmentsByTab.expired
        dataSum.running = result.data.countSelfAssessmentsByTab.running
        dataSum.completed = result.data.countSelfAssessmentsByTab.completed
        setDataSum({...dataSum}) 
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.countData');
    }
  }

  // eslint-disable-next-line
  const showContainerTask = useCallback((index) => {
    object.isShare = false;
    object.index = index;
    object.item = data[index];
    object.status = data[index].status;
    object.caseItem = caseData.find(item => item.assessment?._id === data[index]._id)
    window.COMMON.showContainerSave(false, true);
    setDataSelected([]);
    setObject({ ...object });
    getDataTaskSelfAssessment();
    getDataSelfAssessmentForInfluencer();
    getDataShareSelfAssessment();
  });

  // eslint-disable-next-line
  const showContainerShare = useCallback((index) => {
    object.isShare = true;
    object.index = index;
    object.item = data[index];
    object.status = data[index].status;
    window.COMMON.showContainerSave(false, true);
    options.shareGroups = window.COMMON.createDataSelectWithLanguage(window.COMMON.getValueFromAttr(object.item, 'groups', []), '_id', 'name', language.current, false);
    options.shareCompanies = window.COMMON.createDataSelectWithGroup(window.COMMON.getValueFromAttr(object.item, 'companies', []), '_id', 'information.name', 'type', 'type');
    const influencers = dataInfluencer.filter(function (item) {
      return item._id !== (filter.company && filter.company.value ? filter.company.value : COMPANY_ID);
    });

    // set advisor list
    options.shareInfluencers = window.COMMON.createDataSelect(dataInfluencer, 'information._id', 'information.name');

    //set default data
    defaultOptions.shareGroups = window.COMMON.createDataSelect(window.COMMON.getValueFromAttr(object.item, 'groups', []), '_id', 'name');
    defaultOptions.shareCompanies = window.COMMON.createDataSelect(window.COMMON.getValueFromAttr(object.item, 'companies', []), '_id', 'information.name');

    setUserData(influencers);
    setOptions(options);
    setObject({ ...object });
    setDefaultOptions(defaultOptions);
    getDataShareSelfAssessment();
  });

  const moveToDraft = (draftData) => {
    localStorage.setItem(window.CONSTANT.DRAFT_SCHEDULE_ASSESSMENT_CODE, draftData.draftcode);
    history.push(SCHEDULE_ASSESSMENT_PAGE);
  };

  const showContainerSave = useCallback((index) => {
    const selectedData = data[index];
    if (selectedData.status === 'draft') {
      moveToDraft(selectedData);
      return;
    }
    window.COMMON.showContainerSave();
    object.index = index;
    object.status = 0;
    const dataSave = {};
    if (index === -1) {
      dataSave.name = '';
      dataSave.code = window.COMMON.generateCode('SA');
      dataSave.description = '';
      select.type = typeOptions[0];
      select.period = periodOptions[0];
      select.companies = [];
      select.groups = [];
      date.startDate = new Date();
      date.endDate = window.COMMON_DATE.addDaysFromNow(30);
    } else {
      const obj = data[index];
      object.status = window.COMMON.getValueFromAttr(obj, 'status');
      dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
      dataSave.name = window.COMMON.getValueFromAttr(obj, 'name');
      dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
      dataSave.description = window.COMMON.getValueFromAttr(obj, 'description');
      select.companies = window.COMMON.setDataMultiSelectWithGroup(options.companies, window.COMMON.getValueFromAttr(obj, 'companies'), '_id');
      select.groups = window.COMMON.setDataMultiSelect(options.groups, window.COMMON.getValueFromAttr(obj, 'groups'), '_id');
      select.type = window.COMMON.setDataSelect(typeOptions, window.COMMON.getValueFromAttr(obj, 'type'));
      select.period = window.COMMON.setDataSelect(periodOptions, window.COMMON.getValueFromAttr(obj, 'period'));
      date.startDate = window.COMMON_DATE.convertStringToDate(window.COMMON.getValueFromAttr(obj, 'startDate'), '/');
      date.endDate = window.COMMON_DATE.convertStringToDate(window.COMMON.getValueFromAttr(obj, 'endDate'), '/');
    }
    setObject(object);
    setSelect(select);
    setDate(date);
    setDataSave({ ...dataSave });
    // eslint-disable-next-line
  }, [object, options, select, date, data, periodOptions, typeOptions]);

  const deleteDraft = (draftData) => {
    window.COMMON.removeDraft({
      key: window.CONSTANT.DRAFT_SCHEDULE_ASSESSMENT,
      draftcode: draftData.draftcode
    });
  };

  // eslint-disable-next-line
  const openDeleteDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    if (data[index].status === 1) {
      window.COMMON.showMessage('warning', 'MSG_CODE_013', window.MSG.MSG_CODE_013);
      return;
    }
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = () => {
      const selectedData = data[index];
      if (selectedData.status === 'draft') {
        deleteDraft(selectedData);
        setData((current) => {
          const newCurrent = current.filter(item => item.draftcode !== selectedData.draftcode);
          return [
            ...newCurrent
          ];
        });
        return;
      }
      deleteData();
    };
  });

  const chooseItem = useCallback((checked) => {
    const dataSelected = [];
    const keys = Object.keys(checked);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (checked[key]) {
        dataSelected.push(key);
      }
    }
    setDataSelected(dataSelected);
  }, []);
  const makeAssessmentReportConclusion = useCallback((index, type) => {
    const companyId = window.COMMON.getValueFromAttr(dataTaskCompany[index], 'company._id');
    object.taskId = dataTaskCompany[index]._id;
    object.company = companyId;
    const taskInfluencer = window.COMMON.getObjectInArrs(companyId, dataTaskInfluencer, 'company._id');
    object.taskInfluencerId = taskInfluencer ? taskInfluencer._id : null;
    //check assessment done for each company, done if have 2 approve of company approve and advisor = null
    object.isDone = (dataTaskCompany[index].status === 2) && (taskInfluencer?.status!==undefined? taskInfluencer.status=== 2:true)
    //object.isDone = type === 'COMPANY' ? dataTaskCompany[index].status === 2 : taskInfluencer.status === 2;
    object.type = type === 'COMPANY' ? 0 : 1;
    window.COMMON.showModal('#modal-preview');
    setDataNote([]);
    getDataAttributeReportCompany(companyId);
    setObject({ ...object });
    // eslint-disable-next-line
  }, [object, dataTaskCompany, dataTaskInfluencer]);

  const openExportConclusion = useCallback(async (index, type) => {
    const companyId = window.COMMON.getValueFromAttr(dataTaskCompany[index], 'company._id');
    object.taskId = dataTaskCompany[index]._id;
    const taskInfluencer = window.COMMON.getObjectInArrs(companyId, dataTaskInfluencer, 'company._id');
    object.taskInfluencerId = taskInfluencer ? taskInfluencer._id : null;
    //check assessment done for each company, done if have 2 approve of company approve and advisor = null
    object.isDone = (dataTaskCompany[index].status === 2) && (taskInfluencer?.status!==undefined? taskInfluencer.status=== 2:true)
    object.type = type === 'COMPANY' ? 0 : 1;
    window.COMMON.showModal('#modal-export-conclusion');
    setDataNote([]);
    await getDataAttributeReportCompany(companyId);
    setPreviewReport(dataTaskCompany[index])
    setObject({ ...object });
    // eslint-disable-next-line
  }, [object, dataTaskCompany, dataTaskInfluencer]);


  const handleChangeNote = useCallback((name, data) => {
    if (name === 'company') {
      setDataNote(data);
    }
    if (name === 'advisor') {
      setDataCompareNote(data);
    }
  }, []);

  // eslint-disable-next-line
  const openReworkTaskSelfAssessment = useCallback((index, type) => {
    if (index < 0) {
      console.warn("openReworkTaskSelfAssessment: Index not found");
      return;
    }

    object.taskId = type === 'COMPANY' ? dataTaskCompany[index]._id : dataTaskInfluencer[index]._id;
    window.COMMON.showModal('#modal-save');
    window.saveMethod = () => reworkTaskSelfAssessment(type);
    setObject(object);
  });

  // eslint-disable-next-line
  const openCompleteTaskSelfAssessment = useCallback((index, type) => {
    if (index < 0) {
      console.warn("openReworkTaskSelfAssessment: Index not found");
      return;
    }

    object.taskId = type === 'COMPANY' ? dataTaskCompany[index]._id : dataTaskInfluencer[index]._id;
    window.COMMON.showModal('#modal-save');
    window.saveMethod = () => completeTaskSelfAssessment(type);
    setObject(object);
  });

  const openAssignData = () => {
    select.influencers = [];
    //  get selected companies influencer.
    getSelectedCompaniesAdvisors();
    setSelect({ ...select });
    window.COMMON.showModal('#modal-assign');
  };

  const openShareData = () => {
    select.influencers = [];
    select.companies = [];
    select.groups = [];
    setSelect({ ...select });
    window.COMMON.showModal('#modal-share');
  };

  const openSaveData = (event) => {
    const checkForm = window.COMMON.checkFormValidation('form', event);
    const checkGroups = window.COMMON.checkSelectValidation('select-groups', select.groups);
    const checkCompanies = window.COMMON.checkSelectValidation('select-companies', select.companies);
    if (!checkForm || !checkGroups || !checkCompanies) {
      return;
    }
    window.COMMON.showModal('#modal-save');
    window.saveMethod = saveData;
  };

  const saveData = async () => {
    try {
      dataSave.startDate = window.COMMON_DATE.getStrDateFilter(date.startDate, -1);
      dataSave.endDate = window.COMMON_DATE.getStrDateFilter(date.endDate, 1);
      const keys = Object.keys(select);
      for (let i = 0; i < keys.length; i++) {
        const element = keys[i];
        const check = element === 'groups' || element === 'companies';
        dataSave[element] = window.COMMON.getDataSelect(select[element], check);
      }
      dataSave.company = filter.company && filter.company.value ? filter.company.value : COMPANY_ID;
      const params = {
        input: dataSave
      };
      window.COMMON.trimData(params);
      if (object.index === -1) {
        params.input.company = filter.company.value;
        const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_ADD_SELF_ASSESSMENT, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          window.COMMON.backContainerData();
          getDataSelfAssessment();
          createTrackingEvent(eventName.ADD_SELF_ASSESSMENT);
        }
      } else {
        const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_EDIT_SELF_ASSESSMENT, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          window.COMMON.backContainerData();
          getDataSelfAssessment();
          createTrackingEvent(eventName.EDIT_SELF_ASSESSMENT);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.saveData');
    }
  };

  const deleteData = async () => {
    try {
      const params = {
        id: data[object.index]._id
      };
      const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_DELETE_SELF_ASSESSMENT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataSelfAssessment();
        createTrackingEvent(eventName.DELETE_SELF_ASSESSMENT);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.deleteData');
    }
  };

  const handleSubmit = () => {
    if (dataNote?.length > 0) {
      submitTaskSelfAssessment(dataNote, object.taskId);
    }
    if (dataCompareNote?.length > 0) {
      submitTaskSelfAssessment(dataCompareNote, object.taskInfluencerId);
    }

    addOrUpdateCaseConclusion(conclusionData, object.taskId, object.taskInfluencerId);
  };

  const checkFullyConclusion = async (assessmentId) => {
    try {
      if(!assessmentId) {
        return;
      }
      const params = {
        assessmentId: assessmentId
      };
      const checkFullConclusionRes = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_CHECK_FULLY_CONCLUSION, params, false);
  
      return checkFullConclusionRes && checkFullConclusionRes.data && checkFullConclusionRes.data.checkFullyConclusionByAssessment;
    } catch(error) {
      Common.showErrorLogs('SelfAssessmentPage.checkFullyConClusion');
    }
  }

  const addOrUpdateCaseConclusion = async (dataConclusion, companyTaskId, influencerTaskId) => {
    // Use this to remove unwanted fields when fetching existing data (e.g. GraphQL __typename)
    const truncateConclusionObject = (conclusionFieldData) => ({
      evaluation: conclusionFieldData.evaluation,
      comment: conclusionFieldData.comment
    });

    try {
      const input = {
        companyTask: companyTaskId,
        influencerTask: influencerTaskId,
        overview: truncateConclusionObject(dataConclusion.overview),
        stating: truncateConclusionObject(dataConclusion.stating),
        policy: truncateConclusionObject(dataConclusion.policy),
        content: truncateConclusionObject(dataConclusion.content),
        handling: truncateConclusionObject(dataConclusion.handling)
      }

      if (dataConclusion?._id) {
        input._id = dataConclusion._id
      }

      if (object.caseItem?._id) {
        input.case = object.caseItem._id
      }

      const result = await Common.mutation(CaseAssessmentGRAPHQL.MUTATION_ADD_OR_UPDATE_CASE_CONCLUSION, { input });
      if(Common.checkResultData(result, Constant.MODE_UPDATE)) {
        // check full conclusion -> close case.
        const isFullyConclusion = await checkFullyConclusion(object.caseItem?.assessment?._id)
        if(isFullyConclusion) {
          handleCloseCase(object.caseItem);
        }
        setObject({...object,taskId:null})
      }
    } catch (error) {
      Common.showErrorLogs('addOrUpdateCaseConclusion');
    }
  };

  const handleCloseCase = async (item) => {
    if(!item) {
      return ;
    }

    Common.mutation(CaseAssessmentGRAPHQL.MUTATION_CLOSE_CASE_ASSESSMENT, { caseAssessmentId: item._id });
  };

  const submitTaskSelfAssessment = async (data, id) => {
    try {
      const input = [];
      data.forEach(element => {
        const obj = {};
        obj._id = id;
        obj.group = element.group._id;
        obj.feedback = [];
        element.feedback.forEach(feedback => {
          const child = {};
          child.question = feedback.question._id;
          child.answers = [];
          feedback.answers.forEach(answer => {
            child.answers.push(answer._id);
          });
          child.answerText = feedback.answerText;
          child.note = feedback.note;
          child.consultantEvaluation = feedback.consultantEvaluation;
          child.consultantNote = feedback.consultantNote;
          child.documents = [];
          feedback.documents.forEach(document => {
            child.documents.push({
              id: document.id,
              name: document.name,
              size: document.size,
              type: document.type,
              ext: document.name.split('.').pop()
            });
          });
          obj.feedback.push(child);
        });
        input.push(obj);
      });
      const params = { input };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_NOTE_TASK_SELF_ASSESSMENT_FOR_INFLUENCER, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
        getDataTaskSelfAssessment();
        window.COMMON.hideModal('#modal-preview');
        createTrackingEvent(eventName.NOTE_TASK_SELF_ASSESSMENT_FOR_INFLUENCER);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.submitTaskSelfAssessment');
    }
  };

  const reworkTaskSelfAssessment = async (type) => {
    try {
      const params = {
        id: object.taskId
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_REWORK_TASK_SELF_ASSESSMENT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
        getDataTaskSelfAssessment();
        createTrackingEvent(eventName.REWORK_TASK_SELF_ASSESSMENT);
        if (type === 'COMPANY') {
          const data = dataTaskCompany.find(item => item._id === object.taskId);
          if (data) {
            const result = await window.COMMON.sendMailNotify({
              email: data.company?.information?.email,
              title: window.I18N('redo_self_assessment_for_influencer'),
              body: `
            <p>Kính gửi ${data.company?.information?.name}!</p>
            <p>Tổ chức của bạn đã được yêu cầu thực hiện lại tự đánh giá <b>${data.selfAssessment?.name}</b>. Vui lòng giúp chúng tôi kiểm tra và thực hiện lại.</p>
            <p>Cảm ơn bạn,</p>
            <p>GOPY TEAM</p>
            --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------<br></br>
            <p>Dear ${data.company?.information?.name}!</p>
            <p>Your organization was requested to redo a self assessment <b>${data.selfAssessment?.name}</b>. Please help us check and do it again.</p>
            <p>Thanks you,</p>
            <p>THE GOPY TEAM</p>`
            });
            if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
              createTrackingEvent(eventName.SEND_MAIL);
            }
          }
        }
        if (type === 'INFLUENCER') {
          const data = dataTaskInfluencer.find(item => item._id === object.taskId);
          if (data && Array.isArray(data.influencers)) {
            Promise.all(data.influencers.map(async (influencer) => {
              const result = await window.COMMON.sendMailNotify({
                email: influencer.email,
                title: window.I18N('redo_self_assessment_for_influencer'),
                body: `
                <p>${influencer.name} thân mến!</p>
                <p>Bạn đã được yêu cầu thực hiện lại tự đánh giá <b>${data.selfAssessment?.name}</b> <b>${moment().format('[ngày] DD [tháng] MM')}</b> cho tổ chức ${data.company?.information?.name}. Vui lòng giúp chúng tôi kiểm tra và thực hiện lại.</p>
                <p>Cảm ơn bạn,</p>
                <p>GOPY TEAM</p>
                --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------<br></br>
                <p>Dear ${influencer.name}!</p>
                <p>You organization was requested to redo a self assessment <b>${data.selfAssessment?.name}</b> on <b>${moment().format('MMMM DD')}</b> for organization ${data.company?.information?.name}. Please help us check and do it again.</p>
                <p>Thanks you,</p>
                <p>THE GOPY TEAM</p>`
              });
              if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
                createTrackingEvent(eventName.SEND_MAIL);
              }
            }))
          }
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.reworkTaskSelfAssessment');
    }
  };

  const completeTaskSelfAssessment = async (type) => {
    try {
      const params = {
        id: object.taskId
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_COMPLETE_TASK_SELF_ASSESSMENT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
        getDataTaskSelfAssessment();
        createTrackingEvent(eventName.COMPLETE_TASK_SELF_ASSESSMENT);
        if (type === 'COMPANY') {
          const data = dataTaskCompany.find(item => item._id === object.taskId);
          if (data) {
            const result = await window.COMMON.sendMailNotify({
              email: data.company?.information?.email,
              title: window.I18N('completed_self_assessment_title'),
              body: `
          <p>Kính gửi ${data.company?.information?.name}!</p>
          <p>Tổ chức của bạn đã được phê duyệt kết quả tự đánh giá <b>${data.selfAssessment?.name}</b> vào <b>${moment().format('[ngày] DD [tháng] MM')}</b> </p>
          <p>Cảm ơn bạn,</p>
          <p>GOPY TEAM</p>
          --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------<br></br>
          <p>Dear ${data.company?.information?.name}!</p>
          <p>Your organization was approved for self assessment <b>${data.selfAssessment?.name}</b> on <b>${moment().format('MMMM DD')}</b> </p>
          <p>Thanks you,</p>
          <p>THE GOPY TEAM</p>`
            });
            if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
              createTrackingEvent(eventName.SEND_MAIL);
            }
          }
        }
        if (type === 'INFLUENCER') {
          const data = dataTaskInfluencer.find(item => item._id === object.taskId);
          if (data && Array.isArray(data.influencers)) {
            Promise.all(data.influencers.map(async (influencer) => {
              const result = await window.COMMON.sendMailNotify({
                email: influencer.email,
                title: window.I18N('completed_self_assessment_title'),
                body: `
              <p>${influencer.name} thân mến!</p>
              <p>Kết quả tự đánh giá <b>${data.selfAssessment?.name}</b> vào <b>${moment().format('[ngày] DD [tháng] MM')}</b> cho tổ chức <b>${data.company?.information?.name}</b> đã được phê duyệt</p>
              <p>Cảm ơn bạn,</p>
              <p>GOPY TEAM</p>
              --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------<br></br>
              <p>Dear ${influencer.name}!</p>
              <p>Your result for self assessment <b>${data.selfAssessment?.name}</b> on <b>${moment().format('MMMM DD')}</b> for organization <b>${data.company?.information?.name}</b> have been approved.</p>
              <p>Thanks you,</p>
              <p>THE GOPY TEAM</p>`
              });
              if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
                createTrackingEvent(eventName.SEND_MAIL);
              }
            }))
          }
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.completeTaskSelfAssessment');
    }
  };

  const assignSelfAssessment = async () => {
    try {
      const checkInfluencer = window.COMMON.checkSelectValidation('select-influencers', select.influencers);
      if (!checkInfluencer) {
        return;
      }
      const params = {
        input: {
          selfAssessment: data[object.index]._id,
          influencers: window.COMMON.getDataSelect(select.influencers, true),
          companies: dataSelected
        }
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_ASSIGN_SELF_ASSESSMENT_INFLUENCER, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
        setDataSelected([]);
        getDataSelfAssessmentForInfluencer();
        window.COMMON.hideModal('#modal-assign');
        createTrackingEvent(eventName.ASSIGN_SELF_ASSESSMENT_INFLUENCER);

        //send mail notify to advisor 
        const selectedUsers = userData.filter(item => params.input?.influencers?.includes(item._id));
        if (selectedUsers.length > 0) {
          Promise.all(selectedUsers.map(async (user) => {
            const result = await window.COMMON.sendMailNotify({
              email: user.email,
              title: window.I18N('new_self_assessment_for_influencer_title'),
              body: `
            <p>Chào ${user.name}!</p>
            <p>Bạn đã được chỉ định thực hiện tự đánh giá <b>${data[object.index].name}</b> vào <b>${moment().format('[ngày] DD [tháng] MM')}</b> cho các công ty dưới đây: </p>
            <br>
					  ${data[object.index].companies?.map(company => `<p>${company.information?.name}</p>`)}
					  </br>
            <p>Vui lòng giúp chúng tôi hoàn thành nó.</p>
            <p>Cảm ơn bạn,</p>
            <p>GOPY TEAM</p>
            --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------<br></br>
            <p>Dear ${user.name}!</p>
            <p>You were assigned to do new self assessment <b>${data[object.index].name}</b> on <b>${moment().format('MMMM DD')}</b> for below companies: </p>
            <br>
					  ${data[object.index].companies?.map(company => `<p>${company.information?.name}</p>`)}
					  </br>
            <p>Please help us completed it</p>
            <p>Thanks you,</p>
            <p>THE GOPY TEAM</p>`
            });
            if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
              createTrackingEvent(eventName.SEND_MAIL);
            }
          }));
        }

        //assign influencer => step = 2
        if (data[object.index].step < 2) {
          changeSelfAssessmentStep();
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.assignSelfAssessment');
    }
  };

  const changeSelfAssessmentStep = async () => {
    try {
      const params = {
        input: {
          _id: data[object.index]._id,
          name: data[object.index].name,
          code: data[object.index].code,
          step: 2
        }
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_EDIT_SELF_ASSESSMENT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
        createTrackingEvent(eventName.EDIT_SELF_ASSESSMENT);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.assignSelfAssessment');
    }
  };

  const openDeleteShareDialog = (index) => {
    object.itemIndex = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteShareSelfAssessment;
  };

  const shareSelfAssessment = async () => {
    try {
      const checkInfluencer = window.COMMON.checkSelectValidation('select-share-influencers', select.influencers);
      if (!checkInfluencer) {
        return;
      }
      const params = {
        input: {
          _id: data[object.index]._id,
          influencers: window.COMMON.getDataSelect(select.influencers, true),
          companies: window.COMMON.getDataSelect(defaultOptions.shareCompanies, true),
          groups: window.COMMON.getDataSelect(defaultOptions.shareGroups, true)
        }
      };
      const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_SHARE_SELF_ASSESSMENT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
        getDataShareSelfAssessment();
        window.COMMON.hideModal('#modal-share');
        createTrackingEvent(eventName.SHARE_SELF_ASSESSMENT);

        //send mail notify to assessor
        const selectedUsers = userData.filter(item => params.input?.influencers?.includes(item.information?._id));
        if (selectedUsers.length > 0) {
          Promise.all(selectedUsers.map(async (user) => {
            const result = await window.COMMON.sendMailNotify({
              email: user.information?.email,
              title: window.I18N('new_share_self_assessment_title'),
              body: `
            <p>Kính gửi ${user.information?.name}!</p>
            <p>Tự đánh giá <b>${data[object.index].name}</b> vào <b>${moment().format('[ngày] DD [tháng] MM')}</b> vừa được chia sẻ đến bạn.</p>
            <p>Vui lòng giúp chúng tôi hoàn thành nó.</p>
            <p>Cảm ơn bạn,</p>
            <p>GOPY TEAM</p>
            --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------<br></br>
            <p>Dear ${user.information?.name}!</p>
            <p>New self assessment <b>${data[object.index].name}</b> on <b>${moment().format('MMMM DD')}</b> is shared to you .</p>
            <p>Please help us completed it</p>
            <p>Thanks you,</p>
            <p>THE GOPY TEAM</p>`
            });
            if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
              createTrackingEvent(eventName.SEND_MAIL);
            }
          }));
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.shareSelfAssessment');
    }
  };

  const deleteShareSelfAssessment = async () => {
    try {
      const params = {
        id: data[object.index]._id,
        child: dataShare[object.itemIndex]._id
      };
      const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_DELETE_SHARE_SELF_ASSESSMENT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataShareSelfAssessment();
        createTrackingEvent(eventName.DELETE_SHARE_SELF_ASSESSMENT);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.deleteShareSelfAssessment');
    }
  };

  const handleExportConClusion = () => {
    conclusionExportArea.current.save();
  };

  const canBeConclude = () => {
    const companyId = object.company;
    
    // check task share selfassessment by company is apporved.
/*     const taskShares = getTaskShareByCompanyId(companyId, dataTaskShare)
 */
/*     const isAllTashShareApproved = taskShares.every((item) => item.isApproved);
 */
    const isClosedCase = object.caseItem?.status === Constant.CASE_ASSESSMENT_STATUS.CLOSED
    const isAsessmentExpired = object.status === 2;

    return  !isAsessmentExpired && !isClosedCase && !isCurrentPreviewTaskConcluded && conclusionData.overview.evaluation!=='' && object.isDone /* isAllTashShareApproved && */ ;
  }

  const getTaskShareByCompanyId = useCallback((companyId, dataTaskShareByAssessment) => {
    const taskByCompany = [];
    for (const task of dataTaskShareByAssessment) {
      if(task?.company?._id === companyId) {
        taskByCompany.push(task);
      }
    }
    return taskByCompany;
  },[]);

  const checkShareAble = async () => {
    try {
      const isFullyConclusion = await checkFullyConclusion(object.item?._id);
      setIsShareAbleAssessment(!isFullyConclusion);
    } catch (error) {
      Common.showErrorLogs('SelfAssessmentPage.checkShareAble')
    }
    return true;
  }

  const checkTaskConclusionByCompany = async(companyTaskId) => {
    try {
      const result = await Common.query(CaseAssessmentGRAPHQL.QUERY_ASSESSMENT_CONCLUSION_BY_COMPANY_TASK_ID, { companyTask: companyTaskId })
      if (result?.data?.getAssessmentConclusionByCompanyTaskId) {
        return setIsCurrentPreviewTaskConCluded(true);
      }
    } catch (error) {
      Common.showErrorLogs('SelfAssessmentPage.checkTaskConclusionByCompanyTask')
    }
    return setIsCurrentPreviewTaskConCluded(false);
  }
  const currentAssessmentIsRunning = object.status === Constant.SELF_ASSESSMENT_STATUS.RUNNING 

  const changeTabPreview = (paramTab)=>{
    if (paramTab!==tabPreviewModal){
      setTabPreviewModal(paramTab)
    }
  }

  useEffect(() => {
    if(dataShare.length) {
      getDataTaskShareSelfAsessment();
    }
  }, [dataShare])

  useEffect(() => {
    checkShareAble();
  }, [object.item])

  useEffect(() => {
    if(object.taskId) {
      checkTaskConclusionByCompany(object.taskId)
    }
  }, [object.taskId])

  
  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <div className="container-row">
      <SumInfoFilter dataSum={dataSum} filterForQuery={filterForQuery} handleFilterChange={(param)=>{setFilterForQuery({...filterForQuery, ...param})}} page="assessment"></SumInfoFilter>
      <MDBRow id="container-data" className="wow fadeIn animated" data-wow-delay="0.5s">
        <MDBCol>
          <ul className="list-group">
            <li className="list-group-item d-flex justify-between align-items-center">
              <div>
                <div className="d-flex w-auto align-items-center justify-content-start fadeInDown animated"  data-wow-delay="0.5s">
                  <MDBBtn color={viewTab === viewTabs.assessment ? 'primary' :''} className="mr-0" onClick={() => setViewTab(viewTabs.assessment)}>
                    <MDBIcon fa="true" icon="user-tie" className="fa-fw" /> {'Assessment'}
                  </MDBBtn>
                  {window.COMMON.checkAccountTypeIsSystemOrInfluencer() && <MDBBtn color={viewTab === viewTabs.caseAssessment ? 'primary' : ''} className="mr-0" onClick={() => setViewTab(viewTabs.caseAssessment)}>
                    <MDBIcon fa="true" icon="user-edit" className="fa-fw" /> {'Case assessment'}
                  </MDBBtn>}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end flex-grow-1">
                {
                  !window.COMMON.checkRoleIsSystem() ? <div></div> : <div className="bd-highlight mr-2" style={{ width: '300px' }}>
                    <div className="input-group input-group-transparent">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <MDBIcon fa="true" icon="building" className="fa-fw" />
                        </span>
                      </div>
                      <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.company} options={options.company} onChange={(event) => handleFilterSelect(event, 'company')} isSearchable />
                    </div>
                  </div>
                }
                <Filter btnId="btn-filter-assessment" id="container-filter" className="col-md-8">
                  <MDBRow>
                    <MDBCol sm="12" className="mb-2">
                      <div className="mr-2 w-100">
                        <MDBInputGroup type="text" value={filter.text} hint={window.I18N('search_name_code')} onChange={handleFilterChange} maxLength="100" pattern="\S(.*\S)?" required containerClassName="mt-2"
                          append={
                            <span className="input-group-text cursor-pointer" id="btn-search">
                              <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                            </span>
                          }
                        />
                      </div>
                    </MDBCol>
                    <MDBCol sm="4" className="mb-2 pr-sm-2">
                      <strong>{window.I18N('status')}</strong>
                      <div className="input-group input-group-transparent mt-1">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <MDBIcon fa="true" icon="filter" className="fa-fw" />
                          </span>
                        </div>
                        <Select value={filter.status} options={statusFilter} className="md-form m-0" onChange={(event) => handleFilterSelect(event, 'status')} isSearchable />
                      </div>
                    </MDBCol>
                    <MDBCol sm="4" className="mb-2 pl-sm-2 pr-sm-2">
                      <strong>{window.I18N('type')}</strong>
                      <div className="input-group input-group-transparent mt-1">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <MDBIcon fa="true" icon="filter" className="fa-fw" />
                          </span>
                        </div>
                        <Select value={filter.type} options={typeFilterOptions} className="md-form m-0" onChange={(event) => handleFilterSelect(event, 'type')} isSearchable />
                      </div>
                    </MDBCol>
                    <MDBCol sm="4" className="mb-2 pl-sm-2">
                      <strong>{window.I18N('period')}</strong>
                      <div className="input-group input-group-transparent mt-1">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <MDBIcon fa="true" icon="filter" className="fa-fw" />
                          </span>
                        </div>
                        <Select value={filter.period} options={periodFilterOptions} className="md-form m-0" onChange={(event) => handleFilterSelect(event, 'period')} isSearchable />
                      </div>
                    </MDBCol>
                    <MDBCol sm="12 text-right">
                      <MDBBtn color="primary" onClick={doSearchData}>
                        <MDBIcon fa="true" icon="sync-alt" className="fa-fw" /> {window.I18N('apply')}
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </Filter>

                <div className="bd-highlight">
                  <MDBBtn color="primary" className={`${viewTab==='CASE_ASSESSMENT'?'d-none':''} mr-0`} onClick={() => {
                    localStorage.setItem(window.CONSTANT.INFLUENCER_INPUT, filter.company.value);
                    history.push(SCHEDULE_ASSESSMENT_PAGE);
                  }}>
                    <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
                  </MDBBtn>
                </div>
                
              </div>
            </li>
            <GridItemSelfAssessmentSchedule data={data} handleAssign={showContainerTask} handleShare={showContainerShare} handleEdit={showContainerSave} handleDelete={openDeleteDialog}></GridItemSelfAssessmentSchedule>
            <li className="list-group-item">
              <Pagination total={pagination.total} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={changePage}></Pagination>
            </li>
          </ul>
        </MDBCol>
      </MDBRow>
      {/* Chỉnh sửa thông tin assessment */}
      <Panel id="container-save" title={window.I18N('create_update_data')} icon="question-circle" style={{ display: 'none' }}>
        <div className="container-btn wow fadeInDown animated" data-wow-delay="0.5s">
          <MDBBtn color="" onClick={window.COMMON.backContainerData}>
            <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('btn_back')}
          </MDBBtn>
          <MDBBtn color="primary" type="button" onClick={object.status > 0 ? () => { } : openSaveData} disabled={object.status > 0}>
            <MDBIcon fa="true" icon="save" className="fa-fw" /> {window.I18N('btn_save')}
          </MDBBtn>
        </div>
        <div className="container-top wow fadeInUp animated" data-wow-delay="0.5s">
          <form id="form" className="needs-validation mt-2" noValidate>
            <MDBRow>
              <MDBCol sm="6">
                <MDBInput outline containerClass="mt-0" type="text" label={window.I18N('name') + ' *'} name="name" value={dataSave.name} onChange={handleChange} maxLength="100" pattern="\S(.*\S)?" required>
                  <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                </MDBInput>
                <MDBRow>
                  <MDBCol>
                    <MDBInput outline containerClass="m-0" type="text" label={window.I18N('code') + ' *'} name="code" value={dataSave.code} onChange={handleChange} maxLength="50" pattern="\S(.*\S)?" required>
                      <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                    </MDBInput>
                  </MDBCol>
                  <MDBCol>
                    <div className="input-group input-group-transparent">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                        </span>
                      </div>
                      <DatePicker placeholderText={window.I18N('start_date') + ' *'} className="form-control" dateFormat="dd/MM/yyyy" selected={date.startDate} startDate={date.startDate} endDate={date.endDate}
                        onChange={(event) => handleDatePicker(event, 'startDate')} showYearDropdown showMonthDropdown selectsStart required />
                    </div>
                  </MDBCol>
                  <MDBCol>
                    <div className="input-group input-group-transparent">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                        </span>
                      </div>
                      <DatePicker placeholderText={window.I18N('end_date') + ' *'} className="form-control" dateFormat="dd/MM/yyyy" selected={date.endDate} startDate={date.startDate} endDate={date.endDate}
                        onChange={(event) => handleDatePicker(event, 'endDate')} showYearDropdown showMonthDropdown selectsEnd required />
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBInput outline containerClass="mb-0" type="textarea" label={window.I18N('description')} name="description" value={dataSave.description} onChange={handleChange} maxLength="5000"></MDBInput>
              </MDBCol>
              <MDBCol sm="6">
                <Select id="select-groups" className="md-form mt-0" value={select.groups} placeholder={window.I18N('group_qa') + ' *'} options={options.groups} onChange={(event) => handleSelect(event, 'select-groups', 'groups')} isSearchable isMulti closeMenuOnSelect={false} />
                <Select id="select-companies" value={select.companies} options={options.companies} placeholder={window.I18N('companies') + ' *'} className="md-form" onChange={(event) => handleSelect(event, 'select-companies', 'companies')} isSearchable isMulti closeMenuOnSelect={false} />
                <Select id="select-type" className="md-form" value={select.type} placeholder={window.I18N('type') + ' *'} options={typeOptions} onChange={(event) => handleSelect(event, 'select-type', 'type')} isSearchable />
                <Select id="select-period" className="md-form" value={select.period} placeholder={window.I18N('period') + ' *'} options={periodOptions} onChange={(event) => handleSelect(event, 'select-period', 'period')} isSearchable />
              </MDBCol>
            </MDBRow>
          </form>
        </div>
      </Panel>
      {/* chi tiet assessment sau khi bam vao asign */}
      <Panel id="container-attribute-report" className="container-panel-transparent" icon="info-circle" style={{ display: 'none' }}>
        <div className="container-btn wow fadeInDown animated" data-wow-delay="0.5s">
          <MDBBtn color="" onClick={window.COMMON.backContainerData}>
            <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('btn_back')}
          </MDBBtn>
          {
            object.isShare ? 
            <MDBBtn color="primary" type="button" disabled={!currentAssessmentIsRunning || !isShareAbleAssessment || dataShare.length>0 } onClick={ !currentAssessmentIsRunning || !isShareAbleAssessment || dataShare.length>0 ? () => {} : openShareData}>
              <MDBIcon fa="true" icon="share" className="fa-fw" /> {window.I18N('share_influencer')}
            </MDBBtn> 
            : 
            <MDBBtn color="success" type="button" onClick={!currentAssessmentIsRunning || dataSelected.length === 0 ? () => {}: openAssignData } disabled={!currentAssessmentIsRunning || dataSelected.length === 0 }>
              <MDBIcon fa="true" icon="user-plus" className="fa-fw" /> {window.I18N('assign_influencer')}
            </MDBBtn>
          }
        </div>
        <MDBRow>
          <MDBCol className="wow fadeIn animated" data-wow-delay="0.5s">
            <div className="card">
              <div className="card-header">
                {window.I18N('self')}
              </div>
              <div className="card-body">
                <table className="table table-view mb-0">
                  <tbody>
                    <tr>
                      <td width="20%">{window.I18N('name')}</td>
                      <td width="30%">{window.COMMON.getValueFromAttr(object.item, 'name')}</td>
                      <td width="20%">{window.I18N('type')}</td>
                      <td width="30%">{window.COMMON.getValueFromAttr(object.item, 'typeName')}</td>
                    </tr>
                    <tr>
                      <td width="20%">{window.I18N('code')}</td>
                      <td width="30%">{window.COMMON.getValueFromAttr(object.item, 'code')}</td>
                      <td width="20%">{window.I18N('period')}</td>
                      <td width="30%">{window.COMMON.getValueFromAttr(object.item, 'periodName')}</td>
                    </tr>
                    <tr>
                      <td width="20%">{window.I18N('status')}</td>
                      <td width="30%">{window.COMMON.generateStatusText(window.COMMON.getValueFromAttr(object.item, 'status'))}</td>
                      <td width="20%">{window.I18N('time')}</td>
                      <td width="30%">{window.COMMON.getValueFromAttr(object.item, 'startDate') + ' - ' + window.COMMON.getValueFromAttr(object.item, 'endDate')}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-3">
          <MDBCol>
            {
              object.isShare ? 
                <ListShareSelfAssessment
                  language
                  assessmentStatus={object.status}
                  dataShare={dataShare}
                  dataTaskShare={dataTaskShare}
                  handleDeleteShare={openDeleteShareDialog}
                />
              : 
              <ListSelfAssessment 
                data={dataTaskCompany} 
                dataTaskInfluencer={dataTaskInfluencer} 
                dataSelfAssessmentInfluencer={dataSelfAssessmentInfluencer}
                dataTaskShare={dataTaskShare}
                dataShare={dataShare}
                language={language.current} 
                dataSelected={dataSelected}
                disabled={object.status === 2}
                makeAssessmentReportConclusion={(index) => makeAssessmentReportConclusion(index, 'COMPANY')}
                /* handlePreviewInfluencer={(index) => makeAssessmentReportConclusion(index, 'INFLUENCER')} */
                handleCheckChange={chooseItem}
                handleReject={(index, type) => openReworkTaskSelfAssessment(index, type)}
                handleAccept={(index, type) => openCompleteTaskSelfAssessment(index, type)}
                handleExport={(index) => openExportConclusion(index, 'COMPANY')}
              ></ListSelfAssessment>
            }
          </MDBCol>
        </MDBRow>
      </Panel>
      {/* Modal khi bấm xem lại và kết luận */}
      <Modal 
        id="modal-preview" 
        className="modal-xl" 
        title={
          <Grid container >
            <Grid item className={`tab-control-fanpage ${tabPreviewModal===0?'active':''}`} onClick={()=>{changeTabPreview(0)}} > {'Kết quả đánh giá'}</Grid>
            <Grid item className={`tab-control-fanpage ${tabPreviewModal===1?'active':''}`} onClick={()=>{changeTabPreview(1)}} > {'Kết luận'}</Grid>
            <Grid item className={`tab-control-fanpage ${tabPreviewModal===2?'active':''}`} onClick={()=>{changeTabPreview(2)}} > {'Thông tin công ty'}</Grid>
            <Grid item className={`tab-control-fanpage ${tabPreviewModal===3?'active':''}`} onClick={()=>{changeTabPreview(3)}} > {'Lên lịch làm lại'}</Grid>
            {tabPreviewModal===0 && <Grid item className='d-flex align-items-center justify-content-end flex-grow-1'>
              <Filter id="container-filter-feedback" btnId='btn-filter-feedback' className="col-md-9 mr-0 font-weight-normal font-small">
                <MDBRow>
                    <MDBCol sm="4" className="mb-2 pr-sm-2">
                      <p>{window.I18N('sort')}</p>
                      <div className="input-group input-group-transparent mt-1">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <MDBIcon fa="true" icon="filter" className="fa-fw" />
                          </span>
                        </div>
                        <Select value={filterFeedback.sortFeedback} options={sortFeedbackOptions} className="md-form m-0" onChange={(event) => handleFilterFeedbackSelect(event, 'sortFeedback')} isSearchable />
                      </div>
                    </MDBCol>
                    <MDBCol sm="4" className="mb-2 pl-sm-2 pr-sm-2">
                      <p>{window.I18N('benchmark')}</p>
                      <div className="input-group input-group-transparent mt-1">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <MDBIcon fa="true" icon="filter" className="fa-fw" />
                          </span>
                        </div>
                        <Select value={filterFeedback.benchmark} options={benchmarksOptions} className="md-form m-0" onChange={(event) => handleFilterFeedbackSelect(event, 'benchmark')} isSearchable />
                      </div>
                    </MDBCol>
                    <MDBCol sm="4" className="mb-2 pr-sm-2">
                      <p>{window.I18N('search_question')}</p>
                      <div className="input-group">
                        <MDBInputGroup type="text" value={filterFeedback.text} hint={window.I18N('search_name_code')} onChange={handleFilterFeedbackChange} maxLength="100" pattern="\S(.*\S)?" required
                          append={
                            <span className="input-group-text cursor-pointer">
                              <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                            </span>
                          }
                        />
                      </div>
                    </MDBCol>
                </MDBRow>
                <MDBCol sm="12 text-right">
                      <MDBBtn color='' onClick={()=>{$('#btn-filter-feedback').trigger('click')}}>
                        <MDBIcon fa="true" icon="times" className="fa-fw" /> {window.I18N('btn_close')}
                      </MDBBtn>
                      <MDBBtn color="primary" onClick={applyFilterFeedback}>
                        <MDBIcon fa="true" icon="sync-alt" className="fa-fw" /> {window.I18N('apply')}
                      </MDBBtn>
                  </MDBCol>
              </Filter>
            </Grid>}
          </Grid>
        }
        styleBody={{paddingTop: 0}}
        hideSave= {tabPreviewModal!==1}
        hideFooter = {tabPreviewModal!==1}
        saveEvent={tabPreviewModal ==1 && canBeConclude() ? handleSubmit :  ()=>{}} 
        saveBtn={window.I18N('btn_conclude')}
        handleCloseModal={resetFilterModal}
        saveBtnProps={{
          disabled: !canBeConclude()
        }}
      >
        <MDBRow >
          <MDBCol>
            <ItemPreviewAdminSelfAssessment
              type={object.type}
              selfAssessment={object.item}
              companyTaskId={object.taskId}
              advisorTaskId={object.taskInfluencerId}
              dataAttributeReport={dataAttributeReport}
              company={null}
              dataCompany={dataAttributeReportCompany.items || []}
              isInfluencer={true}
              handleChange={handleChangeNote}
              handleChangeConclusion={setConclusionData}
              dataConclusion={conclusionData}
              caseAssessment={object.caseItem}
              taskShareByCompany={getTaskShareByCompanyId(object.company, dataTaskShare)}
              dataShare={dataShare}
              tabView={tabPreviewModal}
              filter= {appliedFilterFeedBack}
            />
          </MDBCol>
        </MDBRow>
      </Modal>
      {/* Modal khi bấm xuất pdf, để xem trước pdf */}
      <Modal 
        id="modal-export-conclusion" 
        className="modal-xl" 
        hideSave 
        footerActions={
          <>
            <MDBBtn onClick={handleExportConClusion}>{window.I18N('download_pdf')}</MDBBtn>
          </>
        }
      >
        <MDBRow>
          <MDBCol>
            <PDFExport ref={conclusionExportArea} paperSize='A4' landscape={true} margin={'2cm'} fileName={'conclusion'} >
              <ConclusionPdfForm 
                type={object.type} 
                selfAssessment={object.item}
                companyTaskId={object.taskId}
                advisorTaskId={object.taskInfluencerId}
                dataAttributeReport={dataAttributeReport}
                company={null}
                dataCompany={dataAttributeReportCompany.items || []}
                isInfluencer={true}
                dataConclusion={conclusionData}
                handleChangeConclusion={setConclusionData}
                caseAssessment={object.caseItem}
                currentReport={previewReport}
                taskShareByCompany={getTaskShareByCompanyId(object.company, dataTaskShare)}
                dataShare={dataShare}
                >
              </ConclusionPdfForm>
            </PDFExport>
          </MDBCol>
        </MDBRow>
      </Modal>
      
      <Modal id="modal-assign" className="modal-lg" title={window.I18N('assign_influencer')} isNotScroll={true} saveEvent={assignSelfAssessment}>
        <MDBRow>
          <MDBCol>
            <Select
              id="select-influencers"
              className="md-form m-0"
              placeholder={window.I18N('influencers') + ' *'}
              value={select.influencers}
              options={[...options.users, ...window.COMMON.createDataSelectWithGroup(assignedCompanyAdvisors, '_id', 'name', 'accountType', 'accountType')]}
              onChange={(event) => handleSelect(event, 'select-influencers', 'influencers')}
              isSearchable
              isMulti
              closeMenuOnSelect={false}
            />
          </MDBCol>
        </MDBRow>
      </Modal>

      <Modal id="modal-share" className="modal-lg" title={window.I18N('share_influencer')} isNotScroll={true} saveEvent={shareSelfAssessment}>
        <MDBRow>
          <MDBCol>
            <Select 
              id="select-share-influencers" 
              className="md-form m-0" 
              placeholder={window.I18N('assessor') + ' *'} 
              value={select.influencers} 
              options={options.shareInfluencers} 
              onChange={(event) => handleSelect(event, 'select-share-influencers', 'influencers')} 
              isSearchable 
              //isMulti 
              closeMenuOnSelect={false} 
            />
          </MDBCol>
        </MDBRow>
      </Modal>
    </div>
  );
}

const ListShareSelfAssessment = (props) => {
  const {dataShare, handleDeleteShare, language, assessmentStatus, dataTaskShare} = props;
  
  const getFeedbackProgessByAssessor = (assessorId) => {
    const tasksShareByAssessor = dataTaskShare.filter(i => i.influencer?._id === assessorId) 
    const totalFeedbackCount = tasksShareByAssessor.reduce((total, task) => task.feedback?.length ? total + 1 : total, 0)

    if(!tasksShareByAssessor.length) {
      return 0;
    }

    return Math.round(100* totalFeedbackCount / tasksShareByAssessor.length)
  }

  const generateTaskStatusByAssessor = (assessorId) => {

    const tasksShareByAssessor = dataTaskShare.filter(i => i.influencer?._id === assessorId) 

    if (!assessorId) {
      return <small className="text-muted">{window.I18N('none')}</small>;
    }

    if (tasksShareByAssessor.every(task => task.isApproved) ){
      return <label className="label-box label-success p-1 m-0">{window.I18N('approved')}</label>
    }
    // item.status === 1 ? <label className="label-box label-info p-1 m-0">{window.I18N('submited')}</label> 
    return <label className="label-box label-primary p-1 m-0">{window.I18N('in_proccessing')}</label>;
  };

  return (
    <ul className="list-group">
      {
        dataShare.map((item, i) => <li className="list-group-item p-2 wow fadeInUp animated" key={i}>
          <div className="d-flex align-items-center w-100">
            <div className="d-flex align-items-center w-100 pl-2 pr-2">
              <div className="w-100 mr-auto">
                {item.influencers?.map((assessor, idx) => (
                  <div  key={'share_self_assessment_task' + idx}>
                    <MDBRow>
                      <MDBCol>
                          <small className="m-0 text-muted font-weight-bold">Assessors</small>
                          <p  className="card-text mb-1">
                            <MDBIcon fa="true" icon="user" className="fa-fw mr-1" style={{ color: '#dddddd' }}></MDBIcon>
                            {window.COMMON.getValueFromAttr(assessor, 'name')}
                          </p>
                        <hr className="vertical light mt-0"></hr>
                      </MDBCol>
                      <MDBCol>
                        <small className="m-0 text-muted font-weight-bold">{window.I18N('companies')}</small>
                        {
                          window.COMMON.getValueFromAttr(item, 'companies', []).map((child, j) => <p key={i * j + j} className="card-text mb-1">
                            <MDBIcon fa="true" icon="building" className="fa-fw mr-1" style={{ color: '#dddddd' }}></MDBIcon>
                            {window.COMMON.getValueFromAttr(child, 'information.name')}
                          </p>)
                        }
                      </MDBCol>
                      <MDBCol>
                        <small className="m-0 mb-2 text-muted font-weight-bold">{window.I18N('group_qa')}</small>
                        <p className="m-0 mb-2">{generateTaskStatusByAssessor(assessor._id)}</p>
                        {
                          window.COMMON.getValueFromAttr(item, 'groups', []).map((group, idx) => 
                          <p key={i * idx + idx} className="card-text mb-1">
                            <MDBIcon 
                              fa="true" 
                              icon="circle" 
                              className="fa-fw mr-1" 
                              style={{ color: generateTaskStatusByAssessor(assessor._id) ? '#00c851' : '#dddddd' }}
                            ></MDBIcon>
                            {window.COMMON.getValueWithLanguage(group, 'name', language.current)}
                          </p>
                        )}
                        <div className="progress">
                          <div 
                            className="progress-bar progress-bar-success progress-bar-striped progress-bar-animated" 
                            role="progressbar" 
                            aria-valuemin="0" 
                            aria-valuemax="100" 
                            style={{ width: getFeedbackProgessByAssessor(assessor._id) + '%' }}
                          >
                            <span>{ getFeedbackProgessByAssessor(assessor._id) + '%'}</span>
                          </div>
                        </div>
                      </MDBCol>
                      <MDBCol md="2">
                        <small className="m-0 text-muted font-weight-bold">Created Date</small>
                        <p className="text-muted m-0">{window.COMMON_DATE.formatDate(window.COMMON.getValueFromAttr(item, 'createdDate'))}</p>
                        <small className="m-0 text-muted font-weight-bold">Created By</small>
                        <p className="m-0 font-weight-bold mt-2">{window.COMMON.getValueFromAttr(item, 'createdBy.name')}</p>
                      </MDBCol>
                      <MDBCol xs="2" sm="1" md="1" className="text-right">
                        { idx === 0 ? (
                          <MDBBtn 
                            outline
                            className="btn btn-sm btn-transparent m-0" 
                            type="button"
                            color=""
                            disabled={assessmentStatus === 2}
                            onClick={assessmentStatus === 2 ? () => { } : () => handleDeleteShare(i)}
                          >
                            <MDBIcon fa="true" icon="trash-alt" style={{ color: 'red' }} className="fa-fw" />
                          </MDBBtn>
                        ) : null}
                      </MDBCol>
                    </MDBRow>
                    <hr className="light mt-3"></hr>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </li>)
      }
    </ul> 
  )
}

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SelfAssessmentPage);