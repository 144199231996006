const questionEvent = {
  ADD_GROUP_QUESTION: 'ADD_GROUP_QUESTION',
  EDIT_GROUP_QUESTION: 'EDIT_GROUP_QUESTION',
  DEL_GROUP_QUESTION: 'DEL_GROUP_QUESTION',
  ADD_QA: 'ADD_QA',
  EDIT_QA: 'EDIT_QA',
  DEL_QA: 'DEL_QA',
  CHECK_IMPORT_QUESTION: 'CHECK_IMPORT_QUESTION',
  CHECK_IMPORT_ANSWER_QUESTION: 'CHECK_IMPORT_ANSWER_QUESTION',
  IMPORT_QUESTION: 'IMPORT_QUESTION',
  IMPORT_ANSWER_QUESTION: 'IMPORT_ANSWER_QUESTION',
  GRANT_TASK: 'GRANT_TASK',
  SEND_FEEDBACK: 'SEND_FEEDBACK',
  UPDATE_FEEDBACK_BY_TASK: 'UPDATE_FEEDBACK_BY_TASK',
  ADD_CATEGORY_QUESTION: 'ADD_CATEGORY_QUESTION',
  EDIT_CATEGORY_QUESTION: 'EDIT_CATEGORY_QUESTION',
  DEL_CATEGORY_QUESTION: 'DEL_CATEGORY_QUESTION',
  ADD_QA_TEMPLATE: 'ADD_QA_TEMPLATE',
  EDIT_QA_TEMPLATE: 'EDIT_QA_TEMPLATE',
  DEL_QA_TEMPLATE: 'DEL_QA_TEMPLATE',
};

export default questionEvent;