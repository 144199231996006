import React from 'react';
import GridItem from '../../components/GridItem';
import { Fab } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Heading4Styled } from '../../../styles/styled-components/Text/TextSTyled';
const SelectGroupQA = (props) => {
  const { allGroupQA, selectedGroupQA, onClick, onNewGroupQAClick,isShowAddButton } = props;

  return <>
    <div>
      {(selectedGroupQA && selectedGroupQA.length > 0) && <div>
        <Heading4Styled>{window.I18N('selected_group_qa')}</Heading4Styled>
        <div className="mt-3"><GridItem data={selectedGroupQA} handleItemClick={(index) => onClick(index, 'selected')}></GridItem></div>
      </div>}

      <div className="mt-3">
        <Heading4Styled>{window.I18N('all_group_qa')}</Heading4Styled>
        <div className="mt-3"><GridItem showMenu={false} data={allGroupQA} handleItemClick={(index) => onClick(index, 'all')}></GridItem></div>

      </div>

    </div>
    {isShowAddButton === true && <div className="float-button wow fadeInDown animated" data-wow-delay="1s">
      <Fab color="primary" aria-label="add" size="medium" onClick={onNewGroupQAClick}>
        <Add />
      </Fab>
    </div>}
  </>;
};
export default SelectGroupQA;