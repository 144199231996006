import { MDBBtn, MDBCol, MDBIcon, MDBRow } from "mdbreact";
import React, { useState } from "react";
import { connect } from "react-redux"

import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Modal from "../../../components/Modal";
import BillItemDetail from "./BillItemDetail";

const BillItem = ({ data = {}, refetchDataBills = () => {}, language}) => {

  const ACCOUNT_TYPE = localStorage.getItem(window.CONSTANT.ACCOUNT_TYPE)
  const navigate = useHistory()
  const BILL_STATUS = {
    0 : {
      name: window.I18N('payment_pending'),
      label: 'label-warning'
    },
    1 : {
      name: window.I18N('payment_successful'),
      label: 'label-success'
    },
    2 : {
      name: window.I18N('payment_processing'),
      label: 'label-primary'
    },
    3 : {
      name: window.I18N('payment_failed'),
      label: 'label-danger'
    }
  }

  const [isOpenDetailBill, setIsOpenDetailBill] = useState(false)

  const handleConfirmDeleteBill = () => {
    window.COMMON.showModal("#modal-delete")
    window.deleteMethod =handleDeleteBill
  }

  const handleDeleteBill = async () => {
    try {
      const result = await window.COMMON.httpRequest({
          url: `${process.env.REACT_APP_URL_PAYMENT_API}/bills/${data._id}`,
          method: 'DELETE',
          requireToken: true,
          handleError: () => {
              window.COMMON.showErrorLogs('Billing.deleteBill')
          }
      })
      if(result.isDeleted){
        window.COMMON.showMessage('success', 'MSG_CODE_003', window.I18N('MSG_CODE_003'));
        refetchDataBills()
      }
    } catch (error) {
      window.COMMON.showMessage('error', 'MSG_CODE_006', window.I18N('MSG_CODE_006'));
      window.COMMON.showErrorLogs('Billing.deleteBill')
    }
  }

  const handleEditBill = () => {
    const dataEdit = {
      data,
      isEdit: true
    }
    localStorage.setItem(window.CONSTANT.DATA_EDIT_BILL, JSON.stringify(dataEdit))
    navigate.push('/create-bill')
  }

  const generatePaymentURL = (searchParams) => {
      const searchParamsURL = []
      const listSearchParams = Object.entries(searchParams)
      for(let [key, value] of listSearchParams){
        searchParamsURL.push(`${key}=${value}`)
      }
      return `${process.env.REACT_APP_URL_PAYMENT_API}/bills/${data._id}/pay?${searchParamsURL.join('&')}`
  }

  const handlePayBill = () => {
      window.showLoading()
      const searchParams = {
          returnUrl: window.location.origin + '/payment-result',
          locale: language.code === 'VI' ? 'vn' : 'en',
          userId: localStorage.getItem(window.CONSTANT.ID),
          token: localStorage.getItem(window.CONSTANT.AUTHEN_TOKEN)
      }
      const redirectToPaymentGateURL = generatePaymentURL(searchParams)
      setTimeout(() => {
          window.hideLoading()
          window.location.replace(redirectToPaymentGateURL)
      },500) 
  }
 
  const handleShowPopUpConfirmPayment = () => {
    window.COMMON.showModal(`#modal-confirm`)
    window.confirmMethod = handlePayBill
  }

  const handleViewDetailBill = () => {
    window.COMMON.showModal(`#modal-detail-bill-${data._id}`)
    setIsOpenDetailBill(true)
  }

  const handleCloseDetailBill = () => {
    window.COMMON.hideModal(`#modal-detail-bill-${data._id}`)
    setIsOpenDetailBill(false)
  }

  const handleDiscountAmount = () => {
    if(data?.discounts[0]){
      const { value, percent } = data?.discounts[0]
      return (data.amount * percent/100) || value
    }
    return 0
  }

  const handleVATAmount = (dataAfterDiscount) => {
    if(data.vat){
      return (dataAfterDiscount * data.vat/100)
    }
    return 0
  }

  const handleAmountAfterDiscount = () => {
    return data.amount - handleDiscountAmount()
  }

  const handleTotal = () => {
    if(data.amount){
      const amountAfterDiscount = handleAmountAfterDiscount()
      return amountAfterDiscount + handleVATAmount(amountAfterDiscount) 
    }
    return 0
  }

  const amountForDetail = () => {
    const amountAfterDiscount = handleAmountAfterDiscount()
    return ({
      discounts: handleDiscountAmount(),
      vat: handleVATAmount(amountAfterDiscount),
      total: handleTotal()
    })
  }
  
  return (
    <div className="w-full bg-white rounded-lg p-4 mb-2 pb-2 border">
      <MDBRow>
        {/*  Name order*/}
        <MDBCol size="4">
          <h6 className="font-weight-bold" style={{ fontSize: "0.8rem" }}>
            {`PB - ${data._id}`}
          </h6>
          {/* Date */}
          <div>
            <h6
              className="font-weight-bold mr-2"
              style={{ fontSize: "0.6rem" }}
            >
              {`${window.I18N('created_date')}: ${window.COMMON_DATE.getFormatDate(data.createdDate, 'DD/MM/YYYY - HH:mm:ss')}`}
            </h6>
          </div>
        </MDBCol>
        {/* Company */}
        <MDBCol size="4">
          <div className="flex-center justify-content-start h-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <circle cx="7" cy="7" r="7" fill="#D9D9D9" />
            </svg>
            <span className="ml-2">{data.receiver.name}</span>
          </div>
          <div className="mt-2">
            <label className={`font-weight-bold label-box ${BILL_STATUS[data.status].label} p-1 m-0`}>
              {BILL_STATUS[data.status].name}
            </label>
          </div>
          <div className="mt-2">
            <h6
              className="font-weight-bold mr-2"
              style={{ fontSize: "0.6rem" }}
            >
              {`${window.I18N('created_date')}: ${window.COMMON_DATE.getFormatDate(data.createdDate)}`}
            </h6>
          </div>
        </MDBCol>
        {/* Product package */}
        <MDBCol>
          <div className="flex-center justify-content-start h-auto">
            <span>{data.packages[0].name}</span>
          </div>
          <div className="mt-2">
            <label className="font-weight-bold label-box label-primary p-1 m-0">
              {`${data.packages[0].subscriptionValue} ${data.packages[0].subscriptionType}`}
            </label>
          </div>
          <div className="mt-2">
            <h6
              className="font-weight-bold mr-2"
              style={{ fontSize: "0.6rem" }}
            >
              {`${window.I18N('start_date')}: ${window.COMMON_DATE.getFormatDate(data.packages[0].from)}`}
            </h6>
          </div>
          <div className="mt-2">
            <h6
              className="font-weight-bold mr-2"
              style={{ fontSize: "0.6rem" }}
            >
              {`${window.I18N('expired_date')}: ${window.COMMON_DATE.getFormatDate(data.packages[0].to)}`}
            </h6>
          </div>
        </MDBCol>
        {/* Action */}
        <MDBCol className="text-right">
          <div className="dropdown">
            <NavLink to="#" className="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" data-boundary="viewport">
              <MDBIcon fa="true" icon="ellipsis-v" className="fa-fw black-text"></MDBIcon>
            </NavLink>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" data-boundary="viewport">
              {/* View Bill */}
              <li 
                className={'dropdown-item'} 
                onClick={handleViewDetailBill}
              >
                <MDBIcon fa="true" icon="eye" className="fa-fw"></MDBIcon> {window.I18N('view_bill')}
              </li>
              {/* Edit Bill */}
              <li 
                className={`dropdown-item ${![window.CONSTANT.SUPER_ADMIN, window.CONSTANT.GOPY_INFLUENCER].includes(ACCOUNT_TYPE) || ![window.CONSTANT.BILL_PAYMENT_STATUS.PENDING].includes(data.status) ? 'disabled' : ''}`} 
                onClick={handleEditBill}
              >
                <MDBIcon fa="true" icon="edit" className="fa-fw"></MDBIcon> {window.I18N('btn_edit')}   
              </li>
              {/* Payment */}
              <li 
                className={`dropdown-item blue-text ${[window.CONSTANT.SUPER_ADMIN, window.CONSTANT.GOPY_INFLUENCER].includes(ACCOUNT_TYPE) || [window.CONSTANT.BILL_PAYMENT_STATUS.PAID, window.CONSTANT.BILL_PAYMENT_STATUS.DENIED].includes(data.status) ? 'disabled' : ''}`} 
                onClick={handleShowPopUpConfirmPayment}
              >
                <MDBIcon fa="true" icon="credit-card" className="fa-fw"></MDBIcon> {window.I18N('payment')}   
              </li>
              <li className="dropdown-divider"></li>
              {/* Delete Bill */}
              <li 
                className={`dropdown-item red-text ${![window.CONSTANT.SUPER_ADMIN, window.CONSTANT.GOPY_INFLUENCER].includes(ACCOUNT_TYPE) ? 'disabled' : ''}`} 
                onClick={handleConfirmDeleteBill}
              >
                <MDBIcon fa="true" icon="trash-alt" className="fa-fw"></MDBIcon> {window.I18N('btn_delete')}
              </li>
            </ul>
          </div>
        </MDBCol>
      </MDBRow>
      <Modal
        id={`modal-detail-bill-${data._id}`}
        className='modal-xl'
        title={`PB-${data._id}`}
        hideFooter
        handleCloseModal={handleCloseDetailBill} 
      >
        {isOpenDetailBill && 
            <BillItemDetail 
              dataBill={data} 
              status={BILL_STATUS}
              calcAmount={amountForDetail} 
              refetchDataBills={refetchDataBills} 
              onPayBill={handleShowPopUpConfirmPayment}
            />
        }
      </Modal>
    </div>
  )
}

const mapStateToProps = state => ({
  language: state.language.value
})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BillItem)
