import React, { useEffect, useMemo, useState } from "react";
import { MDBBtn, MDBCol, MDBIcon, MDBRow } from "mdbreact";
import { connect } from "react-redux";
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Table from "../../../components/Table";
import AccountGRAPHQL from "../../../../graphql/Account";
import Pagination from "../../../components/Pagination";
import Modal from "../../../components/Modal";
import PaymentDetail from "../components/PaymentDetail";

const DEFAULT_PAGE = 1

const TransactionHistory = ({ language }) => {
    const TRANSACTION_STATUS = useMemo(()=>(
        {
            0 : window.I18N('payment_successful'),
            1 : window.I18N('payment_failed'),
            2 : window.I18N('payment_pending'),
            3 : window.I18N('payment_processing')
        }
    ),[language.code]) 
    
    const STATUS_FILTERS = useMemo(()=>{
        const allStatus = {
            value: null,
            label: window.I18N('all_payment_status')
        }
        const filters = Object.entries(TRANSACTION_STATUS).map((item) => {
            const [statusCode, statusName] = item
            return {
                value: statusCode,
                label: statusName
            }
        })
        return [allStatus, ...filters]
    },[language.code])
     
    const configTable = useMemo(()=>(
        [
            window.COMMON.createCol(window.I18N('orders_code'), `20%`,'py-2','',`order._id`, 'TEXT'),
            window.COMMON.createCol(window.I18N('type_payment'), `10%`,'py-2','',`order.type`, 'TEXT'),
            window.COMMON.createCol('Gopies', `10%`,'py-2','',`order.coin`, 'TEXT'),
            window.COMMON.createCol(window.I18N('date_of_payment'), `20%`,'py-2','',`updatedDate`, 'TEXT'),
            window.COMMON.createCol(window.I18N('amount'), `15%`,'py-2','',`amount`, 'TEXT'),
            window.COMMON.createCol(window.I18N('status'), `12%`,'py-2','',`status`, 'STATUS_PAYMENT'),
            window.COMMON.createCol(window.I18N('payer'), `20%`,'py-2','',`user.avatar,user.name`, 'IMAGE_TEXT'),
            window.COMMON.createCol(window.I18N('action'), '', '', '', '0', 'BUTTON')
        ]
    ),[language.code]) 

    const [transactionsHistory, setTransactionsHistory] = useState([])
    const [dataDetailTransaction, setDataDetailTransaction] = useState({})
    const [pagination, setPagination] = useState({total: 1, pageSize: 7, currentPage: 0})
    const [date, setDate] = useState({
        startDate: window.COMMON_DATE.addDaysFromNow(-90),
        endDate: new Date()
    });
    const [selectStatusPayment, setSelectStatusPayment] = useState(STATUS_FILTERS[0])

    useEffect(() => {
        const timeOutId = setTimeout(()=>{
            getTransactions()
        },100) 
        return () => clearTimeout(timeOutId)
    },[])

    const getTransactions = async (passParams = {}) => {
        window.resetDataTable(`table-transactions`)
        try {
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_URL_PAYMENT_API}/transactions`,
                method: 'GET',
                input: {
                    params: {
                        page: DEFAULT_PAGE,
                        pageSize: pagination.pageSize,
                        sort: 'desc',
                        ...passParams
                    }
                },
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('TransactionsHistory.getTransactions');
                }
            })
            const listIdUsers = result.elements.map(element => element.user && element.user._id).filter(item => item)
            const params = {
                ids: [...new Set(listIdUsers)] 
            }
            const usersInfo = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_BY_IDS, params)

            setTransactionsHistory({
                ...result, 
                elements: result.elements.map((element) => ({
                    ...element,
                    order: {
                        ...element.order,
                        coin: element.order.coin ? window.COMMON.formatCurrency(element.order.coin, { currency: 'VND' }) : '-',
                        type: element.order.coin ? window.I18N('coin') : window.I18N('bill')
                    },
                    amount: window.COMMON.formatCurrency(element.amount), 
                    updatedDate: window.COMMON_DATE.getFormatDate(element.updatedDate, 'DD/MM/YYYY - HH:mm:ss'),
                    user: element.user && usersInfo.data.findAccountByIds.find(user => user._id === element.user._id)
                }))
            })   
            setPagination((prev) => ({...prev, total: result.total}))
        } catch (error) {
            window.COMMON.showErrorLogs('TransactionsHistory.getTransactions');
        }
        window.initDataTable(`table-transactions`,false) 
    }

    const handleGetDetailTransaction = (index) => {
        const data = transactionsHistory.elements[index]
        setDataDetailTransaction(data)
        window.COMMON.showModal("#modal-payment-detail")
    }

 
    const handleFilterTransactions = (page = {}) => {
        const filters = {
            from: window.COMMON_DATE.getStrDateFilter(date.startDate, window.CONSTANT.FROM_FILTER_DATE), 
            to: window.COMMON_DATE.getStrDateFilter(date.endDate, window.CONSTANT.TO_FILTER_DATE), 
            status: selectStatusPayment.value,
            ...page
        }
        getTransactions(filters)
        setPagination((prev) => ({...prev, currentPage: 0}))
    }

    const handleChangePage = (index) => {
        if(index === pagination.currentPage){
            return
        }
        const page = index + 1
        handleFilterTransactions({ page })
        setPagination((prev) => ({...prev, currentPage: index}))
    }

    const handleDatePicker = (event, attr) => {
        date[attr] = event
        setDate({ ...date })
    }

    const handleSelectStatusPayment = (value) => {
        setSelectStatusPayment(value)
    }

    const handleHideModal = () => {
        window.COMMON.hideModal("#modal-payment-detail")
    }
    
    return ( 
        <>
            <div className="w-full wow fadeInRight animated" data-wow-delay="0.2s">
                <div className="p-3 bg-white rounded-lg">
                            <MDBRow className="flex-center mb-2 py-2">
                                <MDBCol>
                                    <h6 className="font-weight-bold" style={{fontSize: '0.8rem'}}>{window.I18N('status')}</h6>
                                    <Select 
                                        id="select-status" 
                                        value={selectStatusPayment} 
                                        options={STATUS_FILTERS} 
                                        className="md-form m-0" 
                                        placeholder={window.I18N('choose_option')} 
                                        onChange={(event) => handleSelectStatusPayment(event)}  
                                    />
                                </MDBCol>
                                <MDBCol>
                                    <h6 className="font-weight-bold" style={{fontSize: '0.8rem'}}>{window.I18N('from_date')}</h6>
                                    <div className="input-group input-group-transparent">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                                            </span>
                                        </div>
                                        <DatePicker 
                                            placeholderText={window.I18N('start_date') + ' *'} 
                                            className="form-control" 
                                            dateFormat="dd/MM/yyyy" 
                                            selected={date.startDate} 
                                            onChange={(event) => handleDatePicker(event, 'startDate')}
                                            maxDate={new Date(date.endDate)}
                                            showYearDropdown 
                                            showMonthDropdown 
                                            required                                      
                                        />              
                                    </div>
                                </MDBCol>
                                <MDBCol>
                                    <h6 className="font-weight-bold" style={{fontSize: '0.8rem'}}>{window.I18N('to_date')}</h6>
                                    <div className="input-group input-group-transparent">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                                            </span>
                                        </div>
                                        <DatePicker 
                                            placeholderText={window.I18N('end_date') + ' *'} 
                                            className="form-control" 
                                            dateFormat="dd/MM/yyyy" 
                                            selected={date.endDate} 
                                            onChange={(event) => handleDatePicker(event, 'endDate')}
                                            maxDate={new Date()}
                                            showYearDropdown 
                                            showMonthDropdown 
                                            required
                                        />
                                    </div>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="flex-center justify-content-between">
                                <MDBCol sm="12 text-right">
                                    <MDBBtn 
                                        color="primary" 
                                        className="m-0" 
                                        onClick={() => handleFilterTransactions()}
                                    >
                                        <MDBIcon fa="true" icon="search" className="fa-fw" /> {window.I18N('search')}
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                </div>
                <div className="mt-2 p-3 bg-white rounded-lg">
                    <Table 
                        id={`table-transactions}`}
                        className="table"
                        config={configTable}
                        data={transactionsHistory.elements}
                        handleRowDetailClick={handleGetDetailTransaction}             
                    />
                    <Pagination 
                        total={pagination.total} 
                        pageSize={pagination.pageSize} 
                        currentPage={pagination.currentPage} 
                        changePage={handleChangePage} 
                    />
                </div>
            </div>
            <Modal
                id="modal-payment-detail"
                title={window.I18N('payment_detail')}
                handleCloseModal={handleHideModal}
                hideFooter
            >
                <PaymentDetail data={dataDetailTransaction}/>
            </Modal>
        </>
     );
}

const mapStateToProps = state => ({
    language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionHistory);