import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBCol, MDBRow, MDBIcon, MDBInput } from 'mdbreact';
import AttributeView from './AttributeView';
import Common from '../../utils/Common';
import {conclusionSections, evaluationList } from './ItemPreviewAdminSelfAssessment';

import QuestionGRAPHQL from '../../graphql/Question';
import SelfAssessmentGRAPHQL from '../../graphql/SelfAssessment';
import CaseAssessmentGRAPHQL from '../../graphql/CaseAssessment';

const ConclusionPdfForm = React.memo(function ExportPDF(props) {

  const { currentReport, taskShareByCompany, dataShare, ...previewAssessmentProps } = props;
  const { name, createdDate, endDate } = previewAssessmentProps.selfAssessment || {};
  const { company } = currentReport || {};

  const [language, setLanguage] = useState();
  const [object, setObject] = useState({ company: null });
  const [dataWorker, setDataWorker] = useState([]);
  const [dataCompany, setDataCompany] = useState([]);
  const [dataAdvisor, setDataAdvisor] = useState([]);
  const [dataAnswer, setDataAnswer] = useState([]);
  const [dataConclusion, setDataConclusion] = useState(props.dataConclusion);
  const [questionsByGroup, setQuestionsByGroup] = useState([]);

  const convertToDate = (dateString) => {
  	//  Convert a "dd/MM/yyyy" string into a Date object
  	const d = dateString.split('/');
  	const result = new Date(d[2] + '/' + d[1] + '/' + d[0]);
  	return result;     
  };

  const getWorkerFeedback = async (selfAssessment, companyId) => {
    try {
	  const res = [];
      await Promise.all(selfAssessment.groups?.map(async (item) => {
        const params = {
          input: {
            group: item._id,
            companyIds: [companyId],
            startDate: window.COMMON_DATE.getStrDateFilter(convertToDate(selfAssessment.startDate), -1),
            endDate: window.COMMON_DATE.getStrDateFilter(convertToDate(selfAssessment.endDate), 1)
          }
        };
        const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_EXPORT_HISTORY_ANSWER, params);
        if (result.data?.getHistoryAnswer?.data) {
          result.data.getHistoryAnswer.data.map(d => {
            return res.push({			
              group: d.group,
              feedback: d.feedback
            });
		  });
        }
      }));
      setDataWorker(res);
    } catch (error) {
      window.COMMON.showErrorLogs('ItemPreviewAdminSelfAssessment.getDataFeedback');
    }
  };

  const getReportConclusion = async (companyTaskId) => {
    if (companyTaskId) {
      const result = await Common.query(CaseAssessmentGRAPHQL.QUERY_ASSESSMENT_CONCLUSION_BY_COMPANY_TASK_ID, { companyTask: companyTaskId });
      let newDataConclusion = {
        ...dataConclusion
      };
      if (result?.data?.getAssessmentConclusionByCompanyTaskId) {
        const caseConclusionData = result.data.getAssessmentConclusionByCompanyTaskId;
        newDataConclusion = {
          _id: caseConclusionData._id,
          overview: caseConclusionData.overview,
          stating: caseConclusionData.stating,
          policy: caseConclusionData.policy,
          content: caseConclusionData.content,
          handling: caseConclusionData.handling
        };
      } else {
        newDataConclusion = {
          _id: '',
          overview: {
            evaluation: '',
            comment: ''
          },
          stating: {
            evaluation: '',
            comment: ''
          },
          policy: {
            evaluation: '',
            comment: ''
          },
          content: {
            evaluation: '',
            comment: ''
          },
          handling: {
            evaluation: '',
            comment: ''
          }
        };
      }

      setDataConclusion(newDataConclusion);

      if (typeof props.handleChangeConclusion === 'function') {
        props.handleChangeConclusion(newDataConclusion);
      }
    } 
  };

  const getDataFeedback = async (id) => {
    try {
      const params = {
        id
      };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_FIND_TASK_SELF_ASSESSMENT_BY_ID, params);
      if (result && result.data && result.data.findTaskSelfAssessment) {
        const obj = result.data.findTaskSelfAssessment;
        await getDataAnswers(obj.items || []);
        object.company = window.COMMON.getValueFromAttr(obj, 'company', null);
        setObject(object);
        if (props.selfAssessment) {
          await getWorkerFeedback(props.selfAssessment, object.company?._id);
        }
       
      }
      await getReportConclusion(id);
    } catch (error) {
      window.COMMON.showErrorLogs('ItemPreviewAdminSelfAssessment.getDataFeedback');
    }
  };

  const getDataFeedbackCompare = async (id) => {
    try {
      const params = {
        id
      };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_FIND_TASK_SELF_ASSESSMENT_BY_ID, params);
      if (result && result.data && result.data.findTaskSelfAssessment) {
        const obj = result.data.findTaskSelfAssessment;
        setDataAdvisor(obj.items || []);
        if (props.handleChange) {
          props.handleChange('advisor', obj.items ?? []);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('ItemPreviewAdminSelfAssessment.getDataFeedbackCompare');
    }
  };

  const getDataAnswers = async (data) => {
    try {
      const groupIds = [];
      data.forEach(item => {
        groupIds.push(item.group._id);
      });
      const params = {
        groupIds
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_ANSWER_QUESTION_BY_GROUP, params);
      if (result && result.data && result.data.getAnswerQuestionsByGroup) {
        setDataAnswer(result.data.getAnswerQuestionsByGroup);
        setDataCompany(data);
        if (props.handleChange) {
          props.handleChange('company', data);
        }
      }

      const queryQuestionResponse = await window.COMMON.query(QuestionGRAPHQL.QUERY_QUESTION_BY_GROUP, params);
      if (queryQuestionResponse && queryQuestionResponse.data && queryQuestionResponse.data.getQuestionsByGroup) {
        const groupQuestion = queryQuestionResponse.data.getQuestionsByGroup.reduce((acc, curr) => {
          const key = curr.group;
          if (acc[key]) {
            acc[key].questions.push(curr.question);
          } else {
            acc[key] = {
              group: {_id: key},
              questions: [curr.question]
            };
          }
          return acc;
        }, {});
        setQuestionsByGroup(Object.values(groupQuestion));
      }
    } catch (error) {
      window.COMMON.showErrorLogs('ItemPreviewAdminSelfAssessment.getDataFeedback');
    }
  };

  const mapGroupIdToTaskShare = (shareList) => {
    const result = [];
    if (shareList?.length) {
      shareList.forEach(i => {
        const feedbackCount = i.feedback?.length;
        const matchGroup = questionsByGroup.find(i => i?.questions?.length === feedbackCount);
        if (matchGroup) {
          i.group = matchGroup.group;
          result.push(i);
        }
      });
    }
    return result;
  };


  const getAnswerQuantity = (data, groupId, questionId, answerId, influencerId) => {
    if (data.length > 0) {
      let count = 0;
      data.filter(item => item.group?._id === groupId && influencerId ? item.influencer._id === influencerId : true).map(d => {
        //find by question id and answer id
        const result = d?.feedback?.find(f => f.question?._id === questionId && f.answers?.findIndex(a => a._id === answerId) > -1 );
        if (result) count += 1;
      });
	  return count;
    };
    return 0;
  };

  const getAnswerText = (feedbacks, questionId) => {
    if (feedbacks && Array.isArray(feedbacks)) {
	  const feedback = feedbacks.find(item => item.question?._id === questionId);
	  if (feedback) return feedback.answerText; 
    }
    return '';
  }; 

  const shareItem = dataShare.reduce((acc, curr) => [...acc, ...curr?.influencers || []], []);
  const generateAnswer = (item, question, groupId) => {
    const answers = JSON.parse(JSON.stringify(window.COMMON.getArrsInArrs(question._id, dataAnswer, 'question')));
    const remainingHeadWidth = 100 - 50 - shareItem.length * 12.5;
    const answerTextHeadWidth = 100 / ( 3 + shareItem.length);
    const dataAssessor = mapGroupIdToTaskShare(taskShareByCompany);
    if (item) {
      answers.forEach(answer => {
        const obj = window.COMMON.getObjectInArrs(answer._id, item.answers, '_id');
        if (obj) {
          answer.selected = true;
        } else {
          answer.selected = false;
        }
      });
    }
    const type = question.type;
    if (type === 'TEXT') {
    //   return <p>{window.COMMON.getValueFromAttr(item, 'answerText')}</p>;
      return <table className="table-view">
        <thead>
          <tr>
            <th width={`${answerTextHeadWidth}%`} >Người lao động</th>
            <th width={`${answerTextHeadWidth}%`} >Công ty</th>
            <th width={`${answerTextHeadWidth}%`} >Tư vấn viên</th>
            {shareItem.map(i => (
              <th key={`text_answer_${i.name}`} width={`${answerTextHeadWidth}%`} >{i.name}</th>
            ))}
          </tr>
        </thead>
        <tbody style={{fontSize:"11px"}}>
          <tr>
            <td className='grey-ic text-justify'>
              {dataWorker.filter(item => item.group?._id === groupId).map((d, dIndex) => (
                <li key={dIndex}>{getAnswerText(d.feedback, question._id)}</li>
              ))}
            </td>
            <td className='grey-ic text-justify'>
              {dataCompany.filter(item => item.group?._id === groupId).map((d, dIndex) => (
                <li key={dIndex}>{getAnswerText(d.feedback, question._id)}</li>
              ))}
            </td>
            <td className='grey-ic text-justify'>
              {dataAdvisor.filter(item => item.group?._id === groupId).map((d, dIndex) => (
                <li key={dIndex}>{getAnswerText(d.feedback, question._id)}</li>
              ))}
            </td>
            {shareItem.map((i, idx) => (
              <td key={`assessor_${item._id}_${idx}`} className="grey-ic text-justify">
                {dataAssessor.filter(item => item.group?._id === groupId).map((d, dIndex) => (
                  <li key={dIndex}>{getAnswerText(d.feedback, question._id)}</li>
                ))}
              </td>
            ))}
          </tr>
        </tbody>
      </table>;
    } else {
      return (
        <table className="table-view">
          <thead>
            <tr>
              <th width={`${remainingHeadWidth}%`}>Câu trả lời</th>
              <th width="12.5%" className="text-center">Người lao động</th>
              <th width="12.5%" className="text-center">Công ty</th>
              <th width="12.5%" className="text-center">Tư vấn viên</th>
              {shareItem.map(i => (
                <th key={i.name} width="12.5%" className="text-center">{i.name}</th>
              ))}
            </tr>
          </thead>
		  {(type === 'CHECKBOX_GRID' || type === 'RADIO_GRID') ? (
            <tbody>
              {
                answers.filter(item => window.COMMON.getValueFromAttr(item, 'parentCode', null) === null).map((item, i) => 
                  <React.Fragment key={item._id}>
                    <tr className="parent">
                      <td>{window.COMMON.getValueWithLanguage(item, 'content', language)}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {shareItem?.length?<td></td>:null}
                    </tr>
				  {
                      window.COMMON.getArrsInArrs(item.code, answers, 'parentCode').map((child, i) => <tr key={child._id}>
                        <td className='pl-4'>{window.COMMON.getValueWithLanguage(child, 'content', language)}</td>
                        <td className="text-center">{getAnswerQuantity(dataWorker, groupId, question._id, child._id)}</td>
                        <td className="text-center">{getAnswerQuantity(dataCompany, groupId, question._id, child._id)}</td>
                        <td className="text-center">{getAnswerQuantity(dataAdvisor, groupId, question._id, child._id)}</td>
                        {shareItem.map((i, idx) => (
                          <td key={`assessor_${item._id}_${idx}`} className="text-center">{getAnswerQuantity(dataAssessor, groupId, question._id, child._id, i._id)}</td>
                        ))}
                      </tr>)
                    }
                  </React.Fragment>
                )
              }
            </tbody>
		  ) : (
            <tbody>
              {
                answers.map((item, i) => <tr key={item._id}>
                  <td>{window.COMMON.getValueWithLanguage(item, 'content', language)}</td>
                  <td className="text-center">{getAnswerQuantity(dataWorker, groupId, question._id, item._id)}</td>
                  <td className="text-center">{getAnswerQuantity(dataCompany, groupId, question._id, item._id)}</td>
                  <td className="text-center">{getAnswerQuantity(dataAdvisor, groupId, question._id, item._id)}</td>
                  {shareItem.map((i, idx) => (
                    <td key={`assessor_${item._id}_${idx}`} className="text-center">{getAnswerQuantity(dataAssessor, groupId, question._id, item._id, i._id)}</td>
                  ))}
                </tr>)
              } 
            </tbody>
		  )}
	  </table>
	  );
    }
  };

  const generateAnswerLayout = (child) => {
    return <>
      <p className="mb-1 mt-1 text-justify"><small className="text-muted ">* Ghi chú: <span dangerouslySetInnerHTML={{ __html: window.COMMON.getValueFromAttr(child, 'note', '').replace(/\n/g, '<br>') }} ></span></small></p>
      {
        window.COMMON.getValueFromAttr(child, 'documents', []).map((item) => <p key={item.id} className="mb-1 mt-1" style={{ cursor: 'pointer', textDecoration: 'underline' }} onDoubleClick={() => window.COMMON.downloadFile(item.id, item.name)}>
          <MDBIcon fa="true" icon="paperclip" className="fa-fw mr-1"></MDBIcon>
          <small className="text-primary">{item.name}</small>
        </p>)
      }
	  { 
        props.isInfluencer ? <></> : <>
          <p className="mb-1"><small className="text-muted">* Đánh giá của tư vấn viên: <span className="text-danger" dangerouslySetInnerHTML={{__html: window.COMMON.getValueFromAttr(child, 'consultantEvaluation', '').replace(/\n/g, '<br>')}}></span></small></p>
          <p className="mb-3"><small className="text-muted">* Ghi chú/Bình luận: <i className="text-danger" dangerouslySetInnerHTML={{__html: window.COMMON.getValueFromAttr(child, 'consultantNote', '').replace(/\n/g, '<br>')}}></i></small></p>
        </>
      }
    </>;
  };

  useEffect(() => {
    setLanguage(props.language);
    
    if (props.companyTaskId) {
      getDataFeedback(props.companyTaskId);
    } else {
      setDataCompany([]);
    }

    if (props.advisorTaskId) {
      getDataFeedbackCompare(props.advisorTaskId);
    } else {
      setDataAdvisor([]);
    }
    
    // eslint-disable-next-line
  }, [props.language, props.selfAssessment, props.companyTaskId, props.advisorTaskId, props.type]);

  useEffect(() => {
    setDataConclusion(props.dataConclusion);
  }, [props.dataConclusion]);

  return previewAssessmentProps?.selfAssessment && company ?
    <>
      <MDBContainer >
        <MDBRow className="d-flex">
          <MDBCol start className="text-center font-weight-bold mr-1 text-uppercase">Bộ (Sở) ........................... </MDBCol>
          <MDBCol end className="text-center font-weight-bold mr-1">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</MDBCol>
        </MDBRow>
        <MDBRow className="d-flex">
          <MDBCol start className="text-center font-weight-bold mr-1">----------------</MDBCol>
          <MDBCol end className="text-center font-weight-bold mr-1">ĐỘC LẬP - TỰ DO - HẠNH PHÚC</MDBCol>
        </MDBRow>
        <MDBRow className="d-flex">
          <MDBCol start className="text-center font-weight-bold mr-1">Sô ......... /KL--...............</MDBCol>
          <MDBCol end className="text-center font-weight-bold mr-1">----------------</MDBCol>
        </MDBRow>
        <MDBRow className="d-flex">
          <MDBCol ></MDBCol>
          <MDBCol end className="text-center font-weight-bold mr-1">.....,ngày.....tháng.....năm.....</MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBContainer >
        <MDBRow>
          <MDBCol className="" ></MDBCol>
        </MDBRow>
      </MDBContainer>
      <br/>
      <div className='Container'>
        <div className='row'>
          <div className='col text-center'>
            <h2 className='font-weight-bold'>KẾT QUẢ THANH TRA</h2>
            <h3 className='font-weight-bold'>Về việc {name}</h3>
          </div>
        </div>
      </div>
      <br />
      <MDBContainer>
        <MDBRow center>
          <MDBCol size='10'>
            <p>Thực hiện Quyết định thanh tra số ......... /QĐ- ......... ngày....../....../...... của..... về thanh tra công 
            tác {name}, từ ngày {createdDate} đến ngày ngày {endDate} Đoàn thanh tra đã tiến hành thanh tra tại {company?.information?.name} {' '}
            Xét báo cáo kết quả thanh tra ................................ ngày ....../....../...... của Trưởng đoàn thanh 
            tra, ý kiến giải trình của cơ quan, tổ chức, cá nhân là đối tượng thanh tra,</p>
            <br />
            <p>................................ Kết luận như sau: </p>

            {/* Report content */}
            <AttributeView data={previewAssessmentProps.dataAttributeReport} company={company} dataCompany={previewAssessmentProps.dataCompany} isView={true}/>
            <hr className="horizontal light"></hr>
            <MDBRow className="mt-3">
              <MDBCol>
                {
                  dataCompany?.map((item, i) => <div key={i} className="container-preview-group-self-assessment p-3">
                    <p className="font-weight-bold h6">{window.COMMON.getValueWithLanguage(item, 'group.name', language)}</p>
                    {
                      window.COMMON.getValueFromAttr(item, 'feedback', []).map((child, j) => <div className="item" key={child.question._id}>
                        <p className="mb-2 font-weight-bold alert alert-info">{(j + 1) + '. ' + window.COMMON.getValueWithLanguage(child, 'question.question', language)}</p>
                        { generateAnswer(child, child.question, item.group?._id) }
                        <MDBRow className='my-3 w-100 mx-0'>
                          {child.question.type==='TEXT'?<MDBCol><hr className="vertical light"></hr> </MDBCol>:null}
                          <MDBCol>
                            {child.question.type!=='TEXT'?<div className='font-weight-bold'>Công ty</div>:null}  
                            { generateAnswerLayout(child, child.question, i, j, props.type === 0) }
                            <hr className="vertical light"></hr>
                          </MDBCol>
                          <MDBCol>
                            {child.question.type!=='TEXT'?<div className='font-weight-bold'>Tư vấn viên</div>:null}
                            {generateAnswerLayout(window.COMMON.getValueFromAttr(dataAdvisor, i + '.feedback.' + j, null), child.question, i, j, props.type === 1)}
                            <hr className="vertical light"></hr>
                          </MDBCol>
                          {shareItem?.map((i, idx) => (
                            <MDBCol key={`assessor_note_${idx}`}>
                              {child.question.type!=='TEXT'?<div className='font-weight-bold'>{i.name}</div>:null}
                            { generateAnswerLayout(window.COMMON.getValueFromAttr(mapGroupIdToTaskShare(taskShareByCompany), idx + '.feedback.' + j, null), child.question, i, j, props.type === 1) }
                          </MDBCol>
                          ))}
                        </MDBRow>
                      </div>)
                    }
                  </div>)
                }
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-2">
              <MDBCol>
                <p className="font-weight-bold h6">IV. KẾT LUẬN THANH TRA</p>
                <div className='table-bordered mt-3'>
                  <div className='font-weight-bold ml-3 my-2'>Admin đánh giá</div>
                  <div className='ml-3'>
                    {evaluationList.map((evaluation, index) => (
                      <MDBInput 
                        gap
                        key={'evaluation-' + `${conclusionSections[0].id}-${index}`}
                        id={'evaluation-' + `${conclusionSections[0].id}-${index}`}
                        type="radio" 
                        containerClass="pl-0 pb-1 mr-4" 
                        label={window.I18N(evaluation)}
                        value={evaluation}
                        name={`consultantEvaluation-${conclusionSections[0].id}-${index}`}
                        onChange={() => {}}
                        default
                        checked={dataConclusion[conclusionSections[0].name]['evaluation'] === evaluation}
                      />
                    ))}
                  </div>
                </div>
                {conclusionSections.map((item, idx) => (
                  <div className="mt-2" key={item.id}>
                    <p className="font-weight-bold">{idx+1}. {window.I18N(item.i18n)}</p>
                    
                    <MDBInput 
                      outline
                      label='Xem và kết luận'
                      name={item.name}
                      type="textarea"
                      valueDefault={dataConclusion[item.name]['comment']}
                      value={dataConclusion[item.name]['comment']}
                      maxLength="5000"
                      onChange={() => {}}
                    />
                  </div>
                ))}
              </MDBCol>
            </MDBRow>
            {/* Report content */}

            <MDBContainer>
              <MDBRow className="d-flex" between>
                <MDBCol className="font-weight-bold mr-1">
                  <span>Nơi nhận:</span> <br/>
                    - Cơ quan cấp trên trực tiếp (nếu có); <br/>
                    - Đối tượng thanh tra; <br/>
                    - Thủ trưởng cơ quan quản lý nhà nước <br/>
                    cùng cấp; <br/>
                    - Cơ quan thanh tra nhà nước cấp trên; <br/>
                    - Lưu: ............  <br/>
                </MDBCol>
                <MDBCol className="text-center font-weight-bold mr-1" middle>
                  <p>(Người ra quyết định thanh tra) </p>
                  <p>(Kí tên, đóng dấu)</p>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
    : null;
});

export default ConclusionPdfForm;