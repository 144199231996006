import { MDBIcon } from 'mdbreact'
import React from 'react'

import styles from './EventSearch.module.css'

const EventSearch = () => {
    return (
        <div className={`${styles['search-container']}`}>
            <MDBIcon fas icon="search" />
            <input placeholder={window.I18N('search_event')} type="search" />
        </div>
    )
}

export default EventSearch
