import React, { useState, useEffect } from 'react';
import { MDBCol, MDBIcon, MDBBtn } from 'mdbreact';
import FormModal from '../../components/FormModal';
import CircleAvatar from '../../components/CircleAvatar';
import EmptyDataText from '../../components/EmptyDataText';
import { JoinRequestsDataStyled } from '../../../styles/styled-components/Chat/JoinRequestsFormModal';
import GroupChatGRAPHQL from '../../../graphql/GroupChat';
const JoinRequestsFormModal = ({ modalId, onClose, groupChatSelected, visible, onAcceptClick, onDeclineClick }) => {
  const CONFIRM_MODAL = 'modal-confirm';
  const [requests, setRequests] = useState([]);
  const [fetchRequestsLoading, setFetchRequestsLoading] = useState(false);
  useEffect(() => {
    if (visible !== true) return;
    fetchRequests();
    return () => {

    };
  }, [visible]);


  const fetchRequests = async () => {
    try {
      const groupId = groupChatSelected._id;
      if (fetchRequestsLoading === true || !groupId) return;
      setFetchRequestsLoading(true);
      const params = {
        groupId
      };
      const result = await window.COMMON.mutation(GroupChatGRAPHQL.QUERY_FIND_GROUP_RELATIONSHIP, params);
      let requestsData = [];
      if (result && result.data) {
        const rawRequests = result.data.findGroupRelationshipByGroup;
        const pendingRequests = rawRequests.filter((item) => item.status === window.CONSTANT.PENDING);
        requestsData = pendingRequests.map((item) => item.user);
      }
      setRequests(requestsData);
    }
    catch (error) {
      window.COMMON.showErrorLogs('ChatPage.fetchRequests');
    }
    setFetchRequestsLoading(false);
  };


  const handleClickCheckbox = (event, _id) => {
    setRequests((current) => {
      const index = current.findIndex((item) => item._id === _id);
      if (index >= 0) {
        current[index].isChecked = !current[index].isChecked;
      }
      return [...current];
    });
  };


  const getCheckedRequests = (requests) => {
    const checkedRequests = requests.filter((item) => item.isChecked === true);
    return checkedRequests;
  };

  const removeCheckedRequests = (checkedRequestesIds) => {
    setRequests((current) => {
      const newRequestes = current.filter((item) => !checkedRequestesIds.includes(item._id));
      return [...newRequestes];
    });
  };

  const handleActionClick = (type) => {
    window.COMMON.showModal(`#${CONFIRM_MODAL}`);
    window.confirmMethod = async () => {
      const checkedRequests = getCheckedRequests(requests);
      const checkedRequestsIds = checkedRequests.map((item) => item._id);

      if (type === 'ACCEPT') {
        const result = await onAcceptClick(groupChatSelected, checkedRequests, checkedRequestsIds);
        if (result === 1) {
          removeCheckedRequests(checkedRequestsIds);
          window.COMMON.showMessage('success', 'MSG_CODE_060', window.I18N('MSG_CODE_060'));
        }
      }
      else if (type === 'REJECT') {
        const result = await onDeclineClick( groupChatSelected, checkedRequestsIds);
        if (result === 1) {
          removeCheckedRequests(checkedRequestsIds);
          window.COMMON.showMessage('success', 'MSG_CODE_061', window.I18N('MSG_CODE_061'));
        }
      }
    };
  };




  return <FormModal id={modalId} title={window.I18N('join_requests')} hideSave={true} onClose={onClose}>
    <form id="form" className="needs-validation" noValidate>

      <MDBCol xs='12'>

        <JoinRequestsDataStyled>
          {(requests.length <= 0 && fetchRequestsLoading === false) && <div className="d-flex justify-content-center pt-3"><EmptyDataText /></div>}
          {requests.length > 0 && <div className="list">
            {requests.map((item, index) => {
              return <div className="item" key={`${index}-request-${item._id}`}>
                <CircleAvatar src={item.avatar} />
                <div className="content">{item.nickname}</div>
                <input type={'checkbox'} checked={item.isChecked} onChange={(event) => handleClickCheckbox(event, item._id)} />
              </div>;
            })}

          </div>}
        </JoinRequestsDataStyled>
        <div className="container-btn mt-5 d-flex justify-content-end">
          <MDBBtn color="danger" onClick={() => handleActionClick('REJECT')} disabled={getCheckedRequests(requests).length > 0 ? false : true}>
            <MDBIcon fa="true" icon="ban" className="fa-fw" /> {window.I18N('btn_reject')}
          </MDBBtn>
          <MDBBtn color="primary" onClick={() => handleActionClick('ACCEPT')} disabled={getCheckedRequests(requests).length > 0 ? false : true}>
            <MDBIcon fa="true" icon="check-circle" className="fa-fw" /> {window.I18N('btn_accept')}
          </MDBBtn>
        </div>



      </MDBCol>


    </form>
  </FormModal >;
};

export default JoinRequestsFormModal;