import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { Bar, Pie, HorizontalBar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import GrievanceGRAPHQL from '../../../graphql/Grievance';
import ReactSelect from 'react-select';
import { MDBIcon } from 'mdbreact';
import CustomChart from './components/CustomChart';

const paletteColor = ["rgba(0, 159, 109, 0.2)","rgba(190, 0, 0, 0.2)","rgba(61, 0, 189, 0.2)","rgba(0, 65, 175, 0.2)","rgba(54, 162, 235, 0.2)","rgba(153, 102, 255, 0.2)","rgba(201, 203, 207, 0.2)", "rgba(74, 147, 124, 0.2)", "rgba(147, 147, 124, 0.2)", "rgba(147, 147, 235, 0.2)", " rgba(147, 196, 235, 0.2)", "rgba(0, 171, 184, 0.2)", "rgba(139, 171, 184, 0.2)", "rgba(139, 255, 184, 0.2)", "rgba(165, 197, 97, 0.2)", "rgba(165, 163, 152, 0.2)", "rgba(0, 0, 0, 0.2)", "rgba(0, 47, 0, 0.2)", "rgba(26, 47, 43, 0.2)", "rgba(255, 38, 214, 0.2)"]

const countData = (data) => {
    const countObj = {}
    for(let i of data) {
        countObj[i] ? countObj[i]++ : countObj[i] = 1  
    }

    const keys = Object.keys(countObj)
    const values = Object.values(countObj)

    return {
        countObj,
        keys,
        values
    }
}

const InsightChartComplaint = ({ companyId, companies }) => {
    const dataMonthsSelect = [
        {
            label: window.I18N('last_3_month'),
            value: "92",
        },
        {
            label: window.I18N('last_6_month'),
            value: "183",
        }
    ]
    const [dataDaysOfMonth, setDataDaysMonth] = useState(dataMonthsSelect[0])
    const statusType = ["IN-PROGRESS", "OPEN", "CANCEL", "CLOSE"]
    const dataComplaint = useRef([])
    const [dataChart, setDataChart] = useState({ labels: [], datasets: [] })
    const [chartMode, setChartMode] = useState('IN-PROGRESS')

    const handleSelectMonths = (value) => {
        setDataDaysMonth(value)
    }

    const InnerHTMLPopup = (data) => {
        const filterDataInfluencer= data.map(item => item.influencer?.information?.name)
        const filterDataType = data.map(item => item.type.value)
        const { keys, values } = countData(filterDataType)
        const { keys: influencerName, values: countComplaints } = countData(filterDataInfluencer)
        const dataType = {
            datasets: [{
                data: values,
                backgroundColor: paletteColor.slice(3)
            }],
            labels: keys
        }
        return <div>
                    <div className='d-flex align-items-center justify-content-around'>
                        <Pie data={dataType} redraw={true} height={200} width={300} options={{
                                legend: {
                                    position: 'bottom',
                                    labels: {
                                        boxWidth: 12,
                                        fontSize: 10
                                    }
                                },
                                title: {
                                    display: true,
                                    text: window.I18N('complaint_type'),
                                },
                                responsive: false,
                                maintainAspectRatio: false
                            }} 
                        />
                            
                    </div>
                   <div className='d-flex justify-content-between'>
                        <div className='w-full d-flex flex-column flex-1' style={{ maxHeight: "200px", minHeight: "100px", maxWidth: '130px' }}>
                            <div style={{ display: "flex", flexDirection: "column", marginRight: "10px" }}>
                                <div style={{ display: "flex", flexDirection: "column", backgroundColor: "#F1F2F8", marginBottom: "10px", padding: "5px 10px"}}>
                                    <div style={{margin: "auto", fontSize: "20px", fontWeight: 500}}>{data.length}</div>
                                    <div style={{ margin: "auto" }}>{window.I18N('complaint')}</div>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", marginRight: "10px" }}>
                                <div style={{ display: "flex", flexDirection: "column", backgroundColor: "#F1F2F8", marginBottom: "10px", padding: "5px 10px"}}>
                                    <div style={{margin: "auto", fontSize: "20px", fontWeight: 500}}>{influencerName.length}</div>
                                    <div style={{ margin: "auto", textAlign: 'center' }}>{window.I18N("influencer_complaint")}</div>
                                </div>
                            </div>
                        </div>
                        <div style={{ backgroundColor: "#F1F2F8", padding: "5px 15px", maxHeight: "200px", minHeight: "100px", width: "170px", overflow: "scroll", marginLeft: " 3px" }}>
                                <div className='mb-2' style={{ fontWeight: 500, textAlign: 'center' }}>{window.I18N('count_complaint')}</div>
                                {influencerName.map((data, i) =>
                                    <li key={i} style={{ fontSize: "11px", }}>{`${data}: ${countComplaints[i]}`}</li>
                                )}              
                        </div>
                   </div>
                </div>
    }

    const getDataComplaint = async (pastDays = '181') => {
        try {
            const startDate = window.COMMON_DATE.getDateFollowPastDate({ pastDays, mode: -1 });
            const params = {
                input: {
                    startDate,
                    endDate: window.COMMON_DATE.getStrDateFilter(new Date(), 1),
                }
            };
            if (companyId) {
                params.input.organizationIds = [companyId]
            } else {
                params.input.organizationIds = [...companies.companyIds, ...companies.influencerIds, ...companies.unionIds]
            }
            const result = await window.COMMON.query(
                GrievanceGRAPHQL.QUERY_COMPLAINT_DATA_INSIGHT, params
            );
            if (result?.data?.queryComplaintDataInsight) {
                const data = window.COMMON.formatDate(result.data.queryComplaintDataInsight, 'updatedDate', "MM/YYYY")
                dataComplaint.current = data
                groupDataforChart(data)
                //formatDatawithLanguage(data)
            }
        } catch (err) {
            console.log(err)
        }
    }
    const groupDataforChart = (paramData) => {
        let groupDataChart = window.COMMON.getDataChartInPastYear({
            data: paramData,
            numberMonths: Math.floor(+dataDaysOfMonth.value/30),
            types: statusType,
            itemKey: 'status',
            dateKey: 'updatedDate',
            formatDateLabel: "MM/YYYY"
        });
        dataChart.labels = groupDataChart.labels
        const indexDataset = statusType.indexOf(chartMode)
        dataChart.datasets = [{
            data: groupDataChart.data[indexDataset],
            backgroundColor: paletteColor,
            borderColor: paletteColor.map(color => color.replace('0.2', '1')),
            borderWidth: 1,
            barPercentage: 0.5,
        }]
        setDataChart({ ...dataChart })
    }

    const handleOnHover = (chartElHover, chartElements) => {
        const data = []
        dataComplaint.current.forEach(el => {
            if (el.updatedDate === dataChart.labels[chartElements[0]._index] && el.status === chartMode) {
                data.push(el)
            }
        })
        return data
    }

    
    useEffect(() => {
        groupDataforChart(dataComplaint.current)
    }, [chartMode])

    useLayoutEffect(() => {
        setTimeout(() => {
            if (companyId || companies.companyIds?.length || companies.influencerIds?.length || companies.unionIds?.length) {
                getDataComplaint(dataDaysOfMonth.value)
            }
        }, 0)
        // eslint-disable-next-line
    }, [companies, companyId, dataDaysOfMonth]);

 
    return (
        <div className='mt-4 mx-auto '>
            <div className='py-2 d-flex flex-row justify-content-between align-items-center'>
            {/* Title Chart */}
                <h6 className="m-0 font-weight-bold" style={{ fontSize: "1rem" }}>{`Complaint Chart Insight`}</h6> 
            {/* Filter Chart */}
                <div className='d-flex flex-row justify-content-end' style={{ gap: '16px', flex: 1 }}>
                    <div className='d-flex align-items-center justify-content-start' style={{width: '30%'}}>
                        <div className="input-group input-group-transparent">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <MDBIcon fa="true" icon="calendar" className="fa-fw" />
                                </span>
                            </div>
                            <ReactSelect
                                value={dataDaysOfMonth}
                                options={dataMonthsSelect}
                                className="md-form m-0"
                                placeholder={window.I18N("choose_option")}
                                onChange={handleSelectMonths}
                            />
                        </div>
                    </div>
                    <div className='d-flex flex-row'>
                        {/* Close Status */}
                        <button 
                            className={`mode-button ${chartMode === 'CLOSE' ? 'mode-active' : ''}`} 
                            onClick={() => { setChartMode("CLOSE") }}
                            style={{ borderRadius: '4px 0 0 4px' }}
                        >
                            {window.I18N('close')}
                        </button>
                        {/* Cancel Status */}
                        <button 
                            className={`mode-button ${chartMode === 'CANCEL' ? 'mode-active' : ''}`} 
                            onClick={() => { setChartMode("CANCEL") }}
                        >
                            {window.I18N('cancel')}
                        </button>
                        {/* Open Status */}
                        <button 
                            className={`mode-button ${chartMode === 'OPEN' ? 'mode-active' : ''}`} 
                            onClick={() => { setChartMode("OPEN") }}
                        >
                            {window.I18N('open')}
                        </button>
                        {/* In-Progress Status */}
                        <button 
                            className={`mode-button ${chartMode === 'IN-PROGRESS' ? 'mode-active' : ''}`} 
                            onClick={() => { setChartMode("IN-PROGRESS") }}
                            style={{ borderRadius: '0 4px 4px 0' }}
                        >
                            {window.I18N('in-progress')}
                        </button>
                    </div>
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-center' style={{ position: 'relative' }}>
                <CustomChart dataChart={dataChart} createInnerHTMLPopup={InnerHTMLPopup} onDataHover={handleOnHover} /> 
            </div>
            <div className='chart-title'>{`${window.I18N('complaint-chart')} ${dataDaysOfMonth.label}`}</div>
        </div>
    )
}

const mapStateToProps = state => ({
    language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InsightChartComplaint);
