import {useEffect} from 'react';
import socket from '../utils/socket';
const useSocket = () => {
  useEffect(() => {
    socket.auth = {
      user:window.COMMON.getCurrentUserInformation()
    };
    socket.connect();
    socket.on('test',(data) => {
      console.log(data);
    });
    return () => {
      socket.disconnect();
      socket.off();
    };
  },[]);
};

export default useSocket;