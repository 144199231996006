import { Chip, Collapse, List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { Fragment, useCallback, useEffect, useState } from 'react';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import 'datatables.net-bs4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import { MDBBtn, MDBIcon } from 'mdbreact';

const useStyles = makeStyles((theme) => ({
  categoryTitle: {
    fontSize: '1.2rem',
    color: '#1976d2',
    fontWeight: 'bold'
  },
  question: {
    fontSize: '1rem',
    color: '#555',
    fontWeight: 'medium',
    width: '100%'
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  answers: {
    marginLeft: '30px',
    marginRight: '40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    rowGap: '5px'
  },
  answerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    columnGap: '20px'
  },
  answer: {
    color: '#777',
    margin: '0',
    fontSize: '0.8rem'
  },
  answerScore: {
    color: '#1976d2',
    fontWeight: 'bold',
    fontSize: '1.1rem'
  },
  item: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: '20px'
  },
  languages: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: '5px'
  }
}));

const ListQuestions = (props) => {
  const { questions, classes, language, onShowAnswer, categoryIndex, onDelete } = props;
  const getLanguages = (data) => {
    if (!data) return [];
    const languages = [];
    data.forEach((item) => {
      languages.push(item.code);
    });
    return languages;
  };
  return (
    <List component="div" disablePadding style={{ marginLeft: '15px' }}>
      {questions.map((item, index) => {
        return (
          <Fragment key={index}>
            <ListItem className={classes.listItem}>
              {/* question */}
              <div className={classes.item}>
                <ListItemText
                  primary={window.COMMON.getValueWithLanguage(item.question, 'question', language)}
                  className={classes.question}
                />
                <div className={classes.languages}>
                  {
                    getLanguages(item.question.question).map((language, j) => (
                      <Chip key={j} color="primary" size="small" label={language} />
                    ))
                  }
                </div>
              </div>
              {/* actions */}
              {item.answers.length > 0 && (
                <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color=""
                  onClick={() => onShowAnswer(categoryIndex, index)}
                >
                  {item.isExpanded ? <ExpandLess /> : <ExpandMore />}
                </MDBBtn>
              )}
              <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={() => onDelete(item.uuid)}>
                <MDBIcon fa="true" icon="trash-alt" className="fa-fw" />
              </MDBBtn>
            </ListItem>
            {/* answers */}
            <Collapse in={item.isExpanded}>
              <div className={classes.answers}>
                {item.answers.map((answer, answersIndex) => {
                  return (
                    <div className={classes.answerContainer} key={answersIndex}>
                      <p className={classes.answer}>{window.COMMON.getValueWithLanguage(answer, 'content', language)}</p>
                      <span className={classes.answerScore}>{answer.score}</span>
                    </div>
                  );
                })}
              </div>
            </Collapse>
          </Fragment>
        );
      })}
    </List>
  );
};

const TableCategoryQuestion = React.memo(function TableCategoryQuestion(props) {
  const { data, onDeleteCategoryGroup, language, onDeleteQuestion } = props;

  const classes = useStyles();
  const [questionsGroupByCategory, setQuestionsGroupByCategory] = useState([]);

  const handleShowQuestion = (index) => {
    const newQuestionsGroupByCategory = [...questionsGroupByCategory];
    newQuestionsGroupByCategory[index].isExpanded = !newQuestionsGroupByCategory[index].isExpanded;
    setQuestionsGroupByCategory(newQuestionsGroupByCategory);
  };
  const handleShowAnswer = useCallback((categoryIndex, questionIndex) => {
    const newQuestionsGroupByCategory = [...questionsGroupByCategory];
    newQuestionsGroupByCategory[categoryIndex].questions[questionIndex].isExpanded = !newQuestionsGroupByCategory[categoryIndex].questions[questionIndex].isExpanded;
    setQuestionsGroupByCategory(newQuestionsGroupByCategory);
  }, [questionsGroupByCategory]);

  // group data by category
  useEffect(() => {
    if (!data) return;
    const result = [];
    data.forEach((item) => {
      const findCategoryTitle = window.COMMON.getValueWithLanguage(item.question, 'category', language);
      const categoryIndex = result.findIndex(item => item.categoryTitle === findCategoryTitle);

      if (categoryIndex === -1) {
        result.push({
          categoryTitle: findCategoryTitle,
          questions: [{
            ...item,
            isExpanded: false
          }],
          isExpanded: false
        });
      } else {
        result[categoryIndex] = {
          ...result[categoryIndex],
          questions: [
            ...result[categoryIndex].questions,
            item
          ]
        };
      }
    });
    setQuestionsGroupByCategory(result);
  }, [data]);

  return (
    <div className={window.COMMON.getValue(props.className) + ' wow fadeIn animated'} data-wow-delay="0.2s" id={props.id}>
      <List>
        {
          questionsGroupByCategory && (
            questionsGroupByCategory.map((item, categoryIndex) => {
              const { categoryTitle, questions, isExpanded } = item;
              return (
                <Fragment key={categoryIndex}>
                  <ListItem className={classes.listItem} style={{ marginTop: '15px' }}>
                    {/* category title */}
                    <h3 className={classes.categoryTitle}>
                      {categoryTitle}
                    </h3>
                    {/* actions */}
                    <div>
                      <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color=""
                        onClick={() => handleShowQuestion(categoryIndex)}
                      >
                        {isExpanded ? <ExpandLess /> : <ExpandMore />}
                      </MDBBtn>
                      <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={() => onDeleteCategoryGroup(categoryTitle)}>
                        <MDBIcon fa="true" icon="trash-alt" className="fa-fw" />
                      </MDBBtn>
                    </div>
                  </ListItem>
                  {/* list question */}
                  <Collapse in={isExpanded}>
                    <ListQuestions
                      categoryIndex={categoryIndex}
                      questions={questions}
                      classes={classes}
                      language={language}
                      onShowAnswer={handleShowAnswer}
                      onDelete={onDeleteQuestion}
                    />
                  </Collapse>
                </Fragment>
              );
            })
          )
        }
      </List>
    </div>
  );
});

export default TableCategoryQuestion;