import React from "react";
import { MDBCol, MDBRow, MDBBtn } from "mdbreact";

const WAITING_SEND_DATA = null
const INIT_DATA_BILL = {
  receiver: {
    name: '',
    email: '',
    phone: ''
  },
  createdUser: {
    _id: '',
    name: '',
    nickname: '',
    email: '',
    phone: '',
    address: ''
  },
  packages: [
    {
      name: '',
      code: '',
      subscriptionType: '',
      from: '',
      to: ''
    }
  ],
  vat: '',
  amount: 0,
  discounts: [
    {
      name: '',
      value: '',
      percent: ''
    }
  ]
}

const SendBillStep = ({dataBill = INIT_DATA_BILL, onSendBill = () => {}}) => {

  
  const handleDiscountAmount = () => {
    if(dataBill?.discounts[0]){
      const { value, percent } = dataBill?.discounts[0]
      return (dataBill.amount * percent/100) || value
    }
    return 0
  }

  const handleVATAmount = (data) => {
    if(dataBill.vat){
      return (data * dataBill.vat/100)
    }
    return 0
  }

  const handleTotal = () => {
    if(dataBill.amount ){
      const dataAfterDiscount = dataBill.amount - handleDiscountAmount()
      return dataAfterDiscount + handleVATAmount(dataAfterDiscount) 
    }
    return 0
  }

  const handleConfirmSendBill = () => {
    window.COMMON.showModal("#modal-confirm")
    window.confirmMethod = handleSendBill
  }

  const handleSendBill = () => {
    onSendBill(WAITING_SEND_DATA, { isReadySendBill: true })
  }
  
  return (
    <div className="w-full bg-white rounded-lg p-5" style={{ fontSize: '0.9rem'}}>
      <MDBRow className="mb-3">
        <MDBCol>
          <p className="font-weight-bold" style={{ fontSize: '1rem'}}>{`${window.I18N('invoice')} ${window.COMMON.generateCode('#')}`}</p>
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <MDBCol>
          <p className="font-weight-bold">{window.I18N('issued_on')}</p>
          <p>{window.COMMON_DATE.getFormatDate(dataBill.packages[0].from)}</p>
        </MDBCol>
        <MDBCol>
          <p className="font-weight-bold">{window.I18N('due_on')}</p>
          <p>{window.COMMON_DATE.getFormatDate(dataBill.packages[0].to)}</p>
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <MDBCol>
          <p className="font-weight-bold">{window.I18N('from')}</p>
          <p>{dataBill.createdUser.nickname}</p>
          <p>{dataBill.createdUser.address}</p>
        </MDBCol>
        <MDBCol>
          <p className="font-weight-bold">{window.I18N('send_to')}</p>
          <p>{dataBill.receiver.name}</p>
          <p>{`${dataBill.receiver.address}, ${dataBill.receiver.province}, ${dataBill.receiver.district}, ${dataBill.receiver.country}`}</p>
        </MDBCol>
      </MDBRow>
      <MDBRow className="pb-3 border-bottom">
        <MDBCol>
          <p className="font-weight-bold">{window.I18N('package_info')}</p>
          <p>{`${dataBill.packages[0].name} - ${window.COMMON.formatCurrency(dataBill.amount)}`}</p>
          <label className={`font-weight-bold label-box label-primary p-1 m-0`}>{`${dataBill.packages[0].subscriptionValue} ${dataBill.packages[0].subscriptionType}`}</label>
        </MDBCol>
        <MDBCol></MDBCol>
      </MDBRow>
      <MDBRow className="py-3">
        <MDBCol>
          <p className="font-weight-bold" style={{ fontSize: '1rem'}}>{window.I18N('items')}</p>
        </MDBCol>
      </MDBRow>
      <div className="border rounded-lg p-3">
        <MDBRow
          className="rounded-lg p-2 m-2"
          style={{ backgroundColor: "#BBA2FF1F" }}
        >
          <MDBCol className="font-weight-bold">{window.I18N('description')}</MDBCol>
          <MDBCol className="font-weight-bold">{window.I18N('amount')}</MDBCol>
          <MDBCol className="font-weight-bold">{window.I18N('subscription_period')}</MDBCol>
          <MDBCol className="font-weight-bold">{window.I18N('quantity')}</MDBCol>
          <MDBCol className="font-weight-bold">{window.I18N('total_amount')}</MDBCol>
        </MDBRow>
        <MDBRow className="p-2 m-2">
          <MDBCol>{dataBill.packages[0].name}</MDBCol>
          <MDBCol>{window.COMMON.formatCurrency(dataBill.amount)}</MDBCol>
          <MDBCol>
            <label className={`font-weight-bold label-box label-primary p-1 m-0`}>{`${dataBill.packages[0].subscriptionValue} ${dataBill.packages[0].subscriptionType}`}</label>
          </MDBCol>
          <MDBCol>1</MDBCol>
          <MDBCol>{window.COMMON.formatCurrency(dataBill.amount)}</MDBCol>
        </MDBRow>
        {/* Discounts */}
        <MDBRow className="p-2 m-2">
          <MDBCol>{window.I18N('discount')}</MDBCol>
          <MDBCol>{dataBill.discounts.length > 0 ? dataBill.discounts[0].name : '-'}</MDBCol>
          <MDBCol>-</MDBCol>
          <MDBCol>-</MDBCol>
          <MDBCol>{window.COMMON.formatCurrency(handleDiscountAmount())}</MDBCol>
        </MDBRow>
          {/* VAT */}
        <MDBRow className="border-bottom p-2 m-2">
          <MDBCol>VAT</MDBCol>
          <MDBCol>{`${dataBill.vat}%`}</MDBCol>
          <MDBCol>-</MDBCol>
          <MDBCol>-</MDBCol>
          <MDBCol>{window.COMMON.formatCurrency(handleVATAmount(dataBill.amount - handleDiscountAmount()))}</MDBCol>
        </MDBRow>
        <MDBRow className="p-3">
          <MDBCol className="text-center font-weight-bold" style={{ fontSize: '1rem'}}>{window.I18N('total_amount')}</MDBCol>
          <MDBCol className="text-center font-weight-bold" style={{ fontSize: '1rem'}}>{window.COMMON.formatCurrency(handleTotal())}</MDBCol>
        </MDBRow>
      </div>
      <MDBRow className="flex-center mt-4">
          <div className="flex-center bd-highlight flex-1 h-auto">
            <MDBBtn color="primary" className="mr-0" onClick={handleConfirmSendBill}>
              {window.I18N('send_bill')}
            </MDBBtn>
          </div>
        </MDBRow>
    </div>
  );
};

export default SendBillStep;
