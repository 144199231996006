import { useEffect } from 'react'

//một hook bắt sự kiện khi click bên ngoài một element có gắn ref
const useClickOutSide = ({ ref, handleOutside }) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                event.stopPropagation()
                handleOutside()
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref])
}

export default useClickOutSide
