import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux"
import MetadataGRAPHQL from "../../../graphql/Metadata";
import { Grid, makeStyles } from "@material-ui/core"; 
import Modal from "../../components/Modal";
import PaymentConfirm from "./components/PaymentConfirm";

const useStyles = makeStyles(() => ({
	button: {
        borderRadius: '24px',
        border: '1px solid #8457FF',
        color: '#8457FF',
        transition: 'all 0.2s linear',
		'&:hover': {
			backgroundColor: '#8b62ff',
            color: '#FFF'
		}
	},
}));

const BuyGopiesPage = ({ language }) => {
    const classes = useStyles()
    const [dataConfigs, setDataConfigs] = useState({})
    const [dataGopiesCoin, setDataGopiesCoin] = useState({})
    const [openConfirm, setOpenConfirm] = useState(false)
    
    useEffect(()=>{
        const getConfigs = async () => {
            const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_CONFIG)
            const data = result.data.getConfigs
            let filterData = {}
            data.forEach((item) => {
                const { name, value } = item
                filterData[name] = value
            })
            setDataConfigs(filterData)
        }
        getConfigs()
    },[])


    const EARN_FREE_GOPIES = useMemo(()=>{
        const { 
            ADD_COMMENT_GOPY, 
            ADD_POST_GOPY, 
            LIKE_GOPY, 
            SHARE_POST_GOPY, 
            COMPLETE_SURVEY_GOPY
        } = dataConfigs
        return  [
            {
                title: window.I18N('create_post'),
                gopy: ADD_POST_GOPY
            },
            {
                title: window.I18N('like_post'),
                gopy: LIKE_GOPY
            },
            {
                title: window.I18N('comment_post'),
                gopy: ADD_COMMENT_GOPY
            },
            {
                title: window.I18N('shared_post'),
                gopy: SHARE_POST_GOPY
            },
            {
                title: window.I18N('finish_survey'),
                gopy: COMPLETE_SURVEY_GOPY
            },
        ]
    },[dataConfigs, language.code])
    
    const PACKAGE_GOPIES_COIN = useMemo(() => {
        const { EXCHANGE_RATE_GOPIES } = dataConfigs
        return [
                {
                    gCoins: 100,
                    price: 100 * EXCHANGE_RATE_GOPIES
                },
                {
                    gCoins: 200,
                    price: 200 * EXCHANGE_RATE_GOPIES
                },
                {
                    gCoins: 300,
                    price: 300 * EXCHANGE_RATE_GOPIES
                },
            ]
    },[dataConfigs])

    const handleGetPackageGopies = (packageGopies) => {
        setDataGopiesCoin(packageGopies)
        window.COMMON.showModal('#modal-payment')
        setOpenConfirm(true)
    }

    const handleHideModal = () => {
        window.COMMON.hideModal('#modal-payment')
        setOpenConfirm(false)
    }

    return ( 
            <>
                <div className="bg-white rounded-lg p-4 wow fadeInRight animated" data-wow-delay="0.2s">
                        <h6 className="font-weight-bold" style={{fontSize: '1.2rem'}}>{window.I18N('how_to_get_gopies')}</h6>
                        <div className="w-full flex-center flex-column">
                            <h6 className="font-weight-bold" style={{fontSize: '1rem'}}>{window.I18N('earn_free_gopies')}</h6>
                            <h6 className="font-weight-bold" style={{fontSize: '0.8rem', color: '#8b62ff'}}>{window.I18N('collect_gopies')}</h6>
                            <div className="p-5" style={{ width: '80%' }}>
                                {EARN_FREE_GOPIES.map((item, index) => (                      
                                            <Grid container className={`w-full p-2`} key={index}>
                                                    <Grid item xs={6} md={4} className="font-weight-bold">
                                                        {item.title}
                                                    </Grid>
                                                    <Grid item xs={3} md={4}>
                                                        <div className="flex-center w-full">
                                                            {Array.from({length: 3}, (_, i) => i).map((_, index) => <span key={index} className="flex mr-2">
                                                                <img height="20px" className="mr-2 pointer-events-none" src={window.CONSTANT.G_COIN} alt="G-Coin"/>
                                                            </span>)}
                                                        </div>                                                               
                                                    </Grid>
                                                    <Grid item xs={3} md={4} className="text-right font-weight-bold">
                                                        {`${item.gopy} ${item.gopy > 1 ? 'Gopies' : 'Gopy'}`}
                                                    </Grid>
                                            </Grid>
                                ))}  
                            </div>
                            {/* Purchase */}
                            <div className="p-5" style={{ width: '100%' }}>
                                <div className="flex-center flex-column">
                                    <h6 className="font-weight-bold" style={{fontSize: '1rem'}}>
                                        {window.I18N('purchase_gopies')}
                                    </h6>
                                    <h6 className="font-weight-bold" style={{fontSize: '0.8rem', color: '#8b62ff'}}>
                                        {window.I18N('buy_gopies_to_start')}
                                    </h6>
                                </div>
                                <div className="px-5 flex-center flex-column" style={{ width: '80%' }}>
                                        {
                                            PACKAGE_GOPIES_COIN.map((item, index) => (
                                                <Grid container className={`mt-3 flex-center`} key={index}>
                                                    <Grid item xs={6} md={6}>
                                                        <Grid container>
                                                            <Grid item xs={12} md={12} className="flex-center">
                                                                <img height="20px" className="mr-4 pointer-events-none" src={window.CONSTANT.G_COIN} alt="G-Coin"/>
                                                                <span className="select-none font-weight-bold">{`${item.gCoins} Gopies`}</span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={6} md={6} onClick={() => handleGetPackageGopies(item)}>
                                                        <Grid container className={`flex-center p-2 cursor-pointer ${classes.button}`}>
                                                            <Grid item xs={12} md={12} className="flex-center">
                                                                <span className="mr-5 select-none font-weight-bold">{`${window.COMMON.formatCurrency(item.price)}`}</span>                                   
                                                                <span className="select-none font-weight-bold">{window.I18N('buy_now')}</span>
                                                            </Grid>
                                                        </Grid> 
                                                    </Grid>
                                                </Grid>
                                            )) 
                                        } 
                                </div>              
                            </div>
                        </div>
                    </div>
                    <Modal
                        id="modal-payment"
                        title={window.I18N('confirm_payment')}
                        handleCloseModal={handleHideModal}
                        hideFooter
                    >
                        {openConfirm && 
                            <PaymentConfirm data={dataGopiesCoin} dataConfigs={dataConfigs}/>
                        }
                    </Modal>
            </>
)}

const mapStateToProps = state => ({
    language: state.language.value
  })
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BuyGopiesPage);