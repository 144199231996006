import gql from 'graphql-tag';

const QUERY_HELP_CENTER = gql`
  query($isActive: [Boolean!]) {
    getHelpCenters(isActive: $isActive) {
      _id
      code
      name {
        code
        text
      }
      content {
        code
        text
      }
      type
      parent
      index
      isActive
      createdDate
      createdBy {
        name
      }
    }
  }
`;

const MUTATION_ADD_HELP_CENTER = gql`
  mutation($input: HelpCenterInput!) {
    addHelpCenter(input: $input)
  }
`;

const MUTATION_EDIT_HELP_CENTER = gql`
  mutation($input: HelpCenterInput!) {
    editHelpCenter(input: $input)
  }
`;

const MUTATION_DEL_HELP_CENTER = gql`
  mutation($id: String!) {
    deleteHelpCenter(id: $id)
  }
`;

const HelpCenterGRAPHQL = {
  QUERY_HELP_CENTER,
  MUTATION_ADD_HELP_CENTER,
  MUTATION_EDIT_HELP_CENTER,
  MUTATION_DEL_HELP_CENTER
};

export default HelpCenterGRAPHQL;