import React, { useState, useCallback, useLayoutEffect, useEffect } from 'react';
import { MDBRow, MDBCol, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';
import Panel from '../../components/Panel';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import { connect } from 'react-redux';

import QuestionGRAPHQL from '../../../graphql/Question';
import eventName from '../../../common/events';
import { useTracking } from 'react-tracking';
import Select from 'react-select';
import RadioBox from '../../components/RadioBox';


function CategoryQuestionPage(props) {
  const { trackEvent } = useTracking();
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const TYPES = [
    { value: 1, label: window.I18N('everyone')},
    { value: 2, label: window.I18N('customized')},
  ];
  const config = [
    window.COMMON.createCol(window.I18N('no'), '8%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('category_name'), '50%', '', '', 'title', 'MULTI_LANGUAGE_CONTENT'),
        window.COMMON.createCol(window.I18N('action'), '10%', '', '', '3', 'BUTTON')
    ];
    const [object, setObject] = useState({ index: -1 });
    const [data, setData] = useState([]);
    const [dataSave, setDataSave] = useState({});
    const [companiesAndInfluencers, setCompaniesAndInfluencers] = useState({})
  const [select, setSelect] = useState({shareCompanies: [], shareOrganizations: []});

  useLayoutEffect(() => {
    setTimeout(() => {
      getCategorydata();
      getDataCompany();
    }, 100);
      // eslint-disable-next-line
    }, []);

    const createTrackingEvent = (event) => {
    return trackEvent({
        name: event,
        createdAt: new Date().toISOString()
    });
    };

    const handleChange = (event) => {
      dataSave.title = dataSave.title.map(content =>
        content.code === event.target.name ?
          { code: content.code, text: event.target.value } :
          content
      ) 
      setDataSave({ ...dataSave });
    };


    const getCategorydata = async () => {
    try {
      window.resetDataTable('table-data');
      const params = {
              company: COMPANY_ID,
              companyType: COMPANY_TYPE
            };
      let data = []
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_QUESTION_CATEGORY, params);
      if (result?.data?.getAllQuestionCategories) {
        data = result?.data?.getAllQuestionCategories
        data.forEach(element => {
          if (element?.title?.length) {
            element.title.forEach((content) => {
              delete content['__typename']
            })
            
          }
          
        });
        setData(result?.data?.getAllQuestionCategories);
      }
    } catch (error) {
        window.COMMON.showErrorLogs('CategoryQuestionPage.getCategorydata');
    }
      window.initDataTable('table-data');
    };

    const openSaveDialog = useCallback((index) => {
        window.COMMON.showModal('#modal-categoryData');
        object.index = index;
        const dataSave = {};
        if (index === -1) {
          dataSave.title = [{ code: "EN", text: "" }, { code: "VI", text: "" }];
          dataSave.shareType = 1;
          select.shareCompanies = []
          select.shareOrganizations = []
        } else {
          const obj = data[index];
          dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
          dataSave.title = window.COMMON.getValueFromAttr(obj, 'title');
          dataSave.shareType = window.COMMON.getValueFromAttr(obj, 'shareType', 1);
          const shareCompanieIds = window.COMMON.getValueFromAttr(obj, 'shareCompanies', []);
          select.shareCompanies = companiesAndInfluencers.companies.filter(option=>shareCompanieIds.includes(option.value));
          const shareOrganizationIds = window.COMMON.getValueFromAttr(obj, 'shareOrganizations', []);
          select.shareOrganizations = companiesAndInfluencers.influencers.filter(option=>shareOrganizationIds.includes(option.value));
        }
        setSelect({...select})
        setObject(object);
        setDataSave({ ...dataSave });
    }, [object, data]);

  // eslint-disable-next-line
    const openDeleteDialog = useCallback((index) => {
        object.index = index;
        setObject(object);
        window.COMMON.showModal('#modal-delete');
        window.deleteMethod = deleteData;
    });

    const saveData = async (event) => {
    try {
      if (!window.COMMON.checkFormValidation('form-categoryData', event)) {
        return;
      }
      const keys = Object.keys(select);
      for (let i = 0; i < keys.length; i++) {
        const element = keys[i];
        dataSave[element] = window.COMMON.getDataSelect(select[element], true);
      }
      const params = {
        input: dataSave
      };
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_ADD_CATEGORY_QUESTION, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getCategorydata();
          createTrackingEvent(eventName.ADD_CATEGORY_QUESTION);
        }
      } else {
        const result = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_EDIT_CATEGORY_QUESTION, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getCategorydata();
          createTrackingEvent(eventName.EDIT_CATEGORY_QUESTION);
        }
      }
      window.COMMON.hideModal('#modal-categoryData');
    } catch (error) {
      window.COMMON.showErrorLogs('CategoryQuestionPage.saveData');
    }
  };

  const deleteData = async () => {
    try {
      const params = {
        id: data[object.index]._id
      };
      const result = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_DEL_CATEGORY_QUESTION, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getCategorydata();
        createTrackingEvent(eventName.DEL_CATEGORY_QUESTION);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('CategoryQuestionPage.deleteData');
    }
  };

  const getDataCompany = async () => {
    try {
      const newOptions = {};
      await window.COMMON.getDataAllCompany();
      const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));
      if (dataCompany && dataCompany.length > 0) {
        const dataInfluencers = window.COMMON.getArrsInArrs(window.CONSTANT.INFLUENCER, dataCompany, 'type');
        const dataCompanies = window.COMMON.getArrsInArrs(window.CONSTANT.COMPANY, dataCompany, 'type');
        newOptions.companies = window.COMMON.createDataSelect(dataCompanies, '_id', 'information.name');
        newOptions.influencers = window.COMMON.createDataSelect(dataInfluencers, '_id', 'information.name') 
      }
      setCompaniesAndInfluencers({...newOptions});

    } catch (error) {
      window.COMMON.showErrorLogs('ScheduleAssessmentPage.getDataCompany');
    }
  };

  const handleChangeShareType = (value) => {
    dataSave.shareType = value
    setDataSave({...dataSave})
    setSelect({shareCompanies: [],  shareOrganizations: []})
  }
  
  const handleSelect = (event, id, attr) => {
    window.COMMON.checkSelectValidation(id, event);
    select[attr] = event
    setSelect({ ...select }); 
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" icon="list-alt">
        <div className="container-top d-flex align-items-center justify-content-end wow fadeInDown animated" data-wow-delay="0.5s">
          <MDBBtn color="primary" className="mr-0" onClick={() => openSaveDialog(-1)}>
            <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
          </MDBBtn>
        </div>
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <Table id="table-data" className="table" config={config} data={data} language={props.language.code} handleRowEditClick={openSaveDialog} handleRowDelClick={openDeleteDialog}></Table>
        </div>
      </Panel>

      <Modal id="modal-categoryData" title={window.I18N('create_update_data')} saveEvent={saveData}>
        <form id="form-categoryData" className="needs-validation" noValidate>
          <MDBRow className='mb-2'>
                <MDBCol>
                    <strong className='font-weight-bold flex-center'>Chia sẻ đến</strong>
                </MDBCol>
          </MDBRow>
          <MDBRow className='mb-3'>
                <MDBCol className='flex-center'>
                    <RadioBox className = 'mb-0' data={TYPES} value={dataSave.shareType} handleChange={handleChangeShareType}></RadioBox>
                </MDBCol>
          </MDBRow>
        {dataSave.shareType === 2 && 
            <>
                    <MDBRow>
                      <MDBCol>     
                          <Select id="select-influencer" value={select.shareOrganizations}  options={companiesAndInfluencers.influencers} placeholder={window.I18N('influencer') + ' *'} className="md-form mt-0"  isMulti isSearchable closeMenuOnSelect={false} onChange={(event) => handleSelect(event, 'select-influencer', 'shareOrganizations')}/>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            <Select id="select-companies" value={select.shareCompanies}  options={companiesAndInfluencers.companies} placeholder={window.I18N('companies') + ' *'} className="md-form mt-0"  isSearchable isMulti closeMenuOnSelect={false} onChange={(event) => handleSelect(event, 'select-companies', 'shareCompanies')} />
                        </MDBCol>
                    </MDBRow>
            </>
          }
          <MDBRow className='mb-2'>
                <MDBCol>
                    <strong className='font-weight-bold flex-center'>Nội dung</strong>
                </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              {dataSave.title?.map(content => 
                <MDBInput key={content.code} outline containerClass="mt-0" type="text" label={content.code} name={content.code} value={content.text} onChange={handleChange} maxLength="100" pattern="\S(.*\S)?" required={content.code==="EN"}>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                </MDBInput>
              )}
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryQuestionPage);