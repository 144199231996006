import React, { useState, useLayoutEffect, useEffect } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { Bar } from 'react-chartjs-2';
import { MDBCardImage, MDBCard, MDBCardBody, MDBCardHeader, MDBBtn, MDBIcon, MDBRow, MDBCol } from 'mdbreact';

import Table from '../components/Table';
import ListItem from '../components/ListItem';
import ListRating from '../components/ListRating';

import MetadataGRAPHQL from '../../graphql/Metadata';

import RewardGRAPHQL from '../../graphql/Reward';
import QuestionGRAPHQL from '../../graphql/Question';
import AccountBalanceGRAPHQL from '../../graphql/AccountBalance';
import AccountGRAPHQL from '../../graphql/Account';
import SelfAssessmentGRAPHQL from '../../graphql/SelfAssessment';
import ExportExcel from '../components/ExportExcel';


import SurveyGRAPHQL from '../../graphql/Survey';
import GrievanceGRAPHQL from '../../graphql/Grievance';
import HighLineChart from '../components/HighLineChart';
import moment from 'moment';

const all_value = 'all';
const all_companies_options = { value: all_value, label: 'All Company', code: 'DEFAULT' };
const group_companies_options = { label: 'Default', options: [all_companies_options] };
const company_filter_keys = ['companyIndustry', 'nation', 'state', 'city', 'organizationType'];


export default function DashboardDashboardGovermentPagePage(props) {
  const types = [
    { value: 'COMPANY', label: window.I18N('company') },
    { value: 'UNION', label: window.I18N('union') },
    { value: 'INFLUENCER', label: window.I18N('influencer') }
  ];

  const config = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('organization'), '20%', 'p-0 pl-1', '', 'avatar,name', 'IMAGE_TEXT'),
    window.COMMON.createCol(window.I18N('industry'), '20%', '', '', 'companyIndustry.value', 'TEXT'),
    window.COMMON.createCol(window.I18N('country'), '12.5%', '', '', 'nation.name', 'TEXT'),
    window.COMMON.createCol(window.I18N('state_city'), '12.5%', '', '', 'state.name', 'TEXT'),
    window.COMMON.createCol(window.I18N('city_district'), '10%', '', '', 'city.name', 'TEXT'),
    window.COMMON.createCol(window.I18N('number_accounts'), '5%', '', 'right', 'numberAccount', 'TEXT'),
    window.COMMON.createCol(window.I18N('start_date'), '10%', '', '', 'createdDate', 'TEXT')
  ];
  const configActivity = [
    window.COMMON.createCol(window.I18N('organization'), '30%', '', '', 'name', 'TEXT'),
    window.COMMON.createCol(window.I18N('post'), '10%', '', '', 'numberPost', 'TEXT'),
    window.COMMON.createCol(window.I18N('like'), '10%', '', '', 'numberLike', 'TEXT'),
    window.COMMON.createCol(window.I18N('dislike'), '10%', '', '', 'numberDislike', 'TEXT'),
    window.COMMON.createCol(window.I18N('comment'), '10%', '', '', 'numberComment', 'TEXT'),
    window.COMMON.createCol(window.I18N('share'), '10%', '', '', 'numberShare', 'TEXT'),
    window.COMMON.createCol(window.I18N('complete_qa'), '10%', '', '', 'numberCompleteQA', 'TEXT'),
    window.COMMON.createCol(window.I18N('total'), '10%', '', '', 'numberActivities', 'TEXT')
  ];
  const [filter, setFilter] = useState({
    startDate: window.COMMON_DATE.addDaysFromNow(-30),
    endDate: new Date()
  });
  const [select, setSelect] = useState({});
  const [options, setOptions] = useState({ companyIndustry: [], nation: [], state: [], city: [], companies: [], organizationType: [] });
  const [count, setCount] = useState({
    numberEmployee: 0,
    numberReward: 0,
    numberActivities: 0,
    numberQA: 0,
    numberPosts: 0,
    numberSurveys: 0,
    numberEcourses: 0,
    numberGrievances: 0,
    numberAssessments: 0
  });
  const [dataCompany, setDataCompany] = useState([]);
  const [dataRating, setDataRating] = useState([]);
  const [dataReward, setDataReward] = useState([]);
  const [dataActivity, setDataActivity] = useState([]);
  const [dataQA, setDataQA] = useState([]);
  const [dataAnswerQA, setDataAnswerQA] = useState([]);
  const [dataSelfAssessment, setDataSelfAssessment] = useState([]);
  const [dataChartByWeek, setDataChartByWeek] = useState({});
  const [dataChartByYear, setDataChartByYear] = useState({});
  const [saveData, setSaveData] = useState({ companyIds: [], companyIds_to_filter: [], company_full_data: [] });
  const [selectAttr, setSelectAttr] = useState(null);
  const [totalActivity, setTotalActivity] = useState([]);
  const [dataChartActivities, setDataChartActivities] = useState({});
  const [dataCompanyWithActivity, setDataCompanyWithActivity] = useState([]);
  const [dataInfluencer, setDataInfluencer] = useState([]);

  const [dataChart, setDataChart] = useState({
    labels: [],
    data: []
  });
  const [grievanceDataChart, setGrievanceDataChart] = useState({
    labels: [],
    data: []
  });
  const [surveyDataChart, setSurveyDataChart] = useState({
    labels: [],
    data: []
  });

  const [courseDataChart, setCourseDataChart] = useState({
    labels: [],
    data: []
  });

  const [assessmentDataChart, setAssessmentDataChart] = useState({
    labels: [],
    data: []
  });

  const [activityChartDay, setActivityChartDay] = useState('7');
  const [grievanceChartDay, setGrievanceChartDay] = useState('7');
  const [surveyChartDay, setSurveyChartDay] = useState('7');
  const [courseChartDay, setCourseChartDay] = useState('7');
  const [assessmentChartDay, setAssessmentChartDay] = useState('7');



  useLayoutEffect(() => {
    setTimeout(() => {
      getDataMetadata();
      getDataLocationOptions('-1', '-1');
      getDataCompany();
	  getInfluencer();
    }, 100);
    // eslint-disable-next-line
  }, []);

  const filterCompanyFollowSelect = () => {
    const currentSelect = select;
    const newCompanyIds_to_filter = [];
    let newCompanyOptions = saveData.company_full_data.filter((item) => {
      for (let i = 0; i < company_filter_keys.length; i++) {

        const key = company_filter_keys[i];


        const select_value = currentSelect[key] ? currentSelect[key].value : null;
        const select_label = currentSelect[key] ? currentSelect[key].label : 'all';
        const keyToGetValue = key === 'organizationType' ? 'accountType' : key;
        const filter_item_value = item[keyToGetValue] || {};
        let item_value;
        if (key === 'organizationType') {
          item_value = filter_item_value;
        }
        else {
          item_value = filter_item_value.name || filter_item_value.value || '';
        }
        const valueToCompare = key === 'organizationType' ? select_value : select_label;
        if (select_value) {
          if (valueToCompare !== item_value) {
            return false;
          }
        }

      }

      newCompanyIds_to_filter.push(item.value_id);
      return true;
    });

    newCompanyOptions = window.COMMON.createDataSelectWithGroup(newCompanyOptions, 'value_id', 'name', 'accountType', 'accountType');
    let select_companies = null;
    if (newCompanyOptions.length > 0) {
      newCompanyOptions.unshift(group_companies_options);
      select_companies = [newCompanyOptions[0].options[0]];

    }

    setSelect((current) => {
      return {
        ...current,
        companies: select_companies
      };
    });


    setOptions((current) => {
      return {
        ...current,
        companies: newCompanyOptions
      };
    });

    setSaveData((current) => {
      return {
        ...current,
        companyIds_to_filter: newCompanyIds_to_filter
      };
    });
    //set this to stop run useEffect again until user changes value in industry, location filter
    setSelectAttr('companies');


  };

  useEffect(() => {
    if (!company_filter_keys.includes(selectAttr)) return;
    filterCompanyFollowSelect();

  }, [select, selectAttr]);







  const handleSelect = (event, attr) => {
    select[attr] = event;
    if (attr === 'nation' || attr === 'state' || attr === 'city') {
      getDataLocationOptions(attr, event.code);
    } else if (attr === 'companies') {
      const allValueIndex = event ? event.findIndex((item) => item.value === all_value) : -1;
      let newEvent;
      if (allValueIndex >= 0) {
        if (allValueIndex === 0 && event.length > 1) {
          event.splice(allValueIndex, 1);
          newEvent = event;
        }
        else if (allValueIndex > 0) {
          newEvent = [event[allValueIndex]];
        }
        else {
          newEvent = event;
        }
        select[attr] = newEvent;
      }

      setSelect((current) => {
        return {
          ...current,
          ...select
        };
      });
    }
    else {
      setSelect((current) => {
        return {
          ...current,
          ...select
        };
      });
    }
    setSelectAttr(attr);
  };

  const handleDatePicker = (event, attr) => {
    filter[attr] = event;
    setFilter({ ...filter });
  };

  const getDataMetadata = async () => {
    try {
      const params = {
        type: ['COMPANY_INDUSTRY']
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_SHORT_METADATA, params, false);
      if (result && result.data) {
        const newOptions = {};
        const newSelect = {};
        const dataIndustry = window.COMMON.getArrsInArrs('COMPANY_INDUSTRY', result.data.getMetadatas, 'type');
        newOptions.companyIndustry = window.COMMON.createDataSelect(dataIndustry, '_id', 'value');
        newOptions.companyIndustry.unshift({ value: null, label: window.I18N('all_industries') });
        newSelect.companyIndustry = { value: null, label: window.I18N('all_industries') };
        setOptions((current) => {
          return {
            ...current,
            ...newOptions
          };
        });
        setSelect((current) => {
          return {
            ...current,
            ...newSelect
          };
        });
      }
    } catch (error) {
      window.COMMON.showErrorLogs('DashboardGovermentPage.getDataMetadata');
    }
  };

  const getDataLocationOptions = async (attr, parent) => {
    const newOptions = {};
    const newSelect = {};
    if (attr === '-1') {
      newOptions.nation = await window.COMMON.getDataLocation(parent);
      newOptions.nation.unshift({ value: null, label: window.I18N('all_countries') });
      newOptions.state = [];
      newOptions.city = [];
      newSelect.nation = { value: null, label: window.I18N('all_countries') };
      newSelect.state = null;
      newSelect.city = null;
    } else if (attr === 'nation') {
      newOptions.state = await window.COMMON.getDataLocation(parent);
      newOptions.state.unshift({ value: null, label: window.I18N('all_states') });
      newOptions.city = [];
      newSelect.state = { value: null, label: window.I18N('all_states') };
      newSelect.city = null;
    } else if (attr === 'state') {
      newOptions.city = await window.COMMON.getDataLocation(parent);
      newOptions.city.unshift({ value: null, label: window.I18N('all_cities') });
      newSelect.city = { value: null, label: window.I18N('all_cities') };
    }
    setOptions((current) => {
      return {
        ...current,
        ...newOptions
      };
    });
    setSelect((current) => {
      return {
        ...current,
        ...newSelect
      };
    });
  };


  const getValueIdFollowCompanyType = (item) => {
    let value_id;
    if (item.accountType === window.CONSTANT.COMPANY) {
      value_id = window.COMMON.getValueFromAttr(item, 'company._id');
    }
    else if (item.accountType === window.CONSTANT.UNION) {
      value_id = window.COMMON.getValueFromAttr(item, 'union._id');
    }
    else if (item.accountType === window.CONSTANT.INFLUENCER) {
      value_id = window.COMMON.getValueFromAttr(item, 'influencer._id');
    }
    return value_id;
  };

  const getInfluencer = async () => {
    try {
      const params = {
        accountType: window.CONSTANT.INFLUENCER
      };

      const resultOrganization = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_BY_TYPE, params);
	  if (resultOrganization && resultOrganization.data && Array.isArray(resultOrganization.data.findAccountByType)) {
        const organizationList = resultOrganization.data.findAccountByType;
        const influencerIds = organizationList
          .map(item => item.influencer?._id)
          .filter(item => item); //filter null item
		
        const resultInfluencers = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_BY_INFLUENCER, { influencerIds });
        if (resultInfluencers && resultInfluencers.data && Array.isArray(resultInfluencers.data.getAccountByInfluencers)) {
		  //filter account organization
		  const filterResult = resultInfluencers.data.getAccountByInfluencers.filter(item => {
            if (organizationList.find(o => o._id === item._id)) return false;
            return true;
		  });
          setDataInfluencer(filterResult);
        }
      }
    } catch (error) {
      console.log(error);
      window.COMMON.showErrorLogs('DashboardGovermentPage.getInfluencer');
    }
  };

  const getDataCompany = async () => {
    try {
      window.showLoading();

      const currentAccountType = localStorage.getItem(window.CONSTANT.ACCOUNT_TYPE);
      let result;

      if (!window.COMMON.checkRoleIsSystemOrInfluencer()) {
        const companyId = localStorage.getItem(window.CONSTANT.ID);
        result = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_BY_ID, { id: companyId }, true);
      }
      //influencer or admin
      else {
        const params = {
          input: {
            companyIndustry: window.COMMON.getDataSelect(select.companyIndustry, false),
            nation: window.COMMON.getDataSelect(select.nation, false),
            state: window.COMMON.getDataSelect(select.state, false),
            city: window.COMMON.getDataSelect(select.city, false)
          }
        };
        result = await window.COMMON.query(AccountGRAPHQL.QUERY_COMPANY_BY_GOVERMENT, params, true);
      }





      let data = [];
      let group_companies = [];
      const newSelect = {};
      newSelect.companies = [];
      newSelect.companyIds = [];
      const companyIds = [];






      if (result && result.data) {
        let drawData;
        if (!window.COMMON.checkRoleIsSystemOrInfluencer()) {
          drawData = [result.data.findAccountById];
        }
        else {
          drawData = result.data.getCompanyAccountsByGoverment;
        }
        data = window.COMMON.formatDate(drawData, 'createdDate');

        count.numberCompany = data.length;

        data.forEach(item => {
          const id = getValueIdFollowCompanyType(item);

          companyIds.push(id);
          item.value_id = id;
          item.numberActivities = 0;
          item.numberPost = 0;
          item.numberLike = 0;
          item.numberDislike = 0;
          item.numberComment = 0;
          item.numberShare = 0;
          item.numberCompleteQA = 0;
          item.assginedQA = 0;
          item.answeredQA = 0;
          item.total = 0;
          item.average = 0;
          let rating = [];
          if (item.accountType === window.CONSTANT.COMPANY) {
            rating = window.COMMON.getValueFromAttr(item, 'company.rating', []);
          }
          else if (item.accountType === window.CONSTANT.UNION) {
            rating = window.COMMON.getValueFromAttr(item, 'union.rating', []);
          }
          else if (item.accountType === window.CONSTANT.INFLUENCER) {
            rating = window.COMMON.getValueFromAttr(item, 'influencer.rating', []);
          }
          if (rating.length > 0) {
            let total = 0;
            rating.forEach(child => {
              total += child.star;
            });
            item.total = rating.length;
            item.average = Math.round((total / item.total) * 10) / 10;
          }
          item.average = item.average.toFixed(1);
        });
      } else {
        count.numberCompany = 0;

      }

      let organizationTypesFollowCompany = types;
      if (!window.COMMON.checkRoleIsSystemOrInfluencer()) {
        organizationTypesFollowCompany = types.filter((item) => item.value === currentAccountType);
      }



      newSelect.organizationType = organizationTypesFollowCompany[0];

      //filter organization follow type
      const dataFilterFollowOrganizationType = [];
      const idsFollowOrganizationType = [];

      data.forEach((item) => {
        if (item.accountType === newSelect.organizationType?.value) {
          dataFilterFollowOrganizationType.push(item);
          idsFollowOrganizationType.push(item.value_id);
        }
      });

      if (dataFilterFollowOrganizationType.length > 0) {
        group_companies = window.COMMON.createDataSelectWithGroup(dataFilterFollowOrganizationType, 'value_id', 'name', 'accountType', 'accountType');
        //show all company option when current account is Admin or influencer (because they can select many company)
        // if(window.COMMON.checkRoleIsSystemOrInfluencer()){
        //   group_companies.unshift(group_companies_options);
        // }
        group_companies.unshift(group_companies_options);
        newSelect.companies = [group_companies[0].options[0]];
        newSelect.companyIds = idsFollowOrganizationType;
      }
      getDataSummary(dataFilterFollowOrganizationType, idsFollowOrganizationType);
      // getDataSummary(data, newSelect.companyIds);

      setCount((current) => {
        return {
          ...current,
          numberCompany: count.numberCompany
        };
      });
      setSelect((current) => {
        return {
          ...current,
          ...newSelect
        };
      });

      setOptions((current) => {
        return {
          ...current,
          companies: group_companies,
          organizationType: organizationTypesFollowCompany
        };
      });
      setSaveData((current) => {
        return {
          ...current,
          companyIds,
          companyIds_to_filter: newSelect.companyIds,
          company_full_data: data
        };
      });

    } catch (error) {
      console.log(error);
      window.COMMON.showErrorLogs('DashboardGovermentPage.getDataCompany');
    }
    window.hideLoading();
  };

  const getCompanyIdsFromSelect = () => {
    const companies_selected = select.companies || [];
    let companyIds = [];
    let isAll = false;
    for (let i = 0; i < companies_selected.length; i++) {
      const company_selected = companies_selected[i];
      if (company_selected.value === all_value) {
        isAll = true;
        break;
      }
      companyIds.push(company_selected.value);
    }

    if (isAll === true) {
      companyIds = [];
      saveData.companyIds_to_filter.forEach((item) => {
        // if (item.accountType === select.organizationType.value) {
        companyIds.push(item);
        // }
      });
    }


    return companyIds;
  };

  const doSearchData = () => {

    const companies_selected = select.companies;
    if (!companies_selected) {
      window.COMMON.showMessage('warning', 'MSG_CODE_049', window.MSG.MSG_CODE_049);
      return;
    }

    const companyIds = getCompanyIdsFromSelect();
    const newDataCompany = saveData.company_full_data.filter(item => companyIds.includes(item.value_id));
    window.COMMON.hideFilter();
    getDataSummary(newDataCompany, companyIds);
  };

  const getDateparams = (pastDays = null) => {
    let startDate = window.COMMON_DATE.getStrDateFilter(filter.startDate, -1);
    if (pastDays) {
      startDate = window.COMMON_DATE.getDateFollowPastDate({ pastDays, mode: -1 });
    }
    const endDate = window.COMMON_DATE.getStrDateFilter(filter.endDate, 1);

    return {
      startDate,
      endDate
    };

  };

  const getCompanyParams = (companyIds = null, dataCompany = null) => {
    let companyIdsToCheck = companyIds;
    if (!companyIds) {
      companyIdsToCheck = getCompanyIdsFromSelect();

    }
    let currentDataCompany = dataCompany;
    if (!dataCompany) {
      currentDataCompany = saveData.company_full_data;
    }
    const companyIdsInput = [];
    const unionIdsInput = [];
    const influencerIdsInput = [];

    currentDataCompany.forEach(item => {
      if (companyIdsToCheck.includes(item.value_id)) {
        if (item.company) {
          companyIdsInput.push(item.value_id);
        }
        if (item.union) {
          unionIdsInput.push(item.value_id);
        }
        if (item.influencer) {
          influencerIdsInput.push(item.value_id);
        }
      }
    });


    return {
      unionIds: unionIdsInput,
      companyIds: companyIdsInput,
      influencerIds: influencerIdsInput
    };
  };

  const getDataSummary = (dataCompany, companyIds) => {
    //filter company by startDate
    const dataCompanyFilter = dataCompany.filter(item => { 
      const compareDate = moment(item.createdDate, 'DD/MM/YYYY');
      const startDate   = moment(filter.startDate, 'DD/MM/YYYY');
      const endDate     = moment(filter.endDate, 'DD/MM/YYYY');
      if (!compareDate.isBetween(startDate, endDate)) {
        return false;
      };
      return true;
    });

    getAccountSummary(dataCompanyFilter, dataCompany, companyIds);
    getRatingSummary(dataCompany, companyIds);
    getRewardSummary(dataCompany, companyIds);
    getAtivitySummary(dataCompany, companyIds);
    getQASummary(dataCompany, companyIds);
    getAnswerQASummary(dataCompany, companyIds);
    getSelfAssessmentSummary(dataCompany, companyIds);
    getSelfAssessmentSummaryByWeek(companyIds);
    getSelfAssessmentSummaryByYear(companyIds);

    //include unionIds and companyIds
    const companyParams = getCompanyParams(companyIds, dataCompany);


    countSurvey(companyParams);
    countEcourse(companyParams);
    countGrievance(companyParams);
    countAssesment(companyParams);

    getActivity(activityChartDay, companyParams);
    getGrievanceDataChart(grievanceChartDay, companyParams);
    getSurveyDataChart(surveyChartDay, companyParams);
    getCourseDataChart(courseChartDay, companyParams);
    getAssessmentDataChart(assessmentChartDay, companyParams);
  };

  const getAccountSummary = async (dataCompanyFilter,dataCompany, companyIds) => {
    try {
      window.resetDataTable('table-company');
      const params = {
        companyIds : window.COMMON.filterListValidObjectId(companyIds)
      };
      const result = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_BY_COMPANY_ID, params);
      if (result && result.data) {
        const data = result.data.countAccountByCompany;
        let countNumber = 0;
        dataCompany.forEach(company => {
          let obj = null;
          if (company.accountType === window.CONSTANT.COMPANY) {
            obj = window.COMMON.getObjectInArrs(company.company?._id, data, '_id');
          } else if (company.accountType === window.CONSTANT.UNION) {
            obj = window.COMMON.getObjectInArrs(company.union?._id, data, '_id');
          }
          else if (company.accountType === window.CONSTANT.INFLUENCER) {
            obj = window.COMMON.getObjectInArrs(company.influencer?._id, data, '_id');
          }
          company.numberAccount = obj ? obj.count : 0;
          countNumber += company.numberAccount;
        });
        setCount((current) => {
          return {
            ...current,
            numberEmployee: countNumber
          };
        });
      }
      setDataCompany(dataCompanyFilter);
    } catch (error) {
      window.COMMON.showErrorLogs('DashboardGovermentPage.getAccountSummary');
    }
    window.initDataTable('table-company');
  };

  const getRatingSummary = async (dataCompany, companyIds) => {
    try {
      //dataCompany includes rating score
      const ratingData = [];
      dataCompany.forEach((item) => {
        if (item.total > 0) {
          ratingData.push(item);
        }
      });
      setDataRating(ratingData);
    } catch (error) {
      window.COMMON.showErrorLogs('DashboardGovermentPage.getRatingSummary');
    }
  };

  const getRewardSummary = async (dataCompany, companyIds) => {
    try {
      const params = {
        input: {
          companyIds: window.COMMON.filterListValidObjectId(companyIds) ,
          startDate: window.COMMON_DATE.getStrDateFilter(filter.startDate, -1),
          endDate: window.COMMON_DATE.getStrDateFilter(filter.endDate, 1)
        }
      };
      const result = await window.COMMON.query(RewardGRAPHQL.QUERY_HISTORY_REWARD_BY_GOVERMENT, params);
      count.numberReward = 0;
      let dataReward = [];
      if (result && result.data) {
        const data = result.data.getHistoryRewardByGoverment;
        data.forEach(item => {
          let company = window.COMMON.getObjectInArrs(item._id, dataCompany, 'company._id');
          if (!company) {
            company = window.COMMON.getObjectInArrs(item._id, dataCompany, 'union._id');
          }
          if (!company) {
            company = window.COMMON.getObjectInArrs(item._id, dataCompany, 'influencer._id');
          }
          if (company) {
            count.numberReward += item.count;
            dataReward.push({
              count: item.count,
              name: window.COMMON.getValueFromAttr(company, 'name'),
              avatar: window.COMMON.getValueFromAttr(company, 'avatar')
            });
          }
        });
      }

      window.COMMON.sortDescData(dataReward, 'count');
      dataReward = dataReward.map((item, index) => {
        return {
          ...item,
          index: index + 1
        };
      });
      setCount((current) => {
        return {
          ...current,
          numberReward: count.numberReward
        };
      });
      setDataReward(dataReward);
    } catch (error) {

      window.COMMON.showErrorLogs('DashboardGovermentPage.getRewardSummary');
    }
  };

  const getAtivitySummary = async (dataCompany, companyIds) => {
    try {
      let organizationTypeToFilter = window.CONSTANT.COMPANY;
      if (dataCompany && dataCompany.length > 0) {
        organizationTypeToFilter = dataCompany[0].accountType;
      }
      let keyToGetActivity = 'companyIds';
      if (organizationTypeToFilter === window.CONSTANT.UNION) {
        keyToGetActivity = 'unionIds';
      }
      else if (organizationTypeToFilter === window.CONSTANT.INFLUENCER) {
        keyToGetActivity = 'influencerIds';
      }
      const params = {
        input: {
          [keyToGetActivity]: window.COMMON.filterListValidObjectId(companyIds),
          startDate: window.COMMON_DATE.getStrDateFilter(filter.startDate, -1),
          endDate: window.COMMON_DATE.getStrDateFilter(filter.endDate, 1)
        }
      };
      const result = await window.COMMON.query(AccountBalanceGRAPHQL.QUERY_ACTIVITY, params);
      count.numberActivities = 0;
      const totalActivity = [
        { name: window.I18N('post'), count: 0, type: 'POST' },
        { name: window.I18N('like'), count: 0, type: 'LIKE' },
        { name: window.I18N('dislike'), count: 0, type: 'DISLIKE' },
        { name: window.I18N('comment'), count: 0, type: 'COMMENT' },
        { name: window.I18N('share'), count: 0, type: 'SHARE' },
        { name: window.I18N('complete_qa'), count: 0, type: 'COMPLETE_QA' }
      ];
      let dataActivity = [];
      let countAllActivity = 0;
      if (result && result.data) {
        const data = result.data.getActivity;

        const newDataCompany = JSON.parse(JSON.stringify(dataCompany));
        data.forEach(item => {
          let company;
          let val;
          let attr;
          if (organizationTypeToFilter === window.CONSTANT.COMPANY && item.company) {
            val = item.company;
            attr = 'company._id';
          }
          else if (organizationTypeToFilter === window.CONSTANT.UNION && item.union) {
            val = item.union;
            attr = 'union._id';
          }
          else if (organizationTypeToFilter === window.CONSTANT.INFLUENCER && item.influencer) {
            val = item.influencer;
            attr = 'influencer._id';
          }

          if (val && attr) {
            company = window.COMMON.getObjectInArrs(val, newDataCompany, attr);
          }

          if (company) {
            countAllActivity += 1;
            company.numberActivities += 1;
            if (item.type === 'POST') {
              company.numberPost += 1;
              totalActivity[0].count += 1;
            } else if (item.type === 'LIKE') {
              company.numberLike += 1;
              totalActivity[1].count += 1;
            } else if (item.type === 'DISLIKE') {
              company.numberDislike += 1;
              totalActivity[2].count += 1;
            } else if (item.type === 'COMMENT') {
              company.numberComment += 1;
              totalActivity[3].count += 1;
            } else if (item.type === 'SHARE') {
              company.numberShare += 1;
              totalActivity[4].count += 1;
            } else if (item.type === 'COMPLETE_QA') {
              company.numberCompleteQA += 1;
              totalActivity[5].count += 1;
            }
          }

        });
        const newDataCompanyWithActivity = JSON.parse(JSON.stringify(newDataCompany));

        newDataCompany.forEach((company, index) => {
          if (company.numberActivities > 0) {
            const companyActivityItem = {
              ...company,
              count: company.numberActivities,
              name: window.COMMON.getValueFromAttr(company, 'name'),
              avatar: window.COMMON.getValueFromAttr(company, 'avatar')
            };
            dataActivity.push(companyActivityItem);
            newDataCompanyWithActivity[index] = companyActivityItem;

          }
        });

        setDataCompanyWithActivity(newDataCompanyWithActivity);

      }



      count.numberActivities = countAllActivity;
      const dataChartActivities = {
        labels: [window.I18N('post'), window.I18N('like'), window.I18N('dislike'), window.I18N('comment'), window.I18N('share'), window.I18N('complete_qa')],
        datasets: [{
          label: 'Activities Chart',
          data: [totalActivity[0].count, totalActivity[1].count, totalActivity[2].count, totalActivity[3].count, totalActivity[4].count, totalActivity[5].count],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }]
      };

      window.COMMON.sortDescData(dataActivity, 'count');
      dataActivity = dataActivity.map((item, index) => {
        return {
          ...item,
          index: index + 1
        };
      });
      setDataActivity(dataActivity);
      setCount((current) => {
        return {
          ...current,
          numberActivities: count.numberActivities,
          numberPosts: totalActivity[0].count
        };
      });
      setTotalActivity(totalActivity);
      setDataChartActivities(dataChartActivities);
    } catch (error) {
      window.COMMON.showErrorLogs('DashboardGovermentPage.getAtivitySummary');
    }
  };

  const getQASummary = async (dataCompany, companyIds) => {
    try {
      const params = {
        input: {
          companyIds: window.COMMON.filterListValidObjectId(companyIds) ,
          startDate: window.COMMON_DATE.getStrDateFilter(filter.startDate, -1),
          endDate: window.COMMON_DATE.getStrDateFilter(filter.endDate, 1)
        }
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_HISTORY_QA, params);
      count.numberQA = 0;
      let dataQA = [];
      if (result && result.data) {
        const data = result.data.getHistoryQA;
        data.forEach(item => {
          let company = window.COMMON.getObjectInArrs(item.user?.company?._id, dataCompany, 'company._id');
          if (!company) {
            company = window.COMMON.getObjectInArrs(item.user?.influencer?._id, dataCompany, 'influencer._id');
          }
          if (!company) {
            company = window.COMMON.getObjectInArrs(item.user?.union?._id, dataCompany, 'union._id');
          }
          if (company) {
            count.numberQA += item.count;

            const dataIndex = dataQA.findIndex(d => d._id === company._id);
            if (dataIndex > -1) {
			  dataQA[dataIndex] = {
                ...dataQA[dataIndex],
                count: dataQA[dataIndex].count + item.count
			  };
            } else {
              dataQA.push({
                _id: window.COMMON.getValueFromAttr(company, '_id'),
                count: item.count,
                name: window.COMMON.getValueFromAttr(company, 'name'),
                avatar: window.COMMON.getValueFromAttr(company, 'avatar')
              });
            }
          }
        });
      }
      window.COMMON.sortDescData(dataQA, 'count');
      dataQA = dataQA.map((item, index) => {
        return {
          ...item,
          index: index + 1
        };
      });
      setDataQA(dataQA);
      setCount((current) => {
        return {
          ...current,
          numberQA: count.numberQA
        };
      });
    } catch (error) {
      window.COMMON.showErrorLogs('DashboardGovermentPage.getQASummary');
    }
  };

  const getAnswerQASummary = async (dataCompany, companyIds) => {
    try {
      const params = {
        input: {
          companyIds: window.COMMON.filterListValidObjectId(companyIds),
          startDate: window.COMMON_DATE.getStrDateFilter(filter.startDate, -1),
          endDate: window.COMMON_DATE.getStrDateFilter(filter.endDate, 1)
        }
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_HISTORY_ANSWER_BY_GOVERMENT, params);
      let dataAnswerQA = [];
      if (result && result.data) {
        const data = result.data.getHistoryAnswerByGoverment;
        data.forEach(item => {
          let company = window.COMMON.getObjectInArrs(item._id, dataCompany, 'company._id');
          if (!company) {
            company = window.COMMON.getObjectInArrs(item._id, dataCompany, 'union._id');
          }
          if (!company) {
            company = window.COMMON.getObjectInArrs(item._id, dataCompany, 'influencer._id');
          }
          if (company) {
            company.answeredQA = item.count;
            dataAnswerQA.push({
              count: item.count,
              name: window.COMMON.getValueFromAttr(company, 'name'),
              avatar: window.COMMON.getValueFromAttr(company, 'avatar')
            });
          }
        });
      }

      window.COMMON.sortDescData(dataAnswerQA, 'count');
      dataAnswerQA = dataAnswerQA.map((item, index) => {
        return {
          ...item,
          index: index + 1
        };
      });
      setDataAnswerQA(dataAnswerQA);
    } catch (error) {
      window.COMMON.showErrorLogs('DashboardGovermentPage.getAnswerQASummary');
    }
  };

  const getSelfAssessmentSummary = async (dataCompany, companyIds) => {
    try {
      const params = {
        input: {
          companyIds: window.COMMON.filterListValidObjectId(companyIds),
          startDate: window.COMMON_DATE.getStrDateFilter(filter.startDate, -1),
          endDate: window.COMMON_DATE.getStrDateFilter(filter.endDate, 1)
        }
      };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_COUNT_SELF_ASSESSMENT, params);
      let dataSelfAssessment = [];
      if (result && result.data) {
        const data = result.data.countHistorySelfAssessment;
        data.forEach(item => {
          let company = window.COMMON.getObjectInArrs(item._id, dataCompany, 'company._id');
          if (!company) {
            company = window.COMMON.getObjectInArrs(item._id, dataCompany, 'union._id');
          }
          if (!company) {
            company = window.COMMON.getObjectInArrs(item._id, dataCompany, 'influencer._id');
          }
          dataSelfAssessment.push({
            count: item.count,
            name: window.COMMON.getValueFromAttr(company, 'name'),
            avatar: window.COMMON.getValueFromAttr(company, 'avatar')
          });

        });
      }

      window.COMMON.sortDescData(dataSelfAssessment, 'count');
      dataSelfAssessment = dataSelfAssessment.map((item, index) => {
        return {
          ...item,
          index: index + 1
        };
      });
      setDataSelfAssessment(dataSelfAssessment);
    } catch (error) {
      window.COMMON.showErrorLogs('DashboardGovermentPage.getSelfAssessmentSummary');
    }
  };

  const getSelfAssessmentSummaryByWeek = async (companyIds) => {
    try {
      const params = {
        input: {
          companyIds: window.COMMON.filterListValidObjectId(companyIds),
          startDate: window.COMMON_DATE.getStrDateFilter(window.COMMON_DATE.addDaysFromNow(-6), -1),
          endDate: window.COMMON_DATE.getStrDateFilter(new Date(), 1)
        }
      };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_COUNT_SELF_ASSESSMENT_BY_WEEK, params);
      const days = [];
      for (let i = 0; i < 7; i++) {
        const date = window.COMMON_DATE.formatDate(window.COMMON_DATE.addDaysFromNow(0 - i), 'DD/MM');
        days.unshift(date);
      }
      const dataChartByWeek = {
        labels: days,
        datasets: [{
          label: 'Self Assessment',
          data: [],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(0, 200, 81, 0.1)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(0, 200, 81, 1)'
          ],
          borderWidth: 1
        }]
      };
      if (result && result.data) {
        const data = result.data.countHistorySelfAssessmentByWeek;
        for (let i = 0; i < days.length; i++) {
          const item = window.COMMON.getObjectInArrs(days[i].substring(0, 2), data, '_id');
          dataChartByWeek.datasets[0].data[i] = item ? item.count : 0;
        }
      }
      setDataChartByWeek(dataChartByWeek);
    } catch (error) {
      window.COMMON.showErrorLogs('DashboardGovermentPage.getSelfAssessmentSummaryByWeek');
    }
  };

  const getSelfAssessmentSummaryByYear = async (companyIds) => {
    try {
      const params = {
        companyIds: window.COMMON.filterListValidObjectId(companyIds)
      };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_COUNT_SELF_ASSESSMENT_BY_YEAR, params);
      const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
      const dataChartByYear = {
        labels: months,
        datasets: [{
          label: 'Self Assessment',
          data: [],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }]
      };
      if (result && result.data) {
        const data = result.data.countHistorySelfAssessmentByYear;
        for (let i = 0; i < months.length; i++) {
          const item = window.COMMON.getObjectInArrs(months[i], data, '_id');
          dataChartByYear.datasets[0].data[i] = item ? item.count : 0;
        }
      }
      setDataChartByYear(dataChartByYear);
    } catch (error) {
      window.COMMON.showErrorLogs('DashboardGovermentPage.getSelfAssessmentSummaryByYear');
    }
  };


  const convertChartDataToExportExcelData = (chartData) => {
    const excelData = [];
    if (chartData && chartData.datasets && chartData.datasets.length > 0) {
      const dataArray = chartData.datasets[0].data;
      const labelArray = chartData.labels;

      for (let i = 0; i < labelArray.length; i++) {
        excelData.push({
          value: dataArray[i],
          label: labelArray[i]
        });
      }
    }

    return excelData;

  };


  const countSurvey = async (companyParams) => {
    try {
      const params = {
        input: {
          ...companyParams,
          ...getDateparams()
        }
      };
      const result = await window.COMMON.query(
        QuestionGRAPHQL.QUERY_COUNT_GROUP_QUESTION, //change api countSurvey => countGroupQuestion (due to Survey not used)
        params
      );
      count.numberSurveys = 0;
      if (result && result.data) {
        count.numberSurveys = result.data.countGroupQuestion;
      }

      setCount((current) => {
        return {
          ...current,
          numberSurveys: count.numberSurveys
        };
      });

    } catch (error) {
      window.COMMON.showErrorLogs('DashboardGovermentPage.countTask');
    }
  };

  const countEcourse = async (companyParams) => {
    try {
      const result = await window.COMMON.httpRequest({
        url: process.env.REACT_APP_ELEARNING_API + '/courses/count',
        method: 'GET',
        input: {
          params: {
            ...companyParams,
            startDate: window.COMMON_DATE.getStrDateFilter(
              filter.startDate,
              -1
            ),
            endDate: window.COMMON_DATE.getStrDateFilter(filter.endDate, 1)
          }
        },
        requireToken: true,
        handleError: () => {
          // window.COMMON.showErrorLogs('DashboardPage.countEcourse');
        }
      });
      count.numberEcourses = 0;
      if (result && result.count) {
        count.numberEcourses = result.count;
      }
      setCount((current) => {
        return {
          ...current,
          numberEcourses: count.numberEcourses
        };
      });
    } catch (error) {
      // window.COMMON.showErrorLogs('DashboardGovermentPage.countEcourse');
    }
  };

  const countGrievance = async (companyParams) => {
    try {

      const params = {
        input: {
          ...companyParams,
          ...getDateparams()
        }
      };
      const result = await window.COMMON.query(
        GrievanceGRAPHQL.QUERY_COUNT_GRIEVANCE,
        params
      );
      count.numberGrievances = 0;
      if (result && result.data) {
        count.numberGrievances = result.data.countGrievance;
      }
      setCount((current) => {
        return {
          ...current,
          numberGrievances: count.numberGrievances
        };
      });

    } catch (error) {
      window.COMMON.showErrorLogs('DashboardGovermentPage.countGrievance');
    }
  };

  const countAssesment = async (companyParams) => {
    try {
      const params = {
        input: {
          ...companyParams,
          ...getDateparams()
        }
      };

      const result = await window.COMMON.query(
        SelfAssessmentGRAPHQL.QUERY_COUNT_ASSESSMENT,
        params
      );

      let count = 0;
      if (result && result.data) {
        count = result.data.countAssessment;
      }
      setCount((current) => {
        return {
          ...current,
          numberAssessments: count
        };
      });

    } catch (error) {
      window.COMMON.showErrorLogs('DashboardGovermentPage.countAssesment');
    }
  };

  const getActivity = async (pastDays = '7', companyParams = null) => {
    try {
      if (select.company !== null) {
        const startDate = window.COMMON_DATE.getDateFollowPastDate({ pastDays, mode: -1 });
        let companyInputs = companyParams;
        if (!companyParams) {
          companyInputs = getCompanyParams();
        }
        const newCompanyInputs = { ...companyInputs };
        const params = {
          input: {
            ...newCompanyInputs,
            startDate,
            endDate: window.COMMON_DATE.getStrDateFilter(new Date(), 1)
          }
        };
        const result = await window.COMMON.query(
          AccountBalanceGRAPHQL.QUERY_ACTIVITY,
          params
        );
        if (result && result.data) {

          const data = window.COMMON.formatDate(
            result.data.getActivity,
            'createdDate',
            pastDays.includes('year') ? 'MM/YYYY' : 'DD/MM/YYYY'
          );

          const types = ['POST', 'LIKE', 'DISLIKE', 'COMMENT', 'SHARE'];


          const dataChart = window.COMMON.getDataChart({
            types,
            data,
            pastDays,
            itemKey: 'type',
            dateKey: 'createdDate'
          });
          setDataChart(dataChart);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('DashboardGovermentPage.getActivity');
    }
  };
  const getGrievanceDataChart = async (pastDays = '7', companyParams = null) => {
    try {
      let companyInputs = companyParams;
      if (!companyParams) {
        companyInputs = getCompanyParams();
      }
      const params = {
        input: {
          ...companyInputs,
          ...getDateparams(pastDays)
        }
      };
      const result = await window.COMMON.query(
        GrievanceGRAPHQL.QUERY_GRIEVANCE_DATA_CHART,
        params
      );
      let grievanceData = [];
      if (result && result.data) {
        grievanceData = result.data.grievanceDataChart;
      }
      const data = window.COMMON.formatDate(
        grievanceData,
        'createdDate',
        pastDays.includes('year') ? 'MM/YYYY' : 'DD/MM/YYYY'
      );
      const types = ['GRIEVANCE', 'INVESTIGATING', 'FINDING', 'APPEALED', 'CLOSED'];
      const dataChart = window.COMMON.getDataChart({
        types,
        data,
        pastDays,
        itemKey: 'status',
        dateKey: 'createdDate'
      });
      setGrievanceDataChart(dataChart);


    }

    catch (error) {
      window.COMMON.showErrorLogs('DashboardGovermentPage.getGrievanceDataChart');
    }
  };
  const getSurveyDataChart = async (pastDays = '7', companyParams = null) => {
    try {
      let companyInputs = companyParams;
      if (!companyParams) {
        companyInputs = getCompanyParams();
      }
      const params = {
        input: {
          ...companyInputs,
          ...getDateparams(pastDays)
        }
      };
      const result = await window.COMMON.query(
        SurveyGRAPHQL.QUERY_SURVEY_DATA_CHART,
        params
      );
      let surveyData = [];
      if (result && result.data) {
        surveyData = result.data.surveyDataChart;
      }
      const data = window.COMMON.formatDate(
        surveyData,
        'createdDate',
        pastDays.includes('year') ? 'MM/YYYY' : 'DD/MM/YYYY'
      );

      const types = ['SURVEY', 'QUESTION', 'ANSWER', 'PARTICIPANT'];
      const dataChart = window.COMMON.getDataChart({
        types,
        data,
        pastDays,
        itemKey: 'type',
        dateKey: 'createdDate'
      });

      setSurveyDataChart(dataChart);


    }

    catch (error) {
      window.COMMON.showErrorLogs('DashboardGovermentPage.getSurveyDataChart');
    }
  };
  const getCourseDataChart = async (pastDays = '7', companyParams = null) => {
    try {

      let companyInputs = companyParams;
      if (!companyParams) {
        companyInputs = getCompanyParams();
      }
      const params = {
        input: {
          ...companyInputs,
          ...getDateparams(pastDays)
        }
      };

      const result = await window.COMMON.httpRequest({
        url: process.env.REACT_APP_ELEARNING_API + '/courses/chart',
        method: 'GET',
        input: {
          params: {
            startDate: params.input.startDate,
            endDate: params.input.endDate,
            companyIds: params.input.companyIds
          }
        },
        requireToken: true,
        handleError: () => {
          // window.COMMON.showErrorLogs('DashboardGovermentPage.getCourseDataChart');
        }
      });
      let courseData = [];
      if (result && result.courseDataChart) {
        courseData = result.courseDataChart;
      }

      const data = window.COMMON.formatDate(
        courseData,
        'createdAt',
        pastDays.includes('year') ? 'MM/YYYY' : 'DD/MM/YYYY'
      );

      const types = ['COURSE', 'ENROLL_COURSE'];
      const dataChart = window.COMMON.getDataChart({
        types,
        data,
        pastDays,
        itemKey: 'type',
        dateKey: 'createdAt'
      });


      setCourseDataChart(dataChart);


    }

    catch (error) {
      // window.COMMON.showErrorLogs('DashboardGovermentPage.getCourseDataChart');
    }
  };
  const getAssessmentDataChart = async (pastDays = '7', companyParams = null) => {
    try {

      let companyInputs = companyParams;
      if (!companyParams) {
        companyInputs = getCompanyParams();
      }
      const params = {
        input: {
          ...companyInputs,
          ...getDateparams(pastDays)
        }
      };
      const result = await window.COMMON.query(
        SelfAssessmentGRAPHQL.QUERY_ASSESSMENT_DATA_CHART,
        params
      );
      let assessmentData = [];
      if (result && result.data) {
        assessmentData = result.data.assessmentDataChart;
      }
      const data = window.COMMON.formatDate(
        assessmentData,
        'createdDate',
        pastDays.includes('year') ? 'MM/YYYY' : 'DD/MM/YYYY'
      );

      const types = ['ASSESSMENT', 'COMPANY', 'ASSIGNED', 'REVIEWED', 'COMPLETED'];
      const dataChart = window.COMMON.getDataChart({
        types,
        data,
        pastDays,
        itemKey: 'status',
        dateKey: 'createdDate'
      });

      setAssessmentDataChart(dataChart);

    }
    catch (error) {
      window.COMMON.showErrorLogs('DashboardPage.getAssessmentDataChart');
    }
  };

  const getCompanyInfluencers = (data) => {
    if (!Array.isArray(data)) return [];

    const result = data.map(item => {
      const influencers = dataInfluencer.filter(i => {
        if (Array.isArray(i.companies)) {
          //find if influencer manage this company
          const company = i.companies.find(c => c._id === item.company?._id);
          if (company) return true;
          return false;
        }
        return false;
      });

	  //create string include all influencer name
	  let influencersName = '';
	  influencers.map((i, iIndex) => {
        influencersName += (iIndex === 0 ? i.name : `, ${i.name}`); //check first item
	  });

	  return {
        ...item,
        influencersName
	  };
    });
    return result;
  };


  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row mb-0 mt-3">
      <div className="card mb-3 mt-3 wow fadeInDown animated text-left" data-wow-delay="0.5s" style={{ zIndex: 1000 }}>
        <div className="card-body pt-2">
          <MDBRow className="mt-2">
            <MDBCol sm="6" className="mb-2 pr-sm-2">
              <strong>{window.I18N('industry')}</strong>
              <div className="input-group input-group-transparent mt-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="filter" className="fa-fw" />
                  </span>
                </div>
                <Select value={select.companyIndustry} options={options.companyIndustry} className="md-form m-0" placeholder={window.I18N('choose_option')} onChange={(event) => handleSelect(event, 'companyIndustry')} isSearchable />
              </div>
            </MDBCol>
            <MDBCol sm="3" className="mb-2 pl-sm-2 pr-sm-2">
              <strong>{window.I18N('organization_type')}</strong>
              <div className="input-group input-group-transparent mt-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="filter" className="fa-fw" />
                  </span>
                </div>
                <Select value={select.organizationType} options={options.organizationType} className="md-form m-0" placeholder={window.I18N('choose_option')} onChange={(event) => handleSelect(event, 'organizationType')} isSearchable />
              </div>
            </MDBCol>
            <MDBCol sm="3" className="mb-2 pl-sm-2">
              <strong>{window.I18N('country')}</strong>
              <div className="input-group input-group-transparent mt-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="filter" className="fa-fw" />
                  </span>
                </div>
                <Select value={select.nation} options={options.nation} className="md-form m-0" placeholder={window.I18N('choose_option')} onChange={(event) => handleSelect(event, 'nation')} isSearchable />
              </div>
            </MDBCol>
            <MDBCol sm="3" className="mb-2 pr-sm-2">
              <strong>{window.I18N('state_city')}</strong>
              <div className="input-group input-group-transparent mt-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="filter" className="fa-fw" />
                  </span>
                </div>
                <Select value={select.state} options={options.state} className="md-form m-0" placeholder={window.I18N('choose_option')} onChange={(event) => handleSelect(event, 'state')} isSearchable />
              </div>
            </MDBCol>
            <MDBCol sm="3" className="mb-2 pl-sm-2 pr-sm-2">
              <strong>{window.I18N('city_district')}</strong>
              <div className="input-group input-group-transparent mt-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="filter" className="fa-fw" />
                  </span>
                </div>
                <Select value={select.city} options={options.city} className="md-form m-0" placeholder={window.I18N('choose_option')} onChange={(event) => handleSelect(event, 'city')} isSearchable />
              </div>
            </MDBCol>
            <MDBCol sm="3" className="mb-2 pl-sm-2 pr-sm-2">
              <strong>{window.I18N('from_date')}</strong>
              <div className="input-group input-group-transparent mt-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                  </span>
                </div>
                <DatePicker className="form-control" dateFormat="dd/MM/yyyy" selected={filter.startDate} onChange={(event) => handleDatePicker(event, 'startDate')} showYearDropdown showMonthDropdown />
              </div>
            </MDBCol>
            <MDBCol sm="3" className="mb-2 pl-sm-2">
              <strong>{window.I18N('to_date')}</strong>
              <div className="input-group input-group-transparent mt-2 mt-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                  </span>
                </div>
                <DatePicker className="form-control" dateFormat="dd/MM/yyyy" selected={filter.endDate} onChange={(event) => handleDatePicker(event, 'endDate')} showYearDropdown showMonthDropdown />
              </div>
            </MDBCol>
            <MDBCol sm="4" className="mb-2 pr-sm-2">
              <strong>{window.I18N('companies')}</strong>
              <div className="input-group input-group-transparent mt-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="filter" className="fa-fw" />
                  </span>
                </div>
                <Select value={select.companies} options={options.companies} className="md-form m-0" placeholder={window.I18N('choose_option')} onChange={(event) => handleSelect(event, 'companies')} isSearchable isMulti closeMenuOnSelect={false} />
              </div>
            </MDBCol>
            <MDBCol sm="12 text-right">
              <MDBBtn color="primary" onClick={doSearchData}>
                <MDBIcon fa="true" icon="search" className="fa-fw" /> {window.I18N('search')}
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </div>
      </div>



      <MDBCard
        className="cascading-admin-card mb-4 wow fadeIn animated"
        data-wow-delay="1s"

      >
        <MDBRow>
          <MDBCol md="2">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage
                cascade
                waves
                src={window.CONSTANT.ICON_EMPLOYEE}
                className="icon mr-1"
              />
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">
                  {count.numberEmployee}
                </h5>
                <p className="text-muted mb-0">{window.I18N('employees')}</p>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="2">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage cascade waves src={window.CONSTANT.ICON_ORGANIZATION} className="icon mr-1" />
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">{count.numberReward}</h5>
                <p className="text-muted mb-0">{window.I18N('reward')}</p>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="2">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage cascade waves src={window.CONSTANT.ICON_RESPONSE} className="icon mr-1" />
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">{count.numberActivities}</h5>
                <p className="text-muted mb-0">{window.I18N('activities')}</p>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="2" >
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage cascade waves src={window.CONSTANT.ICON_QA} className="icon mr-1" />
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">{count.numberQA}</h5>
                <p className="text-muted mb-0">Q&amp;A</p>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="2">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage
                cascade
                waves
                src={window.CONSTANT.ICON_POST}
                className="icon mr-1"
              />
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">{count.numberPosts}</h5>
                <p className="text-muted mb-0">{window.I18N('posts')}</p>
              </div>
            </div>
          </MDBCol>

          <MDBCol md="2">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage
                cascade
                waves
                src={window.CONSTANT.ICON_SURVEY}
                className="icon mr-1"
              />
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">{count.numberSurveys}</h5>
                <p className="text-muted mb-0">{window.I18N('surveys')}</p>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="2">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage
                cascade
                waves
                src={window.CONSTANT.ICON_ECOURSE}
                className="icon mr-1"
              />
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">
                  {count.numberEcourses}
                </h5>
                <p className="text-muted mb-0">{window.I18N('e_courses')}</p>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="2">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage
                cascade
                waves
                src={window.CONSTANT.ICON_GRIEVANCE}
                className="icon mr-1"
              />
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">
                  {count.numberGrievances}
                </h5>
                <p className="text-muted mb-0">{window.I18N('grievances')}</p>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="2">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage
                cascade
                waves
                src={window.CONSTANT.ICON_ASSESSMENT}
                className="icon mr-1"
              />
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">
                  {count.numberAssessments}
                </h5>
                <p className="text-muted mb-0">{window.I18N('assessments')}</p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBCard>

      <MDBCol xs="12" className="mb-3 wow fadeInRight animated" data-wow-delay="0.5s">
        <MDBCard style={{ height: '650px', overflow: 'auto' }}>
          <MDBCardHeader>
            <div className='d-flex justify-content-between align-items-center w-100'>
              <span>{window.I18N('list_organizations')}</span>
              <ExportExcel label={''} className="btn-sm m-0" data={getCompanyInfluencers(dataCompany)} isTemplate={false} dataKey="ORGANIZATION_SUMMARY_REPORT" />
            </div>
          </MDBCardHeader>
          <MDBCardBody>
            <Table id="table-company" className="table" config={config} data={dataCompany}></Table>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>

      <MDBRow className="wow fadeInUp animated" data-wow-delay="0.5s">
        <MDBCol xl="4" className="mb-3 pr-md-1">
          <MDBCard style={{ height: '350px' }}>
            <MDBCardHeader>{window.I18N('organization_activities_chart')}</MDBCardHeader>
            <MDBCardBody>
              <ListItem data={totalActivity} isListView={true} className="list-scroll" attr_content="name" attr_count="count"></ListItem>
            </MDBCardBody>
          </MDBCard>
          <MDBCard style={{ height: '310px' }}>
            <MDBCardBody>
              <Bar height={136} data={dataChartActivities} options={{ legend: { display: false }, responsive: true, maintainAspectRatio: false }}></Bar>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol xl="8" className="mb-3 pl-md-1">
          <MDBCard style={{ height: '660px' }}>
            <MDBCardHeader>
              <div className="d-flex align-items-center justify-content-between">
                <span>{window.I18N('organization_activities_summary')}</span>
                <ExportExcel label={''} className="btn-sm m-0" data={getCompanyInfluencers(dataActivity)} sumData={totalActivity} isTemplate={false} dataKey="ACTIVITIES_SUMMARY_REPORT"></ExportExcel>
              </div>
            </MDBCardHeader>
            <MDBCardBody className='overflow-auto'>
              <Table id="table-activities" className="table" config={configActivity} data={dataActivity}></Table>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol xl="6" className="mb-3 pr-md-1">
          <MDBCard style={{ height: '450px' }}>
            <MDBCardHeader>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <span>{window.I18N('organization_rating_summary')}</span>
                <ExportExcel label={window.I18N('')} data={dataRating} isTemplate={false} dataKey="ORGINAZATION_RATING_SUMMARY" />
              </div>
            </MDBCardHeader>
            <MDBCardBody>
              <ListRating data={dataRating} attr_content="name" attr_average="average" attr_total="total"></ListRating>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol xl="6" className="mb-3 pl-md-1">
          <MDBCard style={{ height: '450px' }}>
            <MDBCardHeader>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <span>{window.I18N('organization_activities_summary')}</span>
                <ExportExcel label={window.I18N('')} data={dataActivity} isTemplate={false} dataKey="ORGINAZATION_ACTIVITIES_SUMMARY" />
              </div>
            </MDBCardHeader>
            <MDBCardBody>
              <ListItem data={dataActivity} isListView={true} className="list-scroll" attr_content="name" attr_count="count"></ListItem>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol xl="6" className="mb-3 pr-md-1">
          <MDBCard style={{ height: '450px' }}>
            <MDBCardHeader>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <span>{window.I18N('organization_qa_summary')}</span>
                <ExportExcel label={window.I18N('')} data={dataQA} isTemplate={false} dataKey="ORGINAZATION_QA_SUMMARY" />
              </div>
            </MDBCardHeader>
            <MDBCardBody>
              <ListItem data={dataQA} isListView={true} className="list-scroll" attr_content="name" attr_count="count"></ListItem>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol xl="6" className="mb-3 pl-md-1">
          <MDBCard style={{ height: '450px' }}>
            <MDBCardHeader>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <span>{window.I18N('organization_answer_summary')}</span>
                <ExportExcel label={window.I18N('')} data={dataAnswerQA} isTemplate={false} dataKey="ORGINAZATION_ANSWER_SUMMARY" />
              </div>
            </MDBCardHeader>
            <MDBCardBody>
              <ListItem data={dataAnswerQA} isListView={true} className="list-scroll" attr_content="name" attr_count="count"></ListItem>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol xl="6" className="mb-3 pr-md-1">
          <MDBCard style={{ height: '450px' }}>
            <MDBCardHeader>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <span>{window.I18N('organization_reward_summary')}</span>
                <ExportExcel label={window.I18N('')} data={dataReward} isTemplate={false} dataKey="ORGINAZATION_REWARD_SUMMARY" />
              </div>
            </MDBCardHeader>
            <MDBCardBody>
              <ListItem data={dataReward} isListView={true} className="list-scroll" attr_content="name" attr_count="count"></ListItem>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol xl="6" className="mb-3 pl-md-1">
          <MDBCard style={{ height: '450px' }}>
            <MDBCardHeader>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <span>{window.I18N('organization_self_summary')}</span>
                <ExportExcel label={window.I18N('')} data={dataSelfAssessment} isTemplate={false} dataKey="ORGINAZATION_SELF_SUMMARY" />
              </div>
            </MDBCardHeader>
            <MDBCardBody>
              <ListItem data={dataSelfAssessment} isListView={true} className="list-scroll" attr_content="name" attr_count="count"></ListItem>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol
          xl="12"
          className="mb-4 wow fadeInRight animated"
          data-wow-delay="0.5s"
        >
          <HighLineChart

            title={window.I18N('engagement_activities')}
            dataLabels={dataChart}
            seriesName={[
              window.I18N('post'),
              window.I18N('like'),
              window.I18N('dislike'),
              window.I18N('comment'),
              window.I18N('share')
            ]}
            showDateFilter={true}
            onDateFilterChange={(value) => {
              getActivity(value);
              setActivityChartDay(value);
            }}
            showInterFilter={true}
          />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol
          xl="12"
          className="mb-4 wow fadeInRight animated"
          data-wow-delay="0.5s"
        >
          <HighLineChart

            title={window.I18N('survey_activities')}
            dataLabels={surveyDataChart}
            seriesName={[
              window.I18N('survey'),
              window.I18N('question'),
              window.I18N('answer'),
              window.I18N('participant')
            ]}
            showDateFilter={true}
            onDateFilterChange={(value) => {
              getSurveyDataChart(value);
              setSurveyChartDay(value);
            }}
            showInterFilter={true}
          />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol
          xl="12"
          className="mb-4 wow fadeInRight animated"
          data-wow-delay="0.5s"
        >
          <HighLineChart

            title={window.I18N('course_activities')}
            dataLabels={courseDataChart}
            seriesName={[
              window.I18N('e_courses'),
              window.I18N('enroll')
            ]}
            showDateFilter={true}
            onDateFilterChange={(value) => {
              getCourseDataChart(value);
              setCourseChartDay(value);
            }}
            showInterFilter={true}
          />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol
          xl="12"
          className="mb-4 wow fadeInRight animated"
          data-wow-delay="0.5s"
        >
          <HighLineChart

            title={window.I18N('grievance_activities')}
            dataLabels={grievanceDataChart}
            seriesName={[
              window.I18N('grievance'),
              window.I18N('investigating'),
              window.I18N('finding'),
              window.I18N('appealed'),
              window.I18N('closed')
            ]}
            showDateFilter={true}
            onDateFilterChange={(value) => {
              getGrievanceDataChart(value);
              setGrievanceChartDay(value);
            }}
            showInterFilter={true}
          />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol
          xl="12"
          className="mb-4 wow fadeInRight animated"
          data-wow-delay="0.5s"
        >
          <HighLineChart

            title={window.I18N('assessment_activities')}
            dataLabels={assessmentDataChart}
            seriesName={[
              window.I18N('assessment'),
              window.I18N('company'),
              window.I18N('assigned'),
              window.I18N('reviewed'),
              window.I18N('completed')
            ]}
            showDateFilter={true}
            onDateFilterChange={(value) => {
              getAssessmentDataChart(value);
              setAssessmentChartDay(value);
            }}
            showInterFilter={true}
          />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol xl="6" className="mb-3 pr-md-1">
          <MDBCard style={{ height: '450px' }}>
            <MDBCardHeader>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <span>{window.I18N('self_chart_week')}</span>
                <ExportExcel label={window.I18N('')} data={convertChartDataToExportExcelData(dataChartByWeek)} isTemplate={false} dataKey="SELF_CHART_WEEK" />
              </div>
            </MDBCardHeader>
            <MDBCardBody>
              <Bar height={136} data={dataChartByWeek} options={{ legend: { display: false }, responsive: true, maintainAspectRatio: false }}></Bar>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol xl="6" className="mb-3 pl-md-1">
          <MDBCard style={{ height: '450px' }}>
            <MDBCardHeader>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <span>{window.I18N('self_chart_year')}</span>
                <ExportExcel label={window.I18N('')} data={convertChartDataToExportExcelData(dataChartByYear)} isTemplate={false} dataKey="SELF_CHART_YEAR" />
              </div>
            </MDBCardHeader>
            <MDBCardBody>
              <Bar height={136} data={dataChartByYear} options={{ legend: { display: false }, responsive: true, maintainAspectRatio: false }}></Bar>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </div>
  );
}
