import React, { useState, useCallback, useLayoutEffect } from 'react';
import Select from 'react-select';
import { MDBRow, MDBCol, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';
import Panel from '../../components/Panel';
import Modal from '../../components/Modal';
import AttributeView from '../../components/AttributeView';
import ColorPicker from '../../components/ColorPicker';

import SelfAssessmentGRAPHQL from '../../../graphql/SelfAssessment';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';

export default function AttributeReportPage(props) {
  const { trackEvent } = useTracking();

  const typeOptions = [
    { value: 'LABEL', label: 'Label' },
    { value: 'TEXT', label: 'Text' },
    { value: 'EMAIL', label: window.I18N('email') },
    { value: 'NUMBER', label: 'Number' }, 
    { value: 'TEXTAREA', label: 'Textarea' }, 
    { value: 'DATE_TIME', label: 'Date Time' }, 
    { value: 'SELECT', label: 'Select' }, 
    { value: 'CHECKBOX', label: 'Checkbox' },
    { value: 'RADIO', label: 'Radio' },
    { value: 'COMPANY', label: 'Data Organization' }
  ];
  
  const [object, setObject] = useState({ index: -1, mode: window.CONSTANT.MODE_INSERT });
  const [data, setData] = useState([]);
  const [dataSave, setDataSave] = useState({ isBold: false });
  const [select, setSelect] = useState({ type: { value: 'LABEL', label: 'Label' }, question: null });

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataAttributeReport();
    }, 100);
    // eslint-disable-next-line
  }, []);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleChange = (event) => {
    if (event.target.className.indexOf('input-number') > -1) {
      dataSave[event.target.name] = parseInt(event.target.value);
    } else if (event.target.type ==='checkbox') {
      dataSave[event.target.name] = event.target.checked;
    } else {
      dataSave[event.target.name] = event.target.value;
    }
    setDataSave({ ...dataSave });
  };

  const handleSelect = (event, id, attr) => {
    window.COMMON.checkSelectValidation(id, event);
    select[attr] = event;
    setSelect({ ...select });
  };

  const handleColor = (color) => {
    dataSave.color = color;
    setDataSave({ ...dataSave });
  };

  const getDataAttributeReport = async () => {
    try {
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_ATTRIBUTE_REPORT, null);
      if (result !=null) {
        let index = 0;
        result.data.getAttributeReports.forEach(item => {
          item.idx = index;
          index++;
        });
        setData(result.data.getAttributeReports);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('AttributeReportPage.getDataAttributeReport');
    }
  };

  const openAttributeReportDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-attribute-report');
    object.index = index;
    object.mode = window.CONSTANT.MODE_INSERT;
    let parentCode = '-1';
    if (index !== -1) {
      parentCode = data[index].code;
    }
    const dataSave = {};
    dataSave.parentCode = parentCode;
    dataSave.code = window.COMMON.generateCode('AR');
    dataSave.attribute = '';
    dataSave.indexText = '';
    dataSave.title = '';
    dataSave.value = '';
    dataSave.unit = '';
    dataSave.isBold = false;
    dataSave.index = 0;
    dataSave.color = '#000000';
    select.type = { value: 'LABEL', label: 'Label' };
    select.question = null;
    setObject(object);
    setSelect(select);
    setDataSave({ ...dataSave });
  }, [object, select, data]);

  const openEditAttributeReportDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-attribute-report');
    object.index = index;
    object.mode = window.CONSTANT.MODE_UPDATE;
    const obj = data[index];
    const dataSave = {};
    dataSave.parentCode = window.COMMON.getValueFromAttr(obj, 'parentCode');
    dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
    dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
    dataSave.attribute = window.COMMON.getValueFromAttr(obj, 'attribute');
    dataSave.indexText = window.COMMON.getValueFromAttr(obj, 'indexText');
    dataSave.title = window.COMMON.getValueFromAttr(obj, 'title');
    dataSave.value = window.COMMON.getValueFromAttr(obj, 'value');
    dataSave.unit = window.COMMON.getValueFromAttr(obj, 'unit');
    dataSave.isBold = window.COMMON.getValueFromAttr(obj, 'isBold');
    dataSave.index = window.COMMON.getValueFromAttr(obj, 'index');
    dataSave.color = window.COMMON.getValueFromAttr(obj, 'color');
    select.type = window.COMMON.setDataSelect(typeOptions, window.COMMON.getValueFromAttr(obj, 'type'));
    setObject(object);
    setSelect(select);
    setDataSave({ ...dataSave });
  }, [object, select, data, typeOptions]);

  // eslint-disable-next-line
  const openDeleteAttributeReportDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteAttributeReport;
  });

  const saveAttributeReport = async (event) => {
    try {
      if (!window.COMMON.checkFormValidation('form', event)) {
        return;
      }
      const params = {
        input: dataSave
      };
      window.COMMON.trimData(params);
      params.input.type = window.COMMON.getDataSelect(select.type, false);
      params.input.question = select.type.value === 'LABEL' ? null : window.COMMON.getDataSelect(select.question, false);
      params.input.value = window.COMMON.parseJSONToString(params.input.value);
      if (object.mode === window.CONSTANT.MODE_INSERT) {
        const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_ADD_ATTRIBUTE_REPORT, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataAttributeReport();
		  createTrackingEvent(eventName.ADD_ATTRIBUTE_REPORT);
        }
      } else {
        const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_EDIT_ATTRIBUTE_REPORT, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataAttributeReport();
		  createTrackingEvent(eventName.EDIT_ATTRIBUTE_REPORT);
        }
      }
      window.COMMON.hideModal('#modal-attribute-report');
    } catch (error) {
      window.COMMON.showErrorLogs('AttributeReportPage.saveAttributeReport');
    }
  };

  const deleteAttributeReport = async () => {
    try {
      const params = {
        id: data[object.index]._id
      };
      const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_DEL_ATTRIBUTE_REPORT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataAttributeReport();
        createTrackingEvent(eventName.DEL_ATTRIBUTE_REPORT);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('AttributeReportPage.deleteAttributeReport');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" icon="list-alt">
        <div className="container-btn wow fadeInDown animated" data-wow-delay="0.5s">
          <MDBBtn color="primary" className="mr-auto" onClick= {() => openAttributeReportDialog(-1)}>
            <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
          </MDBBtn>
        </div>
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <AttributeView data={data} handleAdd={openAttributeReportDialog} handleEdit={openEditAttributeReportDialog} handleDelete={openDeleteAttributeReportDialog}></AttributeView>
        </div>
      </Panel>

      <Modal id="modal-attribute-report" className="modal-xl" isNotScroll= {true} title={window.I18N('create_update_data')} saveEvent= {saveAttributeReport}>
        <form id="form" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol md="6">
              <MDBInput outline containerClass="mt-0" type="text" label={window.I18N('parent_code') + ' *'} name="parentCode" value= {dataSave.parentCode} onChange= {handleChange} maxLength="50" pattern="\S(.*\S)?" disabled></MDBInput>
              <MDBInput outline type="text" label={window.I18N('code') + ' *'} name="code" value= {dataSave.code} onChange= {handleChange} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="text" label={window.I18N('attribute_name') + ' *'} name="attribute" value= {dataSave.attribute} onChange= {handleChange} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="number" className="input-number" label={window.I18N('index') + ' *'} name="index" value= {dataSave.index} onChange= {handleChange} maxLength="10" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline containerClass="p-0 d-inline-block mr-3 mt-0 mb-3" type="checkbox" id="ck-is-bold" name="isBold" label={window.I18N('is_bold')} filled checked= {dataSave.isBold} onChange= {handleChange}></MDBInput>
              <ColorPicker handleChange= {handleColor} color= {dataSave.color}></ColorPicker>
            </MDBCol>
            <MDBCol md="6">
              <MDBInput outline containerClass="mt-0" type="text" label={window.I18N('index')} name="indexText" value= {dataSave.indexText} onChange= {handleChange} maxLength="20" pattern="\S(.*\S)?"></MDBInput>
              <MDBInput outline type="text" label={window.I18N('display_text') + ' *'} name="title" value= {dataSave.title} onChange= {handleChange} maxLength="500" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="text" label={window.I18N('value')} name="value" value= {dataSave.value} onChange= {handleChange} maxLength="5000" pattern="\S(.*\S)?"></MDBInput>
              <MDBInput outline type="text" label={window.I18N('unit')} name="unit" value= {dataSave.unit} onChange= {handleChange} maxLength="50" pattern="\S(.*\S)?"></MDBInput>
              <Select id="select-attribute-type" className="md-form" value= {select.type} options= {typeOptions} placeholder={window.I18N('type')} onChange= {(event) => handleSelect(event, 'select-attribute-type', 'type')} isSearchable/>
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>
    </div>
  );
}