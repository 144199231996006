import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { MDBCol, MDBRow } from 'mdbreact'
import EventSearch from './components/EventSearch'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import EventList from './components/EventList'
import EventForm from './components/EventForm'
import EventMenu from './components/EventMenu'
import RecommendEventItem from './components/RecommendEventItem'
import RecommendEventItemLoading from './components/Loading/RecommendEventItemLoading'

const DISCOVERY_TYPE = 'DISCOVERY_TYPE'
const MY_EVENT_TYPE = 'MY_EVENT_TYPE'

const ROLE_OWNER = 'OWNER'
const ROLE_MEMBER = 'MEMBER'

const EventPage = ({ language }) => {
    const MENU = [
        {
            title: 'home_page',
            icon: 'table',
            active: true,
            type: DISCOVERY_TYPE
        },
        {
            title: 'my_event',
            icon: 'user',
            active: false,
            type: MY_EVENT_TYPE,
            children: [
                {
                    title: 'has_join',
                    icon: 'star',
                    active: false,
                    type: ROLE_MEMBER
                },
                {
                    title: 'organize',
                    icon: 'calendar',
                    active: false,
                    type: ROLE_OWNER
                }
            ]
        }
    ]

    const [menu, setMenu] = useState(MENU)
    const [typeListEvent, setTypeListEvent] = useState({ type: DISCOVERY_TYPE, role: null })
    const [listRecommendEvents, setListRecommendEvents] = useState(null)
    const createEventFormRef = useRef()
    const eventListRef = useRef(null)

    const handleChangeMenu = (indexClick, indexChildrenClick = null) => {
        const updateMenu = menu.map((parent, indexParent) => {
            if (parent.children) {
                const updateChildrenMenu = parent.children.map((child, indexChild) => ({
                    ...child,
                    active: indexChildrenClick !== null ? indexChild === indexChildrenClick : false
                }))
                return {
                    ...parent,
                    active: indexParent === indexClick,
                    children: updateChildrenMenu
                }
            }
            return { ...parent, active: indexParent === indexClick }
        })

        const typeList = updateMenu[indexClick].children
            ? {
                  type: updateMenu[indexClick].type,
                  role: updateMenu[indexClick].children[indexChildrenClick]?.type ?? null
              }
            : { type: updateMenu[indexClick].type, role: null }
        setTypeListEvent(typeList)

        setMenu(updateMenu)
        eventListRef.current.resetDataWhenChangeMenu()
    }

    const openCreateEventPopup = () => {
        window.COMMON.showModal('#modal-create-event')
    }

    const closeCreateEventPopup = () => {
        window.COMMON.hideModal('#modal-create-event')
    }

    const handleCloseCreateEventPopup = () => {
        window.COMMON.showModal('#modal-confirm')
        window.confirmMethod = () => {
            createEventFormRef.current.resetForm()
            closeCreateEventPopup()
        }
        window.closeConfirmMethod = () => {
            openCreateEventPopup()
        }
    }

    const createEventFanpage = () => {
        createEventFormRef.current.submitForm('create', () => {
            closeCreateEventPopup()
            eventListRef.current.refetchListEvents()
        })
    }

    const handleCreateEvent = () => {
        const isValidForm = createEventFormRef.current.validateForm()
        if (isValidForm) {
            window.COMMON.showModal('#modal-confirm')
            window.confirmMethod = createEventFanpage
        }
    }

    return (
        <>
            <MDBRow>
                <MDBCol md="4">
                    <div
                        className="p-3 bg-white rounded-lg"
                        style={{ width: '25%', height: '90vh', position: 'fixed', top: '80px' }}
                    >
                        <h6 className="font-weight-bold mb-2" style={{ fontSize: '1.2rem' }}>
                            {window.I18N('event')}
                        </h6>
                        {/* Search */}
                        <EventSearch />
                        {/* ================================================================================================== */}
                        {/* Menu */}
                        <div
                            className="hide-scrollbar mt-3 border-top"
                            style={{
                                maxHeight: '85%',
                                overflow: 'overlay',
                                overscrollBehavior: 'contain'
                            }}
                        >
                            <div className="mt-2">
                                <EventMenu data={menu} onChange={handleChangeMenu} />
                            </div>
                            {/* ================================================================================================== */}
                            {/* Create new event button */}
                            <div className="w-full mt-3">
                                <Button variant="standard" iconLeft="plus" onClick={openCreateEventPopup}>
                                    <span style={{ fontSize: '15px', fontWeight: 500 }}>
                                        {window.I18N('create_event')}
                                    </span>
                                </Button>
                            </div>

                            {/* Event Recommend */}
                            <div className="mt-3 border-top">
                                <h6 className="font-weight-bold my-2" style={{ fontSize: '1.2rem' }}>
                                    {window.I18N('event_coming_soon')}
                                </h6>

                                <>
                                    {listRecommendEvents === null ? (
                                        <div className="mt-3 w-100 flex-center">
                                            <div className="d-flex align-items-center flex-column justify-content-center">
                                                <span
                                                    style={{ fontSize: '0.8rem', fontWeight: 400, fontStyle: 'italic' }}
                                                >
                                                    {window.I18N('none_event')}
                                                </span>
                                            </div>
                                        </div>
                                    ) : window.COMMON.isEmpty(listRecommendEvents) ? (
                                        <div className="mt-2">
                                            {Array.from({ length: 5 }, (_, i) => i).map((item) => (
                                                <RecommendEventItemLoading key={item} />
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="mt-2">
                                            {listRecommendEvents.map((item, index) => (
                                                <RecommendEventItem
                                                    data={item}
                                                    key={index}
                                                    onClickDetail={eventListRef.current.redirectToDetailEvent}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </>
                            </div>
                        </div>
                    </div>
                </MDBCol>
                <MDBCol className="pl-0" md="8">
                    <div className="rounded-lg">
                        <EventList
                            listType={typeListEvent}
                            ref={eventListRef}
                            onGetListRecommendEvent={setListRecommendEvents}
                        />
                    </div>
                </MDBCol>
            </MDBRow>
            {/* ================================================================================================== */}
            {/* Create event form */}
            <Modal
                id="modal-create-event"
                title={window.I18N('create_event')}
                icon="plus"
                saveBtn={window.I18N('create_event')}
                saveBtnProps={{
                    style: {
                        width: '100%'
                    },
                    outline: false
                }}
                saveEvent={handleCreateEvent}
                styleBody={{
                    padding: '0px',
                    overflow: 'overlay',
                    overscollBehavior: 'contain'
                }}
                handleCloseModal={handleCloseCreateEventPopup}
            >
                <EventForm ref={createEventFormRef} language={language.code} />
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => ({
    language: state.language.value
})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(EventPage)
