import React from 'react';
import track from 'react-tracking';
import TrackingGRAPHQL from '../graphql/Tracking';

const generateSession = () => {
  return `GY-${Date.now()}`;
};

const clearLocalTracking = async () => {
  const trackingBody = localStorage.getItem('tracking');
  if (trackingBody) {
    try {
      await window.COMMON.mutation(TrackingGRAPHQL.MUTATION_CREATE_TRACKING, JSON.parse(trackingBody));
    } catch (error) {
      console.log(error);
    }
    localStorage.removeItem('tracking');
  }
};

export const startSession = () => {
  if (sessionStorage) {
    const currentSession = sessionStorage.getItem('session');
    if (!currentSession) {
      clearLocalTracking();
      sessionStorage.setItem('session', generateSession());
    }
  }
};

export const endSession = () => {
  clearLocalTracking();
  return sessionStorage.clear();
};

const timeoutStackTracking = 3000; // 3s
const defaultTrackingTimeout = 5 * 60 * 1000; // 5m
class TrackWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.trackingIntervalRef = React.createRef();
    this.saveTrackingIntervalRef = React.createRef();
    this.state = {
      cache: null
    };
  }

  stackTracking = () => {
    const currentSession = sessionStorage.getItem('session');
    const dataLayer = JSON.parse(sessionStorage.getItem('dataLayer') || '[]');
    if (!currentSession || !dataLayer?.length) {
      return;
    }

    const trackingBody = {
      session: currentSession,
      events: dataLayer
    };

    if (
      JSON.stringify(trackingBody) === JSON.stringify(this.state.cache || {})
    ) {
      return;
    } else {
      localStorage.setItem('tracking', JSON.stringify(trackingBody));
      this.setState({ cache: trackingBody });
    }
  };

  saveTracking = async () => {
    const trackingBody = localStorage.getItem('tracking');
    if (!trackingBody) {
      return;
    }
    try {
      await window.COMMON.mutation(TrackingGRAPHQL.MUTATION_CREATE_TRACKING, JSON.parse(trackingBody));
	  localStorage.removeItem('tracking');
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    startSession();
    this.trackingIntervalRef.current = setInterval(
      this.stackTracking,
      timeoutStackTracking
    );
    this.saveTrackingIntervalRef.current = setInterval(
      this.saveTracking,
      parseInt(defaultTrackingTimeout)
    );
  }

  componentWillUnmount() {
    clearInterval(this.trackingRef?.current);
    clearInterval(this.saveTrackingIntervalRef?.current);
  }

  render() {
    return this.props.children;
  }
}

const AppTracking = track(
  {},
  {
    dispatch: (eventData) => {
      const sessionDataLayer = sessionStorage.getItem('dataLayer');

      let dataLayer = JSON.parse(sessionDataLayer || '[]');

	  dataLayer = [...dataLayer, eventData];

      sessionStorage.setItem('dataLayer', JSON.stringify(dataLayer));
    }
  }
)((TrackWrapper));

export default AppTracking;
