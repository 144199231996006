import React, { useState, useLayoutEffect } from 'react';
import { MDBCol, MDBRow, MDBBtn, MDBIcon } from 'mdbreact';
import { Switch } from '@material-ui/core';


const ListAccount = React.memo(function ListAccount(props) {
  const [checked, setChecked] = useState({});
  const [data, setData] = useState([]);

  useLayoutEffect(() => {
    setChecked({});
    setData(props.data);
    if (props.dataSelected && props.dataSelected.length > 0) {
      const checked = {};
      props.dataSelected.forEach(item => {
        checked[item] = true;
      });
      setChecked(checked);
    }
  }, [props.data, props.dataSelected]);

  const handleItemClick = (event, item) => {
    event.stopPropagation();
    if (props.handleItemClick !== undefined) {
      props.handleItemClick(item);
    }
  };

  const handleChange = (event, id) => {
    event.stopPropagation();
    checked[id] = !checked[id];
    setChecked({ ...checked });
    if (props.handleCheckChange) {
      props.handleCheckChange(checked);
    }
  };

  const handleAccept = (event, index) => {
    event.stopPropagation();
    if (props.handleAccept) {
      props.handleAccept(index);
    }
  };

  const handleReject = (event, index) => {
    event.stopPropagation();
    if (props.handleReject) {
      props.handleReject(index);
    }
  };

  let html = '';
  if (props.isView) {
    html = <MDBCol sm="12" className="cursor-pointer">
      <div className="card m-0 wow fadeInUp animated" onClick={(event) => handleItemClick(event, props.item)}>
        <div className="container-image-blur">
          <img src={'https://minimal-assets-api.vercel.app/assets/images/covers/cover_1.jpg'} alt=""></img>
        </div>
        <div className="container-icon-blur text-center">
          <img src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(props.item, 'avatar'))} alt=""></img>
        </div>
        <div className="card-body">
          <h5 className="card-title font-weight-bold text-center mb-2">{window.COMMON.getValueFromAttr(props.item, 'name')}</h5>
          <p className="card-text text-center mb-1">
            <MDBIcon fa="true" icon="envelope" className="fa-fw mr-1"></MDBIcon>
            {window.COMMON.getValueFromAttr(props.item, 'email')}
          </p>
          <p className="card-text text-center mb-1">
            <MDBIcon fa="true" icon="phone" className="fa-fw mr-1"></MDBIcon>
            {window.COMMON.getValueFromAttr(props.item, 'phone')}
          </p>
          <p className="card-text text-center mb-1">
            <MDBIcon fa="true" icon="home" className="fa-fw mr-1"></MDBIcon>
            {window.COMMON.getValueFromAttr(props.item, 'address')}
          </p>
          <hr></hr>
          <div className="d-flex align-items-center">
            <p className="card-text m-0 mr-auto">
              <small className="text-muted">{window.I18N('start_date')}: {window.COMMON.getValueFromAttr(props.item, 'createdDate')}</small>
            </p>
          </div>
        </div>
      </div>
    </MDBCol>;
  } else if (props.isCompany) {
    html = data.map((item, i) => 
      <MDBCol sm="6" md="6" lg="4" key={i} className="cursor-pointer">
        <div className="card mt-3 wow fadeInUp animated" onClick={(event) => handleItemClick(event, item)}>
          <div className="container-image-blur">
            <img src={`https://minimal-assets-api.vercel.app/assets/images/covers/cover_${i + 1}.jpg`} alt=""></img>
          </div>
          <div className="container-icon-blur text-center">
            <img src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, 'avatar'))} alt=""></img>
          </div>
          <div className="card-body">
            <h5 className="card-title font-weight-bold text-center mb-2">{window.COMMON.getValueFromAttr(item, 'name')}</h5>
            <p className="card-text text-center mb-1">
              <MDBIcon fa="true" icon="envelope" className="fa-fw mr-1"></MDBIcon>
              {window.COMMON.getValueFromAttr(item, 'email')}
            </p>
            <p className="card-text text-center mb-1">
              <MDBIcon fa="true" icon="phone" className="fa-fw mr-1"></MDBIcon>
              {window.COMMON.getValueFromAttr(item, 'phone')}
            </p>
            <p className="card-text text-center mb-1">
              <MDBIcon fa="true" icon="home" className="fa-fw mr-1"></MDBIcon>
              {window.COMMON.getValueFromAttr(item, 'address')}
            </p>
            <hr></hr>
            <div className="d-flex align-items-center">
              <p className="card-text m-0 mr-auto">
                <small className="text-muted">{window.I18N('request_date')}: {window.COMMON.getValueFromAttr(item, 'createdDate')}</small>
              </p>
              <Switch checked={checked[item._id] || false} onClick={(event) => handleChange(event, item._id)} inputProps={{ 'aria-label': 'controlled' }}/>
            </div>
          </div>
        </div>
      </MDBCol>
    );
  } else if (props.isInfluencer) {
    html = data.map((item, i) => 
      <MDBCol sm="6" md="6" lg="4" key={i} className="cursor-pointer">
        <div className="card mt-3 wow fadeInUp animated" onClick={(event) => handleItemClick(event, item)}>
          <div className="container-image-blur">
            <img src={`https://minimal-assets-api.vercel.app/assets/images/covers/cover_${i + 1}.jpg`} alt=""></img>
          </div>
          <div className="container-icon-blur text-center">
            <img src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, 'avatar'))} alt=""></img>
          </div>
          <div className="card-body">
            <h5 className="card-title font-weight-bold text-center mb-2">{window.COMMON.getValueFromAttr(item, 'name')}</h5>
            <p className="card-text text-center mb-1">
              <MDBIcon fa="true" icon="envelope" className="fa-fw mr-1"></MDBIcon>
              {window.COMMON.getValueFromAttr(item, 'email')}
            </p>
            <p className="card-text text-center mb-1">
              <MDBIcon fa="true" icon="phone" className="fa-fw mr-1"></MDBIcon>
              {window.COMMON.getValueFromAttr(item, 'phone')}
            </p>
            <p className="card-text text-center mb-1">
              <MDBIcon fa="true" icon="home" className="fa-fw mr-1"></MDBIcon>
              {window.COMMON.getValueFromAttr(item, 'address')}
            </p>
            <hr></hr>
            <div className="d-flex align-items-center">
              <p className="card-text m-0 mr-auto">
                <small className="text-muted">{window.I18N('start_date')}: {window.COMMON.getValueFromAttr(item, 'createdDate')}</small>
              </p>
              {
                window.COMMON.getValueFromAttr(item, 'status') === 'PENDING' && !item.isAllowCancel && item.isInfluencer ? <>
                  <MDBBtn className="btn btn-sm mt-0 mb-0 btn-row" type="button" color="warning" onClick= {(event) => handleReject(event, i)}>
                    <MDBIcon fa="true" icon="times" className="fa-fw"></MDBIcon>
                  </MDBBtn>
                  <MDBBtn className="btn btn-sm mt-0 mb-0 mr-0 btn-row" type="button" color="success" onClick= {(event) => handleAccept(event, i)}>
                    <MDBIcon fa="true" icon="check" className="fa-fw"></MDBIcon>
                  </MDBBtn>
                </>
                  : window.COMMON.getValueFromAttr(item, 'status') === 'PENDING' && item.isAllowCancel ?
                    <MDBBtn className="btn btn-sm mt-0 mb-0 mr-0 btn-row" type="button" color="warning" onClick= {(event) => handleReject(event, i)}>
                      <MDBIcon fa="true" icon="ban" className="fa-fw"></MDBIcon> {window.I18N('cancel')}
                    </MDBBtn> 
                  : window.COMMON.getValueFromAttr(item, 'status') === 'PENDING' && !item.isAllowCancel 
                    ? <MDBBtn className="btn btn-sm mt-0 mb-0 mr-0 btn-row" type="button" disabled={true} color="info">
                      <MDBIcon fa="true" icon="paper-plane" className="fa-fw"></MDBIcon> {window.I18N('requested')}
                    </MDBBtn>
                  : window.COMMON.getValueFromAttr(item, 'status') === 'ACCEPTED' 
                    ? <MDBBtn className="btn btn-sm mt-0 mb-0 mr-0 btn-row" type="button" color="danger" onClick= {(event) => handleReject(event, i)}>
                      <MDBIcon fa="true" icon="trash-alt" className="fa-fw"></MDBIcon> {window.I18N('delete')}
                    </MDBBtn>
                    : <MDBBtn className="btn btn-sm mt-0 mb-0 mr-0 btn-row" type="button" color="primary" onClick= {(event) => handleAccept(event, i)}>
                      <MDBIcon fa="true" icon="paper-plane" className="fa-fw"></MDBIcon> {window.I18N('request')}
                    </MDBBtn>
              }
            </div>
          </div>
        </div>
      </MDBCol>
    );
  } else {
    html = data.map((item, i) => 
      <MDBCol sm="6" md="6" lg="4" key={i} className="cursor-pointer">
        <div className="card mt-3 wow fadeInUp animated" onClick={(event) => handleItemClick(event, item)}>
          <div className="container-image-blur">
            <img src={`https://minimal-assets-api.vercel.app/assets/images/covers/cover_${i + 1}.jpg`} alt=""></img>
          </div>
          <div className="container-icon-blur text-center">
            <img src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, 'avatar'))} alt=""></img>
          </div>
          <div className="card-body">
            <h5 className="card-title font-weight-bold text-center mb-2">{window.COMMON.getValueFromAttr(item, 'name')}</h5>
            <p className="card-text text-center mb-1">
              <MDBIcon fa="true" icon="envelope" className="fa-fw mr-1"></MDBIcon>
              {window.COMMON.getValueFromAttr(item, 'nickname')}
            </p>
            <p className="card-text text-center mb-1">
              <MDBIcon fa="true" icon="transgender-alt" className="fa-fw mr-1"></MDBIcon>
              {window.COMMON.getValueFromAttr(item, 'gender') === 0 ? window.I18N('female') : window.I18N('male')}
            </p>
            <p className="card-text text-center mb-1">
              <MDBIcon fa="true" icon="birthday-cake" className="fa-fw mr-1"></MDBIcon>
              {window.COMMON.getValueFromAttr(item, 'birthday')}
            </p>
            <hr></hr>
            <div className="d-flex align-items-center">
              <p className="card-text m-0 mr-auto">
                <small className="text-muted">{window.I18N('request_date')}: {window.COMMON.getValueFromAttr(item, 'createdDate')}</small>
              </p>
              {
                props.type === 'FRIEND' ? <>
                  <MDBBtn className="btn btn-sm mt-0 mb-0 btn-row" type="button" color="warning" onClick= {(event) => handleReject(event, i)}>
                    <MDBIcon fa="true" icon="times" className="fa-fw"></MDBIcon>
                  </MDBBtn>
                  <MDBBtn className="btn btn-sm mt-0 mb-0 mr-0 btn-row" type="button" color="success" onClick= {(event) => handleAccept(event, i)}>
                    <MDBIcon fa="true" icon="check" className="fa-fw"></MDBIcon>
                  </MDBBtn>
                </> : <Switch checked={checked[item._id] || false} onClick={(event) => handleChange(event, item._id)} inputProps={{ 'aria-label': 'controlled' }}/>
              }
            </div>
          </div>
        </div>
      </MDBCol>
    );
  }

  return (
    <div className={'container-list-account ' + window.COMMON.getValue(props.className)}>
      <MDBRow>
        {html}
      </MDBRow>      
    </div>
  );
});

export default ListAccount;