import React, { useState, useLayoutEffect } from 'react';

import AddAccount from '../../components/AddAccount';
import AddCompany from '../../components/AddCompany';

import MetadataGRAPHQL from '../../../graphql/Metadata';
import RoleGRAPHQL from '../../../graphql/Role';
import AccountGRAPHQL from '../../../graphql/Account';

export default function MyProfilePage(props) {
  const ACCOUNT_TYPE = localStorage.getItem(window.CONSTANT.ACCOUNT_TYPE);
  const [object, setObject] = useState({ index: window.CONSTANT.VALUE_PROFILE, item: null, isOrganizationRole: false });
  const [options, setOptions] = useState({
    companyType: [],
    companySize: [],
    companyIndustry: [],
    nation: [],
    city: [],
    state: [],
    role: []
  });

  useLayoutEffect(() => {
    setTimeout(async () => {
      await getDataMetadata();
      await getDataLocationFilterOptions('-1', '-1');
      await getDataRole();
      await getPackageData();
      await getMyAccount();
    }, 100);
    // eslint-disable-next-line
  }, []);

  // COMMON
  const getDataMetadata = async () => {
    try {
      const params = {
        type: ['COMPANY_TYPE', 'COMPANY_SIZE', 'COMPANY_INDUSTRY', 'INDIVIDUAL_EDUCATION_BACKGROUND', 'INDIVIDUAL_INCOME']
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_SHORT_METADATA, params, false);
      if (result && result.data) {
        const data = result.data.getMetadatas;
        const dataType = window.COMMON.getArrsInArrs('COMPANY_TYPE', data, 'type');
        const dataSize = window.COMMON.getArrsInArrs('COMPANY_SIZE', data, 'type');
        const dataIndustry = window.COMMON.getArrsInArrs('COMPANY_INDUSTRY', data, 'type');
        const dataEducationBackground = window.COMMON.getArrsInArrs('INDIVIDUAL_EDUCATION_BACKGROUND', data, 'type');
        const dataIncome = window.COMMON.getArrsInArrs('INDIVIDUAL_INCOME', data, 'type');
        options.companyType = window.COMMON.createDataSelect(dataType, '_id', 'value');
        options.companySize = window.COMMON.createDataSelect(dataSize, '_id', 'value');
        options.companyIndustry = window.COMMON.createDataSelect(dataIndustry, '_id', 'value');
        options.educationBackground = window.COMMON.createDataSelect(dataEducationBackground, '_id', 'value');
        options.income = window.COMMON.createDataSelect(dataIncome, '_id', 'value');
        setOptions({ ...options });
      }
    } catch (error) {
      window.COMMON.showErrorLogs('ComapnyPage.getDataMetadata');
    }
  };

  const getPackageData = async () => {
    try {
      const resCompanyPackages = await window.COMMON.query(RoleGRAPHQL.QUERY_PACKAGE, { filter: { role: 'COMPANY' } });
      const resOrganizationPackages = await window.COMMON.query(RoleGRAPHQL.QUERY_PACKAGE, { filter: { role: 'INFLUENCER' } });
      if (resCompanyPackages?.data.queryPackage) {
        options.companyPackages = window.COMMON.createDataSelect(resCompanyPackages.data.queryPackage, '_id', 'name','default');
      }
      if (resOrganizationPackages?.data.queryPackage) {
        options.organizationPackages = window.COMMON.createDataSelect(resOrganizationPackages.data.queryPackage, '_id', 'name','default');
      }
      setOptions({ ...options });
    } catch (err) {
      window.COMMON.showErrorLogs('ComapnyPage.getPackageData');
    }
  };

  const getDataLocationFilterOptions = async (attr, parent) => {
    options.nation = await window.COMMON.getDataLocation(parent);
    setOptions({ ...options });
  };

  const getDataRole = async () => {
    try {
      const params = {
        company: null
      };
      const result = await window.COMMON.query(RoleGRAPHQL.QUERY_ROLE, params, false);
      if (result && result.data) {
        options.role = window.COMMON.createDataSelect(result.data.getRoles, '_id', 'name', 'code');
      }
      setOptions({ ...options });
    } catch (error) {
      window.COMMON.showErrorLogs('ComapnyPage.getDataRole');
    }
  };

  const getMyAccount = async () => {
    try {
      const result = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_INFO, null);
      if (result && result.data) {
        const data = result.data.getAccountInfo;
        data.birthday = window.COMMON_DATE.formatDate(data.birthday)
        data.establishedDate = window.COMMON_DATE.formatDate(data.establishedDate)
        object.item = data;
        object.isOrganizationRole = ACCOUNT_TYPE === window.CONSTANT.COMPANY || ACCOUNT_TYPE === window.CONSTANT.UNION || ACCOUNT_TYPE === window.CONSTANT.INFLUENCER;
        setObject({ ...object });
      }
      window.COMMON.showContainerSave();
    } catch (error) {
      window.COMMON.showErrorLogs('MyProfilePage.getMyAccount');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      {
        object.isOrganizationRole ? <AddCompany options={options} index={object.index} item={object.item} role={{ code: ACCOUNT_TYPE }} isOrganizationRole={false} isProfile={true}></AddCompany> :
          <AddAccount options={options} index={object.index} item={object.item} role={{ code: ACCOUNT_TYPE }} isOrganizationRole={false} callback={getMyAccount}></AddAccount>
      }
    </div>
  );
}