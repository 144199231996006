import { Box, Button, Card, CardContent, CardMedia, Grid, MenuItem, Typography, Popover } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { MDBCardImage } from 'mdbreact';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';


const EventFanPageCard = ({event, openModalRelationShip, isExpired}) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
	const [anchorElSetting, setAnchorElSetting] = useState({originVertical: "bottom", transformVertical: "top"})
  const [indexImg, setIndexImg] = useState(0)

  const onClickDetail = (event) => {
		let space = window.innerHeight + window.scrollY- event.currentTarget.offsetTop
		setAnchorEl(event.currentTarget);
		if (space > 180) {
			setAnchorElSetting({originVertical: "bottom", transformVertical: "top"})
		} else {
			setAnchorElSetting({originVertical: "top", transformVertical: "bottom"})
		}
	}

  const handleClose = () => {
		setAnchorEl(null);
	};

  const onClickBtnLeave = () => {
    openModalRelationShip(event._id, "LEAVE")
    setAnchorEl(null);
  }

  const onClickBtnJoin = ()=>{
    openModalRelationShip(event._id, "JOIN")
  }

  useEffect(() => {
    const imagesLength = event.images?.length
    setIndexImg(0)
    const timer = setInterval(() => {
      setIndexImg(prev=>(prev+1)%imagesLength)
    }, 3000);
    return () => {
      clearInterval(timer);
    };
  }, [event?.images?.length]);
  
  return (
    <Card className='d-flex' style={{ marginBottom: '16px', height: "200px" }}>
      <CardMedia
        className='my-auto ml-3 mr-1 rounded'
        style={{ width: "164px", height:"164px" }}
        image={event?.images[indexImg]}
        title={event.name}
      />
      <CardContent className='d-flex flex-column justify-content-between flex-1'>
        <Typography variant="subtitle2" color={isExpired?'textSecondary':'error'}>
          {event.eventDate}
        </Typography>
        <Typography  className='overflow-hidden line-clamp-1' variant="h6">
          {event.name}
        </Typography>
        <Typography className='text-capitalize' variant="subtitle1">
            {window.I18N('joined')+ ': ' + event.countMember+'/'+event.limitMember}
          </Typography>
        <div className="d-flex align-items-center">
          <span className='navbar-nav'>
            <MDBCardImage cascade waves src={window.COMMON.setValueImage(event.createdBy.avatar)} className="icon-avatar"/>
            </span>
          <Typography variant="body2">
            {window.I18N('created_by')+ ': ' + event.createdBy.name}
          </Typography>
        </div>
        <Grid container className="mx-8 w-full justify-content-end">
        {!isExpired&&<Button disabled={event.isJoined||(event.countMember>=event.limitMember)} onClick={event.isJoined||(event.countMember>=event.limitMember)?()=>{}:onClickBtnJoin} variant="contained" color="primary" className='text-capitalize mr-2'>
          {event.isJoined?window.I18N('joined'):window.I18N('join')}
        </Button>}
        <Button variant="outlined" onClick={(event) => onClickDetail(event)}>
          <MoreHoriz color="disabled" />
        </Button>
        </Grid>
        
      </CardContent>
      <Popover keepMounted
        anchorOrigin={{horizontal:"left", vertical: anchorElSetting.originVertical }}
        transformOrigin={{horizontal:"left", vertical: anchorElSetting.transformVertical }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{ style: { overflow: "inherit"}}}
      >
        <span style={{filter: "drop-shadow(0px 0px 10px black)", left: "15px",position: "absolute", [anchorElSetting.transformVertical]: -7,}}>
          <Box
            style={{
              backgroundColor: "white",
              display: "block",
              width: 15,
              height: 15,
              transform: "rotate(45deg)",
              clipPath: `polygon(${anchorElSetting.transformVertical==='top'?'0 0':'100% 100%'}, 100% 0%, 0 100%)`,
            }}
          />
        </span>
        {event.isJoined&&event.eventRole!=="OWNER"&&!isExpired&&<MenuItem  onClick={onClickBtnLeave} className='action-member-div' >{window.I18N("leave") }</MenuItem>}
        <MenuItem onClick={()=>{history.push(`/event?eventId=${event._id}`)}} className='action-member-div'>{window.I18N("infomation") }</MenuItem>
      </Popover>
    </Card>
    );
}

export default EventFanPageCard