import React, { useState, useCallback, useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { MDBRow, MDBCol, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';

import Panel from '../../components/Panel';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import RadioBox from '../../components/RadioBox';

import HelpCenterGRAPHQL from '../../../graphql/HelpCenter';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';

function HelpCenter(props) {
  const { trackEvent } = useTracking();

  const config = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('code'), '10%', '', '', 'code', 'TEXT'),
    window.COMMON.createCol(window.I18N('name'), '20%', '', '', 'nameLanguage', 'TEXT'),
    window.COMMON.createCol(window.I18N('index'), '10%', '', '', 'index', 'TEXT'),
    window.COMMON.createCol(window.I18N('type'), '10%', '', '', 'type', 'TEXT'),
    window.COMMON.createCol(window.I18N('btn_active'), '10%', '', '', 'isActive', 'STATUS'),
    window.COMMON.createCol(window.I18N('created_by'), '15%', '', '', 'createdBy.name', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '10%', '', '', '3', 'BUTTON')
  ];
  const types = [
    { value: 'NORMAL', label: window.I18N('normal'), icon: 'text-width' },
    { value: 'PDF', label: 'PDF', icon: 'file-pdf' },
    { value: 'WEBSITE', label: window.I18N('website'), icon: 'blog' },
    { value: 'IMAGE', label: window.I18N('image'), icon: 'image' },
    { value: 'CONTACT_PHONE', label: window.I18N('contact_phone'), icon: 'phone' },
    { value: 'REQUEST_HELP', label: window.I18N('request_help'), icon: 'hands-helping' }
  ];
  const language = useRef();
  const [object, setObject] = useState({ index: -1 });
  const [data, setData] = useState([]);
  const [dataSave, setDataSave] = useState({ isActive: false });

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {
      getDataHelpCenter();
    }, 100);
    // eslint-disable-next-line
  }, [props.language]);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleChange = (event) => {
    if (event.target.className.indexOf('input-number') > -1) {
      dataSave[event.target.name] = parseInt(event.target.value);
    } else if (event.target.type === 'checkbox') {
      dataSave[event.target.name] = event.target.checked;
    } else {
      dataSave[event.target.name] = event.target.value;
    }
    setDataSave({ ...dataSave });
  };

  const handleChangeWithLanguage = (event) => {
    window.COMMON.setValueWithLanguage(dataSave, event.target.name, language.current, event.target.value);
    setDataSave({ ...dataSave });
  };

  const handleRadio = useCallback((value) => {
    dataSave.type = value;
    setDataSave({ ...dataSave });
  }, [dataSave]);

  const getDataHelpCenter = async () => {
    try {
      window.resetDataTable('table-data');
      const params = {
        isActive: [true, false]
      };
      const result = await window.COMMON.query(HelpCenterGRAPHQL.QUERY_HELP_CENTER, params);
      if (result && result.data) {
        const data = window.COMMON.formatDate(result.data.getHelpCenters, 'createdDate');
        data.forEach(item => {
          item.nameLanguage = window.COMMON.getValueWithLanguage(item, 'name', language.current);
        });
        setData(data);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('HelpCenter.getDataHelpCenter');
    }
    window.initDataTable('table-data');
  };

  const openHelpCenterDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-help-center');
    object.index = index;
    const dataSave = {};
    if (index === -1) {
      dataSave.name = [];
      dataSave.content = [];
      dataSave.code = window.COMMON.generateCode('HC');
      dataSave.type = 'NORMAL';
      dataSave.index = 0;
      dataSave.isActive = false;
    } else {
      const obj = data[index];
      dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
      dataSave.name = window.COMMON.getDataWithLanguage(obj, 'name');
      dataSave.content = window.COMMON.getDataWithLanguage(obj, 'content');
      dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
      dataSave.type = window.COMMON.getValueFromAttr(obj, 'type');
      dataSave.index = window.COMMON.getValueFromAttr(obj, 'index');
      dataSave.isActive = window.COMMON.getValueFromAttr(obj, 'isActive');
    }
    setObject(object);
    setDataSave({ ...dataSave });
  }, [object, data]);

  // eslint-disable-next-line
  const openDeleteHelpCenterDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteHelpCenter;
  });

  const saveHelpCenter = async (event) => {
    try {
      if (!window.COMMON.checkFormValidation('form-help-center', event)) {
        return;
      }
      const params = {
        input: dataSave
      };
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(HelpCenterGRAPHQL.MUTATION_ADD_HELP_CENTER, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataHelpCenter();
		  createTrackingEvent(eventName.ADD_HELP_CENTER);
        }
      } else {
        const result = await window.COMMON.mutation(HelpCenterGRAPHQL.MUTATION_EDIT_HELP_CENTER, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataHelpCenter();
		  createTrackingEvent(eventName.EDIT_HELP_CENTER);
        }
      }
      window.COMMON.hideModal('#modal-help-center');
    } catch (error) {
      window.COMMON.showErrorLogs('HelpCenter.saveHelpCenter');
    }
  };

  const deleteHelpCenter = async () => {
    try {
      const params = {
        id: data[object.index]._id
      };
      const result = await window.COMMON.mutation(HelpCenterGRAPHQL.MUTATION_DEL_HELP_CENTER, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataHelpCenter();
        createTrackingEvent(eventName.DEL_HELP_CENTER);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('HelpCenter.deleteHelpCenter');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" icon="list-alt">
        <div className="container-btn wow fadeInDown animated" data-wow-delay="0.5s">
          <MDBBtn color="primary" onClick={() => openHelpCenterDialog(-1)}>
            <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
          </MDBBtn>
        </div>
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <Table id="table-data" className="table" config={config} data={data} handleRowEditClick={openHelpCenterDialog} handleRowDelClick={openDeleteHelpCenterDialog}></Table>
        </div>
      </Panel>

      <Modal id="modal-help-center" className="modal-lg" title={window.I18N('create_update_data')} saveEvent={saveHelpCenter}>
        <form id="form-help-center" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <RadioBox data={types} value={dataSave.type} handleChange={handleRadio}></RadioBox>
              <MDBInput outline type="text" label={window.I18N('code') + ' *'} name="code" value={dataSave.code} onChange={handleChange} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="text" label={window.I18N('name') + ' *'} name="name" value={window.COMMON.getValueWithLanguage(dataSave, 'name', language.current)} onChange={handleChangeWithLanguage} maxLength="200" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="text" label={window.I18N('content')} name="content" value={window.COMMON.getValueWithLanguage(dataSave, 'content', language.current)} onChange={handleChangeWithLanguage} maxLength="200" pattern="\S(.*\S)?"></MDBInput>
              <MDBInput outline type="number" className="input-number" label={window.I18N('index') + ' *'} value={dataSave.index} name="index" onChange={handleChange} maxLength="5" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput type="checkbox" id="ck-active-help-center" name="isActive" label={window.I18N('btn_active')} containerClass="p-0" filled checked={dataSave.isActive} onChange={handleChange}></MDBInput>
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};
    
export default connect(mapStateToProps, mapDispatchToProps)(HelpCenter);
