import gql from 'graphql-tag';



const QUERY_FIND_FRIENDS_RELATIONSHIP_BY_USER = gql`
    query{
        findFriendsByUser{
            _id
            receiver {
                _id
                avatar
                email
                name
                nickname
                avatar
                phone
                gender
                birthday
                address
                accountType
                taxCode
                bio
                organizationCode
                zipcode
                gender
                website
                representative
                representativeEmail
                representativePhone
                representativePosition
                role {
                  _id
                }
                companies {
                  _id
                }
                unions {
                  _id
                }
                companyType {
                  _id
                  value
                }
                companySize {
                  _id
                  value
                }
                companyIndustry {
                  _id
                  value
                }
                nation {
                  _id
                  name
                }
                city {
                  _id
                  name
                }
                state {
                  _id
                  name
                }
                status
                createdDate
            }
            status
            createdBy {
                _id
                avatar
                email
                name
                nickname
                avatar
                phone
                gender
                birthday
                address
                accountType
                taxCode
                bio
                organizationCode
                zipcode
                gender
                website
                representative
                representativeEmail
                representativePhone
                representativePosition
                role {
                  _id
                }
                companies {
                  _id
                }
                unions {
                  _id
                }
                companyType {
                  _id
                  value
                }
                companySize {
                  _id
                  value
                }
                companyIndustry {
                  _id
                  value
                }
                nation {
                  _id
                  name
                }
                city {
                  _id
                  name
                }
                state {
                  _id
                  name
                }
                status
                createdDate
            }
        }
    }
`;
const QUERY_FIND_PENDING_FRIENDS_RELATIONSHIP_BY_USER = gql`
    query{
      findPendingFriendsByUser{
            _id
            receiver {
                _id
                avatar
                email
                name
                nickname
                avatar
                phone
                gender
                birthday
                address
                accountType
                taxCode
                bio
                organizationCode
                zipcode
                gender
                website
                representative
                representativeEmail
                representativePhone
                representativePosition
                role {
                  _id
                }
                companies {
                  _id
                }
                unions {
                  _id
                }
                companyType {
                  _id
                  value
                }
                companySize {
                  _id
                  value
                }
                companyIndustry {
                  _id
                  value
                }
                nation {
                  _id
                  name
                }
                city {
                  _id
                  name
                }
                state {
                  _id
                  name
                }
                status
                createdDate
            }
            status
            createdBy {
                _id
                avatar
                email
                name
                nickname
                avatar
                phone
                gender
                birthday
                address
                accountType
                taxCode
                bio
                organizationCode
                zipcode
                gender
                website
                representative
                representativeEmail
                representativePhone
                representativePosition
                role {
                  _id
                }
                companies {
                  _id
                }
                unions {
                  _id
                }
                companyType {
                  _id
                  value
                }
                companySize {
                  _id
                  value
                }
                companyIndustry {
                  _id
                  value
                }
                nation {
                  _id
                  name
                }
                city {
                  _id
                  name
                }
                state {
                  _id
                  name
                }
                status
                createdDate
            }
        }
    }

`;

const QUERY_CHECK_RELATIONSHIP_ACCOUNT = gql`
  query($anyone: String!) {
    checkRelationship(anyone: $anyone) {
      _id
      receiver {
        _id
        avatar
        nickname
        bio
        company {
          information {
            name
            avatar
          }
        }
        influencer {
          information {
            name
            avatar
          }
        }
      }
      createdBy {
        _id
        avatar
        nickname
        bio
        company {
          information {
            name
            avatar
          }
        }
        influencer {
          information {
            name
            avatar
          }
        }
      }
      status
      createdDate
    }
  }
`;

const MUTATION_ADD_FRIEND = gql`
  mutation ($input: RelationInput!) {
    addFriend(input: $input)
  }
`;

const MUTATION_ACCEPT_FRIEND = gql`
  mutation ($input: RelationInput!) {
    acceptFriend(input: $input)
  }
`;

const MUTATION_DECLINE_FRIEND = gql`
  mutation ($accountId: String!) {
    declineFriend(accountId: $accountId)
  }
`;

const MUTATION_REMOVE_FRIEND = gql`
  mutation ($accountId: String!) {
    removeFriend(accountId: $accountId)
  }
`;

const MUTATION_CANCEL_FRIEND_REQUEST = gql`
  mutation ($accountId: String!) {
    cancelRequestFriend(accountId: $accountId)
  }
`;


const RelationshipGRAPHQL = {
  QUERY_FIND_FRIENDS_RELATIONSHIP_BY_USER,
  QUERY_FIND_PENDING_FRIENDS_RELATIONSHIP_BY_USER,
  QUERY_CHECK_RELATIONSHIP_ACCOUNT,
  MUTATION_ADD_FRIEND,
  MUTATION_ACCEPT_FRIEND,
  MUTATION_DECLINE_FRIEND,
  MUTATION_REMOVE_FRIEND,
  MUTATION_CANCEL_FRIEND_REQUEST
};

export default RelationshipGRAPHQL;