import React, { useState, useEffect, useImperativeHandle } from "react"
import Select from 'react-select';
import { MDBInput, MDBCol, MDBIcon, MDBRow } from "mdbreact"
import CheckboxTree from "react-checkbox-tree";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import DashboardPowerBIGraphQL from "../../graphql/DashboardPowerBI";

const DEFAULT_TEMPLATES_NAME = 'Template Name'
const LIMIT_ITEMS = 15
const TABLE_TEMPLATE = 0
const CHART_TEMPLATE = 1
const TARGET_NODE_IS_ITEM = 1

const DATA_TYPE_TEMPLATE = [
    {
        value: 'TABLE',
        label: 'Table Template'
    },
    {
        value: 'CHART',
        label: 'Chart Template'
    }
]

const INIT_TEMPLATES = {
    label: '',
    value: '',
    children: [],
    isChoice: false
}

const ListGroupsAndItemsBI = React.forwardRef(({isEdit = false, dataTemplate, templateType = 'TABLE'}, ref)=> {
    const [dataGroups, setDataGroups] = useState([])
    const [dataItems, setDataItems] = useState([])
    const [listChecked, setListChecked] = useState([])
    const [dataExpand, setDataExpand] = useState([])
    const [templateName, setTemplateName] = useState(DEFAULT_TEMPLATES_NAME)
    const [selectTypeTemplate, setSelectTypeTemplate] = useState(DATA_TYPE_TEMPLATE[templateType === 'TABLE' ? TABLE_TEMPLATE : CHART_TEMPLATE])

    useEffect(() => {
        if(dataItems.length > 0){
            const templateName = dataItems.map(item => item.isChoice ? item.label : null).filter(item => item !== null)
            setTemplateName(templateName.join('-')) 
        }     
    },[dataGroups])

    useEffect(()=>{
        const getGroups = async () => {
            const result = await window.COMMON.query(DashboardPowerBIGraphQL.QUERY_GROUPS)
            const filterTemplateType = result.data.getDashboardGroups.filter(group => group.useFor && group.useFor.includes(selectTypeTemplate?.value))
            const data = filterTemplateType.map((group)=>({
                ...INIT_TEMPLATES,
                label: group.title,
                value: group._id,
                children: group.items.filter(item => item.useFor.includes(selectTypeTemplate?.value)).map((item)=>({
                    value: item._id, 
                    label: item.title,
                    dataKey: item.dataKey,
                    isChoice: false, 
                    children: item.availableFilter && (selectTypeTemplate?.value === 'TABLE') ? item.availableFilter.values.map((val)=>{
                        return {
                            label: val.toString(), 
                            value: val,
                            isChoice: false
                        }
                    }) : null
                })) ?? []
            }))
            if(isEdit){
                /* Push data into the selection tree to edit the template */
                const listGroupsTemplate = dataTemplate.groups?.map(group => group._id)
                const listItemsTemplate = dataTemplate.items?.map(item => item._id)
                const listMatchFilter = dataTemplate.matchFilters?.map(item => item.values.map(value => value.toString())).flat(2)
                const dataGroupsEdit = data.map(item => {
                    if(listGroupsTemplate?.includes(item.value)){
                        return {
                            ...item,
                            isChoice: true,
                            children: item.children ? item.children.map(child => (listItemsTemplate?.includes(child.value) ? {...child, isChoice: true, children: child.children ? child.children?.map(filter =>(listMatchFilter?.includes(filter.label) ? {...filter, isChoice: true} : filter)) : null}  : child)) : null
                        }
                    }else{
                        return item
                    }
                })
                const dataItemsEdit = dataGroupsEdit.filter((item)=> item.isChoice)
                const dataChecked = dataItemsEdit.map(item => item.children.map(child => (child.isChoice && (child.children ? child.children.map(filter => (filter.isChoice && filter.label)) : child.value)))).flat(2).filter(el => el)
                const dataExpandEdit = dataItemsEdit.map(item => ([item.value, item.children.map(child => (child.value))])).flat(3)
                setDataGroups(dataGroupsEdit)
                setDataItems(dataItemsEdit)
                setListChecked(dataChecked)
                setDataExpand(dataExpandEdit)
                setTemplateName(dataTemplate.name)
            }else{
                setDataGroups(data)
                setDataItems([])
                setListChecked([])
                setDataExpand([])
                setTemplateName(DEFAULT_TEMPLATES_NAME)
            }
        }
        getGroups();
    },[selectTypeTemplate, templateType, dataTemplate])

    const handleSelectedGroups = (event, index)=>{
        const checked = event.target.checked
        //Clone nested object in array with JSON
        const cloneDataGroups = JSON.parse(JSON.stringify(dataGroups))
        const cloneDataItems = JSON.parse(JSON.stringify(dataItems))
        cloneDataGroups.splice(index, 1, {...cloneDataGroups[index], isChoice: checked})
        const mapIndex = cloneDataItems.findIndex(item => item.label === cloneDataGroups[index].label)
        if(checked){
            cloneDataItems.push(cloneDataGroups[index])
        }else{
            //Remove list checked in checkbox tree and remove data items
            handleRemoveCheckedList(cloneDataItems, mapIndex)
            cloneDataItems.splice(mapIndex, 1)
        }
        setDataGroups(cloneDataGroups)
        setDataItems(cloneDataItems)
    }

    const handleSelectedItems = (listChecked, targetNodes) => {
        const { value, checked, parent, children, treeDepth: target, index: indexTarget} = targetNodes
        const isParent = Object.keys(parent).length !== 0

        if(checked && listChecked.length > LIMIT_ITEMS){
            window.COMMON.showMessage('warning', 'MSG_CODE_072', `${window.I18N('MSG_CODE_072')} ${LIMIT_ITEMS}`);
            return
        }

        const cloneDataItems = JSON.parse(JSON.stringify(dataItems))
        //Check the position where the parent item is selected
        const indexListItemsUpdate = cloneDataItems.findIndex(item => item.value === value || item.value === parent.value || item?.children.map(chil => chil.value).includes(parent.value))

        let updateItem = {}
     
        const { children: childrenOfRootNode, ...rest } = cloneDataItems[indexListItemsUpdate]
            // -> root node
        if(children && !isParent){
            const selectedAllItems = childrenOfRootNode.map(item =>(item.children ? {...item, children: item.children.map(filter => ({...filter, isChoice: checked})), isChoice: checked } : {...item, isChoice: checked}))
            updateItem = { ...rest, children: selectedAllItems }
        }else if(children && isParent){
            // -> middle node
            const selectedAllFilters = children.map(filter => ({...filter, isChoice: checked}))
            const selectedItem = childrenOfRootNode.map((item, index) => index === indexTarget ? ({...item, children: selectedAllFilters, isChoice: checked}) : item)
            updateItem = { ...rest, children: selectedItem }
        }else if(!children && isParent){
            // -> leaf node
            if(target === TARGET_NODE_IS_ITEM){
                //case leaf node not children
                const selectedItem = childrenOfRootNode.map((item) => item.value === value ? ({...item, isChoice: checked}) : item)
                updateItem = { ...rest, children: selectedItem }
            }else{
                //selected filter and mark parent node
                const selectedFilter = parent.children.map((filter, index) => index === indexTarget ? ({...filter, isChoice: checked}) : filter)
                const checkFilterSelected = selectedFilter.some(filter => filter.isChoice)
                const markParentNode = childrenOfRootNode.map((item) => item.value === parent.value ? ({...item, children: selectedFilter, isChoice: checkFilterSelected}) : item)
                updateItem = { ...rest, children: markParentNode }
            }
        }

        cloneDataItems.splice(indexListItemsUpdate, 1, updateItem)
        setListChecked(listChecked)
        setDataItems(cloneDataItems)
    }

    const getDataTemplates = () => {
        const data = {
            name: templateName,                   
            groups: dataItems.reduce((dataGroup, group) => group.children.some(child => child.isChoice) ? [...dataGroup, group.value] : dataGroup, []),                 
            items: dataItems.map(item => item.children.reduce((dataItem,item) => item.isChoice ? [...dataItem, item.value] : dataItem,[])).flat(2),
            matchFilters: dataItems.map((item) => item.children.filter(item => item.isChoice).map((item) => (item.children && {
                dataKey: item.dataKey,
                values: item.children.reduce((valueFilter, filter) => filter.isChoice ? [...valueFilter, filter.value] : valueFilter, [])
            }))).flat(2).filter(item => item),
            fromToFilters: [
                {
                    dataKey: window.COMMON.generateCode('FROM-TO-KEY'),
                    from: "",
                    to: ""
                }
            ],
            templateType: selectTypeTemplate.value
        }
        return data
    }

    const checkDataItemsSelected = () => {
        const checkItemsSelected = dataItems.reduce((dataItem, item) => item.children.some(child => child.isChoice) ? [...dataItem, item.value] : dataItem, [])
        return checkItemsSelected.length === 0
    }

    const getDataPreview = () => {
        const dataPreview = {
                groups: dataItems.reduce((dataGroup, group) => group.isChoice && group.children.some(item => item.isChoice) ? [...dataGroup, {_id: group.value, title: group.label}] : dataGroup,[]),
                items: dataItems.map(item => item.children.reduce((dataItem, item) => item.isChoice ? [...dataItem, {_id: item.value, title: item.label, dataKey: item.dataKey}] : dataItem,[])).flat(2),
                matchFilters: dataItems.map((item) => item.children.filter(item => item.isChoice).map((item) => (item.children && {
                    dataKey: item.dataKey,
                    values: item.children.reduce((valueFilter, filter) => filter.isChoice ? [...valueFilter, filter.value] : valueFilter, [])
                }))).flat(2).filter(item => item !== null),
                fromToFilters: [
                    {
                        dataKey: "",
                        from: "",
                        to: ""
                    }
                ]
        }
        return dataPreview
    }

    const handleResetData = () => {
        const resetDataGroups = dataGroups.map(group => ({...group, isChoice: false, children: group.children.map(item => ({...item, isChoice: false, children: item.children ? item.children.map(filter => ({...filter, isChoice: false})) : null}))}))
        setDataGroups(resetDataGroups)
        setDataItems([])
        setTemplateName(DEFAULT_TEMPLATES_NAME)
    }

    const handleRemoveCheckedList = (data, mapIndex) => {
        const listIdItemsRemove = data.map((item, index) => index === mapIndex ? item.children.reduce((dataItem, item) => item.isChoice ? [...dataItem, item.value] : dataItem,[]) : null).flat(2).filter(item => item)
        const listMatchFilterRemove = data.map((item, index) => index === mapIndex ? item.children.filter(item => item.isChoice).map((item) => (item.children && item.children.reduce((valueFilter, filter) => filter.isChoice ? [...valueFilter, filter.label] : valueFilter, [])
        )) : null).flat(2).filter(item => item)
        const mergeRemoveId = [...listIdItemsRemove, ...listMatchFilterRemove]
        const filterListChecked = listChecked.filter(item => !mergeRemoveId.includes(item))
        setListChecked(filterListChecked)
    }
    
    const handleSelectTypeTemplate = (event) => {
        setSelectTypeTemplate(event)
    }

    const getTemplateType = () => {
        if(selectTypeTemplate.value){
            return selectTypeTemplate.value
        }
    }

    useImperativeHandle(ref, () => ({
        getDataTemplates,
        getDataPreview,
        getTemplateType,
        handleResetData,
        checkDataItemsSelected
    }))

    return ( 
        <div className="p-4 bg-white rounded-top">
             <MDBRow className="mb-4">
                    <MDBCol size="8">
                        <h6 className="font-weight-bold" style={{fontSize: '0.8rem'}}>{window.I18N('Template Name')}</h6>
                        <MDBInput containerClass="m-0" outline value={templateName} name="code" type="text" onChange={(e)=> setTemplateName(e.target.value)} maxLength="150" required />
                        <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                    </MDBCol>
                    <MDBCol size="4">
                        <h6 className="font-weight-bold" style={{fontSize: '0.8rem'}}>{window.I18N('Type Templates')}</h6>
                        <Select id="select-type-templates" value={selectTypeTemplate} options={DATA_TYPE_TEMPLATE} className="md-form m-0" placeholder={window.I18N('choose_option')} onChange={(event) => handleSelectTypeTemplate(event)} isDisabled={isEdit}/>
                    </MDBCol>
            </MDBRow>   
            <MDBRow>
                    <MDBCol size="4" className="border-right">
                            <h6 className="font-weight-bold ">Group</h6>
                            {
                                dataGroups.map((item, index) => (
                                    <div key={index} className="pl-4">
                                        <FormControlLabel 
                                            className='my-auto' 
                                            control={ 
                                                <Checkbox 
                                                    checked={item.isChoice} 
                                                    onChange={(e) => handleSelectedGroups(e, index)}
                                                    color="primary"
                                                    fontSize="small"
                                                />
                                            }
                                            label={
                                                <div className="flex-center">
                                                    <MDBIcon fas={true} style={{color: '#33c'}} icon="cube"/>
                                                    <span className="ml-2">{item.label}</span>
                                                </div>
                                            } 
                                        />
        
                                    </div>
                                ))
                            }
                    </MDBCol>
                    <MDBCol className="pl-4" size="8">
                        <h6 className="font-weight-bold ">Items</h6>
                        {dataItems.length > 0 ?
                            <div className="pl-4">
                                    <CheckboxTree
                                        disabled={false}    
                                        nodes={dataItems}
                                        checked={listChecked}
                                        expanded={dataExpand}
                                        onCheck={(listChecked,targetNodes) => handleSelectedItems(listChecked,targetNodes)}
                                        onExpand={dataExpand => setDataExpand([...dataExpand])}
                                        showNodeIcon={true} showExpandAll={true}
                                        expandOnClick                 
                                        icons={{
                                            check: <MDBIcon far={true} className="blue-strong-text" icon="check-square"/>,
                                            uncheck: <MDBIcon far={true} className="blue-strong-text" icon="square"/>,
                                            halfCheck: <MDBIcon fas={true} className="light-blue-text" icon="square"/>,
                                            expandClose: <MDBIcon far={true} className="blue-strong-text" icon="plus-square"/>,
                                            expandOpen: <MDBIcon far={true} className="blue-strong-text" icon="minus-square"/>,
                                            expandAll: <MDBIcon far={true} className="blue-strong-text" icon="plus-square"/>,
                                            collapseAll: <MDBIcon far={true} className="blue-strong-text" icon="minus-square"/>,
                                            parentClose: <MDBIcon fas={true} className="yellow-text" icon="code-branch"/>,
                                            parentOpen: <MDBIcon fas={true} className="yellow-text" icon="code-branch"/>,
                                            leaf: <MDBIcon fas={true} className="blue-strong-text" icon="cube"/>
                                        }}
                                    /> 
                            </div> : 
                            <div className="flex-center" style={{fontSize: '1.2rem', fontStyle: "italic"}}>
                                    {window.I18N('choose_at_least_group')}
                            </div>
                        }
                    </MDBCol>
            </MDBRow>           
        </div>
     );
})

export default ListGroupsAndItemsBI;