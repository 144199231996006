import ChatGRAPHQL from '../graphql/Chat';
export const fetchRelationships = async (statusArray) => {
    let relationships = [];
    let isError = false;
    if(statusArray && statusArray.length > 0){
        const currentUser = window.COMMON.getCurrentUserInformation();
        const fetchRequests = []
        for(let i=0;i<statusArray?.length;i=i+1){
            const fetchRequest = window.COMMON.query(ChatGRAPHQL.QUERY_GET_USER_RELATIONSHIP, {
                status: statusArray[i],
                userId: currentUser?._id
                });
            fetchRequests.push(fetchRequest)
        }
        const fetchRelationshipsData = await Promise.all(fetchRequests).then((fetchResults) => {
            const fetchData = []
            fetchResults.forEach((rs)=>{
                fetchData.push(...(rs?.data?.getUserRelationship||[]))
            })
            return {
                data:fetchData,
                isError:false
            }
        })
        .catch((_e)=>{
            return {
                data:[],
                isError:true
            }
        })

        relationships = fetchRelationshipsData.data;
        isError = fetchRelationshipsData.isError
    }

    return {
        relationships,
        isError
    }
}

export const getRelationshipItem = (relationships,userIdToFind, status) => {
    if(!userIdToFind){
      return undefined
    }
    const result = relationships.find((item)=>{
      const userIds = [];
      if(item?.receiver?._id){
        userIds.push(item?.receiver?._id)
      }
      if(item?.createdBy?._id){
        userIds.push(item?.createdBy?._id)
      }
      if(userIds.includes(userIdToFind)){
        return true
      }
      return false
    })
    if(status && status !== result?.status){
        return undefined
    }

    return result
  }