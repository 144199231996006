import React, { useState, forwardRef, useEffect } from 'react'
import noImage from '../../styles/images/no_image.png'

const Image = forwardRef(({ src, alt, className, fallback: customFallback = noImage, ...props }, ref) => {
    const [fallback, setFallback] = useState('')

    useEffect(() => {
        setFallback('')
    }, [src])

    const handleError = () => {
        setFallback(customFallback)
    }

    return <img className={className} ref={ref} src={fallback || src} alt={alt} {...props} onError={handleError} />
})

export default Image
