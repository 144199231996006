const languageEvent = {
  ADD_LANGUAGE: 'ADD_LANGUAGE',
  EDIT_LANGUAGE: 'EDIT_LANGUAGE',
  UPDATE_STATUS_LANGUAGE: 'UPDATE_STATUS_LANGUAGE',
  ADD_TEXT_LANGUAGE: 'ADD_TEXT_LANGUAGE',
  EDIT_TEXT_LANGUAGE: 'MEDIT_TEXT_LANGUAGE',
  DEL_TEXT_LANGUAGE: 'DEL_TEXT_LANGUAGE',
  CHECK_IMPORT_TEXT_LANGUAGE: 'CHECK_IMPORT_TEXT_LANGUAGE',
  IMPORT_TEXT_LANGUAGE: 'IMPORT_TEXT_LANGUAGE'
};

export default languageEvent;