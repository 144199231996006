import gql from 'graphql-tag';

const QUERY_LOCATION = gql`
  query($parent: String) {
    getLocations(parent: $parent) {
      _id
      name
      code
      parent
    }
  }
`;

const QUERY_DATA_LOCATION = gql`
  query($parent: String) {
    getLocations(parent: $parent) {
      _id
      name
      code
      parent
      createdDate
      createdBy {
        name
      }
    }
  }
`;

const MUTATION_ADD_LOCATION = gql`
  mutation($input: LocationInput!) {
    addLocation(input: $input)
  }
`;

const MUTATION_EDIT_LOCATION = gql`
  mutation($input: LocationInput!) {
    editLocation(input: $input)
  }
`;

const MUTATION_DEL_LOCATION = gql`
  mutation($id: String!) {
    deleteLocation(id: $id)
  }
`;

const MUTATION_CHECK_IMPORT_LOCATION = gql`
  mutation($input: [String!]) {
    checkImportLocation(input: $input)
  }
`;

const MUTATION_IMPORT_LOCATION = gql`
  mutation($input: [LocationInput!]) {
    importLocation(input: $input)
  }
`;

const LocationGRAPHQL = {
  QUERY_LOCATION,
  QUERY_DATA_LOCATION,
  MUTATION_ADD_LOCATION,
  MUTATION_EDIT_LOCATION,
  MUTATION_DEL_LOCATION,
  MUTATION_CHECK_IMPORT_LOCATION,
  MUTATION_IMPORT_LOCATION
};

export default LocationGRAPHQL;
