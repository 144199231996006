// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n    --box-shadow: 0px 2px 20px -1px rgba(0, 0, 0, 0.26);\n    --event-date: #f3425f;\n    --primary-text: #050505;\n    --secondary-text: #65676b;\n}\n\n.EventDetail_calendar-box__2REBz {\n    position: relative;\n    top: -40px;\n    left: 15px;\n    width: 80px;\n    height: 80px;\n    display: flex;\n    flex-direction: column;\n    box-shadow: var(--box-shadow);\n    border-radius: 8px;\n    z-index: 2;\n}\n\n.EventDetail_calendar-header__2TZvq {\n    width: 100%;\n    height: 15px;\n    border-top-left-radius: 8px;\n    border-top-right-radius: 8px;\n    background-color: var(--event-date);\n}\n\n.EventDetail_calendar-body__2fSXH {\n    width: 100%;\n    background-color: #fff;\n    height: calc(100% - 15px);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 2.5rem;\n    font-weight: 500;\n    border-bottom-left-radius: 8px;\n    border-bottom-right-radius: 8px;\n}\n.EventDetail_event-name__2BuJG,\n.EventDetail_event-date-time__FCCpU {\n    position: relative;\n    top: -30px;\n    left: 15px;\n    color: var(--event-date);\n    font-size: 0.9rem;\n    font-weight: 600;\n}\n\n.EventDetail_event-name__2BuJG {\n    padding-right: 30px;\n    color: var(--primary-text);\n    font-size: 1.5rem;\n}\n.EventDetail_event-description__3pnmb {\n    font-size: 15px;\n    line-height: 32px;\n    font-weight: 400;\n    white-space: pre-line;\n    text-align: justify;\n    color: var(--secondary-text);\n}\n\n.EventDetail_fanpage-name__Ro-Ny,\n.EventDetail_fanpage-type__1PpP0 {\n    font-size: 0.7rem;\n    color: var(--secondary-text);\n    font-weight: 400;\n}\n", ""]);
// Exports
exports.locals = {
	"calendar-box": "EventDetail_calendar-box__2REBz",
	"calendar-header": "EventDetail_calendar-header__2TZvq",
	"calendar-body": "EventDetail_calendar-body__2fSXH",
	"event-name": "EventDetail_event-name__2BuJG",
	"event-date-time": "EventDetail_event-date-time__FCCpU",
	"event-description": "EventDetail_event-description__3pnmb",
	"fanpage-name": "EventDetail_fanpage-name__Ro-Ny",
	"fanpage-type": "EventDetail_fanpage-type__1PpP0"
};
module.exports = exports;
