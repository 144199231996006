import React, { useState, useCallback, useLayoutEffect } from 'react';
import Select from 'react-select';
import { MDBInputGroup, MDBInput, MDBRow, MDBCol, MDBIcon } from 'mdbreact';

import Panel from '../../components/Panel';
import List from '../../components/List';
import Pagination from '../../components/Pagination';
import Modal from '../../components/Modal';
import RadioBox from '../../components/RadioBox';

import CompanyGRAPHQL from '../../../graphql/Company';
import AccountGRAPHQL from '../../../graphql/Account';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';

export default function ChangeCompanyPage(props) {
  const { trackEvent } = useTracking();

  const icons = [
    {
      icon: 'exchange-alt',
      color: 'orange',
      code: 'CHANGE'
    }
  ];
  const types = [
    { value: 'COMPANY', label: window.I18N('company') },
    { value: 'UNION', label: window.I18N('union') }
  ];
  const [filter, setFilter] = useState({ text: '' });
  const [object, setObject] = useState({ index: -1, item: null, companyType: null });
  const [pagination, setPagination] = useState({ pageSize: 30, currentPage: 0 });
  const [data, setData] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [select, setSelect] = useState({});
  const [options, setOptions] = useState({ company: [] });

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataCompany();
      getDataUnion();
    }, 100);
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line
  }, []);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      document.getElementById('btn-search').click();
    }
  };

  const handleFilterChange = (event) => {
    filter.text = event.target.value;
    setFilter({ ...filter });
  };

  const handleRadio = useCallback((value) => {
    object.companyType = value;
    setObject({ ...object });
  }, [object]);

  const handleSelect = (event, id, attr) => {
    window.COMMON.checkSelectValidation(id, event);
    select[attr] = event;
    setSelect({ ...select });
  };

  const getDataCompany = async () => {
    try {
      const params = {
        type: window.CONSTANT.COMPANY
      };
      const result = await window.COMMON.query(CompanyGRAPHQL.QUERY_COMPANY, params);
      if (result && result.data) {
        options.company = window.COMMON.createDataSelect(result.data.getCompanies, '_id', 'information.name');
        select.company = options.company && options.company.length > 0 ? options.company[0] : null;  
        setOptions(options);
        setSelect(select);
      } 
    } catch (error) {
      window.COMMON.showErrorLogs('ChangeCompanyPage.getDataCompany');
    }
  };

  const getDataUnion = async () => {
    try {
      const params = {
        type: window.CONSTANT.UNION
      };
      const result = await window.COMMON.query(CompanyGRAPHQL.QUERY_COMPANY, params);
      if (result && result.data) {
        options.union = window.COMMON.createDataSelect(result.data.getCompanies, '_id', 'information.name');
        select.union = options.union && options.union.length > 0 ? options.union[0] : null;  
        setOptions(options);
        setSelect(select);
      } 
    } catch (error) {
      window.COMMON.showErrorLogs('ChangeCompanyPage.getDataUnion');
    }
  };

  const searchData = async () => {
    if (!window.COMMON.checkValue(filter.text)) {
      window.COMMON.showMessage('warning', 'MSG_CODE_018', window.MSG.MSG_CODE_018);
      return;
    }
    try {
      const params = {
        text: filter.text
      };
      const result = await window.COMMON.query(AccountGRAPHQL.QUERY_FIND_INDIVIDUAL_ACCOUNT, params);
      if (result && result.data) {
        const data = result.data.findIndividualAccounts;
        setData(data);
        loadData(0, data);
      } else {
        window.COMMON.showMessage('warning', 'MSG_CODE_019', window.MSG.MSG_CODE_019);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('ChangeCompanyPage.searchData');
    }
  };

  const loadData = (index, items) => {
    const dataList = [];
    for (let i = pagination.pageSize * index; i < pagination.pageSize * (index + 1); i++) {
      if (i > (items ? items.length : data.length) - 1) {
        break;
      }
      const obj = items ? items[i] : data[i];
      if (obj) {
        dataList.push(obj);
      }
    }
    pagination.currentPage = index;
    setDataList(dataList);
    setPagination({ ...pagination });
  };

  const clickItemList = useCallback((index, code) => {
    object.index = index;
    object.item = data[index];
    object.companyType = window.CONSTANT.COMPANY;
    select.company = null;
    select.union = null;
    setObject(object);
    setSelect({ ...select });
    window.COMMON.showModal('#modal-change-company');
  }, [object, select, data]);

  const changeCompany = async (event) => {
    try {
      const checkForm = window.COMMON.checkFormValidation('form', event);
      if (!checkForm) {
        if (object.companyType === window.CONSTANT.COMPANY) {
          const checkCompany = window.COMMON.checkSelectValidation('select-company', select.company);
          if (!checkCompany) {   
            return;
          }
        }
        if (object.companyType === window.CONSTANT.UNION) {
          const checkUnion = window.COMMON.checkSelectValidation('select-union', select.union);
          if (!checkUnion) {
            return;
          }
        }
        return;
      }
      const params = {
        id: object.item._id,
        company: select.company.value,
        companyType: object.companyType
      };
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_CHANGE_COMPANY, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
        searchData();
        createTrackingEvent(eventName.CHANGE_COMPANY);
      }
      window.COMMON.hideModal('#modal-change-company');
    } catch (error) {
      window.COMMON.showErrorLogs('ChangeCompanyPage.changeCompany');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" icon="list-alt">
        <div className="container-top d-flex align-items-center justify-content-end wow fadeInDown animated" data-wow-delay="0.5s">
          <MDBInputGroup type="text" containerClassName="w-50" value={filter.text} hint={window.I18N('search_name_nickname_email_phone')} onChange={handleFilterChange} maxLength="100" pattern="\S(.*\S)?" required
            append={
              <span className="input-group-text cursor-pointer" id="btn-search" onClick={searchData}>
                <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
              </span>
            }
          />
        </div>
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <List data={dataList} title={window.I18N('result_list') + ' (' + data.length + ')'} attr_id="_id" attr_text="name" attr_avatar="avatar" attr_content="email" attr_sub="phone" icons={icons} clickItemList={clickItemList}></List>
          <Pagination total={data.length} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={loadData}></Pagination>
        </div>
      </Panel>
      <Modal id="modal-change-company" isNotScroll={true} title={window.I18N('change_organization')} saveEvent={changeCompany}>
        <form id="form" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <MDBInput outline containerClass="mt-0" type="text" label={window.I18N('name')} value={window.COMMON.getValueFromAttr(object.item, 'name')} readOnly></MDBInput>
              <MDBInput outline type="text" label={window.I18N('nickname')} value={window.COMMON.getValueFromAttr(object.item, 'nickname')} readOnly></MDBInput>
              <MDBInput outline type="text" label={window.I18N('email')} value={window.COMMON.getValueFromAttr(object.item, 'email')} readOnly></MDBInput>
              <MDBInput outline type="text" label={window.I18N('phone_number')} value={window.COMMON.getValueFromAttr(object.item, 'phone')} readOnly></MDBInput>
              <RadioBox data={types} value={object.companyType} handleChange={handleRadio}></RadioBox>
              {
                object.companyType === window.CONSTANT.COMPANY
                  ? <>
                    <MDBInput outline type="text" label={window.I18N('current_company')} value={window.COMMON.getValueFromAttr(object.item, 'company.information.name')} readOnly></MDBInput>
                    <Select id="select-company" value={select.company} options={options.company} placeholder={window.I18N('company') + ' *'} className="md-form mb-0" onChange={(event) => handleSelect(event, 'select-company', 'company')} isSearchable/>
                  </> : <>
                    <MDBInput outline type="text" label={window.I18N('current_union')} value={window.COMMON.getValueFromAttr(object.item, 'union.information.name')} readOnly></MDBInput>
                    <Select id="select-union" value={select.company} options={options.union} placeholder={window.I18N('union') + ' *'} className="md-form mb-0" onChange={(event) => handleSelect(event, 'select-union', 'union')} isSearchable/>
                  </>
              }
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>
    </div>
  );
}