import React, { useState, useCallback, useLayoutEffect } from 'react';
import { MDBRow, MDBCol, MDBInput, MDBCardImage, MDBBtn, MDBIcon } from 'mdbreact';

import Panel from '../../components/Panel';
import Table from '../../components/Table';
import InputFile from '../../components/InputFile';
import Modal from '../../components/Modal';
import ListCheck from '../../components/ListCheck';
import RadioBox from '../../components/RadioBox';
import Select from 'react-select';

import GroupChatGRAPHQL from '../../../graphql/GroupChat';
import eventName from '../../../common/events';
import { useTracking } from 'react-tracking';

export default function GroupChatPage(props) {
  const { trackEvent } = useTracking();
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const COMPANY_ID = window.COMMON.checkValue(localStorage.getItem(window.CONSTANT.COMPANY_ID)) ? localStorage.getItem(window.CONSTANT.COMPANY_ID) : null;
  const types = [
    { value: 'PUBLIC', label: window.I18N('public_groups'), icon: 'globe-asia' },
    { value: 'PRIVATE', label: window.I18N('private_groups'), icon: 'lock' }
  ];
  const config = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('group'), '40%', '', 'p-0 pl-1', 'image,name', 'IMAGE_TEXT'),
    window.COMMON.createCol(window.I18N('code'), '15%', '', '', 'code', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_by'), '20%', '', '', 'createdBy.name', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '10%', '', '', '5', 'BUTTON')
  ];
  const [filter, setFilter] = useState({ radio : 'PUBLIC', organization: null });
  const [object, setObject] = useState({ index: -1, titleGroup: '' });
  const [data, setData] = useState([]);
  const [dataSave, setDataSave] = useState({});
  const [dataMember, setDataMember] = useState([]);
  const [dataRequest, setDataRequest] = useState([]);
  // const [dataBlock, setDataBlock] = useState([]);
  const [valuesMember, setValuesMember] = useState([]);
  const [valuesRequest, setValuesRequest] = useState([]);
  // const [valuesBlock, setValuesBlock] = useState([]);
  const [options, setOptions] = useState({
    organization: []
  });

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataCompany();
    }, 100);
    // eslint-disable-next-line
  }, []);

  // create tracking event
  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleFilterSelect = (event, attr) => {
    filter[attr] = event;
    setFilter({ ...filter });
    getDataGroupChat();
  };

  const handleRadio = useCallback((value) => {
    filter.radio = value;
    setFilter({ ...filter });
    getDataGroupChat();
    // eslint-disable-next-line
  }, [filter]);

  const handleChange = (event) => {
    if (event.target.className.indexOf('input-number') > -1) {
      dataSave[event.target.name] = parseInt(event.target.value);
    } else {
      dataSave[event.target.name] = event.target.value;
    }
    setDataSave({ ...dataSave });
  };

  const handleFile = (file) => {
    dataSave.file = file;
    dataSave.image = window.COMMON.createBlobImage(file);
    setDataSave({ ...dataSave });
  };

  const getDataCompany = async () => {
    try {
      if (COMPANY_TYPE !== window.CONSTANT.ADMIN) {
        filter.organization = { value: COMPANY_ID, label: '', code: COMPANY_TYPE };
        setFilter({ ...filter }); 
        getDataGroupChat();
        return;
      }
      await window.COMMON.getDataAllCompany();
      const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));
      if (dataCompany && dataCompany.length > 0) {
        options.organization = window.COMMON.createDataSelectWithGroup(dataCompany, '_id', 'information.name', 'type', 'type');
        options.organization.unshift({ value: null, label: window.I18N('my_organization'), code: COMPANY_TYPE });
        filter.organization = window.COMMON.getValueFromAttr(options.organization, '0', null);  
      }
      setOptions(options);
      setFilter({ ...filter });
      getDataGroupChat();
    } catch (error) {
      window.COMMON.showErrorLogs('GroupChatPage.getDataCompany');
    }
  };

  const getDataGroupChat = async () => {
    try {
      window.resetDataTable('table-data');
      const params = {
        type: filter.radio,
        organization: filter.organization && filter.organization.value
      };
      const result = await window.COMMON.query(GroupChatGRAPHQL.QUERY_GROUP_CHAT, params);
      if (result && result.data) {
        const data = window.COMMON.formatDate(result.data.getGroupChats, 'createdDate');
        setData(data);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('GroupChatPage.getDataGroupChat');
    }
    window.initDataTable('table-data');
  };

  const openGroupChatDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-group');
    object.index = index;
    const dataSave = { file: null, type: filter.radio, organization: (filter.organization && filter.organization.value) || null };
    if (index === -1) {
      dataSave.name = '';
      dataSave.code = window.COMMON.generateCode('GC');
      dataSave.image = '';
      dataSave.description = '';
    } else {
      const obj = data[index];
      dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
      dataSave.name = window.COMMON.getValueFromAttr(obj, 'name');
      dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
      dataSave.image = window.COMMON.getValueFromAttr(obj, 'image');
      dataSave.description = window.COMMON.getValueFromAttr(obj, 'description');
    }
    document.getElementById('file-group').value = null;
    setObject(object);
    setDataSave({ ...dataSave });
  }, [data, filter, object]);

  // eslint-disable-next-line
  const openDeleteGroupChatDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteGroupChat;
  });

  const saveGroupChat = async (event) => {
    try {
      if (!window.COMMON.checkFormValidation('form', event)) {
        return;
      }
      // TODO upload file
      let dataFiles = [];
      if (dataSave.file) {
        dataFiles = await window.uploadFile([dataSave.file]);
        if (dataFiles.length > 0) {
          dataSave.image = window.COMMON.getNewPathFile(dataFiles[0]._id);
        }
      }
      dataSave.file = null;
      // TODO save data
      const params = {
        input: dataSave
      };
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(GroupChatGRAPHQL.MUTATION_ADD_GROUP_CHAT, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataGroupChat();
		  createTrackingEvent(eventName.ADD_GROUP_CHAT);
        }
      } else {
        const result = await window.COMMON.mutation(GroupChatGRAPHQL.MUTATION_EDIT_GROUP_CHAT, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataGroupChat();
		  createTrackingEvent(eventName.EDIT_GROUP_CHAT);
        }
      }
      window.COMMON.hideModal('#modal-group');
    } catch (error) {
      window.COMMON.showErrorLogs('GroupChatPage.saveGroupChat');
    }
  };

  const deleteGroupChat = async () => {
    try {
      const params = {
        id: data[object.index]._id
      };
      const result = await window.COMMON.mutation(GroupChatGRAPHQL.MUTATION_DEL_GROUP_CHAT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataGroupChat();
      }

	  createTrackingEvent(eventName.DEL_GROUP_CHAT);
    } catch (error) {
      window.COMMON.showErrorLogs('GroupChatPage.deleteGroupChat');
    }
  };

  // DETAILS
  // eslint-disable-next-line
  const showGroupChatDetail = useCallback((index) => {
    window.COMMON.showContainerSave();
    object.index = index;
    object.titleGroup = data[index].name;
    setObject({ ...object });
    getDataGroupRelation();
  });

  const handleChecklistMember = useCallback((index, value) => {
    const id = window.COMMON.getValueIdFromAttr(dataMember[index], 'user._id');
    if (value) {
      valuesMember.push(id);
    } else {
      for (let i = 0; i < valuesMember.length; i++) {
        if (id === valuesMember[i]) {
          valuesMember.splice(i, 1);
          break;
        }
      }
    }
    setValuesMember([...valuesMember]);
  }, [dataMember, valuesMember]);

  const handleChecklistRequest = useCallback((index, value) => {
    const id = window.COMMON.getValueIdFromAttr(dataRequest[index], 'user._id');
    if (value) {
      valuesRequest.push(id);
    } else {
      for (let i = 0; i < valuesRequest.length; i++) {
        if (id === valuesRequest[i]) {
          valuesRequest.splice(i, 1);
          break;
        }
      }
    }
    setValuesRequest([...valuesRequest]);
    // eslint-disable-next-line
  }, [valuesRequest, dataRequest]);

  // const handleChecklistBlock = useCallback((index, value) => {
  //   const id = window.COMMON.getValueIdFromAttr(dataBlock[index], 'user._id');
  //   if (value) {
  //     valuesBlock.push(id);
  //   } else {
  //     for (let i = 0; i < valuesBlock.length; i++) {
  //       if (id === valuesBlock[i]) {
  //         valuesBlock.splice(i, 1);
  //         break;
  //       }
  //     }
  //   }
  //   setValuesBlock([...valuesBlock]);
  // }, [dataBlock, valuesBlock]);

  const getDataGroupRelation = async () => {
    try {
      const params = {
        groupId: data[object.index]._id
      };
      const result = await window.COMMON.query(GroupChatGRAPHQL.QUERY_FIND_GROUP_RELATIONSHIP, params);
      if (result && result.data) {
        const data = window.COMMON.formatDate(result.data.findGroupRelationshipByGroup, 'createdDate');
        setDataMember(window.COMMON.getArrsInArrs('ACCEPTED', data, 'status'));
        setDataRequest(window.COMMON.getArrsInArrs('PENDING', data, 'status'));
        // setDataBlock(window.COMMON.getArrsInArrs('BLOCKED', data, 'status'));
        setValuesMember([]);
        setValuesRequest([]);
        // setValuesBlock([]);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('GroupChatPage.getGroupRelation');
    }
  };

  const openAcceptMemberDialog = () => {
    window.COMMON.showModal('#modal-save');
    window.saveMethod = acceptMember;
  };

  const openRemoveItemDialog = (code) => {
    window.COMMON.showModal('#modal-delete');
    if (code === 'MEMBER') {
      window.deleteMethod = removeMember;
    } else if (code === 'REQUEST') {
      window.deleteMethod = declineMember;
    } else {
      // window.deleteMethod = removeBlockedMember;
    }
  };

  const acceptMember = async () => {
    try {
      const params = {
        groupId: data[object.index]._id,
        users: valuesRequest
      };
      const result = await window.COMMON.mutation(GroupChatGRAPHQL.MUTATION_ACCEPT_GROUP_RELATIONSHIP, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
        getDataGroupRelation();
        createTrackingEvent(eventName.ACCEPT_GROUP_RELATIONSHIP);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('GroupChatPage.acceptMember');
    }
  };

  const removeMember = async () => {
    try {
      const params = {
        groupId: data[object.index]._id,
        users: valuesMember
      };
      const result = await window.COMMON.mutation(GroupChatGRAPHQL.MUTATION_DECLINE_GROUP_RELATIONSHIP, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataGroupRelation();
        createTrackingEvent(eventName.DECLINE_GROUP_RELATIONSHIP);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('GroupChatPage.removeMember');
    }
  };

  const declineMember = async () => {
    try {
      const params = {
        groupId: data[object.index]._id,
        users: valuesRequest
      };
      const result = await window.COMMON.mutation(GroupChatGRAPHQL.MUTATION_DECLINE_GROUP_RELATIONSHIP, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataGroupRelation();
        createTrackingEvent(eventName.DECLINE_GROUP_RELATIONSHIP);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('GroupChatPage.declineMember');
    }
  };

  // const removeBlockedMember = async () => {
  //   try {
  //     const params = {
  //       groupId: data[object.index]._id,
  //       users: valuesBlock
  //     };
  //     const result = await window.COMMON.mutation(GroupChatGRAPHQL.MUTATION_DECLINE_GROUP_RELATIONSHIP, params);
  //     if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
  //       getDataGroupRelation();
  //       createTrackingEvent(eventName.DECLINE_GROUP_RELATIONSHIP);
  //     }
  //   } catch (error) {
  //     window.COMMON.showErrorLogs('GroupChatPage.removeBlockedMember');
  //   }
  // };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" icon="list-alt">
        <div className="container-top d-flex align-items-center justify-content-end wow fadeInDown animated" data-wow-delay="0.5s">
          <div className="d-inline-block mr-2">
            <RadioBox data={types} value={filter.radio} className="m-0" handleChange={handleRadio}></RadioBox>
          </div>
          {
            COMPANY_TYPE !== window.CONSTANT.ADMIN ? <div></div> : <div className="bd-highlight mr-2" style={{ width: '300px' }}>
              <div className="input-group input-group-transparent">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="building" className="fa-fw" />
                  </span>
                </div>
                <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.organization} options={options.organization} onChange={(event) => handleFilterSelect(event, 'organization')} isSearchable />
              </div>
            </div>
          }
          <MDBBtn color="primary" className="m-0" onClick={() => openGroupChatDialog(-1)}>
            <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
          </MDBBtn>
        </div>
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <Table id="table-data" className="table" config={config} data={data} handleRowDetailClick={showGroupChatDetail} handleRowEditClick={openGroupChatDialog} handleRowDelClick={openDeleteGroupChatDialog}></Table>
        </div>
      </Panel>

      <Panel id="container-save" title={object.titleGroup} icon="th" style={{ display: 'none' }}>
        <div className="container-btn wow fadeInDown animated" data-wow-delay="0.5s">
          <MDBBtn color="" onClick={window.COMMON.backContainerData}>
            <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('btn_back')}
          </MDBBtn>
        </div>
          
        <MDBRow>
          <MDBCol md="6" className="wow fadeInLeft animated" data-wow-delay="0.2s">
            <Panel id="container-data" icon="list-alt">
              <div className="container-btn mt-2 wow fadeInDown animated" data-wow-delay="0.5s">
                {/* <MDBBtn color="primary">
                  <MDBIcon fa="true" icon="plus-circle" className="fa-fw"/> Add Member
                </MDBBtn> */}
                <MDBBtn color="danger" onClick={() => openRemoveItemDialog('MEMBER')} disabled={valuesMember.length > 0 ? false : true}>
                  <MDBIcon fa="true" icon="trash-alt" className="fa-fw"/> Remove Member
                </MDBBtn>
              </div>
              <div className="container-table wow fadeIn animated" data-wow-delay="1s">
                <ListCheck data={dataMember} values={valuesMember} attr_id="user._id" attr_image="user.avatar" attr_content="user.nickname" attr_sub="user.email" attr_desc="createdDate" handleCheckChange={handleChecklistMember}></ListCheck>
              </div>
            </Panel>
          </MDBCol>
          <MDBCol md="6" className="wow fadeInLeft animated" data-wow-delay="0.2s">
            <Panel id="container-data" icon="list-alt">
              <div className="container-btn mt-2 wow fadeInDown animated" data-wow-delay="0.5s">
                <MDBBtn color="danger" onClick={() => openRemoveItemDialog('REQUEST')} disabled={valuesRequest.length > 0 ? false : true}>
                  <MDBIcon fa="true" icon="ban" className="fa-fw"/> {window.I18N('btn_reject')}
                </MDBBtn>
                <MDBBtn color="primary" onClick={openAcceptMemberDialog} disabled={valuesRequest.length > 0 ? false : true}>
                  <MDBIcon fa="true" icon="check-circle" className="fa-fw"/> {window.I18N('btn_accept')}
                </MDBBtn>
              </div>
              <div className="container-table wow fadeIn animated" data-wow-delay="1s">
                <ListCheck data={dataRequest} values={valuesRequest} attr_id="user._id" attr_image="user.avatar" attr_content="user.name" attr_sub="createdDate" attr_desc="user.email" handleCheckChange={handleChecklistRequest}></ListCheck>
              </div>
            </Panel>
          </MDBCol>
          {/* <MDBCol md="4" className="wow fadeInLeft animated" data-wow-delay="0.2s">
            <Panel id="container-data" icon="list-alt">
              <div className="container-btn mt-2 wow fadeInDown animated" data-wow-delay="0.5s">
                <MDBBtn color="primary">
                  <MDBIcon fa="true" icon="plus-circle" className="fa-fw"/> Add To Block
                </MDBBtn>
                <MDBBtn color="danger" onClick={() => openRemoveItemDialog('BLOCK')} disabled={valuesBlock.length > 0 ? false : true}>
                  <MDBIcon fa="true" icon="trash-alt" className="fa-fw"/> Unblock
                </MDBBtn>
              </div>
              <div className="container-table wow fadeIn animated" data-wow-delay="1s">
                <ListCheck data={dataBlock} values={valuesBlock} attr_id="user._id" attr_image="user.avatar" attr_content="user.name" attr_sub="user.email" attr_desc="createdDate" handleCheckChange={handleChecklistBlock}></ListCheck>
              </div>
            </Panel>
          </MDBCol> */}
        </MDBRow>
      </Panel>

      <Modal id="modal-group" title={window.I18N('create_update_data')} saveEvent={saveGroupChat}>
        <form id="form" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <MDBInput outline containerClass="mt-0" type="text" name="name" label={window.I18N('group_name') + ' *'} value={dataSave.name} onChange={handleChange} maxLength="100" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="text" name="code" label={window.I18N('code') + ' *'} value={dataSave.code} onChange={handleChange} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="textarea" name="description" label={window.I18N('description')} value={dataSave.description} onChange={handleChange} maxLength="5000"></MDBInput>
              <div className="text-center">
                <MDBCardImage className="input-image-icon" src={window.COMMON.setValueImage(dataSave.image)} hover overlay="white-slight"/><br></br>
                <InputFile id="file-group" icon="upload" handleFile={handleFile} accept="image/*" description="Icon (64 x 64) pixels"></InputFile>
              </div>
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>
    </div>
  );
}