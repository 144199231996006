import React, { useState, useMemo, useEffect } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";

import { MDBIcon, MDBInputGroup } from "mdbreact";
import RoleGRAPHQL from "../../../../../graphql/Role";
import { useDebounce } from "../../../../../hooks/useSearchDebounce";


const TYPE_FILTERS = {
  'STATUS_TYPE' : 'status',
  'PACKAGE_TYPE' : 'package',
  'DATE_FROM_TYPE' : 'from',
  'DATE_TO_TYPE' : 'to',
  'SEARCH_TYPE' : 'search'
}

const DEBOUNCE_SENCOND = 700


const BillHistoryHeader = ({ onFilters = () => {}, language}) => {

  const TRANSACTION_STATUS = useMemo(()=>(
    {
      0 : window.I18N('payment_pending'),
      1 : window.I18N('payment_successful'),
      2 : window.I18N('payment_processing'),
      3 : window.I18N('payment_failed')
    }
),[language.code]) 

const STATUS_FILTERS = useMemo(()=>{
    const allStatus = {
        value: window.I18N('all'),
        label: window.I18N('all_payment_status')
    }
    const filters = Object.entries(TRANSACTION_STATUS).map((item) => {
        const [statusCode, statusName] = item
        return {
            value: parseInt(statusCode),
            label: statusName
        }
    })
    return [allStatus, ...filters]
},[language.code])


  const [dataPackage, setDataPackage] = useState([{
    label: window.I18N('all_packages'),
    value: window.I18N('all')
  }])

  const [filters, setFilters] = useState({
    status: STATUS_FILTERS[0],
    search: '',
    package: dataPackage[0],
    from: window.COMMON_DATE.addDaysFromNow(-90),
    to: new Date()
  })

  const [search, setSearch] = useState('')
  const searchDebounce = useDebounce(search, DEBOUNCE_SENCOND)

  const handleSetFilters = (event, type) => {
    if(type === 'SEARCH_TYPE'){
      setSearch(event.target.value)
    }else{
      const dataFilters = event
      filters[TYPE_FILTERS[type]] = dataFilters
      setFilters((prev) => ({...prev, ...filters}))
    }
  }


  useEffect(() => {
    const getPackage = async () => {
      const result = await window.COMMON.query(RoleGRAPHQL.QUERY_PACKAGE)
      const dataPackage = result.data.queryPackage
      const attrsToSelect = { 
        label: 'name', 
        value: 'code' 
      }
      const groupPackages = [
          {
            label: window.I18N('default').toLowerCase(),
            options: [{value: window.I18N('all'), label: window.I18N('all_packages'), code: 'DEFAULT'}],
          },
          {
            label: window.I18N('companies').toLowerCase(),
            options: window.COMMON.convertDataToSelect(dataPackage.filter(item => item.role === window.CONSTANT.COMPANY), attrsToSelect)
          },
          {
            label: window.I18N('influencers').toLowerCase(),
            options: window.COMMON.convertDataToSelect(dataPackage.filter((data) => data.role === window.CONSTANT.INFLUENCER), attrsToSelect)
          }
      ]  
      setDataPackage(groupPackages)
    }
    getPackage()
  },[])

  useEffect(() => {
    
    const { status, package: packageFilter, from, to } = filters
    const dataFilters = {
      status: status.value,
      package: packageFilter.value,
      search: searchDebounce,
      from: window.COMMON_DATE.getStrDateFilter(from, window.CONSTANT.FROM_FILTER_DATE),
      to: window.COMMON_DATE.getStrDateFilter(to, window.CONSTANT.TO_FILTER_DATE)
    }
    onFilters(dataFilters)

  },[filters, searchDebounce])

  return (
    <div className="w-full bg-white rounded-lg py-3">
      <div className="w-full flex-center justify-content-between">
        <div className="mr-2">
          <h6 className="font-weight-bold" style={{ fontSize: "0.8rem" }}>{window.I18N('btn_search')}</h6>
          <MDBInputGroup
            type="text"
            value={search}
            hint={window.I18N("search_name")}
            onChange={(e) => handleSetFilters(e, "SEARCH_TYPE")}
            maxLength="100"
            required
            append={
              <span
                className="input-group-text cursor-pointer"
                onClick={() => {}}
              >
                <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
              </span>
            }
          />
        </div>
        <div className="d-flex justify-content-start align-items-center">
          {/* Date */}
         <div style={{ flexBasis: '25%' }} className='mr-2'>
            <h6 className="font-weight-bold" style={{ fontSize: "0.8rem" }}>{window.I18N("from_date")}</h6>
            <div className="input-group input-group-transparent mr-2">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                </span>
              </div>
              <DatePicker
                placeholderText={window.I18N("from_date") + " *"}
                className="form-control"
                dateFormat="dd/MM/yyyy"
                selected={filters.from}
                onChange={(event) => handleSetFilters(event, "DATE_FROM_TYPE")}
                showYearDropdown
                showMonthDropdown
                required
                style={{ zIndex: "999" }}
              />
            </div>
         </div>
         {/* To Date */}
          <div style={{ flexBasis: '25%' }} className='mr-2'>
            <h6 className="font-weight-bold" style={{ fontSize: "0.8rem" }}>{window.I18N('to_date')}</h6>
            <div className="input-group input-group-transparent mr-2">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                </span>
              </div>
              <DatePicker
                placeholderText={window.I18N("to_date") + " *"}
                className="form-control"
                dateFormat="dd/MM/yyyy"
                selected={filters.to}
                onChange={(event) => handleSetFilters(event, "DATE_TO_TYPE")}
                showYearDropdown
                showMonthDropdown
                required
                style={{ zIndex: "999" }}
              />
            </div>
          </div>
          {/* status */}
          <div style={{ flexBasis: '25%' }} className='mr-2'>
            <h6 className="font-weight-bold" style={{ fontSize: "0.8rem" }}>{window.I18N('status')}</h6>
            <div className="input-group input-group-transparent mr-2">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <MDBIcon fa="true" icon="clock" className="fa-fw" />
                </span>
              </div>
              <Select
                value={filters.status}
                options={STATUS_FILTERS}
                className="md-form m-0"
                placeholder={window.I18N("choose_option")}
                onChange={(e) => handleSetFilters(e, "STATUS_TYPE")}
                isSearchable
              />
            </div>
          </div>
          {/* Package */}
          <div style={{ flexBasis: '25%' }}>
            <h6 className="font-weight-bold" style={{ fontSize: "0.8rem" }}>{window.I18N('package')}</h6>    
            <div className="input-group input-group-transparent">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <MDBIcon fa="true" icon="cube" className="fa-fw" />
                </span>
              </div>
              <Select
                value={filters.package}
                options={dataPackage}
                className="md-form m-0"
                placeholder={window.I18N("choose_option")}
                onChange={(e) => handleSetFilters(e, "PACKAGE_TYPE")}
                isSearchable
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  language: state.language.value
})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BillHistoryHeader)
