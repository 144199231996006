import React, { useState, useLayoutEffect, useEffect } from 'react';
import FanpageGRAPHQL from '../../graphql/Fanpage';
import ItemPost from './ItemPost';
import { Button, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { useTracking } from 'react-tracking';
import eventName from '../../common/events';


const FanpageManagePost = ({ fanpageId, reloadPost }) => {
    const [dataPosts, setDataPosts] = useState([])
    const { trackEvent } = useTracking();

    const createTrackingEvent = (event) => {
        return trackEvent({
            name: event,
            createdAt: new Date().toISOString()
        });
    };

    const getPostsNeedApprove = async () => {
        try {
            const params = {
                page: 1,
                fanpage: fanpageId,
                status: false
            }
            const result = await window.COMMON.query(FanpageGRAPHQL.QUERY_MY_POST_IN_FANPAGE, params);

            if (result?.data) {
                setDataPosts(result.data.getMyPostsInFapage.data)
            }                
        } catch (error) {
            console.log(error)
        }
    }

    const approveOrRejectPost = async (index, mode) => {
        try {
            const params = {
                postId: dataPosts[index]._id,
                mode: mode
            }
            const result = await window.COMMON.mutation(FanpageGRAPHQL.MUTATION_APPROVE_OR_REJECT_POST, params);
            if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
                if (mode === 1) {
                    createTrackingEvent(eventName.APPROVE_POST);
                } else {
                    createTrackingEvent(eventName.REJECT_POST);
                }
                getPostsNeedApprove()
                reloadPost(fanpageId)
            }                
        } catch (error) {
            console.log(error)
        }
    }

    const onBtnApprovePost = (index) => {
        window.COMMON.showModal('#modal-save');
        window.saveMethod = ()=> approveOrRejectPost(index, 1);
    }

    const onBtnRejectPost = (index) => {
        window.COMMON.showModal('#modal-delete');
        window.deleteMethod = ()=>approveOrRejectPost(index, -1);
    }

    useLayoutEffect(() => {
        setTimeout(() => {
            if (fanpageId) { getPostsNeedApprove()}
        },100)
    }, [fanpageId])
    
    return (
        <div>
            {dataPosts.map((item, index) =>
                <div key={item._id} style={{marginBottom:"3rem"}}>
                    <ItemPost isView={true} fanpagePost={true} item={item} index={index} key={item._id} className="col-md-12 p-0" attr_id="_id" attr_content="text" attr_image="image" attr_avatar="createdBy.avatar" attr_name="createdBy.nickname" attr_time="createdDate" categorySticker={[]} ></ItemPost>
                    <Grid container className='bg-white rounded pb-4 justify-content-center'>
                        <Button variant="contained" color='primary' className='mr-4' onClick={() => onBtnApprovePost(index)}>{window.I18N("approve_post")}</Button>
                        <Button variant="contained" color='default'  onClick={()=>onBtnRejectPost(index)}>{window.I18N("reject_post")}</Button>
                    </Grid>
                </div>
                )}
        </div>
    )
}

const mapStateToProps = state => ({
    language: state.language.value
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(FanpageManagePost);
