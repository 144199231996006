import MailGRAPHQL from '../graphql/Mail';

const MailAPI = {
  async sendMailNotify(params) {
    try {
      const result = await window.COMMON.mutation(MailGRAPHQL.MUTATION_SEND_GRID_MAIL, {
        input: {
          receiver: params.email?.split(','),
          subject: params.title,
          body: params.body
        }
      });
      return result;
    } catch (error) {
      console.log(error);
    }
    return null;
  }
};

export default MailAPI;
