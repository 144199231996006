import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomChatLeftSide } from '../../../../styles/styled-components/Chat/ChatPage';
import BodyChatLefttSide from './BodyChatLeftSide/BodyChatLeftSide';
import HeaderChatLeftSide from './HeaderChatLeftSide';
import AccountGRAPHQL from '../../../../graphql/Account';
import ChatGRAPHQL from '../../../../graphql/Chat';
import socket from '../../../../utils/socket';
import { debounce } from '@material-ui/core';
import useSearchDebounce from '../../../../hooks/useSearchDebounce';
import Modal from '../../../components/Modal';
import GroupChatGRAPHQL from '../../../../graphql/GroupChat';
import {useChatDataProvider} from "../../../../context/ChatDataContext"
const initFetchSearchUserData = {
  limit: 50,
  page: 0,
  loading: false,
  isHasMore: true
};
const initFetchSearchGroupData = {
  limit: 50,
  page: 0,
  loading: false,
  isHasMore: true
};
function hashCode(s) {
  let h = 0;
  for (let i = 0; i < s.length; i++)
    h = Math.imul(31, h) + s.charCodeAt(i) | 0;
  return h;
}
const ChatLeftSide = React.forwardRef(({ 
    onShowGroupChat, 
    individualGroupChatList, 
    onFetchIndividualGroupChatListSuccess, 
    groupChatSelected, 
    friends, 
    onFetchCompany, 
    options, 
    onNewGroupChat, 
    manyGroupChatList, 
    onFetchManyGroupChatListSuccess, 
    onRequestToPublicGroupSuccess, 
    initFetchBlockedGroups,
    fetchDataFriend,
    handleSelectUserProfile,
    userProfileSelect}, 
  ref) => {
  const CONFIRM_MODAL_ID = 'confirm-modal';
  const chatLeftSideHeaderRadioData = [
    { value: 0, label: window.I18N('individual'), icon: 'user' },
    { value: 2, label: window.I18N('suggest'), icon: 'user-secret' },
    { value: 1, label: window.I18N('group'), icon: 'users' }
  ];

  const history = useHistory();
  const [filter, setFilter] = useState({ company: null, radio: chatLeftSideHeaderRadioData[0].value });

  const [searchUserValue, setSearchUserValue] = useState('');
  const [searchGroupValue, setSearchGroupValue] = useState('');
  const [searchUserResult, setSearchResult] = useState({
    employees: [],
    friendsInCompany: [],
    friendsOutCompany: []
  });
  const [searchGroupResult, setSearchGroupResult] = useState({
    public_groups: [],
    private_groups: []
  });
  const [searchRelatedResult, setSearchRelatedResult] = useState([]);
  const [fetchIndividualGroupChatData, setFetchIndividualGroupChatData] = useState({
    loading: false,
    limit: 10,
    isHasMore: true
  });
  const [fetchManyGroupChatData, setFetchManyGroupChatData] = useState({
    loading: false,
    limit: 100,
    isHasMore: true
  });
  const [fetchSearchUserData, setFetchSearchUserData] = useState(initFetchSearchUserData);
  const [fetchSearchGroupData, setFetchSearchGroupData] = useState(initFetchSearchGroupData);
  const [fetchSearchRelatedData, setFetchSearchRelatedData] = useState({loading: false});
  const [confirmModalData, setConfirmModalData] = useState({
    title: '',
    saveEvent: () => { }
  });
  const headerChatLeftSideRef = useRef();
  const filterRef = useRef();
  filterRef.current = filter;

  const searchGroupDebounce = useSearchDebounce();

  const {state, setSelectedUserToChat} = useChatDataProvider()

  useEffect(() => {
    getDataCompany();
    handleFetchIndividualGroupChat();
    getRelatedAccounts()
  }, []);

  useEffect(() => {
    handleFetchManyGroupChat();
  }, [initFetchBlockedGroups]);

  useEffect(() => {
    socket.on('get-individual-group-chat-list', handleGetIndividualGroupChatListEvent);
    socket.on('get-many-group-chat-list', handleGetManyGroupChatListEvent);
    return () => {
      socket.off('get-individual-group-chat-list');
      socket.off('get-many-group-chat-list');
    };
  }, []);

  useEffect(() => {

    if (filter.company === null) return;
    //clear search input
    clearSearch();
    // getAccountFollowCompany();
  }, [filter.company]);

  useEffect(() => {

    handleSearchUserValueChange();

    return () => {
      setFetchSearchUserData(initFetchSearchUserData);
    };

  }, [searchUserValue]);


  useEffect(() => {
    handleSearchGroupValueChange();
    return () => {
      setFetchSearchGroupData(initFetchSearchGroupData);
    };
  }, [searchGroupValue]);

  useEffect(() => {
    // choose user if open chat page with user id
    const userId = history.location.pathname.split('/')[2];
    if (userId) {
      openChatBoxByUserIdParam(userId);
    }
  }, [friends.data.length]);

  //if context has user who selected to chat
  //open group chat
  useEffect(() => {
    if(state?.selectedUserToChat?._id){
      handleClickOnSearchUserResult(state?.selectedUserToChat);
      setSelectedUserToChat(undefined)
    }
  },[state?.selectedUserToChat?._id])

  useImperativeHandle(ref, () => {
    return {
      changeFilterRadio: handleFilterRadio
    };
  });

  const findContactUserByUserIdParam = async (userId) => {
    let user = friends.data.find((item) => item._id === userId);
    if(!user || !user.name){
      try{
        const queryAccountResult = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_BY_ID, {id: userId});
        if(queryAccountResult?.data){
          user = queryAccountResult.data.findAccountById;
        }
      }
      catch(error){
        console.log(error)
        window.COMMON.showErrorLogs('ChatLeftSide.findContactUserByUserIdParam');
      }
    }
    return user;
  }

  const openChatBoxByUserIdParam = async (userId) => {
    const findFriendResult = await findContactUserByUserIdParam(userId)
    if (findFriendResult && findFriendResult.name) {
      handleClickOnSearchUserResult(findFriendResult);
    }
    else{
      window.COMMON.showMessage('error', 'user_not_found', window.I18N('user_not_found'));
    }
    // after select user, delete id in url
    history.replace('/chat');
  }

  const handleSearchUserValueChange = () => {
    //filter.company && searchUserValue
    if (searchUserValue) {
      const page = 1; //init page again
      getAccountFollowCompany(page);
    }
    else {

    }
  };

  const handleSearchGroupValueChange = () => {
    if (searchGroupValue) {
      const page = 1; //init page again
      getGroupWithFilter(page);
    }
    else {

    }
  };

  const handleFilter = (event, attr) => {
    setFilter((current) => {
      return {
        ...current,
        [attr]: event
      };
    });
  };

  const getDataCompany = async () => {
    let dataCompanyRaw = [];

    const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
    const result = await window.COMMON.query(
      AccountGRAPHQL.QUERY_COMPANY_ACCOUNT_BY_ROLE,
      { companyType: COMPANY_TYPE },
      false
    );
    if (result && result.data) {
      dataCompanyRaw = result.data.getCompanyAccountsByRole;
    }


    const companyData = [];
    let companyOptions = [];

    dataCompanyRaw.forEach(item => {
      const newItem = {
        ...item,
        value_id: window.COMMON.getValueIdFollowCompanyType(item, true)
      };

      companyData.push(newItem);
    });
    companyOptions = window.COMMON.createDataSelectWithGroup(companyData, 'value_id', 'name', 'accountType', 'accountType');
    if (!window.COMMON.checkRoleIsSystemOrInfluencer() && companyOptions.length > 0 && companyOptions[0].options.length > 0) {

      setFilter((current) => {
        return {
          ...current,
          company: companyOptions[0].options[0]
        };
      });
    }

    onFetchCompany(companyOptions);
  };

  //if companyType is not in current roles of admin page then return true
  const checkIsDefaultCompany = (companyType) => {
    const currentRoles = [
      window.CONSTANT.SUPER_ADMIN,
      window.CONSTANT.ADMIN,
      window.CONSTANT.COMPANY,
      window.CONSTANT.UNION,
      window.CONSTANT.INFLUENCER,
      window.CONSTANT.INDIVIDUAL
    ];
    if (!currentRoles.includes(companyType)) {
      return true;
    }
    return false;
  };

  const getAccountFollowCompany = async (page = 0) => {
    let isHasMore = true;
    setFetchSearchUserData((current) => {
      return {
        ...current,
        loading: true
      };
    });
    try {
      let users = [];
      if (filter.company) {
        let companyTypeToFilter;
        if (checkIsDefaultCompany(filter.company.code)) {
          companyTypeToFilter = 'COMPANY';
        }
        else {
          companyTypeToFilter = filter.company.code;
        }


        const params = {
          input: {
            organizationType: companyTypeToFilter,
            organization: filter.company.value,
            pageSize: fetchSearchUserData.limit,
            page,
            nickname: searchUserValue,
            // email: searchUserValue,
            // phone: searchUserValue
          }
        };
        const result = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_WITH_FILTER, params, false);
        
        if (result && result.data) {
          users = result.data.getAccountsWithFilter.data;
        }
      }



      // const currentUser = window.COMMON.getCurrentUserInformation();
      // const searchUsers = users.filter((item) => {
      //   return (item._id !== currentUser._id);
      // });
      // setSearchResult(searchUsers);
      combineSearchUserResult(users, friends);
    }
    catch (error) {
      isHasMore = false;
    }
    setFetchSearchUserData((current) => {
      return {
        ...current,
        isHasMore,
        loading: false,
        page: page + 1
      };
    });

  };
  const getGroupWithFilter = async (page = 0) => {
    let isHasMore = true;
    setFetchSearchGroupData((current) => {
      return {
        ...current,
        loading: true
      };
    });
    try {
      const params = {
        input: {
          page,
          pageSize: fetchSearchGroupData.limit,
          name: searchGroupValue

        }
      };
      const result = await window.COMMON.query(ChatGRAPHQL.QUERY_GET_GROUP_WITH_FILTER, params, false);
      if (result && result.data) {
        const groups = result.data.getGroupChatWithFilter;
        const public_groups = [];
        const private_groups = [];
        groups.forEach((item) => {
          item.id = item._id;
          item.groupName = item.name;
          if (item.type === window.CONSTANT.PUBLIC) {
            public_groups.push(item);
          }
          else if (item.type === window.CONSTANT.PRIVATE) {
            private_groups.push(item);
          }
        });
        setSearchGroupResult((current) => {
          return {
            ...current,
            private_groups,
            public_groups
          };
        });
        if (groups.length < fetchSearchGroupData.limit) {
          isHasMore = false;
        }
      }
    }
    catch (error) {
      isHasMore = false;
    }
    setFetchSearchGroupData((current) => {
      return {
        ...current,
        isHasMore,
        loading: false,
        page: page + 1
      };
    });
  };
  const getRelatedAccounts = async (page=1)=>{
    setFetchSearchRelatedData((current) => {
      return {
        ...current,
        loading: true
      };
    });
    try {
      const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
      const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
      const params = {
        [COMPANY_TYPE.toLowerCase()]: COMPANY_ID,
      };
      const result = await window.COMMON.query(AccountGRAPHQL.QUERY_FIND_RELATED_ACCOUNT, params);
      if (result && result.data) {
        setSearchRelatedResult(result.data.findRelatedAccounts)
      }
    }
    catch (error) {
      console.log("getRelatedAccounts ~ error:", error)
    }
    setFetchSearchRelatedData((current) => {
      return {
        ...current,
        loading: false,
      };
    });
  }



  const handleSearchUser = (value) => {
    // setSearchUserValueToShow(value);
    changeSearchUserValue(value);
  };
  //call this function with debounce to search value from api
  const changeSearchUserValue = debounce((value) => {
    setSearchUserValue((current) => {
      return value;
    });
  }, [200]);

  const handleSearchGroup = (value) => {
    changeSearchGroupValue(value);
  };

  const changeSearchGroupValue = (value) => {
    searchGroupDebounce(() => {
      setSearchGroupValue(value);
    }, 300);
  };




  const combineSearchUserResult = (userFromCompany, friends) => {
    const currentUserId = localStorage.getItem(window.CONSTANT.ID);
    const employees = [];
    const friendsInCompany = [];
    const friendsInCompanyIds = [];
    let friendsOutCompany = [];
    const friendsData = friends.data;
    const friendIds = friends.ids;
    userFromCompany.forEach((item) => {
      const userId = item._id;

      if (userId !== currentUserId) {
        //find employees
        if (!friendIds?.includes(userId)) {
          employees.push({
            ...item,
            relationshipType: window.CONSTANT.EMPLOYEE
          });
        }
        //find friend in company
        else {
          friendsInCompany.push({
            ...item,
            relationshipType: window.CONSTANT.FRIEND
          });
          friendsInCompanyIds.push(item._id);
        }
      }

    });

    friendsOutCompany = friendsData.filter(item => {
      if (!friendsInCompanyIds?.includes(item._id) && item.nickname?.toLowerCase().includes(searchUserValue.toLowerCase())) {
        return true;
      }
      return false;
    });

    setSearchResult({
      employees,
      friendsInCompany,
      friendsOutCompany
    });



  };







  const handleClickOnSearchUserResult = async (item) => {
    const radioValue = filterRef.current.radio;

    //kiểm tra xem trên firebase có dữ liệu message giữa 2 người dùng chưa ?
    //0 ==> result is an user
    if (radioValue === 0) {
      await handleClickOnUserResult(item);
      clearSearch();
    }
    //1 ==> result is a group chat
    else if (radioValue === 1) {
      await handleClickOnGroupResult(item);
    }



  };

  const handleClickOnUserResult = async (user) => {
    try {
      //có thể người dùng được chọn chưa có dữ liệu trên firebase
      //nên gọi một api để kiểm tra và thêm người dùng đó
      const userInput = {
        _id: user._id,
        name: user.name,
        avatar: user.avatar,
        email: user.email,
        phone: user.phone,
        nickname: user.nickname
      };
      const addFirebaseUserParams = {
        input: userInput
      };
      const result = await window.COMMON.mutation(ChatGRAPHQL.MUTATION_ADD_FIREBASE_USER, addFirebaseUserParams);
      let status = 1;
      if (result && result.data) {
        status = result.data.addFirebaseUser;
      }
      else {
        status = -1;
      }
      if (status === -1) {
        window.COMMON.showMessage('error', 'MSG_CODE_049', window.I18N('MSG_CODE_049'));
        return;
      }



      const currentUser = window.COMMON.getCurrentUserInformation();
      let groupChatId;
      let createNewGroupChatId = true;
      userInput.id = userInput._id;

      //find individual group chat (from last message collection) - if have then use id else create
      const paramsToFindIndividualGroupChat = {
        userId: currentUser._id,
        anotherUserId: userInput._id
      };
      const findResult = await window.COMMON.query(ChatGRAPHQL.QUERY_GET_INDIVIDUAL_GROUP_CHAT, paramsToFindIndividualGroupChat, false);
      if (findResult && findResult.data) {
        const groupChatResult = findResult.data.getIndividualGroupChat;
        if (groupChatResult) {
          groupChatId = groupChatResult.id;
          createNewGroupChatId = false;
        }
      }

        //sort ids to become a unique id
        if (currentUser._id <= userInput._id) {
          groupChatId = `${currentUser._id}-${userInput._id}`;
        } else {
          groupChatId = `${userInput._id}-${currentUser._id}`;
        }

      
      const groupChatType =  window.CONSTANT.FRIEND;

      const groupchat = {
        createdAt: new Date().getTime().toString(),
        createdBy: currentUser._id,
        _id: groupChatId,
        id: groupChatId,
        groupName: '',
        image: '',
        members: [currentUser, userInput],
        type: groupChatType
      };
      onShowGroupChat(groupchat);

    }
    catch (error) {
      window.COMMON.showErrorLogs('ChatPage.handleClickOnUserResult');
    }
  };
  const handleClickOnGroupResult = async (group) => {
    try {
      //get relationship with group
      const params = {
        groupId: group._id
      };
      const result = await window.COMMON.query(ChatGRAPHQL.QUERY_GET_RELATIONSHIP_WTIH_GROUP, params);
      let status = null;
      if (result && result.data) {
        const relationship = result.data.getRelationshipWithGroup;
        status = relationship?.status;

      }

      if (status === window.CONSTANT.ACCEPTED) {
        handleAccpetedGroupRelationship(group);
        clearSearch();
      }
      else if (status === window.CONSTANT.PENDING) {
        handlePendingAcceptedGroupRelationship();
      }
      else {
        handleNotGroupRelationship(group);
      }

    }
    catch (error) {
      window.COMMON.showErrorLogs('ChatPage.handleClickOnGroupResult');
    }
  };

  const handleAccpetedGroupRelationship = (group) => {
    onShowGroupChat(group);
  };
  const handlePendingAcceptedGroupRelationship = () => {
    window.COMMON.showMessage('success', 'MSG_CODE_059', window.I18N('MSG_CODE_059'));
  };
  const handleNotGroupRelationship = (group) => {

    //hiển thị thông báo xác nhận gửi yêu cầu tham gia
    window.COMMON.showModal(`#${CONFIRM_MODAL_ID}`);

    setConfirmModalData({
      title: window.I18N('confirm_request_join'),
      saveEvent: async () => {
        //xử lý yêu cầu tham gia đến nhóm public
        if (group.type === window.CONSTANT.PUBLIC) {
          await handleRequestToPublicGroup(group);
        }
        //xử lý yêu cầu tham gia đến nhóm private
        else if (group.type === window.CONSTANT.PRIVATE) {
          await handleRequestToPrivateGroup(group);
        }

        window.COMMON.hideModal(`#${CONFIRM_MODAL_ID}`);
      }
    });


  };

  const handleRequestToPublicGroup = async (group) => {
    try {
      const currentUser = window.COMMON.getCurrentUserInformation();
      const groupId = group._id;
      //send request api
      const sendRequestParams = {
        groupId
      };
      // auto accepted if group is public
      const requestResult = await window.COMMON.mutation(GroupChatGRAPHQL.MUTATION_REQUEST_GROUP_RELATIONSHIP, sendRequestParams);
      let requestStatus = -1;
      if (requestResult && requestResult.data) {
        requestStatus = requestResult.data.requestGroupRelationship;
      }
      if (requestStatus !== 1) return;

      //add member to firebase
      const addMemberToFirebaseParams = {
        groupId,
        member: currentUser.id
      };
      const addMemberResult = await window.COMMON.mutation(ChatGRAPHQL.MUTATION_ADD_MEMBER_TO_FIREBASE, addMemberToFirebaseParams);
      let addMemberStatus = -1;
      if (addMemberResult && addMemberResult.data) {
        addMemberStatus = addMemberResult.data.addMemberToFirebase;
      }
      if (addMemberStatus !== 1) return;
      onRequestToPublicGroupSuccess(group, currentUser);
      clearSearch();
    }
    catch (error) {
      window.COMMON.showErrorLogs('ChatPage.handleRequestToPublicGroup');
    }
  };
  const handleRequestToPrivateGroup = async (group) => {
    try {
      const groupId = group._id;
      //send request api
      const sendRequestParams = {
        groupId
      };
      const requestResult = await window.COMMON.mutation(GroupChatGRAPHQL.MUTATION_REQUEST_GROUP_RELATIONSHIP, sendRequestParams);
      let requestStatus = -1;
      if (requestResult && requestResult.data) {
        requestStatus = requestResult.data.requestGroupRelationship;
      }
      if (requestStatus !== 1) return;
      socket.emit('join-group-request',{groupId});
      window.COMMON.showMessage('success', 'MSG_CODE_059', window.I18N('MSG_CODE_059'));
    }
    catch (error) {
      window.COMMON.showErrorLogs('ChatPage.handleRequestToPrivateGroup');
    }
  };

  const fetchDataFriendAndSuggest = async () => {
    fetchDataFriend();
    getRelatedAccounts()
  };



  const clearSearch = () => {
    const radioValue = filterRef.current.radio;
    if (radioValue === 0) {
      setSearchUserValue('');
      setFetchSearchUserData(initFetchSearchUserData);
      if (headerChatLeftSideRef.current) {
        headerChatLeftSideRef.current.resetSearchUserInput();
      }
    }

    if (radioValue === 1) {
      setSearchGroupValue('');
      setFetchSearchGroupData(initFetchSearchGroupData);
      if (headerChatLeftSideRef.current) {
        headerChatLeftSideRef.current.resetSearchGroupInput();
      }
    }



  };




  const handleFilterRadio = (value) => {
    setFilter((current) => {
      return {
        ...current,
        radio: value
      };
    });
  };

  const handleFetchIndividualGroupChat = () => {

    if (fetchIndividualGroupChatData.loading === true) return;

    setFetchIndividualGroupChatData((current) => {
      return {
        ...current,
        loading: true

      };
    });
    getIndividualGroupChatList();
  };

  const getIndividualGroupChatList = () => {
    const filter = {
      limit: fetchIndividualGroupChatData.limit
    };

    if (individualGroupChatList.length > 0) {
      const lastGroupChat = individualGroupChatList[individualGroupChatList.length - 1];
      filter.lastTimestamp = lastGroupChat.timestamp;
    }
    socket.emit(
      'get-individual-group-chat-list',
      filter,
      handleGetIndividualGroupChatListError
    );

  };
  const handleGetIndividualGroupChatListEvent = (data) => {
    onFetchIndividualGroupChatListSuccess(data);
    setFetchIndividualGroupChatData((current) => {
      const isHasMore = data.length >= current.limit;
      return {
        ...current,
        loading: false,
        isHasMore
      };
    });

  };

  const handleGetIndividualGroupChatListError = (message) => {
    setFetchIndividualGroupChatData((current) => {
      return {
        ...current,
        loading: false,
        isHasMore: false
      };
    });
  };

  const handleFetchManyGroupChat = () => {
    //if have not fetch blocked groups yet, then wait
    if (initFetchBlockedGroups === false || fetchManyGroupChatData.loading === true) return;
    setFetchManyGroupChatData((current) => {
      return {
        ...current,
        loading: true
      };
    });
    getManyGroupChatList();
  };

  const getManyGroupChatList = () => {
    const filter = {
      limit: fetchManyGroupChatData.limit
    };
    if (manyGroupChatList.length > 0) {
      const lastGroupChat = manyGroupChatList[manyGroupChatList.length - 1];
      filter.lastId = lastGroupChat.id;
    }
    socket.emit(
      'get-many-group-chat-list',
      filter,
      handleGetManyGroupChatListError
    );
  };

  const handleGetManyGroupChatListEvent = (data) => {
    onFetchManyGroupChatListSuccess(data);
    setFetchManyGroupChatData((current) => {
      const isHasMore = data.length >= current.limit;
      return {
        ...current,
        loading: false,
        isHasMore
      };
    });
  };

  const handleGetManyGroupChatListError = () => {
    setFetchManyGroupChatData((current) => {
      return {
        ...current,
        loading: false,
        isHasMore: false
      };
    });
  };


  return <CustomChatLeftSide>
    <HeaderChatLeftSide
      options={options}
      filter={filter}
      onFilter={handleFilter}
      onSearchUser={handleSearchUser}
      onSearchGroup={handleSearchGroup}
      onNewClick={onNewGroupChat}
      chatLeftSideHeaderRadioData={chatLeftSideHeaderRadioData}
      onFilterRadio={handleFilterRadio}
      ref={headerChatLeftSideRef}
	  friends={friends}
	  onSearchUserResultClick={handleClickOnSearchUserResult}
    />

    <BodyChatLefttSide
      searchUserValue={searchUserValue}
      searchUserResult={searchUserResult}
      searchRelatedResult= {searchRelatedResult}
      filter={filter}
      onSearchUserResultClick={handleClickOnSearchUserResult}
      individualGroupChatList={individualGroupChatList}
      onFetchIndividualGroupChat={handleFetchIndividualGroupChat}
      fetchIndividualGroupChatLoading={fetchIndividualGroupChatData.loading}
      isHasMoreIndividualGroupChat={fetchIndividualGroupChatData.isHasMore}
      onGroupChatClick={onShowGroupChat}
      searchUserLoading={fetchSearchUserData.loading}
      groupChatSelected={groupChatSelected}
      searchGroupValue={searchGroupValue}
      onFetchManyGroupChatList={handleFetchManyGroupChat}
      fetchManyGroupChatLoading={fetchManyGroupChatData.loading}
      isHasMoreManyGroupChat={fetchManyGroupChatData.isHasMore}
      manyGroupChatList={manyGroupChatList}
      searchGroupResult={searchGroupResult}
      searchGroupLoading={fetchSearchGroupData.loading}
      searchRelatedLoading={fetchSearchRelatedData.loading}
      fetchDataFriendAndSuggest= {fetchDataFriendAndSuggest}
      handleSelectUserProfile={handleSelectUserProfile}
      userProfileSelect={userProfileSelect}
      
    />

    <Modal
      id={CONFIRM_MODAL_ID}
      title={confirmModalData.title}
      saveBtn={window.I18N('btn_confirm')}
      saveEvent={confirmModalData.saveEvent}
    />

  </CustomChatLeftSide>;
});

export default ChatLeftSide;