import React, { useState, useEffect, useCallback } from 'react';
import { MDBRow, MDBCol, MDBInput, MDBCardImage, MDBIcon, MDBInputGroup } from 'mdbreact';
import RadioBox from '../../components/RadioBox';
import FormModal from '../../components/FormModal';
import InputFile from '../../components/InputFile';
import { Heading4Styled } from '../../../styles/styled-components/Text/TextSTyled';
import Select from 'react-select';
import { UserSearchContainerStyled } from '../../../styles/styled-components/Chat/ChatGroupFormModal';
import CircleAvatar from '../../components/CircleAvatar';
import useSearchDebounce from '../../../hooks/useSearchDebounce';
import AccountGRAPHQL from '../../../graphql/Account';
import LoadingSpinner from '../../components/LoadingSpinner';
import EmptyDataText from '../../components/EmptyDataText';

const ChatGroupFormModal = ({ modalId, companies, friends, dataGroupChat, onClose, onFormChange, onSaveForm, initMember }) => {
  const accountType = localStorage.getItem(window.CONSTANT.ACCOUNT_TYPE);
  //only super admin can create public group
  const types = accountType === window.CONSTANT.SUPER_ADMIN ? [
    { value: 'PUBLIC', label: window.I18N('public_groups'), icon: 'globe-asia' },
    { value: 'PRIVATE', label: window.I18N('private_groups'), icon: 'lock' }
  ] : [
    { value: 'PRIVATE', label: window.I18N('private_groups'), icon: 'lock' }
  ];
  const [filter, setFilter] = useState({ company: null, radio: types[0]?.value ?? '' });
  const [searchUserResult, setSearchUserResult] = useState([]);
  const [chosenUsers, setChosenUsers] = useState([]);
  const [searchUserValue, setSearchUserValue] = useState('');
  const searchDebounce = useSearchDebounce();
  const [searchUserLoading, setSearchUserLoading] = useState(false);

  useEffect(() => {
    if (!window.COMMON.checkRoleIsSystemOrInfluencer()) {
      const currentUser = window.COMMON.getCurrentUserInformation();
      filter.company = {
        value: currentUser.company,
        code: currentUser.companyType,
        label: ''
      };
    }
    return () => {

    };
  }, []);

  useEffect(() => {
    return () => {
      setSearchUserValue('');
      setSearchUserResult([]);
    };
  }, [filter.company]);

  useEffect(() => {
    if (initMember) {
      setChosenUsers((current) => {
        return [
          ...current,
          initMember
        ];
      });
    }
    return () => {};
  },[initMember]);

  const clearData = () => {
    setSearchUserValue('');
    setSearchUserResult([]);
    setChosenUsers([]);
    filter.radio = 'PUBLIC';
    if (window.COMMON.checkRoleIsSystemOrInfluencer()) {
      filter.company = null;
    }
  };

  const handleSearchValueChange = (value) => {
    searchDebounce(async () => {
      let newSearchResult = [];
      setSearchUserLoading(true);
      try {
        if (value.trim().length > 0) {
          newSearchResult = await findUsers(value);
        }
      }
      catch (error) {
      }
      setSearchUserResult(newSearchResult);
      setSearchUserLoading(false);
    }, 300);
  };

  const findUsers = async (nameValue) => {
    //fetch user follow name and api from api
    let usersFromCompany = [];
    if (filter.company) {
      const companyType = filter.company.code;
      const companyId = filter.company.value;
      const paramsToFilterAccount = {
        input: {
          name: nameValue,
		      email: nameValue,
		      phone: nameValue,
          nickname: nameValue,
          organization: companyId,
          organizationType: companyType,
          page: 1,
          pageSize: 50
        }
      };
      const result = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_WITH_FILTER, paramsToFilterAccount, false);
      if (result && result.data) {
        usersFromCompany = result.data.getAccountsWithFilter.data;
        //remove current user
        usersFromCompany = usersFromCompany.filter((item) => item._id !== localStorage.getItem(window.CONSTANT.ID));
      }

    }
    const usersFromCompanyIds = usersFromCompany.map((item) => item._id);
    //combine user from api and friend
    let final = [...usersFromCompany];
    friends.data.forEach((item) => {
      if (window.COMMON.checkIncludesText(item.name, nameValue) && !usersFromCompanyIds.includes(item._id)) {
        final.push(item);
      }
    });
    //remove chosen users
    const chosenUsersIds = chosenUsers.map((item) => item._id);
    final = final.filter((item) => !chosenUsersIds.includes(item._id));
    return final;
  };


  const saveGroupChat = (event) => {
    if (!window.COMMON.checkFormValidation('form', event)) {
      return ;
    }
    onSaveForm(chosenUsers);
    clearData();
  };
  const handleChange = (event) => {
    const key = event.target.name;
    let value;
    if (event.target.className.indexOf('input-number') > -1) {
      value = parseInt(event.target.value);
    } else {
      value = event.target.value;
    }
    onFormChange([key], [value]);
  };
  const handleFile = async (file) => {
    //upload to db
    await window.uploadFile([file]);

    //upload to firebase
    await window.uploadFirebase(file, (downloadURL) => {
      onFormChange(['image'], [downloadURL]);
    });
  };
  const handleFilter = (event, attr) => {
    setFilter((current) => {
      return {
        ...current,
        [attr]: event
      };
    });
  };

  const handleOnClickUserSearchResult = (clickedItem) => {

    setSearchUserResult((current) => {
      const newData = current.filter((item) => item._id !== clickedItem._id);
      return [
        ...newData
      ];
    });
    setChosenUsers((current) => {
      const checkedItem = current.find((item) => item._id === clickedItem._id);
      if (!checkedItem) {
        current.push(clickedItem);
      }
      return [...current];
    });
  };

  const handleOnClickChosenUser = (clickedItem) => {
    setChosenUsers((current) => {
      const newData = current.filter((item) => item._id !== clickedItem._id);
      return [
        ...newData
      ];
    });
    setSearchUserResult((current) => {
      const checkedItem = current.find((item) => item._id === clickedItem._id);
      if (!checkedItem && window.COMMON.checkIncludesText(clickedItem.name, searchUserValue)) {
        current.push(clickedItem);
      }
      return [...current];
    });
  };
  const handleRadio = useCallback((value) => {
    filter.radio = value;
    setFilter({ ...filter });
    onFormChange(['type'], [value]);

    // eslint-disable-next-line
    }, [filter]);


  const handleClose = () => {
    clearData();
    onClose();
  };



  return <FormModal id={modalId} title={window.I18N( dataGroupChat._id ? 'update_group_chat' : 'create_group_chat')} saveEvent={saveGroupChat} className={`${!dataGroupChat._id ? 'modal-lg' : ''}`} onClose={handleClose}>
    <form id="form" className="needs-validation" noValidate>
      <MDBRow>
        <MDBCol xs='12' md={`${!dataGroupChat._id ? '6' : '12'}`}>
          <MDBInput outline containerClass="mt-0" type="text" name="name" label={window.I18N('group_name') + ' *'} value={dataGroupChat.name} onChange={handleChange} maxLength="100" pattern="\S(.*\S)?" required>
            <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
          </MDBInput>
                   
          {!dataGroupChat._id && <div className="d-inline-block mr-2">
            <RadioBox data={types} value={filter.radio} className="m-0" handleChange={handleRadio}></RadioBox>
          </div>}

          <MDBInput outline type="textarea" name="description" label={window.I18N('description')} value={dataGroupChat.description} onChange={handleChange} maxLength="5000"></MDBInput>
          <div className="text-center">
            <MDBCardImage className="input-image-icon" src={window.COMMON.setValueImage(dataGroupChat.image)} hover overlay="white-slight" /><br></br>
            <InputFile id="file-group-form" icon="upload" handleFile={handleFile} accept="image/*" description="Icon (64 x 64) pixels"></InputFile>
          </div>
        </MDBCol>
        {!dataGroupChat._id && <MDBCol xs='12' md="6">
          <Heading4Styled>{window.I18N('add_members')}</Heading4Styled>
          {window.COMMON.checkRoleIsSystemOrInfluencer() && <div className="input-group input-group-transparent mb-2">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <MDBIcon fa="true" icon="filter" className="fa-fw" />
              </span>
            </div>
            <Select value={filter.company} options={companies} className="md-form m-0" placeholder={window.I18N('companies')} onChange={(event) => { handleFilter(event, 'company'); }} isSearchable closeMenuOnSelect={true} />
          </div>}
          <MDBInputGroup
            value={searchUserValue}
            id={'search_user_for_member'}
            name={'search_user_for_member'}
            type="text"
            hint={window.I18N('search_user')}
            onChange={(event) => {
              setSearchUserValue(event.target.value);
              handleSearchValueChange(event.target.value);
            }}
            prepend={
              <span className="input-group-text" id="btn-search">
                <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
              </span>
            }
          />
          {chosenUsers.length > 0 && <UserSearchContainerStyled>
            <div className="title">{window.I18N('chosen_users')}</div>
            <div className="list">
              {chosenUsers.map((item, index) => {
                return <div className="item" key={`${item._id}-chosen-user-form-${index}`} onClick={() => handleOnClickChosenUser(item)}>
                  <CircleAvatar src={item.avatar} />
                  <div className="content">{item.nickname}</div>
                  <MDBIcon fa="true" icon="times" className="fa-fw"></MDBIcon>
                </div>;
              })}
            </div>
          </UserSearchContainerStyled>}
          {searchUserValue.trim().length > 0 && <UserSearchContainerStyled>
            <div className="title">{window.I18N('list_users')}</div>
            {searchUserLoading === true && <div className="w-100 d-flex justify-content-center"><LoadingSpinner /></div>}
            {(searchUserLoading === false && searchUserResult.length <= 0) && <div className="w-100 d-flex justify-content-center"><EmptyDataText /></div>}
            {searchUserResult.length > 0 && <div className="list">
              {searchUserResult.map((item, index) => {
                return <div className="item" key={`${item._id}-result-user-search-form-${index}`} onClick={() => handleOnClickUserSearchResult(item)}>
                  <CircleAvatar src={item.avatar} />
                  <div className="content">{item.nickname}</div>
                </div>;
              })}

            </div>}
          </UserSearchContainerStyled>}



        </MDBCol>}

      </MDBRow>
    </form>
  </FormModal >;
};

export default ChatGroupFormModal;