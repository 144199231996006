import { MDBCol, MDBRow } from 'mdbreact';
import React from 'react';
import { StepCardStyled } from '../../../styles/styled-components/Survey/NewSurveyPageStyled';
import { Heading4Styled, SLightEmphasis } from '../../../styles/styled-components/Text/TextSTyled';


const NewSurveyPageSteps = ({steps, onStepClick, hideSteps = false, style = {}}) => {

  return <MDBRow className='wow fadeIn animated' data-wow-delay="1s">
    {steps.map((item, index) => {
      return <MDBCol key={`${index}-${item.id}`}>
        <StepCardStyled
          status={item.status}
          onClick={() => onStepClick(index)}
        >
          {!hideSteps && <div>
            <SLightEmphasis className='content'>{window.I18N('step')} {index + 1}</SLightEmphasis>
          </div>}
          <div style={{...style}}>
            <Heading4Styled className='content'>{window.I18N(item.name)}</Heading4Styled>
          </div>

        </StepCardStyled>
      </MDBCol>;
    })}

  </MDBRow>;



};

export const NewSurveyPageStepStatus = {
  WAITING: 'waiting',
  PROCESSING: 'processing',
  COMPLETED: 'completed'
};


export default NewSurveyPageSteps;