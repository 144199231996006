import gql from 'graphql-tag';

const QUERY_SELF_ASSESSMENT = gql`
  query($company: String) {
    getSelfAssessments(company: $company) {
      _id
      code
      name
      description
      type
      period
      companies {
        _id
        type
        information {
          name
        }
      }
      groups {
        _id
        name {
          text
          code
        }
      }
      users
      startDate
      endDate
      status
      createdDate
      createdBy {
        name
        avatar
      }
    }
  }
`;

const QUERY_SELF_ASSESSMENT_WITH_FILTER = gql`
  query($input: SelfAssessmentFilter!) {
    getSelfAssessmentsWithFilter(input: $input) {
      total
      page
      pageSize
      data {
        _id
        code
        name
        description
        type
        period
        companies {
          _id
          type
          information {
            name
          }
        }
        groups {
          _id
          name {
            text
            code
          }
        }
        users
        startDate
        endDate
        status
		step
        createdDate
        createdBy {
          name
          avatar
        }
      }
    }
  }
`;

const QUERY_SELF_ASSESSMENT_WITH_FILTER_BY_TAB = gql`
  query($input: SelfAssessmentFilter!, $tab: ViewTab!) {
    getSelfAssessmentsWithFilterByTab(input: $input, tab: $tab) {
      total
      page
      pageSize
      data {
        _id
        code
        name
        description
        type
        period
        companies {
          _id
          type
          information {
            name
          }
        }
        groups {
          _id
          name {
            text
            code
          }
        }
        users
        startDate
        endDate
        status
		step
        createdDate
        createdBy {
          name
          avatar
        }
      }
    }
  }
`;

const QUERY_FIND_SELF_ASSESSMENT = gql`
  query($id: String!) {
    findSelfAssessment(id: $id) {
      _id
      share {
        _id
        influencers {
          _id
          name
          avatar
        }
        companies {
          _id
          information {
            name
          }
        }
        groups {
          _id
          name {
            text
            code
          }
        }
        createdDate
        createdBy {
          name
          avatar
        }
      }
    }
  }
`;

const QUERY_COUNT_SELF_ASSESSMENT = gql`
  query($input: SelfAssessmentSummaryInput!) {
    countHistorySelfAssessment(input: $input) {
      _id
      count
    }
  }
`;

const QUERY_COUNT_SELF_ASSESSMENT_BY_WEEK = gql`
  query($input: SelfAssessmentSummaryInput!) {
    countHistorySelfAssessmentByWeek(input: $input) {
      _id
      count
    }
  }
`;

const QUERY_COUNT_SELF_ASSESSMENT_BY_YEAR = gql`
  query($companyIds: [String]) {
    countHistorySelfAssessmentByYear(companyIds: $companyIds) {
      _id
      count
    }
  }
`;

const MUTATION_ADD_SELF_ASSESSMENT = gql`
  mutation($input: SelfAssessmentInput!) {
    addSelfAssessment(input: $input)
  }
`;

const MUTATION_EDIT_SELF_ASSESSMENT = gql`
  mutation($input: SelfAssessmentInput!) {
    editSelfAssessment(input: $input)
  }
`;

const MUTATION_DELETE_SELF_ASSESSMENT = gql`
  mutation($id: String!) {
    deleteSelfAssessment(id: $id)
  }
`;

const QUERY_ATTRIBUTE_REPORT = gql`
  query {
    getAttributeReports {
      _id
      code
      type
      attribute
      indexText
      title
      value
      index
      unit
      isBold
      color
      parentCode
    }
  }
`;

const MUTATION_ADD_ATTRIBUTE_REPORT = gql`
  mutation($input: AttributeReportInput!) {
    addAttributeReport(input: $input)
  }
`;

const MUTATION_EDIT_ATTRIBUTE_REPORT = gql`
  mutation($input: AttributeReportInput!) {
    editAttributeReport(input: $input)
  }
`;

const MUTATION_DEL_ATTRIBUTE_REPORT = gql`
  mutation($id: String!) {
    deleteAttributeReport(id: $id)
  }
`;

const QUERY_ATTRIBUTE_REPORT_COMPANY = gql`
  query($company: String!) {
    findAttributeReportCompany(company: $company) {
      _id
      items {
        attribute {
          _id
          type
          value
        }
        value
      }
    }
  }
`;

const MUTATION_ADD_ATTRIBUTE_REPORT_COMPANY = gql`
  mutation($input: AttributeReportCompanyInput!) {
    addAttributeReportCompany(input: $input)
  }
`;

const MUTATION_EDIT_ATTRIBUTE_REPORT_COMPANY = gql`
  mutation($input: AttributeReportCompanyInput!) {
    editAttributeReportCompany(input: $input)
  }
`;

const QUERY_SELF_ASSESSMENT_FOR_INFLUENCER = gql`
query($selfAssessment: String!) {
  getSelfAssessmentInfluencers(selfAssessment: $selfAssessment) {
    _id
    companies {
      _id
    }
    influencers {
      _id
      avatar
      name
	  email
    }
    createdDate
  }
}
`;

const MUTATION_ASSIGN_SELF_ASSESSMENT_INFLUENCER = gql`
  mutation($input: SelfAssessmentInfluencerInput!) {
    assignSelfAssessmentInfluencer(input: $input)
  }
`;

const QUERY_TASK_SELF_ASSESSMENT = gql`
  query($company: String!) {
    getTaskSelfAssessments(company: $company) {
      _id
      selfAssessment {
        _id
        name
        startDate
        endDate
        createdBy {
          _id
          name
          email
          influencer
          {
            _id
          }
        }
      }
      items {
        group {
          _id
          code
          name {
            code
            text
          }
          description {
            code
            text
          }
          image
          hashtag
        }
        isCompleted
        users
        updatedBy {
          name
          avatar
        }
        updatedDate
      }
	  company {
        information {
          _id
          email
          name
        }
      }
      status
      createdDate
    }
  }
`;

const QUERY_TASK_SELF_ASSESSMENT_FOR_INFLUENCER = gql`
  query($user: String) {
    getTaskSelfAssessmentsForInfluencer(user: $user) {
      _id
      selfAssessment {
		_id
        name
        startDate
        endDate
		createdBy {
			_id
			name
			email
		}
      }
      company {
        _id
        information {
			_id
			avatar
			email
			name
			nickname
			companyType {
			  _id
			  value
			}
			companySize {
			  _id
			  value
			}
			companyIndustry {
			  _id
			  code
			  value
			}
			nation {
			  _id
			  code
			  name
			}
			city {
			  _id
			  code
			  name
			}
			state {
			  _id
			  code
			  name
			}
			phone
			address
			bio
			taxCode
			organizationCode
			zipcode
			website
			representative
			representativeEmail
			representativePhone
			representativePosition
        }
      }
      items {
        group {
          _id
          code
          name {
            code
            text
          }
          description {
            code
            text
          }
          image
          hashtag
        }
        isCompleted
        updatedBy{
          name
          avatar
        }
        updatedDate
      }
      status
      createdDate
      influencers{
        _id
        name
      }
    }
  }
`;

const QUERY_TASK_SELF_ASSESSMENT_BY_SCHEDULE = gql`
  query($selfAssessment: String!) {
    getTaskSelfAssessmentsBySchedule(selfAssessment: $selfAssessment) {
      _id
      type
	  influencers {
		_id
		name
		email
	  }
	  selfAssessment {
		_id
		name
	  }
      company {
        _id
        information {
          avatar
          name
		  email
        }
      }
      items {
        group {
          _id
          name {
            code
            text
          }
        }
        isCompleted
        updatedBy {
          name
          avatar
        }
        updatedDate
      }
      status
      createdDate
    }
  }
`;


const QUERY_FIND_TASK_SELF_ASSESSMENT_BY_ID = gql`
  query($id: String!) {
    findTaskSelfAssessment(id: $id) {
      _id
      items {
        group {
          _id
          name {
            code
            text
          }
        }
        isCompleted
        feedback {
          question {
            _id
            type
            question {
              code
              text
            }
          }
          answers {
            _id
            parentCode
          }
          answerText
          note
          consultantEvaluation
          consultantNote
          documents {
            id
            name
            size
            ext
            type
          }
        }
      }
	  selfAssessment {
		name
	  }
      company {
        _id
        information {
          _id
          avatar
          email
          name
          nickname
          companyType {
            _id
            value
          }
          companySize {
            _id
            value
          }
          companyIndustry {
            _id
            code
            value
          }
          nation {
            _id
            code
            name
          }
          city {
            _id
            code
            name
          }
          state {
            _id
            code
            name
          }
          phone
          address
          bio
          taxCode
          organizationCode
          zipcode
          website
          representative
          representativeEmail
          representativePhone
          representativePosition
        }
      }
      influencers {
        _id
        name
        influencer{
          _id
        }
      }
    }
  }
`;

const QUERY_TASK_SELF_ASSESSMENT_BY_SELF_ASSESSMENT = gql`
  query($selfAssessment: String!, $company: String!) {
    getTaskSelfAssessmentBySelfAssessments(selfAssessment: $selfAssessment, company: $company) {
      _id
      type
      items {
        group {
          _id
        }
        isCompleted
        feedback {
          question {
            _id
          }
          answers {
            _id
          }
          answerText
          note
          documents {
            id
            name
            size
            ext
            type
          }
        }
      }
      updatedBy {
        avatar
        name
      }
      updatedDate
    }
  }
`;

const MUTATION_SEND_FEEDBACK_TASK_SELF_ASSESSMENT = gql`
  mutation($input: TaskSelfAssessmentInput!) {
    updateFeedbackTaskSelfAssessment(input: $input)
  }
`;

const MUTATION_NOTE_TASK_SELF_ASSESSMENT_FOR_INFLUENCER = gql`
  mutation($input: [TaskSelfAssessmentInput!]) {
    updateFeedbackTaskSelfAssessmentForInfluencer(input: $input)
  }
`;

const MUTATION_ASSIGN_TASK_SELF_ASSESSMENT = gql`
  mutation($id: String!, $group: String!, $users: [String!]) {
    assignTaskSelfAssessment(id: $id, group: $group, users: $users)
  }
`;

const MUTATION_SUBMIT_TASK_SELF_ASSESSMENT = gql`
  mutation($id: String!, $company: String) {
    submitTaskSelfAssessment(id: $id, company: $company)
  }
`;

const MUTATION_REWORK_TASK_SELF_ASSESSMENT = gql`
  mutation($id: String!) {
    reworkTaskSelfAssessment(id: $id)
  }
`;

const MUTATION_COMPLETE_TASK_SELF_ASSESSMENT = gql`
  mutation($id: String!) {
    completeTaskSelfAssessment(id: $id)
  }
`;

const QUERY_TASK_SHARE_SELF_ASSESSMENT = gql`
  query($user: String) {
    getTaskShareSelfAssessments(user: $user) {
      _id
	  users {
		_id
		name
		email
	  }
	  selfAssessment {
		_id
        code
        name
        description
        type
        period
        startDate
        endDate
		createdDate
		status
		createdBy {
			_id
			name
			email
		}
      }
      company {
		_id
        information {
          name
          avatar
        }
      }
      group {
        _id
        code
        name {
          code
          text
        }
        description {
          code
          text
        }
        image
        hashtag
      }
	  feedback {
        question {
          _id
          type
		  question {
			code
			text
		  }
        }
        answers {
          _id
          parentCode
        }
        answerText
        note
        documents {
          id
          name
          size
          ext
          type
        }
      }
      users {
        _id
        avatar
        nickname
      }
      status
	  leader {
		_id
		name
		email
	  }
	  isApproved
      createdDate
	  updatedDate
	  updatedBy {
		_id
		name
		email
	  }
    }
  }
`;

const QUERY_TASK_SHARE_SELF_ASSESSMENT_BY_SELF_ASSESSMENT = gql`
  query($selfAssessment: String!, $company: String!, $group: String!) {
    getTaskShareSelfAssessmentsBySelfAssessment(selfAssessment: $selfAssessment, company: $company, group: $group) {
      _id
      feedback {
        question {
          _id
        }
        answers {
          _id
        }
        answerText
        note
        documents {
          id
          name
          size
          ext
          type
        }
      }
      leader {
        _id
        name
      }
      company {
        _id
      }
      isApproved
      influencer {
        _id
      }
      updatedBy {
        avatar
        name
      }
      updatedDate
    }
  }
`;

const QUERY_TASK_SHARE_SELF_ASSESSMENT_BY_ID = gql`
  query($id: String!) {
    findTaskShareSelfAssessment(id: $id) {
      _id
      feedback {
        question {
          _id
          type
        }
        answers {
          _id
          parentCode
        }
        answerText
        note
        documents {
          id
          name
          size
          ext
          type
        }
      }
    }
  }
`;

const QUERY_COUNT_ASSESSMENT = gql`
  query($input: CountAssessmentFilter!) {
    countAssessment(input: $input)
  }
`;

const QUERY_COUNT_SELF_ASSESSMENT_SUM = gql`
  query($input: SelfAssessmentCount){
    countSelfAssessments(input: $input){
      total
      news
      running
      expired
      completed
    }
  }
  `

const QUERY_COUNT_SELF_ASSESSMENT_SUM_BY_TAB = gql`
  query($input: SelfAssessmentCount, $tab: ViewTab!){
    countSelfAssessmentsByTab(input: $input, tab: $tab){
      total
      news
      running
      expired
      completed
    }
  }
  `

const QUERY_ASSESSMENT_DATA_CHART = gql`
  query($input: CountAssessmentFilter!) {
    assessmentDataChart(input: $input) {
      status
      createdDate
    }
  }
`;

const QUERY_ASSESSMENT_DATA_INSIGHT = gql`
  query($input: CountAssessmentFilter!) {
    assessmentDataInsightChart(input: $input) {
      status
      groups{
        name{
          code
          text
        }
      }
      createdDate
      type
      period
      task {
        type
        _id
        status
        company{
          _id
          information {
            name
          }
        }
      }
    }
  }
`
const QUERY_RUNNING_SELF_ASSESSMENTS = gql`
  query($id: String) {
    getRunningSelfAssessments(company: $id) {
      _id
      code
      name
      description
      type
      period
      companies {
        _id
        type
        information {
          name
        }
      }
      groups {
        _id
        name {
          text
          code
        }
      }
      users
      startDate
      endDate
      status
      step
      createdDate
      createdBy {
        name
        avatar
      }
    }
  }
`;

const MUTATION_SHARE_SELF_ASSESSMENT = gql`
  mutation($input: SelfAssessmentShareInput!) {
    shareSelfAssessment(input: $input)
  }
`;

const MUTATION_DELETE_SHARE_SELF_ASSESSMENT = gql`
  mutation($id: String!, $child: String!) {
    deleteShareSelfAssessment(id: $id, child: $child)
  }
`;

const MUTATION_ASSIGN_TASK_SHARE_SELF_ASSESSMENT = gql`
  mutation($id: String!, $users: [String!]) {
    assignTaskShareSelfAssessment(id: $id, users: $users)
  }
`;

const MUTATION_ASSIGN_LEADER_TASK_SHARE_SELF_ASSESSMENT = gql`
  mutation($id: String!, $leader: String!) {
    assignLeaderTaskShareSelfAssessment(id: $id, leader: $leader)
  }
`;

const MUTATION_SAVE_TASK_SHARE_SELF_ASSESSMENT = gql`
  mutation($input: TaskShareSelfAssessmentInput!) {
    saveTaskShareSelfAssessment(input: $input)
  }
`;

const MUTATION_REJECT_TASK_SELF_ASSESSMENT = gql`
  mutation($input: TaskSelfAssessmentInput!) {
    rejectTaskSelfAssessment(input: $input)
  }
`;

const MUTATION_UPDATE_TASK_SHARE_SELF_ASSESSMENT = gql`
  mutation($input: ShareSelfAssessmentInput!) {
	updateTaskShareSelfAssessment(input: $input)
  }
`;

const QUERY_CHECK_FULLY_CONCLUSION = gql`
  query CheckFullyConclusion ($assessmentId: String!) {
    checkFullyConclusionByAssessment(assessmentId: $assessmentId)
  }
`; 

const SelfAssessmentGRAPHQL = {
  QUERY_SELF_ASSESSMENT,
  QUERY_SELF_ASSESSMENT_WITH_FILTER,
  QUERY_SELF_ASSESSMENT_WITH_FILTER_BY_TAB,
  QUERY_FIND_SELF_ASSESSMENT,
  QUERY_COUNT_SELF_ASSESSMENT,
  QUERY_COUNT_SELF_ASSESSMENT_BY_WEEK,
  QUERY_COUNT_SELF_ASSESSMENT_BY_YEAR,
  MUTATION_ADD_SELF_ASSESSMENT,
  MUTATION_EDIT_SELF_ASSESSMENT,
  MUTATION_DELETE_SELF_ASSESSMENT,
  QUERY_ATTRIBUTE_REPORT,
  MUTATION_ADD_ATTRIBUTE_REPORT,
  MUTATION_EDIT_ATTRIBUTE_REPORT,
  MUTATION_DEL_ATTRIBUTE_REPORT,
  QUERY_ATTRIBUTE_REPORT_COMPANY,
  MUTATION_ADD_ATTRIBUTE_REPORT_COMPANY,
  MUTATION_EDIT_ATTRIBUTE_REPORT_COMPANY,
  QUERY_SELF_ASSESSMENT_FOR_INFLUENCER,
  MUTATION_ASSIGN_SELF_ASSESSMENT_INFLUENCER,
  QUERY_TASK_SELF_ASSESSMENT,
  QUERY_TASK_SELF_ASSESSMENT_FOR_INFLUENCER,
  QUERY_TASK_SELF_ASSESSMENT_BY_SCHEDULE,
  QUERY_FIND_TASK_SELF_ASSESSMENT_BY_ID,
  QUERY_TASK_SELF_ASSESSMENT_BY_SELF_ASSESSMENT,
  MUTATION_SEND_FEEDBACK_TASK_SELF_ASSESSMENT,
  MUTATION_NOTE_TASK_SELF_ASSESSMENT_FOR_INFLUENCER,
  MUTATION_ASSIGN_TASK_SELF_ASSESSMENT,
  MUTATION_SUBMIT_TASK_SELF_ASSESSMENT,
  MUTATION_REWORK_TASK_SELF_ASSESSMENT,
  MUTATION_COMPLETE_TASK_SELF_ASSESSMENT,
  QUERY_TASK_SHARE_SELF_ASSESSMENT,
  QUERY_TASK_SHARE_SELF_ASSESSMENT_BY_SELF_ASSESSMENT,
  QUERY_TASK_SHARE_SELF_ASSESSMENT_BY_ID,
  MUTATION_SHARE_SELF_ASSESSMENT,
  MUTATION_DELETE_SHARE_SELF_ASSESSMENT,
  MUTATION_ASSIGN_TASK_SHARE_SELF_ASSESSMENT,
  MUTATION_ASSIGN_LEADER_TASK_SHARE_SELF_ASSESSMENT,
  MUTATION_SAVE_TASK_SHARE_SELF_ASSESSMENT,
  QUERY_COUNT_ASSESSMENT,
  QUERY_ASSESSMENT_DATA_CHART,
  MUTATION_REJECT_TASK_SELF_ASSESSMENT,
  MUTATION_UPDATE_TASK_SHARE_SELF_ASSESSMENT,
  QUERY_RUNNING_SELF_ASSESSMENTS,
  QUERY_CHECK_FULLY_CONCLUSION,
  QUERY_COUNT_SELF_ASSESSMENT_SUM,
  QUERY_COUNT_SELF_ASSESSMENT_SUM_BY_TAB,
  QUERY_ASSESSMENT_DATA_INSIGHT
};


export default SelfAssessmentGRAPHQL;
