import { Button } from '@material-ui/core'
import AccountGRAPHQL from '../../../../../graphql/Account'
import RelationshipGRAPHQL from '../../../../../graphql/Relationship';
import React, { useState } from 'react'
import eventName from '../../../../../common/events';
import { useTracking } from 'react-tracking';

const ItemFriendSuggest = ({friendStatus, accountId, fetchDataFriendAndSuggest}) => {
  const { trackEvent } = useTracking();
  const [relationshipStatus,setRelationshipStatus] = useState(friendStatus)

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const onBtnAddFriendClick = (event)=>{
    event.stopPropagation();
    handleAddFriend(accountId)
  }
  const onBtnApproveFriendClick = (event)=>{
    event.stopPropagation();
    handleAcceptFriend(accountId)
  }

  const handleAcceptFriend = async (accountId) => {
    try {
      const params = {
        input: {
          id: accountId,
          name: localStorage.getItem(window.CONSTANT.FULL_NAME)
        }
      };
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_ACCEPT_FRIEND, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
        fetchDataFriendAndSuggest();
        createTrackingEvent(eventName.ACCEPT_FRIEND);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('ChatLeftSide.acceptFriend');
    }
  };
  const onBtnDeclineFriendClick = (event)=>{
    event.stopPropagation();
    declineFriend(accountId)
  }
  const declineFriend = async (accountId) => {
    try {
      const params = {
        accountId: accountId
      };
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_DECLINE_FRIEND, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        fetchDataFriendAndSuggest();
        setRelationshipStatus('');
        createTrackingEvent(eventName.DECLINE_FRIEND);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('ItemFriendSuggest.declineFriend');
    }
  };

  const handleAddFriend = async (accountId)=>{
    try {
      const params = {
        input: {
          name: localStorage.getItem(window.CONSTANT.FULL_NAME),
          id: accountId
        }
      };
      const result = await window.COMMON.mutation(RelationshipGRAPHQL.MUTATION_ADD_FRIEND, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
        fetchDataFriendAndSuggest();
        setRelationshipStatus('RECIEVER');
      }
    } catch (err){
      window.COMMON.showErrorLogs('ItemFriendSuggest.declineFriend');
    }
  }
  
  return (
    <>
      {!relationshipStatus && <Button variant='outlined' color='primary' className='ml-auto text-capitalize' onClick={onBtnAddFriendClick}>{window.I18N('add_friend')}</Button>}
      {relationshipStatus==="SENDER" && 
      <>
        <Button variant='outlined' color='primary' className='ml-auto text-capitalize' onClick={onBtnApproveFriendClick}>{window.I18N('btn_yes')}</Button>
        <Button variant='outlined' color='secondary' className='text-capitalize' onClick={onBtnDeclineFriendClick}>{window.I18N('btn_reject')}</Button>
      </>
      }
      
      {relationshipStatus==="RECIEVER" && <Button variant='outlined' className='ml-auto text-capitalize' disabled>{window.I18N('friend_sent')}</Button>}

    </>
    
  )
}

export default ItemFriendSuggest