import React, { useState, useEffect, useRef, useCallback, useMemo} from "react";
import { PDFExport } from '@progress/kendo-react-pdf'
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBIcon, MDBRow } from "mdbreact";
import { useTracking } from 'react-tracking';
import { Button, Collapse, Grid, Tooltip } from "@material-ui/core";
import Table from "./Table";
import DashboardPowerBIGraphQL from "../../graphql/DashboardPowerBI";
import eventName from "../../common/events";
import Modal from "./Modal";
import ListGroupsAndItemsBI from "./ListGroupsAndItemsBI";
import Pagination from "./Pagination";
import DataChartDashboardBI from "./DataChartDashboardBI";
import ExportExcel from "./ExportExcel";

const TABLE_TEMPLATE = 'TABLE'
const CHART_TEMPLATE = 'CHART'

const ActionButton = ({title, icon, onClick}) => {
    return (
        <div className="ml-1" style={{color: '#222222'}}>
            <Tooltip title={title} placement="top" arrow>
                <Button color="inherit" size="small" onClick={onClick} style={{minWidth: '0px', padding: '5px 4px'}}>
                    <div className="flex-center">
                        <MDBIcon fa="true" icon={icon} className="fa-fw" style={{fontSize: '13px'}}/>
                    </div>
                </Button>
            </Tooltip>
        </div>
    )
}

function TemplatesTable({
        idx,
        configTemplatesTable, 
        dataTemplatesTable, 
        refechDataTemplates,
        templateType = TABLE_TEMPLATE, 
        isPreviewMode = false, 
        handleClosePreview = () => {},
        handleSaveTemplate = () => {}
    }
){
    const { trackEvent } = useTracking();

    const [data, setData] = useState({})
    const [dataChart, setDataChart] = useState([])
    const [dataView, setDataView] = useState({})
    const [dataExportExcel, setDataExportExcel] = useState([])
    const [pagination, setPagination] = useState({total: 1, pageSize: 10, currentPage: 0})
    const [companiesAndInfluencers, setCompaniesAndInfluencers] = useState({})
    const [openEdit, setOpenEdit] = useState(false)
    const [openViewData, setOpenViewData] = useState(false)
    const [isCollapse, setIsCollapse] = useState(false)
    const [select, setSelect] = useState({companies: [{value: 'all', label: 'All Company', code: 'DEFAULT'}], influencers: []});
    const [date, setDate] = useState({
        startDate: window.COMMON_DATE.addDaysFromNow(-90),
        endDate: new Date()
    });

    const exportExcelRef = useRef({})
    const pdfExportRef = useRef(null)
    const listGroupsItemsRef = useRef({})
    
    const configTableViewData = useMemo(()=>{
       const checkDataPreview = isPreviewMode ? dataTemplatesTable.dataTable : dataTemplatesTable
       return checkDataPreview?.items?.map((item) => window.COMMON.createCol(`${item?.title}`, ``,'py-2','',`${item.dataKey}`, 'TEXT'))
    },[dataView, dataTemplatesTable])

   
    const configExportData = useMemo(()=>{
        const checkDataPreview = isPreviewMode ? dataTemplatesTable.dataTable : dataTemplatesTable
        const header = checkDataPreview?.items?.map(data => data?.title)
        const attrs = checkDataPreview?.items?.map(data => data?.dataKey)
        return {
            header,
            attrs
        }
    },[dataView, dataTemplatesTable])

    const handleDatePicker = (event, attr) => {
        date[attr] = event;
        setDate({ ...date });
    };

    const handleSelect = (event, id, attr) => {
        window.COMMON.checkSelectValidation(id, event);
        select[attr] = event
        const allValueIndex = event ? event.findIndex((item) => item.value === 'all') : -1;
        let newEvent;
        if (allValueIndex >= 0) {
          if (allValueIndex === 0 && event.length > 1) {
            event.splice(allValueIndex, 1);
            newEvent = event;
          }
          else if (allValueIndex > 0) {
            newEvent = [event[allValueIndex]];
          }
          else {
            newEvent = event;
          }
          select[attr] = newEvent;
        }
  
        setSelect((current) => {
          return {
            ...current,
            ...select
          };
        }); 
    };

    const handleChangePage = useCallback((index) => {
        pagination.currentPage = index;
        if(templateType === TABLE_TEMPLATE){
            handleGetDataTemplatesTable()
            setPagination((prev) => ({...prev, currentPage: index}))
        }
        // eslint-disable-next-line
    }, [pagination]);

    const createTrackingEvent = (event) => {
        return trackEvent({
            name: event,
            createdAt: new Date().toISOString()
        });
    };

    
    useEffect(()=>{
        let timerId = null
        if(Object.keys(dataTemplatesTable).length !== 0){
          timerId = setTimeout(()=>{
                !isPreviewMode && initDataTable();
                getDataCompany()
            },100)
        }
        
        return () => {
            timerId && clearTimeout(timerId)
        }

    },[dataTemplatesTable])

    useEffect(()=>{
       const timerId = setTimeout(() => {
            if(openViewData && !isPreviewMode){
                handleGetDataWithTemplateType()     
            }
            if(isPreviewMode){
                handleOpenViewData()
                handleGetDataWithTemplateType()
            }
        }, 100)

        return () => {
            clearTimeout(timerId)
        }

    },[openViewData, companiesAndInfluencers])

    useEffect(() => {
        if(dataExportExcel.length > 0){
            exportExcelRef.current[idx].handleClick()
        }
    },[dataExportExcel])

    const getParamsQueryDataTemplates = (pageExportExcel = null) => {
        return {
            page: pageExportExcel ?? pagination.currentPage + 1,
            limit: pagination.pageSize,
            _id: dataTemplatesTable?._id,
            companyIds: select.companies[0].value === 'all' ? (Object.keys(companiesAndInfluencers).length !== 0 ? companiesAndInfluencers.companies[1].options.map(company =>company.value) : []) : select.companies.map(company => company.value),
            startDate: new Date(date.startDate),
            endDate: new Date(date.endDate),
            timeZone: window.COMMON.getTimeZone()
        }
    }

    const getDataTemplatesTable = async (pageExportExcel = null, isLoading = true) => {
        const params = {
            input: getParamsQueryDataTemplates(pageExportExcel)
        }
        const result = await window.COMMON.query(DashboardPowerBIGraphQL.QUERY_TEMPLATES_TABLE_DATA, params, isLoading) 
        return result.data.getDashboardTemplateTableData
    }

    const getDataPreviewTemplates = async (pageExportExcel = null, isLoading = true) => {
        const { groups, items, matchFilters, fromToFilters } = dataTemplatesTable.dataView
        const { _id, ...restParams } = getParamsQueryDataTemplates(pageExportExcel)
        const params = {
            input: {
                ...restParams,
                groups,
                items,
                matchFilters,
                fromToFilters,
            }
        }
        if(params.input.companyIds.length === 0){
            return []
        }
        const result = await window.COMMON.query(DashboardPowerBIGraphQL.QUERY_PREVIEW_DATA_TEMPLATES, params, isLoading)
        return result.data.previewDashboardTemplateTableData
    }

    const handleGetDataTemplatesTable = async () => {
        window.resetDataTable(`table-templates-view-${idx}`)
        try {
            const result = isPreviewMode ? await getDataPreviewTemplates() : await getDataTemplatesTable()
            if(result.length === 0){
                return
            }
            checkEmptyAndSetDataView(result)
        } catch (error) {
            window.COMMON.showErrorLogs('DashboardPowerBI.getDataTemplatesTable');    
        }
        window.initDataTable(`table-templates-view-${idx}`,false) 
        window.COMMON.showModal(`#modal-view-template-${idx}`);
        
    }

    const checkEmptyAndSetDataView = (dataViewTable) => {
        const checkEmptyItem = dataViewTable.data.filter(item => Object.values(item)[0] !== '' && Object.keys(item).length !== 0)
        setDataView({...dataViewTable, data: checkEmptyItem})
        setPagination((prev)=>({...prev, total: dataViewTable.total}))
    }

    
    const handleGetDataViewChartTemplates = () => {
        try {
            const { companyIds, startDate, endDate } = getParamsQueryDataTemplates()
            const { groups, items } = dataTemplatesTable?.dataView ?? {}
            const paramsView = isPreviewMode ? { groups, items } : { _id: dataTemplatesTable._id }
            if(companyIds.length === 0){
                return
            }
            const params = {
                input: {
                    companyIds,
                    startDate,
                    endDate,
                    ...paramsView
                }
            }
            isPreviewMode ? getPreviewDataChart(params) : getDataChartTemplates(params)
        } catch (error) {
            window.COMMON.showErrorLogs('DashboardPowerBI.getDataPreviewChartTemplates');
        }
        window.COMMON.showModal(`#modal-view-template-${idx}`)
    }
    
    const getPreviewDataChart = async (params) => {
        const result = await window.COMMON.query(DashboardPowerBIGraphQL.QUERY_PREVIEW_DATA_CHART_TEMPLATES, params)
        setDataChart(result.data.previewDashboardTemplateChartData)  
    }

    const getDataChartTemplates = async (params) => {
        const result = await window.COMMON.query(DashboardPowerBIGraphQL.QUERY_DATA_CHART_TEMPLATES, params)
        setDataChart(result.data.getDashboardTemplateChartData)
    }

    const getDataCompany = async () => {
        try {
          const newOptions = {};
          await window.COMMON.getDataCompany();
          const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_COMPANY));
          if (dataCompany && dataCompany.length > 0) {
            const dataInfluencers = window.COMMON.getArrsInArrs(window.CONSTANT.INFLUENCER, dataCompany, 'type');
            newOptions.companies = dataCompany.filter(company => company.accountType === 'COMPANY').map(company => ({ value: company.company._id, label: company.name }))
            newOptions.influencers = window.COMMON.createDataSelect(dataInfluencers, '_id', 'information.name') 
          }
          const formatSelectOptions = {
            companies: [
                {
                    label: window.I18N('default').toLowerCase(),
                    options: [{value: 'all', label: 'All Company', code: 'DEFAULT'}],

                },
                {
                    label: window.I18N('companies').toLowerCase(),
                    options: newOptions.companies.map(company => ({...company, code: 'COMPANY'})),
                }
            ],
            influencers: []
          }
          setCompaniesAndInfluencers({...formatSelectOptions});
    
        } catch (error) {
          window.COMMON.showErrorLogs('DashboardPowerBI.getDataCompany');
        }
      };

    const initDataTable = ()=>{
        window.resetDataTable(`table-templates-${idx}`)
        const newData = {
            ...dataTemplatesTable,
            dataTable: dataTemplatesTable.groups?.map(group => ({
                id: group._id,
                group : group.title,
                items: dataTemplatesTable.items?.map(item => item.dataKey?.split("_")[0].toLowerCase() === formatTitle(group.title) ? {
                    content: {
                        0: {
                            text: dataTemplatesTable.matchFilters?.some(el => el?.dataKey === item?.dataKey) ? `${item.title}: 
                            ${dataTemplatesTable.matchFilters[dataTemplatesTable.matchFilters.findIndex(el => el?.dataKey === item?.dataKey)].values?.map(value => value)?.join(", ")}` : item.title
                        }
                    } 
                } : null)?.filter(el => el)}))
            }          
        setData(newData)
        window.initDataTable(`table-templates-${idx}`, false)     
    }

    const formatTitle = (title) => {
        if(title === 'Profile') {
            title = 'Company'
        }else if(title === 'Account Management'){
            title = 'Account'
        }else if(title === 'E-Learning'){
            title = 'Elearning'
        }else if(title === 'Grivance'){
            title = 'Grievance'
        }
        const newStr = title?.split(" ").join('').toLowerCase();
        return newStr
    }

    const handleDeleteTemplates = () => {
        window.COMMON.showModal('#modal-delete');
        window.deleteMethod = deleteTemplates;
    }

    const handleUpdateTemplates = (event) => {
        if (!window.COMMON.checkFormValidation('form-powerdashboard-edit', event)) {
            window.COMMON.showMessage('warning', 'MSG_CODE_029', window.MSG.MSG_CODE_029);
            return;
        }
        const checkItemsSelected = listGroupsItemsRef.current[idx].checkDataItemsSelected()
        if(checkItemsSelected){
            window.COMMON.showMessage('info', 'MSG_CODE_012', 'Dữ liệu không được cập nhật do chưa có mục nào được chọn');
            return;
        }
        window.COMMON.showModal('#modal-save');
        window.saveMethod = updateTemplates;
        window.closeSaveMethod = checkItemsSelected ? () => {} : closeEdit 
    }

    const deleteTemplates = async () => {
        try {
            const params = {
                ids: [data._id]
            }
            const result = await window.COMMON.mutation(DashboardPowerBIGraphQL.MUTATION_DELETE_TEMPLATES,params)
            if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)){
                refechDataTemplates();
                createTrackingEvent(eventName.DELETE_TEMPLATES);
            }
        } catch (error) {
            window.COMMON.showErrorLogs('DashboardPowerBI.deleteTemplates');    
        }
    }

    const updateTemplates = async ()=> {
        try {
            const { templateType, ...restParams } = handleGetDataTemplates()
            const params = {
                _id: data._id,
                input: {
                    ...restParams
                }
            }
            const result = await window.COMMON.mutation(DashboardPowerBIGraphQL.MUTATION_UPDATE_TEMPLATES, params)
            if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
                refechDataTemplates()
                closeEdit();
                createTrackingEvent(eventName.UPDATE_TEMPLATES);
            }
        } catch (error) {
            window.COMMON.showErrorLogs('DashboardPowerBI.updateTemplates');    
        }
    }

    const handleGetDataTemplates = () => {
        const data = listGroupsItemsRef.current[idx].getDataTemplates()
        return data
    }

    const handleGetDataWithTemplateType = () => {
        pagination.currentPage = 0
        templateType === TABLE_TEMPLATE ? handleGetDataTemplatesTable() : handleGetDataViewChartTemplates()
        setPagination(prev => ({...prev, currentPage: 0}))
    }

    const handleOpenEditTemplate = () => {
        setOpenEdit(true)
        window.COMMON.showModal(`#modal-edit-template-${idx}`);
    }

    const closeEdit = () =>{
        setOpenEdit(false)
        window.COMMON.hideModal(`#modal-edit-template-${idx}`);
    }

    const handleCheckDataChange = () => {
        const dataUpdate = handleGetDataTemplates()
        const dataMatchFilter = data.matchFilters.map(data => data.values).flat(2)
        const dataUpdateMatchFilter = dataUpdate.matchFilters.map(data => data.values).flat(2)
        const checkLengthDataChange = (data.items.length !== dataUpdate.items.length) || (dataMatchFilter.length !== dataUpdateMatchFilter.length && templateType === TABLE_TEMPLATE) 
        const checkValueDataChange = data.items.some(item => !dataUpdate.items.includes(item._id)) || (dataMatchFilter.some(value => !dataUpdateMatchFilter.includes(value)) && templateType === TABLE_TEMPLATE)
        return checkLengthDataChange || checkValueDataChange
    }

    const handleCloseEditTemplate = () => {
        //Check for data changes before closing modal
        const checkDataChange = handleCheckDataChange()
        checkDataChange ? handleUpdateTemplates() : closeEdit()   
    }

    const handleOpenViewData = ()=>{
        setOpenViewData(true)
    }

    const handleCloseViewData = ()=>{
        setOpenViewData(false)
        isPreviewMode && handleClosePreview()
        setDataView({})
        window.resetDataTable(`table-templates-view-${idx}`)
        window.COMMON.hideModal(`#modal-view-template-${idx}`);
    }

    const handleShowTemplates = () => {
        setIsCollapse(!isCollapse)
    }

    const exportPDFChart = () => {
        if(pdfExportRef.current){
            pdfExportRef.current.save()
        }
    }

    const handleExportData = async () => {
        try {
            const pageSize = pagination.pageSize
            const totalPage = Math.floor(dataView.total/pageSize) + 1
            let dataExport = []
            if(templateType === TABLE_TEMPLATE){
                //Export Excel for data table
                for(let page = 1; page <= totalPage; page++){
                    const result = isPreviewMode ? getDataPreviewTemplates(page, false) : getDataTemplatesTable(page, false)
                    dataExport.push(result)
                }
                window.showLoading()
                const listDataExport = await Promise.all(dataExport)
                setDataExportExcel(listDataExport.map(item => item.data).flat())
                window.hideLoading()
            }else{
                //Export PDF for chart
                exportPDFChart()
            }
            window.COMMON.showMessage('success', 'MSG_CODE_070', window.MSG.MSG_CODE_070);         
        } catch (error) {
            window.COMMON.showMessage('error', 'MSG_CODE_071', window.MSG.MSG_CODE_071)
            window.COMMON.showErrorLogs('DashboardPowerBI.exportDataTemplates');
        }
    }

    return ( 
        <div className="mb-2">
            {!isPreviewMode && 
            <Collapse in={isCollapse} collapsedSize={48}>
                <MDBRow>
                    <MDBCol
                        xl="12"
                        className="mb-2 wow fadeIn"
                    >
                        <MDBCard>
                                <MDBCardHeader className="flex-center justify-content-between border-bottom">
                                    <div className="flex-center justify-content-start">
                                        <label className={`label-box ${templateType === TABLE_TEMPLATE ? "label-primary" : "label-warning"} p-1 m-0 mr-2`}>
                                            <MDBIcon fas icon={templateType === TABLE_TEMPLATE ? "table" : "chart-bar"} className="mr-1"/>
                                            {templateType === TABLE_TEMPLATE ? 'Table' : 'Chart'}
                                        </label>
                                        <label className={`label-box label-success p-1 m-0 mr-2`}>
                                            <MDBIcon fas icon="calendar-alt" className="mr-1"/>
                                            {window.COMMON.formatDateSingleData(data, 'createdAt')}
                                        </label>
                                        {data.name} 
                                    </div>
                                    <div className="flex-center">
                                        <ActionButton title={window.I18N('view_data')} icon={'eye'} onClick={handleOpenViewData} />
                                        <ActionButton title={window.I18N('edit_templates')} icon={'edit'} onClick={handleOpenEditTemplate} />
                                        <ActionButton title={window.I18N('delete_templates')} icon={'trash-alt'} onClick={handleDeleteTemplates} />
                                        <ActionButton title={isCollapse ? window.I18N('hide_templates') : window.I18N('show_templates')} icon={isCollapse ? 'caret-up' : 'caret-down'} onClick={handleShowTemplates} />
                                    </div>
                                </MDBCardHeader>
                                <MDBCardBody>
                                    <Table                   
                                        id={`table-templates-${idx}`}
                                        className="table table-striped"
                                        config={configTemplatesTable}
                                        data={data.dataTable}
                                    />
                                </MDBCardBody>
                            </MDBCard> 
                    </MDBCol>
                </MDBRow> 
            </Collapse>
            }
             <Modal
                id={`modal-edit-template-${idx}`}
                className='modal-xl'
                title={'Edit Template - ' + data.name}
                saveEvent={handleUpdateTemplates} 
                handleCloseModal={handleCloseEditTemplate}            
            >
            {openEdit &&
                <form id="form-powerdashboard-edit" className="needs-validation" noValidate>
                    <ListGroupsAndItemsBI 
                        ref={el => listGroupsItemsRef.current[idx] = el}
                        isEdit={true}
                        dataTemplate={data}
                        templateType={templateType}
                    />
                </form>
            }
            </Modal>
            <Modal
                id={`modal-view-template-${idx}`}
                className='modal-xl'
                title={`${isPreviewMode ? window.I18N('preview_data') : `${window.I18N('view_data')} - ${data.name}`}`}
                hideSave
                hideSaveTemplate={!isPreviewMode}
                handleCloseModal={handleCloseViewData}
                hideCloseBtn
                actionTemplate
                statusExportBtn={templateType === CHART_TEMPLATE ? 'warning' : ''}
                exportEvent={handleExportData}
                iconExportBtn={templateType === CHART_TEMPLATE ? 'file-pdf' : ''}
                saveBtn={window.I18N('save_templates')}
                exportBtn={templateType === TABLE_TEMPLATE ? window.I18N('export') : window.I18N('export_pdf')}
                saveTemplateEvent={()=>{handleSaveTemplate(); handleCloseViewData()}}
            >
                <MDBCard>
                    <MDBCardHeader className="border-bottom">
                        <MDBRow className="flex-center mb-2 py-2">
                            <MDBCol>
                                <h6 className="font-weight-bold" style={{fontSize: '0.8rem'}}>{window.I18N('companies')}</h6>
                                <Select id="select-companies" value={select.companies} options={companiesAndInfluencers.companies} className="md-form m-0" placeholder={window.I18N('choose_option')} onChange={(event) => handleSelect(event, 'select-companies', 'companies')} isSearchable isMulti closeMenuOnSelect={false}/>
                            </MDBCol>
                            <MDBCol>
                                <h6 className="font-weight-bold" style={{fontSize: '0.8rem'}}>{window.I18N('from_date')}</h6>
                                <div className="input-group input-group-transparent">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                                        </span>
                                    </div>
                                    <DatePicker placeholderText={window.I18N('start_date') + ' *'} className="form-control" dateFormat="dd/MM/yyyy" selected={date.startDate} onChange={(event) => handleDatePicker(event, 'startDate')} showYearDropdown showMonthDropdown required/>              
                                </div>
                            </MDBCol>
                            <MDBCol>
                                <h6 className="font-weight-bold" style={{fontSize: '0.8rem'}}>{window.I18N('to_date')}</h6>
                                <div className="input-group input-group-transparent">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                                        </span>
                                    </div>
                                    <DatePicker placeholderText={window.I18N('end_date') + ' *'} className="form-control" dateFormat="dd/MM/yyyy" selected={date.endDate} onChange={(event) => handleDatePicker(event, 'endDate')} showYearDropdown showMonthDropdown required/>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="flex-center justify-content-between">
                            <MDBCol sm="12 text-right">
                                <MDBBtn color="primary" className="m-0" onClick={handleGetDataWithTemplateType}>
                                    <MDBIcon fa="true" icon="filter" className="fa-fw" /> {window.I18N('filter')}
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </MDBCardHeader>
                    {templateType === TABLE_TEMPLATE ? 
                    <div>
                        <MDBCardBody style={{width: '200%'}}>
                                {openViewData && <Table                   
                                    id={`table-templates-view-${idx}`}
                                    className="table table-striped"
                                    noAnimated={true}
                                    config={configTableViewData}
                                    data={dataView.data}
                                />}
                        </MDBCardBody>
                        <Grid item sm={12}>
                            <Pagination total={pagination.total} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={handleChangePage}></Pagination>
                        </Grid>
                    </div> : 
                       <PDFExport ref={pdfExportRef} fileName={`Data_Chart_${new Date().getTime()}.pdf`}>
                           { dataChart.map((chart, index) => (
                                <div key={chart.dataKey} className="bg-white rounded-lg p-4 mb-2">
                                    <MDBRow>
                                        <MDBCol>
                                                <div className="bg-white rounded-lg p-4 mb-3">
                                                    <DataChartDashboardBI 
                                                        index={index} 
                                                        title={chart.title} 
                                                        data={chart.data} 
                                                        custom={
                                                            {
                                                                horizontalBarSize: 300,
                                                                barSize: 300, 
                                                                pieSize: 350,
                                                                containerSize: 90 //percent
                                                            }}
                                                    />
                                                </div>
                                        </MDBCol>
                                    </MDBRow>
                                </div>
                            )) }
                       </PDFExport>                
                    }
                </MDBCard>
            </Modal>
            <ExportExcel label='' ref={el => exportExcelRef.current[idx] = el} className="btn-sm m-0" style={{display: 'none'}} data={dataExportExcel} isTemplate={false} configDataTemplate={configExportData} dataKey="DATA_TEMPLATE"/>
        </div>
     );
}

export default React.memo(TemplatesTable);