import gql from 'graphql-tag';
const QUERY_MESSAGE = gql`
  query ($id: String!) {
    getMessage(id: $id) {
      content
    }
  }
`;

const QUERY_GET_INDIVIDUAL_GROUP_CHAT = gql`
  query ($userId: String!, $anotherUserId: String!) {
    getIndividualGroupChat(userId: $userId, anotherUserId: $anotherUserId) {
      id
    }
  }
`;

const QUERY_GET_USER_RELATIONSHIP = gql`
  query ($status: String!, $userId: String!) {
    getUserRelationship(status: $status, userId: $userId) {
      _id
      receiver {
          _id
          avatar
          email
          name
          nickname
          avatar
          phone
          gender
          birthday
          address
          accountType
          taxCode
          bio
          organizationCode
          zipcode
          gender
          website
          representative
          representativeEmail
          representativePhone
          representativePosition
          role {
            _id
          }
          companies {
            _id
          }
          unions {
            _id
          }
          companyType {
            _id
            value
          }
          companySize {
            _id
            value
          }
          companyIndustry {
            _id
            value
          }
          nation {
            _id
            name
          }
          city {
            _id
            name
          }
          state {
            _id
            name
          }
          status
          createdDate
      }
      status
      createdBy {
          _id
          avatar
          email
          name
          nickname
          avatar
          phone
          gender
          birthday
          address
          accountType
          taxCode
          bio
          organizationCode
          zipcode
          gender
          website
          representative
          representativeEmail
          representativePhone
          representativePosition
          role {
            _id
          }
          companies {
            _id
          }
          unions {
            _id
          }
          companyType {
            _id
            value
          }
          companySize {
            _id
            value
          }
          companyIndustry {
            _id
            value
          }
          nation {
            _id
            name
          }
          city {
            _id
            name
          }
          state {
            _id
            name
          }
          status
          createdDate
      }
  }
  }
`;
const QUERY_GET_USER_TO_INVITE_TO_GROUP = gql`
  query($input: SearchMembersToInviteInput!){
    getUsersToInviteToGroup(input: $input){
      _id
      avatar
      email
      name
      nickname
      avatar
      phone
      gender
      birthday
      address
      accountType
      taxCode
      bio
      organizationCode
      zipcode
      gender
      website
      representative
      representativeEmail
      representativePhone
      representativePosition
      role {
        _id
      }
      companies {
        _id
      }
      unions {
        _id
      }
      companyType {
        _id
        value
      }
      companySize {
        _id
        value
      }
      companyIndustry {
        _id
        value
      }
      nation {
        _id
        name
      }
      city {
        _id
        name
      }
      state {
        _id
        name
      }
      status
      createdDate
    }
  }
`;
const QUERY_GET_GROUP_WITH_FILTER = gql`
  query($input: SearchGroupWithFilterInput!){
    getGroupChatWithFilter(input: $input){
      _id
      name
      code
    	description
    	image
      type
      createdDate
      createdBy {
        name
      }
    }
  }
`;
const QUERY_GET_RELATIONSHIP_WTIH_GROUP = gql`
  query($groupId: String!){
    getRelationshipWithGroup(groupId: $groupId){
      _id
      user {
        _id
        avatar
        nickname
      }
      status
      createdDate
    }
  }
`;
const QUERY_GET_BLOCKED_GROUP_RELATIONSHIP = gql`
  query{
    getBlockedGroupRelationship{
      _id
      user {
        _id
        avatar
        nickname
      }
      group{
        _id
      }
      status
      createdDate
    }
  }
`;

const QUERY_GET_COUNT_GROUP_RELATIONSHIP = gql`
  query($groupId: String!, $status: RelationshipEnum){
    getCountGroupRelationShip(groupId: $groupId, status: $status)
  }
`;

const MUTATION_ADD_FIREBASE_USER = gql`
  mutation ($input: UserInput!) {
    addFirebaseUser(input: $input)
  }
`;
const MUTATION_ADD_GROUP_RELATIONSHIP = gql`
  mutation ($groupId: String!, $users: [String!]) {
    addGroupRelationship(groupId: $groupId, users: $users)
  }
`;
const MUTATION_ADD_MEMBERS_FROM_RELATIONSHIP_TO_FIREBASE = gql`
  mutation ($groupId: String!) {
    addMembersFromRelationshipToFirebase(groupId: $groupId)
  }
`;
const MUTATION_ADD_MEMBER_TO_FIREBASE = gql`
  mutation ($groupId: String!, $member: String!) {
    addMemberToFirebase(groupId: $groupId, member: $member)
  }
`;
const MUTATION_ADD_GROUP_CHAT_WITH_FIREBASE = gql`
  mutation($input: GroupChatWithFirebaseInput!) {
    addGroupChatWithFirebase(input: $input) {
      _id
    }
  }
`;
const MUTATION_DELETE_MEMBERS_FROM_FIREBASE = gql`
  mutation($groupId: String!, $members: [String]) {
    deleteMembersFromFirebase(groupId: $groupId, members: $members)
  }
`;
const MUTATION_DELETE_GROUP_RELATIONSHIPS = gql`
  mutation($groupId: String!, $users: [String]) {
    deleteGroupRelationships(groupId: $groupId, users: $users)
  }
`;

const MUTATION_PUSH_MESSAGES = gql`
  mutation ($input: PushMessageInput!) {
    pushMessages(input: $input)
  }
`;

const ChatGRAPHQL = {
  QUERY_MESSAGE,
  QUERY_GET_INDIVIDUAL_GROUP_CHAT,
  QUERY_GET_USER_RELATIONSHIP,
  QUERY_GET_USER_TO_INVITE_TO_GROUP,
  QUERY_GET_GROUP_WITH_FILTER,
  QUERY_GET_RELATIONSHIP_WTIH_GROUP,
  QUERY_GET_BLOCKED_GROUP_RELATIONSHIP,
  QUERY_GET_COUNT_GROUP_RELATIONSHIP,
  MUTATION_ADD_FIREBASE_USER,
  MUTATION_ADD_GROUP_RELATIONSHIP,
  MUTATION_ADD_MEMBERS_FROM_RELATIONSHIP_TO_FIREBASE,
  MUTATION_ADD_MEMBER_TO_FIREBASE,
  MUTATION_ADD_GROUP_CHAT_WITH_FIREBASE,
  MUTATION_DELETE_MEMBERS_FROM_FIREBASE,
  MUTATION_DELETE_GROUP_RELATIONSHIPS,
  MUTATION_PUSH_MESSAGES
};

export default ChatGRAPHQL;