// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n    --default-background: #f0f2f5;\n    --box-shadow: 0px 2px 20px -1px rgba(0, 0, 0, 0.26);\n}\n\n.RecommendEventItem_recommend-container__2qnO3 {\n    border-radius: 8px;\n    cursor: pointer;\n    padding: 6px;\n    transition: all 0.2s ease-in;\n}\n\n.RecommendEventItem_recommend-container__2qnO3:hover {\n    background-color: var(--default-background);\n}\n\n.RecommendEventItem_event-name__QYcIE {\n    display: -webkit-box;\n    -webkit-line-clamp: 2;\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n}\n", ""]);
// Exports
exports.locals = {
	"recommend-container": "RecommendEventItem_recommend-container__2qnO3",
	"event-name": "RecommendEventItem_event-name__QYcIE"
};
module.exports = exports;
