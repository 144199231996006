import { MDBCol, MDBRow, MDBInput, MDBIcon } from "mdbreact";
import React, { useEffect, useImperativeHandle, useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import CompanyGRAPHQL from "../../../../../graphql/Company";
import RoleGRAPHQL from "../../../../../graphql/Role";

const SET_DISCOUNT_AND_TAX_STEP = 1
const YEAR = 'YEAR'

const INIT_DATA_SELECT = [
  {
    value: "",
    label: "",
  },
];

const DATA_TYPE = {
  companies: "Company",
  influencers: "Influencer",
  unions: "Union",
};

const SUBSCRIPTION_PERIOD = [
  {
    label: '1 YEAR',
    value: 1
  },
  {
    label: '2 YEARS',
    value: 2
  },
  {
    label: '3 YEARS',
    value: 3
  },
  {
    label: '4 YEARS',
    value: 4
  },
  {
    label: '5 YEARS',
    value: 5
  }
]

const SetPackageStep = React.forwardRef(({isEdit = false, dataEdit = {}, onNextStep = () => {}}, ref) => {
  const [refId, setRefId] = useState(isEdit ? dataEdit?.refId : '')
  const [oldNearestBill, setOldNearestBill] = useState({})
  const [dataCompanies, setDataCompanies] = useState({
    companies: [],
    influencers: [],
    unions: [],
  })

  const [dataSelect, setDataSelect] = useState({
    companies: [],
    influencers: [],
    unions: [],
    package: [],
    type: {
      companies: [],
      influencers: [],
    },
    subscriptionType: SUBSCRIPTION_PERIOD
  })

  const [select, setSelect] = useState({
    type: {
      value: "companies",
      label: "Company",
    },
    companies: INIT_DATA_SELECT,
    package: INIT_DATA_SELECT,
    subscriptionType: SUBSCRIPTION_PERIOD[0],
    companyInfo: [],
  })

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: window.COMMON_DATE.addYearsFromNow(1),
  })

  const handleDatePicker = (event) => {
    date.startDate = event
    date.endDate = window.COMMON_DATE.addYearsFromCustomDate(date.startDate, select.subscriptionType.value)
    setDate({ ...date });
  };

  useEffect(() => {
    const getCompanies = async () => {

      const [companies, packageCompany] = await Promise.all([
        window.COMMON.query(CompanyGRAPHQL.QUERY_COMPANY),
        window.COMMON.query(RoleGRAPHQL.QUERY_PACKAGE)
      ])

      const data = companies.data.getCompanies
      const dataPackage = packageCompany.data.queryPackage
       
      let dataSet = {}
      Object.entries(DATA_TYPE).forEach((item) => {
        const [key, type] = item;
        dataSet[key] = data.filter(
          (child) => child.type === type.toUpperCase()
        )
      })
 
      const dataSetPackage = {
        companies: dataPackage.filter((data) => data.role === window.CONSTANT.COMPANY),
        influencers: dataPackage.filter((data) => data.role === window.CONSTANT.INFLUENCER),
        unions: dataPackage.filter((data) => data.role === window.CONSTANT.COMPANY)
      }
      const attrsToConvert = {
        value: "_id",
        label: "name"
      }
      const dataToSelect = {
        companies: window.COMMON.convertDataToSelect(dataSet.companies),
        influencers: window.COMMON.convertDataToSelect(dataSet.influencers),
        unions: window.COMMON.convertDataToSelect(dataSet.unions),
        package: {
          companies: window.COMMON.convertDataToSelect(dataSetPackage.companies, attrsToConvert),
          influencers: window.COMMON.convertDataToSelect(dataSetPackage.influencers, attrsToConvert),
          unions: window.COMMON.convertDataToSelect(dataSetPackage.unions, attrsToConvert)
        },
        type: Object.entries(DATA_TYPE).map((item) => {
          const [key, type] = item;
          return {
            value: key,
            label: type
          }
        })
      }
      setDataSelect(prev => ({...prev, ...dataToSelect}))
      if(isEdit){
        const findCompanyEdit = data.find(company => company.information.email === dataEdit.receiver.email)
        let typeCompany = ''
        Object.entries(DATA_TYPE).forEach((dataType) => {
          const [key, type] = dataType
          if(type.toUpperCase() === findCompanyEdit.type){
              typeCompany = key
          }
        })
        const companyEdit = {
            type: typeCompany,
            data: findCompanyEdit
        }
        
        const dataCompanySelect = dataToSelect[companyEdit.type].find(data => data.label === companyEdit.data.information.name)
        const dataPackageSelect = dataToSelect.package[companyEdit.type].find(data => data.label === dataEdit.packages[0].name)
        setSelect((prev) => ({
          ...prev,
          type: dataToSelect.type.find(typeSelect => typeSelect.value === companyEdit.type),
          companies: dataCompanySelect,
          package: dataPackageSelect,
          companyInfo: companyEdit.data,
          subscriptionType: SUBSCRIPTION_PERIOD.find(type => type.value === dataEdit.packages[0].subscriptionValue)
        }))
      }else{
        setSelect((prev) => ({
          ...prev,
          companies: dataToSelect.companies[0],
          package: dataToSelect.package.companies.find(data => data.value === dataSet.companies[0].information.package._id),
          companyInfo: dataSet.companies[0]
        }))
      }   
      setDataCompanies({ ...dataSet, package: dataSetPackage })
    }
    getCompanies()
  }, [])

  useEffect(() => {
    if(!window.COMMON.isEmpty(select.companyInfo)){
      const getOldNearestBill = async () => {
        try {
          window.showLoading()
          const result = await window.COMMON.httpRequest({
              url: `${process.env.REACT_APP_URL_PAYMENT_API}/bills`,
              method: 'GET',
              input: {
                params: {
                  page: 1,
                  pageSize: 9999,
                  sort: 'desc'      
                }
              },
              requireToken: true,
              handleError: () => {
                  window.COMMON.showErrorLogs('setPackageStep.getBills');
              }
          })
          window.hideLoading()
          const filterDeletedBill = result.elements.filter(bill => !bill.isDeleted && bill.receiver.email === select.companyInfo.information.email)
          const oldBill = filterDeletedBill.shift()
          const expiresDateOfNearestBill = oldBill ? new Date(oldBill.packages[0].to) : new Date() 
          setOldNearestBill(oldBill || {}) 
          setDate({ startDate: expiresDateOfNearestBill, endDate: window.COMMON_DATE.addYearsFromCustomDate(expiresDateOfNearestBill, 1) })
          } catch (error) {
            window.COMMON.showErrorLogs('setPackageStep.getBills');
          }
      }
      getOldNearestBill()
    }
  },[select.companyInfo])

  const handleSelect = (type, item) => {
    if (type === "type") {
      const companyInfo = dataCompanies[item.value][0]
      const dataSet = {
        type: item,
        companies: dataSelect[item.value][0],
        package: dataSelect.package[item.value].find((itemPackage) => itemPackage.value === companyInfo.information.package._id),
        companyInfo
      }
      setSelect((prev) => ({...prev, ...dataSet }))
    } else if (type === "companies") {
      const companyInfo = dataCompanies[select.type.value].find((company) => company.information._id === item.value)
      const dataPackageSet = dataCompanies.package[select.type.value].find(
        (itemPackage) => itemPackage._id === companyInfo.information.package._id)
      setSelect((prev) => ({
        ...prev,
        companies: item,
        package: dataSelect.package[select.type.value].find((data) => data.value === dataPackageSet._id),
        companyInfo
      }))
    }else if(type === "subscriptionType") {
      setSelect((prev) => ({...prev, subscriptionType: item}))
      setDate(prev => ({...prev, endDate: window.COMMON_DATE.addYearsFromCustomDate(date.startDate, item.value)}))
    }
    else {
      setSelect((prev) => ({ ...prev, [type]: item }))
    }
  };

  const handleGetDataOnStep = (event, getDataOnly = false) => {
      if (!window.COMMON.checkFormValidation('form-create-bill', event)) {
        window.COMMON.showMessage('warning', 'MSG_CODE_010', window.I18N('MSG_CODE_010'))
        return
      }
      const { type, companyInfo, subscriptionType, package: packageCompany } = select
      const { name, email, phone, city, nation, state, address } = companyInfo.information

      const dataPackageCompany = dataCompanies.package[type.value].find((itemPackage) => itemPackage._id === packageCompany.value) || {}
      const packagePrice = dataPackageCompany.menus.reduce((acc, packages)=> acc += packages.menu.price, 0) || 0
    
      const data = {
          refId,
          receiver: {
            name,
            email,
            phone,
            address,
            country: nation.name,
            district: state.name,
            province: city.name,
          },
          packages: [{
            name: select.package.label,
            code: dataPackageCompany.code,
            subscriptionType: YEAR,
            subscriptionValue: subscriptionType.value,
            from: new Date(date.startDate), 
            to: new Date(date.endDate),
            menu: dataPackageCompany.menus.map(item => item.menu._id) || [],
            price: packagePrice 
          }],
          amount: packagePrice * subscriptionType.value,
          isReadySendBill: false
      }
      onNextStep(!getDataOnly ? SET_DISCOUNT_AND_TAX_STEP : null, data)
  }

  useImperativeHandle(ref, () => ({
    nextStep: () => {
      const getDataOnly = true
      const checkRefId = refId ? true : false
      handleGetDataOnStep(ref, getDataOnly)
      return checkRefId
    } 
  }))
  
  return (
    <form className="w-full bg-white rounded-lg p-5" id="form-create-bill">
      <h6 className="font-weight-bold mb-4" style={{ fontSize: "1rem" }}>
        {window.I18N('general_info')}
      </h6>
      <div className="px-3 w-full bg-white rounded-lg">
        <MDBRow className="mb-4">
          <MDBCol size="6">
            <h6 className="font-weight-bold" style={{ fontSize: "0.7rem" }}>
              {window.I18N('organization_type')}
            </h6>
            <Select
              value={select.type}
              options={dataSelect.type}
              className="md-form m-0"
              placeholder={window.I18N("choose_option")}
              onChange={(event) => handleSelect("type", event)}
              isSearchable
              isDisabled={isEdit}
            />
          </MDBCol>
          <MDBCol>
            <h6 className="font-weight-bold" style={{ fontSize: "0.7rem" }}>
              {window.I18N('ref_id')}
            </h6>
            <MDBInput
              value={refId}
              onChange={(e) => setRefId(e.target.value)}
              outline
              type="text"
              containerClass="m-0"
              className="md-form m-0"
              required
            />
            <div className="invalid-feedback">{window.I18N('MSG_CODE_010')}</div>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mb-4">
          <MDBCol>
            <h6 className="font-weight-bold" style={{ fontSize: "0.7rem" }}>
              {window.I18N('company_name')}
            </h6>
            <Select
              value={select.companies}
              options={dataSelect[select.type.value]}
              className="md-form m-0"
              placeholder={window.I18N("choose_option")}
              onChange={(event) => handleSelect("companies", event)}
              isSearchable
              isDisabled={isEdit}
            />
          </MDBCol>
          <MDBCol>
            <h6 className="font-weight-bold" style={{ fontSize: "0.7rem" }}>
              {window.I18N('company_phone')}
            </h6>
            <MDBInput
              outline
              type="text"
              containerClass="m-0"
              value={select.companyInfo.information?.phone}
              disabled={true}
              className="md-form m-0"
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className="mb-4">
          <MDBCol>
            <h6 className="font-weight-bold" style={{ fontSize: "0.7rem" }}>
              {window.I18N('company_email')}
            </h6>
            <MDBInput
              outline
              type="text"
              containerClass="m-0"
              disabled={true}
              className="md-form m-0"
              value={select.companyInfo.information?.email}
            />
          </MDBCol>
          <MDBCol>
            <h6 className="font-weight-bold" style={{ fontSize: "0.7rem" }}>
              {window.I18N('province')}
            </h6>
            <MDBInput
              disabled
              outline
              type="text"
              containerClass="m-0"
              className="md-form m-0"
              value={select.companyInfo.information?.city?.name}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className="mb-4">
          <MDBCol>
            <h6 className="font-weight-bold" style={{ fontSize: "0.7rem" }}>
              {window.I18N('country')}
            </h6>
            <MDBInput
              disabled
              outline
              type="text"
              containerClass="m-0"
              className="md-form m-0"
              value={select.companyInfo.information?.nation?.name}
            />
          </MDBCol>
          <MDBCol>
            <h6 className="font-weight-bold" style={{ fontSize: "0.7rem" }}>
              {window.I18N('address')}
            </h6>
            <MDBInput
              disabled
              outline
              type="text"
              containerClass="m-0"
              className="md-form m-0"
              value={select.companyInfo.information?.address}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className="mb-4">
          <MDBCol size="6">
            <h6 className="font-weight-bold" style={{ fontSize: "0.7rem" }}>
              {window.I18N('district')}
            </h6>
            <MDBInput
              disabled
              outline
              type="text"
              containerClass="m-0"
              className="md-form m-0"
              value={select.companyInfo.information?.state?.name}
            />
          </MDBCol>
        </MDBRow>
      </div>
      {/* Package Information */}
      <h6 className="font-weight-bold mb-4" style={{ fontSize: "1rem" }}>
        {window.I18N('package_info')}
      </h6>
      <div className="px-3 w-full bg-white rounded-lg">
        <MDBRow className="mb-4">
          <MDBCol>
            <h6 className="font-weight-bold" style={{ fontSize: "0.7rem" }}>
              {window.I18N('package_name')}
            </h6>
            <Select
              value={select.package}
              options={dataSelect.package[select.type.value]}
              className="md-form m-0"
              placeholder={window.I18N("choose_option")}
              onChange={(event) => handleSelect("package", event)}
              isSearchable
            />
          </MDBCol>
          <MDBCol>
            <h6 className="font-weight-bold" style={{ fontSize: "0.7rem" }}>
              {window.I18N('subscription_period')}
            </h6>
            <Select
              value={select.subscriptionType}
              options={dataSelect.subscriptionType}
              className="md-form m-0"
              placeholder={window.I18N("choose_option")}
              onChange={(event) => handleSelect('subscriptionType',event)}
              isSearchable
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />
          </MDBCol>
        </MDBRow>

        <MDBRow className="mb-4">
          <MDBCol>
            <h6 className="font-weight-bold" style={{ fontSize: "0.7rem" }}>
              {window.I18N('expired_from')}
            </h6>
            <div className="input-group input-group-transparent">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                </span>
              </div>
              <DatePicker
                placeholderText={window.I18N("start_date") + " *"}
                className="form-control"
                dateFormat="dd/MM/yyyy"
                selected={date.startDate}
                onChange={(event) => handleDatePicker(event)}
                showYearDropdown
                showMonthDropdown
                required
                readOnly={!window.COMMON.isEmpty(oldNearestBill)}
              />
            </div>
          </MDBCol>
          <MDBCol>
            <h6 className="font-weight-bold" style={{ fontSize: "0.7rem" }}>
              {window.I18N('expired_to')}
            </h6>
            <div className="input-group input-group-transparent">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                </span>
              </div>
              <DatePicker
                className="form-control"
                dateFormat="dd/MM/yyyy"
                selected={date.endDate}
                showYearDropdown
                showMonthDropdown
                required
                readOnly
              />
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    </form>
  )
})

export default SetPackageStep;
