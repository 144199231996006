import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
const EventItemLoading = () => {
    return (
        <div
            className="mb-2 border rounded-lg wow fadeIn"
            style={{ position: 'relative', width: '45%', height: '440px' }}
        >
            <Skeleton animation="wave" className="rounded-lg" variant="rect" width="100%" height={200} />
            <div className="p-2">
                <Skeleton animation="wave" className="rounded-lg" variant="text" />
                <Skeleton animation="wave" className="rounded-lg" variant="text" width="60%" />
                <Skeleton animation="wave" className="rounded-lg" variant="text" width="50%" />
            </div>
            <div
                className="w-100 p-2 d-flex align-items-center justify-content-center"
                style={{ position: 'absolute', bottom: '15px' }}
            >
                <Skeleton animation="wave" className="rounded-lg" variant="rect" width="100%" height={40} />
            </div>
        </div>
    )
}

export default EventItemLoading
