import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import RewardGRAPHQL from '../../../graphql/Reward';
import ReactSelect from 'react-select';
import { MDBIcon } from 'mdbreact';
import CustomChart from './components/CustomChart';
import {HorizontalBar, Pie} from 'react-chartjs-2';


const paletteColor = ["rgba(0, 159, 109, 0.5)","rgba(190, 0, 0, 0.5)","rgba(61, 0, 189, 0.5)","rgba(0, 65, 175, 0.5)","rgba(54, 162, 235, 0.5)","rgba(255, 60, 84, 0.5)","rgba(201, 203, 246, 0.5)", "rgba(74, 147, 124, 0.5)", "rgba(147, 147, 124, 0.5)", "rgba(147, 147, 235, 0.5)", " rgba(147, 196, 235, 0.5)", "rgba(0, 171, 184, 0.5)", "rgba(139, 171, 184, 0.5)", "rgba(139, 255, 184, 0.5)", "rgba(165, 197, 97, 0.5)", "rgba(165, 163, 152, 0.5)", "rgba(0, 0, 0, 0.5)", "rgba(0, 47, 0, 0.5)", "rgba(26, 47, 43, 0.5)", "rgba(255, 38, 214, 0.5)"]


const InsightChartReward = ({ companyId, companies, language }) => {
    const dataMonthsSelect = [
        {
            label: window.I18N('last_3_month'),
            value: "91",
        },
        {
            label: window.I18N('last_6_month'),
            value: "183",
        }
    ]
    const [dataDaysOfMonth, setDataDaysMonth] = useState(dataMonthsSelect[0])

    const dataReward = useRef([])
    //const [dataSchedule, setDataSchedule] = useState([])
    const [dataChart, setDataChart] = useState({ labels: [], datasets: [] })
    const [chartMode, setChartMode] = useState(null)
    const category = useRef([])

    const handleSelectMonths = (value) => {
        setDataDaysMonth(value)
    }

    const groupDataforChart = (paramData) => {
        let groupDataChart = window.COMMON.getDataChartInPastYear({
            data: paramData,
            numberMonths: Math.floor(+dataDaysOfMonth.value/30),
            types: Array.from(new Set(category.current.map(el=>el?.code))),
            itemKey: 'category.code',
            dateKey: 'createdDate',
            formatDateLabel: "MM/YYYY"
        });
        dataChart.labels = groupDataChart.labels
        const indexDataset = category.current.findIndex(cat=> cat?.code === chartMode?.code)
        dataChart.datasets = [{
            data: groupDataChart.data[indexDataset],
            backgroundColor: paletteColor,
            borderColor: paletteColor.map(color => color.replace('0.2', '1')),
            borderWidth: 1,
            barPercentage: 0.5,
        }]
        setDataChart({ ...dataChart })
    }

    const getDataReward = async (pastDays = '180') => {
        try {
            const startDate = window.COMMON_DATE.getDateFollowPastDate({ pastDays, mode: -1 });
            const params = {
                input: {
                    startDate,
                    endDate: window.COMMON_DATE.getStrDateFilter(new Date(), 1),
                    pageSize: 100
                }
            };
            if (companyId) {
                params.input.companyIds = [companyId]
            } else {
                if (companies.companyIds?.length){
                    params.input.companyIds = companies.companyIds
                }
                if (companies.influencerIds?.length){
                    params.input.influencerIds = companies.influencerIds
                }
                if (companies.unionIds?.length){
                    params.input.unionIds = companies.unionIds
                } 
            }
            const result = await window.COMMON.query(
                RewardGRAPHQL.QUERY_HISTORY_REWARD,
                params
            );
            if (result?.data?.getHistoryReward) {
                const data = window.COMMON.formatDate(result.data.getHistoryReward.data, 'createdDate', "MM/YYYY")
                data.forEach(el => {
                    el.company =  el.company?.information?.name || '' 
                    el.influencer =  el.influencer?.information?.name || ''
                    el.union =  el.union?.information?.name || ''
                    if ( category.current.findIndex(cat=>cat?.code === el.category?.code)===-1 ) {
                        category.current.push(el.category)
                    }
                })
                setChartMode({...category.current[0]})
                dataReward.current = data
                groupDataforChart(data)
                //formatDatawithLanguage(data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const InnerHTMLPopup = (data ) => {
        let labelsCompany = []
		let companyData= []

        let labelsReward = []
        let rewardData = []

        let totalPoint = 0
        //const listUserIds= []
        data.forEach(history => {
            totalPoint+= history.point

            //groupData for company list
            const organizationName = history.company|| history.influencer || history.union
            const indexCompany = labelsCompany.indexOf(organizationName)
            if (indexCompany === -1) {
                labelsCompany.push(organizationName)
                companyData.push(1)
            } else {
                companyData[indexCompany]= companyData[indexCompany] + 1
            }

            //group data for reward name
            const rewardName = window.COMMON.getValueWithLanguage(history?.reward,'name', language.code)
            const indexReward = labelsReward.indexOf(rewardName)
            if (indexReward === -1) {
                labelsReward.push(rewardName)
                rewardData.push(1)
            } else {
                rewardData[indexReward]= rewardData[indexReward] + 1
            }

            //group data of user exchange
            /* if (listUserIds.findIndex(e=> e._id === history.createdBy._id) === -1){
                listUserIds.push(history.createdBy)
            } */
        });
        
		const datasetsCompany = [
			{
				label: 'Number of person',
				backgroundColor: paletteColor,
				boderColor: paletteColor.map(color => color.replace('0.2', '1')),
				borderWidth: 1,
				barPercentage: 0.5,
				data: companyData,
			},
		]

        const datasetsReward = [
            {
				label: 'Number of reward',
				backgroundColor: paletteColor,
				data: rewardData,
			},
        ]
        return <div className='p-1'>
            <div className='d-flex justify-content-around mb-2'>
                <HorizontalBar
                    width={200}
                    data={{ labels: labelsCompany, datasets: datasetsCompany}}
                    options={{
                        legend: { display: false },
                        responsive: false,
                        maintainAspectRatio: true,
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    stepSize: 1,
                                    fontSize: 10,
                                },
                                gridLines: {display:false}
                            }],
                            xAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    stepSize: 1,
                                    fontSize: 10,
                                },
                            }]
                        },
                        tooltips: {
                            enabled: true,
                        },
                        title: {
                            display: true,
                            text: `${window.I18N('company_exchange')} ${data.length}`,
                        }
                    }}
                ></HorizontalBar>
			</div>
            <div className='d-flex justify-content-around align-items-center' >
                <div className='d-flex flex-column mr-2 p-2' style={{ backgroundColor: "#F1F2F8", width: "100px",  maxHeight: "100px"}}>
					<div className='m-auto' style={{ fontSize: "30px", fontWeight: 500}}>{totalPoint}</div>
					<div className=' mx-auto text-center'>{window.I18N('total_point')}</div>
				</div>
                <div>
                    <Pie data={{ labels: labelsReward, datasets: datasetsReward}} redraw={true} height={75+ labelsReward.length*50} width={170} options={{
                        legend: {
                            position: 'bottom',
                            labels: {
                                boxWidth: 12,
                                fontSize: 10
                            }
                        },
                        responsive: false,
                        maintainAspectRatio: false,
                        title: {
                            display: true,
                            text: `${window.I18N('reward_exchange')}`,
                        }
                    }}
                    />
                </div>
            </div>
        </div>
    }

    const handleDataHover = (_, chartElements) => {
            const data = []
            dataReward.current.forEach(el => {
                if (el.createdDate === dataChart.labels[chartElements[0]._index] && el.category.code === chartMode.code ) {
                    data.push(el)
                }
            })
            return data
    };

    useLayoutEffect(() => {
        setTimeout(() => {
            if (companyId || companies.companyIds?.length|| companies.influencerIds?.length|| companies.unionIds?.length) {
                getDataReward(dataDaysOfMonth.value)
            }
        }, 0)
        // eslint-disable-next-line
    }, [companies, companyId, dataDaysOfMonth]);

    useEffect(() => {
        groupDataforChart(dataReward.current)
    }, [chartMode])

    return (
        <div className='mt-4 mx-auto '>
            <div className='w-full d-flex flex-row align-items-center justify-content-between mb-2'>
                <h6 className="font-weight-bold" style={{ fontSize: "1rem" }}>{`Reward Chart Insight`}</h6>
                <div className='d-flex align-items-center justify-content-end' style={{ gap: '16px', flex: 1 }}>
                    <div className='d-flex align-items-center justify-content-start' style={{width: '40%'}}>
                        <div className="input-group input-group-transparent">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <MDBIcon fa="true" icon="calendar" className="fa-fw" />
                                </span>
                            </div>
                            <ReactSelect
                                value={dataDaysOfMonth}
                                options={dataMonthsSelect}
                                className="md-form m-0"
                                placeholder={window.I18N("choose_option")}
                                onChange={handleSelectMonths}
                            />
                        </div>
                    </div>
                    <div className='d-flex flex-row'>
                        {category.current.map( (el, index) => <button 
                                key= {index}
                                className={`mode-button ${chartMode.code === el.code ? 'mode-active' : ''}`} 
                                onClick={() => { setChartMode({...el}) }}
                                style={{ borderRadius: '4px 0 0 4px' }}
                            >
                                {window.COMMON.getValueWithLanguage(el, 'name', language.code)}
                            </button>)
                        }
                    </div>
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-center' style={{ position: 'relative' }}>
                <CustomChart dataChart={dataChart} createInnerHTMLPopup={InnerHTMLPopup} onDataHover={handleDataHover} />
            </div>
            <div className='chart-title'>{`${window.I18N('reward_exchange')} ${dataDaysOfMonth.label}: ${dataChart.datasets[0]?.data?.reduce((acc,curr)=>acc+curr, 0)||0}`}</div>

        </div>
    )
}

const mapStateToProps = state => ({
    language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InsightChartReward);
