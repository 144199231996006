import React, { useState, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FanpageGRAPHQL from '../../graphql/Fanpage';
import { MDBCardImage } from 'mdbreact';
import {MoreHoriz,Person,PersonAdd } from '@material-ui/icons';
import { IconButton, Grid, MenuItem, Popover, Box } from '@material-ui/core';
import Constant from '../../utils/Constant';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingSpinner from './LoadingSpinner';
import { useTracking } from 'react-tracking';
import eventName from '../../common/events';

const PAGE_SIZE= 20
const FanpageMember = ({ fanpageId, role, reloadParent }) => {
	const history = useHistory();
	const currentUserId = localStorage.getItem(window.CONSTANT.ID);
	const [members, setMembers] = useState({ accepted: [], pending: [], invited: [] })
	const [memberCounts, setMemberCounts] = useState({ accepted: 0, pending: 0 })
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorElSetting, setAnchorElSetting] = useState({originVertical: "bottom", transformVertical: "top"})
	const [object, setObject] = useState(null)
	const [isStillMore, setIsStillMore] = useState(false)
	const { trackEvent } = useTracking();

    const createTrackingEvent = (event) => {
        return trackEvent({
            name: event,
            createdAt: new Date().toISOString()
        });
    };


    const getDataMember = async () => {
        try {
			const acceptedMembers = await window.COMMON.query(FanpageGRAPHQL.QUERY_MEMBER_IN_FANPAGE, { id: fanpageId, status: Constant.FANPAGE.ACCEPTED, pageSize: PAGE_SIZE })
			if (acceptedMembers?.data) {
				members.accepted = window.COMMON.formatMultiDate(acceptedMembers.data.findMembersInFanpage.data, 'createdDate,updatedDate')
			}
			memberCounts.accepted = acceptedMembers.data.findMembersInFanpage.total
			if (acceptedMembers.data.findMembersInFanpage.total === acceptedMembers.data.findMembersInFanpage.data.length) {
				setIsStillMore(false)
			} else {
				setIsStillMore(true)
			}
			if (role === Constant.FANPAGE.OWNER) {
				const [pendingMembers, invitedMembers] = await Promise.all([
					window.COMMON.query(FanpageGRAPHQL.QUERY_MEMBER_IN_FANPAGE, { id: fanpageId, status: Constant.FANPAGE.PENDING }),
					window.COMMON.query(FanpageGRAPHQL.QUERY_MEMBER_IN_FANPAGE, { id: fanpageId, status: Constant.FANPAGE.INVITED })
				])
				if (pendingMembers?.data && invitedMembers?.data) {
					members.pending = window.COMMON.formatMultiDate(pendingMembers.data.findMembersInFanpage.data,'createdDate,updatedDate')
					members.invited = window.COMMON.formatMultiDate(invitedMembers.data.findMembersInFanpage.data,'createdDate,updatedDate')
				}
				memberCounts.pending = pendingMembers.data.findMembersInFanpage.total
			}
			setMemberCounts({...memberCounts})
			setMembers({...members})
        } catch (error) {
            console.log(error)
        }
	}

	const fetchMoreData = async () => {
        try {
			const params = {
				id: fanpageId,
				status: Constant.FANPAGE.ACCEPTED,
                pageSize: PAGE_SIZE,
                page: parseInt(members.accepted.length/PAGE_SIZE) +1
            }
            let data = []
			const result = await window.COMMON.query(FanpageGRAPHQL.QUERY_MEMBER_IN_FANPAGE, params)
            if (result?.data?.findMembersInFanpage?.data) {
				data = window.COMMON.formatMultiDate(result.data.findMembersInFanpage.data, 'createdDate,updatedDate')
                if ((result?.data?.findMembersInFanpage?.data.length + members.accepted.length) >= memberCounts.accepted) {
                    setIsStillMore(false)
                } else {
                    setIsStillMore(true)
				}
				members.accepted = [...members.accepted, ...data]
                setTimeout(() => { setMembers({...members})}, 500)
            }
        } catch (error) {
            window.COMMON.showErrorLogs('FanpageMember.fetchMoreData');
        }
	}
	
	const onClickMember = (event, memberType, index) => {
		let space = window.innerHeight + window.scrollY- event.currentTarget.offsetTop
		setAnchorEl(event.currentTarget);
		if (space > 180) {
			setAnchorElSetting({originVertical: "bottom", transformVertical: "top"})
		} else {
			setAnchorElSetting({originVertical: "top", transformVertical: "bottom"})
		}
		if (memberType === Constant.FANPAGE.ACCEPTED) {
			setObject(members.accepted[index])
		} else if (memberType === Constant.FANPAGE.PENDING) {
			setObject(members.pending[index])
		}
	}

	const handleAcceptMember = async () => {
		const params = { id: fanpageId, users: [object.user._id] }
		const result = await window.COMMON.mutation(FanpageGRAPHQL.MUTATION_ACCEPT_FANPAGE_RELATIONSHIP, params) 
		if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
			createTrackingEvent(eventName.ACCEPT_REQUEST_FANPAGE);
			getDataMember()
			reloadParent()
		}
		handleClose()
	}
	

	const handleRejectMember = async() => {
		const params = {id : fanpageId, users: [object.user._id]}
		const result = await window.COMMON.mutation(FanpageGRAPHQL.MUTATION_DECLINE_FANPAGE_RELATIONSHIP, params)
		if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
			createTrackingEvent(eventName.DECLINE_REQUEST_FANPAGE);
			getDataMember()
			reloadParent()
		}
		handleClose()
	}

	const handleRemoveMember = async() => {
		const params = {id : fanpageId, users: [object.user._id]}
		const result = await window.COMMON.mutation(FanpageGRAPHQL.MUTATION_DELETE_FANPAGE_RELATIONSHIP, params)
		if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
			createTrackingEvent(eventName.REMOVE_MEMBER);
			getDataMember()
			reloadParent()
		}
		handleClose()
	}

	const onBtnRemoveMember = () => {
		window.COMMON.showModal('#modal-delete');
        window.deleteMethod = () => {
            handleRemoveMember();
        };
	}

	const onBtnRejectMember = () => {
		window.COMMON.showModal('#modal-delete');
        window.deleteMethod = () => {
            handleRejectMember();
        };
	}

	const handleInfoMember = () => {
		handleClose()
		history.push('/profile?id=' + object.user._id);
	}

	const handleClose = () => {
		setObject(null)
		setAnchorEl(null);
	};
	

	useLayoutEffect(() => {
		setTimeout(() => {
			if (fanpageId && role) {
				getDataMember()
			}
		}, 100);
	}, [fanpageId,role]);

return (
	<div className='p-4 bg-white'>
		{role === Constant.FANPAGE.OWNER && memberCounts.pending>0 && <>
			<div className='section-div-fanpage'>
				<PersonAdd className='mr-3' color="disabled"></PersonAdd>
				{` ${window.I18N("pending_approval")} · ${memberCounts.pending}`}
			</div>
			{members.pending.map((member, index) =>
				<div key={index} className=' py-2'>
					<Grid  style={{color: object?._id===member._id?"#0866FF":""}} container className='justify-content-between'>
						<Grid item>
							<Grid container>
								<MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(member.user.avatar)} className="member-fanpage-avatar mr-3" />
								<Grid item className='d-flex flex-column justify-content-center'>
									<div className=' font-weight-bold'>{member.user.name}</div>
									<div>{`${window.I18N("request_date")}: ${member.createdDate}`}</div>
								</Grid>
							</Grid>
						</Grid>
						<Grid item className='my-auto'>
							<IconButton onClick={(event) => onClickMember(event,Constant.FANPAGE.PENDING,index)}>
								<MoreHoriz color="disabled" />
							</IconButton>
						</Grid>
					</Grid>
					{member.subQuestion1 && <Grid className=' ml-2 mt-1'>
						<Grid style={{fontWeight:500}}>{member.subQuestion1}</Grid>
						<Grid style={{color:"#0000008A", fontWeight:400}}>{window.I18N('answer')+' : '+ member.subAnswer1 }</Grid>
					</Grid>
					}
					{member.subQuestion2 && <Grid className=' ml-2 mt-1'>
						<Grid style={{fontWeight:500}}>{member.subQuestion2}</Grid>
						<Grid style={{color:"#0000008A", fontWeight:400}}>{window.I18N('answer')+' : '+ member.subAnswer2 }</Grid>
					</Grid>
					}
					{member.subQuestion3 && <Grid className=' ml-2 mt-1'>
						<Grid style={{fontWeight:500}}>{member.subQuestion3}</Grid>
						<Grid style={{color:"#0000008A", fontWeight:400}}>{window.I18N('answer')+' : '+ member.subAnswer3 }</Grid>
					</Grid>
					}
				</div>
			)}
			<hr className='w-80'></hr>
		</>}
		<div className='section-div-fanpage'>
			<Person className= 'mr-2' color="disabled"></Person>
			{` ${window.I18N("member")} · ${memberCounts.accepted}`}
		</div>
		<InfiniteScroll
			dataLength={members.accepted.length}
            next={fetchMoreData}
            hasMore={isStillMore}
            loader={<div className='text-center py-2 '><LoadingSpinner /></div>}
            endMessage={
                <p className='text-center mt-2 mb-0 grey-text'>
                    <span>{window.I18N('no_more_member')}</span>
                </p>
            }
		>
			{members.accepted.map((member, index) =>
				<Grid key={index} style={{color: object?._id===member._id?"#0866FF":""}} container className=' py-2 justify-content-between'>
					<Grid item>
						<Grid container>
							<MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(member.user.avatar)} className="member-fanpage-avatar mr-3" />
							<Grid item className='d-flex flex-column justify-content-center'>
								<div className=' font-weight-bolder'>{member.user.name}</div>
								<div>{member.role === Constant.FANPAGE.OWNER ?
									<span className='onwer-fanpage-info'>{window.I18N("admin_fanpage")}</span> : `${window.I18N("join_date")}: ${member.updatedDate}`}</div>
							</Grid>
						</Grid>
					</Grid>
					<Grid item className='my-auto'>
						{currentUserId === member.user._id ?
						<IconButton >
							<Person color="disabled" />
						</IconButton>:
						<IconButton onClick={(event) => onClickMember(event, Constant.FANPAGE.ACCEPTED, index)}>
							<MoreHoriz color="disabled" />
						</IconButton>}
					</Grid>
				</Grid>
			)}
		</InfiniteScroll>
		<Popover keepMounted
			anchorOrigin={{horizontal:"left", vertical: anchorElSetting.originVertical }}
			transformOrigin={{horizontal:"left", vertical: anchorElSetting.transformVertical }}
			anchorEl={anchorEl}
			open={Boolean(anchorEl)}
			onClose={handleClose}
			PaperProps={{ style: { overflow: "inherit"}}}
			
		>
			<span style={{filter: "drop-shadow(0px 0px 10px black)", left: "15px",position: "absolute", [anchorElSetting.transformVertical]: -7,}}>
			<Box
			style={{
				backgroundColor: "white",
				display: "block",
				width: 15,
				height: 15,
				transform: "rotate(45deg)",
				clipPath: `polygon( ${anchorElSetting.transformVertical==='top'?'0 0':'100% 100%'}, 100% 0%, 0 100%)`,
			}}
			/>
			</span>
			<MenuItem onClick={handleInfoMember} className='action-member-div first-div' >{window.I18N("infomation") }</MenuItem>
			{role === Constant.FANPAGE.OWNER && object?.status === Constant.FANPAGE.ACCEPTED && <MenuItem onClick={onBtnRemoveMember} className='action-member-div'>{window.I18N("delete_member")}</MenuItem>}
			{role === Constant.FANPAGE.OWNER && object?.status === Constant.FANPAGE.PENDING && <MenuItem onClick={handleAcceptMember}  className='action-member-div'>{window.I18N("btn_accept")}</MenuItem>}
			{role === Constant.FANPAGE.OWNER && object?.status === Constant.FANPAGE.PENDING &&<MenuItem onClick={onBtnRejectMember}  className='action-member-div'>{window.I18N("btn_reject")}</MenuItem>}
		</Popover>
	</div>
)
}

const mapStateToProps = state => ({
    language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FanpageMember);
