import moment from 'moment';

const CommonDate = {
  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  },
  addDaysFromNow(days) {
    const result = new Date();
    result.setDate(result.getDate() + days);
    return result;
  },
  addMonths(date, months) {
    const result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
  },
  addMonthsFromNow(months) {
    const result = new Date();
    result.setMonth(result.getMonth() + months);
    return result;
  },
  addYearsFromNow(years) {
    const result = new Date();
    result.setFullYear(result.getFullYear() + years);
    return result;
  },
  addYearsFromCustomDate(date, years) {
    const result = new Date(date);
    result.setFullYear(result.getFullYear() + years);
    return result;
  },
  compareWithNow(date, isTime = false, isHours = false) {
    let now = new Date();
    const valueDate = new Date(date.toString());
    if (!isTime) {
      now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
      valueDate.setHours(0);
      valueDate.setMinutes(0);
      valueDate.setSeconds(0);
    }
    if (isHours) {
      now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours(), 0, 0);
    }
    const compareDate = new Date(valueDate.toString());
    if (now.getTime() === compareDate.getTime()) {
      return 0;
    }
    if (now.getTime() > compareDate.getTime()) {
      return -1;
    }
    return 1;
  },
  getStrDateFilter(date, mode) {
    if (mode === -1) {
      date.setHours(0, 0, 0, 0);
    } else {
      date.setHours(23, 59, 59, 0);
    }
    return date.toString();
  },
  getDiffDate(start, end) {
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  },
  getFormatDate(date, format = 'DD/MM/YYYY') {
    if (!date) {
      return '';
    }
    return moment(date).format(format);
  },
  formatDate(value, format = 'DD/MM/YYYY') {
    if (!value) {
      return '';
    }
    const date = new Date(+value);
    return moment(date).format(format);
  },
  formatDateJS(value) {
    return ('0' + value.getDate()).slice(-2) + '/' + ('0' + (value.getMonth()+1)).slice(-2) + '/' + value.getFullYear();
  },
  formatStringToDate(value, format = 'DD/MM/YYYY') {
    if (!value) {
      return '';
    }
    const date = new Date(value);
    return moment(date).format(format);
  },
  convertStringToDate(str, char) {
    const arrs = str.split(char);
    if (arrs.length > 2) {
      const date = new Date(
        Number(arrs[2]),
        Number(arrs[1]) - 1,
        Number(arrs[0])
      );
      return date;
    }
    return new Date();
  },
  daysInMonth(date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  },
  getDateFollowPastDate({
    pastDays = '7',
    mode = 1,
    convertStrDateFilter = true
  }) {
    let newDate = null;
    if (!pastDays.includes('year') && !isNaN(pastDays)) {
      const daysNumber = Number(pastDays);
      //1-7 = -6 ==> get date on previous 6 days, seventh day is current day
      newDate = this.addDaysFromNow(1 - daysNumber);
    } else {
      const yearArray = pastDays.split(' ');
      const yearNumber = yearArray[0];
      newDate = new Date();
      newDate.setFullYear(newDate.getFullYear() - yearNumber);
    }

    if (newDate !== null && convertStrDateFilter === true) {
      return this.getStrDateFilter(newDate, mode);
    }
    return newDate;
  },
  roundToNextInterval(date, interval){
    const ms = 1000 * 60 * interval;
    return new Date(Math.ceil(date.getTime() / ms) * ms);
  }
};

export default CommonDate;
