import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import GroupChatItem from './GroupChatItem';
import EmptyDataText from '../../../../components/EmptyDataText';
const GroupChatList = ({ parentId, visible, groupChatList, onFetchGroupChat, fetchGroupChatLoading, isHasMore, onGroupChatClick, groupChatSelected }) => {

   
  return <div className="py-3" style={{display:`${visible === true ? 'block' : 'none'}`}}>
    <InfiniteScroll
      dataLength={groupChatList.length}
      next={onFetchGroupChat}
      hasMore={isHasMore}
      scrollableTarget={parentId}
      style={{minHeight:`${groupChatList.length > 0 ? '300px' : '0px'}`}}
    >

      {groupChatList.map((item, index) => <GroupChatItem key={`group-chat-item-${index}`} groupchat={item} onClick={onGroupChatClick} isSelected = {groupChatSelected && item.id === groupChatSelected.id}/>)}

    </InfiniteScroll>
    {fetchGroupChatLoading === true && <div className="d-flex justify-content-center">
      <LoadingSpinner />
    </div>}
    {(fetchGroupChatLoading === false && groupChatList.length <=0) && <div className="d-flex justify-content-center pt-3"><EmptyDataText/></div>}
  </div>;
};
export default GroupChatList;