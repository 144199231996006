const metadataEvent = {
  ADD_METADATA: 'ADD_METADATA',
  EDIT_METADATA: 'EDIT_METADATA',
  DEL_METADATA: 'DEL_METADATA',
  ADD_SUB_METADATA: 'ADD_SUB_METADATA',
  EDIT_SUB_METADATA: 'EDIT_SUB_METADATA',
  DEL_SUB_METADATA: 'DEL_SUB_METADATA',
  IMPORT_SUB_METADATA: 'IMPORT_SUB_METADATA',
  ADD_APPS_CONNECT: 'ADD_APPS_CONNECT',
  EDIT_APPS_CONNECT: 'EDIT_APPS_CONNECT',
  UPDATE_STATUS_APPS_CONNECT: 'UPDATE_STATUS_APPS_CONNECT',
};

export default metadataEvent;