const medicalEvent = {
  APPROVE_MEDICAL: 'APPROVE_MEDICAL',
  REJECT_MEDICAL: 'REJECT_MEDICAL',
  ADD_MEDICAL: 'ADD_MEDICAL',
  DELETE_MEDICAL: 'DELETE_MEDICAL',
  APPROVE_VACCINE: 'APPROVE_VACCINE',
  REJECT_VACCINE: 'REJECT_VACCINE',
  ADD_VACCINE: 'ADD_VACCINE',
  DELELE_VACCINE: 'DELELE_VACCINE'
};

export default medicalEvent;