// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n    --radius-value: 4px;\n}\n\n.Carousel_container__pvEgZ .Carousel_carousel-control-prev__1yyfU {\n    z-index: 1 !important;\n}\n\n.Carousel_rounded-full__1S1w8 {\n    border-radius: var(--radius-value);\n}\n\n.Carousel_rounded-top__1jXQx {\n    border-top-left-radius: var(--radius-value);\n    border-top-right-radius: var(--radius-value);\n}\n\n.Carousel_rounded-bottom__CJTOS {\n    border-bottom-right-radius: var(--radius-value);\n    border-bottom-left-radius: var(--radius-value);\n}\n\n.Carousel_no-rounded__2GoXI {\n    border-radius: 0px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "Carousel_container__pvEgZ",
	"carousel-control-prev": "Carousel_carousel-control-prev__1yyfU",
	"rounded-full": "Carousel_rounded-full__1S1w8",
	"rounded-top": "Carousel_rounded-top__1jXQx",
	"rounded-bottom": "Carousel_rounded-bottom__CJTOS",
	"no-rounded": "Carousel_no-rounded__2GoXI"
};
module.exports = exports;
