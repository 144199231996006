import React, { useRef, useState, useCallback, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';

import Panel from '../../components/Panel';
import GridItem from '../../components/GridItem';
import Modal from '../../components/Modal';
import ItemQA from '../../components/ItemQA';

import AccountGRAPHQL from '../../../graphql/Account';
import QuestionGRAPHQL from '../../../graphql/Question';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';

function MySurveyPage(props) {
  const { trackEvent } = useTracking();

  const USER_ID = localStorage.getItem(window.CONSTANT.ID);
  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const language = useRef();
  const [filter, setFilter] = useState({ company: null });
  const [object, setObject] = useState({ index: -1, mode: 0, item: null });
  const [dataTodo, setDataTodo] = useState([]);
  const [dataCompleted, setDataCompleted] = useState([]);
  const [dataFeedback, setDataFeedback] = useState({});
  const [dataQA, setDataQA] = useState([]);
  const [select, setSelect] = useState({});
  const [options, setOptions] = useState({
    company: [],
    users: []
  });

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {
      getDataCompany();
    }, 100);
    // eslint-disable-next-line
  }, [props.language]);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleFilterSelect = (event) => {
    filter.company = event;
    setFilter({ ...filter });
    getDataAccount();
    getDataSurvey();
  };

  const handleSelect = (event, id, attr) => {
    window.COMMON.checkSelectValidation(id, event);
    select[attr] = event;
    setSelect({ ...select });
  };

  // COMPANY
  const getDataCompany = async () => {
    try {
      if (!window.COMMON.checkRoleIsSystem()) {
        filter.company = { value: COMPANY_ID, label: '', code: COMPANY_TYPE };
        setFilter({ ...filter });
        getDataAccount();
        getDataSurvey();
        return;
      }
      await window.COMMON.getDataAllCompany();
      const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));
      if (dataCompany && dataCompany.length > 0) {
        options.company = window.COMMON.createDataSelectWithGroup(dataCompany, '_id', 'information.name', 'type', 'type');
      }
      filter.company = window.COMMON.getValueFromAttr(options.company, '0.options.0', null);
      setOptions(options);
      setFilter({ ...filter });
      getDataAccount();
      getDataSurvey();
    } catch (error) {
      window.COMMON.showErrorLogs('AccountPage.getDataCompany');
    }
  };

  // ACCOUNT
  const getDataAccount = async () => {
    try {
      if (filter.company !== null) {
        const params = {
          company: filter.company.value,
          companyType: filter.company.code
        };
        const result = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT, params);
        if (result && result.data) {
          const data = result.data.getAccounts.filter(function (item) {
            return item.accountType !== window.CONSTANT.INDIVIDUAL;
          });
          options.users = window.COMMON.createDataSelectWithGroup(data, '_id', 'nickname', 'accountType', 'accountType');
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MySurveyPage.getDataAccount');
    }
  };

  // SURVEY
  const getDataSurvey = async () => {
    try {
      const params = {
        id: USER_ID
      };
      if (window.COMMON.checkRoleIsSystem()) {
        const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));
        const obj = window.COMMON.getObjectInArrs(filter.company.value, dataCompany, '_id');
        params.id = window.COMMON.getValueFromAttr(obj, 'information._id');
      }
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_TASKS_BY_USER, params);
      if (result && result.data) {
        const data = window.COMMON.formatDate(result.data.getAssignedTasks, 'createdDate');
        const dataTodo = [];
        const dataCompleted = [];
        data.forEach(item => {
          item.nameLanguage = window.COMMON.getValueWithLanguage(item, 'group.name', language.current);
          item.descriptionLanguage = window.COMMON.getValueWithLanguage(item, 'group.description', language.current);
          if (item.status) {
            dataCompleted.push(item);
          } else {
            dataTodo.push(item);
          }
        });
        setDataTodo(dataTodo);
        setDataCompleted(dataCompleted);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MySurveyPage.getDataSurvey');
    }
  };

  // QUESTION & ANSWER
  const getDataQuestion = async () => {
    try {
      const params = {
        groupId: object.item.group._id
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_QUESTION, params);
      const dataQA = [];
      if (result && result.data) {
        result.data.getQuestions.forEach(obj => {
          dataQA.push({
            uuid: window.COMMON.getValueFromAttr(obj, '_id', null),
            group: object.item._id,
            question: {
              _id: window.COMMON.getValueFromAttr(obj, '_id', null),
              question: window.COMMON.getDataWithLanguage(obj, 'question'),
              introduction: window.COMMON.getDataWithLanguageWithDefaultValue(obj, 'introduction', null),
              category: window.COMMON.getDataWithLanguageWithDefaultValue(obj, 'category', null),
              standardLowOfCategory: window.COMMON.getValueFromAttr(obj, 'standardLowOfCategory'),
              standardOfCategory: window.COMMON.getValueFromAttr(obj, 'standardOfCategory'),
              type: window.COMMON.getValueFromAttr(obj, 'type'),
              code: window.COMMON.getValueFromAttr(obj, 'code'),
              important: window.COMMON.getValueFromAttr(obj, 'important'),
              createdDate: window.COMMON.getValueFromAttr(obj, 'createdDate')
            },
            answers: []
          });
        });
      }
      getDataAnswerQuestion(dataQA);
    } catch (error) {
      window.COMMON.showErrorLogs('MySurveyPage.getDataQuestion');
    }
  };

  const getDataAnswerQuestion = async (dataQA) => {
    try {
      const params = {
        groupId: object.item.group._id
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_ANSWER_QUESTION, params);
      if (result && result.data) {
        result.data.getAnswerQuestions.forEach(obj => {
          const item = window.COMMON.getObjectInArrs(obj.question, dataQA, 'uuid');
          item.answers.push({
            _id: window.COMMON.getValueFromAttr(obj, '_id', null),
            content: window.COMMON.getDataWithLanguage(obj, 'content'),
            code: window.COMMON.getValueFromAttr(obj, 'code'),
            parentCode: window.COMMON.getValueFromAttr(obj, 'parentCode', null),
            question: window.COMMON.getValueFromAttr(obj, 'question')
          });
        });
      }
      setDataQA(dataQA);
      getDataFeedbackByTask();
    } catch (error) {
      window.COMMON.showErrorLogs('MySurveyPage.getDataQuestion');
    }
  };

  const getDataFeedbackByTask = async () => {
    try {
      const dataFeedback = {};
      if (!object.item.status) {
        setDataFeedback({ ...dataFeedback });
        return;
      }
      let data = [];
      const params = {
        task: object.item._id
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_FEEDBACK_BY_TASK, params);
      if (result && result.data && result.data.findAnswerByTask) {
        data = window.COMMON.getValueFromAttr(result.data.findAnswerByTask, 'feedback', []);
      }
      // TODO init data feedback
      if (data && data.length > 0) {
        data.forEach(item => {
          // TODO init data feedback
          const obj = {
            answerText: item.answerText,
            note: item.note,
            documents: item.documents
          };
          if (item.question.type === 'RADIO_GRID' || item.question.type === 'CHECKBOX_GRID') {
            obj.count = 0;
            obj.answers = {};
            item.answers.forEach(element => {
              if (element.parentCode !== null) {
                if (!obj.answers[element.parentCode]) {
                  obj.answers[element.parentCode] = [];
                  obj.count++;
                }
                obj.answers[element.parentCode].push(element._id);
              }
            });
          } else {
            obj.answers = [];
            item.answers.forEach(element => {
              obj.answers.push(element._id);
            });
          }
          dataFeedback[item.question._id] = obj;
        });
      }
      setDataFeedback({ ...dataFeedback });
    } catch (error) {
      window.COMMON.showErrorLogs('MySurveyPage.getDataFeedbackByTask');
    }
  };

  // eslint-disable-next-line
  const showContainerGroup = useCallback((index, mode) => {
    window.COMMON.showContainerSave();
    object.index = index;
    object.mode = mode;
    object.item = mode === 0 ? dataTodo[index] : dataCompleted[index];
    setObject(object);
    getDataQuestion();
  });

  const openGrantAccount = useCallback((index, mode) => {
    object.index = index;
    object.mode = mode;
    object.item = mode === 0 ? dataTodo[index] : dataCompleted[index];
    select.users = window.COMMON.setDataMultiSelectWithGroup(options.users, window.COMMON.getValueFromAttr(object.item, 'users', []), '_id');
    setObject(object);
    setSelect({ ...select });
    window.COMMON.showModal('#modal-grant');
  }, [options, select, object, dataTodo, dataCompleted]);

  // eslint-disable-next-line
  const openSaveGroup = useCallback((dataSave, deletedFiles) => {
    const feedback = [];
    const files = [];
    const keys = Object.keys(dataSave);
    let index = 0;
    keys.forEach(key => {
      const obj = window.COMMON.getObjectInArrs(key, dataQA, 'question._id');
      const item = {};
      item.question = key;
      item.answers = [];
      item.answerText = dataSave[key].answerText;
      if (object.mode === 2) {
        item.note = dataSave[key].note;
        item.documents = [];
        dataSave[key].documents.forEach(document => {
          item.documents.push({
            id: document.file ? index : document.id,
            name: document.name,
            size: document.size,
            type: document.type,
            ext: document.ext
          });
          if (document.file) {
            index++;
            files.push(document.file);
          }
        });
      }
      if (obj.question.type === 'RADIO_GRID' || obj.question.type === 'CHECKBOX_GRID') {
        const answerKeys = Object.keys(dataSave[key].answers);
        answerKeys.forEach(answerKey => {
          const answer = window.COMMON.getObjectInArrs(answerKey, obj.answers, 'code');
          if (answer) {
            item.answers.push(answer._id);
          }
          item.answers = item.answers.concat(dataSave[key].answers[answerKey]);
        });
      } else {
        item.answers = dataSave[key].answers;
      }
      feedback.push(item);
    });
    window.COMMON.showModal('#modal-save');
    window.saveMethod = () => sendFeedback(feedback);
  });

  const grantTask = async () => {
    try {
      const checkUsers = window.COMMON.checkSelectValidation('select-users', select.users);
      if (!checkUsers) {
        window.COMMON.showMessage('warning', 'MSG_CODE_012', window.MSG.MSG_CODE_012);
        return;
      }
      const params = {
        id: object.item._id,
        users: window.COMMON.getDataSelect(select.users, true)
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_GRANT_TASK, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
        getDataSurvey();
        createTrackingEvent(eventName.GRANT_TASK);
      }
      window.COMMON.hideModal('#modal-grant');
    } catch (error) {
      window.COMMON.showErrorLogs('MySurveyPage.grantTask');
    }
  };

  const sendFeedback = async (feedback) => {
    try {
      const params = {
        input: {
          task: object.item._id,
          group: window.COMMON.getValueFromAttr(object.item, 'group._id'),
          company: COMPANY_TYPE === window.CONSTANT.COMPANY ? COMPANY_ID : null,
          union: COMPANY_TYPE === window.CONSTANT.UNION ? COMPANY_ID : null,
          feedback
        }
      };
      window.COMMON.trimData(params);
      if (!object.item.status) {
        const result = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_SEND_FEEDBACK, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          window.COMMON.backContainerData();
          getDataSurvey();
          createTrackingEvent(eventName.SEND_FEEDBACK);
        }
      } else {
        const result = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_UPDATE_FEEDBACK_BY_TASK, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          window.COMMON.backContainerData();
          getDataSurvey();
          createTrackingEvent(eventName.UPDATE_FEEDBACK_BY_TASK);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MySurveyPage.sendFeedback');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <div id="container-data">
        <div className="card mb-3 p-3 wow fadeInDown animated" data-wow-delay="0.5s" style={{ zIndex: 1 }}>
          <div className="d-flex align-items-center justify-content-end">
            {
              !window.COMMON.checkRoleIsSystem() ? <div></div> : <div className="bd-highlight mr-3" style={{ width: '300px' }}>
                <div className="input-group input-group-transparent">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="building" className="fa-fw" />
                    </span>
                  </div>
                  <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.company} options={options.company} onChange={handleFilterSelect} isSearchable />
                </div>
              </div>
            }
            <ul className="nav nav-pills nav-fill" id="my-tab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" id="home-tab" data-toggle="tab" href="#tab-survey-todo" role="tab" aria-controls="home" aria-selected="true">{window.I18N('todo')}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="contact-tab" data-toggle="tab" href="#tab-survey-completed" role="tab" aria-controls="contact" aria-selected="false">{window.I18N('completed')}</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="tab-content p-0 pt-2" id="my-tab-content">
          <div className="tab-pane fade show active" id="tab-survey-todo" role="tabpanel">
            <GridItem id="container-grid-survey-todo" data={dataTodo} isSurvey={true} handleItemClick={(index) => showContainerGroup(index, 0)} handleItemEditClick={(index) => openGrantAccount(index, 0)}></GridItem>
          </div>
          <div className="tab-pane fade" id="tab-survey-completed" role="tabpanel">
            <GridItem id="container-grid-survey-completed" data={dataCompleted} isSurvey={true} handleItemClick={(index) => showContainerGroup(index, 1)}></GridItem>
          </div>
        </div>
      </div>

      <Panel id="container-save" title={window.I18N('survey')} icon="question-circle" style={{ display: 'none' }}>
        <MDBRow>
          <MDBCol md={object.mode === 2 ? '12' : '8'} className={object.mode === 2 ? '' : 'offset-md-2'}>
            <ItemQA 
              group={object.item} 
              dataFeedback={dataFeedback} 
              data={dataQA}
              language={language.current} 
              isSelfAssessment={object.mode === 2}
              groupData={object.mode === 1 ? dataCompleted[object.index] : dataTodo[object.index]}
              isCompany={true} 
              handleSubmit={openSaveGroup} 
              handleClose={window.COMMON.backContainerData} 
            />
          </MDBCol>
        </MDBRow>
      </Panel>

      <Modal id="modal-grant" className="modal-lg" isNotScroll={true} title={window.I18N('grant_data')} saveEvent={grantTask}>
        <Select id="select-users" className="md-form" placeholder={window.I18N('select_accounts')} value={select.users} options={options.users} onChange={(event) => handleSelect(event, 'select-users', 'users')} isSearchable isMulti closeMenuOnSelect={false} />
      </Modal>
    </div>
  );
}

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MySurveyPage);