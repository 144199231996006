import styled from 'styled-components';

const HeadingStyled = styled.span`
    font-weight: bold;
`;


export const Heading1Styled = styled(HeadingStyled)`
    font-size: 24px;
`;
export const Heading4Styled = styled(HeadingStyled)`
    font-size: 16px;
`;


const LightEmphasis = styled.span`
    font-weight: 500;
`;

export const SLightEmphasis = styled(LightEmphasis)`
    font-size: 12px;
`;