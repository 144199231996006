import { Grid } from '@material-ui/core'
import React from 'react'
import { HorizontalBar } from 'react-chartjs-2'

const paletteColor = [
    'rgba(0, 159, 109, 0.4)',
    'rgba(190, 0, 0, 0.4)',
    'rgba(61, 0, 189, 0.4)',
    'rgba(0, 65, 175, 0.4)',
    'rgba(54, 162, 235, 0.4)',
    'rgba(153, 102, 255, 0.4)',
    'rgba(201, 203, 207, 0.4)',
    'rgba(74, 147, 124, 0.4)',
    'rgba(147, 147, 124, 0.4)',
    'rgba(147, 147, 235, 0.4)',
    'rgba(147, 196, 235, 0.4)',
    'rgba(0, 171, 184, 0.4)',
    'rgba(139, 171, 184, 0.4)',
    'rgba(139, 255, 184, 0.4)',
    'rgba(165, 197, 97, 0.4)',
    'rgba(165, 163, 152, 0.4)',
    'rgba(0, 0, 0, 0.4)',
    'rgba(0, 47, 0, 0.4)',
    'rgba(26, 47, 43, 0.4)',
    'rgba(255, 38, 214, 0.4)'
]

const TimesFrameStatisticPopup = ({
    dataPopup,
    dataChart,
    eventIds,
    hoverLabelIndex,
    indexDatasetPopup,
    hoverDatasetIndex,
    onSetIndexDatasetPopup = () => {}
}) => {
    const createDataTimesFramePopup = () => {
        const timeFrame = `${dataChart?.datasets[indexDatasetPopup]?.label} - ${dataChart?.labels[hoverLabelIndex]}`
        const data = {
            labels: Object.keys(dataPopup[timeFrame]?.eventIdCount ?? []),
            datasets: [
                {
                    label: 'Number of event',
                    backgroundColor: paletteColor,
                    borderWidth: 1,
                    barPercentage: 0.5,
                    data: Object.values((dataPopup[timeFrame]?.eventIdCount ?? []))
                }
            ]
        }
        return (
            <div style={{ width: '300px' }}>
                <div style={{ fontSize: '13px', fontWeight: 400 }}>
                    <Grid container>
                        {hoverDatasetIndex?.map((el, index) => (
                            <Grid
                                key={index}
                                item
                                className={`tab-control-fanpage small-content ${
                                    indexDatasetPopup === el ? 'active' : ''
                                }`}
                                onClick={() => onSetIndexDatasetPopup(el)}
                            >
                                {`${dataChart.datasets[el].label}`}
                            </Grid>
                        ))}
                    </Grid>
                    <div
                        style={{ width: '100%' }}
                        className="mt-1 mb-3 d-flex align-items-center justify-content-center"
                    >
                        <span
                            className="mr-2"
                            style={{
                                display: 'inline-block',
                                height: '1rem',
                                width: '1rem',
                                backgroundColor: paletteColor[indexDatasetPopup]
                            }}
                        ></span>
                        {`${window.I18N('number-of')} ${window.I18N('event').toLowerCase()} ${window
                            .I18N('day')
                            .toLowerCase()} ${timeFrame}: ${
                            dataChart.datasets[indexDatasetPopup].data[hoverLabelIndex]
                        }`}
                    </div>
                </div>
                <div className="mb-2 d-flex align-items-center justify-content-center" style={{ maxHeight: '140px' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: '10px'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: '#F1F2F8',
                                marginBottom: '10px',
                                padding: '5px 10px'
                            }}
                        >
                            <div style={{ margin: 'auto', fontSize: '15px', fontWeight: 500 }}>
                                {dataPopup[timeFrame]?.platformCount?.ANDROID_APP}
                            </div>
                            <div style={{ margin: 'auto', textAlign: 'center' }}>{window.I18N('android_platform')}</div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: '#F1F2F8',
                                padding: '5px 10px'
                            }}
                        >
                            <div style={{ margin: 'auto', fontSize: '15px', fontWeight: 500 }}>
                                {dataPopup[timeFrame]?.platformCount?.IOS_APP}
                            </div>
                            <div style={{ margin: 'auto', textAlign: 'center' }}>{window.I18N('ios_platform')}</div>
                        </div>
                    </div>
                    <div className="mr-2">
                        <HorizontalBar
                            width={200}
                            height={130}
                            data={data}
                            options={{
                                legend: { display: false },
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: {
                                    yAxes: [
                                        {
                                            ticks: {
                                                beginAtZero: true,
                                                stepSize: 1,
                                                fontSize: 10
                                            },
                                            gridLines: {
                                                display: false
                                            }
                                        }
                                    ],
                                    xAxes: [
                                        {
                                            ticks: {
                                                beginAtZero: true,
                                                stepSize: 1,
                                                fontSize: 10
                                            }
                                        }
                                    ]
                                },
                                tooltips: {
                                    enabled: true
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    return <div>{createDataTimesFramePopup()}</div>
}

export default TimesFrameStatisticPopup
