import React, { useState, useCallback, useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { MDBRow, MDBCol, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';

import Panel from '../../components/Panel';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import ImportExcel from '../../components/ImportExcel';
import ExportExcel from '../../components/ExportExcel';

import MetadataGRAPHQL from '../../../graphql/Metadata';
import eventName from '../../../common/events';
import { useTracking } from 'react-tracking';

function SubMetadataPage(props) {
  const { trackEvent } = useTracking();

  const config = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('value'), '20%', '', '', 'valueLanguage', 'TEXT'),
    window.COMMON.createCol(window.I18N('code'), '10%', '', '', 'code', 'TEXT'),
    window.COMMON.createCol(window.I18N('description'), '30%', '', '', 'descriptionLanguage', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_by'), '15%', '', '', 'createdBy.nickname', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '10%', '', '', '3', 'BUTTON')
  ];
  const typeOptions =  [
    { value: 'COMPANY_MAIN_INDUSTRY', label: window.I18N('main_industry') },
    { value: 'COMPANY_MAIN_PRODUCT', label: window.I18N('main_product') },
    { value: 'COMPANY_MARKERT', label: window.I18N('market') },
    { value: 'COMPANY_CUSTOMER', label: window.I18N('customers') }
  ];
  const language = useRef();
  const [filter, setFilter] = useState({ type: { value: 'COMPANY_MAIN_INDUSTRY', label: window.I18N('main_industry') }, companyIndustry: null });
  const [object, setObject] = useState({ index: -1 });
  const [data, setData] = useState([]);
  const [dataSave, setDataSave] = useState({});
  const [options, setOptions] = useState({
    companyIndustry: []
  });
  const [dataImport, setDataImport] = useState({});

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {
      getMetadataPage();
    }, 100);
    // eslint-disable-next-line
  }, [props.language]);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleChange = (event) => {
    dataSave[event.target.name] = event.target.value;
    setDataSave({ ...dataSave });
  };

  const handleChangeWithLanguage = (event) => {
    window.COMMON.setValueWithLanguage(dataSave, event.target.name, language.current, event.target.value);
    setDataSave({ ...dataSave });
  };

  const handleFilterSelect = (event, attr) => {
    filter[attr] = event;
    if (attr === 'type') {
      if ((event.value === 'COMPANY_MARKERT' || event.value === 'COMPANY_CUSTOMER')) {
        filter.companyIndustry = null;
      } else {
        if (!filter.companyIndustry) {
          filter.companyIndustry = window.COMMON.getValueFromAttr(options.companyIndustry, '0', null);
        }
      }
    }
    setFilter({ ...filter });
    getDataSubMetadata();
  };

  const handleFileImport = (dataImport) => {
    checkImportData(dataImport);
    window.COMMON.showModal('#modal-import');
  };

  const getMetadataPage = async () => {
    try {
      const params = {
        type: ['COMPANY_INDUSTRY']
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_SHORT_METADATA, params);
      if (result && result.data) {
        options.companyIndustry = window.COMMON.createDataSelect(result.data.getMetadatas, '_id', 'value');
        filter.companyIndustry = window.COMMON.getValueFromAttr(options.companyIndustry, '0', null);
        filter.type = { value: 'COMPANY_MAIN_INDUSTRY', label: window.I18N('main_industry') };
      }
      setOptions(options);
      setFilter({ ...filter });
      getDataSubMetadata();
    } catch (error) {
      window.COMMON.showErrorLogs('SubMetadataPage.getMetadataPage');
    }
  };

  const getDataSubMetadata = async () => {
    try {
      window.resetDataTable('table-data');
      const params = {
        type: [window.COMMON.getDataSelect(filter.type)],
        parent: window.COMMON.getDataSelect(filter.companyIndustry)
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_SUB_METADATA, params);
      if (result && result.data) {
        const data = window.COMMON.formatDate(result.data.getSubMetadatas, 'createdDate');
        data.forEach(item => {
          item.valueLanguage = window.COMMON.getValueWithLanguage(item, 'value', language.current);
          item.descriptionLanguage  = window.COMMON.getValueWithLanguage(item, 'description', language.current);
        });
        setData(data);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SubMetadataPage.getDataSubMetadata');
    }
    window.initDataTable('table-data');
  };

  const openSaveDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-sub-metadata');
    object.index = index;
    const dataSave = { type: window.COMMON.getDataSelect(filter.type), parent: window.COMMON.getDataSelect(filter.companyIndustry) };
    if (index === -1) {
      dataSave.value = [];
      dataSave.code = window.COMMON.generateCode('SM');
      dataSave.description = [];
    } else {
      const obj = data[index];
      dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
      dataSave.value = window.COMMON.getDataWithLanguage(obj, 'value');
      dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
      dataSave.description = window.COMMON.getDataWithLanguage(obj, 'description');
    }
    setObject(object);
    setDataSave({ ...dataSave });
  }, [filter, object, data]);

  // eslint-disable-next-line
  const openDeleteDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteData;
  });

  const saveData = async (event) => {
    try {
      if (!window.COMMON.checkFormValidation('form-sub-metadata', event)) {
        return;
      }
      const params = {
        input: dataSave
      };
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(MetadataGRAPHQL.MUTATION_ADD_SUB_METADATA, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataSubMetadata();
		      createTrackingEvent(eventName.ADD_SUB_METADATA);
        }
      } else {
        const result = await window.COMMON.mutation(MetadataGRAPHQL.MUTATION_EDIT_SUB_METADATA, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataSubMetadata();
		      createTrackingEvent(eventName.EDIT_SUB_METADATA);
        }
      }
      window.COMMON.hideModal('#modal-sub-metadata');
    } catch (error) {
      window.COMMON.showErrorLogs('SubMetadataPage.saveData');
    }
  };

  const deleteData = async () => {
    try {
      const params = {
        id: data[object.index]._id
      };
      const result = await window.COMMON.mutation(MetadataGRAPHQL.MUTATION_DEL_SUB_METADATA, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataSubMetadata();
        createTrackingEvent(eventName.DEL_SUB_METADATA);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SubMetadataPage.deleteData');
    }
  };

  // IMPORT
  // eslint-disable-next-line
  const deleteImportRow = useCallback((index) => {
    if (dataImport.data.length > 0) {
      dataImport.data.splice(index, 1);
    }
    setDataImport({ ...dataImport });
  });

  const checkImportData = async (dataImport) => {
    try {
      if (dataImport.data.length === 0) {
        return;
      }
      const data = [];
      dataImport.data.forEach(item => {
        if (!data.includes(item.code)) {
          data.push(item.code);
        }
      });
      const params = {
        input: data,
        type: window.COMMON.getDataSelect(filter.type),
        parent: window.COMMON.getDataSelect(filter.companyIndustry)
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(MetadataGRAPHQL.MUTATION_CHECK_IMPORT_SUB_METADATA, params);
      const dataCheck = result.data.checkImportSubMetadata;
      for (let i = 0; i < dataCheck.length; i++) {
        const arrs = dataCheck[i].split('-.-');
        if (Number(arrs[1]) === 1) {
          const array = window.COMMON.getArrsInArrs(arrs[0], dataImport.data, 'code');
          if (array && array.length > 0) {
            array.forEach(element => {
              element.isExisted = true;
            });
          }
        }
      }
      setDataImport(dataImport);
    } catch (error) {
      window.COMMON.showErrorLogs('SubMetadataPage.checkImportData');
    }
  };
  
  const importData = async () => {
    if (dataImport.data.length === 0) {
      window.COMMON.showMessage('warning', 'MSG_CODE_021', window.MSG.MSG_CODE_021);
      return;
    }
    try {
      const dataImported = [];
      dataImport.data.forEach(item => {
        const check = window.COMMON.getObjectInArrs(item.code, dataImported, 'code');
        if (check === null) {
          const arrs = window.COMMON.getArrsInArrs(item.code, dataImport.data, 'code');
          const obj = {};
          obj.code = item.code;
          obj.value = [];
          obj.description = [];
          obj.type = window.COMMON.getDataSelect(filter.type);
          obj.parent = window.COMMON.getDataSelect(filter.companyIndustry);
          arrs.forEach(child => {
            obj.value.push({ code: child.languageCode, text: child.value });
            obj.description.push({ code: child.languageCode, text: child.description });
          });
          dataImported.push(obj);
        }
      });
      const params = {
        input: dataImported
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(MetadataGRAPHQL.MUTATION_IMPORT_SUB_METADATA, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_IMPORT)) {
        getDataSubMetadata();
        createTrackingEvent(eventName.IMPORT_SUB_METADATA);
      }
      window.COMMON.hideModal('#modal-import');
    } catch (error) {
      window.COMMON.showErrorLogs('SubMetadataPage.importData');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" icon="list-alt">
        <div className="container-top d-flex align-items-center justify-content-end wow fadeInDown animated" data-wow-delay="0.5s">
          {
            filter.type && (filter.type.value === 'COMPANY_MARKERT' || filter.type.value === 'COMPANY_CUSTOMER') ? <></> : <div className="bd-highlight mr-2" style={{ width: '300px' }}>
              <div className="input-group input-group-transparent">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="filter" className="fa-fw" />
                  </span>
                </div>
                <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.companyIndustry} options={options.companyIndustry} onChange={(event) => handleFilterSelect(event, 'companyIndustry')} isSearchable/>
              </div>
            </div>
          }
          <div className="bd-highlight mr-2" style={{ width: '300px' }}>
            <div className="input-group input-group-transparent">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <MDBIcon fa="true" icon="filter" className="fa-fw" />
                </span>
              </div>
              <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.type} options={typeOptions} onChange={(event) => handleFilterSelect(event, 'type')} isSearchable/>
            </div>
          </div>
          <ExportExcel label={window.I18N('btn_template')} isTemplate={true} dataKey="EXPORT_SUB_CATEGORY"></ExportExcel>
          <ImportExcel label={window.I18N('btn_import')} id="file-import" dataKey="IMPORT_SUB_METADATA" handleFile={handleFileImport}></ImportExcel>
          <MDBBtn color="primary" className="mr-0" onClick={() => openSaveDialog(-1)}>
            <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
          </MDBBtn>
        </div>
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <Table id="table-data" className="table" config={config} data={data} disabledEdit={!window.COMMON.checkRoleIsSystem()} disabledDel={!window.COMMON.checkRoleIsSystem()} handleRowEditClick={openSaveDialog} handleRowDelClick={openDeleteDialog}></Table>
        </div>
      </Panel>

      <Modal id="modal-sub-metadata" title={window.I18N('create_update_data')} saveEvent={saveData}>
        <form id="form-sub-metadata" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <MDBInput outline type="text" label={window.I18N('value') + ' *'} containerClass="mt-0" name="value" value={window.COMMON.getValueWithLanguage(dataSave, 'value', language.current)} onChange={handleChangeWithLanguage} maxLength="200" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="text" label={window.I18N('code') + ' *'} name="code" value={dataSave.code} onChange={handleChange} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="textarea" label={window.I18N('description')} name="description" value={window.COMMON.getValueWithLanguage(dataSave, 'description', language.current)} onChange={handleChangeWithLanguage} maxLength="5000" pattern="\S(.*\S)?"></MDBInput>
              <label className="badge badge-info p-2 m-0 mr-3">{filter.type.label}</label>
              <label className="badge badge-default p-2 m-0">{filter.companyIndustry && filter.companyIndustry.label}</label>
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>

      <Modal id="modal-import" className="modal-xl" title={window.I18N('import_data')} saveEvent={importData}>
        <Table id="table-import" className="table" config={dataImport.config} data={dataImport.data} handleRowDelClick={deleteImportRow} isImported={true}></Table>
      </Modal>
    </div>
  );
}


const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};
    
export default connect(mapStateToProps, mapDispatchToProps)(SubMetadataPage);