import React, { useEffect, useState } from 'react';
import { Box, Grid } from "@material-ui/core"
import { MDBIcon } from "mdbreact"
import DatePicker from 'react-datepicker';
import moment from 'moment';
import useWindowSize from '../../hooks/useWindowSize';

const SumInfoFilter = (props) => {
    const PAGE_PROPS = 
        {
            survey: [
                {
                    icon: "tasks",
                    content: "total_QA",
                    data: props?.dataSum?.totalQA,
                    active: false,
                    status: null
                },
                {
                    icon: "calendar-alt",
                    content: "task_schedule",
                    data: props?.dataSum?.totalSchedule,
                    active: true
                },
                {
                    icon: "calendar-plus",
                    content: "new_schedule",
                    data: props?.dataSum?.news,
                    status: 0,
                    active: false
                },
                {
                    icon: "pause-circle",
                    content: "pause_schedule",
                    data: props?.dataSum?.paused,
                    status: -1,
                    active: false
                },
                {
                    icon: "stop-circle",
                    content: "stoped_schedule",
                    data: props?.dataSum?.stopped,
                    status: -2,
                    active: false
                },
                {
                    icon: "spinner",
                    content: "proccessing_schedule",
                    data: props?.dataSum?.process,
                    status: 1,
                    active: false
                },
                {
                    icon: "check",
                    content: "finished_schedule",
                    data: props?.dataSum?.finished,
                    status: 2,
                    active: false
                },
            ],
            assessment: [
                {
                    icon: "tasks",
                    content: "total_assessment",
                    data: props?.dataSum?.total,
                    status: null,
                    active: true
                },
                {
                    icon: "file-alt",
                    content: "new_assessment",
                    data: props?.dataSum?.news,
                    status: 0,
                    active: false
                },
                {
                    icon: "spinner",
                    content: "running_assessment",
                    data: props?.dataSum?.running,
                    status: 1,
                    active: false
                },
                {
                    icon: "calendar-times",
                    content: "expired_assessment",
                    data: props?.dataSum?.expired,
                    status: 2,
                    active: false
                },
                {
                    icon: "check",
                    content: "completed_assessment",
                    data: props?.dataSum?.completed,
                    status: 3,
                    active: false
                },
            ]
    }
    const [listPageProps, setListPageProps] = useState(PAGE_PROPS[props.page])
    const [filter, setFilter] = useState({
    startDate: props?.filterForQuery?.startDate,
    endDate: props?.filterForQuery?.endDate,
    status: props?.filterForQuery?.status,
    });
    
    useEffect(()=>{
        const holdActiveItems = listPageProps.map((item,index)=>({...PAGE_PROPS[props.page][index], active: item.status === props.filterForQuery.status}))
        setListPageProps(holdActiveItems)
    },[props.dataSum])

    const handleDatePicker = (event, attr) => {
        filter[attr] = event;
        setFilter({ ...filter });
    };
    const size = useWindowSize();
    const checkValidFilter = () => {
        const startDateToNow = moment(new Date()).diff(moment(filter.startDate), 'days')
        const diffDay = moment(filter.endDate).diff(moment(filter.startDate), 'days')
        if ( startDateToNow < 0 || diffDay < 0) {
            return false
        }
        if (startDateToNow > 730) {
            return false
        }
        if (diffDay > 183) {
            return false
        }
        return true
    }

    const handleSelectedItems = (index, el) => {
        const newListPageProps = listPageProps.map((item, indexItem)=>({...item, active: index === indexItem}))
        setListPageProps(newListPageProps)
        props.handleFilterChange({...filter, status: el.status})
    }

   
    useEffect(() => {
        if (checkValidFilter()) {
            props.handleFilterChange({...filter, status: props.filterForQuery.status})
        } else {
            window.COMMON.showMessage('warning', 'ERROR_DATE', window.I18N('ERROR_DATE'));
        }
    }, [filter.startDate, filter.endDate])

    function sumInfo(paramPage) {   
        return <Grid container className='mt-1 mb-1 bg-white sum-info-filter'>
            <Grid item md={props.filterForQuery.radio === 1 ? 7 : 4} sm={12}>
                <Grid container className='flex-center align-items-stretch justify-content-stretch'>
                    {listPageProps.map((el,index) =>
                       (
                        ((props.filterForQuery.radio === 0 && index === 0) || (props.filterForQuery.radio === 1 && index !== 0) || (paramPage === 'assessment'))  &&
                       <Box 
                            key={index} 
                            sx={{
                                    "&:hover": {
                                        backgroundColor: props.filterForQuery.radio === 1 ? "#F1F2F8" : 'transparent'
                                    }
                            }}
                            className='flex-center justify-content-stretch mr-1'
                            style={{flex: 1}}
                        >
                            <Grid item className="py-2" style={{ width: `${100/ (size.width > 920? PAGE_PROPS[paramPage].length:Math.ceil(PAGE_PROPS[paramPage].length/2)) }%`, cursor: `${props.filterForQuery.radio === 1 ? 'pointer' : 'auto'}`,borderBottom:`2px solid ${el.active ? '#302E90' : "transparent"}`,flex: 1, height: '100%'}} 
                            onClick={() =>props.filterForQuery.radio === 1 ? handleSelectedItems(index, el) : ()=>{}}>
                                <Grid className='d-flex align-items-center mb-2 mt-3 justify-content-center'>
                                    <MDBIcon size='2x' className='mr-2 icon-sum' fas icon={el.icon} />
                                    <Grid item  className='font-weight-bolder' component='h5'>{el.data}</Grid>
                                </Grid>
                                <Grid className='mx-2 text-muted icon-sum font-weight-bolder text-center'>{window.I18N(el.content)}</Grid>
                            </Grid> 
                       </Box>
                        )
                    )}
                </Grid>
            </Grid>
            <Grid item md={props.filterForQuery.radio === 1 ? 4 : 7} sm={12} style={{ margin: "auto" }}>
                <Grid container justifyContent='center'>
                    <Grid item className="d-flex align-items-center py-4 pr-3" md={6} sm={4} xs={6} >
                        <div className={`input-group input-group-transparent ${checkValidFilter()?'':'border border-warning'}`}>
                            <div className="input-group-prepend ">
                                <span className="input-group-text ">
                                    <MDBIcon fa="true" icon="calendar-alt" className="fa-fw icon-sum" />
                                </span>
                            </div>
                            <DatePicker 
                                placeholderText={window.I18N('start_date') + ' *'} 
                                className="form-control" 
                                dateFormat="dd/MM/yyyy" 
                                showYearDropdown 
                                showMonthDropdown 
                                selectsStart
                                selected={filter.startDate}
                                maxDate={new Date(filter.endDate)}
                                onChange={(event) => handleDatePicker(event, 'startDate')}
                            />
                        </div>
                    </Grid>
                    <Grid item className="d-flex align-items-center py-4 pr-" md={6} sm={4} xs={6} >
                        <div className={`input-group input-group-transparent ${checkValidFilter()?'':'border border-warning'}`}>
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <MDBIcon fa="true" icon="calendar-alt" className="fa-fw icon-sum" />
                                </span>
                            </div>
                            <DatePicker 
                                placeholderText={window.I18N('end_date') + ' *'} 
                                className="form-control" 
                                dateFormat="dd/MM/yyyy" 
                                showYearDropdown 
                                showMonthDropdown 
                                selectsEnd
                                selected={filter.endDate}
                                minDate={new Date(filter.startDate)}
                                onChange={(event) => handleDatePicker(event, 'endDate')} 
                            />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    }



    return (sumInfo(props.page))
}
export default SumInfoFilter