import { MenuItem, Select } from '@material-ui/core';
import React from 'react';

export const groupTypes = [
  {
    value: 'DEFAULT',
    label: 'QA'
  },
  {
    value: 'SOFT_KILL_QUESTION',
    label: 'score_card'
  }
];

const SelectGroupsType = ({ onChangeGroupType, questionsType }) => {
  return (
    <div className="block mr-auto">
      <Select
        value={questionsType}
        onChange={onChangeGroupType}
        variant='outlined'
        classes={{
          select: 'p-2 w-full'
        }}
        style={{
          width: '80%'
        }}
      >
        {groupTypes.map((item, index) => {
          return (
            <MenuItem
              key={index}
              value={item.value}
              className="cursor-pointer  m-0"
              style={{
                margin: 0
              }}
            >
              {window.I18N(item.label)}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export default SelectGroupsType;