import React, { useState, useEffect } from 'react';
import {  MDBCol  , MDBIcon, MDBInputGroup } from 'mdbreact';
import FormModal from '../../components/FormModal';
import Select from 'react-select';
import { UserSearchContainerStyled } from '../../../styles/styled-components/Chat/ChatGroupFormModal';
import CircleAvatar from '../../components/CircleAvatar';
import useSearchDebounce from '../../../hooks/useSearchDebounce';
import ChatGRAPHQL from '../../../graphql/Chat';
import LoadingSpinner from '../../components/LoadingSpinner';
import EmptyDataText from '../../components/EmptyDataText';

const InviteMembersFormModal = ({ modalId, companies, onClose, onSaveForm, groupId }) => {

  const [filter, setFilter] = useState({ company: null });
  const [searchUserResult, setSearchUserResult] = useState([]);
  const [chosenUsers, setChosenUsers] = useState([]);
  const [searchUserValue, setSearchUserValue] = useState('');
  const searchDebounce = useSearchDebounce();
  const [searchUserLoading, setSearchUserLoading] = useState(false);

  useEffect(() => {
    if (!window.COMMON.checkRoleIsSystemOrInfluencer()) {
      const currentUser = window.COMMON.getCurrentUserInformation();
      filter.company = {
        value: currentUser.company,
        code: currentUser.companyType,
        label: ''
      };
    }
    return () => {

    };
  }, []);

  useEffect(() => {
    return () => {
      setSearchUserValue('');
      setSearchUserResult([]);
    };
  }, [filter.company]);

  const clearData = () => {
    setSearchUserValue('');
    setSearchUserResult([]);
    setChosenUsers([]);
    if (window.COMMON.checkRoleIsSystemOrInfluencer()) {
      filter.company = null;
    }
  };

  const handleSearchValueChange = (value) => {
    searchDebounce(async () => {
      let newSearchResult = [];
      setSearchUserLoading(true);
      try {
        if (value.trim().length > 0) {
          newSearchResult = await findUsers(value);
        }
      }
      catch (error) {
        console.log(error);
      }
      setSearchUserResult(newSearchResult);
      setSearchUserLoading(false);
    }, 300);
  };

  const findUsers = async (nameValue) => {

    let usersResult = [];

    const companyType = filter.company?.code;
    const companyId = filter.company?.value;
    const paramsToFilterAccount = {
      input: {
        nickname: nameValue,
        // phone: nameValue,
        // email: nameValue,
        organization: companyId,
        organizationType: companyType,
        groupId,
        page: 1,
        pageSize: 50
      }
    };
    
    const result = await window.COMMON.query(ChatGRAPHQL.QUERY_GET_USER_TO_INVITE_TO_GROUP, paramsToFilterAccount, false);
    
    if (result && result.data) {
      usersResult = result.data.getUsersToInviteToGroup;
    }


    
    let final = [...usersResult];
       
    //remove chosen users
    const chosenUsersIds = chosenUsers.map((item) => item._id);
    final = final.filter((item) => !chosenUsersIds.includes(item._id));
    return final;
  };


  const saveForm = async (event) => {
    await onSaveForm(chosenUsers);
    clearData();
  };


  const handleFilter = (event, attr) => {
    setFilter((current) => {
      return {
        ...current,
        [attr]: event
      };
    });
  };

  const handleOnClickUserSearchResult = (clickedItem) => {

    setSearchUserResult((current) => {
      const newData = current.filter((item) => item._id !== clickedItem._id);
      return [
        ...newData
      ];
    });
    setChosenUsers((current) => {
      const checkedItem = current.find((item) => item._id === clickedItem._id);
      if (!checkedItem) {
        current.push(clickedItem);
      }
      return [...current];
    });
  };

  const handleOnClickChosenUser = (clickedItem) => {
    setChosenUsers((current) => {
      const newData = current.filter((item) => item._id !== clickedItem._id);
      return [
        ...newData
      ];
    });
    setSearchUserResult((current) => {
      const checkedItem = current.find((item) => item._id === clickedItem._id);
      if (!checkedItem && window.COMMON.checkIncludesText(clickedItem.name, searchUserValue)) {
        current.push(clickedItem);
      }
      return [...current];
    });
  };

  const handleOnClose = () => {
    onClose();
    clearData();
  };




  return <FormModal id={modalId} title={window.I18N('invite_members')} saveEvent={saveForm} onClose={handleOnClose}>
    <form id="form" className="needs-validation" noValidate>

      <MDBCol xs='12'>
        {window.COMMON.checkRoleIsSystemOrInfluencer() && <div className="input-group input-group-transparent mb-2">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <MDBIcon fa="true" icon="filter" className="fa-fw" />
            </span>
          </div>
          <Select value={filter.company} options={companies} className="md-form m-0" placeholder={window.I18N('companies')} onChange={(event) => { handleFilter(event, 'company'); }} isSearchable closeMenuOnSelect={true} />
        </div>}
        <MDBInputGroup
          value={searchUserValue}
          id={'search_user_for_member'}
          name={'search_user_for_member'}
          type="text"
          hint={window.I18N('search_user')}
          onChange={(event) => {
            setSearchUserValue(event.target.value);
            handleSearchValueChange(event.target.value);
          }}
          prepend={
            <span className="input-group-text" id="btn-search">
              <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
            </span>
          }
        />
        {chosenUsers.length > 0 && <UserSearchContainerStyled>
          <div className="title">{window.I18N('chosen_users')}</div>
          {chosenUsers.length > 0 && <div className="list">
            {chosenUsers.map((item, index) => {
              return <div className="item" key={`${item._id}-chosen-user-form-${index}`} onClick={() => handleOnClickChosenUser(item)}>
                <CircleAvatar src={item.avatar} />
                <div className="content">{item.nickname}</div>
                <MDBIcon fa="true" icon="times" className="fa-fw"></MDBIcon>
              </div>;
            })}
          </div>}

        </UserSearchContainerStyled>}
        <UserSearchContainerStyled>
          <div className="title">{window.I18N('list_users')}</div>
          {searchUserLoading === true && <div className="w-100 d-flex justify-content-center"><LoadingSpinner /></div>}
          {(searchUserLoading === false && searchUserResult.length <= 0) && <div className="w-100 d-flex justify-content-center"><EmptyDataText /></div>}
          {searchUserResult.length > 0 && <div className="list">
            {searchUserResult.map((item, index) => {
              return <div className="item" key={`${item._id}-result-user-search-form-${index}`} onClick={() => handleOnClickUserSearchResult(item)}>
                <CircleAvatar src={item.avatar} />
                <div className="content">{item.nickname}</div>
              </div>;
            })}

          </div>}
        </UserSearchContainerStyled>



      </MDBCol>


    </form>
  </FormModal >;
};

export default InviteMembersFormModal;