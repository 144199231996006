import React, { useState, useEffect } from 'react';
import { MDBCard, MDBCardBody } from 'mdbreact';
import Select from 'react-select';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';



const HighLineChart = ({
  className = '',
  title = '',
  dataLabels = { data: [], labels: [] },
  seriesName = [],
  showDateFilter = false,
  onDateFilterChange = null,
  showInterFilter = false,
  hideTitle = false
}) => {
  const CHART_OPTIONS = {
    title: {
      text: 'My chart'
    },
    series: [
      {
        name: 'Tokyo',
        data: [7.0, 6.9, 9.5, 14.5, 18.4, 21.5, 25.2]
      },
      {
        name: 'London',
        data: [3.9, 4.2, 5.7, 8.5, 11.9, 15.2, 17.0]
      }
    ],
    yAxis: {
      // min:0,
      tickInterval: 5
    },
    xAxis: {
      categories: ['1', '2', '3', '4', '5', '6', '7'],
      tickInterval: 1,
      tickAmount: 7,
      showEmpty: true
    },
    tooltip: {
      shared: true,
      crosshairs: true
    }
  };
  const optionsDateFilter = [
    {
      label: window.I18N('date_filter'),
      options: [
        { label: window.I18N('past_7_days'), value: '7' },
        { label: window.I18N('past_30_days'), value: '30' },
        { label: window.I18N('past_90_days'), value: '90' },
        { label: window.I18N('past_12_months'), value: '1 year' }
      ]
    }
  ];
  const [dateFilterSelect, setDateFilterSelect] = useState(
    optionsDateFilter[0].options[0]
  );

  const optionsIntervalFilter = [
    {
      label: window.I18N('y_interval_filter'),
      options: [
        { label: '10', value: 10 },
        { label: '50', value: 50 }
      ]
    }
  ];
  const [intervalFilterSelect, setIntervalFilterSelect] = useState(optionsIntervalFilter[0].options[0]);
  const [chartOptions, setChartOptions] = useState(CHART_OPTIONS);

  useEffect(() => {
    if (dataLabels) {
      if (dataLabels.labels.length > 0) {
        chartOptions.xAxis.categories = dataLabels.labels;
        chartOptions.series = [];
        for (let i = 0; i < dataLabels.data.length; i++) {
          chartOptions.series.push({
            data: dataLabels.data[i],
            name: seriesName[i]
          });
        }
      }
    }
    setChartOptions((current) => {
      return { ...chartOptions };
    });
    // eslint-disable-next-line
  }, [dataLabels, seriesName]);

  useEffect(() => {
    chartOptions.title.text = `${title} ${!hideTitle ? dateFilterSelect?.label?.toLowerCase() : ''}`;
    setChartOptions((current) => {
      return { ...chartOptions };
    });
    // eslint-disable-next-line
  }, [title]);


  useEffect(() => {
    chartOptions.yAxis.tickInterval = intervalFilterSelect.value;
    setChartOptions((current) => {
      return { ...chartOptions };
    });
    // eslint-disable-next-line
  }, [intervalFilterSelect]);




  const handleSelectPastDaysChange = (selectValue) => {
    if (JSON.stringify(selectValue) === JSON.stringify(dateFilterSelect)) {
      return;
    }

    setDateFilterSelect({ ...selectValue });
    const value = selectValue.value;
    let xTickInterval = 1;
    const xIntervalOb = {
      7: 1,
      30: 5,
      90: 15
    };
    if (!value.includes('year')) {
      xTickInterval = xIntervalOb[value];
    }
    else {
      xTickInterval = 2;
    }

    setChartOptions((current) => {
      current.xAxis.tickInterval = xTickInterval;
	  current.title.text = `${title} ${!hideTitle ? selectValue?.label?.toLowerCase() : ''}`;
      return {
        ...current
      };
    });

    if (onDateFilterChange !== null) {
      onDateFilterChange(selectValue.value);
    }
  };
  const handleSelectIntervalChange = (selectValue) => {
    if (JSON.stringify(selectValue) === JSON.stringify(intervalFilterSelect)) {
      return;
    }
    setIntervalFilterSelect({ ...selectValue });
  };
  return (
    <MDBCard className={className}>
      <div className="d-flex align-items-center justify-content-end mt-3">
        {showDateFilter === true && (
          <div className="bd-highlight mr-3" style={{ width: '200px' }}>
            <div className="input-group">
              {' '}
              <Select
                className="md-form m-0"
                placeholder={window.I18N('choose_option')}
                value={dateFilterSelect}
                options={optionsDateFilter}
                onChange={handleSelectPastDaysChange}
                isSearchable
              />
            </div>
          </div>
        )}
        {showInterFilter === true && (
          <div className="bd-highlight mr-3" style={{ width: '200px' }}>
            <div className="input-group">
              {' '}
              <Select
                className="md-form m-0"
                placeholder={window.I18N('choose_option')}
                value={intervalFilterSelect}
                options={optionsIntervalFilter}
                onChange={handleSelectIntervalChange}
                isSearchable
              />
            </div>
          </div>
        )}
      </div>

      <MDBCardBody>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          allowChartUpdate={true}
        />
      </MDBCardBody>
    </MDBCard>
  );
};

export default HighLineChart;
