import React from 'react';

const Tab = React.memo(function Tab(props) {

  const handleTabClick = (index) => {
    if (window.COMMON.checkValue(props.changeTab)) {
      props.changeTab(index);
    }
  };

  const html = props.data.map((item, i) => 
    <li key={i} onClick={handleTabClick.bind(this, i)}>
      <a className={i === 0 ? 'tab-menu waves-effect active' : 'tab-menu waves-effect'} data-toggle="tab" href={'#tab-' + (i + 1)}>
        <i className={'fa fa-fw ' + item.icon}></i> {item.name}
      </a>
    </li>
  );
  return (
    <div id={props.id} className={'container-row ' + window.COMMON.getValue(props.className)} style={props.style}>
      <div className="container-tabs wow fadeIn animated" data-wow-delay="0.5s">
        <ul className="nav nav-tabs wow fadeInUp animated" data-wow-delay="1s">
          {html}
        </ul>
      </div>
      <div className="tab-content container-tabs-content wow fadeIn animated" data-wow-delay="0.5s">
        {props.children}
      </div>
    </div>
  );
});

export default Tab;