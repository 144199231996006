const commentEvent = {
  ADD_COMMENT: 'ADD_COMMENT',
  DEL_COMMENT: 'DEL_COMMENT',
  LIKE_COMMENT: 'LIKE_COMMENT',
  DISLIKE_COMMENT: 'DISLIKE_COMMENT',
  ADD_EMOTION: 'ADD_EMOTION',
  EDIT_EMOTION: 'EDIT_EMOTION',
  DEL_EMOTION: 'DEL_EMOTION'
};

export default commentEvent;