import gql from 'graphql-tag';

const QUERY_FIND_POST = gql`
  query ($text: String!) {
    findPosts(text: $text) {
      _id
      text
      image
      audio
      video
      createdDate
      type
      like {
        _id
      }
      dislike {
        _id
      }
      comment {
        _id
      }
      background {
        _id
        background
        color
        isGif
      }
      createdBy {
        avatar
        nickname
      }
    }
  }
`;

const QUERY_POST = gql`
  query ($sortType: String, $page: Int!, $pageSize: Int!) {
    getPosts(status: true, sortType: $sortType, page: $page, pageSize: $pageSize) {
      _id
      text
      image
      audio
      video
      type
      isPublic
      like {
        createdBy {
          _id
        }
      }
      dislike {
        createdBy {
          _id
        }
      }
      bookmark {
        createdBy {
          _id
        }
      }
      comment {
        _id
      }
      background {
        _id
        background
        color
        isGif
      }
      extractURL {
        url
        hostname
        icon
        title
        image
        content
      }
      emotion {
        _id
        content {
          code
          text
        }
        icon
      }
      tag {
        _id
        nickname
        avatar
      }
      answer {
        _id
        text
      }
      answerUser {
        answerId
        createdBy {
          _id
        }
      }
      fanpage {
        _id
        name
        image
      }
      createdDate
      createdBy {
        _id
        nickname
        avatar
      }
      postParent {
        _id
        text
        image
        audio
        video
        isPublic
        background {
          _id
          background
          color
          isGif
        }
        extractURL {
          url
          hostname
          icon
          title
          image
          content
        }
        fanpage {
          _id
          name
          image
        }
        createdBy {
          _id
          nickname
          avatar
        }
        createdDate
      }
    }
  }
`;

const QUERY_MY_POSTS = gql`
  query($user: String) {
    getMyPosts(user : $user) {
      _id
      text
      image
      audio
      video
      type
      isPublic
      like {
        createdBy {
          _id
        }
      }
      dislike {
        createdBy {
          _id
        }
      }
      comment {
        _id
      }
      background {
        _id
        background
        color
        isGif
      }
      extractURL {
        url
        hostname
        icon
        title
        image
        content
      }
      emotion {
        _id
        content {
          code
          text
        }
        icon
      }
      tag {
        _id
        nickname
        avatar
      }
      fanpage {
        _id
        name
        image
      }
      createdDate
      createdBy {
        _id
        nickname
        avatar
      }
      postParent {
        _id
        text
        image
        audio
        video
        isPublic
        background {
          _id
          background
          color
          isGif
        }
        extractURL {
          url
          hostname
          icon
          title
          image
          content
        }
        fanpage {
          _id
          name
          image
        }
        createdBy {
          _id
          nickname
          avatar
        }
        createdDate
      }
    }
  }
`;

const QUERY_REPORTED_POST = gql`
  query {
    getReportedPosts {
      _id
      post {
        _id
        text
        image
        audio
        video
        createdDate
        type
        createdBy {
          avatar
          nickname
        }
      }
      reason {
        _id
        content {
          code
          text
        }
      }
      status
      createdDate
      createdBy {
        avatar
        nickname
      }
    }
  }
`;

const QUERY_REVIEW_POST = gql`
  query ($input: ReviewPostFilter!) {
    getReviewPosts(input: $input) {
      total
      page
      pageSize
      data {
        _id
        text
        image
        audio
        video
        status
        statusReview
        like {
          createdBy {
            _id
          }
        }
        dislike {
          createdBy {
            _id
          }
        }
        comment {
          _id
        }
        background {
          _id
          background
          color
          isGif
        }
        fanpage {
          _id
          name
        }
        createdDate
        createdBy {
          _id
          avatar
          nickname
        }
        reviewDate
        reviewBy {
          _id
          avatar
          nickname
        }
        isPublic
        people {
          _id
          avatar
          nickname
        }
      }
    }
  }
`;

const QUERY_REVIEW_POST_DETAIL = gql`
  query ($postId: String!) {
    getReviewPostsDetail(postId: $postId) {
      _id
      statusReview
      description
      reason {
        _id
        content {
          code
          text
        }
      }
      createdDate
      createdBy {
        _id
        avatar
        nickname
      }
    }
  }
`;

const QUERY_COUNT_POST_REVIEW = gql`
  query($input: ReviewPostFilter!) {
    countPostReview(input: $input) {
      countPost
      countReview
      countHide
    }
  }
`;

const QUERY_COUNT_POST = gql`
  query($input: CountPostFilter!) {
    countPost(input: $input)
  }
`;

const MUTATION_ADD_POST = gql`
  mutation ($input: PostInput!) {
    addPost(input: $input) {
      _id
    }
  }
`;

const MUTATION_DEL_POST = gql`
  mutation ($id: String!) {
    deletePost(id: $id) {
      _id
    }
  }
`;

const MUTATION_SHARE_POST = gql`
    mutation($input: ShareInput!) {
      sharePost(input: $input) {
        _id
        gopy
      }
    }
`;

const MUTATION_LIKE_POST = gql`
  mutation ($input: LikeInput!) {
    likePost(input: $input) {
      _id
      gopy
    }
  }
`;

const MUTATION_DISLIKE_POST = gql`
  mutation ($input: LikeInput!) {
    dislikePost(input: $input) {
      _id
      gopy
    }
  }
`;

const MUTATION_REVIEW_POST = gql`
  mutation ($postId: String!, $statusReview: String!, $reason: String, $description: String) {
    reviewPost(postId: $postId, statusReview: $statusReview, reason: $reason, description: $description)
  }
`;

const MUTATION_ACCEPT_REPORT_POST = gql`
  mutation ($postIds: [String!]) {
    acceptReportPost(postIds: $postIds)
  }
`;

const MUTATION_DECLINE_REPORT_POST = gql`
  mutation ($postIds: [String!]) {
    declineReportPost(postIds: $postIds)
  }
`;


const QUERY_SCHEDULE_POST = gql`
  query ($input: SchedulePostFilter!) {
    getSchedulePosts(input: $input) {
      total
      page
      pageSize
      data {
        _id
        date
        color
        post {
          _id
          text
          image
          audio
          video
          type
          answer {
            _id
            text
          }
          people {
            _id
          }
        }
      }
    }
  }
`;

const MUTATION_ADD_SCHEDULE_POST = gql`
  mutation ($input: SchedulePostInput!) {
    addSchedulePost(input: $input)
  }
`;

const MUTATION_EDIT_SCHEDULE_POST = gql`
  mutation ($input: SchedulePostInput!) {
    editSchedulePost(input: $input)
  }
`;

const MUTATION_DEL_SCHEDULE_POST = gql`
  mutation ($id: String!) {
    deleteSchedulePost(id: $id)
  }
`;

const QUERY_BACKGROUND_POST = gql`
  query ($input: BackgroundPostFilter!) {
    getBackgroundPosts(input: $input) {
      total
      page
      pageSize
      data {
        _id
        name
        background
        color
        hashtag
        isGif
        createdDate
        createdBy {
          avatar
          name
        }
      }
    }
  }
`;

const MUTATION_ADD_BACKGROUND_POSTS = gql`
  mutation ($input: [BackgroundPostInput!]) {
    addBackgroundPosts(input: $input)
  }
`;

const MUTATION_EDIT_BACKGROUND_POST = gql`
  mutation ($input: BackgroundPostInput!) {
    editBackgroundPost(input: $input)
  }
`;

const MUTATION_DEL_BACKGROUND_POST = gql`
  mutation ($id: String!) {
    deleteBackgroundPost(id: $id)
  }
`;

const MUTATION_PUSH_NOTIFICATION = gql`
  mutation ($input: NotificationInput!) {
    pushNotification(input: $input)
  }
`;

const PostGRAPHQL = {
  QUERY_FIND_POST,
  QUERY_REPORTED_POST,
  QUERY_POST,
  QUERY_MY_POSTS,
  QUERY_COUNT_POST,
  QUERY_REVIEW_POST,
  QUERY_REVIEW_POST_DETAIL,
  QUERY_COUNT_POST_REVIEW,
  QUERY_SCHEDULE_POST,
  QUERY_BACKGROUND_POST,
  MUTATION_ADD_POST,
  MUTATION_DEL_POST,
  MUTATION_SHARE_POST,
  MUTATION_LIKE_POST,
  MUTATION_DISLIKE_POST,
  MUTATION_REVIEW_POST,
  MUTATION_ACCEPT_REPORT_POST,
  MUTATION_DECLINE_REPORT_POST,
  MUTATION_ADD_SCHEDULE_POST,
  MUTATION_EDIT_SCHEDULE_POST,
  MUTATION_DEL_SCHEDULE_POST,
  MUTATION_ADD_BACKGROUND_POSTS,
  MUTATION_EDIT_BACKGROUND_POST,
  MUTATION_DEL_BACKGROUND_POST,
  MUTATION_PUSH_NOTIFICATION
};

export default PostGRAPHQL;
