import React, { useState, useCallback, useLayoutEffect } from 'react';
import Select from 'react-select';
import { MDBRow, MDBCol, MDBInput, MDBCardImage, MDBBtn, MDBIcon } from 'mdbreact';
import DatePicker from 'react-datepicker';

import Panel from '../../components/Panel';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import RadioBox from '../../components/RadioBox';
import InputFile from '../../components/InputFile';
// import ImportExcel from '../../components/ImportExcel';
// import ExportExcel from '../../components/ExportExcel';

import AccountGRAPHQL from '../../../graphql/Account';
import MedicalGRAPHQL from '../../../graphql/Medical';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';

export default function VaccinationTestingInfoPage(props) {
  const { trackEvent } = useTracking();

  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const testingType = [
    { value: 'RAPID', label: 'Rapid Test'},
    { value: 'PCR', label: 'RT-PCR test'}
  ];
  const vaccine = [
    { value: '1', label: '1 doze(s)'},
    { value: '2', label: '2 doze(s)'},
    { value: '3', label: '3 doze(s)'},
    { value: '4', label: '4 doze(s)'},
    { value: '5', label: '5 doze(s)'}
  ];
  const vaccineType = [
    { value: 'AZ', label: 'AstraZeneca'},
    { value: 'MO', label: 'Moderna'},
    { value: 'PZ', label: 'Pfizer'},
    { value: 'SV', label: 'Sputnik V'},
    { value: 'VC', label: 'Vero Cell'}
  ];
  const status = [
    { value: false, label: window.I18N('negative'), icon: 'minus-circle'},
    { value: true, label: window.I18N('positive'), icon: 'plus-circle'}
  ];
  const config = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('name'), '20%', 'p-0 pl-1', '', 'avatar,name', 'IMAGE_TEXT'),
    window.COMMON.createCol(window.I18N('gender'), '10%', '', '', 'genderText', 'TEXT'),
    window.COMMON.createCol(window.I18N('birthday'), '10%', '', '', 'birthday', 'TEXT'),
    window.COMMON.createCol(window.I18N('vaccination'), '25%', '', '', 'vaccine', 'VACCINE'),
    window.COMMON.createCol(window.I18N('testing'), '30%', '', '', 'medical', 'MEDICAL')
  ];
  const [filter, setFilter] = useState({company: null});
  const [object, setObject] = useState({ index: -1, item: null, vaccine: null, medical: null, image: null });
  const [data, setData] = useState([]);
  const [dataVaccine, setDataVaccine] = useState([]);
  const [dataMedical, setDataMedical] = useState([]);
  const [dataImport, setDataImport] = useState({});
  const [dataSave, setDataSave] = useState({ isPositive: false });
  const [file, setFile] = useState(null);
  const [select, setSelect] = useState({});
  const [date, setDate] = useState({ date: new Date() });
  const [options, setOptions] = useState({
    company: []
  });

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataCompany();
    }, 100);
    // eslint-disable-next-line
  }, []);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleChange = (event) => {
    dataSave[event.target.name] = event.target.value;
    setDataSave({ ...dataSave });
  };

  const handleRadio = useCallback((value) => {
    dataSave.isPositive = value;
    setDataSave({ ...dataSave });
  }, [dataSave]);

  const handleFile = (file) => {
    dataSave.image = window.COMMON.createBlobImage(file);
    setFile(file);
    setDataSave({ ...dataSave });
  };

  const handleFilterSelect = (event) => {
    filter.company = event;
    setFilter({ ...filter });
    getDataAccount();
  };

  const handleSelect = (event, id, attr) => {
    window.COMMON.checkSelectValidation(id, event);
    select[attr] = event;
    setSelect({ ...select });
  };

  const handleDatePicker = (event, attr) => {
    date[attr] = event;
    setDate({ ...date });
  };

  // const handleFileImport = (dataImport) => {
  //   setDataImport(dataImport);
  //   window.COMMON.showModal('#modal-import');
  // };

  const getDataCompany = async () => {
    try {
      if (COMPANY_TYPE === window.CONSTANT.COMPANY || COMPANY_TYPE === window.CONSTANT.UNION || COMPANY_TYPE === window.CONSTANT.INFLUENCER) {
        filter.company = { value: COMPANY_ID, label: '', code: COMPANY_TYPE };
        setFilter({ ...filter }); 
        getDataAccount();
        return;
      }
      await window.COMMON.getDataCompany();
      const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_COMPANY));
      options.company = window.COMMON.createDataSelectWithGroup(dataCompany, 'id', 'name', 'accountType', 'accountType');
      filter.company = window.COMMON.getValueFromAttr(options.company, '0.options.0', null);  
      setOptions(options);
      setFilter({ ...filter });
      getDataAccount();
    } catch (error) {
      window.COMMON.showErrorLogs('VaccinationTestingInfoPage.getDataCompany');
    }
  };

  const getDataAccount = async () => {
    try {
      window.resetDataTable('table-data');
      if (filter.company !== null) {
        const params = {
          company: filter.company.value,
          companyType: filter.company.code
        };
        const result = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_MEDICAL, params);
        if (result && result.data) {
          const data = window.COMMON.formatMultiDate(result.data.getAccounts, 'createdDate,birthday');
          data.forEach(item => {
            item.genderText = item.gender === 0 ? window.I18N('female') : window.I18N('male');
            if (item.medical) {
              const type = window.COMMON.getObjectInArrs(item.medical.type, testingType, 'value');
              item.medical.typeName = type.label;
              item.medical.date = window.COMMON_DATE.formatDate(item.medical.date);
              item.medical.expiredDate = window.COMMON_DATE.formatDate(item.medical.expiredDate);
            }
          });
          setData(data);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('VaccinationTestingInfoPage.getDataAccount');
    }
    window.initDataTable('table-data');
  };

  const getDataVaccine = async () => {
    try {
      const params = {
        user: object.item._id
      };
      const result = await window.COMMON.query(MedicalGRAPHQL.QUERY_VACCINE_BY_USER, params);
      let data = [];
      if (result && result.data) {
        data = result.data.getVaccineByUser;
        data.forEach(item => {
          const type = window.COMMON.getObjectInArrs(item.vaccineType, vaccineType, 'value');
          item.vaccineTypeName = type ? type.label : '';
          item.createdDate = window.COMMON_DATE.formatDate(item.createdDate);
          item.vaccinationDate = window.COMMON_DATE.formatDate(item.date);
        });
      }
      setDataVaccine(data);
    } catch (error) {
      window.COMMON.showErrorLogs('VaccinationTestingInfoPage.getDataVaccine');
    }
  };

  const getDataMedical = async () => {
    try {
      const params = {
        user: object.item._id
      };
      const result = await window.COMMON.query(MedicalGRAPHQL.QUERY_MEDICAL_BY_USER, params);
      let data = [];
      if (result && result.data) {
        data = result.data.getMedicalByUser;
        data.forEach(item => {
          const type = window.COMMON.getObjectInArrs(item.type, testingType, 'value');
          item.createdDate = window.COMMON_DATE.formatDate(item.createdDate);
          item.testingDate = window.COMMON_DATE.formatDate(item.date);
          item.expiredDate = window.COMMON_DATE.formatDate(item.expiredDate);
          item.testingType = type ? type.label : '';
        });
      }
      setDataMedical(data);
    } catch (error) {
      window.COMMON.showErrorLogs('VaccinationTestingInfoPage.getDataMedical');
    }
  };

  const showContainerData = useCallback(async (index) => {
    window.COMMON.showContainerSave();
    object.item = data[index];
    object.vaccine = window.COMMON.getValueFromAttr(object.item, 'vaccine');
    object.medical = window.COMMON.getValueFromAttr(object.item, 'medical');
    setObject({ ...object });
    getDataVaccine();
    getDataMedical();
    // eslint-disable-next-line
  }, [object, data]);

  const openPreviewImage = (image) => {
    object.image = window.COMMON.setNewValueImage(image);
    setObject({ ...object });
    window.COMMON.showModal('#modal-preview');
  };
  
  const openSaveDialog = async (mode) => {
    window.COMMON.showModal('#modal-medical');
    object.mode = mode;
    const dataSave = { image: '' }; 
    if (mode === 0) {
      select.vaccine = vaccine[0];
      select.type = vaccineType[0];
    } else {
      select.type = testingType[0];
    }
    dataSave.content = '';
    dataSave.isPositive = false;
    date.date = new Date();
    document.getElementById('file-image').value = null;
    setFile(null);
    setSelect(select);
    setDate(date);
    setObject(object);
    setDataSave({ ...dataSave });
  };

  const openDeleteData = async (mode, index) => {
    object.mode = mode;
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteData;
  };

  const saveData = async (event) => {
    try {
      const checkForm = window.COMMON.checkFormValidation('form', event);
      if (!checkForm || !dataSave.image) {
        window.COMMON.showMessage('warning', 'MSG_CODE_042', window.MSG.MSG_CODE_042);
        return;
      }
      const dataFiles = await window.uploadFile([file]);
      const documents = [];
      if (dataFiles && dataFiles.length > 0) {
        documents.push({
          id: dataFiles[0]._id,
          name: dataFiles[0].name,
          size: dataFiles[0].size,
          type: dataFiles[0].mime,
          ext: dataFiles[0].ext
        });
      }
      if (documents.length === 0) {
        return;
      }
      if (object.mode === 0) {
        const params = {
          input: {
            user: object.item._id,
            vaccine: parseInt(window.COMMON.getDataSelect(select.vaccine, false)),
            vaccineType: window.COMMON.getDataSelect(select.type, false),
            content: dataSave.content,
            date: date.date.toString(),
            company: window.COMMON.getDataSelect(filter.company, false),
            status: true,
            documents
          }
        };
        window.COMMON.trimData(params);
        const result = await window.COMMON.mutation(MedicalGRAPHQL.MUTATION_ADD_VACCINE, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataVaccine();
          getDataAccount();
		  createTrackingEvent(eventName.ADD_VACCINE);
        }
      } else {
        const params = {
          input: {
            user: object.item._id,
            type: window.COMMON.getDataSelect(select.type, false),
            isPositive: dataSave.isPositive,
            content: dataSave.content,
            date: date.date.toString(),
            company: window.COMMON.getDataSelect(filter.company, false),
            status: true,
            documents
          }
        };
        window.COMMON.trimData(params);
        const result = await window.COMMON.mutation(MedicalGRAPHQL.MUTATION_ADD_MEDICAL, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataMedical();
          getDataAccount();
		  createTrackingEvent(eventName.ADD_MEDICAL);
        }
      }
      window.COMMON.hideModal('#modal-medical');
    } catch (error) {
      window.COMMON.showErrorLogs('VaccinationTestingInfoPage.saveData');
    }
  };

  const deleteData = async () => {
    try {
      if (object.mode === 0) {
        const params = {
          id: dataVaccine[object.index]._id
        };
        const result = await window.COMMON.mutation(MedicalGRAPHQL.MUTATION_DELELE_VACCINE, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
          getDataVaccine();
          getDataAccount();
		  createTrackingEvent(eventName.DELELE_VACCINE);
        }
      } else {
        const params = {
          id: dataMedical[object.index]._id
        };
        const result = await window.COMMON.mutation(MedicalGRAPHQL.MUTATION_DELETE_MEDICAL, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
          getDataMedical();
          getDataAccount();
		  createTrackingEvent(eventName.DELETE_MEDICAL);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('VaccinationTestingInfoPage.deleteData');
    }
  };

  // IMPORT
  const deleteImportRow = useCallback((index) => {
    if (dataImport.data.length > 0) {
      dataImport.data.splice(index, 1);
    }
    setDataImport({ ...dataImport });
  }, [dataImport]);

  const importData = async () => {
    if (dataImport.data.length === 0) {
      window.COMMON.showMessage('warning', 'MSG_CODE_021', window.MSG.MSG_CODE_021);
      return;
    }
    try {
      const dataImported = [];
      dataImport.data.forEach(item => {
        const obj = JSON.parse(JSON.stringify(item));
        delete obj.isExisted;
        dataImported.push({
          accountType: window.CONSTANT.INDIVIDUAL,
          individual: obj
        });
      });
      const params = {
        input: dataImported
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_IMPORT_ACCOUNT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_IMPORT)) {
        getDataAccount();
        window.COMMON.hideModal('#modal-import');
        createTrackingEvent(eventName.IMPORT_ACCOUNT);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('VaccinationTestingInfoPage.importData');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" icon="list-alt">
        {
          !window.COMMON.checkRoleIsSystem() ? <div></div> : <div className="container-top d-flex align-items-center justify-content-end wow fadeInDown animated" data-wow-delay="0.5s">
            <div className="bd-highlight" style={{ width: '300px' }}>
              <div className="input-group input-group-transparent">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="building" className="fa-fw" />
                  </span>
                </div>
                <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.company} options={options.company} onChange={(event) => handleFilterSelect(event, 'company')} isSearchable />
              </div>
            </div>
          </div>
        }
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <Table id="table-data" className="table" config={config} data={data} clickRow={showContainerData}></Table>
        </div>
      </Panel>

      <Panel id="container-save" className="container-panel-transparent" icon="file" style={{ display: 'none' }}>
        <div className="container-btn wow fadeInDown animated" data-wow-delay="0.5s">
          <MDBBtn color="" onClick={window.COMMON.backContainerData}>
            <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('btn_back')}
          </MDBBtn>
        </div>

        <MDBRow className="mt-3">
          <MDBCol>
            <div className="card wow fadeInDown animated" data-wow-delay="1s">
              <div className="card-header">
                {window.I18N('account_information')}
              </div>
              <div className="card-body">
                <table className="table table-view mb-0">
                  <tbody>
                    <tr>
                      <td width="20%">{window.I18N('name')}</td>
                      <td width="30%">{window.COMMON.getValueFromAttr(object.item, 'name')}</td>
                      <td width="20%">{window.I18N('nickname')}</td>
                      <td width="30%">{window.COMMON.getValueFromAttr(object.item, 'nickname')}</td>
                    </tr>
                    <tr>
                      <td width="20%">{window.I18N('gender')}</td>
                      <td width="30%">{window.COMMON.getValueFromAttr(object.item, 'genderText')}</td>
                      <td width="20%">{window.I18N('birthday')}</td>
                      <td width="30%">{window.COMMON.getValueFromAttr(object.item, 'birthday')}</td>
                    </tr>
                    <tr>
                      <td width="20%">{window.I18N('vaccination')}</td>
                      <td width="30%">
                        {
                          object.vaccine ? <div>
                            <label className={'label-box p-1 mb-0 mr-1 ' + (object.vaccine.vaccine < 2 ? 'label-warning' : 'label-success')}>
                              {object.vaccine.vaccine + ' doze(s) - ' + object.vaccine.vaccineType}
                              <MDBIcon fa="true" icon="caret-down" className="fa-fw" />
                            </label>
                            <label className={'label-box p-1 mb-0 ' + (!object.vaccine.status ? 'label-warning' : 'label-success')}>
                              {object.vaccine.status ? window.I18N('approved') : window.I18N('pending')}
                              <MDBIcon fa="true" icon="caret-down" className="fa-fw" />
                            </label>
                          </div> : <label className="label-box label-light p-1 mb-0 mr-1">{window.I18N('none')} <MDBIcon fa="true" icon="caret-down" className="fa-fw" /></label>
                        }
                      </td>
                      <td width="20%">{window.I18N('testing')}</td>
                      <td width="30%">
                        {
                          object.medical ? <div>
                            <label className="label-box label-light p-1 mb-0 mr-1">{object.medical.typeName} <MDBIcon fa="true" icon="caret-down" className="fa-fw" /></label>
                            <label className={'label-box p-1 mb-0 mr-1 ' + (object.medical.isPositive ? 'label-danger' : 'label-success')}>
                              {object.medical.isPositive ? <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> : <MDBIcon fa="true" icon="minus-circle" className="fa-fw" /> }
                              {(object.medical.isPositive ? window.I18N('positive') : (window.I18N('negative') + ' - ' + object.medical.expiredDate))}
                              <MDBIcon fa="true" icon="caret-down" className="fa-fw" />
                            </label>
                            <label className={'label-box p-1 mb-0 ' + (!object.medical.status ? 'label-warning' : 'label-success')}>
                              {object.medical.status ? window.I18N('approved') : window.I18N('pending')}
                              <MDBIcon fa="true" icon="caret-down" className="fa-fw" />
                            </label>
                          </div> : <label className="label-box label-light p-1 mb-0 mr-1">{window.I18N('none')} <MDBIcon fa="true" icon="caret-down" className="fa-fw" /></label>
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow className="mt-3">
          <MDBCol md="6" className="wow fadeInLeft animated" data-wow-delay="1s">
            <ul className="list-group">
              <li className="list-group-item">
                <div className="d-flex align-items-center w-100">
                  <span className="font-weight-bold">{window.I18N('vaccination')}</span>
                  <div className="ml-auto">
                    <MDBBtn color="primary" onClick={() => openSaveDialog(0)}>
                      <MDBIcon fa="true" icon="plus-circle" className="fa-fw"/> {window.I18N('btn_add')}
                    </MDBBtn>
                  </div>
                </div>
              </li>
              {
                dataVaccine.map((item, i) => <li className="list-group-item" key={i}>
                  <div className="d-flex align-items-center w-100">
                    <div className="w-100 mr-auto">
                      <MDBRow className="m-0">
                        <MDBCol className="pl-0 pr-2">
                          <div onClick={() => openPreviewImage(window.COMMON.getValueFromAttr(item, 'documents.0.id'))}>
                            <MDBCardImage zoom cascade waves src={window.COMMON.setNewValueImage(window.COMMON.getValueFromAttr(item, 'documents.0.id'))} className="image-icon"/>
                          </div>
                        </MDBCol>
                        <MDBCol className="pl-0 pr-2">
                          <p className="mb-0"><span className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'vaccine')}</span> {window.I18N('dozes')} - {window.COMMON.getValueFromAttr(item, 'vaccineTypeName')}</p>
                          <small className="mb-0 text-muted">{window.I18N('vaccination_date')}: {window.COMMON.getValueFromAttr(item, 'vaccinationDate')}</small>
                        </MDBCol>
                        <MDBCol className="pl-0 pr-2">
                          <div className="d-flex flex-column align-items-center justify-content-end h-100">
                            <label className={'label-box p-1 mb-0 ' + (!item.status ? 'label-warning' : 'label-success')}>
                              {item.status ? window.I18N('approved') : window.I18N('pending')}
                              <MDBIcon fa="true" icon="caret-down" className="fa-fw" />
                            </label>
                            <span className="text-muted"><small>{window.COMMON.getValueFromAttr(item, 'createdDate')}</small></span>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </div>
                    <MDBIcon fa="true" icon="trash-alt" className="fa-fw" style= {{ color: 'grey', cursor: 'pointer' }} onClick={() => openDeleteData(0, i)}/>
                  </div>
                </li>)
              }
            </ul>
          </MDBCol>
          <MDBCol md="6" className="wow fadeInRight animated" data-wow-delay="1s">
            <ul className="list-group">
              <li className="list-group-item">
                <div className="d-flex align-items-center w-100">
                  <span className="font-weight-bold">{window.I18N('testing')}</span>
                  <div className="ml-auto">
                    <MDBBtn color="primary" onClick={() => openSaveDialog(1)}>
                      <MDBIcon fa="true" icon="plus-circle" className="fa-fw"/> {window.I18N('btn_add')}
                    </MDBBtn>
                  </div>
                </div>
              </li>
              {
                dataMedical.map((item, i) => <li className="list-group-item" key={i}>
                  <div className="d-flex align-items-center w-100">
                    <div className="w-100 mr-auto">
                      <MDBRow className="m-0">
                        <MDBCol className="pl-0 pr-2">
                          <div onClick={() => openPreviewImage(window.COMMON.getValueFromAttr(item, 'documents.0.id'))}>
                            <MDBCardImage zoom cascade waves src={window.COMMON.setNewValueImage(window.COMMON.getValueFromAttr(item, 'documents.0.id'))} className="image-icon"/>
                          </div>                        </MDBCol>
                        <MDBCol className="pl-0 pr-2">
                          <p className="mb-0">
                            {window.COMMON.getValueFromAttr(item, 'isPositive') ? <label className="font-weight-bold label-box label-danger p-1 m-0"><MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('positive')}</label> : <label className="font-weight-bold label-box label-success p-1 m-0"><MDBIcon fa="true" icon="minus-circle" className="fa-fw" /> {window.I18N('negative')}</label>}
                            <span> - {window.COMMON.getValueFromAttr(item, 'testingType')}</span></p>
                          <small className="mb-0 text-muted">{window.I18N('valid_date')}: {window.COMMON.getValueFromAttr(item, 'testingDate')} - {window.COMMON.getValueFromAttr(item, 'expiredDate')}</small>
                        </MDBCol>
                        <MDBCol className="pl-0 pr-2">
                          <div className="d-flex flex-column align-items-center justify-content-end h-100">
                            <label className={'label-box p-1 mb-0 ' + (!item.status ? 'label-warning' : 'label-success')}>
                              {item.status ? window.I18N('approved') : window.I18N('pending')}
                              <MDBIcon fa="true" icon="caret-down" className="fa-fw" />
                            </label>
                            <span className="text-muted"><small>{window.COMMON.getValueFromAttr(item, 'createdDate')}</small></span>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </div>
                    <MDBIcon fa="true" icon="trash-alt" className="fa-fw" style= {{ color: 'grey', cursor: 'pointer' }} onClick={() => openDeleteData(1, i)}/>
                  </div>
                </li>)
              }
            </ul>
          </MDBCol>
        </MDBRow>
      </Panel>

      <Modal id="modal-medical" title={object.mode === 0 ? window.I18N('add_vaccination') : window.I18N('add_testing')} saveEvent={saveData}>
        <form id="form" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              {
                object.mode === 0 ? <>
                  <Select id="select-vaccine" value={select.vaccine} options={vaccine} placeholder={window.I18N('vaccine_dozes') + ' *'} className="md-form mt-0" onChange={(event) => handleSelect(event, 'select-vaccine', 'vaccine')} isSearchable/>
                  <Select id="select-type" value={select.type} options={vaccineType} placeholder={window.I18N('vaccine_type') + ' *'} className="md-form" onChange={(event) => handleSelect(event, 'select-type', 'type')} isSearchable/>
                  <div className="input-group input-group-transparent">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                      </span>
                    </div>
                    <DatePicker calendarClassName="12" placeholderText={window.I18N('vaccination_date')} className="form-control" dateFormat="dd/MM/yyyy" selected={date.date} onChange={(event) => handleDatePicker(event, 'date')} showYearDropdown showMonthDropdown/>
                  </div>
                </>: <>
                  <Select id="select-type" value={select.type} options={testingType} placeholder={window.I18N('testing_type') + ' *'} className="md-form" onChange={(event) => handleSelect(event, 'select-type', 'type')} isSearchable/>
                  <RadioBox data={status} value={dataSave.isPositive} handleChange={handleRadio}></RadioBox>
                  <div className="input-group input-group-transparent">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                      </span>
                    </div>
                    <DatePicker calendarClassName="12" placeholderText={window.I18N('testing_date')} className="form-control" dateFormat="dd/MM/yyyy" selected={date.date} onChange={(event) => handleDatePicker(event, 'date')} showYearDropdown showMonthDropdown/>
                  </div>
                </>
              }
              <MDBInput outline value={dataSave.content} name="content" onChange={handleChange} type="text" label={window.I18N('content')} maxLength="200" pattern="\S(.*\S)?"></MDBInput>
              <div className="text-center">
                <MDBCardImage className="image-survey" src={window.COMMON.setValueImage(dataSave.image)} hover overlay="white-slight"/><br></br>
                <InputFile id="file-image" icon="upload" handleFile={handleFile} accept="image/*" description="Image"></InputFile>
              </div>
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>

      <Modal id="modal-preview" title={window.I18N('preview')} className="modal-lg">
        <div className="text-center">
          <MDBCardImage className="image-preview" src={object.image} hover overlay="white-slight"/>
        </div>
      </Modal>

      <Modal id="modal-import" className="modal-xl" title={window.I18N('import_data')} saveEvent={importData}>
        <Table id="table-import" className="table" config={dataImport.config} data={dataImport.data} handleRowDelClick={deleteImportRow} isImported={true}></Table>
      </Modal>
    </div>
  );
}