import React, { useState, useLayoutEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { MDBCardImage, MDBInputGroup, MDBCardHeader, MDBCardBody, MDBCard, MDBCol, MDBRow, MDBBtn, MDBIcon } from 'mdbreact';

import ExportExcel from '../../components/ExportExcel';
import SelfAssessmentPDF from '../../pdf/SelfAssessmentPDF';
import Modal from '../../components/Modal';
import ItemQuestionData from '../../components/ItemQuestionData';

import SelfAssessmentGRAPHQL from '../../../graphql/SelfAssessment';
import QuestionGRAPHQL from '../../../graphql/Question';
import AccountGRAPHQL from '../../../graphql/Account';

function SelfAssessmentReportPage(props) {
  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const ACCOUNT_TYPE = localStorage.getItem(window.CONSTANT.ACCOUNT_TYPE);
  const language = useRef();
  const [filter, setFilter] = useState({
    company: null,
    group: null,
    influencer: null,
    selfAssessment: null,
    text: '',
    startDate: window.COMMON_DATE.addDaysFromNow(-30),
    endDate: new Date()
  });
  const [object, setObject] = useState({
    item: null,
    index: 0,
    templatePDF: null
  });
  const [dataSelfAssessment, setDataSelfAssessment] = useState([]);
  const [dataSelfAssessmentAssessor, setDataSelfAssessmentAssessor] = useState([]);
  const [dataQuestion, setDataQuestion] = useState([]);
  const [dataAnswer, setDataAnswer] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [dataNote, setDataNote] = useState([]);
  const [options, setOptions] = useState({
    group: [],
    company: [],
    influencer: [],
    selfAssessment: []
  });
  const [count, setCount] = useState({
    company: 0,
    employee: 0,
    response: 0,
    question: 0,
    employeeResponse: 0
  });

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {
      getDataCompany();
    }, 100);
    // eslint-disable-next-line
  }, [props.language]);

  const handleFilterSelect = (event, attr) => {
    filter[attr] = event;
    if (attr === 'selfAssessment' && filter.selfAssessment) {
      if (filter.selfAssessment) {
        const obj = window.COMMON.getObjectInArrs(filter.selfAssessment.value, dataSelfAssessment, '_id');
        options.group = window.COMMON.createDataSelectWithLanguage(window.COMMON.getValueFromAttr(obj, 'groups', []), '_id', 'name', language.current, false);
        filter.group = window.COMMON.getValueFromAttr(options.group, '0', null);
        options.company = window.COMMON.createDataSelect(window.COMMON.getValueFromAttr(obj, 'companies', []), '_id', 'information.name');
        filter.company = window.COMMON.getValueFromAttr(options.company, '0', null);
      }
    }
    setFilter({ ...filter });
    setCount(prev => ({...prev, company: options.company.length}));
    if (attr === 'influencer') {
      getDataSelfAssessment();
    }
  };

  const handleDatePicker = (event, attr) => {
    filter[attr] = event;
    setFilter({ ...filter });
  };

  const handleChange = (event) => {
    filter.text = event.target.value;
    setFilter({ ...filter });
    doSearchQuestion();
  };

  const getDataCompany = async () => {
    try {
      if (!window.COMMON.checkRoleIsSystem()) {
        filter.influencer = { value: COMPANY_ID, label: '' };
        setFilter({ ...filter }); 
        getDataSelfAssessment();
        return;
      }
      await window.COMMON.getDataAllCompany();
      const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));
      if (dataCompany && dataCompany.length > 0) {
        const dataInfluencer = window.COMMON.getArrsInArrs(window.CONSTANT.INFLUENCER, dataCompany, 'type');
        options.influencer = window.COMMON.createDataSelect(dataInfluencer, '_id', 'information.name');
        filter.influencer = window.COMMON.getValueFromAttr(options.influencer, '0', null);
      }
      setOptions(options);
      setFilter({ ...filter });
      getDataSelfAssessment();
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentReportPage.getDataCompany');
    }
  };

  // SELF ASSESSMENT
  const getDataSelfAssessment = async () => {
    try {
      const params = {
        company: window.COMMON.checkRoleIsSystem() || ACCOUNT_TYPE === window.CONSTANT.INFLUENCER ? window.COMMON.getDataSelect(filter.influencer, false) : null
      };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_SELF_ASSESSMENT, params);
      let data = [];
      options.selfAssessment = [];
      options.group = [];
      options.company = [];
      filter.selfAssessment = null;
      filter.group = null;
      filter.company = null;
      filter.group = null;
      filter.company = null;
      if (result && result.data) {
        data = result.data.getSelfAssessments;
        options.selfAssessment = window.COMMON.createDataSelect(data, '_id', 'name');
        filter.selfAssessment = window.COMMON.getValueFromAttr(options.selfAssessment, '0', null);
        if (filter.selfAssessment) {
          options.group = window.COMMON.createDataSelectWithLanguage(window.COMMON.getValueFromAttr(data, '0.groups', []), '_id', 'name', language.current, false);
          filter.group = window.COMMON.getValueFromAttr(options.group, '0', null);
          options.company = window.COMMON.createDataSelect(window.COMMON.getValueFromAttr(data, '0.companies', []), '_id', 'information.name');
          filter.company = window.COMMON.getValueFromAttr(options.company, '0', null);
        }
      }
      setOptions(options);
      setCount(prev => ({...prev, company: options.company.length}));
      setDataSelfAssessment(data);
      setFilter({ ...filter });
      doSearchData();
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.getDataSelfAssessment');
    }
  };

  const doSearchQuestion = () => {
    let dataSearch = [];
    if (filter.text) {
      dataQuestion.forEach(item => {
        if (window.COMMON.getValueWithLanguage(item, 'question', language.current).toLowerCase().indexOf(filter.text.toLowerCase()) > -1) {
          dataSearch.push(item);
        }
      });
    } else {
      dataSearch = dataQuestion;
    }
    setDataSearch(dataSearch);
  };

  const changeQuestion = (index) => {
    object.index = index;
    object.item = dataQuestion[index];
    setObject({ ...object });
  };

  const doSearchData = () => {
    if (filter.group) {
      getDataQuestion();
    }
    if (filter.company) {
      countCompanyEmployees();
    }
  };

  const countCompanyEmployees = useCallback(async () => {
    try {
      const res = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_BY_COMPANY_ID, {
        companyIds: [filter.company.value]
      });


      const currentCompanyResult = res?.data?.countAccountByCompany?.length ?  res?.data?.countAccountByCompany[0] : {};

      setCount(prev => ({...prev, employee: currentCompanyResult.count || 0}));

    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentReportPage.countCompanyEmployees');
    }
  }, [filter.company]);

  const getDataQuestion = async () => {
    try {
      const params = {
        groupId: window.COMMON.getDataSelect(filter.group, false)
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_QUESTION, params);
      let dataQuestion = [];
      if (result && result.data && result.data.getQuestions) {
        dataQuestion = result.data.getQuestions;
        let index = 0;
        dataQuestion.forEach(question => {
          question.index = index++;
          question.answerCompany = '';
          question.answerWorker = '';
          question.answerInfluencer = '';
          question.total = 0;
        });
        count.question = dataQuestion.length;
      }

      setCount(prev => ({...prev, question: dataQuestion.length}));

      getDataAnswer(dataQuestion);
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentReportPage.getDataQuestion');
    }
  };

  const getDataAnswer = async (dataQuestion) => {
    try {
      const params = {
        groupId: window.COMMON.getDataSelect(filter.group, false)
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_ANSWER_QUESTION, params);
      let dataAnswer = [];
      if (result && result.data && result.data.getAnswerQuestions) {
        dataAnswer = result.data.getAnswerQuestions;
        dataAnswer.forEach(answer => {
          answer.answerCompany = 0;
          answer.answerWorker = 0;
          answer.answerInfluencer = 0;
          if (!answer.parentCode) {
            answer.total = 0;
          }
        });
      }
      getDataFeedback(dataQuestion, dataAnswer);
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentReportPage.getDataAnswer');
    }
  };

  const getDataFeedback = async (dataQuestion, dataAnswer) => {
    try {
      const params = {
        selfAssessment: filter.selfAssessment.value,
        company: filter.company.value
      };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_TASK_SELF_ASSESSMENT_BY_SELF_ASSESSMENT, params);
      const dataNote = [];
      if (result && result.data && result.data.getTaskSelfAssessmentBySelfAssessments) {
        const company = window.COMMON.getObjectInArrs(window.CONSTANT.COMPANY, result.data.getTaskSelfAssessmentBySelfAssessments, 'type');
        const influencer = window.COMMON.getObjectInArrs(window.CONSTANT.INFLUENCER, result.data.getTaskSelfAssessmentBySelfAssessments, 'type');
        const obj = window.COMMON.getObjectInArrs(filter.group.value, influencer && influencer.items, 'group._id');
        if (influencer && obj) {
          dataNote.push({
            item: obj.feedback,
            updatedDate: window.COMMON_DATE.formatDate(influencer.updatedDate, 'DD MMM YYYY'),
            updatedBy: influencer.updatedBy
          });
        }

        let responseCount = 0;
        // count response of current groupQA & current task
        result.data.getTaskSelfAssessmentBySelfAssessments.forEach(t => {
          if (t.items?.length) {
            t.items.forEach(i => {
              if (i.group?._id === filter.group.value && i.feedback?.length) {
                responseCount += 1;
              }
            });
          }
        });

        setCount(prev => ({...prev, response: responseCount}));

        updateCountData(dataQuestion, dataAnswer, company, 'answerCompany');
        updateCountData(dataQuestion, dataAnswer, influencer, 'answerInfluencer');
      }
      getDataFeedbackShare(dataQuestion, dataAnswer, dataNote);
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentReportPage.getDataFeedback');
    }
  };

  const getDataFeedbackShare = async (dataQuestion, dataAnswer, dataNote) => {
    try {
      const params = {
        selfAssessment: filter.selfAssessment.value,
        group: filter.group.value,
        company: filter.company.value
      };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_TASK_SHARE_SELF_ASSESSMENT_BY_SELF_ASSESSMENT, params);
      let data = [];
      if (result && result.data && result.data.getTaskShareSelfAssessmentsBySelfAssessment) {
        data = result.data.getTaskShareSelfAssessmentsBySelfAssessment;
        data.forEach(element => {
          dataNote.push({
            item: element.feedback,
            updatedDate: window.COMMON_DATE.formatDate(element.updatedDate, 'DD MMM YYYY'),
            updatedBy: element.updatedBy
          });
        });
      }
      setDataNote(dataNote);
      setDataSelfAssessmentAssessor(data);
      getDataHistoryQA(dataQuestion, dataAnswer);
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentReportPage.getDataFeedbackShare');
    }
  };

  const getDataHistoryQA = async (dataQuestion, dataAnswer) => {
    try {
      window.showLoading();
      const params = {
        input: {
          group: window.COMMON.getDataSelect(filter.group, false),
          startDate: window.COMMON_DATE.getStrDateFilter(filter.startDate, -1),
          endDate: window.COMMON_DATE.getStrDateFilter(filter.endDate, 1)
        }
      };
      if (filter.company && filter.company.code === window.CONSTANT.COMPANY) {
        params.input.companyIds = window.COMMON.getDataSelect(filter.company, true);
      } else if (filter.company && filter.company.code === window.CONSTANT.UNION) {
        params.input.unionIds = window.COMMON.getDataSelect(filter.company, true);
      }
      params.input.page = 1;
      params.input.pageSize = 10;
      let data = [];
      let employeeResponse = 0;
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_HISTORY_ANSWER_REPORT, params, false);
      if (result && result.data && result.data.getHistoryAnswer) {
        const total = result.data.getHistoryAnswer.total;
        const page = total < 10 ? 1 : Math.ceil(total / 10);
        data = data.concat(result.data.getHistoryAnswer.data);
        for (let i = 1; i < page; i++) {
          await new Promise(resolve => setTimeout(resolve, 2000));
          params.input.page = i + 1;
          const resultItem = await window.COMMON.query(QuestionGRAPHQL.QUERY_HISTORY_ANSWER_REPORT, params, false);
          if (resultItem && resultItem.data && resultItem.data.getHistoryAnswer) {
            data = data.concat(resultItem.data.getHistoryAnswer.data);
          }
        }
      }
      if (data && data.length > 0) {
        const companies = [];
        const employees = [];
        data.filter(answer => answer?.company?._id === filter.company.value).forEach(item => {
          employeeResponse += 1;
          const company = window.COMMON.getValueFromAttr(item, 'company._id');
          const employeeId = window.COMMON.getValueFromAttr(item, 'createdBy._id');

          if (companies.indexOf(company) === -1) {
            companies.push(company);
          }

          if (employees.indexOf(employeeId) === -1) {
            employees.push(employeeId);
          }

          if (item.feedback && item.feedback.length > 0) {
            item.feedback.forEach(feedback => {
              const question = window.COMMON.getObjectInArrs(feedback.question._id, dataQuestion, '_id');
              if (question) {
                if (question.type === 'TEXT') {
                  if (question.answerWorker) {
                    question.answerWorker += ',';
                  }
                  question.answerWorker += feedback.answerText;
                } else {
                  if (feedback.answers && feedback.answers.length > 0) {
                    feedback.answers.forEach(element => {
                      const answer = window.COMMON.getObjectInArrs(element._id, dataAnswer, '_id');
                      if (answer) {
                        answer.answerWorker += 1;
                        if (question.type === 'RADIO_GRID' || question.type === 'CHECKBOX_GRID') {
                          if (answer.parentCode) {
                            const parent = window.COMMON.getObjectInArrs(answer.parentCode, dataAnswer, 'code');
                            parent.total += 1;
                          }
                        } else {
                          question.total += 1;
                        }
                      }
                    });
                  }
                }
              }
            });
          }
          
        });
      }

      object.index = 0;
      object.item = dataQuestion[0];
      for (let i = 0; i < dataQuestion.length; i++) {
        const question = dataQuestion[i];
        if (question.type !== 'TEXT') {
          const answers = window.COMMON.getArrsInArrs(question._id, dataAnswer, 'question');
          calcAverageAndBenmark(question, answers);
        }
      }

      setDataQuestion(dataQuestion);
      setDataSearch(dataQuestion);
      setDataAnswer(dataAnswer);
      setObject({ ...object });
      setCount(prev => ({...prev, employeeResponse}));
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentReportPage.getDataHistoryQA');
    }
    window.hideLoading();
  };

  const updateCountData = (dataQuestion, dataAnswer, data, attr) => {
    const obj = window.COMMON.getObjectInArrs(filter.group.value, data && data.items, 'group._id');
    if (obj && obj.feedback) {
      obj.feedback.forEach(item => {
        const question = window.COMMON.getObjectInArrs(item.question._id, dataQuestion, '_id');
        if (question) {
          if (question.type === 'TEXT') {
            question[attr] = item.answerText;
          } else {
            if (item.answers && item.answers.length > 0) {
              item.answers.forEach(element => {
                const answer = window.COMMON.getObjectInArrs(element._id, dataAnswer, '_id');
                if (answer) {
                  answer[attr] = 1;
                }
              });
            }
          }
        }
      });
    }
  };

  const getDataExport = async () => {
    window.showLoading();
    const result = [];
    const cols = [
      window.I18N('answer'),
      window.I18N('organization'),
      window.I18N('worker'),
      window.I18N('advisor')
    ];
    const averageCols = [window.I18N('deviation_average') + ' (%)', '', ''];
    const benmarkCols = [window.I18N('bench_mark_deviation_average') + ' (%)', '', ''];
    if (dataSelfAssessmentAssessor.length > 0) {
      dataSelfAssessmentAssessor.forEach(element => {
        cols.push(window.I18N('assessor') + ' - ' + window.COMMON.getValueFromAttr(element, 'influencer.name'));
        averageCols.push('');
        benmarkCols.push('');
      });
    }
    for (let i = 0; i < dataQuestion.length; i++) {
      const question = dataQuestion[i];
      result.push([(i + 1) + '. ' + window.COMMON.getValueWithLanguage(question, 'question', language.current)]);
      result.push(cols);
      if (question.type === 'TEXT') {
        const dataCols = [];
        dataCols.push('');
        dataCols.push(window.COMMON.setDataCell(question.answerCompany));
        dataCols.push(window.COMMON.setDataCell(question.answerWorker));
        dataCols.push(window.COMMON.setDataCell(question.answerInfluencer));
        dataSelfAssessmentAssessor.forEach(selfAssessor => {
          const obj = window.COMMON.getObjectInArrs(question._id, selfAssessor.feedback, 'question._id');
          dataCols.push(window.COMMON.setDataCell(obj?.answerText));
        });
        result.push(dataCols);
      } else if (question.type === 'RADIO_GRID' || question.type === 'CHECKBOX_GRID') {
        const answers = window.COMMON.getArrsInArrs(question._id, dataAnswer, 'question');
        const parent = answers.filter(function(item) { return item.parentCode === null; });
        parent.forEach(answer => {
          result.push([window.COMMON.getValueWithLanguage(answer, 'content', language.current)]);
          const children = window.COMMON.getArrsInArrs(answer.code, dataAnswer, 'parentCode');
          children.forEach(child => {
            const dataCols = [];
            dataCols.push(window.COMMON.getValueWithLanguage(child, 'content', language.current));
            dataCols.push(child.answerCompany || 0);
            dataCols.push(child.answerWorker || 0);
            dataCols.push(child.answerInfluencer || 0);
            dataSelfAssessmentAssessor.forEach(selfAssessor => {
              dataCols.push(countAnswerAssessor(selfAssessor, question._id, child._id));
            });
            result.push(dataCols);
          });
          const averageColsClone = JSON.parse(JSON.stringify(averageCols));
          averageColsClone.push(answer.average || 0);
          const benmarkColsClone = JSON.parse(JSON.stringify(benmarkCols));
          benmarkColsClone.push(answer.benmark || 0);
          result.push(averageColsClone);
          result.push(benmarkColsClone);
        });
      } else {
        const answers = window.COMMON.getArrsInArrs(question._id, dataAnswer, 'question');
        answers.forEach(answer => {
          const dataCols = [];
          dataCols.push(window.COMMON.getValueWithLanguage(answer, 'content', language.current));
          dataCols.push(answer.answerCompany || 0);
          dataCols.push(answer.answerWorker || 0);
          dataCols.push(answer.answerInfluencer || 0);
          dataSelfAssessmentAssessor.forEach(selfAssessor => {
            dataCols.push(countAnswerAssessor(selfAssessor, question._id, answer._id));
          });
          result.push(dataCols);
        });
        const averageColsClone = JSON.parse(JSON.stringify(averageCols));
        averageColsClone.push(question.average || 0);
        const benmarkColsClone = JSON.parse(JSON.stringify(benmarkCols));
        benmarkColsClone.push(question.benmark || 0);
        result.push(averageColsClone);
        result.push(benmarkColsClone);
      }
      result.push([]);
    }
    window.hideLoading();
    return result;
  };
  
  const countAnswerAssessor = (item, questionId, answerId) => {
    const obj = window.COMMON.getObjectInArrs(questionId, item.feedback, 'question._id');
    if (obj) {
      const check = window.COMMON.getObjectInArrs(answerId, obj.answers, '_id');
      if (check) {
        return 1;
      }
    }
    return 0;
  };

  const calcAverageAndBenmark = (question, dataAnswer) => {
    if (question.type === 'RADIO_GRID' || question.type === 'CHECKBOX_GRID') {
      const parent = dataAnswer.filter(function(item) { return item.parentCode === null; });
      if (parent && parent.length > 0) {
        parent.forEach(element => {
          let averageAll = 0;
          const answers = window.COMMON.getArrsInArrs(element.code, dataAnswer, 'parentCode');
          answers.forEach(answer => {
            const percentWorker = element && element.total > 0 ? parseFloat(answer.answerWorker / element.total * 100) : 0;
            const percentCompany = parseFloat(answer.answerCompany * 100);
            let value = Math.round((percentWorker - (percentWorker + percentCompany) / 2) * 100) / 100;
            value = value < 0 ? value * -1 : value;
            answer.average = value;
            averageAll += value;
          });
          element.average = answers.length > 0 ? (Math.round(averageAll / answers.length * 100) / 100) : 0;
          element.benmark = answers.length > 0 ? (Math.round(averageAll * 100) / 100) : 0;
        });
      }
    } else {
      let averageAll = 0;
      const totalCompany  = dataAnswer.reduce((acc,curr)=>acc + curr.answerCompany,0)
      dataAnswer.forEach(answer => {
        const percentWorker = question.total > 0 ? parseFloat(answer.answerWorker / question.total * 100) : 0;
        const percentCompany = parseFloat(answer.answerCompany/totalCompany * 100) || 0;
        let value = Math.round((percentWorker - (percentWorker + percentCompany) / 2) * 100) / 100;
        value = value < 0 ? value * -1 : value;
        answer.average = value;
        averageAll += value;
      });
      question.average = dataAnswer.length > 0 ? (Math.round(averageAll / dataAnswer.length * 100) / 100) : 0;
      question.benmark = dataAnswer.length > 0 ? (Math.round(averageAll * 100) / 100) : 0;
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <div className="card mb-3 mt-3 wow fadeInDown animated text-left" data-wow-delay="0.5s" style={{ zIndex: 1000 }}>
        <div className="card-body pt-2">
          <MDBRow className="mt-2">
            {
              window.COMMON.checkRoleIsSystem() ? <>
                <MDBCol sm="4" className="mb-2">
                  <strong>{window.I18N('influencer_organization')}</strong>
                  <div className="input-group input-group-transparent mt-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <MDBIcon fa="true" icon="building" className="fa-fw" />
                      </span>
                    </div>
                    <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.influencer} options={options.influencer} onChange={(event) => handleFilterSelect(event, 'influencer')} isSearchable />
                  </div>
                </MDBCol>
                <MDBCol sm="8" className="mb-2">
                  <strong>{window.I18N('self')}</strong>
                  <div className="input-group input-group-transparent mt-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <MDBIcon fa="true" icon="tasks" className="fa-fw" />
                      </span>
                    </div>
                    <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.selfAssessment} options={options.selfAssessment} onChange={(event) => handleFilterSelect(event, 'selfAssessment')} isSearchable />
                  </div>
                </MDBCol>
              </> : <MDBCol sm="12" className="mb-2">
                <strong>{window.I18N('self')}</strong>
                <div className="input-group input-group-transparent mt-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="tasks" className="fa-fw" />
                    </span>
                  </div>
                  <Select 
                    className="md-form m-0" 
                    placeholder={window.I18N('choose_option')} 
                    value={filter.selfAssessment} 
                    options={options.selfAssessment} 
                    onChange={(event) => handleFilterSelect(event, 'selfAssessment')} 
                    isSearchable 
                  />
                </div>
              </MDBCol>
            }
            <MDBCol sm="4" className="mb-2">
              <strong>{window.I18N('group_qa')}</strong>
              <div className="input-group input-group-transparent mt-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="question-circle" className="fa-fw" />
                  </span>
                </div>
                <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.group} options={options.group} onChange={(event) => handleFilterSelect(event, 'group')} isSearchable />
              </div>
            </MDBCol>
            <MDBCol sm="4" className="mb-2">
              <strong>{window.I18N('organization')}</strong>
              <div className="input-group input-group-transparent mt-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="building" className="fa-fw" />
                  </span>
                </div>
                <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.company} options={options.company} onChange={(event) => handleFilterSelect(event, 'company')} isSearchable />
              </div>
            </MDBCol>
            <MDBCol sm="2" className="mb-2">
              <strong>{window.I18N('from_date')}</strong>
              <div className="input-group input-group-transparent mt-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                  </span>
                </div>
                <DatePicker className="form-control" dateFormat="dd/MM/yyyy" selected={filter.startDate} onChange={(event) => handleDatePicker(event, 'startDate')} showYearDropdown showMonthDropdown/>
              </div>
            </MDBCol>
            <MDBCol sm="2" className="mb-2">
              <strong>{window.I18N('to_date')}</strong>
              <div className="input-group input-group-transparent mt-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                  </span>
                </div>
                <DatePicker className="form-control" dateFormat="dd/MM/yyyy" selected={filter.endDate} onChange={(event) => handleDatePicker(event, 'endDate')} showYearDropdown showMonthDropdown/>
              </div>            
            </MDBCol>
            <MDBCol sm="12">
              <div className="d-flex flex-row align-items-center justify-content-end">
                <MDBBtn color="" onClick={doSearchData}>
                  <MDBIcon fa="true" icon="search" className="fa-fw" /> {window.I18N('btn_search')}
                </MDBBtn>
                <MDBBtn color="warning" onClick={() => window.COMMON.exportPDF('SELF_REPORT_DATA')}>
                  <MDBIcon fa="true" icon="file-pdf" className="fa-fw"/> {window.I18N('export_pdf')}
                </MDBBtn>
                <ExportExcel label={window.I18N('export')} className="mr-0" isTemplate={false} dataKey="SELF_REPORT_DATA" handleData={getDataExport}></ExportExcel>
              </div>
            </MDBCol>
          </MDBRow>
        </div>
      </div>
      
      <MDBCard className="mb-2 wow fadeInDown animated" data-wow-delay="0.5s">
        <MDBRow>
          <MDBCol md="3" size="6">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage cascade waves src={window.CONSTANT.ICON_ORGANIZATION} className="icon mr-1"/>
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">{count.company}</h5>
                <p className="text-muted mb-0">{window.I18N('total_companies')}</p>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="3" size="6">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage cascade waves src={window.CONSTANT.ICON_EMPLOYEE} className="icon mr-1"/>
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">{count.employee}</h5>
                <p className="text-muted mb-0">{window.I18N('total_employees')}</p>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="3" size="6">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage cascade waves src={window.CONSTANT.ICON_RESPONSE} className="icon mr-1"/>
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">{count.response + count.employeeResponse}</h5>
                <p className="text-muted mb-0">{window.I18N('total_responses')}</p>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="3" size="6">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage cascade waves src={window.CONSTANT.ICON_QA} className="icon mr-1"/>
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">{count.question}</h5>
                <p className="text-muted mb-0">{window.I18N('total_questions')}</p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBCard>

      <MDBRow>
        <MDBCol md="5" lg="4" className="pr-md-1">
          <MDBCard className="wow fadeInUp animated text-left" data-wow-delay="0.5s">
            <MDBCardHeader className="pb-0">{window.I18N('question_list')}</MDBCardHeader>
            <MDBCardBody>
              <MDBInputGroup type="text" value={filter.text} hint={window.I18N('search_question')} onChange={handleChange} maxLength="100" pattern="\S(.*\S)?" required
                append={
                  <span className="input-group-text cursor-pointer">
                    <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                  </span>
                }
              />
              <div className="container-list-question mt-3">
                {
                  dataSearch.map((item, i) => 
                    <div className={'item d-flex align-items-center' + (item.index === object.index ? ' active' : '')} key={i} onClick={() => changeQuestion(item.index)}>
                      <MDBIcon fa="true" icon="circle" className="fa-fw mr-2" style={{ color: '#10C986' }}/>
                      <span>{(item.index + 1) + '. ' + window.COMMON.getValueWithLanguage(item, 'question', language.current)}</span>
                    </div>
                  )
                }
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="7" lg="8" className="pl-md-1">
          <ItemQuestionData item={object.item} dataNote={dataNote} dataAssessor={dataSelfAssessmentAssessor} total={count.response} answers={object.item && window.COMMON.getArrsInArrs(object.item._id, dataAnswer, 'question')} language={language.current}></ItemQuestionData>
        </MDBCol>
      </MDBRow>

      <Modal id="modal-pdf" title={window.I18N('export_pdf')} className="modal-lg" icon='download' saveBtn={window.I18N('download_pdf')} saveEvent={() => window.COMMON.exportPDF('SELF_REPORT_DATA')}>
        <MDBRow>
          <MDBCol className="wow fadeIn animated" data-wow-delay="0.5s">
            <div className="d-flex align-items-center justify-content-center w-100">
              <div id="content-pdf" className="container-pdf" style={{ position: 'relative', width: '445px' }}>
                <SelfAssessmentPDF dataKey="SELF_REPORT_DATA" dataExport={{questions: dataQuestion, answers: dataAnswer, selfAssessor: dataSelfAssessmentAssessor}} langague={language.current}></SelfAssessmentPDF>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SelfAssessmentReportPage);