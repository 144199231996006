import React, { useState, useLayoutEffect, useCallback,useRef, useEffect } from 'react';
import EventGRAPHQL from '../../graphql/Event';
import { Cancel, ExitToApp, EventAvailable } from '@material-ui/icons';
import { Button, Grid, Typography, Modal as MuiModal,Box } from '@material-ui/core';
import Modal  from './Modal';
import EventFanPageCard from './EventFanPageCard';
import { useTracking } from 'react-tracking';
import eventName from '../../common/events';
import { makeStyles } from '@material-ui/core/styles';
import EventForm from '../views/event/components/EventForm';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingSpinner from './LoadingSpinner';


const useStyles = makeStyles({
  modalBox: {
    width: "80%",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: "white",
    display: "flex",
    flexDirection:"column",
    alignItems: "center",
    borderRadius: "5px",
    padding: "20px 20px 30px 20px",
    "@media (min-width: 800px)": {
        width: "500px"
    }
  },
});
const PAGE_SIZE= 10
const SORT_TYPE = "eventDate"
const FanpageEvent = ({ fanpageId, role }) => {
  const classes = useStyles();
  const { trackEvent } = useTracking();
  const [events, setEvents] = useState([])
  const [formatEvents, setFormatEvents] = useState({upcoming:[], expired:[]})
  const [modal, setModal] = useState({eventId: null, mode: null, open: false})
  const createEventFormRef = useRef()
  const [dataRelationship, setDataRelationship] = useState([])
  const [isStillMore, setIsStillMore] = useState(false)

  const getDataEventFanpage = async ()=>{
    try {
      const result = await window.COMMON.query(EventGRAPHQL.QUERY_FANPAGE_EVENTS,  { fanpage: fanpageId, pageSize : PAGE_SIZE, sortType: SORT_TYPE})
      if (result?.data?.getFanpageEvents?.data.length>0){
        if (result?.data?.getFanpageEvents?.data.length === result?.data?.getFanpageEvents.total){
          setIsStillMore(false)
        } else {
          setIsStillMore(true)
        }
        const events = window.COMMON.formatDate(result.data.getFanpageEvents.data,'eventDate', 'DD MMM YYYY - HH:mm')
        setEvents(events)
      }
    } catch (err){
      console.log(err)
    }
  }

  const fetchMoreData = async () => {
    try {
        const params = {
            fanpage: fanpageId,
            pageSize: PAGE_SIZE,
            sortType: SORT_TYPE,
            page: parseInt(events.length/PAGE_SIZE) +1
        }
    const result = await window.COMMON.query(EventGRAPHQL.QUERY_FANPAGE_EVENTS, params)
        if (result?.data?.getFanpageEvents?.data.length >0) {
            if ((result.data.getFanpageEvents.data.length + events.length) >= result.data.getFanpageEvents.total) {
                setIsStillMore(false)
            } else {
                setIsStillMore(true)
            }
            const moreEvents = window.COMMON.formatDate(result.data.getFanpageEvents.data,'eventDate', 'DD MMM YYYY - HH:MM')
            setTimeout(() => {
                setEvents([...events, ...moreEvents])
            })
        }
    } catch (error) {
        window.COMMON.showErrorLogs('FanpageEvent.fetchMoreData');
    }
}

  const getDataEventRelationship = async ()=>{
    try {
      const result = await window.COMMON.query(EventGRAPHQL.FIND_FANPAGE_EVENTS_RELATIONSHIP_BY_USER,  { fanpage: fanpageId, pageSize : 100})
      if (result?.data?.findFanpageEventRelationshipByUser?.data.length>0){
        setDataRelationship(result?.data.findFanpageEventRelationshipByUser.data)
      }
    } catch (err){
      console.log(err)
    }
  }

  const handleJoinEvent = async(eventId) => {
    try {
      const result = await window.COMMON.mutation(EventGRAPHQL.MUTATION_REQUEST_JOIN_FANPAGE_EVENT, {id: eventId})
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          createTrackingEvent(eventName.JOIN_EVENT);
          getDataEventRelationship();
      }
    } catch (error) {
        window.COMMON.showErrorLogs('FanpageContent.handleJoinEvent');
    }
    setModal({mode: null, eventId: null, open: false})
  }

  const handleLeaveEvent = async(eventId) =>{
    try {
      const result = await window.COMMON.mutation(EventGRAPHQL.MUTATION_LEAVE_FANPAGE_EVENT, {id: eventId})
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
          createTrackingEvent(eventName.LEAVE_EVENT);
          getDataEventRelationship();
      }
    } catch (error) {
        window.COMMON.showErrorLogs('FanpageContent.handleLeaveFanpage');
    }
    setModal({mode: null, eventId: null, open: false})
  }

  const createEventFanpage = () => {
    createEventFormRef.current.submitForm('create', () => {
        closeCreateEventPopup();
        getDataEventFanpage();
        getDataEventRelationship();
    })
  }

  const handleCreateEvent = () => {
    const isValidForm = createEventFormRef.current.validateForm()
    if (isValidForm) {
        window.COMMON.showModal('#modal-confirm')
        window.confirmMethod = createEventFanpage
    }
  }

  const onClickSendRequest = ()=>{
    if (modal.mode === 'JOIN'){
      handleJoinEvent(modal.eventId)
    } else if(modal.mode === 'LEAVE'){
      handleLeaveEvent(modal.eventId)
    }
  }

  const openRelationshipModal = useCallback((eventId, mode) => {
    modal.eventId= eventId
    modal.mode = mode
    modal.open= true
    setModal({ ...modal });
  }, []);

  const onCloseModal = () => {
    setModal({open:false, mode: null, eventId: null})
  }
  
  const createTrackingEvent = (event) => {
    return trackEvent({
        name: event,
        createdAt: new Date().toISOString()
    });
  };
  
  useEffect(()=>{
    if (events.length ){
        const upcomingEvents= []
        const expiredEvents= []
        events.forEach(event=>{
          //check is joined event
          const index = dataRelationship.findIndex(relationship => relationship.fanpageEvent._id === event._id)
          if (index !== -1){
            event.isJoined = true
            event.eventRole= dataRelationship[index].role
          } else {
            event.isJoined = false
          }
          //check is expired event
          if (event.status === 1  || (new Date(event.eventDate) < new Date()) ){
            expiredEvents.push(event)
          } else {
            upcomingEvents.push(event)
          }
        })
        setFormatEvents({upcoming: upcomingEvents, expired: expiredEvents })
    }
  },[events, dataRelationship])


  useLayoutEffect(() => {
		setTimeout(() => {
			if (fanpageId) {
				getDataEventFanpage()
        getDataEventRelationship()
			}
		}, 100);
	}, [fanpageId]);

  const openCreateEventPopup = () => {
    window.COMMON.showModal('#modal-create-event')
  }

  const closeCreateEventPopup = () => {
    createEventFormRef.current.resetForm()
    window.COMMON.hideModal('#modal-create-event')
  }

  return (<>
  <InfiniteScroll
    dataLength={events.length}
    next={fetchMoreData}
    hasMore={isStillMore}
    loader={<div className='text-center py-2 '><LoadingSpinner /></div>}
    endMessage={
        <p className='text-center mt-2 mb-0 grey-text'>
            <span>{window.I18N('no_more_event')}</span>
        </p>
    }
  >
    <div className='p-4 bg-white rounded mb-4'>
      <Grid className='d-flex p-2 justify-content-between' container>
        <Typography variant="h6" gutterBottom>
          {window.I18N('upcoming_events')}
        </Typography>
        {role==="OWNER"&&<Button onClick={openCreateEventPopup} className='text-capitalize' variant="contained" color="primary" style={{ marginBottom: '16px' }}>
          {window.I18N('create_event')}
        </Button>}
      </Grid>
      <Grid container direction="column" spacing={2}>
        {formatEvents.upcoming.map((event, index) => (
          <Grid item key={index}>
            <EventFanPageCard isExpired={false} event={event} openModalRelationShip={openRelationshipModal}/>
          </Grid>
        ))}
      </Grid>
    </div>
    {formatEvents.expired?.length>0&&<div className='p-4 bg-white rounded'>
      <Grid className='d-flex p-2 justify-content-between' container>
        <Typography variant="h6" gutterBottom>
        {window.I18N('expired_events')}
        </Typography>
      </Grid>
      <Grid container direction="column" spacing={2}>
        {formatEvents.expired.map((event, index) => (
          <Grid item key={index}>
            <EventFanPageCard isExpired={true} event={event} openModalRelationShip={openRelationshipModal}/>
          </Grid>
        ))}
      </Grid>
    </div>}
    </InfiniteScroll>
      <MuiModal
        open={modal?.open}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modalBox}>
          {modal.mode === "JOIN" && <>
              <Grid component="h3" className=' my-2 justify-content-center'>{window.I18N("join_event")}</Grid>
              <Grid component="h6" className='py-4 font-weight-bolder' item>{window.I18N("join_event_name")+ ': '+ formatEvents.upcoming.find(el=>el._id === modal.eventId).name} </Grid>
              {formatEvents.upcoming.find(el=>el._id === modal.eventId).gopy>0&&<Grid component="h6" className='pb-4 my-auto font-weight-bolder' item>
                  {window.I18N("join_event_gopy")+': '+ formatEvents.upcoming.find(el=>el._id === modal.eventId).gopy} 
              </Grid>}
            </>
          }
          {modal.mode === "LEAVE" && <>
              <Grid component="h3" className=' my-2 justify-content-center'>{window.I18N("leave_event")}</Grid>
              <Grid component="h6" className='py-4 font-weight-bolder' item>{window.I18N("leave_event_name")+ ': '+ formatEvents.upcoming.find(el=>el._id === modal.eventId).name} </Grid>
            </>
          }
          <Grid container className="mx-8 mt-2 w-full justify-content-end">
            <Button
              className='text-capitalize'
              variant="contained"
              color="primary"
              onClick={onCloseModal}
            >
              <Cancel /> &nbsp; {window.I18N("btn_cancel")}
            </Button>
            <Button
              className='ml-4 text-capitalize'
              variant="contained"
              color={modal.mode === "LEAVE"?"secondary":"primary"} 
              onClick={onClickSendRequest}
            >
              {modal.mode === "JOIN" && <><EventAvailable /> &nbsp; {window.I18N("btn_confirm")}</>}
              {modal.mode === "LEAVE" && <><ExitToApp /> &nbsp; {window.I18N("btn_confirm")}</>}
            </Button>
          </Grid>
        </Box>
      </MuiModal>
      {/* Create event form */}
      {role==="OWNER"&& <Modal
        id="modal-create-event"
        title={window.I18N('Tạo sự kiện')}
        icon="plus"
        saveBtn={window.I18N('Tạo sự kiện')}
        saveBtnProps={{
          style: {
            width: '100%'
          },
          outline: false
        }}
        saveEvent={handleCreateEvent}
        styleBody={{
          padding: '0px',
          overflow: 'overlay',
          overscollBehavior: 'contain'
        }}
        handleCloseModal={closeCreateEventPopup}
      >
        <EventForm canChangeFanpage ref={createEventFormRef} fanpageId={fanpageId} />
      </Modal>}
    
    </>)
}

export default FanpageEvent