import styled from 'styled-components';
import { veryLightMainColor } from '../styled-contants';
export const UserSearchContainerStyled = styled.div`
    margin-top: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
    .title{
        font-weight: bold;
        font-size: 16px;
    }
    .list{
        min-height: 50px;
        max-height: 150px;
        overflow-y: scroll;

        .item{
            width: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
            column-gap: 10px;
            padding: 5px;
            .content{
                flex: 1;
            }
        }
        .item:hover{
            background-color: ${veryLightMainColor};
        }
    }

`;