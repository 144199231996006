import React, { useState, useCallback, useLayoutEffect } from 'react';
import { MDBRow, MDBCol, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';
import Panel from '../../components/Panel';
import Table from '../../components/Table';
import Modal from '../../components/Modal';

import MetadataGRAPHQL from '../../../graphql/Metadata';
import eventName from '../../../common/events';
import { useTracking } from 'react-tracking';

export default function ConfigPage(props) {
  const { trackEvent } = useTracking();

  const config = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('name'), '20%', '', '', 'name', 'TEXT'),
    window.COMMON.createCol(window.I18N('value'), '20%', '', '', 'value', 'TEXT'),
    window.COMMON.createCol(window.I18N('code'), '15%', '', '', 'code', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_by'), '20%', '', '', 'createdBy.name', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '10%', '', '', '3', 'BUTTON')
  ];
  const [object, setObject] = useState({ index: -1 });
  const [data, setData] = useState([]);
  const [dataSave, setDataSave] = useState({});

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataConfig();
    }, 100);
    // eslint-disable-next-line
  }, []);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleChange = (event) => {
    dataSave[event.target.name] = event.target.value;
    setDataSave({ ...dataSave });
  };

  const getDataConfig = async () => {
    try {
      window.resetDataTable('table-data');
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_CONFIG, {});
      if (result && result.data) {
        const data = window.COMMON.formatDate(result.data.getConfigs, 'createdDate');
        setData(data);
        localStorage.setItem(window.CONSTANT.DATA_CONFIG, JSON.stringify(data));
      }
    } catch (error) {
      window.COMMON.showErrorLogs('ConfigPage.getDataConfig');
    }
    window.initDataTable('table-data');
  };

  const openSaveDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-config');
    object.index = index;
    const dataSave = {};
    if (index === -1) {
      dataSave.name = '';
      dataSave.value = '';
      dataSave.code = window.COMMON.generateCode('M');
    } else {
      const obj = data[index];
      dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
      dataSave.name = window.COMMON.getValueFromAttr(obj, 'name');
      dataSave.value = window.COMMON.getValueFromAttr(obj, 'value');
      dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
    }
    setObject(object);
    setDataSave({ ...dataSave });
  }, [object, data]);

  // eslint-disable-next-line
  const openDeleteDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteData;
  });

  const saveData = async (event) => {
    try {
      if (!window.COMMON.checkFormValidation('form-config', event)) {
        return;
      }
      const params = {
        input: dataSave
      };
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(MetadataGRAPHQL.MUTATION_ADD_CONFIG, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataConfig();
		      createTrackingEvent(eventName.ADD_CONFIG);
        }
      } else {
        const result = await window.COMMON.mutation(MetadataGRAPHQL.MUTATION_EDIT_CONFIG, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataConfig();
		      createTrackingEvent(eventName.EDIT_CONFIG);
        }
      }
      window.COMMON.hideModal('#modal-config');
    } catch (error) {
      window.COMMON.showErrorLogs('ConfigPage.saveData');
    }
  };

  const deleteData = async () => {
    try {
      const params = {
        id: data[object.index]._id
      };
      const result = await window.COMMON.mutation(MetadataGRAPHQL.MUTATION_DEL_CONFIG, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataConfig();
        createTrackingEvent(eventName.DEL_CONFIG);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('ConfigPage.deleteData');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" icon="list-alt">
        <div className="container-top d-flex align-items-center justify-content-end wow fadeInDown animated" data-wow-delay="0.5s">
          <MDBBtn color="primary" className="mr-0" onClick={() => openSaveDialog(-1)}>
            <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
          </MDBBtn>
        </div>
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <Table id="table-data" className="table" config={config} data={data} handleRowEditClick={openSaveDialog} handleRowDelClick={openDeleteDialog}></Table>
        </div>
      </Panel>

      <Modal id="modal-config" title={window.I18N('create_update_data')} saveEvent={saveData}>
        <form id="form-config" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <MDBInput outline containerClass="mt-0" type="text" label={window.I18N('name') + ' *'} name="name" value={dataSave.name} onChange={handleChange} maxLength="100" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline containerClass="mt-0" type="text" label={window.I18N('value') + ' *'} name="value" value={dataSave.value} onChange={handleChange} maxLength="100" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="text" label={window.I18N('code') + ' *'} name="code" value={dataSave.code} onChange={handleChange} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>
    </div>
  );
}