import React from 'react';
import BodyChatRightSide from './BodyChatRightSide/BodyChatRightSide';
import BottomChatRightSide from './BottomChatRightSide';
import HeaderChatRightSide from './HeaderChatRightSide';
import {CustomChatRightSide} from '../../../../styles/styled-components/Chat/ChatPage';
import EmptyDataText from '../../../components/EmptyDataText';

const ChatRightSide = ({
  groupChatSelected, 
  onClose, 
  onSendMessage, 
  messages, 
  reply,
  onSetMessages, 
  groupChatMenuItems, 
  groupChatMenuFunctions, 
  onHeaderBadgeClick, 
  onFormChange,
  onReply,
  friends,
  dataStickers,
  allStickers
}) => {
  const handleSendMessage = (message, type, moreInfor = {}) => {
    onSendMessage(message, groupChatSelected, type, moreInfor);
  };
  return <CustomChatRightSide>
    <HeaderChatRightSide 
      groupChatSelected={groupChatSelected} 
      onClose={onClose} 
      groupChatMenuItems={groupChatMenuItems}
      groupChatMenuFunctions={groupChatMenuFunctions}
      onHeaderBadgeClick={onHeaderBadgeClick}
    />
    <BodyChatRightSide messages={messages} groupChatSelected={groupChatSelected} onSetMessages={onSetMessages} onReply={onReply} allStickers={allStickers} />
    <BottomChatRightSide 
      onSendMessage={handleSendMessage} 
      groupChatSelected={groupChatSelected}
      onFormChange={onFormChange}
      messages={messages}
      reply={reply}
      onReply={onReply}
      dataStickers={dataStickers}
    />
  </CustomChatRightSide>;
};  

export default ChatRightSide;