import React, { useState } from 'react';
import { MDBRow, MDBCol, MDBInput } from 'mdbreact';

import Modal from './Modal';
import ItemPost from './ItemPost';

import PostGRAPHQL from '../../graphql/Post';
import { useTracking } from 'react-tracking';
import eventName from '../../common/events';

const ItemShare = React.memo(function ItemShare (props) {
  const { trackEvent } = useTracking();
  const [text, setText] = useState('');

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const sharePost = async (event) => {
    try {
      const params = {
        input: props.dataShare
      };
      params.input.text = text;
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(PostGRAPHQL.MUTATION_SHARE_POST, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
        createTrackingEvent(eventName.SHARE_POST);
      }
      window.COMMON.hideModal('#modal-share');
    } catch (error) {
      window.COMMON.showErrorLogs('ItemShare.sharePost');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <Modal id="modal-share" className="modal-lg" title={window.I18N('share_post')} saveBtn={window.I18N('share')} saveEvent={(event) => sharePost(event)}>
      <MDBRow>
        <MDBCol>
          <MDBInput outline id="input-share-text" type="textarea" containerClass="m-0" hint={window.I18N('share_what_you_thinging')} value={text} onChange={(event) => handleChange(event)} maxLength="5000" pattern="\S(.*\S)?" required>
            <div className="invalid-feedback">
              {window.I18N('MSG_CODE_029')}
            </div>
          </MDBInput>
          {
            props.itemShare ? <ItemPost item={props.itemShare} index={0} isShare={true} className="mt-3 mb-0" attr_id="_id" attr_content="text" attr_image="image" attr_avatar="createdBy.avatar" attr_name="createdBy.nickname" attr_time="createdDate"></ItemPost> : <div></div>
          }
        </MDBCol>
      </MDBRow>
    </Modal>
  );
});

export default ItemShare;