
import React, { useState, useCallback, useLayoutEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { MDBRow, MDBCol, MDBCard, MDBCardImage, MDBBtn, MDBIcon, MDBInput, MDBInputGroup } from 'mdbreact';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { IconButton } from '@material-ui/core';
import { ThumbUp, ThumbDown, Comment, Image, Audiotrack, VideoCall } from '@material-ui/icons';

import Pagination from '../../components/Pagination';
import Modal from '../../components/Modal';
import RadioBox from '../../components/RadioBox';
import ItemPost from '../../components/ItemPost';

import MetadataGRAPHQL from '../../../graphql/Metadata';
import PostGRAPHQL from '../../../graphql/Post';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';

function PostContentScreeningPage(props) {
  const { trackEvent } = useTracking();
  const language = useRef();

  const statusReviewFilterOptions = [
    { value: null, label: window.I18N('all') },
    { value: 'REVIEW', label: window.I18N('reviewed') },
    { value: 'NOT_REVIEW', label: window.I18N('not_review') },
    { value: 'HIDE', label: window.I18N('block') }
  ];

  const types = [
    { value: false, label: window.I18N('personal'), icon: 'user' },
    { value: true, label: window.I18N('fanpage'), icon: 'users' }
  ];

  const [filter, setFilter] = useState({ text: '', isFanpage: false, startDate: window.COMMON_DATE.addDaysFromNow(-30), endDate: new Date(), statusReview: { value: null, label: window.I18N('all') } });
  const [count, setCount] = useState({ total: 0, reviewed: 0, hidden: 0, remain: 0 });
  const [object, setObject] = useState({ item: null, index: 0, mode: -1 });
  const [pagination, setPagination] = useState({ total: 0, pageSize: 10, currentPage: 0 });
  const [data, setData] = useState([]);
  const [dataHistory, setDataHistory] = useState([]);
  const [dataSave, setDataSave] = useState({});
  const [select, setSelect] = useState({});
  const [options, setOptions] = useState({
    reason: []
  });

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {
      getDataReason();
      countDataPostContentScreening();
      getDataPostContentScreening();
    }, 100);
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line
  }, [props.language]);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      document.getElementById('btn-search').click();
    }
  };

  const handleFilterChange = (event) => {
    filter.text = event.target.value;
    setFilter({ ...filter });
  };

  const handleFilterSelect = (event) => {
    filter.statusReview = event;
    setFilter({ ...filter });
  };

  const handleDatePicker = (event, attr) => {
    filter[attr] = event;
    setFilter({ ...filter });
  };

  const handleRadio = useCallback((value) => {
    filter.isFanpage = value;
    pagination.currentPage = 0;
    setPagination(pagination);
    setFilter({ ...filter });
    doSearchData();
    // eslint-disable-next-line
  }, [pagination, filter]);

  const handleChange = (event, attr) => {
    dataSave[attr] = event.target.value;
    setDataSave({...dataSave});
  };

  const handleSelect = (event, id, attr) => {
    window.COMMON.checkSelectValidation(id, event);
    select[attr] = event;
    setSelect({ ...select });
  };

  const getDataReason = async () => {
    try {
      const params = {
        type: 'REPORT_POST'
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_REASON, params);
      if (result && result.data && result.data.getReasons) {
        options.reason = window.COMMON.createDataSelectWithLanguage(result.data.getReasons, '_id', 'content', language.current, false);
        setOptions(options);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('PostContentScreeningPage.getDataReason');
    }
  };

  const countDataPostContentScreening = async () => {
    try {
      const params = {
        input: {
          isFanpage: filter.isFanpage,
          startDate: window.COMMON_DATE.getStrDateFilter(filter.startDate, -1),
          endDate: window.COMMON_DATE.getStrDateFilter(filter.endDate, 1)
        }
      };
      const result = await window.COMMON.query(PostGRAPHQL.QUERY_COUNT_POST_REVIEW, params);
      if (result && result.data && result.data.countPostReview) {
        const data = result.data.countPostReview;
        count.total = window.COMMON.getValueFromAttr(data, 'countPost', 0);
        count.reviewed = window.COMMON.getValueFromAttr(data, 'countReview', 0);
        count.hidden = window.COMMON.getValueFromAttr(data, 'countHide', 0);
        count.remain = count.total - count.reviewed - count.hidden;
      }
      setCount(count);
    } catch (error) {
      window.COMMON.showErrorLogs('PostContentScreeningPage.getDataPostContentScreening');
    }
  };

  const getDataPostContentScreening = async () => {
    try {
      const params = {
        input: {
          isFanpage: filter.isFanpage,
          statusReview: window.COMMON.getDataSelect(filter.statusReview, false),
          text: filter.text,
          startDate: window.COMMON_DATE.getStrDateFilter(filter.startDate, -1),
          endDate: window.COMMON_DATE.getStrDateFilter(filter.endDate, 1),
          pageSize: pagination.pageSize,
          page: pagination.currentPage + 1
        }
      };
      let data = [];
      const result = await window.COMMON.query(PostGRAPHQL.QUERY_REVIEW_POST, params);
      if (result && result.data && result.data.getReviewPosts) {
        data = window.COMMON.formatMultiDate(result.data.getReviewPosts.data, 'createdDate,reviewDate', 'DD MMM YYYY - HH:mm');
        pagination.total = result.data.getReviewPosts.total;
      }
      setPagination(pagination);
      setData(data);
    } catch (error) {
      window.COMMON.showErrorLogs('PostContentScreeningPage.getDataPostContentScreening');
    }
  };

  const getDataPostContentScreeningDetail = async () => {
    try {
      const params = {
        postId: data[object.index]._id
      };
      let dataHistory = [];
      const result = await window.COMMON.query(PostGRAPHQL.QUERY_REVIEW_POST_DETAIL, params);
      if (result && result.data && result.data.getReviewPostsDetail) {
        dataHistory = window.COMMON.formatDate(result.data.getReviewPostsDetail, 'createdDate', 'DD MMM YYYY - HH:mm');
      }
      setDataHistory(dataHistory);
    } catch (error) {
      window.COMMON.showErrorLogs('PostContentScreeningPage.getDataPostContentScreeningDetail');
    }
  };

  const doSearchData = (currentPage) => {
    pagination.currentPage = currentPage;
    countDataPostContentScreening();
    getDataPostContentScreening();
  };

  const changePage = useCallback((index) => {
    pagination.currentPage = index;
    setPagination({ ...pagination });
    getDataPostContentScreening();
    // eslint-disable-next-line
  }, [pagination, filter]);

  const handleReviewClick = (event, index) => {
    object.index = index;
    object.item = data[index];
    setObject({ ...object });
    if (object.item.statusReview !== 'HIDE') {
      reviewPost(event, 'REVIEW');
    }
    window.COMMON.showModal('#modal-post-item');
  };

  const handleHistoryClick = (event, index) => {
    object.index = index;
    object.item = data[index];
    setObject({ ...object });
    getDataPostContentScreeningDetail();
    window.COMMON.showModal('#modal-post-history');
  };

  const handleHideClick = (event, index, mode) => {
    object.index = index;
    object.item = data[index];
    object.mode = mode;
    select.reason = null;
    dataSave.description = '';
    setObject(object);
    setSelect(select);
    setDataSave({ ...dataSave });
    window.COMMON.showModal('#modal-hide-post');
  };

  const historyClick = () => {
    object.item = null;
    setObject({ ...object });
    window.COMMON.hideModal('#modal-post-item');
    getDataPostContentScreeningDetail();
    window.COMMON.showModal('#modal-post-history');
  };

  const hideClick = () => {
    window.COMMON.hideModal('#modal-post-item');
    object.mode = object.item.statusReview === 'HIDE' ? 1 : -1;
    object.item = null;
    select.reason = null;
    dataSave.description = '';
    setObject(object);
    setSelect(select);
    setDataSave({ ...dataSave });
    window.COMMON.showModal('#modal-hide-post');
  };

  const reviewPost = async (event, status) => {
    try {
      if (status === 'HIDE') {
        const checkForm = window.COMMON.checkFormValidation('form-hide-post', event);
        const checkReason = window.COMMON.checkSelectValidation('select-reason', select.reason);
        if (!checkForm || !checkReason) {
          return false;
        }
      }
      const params = {
        postId: data[object.index]._id,
        statusReview: status,
        reason: status === 'HIDE' ? window.COMMON.getDataSelect(select.reason, false) : null,
        description: status === 'HIDE' || status === 'UNHIDE' ? dataSave.description : ''
      };
      const result = await window.COMMON.mutation(PostGRAPHQL.MUTATION_REVIEW_POST, params);
      if (data[object.index].statusReview === status) {
        createTrackingEvent(eventName.REVIEW_POST);
        return;
      }
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
        createTrackingEvent(status === 'REVIEW' ? eventName.REVIEW_POST : (status === 'HIDE' ? eventName.HIDE_POST : eventName.UNHIDE_POST));
        doSearchData(pagination.currentPage);
      }
      window.COMMON.hideModal('#modal-hide-post');
    } catch (error) {
      window.COMMON.showErrorLogs('FanpagePage.updateStatus');
    }
  }; 

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <MDBCard className="mt-4 mb-2 wow fadeInDown animated" data-wow-delay="0.5s">
        <MDBRow>
          <MDBCol md="3" size="6">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage cascade waves src={window.CONSTANT.ICON_POST} className="icon mr-1"/>
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">{count.total}</h5>
                <p className="text-muted mb-0">{window.I18N('total')}</p>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="3" size="6">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage cascade waves src={window.CONSTANT.ICON_POST} className="icon mr-1"/>
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">{count.reviewed}</h5>
                <p className="text-muted mb-0">{window.I18N('reviewed')}</p>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="3" size="6">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage cascade waves src={window.CONSTANT.ICON_POST} className="icon mr-1"/>
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">{count.hidden}</h5>
                <p className="text-muted mb-0">{window.I18N('block')}</p>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="3" size="6">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage cascade waves src={window.CONSTANT.ICON_POST} className="icon mr-1"/>
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">{count.remain}</h5>
                <p className="text-muted mb-0">{window.I18N('remain')}</p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBCard>
      
      <MDBRow className="wow fadeIn animated" data-wow-delay="1s">
        <MDBCol>
          <ul className="list-group">
            <li className="list-group-item">
              <div className="d-flex align-items-center w-100 mt-2">
                <div className="input-group input-group-transparent ml-auto" style={{ width: '200px' }}>
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                    </span>
                  </div>
                  <DatePicker className="form-control" dateFormat="dd/MM/yyyy" selected={filter.startDate} onChange={(event) => handleDatePicker(event, 'startDate')} showYearDropdown showMonthDropdown/>
                </div>              
                <strong className="ml-2 mr-2"> - </strong>
                <div className="input-group input-group-transparent" style={{ width: '200px' }}>
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                    </span>
                  </div>
                  <DatePicker className="form-control" dateFormat="dd/MM/yyyy" selected={filter.endDate} onChange={(event) => handleDatePicker(event, 'endDate')} showYearDropdown showMonthDropdown/>
                </div>
                <div className="bd-highlight mr-3 ml-3" style={{ width: '200px' }}>
                  <div className="input-group input-group-transparent">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <MDBIcon fa="true" icon="filter" className="fa-fw" />
                      </span>
                    </div>
                    <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.statusReview} options={statusReviewFilterOptions} onChange={(event) => handleFilterSelect(event, 'statusReview')} isSearchable />
                  </div>
                </div>
                <div style={{ width: '350px' }}>
                  <MDBInputGroup type="text" value={filter.text} hint={window.I18N('search_content_post')} onChange={handleFilterChange} maxLength="200" pattern="\S(.*\S)?" required
                    append={
                      <span className="input-group-text cursor-pointer" onClick={() => doSearchData(0)}>
                        <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                      </span>
                    }
                  />
                </div>
              </div>
              <div className="d-flex align-items-center w-100 mt-2">
                <div className="d-inline-block ml-auto">
                  <RadioBox data={types} value={filter.isFanpage} className="m-0" handleChange={handleRadio}></RadioBox>
                </div>
                <a color="primary" className="btn" href="https://gopy.io/public/doc/screening_guidelines.pdf?fbclid=IwAR2c7_pPm5ak0qTnPXOQ-MbHfuhsdpbSKZUVKW3aAMqDSAevGvPY4yd6pPM" target="_blank">
                  <MDBIcon fa="true" icon="info-circle" className="fa-fw" /> {window.I18N('guide')}
                </a>
                <MDBBtn id="btn-search" className="mr-0" color="primary" onClick={() => doSearchData(0)}>
                  <MDBIcon fa="true" icon="search" className="fa-fw" /> {window.I18N('btn_search')}
                </MDBBtn>
              </div>
            </li>
            {
              data.map((item, i) => <li className="list-group-item" key={i}>
                <div className="w-100 d-flex align-items-start w-100">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <MDBCardImage cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, 'createdBy.avatar'))} className="image-icon rounded-circle mr-2"/>
                      <div>
                        <p className="mb-0 font-weight-bold">
                          {window.COMMON.getValueFromAttr(item, 'createdBy.nickname')}
                          {
                            filter.isFanpage ? <span className="text-muted">{' · ' + window.COMMON.getValueFromAttr(item, 'fanpage.name', '')}</span> : <></>
                          }
                        </p>
                        <span className="text-muted ml-auto"><small>{window.COMMON.getValueFromAttr(item, 'createdDate')}</small></span>
                      </div>
                    </div>
                    <span className="text-muted mb-2 mt-1">{window.COMMON.getValueFromAttr(item, 'text')}</span>
                    <div className="d-flex align-items-center">
                      {
                        window.COMMON.checkValue(window.COMMON.getValueFromAttr(item, 'audio')) ? <IconButton aria-label="audio"> 
                          <Audiotrack color="disabled" fontSize="small"/>
                          <small className="ml-1 grey-text" style={{ fontSize: '12px' }}>1</small>
                        </IconButton> : <></>
                      }
                      {
                        window.COMMON.checkValue(window.COMMON.getValueFromAttr(item, 'video')) ? <IconButton aria-label="video"> 
                          <VideoCall color="disabled" fontSize="small"/>
                          <small className="ml-1 grey-text" style={{ fontSize: '12px' }}>1</small>
                        </IconButton> : <></>
                      }
                      {
                        window.COMMON.checkValue(window.COMMON.getValueFromAttr(item, 'audio')) || window.COMMON.checkValue(window.COMMON.getValueFromAttr(item, 'video')) ? <></> : <IconButton aria-label="image"> 
                          <Image color="disabled" fontSize="small"/>
                          <small className="ml-1 grey-text" style={{ fontSize: '12px' }}>{window.COMMON.getValueFromAttr(item, 'image', []).length}</small>
                        </IconButton>
                      }
                      <IconButton aria-label="like">
                        <ThumbUp color="disabled" fontSize="small"/>
                        <small className="ml-1 grey-text" style={{ fontSize: '12px' }}>{window.COMMON.getValueFromAttr(item, 'like', []).length}</small>
                      </IconButton>
                      <IconButton aria-label="dislike">
                        <ThumbDown color="disabled" fontSize="small"/>
                        <small className="ml-1 grey-text" style={{ fontSize: '12px' }}>{window.COMMON.getValueFromAttr(item, 'dislike', []).length}</small>
                      </IconButton>
                      <IconButton aria-label="comment">
                        <Comment color="disabled" fontSize="small"/>
                        <small className="ml-1 grey-text" style={{ fontSize: '12px' }}>{window.COMMON.getValueFromAttr(item, 'comment', []).length}</small>
                      </IconButton>
                    </div>
                  </div>
                  <div className="ml-auto d-flex">
                    <div>
                      <div>
                        {
                          item.statusReview === 'HIDE' ? <label className="label-box label-danger p-1 m-0">{window.I18N('block')}</label> 
                            : item.statusReview === 'UNHIDE' ? <label className="label-box label-info p-1 m-0">{window.I18N('unblock')}</label> 
                              : item.statusReview === 'REVIEW' ? <label className="label-box label-success p-1 m-0">{window.I18N('reviewed')}</label> 
                                : <label className="label-box label-light p-1 m-0">{window.I18N('not_review')}</label>
                        }
                      </div>
                      {
                        item.statusReview ? <div>
                          <p className="text-muted mb-0 mt-1">{window.I18N('implemented_by')}: <span className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'reviewBy.nickname')}</span></p>
                          <p className="text-muted mb-0 mt-1">{window.I18N('implemented_date')}: <span className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'reviewDate')}</span></p>
                        </div> : <></>
                      }
                    </div>
                    <div className="dropdown ml-2">
                      <NavLink to="#" className="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-boundary="viewport">
                        <MDBIcon fa="true" icon="ellipsis-v" className="fa-fw black-text"></MDBIcon>
                      </NavLink>
                      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" data-boundary="viewport">
                        <li className="dropdown-item" onClick= {(event) => handleReviewClick(event, i)}>
                          <MDBIcon fa="true" icon="check-circle" className="fa-fw"></MDBIcon> {window.I18N('review')}
                        </li>
                        {
                          item.statusReview === '' ? <></> : <li className="dropdown-item" onClick= {(event) => handleHistoryClick(event, i)}>
                            <MDBIcon fa="true" icon="history" className="fa-fw"></MDBIcon> {window.I18N('history')}
                          </li>
                        }
                        {
                          item.statusReview === 'HIDE' ? <li className="dropdown-item" onClick={(event) => handleHideClick(event, i, 1)}>
                            <MDBIcon fa="true" icon="eye" className="fa-fw"></MDBIcon> {window.I18N('unblock')}
                          </li> : <li className="dropdown-item" onClick={(event) => handleHideClick(event, i, -1)}>
                            <MDBIcon fa="true" icon="eye-slash" className="fa-fw"></MDBIcon> {window.I18N('block')}
                          </li>
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </li>)
            }
            <li className="list-group-item">
              <Pagination total={pagination.total} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={changePage}></Pagination>
            </li>
          </ul>
        </MDBCol>
      </MDBRow>

      <Modal id="modal-post-item" className="modal-lg" title={window.I18N('view_details')} 
        iconClose="eye" closeBtn={window.I18N('viewed')} 
        icon="history" saveBtn={window.I18N('history')} saveEvent={() => historyClick()}
        rejectIcon={object.item && object.item.statusReview === 'HIDE' ? 'eye' : 'eye-slash'} 
        rejectBtn={object.item && object.item.statusReview === 'HIDE' ? window.I18N('unblock') : window.I18N('block')} 
        hideReject={false} rejectEvent={() => hideClick()}
        handleCloseModal={() => {
          object.item = null;
          setObject({ ...object });
        }}>
        {
          object.item ? <ItemPost item={object.item} index={object.index} key={object.item && object.item._id} isView={true} className="col-md-12 p-0" attr_id="_id" attr_content="text" attr_image="image" attr_avatar="createdBy.avatar" attr_name="createdBy.nickname" attr_time="createdDate"></ItemPost> : <></>
        }
      </Modal>

      <Modal id="modal-post-history" className="modal-lg" title={window.I18N('history')} hideSave={true}>
        <ul className="timeline">
          {
            dataHistory.map((item, i) => <li key={i}>
              <div className="d-flex align-items-center">
                <MDBCardImage cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, 'createdBy.avatar'))} className="image-icon rounded-circle mr-2"/>
                <div className="mr-auto">
                  <p className="mb-0 font-weight-bold">
                    {window.COMMON.getValueFromAttr(item, 'createdBy.nickname')}
                  </p>
                  <small className="text-muted ml-auto">{window.COMMON.getValueFromAttr(item, 'createdDate')}</small>
                </div>
                {
                  item.statusReview === 'HIDE' ? <label className="label-box label-danger p-1 m-0">{window.I18N('block')}</label> 
                    : item.statusReview === 'REVIEW' ? <label className="label-box label-success p-1 m-0">{window.I18N('review')}</label> 
                      : <label className="label-box label-info p-1 m-0">{window.I18N('unblock')}</label>
                }
              </div>
              <div>
                {
                  item.description && (item.statusReview === 'HIDE' || item.statusReview === 'UNHIDE') ? <small className="text-muted mt-2"><small className="font-weight-bold">*{window.I18N('note')}</small>: {item.description}</small> : <></>
                }       
              </div>     
              <div className="mt-2">
                {
                  item.reason && item.statusReview === 'HIDE' ? <label className="label-box label-light p-1 m-0">{window.COMMON.getValueWithLanguage(item, 'reason.content', language.current)}</label> : <></>
                }
              </div>
            </li>)
          }
        </ul>
      </Modal>

      <Modal id="modal-hide-post" className="modal-lg" icon={object.mode === -1 ? 'eye-slash' : 'eye'} title={window.I18N(object.mode === -1 ? 'block_post' : 'unblock_post')} saveBtn={window.I18N(object.mode === -1 ? 'block' : 'unblock')} saveEvent={(event) => reviewPost(event, object.mode === -1 ? 'HIDE' : 'UNHIDE')}>
        <form id="form-hide-post" className="needs-validation" noValidate>
          {
            object.mode === -1 ? <Select id="select-reason" className="md-form mt-0" value={select.reason} placeholder={window.I18N('reason') + ' *'} options={options.reason} onChange={(event) => handleSelect(event, 'select-reason', 'reason')} isSearchable/> : <></>
          }
          <MDBInput outline type="textarea" containerClass="m-0" hint={window.I18N(object.mode === -1 ? 'why_block_post' : 'why_unblock_post')}
            value={dataSave.description} onChange={(event) => handleChange(event, 'description')} maxLength="5000" pattern="\S(.*\S)?">
            <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
          </MDBInput>
        </form>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};
  
export default connect(mapStateToProps, mapDispatchToProps)(PostContentScreeningPage);