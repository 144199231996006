
import React, { useState, useLayoutEffect } from 'react';
import { MDBBtn, MDBIcon } from 'mdbreact';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

import AccountBalanceGRAPHQL from '../../../graphql/AccountBalance';

export default function GopyBalancePage(props) {
  const [filter, setFilter] = useState({ 
    type: { value: 'month', label: window.I18N('month') },
    date: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  });

  const [total, setTotal] = useState({ 
    total_gopies: 0,
    total_added_gopies: 0,
    total_subtracted_gopies: 0,
    gopies_balance: 0
  });

  const [data, setData] = useState([]);

  const [dataChart, setDataChart] = useState({
    labels: [],
    datasets: [
      {
        label: window.I18N('total_add_gopy'),
        data: [],
        borderColor: 'rgba(96, 203, 150, 1)',
        backgroundColor: 'rgba(96, 203, 150, 0.2)',
        borderWidth: 1
      },
      {
        label: window.I18N('total_subtract_gopy'),
        data: [],
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderWidth: 1
      }
    ]
  });

  const [dataChartAdd, setDataChartAdd] = useState({
    labels: [
      window.I18N('post'), window.I18N('like'), window.I18N('dislike'), window.I18N('comment'), window.I18N('share'),
      window.I18N('complete_qa'), window.I18N('complete_survey'), window.I18N('register_account'), window.I18N('add_friend'), window.I18N('create_fanpage')
    ],
    datasets: [{
      data: [],
      backgroundColor: ['red', 'orange', 'blue', 'green', 'grey', 'brown', 'purple', 'lightBlue', 'lightGreen', 'lightGrey']
    }]
  });

  const [dataChartSubtract, setDataChartSubtract] = useState({
    labels: [
      window.I18N('unlike'), window.I18N('remove_dislike'), window.I18N('remove_comment'), window.I18N('remove_post'), window.I18N('exchange'), window.I18N('delete_fanpage')
    ],
    datasets: [{
      data: [],
      backgroundColor: ['red', 'orange', 'blue', 'green', 'grey', 'black']
    }]
  });

  const typeOptions =  [
    { value: 'month', label: window.I18N('month') },
    { value: 'quarter', label: window.I18N('quarter') },
    { value: 'year', label: window.I18N('year') },
  ];

  const addedTypes = ['POST', 'LIKE', 'DISLIKE', 'COMMENT', 'SHARE', 'COMPLETE_QA', 'COMPLETE_SURVEY', 'REGISTER_ACCOUNT', 'ADD_FRIEND', 'CREATE_FANPAGE'];
  const subtractedTypes = ['UNLIKE', 'REMOVE_DISLIKE', 'REMOVE_COMMENT', 'REMOVE_POST', 'EXCHANGE', 'DELETE_FANPAGE'];

  useLayoutEffect(() => {
    setTimeout(() => {
      getTotalGopy();
      searchDataGopy();
    }, 100)
    // eslint-disable-next-line
  }, []);

  const handleSelect = (event) => {
    filter.type = event;
    setFilter({ ...filter })
  };

  const handleDatePicker = (event, attr) => {
    filter[attr] = event;
    setFilter({ ...filter });
  };

  const getTotalGopy = async () => {
    try {
      const result = await window.COMMON.query(AccountBalanceGRAPHQL.QUERY_TOTAL_GOPY);
      if (result && result.data) {
        total.total_gopies = window.COMMON.getValueFromAttr(result.data, 'countTotalGopy', '0');
      } else {
        total.total_gopies = 0;
      }
      setTotal(total);
    } catch (error) {
      window.COMMON.showErrorLogs('GopyBalancePage.getTotalGopy');
    }
  };

  const searchDataGopy = async () => {
    try {
      let startDate = '';
      let endDate = '';
      const labels = [];
      const dataAddGopy = [];
      const dataSubtractGopy = [];
      const date = new Date(filter.date);
      if (filter.type.value === 'month') {
        const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
        startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        endDate = new Date(date.getFullYear(), date.getMonth(), daysInMonth);
        for (let i = 1; i <= daysInMonth; i++) {
          labels.push(i < 10 ? `0${i}` : `${i}`);
          dataAddGopy.push(0)
          dataSubtractGopy.push(0)
        }
      } else if (filter.type.value === 'year') {
        startDate = new Date(date.getFullYear(), 0, 1);
        endDate = new Date(date.getFullYear(), 11, 31);
        for (let i = 1; i <= 12; i++) {
          labels.push(window.I18N(`month_${i < 10 ? `0${i}` : `${i}`}`));
          dataAddGopy.push(0)
          dataSubtractGopy.push(0)
        }
      } else {
        const quarter = Math.floor((date.getMonth() + 3) / 3);
        const daysInMonth = new Date(date.getFullYear(), (quarter * 3 - 1) + 1, 0).getDate();
        startDate = new Date(date.getFullYear(), (quarter - 1) * 3, 1);
        endDate = new Date(date.getFullYear(), quarter * 3 - 1, daysInMonth);
        for (let i = (quarter - 1) * 3 + 1; i <= quarter * 3; i++) {
          labels.push(window.I18N(`month_${i < 10 ? `0${i}` : `${i}`}`));
          dataAddGopy.push(0)
          dataSubtractGopy.push(0)
        }
      }
      const params = {
        input: {
          type: filter.type.value,
          startDate: window.COMMON_DATE.getStrDateFilter(startDate, -1),
          endDate: window.COMMON_DATE.getStrDateFilter(endDate, 1)
        }
      };
      // TODO total added gopy
      const result = await window.COMMON.query(AccountBalanceGRAPHQL.QUERY_ADDED_GOPY_BALANCE, params);
      if (result && result.data) {
        const data = result.data.getAddedGopyBalance;
        total.total_added_gopies = window.COMMON.getValueFromAttr(data, 'totalGopy', '0');
        const dataTotalGopy = window.COMMON.getValueFromAttr(data, 'dataTotal', []);
        dataTotalGopy.forEach(element => {
          let index = Number(element._id);
          if (filter.type.value === 'quarter') {
            const quarter = Math.floor((date.getMonth() + 3) / 3);
            index = Number(element._id) - (quarter - 1) * 3;
          }
          dataAddGopy[index - 1] = element.count;
        });
      } else {
        total.total_added_gopies = 0;
      }
      // TODO total subtracted gopy
      const resultSubtracted = await window.COMMON.query(AccountBalanceGRAPHQL.QUERY_SUBTRACTED_GOPY_BALANCE, params);
      if (resultSubtracted && resultSubtracted.data) {
        const data = resultSubtracted.data.getSubtractedGopyBalance;
        total.total_subtracted_gopies = window.COMMON.getValueFromAttr(data, 'totalSubtractGopy', '0');
        const dataSubtractTotalGopy = window.COMMON.getValueFromAttr(data, 'dataSubtractTotal', []);
        dataSubtractTotalGopy.forEach(element => {
          let index = Number(element._id);
          if (filter.type.value === 'quarter') {
            const quarter = Math.floor((date.getMonth() + 3) / 3);
            index = Number(element._id) - (quarter - 1) * 3;
          }
          dataSubtractGopy[index - 1] = element.count * -1;
        });
      } else {
        total.total_subtracted_gopies = 0;
      }
      total.gopies_balance = Number(total.total_added_gopies) + Number(total.total_subtracted_gopies);
      dataChart.labels = labels;
      dataChart.datasets[0].data = dataAddGopy;
      dataChart.datasets[1].data = dataSubtractGopy;
      // TODO gopy balance with types
      const paramsWithTypes = {
        input: {
          types: addedTypes.concat(subtractedTypes),
          startDate: window.COMMON_DATE.getStrDateFilter(startDate, -1),
          endDate: window.COMMON_DATE.getStrDateFilter(endDate, 1)
        }
      };
      const dataAddedTypes = [];
      const dataSubtractedTypes = [];
      const resultWithTypes = await window.COMMON.query(AccountBalanceGRAPHQL.QUERY_GOPY_BALANCE_WITH_TYPES, paramsWithTypes);
      let data = [];
      if (resultWithTypes && resultWithTypes.data) {
        data = window.COMMON.sortDescData(resultWithTypes.data.getGopyBalanceWithTypes, 'count');
      }
      addedTypes.forEach(element => {
        const check = window.COMMON.getObjectInArrs(element, data, '_id');
        if (check) {
          dataAddedTypes.push(check.count);
        } else {
          dataAddedTypes.push(0);
        }
      });
      subtractedTypes.forEach(element => {
        const check = window.COMMON.getObjectInArrs(element, data, '_id');
        if (check) {
          dataSubtractedTypes.push(check.count);
        } else {
          dataSubtractedTypes.push(0);
        }
      });
      dataChartAdd.datasets[0].data = dataAddedTypes;
      dataChartSubtract.datasets[0].data = dataSubtractedTypes;
      setTotal(total);
      setData(data);
      setDataChartAdd(dataChartAdd);
      setDataChartSubtract(dataChartSubtract);
      setDataChart({ ...dataChart });
    } catch (error) {
      window.COMMON.showErrorLogs('GopyBalancePage.searchDataGopy');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <div className="card p-3 mt-3" style={{ zIndex: 1000 }}>
        <div className="container-top d-flex align-items-center justify-content-end wow fadeInDown animated" data-wow-delay="0.5s">
          <div className="bd-highlight mr-2" style={{ width: '200px' }}>
            <div className="input-group input-group-transparent">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <MDBIcon fa="true" icon="filter" className="fa-fw" />
                </span>
              </div>
              <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.type} options={typeOptions} onChange={handleSelect} isSearchable/>
            </div>
          </div>
          <div className="bd-highlight mr-3" style={{ width: '200px' }}>
            <div className="input-group input-group-transparent">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                </span>
              </div>
              {
                filter.type && filter.type.value === 'month' ? <DatePicker className="form-control" dateFormat="MM/yyyy" selected={filter.date} onChange={(event) => handleDatePicker(event, 'date')} showMonthYearPicker/> :
                filter.type && filter.type.value === 'quarter' ? <DatePicker className="form-control" dateFormat="yyyy, QQQ" selected={filter.date} onChange={(event) => handleDatePicker(event, 'date')} showQuarterYearPicker/> :
                <DatePicker className="form-control" dateFormat="yyyy" selected={filter.date} onChange={(event) => handleDatePicker(event, 'date')} showYearPicker/>
              }
            </div>
          </div>
          <MDBBtn color="primary" className="mr-0" onClick={() => searchDataGopy()}>
            <MDBIcon fa="true" icon="search" className="fa-fw" /> {window.I18N('btn_search')}
          </MDBBtn>
        </div>
      </div>
      <div className="row mt-3" style={{ zIndex: -1 }}>
        <div className="col-12 col-lg-4 p-0 wow fadeInRight animated" data-wow-delay="1s">
          <div className="w-100 pl-2 pr-2">
            <div className="row m-0">
              <div className="col-12 p-2">
                <div className="container-item-dashboard card p-3">
                  <div className="d-flex align-items-start w-100">
                    <div className="mr-auto">
                      <p className="mb-3">{window.I18N('total_gopy')}</p>
                      <h4 className="font-weight-bold mb-3">{total.total_gopies}</h4>
                    </div>
                    <div className="container-icon-dashboard d-flex align-items-center justify-content-center" style={{ background: '#83C86A' }}>
                      <i className="fas fa-file-invoice-dollar text-white"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 p-2">
                <div className="container-item-dashboard card p-3">
                  <div className="d-flex align-items-start w-100">
                    <div className="mr-auto">
                      <p className="mb-3">{window.I18N('total_add_gopy')}</p>
                      <h4 className="font-weight-bold mb-3">{total.total_added_gopies}</h4>
                    </div>
                    <div className="container-icon-dashboard d-flex align-items-center justify-content-center" style={{ background: '#60CB96' }}>
                      <i className="fas fa-hand-holding-usd text-white"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 p-2">
                <div className="container-item-dashboard card p-3">
                  <div className="d-flex align-items-start w-100">
                    <div className="mr-auto">
                      <p className="mb-3">{window.I18N('total_subtract_gopy')}</p>
                      <h4 className="font-weight-bold mb-3">{total.total_subtracted_gopies}</h4>
                    </div>
                    <div className="container-icon-dashboard d-flex align-items-center justify-content-center" style={{ background: 'red' }}>
                      <i className="fas fa-receipt text-white"></i>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-6 p-2">
                <div className="container-item-dashboard card p-3">
                  <div className="d-flex align-items-start w-100">
                    <div className="mr-auto">
                      <p className="mb-3">{window.I18N('gopies_balance')}</p>
                      <h4 className="font-weight-bold mb-3">{total.gopies_balance}</h4>
                    </div>
                    <div className="container-icon-dashboard d-flex align-items-center justify-content-center" style={{ background: '#ffbf00' }}>
                      <i className="fas fa-comment-dollar text-white"></i>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8 mt-2 wow fadeInLeft animated" data-wow-delay="1s">
          <div className="card p-3">
            <div className="card-title">{window.I18N('gopies_balance_chart')}</div>
            <div className="card-body" style={{ height: '360px' }}>
              <Line data={dataChart} options={{responsive: true, maintainAspectRatio: false}}></Line>
            </div>
          </div>
        </div>
      </div>
      <div className="card p-3 mt-4 wow fadeInUp animated" data-wow-delay="1s">
        <div className="card-title">{window.I18N('gopies_balance_chart')}</div>
        <div className="card-body" style={{ height: '360px' }}>
          <Bar data={dataChart} options={{responsive: true, maintainAspectRatio: false}}></Bar>
        </div>
      </div>
      <div className="row mt-4 mb-3">
        <div className="col-12 col-lg-6 mt-2 wow fadeInLeft animated" data-wow-delay="1s">
          <div className="card p-3">
            <div className="card-title">{window.I18N('gopies_balance_details')}</div>
            <div className="card-body" style={{ minHeight: '360px' }}>
              {
                data.map((item, i) => 
                  <div key={i} className="d-flex align-items-center w-100 border-bottom pt-3 pb-3">
                    <span className="mr-1">{ i + 1 }.</span>
                    <span className="font-weight-bold">{ window.I18N(item._id.toLowerCase()) }</span>
                    <span className="ml-auto font-weight-bold">{ window.I18N(item.count) }</span>
                  </div>
                )
              }
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 mt-2 wow fadeInRight animated" data-wow-delay="1s">
          <div className="card p-3">
            <div className="card-title">{window.I18N('added_gopies_balance')}</div>
            <div className="card-body" style={{ height: '360px' }}>
              <Doughnut data={dataChartAdd} />
            </div>
          </div>
          <div className="card p-3 mt-3">
            <div className="card-title">{window.I18N('subtracted_gopies_balance')}</div>
            <div className="card-body" style={{ height: '360px' }}>
              <Doughnut data={dataChartSubtract} options={{responsive: true, maintainAspectRatio: false}} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}