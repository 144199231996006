import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { MDBRow, MDBCol, MDBCardImage, MDBIcon } from 'mdbreact';

const GridItemSelfAssessmentSchedule = React.memo(function GridItemSelfAssessmentSchedule(props) {

  const handleAssign = (event, row, col) => {
    event.stopPropagation();
    if (props.handleAssign) {
      props.handleAssign(row, col);
    }
  };

  const handleShare = (event, row, col) => {
    event.stopPropagation();

    if (props.handleShare) {
      props.handleShare(row, col);
    }
  };

  const handleEdit = (event, index) => {
    event.stopPropagation();
    if (props.handleEdit) {
      props.handleEdit(index);
    }
  };

  const handleDelete = (event, row, col) => {
    event.stopPropagation();
    if (props.handleDelete) {
      props.handleDelete(row, col);
    }
  };

  if (!props.data || props.data.length === 0) {
    return <li className="list-group-item p-3 text-center">{window.I18N('empty_table')}</li>;
  }
 
  return (
    <>
      {
        props.data.map((item, i) => 
          <li className="list-group-item p-3" key={i}>
            <MDBRow className={(item.status === -2 ? ' disabled' : '')}>
              <MDBCol>
                <p className="font-weight-bold m-0">
                  {window.COMMON.getValueFromAttr(item, 'name')}
                </p>
                <p className="mb-3">
                  <MDBIcon fa="true" icon="qrcode" className="fa-fw mr-1"></MDBIcon>
                  <small className="text-muted">{window.COMMON.getValueFromAttr(item, 'code')}</small>
                </p>
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    <small className="text-muted">{window.I18N('created_by')}</small>:&nbsp;
                    <MDBCardImage cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, 'createdBy.avatar'))} className="small-icon rounded-circle mr-1" />
                    <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'createdBy.name')}</small>
                  </div>
                  <span>
                    <small className="text-muted">{window.I18N('created_date')}</small>:&nbsp;
                    <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'createdDate')}</small>
                  </span>
                </div>
              </MDBCol>
              <MDBCol>
                {
                  window.COMMON.getValueFromAttr(item, 'groups', []).map((child, j) => <div className={'d-flex align-items-start flex-column bd-highlight' + (j === 0 ? '' : ' mt-2')} key={i * j + j}>
                    <div className="w-100 d-flex align-items-center">
                      <p className="mb-0">
                        <MDBIcon fa="true" icon="circle" className="fa-fw mr-1"></MDBIcon>
                        {window.COMMON.getValueFromAttr(child, 'nameLanguage')}
                      </p>
                    </div>
                  </div>)
                }
              </MDBCol>
              <MDBCol>
                {
                  item.status !== 'draft' && (item.status === 1 ? <label className="label-box label-info p-1 m-0">{window.I18N('running')}</label>
                    : item.status === 0 ? <label className="label-box label-primary p-1 m-0">{window.I18N('new')}</label>
                      : item.status === 3 ? <label className="label-box label-success p-1 m-0">{window.I18N('completed')}</label>
                        : <label className="label-box label-danger p-1 m-0">{window.I18N('expired')}</label>)
                }
                {item.status === 'draft' && <label className="label-box label-primary p-1 m-0">{window.I18N('draft')}</label>}
                <p className="mt-1 mb-0">
                  <MDBIcon fa="true" icon="clock" className="fa-fw mr-1"></MDBIcon>
                  <small className="text-muted">{window.COMMON.getValueFromAttr(item, 'startDate') + ' - ' + window.COMMON.getValueFromAttr(item, 'endDate')}</small>
                </p>
                <p className="mt-2 mb-0">
                  <small className="text-muted">{window.I18N('type')}</small>:&nbsp;
                  <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'typeName')}</small>
                </p>
                <p className="mt-1 mb-0">
                  <small className="text-muted">{window.I18N('period')}</small>:&nbsp;
                  <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'periodName')}</small>
                </p>
              </MDBCol>
              <MDBCol xs="2" sm="1" md="1" className="text-right">
                <div className="dropdown">
                  <NavLink to="#" className="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-boundary="viewport">
                    <MDBIcon fa="true" icon="ellipsis-v" className="fa-fw black-text"></MDBIcon>
                  </NavLink>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" data-boundary="viewport">
                    {item.status !== 'draft' && <li className="dropdown-item" onClick={(event) => handleAssign(event, i)}>
                      <MDBIcon fa="true" icon="user-plus" className="fa-fw"></MDBIcon> {window.I18N('assign_influencer')}
                    </li>}
                    {item.status !== 'draft' && <li className={'dropdown-item'} onClick={(event) => handleShare(event, i)}>
                      <MDBIcon fa="true" icon="share" className="fa-fw" style={{ color: '#007bff' }}></MDBIcon> {window.I18N('share_influencer')}
                    </li>}
                    {item.status !== 'draft' && <li className="dropdown-divider"></li>}
                    <li className="dropdown-item" onClick={(event) => handleEdit(event, i)}>
                      <MDBIcon fa="true" icon="edit" className="fa-fw" style={{ color: 'orange' }}></MDBIcon> {window.I18N('btn_edit')}
                    </li>
                    <li className={'dropdown-item' + (item.status === 1 ? ' disabled' : '')} onClick={item.status === 1 ? () => { } : (event) => handleDelete(event, i)}>
                      <MDBIcon fa="true" icon="trash-alt" className="fa-fw" style={{ color: 'red' }}></MDBIcon> {window.I18N('btn_delete')}
                    </li>
                  </ul>
                </div>
              </MDBCol>
            </MDBRow>
          </li>
        )
      }
    </>
  );
});

export default GridItemSelfAssessmentSchedule;