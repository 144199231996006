import { MDBCol, MDBIcon, MDBRow } from 'mdbreact';
import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Common from '../../../../utils/Common';
import Constant from '../../../../utils/Constant';

const CaseAssessmentListItems = (props) => {
  const ACCOUNT_TYPE = localStorage.getItem(Constant.ACCOUNT_TYPE);
  const USER_ID = localStorage.getItem(Constant.ID);
  const isAdmin = ACCOUNT_TYPE === Constant.SUPER_ADMIN;
  const isInfluencer = ACCOUNT_TYPE === Constant.INFLUENCER;

  const generateCaseStatusLabel = (status) => {
    const { CASE_ASSESSMENT_STATUS } = Constant;
    switch (status) {
      case CASE_ASSESSMENT_STATUS.PENDING:
        return (
          <label className="label-box label-info p-1 m-0">
            {window.I18N('case_pending')}
          </label>
        );
      case CASE_ASSESSMENT_STATUS.APPROVED:
        return (
          <label className="label-box label-success p-1 m-0">
            {window.I18N('case_approved')}
          </label>
        );
      case CASE_ASSESSMENT_STATUS.REJECTED:
        return (
          <label className="label-box label-danger p-1 m-0">
            {window.I18N('case_rejected')}
          </label>
        );
      case CASE_ASSESSMENT_STATUS.RUNNING:
        return (
          <label className="label-box label-primary p-1 m-0">
            {window.I18N('case_running')}
          </label>
        );
      case CASE_ASSESSMENT_STATUS.CLOSED:
      default:
        return (
          <label className="label-box label-warning p-1 m-0">
            {window.I18N('case_closed')}
          </label>
        );
    }
  };
  const getAssessmentTypeName = (typeString) => {
    switch (typeString) {
      case '1':
        return window.I18N('periodic_assessment');
      case '2':
        return window.I18N('requested_assessment');
      default:
        return '';
    }
  };
  const getAssessmentPeriodName = (periodString) => {
    switch (periodString) {
      case '1':
        return window.I18N('annually');
      case '2':
        return window.I18N('half_year');
      default:
        return '';
    }
  };

  const renderApproveRejectMenu = (item) => {
    return Common.checkAccountTypeIsSystemOrInfluencer() && (
      <>
        {item.status !== 'draft' && (
          <li
            role="button"
            className={`dropdown-item ${item.status !== Constant.CASE_ASSESSMENT_STATUS.PENDING ? 'disabled' : ''}`}
            onClick={(event) => handleApprove(event, item)}
          >
            <MDBIcon fa="true" icon="check" className="fa-fw" style={{ color: '#00C851' }}></MDBIcon> {window.I18N('btn_approve')}
          </li>
        )}
        {item.status !== 'draft' && (
          <li
            role="button"
            className={`dropdown-item ${item.status !== Constant.CASE_ASSESSMENT_STATUS.PENDING ? 'disabled' : ''}`}
            onClick={(event) => handleReject(event, item)}
          >
            <MDBIcon fa="true" icon="ban" className="fa-fw" style={{ color: '#ff4444' }}></MDBIcon> {window.I18N('btn_reject')}
          </li>
        )}
        <li className="dropdown-divider"></li>
      </>
    );
  };

  const handleDeleteItem = (event, item) => {
    event.stopPropagation();

    if (!isDeleteAble(item)) {
      return ;
    }

    if (props.handleDelete) {
      props.handleDelete(item);
    }
  };

  const handleApprove = (event, item) => {
    event.stopPropagation();
    if (props.handleApprove) {
      props.handleApprove(item);
    }
  };

  const handleReject = (event, item) => {
    event.stopPropagation();
    if (props.handleReject) {
      props.handleReject(item);
    }
  };

  const handleEdit = (event, item) => {
    event.stopPropagation();
    if (props.handleEdit) {
      props.handleEdit(item);
    }
  };

  const handlePreview = (event, item) => {
    event.stopPropagation();
    if (props.handlePreview) {
      props.handlePreview(item);
    }
  };

  const checkRejectedCase = (caseItem) => {
    const isRejectedCase = caseItem.status === Constant.CASE_ASSESSMENT_STATUS.REJECTED;

    // rejected by admin influencer.
    // if (isRejectedCase) {
    //   return isBefore(new Date(), new Date(parseInt(caseItem.approvalReminderDate)));
    // }

    return isRejectedCase;
  };

  const checkCaseEditAble = (caseItem) => {
    const isPendingCase = caseItem.status === Constant.CASE_ASSESSMENT_STATUS.PENDING;
    
    const isAuthor = USER_ID === caseItem.createdBy?._id;

    if (!isAuthor) {
      return false;
    }

    return isPendingCase || checkRejectedCase(caseItem);
  };

  const handleViewRejectReason = (ev, item) => {
    // open reject reason popup;
    ev.stopPropagation();
    if (props.handleViewRejectReason) {
      props.handleViewRejectReason(item);
    }
  };

  const isDeleteAble = (caseItem) => {
    const isPendingCase = caseItem.status === Constant.CASE_ASSESSMENT_STATUS.PENDING;
    return isPendingCase || checkRejectedCase(caseItem);
  };

  return props.data.map(item => (
    <li className='list-group-item p-3' key={item.code}>
      <MDBRow>
        <MDBCol role='button' onClick={ev => handlePreview(ev, item)}>
          <p className="font-weight-bold m-0">
            {Common.getValueFromAttr(item, 'name')}
          </p>
          <p className="mb-3">
            <MDBIcon fa="true" icon="qrcode" className="fa-fw mr-1"></MDBIcon>
            <small className='text-muted'>{Common.getValueFromAttr(item, 'code')}</small>
          </p>
          <div className='d-flex align-items-center'>
            <div className="mr-3">
              <small className="text-muted">{window.I18N('created_by')}</small>:&nbsp;
              {/* <MDBCardImage cascade waves src={Common.setValueImage(Common.getValueFromAttr(item, 'createdBy.avatar'))} className="small-icon rounded-circle mr-1" /> */}
              <small className="font-weight-bold">{Common.getValueFromAttr(item, 'createdBy.nickname')}</small>
            </div>
            <span>
              <small className="text-muted">{window.I18N('created_date')}</small>:&nbsp;
              <small className="font-weight-bold">{Common.formatDateSingleData(item, 'createdDate')}</small>
            </span>
          </div>
        </MDBCol>
        <MDBCol>
          <p className="mt-1 mb-0">
            <small className="text-muted">{window.I18N('case_status')}</small>&nbsp;
            <span>{generateCaseStatusLabel(item.status)}</span>
          </p>
          <p className="mt-2 mb-0">
            <small className="text-muted">{window.I18N('reminder_date')}</small>:&nbsp;
            <small className="font-weight-bold">{Common.formatDateSingleData(item, 'approvalReminderDate')}</small>
          </p>
        </MDBCol>
        <MDBCol>
          <p className="mt-1 mb-0">
            <small className="text-muted">{window.I18N('assessment_status')}</small>&nbsp;
            <span>
              {
                (item.assessment?.status === 1 ? <label className="label-box label-info p-1 m-0">{window.I18N('running')}</label>
                  : item.assessment?.status === 0 ? <label className="label-box label-primary p-1 m-0">{window.I18N('new')}</label>
                    : item.assessment?.status === 3 ? <label className="label-box label-success p-1 m-0">{window.I18N('completed')}</label>
                      : <label className="label-box label-danger p-1 m-0">{window.I18N('expired')}</label>)
              }
            </span>
          </p>
          <p className="mt-1 mb-0">
            <MDBIcon fa="true" icon="clock" className="fa-fw mr-1"></MDBIcon>
            <small className="text-muted">
              {Common.formatDateSingleData(item.assessment || {}, 'startDate')} - {Common.formatDateSingleData(item.assessment || {}, 'endDate')}
            </small>
          </p>
          <p className="mt-2 mb-0">
            <small className="text-muted">{window.I18N('type')}</small>:&nbsp;
            <small className="font-weight-bold">{getAssessmentTypeName(Common.getValueFromAttr(item, 'assessment.type'))}</small>
          </p>
          <p className="mt-1 mb-0">
            <small className="text-muted">{window.I18N('period')}</small>:&nbsp;
            <small className="font-weight-bold">{getAssessmentPeriodName(Common.getValueFromAttr(item, 'assessment.period'))}</small>
          </p>
        </MDBCol>
        <MDBCol xs="2" sm="1" md="1" className="text-right">
          <div className="dropdown">
            <NavLink to="#" className="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-boundary="viewport">
              <MDBIcon fa="true" icon="ellipsis-v" className="fa-fw black-text"></MDBIcon>
            </NavLink>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" data-boundary="viewport">
              {renderApproveRejectMenu(item)}
              {(isInfluencer || isAdmin) && (
                <li className="dropdown-item" onClick={(event) => handlePreview(event, item)}>
                  <MDBIcon fa="true" icon="eye" className="fa-fw"></MDBIcon> {window.I18N('preview')}
                </li>
              )}

              { checkRejectedCase(item) &&
                <li role="button" className="dropdown-item" onClick={(ev) => handleViewRejectReason(ev, item)}>
                  <MDBIcon fa="true" icon="eye" className="fa-fw" style={{ color: 'red' }}></MDBIcon> {window.I18N('btn_view_reject_reason')}
                </li>
              }

              {/* The author should edit the case before it is approved.*/}
              { checkCaseEditAble(item) && (
                <li role="button" className="dropdown-item" onClick={(event) => handleEdit(event, item)}>
                  <MDBIcon fa="true" icon="edit" className="fa-fw" style={{ color: 'orange' }}></MDBIcon> {window.I18N('btn_edit')}
                </li>
              )}
              
              <li className={`dropdown-item ${!isDeleteAble(item) ? 'disabled': ''}`} onClick={(event) => handleDeleteItem(event, item)}>
                <MDBIcon fa="true" icon="trash-alt" className="fa-fw" style={{ color: 'red' }}></MDBIcon> {window.I18N('btn_delete')}
              </li>
            </ul>
          </div>
        </MDBCol>
      </MDBRow>
    </li>
  ));
};
CaseAssessmentListItems.propTypes = {
  data: PropTypes.array.isRequired,
  handleDelete: PropTypes.func
};

export default CaseAssessmentListItems;
