const surveyEvent = {
  ADD_MENU_SURVEY: 'ADD_MENU_SURVEY',
  EDIT_MENU_SURVEY: 'EDIT_MENU_SURVEY',
  PUSH_MENU_SURVEY: 'PUSH_MENU_SURVEY',
  DEL_MENU_SURVEY: 'DEL_MENU_SURVEY',
  DEL_QUESTION_SURVEY: 'DEL_QUESTION_SURVEY',
  DEL_RECOMMEND_SURVEY: 'DEL_RECOMMEND_SURVEY',
  CHECK_IMPORT_QUESTION_SURVEY: 'CHECK_IMPORT_QUESTION_SURVEY',
  CHECK_IMPORT_ANSWER_SURVEY: 'CHECK_IMPORT_ANSWER_SURVEY',
  CHECK_IMPORT_RECOMMEND_SURVEY: 'CHECK_IMPORT_RECOMMEND_SURVEY',
  IMPORT_QUESTION_SURVEY: 'IMPORT_QUESTION_SURVEY',
  IMPORT_ANSWER_SURVEY: 'IMPORT_ANSWER_SURVEY',
  IMPORT_RECOMMEND_SURVEY: 'IMPORT_RECOMMEND_SURVEY'
};

export default surveyEvent;