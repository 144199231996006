import React, { useState, useCallback, useLayoutEffect, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { MDBCardImage, MDBInputGroup, MDBCardHeader, MDBCardBody, MDBCard, MDBCol, MDBRow, MDBBtn, MDBIcon } from 'mdbreact';

import ItemQuestionChart from '../../components/ItemQuestionChart';
import ExportExcel from '../../components/ExportExcel';
import RadioBox from '../../components/RadioBox';

import MetadataGRAPHQL from '../../../graphql/Metadata';
import QuestionGRAPHQL from '../../../graphql/Question';
import FinalConclusionReport from './FinalConclusionReport';
import {findAnswerHighestScore, handleCalcAverage, handleCalcAverageScoreOfCategory, sortQuestionByDate} from '../../../utils/poolQuestionReport';

function QuestionReportPage(props) {
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const types = [
    { value: 1, label: window.I18N('find_organization'), icon: 'building' },
    { value: 2, label: window.I18N('find_location'), icon: 'map-marker' }
  ];
  const exportTypes = [
    { value: 1, label: window.I18N('survey') },
    { value: 2, label: 'Raw data' }
  ];
  const language = useRef();
  const dataCompany = useRef();
  const [filter, setFilter] = useState({
    radio: 1,
    radioExport: 1,
    company: null,
    group: null,
    companyIndustry: null,
    nation: null,
    state: null,
    city: null,
    startDate: window.COMMON_DATE.addDaysFromNow(-30),
    endDate: new Date()
  });
  const [object, setObject] = useState({
    item: null,
    index: 0
  });
  const [data, setData] = useState([]);
  const [dataQuestion, setDataQuestion] = useState([]);
  const [dataAnswer, setDataAnswer] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [groupQuestions, setGroupQuestions] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [options, setOptions] = useState({
    group: [],
    company: [],
    industry: [],
    nation: [],
    state: [],
    city: []
  });
  const [count, setCount] = useState({
    company: 0,
    employee: 0,
    response: 0,
    question: 0
  });

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {
      getDataMetadata();
      getDataLocationOptions('-1', '-1');
      getDataCompany();
    }, 100);
    // eslint-disable-next-line
  }, [props.language]);

  const handleFilterSelect = (event, attr) => {
    filter[attr] = event;
    if (attr === 'nation' || attr === 'state') {
      getDataLocationOptions(attr, event.code);
    } else {
      setFilter({ ...filter });
    }
  };

  const handleChange = (event) => {
    filter.text = event.target.value;
    setFilter({ ...filter });
    doSearchQuestion();
  };

  const handleRadio = useCallback((attr, value) => {
    filter[attr] = value;
    if (attr === 'radio') {
      if (value === 1) {
        filter.company = options.company && options.company.length > 0 ? options.company[0] : null;
      } else {
        filter.companyIndustry = options.companyIndustry && options.companyIndustry.length > 0 ? options.companyIndustry[0] : null;
        filter.nation = options.nation && options.nation.length > 0 ? options.nation[0] : null;
        filter.state = null;
        filter.city = null;
        options.state = [];
        options.city = [];
      }
    }
    setOptions(options);
    setFilter({ ...filter });
  }, [filter, options]);

  const handleDatePicker = (event, attr) => {
    filter[attr] = event;
    setFilter({ ...filter });
  };

  const getDataMetadata = async () => {
    try {
      const params = {
        type: ['COMPANY_INDUSTRY']
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_SHORT_METADATA, params, false);
      if (result && result.data) {
        const dataIndustry = window.COMMON.getArrsInArrs('COMPANY_INDUSTRY', result.data.getMetadatas, 'type');
        options.companyIndustry = window.COMMON.createDataSelect(dataIndustry, '_id', 'value');
        options.companyIndustry.unshift({ value: null, label: window.I18N('all_industries') });
        filter.companyIndustry = { value: null, label: window.I18N('all_industries') };
        setFilter(filter);
        setOptions(options);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionReportPage.getDataMetadata');
    }
  };

  const getDataLocationOptions = async (attr, parent) => {
    if (attr === '-1') {
      options.nation = await window.COMMON.getDataLocation(parent);
      options.nation.unshift({ value: null, label: window.I18N('all_countries') });
      options.state = [];
      options.city = [];
      filter.nation = { value: null, label: window.I18N('all_countries') };
      filter.state = null;
      filter.city = null;
    } else if (attr === 'nation') {
      options.state = await window.COMMON.getDataLocation(parent);
      options.state.unshift({ value: null, label: window.I18N('all_states') });
      options.city = [];
      filter.state = { value: null, label: window.I18N('all_states') };
      filter.city = null;
    } else if (attr === 'state') {
      options.city = await window.COMMON.getDataLocation(parent);
      options.city.unshift({ value: null, label: window.I18N('all_cities') });
      filter.city = { value: null, label: window.I18N('all_cities') };
    }
    setOptions(options);
    setFilter({ ...filter });
  };

  const getDataCompany = async () => {
    try {
      if (!window.COMMON.checkRoleIsSystemOrInfluencer()) {
        filter.company = { value: COMPANY_ID, label: '' };
        setFilter({ ...filter });
        getDataGroup();
        return;
      }
      await window.COMMON.getDataCompany();
      dataCompany.current = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_COMPANY));
      if (dataCompany.current && dataCompany.current.length > 0) {
        options.company = window.COMMON.createDataSelectWithGroup(dataCompany.current, 'id', 'name', 'accountType', 'accountType');
      }
      if (window.COMMON.checkRoleIsSystemOrInfluencer()) {
        options.company.unshift({ value: null, label: window.I18N('all_organizations') });
        filter.company = window.COMMON.getValueFromAttr(options.company, '0', null);
      } else {
        filter.company = window.COMMON.getValueFromAttr(options.company, '0.options.0', null);
      }
      setOptions(options);
      setFilter({ ...filter });
      getDataGroup();
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionReportPage.getDataCompany');
    }
  };

  const getDataGroup = async () => {
    try {
      const params = {
        company: COMPANY_TYPE !== window.CONSTANT.INFLUENCER ? window.COMMON.getDataSelect(filter.company, false) : COMPANY_ID
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_ALL_GROUP_QUESTION, params, false);
      if (result && result.data) {
        options.group = window.COMMON.createDataSelect(result.data.getAllGroupQuestions, '_id', 'name.0.text', 'code', '', 'type', 'finalConclusion');
        filter.group = options.group && options.group.length > 0 ? options.group[0] : null;
        setFilter(filter);
        setOptions({ ...options });
        if (filter.group && filter.group.value) {
          doSearchData();
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionReportPage.getDataGroup');
    }
  };

  const doSearchData = () => {
    getDataQuestion();
  };

  const getDataQuestion = async () => {
    try {
      const params = {
        groupId: window.COMMON.getDataSelect(filter.group, false)
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_QUESTION, params);
      let dataQuestion = [];
      if (result && result.data && result.data.getQuestions) {
        dataQuestion = result.data.getQuestions;
        let index = 0;
        dataQuestion.forEach(question => {
          question.index = index++;
        });
        count.question = dataQuestion.length;
      } else {
        count.question = 0;
      }
      object.index = 0;
      object.item = dataQuestion[0];
      setCount(count);
      setDataQuestion(dataQuestion);
      setDataSearch(dataQuestion);
      getDataAnswer(dataQuestion);
      setObject({ ...object });
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionReportPage.getDataQuestion');
    }
  };

  const getDataAnswer = async (dataQuestion) => {
    try {
      const params = {
        groupId: window.COMMON.getDataSelect(filter.group, false)
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_ANSWER_QUESTION, params);
      let dataAnswer = [];
      if (result && result.data && result.data.getAnswerQuestions) {
        dataAnswer = result.data.getAnswerQuestions;
      }
      setDataAnswer(dataAnswer);
      getDataHistoreyQA(dataQuestion, dataAnswer);
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionReportPage.getDataAnswer');
    }
  };

  const getDataHistoreyQA = async (dataQuestion, dataAnswer) => {
    try {
      window.showLoading();
      let params = {};
      if (filter.radio === 1) {
        params = {
          input: {
            group: window.COMMON.getDataSelect(filter.group, false),
            startDate: window.COMMON_DATE.getStrDateFilter(filter.startDate, -1),
            endDate: window.COMMON_DATE.getStrDateFilter(filter.endDate, 1)
          }
        };
        if (filter.company && filter.company.code === window.CONSTANT.COMPANY) {
          params.input.companyIds = window.COMMON.getDataSelect(filter.company, true);
        } else if (filter.company && filter.company.code === window.CONSTANT.UNION) {
          params.input.unionIds = window.COMMON.getDataSelect(filter.company, true);
        }
      } else {
        params = {
          input: {
            group: window.COMMON.getDataSelect(filter.group, false),
            companyIndustry: window.COMMON.getDataSelect(filter.companyIndustry, false),
            nation: window.COMMON.getDataSelect(filter.nation, false),
            state: window.COMMON.getDataSelect(filter.state, false),
            city: window.COMMON.getDataSelect(filter.city, false),
            startDate: window.COMMON_DATE.getStrDateFilter(filter.startDate, -1),
            endDate: window.COMMON_DATE.getStrDateFilter(filter.endDate, 1)
          }
        };
      }
      params.input.page = 1;
      params.input.pageSize = 10;
      let data = [];
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_EXPORT_HISTORY_ANSWER, params, false);
      if (result && result.data && result.data.getHistoryAnswer) {
        const total = result.data.getHistoryAnswer.total;
        const page = total < 10 ? 1 : Math.ceil(total / 10);
        data = data.concat(result.data.getHistoryAnswer.data);
        for (let i = 1; i < page; i++) {
          await new Promise(resolve => setTimeout(resolve, 2000));
          params.input.page = i + 1;
          const resultItem = await window.COMMON.query(QuestionGRAPHQL.QUERY_EXPORT_HISTORY_ANSWER, params, false);
          if (resultItem && resultItem.data && resultItem.data.getHistoryAnswer) {
            data = data.concat(resultItem.data.getHistoryAnswer.data);
          }
        }
      }

      if (data && data.length > 0) {
        const companies = [];
        const employees = [];
        data.forEach(item => {
          const company = window.COMMON.getValueFromAttr(item, 'company._id');
          const employeeId = window.COMMON.getValueFromAttr(item, 'createdBy._id');
          if (companies.indexOf(company) === -1) {
            companies.push(company);
          }
          if (employees.indexOf(employeeId) === -1) {
            employees.push(employeeId);
          }
        });
        count.company = companies.length;
        count.employee = employees.length;
        count.response = data.length;
      } else {
        count.company = 0;
        count.employee = 0;
        count.response = 0;
      }
      setData(data);
      setCount({ ...count });
      processChartData(data, dataQuestion, dataAnswer);
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionReportPage.getDataHistoreyQA');
    }
    window.hideLoading();
  };

  const processChartData = async (dataSurvey, dataQuestion, dataAnswer) => {
    // TODO create data count for answers
    const dataCount = [];
    if (dataSurvey && dataSurvey.length > 0) {
      dataSurvey.forEach(item => {
        item.feedback.forEach(feedback => {
          if (feedback.question && feedback.question.type !== 'TEXT') {
            feedback.answers.forEach(answer => {
              const obj = window.COMMON.getObjectInArrs(answer._id, dataCount, 'answerId');
              if (obj) {
                obj.count += 1;
              } else {
                dataCount.push({ answerId: answer._id, count: 1 });
              }
            });
          }
        });
      });
    }
    // TODO initial data chart
    const dataChart = [];
    if (dataQuestion.length > 0) {
      dataQuestion.forEach(question => {
        const obj = {
          questionId: question._id,
          type: question.type,
          chart: []
        };
        if (obj.type === 'TEXT') {
          dataSurvey.forEach(item => {
            const answer = window.COMMON.getObjectInArrs(obj.questionId, item.feedback, 'question._id');
            if (answer) {
              obj.chart.push(answer.answerText);
            }
          });
          dataChart.push(obj);
        } else {
          let chart = {
            labels: [],
            datasets: [{
              data: [],
              backgroundColor: [],
              borderColor: []
            }]
          };
          if (obj.type === 'RADIO_GRID' || obj.type === 'CHECKBOX_GRID') {
            chart = {
              labels: [],
              datasets: []
            };
          }
          const answers = window.COMMON.getArrsInArrs(question._id, dataAnswer, 'question');
          const labels = [];
          for (let i = 0; i < answers.length; i++) {
            const answer = answers[i];
            if (obj.type === 'RADIO_GRID' || obj.type === 'CHECKBOX_GRID') {
              if (answer.parentCode === null) {
                labels.push(window.COMMON.getValueWithLanguage(answer, 'content', language.current));
                const children = window.COMMON.getArrsInArrs(answer.code, dataAnswer, 'parentCode');
                for (let j = 0; j < children.length; j++) {
                  const child = children[j];
                  const objCount = window.COMMON.getObjectInArrs(child._id, dataCount, 'answerId');
                  if (!chart.datasets[j]) {
                    chart.datasets.push({
                      label: window.COMMON.getValueWithLanguage(child, 'content', language.current),
                      backgroundColor: [window.CONSTANT.BACKGROUND_COLOR[j]],
                      borderColor: window.CONSTANT.BORDER_COLOR[j],
                      data: [objCount ? objCount.count : 0]
                    });
                  } else {
                    chart.datasets[j].backgroundColor.push(window.CONSTANT.BACKGROUND_COLOR[j]);
                    chart.datasets[j].data.push(objCount ? objCount.count : 0);
                  }
                }
              }
            } else {
              labels.push(window.COMMON.getValueWithLanguage(answer, 'content', language.current));
              const objCount = window.COMMON.getObjectInArrs(answer._id, dataCount, 'answerId');
              chart.datasets[0].data[i] = objCount ? objCount.count : 0;
              chart.datasets[0].backgroundColor[i] = window.CONSTANT.BACKGROUND_COLOR[i];
              chart.datasets[0].borderColor[i] = window.CONSTANT.BORDER_COLOR[i];
            }
          }
          chart.labels = labels;
          obj.chart = chart;
          dataChart.push(obj);
        }
      });
    }
    setDataChart(dataChart);
  };

  const getDataExport = async () => {
    window.showLoading();
    const result = filter.radioExport === 1 ? generateDataExport() : generateDataRawExport(data, count, dataQuestion);
    window.hideLoading();
    return result;
  };

  const generateDataExport = () => {
    const dataExport = {
      summary: {
        survey: filter.group ? filter.group.label : '',
        startDate: window.COMMON_DATE.formatStringToDate(filter.startDate),
        endDate: window.COMMON_DATE.formatStringToDate(filter.endDate),
        companies: count.company,
        employees: count.employee,
        responses: count.response
      },
      data: [],
      dataCompanies: []
    };
    const dataCount = [];
    if (data && data.length > 0) {
      const employees = [];
      data.forEach(item => {
        // TODO create data companies
        const id = window.COMMON.getValueFromAttr(item, 'company._id', '-1');
        const company = window.COMMON.getObjectInArrs(id, dataCompany.current, 'id');
        let check = window.COMMON.getObjectInArrs(id, dataExport.dataCompanies, 'id');
        const employeeId = window.COMMON.getValueFromAttr(item, 'createdBy._id');
        if (!check) {
          employees.push(employeeId);
          check = {
            id,
            name: company ? company.name : 'Other',
            industry: company ? company.companyIndustry.value : '',
            type: company ? company.companyType.value : '',
            size: company ? company.companySize.value : '',
            employees: 1,
            responses: 1,
            dataCount: [],
            data: []
          };
          dataExport.dataCompanies.push(check);
        } else {
          if (employees.indexOf(employeeId) === -1) {
            employees.push(employeeId);
            check.employees += 1;
          }
          check.responses += 1;
        }
        // TODO create data count for answers
        item.feedback.forEach(feedback => {
          if (feedback.question && feedback.question.type !== 'TEXT') {
            feedback.answers.forEach(answer => {
              const obj = window.COMMON.getObjectInArrs(answer._id, dataCount, 'answerId');
              if (obj) {
                obj.count += 1;
              } else {
                dataCount.push({ answerId: answer._id, count: 1 });
              }
              const objCompany = window.COMMON.getObjectInArrs(answer._id, check.dataCount, 'answerId');
              if (objCompany) {
                objCompany.count += 1;
              } else {
                check.dataCount.push({ answerId: answer._id, count: 1 });
              }
            });
          }
        });
      });
    }
    // TODO set data export
    if (dataQuestion && dataQuestion.length > 0) {
      dataQuestion.forEach(question => {
        const questionName = window.COMMON.getValueWithLanguage(question, 'question', language.current);
        dataExport.data.push([questionName]);
        setColForCompany(dataExport.dataCompanies, [questionName]);
        if (question.type === 'TEXT') {
          let text = '';
          data.forEach(item => {
            const answer = window.COMMON.getObjectInArrs(question._id, item.feedback, 'question._id');
            if (answer) {
              text += answer.answerText + ',';
            }
          });
          dataExport.data.push([text.replace(/.$/, '')]);
          setTextForCompany(dataExport.dataCompanies, data, question);
        } else {
          const answers = window.COMMON.getArrsInArrs(question._id, dataAnswer, 'question');
          if (answers && answers.length > 0) {
            if (question.type === 'RADIO_GRID' || question.type === 'CHECKBOX_GRID') {
              const parent = answers.filter(function (item) {
                return item.parentCode === null;
              });
              if (parent && parent.length > 0) {
                parent.forEach(answer => {
                  const answerName = window.COMMON.getValueWithLanguage(answer, 'content', language.current);
                  dataExport.data.push([answerName]);
                  setColForCompany(dataExport.dataCompanies, [answerName]);
                  const children = window.COMMON.getArrsInArrs(answer.code, answers, 'parentCode');
                  if (children && children.length > 0) {
                    let total = 0;
                    const arrs = [];
                    const counts = [];
                    children.forEach(child => {
                      arrs.push(window.COMMON.getValueWithLanguage(child, 'content', language.current));
                      const objCount = window.COMMON.getObjectInArrs(child._id, dataCount, 'answerId');
                      total += objCount ? objCount.count : 0;
                      counts.push(objCount ? objCount.count : 0);
                    });
                    arrs.push(window.I18N('total'));
                    counts.push(total);
                    // TODO calculate percent
                    const percents = [];
                    children.forEach(child => {
                      const objCount = window.COMMON.getObjectInArrs(child._id, dataCount, 'answerId');
                      const percent = total > 0 ? parseFloat((objCount ? objCount.count : 0) / total * 100) : 0;
                      percents.push((Math.round(percent * 100) / 100).toFixed(2) + '%');
                    });
                    percents.push(total > 0 ? '100.00%' : '0.00%');
                    dataExport.data.push(arrs);
                    dataExport.data.push(counts);
                    dataExport.data.push(percents);
                    setColForCompany(dataExport.dataCompanies, arrs);
                    setCountForCompany(dataExport.dataCompanies, children);
                  }
                });
              }
            } else {
              let total = 0;
              const arrs = [];
              const counts = [];
              answers.forEach(answer => {
                arrs.push(window.COMMON.getValueWithLanguage(answer, 'content', language.current));
                const objCount = window.COMMON.getObjectInArrs(answer._id, dataCount, 'answerId');
                total += objCount ? objCount.count : 0;
                counts.push(objCount ? objCount.count : 0);
              });
              arrs.push(window.I18N('total'));
              counts.push(total);
              // TODO calculate percent
              const percents = [];
              answers.forEach(answer => {
                const objCount = window.COMMON.getObjectInArrs(answer._id, dataCount, 'answerId');
                const percent = total > 0 ? parseFloat((objCount ? objCount.count : 0) / total * 100) : 0;
                percents.push((Math.round(percent * 100) / 100).toFixed(2) + '%');
              });
              percents.push(total > 0 ? '100.00%' : '0.00%');
              dataExport.data.push(arrs);
              dataExport.data.push(counts);
              dataExport.data.push(percents);
              setColForCompany(dataExport.dataCompanies, arrs);
              setCountForCompany(dataExport.dataCompanies, answers);
            }
          }
        }
        dataExport.data.push([]);
        setColForCompany(dataExport.dataCompanies, []);
      });
    }
    return dataExport;
  };

  const generateDataRawExport = async () => {
    const dataExport = [];
    const questions = [];
    let index = 0;
    let categoriesAverage = 0;
    const categories = groupQuestions.map(item => item.categoryTitle);
    const finalConclusion = filter?.group?.finalConclusion;
    data.forEach(item => {
      index++;
      item.updatedDate = window.COMMON_DATE.formatDate(item.updatedDate, 'DD/MM/YYYY hh:mm');
      item.groupLanguage = window.COMMON.getValueWithLanguage(item, 'group.name', language.current);
      item.organizationName = item.company ? window.COMMON.getValueFromAttr(item.company, 'information.name') : (item.influencer ? window.COMMON.getValueFromAttr(item.influencer, 'information.name') : '');
      item.answers = [];
      item.feedback.forEach(child => {
        if (index === 1) {
          const questionLanguage = window.COMMON.getValueWithLanguage(child, 'question.question', language.current);
          questions.push(questionLanguage);
        }
        let answerLanguage = '';
        if (child.question) {
          if (child.question.type === 'TEXT') {
            answerLanguage = child.answerText;
          } else if (child.question.type === 'RADIO_GRID' || child.question.type === 'CHECKBOX_GRID') {
            const parent = child.answers.filter(function (item) {
              return window.COMMON.getValueFromAttr(item, 'parentCode', null) === null;
            });
            parent.forEach(element => {
              answerLanguage += window.COMMON.getValueWithLanguage(element, 'content', language.current) + ': ';
              child.answers.forEach(answer => {
                if (answer.parentCode === element.code) {
                  answerLanguage += window.COMMON.getValueWithLanguage(answer, 'content', language.current) + ', ';
                }
              });
              answerLanguage = answerLanguage.trim().replace(/,$/, '');
              answerLanguage += ' - ';
            });
            answerLanguage = answerLanguage.trim().replace(/-$/, '');
          } else {
            child.answers.forEach(answer => {
              answerLanguage += window.COMMON.getValueWithLanguage(answer, 'content', language.current) + ', ';
            });
            answerLanguage = answerLanguage.trim().replace(/,$/, '');
          }
        }
        item.answers.push(answerLanguage);
      });
      dataExport.push(item);
    });

    if (filter.group?.type === 2) {
      data.forEach((dataItem, index) => {
        // group question by category
        const sorQuestionsByDate = sortQuestionByDate(dataItem.feedback.filter(feedback => feedback.question !== null))

        const categoryQuestions = [];
        let categoriesStandardSum = 0;
        sorQuestionsByDate.forEach((item) => {
          const categoryTitle = window.COMMON.getValueWithLanguage(item.question, 'category', language.current);
          const categoryIndex = categoryQuestions.findIndex(item => item.categoryTitle === categoryTitle);

          const answerHighestScore = findAnswerHighestScore(dataAnswer, item.question._id);

          // remove text question
          if (item.question.type === 'TEXT') return;

          if (categoryIndex === -1) {
            categoryQuestions.push({
              categoryTitle,
              historyAnswers: [{
                historyAnswer: item.answers,
                answerHighestScore
              }]
            });
            categoriesStandardSum += item.question.standardOfCategory;
          } else {
            categoryQuestions[categoryIndex] = {
              ...categoryQuestions[categoryIndex],
              historyAnswers: [
                ...categoryQuestions[categoryIndex].historyAnswers,
                {
                  historyAnswer: item.answers,
                  answerHighestScore
                }
              ]
            };
          }
        });
        categoriesAverage = Number((categoriesStandardSum / categoryQuestions.length).toFixed(2));
        const result = [];
        // calculate average of each category in feedback
        categoryQuestions.forEach(item => {
          if (item.historyAnswers.length === 0) return;

          const calcAnswerResult = handleCalcAverageScoreOfCategory(item.historyAnswers);
          result.push({
            categoryTitle: item.categoryTitle,
            averageAnswers: Number(calcAnswerResult.toFixed(2))
          });
        });
        // add to dataExport
        dataExport.forEach((item, i) => {
          if (i === index) {
            item.answerResult = result;
          }
        });
      });
      
    }
    const standardOfConclusion = dataExport[0].group.standardOfConclusion
    return { questions, data: dataExport, finalConclusion, categories, categoriesAverage, standardOfConclusion };
  };

  const setColForCompany = (data, value) => {
    data.forEach(item => {
      item.data.push(value);
    });
  };

  const setTextForCompany = (data, dataSurvey, question) => {
    data.forEach(company => {
      let text = '';
      dataSurvey.forEach(item => {
        const answer = window.COMMON.getObjectInArrs(question._id, item.feedback, 'question._id');
        const id = window.COMMON.getValueFromAttr(item, 'company._id', '-1');
        if (company.id === id && answer) {
          text += answer.answerText + ',';
        }
      });
      company.data.push([text.replace(/.$/, '')]);
    });
  };

  const setCountForCompany = (data, answers) => {
    data.forEach(item => {
      let total = 0;
      const counts = [];
      answers.forEach(answer => {
        const objCount = window.COMMON.getObjectInArrs(answer._id, item.dataCount, 'answerId');
        total += objCount ? objCount.count : 0;
        counts.push(objCount ? objCount.count : 0);
      });
      counts.push(total);
      item.data.push(counts);
      // TODO calculate percent
      const percents = [];
      answers.forEach(answer => {
        const objCount = window.COMMON.getObjectInArrs(answer._id, item.dataCount, 'answerId');
        const percent = total > 0 ? parseFloat((objCount ? objCount.count : 0) / total * 100) : 0;
        percents.push((Math.round(percent * 100) / 100).toFixed(2) + '%');
      });
      percents.push(total > 0 ? '100.00%' : '0.00%');
      item.data.push(percents);
    });
  };

  const doSearchQuestion = () => {
    let dataSearch = [];
    if (filter.text) {
      dataQuestion.forEach(item => {
        if (window.COMMON.getValueWithLanguage(item, 'question', language.current).toLowerCase().indexOf(filter.text.toLowerCase()) > -1) {
          dataSearch.push(item);
        }
      });
    } else {
      dataSearch = dataQuestion;
    }
    setDataSearch(dataSearch);
  };

  const changeQuestion = (index) => {
    object.index = index;
    object.item = dataQuestion[index];
    setObject({ ...object });
  };
  useEffect(() => {
    if (!dataSearch) return;
    const result = [];

    dataSearch.forEach((item) => {
      const findCategoryTitle = window.COMMON.getValueWithLanguage(item, 'category', language.current);
      const categoryIndex = result.findIndex(item => item.categoryTitle === findCategoryTitle);

      if (categoryIndex === -1) {
        result.push({
          categoryTitle: findCategoryTitle,
          standardLowOfCategory: item.standardLowOfCategory,
          standardOfCategory: item.standardOfCategory,
          questions: [item]
        });
      } else {
        result[categoryIndex] = {
          ...result[categoryIndex],
          questions: [
            ...result[categoryIndex].questions,
            item
          ]
        };
      }
    });
    setGroupQuestions(result);
  }, [dataSearch, language.current]);

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <div className="card mb-3 mt-3 wow fadeInDown animated text-left" data-wow-delay="0.5s" style={{ zIndex: 1 }}>
        <div className="card-body pt-2">
          <MDBRow>
            <MDBCol sm="12" className="mb-2">
              <strong>{window.I18N('group_qa')}</strong>
              <div className="input-group input-group-transparent mt-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="question-circle" className="fa-fw" />
                  </span>
                </div>
                <Select className="md-form m-0" value={filter.group} options={options.group} placeholder={window.I18N('choose_option')} onChange={(event) => handleFilterSelect(event, 'group')} isSearchable />
              </div>
            </MDBCol>
            {
              COMPANY_TYPE === window.CONSTANT.ADMIN && filter.radio === 2 ?
                <MDBCol sm="6" className="mb-2">
                  <strong>{window.I18N('industry')}</strong>
                  <div className="input-group input-group-transparent mt-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <MDBIcon fa="true" icon="filter" className="fa-fw" />
                      </span>
                    </div>
                    <Select value={filter.companyIndustry} options={options.companyIndustry} className="md-form m-0" placeholder={window.I18N('choose_option')} onChange={(event) => handleFilterSelect(event, 'companyIndustry')} isSearchable />
                  </div>
                </MDBCol> : null
            }
            {
              COMPANY_TYPE === window.CONSTANT.ADMIN && filter.radio === 2 ?
                <MDBCol sm="3" className="mb-2">
                  <strong>{window.I18N('country')}</strong>
                  <div className="input-group input-group-transparent mt-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <MDBIcon fa="true" icon="filter" className="fa-fw" />
                      </span>
                    </div>
                    <Select value={filter.nation} options={options.nation} className="md-form m-0" placeholder={window.I18N('choose_option')} onChange={(event) => handleFilterSelect(event, 'nation')} isSearchable />
                  </div>
                </MDBCol> : null
            }
            {
              COMPANY_TYPE === window.CONSTANT.ADMIN && filter.radio === 2 ?
                <MDBCol sm="3" className="mb-2">
                  <strong>{window.I18N('state_city')}</strong>
                  <div className="input-group input-group-transparent mt-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <MDBIcon fa="true" icon="filter" className="fa-fw" />
                      </span>
                    </div>
                    <Select value={filter.state} options={options.state} className="md-form m-0" placeholder={window.I18N('choose_option')} onChange={(event) => handleFilterSelect(event, 'state')} isSearchable />
                  </div>
                </MDBCol> : null
            }
            {
              COMPANY_TYPE === window.CONSTANT.ADMIN && filter.radio === 2 ?
                <MDBCol sm="3" className="mb-2">
                  <strong>{window.I18N('city_district')}</strong>
                  <div className="input-group input-group-transparent mt-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <MDBIcon fa="true" icon="filter" className="fa-fw" />
                      </span>
                    </div>
                    <Select value={filter.city} options={options.city} className="md-form m-0" placeholder={window.I18N('choose_option')} onChange={(event) => handleFilterSelect(event, 'city')} isSearchable />
                  </div>
                </MDBCol> : null
            }
            {
              window.COMMON.checkRoleIsSystemOrInfluencer() && filter.radio === 1 ?
                <MDBCol sm="6" className="mb-2">
                  <strong>{window.I18N('organization')}</strong>
                  <div className="input-group input-group-transparent mt-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <MDBIcon fa="true" icon="building" className="fa-fw" />
                      </span>
                    </div>
                    <Select className="md-form m-0" value={filter.company} options={options.company} placeholder={window.I18N('choose_option')} onChange={(event) => handleFilterSelect(event, 'company')} isSearchable />
                  </div>
                </MDBCol> : <></>
            }
            <MDBCol sm="3" className="mb-2">
              <strong>{window.I18N('from_date')}</strong>
              <div className="input-group input-group-transparent mt-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                  </span>
                </div>
                <DatePicker className="form-control" dateFormat="dd/MM/yyyy" selected={filter.startDate} onChange={(event) => handleDatePicker(event, 'startDate')} showYearDropdown showMonthDropdown />
              </div>
            </MDBCol>
            <MDBCol sm="3" className="mb-2">
              <strong>{window.I18N('to_date')}</strong>
              <div className="input-group input-group-transparent mt-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                  </span>
                </div>
                <DatePicker className="form-control" dateFormat="dd/MM/yyyy" selected={filter.endDate} onChange={(event) => handleDatePicker(event, 'endDate')} showYearDropdown showMonthDropdown />
              </div>
            </MDBCol>
            <MDBCol sm="12">
              <div className="d-flex flex-row align-items-center justify-content-end">
                {
                  COMPANY_TYPE === window.CONSTANT.ADMIN ?
                    <div className="mr-2">
                      <RadioBox data={types} value={filter.radio} className="m-0" handleChange={(value) => handleRadio('radio', value)}></RadioBox>
                    </div> : null
                }
                <RadioBox data={exportTypes} value={filter.radioExport} className="m-0 ml-auto" handleChange={(value) => handleRadio('radioExport', value)}></RadioBox>
                <MDBBtn color="" onClick={doSearchData}>
                  <MDBIcon fa="true" icon="search" className="fa-fw" /> {window.I18N('btn_search')}
                </MDBBtn>
                <ExportExcel 
                  label={window.I18N('export')} 
                  className="mr-0" 
                  isTemplate={false} 
                  dataKey={filter.radioExport === 1 ? 'QA_REPORT_DATA' : filter.group?.type === 2 ? 'SOFT_SKILLS_QA_REPORT' : 'QA_REPORT'} 
                  handleData={getDataExport}
                  language={language.current}
                ></ExportExcel>
              </div>
            </MDBCol>
          </MDBRow>
        </div>
      </div>

      <MDBCard className="mb-2 wow fadeInDown animated" data-wow-delay="0.5s">
        <MDBRow>
          <MDBCol md="3" size="6">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage cascade waves src={window.CONSTANT.ICON_ORGANIZATION} className="icon mr-1" />
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">{count.company}</h5>
                <p className="text-muted mb-0">{window.I18N('total_companies')}</p>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="3" size="6">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage cascade waves src={window.CONSTANT.ICON_EMPLOYEE} className="icon mr-1" />
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">{count.employee}</h5>
                <p className="text-muted mb-0">{window.I18N('total_employees')}</p>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="3" size="6">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage cascade waves src={window.CONSTANT.ICON_RESPONSE} className="icon mr-1" />
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">{count.response}</h5>
                <p className="text-muted mb-0">{window.I18N('total_responses')}</p>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="3" size="6">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage cascade waves src={window.CONSTANT.ICON_QA} className="icon mr-1" />
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">{count.question}</h5>
                <p className="text-muted mb-0">{window.I18N('total_questions')}</p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBCard>

      <MDBRow style={{ zIndex: 2 }}>
        <MDBCol lg="5" className="pr-md-1">
          <MDBCard className="wow fadeInUp animated text-left" data-wow-delay="0.5s" style={{ height: '100%' }}>
            <MDBCardHeader className="pb-0">{window.I18N('question_list')}</MDBCardHeader>
            <MDBCardBody>
              <MDBInputGroup type="text" value={filter.text} hint={window.I18N('search_question')} onChange={handleChange} maxLength="100" pattern="\S(.*\S)?" required
                append={
                  <span className="input-group-text cursor-pointer">
                    <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                  </span>
                }
              />
              {
                filter.group?.type === 2 ? (
                  <div className="container-list-question mt-3" style={{ maxHeight: '415px', overflowX: 'overlay' }}>
                    {
                      groupQuestions.map((item, i) => {
                        const { categoryTitle, standardLowOfCategory, standardOfCategory, questions } = item;
                        return (
                          <div key={i}>
                            <div className='d-flex flex-column mt-3'>
                              <div className='d-flex align-items-center'>
                                <MDBIcon fa="true" icon="asterisk" className="fa-fw mr-2" style={{ color: '#1976d2' }} />
                                <strong className='m-0'>{categoryTitle} ({standardLowOfCategory} - {standardOfCategory}%)</strong>
                              </div>
                              <div className='d-flex flex-column ml-4'>
                                {
                                  questions.map((question, j) => {
                                    return (
                                      <div
                                        className={'item d-flex align-items-center p-2' + (question.index === object.index ? ' active' : '')}
                                        key={j}
                                        onClick={() => changeQuestion(question.index)}
                                      >
                                        <MDBIcon fa="true" icon="circle" className="fa-fw mr-2" style={{ color: '#10C986' }} />
                                        <span>{(j + 1) + '. ' + window.COMMON.getValueWithLanguage(question, 'question', language.current)}</span>
                                      </div>
                                    );
                                  })
                                }
                              </div>
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                ) : (
                  <div className="container-list-question mt-3" style={{ maxHeight: '415px', overflowX: 'overlay' }}>
                    {
                      dataSearch.map((item, i) =>
                        <div className={'item d-flex align-items-center' + (item.index === object.index ? ' active' : '')} key={i} onClick={() => changeQuestion(item.index)}>
                          <MDBIcon fa="true" icon="circle" className="fa-fw mr-2" style={{ color: '#10C986' }} />
                          <span>{(item.index + 1) + '. ' + window.COMMON.getValueWithLanguage(item, 'question', language.current)}</span>
                        </div>
                      )
                    }
                  </div>
                )
              }
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol lg="7" className="pl-md-1">
          <div style={{ height: '100%' }}>
            <ItemQuestionChart item={object.item} dataChart={object.item && window.COMMON.getObjectInArrs(object.item._id, dataChart, 'questionId')} language={language.current}></ItemQuestionChart>
          </div>
        </MDBCol>
      </MDBRow>
      {
        data.find(item => item.group.type === 2) && (
          <FinalConclusionReport
            data={data}
            dataAnswer={dataAnswer}
            language={language.current}
          />
        )
      }
    </div>
  );
}

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionReportPage);