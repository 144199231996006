import React, { useState, useEffect } from 'react';
import { MDBInput, MDBIcon, MDBRow, MDBCol } from 'mdbreact';
import Select from 'react-select';

import AttributeView from './AttributeView';
import { Button,Typography } from '@material-ui/core';
import QuestionGRAPHQL from '../../graphql/Question';
import SelfAssessmentGRAPHQL from '../../graphql/SelfAssessment';
import CaseAssessmentGRAPHQL from '../../graphql/CaseAssessment';
import Constant from '../../utils/Constant';
import DatePicker from 'react-datepicker';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { useTracking } from 'react-tracking';
import eventName from '../../common/events';
import ReminderDiv from './ReminderDiv';
import Add from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBack from '@material-ui/icons/ArrowBack'



const evaluationListEnhance = {
  fully_compliance: {
    overview: '',
    stating: '',
    policy: '',
    content: '',
    handling: ''
  },
  partially_compliance: {
    overview: '',
    stating: '',
    policy: '',
    content: '',
    handling: ''
  },
  none_compliance: {
    overview: '',
    stating: '',
    policy: '',
    content: '',
    handling: ''
  },
  not_acceptible: {
    overview: '',
    stating: '',
    policy: '',
    content: '',
    handling: ''
  }

};
export const conclusionSections = [
  {
    id: 'bcf19bcc-8b73-4c2b-bbfc-47da1ad9397c',
    name: 'overview',
    i18n: 'conclusion_overview'
  },
  {
    id: 'ff47191d-e5ee-4faf-ae80-49a56ca311bc',
    name: 'stating',
    i18n: 'conclusion_stating'
  },
  {
    id: '551396b3-0674-400c-b135-8c471e3e3044',
    name: 'policy',
    i18n: 'conclusion_policy'
  },
  {
    id: 'a6929b50-25a8-4106-b7c5-4dedeccd741d',
    name: 'content',
    i18n: 'conclusion_content'
  },
  {
    id: 'a84859a4-37e3-4d22-ada7-4010b4eca268',
    name: 'handling',
    i18n: 'conclusion_handling'
  }
];
export const evaluationList = Object.keys(evaluationListEnhance);

const listDateRedoTaskOption = [
  {value: '14', label:'2 tuần'},
  {value: '30', label:'1 tháng'},
  {value: '90', label:'3 tháng'},
  {value: '180', label:'6 tháng'},
  {value: null, label: 'Tuỳ chỉnh'}
]

const listDateReminderOption = [
  {value: '1', label:'Trước 1 ngày'},
  {value: '3', label:'Trước 3 ngày'},
  {value: '5', label:'Trước 5 ngày'},
  {value: '7', label:'Trước 7 ngày'},
]

const ItemPreviewAdminSelfAssessment = React.memo(function ItemPreviewAdminSelfAssessment(props) {
  const { trackEvent } = useTracking();
  const { taskShareByCompany, dataShare } = props;
  const [language, setLanguage] = useState();
  const [object, setObject] = useState({ company: null });
  const [dataWorker, setDataWorker] = useState([]);
  const [dataCompany, setDataCompany] = useState([]);
  const [dataAdvisor, setDataAdvisor] = useState([]);
  const [listAdvisors, setListAdvisors] = useState([])
  const [dataAnswer, setDataAnswer] = useState([]);
  const [dataConclusion, setDataConclusion] = useState({
    _id: '',
    overview: {
      evaluation: '',
      comment: ''
    },
    stating: {
      evaluation: '',
      comment: ''
    },
    policy: {
      evaluation: '',
      comment: ''
    },
    content: {
      evaluation: '',
      comment: ''
    },
    handling: {
      evaluation: '',
      comment: ''
    }
  });
  const [canChangeConclusion, setCanChangeConclusion] = useState(true)

  const [questionsByGroup, setQuestionsByGroup] = useState([]);
  const [benmarkResult, setBenmarkResult] = useState([]);
  const [dataReminder, setDataReminder] = useState({
    reminderDate: '' ,
    eventDate: new Date(moment(new Date()).add(listDateRedoTaskOption[0].value,'days')), 
    messageContent: '', 
    influencers: []})
  const [influencerCompany, setInfluencerCompany] = useState(null)
  const [taskReminders, setTaskReminders] = useState([])
  const [canAddReminder, setCanAddReminder] = useState(true)
  const [addingEditable, setAddingEditable] = useState(false);
  const [isNotValidNewReminder, setIsNotValidNewReminder] = useState(false)
  const [select, setSelect] = useState({redoDate: listDateRedoTaskOption[0], remindDate: listDateReminderOption[0]})
  /* const [minutesDelay, setminutesDelay] = useState(0) */

  useEffect(() => {
    setLanguage(props.language);
    setTimeout(() => {
      if (props.companyTaskId) {
        getDataFeedback(props.companyTaskId);
      } else {
        setDataCompany([]);
      }
      if (props.advisorTaskId) {
        getDataFeedbackCompare(props.advisorTaskId);
        getDataReminder()
      } else {
        setDataAdvisor([]);
        setListAdvisors([])
      }
    }, 100);
    // eslint-disable-next-line
  }, [props.language, props.selfAssessment, props.companyTaskId, props.advisorTaskId, props.type]);

  const getDataReminder = async()=>{
    const params = {taskId: props.advisorTaskId}
    const result = await window.COMMON.query(CaseAssessmentGRAPHQL.QUERY_REMINDERS_TASK, {input: params});
    if (result?.data){
      let countSchedule = 0
      const formatData =  window.COMMON.formatMultiDate(result.data.queryRemindersTask, 'createdDate,reminderDate');
      formatData.forEach(task=>{
        task.assignUsers = task.assignUsers.map(advisor=>({value: advisor._id, label: advisor.name}))
        if (task.status === 'SCHEDULE'){
          countSchedule++
        }
      })
      if (countSchedule >=2){
        setCanAddReminder(false)
      } else{
        setCanAddReminder(true)
      }
      //can check them da co conclusion chua
      setTaskReminders(formatData)
    }
    //set new Reminder

  }
  
  const handleChange = (name, value, row, col) => {
    //change data compare
    dataAdvisor[row].feedback[col][name] = value;
    setDataAdvisor([ ...dataAdvisor ]);
    //change data company
    dataCompany[row].feedback[col][name] = value;
    setDataCompany([ ...dataCompany ]);
    // if (props.handleChange) {
    //   props.handleChange('company', dataCompany);
    //   props.handleChange('advisor', dataAdvisor);
    // }
  };

  const handleCommentChange = (commentFieldName, value) => {
    const newDataConclusion = {
      ...dataConclusion,
      [commentFieldName]: {
        ...dataConclusion[commentFieldName],
        comment: value
      }
    };
    setDataConclusion(newDataConclusion);

    if (props.handleChangeConclusion) {
      props.handleChangeConclusion(newDataConclusion);
    }
  };
  const handleEvaluationChange = (value) => {
    const newDataConclusion = {
      ...dataConclusion,
      overview: {
        comment: evaluationListEnhance[value].overview,
        evaluation: value
      },
      stating: {
        comment: evaluationListEnhance[value].stating,
        evaluation: value
      },
      policy: {
        comment: evaluationListEnhance[value].policy,
        evaluation: value
      },
      content: {
        comment: evaluationListEnhance[value].content,
        evaluation: value
      },
      handling: {
        comment: evaluationListEnhance[value].handling,
        evaluation: value
      }
    };
    setDataConclusion(newDataConclusion);
    if (props.handleChangeConclusion) {
      props.handleChangeConclusion(newDataConclusion);
    }
  };

  const convertToDate = (dateString) => {
  	//  Convert a "dd/MM/yyyy" string into a Date object
  	const d = dateString.split('/');
  	const result = new Date(d[2] + '/' + d[1] + '/' + d[0]);
  	return result;     
  };

  const mapGroupIdToTaskShare = (shareList) => {
    const result = [];
    if (shareList?.length) {
      shareList.forEach(i => {
        const feedbackCount = i.feedback?.length;
        const matchGroup = questionsByGroup.find(i => i?.questions?.length === feedbackCount);
        if (matchGroup) {
          i.group = matchGroup.group;
          result.push(i);
        }
      });
    }
    return result;
  };

  const getWorkerFeedback = async (selfAssessment, companyId) => {
    try {
	  const res = [];
      await Promise.all(selfAssessment.groups?.map(async (item) => {
        const params = {
          input: {
            group: item._id,
            companyIds: [companyId],
            startDate: window.COMMON_DATE.getStrDateFilter(convertToDate(selfAssessment.startDate), -1),
            endDate: window.COMMON_DATE.getStrDateFilter(convertToDate(selfAssessment.endDate), 1)
          }
        };
        const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_EXPORT_HISTORY_ANSWER, params);
        if (result.data?.getHistoryAnswer?.data) {
          result.data.getHistoryAnswer.data.map(d => {
            return res.push({			
              group: d.group,
              feedback: d.feedback
            });
		  });
        }
      }));
      setDataWorker(res);
    } catch (error) {
      window.COMMON.showErrorLogs('ItemPreviewAdminSelfAssessment.getDataFeedback');
    }
  };

  const getCaseConclusion = async (companyTaskId) => {
    if (companyTaskId) {
      const result = await window.COMMON.query(CaseAssessmentGRAPHQL.QUERY_ASSESSMENT_CONCLUSION_BY_COMPANY_TASK_ID, { companyTask: companyTaskId });
      let newDataConclusion = {
        ...dataConclusion
      };
      if (result?.data?.getAssessmentConclusionByCompanyTaskId) {
        const caseConclusionData = result.data.getAssessmentConclusionByCompanyTaskId;
        newDataConclusion = {
          _id: caseConclusionData._id,
          overview: caseConclusionData.overview,
          stating: caseConclusionData.stating,
          policy: caseConclusionData.policy,
          content: caseConclusionData.content,
          handling: caseConclusionData.handling
        };
        setCanChangeConclusion(false)
      } else {
        newDataConclusion = {
          _id: '',
          overview: {
            evaluation: '',
            comment: ''
          },
          stating: {
            evaluation: '',
            comment: ''
          },
          policy: {
            evaluation: '',
            comment: ''
          },
          content: {
            evaluation: '',
            comment: ''
          },
          handling: {
            evaluation: '',
            comment: ''
          }
        };
        setCanChangeConclusion(true)
      }
      setDataConclusion(newDataConclusion);
      if (props.handleChangeConclusion) {
        props.handleChangeConclusion(newDataConclusion);
      }
    }
  };

  const getDataFeedback = async (id) => {
    try {
      const params = {
        id
      };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_FIND_TASK_SELF_ASSESSMENT_BY_ID, params);
      if (result && result.data && result.data.findTaskSelfAssessment) {
        const obj = result.data.findTaskSelfAssessment;
        await getDataAnswers(obj.items || []);
        object.company = window.COMMON.getValueFromAttr(obj, 'company', null);
        setObject(object);
        if (props.selfAssessment) {
          getWorkerFeedback(props.selfAssessment, object.company?._id);
        }
       
      }
      await getCaseConclusion(id);
    } catch (error) {
      window.COMMON.showErrorLogs('ItemPreviewAdminSelfAssessment.getDataFeedback');
    }
  };

  const getDataFeedbackCompare = async (id) => {
    try {
      const params = {
        id
      };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_FIND_TASK_SELF_ASSESSMENT_BY_ID, params);
      if (result && result.data && result.data.findTaskSelfAssessment) {
        const obj = result.data.findTaskSelfAssessment;
        setDataAdvisor(obj.items || []);
        const formatListAdvisors = window.COMMON.createDataSelect(obj.influencers, '_id', 'name').map(el=>({label: el?.label, value: el?.value})) || []
        setListAdvisors(formatListAdvisors);
        if (obj.influencers?.length){
          setInfluencerCompany(obj.influencers[0].influencer?._id)
        }

        if (props.handleChange) {
          props.handleChange('advisor', obj.items ?? []);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('ItemPreviewAdminSelfAssessment.getDataFeedbackCompare');
    }
  };
  useEffect(()=>{
    if (props.tabView ===3 ){
      resetReminderState()
    }
  },[props.tabView])

  const resetReminderState =()=>{
    const companyName =  object.company.information.name
    let reminderMessage = 'Yêu cầu thực hiện lại tự đánh giá '
      reminderMessage += `"${props.selfAssessment.name}" dành cho công ty ${companyName}.\n`
      if (dataAdvisor.length){
        reminderMessage += 'Các khảo sát cần làm lại:  '
        dataAdvisor.forEach( (groupQA, index) => {
          reminderMessage+= `"${groupQA?.group?.name[0]?.text}"`
          if (index !== dataAdvisor.length-1){
            reminderMessage += ', '
          } else {
            reminderMessage+= '.\n'
          }
        })
      }
      dataReminder.eventDate = new Date(moment(new Date()).add(listDateRedoTaskOption[0].value,'days'))
      dataReminder.messageContent = reminderMessage
      dataReminder.influencers = listAdvisors
      setSelect({redoDate: listDateRedoTaskOption[0], remindDate: listDateReminderOption[0]})
      setAddingEditable(false)
      setIsNotValidNewReminder(false)
      setDataReminder({...dataReminder})
  }
  const getDataAnswers = async (data) => {
    try {
      const groupIds = [];
      data.forEach(item => {
        groupIds.push(item.group._id);
      });
      const params = {
        groupIds
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_ANSWER_QUESTION_BY_GROUP, params);
      if (result && result.data && result.data.getAnswerQuestionsByGroup) {
        setDataAnswer(result.data.getAnswerQuestionsByGroup);
        setDataCompany(data);
        if (props.handleChange) {
          props.handleChange('company', data);
        }
      }
      
      const queryQuestionResponse = await window.COMMON.query(QuestionGRAPHQL.QUERY_QUESTION_BY_GROUP, params);
      if (queryQuestionResponse && queryQuestionResponse.data && queryQuestionResponse.data.getQuestionsByGroup) {
        const groupQuestion = queryQuestionResponse.data.getQuestionsByGroup.reduce((acc, curr) => {
          const key = curr.group;
          if (acc[key]) {
            acc[key].questions.push(curr.question);
          } else {
            acc[key] = {
              group: {_id: key},
              questions: [curr.question]
            };
          }
          return acc;
        }, {});
        setQuestionsByGroup(Object.values(groupQuestion));
      }
    } catch (error) {
      window.COMMON.showErrorLogs('ItemPreviewAdminSelfAssessment.getDataFeedback');
    }
  };

  const getAnswerQuantity = (data, groupId, questionId, answerId, influencerId) => {
    if (data.length > 0) {
      let count = 0;
      data.filter(item => item.group?._id === groupId && influencerId ? item.influencer._id === influencerId : true).map(d => {
        //find by question id and answer id
        const result = d?.feedback?.find(f => f.question?._id === questionId && f.answers?.findIndex(a => a._id === answerId) > -1 );
        if (result) count += 1;
      });
	  return count;
    };
    return 0;
  };

  const getAnswerText = (feedbacks, questionId) => {
    if (feedbacks && Array.isArray(feedbacks)) {
	  const feedback = feedbacks.find(item => item.question?._id === questionId);
	  if (feedback) return feedback.answerText; 
    }
    return '';
  }; 
  
  const shareItem = dataShare.reduce((acc, curr) => [...acc, ...curr?.influencers || []], []);
  const generateAnswer = (item, question, groupId) => {
    const answers = JSON.parse(JSON.stringify(window.COMMON.getArrsInArrs(question._id, dataAnswer, 'question')));
    const remainingHeadWidth = 100 - 50 - shareItem.length * 12.5;
    const answerTextHeadWidth = 100 / ( 3 + shareItem.length);
    const dataAssessor = mapGroupIdToTaskShare(taskShareByCompany);
    if (item) {
      answers.forEach(answer => {
        const obj = window.COMMON.getObjectInArrs(answer._id, item.answers, '_id');
        if (obj) {
          answer.selected = true;
        } else {
          answer.selected = false;
        }
      });
    }
    const type = question.type;
    if (type === 'TEXT') {
    //   return <p>{window.COMMON.getValueFromAttr(item, 'answerText')}</p>;
      return <table className="table-view">
        <thead>
          <tr>
            <th width={`${answerTextHeadWidth}%`} >{window.I18N('worker')}</th>
            <th width={`${answerTextHeadWidth}%`} >{window.I18N('company')}</th>
            <th width={`${answerTextHeadWidth}%`} >{window.I18N('advisor')}</th>
            {shareItem.map(i => (
              <th key={`text_answer_${i.name}`} width={`${answerTextHeadWidth}%`} >{i.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{} } className='grey-ic text-justify '>
              {dataWorker.filter(item => item.group?._id === groupId).map((d, dIndex) => (
                <li key={dIndex}>{getAnswerText(d.feedback, question._id)}</li>
              ))}
            </td>
            <td className='grey-ic text-justify '>
              {dataCompany.filter(item => item.group?._id === groupId).map((d, dIndex) => (
                <li key={dIndex}>{getAnswerText(d.feedback, question._id)}</li>
              ))}
            </td>
            <td className='grey-ic text-justify '>
              {dataAdvisor.filter(item => item.group?._id === groupId).map((d, dIndex) => (
                <li key={dIndex}>{getAnswerText(d.feedback, question._id)}</li>
              ))}
            </td>
            {shareItem.map((i, idx) => (
              <td key={`assessor_${item._id}_${idx}`} className="grey-ic text-justify">
                {dataAssessor.filter(item => item.group?._id === groupId).map((d, dIndex) => (
                  <li key={dIndex}>{getAnswerText(d.feedback, question._id)}</li>
                ))}
              </td>
            ))}
          </tr>
        </tbody>
      </table>;
    } else {
      return (
        <table className="table-view">
          <thead>
            <tr>
              <th width={`${remainingHeadWidth}%`}>{window.I18N('answer')}</th>
              <th width="12.5%" className="text-center">{window.I18N('worker')}</th>
              <th width="12.5%" className="text-center">{window.I18N('company')}</th>
              <th width="12.5%" className="text-center">{window.I18N('advisor')}</th>
              {shareItem.map(i => (
                <th key={i.name} width="12.5%" className="text-center">{i.name}</th>
              ))}
            </tr>
          </thead>
		  {(type === 'CHECKBOX_GRID' || type === 'RADIO_GRID') ? (
            <tbody>
              {
                answers.filter(item => window.COMMON.getValueFromAttr(item, 'parentCode', null) === null).map((item, i) => 
                  <React.Fragment key={item._id}>
                    <tr className="parent">
                      <td>{window.COMMON.getValueWithLanguage(item, 'content', language)}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {shareItem?.length?<td></td>:null}
                    </tr>
				  {
                      window.COMMON.getArrsInArrs(item.code, answers, 'parentCode').map((child, i) => <tr key={child._id}>
                        <td className='pl-4'>{window.COMMON.getValueWithLanguage(child, 'content', language)}</td>
                        <td className="text-center">{getAnswerQuantity(dataWorker, groupId, question._id, child._id)}</td>
                        <td className="text-center">{getAnswerQuantity(dataCompany, groupId, question._id, child._id)}</td>
                        <td className="text-center">{getAnswerQuantity(dataAdvisor, groupId, question._id, child._id)}</td>
                        {shareItem.map((i, idx) => (
                          <td key={`assessor_${item._id}_${idx}`} className="text-center">{getAnswerQuantity(dataAssessor, groupId, question._id, child._id, i._id)}</td>
                        ))}
                      </tr>)
                    }
                  </React.Fragment>
                )
              }
            </tbody>
		  ) : (
            <tbody>
              {
                answers.map((item, i) => <tr key={item._id}>
                  <td>{window.COMMON.getValueWithLanguage(item, 'content', language)}</td>
                  <td className="text-center">{getAnswerQuantity(dataWorker, groupId, question._id, item._id)}</td>
                  <td className="text-center">{getAnswerQuantity(dataCompany, groupId, question._id, item._id)}</td>
                  <td className="text-center">{getAnswerQuantity(dataAdvisor, groupId, question._id, item._id)}</td>
                  {shareItem.map((i, idx) => (
                    <td key={`assessor_${item._id}_${idx}`} className="text-center">{getAnswerQuantity(dataAssessor, groupId, question._id, item._id, i._id)}</td>
                  ))}
                </tr>)
              } 
            </tbody>
		  )}
	  </table>
	  );
    }
  };

  const generateAnswerLayout = (child) => {
    return <>
      <p className="mb-1 mt-1"><small className="text-muted">* {window.I18N('note')}: <i dangerouslySetInnerHTML={{__html: window.COMMON.getValueFromAttr(child, 'note', '').replace(/\n/g, '<br>')}}></i></small></p>
      {
        window.COMMON.getValueFromAttr(child, 'documents', []).map((item) => <p key={item.id} className="mb-1 mt-1" style={{ cursor: 'pointer', textDecoration: 'underline' }} onDoubleClick={() => window.COMMON.downloadFile(item.id, item.name)}>
          <MDBIcon fa="true" icon="paperclip" className="fa-fw mr-1"></MDBIcon>
          <small className="text-primary">{item.name}</small>
        </p>)
      }
	    { 
        props.isInfluencer ? <></> : <>
          <p className="mb-1"><small className="text-muted">* {window.I18N('consultant_evaluation')}: <i className="text-danger" dangerouslySetInnerHTML={{__html: window.COMMON.getValueFromAttr(child, 'consultantEvaluation', '').replace(/\n/g, '<br>')}}></i></small></p>
          <p className="mb-3"><small className="text-muted">* {window.I18N('consultant_note')}: <i className="text-danger" dangerouslySetInnerHTML={{__html: window.COMMON.getValueFromAttr(child, 'consultantNote', '').replace(/\n/g, '<br>')}}></i></small></p>
        </>
      }
    </>;
  };

  useEffect(()=>{
    const dataFeedbackCompare = []
    if (dataCompany.length && dataAdvisor.length){
      dataCompany.forEach(groupFeedback=>{
        groupFeedback.feedback.forEach(questionFeedback => {
          if (questionFeedback.question.type !== 'TEXT'){
            let formatFeedback = {id: questionFeedback.question._id}
            formatFeedback.answerIds = dataAnswer.filter(answer => answer.question === formatFeedback.id).map(el=>el._id)
            formatFeedback.company = new Array(formatFeedback.answerIds.length).fill(0)
            formatFeedback.advisor = new Array(formatFeedback.answerIds.length).fill(0)
            questionFeedback.answers.forEach(answer => {
              let indexOfCompanyFeedbackAnswer = formatFeedback.answerIds.indexOf(answer._id)
              if (indexOfCompanyFeedbackAnswer !== -1) {
                formatFeedback.company[indexOfCompanyFeedbackAnswer] = 1
              }
            })
            dataFeedbackCompare.push(formatFeedback)
          }
        })
      })

      dataAdvisor.forEach(groupFeedback => {
        groupFeedback.feedback.forEach(questionFeedback => {
          let indexOfQuestionCompareData = dataFeedbackCompare.findIndex(el => el.id === questionFeedback.question._id)
          if (indexOfQuestionCompareData !== -1){
            questionFeedback.answers.forEach(answer => {
              let indexOfCompanyFeedbackAnswer = dataFeedbackCompare[indexOfQuestionCompareData].answerIds.indexOf(answer._id)
              if (indexOfCompanyFeedbackAnswer !== -1){
                dataFeedbackCompare[indexOfQuestionCompareData].advisor[indexOfCompanyFeedbackAnswer] = 1
              }
            })
          }
          
        })
      })
      const benchMarksResult = window.COMMON.calculateBenchmark(dataFeedbackCompare,'company', 'advisor')
      setBenmarkResult(benchMarksResult)
    }
  },[dataCompany, dataAdvisor])

  const sortQuestion = (questionA, questionB)=>{
    let sortType = props.filter?.sortFeedback?.value
    
    let pointA = benmarkResult.find(el=>el.id===questionA.question._id)?.point
    let pointB = benmarkResult.find(el=>el.id===questionB.question._id)?.point
    if (questionA.question.type === 'TEXT'){pointA= -1*sortType* 999}
    if (questionB.question.type === 'TEXT'){pointB= -1*sortType* 999}

    if (!sortType){
      return 0
    }else if (pointA < pointB){
      return  sortType
    } else if (pointA > pointB){
      return  -1*sortType
    } else {
      return 0
    }
  }

  const filterQuestion = (question) => {
    const filterText = props.filter?.text
    const filterBenchmark = props.filter?.benchmark?.value
    const type = benmarkResult.find(el=>el.id===question.question._id)?.type
    const textQuestion = window.COMMON.getValueWithLanguage(question, 'question.question', language)

    if (filterBenchmark && Constant.GROUP_DIFFERENCE_FEEDBACK[type]!==filterBenchmark){
      return false
    } 
    if (filterText && !textQuestion.toLowerCase().includes(filterText.toLowerCase())){
      return false
    }
    return true
  }

  const handleDataReminder = (event, attr)=>{
    dataReminder[attr] = event;
    if (attr==='influencers'){
      if (event?.length ===0 || !event){
        setIsNotValidNewReminder(true)
      } else {
        setIsNotValidNewReminder(false)
      }
      
    } else if(attr==='messageContent'){
      if (event ==='') {
        setIsNotValidNewReminder(true)
      }else{
        setIsNotValidNewReminder(false)
      }
    } else if(attr==='eventDate'){
      if (!moment(event).isValid || moment(event)< moment(new Date())) {
        setIsNotValidNewReminder(true)
      } else {
        setIsNotValidNewReminder(false)
      }
    }
    setDataReminder({ ...dataReminder });
  }

  const handleSelectDate = (event, attr) =>{
    select[attr] = event
    if (attr === 'redoDate'){
      if (select.redoDate.value){
        setIsNotValidNewReminder(false)
        dataReminder.eventDate = new Date(moment(new Date()).add(select.redoDate.value,'days'))
      } else {
        setIsNotValidNewReminder(true)
        dataReminder.eventDate = ''
      }
      setDataReminder({...dataReminder})
    }
    setSelect({...select})
  }

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };
  const handleAddReminder = async () => {
    if (addingEditable){
      const reminderInput = {}
      reminderInput.assignUsers = dataReminder.influencers.map(el=>el.value)
      reminderInput.eventDate =  moment(dataReminder.eventDate).format('LL')
      reminderInput.reminderDate = moment(dataReminder.eventDate).add(-1*select.remindDate.value, 'days').set({hour:8 ,minute:0, second:0}).toLocaleString()
      reminderInput.task = props.advisorTaskId
      reminderInput.messageContent = dataReminder.messageContent
      reminderInput.company = influencerCompany
      const result = await window.COMMON.mutation(CaseAssessmentGRAPHQL.MUTATION_ADD_REMINDER_TASK, { reminderInput });
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
        resetReminderState()
        getDataReminder();
        createTrackingEvent(eventName.ADD_REMIND_TASK);
      }
    } else {
      setAddingEditable(true)
    }
    
    //const listAssessmentInfluuencer = dataSelfAssessmentInfluencer.filter(assessmentInfluencer => assessmentInfluencer.companies.map(company=>company._id).includes(object.company))
    //get advisors of the last AssessmentInfluencer assigh for company
    //const listAdvisor = listAssessmentInfluuencer[listAssessmentInfluuencer.length-1].influencers 
    
  }

  const handleEditReminder = async(paramReminder) =>{
    const reminderInput = paramReminder
    const result = await window.COMMON.mutation(CaseAssessmentGRAPHQL.MUTATION_UPDATE_REMINDER_TASK, { reminderInput });    
    if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
      getDataReminder();
      createTrackingEvent(eventName.UPDATE_REMIND_TASK);
    }
  }

  const handleCancelReminder = async(paramId) =>{
    const result = await window.COMMON.mutation(CaseAssessmentGRAPHQL.MUTATION_CANCEL_REMINDER_TASK, {reminderId: paramId} );
    if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
      getDataReminder();
      createTrackingEvent(eventName.CANCEL_REMIND_TASK);
    }
  }


  return <div className="wow fadeIn animated" data-wow-delay="0.5s">
    {props.tabView===2&&<AttributeView data={props.dataAttributeReport} company={props.company || object.company} dataCompany={props.dataCompany} isView={true}/>}
    
    {props.tabView===0&&<MDBRow>
      <MDBCol>
        {
          dataCompany.map((item, i) => <div key={i} className="container-preview-group-self-assessment p-3">
            <p className="font-weight-bold h6 label-warning p-2">{window.COMMON.getValueWithLanguage(item, 'group.name', language)}</p>
            {window.COMMON.getValueFromAttr(item, 'feedback', []).sort(sortQuestion).filter(filterQuestion).map((child, j) => 
              <div className="item" key={child.question._id}>
                <MDBRow className='d-flex justify-content-between mb-2 font-weight-bold alert alert-info'>
                  <div>{(j + 1) + '. ' + window.COMMON.getValueWithLanguage(child, 'question.question', language)}</div>
                  {props.filter?.sortFeedback?.value && <div>{benmarkResult.find(el=>el.id===child.question._id)?.point}</div>}
                </MDBRow>
                { generateAnswer(child, child.question, item.group?._id) }
                <MDBRow className='my-3 w-100 mx-0'>
                  {child.question.type==='TEXT'?<MDBCol><hr className="vertical light"></hr> </MDBCol>:null}
                  <MDBCol>
                    {child.question.type!=='TEXT'?<div className='font-weight-bold'>{window.I18N('company')}</div>:null}
                    { generateAnswerLayout(child, child.question, i, j, props.type === 0) }
                    <hr className="vertical light"></hr>
                  </MDBCol>
                  <MDBCol>
                    {child.question.type!=='TEXT'?<div className='font-weight-bold'>{window.I18N('advisor')}</div>:null}
                    {generateAnswerLayout(window.COMMON.getValueFromAttr(dataAdvisor, i + '.feedback.' + j, null), child.question, i, j, props.type === 1)}
                    <hr className="vertical light"></hr>
                  </MDBCol>
                  {shareItem?.map((i, idx) => (
                    <MDBCol key={`assessor_note_${idx}`}>
                      {child.question.type!=='TEXT'?<div className='font-weight-bold'>{i.name}</div>:null}
                      { generateAnswerLayout(window.COMMON.getValueFromAttr(mapGroupIdToTaskShare(taskShareByCompany), idx + '.feedback.' + j, null), child.question, i, j, props.type === 1) }
                    </MDBCol>
                  ))}
                </MDBRow>
                {/* {props.isInfluencer && (
                  <>
                    <div className='table-bordered mt-3'>
                      <div className='font-weight-bold ml-3 my-2'>{window.I18N('admin_evaluation')}</div>
                      {evaluationList.map((item, index) => (
                        <MDBInput 
                          gap key={'evaluation-' + child?.question?._id + '-' +index} 
                          id={'evaluation-' + child?.question?._id + '-' +index} type="radio" 
                          containerClass="pl-0 pb-1 mr-4" 
                          label={window.I18N(item)} 
                          value={item}
                          name={`consultantEvaluation-${i}-${j}`}
                          onChange={(event) => handleChange('consultantEvaluation',event.target.value, i, j)} 
                          checked={window.COMMON.getValueFromAttr(child, 'consultantEvaluation') === item} 
                          disabled={false}
                        />
                      ))}
                    </div>
                    <MDBInput 
                      outline
                      label={window.I18N('consultant_note')} 
                      name="consultantNote" 
                      value={window.COMMON.getValueFromAttr(child, 'consultantNote')} 
                      onChange={(event) => handleChange(event.target.name, event.target.value, i, j)} 
                      maxLength="5000"
                    />
                  </>
                )} */}
              </div>)
            }
            {window.COMMON.getValueFromAttr(item, 'feedback', []).filter(filterQuestion).length===0 && <div style={{height:'160px'}}>
                {window.I18N('empty_data')}
              </div>}
          </div>)
        }
      </MDBCol>
    </MDBRow>}
    {/* Conclusion sections */}
    {props.tabView===1&&<MDBRow className="mt-2">
      <MDBCol>
        <p className="font-weight-bold h6">IV. {window.I18N('final_conclusion')}</p>
        <div className='table-bordered mt-3'>
          <div className='font-weight-bold ml-3 my-2'>{window.I18N('admin_evaluation')}</div>
          <div className='ml-3 mb-3'>
            <Select
              isDisabled={!canChangeConclusion}
              options={Object.keys(evaluationListEnhance).map(evaluation => ({ value: evaluation, label: window.I18N(evaluation) }))} 
              value={{value: dataConclusion.overview.evaluation , label: window.I18N(dataConclusion.overview.evaluation)}} 
              onChange={newValue => handleEvaluationChange( newValue.value)} 
            />
          </div>
        </div>
        {conclusionSections.map((item, idx) => (
          <div className="mt-2" key={item.id}>
            <p className="font-weight-bold">{idx+1}. {window.I18N(item.i18n)}</p>
            
            <MDBInput 
              disabled={!canChangeConclusion}
              outline
              label={window.I18N('conclusion')} 
              name={item.name}
              type="textarea"
              value={dataConclusion[item.name]['comment']}
              onChange={(event) => handleCommentChange(item.name, event?.target?.value)}
              maxLength="5000"
            />
          </div>
        ))}
      </MDBCol>
    </MDBRow>}
    {/* Reminder sections*/}
    {props.tabView===3&& <div>
      {addingEditable && <>
        <Grid container spacing={2}>
          <Grid item xs={3} className='mr-2' >
            <Typography>{window.I18N("select_noti_date")}</Typography>
            <div className='input-group mt-1'>
            <Select
                    id="select-noti-date"
                    className="md-form m-0"
                    placeholder={window.I18N('select_noti_date') + ' *'}
                    value={select.remindDate}
                    options={listDateReminderOption}
                    //onChange={(event) => handleDataReminder(event, 'scheduleDaysFromNow')}
                    onChange= {(event)=> handleSelectDate(event, 'remindDate')}
                  />
            </div>
          </Grid>
          <Grid item xs={3} className='mr-2' >
            <Typography>{window.I18N("select_redo_date")}</Typography>
            <div className='input-group mt-1'>
            <Select
                    id="select-redo-date"
                    className="md-form m-0"
                    placeholder={window.I18N('select_redo_date') + ' *'}
                    value={select.redoDate}
                    options={listDateRedoTaskOption}
                    //onChange={(event) => handleDataReminder(event, 'scheduleDaysFromNow')}
                    onChange= {(event)=> handleSelectDate(event, 'redoDate')}
                  />
            </div>
          </Grid>
          <Grid item xs={3} >
            <Typography>{window.I18N('assign_influencer')}</Typography>
            <div className='input-group mt-1'>
              <Select
                    id="select-influencers"
                    className="md-form m-0"
                    placeholder={window.I18N('assign_influencer') + ' *'}
                    value={dataReminder.influencers}
                    options={listAdvisors}
                    onChange={(event) => handleDataReminder(event, 'influencers')}
                    isSearchable
                    isMulti
                    closeMenuOnSelect={false}
                  />
            </div>
          </Grid>
          
          {/* <Grid item>
            <div>add minute for test</div>
            <div className='input-group mt-1'>
              <MDBInput outline className="input-number ml-4" type='number' value={minutesDelay} onChange={(e)=>{setminutesDelay(e.target.value)}}></MDBInput>
            </div>
          </Grid> */}
        </Grid>
        <Grid container className='mt-2'>
          {/* <Grid item >
            <div>Ngày thông báo</div>
            <div className='input-group mt-1'>
              <div className="input-group-prepend ">
                <span className="input-group-text ">
                    <MDBIcon fa="true" icon="calendar-alt" className="fa-fw icon-sum" />
                </span>
              </div>
              {<DatePicker  className="form-control" dateFormat="dd/MM/yyyy" showYearDropdown showMonthDropdown selectsStart
                //minDate={new Date()}
                disabled
                selected={dataReminder.reminderDate}
              />}
            </div>
          </Grid> */}
          <Grid item >
            <Typography>{window.I18N('redo_date')}</Typography>
            <div className='input-group mt-1'>
              <div className="input-group-prepend ">
                <span className="input-group-text ">
                    <MDBIcon fa="true" icon="calendar-alt" className="fa-fw icon-sum" />
                </span>
              </div>
              {<DatePicker placeholderText={window.I18N('redo_date') + ' *'} className="form-control" dateFormat="dd/MM/yyyy" showYearDropdown showMonthDropdown selectsStart
                minDate={new Date(moment(new Date()).add(8,'days'))}
                maxDate={new Date(moment(new Date()).add(365,'days'))}
                selected={dataReminder.eventDate}
                disabled={!(select.redoDate.value === null)}
                onChange={select.redoDate.value === null ?(event) => handleDataReminder(event, 'eventDate'): ()=>{}}
              />}
            </div>
          </Grid>
        </Grid>
        <Grid container >
          <Grid item xs={12}>
            <MDBInput outline type="textarea" label={window.I18N('description')} value={dataReminder.messageContent} onChange={(event)=>handleDataReminder(event.target.value, 'messageContent')} maxLength="500" pattern="\S(.*\S)?" required>
              <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
            </MDBInput>
          </Grid>
        </Grid>
      </>}
      {canChangeConclusion&&<Grid container className='justify-content-center'>
        <Typography>{window.I18N('need_conclusion')}</Typography>
      </Grid>}
      <Grid container className='justify-content-end'>
        <Button 
          startIcon={addingEditable?<SaveIcon />:<Add/>}
          className='mr-2'
          variant="contained" color="primary"
          disabled={!props.advisorTaskId || !canAddReminder || canChangeConclusion || (addingEditable&&isNotValidNewReminder)} 
          onClick={!props.advisorTaskId || !canAddReminder || canChangeConclusion || (addingEditable&&isNotValidNewReminder) ? ()=>{}:handleAddReminder}>
            {addingEditable ? window.I18N("btn_save") : window.I18N("btn_add")}
        </Button>
        {addingEditable&&<Button variant="contained" color="secondary" onClick={resetReminderState} startIcon={<ArrowBack />}>{window.I18N('btn_back')}</Button>}
      </Grid>
      <div >
        <Typography variant="h6"  >{window.I18N('list_reminder')}</Typography>
        {taskReminders.length===0 && <Typography style={{height:`${addingEditable?'130px':'390px'}`}}>
                {window.I18N('empty_data')}
        </Typography>}
        {taskReminders.map((reminder)=>
        <div key={reminder._id} className='d-flex flex-column p-2 ml-2' > 
          <ReminderDiv canModify={true} listAdvisors={listAdvisors} reminder={reminder} handleEditReminder={handleEditReminder} handleCancelReminder={handleCancelReminder} ></ReminderDiv>
        </div>
        )}
      </div>
      </div>}
  </div>;
});

export default ItemPreviewAdminSelfAssessment;