
import React, { useState, useCallback, useLayoutEffect } from 'react';
import { MDBRow, MDBCol, MDBInput, MDBCard, MDBCardImage, MDBBtn, MDBIcon } from 'mdbreact';
import Select from 'react-select';

import Pagination from '../../components/Pagination';
import Modal from '../../components/Modal';

import MedicalGRAPHQL from '../../../graphql/Medical';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';

export default function RequestTestingPage(props) {
  const { trackEvent } = useTracking();

  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const testingType = [
    { value: 'RAPID', label: 'Rapid Test'},
    { value: 'PCR', label: 'RT-PCR test'}
  ];

  const [filter, setFilter] = useState({ text: '' });
  const [object, setObject] = useState({ isCheckboxed: false, isCheckAll: false, image: null });
  const [count, setCount] = useState({ employee: 0, request: 0 });
  const [pagination, setPagination] = useState({ total: 0, pageSize: 30, currentPage: 0 });
  const [data, setData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [options, setOptions] = useState({
    company: []
  });

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataCompany();
    }, 100);
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line
  }, []);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      document.getElementById('btn-search').click();
    }
  };

  // const handleFilterChange = (event) => {
  //   filter.text = event.target.value;
  //   setFilter({ ...filter });
  // };

  const handleFilterSelect = (event) => {
    filter.company = event;
    setFilter({ ...filter });
    getDataMedical();
  };

  const handleChange = (event, index) => {
    dataSearch[index].isChecked = event.target.checked;
    if (!event.target.checked) {
      object.isCheckboxed = false;
      object.isCheckAll = false;
      for (let i = 0; i < data.length; i++) {
        if (data[i].isChecked) {
          object.isCheckboxed = true;
          break;
        }
      }
    } else {
      object.isCheckboxed = true;
      object.isCheckAll = true;
      for (let i = 0; i < data.length; i++) {
        if (!data[i].isChecked) {
          object.isCheckAll = false;
          break;
        }
      }
    }
    setObject(object);
    setDataSearch([ ...dataSearch ]);
  };

  const handleCheckAll = (event) => {
    object.isCheckAll = event.target.checked;
    object.isCheckboxed = object.isCheckAll && dataSearch.length > 0;
    for (let i = 0; i < dataSearch.length; i++) {
      dataSearch[i].isChecked = object.isCheckAll;
    }
    setObject(object);
    setDataSearch([ ...dataSearch ]);
  };

  const getDataCompany = async () => {
    try {
      if (COMPANY_TYPE === window.CONSTANT.COMPANY || COMPANY_TYPE === window.CONSTANT.UNION  || COMPANY_TYPE === window.CONSTANT.INFLUENCER) {
        filter.company = { value: COMPANY_ID, label: '', code: COMPANY_TYPE };
        setFilter({ ...filter }); 
        getDataMedical();
        return;
      }
      await window.COMMON.getDataAllCompany();
      const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));
      if (dataCompany && dataCompany.length > 0) {
        options.company = window.COMMON.createDataSelectWithGroup(dataCompany, '_id', 'information.name', 'type', 'type');
      }
      filter.company = window.COMMON.getValueFromAttr(options.company, '0.options.0', null);  
      setOptions(options);
      setFilter({ ...filter });
      getDataMedical();
    } catch (error) {
      window.COMMON.showErrorLogs('RequestTestingPage.getDataCompany');
    }
  };

  const getDataMedical = async () => {
    try {
      const params = {
        company: filter.company.value,
        status: false,
        pageSize: pagination.pageSize,
        page: pagination.currentPage + 1
      };
      const employees = [];
      let data = [];
      const result = await window.COMMON.query(MedicalGRAPHQL.QUERY_MEDICAL, params);
      if (result && result.data && result.data.getMedical) {
        data = result.data.getMedical.data;
        data.forEach(item => {
          if (employees.indexOf(item.user._id) === -1) {
            employees.push(item.user._id);
          }
          const type = window.COMMON.getObjectInArrs(item.type, testingType, 'value');
          item.isChecked = false;
          item.createdDate = window.COMMON_DATE.formatDate(item.createdDate);
          item.birthday = window.COMMON_DATE.formatDate(item.user.birthday);
          item.testingDate = window.COMMON_DATE.formatDate(item.date);
          item.expiredDate = window.COMMON_DATE.formatDate(item.expiredDate);
          item.testingType = type ? type.label : '';
        });
        pagination.total = result.data.getMedical.total;
        count.request = pagination.total;
        count.employee = employees.length;
        object.isCheckboxed = false;
        object.isCheckAll = false;
      }
      setObject(object);
      setCount(count);
      setPagination(pagination);
      setData(data);
      searchData(data);
    } catch (error) {
      window.COMMON.showErrorLogs('RequestTestingPage.getDataMedical');
    }
  };

  const searchData = (data) => {
    let dataSearch = [];
    // if (filter.text) {
    //   data.forEach(item => {
    //     if (window.COMMON.getValueFromAttr(item, 'user.nickname').toLowerCase().indexOf(filter.text.toLowerCase()) > -1
    //     || window.COMMON.getValueFromAttr(item, 'vaccine').toString().toLowerCase().indexOf(filter.text.toLowerCase()) > -1 
    //     || window.COMMON.getValueFromAttr(item, 'vaccineTypeName').toLowerCase().indexOf(filter.text.toLowerCase()) > -1) {
    //       dataSearch.push(item);
    //     }
    //   });
    // } else {
    //   dataSearch = data;
    // }
    dataSearch = data;
    setDataSearch([ ...dataSearch ]);
  };

  const changePage = useCallback((index) => {
    pagination.currentPage = index;
    setPagination({ ...pagination });
    getDataMedical();
    // eslint-disable-next-line
  }, [object, pagination]);

  const openPreviewImage = (image) => {
    object.image = window.COMMON.setNewValueImage(image);
    setObject({ ...object });
    window.COMMON.showModal('#modal-preview');
  };

  const openApproveDataDialog = () => {
    window.COMMON.showModal('#modal-save');
    window.saveMethod = approveData;
  };

  const openRejectDataDialog = () => {
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = rejectData;
  };

  const getIds = () => {
    const ids = [];
    data.forEach(item => {
      if (item.isChecked) {
        ids.push(item._id);
      }
    });
    return ids;
  };

  const approveData = async () => {
    try {
      const params = { ids: getIds() };
      const result = await window.COMMON.mutation(MedicalGRAPHQL.MUTATION_APPROVE_MEDICAL, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_APPROVE)) {
        getDataMedical();
        createTrackingEvent(eventName.APPROVE_MEDICAL);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestTestingPage.approveData');
    }
  };

  const rejectData = async () => {
    try {
      const params = { ids: getIds() };
      const result = await window.COMMON.mutation(MedicalGRAPHQL.MUTATION_REJECT_MEDICAL, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DECLINE)) {
        getDataMedical();
        createTrackingEvent(eventName.REJECT_MEDICAL);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestTestingPage.rejectData');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <MDBCard className="mt-4 mb-2 wow fadeInDown animated" data-wow-delay="0.5s">
        <MDBRow>
          <MDBCol md="3" size="6">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage cascade waves src={window.CONSTANT.ICON_EMPLOYEE} className="icon mr-1"/>
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">{count.employee}</h5>
                <p className="text-muted mb-0">{window.I18N('employees')}</p>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="3" size="6">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage cascade waves src={window.CONSTANT.ICON_RESPONSE} className="icon mr-1"/>
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">{count.request}</h5>
                <p className="text-muted mb-0">{window.I18N('request')}</p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBCard>
      
      <MDBRow className="wow fadeIn animated" data-wow-delay="1s">
        <MDBCol>
          <ul className="list-group">
            <li className="list-group-item">
              <div className="d-flex align-items-center w-100">
                <MDBInput type="checkbox" id="checkbox-all" containerClass="pl-2 pt-2 mr-auto" filled checked={object.isCheckAll} onChange={handleCheckAll}/>
                {
                  !window.COMMON.checkRoleIsSystem() ? <div></div> : <div className="bd-highlight mr-2" style={{ width: '300px' }}>
                    <div className="input-group input-group-transparent">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <MDBIcon fa="true" icon="building" className="fa-fw" />
                        </span>
                      </div>
                      <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.company} options={options.company} onChange={(event) => handleFilterSelect(event, 'company')} isSearchable />
                    </div>
                  </div>
                }
                <MDBBtn color="danger" disabled={!object.isCheckboxed} onClick={!object.isCheckboxed ? () => {} : openRejectDataDialog}>
                  <MDBIcon fa="true" icon="ban" className="fa-fw"/> {window.I18N('btn_reject')}
                </MDBBtn>
                <MDBBtn color="primary"  className="mr-0" disabled={!object.isCheckboxed} onClick={!object.isCheckboxed ? () => {} : openApproveDataDialog}>
                  <MDBIcon fa="true" icon="check-circle" className="fa-fw"/> {window.I18N('btn_approve')}
                </MDBBtn>
              </div>
            </li>
            {
              dataSearch.map((item, i) => <li className="list-group-item" key={i}>
                <div className="d-flex align-items-center w-100">
                  <MDBInput type="checkbox" id={'checkbox-' + item._id} containerClass="pl-2 pt-2" filled checked={item.isChecked} onChange={(event) => handleChange(event, i)}/>
                  <div className="d-flex align-items-center w-100 pl-2 pr-2">
                    <div className="w-100 mr-auto">
                      <MDBRow>
                        <MDBCol>
                          <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, 'user.avatar'))} className="image-icon rounded-circle mr-2"/>
                          <span>{window.COMMON.getValueFromAttr(item, 'user.nickname')}</span>
                        </MDBCol>
                        <MDBCol>
                          <span><MDBIcon className="fa-fw mr-1" fa="true" icon="transgender-alt"></MDBIcon>{window.COMMON.getValueFromAttr(item, 'user.gender') === 0 ? window.I18N('female') : window.I18N('male')}</span><br></br>
                          <span><MDBIcon className="fa-fw mr-1" fa="true" icon="calendar"></MDBIcon>{window.COMMON.getValueFromAttr(item, 'birthday')}</span>
                        </MDBCol>
                        <MDBCol>
                          <div onClick={() => openPreviewImage(window.COMMON.getValueFromAttr(item, 'documents.0.id'))}>
                            <MDBCardImage zoom cascade waves src={window.COMMON.setNewValueImage(window.COMMON.getValueFromAttr(item, 'documents.0.id'))} className="image-icon"/>
                          </div>
                        </MDBCol>
                        <MDBCol>
                          <p className="mb-0">
                            {window.COMMON.getValueFromAttr(item, 'isPositive') ? <label className="font-weight-bold label-box label-danger p-1 m-0"><MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('positive')}</label> : <label className="font-weight-bold label-box label-success p-1 m-0"><MDBIcon fa="true" icon="minus-circle" className="fa-fw" /> {window.I18N('negative')}</label>}
                            <span> - {window.COMMON.getValueFromAttr(item, 'testingType')}</span></p>
                          <small className="mb-0 text-muted">{window.I18N('valid_date')}: {window.COMMON.getValueFromAttr(item, 'testingDate')} - {window.COMMON.getValueFromAttr(item, 'expiredDate')}</small>
                        </MDBCol>
                      </MDBRow>
                    </div>
                    <span className="text-muted"><small>{window.COMMON.getValueFromAttr(item, 'createdDate')}</small></span>
                  </div>
                </div>
              </li>)
            }
            <li className="list-group-item">
              <Pagination total={pagination.total} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={changePage}></Pagination>
            </li>
          </ul>
        </MDBCol>
      </MDBRow>

      <Modal id="modal-preview" title={window.I18N('preview')} className="modal-lg">
        <div className="text-center">
          <MDBCardImage className="image-preview" src={object.image} hover overlay="white-slight"/>
        </div>
      </Modal>
    </div>
  );
}