import gql from 'graphql-tag';

const QUERY_GROUP_QUESTION = gql`
  query($company: String, $startDate: String, $endDate: String) {
    getGroupQuestions(company: $company, startDate: $startDate, endDate: $endDate) {
      _id
      code
      name {
        code
        text
      }
      description {
        code
        text
      }
      company {
        _id
        information {
          avatar
          name
        }
      }
      type
      image
      hashtag
      countSchedule
      isShared
      isPublic
      createdDate
      createdBy {
        name
        avatar
      }
      standardOfConclusion {
        min
        max
      }
      finalConclusion {
        low {
          code
          text
        }
        medium {
          code
          text
        }
        high {
          code
          text
        }
      }
    }
  }
`;

const QUERY_ALL_GROUP_QUESTION = gql`
  query($company: String) {
    getAllGroupQuestions(company: $company) {
      _id
      code
      name {
        code
        text
      }
      description {
        code
        text
      }
      company {
        _id
        information {
          avatar
          name
        }
      }
      image
      hashtag
      isPublic
      countSchedule
      createdDate
      createdBy {
        name
      }
      type
      standardOfConclusion {
        min
        max
      }
      finalConclusion {
        low {
          text
          code
        }
        medium {
          text
          code
        }
        high {
          text
          code
        }
      }
    }
  }
`;

const QUERY_SHARED_GROUP_QUESTION = gql`
  query {
    getSharedGroupQuestions {
      _id
      name {
        code
        text
      }
      description {
        code
        text
      }
      image
      hashtag
      type
    }
  }
`;

const MUTATION_ADD_GROUP_QUESTION = gql`
  mutation($input: GroupQuestionInput!) {
    addGroupQuestion(input: $input)
  }
`;

const MUTATION_EDIT_GROUP_QUESTION = gql`
  mutation($input: GroupQuestionInput!) {
    editGroupQuestion(input: $input)
  }
`;

const MUTATION_DEL_GROUP_QUESTION = gql`
  mutation($id: String!) {
    deleteGroupQuestion(id: $id)
  }
`;

const MUTATION_ADD_QA = gql`
  mutation($input: QAInput!) {
    addQA(input: $input) 
  }
`;

const MUTATION_ADD_MULTI_QA = gql`
  mutation($input: [QAInput!]) {
    addMultiQA(input: $input) 
  }
`;

const MUTATION_EDIT_QA = gql`
  mutation($input: QAInput!) {
    updateQA(input: $input)
  }
`;

const MUTATION_DEL_QA = gql`
  mutation($id: String!) {
    deleteQA(id: $id)
  }
`;

const MUTATION_ADD_POOL_QUESTION = gql`
  mutation($input: QuestionTemplateAndAnswerInput!) {
    addPoolQuestion(input: $input)
  }
`;
const MUTATION_EDIT_POOL_QUESTION = gql`
  mutation($input: QuestionTemplateAndAnswerInput!) {
    updatePoolQuestion(input: $input)
  }
`;
const MUTATION_DEL_POOL_QUESTION = gql`
  mutation($id: String!) {
    deletePoolQuestion(id: $id)
  }
`;
const MUTATION_ADD_MULTI_POOL_QUESTION = gql`
  mutation($input: [QuestionTemplateAndAnswerInput!]) {
    addMultiPoolQuestion(input: $input)
  }
`;

const QUERY_QUESTION = gql`
  query($groupId: String!) {
    getQuestions(groupId: $groupId) {
      _id
      code
      type
      important
      standardLowOfCategory
      standardOfCategory
      introduction {
        code
        text
      }
      question {
        code
        text
      }
      category {
        code
        text
      }
      recommend {
        low {
          code
          text
        }
        medium {
          code
          text
        }
        high {
          code
          text
        }
      }
      createdDate
    }
  }
`;

const QUERY_QUESTION_BY_GROUP = gql`
  query($groupIds: [String!]) {
    getQuestionsByGroup(groupIds: $groupIds) {
      _id
      code
      type
      important
      group
      introduction {
        code
        text
      }
      question {
        code
        text
      }
    }
  }
`;

const QUERY_ANSWER_QUESTION = gql`
  query($groupId: String!) {
    getAnswerQuestions(groupId: $groupId) {
      _id
      code
      question
      group
      parentCode
      content {
        code
        text
      }
      score
    }
  }
`;

const QUERY_ANSWER_QUESTION_BY_GROUP = gql`
  query($groupIds: [String!]) {
    getAnswerQuestionsByGroup(groupIds: $groupIds) {
      _id
      code
      question
      parentCode
      content {
        code
        text
      }
    }
  }
`;

const MUTATION_CHECK_IMPORT_QUESTION = gql`
  mutation($input: [String!]) {
    checkImportQuestion(input: $input)
  }
`;

const MUTATION_CHECK_IMPORT_ANSWER_QUESTION = gql`
  mutation($input: [String!]) {
    checkImportAnswerQuestion(input: $input)
  }
`;

const MUTATION_IMPORT_QUESTION = gql`
  mutation($input: [QuestionInput!]) {
    importQuestion(input: $input)
  }
`;

const MUTATION_IMPORT_ANSWER_QUESTION = gql`
  mutation($input: [AnswerQuestionInput!]) {
    importAnswerQuestion(input: $input)
  }
`;

const QUERY_TASKS_BY_USER = gql`
  query($id: String!) {
    getAssignedTasks(id: $id) {
      _id
      group {
        _id
        code
        name {
          code
          text
        }
        description {
          code
          text
        }
        image
        hashtag
        type
      }
      users {
        _id
        avatar
        nickname
      }
      status
      createdDate
    }
  }
`;

const QUERY_FEEDBACK_BY_TASK = gql`
  query($task: String!) {
    findAnswerByTask(task: $task) {
      _id
      feedback {
        question {
          _id
          type
        }
        answers {
          _id
          parentCode
        }
        answerText
      }
    }
  }
`;

const MUTATION_GRANT_TASK = gql`
  mutation($id: String!, $users: [String!]) {
    assigneTasks(id: $id, users: $users)
  }
`;

const MUTATION_SEND_FEEDBACK = gql`
  mutation($input: AnswerInput!) {
    saveFeedback(input: $input)
  }
`;

const MUTATION_UPDATE_FEEDBACK_BY_TASK = gql`
  mutation($input: AnswerInput!) {
    updateFeedbackByTask(input: $input)
  }
`;

const QUERY_DATA_HISTORY_ANSWER = gql`
  query($input: HistoryAnswerFilter!) {
    getHistoryAnswer(input: $input) {
      total
      page
      pageSize
      data {
        _id
        company {
          _id
          information {
            name
          }
        }
        union {
          _id
          information {
            name
          }
        }
        influencer {
          _id
          information {
            name
          }
        }
        group {
          _id
          code
          name {
            code
            text
          }
        }
        createdDate
        updatedDate
        createdBy {
          _id
          avatar
          nickname
        }
      }
    }
  }
`;

const QUERY_EXPORT_HISTORY_ANSWER = gql`
  query($input: HistoryAnswerFilter!) {
    getHistoryAnswer(input: $input) {
      total
      page
      pageSize
      data {
        _id
        feedback {
          question {
            _id
            code
            type
            standardLowOfCategory
            standardOfCategory
            category {
              code
              text
            }
            recommend {
              low {
                code
                text
              }
              medium {
                code
                text
              }
              high {
                code
                text
              }
            }
            question {
              code
              text
            }
            createdDate
          }
          answers {
            _id
            code
            parentCode
            score
            content {
              code
              text
            }
          }
          answerText
        }
        company {
          _id
          information {
            name
          }
        }
        union {
          _id
          information {
            name
          }
        }
        influencer {
          _id
          information {
            name
          }
        }
        group {
          _id
          code
          type
          name {
            code
            text
          }
          standardOfConclusion {
            min
            max
          }
          finalConclusion {
            low {
              code
              text
            }
            medium {
              code
              text
            }
            high {
              code
              text
            }
          }
        }
        createdDate
        updatedDate
        createdBy {
          _id
          avatar
          nickname
        }
      }
    }
  }
`;

const QUERY_HISTORY_ANSWER_REPORT = gql`
  query($input: HistoryAnswerFilter!) {
    getHistoryAnswer(input: $input) {
      total
      page
      pageSize
      data {
        _id
        company {
          _id
        }
        union {
          _id
        }
        influencer {
          _id
        }
        feedback {
          question {
            _id
          }
          answers {
            _id
          }
          answerText
        }
        createdBy {
          _id
        }
      }
    }
  }
`;

const QUERY_COUNT_HISTORY_QA = gql`
  query($input: HistoryQAFilter!) {
    countHistoryQA(input: $input)
  }
`;

const QUERY_HISTORY_QA = gql`
  query($input: HistoryQAFilter!) {
    getHistoryQA(input: $input) {
      _id
      count
	  user {
		_id
		company {
		  _id
		}
		union {
		  _id
		}
		influencer {
		  _id
		}
	  }
    }
  }
`;

const QUERY_TOP_HISTORY_ANSWER = gql`
  query($input: HistoryAnswerFilter!) {
    topHistoryAnswer(input: $input) {
      _id
      count
    }
  }
`;

const QUERY_HISTORY_ANSWER_BY_GOVERMENT = gql`
  query($input: HistoryAnswerFilter!) {
    getHistoryAnswerByGoverment(input: $input) {
      _id
      count
    }
  }
`;

const QUERY_COUNT_GROUP_QUESTION = gql`
  query($input: GroupQuestionFilter!) {
    countGroupQuestion(input: $input)
  }
`;

const QUERY_GROUP_DATA_CHART = gql`
  query($input: GroupQuestionFilter!){
    groupDataChart(input: $input){
      type
      createdDate
    }
  }
`;

const QUERY_COUNT_TOTAL_QA = gql`
  query($input: GroupTotalQA){
    countTotalQA(input: $input)
  }
`;

const QUERY_QUESTION_CATEGORY = gql`
  query($company: String!, $companyType: String!) {
    getAllQuestionCategories(company: $company, companyType: $companyType) {
      _id
      title {
        code
        text
      }
      shareType
      shareOrganizations
      shareCompanies
    }
  }
`;

const QUERY_QUESTION_BANK = gql`
  query ($input: FilterQuestionParams) {
    getQuestionBank (input: $input) {
      data {
          _id
        code
        type
        choices {
          _id
          code
          question
          group
          parentCode
          content {
            code
            text
          }
          score
        }
        introduction
        question
        createdDate
        createdBy {
          _id
        }
        questionCategory{
          _id
          title {
            code
            text
          }
        }
      }
      total
      page
      pageSize
    }
  }
`
const QUERY_QUESTION_REPORT_BY_CODE = gql`
  query($code: String!) {
    getQuestionBankByCode(code: $code){
      _id
      code
      question
      introduction
      type
      createdDate
      createdBy{
        _id
        avatar
        nickname
      }
      questionCategory{
        _id
        title {
          code
          text
        }
      }
      numberOfCloneVersion
      cloneQuestion{
        _id
        question{
          code
          text
        }
        introduction{
          code
          text
        }
        choices{
          content{
            code
            text
          }
        }
        group{
          name{
            text
            code
          }
          type
        }
        type
        createdDate
        createdBy{
          name
          company{
            information{
              name
            }
          }
        }
      }
      questionFrequencyOfUse
      useForGroupQuestion
      useForSoftSkill
    }
  }
`

const QUERY_QUESTION_BANK_REPORT = gql`
  query($input: QuestionBankReportInput) {
    getQuestionBankReport(input: $input){
      data {
        _id
        code
        question
        introduction
        type
        createdDate
        createdBy{
          _id
          avatar
          nickname
        }
        questionCategory{
          _id
          title {
            code
            text
          }
        }
        numberOfCloneVersion
        cloneQuestion{
            _id
            question{
                code
                text
            }
            introduction{
                code
                text
            }
            choices{
                content{
                    code
                    text
                }
            }
            group{
                name{
                    text
                    code
                }
                type
            }
            type
            createdDate
            createdBy{
                name
                company{
                    information{
                        name
                    }
                }
            }
        }
        questionFrequencyOfUse
        useForGroupQuestion
        useForSoftSkill
      }
      total
      page
    }
  }
`

const MUTATION_ADD_CATEGORY_QUESTION = gql`
  mutation($input: QuestionCategoryInput!) {
    addQuestionCategory(input: $input)
  }
`;

const MUTATION_EDIT_CATEGORY_QUESTION = gql`
  mutation($input: QuestionCategoryInput!) {
    updateQuestionCategory(input: $input)
  }
`;

const MUTATION_DEL_CATEGORY_QUESTION = gql`
  mutation($id: String!) {
    deleteQuestionCategory(id: $id)
  }
`;

const QuestionGRAPHQL = {
  QUERY_GROUP_QUESTION,
  QUERY_ALL_GROUP_QUESTION,
  QUERY_SHARED_GROUP_QUESTION,
  MUTATION_ADD_GROUP_QUESTION,
  MUTATION_EDIT_GROUP_QUESTION,
  MUTATION_DEL_GROUP_QUESTION,
  QUERY_QUESTION,
  QUERY_QUESTION_BY_GROUP,
  QUERY_ANSWER_QUESTION,
  QUERY_ANSWER_QUESTION_BY_GROUP,
  MUTATION_ADD_QA,
  MUTATION_ADD_MULTI_QA,
  MUTATION_ADD_POOL_QUESTION,
  MUTATION_EDIT_POOL_QUESTION,
  MUTATION_DEL_POOL_QUESTION,
  MUTATION_ADD_MULTI_POOL_QUESTION,
  MUTATION_EDIT_QA,
  MUTATION_DEL_QA,
  MUTATION_CHECK_IMPORT_QUESTION,
  MUTATION_CHECK_IMPORT_ANSWER_QUESTION,
  MUTATION_IMPORT_QUESTION,
  MUTATION_IMPORT_ANSWER_QUESTION,
  QUERY_TASKS_BY_USER,
  QUERY_FEEDBACK_BY_TASK,
  MUTATION_GRANT_TASK,
  MUTATION_SEND_FEEDBACK,
  MUTATION_UPDATE_FEEDBACK_BY_TASK,
  QUERY_DATA_HISTORY_ANSWER,
  QUERY_EXPORT_HISTORY_ANSWER,
  QUERY_HISTORY_ANSWER_REPORT,
  QUERY_COUNT_HISTORY_QA,
  QUERY_HISTORY_QA,
  QUERY_TOP_HISTORY_ANSWER,
  QUERY_HISTORY_ANSWER_BY_GOVERMENT,
  QUERY_COUNT_GROUP_QUESTION,
  QUERY_GROUP_DATA_CHART,
  QUERY_COUNT_TOTAL_QA,
  QUERY_QUESTION_CATEGORY,
  QUERY_QUESTION_BANK,
  QUERY_QUESTION_BANK_REPORT,
  QUERY_QUESTION_REPORT_BY_CODE,
  MUTATION_ADD_CATEGORY_QUESTION,
  MUTATION_EDIT_CATEGORY_QUESTION,
  MUTATION_DEL_CATEGORY_QUESTION
};

export default QuestionGRAPHQL;