import { useRef, useEffect, useState } from 'react';
const useSearchDebounce = () => {
  const timeOutRef = useRef();

  const searchDebounce = (searchFunction, ms = 200) => {
    if (timeOutRef.current ) {
      clearTimeout(timeOutRef.current);
    }
    timeOutRef.current = setTimeout(searchFunction,ms);
  };

  return searchDebounce;
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
      const handler = setTimeout(() => {
          setDebouncedValue(value);
      }, delay);

      return () => clearTimeout(handler);
  }, [value]);

  return debouncedValue;
}

export default useSearchDebounce;