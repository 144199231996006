import gql from 'graphql-tag';

const QUERY_MEDICAL = gql`
  query($company: String!, $status: Boolean, $page: Int, $pageSize: Int) {
    getMedical(company: $company, status: $status, page: $page, pageSize: $pageSize) {
      total
      page
      pageSize
      data {
        _id
        content
        documents {
          id
          name
          ext
          type
          size
        }
        type
        user {
          _id
          avatar
          nickname
          email
          phone
          birthday
          gender
        }
        date
        expiredDate
        isPositive
        status
        createdDate
      }
    }
  }
`;

const QUERY_MEDICAL_BY_USER = gql`
  query($user: String!) {
    getMedicalByUser(user: $user) {
      _id
      content
      documents {
        id
        name
        ext
        type
        size
      }
      type
      user {
        _id
        avatar
        nickname
        email
        phone
        birthday
        gender
      }
      date
      expiredDate
      isPositive
      status
      createdDate
    }
  }
`;

const MUTATION_APPROVE_MEDICAL = gql`
  mutation($ids: [String!]) {
    approveMedical(ids: $ids)
  }
`;

const MUTATION_REJECT_MEDICAL = gql`
  mutation($ids: [String!]) {
    rejectMedical(ids: $ids)
  }
`;

const MUTATION_ADD_MEDICAL = gql`
  mutation($input: MedicalInput!) {
    addMedical(input: $input)
  }
`;

const MUTATION_DELETE_MEDICAL = gql`
  mutation($id: String!) {
    deleteMedical(id: $id)
  }
`;

const QUERY_VACCINE = gql`
  query($company: String!, $status: Boolean, $page: Int, $pageSize: Int) {
    getVaccine(company: $company, status: $status, page: $page, pageSize: $pageSize) {
      total
      page
      pageSize
      data {
        _id
        content
        documents {
          id
          name
          ext
          type
          size
        }
        vaccine
        vaccineType
        user {
          _id
          avatar
          nickname
          email
          phone
          birthday
          gender
        }
        date
        status
        createdDate
      }
    }
  }
`;

const QUERY_VACCINE_BY_USER = gql`
  query($user: String!) {
    getVaccineByUser(user: $user) {
      _id
      content
      documents {
        id
        name
        ext
        type
        size
      }
      vaccine
      vaccineType
      user {
        _id
        avatar
        nickname
        email
        phone
        birthday
        gender
      }
      date
      status
      createdDate
    }
  }
`;

const MUTATION_APPROVE_VACCINE = gql`
  mutation($ids: [String!]) {
    approveVaccine(ids: $ids)
  }
`;

const MUTATION_REJECT_VACCINE = gql`
  mutation($ids: [String!]) {
    rejectVaccine(ids: $ids)
  }
`;

const MUTATION_ADD_VACCINE = gql`
  mutation($input: VaccineInput!) {
    addVaccine(input: $input)
  }
`;

const MUTATION_DELELE_VACCINE = gql`
  mutation($id: String!) {
    deleteVaccine(id: $id)
  }
`;

const QUERY_CHECK_QR_CODE = gql`
  query($company: String!, $location: String, $page: Int, $pageSize: Int) {
    getCheckQR(company: $company, location: $location, page: $page, pageSize: $pageSize) {
      total
      page
      pageSize
      data {
        content
        location
        address
        vaccine {
          _id
          vaccine
          vaccineType
          date
          status
        }
        medical {
          _id
          type
          date
          expiredDate
          isPositive
          status
        }
        createdBy {
          _id
          avatar
          nickname
          email
          phone
          birthday
          gender
        }
        createdDate
      }
    }
  }
`;

const MedicalGRAPHQL = {
  QUERY_MEDICAL,
  QUERY_MEDICAL_BY_USER,
  MUTATION_APPROVE_MEDICAL,
  MUTATION_REJECT_MEDICAL,
  MUTATION_ADD_MEDICAL,
  MUTATION_DELETE_MEDICAL,
  QUERY_VACCINE,
  QUERY_VACCINE_BY_USER,
  MUTATION_APPROVE_VACCINE,
  MUTATION_REJECT_VACCINE,
  MUTATION_ADD_VACCINE,
  MUTATION_DELELE_VACCINE,
  QUERY_CHECK_QR_CODE
};

export default MedicalGRAPHQL;