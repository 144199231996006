import React, { useEffect, useState, useRef } from "react";
import Select from 'react-select';
import { MDBBtn, MDBCol, MDBIcon, MDBRow } from "mdbreact";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import DataChartDashboardBI from "../../components/DataChartDashboardBI";
import DashboardPowerBIGraphQL from "../../../graphql/DashboardPowerBI";
import { PDFExport } from "@progress/kendo-react-pdf";


function DataVisualizedPage() {

    const [dataChart, setDataChart] = useState([])
    const [dataTemplates, setDataTemplates] = useState([])
    const [selectTemplates, setSelectTemplates] = useState()
    const [date, setDate] = useState({startDate: window.COMMON_DATE.addDaysFromNow(-90), endDate: new Date()});
    const [companiesAndInfluencers, setCompaniesAndInfluencers] = useState({})
    const [select, setSelect] = useState({companies: [{value: 'all', label: 'All Company', code: 'DEFAULT'}], influencers: []});

    const pdfExportRef = useRef()

    const handleDatePicker = (event, attr) => {
        date[attr] = event;
        setDate({ ...date });
    };

    const handleSelect = (event, id, attr) => {
        window.COMMON.checkSelectValidation(id, event);
        select[attr] = event
        const allValueIndex = event ? event.findIndex((item) => item.value === 'all') : -1;
        let newEvent;
        if (allValueIndex >= 0) {
          if (allValueIndex === 0 && event.length > 1) {
            event.splice(allValueIndex, 1);
            newEvent = event;
          }
          else if (allValueIndex > 0) {
            newEvent = [event[allValueIndex]];
          }
          else {
            newEvent = event;
          }
          select[attr] = newEvent;
        }
  
        setSelect((current) => {
          return {
            ...current,
            ...select
          };
        }); 
    };


    useEffect(() => {
        const timerId = setTimeout(()=> {
            getTemplates()
            getDataCompany()
        },100)

        return () => {
            clearTimeout(timerId)
        }

    },[])
 
    const getTemplates = async () => {
        try{
            const params = {
                input: {
                    templateType: 'CHART'
                }
            }
            const result = await window.COMMON.query(DashboardPowerBIGraphQL.QUERY_LIST_TEMPLATES, params)
            const convertSelectTemplates = result.data.getDashboardTemplates.reverse().map(template => ({value: template._id, label: template.name, code: ''}))
            setDataTemplates(convertSelectTemplates)
            setSelectTemplates(convertSelectTemplates[0])
        }catch(error){
            window.COMMON.showErrorLogs('DashboardPowerBI.getTemplates');
        }
    }

    const getDataChartTemplates = async () => {
        if(dataTemplates.length === 0){
            window.COMMON.showMessage('warning', 'MSG_CODE_029', 'Chưa có template, vui lòng tạo template');
            return
        }
        try {
            const params = {
                input: {
                    _id: selectTemplates.value,
                    companyIds: select.companies[0].value === 'all' ? (Object.keys(companiesAndInfluencers).length !== 0 ? companiesAndInfluencers.companies[1].options.map(company =>company.value) : []) : select.companies.map(company => company.value),
                    startDate: new Date(date.startDate),
                    endDate: new Date(date.endDate),
                    timeZone: window.COMMON.getTimeZone()
                }
            }
            const result = await window.COMMON.query(DashboardPowerBIGraphQL.QUERY_DATA_CHART_TEMPLATES, params)
            setDataChart(result.data.getDashboardTemplateChartData)
        } catch (error) {
            window.COMMON.showErrorLogs('DashboardPowerBI.getDataChartTemplates');
        }
    }

    const getDataCompany = async () => {
        try {
          const newOptions = {};
          await window.COMMON.getDataCompany();
          const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_COMPANY));
          if (dataCompany && dataCompany.length > 0) {
            const dataInfluencers = window.COMMON.getArrsInArrs(window.CONSTANT.INFLUENCER, dataCompany, 'type');
            newOptions.companies = dataCompany.filter(company => company.accountType === 'COMPANY').map(company => ({ value: company.company._id, label: company.name }))
            newOptions.influencers = window.COMMON.createDataSelect(dataInfluencers, '_id', 'information.name') 
          }
          const formatSelectOptions = {
            companies: [
                {
                    label: window.I18N('default').toLowerCase(),
                    options: [{value: 'all', label: 'All Company', code: 'DEFAULT'}],

                },
                {
                    label: window.I18N('companies').toLowerCase(),
                    options: newOptions.companies.map(company => ({...company, code: 'COMPANY'})),
                }
            ],
            influencers: []
          }
          setCompaniesAndInfluencers({...formatSelectOptions});
    
        } catch (error) {
          window.COMMON.showErrorLogs('DashboardPowerBI.getDataCompany');
        }
      };


    const handleViewChartTemplates = (index) => {
        getDataChartTemplates(index)
    }

    const handleSelectTemplates = (event) => {
        setSelectTemplates(event)
    }

    const handleExportChartPDF = () => {
        try {
            if(pdfExportRef.current){
                pdfExportRef.current.save()
                window.COMMON.showMessage('success', 'MSG_CODE_070', window.MSG.MSG_CODE_070);  
            }
        } catch (error) {
            window.COMMON.showMessage('error', 'MSG_CODE_071', window.MSG.MSG_CODE_071)          
        }
    }

    return ( 
        <div className="mt-3" style={{width: '100%'}}>
            <div className="needs-validation mb-2 p-4 bg-white rounded-lg wow fadeInRight animated" data-wow-delay="0.2s" noValidate style={{position: 'relative', zIndex: 1000}}>
                <MDBRow className="mb-2">
                        <MDBCol>
                            <h6 className="font-weight-bold" style={{fontSize: '0.8rem'}}>{window.I18N('Templates')}</h6>
                            <Select id="select-templates" value={selectTemplates} options={dataTemplates} className="md-form m-0" placeholder={window.I18N('choose_option')} onChange={(event) => handleSelectTemplates(event)}/>
                        </MDBCol>
                        <MDBCol>
                                <h6 className="font-weight-bold" style={{fontSize: '0.8rem'}}>{window.I18N('companies')}</h6>
                                <Select id="select-companies" value={select.companies} options={companiesAndInfluencers.companies} className="md-form m-0" placeholder={window.I18N('choose_option')} onChange={(event) => handleSelect(event, 'select-companies', 'companies')} isSearchable isMulti closeMenuOnSelect={false} />
                        </MDBCol>
                </MDBRow>
                <MDBRow>
                        <MDBCol>
                                <h6 className="font-weight-bold" style={{fontSize: '0.8rem'}}>{window.I18N('from_date')}</h6>
                                <div className="input-group input-group-transparent">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                                        </span>
                                    </div>
                                    <DatePicker placeholderText={window.I18N('start_date') + ' *'} className="form-control" dateFormat="dd/MM/yyyy" selected={date.startDate} onChange={(event) => handleDatePicker(event, 'startDate')} showYearDropdown showMonthDropdown required/>               
                                </div>
                        </MDBCol>
                        <MDBCol>
                                <h6 className="font-weight-bold" style={{fontSize: '0.8rem'}}>{window.I18N('to_date')}</h6>
                                <div className="input-group input-group-transparent">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                                        </span>
                                    </div>
                                    <DatePicker placeholderText={window.I18N('end_date') + ' *'} className="form-control" dateFormat="dd/MM/yyyy" selected={date.endDate} onChange={(event) => handleDatePicker(event, 'endDate')} showYearDropdown showMonthDropdown required/>
                                </div>
                        </MDBCol> 
                </MDBRow>             
                <MDBRow className="mt-3">
                        <MDBCol className="flex-center justify-content-end">
                            <MDBBtn className="m-0 mr-2" color='warning' type="button" style={{color: '#fff'}} onClick={() => handleExportChartPDF()}>
                                <MDBIcon fa="true" icon='file-pdf' className="fa-fw" /> {window.I18N('export-pdf')}
                            </MDBBtn>
                            <MDBBtn className="m-0" color="primary" type="button" onClick={() => handleViewChartTemplates()}>
                                <MDBIcon fa="true" icon="chart-bar" className="fa-fw" /> View Chart
                            </MDBBtn>
                        </MDBCol>
                </MDBRow>
            </div>
            {dataChart.length > 0 ?      
                <PDFExport ref={pdfExportRef} fileName={`Data_Chart_${new Date().getTime()}.pdf`}>
                    <>
                        {
                            dataChart.map((chart, index) => (
                                <div key={chart.dataKey} className="bg-white rounded-lg p-4 mb-2" style={{position: 'relative'}}>
                                    <MDBRow>
                                        <MDBCol>
                                                <div className="bg-white rounded-lg p-4 mb-3">
                                                    <DataChartDashboardBI title={chart.title} data={chart.data} index={index}/>
                                                </div>
                                        </MDBCol>
                                    </MDBRow>
                                </div>
                            ))
                        }
                    </>
                </PDFExport>
             : <div className="flex-center bg-white rounded-lg p-4 wow fadeInRight animated" data-wow-delay="0.2s" style={{position: 'relative', height: '300px'}}>
                    <h6 style={{fontSize: '1.2rem', fontStyle: "italic"}}>Choose a template and press the view chart button to view the data in chart form</h6>
                </div>
            }
      </div>
     );
}

const mapStateToProps = state => ({
    language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DataVisualizedPage);