import { MDBCol, MDBIcon, MDBInput, MDBRow } from 'mdbreact';
import React, { useImperativeHandle } from 'react';
import { useRef } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import Common from '../../../../utils/Common';

const CaseSelfAssessmentForm = React.forwardRef((props, ref) => {
  const { data, handleChange, handleSelect, options } = props;

  const formRef = useRef();
  useImperativeHandle(ref, () => {
    return {
      validateForm
    };
  }, []);

  const validateForm = () => {
    return Common.checkFormValidation('case-self-assessment-form', formRef.current);
  };

  const onFieldChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (handleChange) {
      handleChange(name, value);
    }
  };

  const onDatePickerChange = (fieldName, value) => {
    if (handleChange) {
      handleChange(fieldName, value);
    }
  };

  const onSelectChange = (fieldName, value, isMultiple = false) => {
    if (handleSelect) {
      handleSelect(fieldName, value, isMultiple);
    }
  };

  return (
    <div className="container-top wow fadeInUp animated" data-wow-delay="0.5s">
      <form
        id="case-self-assessment-form"
        className="needs-validation mt-2"
        noValidate
        ref={formRef}
      >
        <MDBRow>
          <MDBCol sm="6">
            <MDBInput
              outline
              containerClass="mt-0"
              type="text"
              label={window.I18N('name') + ' *'}
              name="name"
              value={data.name}
              onChange={onFieldChange}
              maxLength="100"
              pattern="\S(.*\S)?"
              required
            >
              <div className="invalid-feedback">
                {window.I18N('MSG_CODE_029')}
              </div>
            </MDBInput>
            <MDBRow>
              <MDBCol>
                <MDBInput
                  outline
                  containerClass="m-0"
                  type="text"
                  label={window.I18N('code') + ' *'}
                  name="code"
                  value={data.code}
                  onChange={onFieldChange}
                  maxLength="50"
                  pattern="\S(.*\S)?"
                  required
                >
                  <div className="invalid-feedback">
                    {window.I18N('MSG_CODE_029')}
                  </div>
                </MDBInput>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mt-4'>
              <MDBCol>
                <div className="input-group input-group-transparent">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon
                        fa="true"
                        icon="calendar-alt"
                        className="fa-fw"
                      />
                    </span>
                    <span className="input-group-text">{window.I18N('start_date')}</span>
                  </div>
                  <DatePicker
                    placeholderText={window.I18N('start_date') + ' *'}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    selected={data.startDate}
                    startDate={props.caseDueDate || data.startDate}
                    endDate={data.endDate}
                    onChange={(value) => onDatePickerChange('startDate', value)}
                    showYearDropdown
                    showMonthDropdown
                    selectsStart
                    required
                  />
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mt-4'>
              <MDBCol>
                <div className="input-group input-group-transparent">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon
                        fa="true"
                        icon="calendar-alt"
                        className="fa-fw"
                      />
                    </span>
                    <span className="input-group-text">{window.I18N('end_date')}</span>
                  </div>
                  <DatePicker
                    placeholderText={window.I18N('end_date') + ' *'}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    selected={data.endDate}
                    startDate={data.startDate}
                    endDate={data.endDate}
                    onChange={(value) => onDatePickerChange('endDate', value)}
                    showYearDropdown
                    showMonthDropdown
                    selectsEnd
                    required
                  />
                </div>
              </MDBCol>
            </MDBRow>
            <MDBInput
              outline
              containerClass="mb-0"
              type="textarea"
              label={window.I18N('description')}
              name="description"
              value={data.description}
              onChange={onFieldChange}
              maxLength="5000"
            ></MDBInput>
          </MDBCol>
          <MDBCol sm="6">
            {/* {window.COMMON.checkRoleIsSystem() && (
              <Select
                id="select-influencer"
                value={da.influencer}
                options={options.influencers}
                placeholder={window.I18N('influencer') + ' *'}
                className="md-form mt-0"
                onChange={(event) =>
                  handleSelect(event, 'select-influencer', 'influencer')
                }
                isSearchable
              />
            )} */}
            <Select
              id="select-advisors"
              value={data.advisors}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option._id}
              options={options.advisors}
              placeholder={window.I18N('assign_influencer')}
              className="md-form mt-0"
              onChange={(values) =>
                onSelectChange('advisors', values, true)
              }
              isSearchable
              isMulti
              closeMenuOnSelect={false}
            />
            <Select
              id="select-type"
              className="md-form"
              value={data.type}
              placeholder={window.I18N('type') + ' *'}
              options={options.types}
              onChange={(value) => onSelectChange('type', value)}
              isSearchable
            />
            <Select
              id="select-period"
              className="md-form"
              value={data.period}
              placeholder={window.I18N('period') + ' *'}
              options={options.periods}
              onChange={(value) =>
                onSelectChange('period', value)
              }
              isSearchable
            />
          </MDBCol>
        </MDBRow>
      </form>
    </div>
  );
});

export default CaseSelfAssessmentForm;
