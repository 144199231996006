import React, { useLayoutEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { MDBIcon } from 'mdbreact';

const Pagination = React.memo(function Pagination(props) {
  const [paginationHTML, setPaginationHTML] = React.useState('');
  const [object, setObject] = React.useState({});

  useLayoutEffect(() => {
    object.total = props.total;
    object.pageSize = props.pageSize;
    object.currentPage = props.currentPage;
    if (Number.isInteger(object.total / object.pageSize)) {
      object.page = object.total / object.pageSize;
    } else {
      object.page = parseInt(object.total / object.pageSize) + 1;
    }
    setObject({ ...object });
    generatePage();
    // eslint-disable-next-line
  }, [props.total, props.pageSize, props.currentPage]);

  const changePage = (index) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    object.currentPage = index;
    setObject({ ...object });
    generatePage();
    if (props.changePage !== undefined) {
      props.changePage(index);
    }
  };

  const nextPrevPage = (mode) => {
    object.currentPage = object.currentPage + mode;
    setObject({ ...object });
    generatePage();
    if (props.changePage !== undefined) {
      props.changePage(object.currentPage);
    }
  };

  const calcPage = (index) => {
    if (object.currentPage < 4) {
      if (index < 5) {
        return <li className={index === object.currentPage ? 'page-item active' : 'page-item'} key={index}>
          <NavLink className="page-link waves-effect" to="#" onClick={() => changePage(index)}>{index + 1}</NavLink>
        </li>;
      } else if (index === 5) {
        return <li className="page-item" key={index}>
          <NavLink className="page-link waves-effect" to="#">...</NavLink>
        </li>;
      } else {
        return <li className={object.page - 1 === object.currentPage ? 'page-item active' : 'page-item'} key={index}>
          <NavLink className="page-link waves-effect" to="#" onClick={() => changePage(object.page - 1)}>{object.page}</NavLink>
        </li>;
      }
    } else if (object.currentPage > object.page - 1 - 4) {
      if (index === 0) {
        return <li className={object.index === object.currentPage ? 'page-item active' : 'page-item'} key={index}>
          <NavLink className="page-link waves-effect" to="#" onClick={() => changePage(index)}>{index + 1}</NavLink>
        </li>;
      } else if (index === 1) {
        return <li className="page-item" key={index}>
          <NavLink className="page-link waves-effect" to="#">...</NavLink>
        </li>;
      } else {
        const indexReserve = object.page - 1 - 6 + index;
        return <li className={indexReserve === object.currentPage ? 'page-item active' : 'page-item'} key={index}>
          <NavLink className="page-link waves-effect" to="#" onClick={() => changePage(indexReserve)}>{indexReserve + 1}</NavLink>
        </li>;
      }
    } else {
      if (index === 0) {
        return <li className={index === object.currentPage ? 'page-item active' : 'page-item'} key={index}>
          <NavLink className="page-link waves-effect" to="#" onClick={() => changePage(index)}>{index + 1}</NavLink>
        </li>;
      } else if (index === 1 || index === 5) {
        return <li className="page-item" key={index}>
          <NavLink className="page-link waves-effect" to="#">...</NavLink>
        </li>;
      } else if (index === 6) {
        return <li className={object.page - 1 === object.currentPage ? 'page-item active' : 'page-item'} key={index}>
          <NavLink className="page-link waves-effect" to="#" onClick={() => changePage(object.page - 1)}>{object.page}</NavLink>
        </li>;
      } else if (index === 2) {
        return <li className="page-item" key={index}>
          <NavLink className="page-link waves-effect" to="#" onClick={() => changePage(object.currentPage - 1)}>{object.currentPage}</NavLink>
        </li>;
      } else if (index === 3) {
        return <li className="page-item active" key={index}>
          <NavLink className="page-link waves-effect" to="#" onClick={() => changePage(object.currentPage)}>{object.currentPage + 1}</NavLink>
        </li>;
      } else {
        return <li className="page-item" key={index}>
          <NavLink className="page-link waves-effect" to="#" onClick={() => changePage(object.currentPage + 1)}>{object.currentPage + 2}</NavLink>
        </li>;
      }
    }
  };

  const generatePage = () => {
    let html = '';
    if (object.page <= 7) {
      html = Array.from({length: object.page}, (page, index) => 
        <li className={index === object.currentPage ? 'page-item active' : 'page-item'} key={index}>
          <NavLink className="page-link waves-effect" to="#" onClick={() => changePage(index)}>{index + 1}</NavLink>
        </li>
      );
    } else {
      html = Array.from({length: 7}, (page, index) => calcPage(index));
    }
    setPaginationHTML(html);
  };

  const to = (object.currentPage + 1) * object.pageSize;
  return (
    <div className="p-0 m-0 d-flex align-items-center w-100">
      <div className="ml-auto mr-3">
        {
          props.total === 0 ? <p className="p-1 mb-2">{window.I18N('showing_0')} {object.total} {window.I18N('entries')}</p>
            : <p className="p-1 mb-2">{window.I18N('showing')} {object.currentPage * object.pageSize + 1} - {to > object.total ? object.total : to} {window.I18N('of')} {object.total} {window.I18N('entries')}</p>
        }
      </div>
      <div>
        <ul className={'pagination mb-0 ' + window.COMMON.getValue(props.className)}>
          <li className={object.currentPage === 0 || object.page === 0 ? 'page-item first disabled' : 'page-item first'}>
            <NavLink className="page-link waves-effect" to="#" onClick={() => changePage(0)}>
              <MDBIcon fa="true" icon="backward"></MDBIcon>
            </NavLink>
          </li>
          <li className={object.currentPage === 0 || object.page === 0 ? 'page-item prev disabled' : 'page-item prev'}>
            <NavLink className="page-link waves-effect" to="#" onClick={() => nextPrevPage(-1)}>
              <MDBIcon fa="true" className="fa-lg" icon="caret-left"></MDBIcon>
            </NavLink>
          </li>
          {paginationHTML}
          <li className={object.currentPage === object.page - 1 || object.page === 0 ? 'page-item next disabled' : 'page-item next'}>
            <NavLink className="page-link waves-effect" to="#" onClick={() => nextPrevPage(1)}>
              <MDBIcon fa="true" className="fa-lg" icon="caret-right"></MDBIcon>
            </NavLink>
          </li>
          <li className={object.currentPage === object.page - 1 || object.page === 0 ? 'page-item last disabled' : 'page-item last'}>
            <NavLink className="page-link waves-effect" to="#" onClick={() => changePage(object.page - 1)}>
              <MDBIcon fa="true" icon="forward"></MDBIcon>
            </NavLink>
          </li>
        </ul>        
      </div>
    </div>
  );
});

export default Pagination;
