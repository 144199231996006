const companyEvent = {
  ACCEPT_COMPANY_RELATIONSHIP: 'ACCEPT_COMPANY_RELATIONSHIP',
  DECLINE_COMPANY_RELATIONSHIP: 'DECLINE_COMPANY_RELATIONSHIP',
  ACCEPT_COMPANY_RELATIONSHIP_FOR_INFLUENCER: 'ACCEPT_COMPANY_RELATIONSHIP_FOR_INFLUENCER',
  DECLINE_COMPANY_RELATIONSHIP_FOR_INFLUENCER: 'DECLINE_COMPANY_RELATIONSHIP_FOR_INFLUENCER',
  UPDATE_GOPY_AND_SUB_QUESTION_RELATIONSHIP_FOR_INFLUENCER: 'UPDATE_GOPY_AND_SUB_QUESTION_RELATIONSHIP_FOR_INFLUENCER',
  ADD_BUSINESS_AWARD: 'ADD_BUSINESS_AWARD',
  EDIT_BUSINESS_AWARD: 'EDIT_BUSINESS_AWARD',
  DEL_BUSINESS_AWARD: 'DEL_BUSINESS_AWARD'
};

export default companyEvent;