import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux"
import { makeStyles } from "@material-ui/styles";
import { CancelOutlined, CheckCircleOutline } from "@material-ui/icons";
import { Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
    paymentResultContainer: {
        width: '90%',
        marginTop: '20px',
        padding: '10px',
        borderRadius: '6px',
        backgroundColor: '#ffffff',
        boxShadow: '-1px 2px 12px 1px rgba(0,0,0,0.2)'
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    colorStatus: {
        fontSize: '16px'
    }
}));

function PaymentResultPage({ language }) {
    const classes = useStyles()
    const [paymentInfo, setPaymentInfo] = useState({})

    const TRANSACTION_INFO = useMemo(() => {
        const { orderCoin, orderDescription, amount, transaction, orderBill } = paymentInfo
        return [
            {
                title: orderCoin ? window.I18N('order_coin') : window.I18N('order_bill'),
                value: orderCoin ? <div> 
                            <span className="mr-1">{ window.COMMON.formatCurrency(orderCoin, { currency: 'VND' }) }</span> 
                            <img src={window.CONSTANT.G_COIN} alt="No Image" height='20px'/> 
                        </div> : orderBill
            },
            {
                title: window.I18N('order_description'),
                value: orderDescription !== 'undefined' ? orderDescription : window.I18N('none')
            },
            {
                title: window.I18N('total_amount'),
                value: window.COMMON.formatCurrency(amount) 
            },
            {
                title: window.I18N('transaction_code'),
                value: transaction
            }
        ]
    },[paymentInfo, language.code])

    useEffect(()=>{
        const urlParams = new URLSearchParams(window.location.search)
        let rawPaymentInfo = {}
        urlParams.forEach((value, key) => {
            rawPaymentInfo[key] = value
        });
        setPaymentInfo(rawPaymentInfo)
    },[window.location])

    return ( 
        <div className="wow fadeInRight animated" data-wow-delay="0.2s">
            <div className={`${classes.container}`}>
                <div className={`${classes.paymentResultContainer}`}>
                    <div className={`${classes.flexCenter} ${classes.colorStatus} font-weight-bold`} style={{ color: paymentInfo.status === '0' ? 'rgb(5, 150, 105)' : 'rgb(238, 0, 0)' }}>
                        {paymentInfo.status === '0' ? <CheckCircleOutline className={`mr-2 fs-2`}/> : <CancelOutlined className={`mr-2 fs-2`}/>}
                        {paymentInfo.message}
                    </div>
                    <div className="my-5" style={{padding: '20px 20%'}}>
                        {
                            TRANSACTION_INFO.map((info, index) => {
                                const { title, value } = info
                                return (
                                    <Grid container key={index} className={`${classes.flexCenter} mb-4 px-5`}>
                                        <Grid item xs={6} md={6} className="font-weight-bold mb-2">
                                            {title}
                                        </Grid>
                                        <Grid item xs={6} md={6} className="font-weight-bold text-right">
                                            {value}
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </div>
                    <div className="flex-center w-full mt-10">
                        <Button variant="contained" color="primary" className="mr-3">
                            <Link to={'/payment-management'} style={{ color: '#FFF', textDecoration: 'none' }}>
                                {window.I18N('transaction_history')}
                            </Link>
                        </Button>
                        <Button variant="contained" color="primary">
                            <Link to={'/buy-gopies'} style={{ color: '#FFF', textDecoration: 'none' }}>
                                {window.I18N('continued_purchase')}
                            </Link>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
     );
}

const mapStateToProps = state => ({
    language: state.language.value
  })
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentResultPage);