import mimi1 from './mimi1.gif';
import mimi2 from './mimi2.gif';
import mimi3 from './mimi2.gif';
import mimi4 from './mimi4.gif';
import mimi5 from './mimi5.gif';
import mimi6 from './mimi6.gif';
import mimi7 from './mimi7.gif';
import mimi8 from './mimi8.gif';
import mimi9 from './mimi9.gif';

const gifList =[
  {file: mimi1,name: 'mimi1'},
  {file: mimi2,name: 'mimi2'},
  {file: mimi3,name: 'mimi3'},
  {file: mimi4,name: 'mimi4'},
  {file: mimi5,name: 'mimi5'},
  {file: mimi6,name: 'mimi6'},
  {file: mimi7,name: 'mimi7'},
  {file: mimi8,name: 'mimi8'},
  {file: mimi9,name: 'mimi9'}
];


export default gifList;