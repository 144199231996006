const roleEvent = {
  ADD_MENU: 'ADD_MENU',
  EDIT_MENU: 'EDIT_MENU',
  DEL_MENU: 'DEL_MENU',
  ADD_ROLE: 'ADD_ROLE',
  EDIT_ROLE: 'EDIT_ROLE',
  DEL_ROLE: 'DEL_ROLE',
  ADD_RIGHT: 'ADD_RIGHT',
  EDIT_RIGHT: 'EDIT_RIGHT',
  DEL_RIGHT: 'DEL_RIGHT',
  ADD_PACKAGE: 'ADD_PACKAGE',
  EDIT_PACKAGE: 'EDIT_PACKAGE',
  DEL_PACKAGE: 'DEL_PACKAGE',
};

export default roleEvent;