import gql from 'graphql-tag';

const QUERY_REASON = gql`
  query($type: String!, $isActive: [Boolean!]) {
    getReasons(type: $type, isActive: $isActive) {
      _id
      code
      content {
        code
        text
      }
      type
      index
      isActive
      createdDate
      createdBy {
        name
      }
    }
  }
`;

const MUTATION_ADD_REASON = gql`
  mutation($input: ReasonInput!) {
    addReason(input: $input)
  }
`;

const MUTATION_EDIT_REASON = gql`
  mutation($input: ReasonInput!) {
    editReason(input: $input)
  }
`;

const MUTATION_DEL_REASON = gql`
  mutation($id: String!) {
    deleteReason(id: $id)
  }
`;

const ReasonGRAPHQL = {
  QUERY_REASON,
  MUTATION_ADD_REASON,
  MUTATION_EDIT_REASON,
  MUTATION_DEL_REASON
};

export default ReasonGRAPHQL;