import React, { memo, useImperativeHandle, useRef } from 'react'
import { MDBBtn, MDBIcon } from 'mdbreact'
import $ from 'jquery'
import useClickOutSide from '../../hooks/useClickOutSide'


const Filter = React.forwardRef((props, ref) => {
    const filterRef = useRef()

    const handleCloseExpandedAria = () => {
        $(`#${props.id}`).collapse('hide')
    }

    useImperativeHandle(ref, () => ({
        closeFilter: handleCloseExpandedAria
    }))

    useClickOutSide({
        ref: filterRef,
        handleOutside: props.onClickOutSide ? props.onClickOutSide : handleCloseExpandedAria
    })

    return (
        <div className="d-inline-block container-filter">
            <MDBBtn
                color=""
                className={'dropdown-toggle btn-filter ' + props.btnClassName}
                data-toggle="collapse"
                data-target={'#' + props.id}
                aria-expanded="false"
                aria-controls={props.id}
                onClick={props.onClick}
            >
                <MDBIcon fa="true" icon="filter" className="fa-fw" /> {window.I18N('filter')}
            </MDBBtn>
            <div className={'collapse container-filter-content ' + props.className} id={props.id} ref={filterRef}>
                {props.children}
            </div>
        </div>
    )
})

export default memo(Filter)
