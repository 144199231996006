import React, { useRef, useLayoutEffect, useState } from 'react';
import { Line, HorizontalBar } from 'react-chartjs-2';
import AccountBalanceGRAPHQL from '../../../graphql/AccountBalance';
import RoleGRAPHQL from '../../../graphql/Role';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import ReactSelect from 'react-select';
import { MDBIcon } from 'mdbreact';
import CustomChart from './components/CustomChart';

const paletteColor = ["rgba(0, 159, 109, 0.6)","rgba(190, 0, 0, 0.6)","rgba(61, 0, 189, 0.6)","rgba(0, 65, 175, 0.6)","rgba(54, 162, 235, 0.6)","rgba(153, 102, 255, 0.6)","rgba(201, 203, 207, 0.6)", "rgba(74, 147, 124, 0.6)", "rgba(147, 147, 124, 0.6)", "rgba(147, 147, 235, 0.6)", " rgba(147, 196, 235, 0.6)", "rgba(0, 171, 184, 0.6)", "rgba(139, 171, 184, 0.6)", "rgba(139, 255, 184, 0.6)", "rgba(165, 197, 97, 0.6)", "rgba(165, 163, 152, 0.6)", "rgba(0, 0, 0, 0.6)", "rgba(0, 47, 0, 0.6)", "rgba(26, 47, 43, 0.6)", "rgba(255, 38, 214, 0.6)"]
const types = ['POST', 'LIKE', 'DISLIKE', 'COMMENT', 'SHARE'];
const maxAgeLabel = [25, 40, 65]
const minAgeLabel = [16, 26, 41]

const InsightChartEngagement = ({ companyType, companyId, companies }) => {
    const dataMonthsSelect = [
        {
            label: window.I18N('last_3_month'),
            value: "92",
        },
        {
            label: window.I18N('last_6_month'),
            value: "183",
        }
    ]
    const [dataDaysOfMonth, setDataDaysMonth] = useState(dataMonthsSelect[0])
    const labelGroup = {
		gender: [window.I18N('female') , window.I18N('male')],
		age: ['16- 25', '26-40', '41-65', window.I18N('unknow')]
	}
    const [dataRole, setDataRole] = useState([])
    const [dataChart, setDataChart] = useState({ labels: [], datasets: [] })
 
    const engagementData = useRef([])
    const chartRef = useRef()

    const handleSelectMonths = (value) => {
        setDataDaysMonth(value)
    }

    const groupDataforChart = (paramData) => {
        let groupDataChart = window.COMMON.getDataChartInPastYear({
            data: paramData,
            numberMonths: Math.floor(+dataDaysOfMonth.value/30),
            types: types,
            itemKey: 'type',
            dateKey: 'createdDate',
            formatDateLabel: "MM/YYYY"
        });
        dataChart.labels = groupDataChart.labels
        dataChart.datasets = types.map((el, index) => ({
            label: el,
            data: groupDataChart.data[index],
            borderColor: paletteColor[index],
            backgroundColor: "transparent",
            lineTension: 0.2,
        }))
        setDataChart({ ...dataChart })
    }

 
    const labelDiv = (hoverLabelIndex, indexDatasetPopup, hoverDatasetIndex) => {
        const labelDiv = <div style={{ fontSize: "13px", fontWeight: 400 }} >
            <Grid container>
                {hoverDatasetIndex?.map((el,index) =>
                    <Grid key={index} item className={`tab-control-fanpage small-content ${indexDatasetPopup===el?'active':''}`} onClick={()=>chartRef?.current?.handleSetIndexDatasetPopup(el)} > {types[el]}</Grid>
                )}
            </Grid>
            <div style={{width:"100%"}} className='mt-1 mb-3 d-flex align-items-center justify-content-center'>
                <span className='mr-2' style={{ display: "inline-block", height: "1rem", width: "1rem", backgroundColor: paletteColor[indexDatasetPopup] }}></span>
                {`${window.I18N('number-of')} ${types[indexDatasetPopup]} ${window.I18N('in-month')} ${dataChart.labels[hoverLabelIndex]}: ${dataChart.datasets[indexDatasetPopup].data[hoverLabelIndex]}`}
            </div>
			
		</div>

		return labelDiv
    }
    
    const genderDiv = (male, female) => {
		const genderDiv = 
			<div style={{ display: "flex", flexDirection: "column", marginRight: "10px" }}>
				<div style={{ display: "flex", flexDirection: "column", backgroundColor: "#F1F2F8", marginBottom: "10px", padding: "5px 10px"}}>
					<div style={{margin: "auto", fontSize: "20px", fontWeight: 500}}>{male}</div>
					<div style={{ margin: "auto" }}>{labelGroup.gender[1] }</div>
				</div>
				<div style={{display: "flex",flexDirection: "column", backgroundColor: "#F1F2F8", padding: "5px 10px"}}>
					<div style={{margin: "auto", fontSize: "20px", fontWeight: 500}}>{female}</div>
					<div style={{ margin: "auto" }}>{labelGroup.gender[0] }</div>
				</div>
			</div>
		
		return genderDiv
	}

    const roleDiv = (paramGroupRole) => {
		return <HorizontalBar
			width={180}
			data={paramGroupRole}
			options={{
				legend: { display: false },
				responsive: false,
				maintainAspectRatio: true,
				scales: {
					yAxes: [{
						ticks: {
							beginAtZero: true,
							stepSize: 1,
							fontSize: 10,
						},
						gridLines: {display:false}
					}],
					xAxes: [{
						ticks: {
							beginAtZero: true,
							stepSize: 1,
							fontSize: 10,
						},
					}]
				},
				tooltips: {
					enabled: true,
				},
			}}
		></HorizontalBar>
    }
    
    const ageDiv = (paramGroupAge) => {
        return <div className='mr-2'>
        <HorizontalBar
            width={160}
			data={paramGroupAge}
            options={{
				legend: { display: false },
				responsive: false,
				maintainAspectRatio: true,
				scales: {
					yAxes: [{
						ticks: {
							beginAtZero: true,
							stepSize: 1,
							fontSize: 10,
						},
						gridLines: {display:false}
					}],
					xAxes: [{
						ticks: {
							beginAtZero: true,
							stepSize: 1,
							fontSize: 10,
						},
					}]
				},
				tooltips: {
					enabled: true,
				},
			}}
            ></HorizontalBar>
        </div>
    }

    const createInnerHTMLPopup = ({hoverLabelIndex, indexDatasetPopup, hoverDatasetIndex}) => {
        let popupData = []
        if (hoverLabelIndex !== null && hoverDatasetIndex?.length ) {
            popupData = engagementData.current.filter(el => el.createdDate === dataChart.labels[hoverLabelIndex] && el.type === dataChart.datasets[indexDatasetPopup].label)
        }
        var groupDataByUser = popupData.reduce((acc, curr) => {
            var i = acc.findIndex(el => el.idCreator === curr.idCreator)
            if (i === -1) {
                acc.push({...curr, interactCount: 1})
            } else {
                acc[i].interactCount++
            }
            return acc
        }, [])

        //group data for genderDiv
        const groupGender = {}
		groupGender.male = groupDataByUser.filter(el => el.gender === 1).length
		groupGender.female = groupDataByUser.filter(el => el.gender === 0).length
        
        //group data for roleDiv
        const groupRole = {
			labels: dataRole.map(el => el.label),
			datasets : [
				{
					label: 'Number of person',
					backgroundColor: paletteColor,
					borderWidth: 1,
					barPercentage: 0.5,
					data: new Array(dataRole.length).fill(0),
				},
			]
        }
        groupDataByUser.forEach(account => {
			let index = groupRole.labels.indexOf(account.roleText)
			if (index !== -1) {
				groupRole.datasets[0].data[index] = groupRole.datasets[0].data[index] + 1
			}
        })
        
        //group data for ageDiv
        const groupAge = {
            labels: labelGroup.age,
            datasets : [
				{
					label: 'Number of person',
					backgroundColor: paletteColor,
					borderWidth: 1,
					barPercentage: 0.5,
					data: new Array(4).fill(0),
				},
			]
        }
        groupDataByUser.forEach((account) => {
			if (!Number.isInteger(account.age) || account.age < minAgeLabel[0] ||account.age > maxAgeLabel[4]) {
				groupAge.datasets[0].data[3] = groupAge.datasets[0].data[3] + 1
			} else if (account.age <= maxAgeLabel[0]) {
				groupAge.datasets[0].data[0] = groupAge.datasets[0].data[0] +1
			} else if (account.age <= maxAgeLabel[1]) {
				groupAge.datasets[0].data[1] = groupAge.datasets[0].data[1] +1
			} else {
				groupAge.datasets[0].data[2] = groupAge.datasets[0].data[2] +1
			}
		})

        var innerHtml =<div style={{ minWidth: '280px' }}>
			{labelDiv(hoverLabelIndex, indexDatasetPopup, hoverDatasetIndex)}
            <div className='d-flex align-items-center justify-content-center' >
                {genderDiv(groupGender.male, groupGender.female)}
                {ageDiv(groupAge)}
				{companyId&&roleDiv(groupRole)}
			</div>
        </div>
		return innerHtml

	}
    const getDataRole = async () => {
        try {
            const params = {
                company: companyId
            };
            const result = await window.COMMON.query(RoleGRAPHQL.QUERY_ROLE, params);
            if (result && result.data) {
                let optionsRole = window.COMMON.createDataSelect(result.data.getRoles, 'code', 'name', 'code', '_id'); 
                optionsRole.push({ value: window.CONSTANT.INDIVIDUAL, label: window.I18N('individual'), code: window.CONSTANT.INDIVIDUAL });
                optionsRole.push({ value: window.CONSTANT[companyType], label: window.I18N(companyType), code: window.CONSTANT[companyType] });
                optionsRole.reverse();
                getDataActivities('30', optionsRole);
                setDataRole(optionsRole)
            }

        } catch (error) {
            console.log(error)
            window.COMMON.showErrorLogs('InsightChartEngagementPage.getDataRole');
        }
    };
    const getDataActivities = async (pastDays = '183', optionRole) => {
        try {
                const startDate = window.COMMON_DATE.getDateFollowPastDate({ pastDays, mode: -1 });
                const params = {
                    input: {
                        startDate,
                        endDate: window.COMMON_DATE.getStrDateFilter(new Date(), 1)
                    }
                };
                if (companyId) {
                    params.input[`${companyType.toLowerCase()}Ids`] = [companyId]
                } else {
                    params.input.companyIds = companies.companyIds
                    params.input.influencerIds = companies.influencerIds
                    params.input.unionIds = companies.unionIds
                }
                const result = await window.COMMON.query(
                    AccountBalanceGRAPHQL.QUERY_ACTIVITY_INSIGHT,
                    params
                );
                let dataEngagement = [];
                if (result && result.data?.getActivityDataInsight) {
                    result.data.getActivityDataInsight.forEach(item => {
                        let active = {}
                        active.idCreator = item.createdBy._id
                        active.type = item.type
                        active.createdDate = window.COMMON_DATE.formatDate(item.createdDate, "MM/YYYY");
                        active.age = moment(new Date()).diff(moment(window.COMMON_DATE.formatDate(item.createdBy.birthday), 'DD/MM/YYYY'), 'year');
                        active.gender = item.createdBy.gender
                        active.genderText = item.createdBy.gender === 0 ? window.I18N('female') : window.I18N('male');
                        if (optionRole) {
                            active.roleText = window.COMMON.getValueFromAttr(window.COMMON.getObjectInArrs(item.createdBy.accountType, optionRole, 'code'), 'label');
                            if (active.roleText === '') {
                                active.roleText = item.createdBy.accountType
                            }
                        }
                        active.state = item.createdBy?.state?.name
                        active.city = item.createdBy?.city?.name
                        dataEngagement.push(active)
                    })
                    engagementData.current = dataEngagement
                    groupDataforChart(dataEngagement)
                }
        } catch (error) {
            console.log(error)
            window.COMMON.showErrorLogs('InsightChartEngagementPage.getActivity');
        }
    };
    useLayoutEffect(() => {
        setTimeout(() => {
            if (companyId && companyType) {
                getDataRole()
            } else if (companies ) {
                getDataActivities(dataDaysOfMonth.value);
            }
        }, 100);
        // eslint-disable-next-line
    }, [companyType, companyId, companies, dataDaysOfMonth]);
    return (
        <div className='mt-4 mx-auto'>
            <div className='mb-2 d-flex align-items-center justify-content-between'>
            <h6 className="m-0 font-weight-bold" style={{ fontSize: "1rem" }}>{`Engagement Chart Insight`}</h6>     
                    <div className='mb-2 d-flex align-items-center justify-content-start' style={{width: '30%', marginRight: '3%'}}>
                        <div className="input-group input-group-transparent">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <MDBIcon fa="true" icon="calendar" className="fa-fw" />
                                </span>
                            </div>
                            <ReactSelect
                                value={dataDaysOfMonth}
                                options={dataMonthsSelect}
                                className="md-form m-0"
                                placeholder={window.I18N("choose_option")}
                                onChange={handleSelectMonths}
                                styles={{ menuPortal: (base) => ({ ...base, fontSize: '14px'}) }}
                            />
                        </div>
                    </div>
            </div>
            <div className='d-flex align-items-center justify-content-center' style={{ position: 'relative' }}>
                <CustomChart typeChart='Line' dataChart={dataChart} createInnerHTMLPopup={createInnerHTMLPopup} ref={chartRef} displayLegend={true}/>
            </div>
            <div className='chart-title'>{`${window.I18N('engagement-chart')} ${dataDaysOfMonth.label}`}</div>
        </div>
    )
}
export default InsightChartEngagement