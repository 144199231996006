import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import { createUploadLink } from 'apollo-upload-client';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './App';
import history from './history';
import * as serviceWorker from './serviceWorker';
const URL_API = process.env.REACT_APP_URL_API;

// login
const linkLogin = new HttpLink({
  uri: URL_API,
  onError: ({ networkError, graphQLErrors }) => {
    console.log('graphQLErrors', graphQLErrors);
    console.log('networkError', networkError);
  }
});
const clientLogin = new ApolloClient({
  link: linkLogin,
  cache: new InMemoryCache()
});
window.API_LOGIN = clientLogin;

// service
const httpLink = createUploadLink({
  uri: URL_API,
  onError: ({ networkError, graphQLErrors }) => {
    console.log('graphQLErrors', graphQLErrors);
    console.log('networkError', networkError);
  }
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('authenToken');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : 'abc'
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});
window.API = client;

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router history={history}>
      <App />
    </Router>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
