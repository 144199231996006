import React, { useState, useCallback, useEffect } from 'react';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBIcon, MDBRow, MDBInputGroup } from 'mdbreact';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import QuestionGRAPHQL from '../../../graphql/Question';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Bar } from 'react-chartjs-2';
import { Grid } from "@material-ui/core"
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingSpinner from '../../components/LoadingSpinner';

const PAGE_SIZE= 10
const QuestionBankReportPage = (props) => {
    const config = [
        window.COMMON.createCol(
            window.I18N('created_by'),
            '15%',
            '',
            '',
            'createdBy.avatar,createdBy.nickname',
            'IMAGE_TEXT'
        ),
        window.COMMON.createCol(
            window.I18N('content'),
            '30%',
            'p-0 pl-1',
            '',
            'question',
            'TEXT'
        ),
        window.COMMON.createCol(
            window.I18N('category_name'),
            '20%',
            '',
            '',
            'questionCategory.title',
            'MULTI_LANGUAGE_CONTENT'
        ),       
        window.COMMON.createCol(
            window.I18N('created_date'),
            '10%',
            '',
            '',
            'createdDate',
            'TEXT'
        ),
        window.COMMON.createCol(
            window.I18N('total_clone'),
            '10%',
            '',
            '',
            'numberOfCloneVersion',
            'TEXT'
        ),
        window.COMMON.createCol(
            window.I18N('view_details'),
            '10%',
            '',
            '',
            '0',
            'BUTTON'
        ),
    ];
    const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
    const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
    const [dataReport,setDataReport] = useState([])
    const [pagination, setPagination] = useState({ total: 0, pageSize: 10, currentPage: 0 });
    const [object, setObject] = useState({});
    const [chartQuestion, setChartQuestion] = useState({item: null, index: -1})
    const [filter, setFilter] = useState({
        startDate: window.COMMON_DATE.addDaysFromNow(-91),
        endDate: new Date(),
        text:''
    });
    const [filterChart, setFilterChart] = useState({text:''})
    const [categories, setCategories] = useState([]);
    const [select, setSelect] = useState({
        category: null
    });
    const [isStillMore, setIsStillMore] = useState(true)
    const [dataQuestion, setDataQuestion] = useState([]);
    const [dataChartActivities, setDataChartActivities] = useState({});
    const handleDatePicker = (event, attr) => {
        filter[attr] = event;
        setFilter({ ...filter });
    };
    const checkValidFilter = () => {
        const startDateToNow = moment(new Date()).diff(moment(filter.startDate), 'days')
        const diffDay = moment(filter.endDate).diff(moment(filter.startDate), 'days')
        if ( startDateToNow <=0 || diffDay < 0) {
            return false
        }
        return true
    }

    const handleFilterText = (event) => {
        setFilter({...filter, text: event.target.value})
    }

    const handleFilterChartText = (event) => {
        setFilterChart({...filterChart, text: event.target.value})
    }

    const handleSearchText = () => {
        if (checkValidFilter()) {
            pagination.currentPage = 0
            getReportData()
        }
    }

    const handleSearchChartText = () => {
        getQuestionBankData()
    }

    const handleSelect = (event, id, attr) => {
        window.COMMON.checkSelectValidation(id, event);
        if (JSON.stringify(select[attr])  === JSON.stringify(event)){ return }
        select[attr] = event;
        getQuestionBankData()
        setSelect({ ...select });
        setChartQuestion({item: null, index: -1})
    };

    const showQuestionDetail = useCallback((index) => {
        window.COMMON.showModal('#modal-question-bank-report');
        setObject({ ...dataReport[index] });
    // eslint-disable-next-line
    }, [dataReport]);
    
    const changePage = useCallback((index) => {
        pagination.currentPage = index;
        getReportData();
        // eslint-disable-next-line
    }, [ pagination, filter]);

    const getReportData = async () => {
        try {
            window.resetDataTable('table-data');
            const input = {
                    pageSize: pagination.pageSize,
                    page: pagination.currentPage + 1,
                    startDate: window.COMMON_DATE.formatDate(filter.startDate, 'YYYY-MM-DD'),
                    endDate: window.COMMON_DATE.formatDate(filter.endDate, 'YYYY-MM-DD'),
                    question: filter.text
            };
            const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_QUESTION_BANK_REPORT, { input });
            let data = [];
            if (result?.data?.getQuestionBankReport) {
                data = window.COMMON.formatDate(result.data.getQuestionBankReport.data, 'createdDate'); 
                setDataReport(data)
                pagination.total = result?.data?.getQuestionBankReport.total
                setPagination({ ...pagination })
            }
            
        } catch (error) {
            window.COMMON.showErrorLogs('QuestionBankReportPage.getReportData');
        }
        window.initDataTable('table-data',false);
    }

    const getReportByCode = async (paramCode) => {
        try {
            const param = {
                code : paramCode
            };
            const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_QUESTION_REPORT_BY_CODE, param);
            if (result?.data?.getQuestionBankByCode) {
                getAtivitySummary(result.data.getQuestionBankByCode)
            }
        } catch (error) {
            window.COMMON.showErrorLogs('QuestionBankReportPage.getReportData');
        }
    }

    const getCategorydata = async () => {
    try {
        const params = {
                company: COMPANY_ID,
                companyType: COMPANY_TYPE
            };
        const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_QUESTION_CATEGORY, params);
        if (result?.data?.getAllQuestionCategories ) {
            const optionsCategory = result?.data?.getAllQuestionCategories.map(el => ({ value: el._id, label: getCategoryByLanguage(el.title) }))
            setCategories([...optionsCategory])
            select.category= optionsCategory[0]
            setSelect({ ...select })
            getQuestionBankData();
        }
    } catch (error) {
        window.COMMON.showErrorLogs('QuestionBankReportPage.getCategorydata');
        }
    };
    
    const chooseQuestion = (index) => {
        if (chartQuestion.index!==index){
            chartQuestion.index = index;
            chartQuestion.item = dataQuestion[index];
            setChartQuestion({ ...chartQuestion });
            getReportByCode(dataQuestion[index].code)
        }   
    }
    const getQuestionBankData = async () => {
        try {
            const input = {
                order: 'ASC',
                pagination: {
                    pageSize: PAGE_SIZE,
                    page: 1
                },
                question: { }
            }
            if (select.category?.value) {
                input.question.categoryId = select.category.value
            }
            if (filterChart.text) {
                input.question.name = filterChart.text
            }
            let data = []
            const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_QUESTION_BANK, {input});
            if (result?.data?.getQuestionBank) {
                data = window.COMMON.formatDate(result?.data?.getQuestionBank?.data, 'createdDate');
                if (data.length === result?.data?.getQuestionBank?.total) {
                    setIsStillMore(false)
                } else {
                    setIsStillMore(true);
                }
                setDataQuestion(data);
            }
        } catch (error) {
            window.COMMON.showErrorLogs('QuestionBankReportPage.getQuestionBankData');
        }
    };

    const fetchMoreData = async () => {
        try {
            const input = {
                order: 'ASC',
                pagination: {
                    pageSize: PAGE_SIZE,
                    page: parseInt(dataQuestion.length/PAGE_SIZE) +1
                },
                question: { }
            }
            if (select.category?.value) {
                input.question.categoryId = select.category.value
            }
            if (filterChart.text) {
                input.question.name = filterChart.text
            }
            let data = []
            const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_QUESTION_BANK, {input});
            if (result?.data?.getQuestionBank) {
                data = window.COMMON.formatDate(result?.data?.getQuestionBank?.data, 'createdDate');
                if (data.length + dataQuestion.length >= result?.data?.getQuestionBank?.total) {
                    setIsStillMore(false)
                } else {
                    setIsStillMore(true)
                }
                setTimeout(() => { setDataQuestion([...dataQuestion, ...data]);}, 100)
            }
        } catch (error) {
            window.COMMON.showErrorLogs('QuestionBank.getQuestionBankData');
        }
    }

    const getCategoryByLanguage = (multiLanguageText) => {
        if (!multiLanguageText || multiLanguageText.length===0) return ""
        const result = multiLanguageText.find(el => el.code === props.language?.code)?.text || multiLanguageText[0]?.text ||""
        return result
    }

    const getAtivitySummary = (data) => {
    const dataChartActivities = {
        labels: [window.I18N('QA'), window.I18N('score_card')],
        datasets: [{
        label: window.I18N('total_clone'),
        data: [data.useForGroupQuestion, data.useForSoftSkill],
        backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
        ],
        borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
        ],
        borderWidth: 1,
        barPercentage: 0.5
        }]
    };
    setDataChartActivities(dataChartActivities);
    };

    useEffect(() => {
        if (checkValidFilter()) {
            pagination.currentPage = 0
            getReportData() 
            getCategorydata()
        } else {
            window.COMMON.showMessage('warning', 'Error Date', 'invalid time or time interval is too long or too old');
        }
    }, [])

    return (
        <>
        <Grid container className='bg-white d-flex align-items-center justify-content-between w-100 rounded'>
            <Grid item >
                <Grid container className='px-3' justifyContent='center'>
                    <Grid item className="d-flex align-items-center py-4 pr-3" md={6} sm={4} xs={6} >
                        <div className={`input-group input-group-transparent ${checkValidFilter()?'':'border border-warning'}`}>
                            <div className="input-group-prepend ">
                                <span className="input-group-text ">
                                    <MDBIcon fa="true" icon="calendar-alt" className="fa-fw icon-sum" />
                                </span>
                            </div>
                            <DatePicker placeholderText={window.I18N('start_date') + ' *'} className="form-control" dateFormat="dd/MM/yyyy" showYearDropdown showMonthDropdown selectsStart
                                selected={filter.startDate}
                                onChange={(event) => handleDatePicker(event, 'startDate')}
                            />
                        </div>
                    </Grid>
                    <Grid item className="d-flex align-items-center py-4 pr-" md={6} sm={4} xs={6} >
                        <div className={`input-group input-group-transparent ${checkValidFilter()?'':'border border-warning'}`}>
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <MDBIcon fa="true" icon="calendar-alt" className="fa-fw icon-sum" />
                                </span>
                            </div>
                            <DatePicker placeholderText={window.I18N('end_date') + ' *'} className="form-control" dateFormat="dd/MM/yyyy" showYearDropdown showMonthDropdown selectsEnd
                                selected={filter.endDate}
                                onChange={(event) => handleDatePicker(event, 'endDate')} />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className="d-flex align-items-center px-3">
                <Grid container justifyContent='center' style={{ width: '350px' }}>
                    <MDBInputGroup type="text" value={filter.text} hint={window.I18N('search_name')} onChange={handleFilterText} maxLength="100"  required
                        append={
                            <span className="input-group-text cursor-pointer" onClick={handleSearchText}>
                                <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                            </span>
                        }
                    />
                </Grid>   
            </Grid>
            <Grid item xs={12}>
                <MDBRow>
                    <MDBCol
                        xl="12"
                        className="mb-4 wow fadeInRight animated"
                        data-wow-delay="0.5s"
                    >
                        <MDBCard >
                            <MDBCardHeader>{window.I18N('question_list')}</MDBCardHeader>
                            <MDBCardBody>
                                <Table
                                id="table-data"
                                className="table table-striped"
                                config={config}
                                data={dataReport}
                                language={props.language.code}
                                handleRowDetailClick={showQuestionDetail}
                                ></Table>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </Grid>
            <Grid item sm={12} style={{ margin: "auto" }}>
                <li className="list-group-item">
                    <Pagination total={pagination.total} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={changePage}></Pagination>
                </li>
            </Grid>
            <Modal hideSave={true} className="modal-xl" id="modal-question-bank-report" title={window.I18N('question_clone_detail')}>
                <div className='label-light rounded'>
                <Grid container className='font-weight-bold p-3'>
                    <Grid item xs={3}>
                        <Grid container>{window.I18N('id')}</Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container>{window.I18N('origin_content')}</Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container>{window.I18N('category_name')}</Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Grid container>{window.I18N('total_clone')}</Grid>
                    </Grid>
                </Grid>
                <Grid container className='pb-3 px-3'>
                    <Grid item xs={3}>
                        <Grid container>{object?._id}</Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container>{object?.question}</Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container>{getCategoryByLanguage(object?.questionCategory?.title)}</Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Grid container>{object?.numberOfCloneVersion}</Grid>
                    </Grid>
                </Grid>
                </div>
                {object?.useForGroupQuestion > 0 && <div className='pt-3'>
                        <Grid container className='font-weight-bold mb-1 container-bg-login p-2 rounded'>{window.I18N('total_clone')} {window.I18N('QA')}: {object?.useForGroupQuestion }</Grid>
                    <Grid item className='pl-3'>
                        <Grid container className='font-weight-bold mb-1'>
                            <Grid item xs={3}>
                                <Grid container>{window.I18N('created_by')}</Grid>
                            </Grid>
                            <Grid item xs={3}>
                                    <Grid container>{window.I18N('group_qa')}</Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container>{window.I18N('clone_content')}</Grid>
                            </Grid>
                            <Grid item xs={2}>
                                <Grid container>{window.I18N('created_date')}</Grid>
                            </Grid>
                        </Grid>
                        {object.cloneQuestion.filter(el => el.group.type === 1).map((cloneQuestion, index)=>
                            <Grid key={index} container className={`p-1 ${index===0?'':"border-top"}`}>
                                <Grid item xs={3}>
                                    <Grid container>{cloneQuestion.createdBy.name }</Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Grid container>{getCategoryByLanguage(cloneQuestion.group.name)}</Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container>{getCategoryByLanguage(cloneQuestion.question)}</Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid container>{window.COMMON_DATE.formatDate(cloneQuestion.createdDate)}</Grid>
                                </Grid>
                            </Grid>
                    )}
                    </Grid> 
                </div>}
                {object?.useForSoftSkill > 0 && <div className='pt-3'>
                        <Grid container className='font-weight-bold mb-1 container-bg-login p-2 rounded'>{window.I18N('total_clone')} {window.I18N('score_card')}: {object?.useForSoftSkill }</Grid>
                    <Grid item className='pl-3'>
                        <Grid container className='font-weight-bold mb-1'>
                            <Grid item xs={3}>
                                <Grid container>{window.I18N('created_by')}</Grid>
                            </Grid>
                            <Grid item xs={3}>
                                    <Grid container>{window.I18N('group_qa')}</Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container>{window.I18N('clone_content')}</Grid>
                            </Grid>
                            <Grid item xs={2}>
                                <Grid container>{window.I18N('created_date')}</Grid>
                            </Grid>
                        </Grid>
                        {object.cloneQuestion.filter(el => el.group.type === 2).map((cloneQuestion,index)=>
                            <Grid key={index} className={`p-1 ${index===0?'':"border-top"}`} container>
                                <Grid item xs={3}>
                                    <Grid container>{cloneQuestion.createdBy.name }</Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Grid container>{getCategoryByLanguage(cloneQuestion.group.name)}</Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container>{getCategoryByLanguage(cloneQuestion.question)}</Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid container>{window.COMMON_DATE.formatDate(cloneQuestion.createdDate)}</Grid>
                                </Grid>
                            </Grid>
                    )}
                    </Grid> 
                </div>}
            </Modal>
        </Grid>
        <Grid container className='mt-3 mb-1 bg-white rounded'>
            <div className="p-3 rounded d-flex align-items-center justify-content-between w-100" style={{ zIndex: 1 }}>
                <div className="bd-highlight mr-4" style={{ width: '350px' }}>
                    <div className="input-group input-group-transparent">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <MDBIcon fa="true" icon="code-branch" className="fa-fw" />
                            </span>
                        </div>
                        <Select id='select-category' className="md-form m-0" placeholder={window.I18N('choose_category')} value={select.category} options={categories} onChange={(event) => handleSelect(event, 'select-category', 'category')} isSearchable />
                    </div>
                </div>
                <div className="mr-2 ml-auto" style={{ width: '400px' }}>
                    <MDBInputGroup type="text" value={filterChart.text} hint={window.I18N('search_name')} onChange={handleFilterChartText} maxLength="100" pattern="\S(.*\S)?" required
                        append={
                            <span className="input-group-text cursor-pointer" onClick={handleSearchChartText}>
                                <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                            </span>
                        }
                    />
                </div>
            </div>
            <Grid container className='p-1'>
                <Grid item xs={6} id='infinity-scroll' style={{ height: "470px", overflow: "auto" }}>
                    <InfiniteScroll
                        dataLength={dataQuestion.length}
                        next={fetchMoreData}
                        hasMore={isStillMore}
                        loader={<div className='text-center py-2 '><LoadingSpinner /></div>}
                        height={450}
                        style={{ width: "100%" }}
                        scrollableTarget='infinity-scroll'
                        endMessage={
                            <p className='text-center py-2 '>
                                <b>{window.I18N('no_more_question')}</b>
                            </p>
                        }
                    >
                        <div className="container-list-question mt-3">
                            {dataQuestion.map((question, i) =>
                            <div className={'item d-flex align-items-center' + (i === chartQuestion.index ? ' active' : '')} key={i} onClick={() => chooseQuestion(i)}>
                                <MDBIcon fa="true" icon="circle" className="fa-fw mr-2" style={{ color: '#10C986' }} />
                                <span>{(i + 1) + '. ' + question.question}</span>
                                <div className="ml-auto align-self-center">
                                        <span className="badge badge-primary badge-pill">{question.type }</span>
                                </div>
                            </div>
                            )}  
                        </div>
                    </InfiniteScroll>
                </Grid>
                <Grid item xs={6}>
                    <MDBCard className='mx-auto' style={{ height: '310px', width: "80%" }}>
                        <MDBCardHeader className='mx-auto mt-2'>{window.I18N('question_clone_chart')}</MDBCardHeader>
                        <MDBCardBody>
                            <Bar height={136} data={dataChartActivities} options={{
                                legend: { display: false },
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: { yAxes: [{
                                    ticks: {
                                        beginAtZero: true,
                                        stepSize: 1
                                    }
                                }] }
                            }}></Bar>
                        </MDBCardBody>
                    </MDBCard>
                        {chartQuestion.item &&
                            <div style={{width: "80%" }}  className="alert alert-warning mx-auto" role="alert">
                                <p className="mb-1 font-weight-bold" style={{ color: '#FC9905' }}>
                                    <span>{window.I18N('answer')}</span>
                                </p>
                                {chartQuestion.item?.choices?.map((answer,i) =>
                                    <div key={i} className='p-2'>{i + 1}. {getCategoryByLanguage(answer.content)}</div>
                                )} 
                            </div>
                        }
                </Grid>
            </Grid>
        </Grid>
    </>
    )
}

const mapStateToProps = state => ({
    language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionBankReportPage);
