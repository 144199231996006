import React, { PureComponent } from 'react';
import { MDBCardImage, MDBBtn, MDBIcon } from 'mdbreact';
import LazyLoad from 'react-lazyload';

const Spinner = () => (
  <div className="post loading">
    <svg
      width="40"
      height="40"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid">
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#49d1e0"
        strokeWidth="10"
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138"
        transform="rotate(275.845 50 50)">
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="linear"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"/>
      </circle>
    </svg>
  </div>
);

class ListRating extends PureComponent {
  render() {
    const html = this.props.data.map((item, i) => 
      <LazyLoad key={i} height={100} offset={[-100, 100]} placeholder={<Spinner />}>
        <div className="list-group-item list-group-item-action p-2">
          <div className="media">
            <div className="media-left align-self-center p-1">
              <strong>{ i + 1 }. </strong>
              { window.COMMON.getValueFromAttr(item, this.props.attr_image) === '' ? null 
                : <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, this.props.attr_image))} className="image-icon rounded-circle mr-1"/> }
            </div>
            <div className="media-body align-self-center">
              <p className="m-0">{window.COMMON.getValueFromAttr(item, this.props.attr_content)}</p>
            </div>
            <div className="media-right align-self-center">
              <MDBIcon fa="true" icon="user" className="fa-fw ml-1"></MDBIcon> <span className="mr-3">{window.COMMON.getValueFromAttr(item, this.props.attr_total)}</span>
              <MDBBtn color={window.COMMON.checkStar(window.COMMON.getValueFromAttr(item, this.props.attr_average))} className="btn-sm m-0">
                <strong className="mr-1">{window.COMMON.getValueFromAttr(item, this.props.attr_average)}</strong>
                <MDBIcon fa="true" icon="star" className="fa-fw"></MDBIcon>
              </MDBBtn>
            </div>
          </div>
        </div>
      </LazyLoad>
    );

    return (
      <div className="container-list-rating"> {html}</div>
    );
  }
}

export default ListRating;