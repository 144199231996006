import React, { useState, useLayoutEffect } from 'react';
import FanpageGRAPHQL from '../../graphql/Fanpage';
import { Settings,Create, Cancel, Save, Public, Lock } from '@material-ui/icons';
import { Box, Button, FormControl, FormControlLabel, Grid, Modal, Radio, RadioGroup, Switch, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import image from '../../styles/images/icons/ic_of_facebook.png'
import InputFile from './InputFile';
import { MDBCardImage } from 'mdbreact';
import Constant from '../../utils/Constant';
import { connect } from 'react-redux';
import { useTracking } from 'react-tracking';
import eventName from '../../common/events';

const EDIT_AUTO_APPROVE_MEMBER = 1
const EDIT_HIDE_FANPAGE = 2
const EDIT_AVATAR = 3
const EDIT_FAPAGE_NAME = 4
const EDIT_FANPAGE_GOPIES = 5
const EDIT_FANPAGE_QUESTIONS = 6
const EDIT_AUTO_APPROVE_POST = 7
const useStyles = makeStyles({
    modalBox: {
        width: "90%",
		position: 'absolute',
		top: '50%',
		left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        display: "flex",
        flexDirection:"column",
        alignItems: "center",
        borderRadius: "5px",
        padding: "20px 20px 40px 20px",
        "@media (min-width: 800px)": {
            width: "600px"
        }
    },
});
const FanpageSetting = ({ fanpageId, reloadParent, reloadHeader }) => {
    const classes = useStyles();
    const [dataModal, setDataModal] = useState({open: false, show: -1})
    const [dataFanpage, setDataFanpage] = useState({ name: "", image: "", description: "", isApproveMember: '', isHide: '', gopy: 0, type: "", isApprovePost: '' })
    const [dataSave, setDataSave] = useState({ name: "", image: "", description: "", isApproveMember: '', isHide: '', gopy: 0, type: "",subQuestion1: '',subQuestion2: '',subQuestion3: '', isApprovePost: '' })
    const [file, setFile] = useState(null);
    const { trackEvent } = useTracking();

    const createTrackingEvent = (event) => {
        return trackEvent({
            name: event,
            createdAt: new Date().toISOString()
        });
    };

    const getData = async () => {
        try {
            const result = await window.COMMON.query(FanpageGRAPHQL.QUERY_FANPAGE_DETAIL, { id: fanpageId })
            if (result?.data) {
                setDataFanpage(result.data.getFanpageDetail)
                setDataSave({
                    name: result.data.getFanpageDetail.name,
                    image: result.data.getFanpageDetail.image,
                    description: result.data.getFanpageDetail.description,
                    isApproveMember: Boolean(result.data.getFanpageDetail.isApproveMember),
                    isApprovePost: Boolean(result.data.getFanpageDetail.isApprovePost),
                    isHide: Boolean(result.data.getFanpageDetail.isHide),
                    type: result.data.getFanpageDetail.type,
                    gopy: result.data.getFanpageDetail.gopy,
                    subQuestion1: result.data.getFanpageDetail.subQuestion1,
                    subQuestion2: result.data.getFanpageDetail.subQuestion2,
                    subQuestion3: result.data.getFanpageDetail.subQuestion3,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleChangeText = (event) => {
        dataSave[event.target.name] = event.target.value
        if (event.target.name === "type" && event.target.value === Constant.FANPAGE.PUBLIC) {
            dataSave.gopy = 0
        }
        if (event.target.name === "type" && event.target.value === Constant.FANPAGE.PRIVATE) {
            dataSave.gopy = dataFanpage.gopy
        }
        setDataSave({ ...dataSave })
    }

    const toggleChecked = (name) => {
        dataSave[name] = !dataSave[name]
        setDataSave({ ...dataSave })
    };
    const onEditClick = (mode) => {
        dataModal.show = mode
        dataModal.open= true
        setDataModal({ ...dataModal })
    }

    const handleFile = (file) => {
        dataSave.image = window.COMMON.createBlobImage(file);
        setFile(file);
        setDataSave({ ...dataSave });
    };

    const onCloseModal = () => {
        setDataSave({
            name: dataFanpage.name,
            image: dataFanpage.image,
            description: dataFanpage.description,
            isApproveMember: dataFanpage.isApproveMember,
            isApprovePost: dataFanpage.isApprovePost,
            isHide: dataFanpage.isHide,
            gopy: dataFanpage.gopy,
            type: dataFanpage.type,
            subQuestion1: dataFanpage.subQuestion1,
            subQuestion2: dataFanpage.subQuestion2,
            subQuestion3 : dataFanpage.subQuestion3
        })
        setDataModal({open:false, show: -1})
    }

    const onSaveBtn = async() => {
        try {
            let result
            const params = {
                id: fanpageId
            };
            if (dataModal.show === EDIT_AUTO_APPROVE_MEMBER) {
                params.isApproveMember = dataSave.isApproveMember
                result = await window.COMMON.mutation(FanpageGRAPHQL.MUTATION_EDIT_APPROVE_MEMBER_FANPAGE, params)
            }else if (dataModal.show === EDIT_AUTO_APPROVE_POST) {
                params.isApprovePost = dataSave.isApprovePost
                result = await window.COMMON.mutation(FanpageGRAPHQL.MUTATION_EDIT_APPROVE_POST_FANPAGE, params)
            } else if (dataModal.show === EDIT_HIDE_FANPAGE) {
                params.isHide = dataSave.isHide
                result = await window.COMMON.mutation(FanpageGRAPHQL.MUTATION_EDIT_HIDE_FANPAGE, params)
            }else if (dataModal.show === EDIT_FAPAGE_NAME || dataModal.show === EDIT_FANPAGE_QUESTIONS) {
                params.name = dataSave.name
                params.description = dataSave.description
                params.subQuestion1 = dataSave.subQuestion1
                params.subQuestion2 = dataSave.subQuestion2
                params.subQuestion3 = dataSave.subQuestion3
                result = await window.COMMON.mutation(FanpageGRAPHQL.MUTATION_EDIT_INFO_FANPAGE, params)
            }else if (dataModal.show === EDIT_FANPAGE_GOPIES) {
                params.type = dataSave.type
                params.gopy = parseInt(dataSave.gopy)
                result = await window.COMMON.mutation(FanpageGRAPHQL.MUTATION_EDIT_GOPY_FANPAGE, params)
            } else if (dataModal.show === EDIT_AVATAR) {
                let dataFiles = [];
                if (file) {
                    dataFiles = await window.uploadFile([file]);
                    if (dataFiles.length > 0) {
                        dataSave.image = window.COMMON.getNewPathFile(dataFiles[0]._id);
                    }
                }
                setFile(null);
                params.image = dataSave.image
                result = await window.COMMON.mutation(FanpageGRAPHQL.MUTATION_EDIT_IMAGE_FANPAGE, params)
            }
            if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
                createTrackingEvent(eventName.EDIT_FANPAGE);
                getData()
                onCloseModal()
                reloadParent()
                reloadHeader(fanpageId)
            } else {
                onCloseModal()
            }
            
        } catch (error) {
            onCloseModal()
            console.log(error)
        }
    }

    useLayoutEffect(() => {
        setTimeout(() => {
            if (fanpageId) {
                getData()
            }
        }, 100);
    }, [fanpageId]);

    return (
        <div >
            <div className='rounded bg-white px-4 pb-4 pt-2'>
                <Grid className='section-div-fanpage' container>
                    <Settings className=" mr-3"></Settings>
                    {window.I18N("settings")}
                </Grid>
                <Grid className=' fanpage-seting-div'>
                    <Grid className='my-auto' item>{window.I18N("auto_approve_member")} </Grid>
                    <Switch checked={Boolean(dataFanpage?.isApproveMember)} onClick={()=>{onEditClick(EDIT_AUTO_APPROVE_MEMBER)}} color="primary"></Switch>
                </Grid>
                <hr style={{width:"90%"}} className='my-1'></hr>
                <Grid className=' fanpage-seting-div'>
                    <Grid className='my-auto' item>{window.I18N("auto_approve_post")} </Grid>
                    <Switch checked={Boolean(dataFanpage?.isApprovePost)} onClick={()=>{onEditClick(EDIT_AUTO_APPROVE_POST)}} color="primary"></Switch>
                </Grid>
                <hr style={{width:"90%"}} className='my-1'></hr>
                <Grid className='fanpage-seting-div'>
                    <Grid item className='my-auto'>{window.I18N("hide_fanpage")}</Grid>
                    <Switch checked={Boolean(dataFanpage?.isHide)} onClick={()=>{onEditClick(EDIT_HIDE_FANPAGE)}} color="primary"></Switch>
                </Grid>
                
            </div>
            <div className='rounded bg-white mt-4 px-4 pb-4 pt-2'>
                <Grid container className={'section-div-fanpage'}>
                        <i style={{ backgroundImage: `url(${image})`, backgroundPosition: "0px -260px", backgroundSize: "25px 496px"}}
                            className='icon-fanpange'
                        ></i>
                        {window.I18N("customize_fanpage")}
                </Grid>
                <Grid className='fanpage-seting-div py-3'>
                    <Grid >{window.I18N("fanpage_image")}</Grid>
                    <Create role="button" onClick={()=>{onEditClick(EDIT_AVATAR)}} ></Create>
                </Grid>
                <hr style={{width:"90%"}} className='my-1'></hr>
                <Grid className=' fanpage-seting-div py-3'>
                    <Grid>{window.I18N("name_and_description")}</Grid>
                    <Create role="button" onClick={()=>{onEditClick(EDIT_FAPAGE_NAME)}} ></Create>
                </Grid>
                <hr style={{width:"90%"}} className='my-1'></hr>
                <Grid className=' fanpage-seting-div py-3'>
                    <Grid>{window.I18N("fanpage_questions")}</Grid>
                    <Create role="button" onClick={()=>{onEditClick(EDIT_FANPAGE_QUESTIONS)}} ></Create>
                </Grid>
                <hr style={{width:"90%"}} className='my-1'></hr>
                <Grid className=' fanpage-seting-div py-3'>
                    <Grid>{window.I18N("need_gopies")}</Grid>
                    <Create role="button" onClick={()=>{onEditClick(EDIT_FANPAGE_GOPIES)}} ></Create>
                </Grid>
            </div>
            <Modal
				open={dataModal?.open}
				onClose={onCloseModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
            >
                <Box className={classes.modalBox}>

                    {dataModal.show === EDIT_AUTO_APPROVE_MEMBER && <>
                        <Grid component="h3" className=' my-2 justify-content-center'>{window.I18N("confirm_change")}</Grid>
                        <Grid container className=' justify-content-around my-4'>
                            <Grid component="h6" className='my-auto font-weight-bolder' item>{window.I18N("auto_approve_member")} </Grid>
                            <Switch checked={dataSave.isApproveMember} onClick={() => toggleChecked('isApproveMember')} color="primary"></Switch>
                        </Grid>
                        </>
                    }

                    {dataModal.show === EDIT_AUTO_APPROVE_POST && <>
                        <Grid component="h3" className=' my-2 justify-content-center'>{window.I18N("confirm_change")}</Grid>
                        <Grid container className=' justify-content-around my-4'>
                            <Grid component="h6" className='my-auto font-weight-bolder' item>{window.I18N("auto_approve_post")} </Grid>
                            <Switch checked={dataSave.isApprovePost} onClick={() => toggleChecked('isApprovePost')} color="primary"></Switch>
                        </Grid>
                        </>
                    }

                    {dataModal.show === EDIT_HIDE_FANPAGE &&<>
                        <Grid component="h3" className='my-2 justify-content-center'>{window.I18N("confirm_change")}</Grid>
                        <Grid container className=' justify-content-around my-4'>
                            <Grid component="h6" className='my-auto font-weight-bolder' item>{window.I18N("hide_fanpage") }</Grid>
                            <Switch checked={dataSave.isHide} onClick={()=>toggleChecked('isHide')} color="primary"></Switch>
                        </Grid>
                    </>}

                    {dataModal.show === EDIT_FAPAGE_NAME && <>
                        <Grid component="h3" className=' my-2 justify-content-center'>{window.I18N("confirm_change")}</Grid>
                        <Grid container >
                            <TextField multiline={true} style={{width:"80%"}} className='my-4 mx-auto' label={window.I18N('fanpage_name')} variant="outlined"  name="name" value={dataSave.name} onChange={handleChangeText}></TextField>
                        </Grid>
                        <Grid container >
                            <TextField style={{width:"80%"}} multiline={true} minRows={3} className='my-4 mx-auto' label={window.I18N('fanpage_description')} variant="outlined"  name="description" value={dataSave.description} onChange={handleChangeText}></TextField>
                        </Grid>
                    </>}
                    {dataModal.show === EDIT_AVATAR &&<>
                        <Grid component="h3" className=' my-2 justify-content-center'>{window.I18N("confirm_change")}</Grid>
                        <MDBCardImage style={{width:"410px", height:"200px", objectFit:"cover"}} className="image-logo mt-3" src={dataSave.image} hover overlay="white-slight"/>
                        <Grid container className='justify-content-center'>
                            <InputFile id="file-avatar" icon="upload" handleFile={handleFile} accept="image/*" description={`${window.I18N("fanpage_image")} (aspect ratio 2:1)`}></InputFile>
                        </Grid>
                        </>
                    }
                    {dataModal.show === EDIT_FANPAGE_QUESTIONS &&<>
                        <Grid component="h3" className=' my-2 justify-content-center'>{window.I18N("confirm_change")}</Grid>
                        <Grid container >
                            <TextField style={{width:"80%"}} multiline={true} className='my-4 mx-auto' label={window.I18N('subQuestion1')} variant="outlined"  name="subQuestion1" value={dataSave.subQuestion1|| ""} onChange={handleChangeText}></TextField>
                        </Grid>
                        <Grid container >
                            <TextField style={{width:"80%"}} multiline={true} className='my-4 mx-auto' label={window.I18N('subQuestion2')} variant="outlined"  name="subQuestion2" value={dataSave.subQuestion2 || ""} onChange={handleChangeText}></TextField>
                        </Grid>
                        <Grid container >
                            <TextField style={{width:"80%"}} multiline={true} className='my-4 mx-auto' label={window.I18N('subQuestion3')} variant="outlined"  name="subQuestion3" value={dataSave.subQuestion3 || ""} onChange={handleChangeText}></TextField>
                        </Grid>
                        </>
                    }

                    {dataModal.show === EDIT_FANPAGE_GOPIES &&<>
                        <Grid component="h3" className=' my-2 justify-content-center'>{window.I18N("confirm_change")}</Grid>
                        <Grid container className=' my-2 justify-content-center'>
                            <Grid component="h6" className='font-weight-bolder mr-4 pr-4 pt-2' item>{window.I18N("privacy")}</Grid>
                            <FormControl component="fieldset">
                                <RadioGroup name="type" value={dataSave.type} onChange={handleChangeText}>
                                    <FormControlLabel
                                        disabled={dataFanpage.type === Constant.FANPAGE.PRIVATE}
                                        value={Constant.FANPAGE.PUBLIC}
                                        control={<Radio className='mb-1'></Radio>}
                                        label={<>
                                            <Public className='mr-1 mb-1'></Public>{window.I18N("public_fanpage")}
                                        </>}>
                                    </FormControlLabel>
                                    <FormControlLabel
                                        disabled={dataFanpage.type === Constant.FANPAGE.PRIVATE}
                                        value={Constant.FANPAGE.PRIVATE}
                                        control={<Radio></Radio>}
                                        label={<>
                                            <Lock className='mr-1 mb-1'></Lock>{window.I18N("private_fanpage")}</>
                                        }>
                                    </FormControlLabel>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid container>
                            <TextField disabled={dataSave.type === Constant.FANPAGE.PUBLIC} style={{ width: "50%" }} label={window.I18N("need_gopies")} type='number' className='my-4 mx-auto' variant="outlined" name="gopy" value={dataSave.gopy} onChange={handleChangeText}></TextField>
                        </Grid>
                    </>}

                    <Grid container className="mx-8 mt-4 w-full justify-content-end">
                        <Button
                            className='text-capitalize'
                            variant="contained"
                            color="primary"
                            onClick={onCloseModal}
                        >
                            <Cancel /> &nbsp; {window.I18N("btn_cancel")}
                        </Button>
                        <Button
                            className='ml-4 text-capitalize'
                            variant="contained"
                            color="primary" 
                            disabled = {dataSave.name=== dataFanpage.name&&
                                        dataSave.image=== dataFanpage.image&&
                                        dataSave.description=== dataFanpage.description&&
                                        dataSave.isApproveMember=== dataFanpage.isApproveMember&&
                                        dataSave.isApprovePost=== dataFanpage.isApprovePost&&
                                        dataSave.isHide=== dataFanpage.isHide&&
                                        dataSave.gopy == dataFanpage.gopy &&
                                        dataSave.type === dataFanpage.type &&
                                        dataSave.subQuestion1 === dataFanpage.subQuestion1 &&
                                        dataSave.subQuestion2 === dataFanpage.subQuestion2 &&
                                        dataSave.subQuestion3 === dataFanpage.subQuestion3
                                    }
                            onClick={onSaveBtn}
                        >
                            <Save /> &nbsp; {window.I18N("btn_save")}
                        </Button>
                    </Grid>
				</Box>
			</Modal>
        </div>
    )
}

const mapStateToProps = state => ({
    language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FanpageSetting);
