import gql from 'graphql-tag'

const MUTATION_CREATE_TRACKING = gql`
    mutation ($session: String, $events: [EventsInput]) {
        createQTracking(input: { session: $session, events: $events }) {
            _id
            session
            events {
                name
            }
            createdDate
            createdBy {
                _id
                name
            }
        }
    }
`

const QUERY_EVENTS_TRACKING = gql`
    query ($input: EventTrackingFilter!) {
        getEventTracking(input: $input) {
            _id
            platform
            eventId
            company {
                _id
                type
                information {
                    _id
                    avatar
                    email
                    name
                    nickname
                    companyType {
                        _id
                        value
                    }
                    companySize {
                        _id
                        value
                    }
                    companyIndustry {
                        _id
                        code
                        value
                    }
                    nation {
                        _id
                        code
                        name
                    }
                    city {
                        _id
                        code
                        name
                    }
                    state {
                        _id
                        code
                        name
                    }
                    phone
                    address
                    bio
                    taxCode
                    organizationCode
                    zipcode
                    website
                    representative
                    representativeEmail
                    representativePhone
                    representativePosition
                    package {
                        _id
                    }
                }
            }
            union {
                _id
                type
                information {
                    _id
                    avatar
                    email
                    name
                    nickname
                    companyType {
                        _id
                        value
                    }
                    companySize {
                        _id
                        value
                    }
                    companyIndustry {
                        _id
                        code
                        value
                    }
                    nation {
                        _id
                        code
                        name
                    }
                    city {
                        _id
                        code
                        name
                    }
                    state {
                        _id
                        code
                        name
                    }
                    phone
                    address
                    bio
                    taxCode
                    organizationCode
                    zipcode
                    website
                    representative
                    representativeEmail
                    representativePhone
                    representativePosition
                    package {
                        _id
                    }
                }
            }
            influencer {
                _id
                type
                information {
                    _id
                    avatar
                    email
                    name
                    nickname
                    companyType {
                        _id
                        value
                    }
                    companySize {
                        _id
                        value
                    }
                    companyIndustry {
                        _id
                        code
                        value
                    }
                    nation {
                        _id
                        code
                        name
                    }
                    city {
                        _id
                        code
                        name
                    }
                    state {
                        _id
                        code
                        name
                    }
                    phone
                    address
                    bio
                    taxCode
                    organizationCode
                    zipcode
                    website
                    representative
                    representativeEmail
                    representativePhone
                    representativePosition
                    package {
                        _id
                    }
                }
            }
            postId
            commentId
            notificationId
            groupQuestionId
            accountId
            groupChatId
            menuSurveyId
            learningId
            menuLearningId
            rewardId
            code
            content
            description
            createdDate
            createdBy {
                _id
                email
                name
                nickname
                avatar
                phone
                gender
                birthday
                address
                accountType
                taxCode
                bio
                role {
                    _id
                }
                companies {
                    _id
                }
                unions {
                    _id
                }
                nation {
                    _id
                    code
                    name
                }
                city {
                    _id
                    code
                    name
                }
                state {
                    _id
                    code
                    name
                }
                status
                createdDate
            }
        }
    }
`

const TrackingGRAPHQL = {
    MUTATION_CREATE_TRACKING,
    QUERY_EVENTS_TRACKING
}

export default TrackingGRAPHQL
