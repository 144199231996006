import React, { useState, useLayoutEffect } from 'react';
import { MDBInput, MDBIcon, MDBRow, MDBCol } from 'mdbreact';

import AttributeView from './AttributeView';

import QuestionGRAPHQL from '../../graphql/Question';
import SelfAssessmentGRAPHQL from '../../graphql/SelfAssessment';

const bg = [ 'bg-danger', 'bg-warning' , 'bg-info', '', 'bg-success'];

const ItemPreviewSelfAssessment = React.memo(function ItemPreviewSelfAssessment(props) {
  const [language, setLanguage] = useState();
  const [object, setObject] = useState({ company: null });
  const [data, setData] = useState([]);
  const [dataCompare, setDataCompare] = useState([]);
  const [dataAnswer, setDataAnswer] = useState([]);

  useLayoutEffect(() => {
    setLanguage(props.language);
    setTimeout(() => {
      if (props.id) {
        getDataFeedback(props.id);
      } else {
        setData([]);
      }
      if (props.compareId) {
        getDataFeedbackCompare(props.compareId);
      } else {
        setDataCompare([]);
      }
    }, 100);
    // eslint-disable-next-line
  }, [props.language, props.id, props.compareId, props.type]);

  const handleChange = (event, row, col) => {
    if (props.type === 1) {
      dataCompare[row].feedback[col][event.target.name] = event.target.value;
      setDataCompare([ ...dataCompare ]);
      if (props.handleChange) {
        props.handleChange(dataCompare);
      }
    } else {
      data[row].feedback[col][event.target.name] = event.target.value;
      setData([ ...data ]);
      if (props.handleChange) {
        props.handleChange(data);
      }
    }
  };

  const getDataFeedback = async (id) => {
    try {
      const params = {
        id
      };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_FIND_TASK_SELF_ASSESSMENT_BY_ID, params);
      if (result && result.data && result.data.findTaskSelfAssessment) {
        const obj = result.data.findTaskSelfAssessment;
        getDataAnswers(obj.items || []);
        object.company = window.COMMON.getValueFromAttr(obj, 'company.information', null);
        setObject(object);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('ItemPreviewSelfAssessment.getDataFeedback');
    }
  };

  const getDataFeedbackCompare = async (id) => {
    try {
      const params = {
        id
      };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_FIND_TASK_SELF_ASSESSMENT_BY_ID, params);
      if (result && result.data && result.data.findTaskSelfAssessment) {
        const obj = result.data.findTaskSelfAssessment;
        setDataCompare(obj.items || []);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('ItemPreviewSelfAssessment.getDataFeedbackCompare');
    }
  };

  const getDataAnswers = async (data) => {
    try {
      const groupIds = [];
      data.forEach(item => {
        groupIds.push(item.group._id);
      });
      const params = {
        groupIds
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_ANSWER_QUESTION_BY_GROUP, params);
      if (result && result.data && result.data.getAnswerQuestionsByGroup) {
        setDataAnswer(result.data.getAnswerQuestionsByGroup);
        setData(data);
        if (props.handleChange) {
          props.handleChange(data);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('ItemPreviewSelfAssessment.getDataFeedback');
    }
  };

  const generateAnswer = (item, question) => {
    const answers = JSON.parse(JSON.stringify(window.COMMON.getArrsInArrs(question._id, dataAnswer, 'question')));
    if (item) {
      answers.forEach(answer => {
        const obj = window.COMMON.getObjectInArrs(answer._id, item.answers, '_id');
        if (obj) {
          answer.selected = true;
        } else {
          answer.selected = false;
        }
      });
    }
    const type = question.type;
    if (type === 'TEXT') {
      return <p>{window.COMMON.getValueFromAttr(item, 'answerText')}</p>;
    } else if (type === 'RADIO') {
      return answers.map((answer) => <div key={answer._id} className="d-inline-block mr-3">
        <MDBIcon far icon={answer.selected ? 'dot-circle' : 'circle'} className="fa-fw"></MDBIcon> {window.COMMON.getValueWithLanguage(answer, 'content', language)}
      </div>);
    } else if (type === 'CHECKBOX') {
      return answers.map((answer) => <div key={answer._id} className="d-inline-block mr-3">
        <MDBIcon far icon={answer.selected ? 'check-square' : 'square'} className="fa-fw"></MDBIcon> {window.COMMON.getValueWithLanguage(answer, 'content', language)}
      </div>);
    } else if (type === 'RADIO_GRID') {
      const parent = answers.filter(function(item) {
        return window.COMMON.getValueFromAttr(item, 'parentCode', null) === null;
      });
      return parent.map((answer) => <div key={answer._id} className="w-100 mb-1">
        <span className="mr-3">{window.COMMON.getValueWithLanguage(answer, 'content', language)}: </span>
        {window.COMMON.getArrsInArrs(answer.code, answers, 'parentCode').map((child) => <div key={child._id} className="d-inline-block mr-3">
          <MDBIcon far icon={child.selected ? 'dot-circle' : 'circle'} className="fa-fw"></MDBIcon> {window.COMMON.getValueWithLanguage(child, 'content', language)}
        </div>)}
      </div>);
    } else if (type === 'CHECKBOX_GRID') {
      const parent = answers.filter(function(item) {
        return window.COMMON.getValueFromAttr(item, 'parentCode', null) === null;
      });
      return parent.map((answer) => <div key={answer._id} className="w-100 mb-1">
        <span className="mr-3">{window.COMMON.getValueWithLanguage(answer, 'content', language)}: </span>
        {window.COMMON.getArrsInArrs(answer.code, answers, 'parentCode').map((child) => <div key={child._id} className="d-inline-block mr-3">
          <MDBIcon far icon={child.selected ? 'check-square' : 'square'} className="fa-fw"></MDBIcon> {window.COMMON.getValueWithLanguage(child, 'content', language)}
        </div>)}
        <br></br>
      </div>);
    } else {
      return <div className="progress mb-1" style={{ height: '30px' }}>
        {
          answers.map((answer, i) => <div key={answer._id} className={'progress-bar progress-bar-striped progress-bar-animated w-100 ' + bg[i % 5] + (answer.selected ? ' active' : '')} role="progressbar">
            {window.COMMON.getValueWithLanguage(answer, 'content', props.language)}
          </div>)
        }
      </div>;
    }
  };

  const generateAnswerLayout = (child, question, i, j, isEnable) => {
    return <>
      { generateAnswer(child, question) }
      {
        window.COMMON.getValueFromAttr(child, 'documents', []).map((item) => <p key={item.id} className="mb-1 mt-1" style={{ cursor: 'pointer', textDecoration: 'underline' }} onDoubleClick={() => window.COMMON.downloadFile(item.id, item.name)}>
          <MDBIcon fa="true" icon="paperclip" className="fa-fw mr-1"></MDBIcon>
          <small className="text-primary">{item.name}</small>
        </p>)
      }
      <p className="mb-1 mt-1"><small className="text-muted">* {window.I18N('note')}: <i dangerouslySetInnerHTML={{__html: window.COMMON.getValueFromAttr(child, 'note', '').replace(/\n/g, '<br>')}}></i></small></p>
      { 
        props.isInfluencer ? <> {
          isEnable ? <>
            <MDBInput outline type="textarea" label={window.I18N('consultant_evaluation')} name="consultantEvaluation" value={window.I18N(window.COMMON.getValueFromAttr(child, 'consultantEvaluation'))} onChange={(event) => handleChange(event, i, j)} maxLength="5000"></MDBInput>
            <MDBInput outline type="textarea" label={window.I18N('consultant_note')} name="consultantNote" value={window.COMMON.getValueFromAttr(child, 'consultantNote')} onChange={(event) => handleChange(event, i, j)} maxLength="5000"></MDBInput>
          </> : <>
            <MDBInput outline type="textarea" label={window.I18N('consultant_evaluation')} name="consultantEvaluation" value={window.I18N(window.COMMON.getValueFromAttr(child, 'consultantEvaluation'))} readOnly={true}></MDBInput>
            <MDBInput outline type="textarea" label={window.I18N('consultant_note')} name="consultantNote" value={window.COMMON.getValueFromAttr(child, 'consultantNote')} readOnly={true}></MDBInput>
          </>
        }
        </> : <>
          <p className="mb-1"><small className="text-muted">* {window.I18N('consultant_evaluation')}: <i className="text-danger" dangerouslySetInnerHTML={{__html: window.I18N(window.COMMON.getValueFromAttr(child, 'consultantEvaluation', '')).replace(/\n/g, '<br>')}}></i></small></p>
          <p className="mb-3"><small className="text-muted">* {window.I18N('consultant_note')}: <i className="text-danger" dangerouslySetInnerHTML={{__html: window.COMMON.getValueFromAttr(child, 'consultantNote', '').replace(/\n/g, '<br>')}}></i></small></p>
        </>
      }
    </>;
  };

  return <div className="wow fadeIn animated" data-wow-delay="0.5s">
    <AttributeView data={props.dataAttributeReport} company={props.company || object.company} dataCompany={props.dataCompany} isView={true}/>
    {
      dataCompare.length > 0 ? <MDBRow>
        <MDBCol>
          <p className="m-0 font-weight-bold" style={{ textDecoration: 'underline' }}>
            <MDBIcon fa="true" icon="building" className="fa-fw mr-1"></MDBIcon> {window.I18N('company')}
          </p>
        </MDBCol>
        <MDBCol>
          <p className="m-0 font-weight-bold" style={{ textDecoration: 'underline' }}>
            <MDBIcon fa="true" icon="building" className="fa-fw mr-1"></MDBIcon> {window.I18N('influencer')}
          </p>
        </MDBCol>
      </MDBRow> : <></>
    }
    <MDBRow className="mt-3">
      <MDBCol>
        {
          props.type === 0 ? data.map((item, i) => <div key={i} className="container-preview-group-self-assessment p-3">
            <p className="font-weight-bold text-title">{window.COMMON.getValueWithLanguage(item, 'group.name', language)}</p>
            {
              window.COMMON.getValueFromAttr(item, 'feedback', []).map((child, j) => <div className="item" key={child.question._id}>
                <p className="mb-2 font-weight-bold">{(j + 1) + '. ' + window.COMMON.getValueWithLanguage(child, 'question.question', language)}</p>
                <MDBRow>
                  { 
                    dataCompare.length > 0 ? <>
                      <MDBCol>
                        { generateAnswerLayout(child, child.question, i, j, props.type === 0) }
                        <hr className="vertical light"></hr>
                      </MDBCol>
                      <MDBCol>
                        { generateAnswerLayout(window.COMMON.getValueFromAttr(dataCompare, i + '.feedback.' + j, null), child.question, i, j, props.type === 1) }
                      </MDBCol>
                    </> : <MDBCol>
                      { generateAnswerLayout(child, child.question, i, j, true) }
                    </MDBCol>
                  } 
                </MDBRow>
              </div>)
            }
          </div>) : dataCompare.map((item, i) => <div key={i} className="container-preview-group-self-assessment p-3">
            <p className="font-weight-bold text-title">{window.COMMON.getValueWithLanguage(item, 'group.name', language)}</p>
            {
              window.COMMON.getValueFromAttr(item, 'feedback', []).map((child, j) => <div className="item" key={child.question._id}>
                <p className="mb-2 font-weight-bold">{(j + 1) + '. ' + window.COMMON.getValueWithLanguage(child, 'question.question', language)}</p>
                <MDBRow>
                  { 
                    dataCompare.length > 0 ? <>
                      <MDBCol>
                        { generateAnswerLayout(window.COMMON.getValueFromAttr(data, i + '.feedback.' + j, null), child.question, i, j, props.type === 0) }
                        <hr className="vertical light"></hr>
                      </MDBCol>
                      <MDBCol>
                        { generateAnswerLayout(child, child.question, i, j, props.type === 1) }
                      </MDBCol>
                    </> : <MDBCol>
                      { generateAnswerLayout(child, child.question, i, j, true) }
                    </MDBCol>
                  } 
                </MDBRow>
              </div>)
            }
          </div>)
        }
      </MDBCol>
    </MDBRow>
  </div>;
});

export default ItemPreviewSelfAssessment;