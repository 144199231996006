import React, { useState, useLayoutEffect } from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import { MDBCardImage, MDBBtn, MDBIcon } from 'mdbreact';

const TableTree = React.memo(function TableTree(props) {
  const [status, setStatus] = useState({});
  
  useLayoutEffect(() => {
    window.resetDataTableTree = resetDataTableTree;
    window.initDataTableTree = initDataTableTree;
    setStatus(props.status);
  }, [props.status]);
  
  const resetDataTableTree = (id) => {
    if ($.fn.DataTable.isDataTable('#'.concat(id))) {
      const table = $('#'.concat(id));
      table.dataTable().fnDestroy();
      table.hide();
    }
  };

  const initDataTableTree = (id) => {
    const contentHeight = $('#'.concat(id)).parents('.container-page').height();
    const table = $('#'.concat(id));
    table.show();
    table.dataTable({
      bFilter : false,
      paging: false,
      iDisplayLength : -1,
      sPaginationType : 'full_numbers',
      sDom: '<f<t>pli>',
      bAutoWidth : true,
      bStateSave : true,
      bSort : false,
      bRetrieve : true,
      scrollCollapse: true,
      scrollY: contentHeight - 240 + 'px',
      aLengthMenu : [ [ 15, 30, 60, 100, -1 ], [ 15, 30, 60, 100, "All" ] ],
      language : {
        emptyTable: window.I18N('empty_table'),
        info: window.I18N('info_table'),
        infoEmpty: window.I18N('info_empty_table'),
        infoFiltered: window.I18N('info_filtered_table'),
        lengthMenu: window.I18N('length_menu_table'),
        search: window.I18N('search_table'),
        zeroRecords: window.I18N('zero_records_table'),
        oPaginate: {	
          sFirst: '<i class="fa fa-backward"></i>',
          sPrevious: '<i class="fa fa-lg fa-caret-left"></i>',
          sNext: '<i class="fa fa-lg fa-caret-right"></i>',
          sLast: '<i class="fa fa-forward"></i>'
        }
      }
    });
  };
  
  const generateTitle = (index, config, item) => {
    if (config.type === 'IMAGE_TEXT') {
      return window.I18N('image');
    }
    if (config.type === 'CHECK') {
      return 
    }
    const value = window.COMMON.getValueFromAttr(item, config.attr);
    if (config.type === 'INDEX') {
      return index + 1;
    }
    if (config.type === 'IMAGE') {
      return window.I18N('image');
    }
    if (config.type === 'CHECKBOX') {
      return 'Checkbox';
    }
    const types = ['BUTTON', 'STATUS', 'STATUSPOPUP', 'PERCENT'];
    if (types.includes(config.type)) {
      return '';
    }
    return value;
  };
  
  const generateCell = (index, config, item) => {
    const id = item[props.itemId];
    const value = window.COMMON.getValueFromAttr(item, config.attr);

    if (config.type === 'INDEX') {
      return index + 1;
    }

    if (config.type === 'ICON') {
      return <MDBIcon fa="true" icon={value} className="fa-fw fa-lg"/>;
    }

    if (config.type === 'IMAGE') {
      return <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(value)} className="image-icon rounded-circle"/>;
    }

    if (config.type === 'COLLAPSE') {
      let check = false;
      if (status && status[id]) {
        check = status[id];
      }
      if (!item.hasChildren) {
        return <div className={value === null ? 'container-collapse text-center' : 'container-collapse h-100 d-flex align-items-center'}>
          <MDBIcon fa="true" icon="minus-circle" style={{ fontSize: '16px'  }} className="disabled" />
          <span className="ml-2">{value}</span>
        </div>;
      }
      return <div className={value === null ? 'container-collapse text-center' : 'container-collapse h-100 d-flex align-items-center'} onClick={() => toggle(id, !check)}>
        <MDBIcon fa="true" icon={check ? 'minus-circle' : 'plus-circle'} style={{ fontSize: '16px'  }} className={item.hasChildren ? '' : 'disabled' } />
        <span className="ml-2">{value}</span>
      </div>;
    }

    if (config.type === 'LANGUAGE') {
      if (value !== null && value.length > 0) {
        return <ul className="container-text-language">
          { value.map((item, i) => (
            <li key={i}>
              <p className="txt-code">{item.code}:</p>
              <pre className="txt-text">{item.text}</pre>
            </li>
          ))}
        </ul>;
      }
      return '';
    }

    if (config.type === 'BUTTON') {
      if (config.attr === '0') {
        return <MDBBtn className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowDetail(event, item.idx)}><MDBIcon fa="true" icon="info" className="fa-fw" /></MDBBtn>;
      } else if (config.attr === '1') {
        return <MDBBtn className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowAdd(event, item.idx)}><MDBIcon fa="true" icon="plus-circle" className="fa-fw" /></MDBBtn>;
      } else if (config.attr === '2') {
        return <MDBBtn className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowEdit(event, item.idx)}><MDBIcon fa="true" icon="edit" className="fa-fw" /></MDBBtn>;
      } else if (config.attr === '-1') {
        return <MDBBtn className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowDel(event, item.idx)}><MDBIcon fa="true" icon="trash-alt" className="fa-fw" /></MDBBtn>;
      } else if (config.attr === '3' && !item.isParent) {
        return <div>
          <MDBBtn className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowEdit(event, item.idx)}><MDBIcon fa="true" icon="edit" className="fa-fw" /></MDBBtn>
          <MDBBtn className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowDel(event, item.idx)}><MDBIcon fa="true" icon="trash-alt" className="fa-fw" /></MDBBtn>
        </div>;
      } else if (config.attr === '1-2' && item.isParent) {
        return <div>
          <MDBBtn className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowAdd(event, item.idx)}><MDBIcon fa="true" icon="plus-circle" className="fa-fw" /></MDBBtn>
        </div>;
      } else if (config.attr === '4' || item.isParent) {
        return <div>
          <MDBBtn className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowAdd(event, item.idx)}><MDBIcon fa="true" icon="plus-circle" className="fa-fw" /></MDBBtn>
          <MDBBtn className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowEdit(event, item.idx)}><MDBIcon fa="true" icon="edit" className="fa-fw" /></MDBBtn>
          <MDBBtn className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowDel(event, item.idx)}><MDBIcon fa="true" icon="trash-alt" className="fa-fw" /></MDBBtn>
        </div>;
      } else if (config.attr === '1-2' && !item.isParent) {
        return <div>
          <MDBBtn className="btn btn-sm btn-transparent m-0 ml-3" type="button" color="" onClick={(event) => clickRowEdit(event, item.idx)}><MDBIcon fa="true" icon="edit" className="fa-fw" /></MDBBtn>
          {!item.default ? <MDBBtn className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowDel(event, item.idx)}><MDBIcon fa="true" icon="trash-alt" className="fa-fw" /></MDBBtn> : <></>} 
          
        </div>;
      } 
      
    }
    if (config.type === 'CHECK') {
      if (value) return <MDBIcon fas icon="check" />

    }
    return value;
  };
  
  const generateTableChild = (item, index) => {
    return <tr key={'child-' + index} data-level={item.level}>
      <td colSpan={props.config.length} className="p-0 td-not-selected">
        <table className={window.COMMON.getValue(props.className) + ' table-child mb-0'}>
          <tbody>{item.hasChildren ? generateChildrenRow(item.children, item[props.itemId]) : null}</tbody>
        </table>
      </td>
    </tr>;
  };
  
  const generateChildrenRow = (data, id) => {
    return data.map((item, i) => [
      (<tr key={i} data-id={window.COMMON.getValue(item[props.itemId])} data-parent={item.parentId} data-level={item.level} onClick={(event) => clickRow(event, item)} className={status[id] ? 'd-table-row' : 'd-none'}>
        {props.config.map((config, j) => (
          <td key={j} className={'txt-ellipsis ' +  config.className} align={config.alignment} width={config.width} title={generateTitle(i, config, item)}> 
            {generateCell(i, config, item)}
          </td>
        ))}
      </tr>),
      status[item[props.itemId]] ? generateTableChild(item, i) : null
    ]);
  };
  
  const toggle = (id, value) => {
    status[id] = value;
    setStatus({ ...status });
  };

  const clickRowDetail = (event, index) => {
    event.stopPropagation();
    if (props.handleRowDetailClick) {
      props.handleRowDetailClick(index);
    }
  };

  const clickRowAdd = (event, index) => {
    event.stopPropagation();
    if (props.handleRowAddClick) {
      props.handleRowAddClick(index);
    }
  };

  const clickRowEdit = (event, index) => {
    event.stopPropagation();
    if (props.handleRowEditClick) {
      props.handleRowEditClick(index);
    }
  };

  const clickRowDel = (event, index) => {
    event.stopPropagation();
    if (props.handleRowDelClick) {
      props.handleRowDelClick(index);
    }
  };
  
  const clickRow = (item) => {
    $('tr').removeClass('selected');
    $('[data-id=' + item[props.itemId] + ']').addClass('selected');
  };
  
  let header = null;
  if (window.COMMON.checkValue(props.config)) {
    header = (
      <tr>
        {props.config.map((item, i) => (
          item !== '' ? <th key={i} width={item.width} style={{textAlign: item.alignment}}>{item.colName}</th> : ''
        ))}
      </tr>
    );
  }
  
  let body = null;
  if (window.COMMON.checkValue(props.data)) {
    const data = window.COMMON.getArrsInArrs(true, props.data, 'isParent');
    body = data.map((item, i) => [
      (<tr key={i} data-id={window.COMMON.getValue(item[props.itemId])} data-level={item.level} onClick={() => clickRow(item)}>
        {props.config.map((config, j) => (
          config !== '' ? <td key={j} className={config.className === 'td-child' ? 'txt-ellipsis' : 'txt-ellipsis ' + config.className} align={config.alignment} width={config.width} title={generateTitle(i, config, item)}> 
            {generateCell(i, config, item)}
          </td> : null
        ))}
      </tr>),
      status[item[props.itemId]] ? generateTableChild(item, i) : null
    ]);
  }
  
  return (
    <div>
      <table className={window.COMMON.getValue(props.className)} id={props.id}>
        <thead>{header}</thead>
        <tbody>{body}</tbody>
      </table>
    </div>
  );
});
  
export default TableTree;