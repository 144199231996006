import React from 'react'; 
import { MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';

const GridItemShareSelfAssessment = React.memo(function GridItemShareSelfAssessment(props) {

  const handleItemClick = (event, row, col) => {
    event.stopPropagation();
    if (props.handleItemClick) {
      props.handleItemClick(row, col);
    }
  };

  const handleEditClick = (event, row, col) => {
    event.stopPropagation();
    if (props.handleItemEditClick) {
      props.handleItemEditClick(row, col);
    }
  };

  const handlePreviewClick = (event, data) => {
    event.stopPropagation();
    if (props.handlePreviewClick) {
      const result = data.map(item => {
        return {
		  _id: item._id,
		  users: item.users,
          group: item.group,
          feedback: item.feedback,
          status: item.status,
		  isApproved: item.isApproved,
		  selfAssessment: item.selfAssessment
        };
      });
      props.handlePreviewClick(result);
    }
  };

  const getDraftData = (assessmentId, groupId) => {
    const userId = localStorage.getItem('id');
    const draft = JSON.parse(localStorage.getItem('draftAssessment') || '[]');

    const dataDraft = draft.find(item => item.user === userId && item.assessment === assessmentId && item.group === groupId);
    if (dataDraft) {
	  const questions = dataDraft.questions;
      const feedback = Object.keys(dataDraft.feedback).map((k) => dataDraft.feedback[k]);
	  const percentFinished = parseInt(feedback.length / questions.length * 100);
	  return (
        <small className="text-muted">
          <MDBIcon fa="true" icon="clipboard" className="fa-fw" /> {window.I18N('draft')} <b>{percentFinished}% ({feedback.length}/{questions.length})</b>
        </small>
      );
    }
    return <></>;
  };
  
  const filterGroupByAssessment = (assessmentId) => {
    if (!props.shareList || !assessmentId) return [];
    return props.shareList?.filter(child => child.selfAssessment?._id === assessmentId);
  };

  const canBePreview = (groupData) => {
    let result = true;
    if (groupData && Array.isArray(groupData)) {
      groupData.map(item => {
        if (!item.status) result = false;
      });
    }
    return result;
  };

  const isApproved = (groupData) => {
    let result = true;
    if (groupData && Array.isArray(groupData)) {
      groupData.map(item => {
        if (!item.isApproved) result = false;
      });
    }
    return result;
  };

  if (!props.data || props.data.length === 0) {
    return <li className="list-group-item p-3 text-center">{window.I18N('empty_table')}</li>;
  }

  return (
    <>
      {
        props.data.map((item, i) => 
          <li className="list-group-item p-3" key={i}>
            <MDBRow>
              <MDBCol size="3">
                <p className="font-weight-bold mb-1">
                  {window.COMMON.getValueFromAttr(item, 'name')}
                </p>
                <p className="mb-1">
                  <MDBIcon fa="true" icon="clock" className="fa-fw mr-1"></MDBIcon>
                  <small className="text-muted">{window.COMMON.formatUnixDateSingleData(item, 'startDate')} - {window.COMMON.formatUnixDateSingleData(item, 'endDate')}</small>
                </p>
                <p className="mb-3">
                  <MDBIcon fa="true" icon="calendar-alt" className="fa-fw mr-1"></MDBIcon>
                  <small className="text-muted">{window.COMMON.formatUnixDateSingleData(item, 'createdDate')}</small>
                </p>
                {
                  isApproved(filterGroupByAssessment(item._id)) ? <label className="label-box label-success p-1 m-0">{window.I18N('approved')}</label> 
                    : canBePreview(filterGroupByAssessment(item._id)) ? <label className="label-box label-info p-1 m-0">{window.I18N('submited')}</label>
                      : <label className="label-box label-primary p-1 m-0">{window.I18N('in_proccessing')}</label>
                }
              </MDBCol>
              <MDBCol size="2">
                {item.users?.length ? (
                  <>
                    {item.users.map((item) => 
                      <p key={item._id} className="card-text m-0">
                        <MDBIcon fa="true" icon="user" className="fa-fw mr-1"></MDBIcon>
                        <span>{window.COMMON.getValueFromAttr(item, 'name')}</span>
                      </p>
                    )}
                  </>
                ) : null}
              </MDBCol>
              <MDBCol>
                {
                  filterGroupByAssessment(item._id)?.map((child, j) => <div className={'d-flex align-items-start flex-column bd-highlight' + (j === 0 ? '' : ' mt-4')} key={i *j + j}>
                    <div className="w-100 d-flex align-items-center">
                      <p 
					  	className="mb-1 mr-auto font-weight-bold text-anchor" 
                        onClick={(event) => handleItemClick(event, props.shareList?.findIndex(s => s._id === child._id))}
					  >
                        <MDBIcon fa="true" icon="circle" style={{ color: child.status ? '#00c851' : '#dddddd' }} className="fa-fw mr-1"></MDBIcon>
                        {window.COMMON.getValueFromAttr(child, 'nameLanguage')}
                      </p>
                      {
                        !child.status ? (
                          <p className="m-0">{getDraftData(item._id, child.group?._id)}</p>
                        ) : (
                          <p className="m-0">
                            <small className="text-muted">{window.I18N('completed_by')} <b>{window.COMMON.getValueFromAttr(child, 'updatedBy.name')}</b></small>
                          </p>
                        )
                      }
                    </div>
                    <div className="bd-highlight w-100">
                      <div className="d-flex align-items-center">
                        <p className="m-0 mr-auto">
                          <MDBIcon fa="true" icon="qrcode" className="fa-fw mr-1"></MDBIcon>
                          <small className="text-muted">{window.COMMON.getValueFromAttr(child, 'group.code')}</small>
                        </p>
                        {
                          !child.status ? <></> : <p className="m-0"><small className="text-muted">{window.I18N('completed_date')} <b>{child.updatedDate}</b></small></p>
                        }
                      </div>
                    </div>
                    <div className='d-flex align-items-center'>
                      <MDBBtn 
                      outline 
                      className="btn btn-sm btn-transparent m-0" 
                      type="button" 
                      color="" 
                      disabled={isApproved(filterGroupByAssessment(item._id))} 
                      onClick={isApproved(filterGroupByAssessment(item._id)) ? () => {} : (event) => handleEditClick(event, props.shareList?.findIndex(s => s._id === child._id))}
                    >
                        <small className="text-muted mr-1">{window.COMMON.getValueFromAttr(child, 'users', []).length}</small>
                        <MDBIcon fa="true" icon="users" style={{ color: 'orange' }} className="fa-fw" />
                      </MDBBtn>
                      <MDBIcon fa="true" icon="building" className="fa-fw mr-1 ml-2"></MDBIcon>
                      <small className="text-muted">{child?.company?.information?.name}</small>
                    </div>
                  </div>)
                }
                <div className="text-right">
                  {
                    canBePreview(filterGroupByAssessment(item._id)) ? 
                      <MDBBtn 
                        outline 
                        className="btn btn-sm m-0" 
                        type="button" 
                        color="" 
                        onClick={(event) => handlePreviewClick(event, filterGroupByAssessment(item._id))}
                      >
                        <MDBIcon fa="true" icon="eye" className="fa-fw" /> {window.I18N('preview')}
                      </MDBBtn> : <></>
                  }
                </div>
              </MDBCol>
            </MDBRow>
          </li>
        )
      }
    </>
  );
});

export default GridItemShareSelfAssessment;