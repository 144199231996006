
import React, { useState, useRef, useCallback, useLayoutEffect } from 'react';
import Select from 'react-select';
import { MDBInputGroup, MDBBtn, MDBIcon, MDBCol, MDBRow, MDBCardImage, MDBInput } from 'mdbreact';

import ListAccount from '../../components/ListAccount';
import Modal from '../../components/Modal';
import RadioBox from '../../components/RadioBox';

import CompanyGRAPHQL from '../../../graphql/Company';
import AccountGRAPHQL from '../../../graphql/Account';
import eventName from '../../../common/events';
import { useTracking } from 'react-tracking';

export default function RequestAccountPage(props) {
  const { trackEvent } = useTracking();

  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const FULL_NAME = localStorage.getItem(window.CONSTANT.FULL_NAME);
  const types = [
    { value: 'INDIVIDUAL', label: window.I18N('individual') },
    { value: 'INFLUENCER_COMPANY', label: window.I18N('influencer') },
    { value: 'INFLUENCER', label: window.I18N('btn_approve') },
    { value: 'FRIEND', label: window.I18N('friends') }
  ];

  const dataInfluencer = useRef([]);
  const [filter, setFilter] = useState({ text: '', company: null, type: window.CONSTANT.INDIVIDUAL });
  const [object, setObject] = useState({ index: -1, item: null });
  const [data, setData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [dataFriend, setDataFriend] = useState([]);
  const [dataRequest, setDataRequest] = useState([]);
  const [dataSave, setDataSave] = useState({});
  const [options, setOptions] = useState({ company: [] });

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataCompany();
      getDataInfluencer();
    }, 100);
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line
  }, []);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      document.getElementById('btn-search').click();
    }
  };

  const handleFilterChange = (event) => {
    filter.text = event.target.value;
    setFilter({ ...filter });
  };

  const handleFilterSelect = (event) => {
    filter.company = event;
    setFilter({ ...filter });
    if (filter.type === 'FRIEND') {
      getDataFriend();
    } else if (filter.type === 'INFLUENCER_COMPANY') {
      getDataRequestOrganization();
    } else {
      getDataRequest();
    }
  };
  
  const handleRadio = useCallback((value) => {
    filter.type = value;
    filter.text = '';
    setFilter({ ...filter });
    if (filter.type === 'FRIEND') {
      getDataFriend();
    } else if (filter.type === 'INFLUENCER_COMPANY') {
      getDataRequestOrganization();
    } else {
      getDataRequest();
    }
    // eslint-disable-next-line
  }, [filter]);

  const handleChange = (event) => {
    if (event.target.className.indexOf('input-number') > -1) {
      dataSave[event.target.name] = parseInt(event.target.value);
    } else {
      dataSave[event.target.name] = event.target.value;
    }
    setDataSave({ ...dataSave });
  };

  const getDataCompany = async () => {
    try {
      if (!window.COMMON.checkRoleIsSystem()) {
        filter.company = { value: COMPANY_ID, label: FULL_NAME, code: COMPANY_TYPE };
        setFilter({ ...filter });
        getDataRequest();
        return;
      }
      await window.COMMON.getDataCompany();
      const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_COMPANY));
      options.company = window.COMMON.createDataSelectWithGroup(dataCompany, 'id', 'name', 'accountType', 'accountType');
      filter.company = window.COMMON.getValueFromAttr(options.company, '0.options.0', null);  
      setOptions(options);
      setFilter({ ...filter }); 
      getDataRequest();
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountPage.getDataCompany');
    }
  };

  const getDataInfluencer = async () => {
    try {
      const params = {
        type: window.CONSTANT.INFLUENCER
      };
      const result = await window.COMMON.query(CompanyGRAPHQL.QUERY_COMPANY, params);
      if (result && result.data) {
        dataInfluencer.current = result.data.getCompanies;
      } 
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountPage.getDataInfluencer');
    }
  };

  const getPackageIdOfCompany = (paramId) => {
    const allCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_COMPANY));
    return allCompany.find(el => el.company?._id === paramId)?.package._id
  }

  // REQUEST COMPANY
  const getDataRequest = async () => {
    try {
      if (filter.company !== null) {
        const params = {
          company: filter.company.value,
          type: filter.type,
          status: ['PENDING']
        };
        const result = await window.COMMON.query(CompanyGRAPHQL.QUERY_RELATION_COMPANY, params);
        const dataRelation = [];
        if (result && result.data) {
          const data = result.data.findRelationshipCompany;
          data.forEach(item => {
            item.user.createdDate = window.COMMON_DATE.formatDate(item.createdDate);
            item.user.birthday = window.COMMON_DATE.formatDate(item.user.birthday);
            dataRelation.push(item.user);
          });
        }
        setData(dataRelation);
        searchData(dataRelation);
        setDataRequest([]);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountPage.getDataRequest');
    }
  };

  const searchData = (data) => {
    let dataSearch = [];
    if (filter.text) {
      data.forEach(item => {
        if (filter.type === 'FRIEND') {
          if (window.COMMON.getValueFromAttr(item, 'nickname').toLowerCase().indexOf(filter.text.toLowerCase()) > -1) {
            dataSearch.push(item);
          }
        } else {
          if (window.COMMON.getValueFromAttr(item, 'name').toLowerCase().indexOf(filter.text.toLowerCase()) > -1 
            || window.COMMON.getValueFromAttr(item, 'email').toLowerCase().indexOf(filter.text.toLowerCase()) > -1 
            || window.COMMON.getValueFromAttr(item, 'phone').toLowerCase().indexOf(filter.text.toLowerCase()) > -1) {
            dataSearch.push(item);
          }
        }
      });
    } else {
      dataSearch = data;
    }
    setDataSearch(dataSearch);
  };

  const chooseItem = useCallback((checked) => {
    const dataRequest = [];
    const keys = Object.keys(checked);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (checked[key]) {
        dataRequest.push(key);
      }
    }
    setDataRequest(dataRequest);
  }, []);

  const showItemDetails = useCallback((item) => {
    object.item = item;
    setObject({ ...object });
    window.COMMON.showModal('#modal-detail');
  }, [object]);

  const openAcceptAccountDialog = () => {
    window.COMMON.showModal('#modal-save');
    window.saveMethod = acceptAccount;
  };

  const openDeclineAccountDialog = () => {
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = declineAccount;
  };

  const acceptAccount = async () => {
    try {
      const params = {
        input: {
          company: filter.company.value,
          companyName: filter.company.label,
          companyType: filter.company.code,
          type: filter.type,
          users: dataRequest
        }
      };
      const result = await window.COMMON.mutation(CompanyGRAPHQL.MUTATION_ACCEPT_COMPANY_RELATIONSHIP, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_APPROVE)) {
        if (params.input.type === 'INDIVIDUAL'){
          const paramPackage = {input: {organizationIds : params.input.company, packageId:""}}
          if (window.COMMON.checkRoleIsSystem()){
            paramPackage.input.packageId = getPackageIdOfCompany(params.input.company)
          } else {
            paramPackage.input.packageId = localStorage.getItem(window.CONSTANT.PACKAGE_ID)
          } 
          const resultSetPackage = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_SET_PACKAGE, paramPackage)
          if ( window.COMMON.checkResultData(resultSetPackage, window.CONSTANT.MODE_UPDATE)){
            createTrackingEvent(eventName.SET_PACKAGE);
          }
        }
        getDataRequest();
        createTrackingEvent(eventName.ACCEPT_COMPANY_RELATIONSHIP);
      }  
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountPage.acceptAccount');
    }
  };

  const declineAccount = async () => {
    try {
      const params = {
        input: {
          company: filter.company.value,
          companyName: filter.company.label,
          companyType: filter.company.code,
          type: filter.type,
          users: dataRequest
        }
      };
      const result = await window.COMMON.mutation(CompanyGRAPHQL.MUTATION_DECLINE_COMPANY_RELATIONSHIP, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DECLINE)) {
        getDataRequest();
        createTrackingEvent(eventName.DECLINE_COMPANY_RELATIONSHIP);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountPage.declineAccount');
    }
  };

  // REQUEST INFLUENCER
  const getDataRequestOrganization = async () => {
    try {
      if (filter.company !== null) {
        const params = {
          organization: filter.company.value
        };
        const result = await window.COMMON.query(CompanyGRAPHQL.QUERY_RELATION_ORGANIZATION, params);
        let dataRelation = [];
        if (result && result.data) {
          dataRelation = result.data.findRelationshipOrganization;
        }
        const data = [];
        dataInfluencer.current.forEach(element => {
          const obj = JSON.parse(JSON.stringify(element));
          const request = window.COMMON.getObjectInArrs(window.COMMON.getValueFromAttr(element, 'information._id'), dataRelation, 'user._id');
          obj.information.relationId = request ? request._id : null;
          obj.information.status = request ? request.status : 'OPEN';
          obj.information.companyId = element._id;
          obj.information.isInfluencer = false;
          obj.information.isAllowCancel = request ? request.type === 'COMPANY' : false;
          obj.information.createdDate = window.COMMON_DATE.formatDate(element.createdDate);
          obj.information.gopy = obj.gopy || 0;
          obj.information.subQuestion1 = obj.subQuestion1 || '';
          obj.information.subQuestion2 = obj.subQuestion2 || '';
          obj.information.subQuestion3 = obj.subQuestion3 || '';
          data.push(obj.information);
        });
        setData(data);
        searchData(data);
        setDataRequest([]);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountPage.getDataRequestOrganization');
    }
  };

  const openSendRequestDialog = (index) => {
    object.index = index;
    const obj = dataSearch[index];
    setObject(object);
    const dataSave = {
      gopy: 0
    };
    if (obj.gopy > 0 || obj.subQuestion1 || obj.subQuestion2 || obj.subQuestion3) {
      dataSave.gopy = window.COMMON.getValueFromAttr(obj, 'gopy', 0);
      dataSave.subQuestion1 = window.COMMON.getValueFromAttr(obj, 'subQuestion1', '');
      dataSave.subQuestion2 = window.COMMON.getValueFromAttr(obj, 'subQuestion2', '');
      dataSave.subQuestion3 = window.COMMON.getValueFromAttr(obj, 'subQuestion3', '');
      dataSave.subAnswer1 = '';
      dataSave.subAnswer2 = '';
      dataSave.subAnswer3 = '';
      setDataSave({ ...dataSave });
      window.COMMON.showModal('#modal-update-gopy-and-sub-question');
    } else {
      setDataSave({ ...dataSave });
      window.COMMON.showModal('#modal-save');
      window.saveMethod = sendRequestInfluencerJoinCompany;
    }
  };

  const openDeleteRequestDialog = (index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteInfluencerJoinCompany;
  };

  const sendRequestInfluencerJoinCompany = async () => {
    try {
      const obj = dataSearch[object.index];
      const params = {
        influencer: obj.companyId,
        company: window.COMMON.getDataSelect(filter.company, false),
        isInfluencer: false
      };
      if (obj.gopy > 0 || obj.subQuestion1 || obj.subQuestion2 || obj.subQuestion3) {
        params.input = dataSave;
      }
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_REQUEST_INFLUENCER_JOIN_COMPANY, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_APPROVE)) {
        getDataRequestOrganization();
        createTrackingEvent(eventName.REQUEST_INFLUENCER_JOIN_COMPANY);
        window.COMMON.hideModal('#modal-update-gopy-and-sub-question');
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestInfluencerPage.acceptAccount');
    }
  };

  const deleteInfluencerJoinCompany = async () => {
    try {
      const params = {
        id: dataSearch[object.index].relationId,
        companyType: filter.company && filter.company.code
      };
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_DELETE_INFLUENCER_JOIN_COMPANY, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DECLINE)) {
        getDataRequestOrganization();
        createTrackingEvent(eventName.DELETE_INFLUENCER_JOIN_COMPANY);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestInfluencerPage.declineAccount');
    }
  };

  // REQUEST FRIEND
  const getDataFriend = async () => {
    try {
      const result = await window.COMMON.query(AccountGRAPHQL.QUERY_PENDING_FRIEND_BY_USER, null);
      const dataFriend = [];
      if (result && result.data) {
        const data = result.data.findPendingFriendsByUser;
        data.forEach(item => {
          item.createdBy.createdDate = window.COMMON_DATE.formatDate(item.createdDate);
          item.createdBy.birthday = window.COMMON_DATE.formatDate(item.createdBy.birthday);
          dataFriend.push(item.createdBy);
        });
      }
      setDataFriend(dataFriend);
      searchData(dataFriend);
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountPage.getDataFriend');
    }
  };

  const openAcceptFriendDialog = (index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-save');
    window.saveMethod = acceptFriend;
  };

  const openDeclineFriendDialog = (index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = declineFriend;
  };

  const acceptFriend = async () => {
    try {
      const params = {
        input: {
          id: window.COMMON.getValueIdFromAttr(dataFriend[object.index], '_id'),
          name: localStorage.getItem(window.CONSTANT.FULL_NAME)
        }
      };
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_ACCEPT_FRIEND, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
        getDataFriend();
        createTrackingEvent(eventName.ACCEPT_FRIEND);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountPage.acceptFriend');
    }
  };

  const declineFriend = async () => {
    try {
      const params = {
        accountId: window.COMMON.getValueIdFromAttr(dataFriend[object.index], '_id')
      };
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_DECLINE_FRIEND, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataFriend();
        createTrackingEvent(eventName.DECLINE_FRIEND);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountPage.declineFriend');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <div className="card mt-3 p-3 wow fadeInDown animated" data-wow-delay="0.5s" style={{ zIndex: 999 }}>
        {
          !window.COMMON.checkRoleIsSystem() ? <div></div> : <div className="d-flex align-items-center justify-content-end mb-2">
            <div className="bd-highlight ml-auto" style={{ width: '300px' }}>
              <div className="input-group input-group-transparent">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="building" className="fa-fw" />
                  </span>
                </div>
                <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.company} options={options.company} onChange={handleFilterSelect} isSearchable/>
              </div>
            </div>
          </div>
        }
        
        <div className="d-flex align-items-center justify-content-end">
          <div className="ml-auto mr-2" style={{ width: '300px' }}>
            <MDBInputGroup type="text" value={filter.text} hint={filter.type !== 'INFLUENCER' && filter.type !== 'INFLUENCER_COMPANY' ? window.I18N('search_nickname') : window.I18N('search_name_email_phone')} onChange={handleFilterChange} maxLength="100" pattern="\S(.*\S)?" required
              append={
                <span className="input-group-text cursor-pointer" id="btn-search" onClick={() => searchData(filter.type === 'FRIEND' ? dataFriend : data)}>
                  <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                </span>
              }
            />
          </div>
          <div className="d-inline-block">
            <RadioBox data={types} value={filter.type} className="m-0" handleChange={handleRadio}></RadioBox>
          </div>
          <div className="bd-highlight">
            <MDBBtn color="danger" onClick={openDeclineAccountDialog} disabled={dataRequest.length > 0 ? false : true}>
              <MDBIcon fa="true" icon="ban" className="fa-fw"/> {window.I18N('btn_reject')}
            </MDBBtn>
            <MDBBtn color="primary" className="mr-0" onClick={openAcceptAccountDialog} disabled={dataRequest.length > 0 ? false : true}>
              <MDBIcon fa="true" icon="check-circle" className="fa-fw"/> {window.I18N('btn_accept')}
            </MDBBtn>
          </div>
        </div>
      </div>
      <div className="wow fadeIn animated p-2" data-wow-delay="1s">
        {
          filter.type === 'INFLUENCER_COMPANY' ? <ListAccount isInfluencer={true} data={dataSearch} handleItemClick={showItemDetails} handleAccept={openSendRequestDialog} handleReject={openDeleteRequestDialog}></ListAccount> :
            <ListAccount data={dataSearch} dataSelected={dataRequest} isCompany={filter.type === window.CONSTANT.INFLUENCER ? true : false} type={filter.type} handleCheckChange={chooseItem} handleItemClick={showItemDetails} handleAccept={openAcceptFriendDialog} handleReject={openDeclineFriendDialog}></ListAccount>
        }
      </div>

      <Modal id="modal-detail" className="modal-lg" title={window.COMMON.getValueFromAttr(object.item, 'name')} hideSave={true}>
        <MDBRow>
          <MDBCol md="3" sm="4" xs="12" className="text-center">
            <MDBCardImage cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(object.item, 'avatar'))} className="image-logo"/>
          </MDBCol>
          <MDBCol md="9" sm="8" xs="12">
            <table className="table table-view mb-0">
              {
                !object.item ? <tbody></tbody> : <tbody>
                  <tr>
                    <td width="30%">{window.I18N('name')}</td>
                    <td width="70%">{object.item.name}</td>
                  </tr>
                  <tr>
                    <td>{window.I18N('nickname')}</td>
                    <td>{object.item.nickname}</td>
                  </tr>
                  <tr>
                    <td>{window.I18N('email')}</td>
                    <td>{object.item.email}</td>
                  </tr>
                  <tr>
                    <td>{window.I18N('phone_number')}</td>
                    <td>{object.item.phone}</td>
                  </tr>
                  {
                    filter.type === window.CONSTANT.INFLUENCER ? <></> : <>
                      <tr>
                        <td>{window.I18N('gender')}</td>
                        <td>{object.item.gender === 0 ? window.I18N('female') : window.I18N('male')}</td>
                      </tr>
                      <tr>
                        <td>{window.I18N('birthday')}</td>
                        <td>{object.item.birthday}</td>
                      </tr>
                    </>
                  }
                </tbody>
              }
            </table>
          </MDBCol>
        </MDBRow>
      </Modal>

      <Modal id="modal-update-gopy-and-sub-question" className="modal-lg" title={window.I18N('create_update_data')} saveBtn={window.I18N('send')} saveEvent={sendRequestInfluencerJoinCompany}>
        <form id="form-update-gopy-and-sub-question" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <p className="mb-3"><strong>{window.I18N('need_gopies')}: </strong> <span className="font-weight-bold">{dataSave.gopy}</span> Gopies</p>
              {(dataSave.subQuestion1 || dataSave.subQuestion2 || dataSave.subQuestion3) &&
              <p className="mb-4">{window.I18N("question_privacy")}</p>
              }
              {
                dataSave.subQuestion1 ? <>
                  <p className="mb-2"><strong>{window.I18N('question')}: </strong> {dataSave.subQuestion1}</p>
                  <MDBInput outline containerClass="mt-0" value={dataSave.subAnswer1} name="subAnswer1" onChange={handleChange} type="textarea" label={window.I18N('answer')} maxLength="1000" pattern="\S(.*\S)?">
                    <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                  </MDBInput>
                </> : <></>
              }
              {
                dataSave.subQuestion2 ? <>
                  <p className="mb-2"><strong>{window.I18N('question')}: </strong> {dataSave.subQuestion2}</p>
                  <MDBInput outline containerClass="mt-0" value={dataSave.subAnswer2} name="subAnswer2" onChange={handleChange} type="textarea" label={window.I18N('answer')} maxLength="1000" pattern="\S(.*\S)?">
                    <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                  </MDBInput>
                </> : <></>
              }
              {
                dataSave.subQuestion3 ? <>
                <p className="mb-2"><strong>{window.I18N('question')}: </strong> {dataSave.subQuestion3}</p>
                  <MDBInput outline containerClass="mt-0 mb-0" value={dataSave.subAnswer3} name="subAnswer3" onChange={handleChange} type="textarea" label={window.I18N('answer')} maxLength="1000" pattern="\S(.*\S)?">
                    <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                  </MDBInput>
                </> : <></>
              }
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>
    </div>
  );
}