const taskScheduleEvent = {
  ADD_TASK_SCHEDULE: 'ADD_TASK_SCHEDULE',
  EDIT_TASK_SCHEDULE: 'EDIT_TASK_SCHEDULE',
  UPDATE_STATUS_TASK_SCHEDULE: 'UPDATE_STATUS_TASK_SCHEDULE',
  DEL_TASK_SCHEDULE: 'DEL_TASK_SCHEDULE',
  ADD_REMIND_TASK: 'ADD_REMIND_TASK',
  UPDATE_REMIND_TASK: 'UPDATE_REMIND_TASK',
  CANCEL_REMIND_TASK: 'CANCEL_REMIND_TASK'
};

export default taskScheduleEvent;