import React, { useState, useLayoutEffect } from 'react';
import FanpageGRAPHQL from '../../graphql/Fanpage';
import {Cancel, Save, Lock, Public, SearchOutlined, Backspace } from '@material-ui/icons';
import { Card, CardContent, Grid, Button, Box, Modal, TextField, InputBase, InputAdornment, IconButton } from '@material-ui/core';
import { MDBCardImage } from 'mdbreact';
import { useHistory } from 'react-router-dom';
import Constant from '../../utils/Constant';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingSpinner from './LoadingSpinner';
import { useTracking } from 'react-tracking';
import eventName from '../../common/events';

const useStyles = makeStyles({
    modalBox: {
        width: "90%",
		position: 'absolute',
		top: '50%',
		left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        display: "flex",
        flexDirection:"column",
        alignItems: "center",
        borderRadius: "5px",
        padding: "20px 20px 40px 20px",
        "@media (min-width: 800px)": {
            width: "600px"
        }
    },
    focused: {
            borderColor: "#33b5e5 !important",
            '& :focus': {
                boxShadow: "none !important"
            } 
        },
        root: {
            margin: "0rem 1rem 1rem auto",
            width: "25vw",
            padding: "0.5rem 0.5rem 0.5rem 1rem",
            border: "solid 1px #dee2e6", 
            borderRadius: "20px",
            background: "white"
        }
});
const PAGE_SIZE= 10   
const DiscoverFanpage = ({ reloadParent }) => {
    const classes = useStyles();
    const history = useHistory();
    const [dataDiscoverFanpages, setDataDiscoverFanpages] = useState([])
    const [idsFanpagesPending, setIdsFanpagesPending] = useState([])
    const currentUser = window.COMMON.getCurrentUserInformation()
    const [openModal, setOpenModal] = useState(false)
    const [object, setObject] = useState(-1)
    const [dataSave, setDataSave] = useState({ answerQuestion1: '', answerQuestion2: '', answerQuestion3: '' })
    const [isStillMore, setIsStillMore] = useState(false)
    const [discoverFanpageCount, setDiscoverFanpageCount] = useState(0)
    const [searchText, setSearchText] = useState({ input: '', applied: '' })
    const { trackEvent } = useTracking();

    const createTrackingEvent = (event) => {
        return trackEvent({
            name: event,
            createdAt: new Date().toISOString()
        });
    };
    
    const getDataDiscoverFanpages = async () => {
        try {
            const params = {
                text: searchText.applied,
                pageSize : PAGE_SIZE
            }
            const result = await window.COMMON.query(FanpageGRAPHQL.QUERY_FIND_FANPAGE, params)
            if (result?.data) {
                setDataDiscoverFanpages(result?.data.findFanpages.data)
                setDiscoverFanpageCount(result?.data.findFanpages.total)
                if (result?.data.findFanpages.data.length === result?.data.findFanpages.total) {
                    setIsStillMore(false)
                } else {
                    setIsStillMore(true)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getDataFanpagesPending = async () => {
        try {
            const params = {
                status: Constant.FANPAGE.PENDING,
                isOwner: false,
                isJoin: false,
                pageSize: 100
            }
            const result = await window.COMMON.query(FanpageGRAPHQL.QUERY_FANPAGE_RELATIONSHIPS_BY_USER, params)

            if (result?.data) {
                const idsOfPendingFanpage = result.data.getFanpageRelationships.data.map(el=>el.fanpage._id)
                setIdsFanpagesPending(idsOfPendingFanpage)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const fetchMoreData = async () => {
        try {
            const params = {
                text: searchText.applied,
                pageSize: PAGE_SIZE,
                page: parseInt(dataDiscoverFanpages.length/PAGE_SIZE) +1
            }
			const result = await window.COMMON.query(FanpageGRAPHQL.QUERY_FIND_FANPAGE, params)
            if (result?.data?.findFanpages?.data?.length >0) {
                if ((result?.data.findFanpages.data.length + dataDiscoverFanpages.length) >= discoverFanpageCount) {
                    setIsStillMore(false)
                } else {
                    setIsStillMore(true)
				}
                setTimeout(() => {
                    setDataDiscoverFanpages([...dataDiscoverFanpages, ...result?.data.findFanpages.data])
                }, 500)
            }
        } catch (error) {
            window.COMMON.showErrorLogs('FanpageMember.fetchMoreData');
        }
	}
    
    const saveRequestFanpage = async (paramIndex) => {
        try {
            const params = {
                name: currentUser.nickname,
                id : dataDiscoverFanpages[paramIndex]._id
            }
            if (dataDiscoverFanpages[paramIndex].subQuestion1) {
                params.subQuestion1 = dataDiscoverFanpages[paramIndex].subQuestion1
                params.subAnswer1 = dataSave.answerQuestion1
            }
            if (dataDiscoverFanpages[paramIndex].subQuestion2) {
                params.subQuestion2 = dataDiscoverFanpages[paramIndex].subQuestion2
                params.subAnswer2 = dataSave.answerQuestion2
            }
            if (dataDiscoverFanpages[paramIndex].subQuestion3) {
                params.subQuestion3 = dataDiscoverFanpages[paramIndex].subQuestion3
                params.subAnswer3 = dataSave.answerQuestion3
            }
            const result = await window.COMMON.mutation(FanpageGRAPHQL.MUTATION_REQUEST_FANPAGE_RELATIONSHIP, {input: params})
            if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
                createTrackingEvent(eventName.JOIN_FANPAGE);
                getDataFanpagesPending()
                getDataDiscoverFanpages()
                reloadParent()
                onCloseModal()
            }
        } catch (error) {
            console.log(error)
        }
        
    }

    const handleChangeText = (event) => {
        dataSave[event.target.name] = event.target.value
        setDataSave({ ...dataSave })
    }

    const handleChangeInputSearchText = (event) => {
        searchText.input = event.target.value
        setSearchText({...searchText})
    }

    const handleSearchPage = () => {
        searchText.applied = searchText.input
        searchText.input = ''
        setSearchText({...searchText})
        getDataDiscoverFanpages()
    }

    const onRemoveAppliedSearch = () => {
        searchText.applied = ''
        setSearchText({...searchText})
        getDataDiscoverFanpages()
    }

    const onClickRequest = (paramIndex) => {
        if (dataDiscoverFanpages[paramIndex].type === Constant.FANPAGE.PUBLIC ||
            (dataDiscoverFanpages[paramIndex].gopy===0 && !dataDiscoverFanpages[paramIndex].subQuestion1 && !dataDiscoverFanpages[paramIndex].subQuestion2 && !dataDiscoverFanpages[paramIndex].subQuestion3)
        ) {
            saveRequestFanpage(paramIndex)
        } else {
            setObject(paramIndex)
            setOpenModal(true)
        }
    }

    const onCloseModal = () => {
        setDataSave({
            answerQuestion1: '',
            answerQuestion2: '',
            answerQuestion3: ''
        })
        setObject(-1)
        setOpenModal(false)
    }


    useLayoutEffect(() => {
        setTimeout(() => {
            getDataDiscoverFanpages()
            getDataFanpagesPending()
        }, 100);
    }, []);

    return (
        <Grid container >
            <Grid container className='mb-2'>
                {searchText.applied && <Grid item className='d-flex align-items-center ml-3' >
                    <span className='applied-search px-2'>{searchText.applied}
                        <IconButton onClick={onRemoveAppliedSearch} size="small" className='ml-2'>
                            <Backspace style={{fontSize:"1rem", opacity:0.6}}></Backspace>
                        </IconButton>
                    </span>
                    
                    
                </Grid>}
                <InputBase
                    value={searchText.input}
                    onChange={handleChangeInputSearchText}
                    classes={{root: classes.root, focused:classes.focused }}
                    placeholder='Search'
                    endAdornment={
                        <InputAdornment position='end'>
                            <IconButton onClick={handleSearchPage}>
                                <SearchOutlined style={{ color: '#8457FF' }} />
                            </IconButton>
                        </InputAdornment>
                    }
                >
                </InputBase>
            </Grid>
            <InfiniteScroll
                dataLength={dataDiscoverFanpages.length}
                next={fetchMoreData}
                hasMore={isStillMore}
                loader={<div className='text-center py-2 '><LoadingSpinner /></div>}
                endMessage={
                    <p className='text-center mt-2 mb-0 grey-text'>
                        <span>{window.I18N('no_more_fanpage')}</span>
                    </p>
                }
            >   
                <Grid container >
                {dataDiscoverFanpages.map((fanpage, index) =>
                    <Grid className='px-4 pb-4 mb-4 d-flex flex-column align-items-center' key={fanpage._id} item xs={dataDiscoverFanpages.length===1?12:6}>
                        <Card style={{width:"25vw"}}>
                            <MDBCardImage style={{width:"25vw", height:"12vw", objectFit:"cover", margin:0}} className="image-logo" src={fanpage.image} hover overlay="white-slight"/>
                            <CardContent className="py-4 text-center   ">
                                <div style={{ height:"100px"}} className=' pl-2 overflow-hidden ' >
                                    <Grid onClick={() => { history.push(`/my-fanpage?${fanpage._id}`) }} container className='my-1 cursor-pointer' style={{ fontWeight: 500, fontSize: "18px" }}>
                                        {fanpage.name}
                                        {fanpage.type === Constant.FANPAGE.PRIVATE ?<Lock color='disabled' fontSize='small' className='ml-1 my-auto'></Lock>:
                                        <Public color='disabled' fontSize='small' className='ml-1 my-auto'></Public>}
                                    </Grid>
                                    <Grid container className='my-1' style={{ color: "#0000008A", fontWeight: 400 }}>{(fanpage.type === "PUBLIC" ? window.I18N("public_fanpage") : window.I18N("private_fanpage")) + ` - ${fanpage.countMember} ` + window.I18N("member")}</Grid>
                                    {fanpage.type==="PRIVATE" && fanpage.gopy>0 &&<Grid container className='my-1' >{window.I18N("need_gopies")} : {fanpage.gopy}</Grid>}
                                    <Grid container className='my-1 text-left' >{fanpage.description}</Grid>
                                </div>
                            </CardContent>
                            <Grid container className='bg-white rounded pb-4 justify-content-center'>
                                {idsFanpagesPending.includes(fanpage._id) ?
                                    <Button className='text-capitalize' variant="contained" disabled style={{ padding: "6px 40px" }} >
                                    {window.I18N("sent_request")}
                                </Button>
                                    : <Button className='text-capitalize' variant="contained" color='primary' style={{ padding: "6px 40px" }} onClick={() => onClickRequest(index)}>
                                    {fanpage.type==="PUBLIC"?window.I18N("follow"):window.I18N("join")}
                                </Button>}
                            </Grid>
                        </Card>
                    </Grid>    
                )}
                </Grid>
            </InfiniteScroll>
            {object!==-1 && <Modal
                open={openModal}
                onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.modalBox}>
                    <Grid component="h5" className=' my-3 pb-4 text-center justify-content-center'>{window.I18N("confirm_join") + ' "' + dataDiscoverFanpages[object].name + '"'}</Grid>
                    {object !== -1 && dataDiscoverFanpages[object]?.gopy > 0 && 
                        <Grid container >
                            <div className='mx-auto mb-1' style={{ width: "80%", fontWeight: 500, fontSize: "15px" }}>
                                {window.I18N('need_gopies') +' : '+ dataDiscoverFanpages[object]?.gopy}
                            </div>
                        </Grid>
                    }
                    {object!==-1 && (dataDiscoverFanpages[object]?.subQuestion1 ||  dataDiscoverFanpages[object]?.subQuestion2 || dataDiscoverFanpages[object]?.subQuestion3 )&&
                        <Grid container >
                            <div className='mx-auto my-2' style={{ width: "80%", fontSize: "13px" }}>{window.I18N('question_privacy')}</div>
                        </Grid>
                    }
                    {object!==-1 && dataDiscoverFanpages[object]?.subQuestion1 &&
                        <Grid container >
                            <div className='mx-auto mb-1' style={{ width: "80%", fontWeight: 500, fontSize: "15px" }}>{dataDiscoverFanpages[object]?.subQuestion1}</div>
                            <TextField style={{ width: "80%" }} multiline={true} minRows={2} className='mb-4 mx-auto' label={window.I18N('write_answer')} variant="outlined" name="answerQuestion1" value={dataSave.answerQuestion1} onChange={handleChangeText}></TextField>
                        </Grid>
                    }
                    {object!==-1 && dataDiscoverFanpages[object]?.subQuestion2 &&
                        <Grid container >
                            <div className='mx-auto mb-1' style={{ width: "80%", fontWeight: 500, fontSize: "15px" }}>{dataDiscoverFanpages[object]?.subQuestion2}</div>
                            <TextField style={{ width: "80%" }} multiline={true} minRows={2} className='mb-4 mx-auto' label={window.I18N('write_answer')} variant="outlined" name="answerQuestion2" value={dataSave.answerQuestion2} onChange={handleChangeText}></TextField>
                        </Grid>
                    }
                    {object!==-1 && dataDiscoverFanpages[object]?.subQuestion3 &&
                        <Grid container >
                            <div className='mx-auto mb-1' style={{ width: "80%", fontWeight: 500, fontSize: "15px" }}>{dataDiscoverFanpages[object]?.subQuestion3}</div>
                            <TextField style={{ width: "80%" }} multiline={true} minRows={2} className='mb-4 mx-auto' label={window.I18N('write_answer')} variant="outlined" name="answerQuestion3" value={dataSave.answerQuestion3} onChange={handleChangeText}></TextField>
                        </Grid>
                    }
                    <Grid container className="mx-8 mt-4 w-full justify-content-end">
                        <Button
                            className='text-capitalize'
                            variant="contained"
                            color="primary"
                            onClick={onCloseModal}
                        >
                            <Cancel /> &nbsp; {window.I18N("btn_cancel")}
                        </Button>
                        <Button
                            className='ml-4 text-capitalize'
                            variant="contained"
                            color="primary"
                            onClick={()=>saveRequestFanpage(object)}
                        >
                            <Save /> &nbsp; {window.I18N("btn_save")}
                        </Button>
                    </Grid>
                </Box>
            </Modal>}
        </Grid>
    )
}

const mapStateToProps = state => ({
    language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverFanpage);
