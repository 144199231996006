import gql from 'graphql-tag';

const LOGIN_QUERY = gql`
  query($input: LoginInput) {
    loginAdmin(input: $input) {
      _id
      email
      name
      nickname
      avatar
      accountType
      company
      companies
      union
      influencer
      token
      package
    }
  }
`;

const MUTATION_CHECK_ACCOUNT = gql`
  mutation($username: String!) {
    checkExistAccount(username: $username)
  }
`;

const MUTATION_FORGOT_PASSWORD = gql`
  mutation($username: String!) {
    forgotPasswordAccount(username: $username)
  }
`;

const MUTATION_REGISTER_ACCOUNT = gql`
  mutation($input: RegisterAdminInput!) {
    registerAccountAdmin(input: $input) {
      _id
    }
  }
`;

const LoginGRAPHQL = {
  LOGIN_QUERY,
  MUTATION_CHECK_ACCOUNT,
  MUTATION_FORGOT_PASSWORD,
  MUTATION_REGISTER_ACCOUNT
};

export default LoginGRAPHQL;
