import gql from 'graphql-tag';

const QUERY_MENU_LEARNING = gql`
  query($parentId: String!) {
    getMenuLearnings(parentId: $parentId) {
      _id
      code
      name {
        code
        text
      }
      content {
        code
        text
      }
      parent {
        _id
      }
      company
      icon
      index
      type
      createdDate
    }
  }
`;

const MUTATION_ADD_MENU_LEARNING = gql`
  mutation($input: MenuLearningInput!) {
    addMenuLearning(input: $input)
  }
`;

const MUTATION_EDIT_MENU_LEARNING = gql`
  mutation($input: MenuLearningInput!) {
    editMenuLearning(input: $input)
  }
`;

const MUTATION_DEL_MENU_LEARNING = gql`
  mutation($id: String!) {
    deleteMenuLearning(id: $id)
  }
`;

const QUERY_LEARNING = gql`
  query($company: String) {
    getLearnings(company: $company) {
      _id
      code
      name {
        code
        text
      }
      content {
        code
        text
      }
      description {
        code
        text
      }
      company
      icon
      type
      createdDate
    }
  }
`;

const MUTATION_ADD_LEARNING = gql`
  mutation($input: LearningInput!) {
    addLearning(input: $input)
  }
`;

const MUTATION_EDIT_LEARNING = gql`
  mutation($input: LearningInput!) {
    editLearning(input: $input)
  }
`;

const MUTATION_DEL_LEARNING = gql`
  mutation($id: String!) {
    deleteLearning(id: $id)
  }
`;

const QUERY_QUESTION_LEARNING = gql`
  query($parentId: String!) {
    getQuestionLearnings(parentId: $parentId) {
      _id
      code
      question {
        code
        text
      }
      answer {
        code
        text
      }
      correctAnswer
      description {
        code
        text
      }
      createdDate
    }
  }
`;

const MUTATION_DEL_QUESTION_LEARNING = gql`
  mutation($id: String!) {
    deleteQuestionLearning(id: $id)
  }
`;

const MUTATION_CHECK_IMPORT_QUESTION_LEARNING = gql`
  mutation($input: [String!]) {
    checkImportQuestionLearning(input: $input)
  }
`;

const MUTATION_IMPORT_QUESTION_LEARNING = gql`
  mutation($input: [QuestionLearningInput!]) {
    importQuestionLearning(input: $input)
  }
`;

const LearningGRAPHQL = {
  QUERY_MENU_LEARNING,
  MUTATION_ADD_MENU_LEARNING,
  MUTATION_EDIT_MENU_LEARNING,
  MUTATION_DEL_MENU_LEARNING,
  QUERY_LEARNING,
  MUTATION_ADD_LEARNING,
  MUTATION_EDIT_LEARNING,
  MUTATION_DEL_LEARNING,
  QUERY_QUESTION_LEARNING,
  MUTATION_DEL_QUESTION_LEARNING,
  MUTATION_CHECK_IMPORT_QUESTION_LEARNING,
  MUTATION_IMPORT_QUESTION_LEARNING
};

export default LearningGRAPHQL;