import React, { Fragment } from 'react';
import { CustomBodyChatLeftSide } from '../../../../../styles/styled-components/Chat/ChatPage';
import GroupChatList from './GroupChatList';
import ResultSearchList from './ResultSearchList';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { CustomChatResultSearchItem } from '../../../../../styles/styled-components/Chat/ChatPage';
import CircleAvatar from '../../../../components/CircleAvatar';
import ItemFriendSuggest from './ItemFriendSuggest';
const BodyChatLefttSide = ({
  onGroupChatClick,
  searchUserValue,
  filter,
  searchUserResult,
  onSearchUserResultClick,
  individualGroupChatList,
  onFetchIndividualGroupChat,
  fetchIndividualGroupChatLoading,
  isHasMoreIndividualGroupChat,
  searchUserLoading,
  groupChatSelected,
  searchGroupValue,
  manyGroupChatList,
  onFetchManyGroupChatList,
  fetchManyGroupChatLoading,
  isHasMoreManyGroupChat,
  searchGroupResult,
  searchGroupLoading,
  searchRelatedLoading,
  searchRelatedResult,
  fetchDataFriendAndSuggest,
  handleSelectUserProfile,
  userProfileSelect
}) => {

  const checkSearchValueIsEmpty = (value) => {
    if (value && value.trim().length > 0) {
      return false;
    }
    return true;
  };

  return <CustomBodyChatLeftSide id="body-chat-left-side" className="beautiful-scroll">
    {/* individual group chat*/}
    {filter.radio === 0 && <Fragment>
      <GroupChatList
        parentId={'body-chat-left-side'}
        visible={checkSearchValueIsEmpty(searchUserValue) === true}
        groupChatList={individualGroupChatList}
        onFetchGroupChat={onFetchIndividualGroupChat}
        fetchGroupChatLoading={fetchIndividualGroupChatLoading}
        isHasMore={isHasMoreIndividualGroupChat}
        onGroupChatClick={onGroupChatClick}
        groupChatSelected={groupChatSelected}
      />
      <ResultSearchList searchResult={searchUserResult} visible={checkSearchValueIsEmpty(searchUserValue) === false} onSearchResultClick={onSearchUserResultClick} searchUserLoading={searchUserLoading} />
    </Fragment>}
    {/* many group chat*/}
    {filter.radio === 1 && <Fragment>
      <GroupChatList
        parentId={'body-chat-left-side'}
        visible={checkSearchValueIsEmpty(searchGroupValue) === true}
        groupChatList={manyGroupChatList}
        onFetchGroupChat={onFetchManyGroupChatList}
        fetchGroupChatLoading={fetchManyGroupChatLoading}
        isHasMore={isHasMoreManyGroupChat}
        onGroupChatClick={onGroupChatClick}
        groupChatSelected={groupChatSelected}
      />
      <ResultSearchList 
        searchResult={searchGroupResult} 
        visible={checkSearchValueIsEmpty(searchGroupValue) === false} 
        onSearchResultClick={onSearchUserResultClick} 
        searchUserLoading={searchGroupLoading} 
      />

    </Fragment>}
    {filter.radio === 2 && <Fragment>
      {searchRelatedLoading === true && <div className="d-flex justify-content-center">
        <LoadingSpinner />
      </div>}
      {searchRelatedResult.map(item =><CustomChatResultSearchItem className={userProfileSelect?._id===item._id?'active':''} onClick={()=>{handleSelectUserProfile(item)}} key={item._id} data={item}>
        <CircleAvatar src={item.avatar} />
        <div className="name">{item.nickname}</div>
        <ItemFriendSuggest friendStatus={item.friendStatus} accountId={item._id} fetchDataFriendAndSuggest={fetchDataFriendAndSuggest} ></ItemFriendSuggest>
      </CustomChatResultSearchItem>)}
    </Fragment>}

  </CustomBodyChatLeftSide>;
};
export default BodyChatLefttSide;