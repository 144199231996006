import React, { useState, useCallback, useLayoutEffect } from 'react';
import { MDBRow, MDBCol, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';
import Select from 'react-select';

import Table from '../../components/Table';
import Panel from '../../components/Panel';
import Modal from '../../components/Modal';
import ImportExcel from '../../components/ImportExcel';
import ExportExcel from '../../components/ExportExcel';

import LocationGRAPHQL from '../../../graphql/Location';
import eventName from '../../../common/events';
import { useTracking } from 'react-tracking';

export default function LocationPage(props) {
  const { trackEvent } = useTracking();

  const types = [
    { value: 1, label: window.I18N('country') },
    { value: 2, label: window.I18N('state_city') },
    { value: 3, label: window.I18N('city_district') }
  ];
  const config = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('code'), '15%', '', '', 'code', 'TEXT'),
    window.COMMON.createCol(window.I18N('name'), '25%', '', '', 'name', 'TEXT'),
    window.COMMON.createCol(window.I18N('parent_code'), '15%', '', '', 'parent', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_by'), '20%', '', '', 'createdBy.name', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '10%', '', '', '3', 'BUTTON')
  ];
  const [filter, setFilter] = useState({ nation: null, state: null, city: null, type: {value: 1, label: window.I18N('country')} });
  const [object, setObject] = useState({ index: -1, parent: '-1' });
  const [data, setData] = useState([]);
  const [dataCountry, setDataCountry] = useState([]);
  const [dataSave, setDataSave] = useState({ });
  const [dataImport, setDataImport] = useState({});
  const [options, setOptions] = useState({
    nation: [],
    city: [],
    state: [],
    company: []
  });

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataLocation();
    }, 100);
    // eslint-disable-next-line
  }, []);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleFilterSelect = async (event, attr) => {
    filter[attr] = event;
    if (attr === 'type') {
      object.parent = '-1';
      options.nation = await window.COMMON.createDataSelect(dataCountry, '_id', 'name', 'code');
      if (filter.type.value > 1) {
        filter.nation = options.nation && options.nation.length > 0 ? options.nation[0] : null;
        object.parent = window.COMMON.getValueFromAttr(filter, 'nation.code', '-2');
      }
      if (filter.type.value > 2) {
        options.state = await window.COMMON.getDataLocation(filter.nation.code);
        filter.state = options.state && options.state.length > 0 ? options.state[0] : null;
        object.parent = window.COMMON.getValueFromAttr(filter, 'state.code', '-2');
      }
    } else if (attr === 'nation') {
      if (filter.type.value === 3) {
        options.state = await window.COMMON.getDataLocation(filter.nation.code);
        filter.state = options.state && options.state.length > 0 ? options.state[0] : null;
        object.parent = window.COMMON.getValueFromAttr(filter, 'state.code', '-2'); 
      } else {
        object.parent = window.COMMON.getValueFromAttr(filter, 'nation.code', '-2');
      }
    } else {
      object.parent = window.COMMON.getValueFromAttr(filter, 'state.code', '-2');
    }
    setObject(object);
    setOptions(options);
    setFilter({ ...filter });
    getDataLocation();
  };

  const handleChange = (event) => {
    if (event.target.type === 'checkbox') {
      dataSave[event.target.name] = event.target.checked;
    } else {
      dataSave[event.target.name] = event.target.value;
    }
    setDataSave({ ...dataSave });
  };

  const handleFileImport = (dataImport) => {
    checkImportData(dataImport);
    window.COMMON.showModal('#modal-import');
  };

  const getDataLocation = async () => {
    try {
      window.resetDataTable('table-data');
      const result = await window.COMMON.query(LocationGRAPHQL.QUERY_DATA_LOCATION, { parent: object.parent });
      if (result && result.data) {
        const data = window.COMMON.formatDate(result.data.getLocations, 'createdDate');
        setData(data);
        if (object.parent === '-1') {
          setDataCountry(data);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('LocationPage.getDataLocation');
    }
    window.initDataTable('table-data');
  };

  const openSaveDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-location');
    object.index = index;
    const dataSave = {};
    if (index === -1) {
      dataSave.name = '';
      dataSave.code = '';
      dataSave.parent = object.parent;
    } else {
      const obj = data[index];
      dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
      dataSave.name = window.COMMON.getValueFromAttr(obj, 'name');
      dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
      dataSave.parent = window.COMMON.getValueFromAttr(obj, 'parent');
    }
    setObject(object);
    setDataSave({ ...dataSave });
  }, [object, data]);

  // eslint-disable-next-line
  const openDeleteDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteData;
  });

  const saveData = async (event) => {
    try {
      if (!window.COMMON.checkFormValidation('form-location', event)) {
        return;
      }
      const params = {
        input: dataSave
      };
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(LocationGRAPHQL.MUTATION_ADD_LOCATION, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataLocation();
		  createTrackingEvent(eventName.ADD_LOCATION);
        }
      } else {
        const result = await window.COMMON.mutation(LocationGRAPHQL.MUTATION_EDIT_LOCATION, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataLocation();
		  createTrackingEvent(eventName.EDIT_ACCOUNT);
        }
      }
      window.COMMON.hideModal('#modal-location');
    } catch (error) {
      window.COMMON.showErrorLogs('LocationPage.saveData');
    }
  };

  const deleteData = async () => {
    try {
      const params = {
        id: data[object.index]._id
      };
      const result = await window.COMMON.mutation(LocationGRAPHQL.MUTATION_DEL_LOCATION, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataLocation();
        createTrackingEvent(eventName.DEL_LOCATION);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('LocationPage.deleteData');
    }
  };

  // IMPORT
  const deleteImportRow = useCallback((index) => {
    if (dataImport.data.length > 0) {
      dataImport.data.splice(index, 1);
    }
    setDataImport({ ...dataImport });
  }, [dataImport]);

  const checkImportData = async (dataImport) => {
    try {
      if (dataImport.data.length === 0) {
        return;
      }
      const data = [];
      dataImport.data.forEach(item => {
        if (!data.includes(item.code)) {
          data.push(item.code);
        }
      });
      const params = {
        input: data
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(LocationGRAPHQL.MUTATION_CHECK_IMPORT_LOCATION, params);
      if (result.data && result.data.checkImportLocation) {
        for (let i = 0; i < result.data.checkImportLocation.length; i++) {
          const arrs = result.data.checkImportLocation[i].split('-.-');
          if (Number(arrs[1]) === 1) {
            const obj = window.COMMON.getObjectInArrs(arrs[0], dataImport.data, 'code');
            obj.isExisted = true;
          }
        }
      }
      setDataImport(dataImport);
	  createTrackingEvent(eventName.CHECK_IMPORT_LOCATION);
    } catch (error) {
      window.COMMON.showErrorLogs('LocationPage.checkImportData');
    }
  };
  
  const importData = async () => {
    if (dataImport.data.length === 0) {
      window.COMMON.showMessage('warning', 'MSG_CODE_021', window.MSG.MSG_CODE_021);
      return;
    }
    try {
      const params = {
        input: []
      };
      dataImport.data.forEach(item => {
        if (item.parent && item.code && item.name) {
          const obj = {
            parent: item.parent,
            code: item.code,
            name: item.name
          };
          params.input.push(obj);
        }
      });
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(LocationGRAPHQL.MUTATION_IMPORT_LOCATION, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_IMPORT)) {
        getDataLocation();
        createTrackingEvent(eventName.IMPORT_LOCATION);
      }
      window.COMMON.hideModal('#modal-import');
    } catch (error) {
      window.COMMON.showErrorLogs('LocationPage.importData');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" icon="list-alt">
        <div className="container-top d-flex align-items-center justify-content-end wow fadeInDown animated" data-wow-delay="0.5s">
          {
            filter.type.value > 3 ? <div className="bd-highlight mr-2" style={{ width: '200px' }}>
              <div className="input-group input-group-transparent">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="filter" className="fa-fw" />
                  </span>
                </div>
                <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.city} options={options.city} onChange={(event) => handleFilterSelect(event, 'city')} isSearchable/>
              </div>
            </div> : <div></div>
          }
          {
            filter.type.value > 2 ? <div className="bd-highlight mr-2" style={{ width: '200px' }}>
              <div className="input-group input-group-transparent">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="filter" className="fa-fw" />
                  </span>
                </div>
                <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.state} options={options.state} onChange={(event) => handleFilterSelect(event, 'state')} isSearchable/>
              </div>
            </div> : <div></div>
          }
          {
            filter.type.value > 1 ? <div className="bd-highlight mr-2" style={{ width: '200px' }}>
              <div className="input-group input-group-transparent">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="filter" className="fa-fw" />
                  </span>
                </div>
                <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.nation} options={options.nation} onChange={(event) => handleFilterSelect(event, 'nation')} isSearchable/>
              </div>
            </div> : <div></div>
          }
          <div className="bd-highlight mr-2" style={{ width: '360px' }}>
            <div className="input-group input-group-transparent">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <MDBIcon fa="true" icon="filter" className="fa-fw" />
                </span>
              </div>
              <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.type} options={types} onChange={(event) => handleFilterSelect(event, 'type')} isSearchable/>
            </div>
          </div>
          <ExportExcel label={window.I18N('btn_template')} isTemplate={true} dataKey="LOCATION_TEMPLATE"></ExportExcel>
          <ImportExcel label={window.I18N('btn_import')} id="file-import" dataKey="IMPORT_LOCATION" handleFile={handleFileImport}></ImportExcel>
          <MDBBtn color="primary" className="mr-0" onClick={() => openSaveDialog(-1)}>
            <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
          </MDBBtn>
        </div>
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <Table id="table-data" className="table" config={config} data={data} handleRowEditClick={openSaveDialog} handleRowDelClick={openDeleteDialog}></Table>
        </div>
      </Panel>

      <Modal id="modal-location" title={window.I18N('create_update_data')} saveEvent={saveData}>
        <form id="form-location" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <MDBInput outline containerClass="mt-0" type="text" label={window.I18N('parent_code') + ' *'} name="parent" value={dataSave.parent} onChange={handleChange} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="text" label={window.I18N('name') + ' *'} name="name" value={dataSave.name} onChange={handleChange} maxLength="100" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="text" label={window.I18N('code') + ' *'} name="code" value={dataSave.code} onChange={handleChange} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>

      <Modal id="modal-import" className="modal-xl" title={window.I18N('import_data')} saveEvent={importData}>
        <Table id="table-import" className="table" config={dataImport.config} data={dataImport.data} handleRowDelClick={deleteImportRow} isImported={true}></Table>
      </Modal>
    </div>
  );
}