import gql from 'graphql-tag';

const QUERY_GROUPS = gql`
    query {
        getDashboardGroups {
            _id
            categoryName
            title
            items {
                _id
                dataKey
                title
                availableFilter {
                    name
                    values
                }
                useFor
            }
            useFor
        }
    }
`;

const QUERY_SINGLE_TEMPLATES = gql`
    query ($_id: String!) {
        getDashboardTemplate(_id: $_id) {
            _id
            name
            groups {
                _id
                categoryName
                title
            }
            items {
                _id
                dataKey
                title
            }
            createdBy {
                _id
            }
            updatedBy {
                _id
            }
            createdAt
            updatedAt
            fromToFilters {
                dataKey
                from
                to
            }
            matchFilters {
                dataKey
                values
            }
        }
}
`

const QUERY_LIST_TEMPLATES = gql`
    query($input: GetDashboardTemplateList) {
        getDashboardTemplates(input: $input) {
            _id
            name
            groups {
                _id
                categoryName
                title
            }
            items {
                _id
                dataKey
                title
            }
            createdBy {
                _id
            }
            updatedBy {
                _id
            }
            createdAt
            updatedAt
            matchFilters {
                dataKey
                values
            }
            fromToFilters {
                dataKey
                from
                to
            }
            templateType
        }
}

`

const QUERY_TEMPLATES_TABLE_DATA = gql`
    query ($input: GetDashboardTemplateFilterData!){
        getDashboardTemplateTableData(input: $input){
            total
            data
        }
    }
`;

const QUERY_PREVIEW_DATA_TEMPLATES = gql`
    query ($input: PreviewDashboardTemplateFilterData!){
        previewDashboardTemplateTableData(input: $input){
            total
            data
        }
    }
`

const QUERY_DATA_CHART_TEMPLATES = gql`
    query($input: GetDashboardTemplateFilterData!){
        getDashboardTemplateChartData(input: $input){
            dataKey
            data {
                barChart {
                    x
                    y
                }
                pieChart {
                    labels
                    values
                }
                lineChart {
                    x
                    y
                    line
                }
                timeGroup
                valueGroup
            }
            title
        }
    }
`

const QUERY_PREVIEW_DATA_CHART_TEMPLATES = gql`
    query($input: PreviewDashboardTemplateFilterData!){
        previewDashboardTemplateChartData(input: $input){
            dataKey
            data {
                barChart {
                    x
                    y
                }
                pieChart {
                    labels
                    values
                }
                lineChart {
                    x
                    y
                    line
                }
                timeGroup
                valueGroup
            }
            title
        }
    }
`

const MUTATION_CREATE_TEMPLATE = gql`
    mutation ($input: CreateTemplate!) {
        createTemplate(input: $input) {
            _id
            name
            groups {
                _id
                title
            }
            items {
                _id
                title
            }
            createdBy {
                _id
            }
            updatedBy {
                _id
            }
            createdAt
            updatedAt
            matchFilters {
                dataKey
                values
            }
            fromToFilters {
                dataKey
                from
                to
            }
            templateType
        }
    }
`;

const MUTATION_DELETE_TEMPLATES = gql`
    mutation ($ids: [String]!) {
        deleteTemplates(ids: $ids)
    }
`

const MUTATION_UPDATE_TEMPLATES = gql`
    mutation ($_id: String!, $input:UpdateTemplate!) {
        updateTemplate(_id: $_id, input: $input) {
            _id
            name
            createdAt
            updatedAt
            groups {
                _id
                categoryName
                title
            }
            items {
                _id
                dataKey
                title
            }
            createdBy {
                _id
            }
            updatedBy {
                _id
            }
            matchFilters {
                dataKey
                values
            }
            fromToFilters {
                dataKey
                from
                to
            }
        }
    }

`

const DashboardPowerBIGraphQL = {
    QUERY_GROUPS,
    QUERY_SINGLE_TEMPLATES,
    QUERY_LIST_TEMPLATES,
    QUERY_TEMPLATES_TABLE_DATA,
    QUERY_PREVIEW_DATA_TEMPLATES,
    QUERY_DATA_CHART_TEMPLATES,
    QUERY_PREVIEW_DATA_CHART_TEMPLATES,
    MUTATION_CREATE_TEMPLATE,
    MUTATION_DELETE_TEMPLATES,
    MUTATION_UPDATE_TEMPLATES,
}

export default DashboardPowerBIGraphQL