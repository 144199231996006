import React from 'react';
import { MDBCol, MDBRow, MDBIcon } from 'mdbreact';

const ScheduleCalendar = React.memo(function ScheduleCalendar(props) {
  const DAYS_IN_WEEK = [window.I18N('sun'), window.I18N('mon'), window.I18N('tue'), window.I18N('wed'), window.I18N('thu'), window.I18N('fri'), window.I18N('sat')];
  const TIMES = [
    { value: '00:00', label: '0 AM' },
    { value: '01:00', label: '1 AM' },
    { value: '02:00', label: '2 AM' },
    { value: '03:00', label: '3 AM' },
    { value: '04:00', label: '4 AM' },
    { value: '05:00', label: '5 AM' },
    { value: '06:00', label: '6 AM' },
    { value: '07:00', label: '7 AM' },
    { value: '08:00', label: '8 AM' },
    { value: '09:00', label: '9 AM' },
    { value: '10:00', label: '10 AM' },
    { value: '11:00', label: '11 AM' },
    { value: '12:00', label: '12 PM' },
    { value: '13:00', label: '1 PM' },
    { value: '14:00', label: '2 PM' },
    { value: '15:00', label: '3 PM' },
    { value: '16:00', label: '4 PM' },
    { value: '17:00', label: '5 PM' },
    { value: '18:00', label: '6 PM' },
    { value: '19:00', label: '7 PM' },
    { value: '20:00', label: '8 PM' },
    { value: '21:00', label: '9 PM' },
    { value: '22:00', label: '10 PM' },
    { value: '23:00', label: '11 PM' }
  ];

  const dateClick = (event, item, time) => {
    event.stopPropagation();
    if (props.handleDateClick) {
      props.handleDateClick(item, time);
    }
  };

  const itemClick = (event, item) => {
    event.stopPropagation();
    if (props.handleItemClick) {
      props.handleItemClick(item);
    }
  };

  const generateItems = (item, time) => {
    let data = [];
    if (time) {
      const arrs = time.value.split(':');
      const start = Number(arrs[0]) * 60 + Number(arrs[1]);
      const end = start + 60;
      const date = window.COMMON_DATE.formatDateJS(item.date);
      data = props.dataSchedule.filter(function(element) {
        const itemTime = Number(element.hours) * 60 + Number(element.minutes);
        return itemTime >= start && itemTime < end && date === element.textDate; 
      });
    } else {
      data = window.COMMON.getArrsInArrs(window.COMMON_DATE.formatDateJS(item.date), props.dataSchedule, 'textDate');
    }
    if (data.length > 0 && data.length <= 4) {
      return <div className="container-list-schedule">
        {
          data.map((item, i) => 
            <div className="item-schedule d-flex" key={item._id} style={{ backgroundColor: item.color }} onClick={(event) => itemClick(event, item)}>
              <div className="text-time font-weight-bold mr-1" style={{ width: '35px' }}>{item.textTime}</div>
              <div className="text-content">{window.COMMON.getValueFromAttr(item, 'post.text')}</div>
            </div>
          )
        }
      </div>;
    }
    if (data.length > 4) {
      const dataTop = [];
      for (let i = 0; i < 3; i++) {
        const element = data[i];
        dataTop.push(element);
      }
      return <div className="container-list-schedule">
        {
          dataTop.map((item, i) => 
            <div className="item-schedule d-flex" key={item._id} style={{ backgroundColor: item.color }} onClick={(event) => itemClick(event, item)}>
              <div className="text-time font-weight-bold mr-1" style={{ width: '35px' }}>{item.textTime}</div>
              <div className="text-content">{window.COMMON.getValueFromAttr(item, 'post.text')}</div>
            </div>
          )
        }
        <div className="dropdown">
          <p className="item-more" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-boundary="viewport" onClick={(event) => event.stopPropagation()}>
            <span>+ {data.length - 3 + ' ' + window.I18N('view_more')}</span> <MDBIcon fa="true" icon="caret-down" className="fa-fw" />
          </p>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" data-boundary="viewport"  style={{ height: '200px', overflow: 'auto' }}>
            {
              data.map((item, i) => 
                <li className='dropdown-item d-flex mb-1' style={{ backgroundColor: item.color }} key={item._id} onClick={(event) => itemClick(event, item)}>
                  <div className="text-time font-weight-bold mr-1" style={{ width: '60px' }}>{item.textTime}</div>
                  <div className="text-content">{window.COMMON.getValueFromAttr(item, 'post.text')}</div>
                </li>
              )
            }
          </ul>
        </div>
      </div>;
    }
    return <></>;
  };

  const generateListItems = (time) => {
    const data = window.COMMON.getArrsInArrs(window.COMMON_DATE.formatDateJS(time.date), props.dataSchedule, 'textDate');
    if (data.length > 0) {
      return data.map((item, i) => 
        <li key={item._id} className="list-group-item item-schedule" onClick={(event) => itemClick(event, item)}>
          <div className="d-flex align-items-center">
            <div className="text-time ml-2" style={{ width: '80px' }}>{item.textTime}</div>
            <MDBIcon fa="true" icon="circle" style={{ color: item.color }} className="fa-fw mr-1" />
            <div className="text-content">{window.COMMON.getValueFromAttr(item, 'post.text')}</div>
          </div>
        </li>
      );
    }
    return <></>;
  };

  return (
    <div className="container-schedule-calendar w-100" id="container-schedule-calendar">
      { props.children }
      {
        props.view.value === 'MONTH' ? <div className="view-month bg-white w-100 mt-2 wow fadeInUp animated" data-wow-delay="0.5s">
          <MDBRow className="m-0">
            {
              DAYS_IN_WEEK.map((item, i) => 
                <MDBCol key={i} className="item-title p-2 text-center">
                  <span className={'text-title'}>{item}</span>
                </MDBCol>
              )
            }
          </MDBRow>
          {
            props.data.map((item, i) => 
              <MDBRow className="m-0" key={i}>
                {
                  item.map((child, i) => 
                    <MDBCol key={child.date} className={'item p-2 item-' + child.type + (window.COMMON_DATE.compareWithNow(child.date) === 0 ? ' item-today' : '')} onClick={(event) => dateClick(event, child)}>
                      <div className="mb-2"><span className={'text-date'}>{child.day}</span></div>
                      {generateItems(child)}
                    </MDBCol>
                  )
                }
              </MDBRow>
            )
          }
        </div> : (props.view.value === 'WEEK' || props.view.value === 'DAY') ? <div className="view-week bg-white w-100 mt-2 wow fadeInUp animated" data-wow-delay="0.5s">
          <MDBRow className="m-0">
            <MDBCol sm="1" className="item p-0">
              <div className="container-title d-flex align-items-center justify-content-center">
                <p className="text-title mb-2"><small>{window.I18N('time')}</small></p>
              </div>
              {
                TIMES.map((time, i) => <div key={time.value + '-' + i} className="container-time p-2 d-flex align-items-center justify-content-center">
                  <small className="grey-text">{time.label}</small>
                </div>)
              }
            </MDBCol>
            {
              props.data.map((item, i) => 
                <MDBCol key={item.date + '-' + i} className={'item p-0' + (window.COMMON_DATE.compareWithNow(item.date) === 0 ? ' item-today' : '')}>
                  <div className="container-title p-2">
                    <p className="text-title mb-2"><small>{item.daysInWeek}</small></p>
                    <span className="text-date">{item.day}</span>
                  </div>
                  {
                    TIMES.map((time, j) => <div key={time.value + '-' + i + '-' + j} className="container-time p-2" onClick={(event) => dateClick(event, item, time)}>
                      {generateItems(item, time)}
                    </div>)
                  }
                </MDBCol>
              )
            }
          </MDBRow>
        </div> : <div className="view-list bg-white w-100 mt-2 wow fadeInUp animated" data-wow-delay="0.5s">
          <ul className="list-group">
            {
              props.data.map((item, i) => <React.Fragment key={i}>
                <li className={'list-group-item item-title' + (window.COMMON_DATE.compareWithNow(item.date) === 0 ? ' item-today' : '')}>
                  <div className="d-flex align-items-center">
                    <p className="text-title mb-0">{item.daysInWeek + ' - ' + item.displayDate}</p>
                  </div>
                </li>
                {generateListItems(item)}
              </React.Fragment>
              )
            }
          </ul>
        </div>
      }
    </div>
  );
});

export default ScheduleCalendar;