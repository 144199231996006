import gql from 'graphql-tag';

const QUERY_USER_CASE_ASSESSMENT = gql`
  query($id: String) {
    getUserCaseAssessments(companyId: $id) {
      _id
      name
      code
      goal
      reason
      rejectReason
      approvalReminderDate
      nation {
        _id
        code
        name
      }
      state {
        _id
        code
        name
      }
      city {
        _id
        code
        name
      }
      companyIndustry {
        _id
        code
        value
      }
      assessment {
        _id
        code
        name
        status
        period
        startDate
        endDate
      }
      status
      createdDate
      createdBy {
        _id
        name
        nickname
        email
        avatar
      }
    }
  }
`;

const QUERY_CASE_ASSESSMENT_WITH_FILTER = gql`
  query($input: CaseAssessmentQuery!) {
    queryCaseAssessments(query: $input) {
      data {
        _id
        name
        advisors {
          _id
          name
          nickname
          email
        }
        conclusions {
          _id

          overview {
            evaluation
            comment
          }
          stating {
            evaluation
            comment
          }
          policy {
            evaluation
            comment
          }
          content {
            evaluation
            comment
          }
          handling {
            evaluation
            comment
          }
        }
        code
        goal
        reason
        rejectReason
        approvalReminderDate
        nation {
          _id
          code
          name
        }
        state {
          _id
          code
          name
        }
        city {
          _id
          code
          name
        }
        companyIndustry {
          _id
          code
          value
        }
        assessment {
          _id
          code
          name
          status
          type
          period
          description
          startDate
          endDate
          companies {
            _id
            information {
              name
            }
          }
          groups
          {
            _id
            code
            name {
              code
              text
            }
          }
        }
        status
        createdDate
        createdBy {
          _id
          name
          nickname
          email
          avatar
        }
        influencerCompany {
          _id
          information {
            name
          }
        }
      }
      total
      pageSize
      pageIndex
    }
  }
`;

const QUERY_GET_CASE_BY_SELF_ASSESSMENTS = gql`
  query($selfAssessmentIds: [String!]) {
    getCaseAssessmentsBySelfAssessmentIds(selfAssessmentIds: $selfAssessmentIds) {
      _id
      name
      code
      assessment {
        _id
        name
        code
      }
      createdBy
      {
        _id
        influencer
        {
          _id
        }
      }
      status
    }
  }
`;

const QUERY_GET_CASE_CONCLUSION_BY_CASE = gql`
  query($caseId: String!) {
    getCaseAssessmentConclusionByCaseId(caseId: $caseId) {
      _id
      case {
        _id
        name
        code
        status
        assessment {
          _id
          name
          code
        }
      }

      companyTask {
        _id
      }
      influencerTask {
        _id
      }

      overview {
        evaluation
        comment
      }
      stating {
        evaluation
        comment
      }
      policy {
        evaluation
        comment
      }
      content {
        evaluation
        comment
      }
      handling {
        evaluation
        comment
      }

      createdDate
      createdBy {
        _id
        name
        nickname
        email
      }
      updatedDate
    }
  }
`;

const QUERY_ASSESSMENT_CONCLUSION_BY_COMPANY_TASK_ID = gql`
  query($companyTask: String!) {
    getAssessmentConclusionByCompanyTaskId(companyTask: $companyTask) {
      _id
      companyTask {
        _id
      }
      influencerTask {
        _id
      }
      overview {
        comment
        evaluation
      }
      stating {
        comment
        evaluation
      }
      policy {
        comment
        evaluation
      }
      content {
        comment
        evaluation
      }
      handling {
        comment
        evaluation
      }
    }
  }
`;

const QUERY_REMINDERS_TASK = gql`
  query($input: RemindersTaskFilter!) {
    queryRemindersTask(input: $input) {
      _id
      task{
        _id
      }
      assignUsers {
        _id
        name
      }
      reminderDate
      eventDate
      status
      messageContent
      createdDate
      updatedDate
      createdBy{
        name
      }
      updatedBy{
        name
      }
    }
  }
`
const QUERY_LAST_CONCLUSION = gql`
  query($companyIds: [String!]){
    getLastConclusionOfCompanies(companyIds: $companyIds){
      latestConclusionId
      companyId
      createdDate
      evalution
    }
  }
`


const MUTATION_ADD_CASE_ASSESSMENT = gql`
  mutation($caseInput: CaseAssessmentInput!, $assessmentInput: SelfAssessmentInput!) {
    addCaseAssessment(caseInput: $caseInput, assessmentInput: $assessmentInput)
  }
`;

const MUTATION_EDIT_CASE_ASSESSMENT = gql`
  mutation($caseInput: CaseAssessmentInput!, $assessmentInput: SelfAssessmentInput!) {
    editCaseAssessment(caseInput: $caseInput, assessmentInput: $assessmentInput)
  }
`;

const MUTATION_DELETE_CASE_ASSESSMENT = gql`
  mutation($id: String!) {
    deleteCaseAssessment(caseAssessmentId: $id)
  }
`;

const MUTATION_APPROVE_CASE_ASSESSMENT = gql`
  mutation($id: String!) {
    approveCaseAssessment(caseAssessmentId: $id)
  }
`;

const MUTATION_REJECT_CASE_ASSESSMENT = gql`
  mutation($id: String!, $reason: String) {
    rejectCaseAssessment(caseAssessmentId: $id, reason: $reason)
  }
`;

const MUTATION_ADD_OR_UPDATE_CASE_CONCLUSION = gql`
  mutation($input: CaseConclusionInput!) {
    addOrUpdateCaseAssessmentConclusion(input: $input)
  }
`;

const MUTATION_CLOSE_CASE_ASSESSMENT = gql`
  mutation($caseAssessmentId: String!) {
    closeCaseAssessment(caseAssessmentId: $caseAssessmentId)
  }
`;

const MUTATION_ADD_REMINDER_TASK = gql`
  mutation($reminderInput: ReminderTaskCreateInput!){
    addReminderTask(reminderInput: $reminderInput)
  }
`

const MUTATION_UPDATE_REMINDER_TASK = gql`
  mutation($reminderInput: ReminderTaskUpdateInput!){
    updateReminderTask(reminderInput: $reminderInput)
  }
`

const MUTATION_CANCEL_REMINDER_TASK = gql`
  mutation($reminderId: String!){
    cancelReminderTask(reminderId: $reminderId)
  }
`

const CaseAssessmentGRAPHQL = {
  QUERY_USER_CASE_ASSESSMENT,
  QUERY_CASE_ASSESSMENT_WITH_FILTER,
  QUERY_GET_CASE_BY_SELF_ASSESSMENTS,
  QUERY_GET_CASE_CONCLUSION_BY_CASE,
  QUERY_ASSESSMENT_CONCLUSION_BY_COMPANY_TASK_ID,
  QUERY_REMINDERS_TASK,
  QUERY_LAST_CONCLUSION,
  
  MUTATION_ADD_CASE_ASSESSMENT,
  MUTATION_EDIT_CASE_ASSESSMENT,
  MUTATION_DELETE_CASE_ASSESSMENT,
  MUTATION_APPROVE_CASE_ASSESSMENT,
  MUTATION_REJECT_CASE_ASSESSMENT,
  MUTATION_ADD_OR_UPDATE_CASE_CONCLUSION,
  MUTATION_CLOSE_CASE_ASSESSMENT,
  MUTATION_ADD_REMINDER_TASK,
  MUTATION_UPDATE_REMINDER_TASK,
  MUTATION_CANCEL_REMINDER_TASK
};

export default CaseAssessmentGRAPHQL;
