import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { Bar, Pie, HorizontalBar } from 'react-chartjs-2';
import TaskScheduleGRAPHQL from '../../../graphql/TaskSchedule';
import { connect } from 'react-redux';
import ReactSelect from 'react-select';
import { MDBIcon } from 'mdbreact';
import CustomChart from './components/CustomChart';

const paletteColor = ["rgba(0, 159, 109, 0.5)","rgba(190, 0, 0, 0.5)","rgba(61, 0, 189, 0.5)","rgba(0, 65, 175, 0.5)","rgba(54, 162, 235, 0.5)","rgba(255, 60, 84, 0.5)","rgba(201, 203, 246, 0.5)", "rgba(74, 147, 124, 0.5)", "rgba(147, 147, 124, 0.5)", "rgba(147, 147, 235, 0.5)", " rgba(147, 196, 235, 0.5)", "rgba(0, 171, 184, 0.5)", "rgba(139, 171, 184, 0.5)", "rgba(139, 255, 184, 0.5)", "rgba(165, 197, 97, 0.5)", "rgba(165, 163, 152, 0.5)", "rgba(0, 0, 0, 0.5)", "rgba(0, 47, 0, 0.5)", "rgba(26, 47, 43, 0.5)", "rgba(255, 38, 214, 0.5)"]

const InsightChartTaskSchedule = ({ companyId, companies }) => {
    const dataMonthsSelect = [
        {
            label: window.I18N('last_3_month'),
            value: "92",
        },
        {
            label: window.I18N('last_6_month'),
            value: "183",
        }
    ]
    const [dataDaysOfMonth, setDataDaysMonth] = useState(dataMonthsSelect[0])
    const scheduleStatus = [
        "stopped",
        "paused",
        "news",
        "process",
        "finished"
    ]
    const grantType = ["COMPANY", "GROUP_CHAT"]

    const dataSchedule = useRef([])
    const [dataChart, setDataChart] = useState({ labels: [], datasets: [] })
    const [focusedIndex, setFocusedIndex] = useState(null);
    const [chartMode, setChartMode] = useState('COMPANY')

    const handleSelectMonths = (value) => {
        setDataDaysMonth(value)
    }

    const groupDataforChart = (paramData) => {
        let groupDataChart = window.COMMON.getDataChartInPastYear({
            data: paramData,
            numberMonths: Math.floor(+dataDaysOfMonth.value/30),
            types: grantType,
            itemKey: 'grantType',
            dateKey: 'startDate',
            formatDateLabel: "MM/YYYY"
        });
        //console.log(groupDataChart)
        dataChart.labels = groupDataChart.labels
        const indexDataset = grantType.indexOf(chartMode)
        dataChart.datasets = [{
            data: groupDataChart.data[indexDataset],
            backgroundColor: paletteColor,
            borderColor: paletteColor.map(color => color.replace('0.2', '1')),
            borderWidth: 1,
            barPercentage: 0.5,
        }]
        setDataChart({ ...dataChart })
    }

    const getDataSchedule = async (pastDays = '180') => {
        try {
            const startDate = window.COMMON_DATE.getDateFollowPastDate({ pastDays, mode: -1 });
            const params = {
                input: {
                    startDate,
                    endDate: window.COMMON_DATE.getStrDateFilter(new Date(), 1),
                }
            };
            if (companyId) {
                params.input.organizationIds = [companyId]
            } else {
                params.input.organizationIds = [...companies.companyIds, ...companies.influencerIds, ...companies.unionIds]
            }
            const result = await window.COMMON.query(
                TaskScheduleGRAPHQL.QUERY_TASK_SCHEDULE_DATA_INSIGHT,
                params
            );
            if (result?.data?.getTaskSchedulesDataInsight) {
                const data = window.COMMON.formatDate(result.data.getTaskSchedulesDataInsight, 'startDate', "MM/YYYY")
                data.forEach(el => {
                    el.group = el.group?.name[0]?.text
                    el.companies = el.companies.map(company => company?.information?.name )
                })
                dataSchedule.current = data
                groupDataforChart(data)
                //formatDatawithLanguage(data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const InnerHTMLPopup = (dataPopUp) => {
        const [data, focusedIndex] = dataPopUp
        const dataScheduleStatus = {
            datasets: [{
                data: new Array(scheduleStatus.length).fill(0),
                backgroundColor: paletteColor,
                barPercentage: 0.4
            }],
            labels: scheduleStatus.map(el => window.I18N(el))
        }
        const dataTaskStatus = {
            datasets: [{
                data: [0, 0],
                backgroundColor: paletteColor.slice(5)
            }],
            labels: [window.I18N('done'), window.I18N('not_done')]
        }
        const dataQA = []
        const dataCompany = []
        data.forEach(schedule => {
            dataScheduleStatus.datasets[0].data[2 + schedule.status]++
            if (!dataQA.includes(schedule.group)) {
                dataQA.push(schedule.group)
            }
            dataTaskStatus.datasets[0].data[0] += schedule.taskDone
            dataTaskStatus.datasets[0].data[1] += schedule.taskNotDone
            if (chartMode === "COMPANY") {
                schedule.companies.forEach(company => {
                    if (!dataCompany.includes(company)) {
                        dataCompany.push(company)
                    }
                })
            }
        })

        return <div>
            <div className='d-flex align-items-center justify-content-around'>
                <HorizontalBar data={dataScheduleStatus} redraw={true} width={170} options={{
                    legend: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: window.I18N('schedule_status'),
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                stepSize: 1,
                                fontSize: 10,
                            },
                            gridLines: { display: false }
                        }],
                        xAxes: [{
                            ticks: {
                                beginAtZero: true,
                                stepSize: 1,
                                fontSize: 10,
                            },
                        }]
                    },
                    responsive: false,
                    maintainAspectRatio: false
                }}
                />
                <Pie data={dataTaskStatus} redraw={true} width={170} options={{
                    legend: {
                        position: 'bottom',
                        labels: {
                            boxWidth: 12,
                            fontSize: 10
                        }
                    },
                    title: {
                        display: true,
                        text: `${window.I18N('tasks_number')} ${dataTaskStatus.datasets[0].data[0] + dataTaskStatus.datasets[0].data[1]}`,
                    },
                    responsive: false,
                    maintainAspectRatio: false
                }} />
            </div>
            <div className='d-flex justify-content-around' >
                <div style={{ backgroundColor: "#F1F2F8", padding: "5px 15px", maxHeight: "200px", minHeight: "100px", width: "150px", overflow: "scroll", marginLeft: " 3px" }}>
                    <div style={{ fontWeight: 500 }}>{dataQA.length} {window.I18N("group_qa")}</div>
                    {dataQA.map((group, i) =>
                        <li key={i} style={{ fontSize: "11px", }}>{group}</li>
                    )}
                </div>
                <div style={{ backgroundColor: "#F1F2F8", padding: "5px 15px", maxHeight: "200px", minHeight: "100px", width: "150px", overflow: "scroll", marginLeft: " 3px" }}>
                    <div >
                        <div style={{ fontWeight: 500 }}>{dataCompany.length} {window.I18N("company")}</div>
                        {dataCompany.map((company, i) => <li key={i} style={{ fontSize: "11px", }}>
                            {company}
                        </li>)}
                    </div>
                </div>
            </div>
            <div className=" text-center mt-2" style={{ fontWeight: 500, fontSize: 14 }}>{window.I18N('survey')} {window.I18N('in-month')} {dataChart.labels[focusedIndex]} : {data.length}</div>
        </div>
    }

    const handleDataHover = (chartElHover, chartElements) => {
        const data = []
        const focusedIndex = chartElements[0]._index
        dataSchedule.current.forEach(el => {
            if (el.startDate === dataChart.labels[focusedIndex] && el.grantType=== chartMode) {
                data.push(el)
            }
        })
        return [data, focusedIndex]
    }

    useLayoutEffect(() => {
        setTimeout(() => {
            if (companyId || companies.companyIds?.length|| companies.influencerIds?.length|| companies.unionIds?.length) {
                getDataSchedule(dataDaysOfMonth.value)
            }
        }, 0)
        // eslint-disable-next-line
    }, [companies, companyId, dataDaysOfMonth]);

    useEffect(() => {
        groupDataforChart(dataSchedule.current)
    }, [chartMode])
    /* useEffect(() => {
        if (dataScheduleRaw.current?.length) {
            formatDatawithLanguage(dataScheduleRaw.current)
        }
    }, [language]) */

    return <div className='mt-4 mx-auto '>
        <div className='w-full d-flex flex-row align-items-center justify-content-between mb-2'>
        {/* tile chart */}
            <h6 className="m-0 font-weight-bold" style={{ fontSize: "1rem" }}>{`Task Schedule Chart Insight`}</h6>     
        {/* filter chart */}
           <div className='d-flex align-items-center justify-content-end' style={{ gap: '16px', flex: 1 }}>
                <div className='d-flex align-items-center justify-content-start' style={{width: '40%'}}>
                        <div className="input-group input-group-transparent">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <MDBIcon fa="true" icon="calendar" className="fa-fw" />
                                </span>
                            </div>
                            <ReactSelect
                                value={dataDaysOfMonth}
                                options={dataMonthsSelect}
                                className="md-form m-0"
                                placeholder={window.I18N("choose_option")}
                                onChange={handleSelectMonths}
                            />
                        </div>
                </div>
                <div className='d-flex flex-row'>
                {/* GROUP_CHAT */}
                    <button 
                        className={`mode-button ${chartMode === 'GROUP_CHAT' ? 'mode-active' : ''}`} 
                        onClick={() => { setChartMode("GROUP_CHAT") }}
                        style={{ borderRadius: '4px 0 0 4px' }}
                    >
                        {window.I18N('group_chat')}
                    </button>
                {/* COMPANY */}
                    <button 
                        className={`mode-button ${chartMode === 'COMPANY' ? 'mode-active' : ''}`} 
                        onClick={() => { setChartMode("COMPANY") }}
                        style={{ borderRadius: '0 4px 4px 0' }}
                    >
                        {window.I18N('company')}
                    </button>
                </div>
           </div>
        </div>
        <div className='d-flex align-items-center justify-content-center' style={{ position: 'relative' }}>
           <CustomChart dataChart={dataChart} createInnerHTMLPopup={InnerHTMLPopup} onDataHover={handleDataHover} />
        </div>
        <div className='chart-title'>{`${window.I18N('schedule-chart')} ${dataDaysOfMonth.label}`}</div>
    </div>
}

const mapStateToProps = state => ({
    language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InsightChartTaskSchedule);