const groupChatEvent = {
  ADD_GROUP_CHAT: 'ADD_GROUP_CHAT',
  EDIT_GROUP_CHAT: 'EDIT_GROUP_CHAT',
  DEL_GROUP_CHAT: 'DEL_GROUP_CHAT',
  ACCEPT_GROUP_RELATIONSHIP: 'ACCEPT_GROUP_RELATIONSHIP',
  DECLINE_GROUP_RELATIONSHIP: 'DECLINE_GROUP_RELATIONSHIP',
  BLOCK_GROUP_RELATIONSHIP: 'BLOCK_GROUP_RELATIONSHIP',
  UPDATE_STATUS_FANPAGE: 'UPDATE_STATUS_FANPAGE'
};

export default groupChatEvent;