import { Button, Fab, Slider } from '@material-ui/core';
import { Add, Save } from '@material-ui/icons';
import { MDBCol, MDBInput, MDBRow, MDBBtn, MDBIcon } from 'mdbreact';
import React, { Fragment, isValidElement, useEffect, useImperativeHandle, useState } from 'react';
import QuestionGRAPHQL from '../../../graphql/Question';
import CategoryQuestionItem from './CategoryQuestionItem';
import Common from '../../../utils/Common';
import {Tooltip} from '@material-ui/core';

const CategoryQuestionsList = React.forwardRef((props, ref) => {
  const {
    groupId,
    dataQA,
    setDataQA,
    numberQuestion,
    isCloned,
    checkSchedule,
    group,
    language,
    addItemQuestion,
    questionsGroupByCategory,
    setQuestionsGroupByCategory
  } = props;

  const questionsRef = React.useRef({});

  // GROUP QUESTIONS FUNCTIONS
  const handleTitleChange = (e, groupIndex) => {
    // update title of group
    // update title for every question in group
    const newDataQA = getDataItems()
    const groupQuestions = questionsGroupByCategory[groupIndex].questions;
    const newDataQuestions = newDataQA.map(item => {
      if (groupQuestions.findIndex(q => q.uuid === item.uuid) >= 0) {
        if (!item?.question?.category?.length || !item?.question?.category.find(c => c.code === language)) {
          item.question.category = [
            {
              text: "",
              code: language
            }
          ]
        }
        item.question.category.find(c => c.code === language).text = e.target.value
      }
      return item
    })
    setDataQA(newDataQuestions);
  };

 
  const handleChangePassScore = (newValue, groupIndex) => {
    const minValue = Math.min(...newValue)
    const maxValue = Math.max(...newValue)
    const newDataQA = getDataItems()
    const groupQuestions = questionsGroupByCategory[groupIndex].questions;
    const newDataQuestions = newDataQA.map(item => {
      if (groupQuestions.findIndex(q => q.uuid === item.uuid) >= 0) {
        item.question.standardLowOfCategory = minValue
        item.question.standardOfCategory = maxValue
      }
      return item
    })
    setDataQA(newDataQuestions);
  };
  const addGroupQuestions = () => {
    const newDataQA = getDataItems()
    setDataQA(
      [
        ...newDataQA,
        {
          uuid: window.COMMON.genKey(),
          group: groupId,
          question: {
            _id: null,
            question: [],
            standardLowOfCategory: window.CONSTANT.MIN_STANDARD_OF_CATEGORY,
            standardOfCategory: window.CONSTANT.MAX_STANDARD_OF_CATEGORY,
            introduction: [],
            type: 'RADIO',
            code: window.COMMON.generateCode('Q'),
            category: [
              {
                text: "",
                code: language
              }
            ],
            recommend: {
              low: [{
                text: window.I18N('low'),
                code: language
              }],
              medium: [{
                text: window.I18N('medium'),
                code: language
              }],
              high: [{
                text: window.I18N('high'),
                code: language
              }]
            },
          },
          answers: [],
        }
      ]);
    setTimeout(()=>{window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })},500)
  };
  const handleDeleteGroupQuestion = async (categoryTitle) => {
    const newDataQA = getDataItems()
    try {
      const newData = await Promise.all(newDataQA.map(async (item) => {
        const findCategoryTitle = window.COMMON.getValueWithLanguage(item.question, 'category', language);
        if (findCategoryTitle === categoryTitle) {
          if(item.question._id !== null) {
            const params = {
              id: item.question._id
            };
            const result = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_DEL_QA, params);
            if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
              return null;
            }
          }else{
              return null;
          }
        }
        return item;
      }));

      const filteredData = newData.filter(item => item !== null);
      setDataQA(filteredData);
    } catch (error) {
      window.COMMON.showErrorLogs('CategoryQuestionsList.handleDeleteGroupQuestion');
    }
  };
  const onDeleteGroupQuestion = (categoryTitle) => {
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = () => {
      handleDeleteGroupQuestion(categoryTitle);
    };
  };

  // QUESTION & ANSWER FUNCTIONS

  const removeItemQuestion = (questionId) => {
    const newDataQA = getDataItems()
    const newData = newDataQA.filter((item) => item.uuid !== questionId);
    setDataQA(newData);
  };

  const handleRecommendTextChange = (e, groupIndex) => {
    const { name: level, value } = e.target;
    const newData = questionsGroupByCategory.map((item, i) => {
      if (i === groupIndex) {
        window.COMMON.setValueWithLanguage(item.recommend, level, language, value)
      }
      return item;
    });
    setQuestionsGroupByCategory(newData);
  };
  const addFormQuestion = (categoryTitle, standardLowOfCategory, standardOfCategory, recommend) => {
    const newDataQA = getDataItems()
    newDataQA.push({
      uuid: window.COMMON.genKey(),
      group: groupId,
      question: {
        _id: null,
        question: [],
        standardLowOfCategory,
        standardOfCategory,
        introduction: [],
        type: 'RADIO',
        code: window.COMMON.generateCode('Q'),
        category: [
          {
            text: categoryTitle,
            code: language
          }
        ],
        recommend
      },
      answers: [],
    });
    setDataQA([...newDataQA]);
  };

  const getDataItems = () => {
      const newDataQuestions = [];
      dataQA.forEach((item)=>{
        const { question, answers } = questionsRef.current[item.uuid].getData()
        const { recommend, standardLowOfCategory, standardOfCategory } = item.question
        const newQuestions = { ...question, recommend, standardOfCategory, standardLowOfCategory }
        newDataQuestions.push({...item, answers, question: newQuestions})
      })
      return newDataQuestions;
  }

  const saveAllGroupQuestions = async () => {
    if(!questionsGroupByCategory) return;
    const categoryTitleArr = []
    for(let i=0;i<questionsGroupByCategory.length;i++){
      const item = questionsGroupByCategory[i];
      const { categoryTitle = '' } = item;
      // handleSaveGroupQAByCategoryTitle(categoryTitle)
      categoryTitleArr.push(categoryTitle);
    }
    if(categoryTitleArr.length<=0) return;

    const questions = dataQA.filter(item => {
      const questionCategory = window.COMMON.getValueWithLanguage(item.question, 'category', language)
      return categoryTitleArr.includes(questionCategory)
    })

    // validate questions input.
    const errorMessages = questions.reduce((errors, item, idx) => {
      if(questionsRef.current[item.uuid]) {
        const invalidMessage = questionsRef.current[item.uuid].validateQuestionInput();

        if(invalidMessage) {
          return [...errors, `Question ${idx + 1} ${invalidMessage}`]
        }
      }
      return errors
    },[])

    if(errorMessages?.length) {
      return Common.showMessage(
        'error',
        errorMessages.shift(),
      );
    }

    // save category questions
    await Promise.all(questions.map(question => questionsRef.current[question.uuid].saveQA()))

  }

  useImperativeHandle(ref, () => ({
    getDataCategoryList: getDataItems
  }))

  useEffect(() => {
    if (!dataQA) return;
    const result = [];
    dataQA.forEach((item) => {
      const findCategoryTitle = window.COMMON.getValueWithLanguage(item.question, 'category', language)
      const categoryIndex = result.findIndex(item => item.categoryTitle === findCategoryTitle)

      if (categoryIndex === -1) {
        result.push({
          categoryTitle: findCategoryTitle,
          standardLowOfCategory: item.question.standardLowOfCategory,
          standardOfCategory: item.question.standardOfCategory,
          recommend: item.question.recommend,
          questions: [item]
        })
      } else {
        result[categoryIndex] = {
          ...result[categoryIndex],
          questions: [
            ...result[categoryIndex].questions,
            item
          ]
        }
      }
    });

    setQuestionsGroupByCategory(result)
  }, [dataQA, language])

  return <Fragment>
    {/* questions */}
    <MDBRow>
      <MDBCol>
        {
          questionsGroupByCategory?.map((item, i) => {
            const { categoryTitle = '', questions = [], standardLowOfCategory, standardOfCategory, recommend } = item;
            
            return (
              <MDBRow className='group-question-container' key={`group-questions-${i}`}>
                {/* title */}
                <MDBCol md='12' className='group-question-header d-flex align-items-center justify-content-center'>
                  <div
                    style={{
                      width: '80%'
                    }}
                  >
                    <MDBInput
                      label={window.I18N('category_name') + ' *'}
                      type='text'
                      rows='2'
                      value={categoryTitle}
                      onChange={e => handleTitleChange(e, i)}
                      className='group-question-header-input'
                      required
                      outline
                      maxLength="100"
                    />
                  </div>
                  {/* standard of question in category */}
                  <div
                    style={{
                      width: '20%',
                      marginLeft: '20px'
                    }}
                    className='category-div'
                  >
                    <div>{window.I18N('standard_of_category')}</div>
                    <Slider
                        value={[standardLowOfCategory, standardOfCategory]}
                        onChange={(event, newValue) => handleChangePassScore(newValue, i)}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        getAriaValueText={(value) => {}}
                    />
                    <div className='d-flex align-items-center justify-content-between' style={{ marginTop: '-10px' }}>
                        <div>{0}</div>
                        <div>{100}</div>
                    </div>
                  </div>
                </MDBCol>
                {/* recommend text */}
                <MDBCol md='12' className='group-question-recommend'>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginTop: '20px'
                    }}
                  >
                    <h3
                      style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                      }}
                    >
                      {window.I18N('recommend')}
                    </h3>
                  </div>
                  {/* list question */}
                  <div
                    style={{
                      marginBottom: '30px',
                      width: '80%'
                    }}
                  >
                    <MDBInput
                      label={window.I18N('low') + ' *'}
                      className='group-question-header-input'
                      type='text' rows='1' required outline maxLength="100"
                      name={'low'}
                      value={window.COMMON.getValueWithLanguage(recommend, 'low', language)}
                      onChange={e => handleRecommendTextChange(e, i)}
                    />
                    <MDBInput
                      label={window.I18N('medium') + ' *'}
                      className='group-question-header-input'
                      type='text' rows='1' required outline maxLength="100"
                      name={'medium'}
                      value={window.COMMON.getValueWithLanguage(recommend, 'medium', language)}
                      onChange={e => handleRecommendTextChange(e, i)}
                    />
                    <MDBInput
                      label={window.I18N('high') + ' *'}
                      className='group-question-header-input'
                      type='text' rows='1' required outline maxLength="100"
                      name={'high'}
                      value={window.COMMON.getValueWithLanguage(recommend, 'high', language)}
                      onChange={e => handleRecommendTextChange(e, i)}
                    />
                  </div>
                </MDBCol>
                {/* question */}
                <MDBCol>
                  {questions.map((item, j) => {
                    return (
                      <CategoryQuestionItem
                        index={j}
                        key={item.uuid}
                        id={item.uuid}
                        item={item.question}
                        category={item.question.category}
                        standardLowOfCategory={standardLowOfCategory}
                        standardOfCategory={standardOfCategory}
                        answers={item.answers}
                        ref={(el) => questionsRef.current[item.uuid] = el}
                        recommend={recommend}
                        numberQuestion={numberQuestion}
                        group={group}
                        language={language}
                        isCloned={isCloned}
                        checkSchedule={checkSchedule}
                        isFromQuestionBank={item.isFromQuestionBank}
                        dataFromOrigin={item.dataFromOrigin}
                        handleSaveItem={addItemQuestion}
                        handleRemoveItem={removeItemQuestion}
                      />
                    )
                  }
                  )}
                </MDBCol>
                {/* Add */}
                <MDBCol md="12 p-3" className='group-question-actions'>
                  <Button 
                    color="primary" 
                    size="small" 
                    variant='outlined'  
                    onClick={() => addFormQuestion(categoryTitle, standardLowOfCategory, standardOfCategory, recommend)}
                  >
                    {window.I18N('add_question')}
                  </Button>
                  <div className='group-question-actions-right'>
                    <Button color="secondary" size="small" variant='outlined' onClick={() => onDeleteGroupQuestion(categoryTitle)}>
                      {window.I18N('delete_group')}
                    </Button>
                  </div>
                </MDBCol>
              </MDBRow>
            );
          })
        }
      </MDBCol>
    </MDBRow>

    <div className="float-button wow fadeInDown animated" data-wow-delay="1s">
      <Tooltip title={window.I18N('btn_save')}>
        <Fab style={{marginRight:'8px'}} color="primary" aria-label="add" size="medium" onClick={saveAllGroupQuestions}>
          <Save />
        </Fab>
      </Tooltip>
      <Tooltip title={window.I18N('add_group_category')}>
        <Fab color="primary" aria-label="add" size="medium" onClick={addGroupQuestions}>
          <Add />
        </Fab>
      </Tooltip>
    </div>
  </Fragment>;
});
export default CategoryQuestionsList;
