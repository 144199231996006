// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n    --background-default: #f0f2f5;\n}\n\n.EventSearch_search-container__-M99U {\n    width: 100%;\n    height: 36px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: var(--background-default);\n    border-radius: 50px;\n    padding: 5px 10px;\n    font-size: 15px;\n}\n\ninput {\n    width: 100%;\n    padding: 0.375rem 0.5rem;\n    font-size: 1rem;\n    font-weight: 400;\n    line-height: 1.5;\n    color: #495057;\n    background-color: transparent;\n    background-clip: padding-box;\n    border: 1px solid transparent;\n    border-radius: 0.25rem;\n    transition: border-color.15s ease-in-out, box-shadow.15s ease-in-out;\n}\n\ninput:focus-visible {\n    outline: none;\n}\n\ninput[type='search']::-webkit-search-cancel-button {\n    color: #495057;\n}\n", ""]);
// Exports
exports.locals = {
	"search-container": "EventSearch_search-container__-M99U"
};
module.exports = exports;
