import React, { useState, useCallback, useLayoutEffect } from 'react';
import Select from 'react-select';
import { MDBRow, MDBCol, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';
import Panel from '../../components/Panel';
import Table from '../../components/Table';
import Modal from '../../components/Modal';

import MetadataGRAPHQL from '../../../graphql/Metadata';
import eventName from '../../../common/events';
import { useTracking } from 'react-tracking';

export default function MetadataPage(props) {
  const { trackEvent } = useTracking();

  const config = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('value'), '30%', '', '', 'value', 'TEXT'),
    window.COMMON.createCol(window.I18N('code'), '15%', '', '', 'code', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_by'), '30%', '', '', 'createdBy.name', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '10%', '', '', '3', 'BUTTON')
  ];
  const typeOptions =  [
    { value: 'COMPANY_TYPE', label: window.I18N('organization_type') },
    { value: 'COMPANY_SIZE', label: window.I18N('headcounts') },
    { value: 'COMPANY_INDUSTRY', label: window.I18N('industry') },
    { value: 'TEXT_INPUT', label: window.I18N('text_filter') },
    { value: 'COMPLAINT_TYPE', label: window.I18N('complaint_type') },
    { value: 'FANPAGE_TYPE', label: window.I18N('fanpage_type') },
    { value: 'INDIVIDUAL_AGE', label: window.I18N('age') },
    { value: 'INDIVIDUAL_OCCUPATION', label: window.I18N('occupation') },
    { value: 'INDIVIDUAL_JOB_TITLE', label: window.I18N('job_title') },
    { value: 'INDIVIDUAL_EDUCATION_BACKGROUND', label: window.I18N('education_background') },
    { value: 'INDIVIDUAL_INCOME', label: window.I18N('income') }
  ];
  const [object, setObject] = useState({ index: -1, type: { value: 'COMPANY_TYPE', label: window.I18N('organization_type') } });
  const [data, setData] = useState([]);
  const [dataSave, setDataSave] = useState({});

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataMetadata();
    }, 100);
    // eslint-disable-next-line
  }, []);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleChange = (event) => {
    dataSave[event.target.name] = event.target.value;
    setDataSave({ ...dataSave });
  };

  const handleSelect = (event) => {
    object.type = event;
    setObject({ ...object });
    getDataMetadata();
  };

  const getDataMetadata = async () => {
    try {
      window.resetDataTable('table-data');
      const params = {
        type: [object.type.value]
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_METADATA, params);
      if (result && result.data) {
        const data = window.COMMON.formatDate(result.data.getMetadatas, 'createdDate');
        setData(data);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MetadataPage.getDataMetadata');
    }
    window.initDataTable('table-data');
  };

  const openSaveDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-metadata');
    object.index = index;
    const dataSave = {};
    if (index === -1) {
      dataSave.value = '';
      dataSave.code = window.COMMON.generateCode('M');
      dataSave.type = object.type.value;
    } else {
      const obj = data[index];
      dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
      dataSave.value = window.COMMON.getValueFromAttr(obj, 'value');
      dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
      dataSave.type = window.COMMON.getValueFromAttr(obj, 'type');
    }
    setObject(object);
    setDataSave({ ...dataSave });
  }, [object, data]);

  // eslint-disable-next-line
  const openDeleteDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteData;
  });

  const saveData = async (event) => {
    try {
      if (!window.COMMON.checkFormValidation('form-metadata', event)) {
        return;
      }
      const params = {
        input: dataSave
      };
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(MetadataGRAPHQL.MUTATION_ADD_METADATA, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataMetadata();
		  createTrackingEvent(eventName.ADD_METADATA);
        }
      } else {
        const result = await window.COMMON.mutation(MetadataGRAPHQL.MUTATION_EDIT_METADATA, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataMetadata();
		  createTrackingEvent(eventName.EDIT_METADATA);
        }
      }
      window.COMMON.hideModal('#modal-metadata');
    } catch (error) {
      window.COMMON.showErrorLogs('MetadataPage.saveData');
    }
  };

  const deleteData = async () => {
    try {
      const params = {
        id: data[object.index]._id
      };
      const result = await window.COMMON.mutation(MetadataGRAPHQL.MUTATION_DEL_METADATA, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataMetadata();
        createTrackingEvent(eventName.DEL_METADATA);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MetadataPage.deleteData');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" icon="list-alt">
        <div className="container-top d-flex align-items-center justify-content-end wow fadeInDown animated" data-wow-delay="0.5s">
          <div className="bd-highlight mr-2" style={{ width: '300px' }}>
            <div className="input-group input-group-transparent">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <MDBIcon fa="true" icon="filter" className="fa-fw" />
                </span>
              </div>
              <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={object.type} options={typeOptions} onChange={handleSelect} isSearchable/>
            </div>
          </div>
          <MDBBtn color="primary" className="mr-0" onClick={() => openSaveDialog(-1)}>
            <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
          </MDBBtn>
        </div>
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <Table id="table-data" className="table" config={config} data={data} handleRowEditClick={openSaveDialog} handleRowDelClick={openDeleteDialog}></Table>
        </div>
      </Panel>

      <Modal id="modal-metadata" title={window.I18N('create_update_data')} saveEvent={saveData}>
        <form id="form-metadata" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <MDBInput outline containerClass="mt-0" type="text" label={window.I18N('value') + ' *'} name="value" value={dataSave.value} onChange={handleChange} maxLength="100" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="text" label={window.I18N('code') + ' *'} name="code" value={dataSave.code} onChange={handleChange} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <label className="badge badge-info p-2 m-0">{object.type.label}</label>
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>
    </div>
  );
}