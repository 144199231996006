import React, { useState, useCallback, useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { MDBRow, MDBCol, MDBCardImage, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';

import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Tab from '../../components/Tab';
import InputFile from '../../components/InputFile';

import CommentGRAPHQL from '../../../graphql/Comment';
import MetadataGRAPHQL from '../../../graphql/Metadata';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';

function EmotionPage(props) {
  const { trackEvent } = useTracking();

  const dataTabs = [
    { icon: 'fa-th', name: window.I18N('emotion') },
    { icon: 'fa-th', name: 'Sticker' }
  ];
  const config = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('icon'), '10%', 'p-0 pl-1', '', 'icon', 'IMAGE'),
    window.COMMON.createCol(window.I18N('code'), '15%', '', '', 'code', 'TEXT'),
    window.COMMON.createCol(window.I18N('content'), '30%', '', '', 'contentLanguage', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_by'), '20%', '', '', 'createdBy.name', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '10%', '', '', '3', 'BUTTON')
  ];
  const configSticker = [
    window.COMMON.createCol(window.I18N('no'), '10%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('name'), '30%', 'p-0 pl-1', '', 'icon,name', 'IMAGE_TEXT'),
    window.COMMON.createCol(window.I18N('code'), '20%', '', '', 'code', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_by'), '20%', '', '', 'createdBy.name', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '10%', '', '', '3', 'BUTTON')
  ];
  const language = useRef();
  const [object, setObject] = useState({ index: -1, indexTab: 0 });
  const [data, setData] = useState([]);
  const [dataSticker, setDataSticker] = useState([]);
  const [dataChildSticker, setDataChildSticker] = useState([]);
  const [dataSave, setDataSave] = useState({});

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {
      if (object.indexTab === 0) {
        getDataEmotion();
      } else {
        console.log('');
      }
    }, 100);
    // eslint-disable-next-line
  }, [props.language]);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const changeTab = (index) => {
    if (index === 0) {
      setTimeout(() => getDataEmotion(), 500);
    } else {
      setTimeout(() => getDataSticker('-1'), 500);
    }
    object.indexTab = index;
    setObject(object);
  };

  const handleChange = (event) => {
    if (event.target.className.indexOf('input-number') > -1) {
      dataSave[event.target.name] = parseInt(event.target.value);
    } else {
      dataSave[event.target.name] = event.target.value;
    }
    setDataSave({ ...dataSave });
  };
  
  const handleChangeWithLanguage = (event) => {
    window.COMMON.setValueWithLanguage(dataSave, event.target.name, language.current, event.target.value);
    setDataSave({ ...dataSave });
  };

  const handleFile = (file) => {
    dataSave.file = file;
    dataSave.icon = window.COMMON.createBlobImage(file);
    setDataSave({ ...dataSave });
  };

  const handleFiles = (files) => {
    const input = [];
    let index = 0;
    const code = window.COMMON.generateCode('STICKER-CHILD');
    files.forEach(file => {
      index++;
      const ext = file.name.split('.').pop();
      const blob = file.slice(0, file.size, file.type); 
      const newFile = new File([blob], window.COMMON.generateCode('sticker') + '-' + index + '.' + ext, {type: file.type});
      const obj = {};
      obj.name = newFile.name;
      obj.code = code + '-' + index;
      obj.url = window.COMMON.createBlobImage(file);
      obj.file = newFile;
      input.push(obj);
    });
    dataSave.input = input;
    setDataSave({ ...dataSave });
  };

  // EMOTION
  const getDataEmotion = async () => {
    try {
      window.resetDataTable('table-emotion');
      const result = await window.COMMON.query(CommentGRAPHQL.QUERY_EMOTION, null);
      if (result && result.data) {
        const data = window.COMMON.formatDate(result.data.getEmotions, 'createdDate');
        data.forEach(item => {
          item.contentLanguage = window.COMMON.getValueWithLanguage(item, 'content', language.current);
        });
        setData(data);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('EmotionPage.getDataEmotion');
    }
    window.initDataTable('table-emotion');
  };

  const openEmotionDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-emotion');
    object.index = index;
    const dataSave = { file: null };
    if (index === -1) {
      dataSave.content = [];
      dataSave.code = window.COMMON.generateCode('E');
      dataSave.icon = '';
    } else {
      const obj = data[index];
      dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
      dataSave.content = window.COMMON.getDataWithLanguage(obj, 'content');
      dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
      dataSave.icon = window.COMMON.getValueFromAttr(obj, 'icon');
    }
    document.getElementById('file-emotion').value = null;
    setObject(object);
    setDataSave({ ...dataSave });
  }, [object, data]);

  // eslint-disable-next-line
  const openDeleteEmotionDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteEmotion;
  });

  const saveEmotion = async (event) => {
    try {
      if (!window.COMMON.checkFormValidation('form-emotion', event)) {
        return;
      }
      // TODO upload file
      let dataFiles = [];
      if (dataSave.file) {
        dataFiles = await window.uploadFile([dataSave.file]);
        if (dataFiles.length > 0) {
          dataSave.icon = window.COMMON.getNewPathFile(dataFiles[0]._id);
        }
      }
      dataSave.file = null;
      // TODO save data
      const params = {
        input: dataSave
      };
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(CommentGRAPHQL.MUTATION_ADD_EMOTION, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataEmotion();
		      createTrackingEvent(eventName.ADD_EMOTION);
        }
      } else {
        const result = await window.COMMON.mutation(CommentGRAPHQL.MUTATION_EDIT_EMOTION, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataEmotion();
		      createTrackingEvent(eventName.EDIT_EMOTION);
        }
      }
      window.COMMON.hideModal('#modal-emotion');
    } catch (error) {
      window.COMMON.showErrorLogs('EmotionPage.saveEmotion');
    }
  };

  const deleteEmotion = async () => {
    try {
      const params = {
        id: data[object.index]._id
      };
      const result = await window.COMMON.mutation(CommentGRAPHQL.MUTATION_DEL_EMOTION, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataEmotion();
        createTrackingEvent(eventName.DEL_EMOTION);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('EmotionPage.deleteEmotion');
    }
  };

  // STICKER
  const getDataSticker = async (parentCode) => {
    try {
      window.resetDataTable('table-sticker');
      const params = {
        parentCode
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_STICKERS, params);
      let data = [];
      if (result && result.data) {
        data = window.COMMON.formatDate(result.data.getStickers, 'createdDate');
      }
      if (parentCode !== '-1') {
        setDataChildSticker(data);
      } else {
        setDataSticker(data);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('EmotionPage.getDataSticker');
    }
    window.initDataTable('table-sticker');
  };

  const openStickerDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-sticker');
    object.index = index;
    const dataSave = { parentCode: '-1' };
    if (index === -1) {
      dataSave.name = '';
      dataSave.code = window.COMMON.generateCode('STICKER');
      dataSave.icon = '';
      setDataChildSticker([]);
    } else {
      const obj = dataSticker[index];
      dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
      dataSave.name = window.COMMON.getValueFromAttr(obj, 'name');
      dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
      dataSave.icon = window.COMMON.getValueFromAttr(obj, 'icon');
      getDataSticker(dataSave.code);
    }
    document.getElementById('files-sticker').value = null;
    setObject(object);
    setDataSave({ ...dataSave });
  }, [object, dataSticker]);

  // eslint-disable-next-line
  const openDeleteStickerDialog = useCallback((index, mode) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = mode === 0 ? () => deleteSticker() : () => deleteChildSticker();
  });

  const deleteItem = (index) => {
    dataSave.input.splice(index, 1);
    setDataSave({ ...dataSave });
  };

  const saveSticker = async (event) => {
    try {
      if (!window.COMMON.checkFormValidation('form-sticker', event)) {
        return;
      }
      // TODO upload file
      const files = [];
      if (dataSave.input && dataSave.input.length > 0) {
        dataSave.input.forEach(element => {
          files.push(element.file);
        });
        const dataFiles = await window.uploadFile(files);
        dataFiles.forEach(file => {
          const obj = window.COMMON.getObjectInArrs(file.originalFilename, dataSave.input, 'name');
          if (obj) {
            obj.icon = window.COMMON.getNewPathFile(file._id);
            obj.parentCode = dataSave.code;
            delete obj.file;
            delete obj.url;
          }
        });
      }
      let params = null;
      if (object.index === -1) {
        // TODO save data
        dataSave.input.unshift({
          name: dataSave.name,
          code: dataSave.code,
          parentCode: dataSave.parentCode,
          icon: window.COMMON.getValueFromAttr(dataSave.input, '0.icon')
        });
        params = {
          input: dataSave.input
        };
      } else {
        params = {
          input: {
            _id: dataSave._id,
            name: dataSave.name,
            code: dataSave.code,
            parentCode: dataSave.parentCode,
            icon: dataSave.icon
          }
        };
      }
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(MetadataGRAPHQL.MUTATION_ADD_STICKERS, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataSticker('-1');
		      createTrackingEvent(eventName.ADD_EMOTION);
        }
      } else {
        const result = await window.COMMON.mutation(MetadataGRAPHQL.MUTATION_EDIT_STICKER, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataSticker('-1');
		      createTrackingEvent(eventName.EDIT_EMOTION);
        }
      }
      window.COMMON.hideModal('#modal-sticker');
    } catch (error) {
      window.COMMON.showErrorLogs('EmotionPage.saveSticker');
    }
  };

  const deleteSticker = async () => {
    try {
      const params = {
        id: dataSticker[object.index]._id
      };
      const result = await window.COMMON.mutation(MetadataGRAPHQL.MUTATION_DEL_STICKER, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        dataSticker.splice(object.index, 1);
        setDataSave({ ...dataSticker });
        createTrackingEvent(eventName.DEL_EMOTION);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('EmotionPage.deleteSticker');
    }
  };

  const deleteChildSticker = async () => {
    try {
      const params = {
        id: dataChildSticker[object.index]._id
      };
      const result = await window.COMMON.mutation(MetadataGRAPHQL.MUTATION_DEL_STICKER, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataSticker(dataSave.code);
        createTrackingEvent(eventName.DEL_EMOTION);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('EmotionPage.deleteSticker');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Tab data={dataTabs} id="container-data" changeTab={changeTab}>
        <div id="tab-1" className="tab-pane fade active show">
          <div className="container-btn mb-2 wow fadeInDown animated" data-wow-delay="0.5s">
            <MDBBtn color="primary" onClick={() => openEmotionDialog(-1)}>
              <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
            </MDBBtn>
          </div>
          <div className="container-table wow fadeIn animated" data-wow-delay="1s">
            <Table id="table-emotion" className="table" config={config} data={data} handleRowEditClick={openEmotionDialog} handleRowDelClick={openDeleteEmotionDialog}></Table>
          </div>
        </div>
        <div id="tab-2" className="tab-pane">
          <div className="container-btn mb-2 wow fadeInDown animated" data-wow-delay="0.5s">
            <MDBBtn color="primary" onClick={() => openStickerDialog(-1)}>
              <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
            </MDBBtn>
          </div>
          <div className="container-table wow fadeIn animated" data-wow-delay="1s">
            <Table id="table-sticker" className="table" config={configSticker} data={dataSticker} handleRowEditClick={openStickerDialog} handleRowDelClick={(index) => openDeleteStickerDialog(index, 0)}></Table>
          </div>
        </div>
      </Tab>

      <Modal id="modal-emotion" title={window.I18N('create_update_data')} saveEvent={saveEmotion}>
        <form id="form-emotion" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <MDBInput outline containerClass="mt-0" type="text" label={window.I18N('code') + ' *'} name="code" value={dataSave.code} onChange={handleChange} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="text" label={window.I18N('content') + ' *'} name="content" value={window.COMMON.getValueWithLanguage(dataSave, 'content', language.current)} onChange={handleChangeWithLanguage} maxLength="200" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <div className="text-center">
                <MDBCardImage className="input-image-icon" src={window.COMMON.setValueImage(dataSave.icon)} hover overlay="white-slight"/><br></br>
                <InputFile id="file-emotion" icon="upload" handleFile={handleFile} accept="image/*" description="Icon (64 x 64) pixels"></InputFile>
              </div>
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>

      <Modal id="modal-sticker" className="modal-lg" title={window.I18N('create_update_data')} saveEvent={saveSticker}>
        <form id="form-sticker" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <MDBInput outline containerClass="mt-0" type="text" label={window.I18N('code') + ' *'} name="code" value={dataSave.code} onChange={handleChange} maxLength="50" pattern="\S(.*\S)?" disabled={object.index !== -1} required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="text" label={window.I18N('name') + ' *'} name="name" value={dataSave.name} onChange={handleChange} maxLength="200" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
            </MDBCol>
          </MDBRow>
          <div className="text-center mt-3 mb-3">
            <InputFile id="files-sticker" icon="upload" handleFile={handleFiles} isMulti={true} accept="image/*" description="Icon (64 x 64) pixels" disabled={object.index !== -1}></InputFile>
          </div>
          <MDBRow>
            {
              window.COMMON.getValueFromAttr(dataSave, 'input', []).map((item, i) => <MDBCol key={i} lg="2" md="3" className="p-2">
                <div className="card border p-2 pl-3 pr-3" style={{ backgroundColor: '#fbfbfb' }}>
                  <div className="position-absolute top-0">
                    <MDBIcon fa="true" icon="trash-alt" style= {{ color: 'red' }} className="fa-fw cursor-pointer" onClick={() => deleteItem(i)} />
                  </div>
                  <div className="w-100 text-center">
                    <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(item.url)} style={{ height: '32px', width: '32px' }}/>
                  </div>
                </div>
              </MDBCol>
              )
            }
            {
              dataChildSticker.map((item, i) => <MDBCol key={i} lg="2" md="3" className="p-2">
                <div className="card border p-2 pl-3 pr-3" style={{ backgroundColor: '#fbfbfb' }}>
                  <div className="position-absolute top-0">
                    <MDBIcon fa="true" icon="trash-alt" style= {{ color: 'red' }} className="fa-fw cursor-pointer" onClick={() => openDeleteStickerDialog(i, 1)} />
                  </div>
                  <div className="w-100 text-center">
                    <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(item.icon)} style={{ height: '32px', width: '32px' }}/>
                  </div>
                </div>
              </MDBCol>
              )
            }
          </MDBRow>
        </form>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};
  
export default connect(mapStateToProps, mapDispatchToProps)(EmotionPage);