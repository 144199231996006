import React, { useImperativeHandle, useRef } from 'react';
import { MDBRow, MDBCol, MDBIcon, MDBInput } from 'mdbreact';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
const ScheduleAssessmentForm = React.forwardRef((props, ref) => {
  const { dataSave, date, select, options, handleChange, handleDatePicker, handleSelect, typeOptions, periodOptions } = props;
  const formRef = useRef();
  useImperativeHandle(ref, () => {
    return {
      handleValidForm
    };
  }, []);

  const handleValidForm = () => {
    const checkForm = window.COMMON.checkFormValidation('form', formRef.current);
    if (!checkForm) {
      return false;
    }
    return true;

  };

  return <div className="container-top wow fadeInUp animated" data-wow-delay="0.5s">
    <form id="form" className="needs-validation mt-2" noValidate ref={formRef}>
      <MDBRow>
        <MDBCol sm="6">
          <MDBInput outline containerClass="mt-0" type="text" label={window.I18N('name') + ' *'} name="name" value={dataSave.name} onChange={handleChange} maxLength="100" pattern="\S(.*\S)?" required>
            <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
          </MDBInput>
          <MDBRow>
            <MDBCol>
              <MDBInput outline containerClass="mt-0" type="text" label={window.I18N('code') + ' *'} name="code" value={dataSave.code} onChange={handleChange} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol >
              <div className="input-group input-group-transparent">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                  </span>
                </div>
                <DatePicker placeholderText={window.I18N('start_date') + ' *'} className="form-control" dateFormat="dd/MM/yyyy" selected={date.startDate} startDate={date.startDate} endDate={date.endDate}
                  onChange={(event) => handleDatePicker(event, 'startDate')} showYearDropdown showMonthDropdown selectsStart required />
              </div>
            </MDBCol>
            <MDBCol>
              <div className="input-group input-group-transparent">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                  </span>
                </div>
                <DatePicker placeholderText={window.I18N('end_date') + ' *'} className="form-control" dateFormat="dd/MM/yyyy" selected={date.endDate} startDate={date.startDate} endDate={date.endDate}
                  onChange={(event) => handleDatePicker(event, 'endDate')} showYearDropdown showMonthDropdown selectsEnd required />
              </div>
            </MDBCol>
          </MDBRow>
          <MDBInput outline containerClass="mb-0" type="textarea" label={window.I18N('description')} name="description" value={dataSave.description} onChange={handleChange} maxLength="5000"></MDBInput>
        </MDBCol>
        <MDBCol sm="6">
          {window.COMMON.checkRoleIsSystem() && <Select id="select-influencer" value={select.influencer} options={options.influencers} placeholder={window.I18N('influencer') + ' *'} className="md-form mt-0" onChange={(event) => handleSelect(event, 'select-influencer', 'influencer')} isSearchable />}
          <Select id="select-companies" value={select.companies} options={options.companies} placeholder={window.I18N('companies') + ' *'} className="md-form mt-0" onChange={(event) => handleSelect(event, 'select-companies', 'companies')} isSearchable isMulti closeMenuOnSelect={false} />
          <Select id="select-type" className="md-form" value={select.type} placeholder={window.I18N('type') + ' *'} options={typeOptions} onChange={(event) => handleSelect(event, 'select-type', 'type')} isSearchable />
          <Select id="select-period" className="md-form" value={select.period} placeholder={window.I18N('period') + ' *'} options={periodOptions} onChange={(event) => handleSelect(event, 'select-period', 'period')} isSearchable />
        </MDBCol>
      </MDBRow>
    </form>
  </div>;
});

export default ScheduleAssessmentForm;