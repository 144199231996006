import { MDBRow, MDBCol, MDBIcon, MDBInputGroup } from "mdbreact";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DashboardPowerBIGraphQL from "../../../graphql/DashboardPowerBI";
import TemplatesTable from "../../components/TemplatesTable";
import RadioBox from "../../components/RadioBox";
import { useDebounce } from "../../../hooks/useSearchDebounce";

const TABLE_TEMPLATE = 'TABLE'
const CHART_TEMPLATE = 'CHART'

function DataLibraryPage({ onCreateTemplates }) {
   
    const configTemplatesTable = [
        window.COMMON.createCol(
            'GroupId',
            '25%',
            'py-3',
            '',
            'id',
            'TEXT'
        ),
        window.COMMON.createCol(
            'Groups',
            '18%',
            '',
            '',
            'group',
            'TEXT'
        ),
        window.COMMON.createCol(
            'Items',
            '57%',
            'py-3',
            '',
            'items',
            'ARRAY_ANSWER'
        ),
      
    ];

    const TEMPLATE_TYPE = [
        { value: TABLE_TEMPLATE, label: 'Table Template', icon: "table"},
        { value: CHART_TEMPLATE, label: 'Chart Template', icon: "chart-bar"}
    ]

    const [dataForRenderTemplate, setDataForRenderTemplate] = useState([])
    const [dataTemplates, setDataTemplates] = useState({isEmpty: false, data: []})
    const [templateType, setTemplateType] = useState(TABLE_TEMPLATE)
    const [dataFilterTemplates, setDataFilterTemplates] = useState([])
    const [filterTemplate, setFilterTemplate] = useState('')
    const [countTemplate, setCountTemplate] = useState({total: 0, table: 0, chart: 0})

    const searchDebounce = useDebounce(filterTemplate, 500)
    
    useEffect(() => {
        const timerId = setTimeout(()=>{
            getTemplates()
        }, 100)

        return () => {
            clearTimeout(timerId)
        }
    },[templateType])
    
    useEffect(()=>{
        const filterTemplateName = dataTemplates.data.filter(template => template.name.toLowerCase().includes(filterTemplate.toLowerCase()))
        setDataFilterTemplates(filterTemplateName.length === dataTemplates.data.length ? dataTemplates.data : (filterTemplateName.length > 0 ? filterTemplateName : []))
    },[searchDebounce])
    
    useEffect(() => {
        const checkDataEmpty = !dataTemplates.isEmpty || dataFilterTemplates.length !== 0
        if(checkDataEmpty){
            if(searchDebounce){
                setDataForRenderTemplate(dataFilterTemplates)
            }else{
                setDataForRenderTemplate(dataTemplates.data)
            }       
        }
    },[dataTemplates, dataFilterTemplates])


    const getTemplates = async () => {
        try{
            const result = await window.COMMON.query(DashboardPowerBIGraphQL.QUERY_LIST_TEMPLATES)
            const tableTemplate = result.data.getDashboardTemplates.reverse().filter(template => template.templateType === TABLE_TEMPLATE)
            const chartTemplate = result.data.getDashboardTemplates.filter(template => template.templateType === CHART_TEMPLATE)
            const dataTemplate = {
                isEmpty: templateType === TABLE_TEMPLATE ? tableTemplate.length === 0 : chartTemplate.length === 0,
                data: templateType === TABLE_TEMPLATE ? tableTemplate : chartTemplate
            }
            setDataTemplates(dataTemplate)
            setCountTemplate({ total: result.data.getDashboardTemplates.length, table: tableTemplate.length, chart: chartTemplate.length})
        }catch(error){
            window.COMMON.showErrorLogs('DashboardPowerBI.getTemplates');
        }
    }

    const handleSelectTemplateType = (event) => {
        if(event === templateType){
            return
        }
        setDataTemplates(prev => ({...prev, data: []}))
        setDataForRenderTemplate([])
        setTemplateType(event)
    }

    const handleFilterTemplates = (event) => {
        const value = event.target.value
        if (!value.startsWith(' ')) {
            setFilterTemplate(value)
        }
    }

    return ( 
        <>
            <div className="mt-3 px-3 bg-white rounded-lg wow fadeInRight animated" data-wow-delay="0.3s">
                <MDBRow className="w-full">
                        <MDBCol size="4" className="p-3">
                            <div className="px-3 flex-center justify-content-between">
                                <div className="flex-center flex-column">
                                    <div className="flex-center">
                                        <MDBIcon fas icon="th-list" className="mr-2" style={{fontSize: '22px', color: '#10c986'}}/>
                                        <span className="font-weight-bold" style={{fontSize: '20px'}}>{countTemplate.total}</span>
                                    </div>
                                    <span className="text-muted" style={{fontSize: '12px'}}>Total</span>
                                </div>
                                <div className="flex-center flex-column">
                                    <div className="flex-center">
                                        <MDBIcon fas icon="table" className="mr-2" style={{fontSize: '22px', color: '#367cff'}}/>
                                        <span className="font-weight-bold" style={{fontSize: '20px'}}>{countTemplate.table}</span>
                                    </div>
                                    <span className="text-muted" style={{fontSize: '12px'}}>Table</span>
                                </div>
                                <div className="flex-center flex-column">
                                    <div className="flex-center">
                                        <MDBIcon fas icon="chart-bar" className="mr-2" style={{fontSize: '22px', color: '#fc9905'}}/>
                                        <span className="font-weight-bold" style={{fontSize: '20px'}}>{countTemplate.chart}</span>
                                    </div>
                                    <span className="text-muted" style={{fontSize: '12px'}}>Chart</span>
                                </div>
                            </div>
                        </MDBCol>             
                        <MDBCol size="8">
                            <div className="flex-center justify-content-end">
                                    <RadioBox data={TEMPLATE_TYPE} value={templateType} handleChange={handleSelectTemplateType} className={'m-0'}/>
                                    <div>
                                        <MDBInputGroup type="text" value={filterTemplate} hint={window.I18N('search_name')} onChange={handleFilterTemplates} maxLength="100"  required
                                            append={
                                                <span className="input-group-text cursor-pointer" onClick={()=>{}}>
                                                    <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                                                </span>
                                            }
                                        />
                                    </div>
                            </div>
                        </MDBCol>
                </MDBRow>
            </div>
            {dataTemplates.isEmpty ? 
                <div className="w-full bg-white rounded-lg flex-center wow fadeInRight animated py-3 mt-2" data-wow-delay="0.2s">
                    <div className="w-full flex-center wow fadeInRight animated" style={{flex: 1}} data-wow-delay="0.2s" onClick={onCreateTemplates}>
                            <div className="flex-center rounded-lg p-2 border create-template" style={{width: '30%',cursor: 'pointer'}}>
                                    <MDBIcon fas icon="plus"/>
                                    <span className="px-md-2 h6 m-0">Create your data template now</span>
                            </div>
                    </div>
                </div> : <></>
            }
            {dataForRenderTemplate.length === 0 && searchDebounce ? 
                <div className="w-full flex-center bg-white rounded-lg wow fadeInRight animated py-3 mt-2" data-wow-delay="0.2s">
                    <div className="w-full flex-center wow fadeInRight animated" style={{flex: 1}} data-wow-delay="0.2s">
                            <div className="flex-center rounded p-2" style={{width: '30%',cursor: 'pointer'}}>
                                    <span className="px-md-2 h6 m-0" style={{fontStyle: 'italic'}}>No result</span>
                            </div>
                    </div>
                </div>  : <></>
            }   
            {
                <div className="mt-2">
                    { 
                        dataForRenderTemplate?.map((template, i) => (
                           <div className="w-full" key={template?._id ?? 'templates-table'}>
                                <TemplatesTable
                                    configTemplatesTable={configTemplatesTable}
                                    dataTemplatesTable={template}
                                    idx={i}
                                    refechDataTemplates={getTemplates}
                                    templateType={templateType}
                                />
                           </div>
                         
                        ))
                    }     
                </div>
            }
        </>
     );
}

const mapStateToProps = state => ({
    language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DataLibraryPage);