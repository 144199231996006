import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { Bar, HorizontalBar } from 'react-chartjs-2';
import AccountGRAPHQL from '../../../graphql/Account';
import RoleGRAPHQL from '../../../graphql/Role';
import moment from 'moment';
import CustomChart from './components/CustomChart';


const paletteColor = ["rgba(0, 159, 109, 0.2)","rgba(190, 0, 0, 0.2)","rgba(61, 0, 189, 0.2)","rgba(0, 65, 175, 0.2)","rgba(54, 162, 235, 0.2)","rgba(153, 102, 255, 0.2)","rgba(201, 203, 207, 0.2)", "rgba(74, 147, 124, 0.2)", "rgba(147, 147, 124, 0.2)", "rgba(147, 147, 235, 0.2)", " rgba(147, 196, 235, 0.2)", "rgba(0, 171, 184, 0.2)", "rgba(139, 171, 184, 0.2)", "rgba(139, 255, 184, 0.2)", "rgba(165, 197, 97, 0.2)", "rgba(165, 163, 152, 0.2)", "rgba(0, 0, 0, 0.2)", "rgba(0, 47, 0, 0.2)", "rgba(26, 47, 43, 0.2)", "rgba(255, 38, 214, 0.2)"]

const maxAgeLabel = [25, 30, 35, 40, 65]
const minAgeLabel = [16, 26, 31, 36, 41]

const InsightChartProfile = ({ companyType, companyId, companies }) => {
	const labelGroup = {
		gender: [window.I18N('female') , window.I18N('male')],
		age: ['16- 25', '26-30', '31-35', '36-40', '41-65', window.I18N('unknow')]
	}
	const [dataAccount, setDataAccount] = useState([]);
	const [dataRole, setDataRole] = useState([])
	const [dataChart, setDataChart] = useState({ labels: [], datasets: [] })
	const [chartMode, setChartMode] = useState(null)
	const groupDataforChart = () => {
		if (chartMode === "GENDER") {
			dataChart.labels = labelGroup.gender.map(el=>window.I18N(el))
			dataChart.datasets = [
				{
					label: 'Number of person',
					backgroundColor: paletteColor,
					boderColor: paletteColor.map(color => color.replace('0.2', '1')),
					borderWidth: 1,
					barPercentage: 0.3,
					data: new Array(2).fill(0),
				},
			]
			if (dataAccount?.length) {
				dataAccount.forEach((account) => {
					if (account.gender===0) {
						dataChart.datasets[0].data[0] = dataChart.datasets[0].data[0] + 1
					} else if (account.gender===1) {
						dataChart.datasets[0].data[1] = dataChart.datasets[0].data[1] + 1
					}
				})
			}
			
		} else if (chartMode === "ROLE") {
			dataChart.labels = dataRole.map(el => el.label)
			dataChart.datasets = [
				{
					label: 'Number of person',
					backgroundColor: paletteColor,
					boderColor: paletteColor.map(color => color.replace('0.2', '1')),
					borderWidth: 1,
					barPercentage: 0.5,
					data: new Array(dataRole.length).fill(0),
				},
			]
			if (dataAccount?.length) {
				dataAccount.forEach((account) => {
					let index = dataChart.labels.indexOf(account.roleText)
					if (index !== -1) {
						dataChart.datasets[0].data[index] = dataChart.datasets[0].data[index] + 1
					}
				})
			}
		} else if (chartMode === "AGE") {
			dataChart.labels = labelGroup.age
			dataChart.datasets = [
				{
					label: 'Number of person',
					backgroundColor: paletteColor,
					boderColor: paletteColor.map(color => color.replace('0.2', '1')),
					borderWidth: 1,
					barPercentage: 0.5,
					data: new Array(6).fill(0),
				},
			]
			dataAccount.forEach((account) => {
				if (!Number.isInteger(account.age) || account.age < minAgeLabel[0] ||account.age > maxAgeLabel[4]) {
					dataChart.datasets[0].data[5] = dataChart.datasets[0].data[5] + 1
				} else if (account.age <= maxAgeLabel[0]) {
					dataChart.datasets[0].data[0] = dataChart.datasets[0].data[0] +1
				} else if (account.age <= maxAgeLabel[1]) {
					dataChart.datasets[0].data[1] = dataChart.datasets[0].data[1] +1
				} else if (account.age <= maxAgeLabel[2]) {
					dataChart.datasets[0].data[2] = dataChart.datasets[0].data[2] +1
				} else if (account.age <= maxAgeLabel[3]) {
					dataChart.datasets[0].data[3] = dataChart.datasets[0].data[3] +1
				}else {
					dataChart.datasets[0].data[4] = dataChart.datasets[0].data[4] +1
				}
			})
		} else if (chartMode === "LOCATION") {
			let labels = []
			let datasetsData= []
			dataAccount.forEach(account => {
				const index = labels.indexOf(`${account?.state?.name} - ${account?.city?.name}`)
				if (index === -1) {
					labels.push(`${account?.state?.name} - ${account?.city?.name}`)
					datasetsData.push(1)
				} else {
					datasetsData[index]= datasetsData[index] + 1
				}
			})
			dataChart.labels = labels
			dataChart.datasets = [
				{
					label: 'Number of person',
					backgroundColor: paletteColor,
					boderColor: paletteColor.map(color => color.replace('0.2', '1')),
					borderWidth: 1,
					barPercentage: 1,
					data: datasetsData,
				},
			]
		}
		setDataChart({ ...dataChart })
	}

	const labelDiv = (paramLabel) => {
		let index = dataChart.labels.indexOf(paramLabel)
		const labelDiv = <div style={{fontSize: "13px", fontWeight: 400}} className='d-flex align-items-center justify-content-center  mb-2'>
			<span className='mr-2' style={{ display: "inline-block", height: "1rem", width: "1rem", backgroundColor: paletteColor[index] }}></span>
			{paramLabel}: {dataChart.datasets[0].data[index]} {window.I18N("user")}
		</div>

		return labelDiv
	}

	const genderDiv = (male, female) => {
		const genderDiv = 
			<div style={{ display: "flex", flexDirection: "column", marginRight: "10px" }}>
				<div style={{ display: "flex", flexDirection: "column", backgroundColor: "#F1F2F8", marginBottom: "10px", padding: "5px 10px"}}>
					<div style={{margin: "auto", fontSize: "20px", fontWeight: 500}}>{male}</div>
					<div style={{ margin: "auto" }}>{labelGroup.gender[1] }</div>
				</div>
				<div style={{display: "flex",flexDirection: "column", backgroundColor: "#F1F2F8", padding: "5px 10px"}}>
					<div style={{margin: "auto", fontSize: "20px", fontWeight: 500}}>{female}</div>
					<div style={{ margin: "auto" }}>{labelGroup.gender[0] }</div>
				</div>
			</div>
		
		return genderDiv
	}

	const locationDiv = (paramGroupLocation) => {
		const locationDiv = 
			<div style={{backgroundColor: "#F1F2F8", padding: "5px 15px", maxHeight: "200px", overflow: "scroll", marginLeft:" 3px"}}>
				{Object.keys(paramGroupLocation).map((state, i) =>
					<div key={i} style={{marginBottom: "8px"}}>
						<div style={{fontSize: "12px", fontWeight: 500}}>{state}</div>
						{Object.keys(paramGroupLocation[state]).map((city, ii) =>
							<div key={ii} style={{marginLeft: "10px"}}>{city}: {paramGroupLocation[state][city]}</div>
						)}
					</div>
				)}
			</div>
		
		return locationDiv
	}

	const companyDiv = (dataCompany) => {
		const companyDiv =
			<div style={{ backgroundColor: "#F1F2F8", padding: "5px 15px", maxHeight: "200px", minHeight: "100px", width: "150px", overflow: "scroll", marginLeft: " 3px" }}>
                <div >
                    <div style={{ fontWeight: 500 }}>{dataCompany.length} {window.I18N("company")}</div>
                    {dataCompany.map((company, i) => <li key={i} style={{ fontSize: "11px", }}>
                        {company}
                    </li>)}
                </div>
			</div>
		return companyDiv
	}

	const roleDiv = (paramGroupRole) => {
		return <HorizontalBar
			width={200}
			data={paramGroupRole}
			options={{
				legend: { display: false },
				responsive: false,
				maintainAspectRatio: true,
				scales: {
					yAxes: [{
						ticks: {
							beginAtZero: true,
							stepSize: 1,
							fontSize: 10,
						},
						gridLines: {display:false}
					}],
					xAxes: [{
						ticks: {
							beginAtZero: true,
							stepSize: 1,
							fontSize: 10,
						},
					}]
				},
				tooltips: {
					enabled: true,
				},
			}}
		></HorizontalBar>
	}

	const createInnerHTMLPopup = (paramLabel) => {
		var dataOfBar = []
		if (chartMode === 'ROLE') {
			dataOfBar = dataAccount.filter(el => el.roleText === paramLabel)
		} else if (chartMode === 'GENDER') {
			dataOfBar = dataAccount.filter(el => el.genderText === paramLabel)
		} else if (chartMode === 'AGE') {
			const index = labelGroup.age.indexOf(paramLabel)
			dataOfBar = dataAccount.filter(el => {
				if (index === 5) {
					return !Number.isInteger(el.age) || el.age>maxAgeLabel[4] || el.age<minAgeLabel[0]
				} else if (minAgeLabel[index] <= el.age && el.age <= maxAgeLabel[index]) {
					return true
				} else {
					return false
				}
			})
		} else if (chartMode === 'LOCATION') {
			dataOfBar = dataAccount.filter(account => `${account?.state?.name} - ${account?.city?.name}` === paramLabel)
		}

		const groupGender = {}
		groupGender.male = dataOfBar.filter(el => el.gender === 1).length
		groupGender.female = dataOfBar.filter(el => el.gender === 0).length

		const groupLocation = {}
		dataOfBar.forEach(account => {
			if (groupLocation[account?.state?.name]) {
				if (groupLocation[account?.state?.name][account?.city?.name]) {
					groupLocation[account?.state?.name][account?.city?.name] = groupLocation[account?.state?.name][account?.city?.name] + 1
				} else {
					groupLocation[account?.state?.name][account?.city?.name] = 1
				}
			} else {
				groupLocation[account?.state?.name] = { [account?.city?.name]: 1 }
			}
		})
		let groupRole = null
		const dataCompany = []
		if (companyId) {
			groupRole = {
				labels: dataRole.map(el => el.label),
				datasets : [
					{
						label: 'Number of person',
						backgroundColor: paletteColor,
						boderColor: paletteColor.map(color => color.replace('0.2', '1')),
						borderWidth: 1,
						barPercentage: 0.5,
						data: new Array(dataRole.length).fill(0),
					},
				]
			}
			dataOfBar.forEach(account => {
				let index = groupRole.labels.indexOf(account.roleText)
				if (index !== -1) {
					groupRole.datasets[0].data[index] = groupRole.datasets[0].data[index] + 1
				}
			})
		} else {
			dataOfBar.forEach(account => {
				let organization = account.company || account.influencer || account.union
				if (organization?.information?.name && !dataCompany.includes(organization.information.name)) {
                        dataCompany.push(organization.information.name)
                    }
			})
		}
		
		var innerHtml =<div>
			{labelDiv(paramLabel)}
			<div className='d-flex  justify-content-around'>
				{chartMode!=='ROLE' && companyId && roleDiv(groupRole)}
				{chartMode!=='GENDER' && genderDiv(groupGender.male, groupGender.female)}
				{chartMode !== 'LOCATION' && locationDiv(groupLocation)}
				{!companyId && companyDiv(dataCompany)}
			</div>
		</div>
		return innerHtml

	}

	const getDataRole = async () => {
		try {
			const params = {
				company: companyId
			};
			const result = await window.COMMON.query(RoleGRAPHQL.QUERY_ROLE, params);
			if (result && result.data) {
				let optionsRole = window.COMMON.createDataSelect(result.data.getRoles, 'code', 'name', 'code', '_id');
				optionsRole.push({ value: window.CONSTANT.INDIVIDUAL, label: window.I18N('individual'), code: window.CONSTANT.INDIVIDUAL });
				optionsRole.reverse();
				getDataAccount(optionsRole);
			}

		} catch (error) {
			window.COMMON.showErrorLogs('AccountPage.getDataRole');
		}
	};
	const getDataAccount = async (role) => {
		try {
			const params = {
				input: {
					organizationType: companyType,
					pageSize: 9999,
				}
			};
			if (companyId) {
				params.input.organization = companyId
			} else {
				if (companies.companyIds?.length) {
					params.input.companyIds = companies.companyIds
				}
				if (companies.influencerIds?.length) {
					params.input.influencerIds = companies.influencerIds
				}
				if (companies.unionIds?.length) {
					params.input.unionIds = companies.unionIds
				}
			}
			const result = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_WITH_FILTER, params);
			let data = [];
			if (result && result.data && result.data.getAccountsWithFilter) {
				//const dataRole = role;
				data = window.COMMON.formatMultiDate(result.data.getAccountsWithFilter.data, 'createdDate,birthday').filter(el=>el.accountType!=='COMPANY'&& el.accountType!=="INFLUENCER" && el.accountType!=="UNION")
				data.forEach(item => {
					item.genderText = item.gender === 0 ? window.I18N('female') : window.I18N('male');
					if (role) {
						item.roleText = window.COMMON.getValueFromAttr(window.COMMON.getObjectInArrs(item.accountType, role, 'code'), 'label');
					}
					if (moment(item.birthday,'DD/MM/YYYY').isValid) {
						item.age = moment(new Date()).diff(moment(item.birthday,'DD/MM/YYYY'), 'year')
					}
				});
			}
			setDataAccount(data);
			if (role) {
				setDataRole(role)
			}
			if (!chartMode) {
				setChartMode('GENDER')
			}
			
		} catch (error) {
			window.COMMON.showErrorLogs('AccountPage.getDataAccount');
		}
	};

	const onChangeChartMode = (paramMode) => {
		setChartMode(paramMode)
	}
	useEffect(() => {
		if (chartMode) {
			groupDataforChart()
		}
	}, [chartMode, dataAccount, dataRole])

	useLayoutEffect(() => {
		setTimeout(() => {
			if (companyId && companyType) {
				getDataRole()
			} else if (companies.companyIds?.length || companies.influencerIds?.length || companies.unionIds?.length) {
				getDataAccount()
			}
		}, 100);
		// eslint-disable-next-line
	}, [companyType, companyId, companies]);

	return <div  className='mt-4 mx-auto '>
	<div className='d-flex align-items-center justify-content-between'>
		<h6 className="m-0 font-weight-bold" style={{ fontSize: "1rem" }}>{`Profile Chart Insight`}</h6>     
		<div className='w-full d-flex flex-row-reverse mb-2'>
		{/* GENDER */}
			<button 
				className={`mode-button ${chartMode==='GENDER'?'mode-active':''}`} 
				onClick={() => { onChangeChartMode("GENDER") }}
				style={{ borderRadius: '0 6px 6px 0' }}
			>
				{window.I18N('gender')}
			</button>
		{/* ROLE */}
			{companyId && 
				<button 
					className={`mode-button ${chartMode==='ROLE'?'mode-active':''}`} 
					onClick={() => { onChangeChartMode("ROLE") }}
				>
					{window.I18N('role')}
				</button>
			}
		{/* AGE */}
			<button 
				className={`mode-button ${chartMode==='AGE'?'mode-active':''}`} 
				onClick={() => { onChangeChartMode("AGE") }}
			>
				{window.I18N('age')}
			</button>
		{/* LOCATION */}
			<button 
				className={`mode-button ${chartMode==='LOCATION'?'mode-active':''}`} 
				onClick={() => { onChangeChartMode("LOCATION")}}
				style={{ borderRadius: '6px 0 0 6px' }}
			>
				{window.I18N('location')}
			</button>
		</div>

	</div>
		<div className='d-flex align-items-center justify-content-center' style={{ position: 'relative' }}>
			 <CustomChart typeChart={ chartMode === 'LOCATION' ? 'HorizontalBar' : 'Bar' } dataChart={dataChart} createInnerHTMLPopup={createInnerHTMLPopup} />
		</div>
		<div className='chart-title'>{window.I18N('account-of-company')}: {dataAccount.length }</div>
	</div>
	
}

export default InsightChartProfile;
