import React from "react";
import { useState, useMemo } from "react";
import PowerDashboardTabs, { NewSurveyPageStepStatus as status } from "../survey/NewSurveyPageSteps";
import DataLibraryPage from "./DataLibraryPage";
import DataGoPage from "./DataGoPage";
import DataVisualizedPage from "./DataVisualizedPage";
import { connect } from "react-redux";

const DATA_LIBRARY_PAGE = 0;
const DATA_GO_PAGE = 1;
// const DATA_VISUALIZED_PAGE = 2;

function PowerDashboardPage() {

    const TABS = useMemo(() => (
        [
            {
              id: 'tab0',
              name: 'data_library',
              status: status.PROCESSING,
              component: <DataLibraryPage onCreateTemplates={() => handleChangeTabs(DATA_GO_PAGE)} />
            },
            {
              id: 'tab1',
              name: 'data_go',
              status: status.WAITING,
              component: <DataGoPage />
            },
            {
              id: 'tab2',
              name: 'data_visualized',
              status: status.WAITING,
              component: <DataVisualizedPage />
            }
        ]
    ),[])
    const [currentTabs, setCurrentTabs] = useState(DATA_LIBRARY_PAGE)
    const [tabs, setTabs] = useState(TABS)


    const handleChangeTabs = (index)=>{
            setCurrentTabs(index) 
            setTabs(prev => (
                prev.map((tab, i)=>(
                    {...tab, status: i === index ? status.PROCESSING : status.WAITING}
                ))
            ))
    }

    return ( 
        <div className="w-full">
            <PowerDashboardTabs steps={tabs} hideSteps style={{textAlign: "center", padding: "10px 0"}} onStepClick={handleChangeTabs}/>
            {tabs[currentTabs].component}
        </div>
     );
}

const mapStateToProps = state => ({
    language: state.language.value
  });
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PowerDashboardPage);