import React from 'react'
import { connect } from 'react-redux'
import Carousel from '../../../../components/Carousel'
import styles from './EventItem.module.css'
import Button from '../../../../components/Button'
import JoinOrLeaveEvent from '../JoinOrLeaveEvent'
import { Avatar } from '@material-ui/core'

const EventItem = ({ data, onClickDetail = () => {}, refetchData = () => {}, language }) => {
    if (window.COMMON.isEmpty(data)) {
        return null
    }
    const {
        fanpageEvent: { images, eventDate, description, countMember, name, fanpage, gopy }
    } = data
    return (
        <div
            className={`${styles['event-item-container']} border rounded-lg wow fadeIn`}
            onClick={() => onClickDetail(data)}
        >
            <div style={{ height: '180px' }}>
                <Carousel
                    imageVariant="rounded-top"
                    data={images.map((item) => ({ imageUrl: item }))}
                    showControls={false}
                />
            </div>
            <div className="p-2">
                <div className={`${styles['event-date-time']}`}>{window.COMMON.formatDateForEvent(eventDate, language.code)}</div>
                <div className={`${styles['event-name']}`}>{name}</div>
                <div className={`${styles['event-description']}`}>{description}</div>
                <div className={`${styles['event-members']} mt-2`}>{`${countMember} ${window.I18N(
                    'will_participate'
                )}`}</div>
                <div
                    className={`${styles['event-members']} mt-1`}
                    dangerouslySetInnerHTML={{
                        __html: gopy
                            ? `${window.I18N('gopy_for_join').replace('X', `<strong>${gopy}</strong>`)}`
                            : `<strong>${window.I18N('free')}</strong>`
                    }}
                ></div>
                <div>
                    <div className="mt-1 d-flex align-items-center" style={{ gap: '8px' }}>
                        <Avatar alt={fanpage.name} src={fanpage.image} style={{ width: '28px', height: '28px' }} />
                        <div className="d-flex flex-column align-items-start justify-content-start">
                            <span className={`${styles['event-members']} text-center`}>{fanpage.name}</span>
                            <span className={`${styles['event-members']} text-center`}>{fanpage.type}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles['button-container']} px-2`}>
                {data.role === null || data.role === 'MEMBER' ? (
                    <JoinOrLeaveEvent
                        eventDate={data.fanpageEvent.eventDate}
                        eventId={data.fanpageEvent._id}
                        status={data.role}
                    />
                ) : (
                    <Button iconLeft="info-circle" variant="light" onClick={() => onClickDetail(data)}>
                        <span className="ml-1" style={{ fontSize: '0.9rem', fontWeight: 500 }}>
                            {window.I18N('view_details')}
                        </span>
                    </Button>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    language: state.language.value
})
const mapDispatchToProps = {}


export default connect(mapStateToProps, mapDispatchToProps)(EventItem)
