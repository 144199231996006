import React from 'react'
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem } from 'mdbreact'

import styles from './Carousel.module.css'
import Image from '../Image'

const Carousel = ({ data = [], imageVariant = 'no-rounded', showControls = true }) => {
    return (
        <MDBCarousel
            activeItem={1}
            length={data.length}
            showControls={showControls}
            showIndicators={false}
            className={`w-100 h-100 ${styles['container']}`}
        >
            <MDBCarouselInner>
                {data.map((item, index) => (
                    <MDBCarouselItem key={index} itemId={index + 1}>
                        <Image className={`d-block w-100 h-100 ${styles[imageVariant]}`} src={item.imageUrl} alt="" />
                    </MDBCarouselItem>
                ))}
            </MDBCarouselInner>
        </MDBCarousel>
    )
}

export default Carousel
