import gql from 'graphql-tag';

const QUERY_GROUP_CHAT = gql`
  query($type: String!, $organization: String) {
    getGroupChats(type: $type, organization: $organization) {
      _id
      name
      code
    	description
    	image
      type
      createdDate
      createdBy {
        name
      }
    }
  }
`;

const QUERY_GROUP_CHAT_BY_ID = gql`
  query($id: String!){
    findGroupById(id: $id) {
      _id
      name
      code
    	description
    	image
      type
      createdDate
      createdBy {
        name
      }
    }
  }
`;

const QUERY_FIND_GROUP_RELATIONSHIP = gql`
  query($groupId: String!) {
    findGroupRelationshipByGroup(groupId: $groupId) {
      _id
      user {
        _id
        avatar
        nickname
        name
      }
      status
      createdDate
    }
  }
`;

const QUERY_FIND_MEMBERS_IN_GROUP = gql`
  query($groupId: String!){
    findMembersInGroup(groupId: $groupId){
      _id
      user {
        _id
        avatar
        nickname
        name
      }
      status
      createdDate
    }
  }
`;

const MUTATION_ADD_GROUP_CHAT = gql`
  mutation($input: GroupChatInput!) {
    addGroupChat(input: $input) {
      _id
    }
  }
`;

const MUTATION_EDIT_GROUP_CHAT = gql`
  mutation($input: GroupChatInput!) {
    editGroupChat(input: $input) {
      _id
    }
  }
`;

const MUTATION_DEL_GROUP_CHAT = gql`
  mutation($id: String!) {
    deleteGroupChat(id: $id) {
      _id
    }
  }
`;

const MUTATION_REQUEST_GROUP_RELATIONSHIP = gql`
  mutation($groupId: String!) {
    requestGroupRelationship(groupId: $groupId)
}
`;

const MUTATION_ACCEPT_GROUP_RELATIONSHIP = gql`
  mutation($groupId: String!, $users: [String!]) {
    acceptGroupRelationship(groupId: $groupId, users: $users)
  }
`;

const MUTATION_DECLINE_GROUP_RELATIONSHIP = gql`
  mutation($groupId: String!, $users: [String!]) {
    declineGroupRelationship(groupId: $groupId, users: $users)
  }
`;

const MUTATION_BLOCK_GROUP_RELATIONSHIP = gql`
  mutation($groupId: String!, $users: [String!]) {
    blockGroupRelationship(groupId: $groupId, users: $users)
  }
`;
const MUTATION_LEAVE_GROUP_RELATIONSHIP = gql`
  mutation($groupId: String!){
    leaveGroupRelationship(groupId: $groupId)
  }
`;

const GroupChatGRAPHQL = {
  QUERY_GROUP_CHAT,
  QUERY_GROUP_CHAT_BY_ID,
  QUERY_FIND_GROUP_RELATIONSHIP,
  QUERY_FIND_MEMBERS_IN_GROUP,
  MUTATION_ADD_GROUP_CHAT,
  MUTATION_EDIT_GROUP_CHAT,
  MUTATION_DEL_GROUP_CHAT,
  MUTATION_REQUEST_GROUP_RELATIONSHIP,
  MUTATION_ACCEPT_GROUP_RELATIONSHIP,
  MUTATION_DECLINE_GROUP_RELATIONSHIP,
  MUTATION_BLOCK_GROUP_RELATIONSHIP,
  MUTATION_LEAVE_GROUP_RELATIONSHIP
};

export default GroupChatGRAPHQL;