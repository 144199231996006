import React, { useMemo } from 'react';
import { CustomHeaderChatRightSide } from '../../../../styles/styled-components/Chat/ChatPage';
import CircleAvatar from '../../../components/CircleAvatar';
import { MDBIcon } from 'mdbreact';
import { NavLink } from 'react-router-dom';
import Badge from '../../../components/Badge';
const HeaderChatRightSide = ({ groupChatSelected, onClose, groupChatMenuItems, groupChatMenuFunctions, onHeaderBadgeClick }) => {
  const groupChatToShow = useMemo(() => window.COMMON.getGroupChatInforToShow(groupChatSelected), [groupChatSelected]);
  return <CustomHeaderChatRightSide>
    <div className="py-2 information-wrapper">
      <CircleAvatar src={groupChatToShow.image} size={'48px'} showDot={groupChatSelected.isOnline === 'ONLINE'} />
      <div className="name">
        <span>{`${groupChatToShow?.groupName === '' ? groupChatSelected?.members[1]?.nickname : groupChatToShow.groupName }`}</span>
      </div>
      <div className='action'>
        {!window.COMMON.checkIndividualChatType(groupChatSelected.type) && <div className='mr-3'>
          <Badge count={groupChatSelected.joinRequestCount} onClick={onHeaderBadgeClick} />
        </div>}

        <div className="menu">
          <div className="dropdown">
            <NavLink to="#" className="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-boundary="viewport">
              <MDBIcon fa="true" icon="cog" className="fa-fw black-text" size="lg" />
            </NavLink>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" data-boundary="viewport">
              {groupChatMenuItems.map((item, index) => {
                return <li key={`${item._id}-${index}`} className="dropdown-item" onClick={(event) => {

                  if (groupChatMenuFunctions[item.onClickKey]) {
                    groupChatMenuFunctions[item.onClickKey](item.params);
                  }
                }}>
                  <MDBIcon fa="true" icon={`${item.icon}`} className="fa-fw"></MDBIcon> {window.I18N(item.title)}
                </li>;
              })}

            </ul>
          </div>
        </div>
        <div className="action-btn" onClick={onClose}>
          <MDBIcon fa="true" icon="times" className="fa-fw fa-lg"></MDBIcon>
        </div>
      </div>
    </div>
  </CustomHeaderChatRightSide>;
};


export default HeaderChatRightSide;