const templates = {
  approvedCase: `
    <p>Cuộc thanh tra đánh giá về vấn đề 
    <b> {{name}} </b> đã được phê duyệt. <br></br>
    - Người phê duyệt: {{approvedBy}} <br></br>
    
    <br></br>

    <p>Case assessment 
    <b>{{name}}</b> has been approved: <br></br>
    - approver: {{approvedBy}} <br></br>
  `,
  rejectCase: `
  <p>Cuộc thanh tra đánh giá về vấn đề 
    <b> {{name}} </b> đã bị {{rejectedBy}} từ chối.<br></br>
    Lí do từ chối: <br></br>
    {{reason}}
    <br></br>
    Vui lòng kiểm tra và cập nhật lại. <br></br>
    <br></br>

    <p>The case assessment 
    <b>{{name}}</b> has been rejected by {{rejectedBy}}. <br></br>
    Reject reason: <br></br>
    {{reason}}
    <br></br>
    Please check and resubmit case.
  `
};

export default templates;