import React, { useState, useCallback, useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { MDBRow, MDBCol, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';

import PostGRAPHQL from '../../graphql/Post';
import SurveyGRAPHQL from '../../graphql/Survey';
import QuestionGRAPHQL from '../../graphql/Question';

import RadioBox from './RadioBox';
import ItemPost from './ItemPost';
import ChatGRAPHQL from '../../graphql/Chat';
import socket from '../../utils/socket';

const PUSH_MESSAGE_TYPE = 'PUSH_MESSAGE'

const PushNotification = React.memo(function PushNotification (props) {
  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const NICK_NAME = localStorage.getItem(window.CONSTANT.NICK_NAME);
  const genderOptions =  [
    { value: -1, label: window.I18N('all_gender') },
    { value: 0, label: window.I18N('female') },
    { value: 1, label: window.I18N('male') }
  ];
  const maritalStatusOptions =  [
    { value: -1, label: window.I18N('all_marital_status') },
    { value: 0, label: window.I18N('single') },
    { value: 1, label: window.I18N('married') }
  ];
  const types = [
    { value: 'COMPANY', label: window.I18N('companies') },
    { value: 'LOCATION', label: window.I18N('location') },
    { value: 'GROUP_CHAT', label: window.I18N('group_chat') }
  ];
  const pushTypes = [
    { value: 'PUSH_NOTIFICATION', label: window.I18N('normal'), icon: 'text-width' },
    { value: 'PUSH_NOTIFICATION_POST', label: window.I18N('post'), icon: 'calendar-plus', disabled: true },
    { value: 'PUSH_NOTIFICATION_SURVEY', label: window.I18N('survey'), icon: 'question-circle' },
    { value: 'PUSH_NOTIFICATION_BROWSE_SURVEY', label: window.I18N('browse_survey'), icon: 'question-circle' },
    { value: PUSH_MESSAGE_TYPE, label: window.I18N('push_message'), icon: 'comment' }
  ];

  const language = useRef();
  const [object, setObject] = useState({ isShowCompany: false, type: 'COMPANY', pushType: 'PUSH_NOTIFICATION' });
  const [dataSave, setDataSave] = useState({});
  const [select, setSelect] = useState({});
  const [selectOptional, setSelectOptional] = useState({});
  const [options, setOptions] = useState({
    companies: [],
    nation: [],
    city: [],
    state: [],
    menuSurvey: []
  });

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {
      getDataLocationOptions('-1', '-1');
      getDataMenuSurvey();
      getDataGroupQuestion();
      resetForm(window.COMMON.checkValue(props.pushPost));
    }, 100);
    // eslint-disable-next-line
  }, [language.current, props.pushType, props.pushPost, props.dataCompanies]);

  const handleChange = (event) => {
    dataSave[event.target.name] = event.target.value;
    setDataSave({ ...dataSave });
  };

  const handleSelect = (event, id, attr) => {
    window.COMMON.checkSelectValidation(id, event);
    select[attr] = event;
    if (attr === 'type') {
      select.days = [];
      select.months = [];
      setSelect({ ...select });
    } else if (attr === 'nation' || attr === 'state') {
      getDataLocationOptions(attr, event.code);
    } else {
      if (attr === 'city' || attr === 'companyIndustry') {
        select.companies = [];
        let dataCompanies = props.dataCompanies;
        if (select['nation'] && select['nation'].value) {
          dataCompanies = window.COMMON.getArrsInArrs(select['nation'].value, dataCompanies, !window.COMMON.checkRoleIsSystem() ? 'nation._id' : 'information.nation._id');
        }
        if (select['state'] && select['state'].value) {
          dataCompanies = window.COMMON.getArrsInArrs(select['state'].value, dataCompanies, !window.COMMON.checkRoleIsSystem() ? 'state._id' : 'information.state._id');
        }
        if (select['city'] && select['city'].value) {
          dataCompanies = window.COMMON.getArrsInArrs(select['city'].value, dataCompanies, !window.COMMON.checkRoleIsSystem() ? 'city._id' : 'information.city._id');
        }
        if (select['companyIndustry'] && select['companyIndustry'].value) {
          dataCompanies = window.COMMON.getArrsInArrs(select['companyIndustry'].value, dataCompanies, !window.COMMON.checkRoleIsSystem() ? 'companyIndustry._id' : 'information.companyIndustry._id');
        }
        setOptionCompany(dataCompanies);
        setOptions(options);
      }
      setSelect({ ...select });
    }
  };

  const hanldleSelectOptional = (event, attr) => {
    selectOptional[attr] = event;
    setSelectOptional({ ...selectOptional });
  };

  const handleRadio = useCallback((value, attr) => {
    object[attr] = value;
    if (attr === 'type') {
      select.companies = [];
      select.accountTypes = [];
      select.groupChats = [];
      select.companyIndustry = null;
      select.gender = genderOptions[0];
      select.maritalStatus = maritalStatusOptions[0];
      select.age = props.options.age && props.options.age.length > 0 ? props.options.age[0] : null;
      select.occupation = props.options.occupation && props.options.occupation.length > 0 ? props.options.occupation[0] : null;
      select.educationBackground = props.options.educationBackground && props.options.educationBackground.length > 0 ? props.options.educationBackground[0] : null;
      select.income = props.options.income && props.options.income.length > 0 ? props.options.income[0] : null;
      select.nation = null;
      select.state = null;
      select.city = null;
      options.state = [];
      options.city = [];
      options.companies = props.options.companies;
      if (value === 'LOCATION') {
        select.companyIndustry = props.options.companyIndustry && props.options.companyIndustry.length > 0 ? props.options.companyIndustry[0] : null;
      }
      setOptionCompany(props.dataCompanies);
      setSelect(select);
      setOptions(options);
    } else {
      selectOptional.menuSurvey = null;
      selectOptional.groupSurvey = null;
      if (value === 'PUSH_NOTIFICATION_SURVEY') {
        selectOptional.menuSurvey = options.menuSurvey && options.menuSurvey.length > 0 ? options.menuSurvey[0] : null;
      } else if (value === 'PUSH_NOTIFICATION_BROWSE_SURVEY') {
        selectOptional.groupSurvey = options.groupSurvey && options.groupSurvey.length > 0 ? options.groupSurvey[0] : null;
      }
      setSelectOptional(selectOptional);
    }
    setObject({ ...object });
  }, [props.options, select, options, object, genderOptions, maritalStatusOptions]);

  // LOCATION
  const getDataLocationOptions = async (attr, parent) => {
    if (attr === '-1') {
      options.nation = await window.COMMON.getDataLocation(parent);
      options.state = [];
      options.city = [];
      select.nation = null;
      select.state = null;
      select.city = null;
    } else if (attr === 'nation') {
      options.state = await window.COMMON.getDataLocation(parent);
      options.city = [];
      select.state = null;
      select.city = null;
    } else if (attr === 'state') {
      options.city = await window.COMMON.getDataLocation(parent);
      select.city = null;
    }
    select.companies = [];
    let dataCompanies = props.dataCompanies;
    if (select['nation'] && select['nation'].value) {
      dataCompanies = window.COMMON.getArrsInArrs(select['nation'].value, dataCompanies, !window.COMMON.checkRoleIsSystem() ? 'nation._id' : 'information.nation._id');
    }
    if (select['state'] && select['state'].value) {
      dataCompanies = window.COMMON.getArrsInArrs(select['state'].value, dataCompanies, !window.COMMON.checkRoleIsSystem() ? 'state._id' : 'information.state._id');
    }
    if (select['city'] && select['city'].value) {
      dataCompanies = window.COMMON.getArrsInArrs(select['city'].value, dataCompanies, !window.COMMON.checkRoleIsSystem() ? 'city._id' : 'information.city._id');
    }
    if (select['companyIndustry'] && select['companyIndustry'].value) {
      dataCompanies = window.COMMON.getArrsInArrs(select['companyIndustry'].value, dataCompanies, !window.COMMON.checkRoleIsSystem() ? 'companyIndustry._id' : 'information.companyIndustry._id');
    }
    setOptionCompany(dataCompanies);
    setOptions(options);
    setSelect({ ...select });
  };

  const resetForm = async (isPushPost) => {
    object.status = 0;
    const dataSave = {};
    object.isShowCompany = COMPANY_TYPE === window.CONSTANT.ADMIN || COMPANY_TYPE === window.CONSTANT.INFLUENCER;
    object.pushType = isPushPost ? 'PUSH_NOTIFICATION_POST' : 'PUSH_NOTIFICATION';
    dataSave.title = NICK_NAME;
    dataSave.body = '';
    dataSave.type = 'PUSH_NOTIFICATION';
    select.companies = [];
    if (object.isShowCompany) {
      select.companies = [];
    } else {
      select.companies = [COMPANY_ID];
    }
    select.groupChats = [];
    select.accountTypes = [];
    select.companyIndustry = props.options.companyIndustry && props.options.companyIndustry.length > 0 ? props.options.companyIndustry[0] : null;
    select.gender = genderOptions[0];
    select.maritalStatus = maritalStatusOptions[0];
    select.age = props.options.age && props.options.age.length > 0 ? props.options.age[0] : null;
    select.occupation = props.options.occupation && props.options.occupation.length > 0 ? props.options.occupation[0] : null;
    select.educationBackground = props.options.educationBackground && props.options.educationBackground.length > 0 ? props.options.educationBackground[0] : null;
    select.income = props.options.income && props.options.income.length > 0 ? props.options.income[0] : null;
    options.state = [];
    options.city = [];
    select.nation = null;
    select.state = null;
    select.city = null;
    selectOptional.menuSurvey = null;
    selectOptional.groupSurvey = null;
    window.COMMON.resetFormValidation('form-push-notification');
    setOptionCompany(props.dataCompanies);
    setObject(object);
    setSelect(select);
    setSelectOptional(selectOptional);
    setOptions(options);
    setDataSave({ ...dataSave });
    // eslint-disable-next-line
  };

  const setOptionCompany = (dataCompanies) => {
    if (!dataCompanies || dataCompanies.length === 0) {
      options.companies = [];
      return;
    }
    if (!window.COMMON.checkRoleIsSystem()) {
      if (dataCompanies && dataCompanies.length > 0) {
        options.companies = window.COMMON.createDataSelectWithGroup(dataCompanies, 'id', 'name', 'companyIndustry._id', 'accountType');
      }
    } else {
      if (dataCompanies && dataCompanies.length > 0) {
        options.companies = window.COMMON.createDataSelectWithGroup(dataCompanies, '_id', 'information.name', 'information.companyIndustry._id', 'type');
      }
    }
  };

  // MENU SURVEY
  const getDataMenuSurvey = async () => {
    try {
      const result = await window.COMMON.query(SurveyGRAPHQL.QUERY_MENU_SURVEY, null);
      if (result && result.data) {
        options.menuSurvey = window.COMMON.createDataSelectWithLanguage(result.data.getMenuSurveys, '_id', 'content', language.current, true);
        setOptions(options);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('PushNotification.getDataMenuSurvey');
    }
  };

  // GROUP QUESTION
  const getDataGroupQuestion = async (mode) => {
    try {
      const params = {
        company: window.COMMON.checkValue(COMPANY_ID) ? COMPANY_ID : null
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_GROUP_QUESTION, params);
      if (result && result.data) {
        options.groupSurvey = window.COMMON.createDataSelectWithLanguage(result.data.getGroupQuestions, '_id', 'name', language.current, false);
        setOptions(options);
      }
    } catch (error) {
      console.log(error);
      window.COMMON.showErrorLogs('PushNotification.getDataGroupQuestion');
    }
  };

  const pushMessages = async (params) => {
    try{
      params.input.socketId = socket?.id
      const result = await window.COMMON.mutation(ChatGRAPHQL.MUTATION_PUSH_MESSAGES, params);
      const pushMessagesResult = result.data.pushMessages;

      if(pushMessagesResult === 1){
        window.showNotification(
          'success',
          'MSG_CODE_076',
          window.I18N('MSG_CODE_076')
        );
        resetForm(false);
      }
      else if(pushMessagesResult === -1){
        window.showNotification(
          'error',
          'MSG_CODE_074',
          window.I18N('MSG_CODE_074')
        );
      }
      else if(pushMessagesResult === -2){
        window.showNotification(
          'error',
          'MSG_CODE_075',
          window.I18N('MSG_CODE_075')
        );
      }
    }
    catch(error){
      window.COMMON.showErrorLogs('PushNotification.pushMessages');
    }
  }

  const pushNotification = async (event) => {
    try {
      const checkForm = window.COMMON.checkFormValidation('form-push-notification', event);
      let check = false;
      if (!checkForm) {
        check = true;
      }
      if (object.type === 'GROUP_CHAT') {
        const checkGroupChats = window.COMMON.checkSelectValidation('select-group-chats', select.groupChats);
        if (!checkGroupChats) {
          check = true;
        }
      }
      if (check) {
        return;
      }
      if (object.type === 'LOCATION' && options.companies.length === 0) {
        window.COMMON.showMessage('warning', 'MSG_CODE_073', window.I18N('MSG_CODE_073'));
        return;
      }
      dataSave.grantType = object.type;
      const keys = Object.keys(select);
      for (let i = 0; i < keys.length; i++) {
        const element = keys[i];
        const check = element === 'accountTypes' || element === 'companies' || element === 'groupChats';
        dataSave[element] = window.COMMON.getDataSelect(select[element], check);
      }
      if (dataSave.companies.length === 0 && object.type === 'LOCATION') {
        //khi chọn industry, location đã filter lại options company=> lấy toàn bộ company gửi về BE
        options.companies.forEach(element => {
          if (element.options.length > 0) {
            element.options.forEach(child => {
              dataSave.companies.push(child.value);
            });
          }
        });
      }
      let id = null;
      if (object.pushType === 'PUSH_NOTIFICATION_POST') {
        id = window.COMMON.getValueFromAttr(props.pushPost, '_id');
      } else if (object.pushType === 'PUSH_NOTIFICATION_SURVEY') {
        id = window.COMMON.getDataSelect(selectOptional.menuSurvey);
      } else if (object.pushType === 'PUSH_NOTIFICATION_BROWSE_SURVEY') {
        id = window.COMMON.getDataSelect(selectOptional.groupSurvey);
      }
      const params = {
        input: dataSave
      };
      params.input.id = id;
      params.input.type = object.pushType;
      if ((COMPANY_TYPE === window.CONSTANT.COMPANY || COMPANY_TYPE === window.CONSTANT.UNION) && object.type === 'COMPANY') {
        params.input.companies = [COMPANY_ID];
      }
      window.COMMON.trimData(params);

      if(object.pushType!==PUSH_MESSAGE_TYPE){
        const result = await window.COMMON.mutation(PostGRAPHQL.MUTATION_PUSH_NOTIFICATION, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          resetForm(false);
        }
      }
      else{
        pushMessages(params);
      }

    } catch (error) {
      window.COMMON.showErrorLogs('PushNotification.pushNotification');
    }
  };

  const handleOnClickPushNotification = (event) => {

    if(object.pushType===PUSH_MESSAGE_TYPE){
      
      window.COMMON.showModal('#modal-save');
      window.saveMethod = () => {
        pushNotification(event);
      }
    }
    else{
      pushNotification(event);
    }
  }

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <>
      <form id="form-push-notification" className="needs-validation w-100" noValidate>
        <MDBRow>
          <MDBCol sm="12">
            <RadioBox data={types} value={object.type} isTab={true} className="m-0" handleChange={(value) => handleRadio(value, 'type')}></RadioBox>
          </MDBCol>
          <MDBCol sm="12">
            <MDBRow>
              {
                !object.isShowCompany || object.type !== 'COMPANY' ? <></> : <MDBCol>
                  <Select id="select-companies" value={select.companies} options={options.companies} placeholder={window.I18N('all_organizations')} className="md-form" onChange={(event) => handleSelect(event, 'select-companies', 'companies')} isSearchable isMulti closeMenuOnSelect={false}/>
                </MDBCol>
              }
              <MDBCol sm="6">
                {
                  object.type !== 'COMPANY' ? <></> : 
                    <Select id="select-account-types" value={select.accountTypes} options={props.options.role} placeholder={window.I18N('all_account_types')} className="md-form" onChange={(event) => handleSelect(event, 'select-account-types', 'accountTypes')} isSearchable isMulti closeMenuOnSelect={false}/>
                }
              </MDBCol>
            </MDBRow>
            {
              object.type !== 'LOCATION' ? <></> : <> 
                <MDBRow>
                  <MDBCol>
                    <Select id="select-nation" value={select.nation} options={options.nation} className="md-form" placeholder={window.I18N('choose_option')} onChange={(event) => handleSelect(event, 'select-nation', 'nation')} isSearchable/>
                  </MDBCol>
                  <MDBCol>
                    <Select id="select-state" value={select.state} options={options.state} className="md-form" placeholder={window.I18N('choose_option')} onChange={(event) => handleSelect(event, 'select-state', 'state')} isSearchable/>
                  </MDBCol>
                  <MDBCol>
                    <Select id="select-city" value={select.city} options={options.city} className="md-form" placeholder={window.I18N('choose_option')} onChange={(event) => handleSelect(event, 'select-city', 'city')} isSearchable/>
                  </MDBCol>
                </MDBRow>
              </>
            }
            {
              object.type !== 'LOCATION' ? <></> : <> 
                <MDBRow>
                  <MDBCol>
                    {
                      !object.isShowCompany ? <></> : <Select id="select-company-industry" value={select.companyIndustry} options={props.options.companyIndustry} className="md-form mt-0" onChange={(event) => handleSelect(event, 'select-company-industry', 'companyIndustry')} isSearchable/>
                    }
                  </MDBCol>
                  <MDBCol>
                    {
                      !object.isShowCompany ? <></> : 
                        <Select id="select-companies" value={select.companies} options={options.companies} placeholder={window.I18N('all_organizations')} className="md-form mt-0" onChange={(event) => handleSelect(event, 'select-companies', 'companies')} isSearchable isMulti closeMenuOnSelect={false}/>
                    }               
                  </MDBCol>
                </MDBRow>
              </>
            }
            {
              object.type === 'GROUP_CHAT' ? <></> : <> 
                <MDBRow>
                  <MDBCol>
                    <Select id="select-age" value={select.age} options={props.options.age} className="md-form mt-0" label={window.I18N('age') + ' *'} onChange={(event) => handleSelect(event, 'select-age', 'age')} isSearchable/>                  
                    <Select id="select-occupation" value={select.occupation} options={props.options.occupation} className="md-form" onChange={(event) => handleSelect(event, 'select-occupation', 'occupation')} isSearchable/>
                  </MDBCol>
                  <MDBCol>
                    <Select id="select-gender" value={select.gender} options={genderOptions} className="md-form mt-0" onChange={(event) => handleSelect(event, 'select-gender', 'gender')} isSearchable/>
                    <Select id="select-income" value={select.income} options={props.options.income} className="md-form mt-0" onChange={(event) => handleSelect(event, 'select-income', 'income')} isSearchable/>
                  </MDBCol>
                  <MDBCol>
                    <Select id="select-marital-status" value={select.maritalStatus} options={maritalStatusOptions} className="md-form mt-0" onChange={(event) => handleSelect(event, 'select-marital-status', 'maritalStatus')} isSearchable/>
                    <Select id="select-education-background" value={select.educationBackground} options={props.options.educationBackground} className="md-form mt-0" onChange={(event) => handleSelect(event, 'select-education-background', 'educationBackground')} isSearchable/>                 
                  </MDBCol>
                </MDBRow>
              </>
            }
          </MDBCol>
          {
            object.type === 'GROUP_CHAT' ? <MDBCol sm="12">
              <Select id="select-group-chats" value={select.groupChats} options={props.options.groupChats} placeholder={window.I18N('group_chat') + ' *'} className="md-form" onChange={(event) => handleSelect(event, 'select-group-chats', 'groupChats')} isSearchable isMulti closeMenuOnSelect={false}/>
            </MDBCol> : <></>
          }
          <MDBCol sm="12">
            <MDBInput outline containerClass="mt-0 mb-0" id="input-push-notification" type="textarea" label={window.I18N('input_content')} name="body" value={dataSave.body} onChange={handleChange} maxLength="5000" pattern="\S(.*\S)?" required></MDBInput>
          </MDBCol>
          <MDBCol sm="12">
            <hr></hr>
            <RadioBox data={pushTypes} value={object.pushType} className="mb-3" handleChange={(value) => handleRadio(value, 'pushType')}></RadioBox>
            {
              object.pushType === 'PUSH_NOTIFICATION_SURVEY' ? <div className="input-group input-group-transparent mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="question-circle" className="fa-fw" />
                  </span>
                </div>
                <Select className="md-form m-0" id="select-menu-survey" placeholder={window.I18N('choose_option')} value={selectOptional.menuSurvey} options={options.menuSurvey} onChange={(event) => hanldleSelectOptional(event, 'menuSurvey')} isSearchable/>
              </div> 
                : object.pushType === 'PUSH_NOTIFICATION_BROWSE_SURVEY' ?  <div className="input-group input-group-transparent mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="question-circle" className="fa-fw" />
                    </span>
                  </div>
                  <Select className="md-form m-0" id="select-group-survey" placeholder={window.I18N('choose_option')} value={selectOptional.groupSurvey} options={options.groupSurvey} onChange={(event) => hanldleSelectOptional(event, 'groupSurvey')} isSearchable/>
                </div>
                  : object.pushType === 'PUSH_NOTIFICATION_POST' ? <ItemPost item={props.pushPost} index={0} isShare={true} className="mt-3 mb-3" attr_id="_id" attr_content="text" attr_image="image" attr_avatar="createdBy.avatar" attr_name="createdBy.nickname" attr_time="createdDate"></ItemPost> : <div></div>
            }
          </MDBCol>
        </MDBRow>
      </form>
      <div className="d-flex justify-content-end w-100">
        <MDBBtn color="" onClick={() => resetForm(false)}>
          <MDBIcon fa="true" icon="redo-alt" className="fa-fw" /> {window.I18N('btn_clear')}
        </MDBBtn>
        <MDBBtn color="primary" onClick={handleOnClickPushNotification} className="mr-0">
          <MDBIcon fa="true" icon="paper-plane" className="fa-fw" /> {window.I18N('btn_push')}
        </MDBBtn>
      </div>
    </>
  );
});

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};
  
export default connect(mapStateToProps, mapDispatchToProps)(PushNotification);