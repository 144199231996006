const accountEvent = {
  ADD_ACCOUNT: 'ADD_ACCOUNT',
  EDIT_ACCOUNT: 'EDIT_ACCOUNT',
  SET_PACKAGE: 'SET_PACKAGE',
  UPDATE_STATUS_ACCOUNT: 'UPDATE_STATUS_ACCOUNT',
  RESET_PASSWORD_ACCOUNT: 'RESET_PASSWORD_ACCOUNT',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  ACCEPT_REQUEST_ACCOUNT: 'ACCEPT_REQUEST_ACCOUNT',
  DECLINE_REQUEST_ACCOUNT: 'DECLINE_REQUEST_ACCOUNT',
  ACCEPT_REPORT_ACCOUNT: 'ACCEPT_REPORT_ACCOUNT',
  DECLINE_REPORT_ACCOUNT: 'DECLINE_REPORT_ACCOUNT',
  REQUEST_INFLUENCER_JOIN_COMPANY: 'REQUEST_INFLUENCER_JOIN_COMPANY',
  DELETE_INFLUENCER_JOIN_COMPANY: 'DELETE_INFLUENCER_JOIN_COMPANY',
  ACCEPT_FRIEND: 'ACCEPT_FRIEND',
  DECLINE_FRIEND: 'DECLINE_FRIEND',
  CHANGE_COMPANY: 'CHANGE_COMPANY',
  CHECK_IMPORT_ACCOUNT: 'CHECK_IMPORT_ACCOUNT',
  IMPORT_ACCOUNT: 'IMPORT_ACCOUNT'
};

export default accountEvent;