import React, { useEffect, useMemo, useRef, useState } from 'react'
import { MDBBtn, MDBIcon } from 'mdbreact'
import ReactSelect from 'react-select'

import Filter from '../../../components/Filter'

const UserBehaviorFilters = ({ onFilters = () => {}, EVENT_TRACKING, DEFAULT_FILTER_EVENTIDS }) => {
    const dataDaysSelect = [
        {
            label: window.I18N('past_7_days'),
            value: '7'
        },
        {
            label: window.I18N('past_30_days'),
            value: '30'
        }
    ]

    const dataEventsOptions = useMemo(() => {
        const data = Object.entries(EVENT_TRACKING).map((item) => {
            const [value, label] = item
            return {
                label,
                value
            }
        })
        return data
    }, [])

    const [filters, setFilters] = useState({
        eventIds: dataEventsOptions.filter((item) => DEFAULT_FILTER_EVENTIDS.includes(item.value)),
        days: dataDaysSelect[0]
    })
    const [templeFilters, setTempleFilters] = useState({
        eventIds: null,
        days: null
    })

    const filterRef = useRef()

    const handleSelectEvents = (value) => {
        const isOverLengthEvents = (value?.length || 0) > DEFAULT_FILTER_EVENTIDS.length
        if (isOverLengthEvents) {
            window.COMMON.showMessage(
                'warning',
                'MSG_CODE_077',
                window.I18N('MSG_CODE_077').replace('X', `${DEFAULT_FILTER_EVENTIDS.length}`)
            )
            return
        } else {
            setTempleFilters((prev) => ({ ...prev, eventIds: value ?? [] }))
        }
    }

    const handleSelectDays = (value) => {
        setTempleFilters((prev) => ({ ...prev, days: value }))
    }

    const handleSetTempleFilters = (value, type = 'days') => {
        if (type === 'days') {
            handleSelectDays(value)
        } else {
            handleSelectEvents(value)
        }
    }

    const handleResetTempleFilters = () => {
        setTempleFilters((prev) => ({ ...prev, eventIds: null, days: null }))
    }

    const handleGetFilters = () => {
        let dataFiltersSet = {}
        for (let key in templeFilters) {
            if (templeFilters[key] !== null) {
                dataFiltersSet[key] = templeFilters[key]
            }
        }
        onFilters(dataFiltersSet)
        setFilters((prev) => ({ ...prev, ...dataFiltersSet }))
    }

    const handleCloseAndResetFilters = () => {
        filterRef.current.closeFilter()
        handleResetTempleFilters()
    }

    const handleApplyFilters = () => {
        if (
            !window.COMMON.checkSelectValidation(
                'users-behavior-form-filter',
                templeFilters.eventIds ?? filters.eventIds
            )
        ) {
            window.COMMON.showMessage('warning', 'MSG_CODE_010', window.I18N('MSG_CODE_010'))
            return
        }
        handleGetFilters()
        handleCloseAndResetFilters()
    }

    //initial filters for user behavior page
    useEffect(() => {
        onFilters(filters)
    }, [])

    return (
        <Filter
            id="container-filter"
            btnClassName={`m-0`}
            onClick={() => handleResetTempleFilters()}
            onClickOutSide={handleCloseAndResetFilters}
            ref={filterRef}
        >
            {/* Header start*/}
            <form id="users-behavior-form-filter">
                <div className="d-flex align-items-center justify-content-between" style={{ gap: '8px' }}>
                    {/* Filter with eventId */}
                    <div className="w-75">
                        <h6 className="font-weight-bold" style={{ fontSize: '0.8rem' }}>
                            {`${window.I18N('event_filter')}*`}
                        </h6>
                        <div className="input-group input-group-transparent">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <MDBIcon fa="true" icon="th-list" className="fa-fw" />
                                </span>
                            </div>
                            <ReactSelect
                                key="eventIds"
                                required
                                isSearchable
                                isMulti
                                isClearable={false}
                                closeMenuOnSelect={false}
                                value={templeFilters.eventIds ?? filters.eventIds}
                                options={dataEventsOptions}
                                className="md-form m-0"
                                placeholder={window.I18N('choose_option')}
                                onChange={(value) => handleSetTempleFilters(value, 'eventIds')}
                            />
                        </div>
                    </div>
                    {/* Filter with date */}
                    <div className="w-25">
                        <h6 className="font-weight-bold" style={{ fontSize: '0.8rem' }}>
                            {window.I18N('date_filter')}
                        </h6>
                        <div className="input-group input-group-transparent">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <MDBIcon fa="true" icon="calendar" className="fa-fw" />
                                </span>
                            </div>
                            <ReactSelect
                                key="days"
                                value={templeFilters.days ?? filters.days}
                                options={dataDaysSelect}
                                className="md-form m-0"
                                placeholder={window.I18N('choose_option')}
                                onChange={(value) => handleSetTempleFilters(value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="text-right mt-2">
                    <MDBBtn className="mx-0" color="primary" onClick={handleApplyFilters}>
                        <MDBIcon fa="true" icon="sync-alt" className="fa-fw" /> {window.I18N('apply')}
                    </MDBBtn>
                </div>
            </form>
        </Filter>
    )
}

export default React.memo(UserBehaviorFilters)
