import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    actionsContainer: {
        marginBottom: theme.spacing(2)
    },
    resetContainer: {
        padding: theme.spacing(1)
    }
}))

const getStepContent = (days) => {
    return window.I18N('message_reminder').replace('X', `${days}`)
}

const ReminderView = ({ eventDate = null, reminders = [] }) => {
    const classes = useStyles()

    function mappingReminders(listReminders, reminder) {
        let activeReminder = -1
        if (listReminders.length === 1 && reminder <= listReminders[0]) {
            return listReminders.length + 1
        }
        for (let index = 0; index < listReminders.length; index++) {
            if (reminder <= listReminders[index]) {
                activeReminder = index
            }
        }
        return activeReminder
    }

    const handleStepsReminder = (activeStep = -1) => {
        const dataSteps = reminders.map((reminder, index) => {
            const date = window.COMMON_DATE.addDays(+eventDate, -reminder)
            const formatDate = window.COMMON_DATE.formatDate(date, 'DD/MM/YYYY')
            return `${formatDate} - ${
                reminder === 1
                    ? window.I18N('days_before_event').replace('X', `${reminder}`).replace('days', 'day')
                    : window.I18N('days_before_event').replace('X', `${reminder}`)
            } ${activeStep > index && activeStep !== -1 ? `(${window.I18N('remider_sent')})` : ''}`
        })

        return dataSteps
    }

    const activeStep = useMemo(() => {
        const dayOfNow = new Date()
        const dayOfEvent = new Date(+eventDate)
        dayOfNow.setHours(dayOfEvent.getHours())
        dayOfNow.setMinutes(dayOfEvent.getMinutes())
        const timeDiff = window.COMMON_DATE.getDiffDate(dayOfNow, dayOfEvent)
        let currentStep = mappingReminders(reminders, timeDiff)
        if (dayOfNow >= dayOfEvent) {
            return reminders.length + 1
        } else {
            return currentStep
        }
    }, [eventDate])

    const steps = handleStepsReminder(activeStep)

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                            <div style={{ whiteSpace: 'pre-line' }}>{getStepContent(reminders[activeStep])}</div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </div>
    )
}

export default ReminderView
