import gql from 'graphql-tag';

const QUERY_TASK_SCHEDULE = gql`
  query($input: TaskScheduleFilter!) {
    getTaskSchedules(input: $input) {
      data {
        _id
        code
        name
        description
        grantType
        type
        days
        months
        company {
          _id
          information {
            avatar
            name
          }
        }
        companyIndustry
        companies
        nation
        state
        city
        gender
        maritalStatus
        age
        occupation
        educationBackground
        income
        groupChats
        accountTypes
        users
        group {
          _id
          code
          name {
            code
            text
          }
        }
        status
        startDate
        endDate
        createdDate
        createdBy {
          avatar
          name
          _id
        }
      }
      total
      page
      pageSize
    }
  }
`;

const QUERY_COUNT_TASK_SCHEDULE = gql`
  query($input: TaskSchedulesCount) {
    countTaskSchedules(input: $input){
      total
      news
      process
      finished
      paused
      stopped
    }
  }
`;

const QUERY_TASK_SCHEDULE_DATA_INSIGHT = gql`
  query($input: DataInsightInput) {
    getTaskSchedulesDataInsight(input: $input){
        _id
        name
        description
        grantType
        companyIndustry
        nation
        state
        city
        status
        startDate
        endDate
        createdDate
        group {
          _id
          code
          name {
            code
            text
          }
        }
        company {
            information {
                name
            }
            _id
        }
        companies {
            _id
            information {
                name
            }
        }
        taskDone
        taskNotDone
    }
  }
`


const MUTATION_ADD_TASK_SCHEDULE = gql`
  mutation($input: TaskScheduleInput!) {
    addTaskSchedule(input: $input)
  }
`;

const MUTATION_EDIT_TASK_SCHEDULE = gql`
  mutation($input: TaskScheduleInput!) {
    editTaskSchedule(input: $input)
  }
`;

const MUTATION_UPDATE_STATUS_TASK_SCHEDULE = gql`
  mutation($id: String!, $status: Int!) {
    updateStatusTaskSchedule(id: $id, status: $status)
  }
`;

const MUTATION_DEL_TASK_SCHEDULE = gql`
  mutation($id: String!) {
    deleteTaskSchedule(id: $id)
  }
`;

const TaskScheduleGRAPHQL = {
  QUERY_TASK_SCHEDULE,
  QUERY_COUNT_TASK_SCHEDULE,
  QUERY_TASK_SCHEDULE_DATA_INSIGHT,
  MUTATION_ADD_TASK_SCHEDULE,
  MUTATION_EDIT_TASK_SCHEDULE,
  MUTATION_UPDATE_STATUS_TASK_SCHEDULE,
  MUTATION_DEL_TASK_SCHEDULE,
};

export default TaskScheduleGRAPHQL;