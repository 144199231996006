import React from 'react'
import Carousel from '../../../../components/Carousel'
import { connect } from 'react-redux'

import styles from './RecommendEventItem.module.css'

const RecommendEventItem = ({ data, onClickDetail = () => {}, language }) => {
    const {
        fanpageEvent: { images, name, eventDate }
    } = data
    return (
        <div
            className={`mb-2 d-flex align-items-center justify-content-start ${styles['recommend-container']}`}
            style={{ gap: '8px' }}
            onClick={() => onClickDetail(data)}
        >
            <div
                style={{
                    width: '120px',
                    height: '60px',
                    flexShrink: 0
                }}
            >
                <Carousel
                    data={images.map((image) => ({ imageUrl: image }))}
                    imageVariant="rounded-full"
                    showControls={false}
                />
            </div>
            <div className="d-flex flex-column align-items-start justify-content-center">
                <span style={{ fontSize: '10px', color: '#f3425f', fontWeight: 700 }}>
                    {window.COMMON.formatDateForEvent(eventDate, language.code)}
                </span>
                <span className={styles['event-name']} style={{ fontSize: '12px', fontWeight: 700 }}>
                    {name}
                </span>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    language: state.language.value
})
const mapDispatchToProps = {}


export default connect(mapStateToProps, mapDispatchToProps)(RecommendEventItem)
