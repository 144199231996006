import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';
import NewSurveyPageSteps from '../survey/NewSurveyPageSteps';
import { SurveyPagePaperStyled } from '../../../styles/styled-components/Survey/NewSurveyPageStyled';
import { StepButtonsContainer } from '../../../styles/styled-components/Survey/NewSurveyPageStyled';
import ScheduleAssessmentForm from './ScheduleAssessmentForm';
import { MDBBtn, MDBIcon } from 'mdbreact';
import QuestionGRAPHQL from '../../../graphql/Question';
import SelfAssessmentGRAPHQL from '../../../graphql/SelfAssessment';

import SelectGroupQA from './SelectGroupQA';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import useUnSaveChangesWarning from '../../../hooks/useUnSaveChangesWarning';
import NewSurveyPage from '../survey/NewSurveyPage';
import eventName from '../../../common/events';
import { useTracking } from 'react-tracking';
import moment from 'moment';

const qaCreateStep = {
  id: 'step2',
  name: 'group_qa',
  status: 'waiting'
};
const questionAndAnswerStep = {
  id: 'step3',
  name: 'question_answer',
  status: 'waiting'
};
const ScheduleAssessmentPage = (props) => {
  const { trackEvent } = useTracking();

  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const ACCOUNT_TYPE = localStorage.getItem(window.CONSTANT.ACCOUNT_TYPE);
  const typeOptions = [
    { value: '1', label: window.I18N('periodic_assessment') },
    { value: '2', label: window.I18N('requested_assessment') }
  ];

  const periodOptions = [
    { value: '1', label: window.I18N('annually') },
    { value: '2', label: window.I18N('half_year') }
  ];
  const STEPS = [
    {
      id: 'step0',
      name: 'fill_form',
      status: 'processing'
    },
    {
      id: 'step1',
      name: 'select_group_qa',
      status: 'waiting'
    }


  ];

  const history = useHistory();
  const location = useLocation();

  const [steps, setSteps] = useState(STEPS);
  const [currentStep, setCurrentStep] = useState({
    index: 0,
    isFirstStep: true,
    isLastStep: false,
    name: STEPS[0].name
  });
  const [showQACreateStep, setShowQACreateStep] = useState(false);

  const [scheduleAssessmentFormData, setScheduleAssessmentFormData] = useState({});
  const [scheduleAssessmentFormDate, setScheduleAssessmentDate] = useState({ startDate: new Date(), endDate: window.COMMON_DATE.addDaysFromNow(30) });
  const [scheduleAssessmentSelect, setScheduleAssessmentSelect] = useState({});
  const [scheduleAssessmentOptions, setScheduleAssessmentOptions] = useState({
    companies: [],
    influencers: []
  });
  const [groupQAData, setGroupQAData] = useState([]);
  const [selectedGroupQAData, setSelectedGroupQAData] = useState([]);

  const language = useRef();
  const scheduleAssessmentFormRef = useRef();
  const scheduleAssessmentFormSaveDataRef = useRef();
  const newSurveyPageRef = useRef();
  scheduleAssessmentFormSaveDataRef.current = {
    dataSave: scheduleAssessmentFormData,
    date: scheduleAssessmentFormDate,
    select: scheduleAssessmentSelect,
    options: scheduleAssessmentOptions,
    selectedGroups: selectedGroupQAData,
    showQACreateStep
  };

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {
      getDataCompany();
    }, 100);
  }, [props.language]);


  useEffect(() => {
    getDraftData();
  }, []);


  //update influencer select follow company id
  useEffect(() => {
    if (!scheduleAssessmentFormData.influencer || !scheduleAssessmentOptions.influencers) {
      return;
    }
    let influencer_value;
    if (window.COMMON.checkRoleIsSystem()) {
      influencer_value = scheduleAssessmentOptions.influencers.find((item) => item.value === scheduleAssessmentFormData.influencer);
    }
    else {
      influencer_value = {
        value: scheduleAssessmentFormData.influencer,
        label: '',
        code: ''
      };
    }

    setScheduleAssessmentSelect((current) => {
      return {
        ...current,
        influencer: influencer_value
      };
    });
  }, [scheduleAssessmentOptions.influencers, scheduleAssessmentFormData.influencer]);

  //fetch group qa follow company id (influencer id)
  useEffect(() => {
    if (!scheduleAssessmentSelect.influencer || !scheduleAssessmentSelect.influencer.value) {
      return;
    }
    getDataGroupQuestion(scheduleAssessmentSelect.influencer.value);

  }, [scheduleAssessmentSelect.influencer]);
  useEffect(() => {
    if (showQACreateStep === true) {
      setSteps((current) => {
        return [
          ...current,
          qaCreateStep,
          questionAndAnswerStep
        ];
      });
      if (currentStep.index === 1) {
        handleStepClick(currentStep.index + 1);
      }
    }
    else {
      setSteps((current) => { 
        const newCurrent = current.filter((item) => {
          if (item.id === qaCreateStep.id || item.id === questionAndAnswerStep.id) {
            return false;
          }
          return true;
        });
        return [...newCurrent];
      });
      if (steps.length > 2 && currentStep.index >= 2) {
        handleStepClick(1);
      }
    }

  }, [showQACreateStep]);

  useEffect(() => {
    if (scheduleAssessmentFormData.pageSurveyDraftcode) {
      const pageSurveyDraftcode = scheduleAssessmentFormData.pageSurveyDraftcode;
      const surveyDraftData = window.COMMON.getLocalJSONData({
        key: window.CONSTANT.DRAFT_SURVEYS,
        defaultValue: []
      });
      const draftData = surveyDraftData.find((item) => item.draftcode === pageSurveyDraftcode);
      if (draftData) {
        setShowQACreateStep(true);
      }

    }

  }, [scheduleAssessmentFormData.pageSurveyDraftcode]);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };


  const handleWhenRedirect = () => {
    saveDraft();
  };
  const handleWhenReLoad = () => {
    localStorage.setItem(window.CONSTANT.DRAFT_SCHEDULE_ASSESSMENT_CODE, scheduleAssessmentFormSaveDataRef.current.dataSave.draftcode);
    saveDraft();
  };

  const [WarningPrompt, setUsePrompt, setCancelUsePrompt, setCancelPromptlBeforeSubmit] = useUnSaveChangesWarning({
    message: window.I18N('MSG_CODE_078'),
    defaultIsUsePrompt: true,
    callbackWhenRedirect: handleWhenRedirect,
    callbackWhenReload: handleWhenReLoad,
    currentPath: location.pathname,
    isActive: true

  });

  const saveSurverPageDraft = () => {

  };

  const saveDraft = () => {
    const { dataSave, date, select, selectedGroups, showQACreateStep } = scheduleAssessmentFormSaveDataRef.current;
    let pageSurveyDraftcode = null;
    if (showQACreateStep === true) {
      pageSurveyDraftcode = newSurveyPageRef.current.getDraftCode();
      newSurveyPageRef.current.saveDraft();
    }

    if (pageSurveyDraftcode) {
      dataSave.pageSurveyDraftcode = pageSurveyDraftcode;
    }
    else {
      delete dataSave.pageSurveyDraftcode;
    }


    const value = {
      scheduleAssessment: {
        scheduleAssessmentFormData: dataSave,
        select,
        date,
        selectedGroupQAData: selectedGroups
      },
      draftcode: dataSave.draftcode
    };







    //add code of group qa creation

    addDraft(window.CONSTANT.DRAFT_SCHEDULE_ASSESSMENT, value, dataSave.draftcode);
  };

  const getDraftFromLocation = (key, defaultValue = []) => {
    const dataString = localStorage.getItem(key);
    let data = defaultValue;
    if (dataString) {
      data = JSON.parse(dataString);
    }
    return data;

  };
  const addDraft = (key, value, draftcode) => {
    const data = getDraftFromLocation(key, []);
    const index = data.findIndex((item) => item.draftcode === draftcode);
    if (index >= 0) {
      data[index] = value;
    }
    else {
      data.unshift(value);
    }
    localStorage.setItem(key, JSON.stringify(data));
  };


  const getDraftData = () => {

    const draftcode = localStorage.getItem(window.CONSTANT.DRAFT_SCHEDULE_ASSESSMENT_CODE);
    localStorage.removeItem(window.CONSTANT.DRAFT_SCHEDULE_ASSESSMENT_CODE);
    if (draftcode) {
      const draftDataArr = getDraftFromLocation(window.CONSTANT.DRAFT_SCHEDULE_ASSESSMENT, []);
      const draftData = draftDataArr.find((item) => item.draftcode === draftcode);
      if (draftData) {
        initDraftData(draftData);
        return;
      }
    }




    initScheduleAssessmentFormData();
  };

  const initDraftData = (draftData) => {
    const scheduleAssessment = draftData.scheduleAssessment;
    const data = scheduleAssessment.scheduleAssessmentFormData;
    data.selectedGroups = scheduleAssessment.selectedGroupQAData;
    const initSelect = scheduleAssessment.select;
    const initDate = {};

    initDate.startDate = new Date(scheduleAssessment.date.startDate);
    initDate.endDate = new Date(scheduleAssessment.date.endDate);
    if (initSelect.influencer && initSelect.influencer.value) {
      data.influencer = initSelect.influencer.value;
    }
    setScheduleAssessmentFormData((current) => {
      return {
        ...current,
        ...data
      };
    });
    setScheduleAssessmentSelect((current) => {
      return {
        ...current,
        ...initSelect
      };
    });
    setScheduleAssessmentDate((current) => {
      return {
        ...current,
        ...initDate
      };
    });
  };


  const initScheduleAssessmentFormData = () => {
    const data = {};
    const initSelect = {};
    const initDate = {};
    const initInfluencer = localStorage.getItem(window.CONSTANT.INFLUENCER_INPUT);
    localStorage.removeItem(window.CONSTANT.INFLUENCER_INPUT);
    data.name = '';
    data.code = window.COMMON.generateCode('SA');
    data.draftcode = window.COMMON.generateCode('DR');
    data.description = '';
    data.influencer = initInfluencer;
    data.createdDate = new Date();
    data.createdBy = {
      avatar: localStorage.getItem(window.CONSTANT.AVATAR),
      name: '',
      _id: localStorage.getItem(window.CONSTANT.ID)
    };
    initSelect.type = typeOptions[0];
    initSelect.period = periodOptions[0];
    initSelect.companies = [];
    initSelect.groups = [];
    initDate.startDate = new Date();
    initDate.endDate = window.COMMON_DATE.addDaysFromNow(30);
    setScheduleAssessmentFormData((current) => {
      return {
        ...current,
        ...data
      };
    });
    setScheduleAssessmentSelect((current) => {
      return {
        ...current,
        ...initSelect
      };
    });
    setScheduleAssessmentDate((current) => {
      return {
        ...current,
        ...initDate
      };
    });
  };


  const getDataCompany = async () => {
    try {
      const newOptions = {};
      if (!window.COMMON.checkRoleIsSystem()) {
        if (COMPANY_TYPE === window.CONSTANT.INFLUENCER) {
          await window.COMMON.getDataCompany();
          const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_COMPANY));
          if (dataCompany && dataCompany.length > 0) {
            newOptions.companies = window.COMMON.createDataSelectWithGroup(dataCompany, 'id', 'name', 'accountType', 'accountType');
          }
          setScheduleAssessmentOptions((current) => {
            return {
              ...current,
              ...newOptions
            };
          });
        }
        return;
      }
      await window.COMMON.getDataAllCompany();
      const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));
      if (dataCompany && dataCompany.length > 0) {
        const dataInfluencer = window.COMMON.getArrsInArrs(window.CONSTANT.INFLUENCER, dataCompany, 'type');
        const dataOrganization = dataCompany.filter(function (item) {
          return item.type !== window.CONSTANT.INFLUENCER;
        });
        const influencers = dataCompany.filter(function (item) {
          return item.type === window.CONSTANT.INFLUENCER;
        });
        newOptions.company = window.COMMON.createDataSelect(dataInfluencer, '_id', 'information.name');
        newOptions.companies = window.COMMON.createDataSelectWithGroup(dataOrganization, '_id', 'information.name', 'type', 'type');
        newOptions.influencers = window.COMMON.createDataSelect(influencers, '_id', 'information.name', 'type', 'type');
      }
      setScheduleAssessmentOptions((current) => {
        return {
          ...current,
          ...newOptions
        };
      });

    } catch (error) {
      window.COMMON.showErrorLogs('ScheduleAssessmentPage.getDataCompany');
    }
  };

  const getDataGroupQuestion = async (companyId) => {
    try {
      const params = {
        company: companyId
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_GROUP_QUESTION, params);
      let data = [];
      if (result && result.data) {
        data = window.COMMON.formatDate(result.data.getGroupQuestions, 'createdDate');
      }
      data = data.map((item, index) => {
        item.nameLanguage = window.COMMON.getValueWithLanguage(item, 'name', language.current);
        item.descriptionLanguage = window.COMMON.getValueWithLanguage(item, 'description', language.current);
        item.index = index;
        return {
          ...item
        };
      });
      if (scheduleAssessmentFormData.selectedGroups && scheduleAssessmentFormData.selectedGroups.length > 0) {
        const groupsIDs = scheduleAssessmentFormData.selectedGroups.map((item) => {
          return item._id;
        });
        const selectedData = [];
        const newData = [];
        data.forEach((item) => {
          if (groupsIDs.includes(item._id)) {
            selectedData.push(item);
          }
          else {
            newData.push(item);
          }
        });
        setSelectedGroupQAData(selectedData);
        data = newData;
      }
      setGroupQAData(data);
    }
    catch (error) {
      window.COMMON.showErrorLogs('ScheduleAssessmentPage.getDataGroupQuestion');
    }
  };

  const handleScheduleAssessmentFormChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setScheduleAssessmentFormData((current) => {
      current[name] = value;
      return { ...current };
    });
  };
  const handleScheduleAssessmentFormDatePicker = (event, attr) => {

    setScheduleAssessmentDate((current) => {
      current[attr] = event;
      return { ...current };
    });
  };

  const handleScheduleAssessmentFormSelect = (event, id, attr) => {
    setScheduleAssessmentSelect((current) => {
      window.COMMON.checkSelectValidation(id, event);
      current[attr] = event;
      return {
        ...current
      };
    });
  };


  const handleChangeStep = (indexClick) => {
    setSteps((current) => {
      const newSteps = current.map((item, index) => {
        let status = 'completed';
        if (indexClick === index) {
          status = 'processing';
        }
        else if (indexClick < index) {
          status = 'waiting';
        }
        else if (indexClick > index) {
          status = 'completed';
        }
        return {
          ...item,
          status
        };
      });
      return [
        ...newSteps
      ];
    });
    setCurrentStep({
      index: indexClick
    });
  };
  const handleStepClick = (indexClick) => {
      
    if (indexClick === currentStep.index) {
      return;
    }
    //when next click > current click
    if (indexClick > currentStep.index) {
      //handle form
      handleFormBeforeClick(indexClick);
      return;
    }

    handleChangeStep(indexClick);
  };
  const handleFormBeforeClick = (indexClick) => {
    let validResult = true;
    if (currentStep.index === 0) {
      const formValidResult = scheduleAssessmentFormRef.current.handleValidForm();
      const checkCompanies = window.COMMON.checkSelectValidation('select-companies', scheduleAssessmentSelect.companies);
      let checkInfluencer = true;
      if (window.COMMON.checkRoleIsSystem()) {
        checkInfluencer = window.COMMON.checkSelectValidation('select-influencer', scheduleAssessmentSelect.influencer);
      }
      if (!formValidResult || !checkCompanies || !checkInfluencer) {
        validResult = false;
      }
    }
    //valid survey form
    if (currentStep.index === 2) {
      validResult = newSurveyPageRef.current.validSurveyForm();
    }
    if (currentStep.index === 3) {
      validResult = newSurveyPageRef.current.validQuestionAndAnswerForm();
    }


    if (validResult === true) {
      handleChangeStep(indexClick);
    }
  };
  const handleCreateBackgroundForPaper = () => {
    if (currentStep.index === 0) {
      return '#FFFFFF';
    }
    return 'transparent';
  };

  const arrayInsert = (array, index, value) => {
    array.splice(index, 0, value);

  };

  const removeFromQASelected = (index) => {
    const group_qa = selectedGroupQAData[index];
    setSelectedGroupQAData((current) => {
      current.splice(index, 1);
      return [
        ...current
      ];
    });
    return group_qa;
  };
  const removeFromQAAll = (index) => {
    const group_qa = groupQAData[index];
    setGroupQAData((current) => {
      current.splice(index, 1);
      return [
        ...current
      ];
    });
    return group_qa;
  };

  const handleOnClickGroupQA = (index, status) => {
    let group_qa;
    if (status === 'selected') {
      group_qa = removeFromQASelected(index);
      //add to all
      setGroupQAData((current) => {
        arrayInsert(current, group_qa.index, group_qa);
        return current;
      });
    }
    else if (status === 'all') {
      group_qa = removeFromQAAll(index);
      setSelectedGroupQAData((current) => {
        current.push(group_qa);
        return current;
      });
    }
  };


  const saveScheduleAssessment = () => {
    setCancelPromptlBeforeSubmit(async () => {
      scheduleAssessmentFormData.startDate = window.COMMON_DATE.getStrDateFilter(scheduleAssessmentFormDate.startDate, -1);
      scheduleAssessmentFormData.endDate = window.COMMON_DATE.getStrDateFilter(scheduleAssessmentFormDate.endDate, 1);
      const keys = Object.keys(scheduleAssessmentSelect);
      for (let i = 0; i < keys.length; i++) {
        const element = keys[i];
        const check = element === 'groups' || element === 'companies';
        scheduleAssessmentFormData[element] = window.COMMON.getDataSelect(scheduleAssessmentSelect[element], check);
      }
      const companyId = (scheduleAssessmentSelect.influencer && scheduleAssessmentSelect.influencer.value) ? scheduleAssessmentSelect.influencer.value : COMPANY_ID;
      scheduleAssessmentFormData.company = companyId;
      const draftcode = scheduleAssessmentFormData.draftcode;
      delete scheduleAssessmentFormData.influencer;
      delete scheduleAssessmentFormData.draftcode;
      delete scheduleAssessmentFormData.createdDate;
      delete scheduleAssessmentFormData.selectedGroups;
      delete scheduleAssessmentFormData.createdBy;
      delete scheduleAssessmentFormData.pageSurveyDraftcode;
      const groups = [];
      selectedGroupQAData.forEach((item) => {
        groups.push(item._id);
      });
      scheduleAssessmentFormData.groups = groups;
      scheduleAssessmentFormData.step = 1;

      const params = {
        input: scheduleAssessmentFormData
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_ADD_SELF_ASSESSMENT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
        localStorage.setItem(window.CONSTANT.INFLUENCER_BACK, scheduleAssessmentFormData.company);
        window.COMMON.removeDraft({
          key: window.CONSTANT.DRAFT_SCHEDULE_ASSESSMENT,
          draftcode
        });

        if (showQACreateStep === true) {
          handleCancelNewGroupQA({
            draftcode:newSurveyPageRef.current.getDraftCode(),
            removeStep:false
          });
        }

        //send mail to company admin
        const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_COMPANY));
	  	const selectedCompanies = dataCompany.filter(item => scheduleAssessmentFormData.companies?.includes(item.company?._id));
        Promise.all(selectedCompanies.map(async (company) => {
          const result = await window.COMMON.sendMailNotify({
            email: company.email,
            title: window.I18N('new_self_assessment_title'),
            body: `
          <p>Kính gửi ${company.name}!</p>
          <p>Tổ chức của bạn vừa nhận được một bản tự đánh giá mới về <b>${scheduleAssessmentFormData.name}</b> vào <b>${moment().format('[ngày] DD [tháng] MM')}</b>. Vui lòng giúp chúng tôi hoàn thành nó</p>
          <p>Trân trọng,</p>
          <p>GOPY TEAM</p>
          <br></br>
          <p>Dear ${company.name}!</p>
          <p>Your organization just received a new self assessment <b>${scheduleAssessmentFormData.name}</b> on <b>${moment().format('MMMM DD')}</b> Please help us to completed it.</p>
          <p>Thank you,</p>
          <p>THE GOPY TEAM</p>`
          });
		  if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
            createTrackingEvent(eventName.SEND_MAIL);
		  }
        }));

        history.push('/self-assessment');
      }
    });
  };

  const handleSave = () => {
    if (selectedGroupQAData.length <= 0) {
      window.COMMON.showMessage('warning', 'MSG_CODE_047', window.I18N('MSG_CODE_047'));
      return;
    }
    window.COMMON.showModal('#modal-save');
    window.saveMethod = saveScheduleAssessment;
  };

  const handleNewGroupQAClick = () => {
    setShowQACreateStep(true);
  };

  const handleSaveSurveyPage = () => {
    window.COMMON.showModal('#modal-save');
    window.saveMethod = async () => {
      const validQuestionAndAnswerResult = newSurveyPageRef.current.validQuestionAndAnswerForm();
      if (validQuestionAndAnswerResult === false) return;
      const newGroupQA = await newSurveyPageRef.current.createQA();
      if (!newGroupQA) {
        window.COMMON.showMessage('error', 'MSG_CODE_048', window.I18N('MSG_CODE_048'));
        return;
      }

      newGroupQA.createdDate = window.COMMON_DATE.formatStringToDate(parseInt(newGroupQA.createdDate));
      newGroupQA.nameLanguage = window.COMMON.getValueWithLanguage(newGroupQA, 'name', language.current);
      newGroupQA.descriptionLanguage = window.COMMON.getValueWithLanguage(newGroupQA, 'description', language.current);
      newGroupQA.index = 0;
      newGroupQA.createdBy = {
        ...newGroupQA.createdBy,
        avatar: localStorage.getItem(window.CONSTANT.AVATAR)
      };
      setGroupQAData((current) => {
        current.unshift(newGroupQA);
        return [...current];
      });
      setShowQACreateStep(false);
      setScheduleAssessmentFormData((current) => {
        delete current.pageSurveyDraftcode;
        return {...current};
      });
            
    };

  };

  const isFirstStep = currentStep.index === 0;
  const isLastStep = currentStep.index === steps.length - 1;
  const isSelectGroupQAStep = currentStep.index === 1;
  const stepName = steps[currentStep.index].name;

  const handleCancelNewGroupQA = ({draftcode, removeStep = true}) => {
    window.COMMON.removeDraftSurvey({key:window.CONSTANT.DRAFT_SURVEYS,draftcode});
    if (removeStep === true) {
      setShowQACreateStep(false);
      setScheduleAssessmentFormData((current) => {
        delete current.pageSurveyDraftcode;
        return {...current};
      });
    }
  };

  return <>
    <div className="container-row">
      <NewSurveyPageSteps steps={steps} onStepClick={handleStepClick} />
      <SurveyPagePaperStyled backgroundColor={handleCreateBackgroundForPaper()}>
        <div style={{ display: currentStep.index === 0 ? 'block' : 'none' }}>
          <ScheduleAssessmentForm
            dataSave={scheduleAssessmentFormData}
            date={scheduleAssessmentFormDate}
            select={scheduleAssessmentSelect}
            options={scheduleAssessmentOptions}
            handleChange={handleScheduleAssessmentFormChange}
            handleDatePicker={handleScheduleAssessmentFormDatePicker}
            handleSelect={handleScheduleAssessmentFormSelect}
            typeOptions={typeOptions}
            periodOptions={periodOptions}
            ref={scheduleAssessmentFormRef}
          />
        </div>
        <div style={{ display: currentStep.index === 1 ? 'block' : 'none' }}>
          <SelectGroupQA
            allGroupQA={groupQAData}
            selectedGroupQA={selectedGroupQAData}
            onClick={handleOnClickGroupQA}
            onNewGroupQAClick={handleNewGroupQAClick}
            isShowAddButton={!showQACreateStep}
          />
        </div>
        {showQACreateStep === true && <div style={{ display: (currentStep.index === 2 || currentStep.index === 3) ? 'block' : 'none' }}>
          <NewSurveyPage
            initCompany={scheduleAssessmentSelect.influencer}
            surveyPageDraftCode={scheduleAssessmentFormData.pageSurveyDraftcode}
            stepName={stepName}
            mode={window.CONSTANT.ONLY_CREATE}
            onRedirect={handleWhenRedirect}
            onReload={handleWhenReLoad}
            onCancel={handleCancelNewGroupQA}
            ref={newSurveyPageRef}
          />
        </div>}
      </SurveyPagePaperStyled>
      <StepButtonsContainer isFull={(currentStep.index !== 1 && currentStep.index !== 3) ? true : false}>

        <div className='d-flex align-items-center justify-content-end w-100 mt-3'>
          {isFirstStep === false && <MDBBtn color='' className='btn-back' onClick={() => handleStepClick(currentStep.index - 1)}>
            <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('btn_back')}
          </MDBBtn>}
          {isLastStep === false && <MDBBtn color="primary" className="mr-0" type="button" onClick={() => handleStepClick(currentStep.index + 1)}>
            {window.I18N('btn_next')} <i className="fas fa-arrow-right"></i>
          </MDBBtn>}
          {isSelectGroupQAStep === true && <MDBBtn color="primary" className="mr-0" type="button" onClick={handleSave}>
            <MDBIcon fa="true" icon="save" className="fa-fw" /> {window.I18N('btn_save')}
          </MDBBtn>}
          {(showQACreateStep === true && isLastStep === true) && <MDBBtn color="primary" className="mr-0" type="button" onClick={handleSaveSurveyPage}>
            <MDBIcon fa="true" icon="save" className="fa-fw" /> {window.I18N('btn_add')}
          </MDBBtn>}
        </div>

      </StepButtonsContainer>
    </div>
    {WarningPrompt}
  </>;
};

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleAssessmentPage);