
import React, { useState, useCallback, useLayoutEffect } from 'react';
import { MDBRow, MDBCol, MDBInputGroup, MDBInput, MDBCardImage, MDBBtn, MDBIcon } from 'mdbreact';

import Modal from '../../components/Modal';
import RadioBox from '../../components/RadioBox';
import Pagination from '../../components/Pagination';

import AccountGRAPHQL from '../../../graphql/Account';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';

export default function RequestSystemAccountPage(props) {
  const { trackEvent } = useTracking();

  const types = [
    { value: 'COMPANY', label: window.I18N('company') },
    { value: 'UNION', label: window.I18N('union') },
    { value: 'INFLUENCER', label: window.I18N('influencer') }
  ];
  const [filter, setFilter] = useState({ text: '', accountType: window.CONSTANT.COMPANY });
  const [object, setObject] = useState({ index: -1, item: null, isCheckboxed: false, isCheckAll: false });
  const [pagination, setPagination] = useState({ pageSize: 30, currentPage: 0 });
  const [data, setData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [dataList, setDataList] = useState([]);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataRequestAccount();
    }, 100);
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      document.getElementById('btn-search').click();
    }
  };

  const handleFilterChange = (event) => {
    filter.text = event.target.value;
    setFilter({ ...filter });
  };

  const handleRadio = useCallback((value) => {
    filter.accountType = value;
    filter.text = '';
    setFilter({ ...filter });
    getDataRequestAccount();
    // eslint-disable-next-line
  }, [filter]);

  const handleChange = (event, index) => {
    dataList[index].isChecked = event.target.checked;
    if (!event.target.checked) {
      object.isCheckboxed = false;
      object.isCheckAll = false;
      for (let i = 0; i < data.length; i++) {
        if (data[i].isChecked) {
          object.isCheckboxed = true;
          break;
        }
      }
    } else {
      object.isCheckboxed = true;
      object.isCheckAll = true;
      for (let i = 0; i < data.length; i++) {
        if (!data[i].isChecked) {
          object.isCheckAll = false;
          break;
        }
      }
    }
    setObject(object);
    setDataList([ ...dataList ]);
  };

  const handleCheckAll = (event) => {
    object.isCheckAll = event.target.checked;
    object.isCheckboxed = object.isCheckAll && dataList.length > 0;
    for (let i = 0; i < dataList.length; i++) {
      dataList[i].isChecked = object.isCheckAll;
    }
    setObject(object);
    setDataList([ ...dataList ]);
  };

  const showItemDetails = (item) => {
    object.item = item;
    setObject({ ...object });
    window.COMMON.showModal('#modal-detail');
  };

  const getDataRequestAccount = async () => {
    try {
      const params = {
        accountType: filter.accountType
      };
      const result = await window.COMMON.query(AccountGRAPHQL.QUERY_REQUEST_ACCOUNT, params);
      let data = [];
      if (result && result.data) {
        data = result.data.findRequestAccounts;
      }
      data.forEach(item => {
        item.isChecked = false;
        item.createdDate = window.COMMON_DATE.formatDate(item.createdDate);
      });
      object.isCheckboxed = false;
      object.isCheckAll = false;
      setObject(object);
      setData(data);
      searchData(data);
    } catch (error) {
      window.COMMON.showErrorLogs('RequestSystemAccountPage.getDataRequestAccount');
    }
  };

  const searchData = (data) => {
    let dataSearch = [];
    if (filter.text) {
      data.forEach(item => {
        if (window.COMMON.getValueFromAttr(item, 'name').toLowerCase().indexOf(filter.text.toLowerCase()) > -1 
          || window.COMMON.getValueFromAttr(item, 'email').toLowerCase().indexOf(filter.text.toLowerCase()) > -1 
          || window.COMMON.getValueFromAttr(item, 'phone').toLowerCase().indexOf(filter.text.toLowerCase()) > -1) {
          dataSearch.push(item);
        }
      });
    } else {
      dataSearch = data;
    }
    setDataSearch(dataSearch);
    loadData(0, dataSearch);
  };

  const loadData = useCallback((index, items) => {
    const dataList = [];
    if (items.length > 0) {
      let check = true;
      for (let i = pagination.pageSize * index; i < pagination.pageSize * (index + 1); i++) {
        if (i > items.length - 1) {
          break;
        }
        const obj = items[i];
        if (obj) {
          dataList.push(obj);
        }
        if (!obj.isChecked) {
          check = false;
        }
      }
      object.isCheckAll = check;
    }
    pagination.currentPage = index;
    setObject(object);
    setDataList(dataList);
    setPagination({ ...pagination });
  }, [object, pagination]);

  const openAcceptAccountDialog = () => {
    window.COMMON.showModal('#modal-save');
    window.saveMethod = acceptAccount;
  };

  const openDeclineAccountDialog = () => {
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = declineAccount;
  };

  const getInputData = () => {
    const input = [];
    data.forEach(item => {
      if (item.isChecked) {
        input.push(item._id);
      }
    });
    return input;
  };

  const acceptAccount = async () => {
    try {
      const params = {
        accountIds: getInputData()
      };
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_ACCEPT_REQUEST_ACCOUNT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
        getDataRequestAccount();
        createTrackingEvent(eventName.ACCEPT_REQUEST_ACCOUNT);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestSystemAccountPage.acceptAccount');
    }
  };

  const declineAccount = async () => {
    try {
      const params = {
        accountIds: getInputData()
      };
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_DECLINE_REQUEST_ACCOUNT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataRequestAccount();
        createTrackingEvent(eventName.DECLINE_REQUEST_ACCOUNT);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestSystemAccountPage.declineAccount');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <MDBRow className="wow fadeIn animated" data-wow-delay="1s">
        <MDBCol>
          <ul className="list-group">
            <li className="list-group-item">
              <div className="d-flex align-items-center w-100">
                <MDBInput type="checkbox" id="checkbox-all" containerClass="pl-2 pt-2" filled checked={object.isCheckAll} onChange={handleCheckAll}/>
                <div className="d-inline-block ml-auto">
                  <RadioBox data={types} value={filter.accountType} className="m-0" handleChange={handleRadio}></RadioBox>
                </div>
                <div className="ml-2 mr-2" style={{ width: '350px' }}>
                  <MDBInputGroup type="text" value={filter.text} hint={window.I18N('search_organization_email_phone')} onChange={handleFilterChange} maxLength="100" pattern="\S(.*\S)?" required
                    append={
                      <span className="input-group-text cursor-pointer" id="btn-search" onClick={() => searchData(data)}>
                        <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                      </span>
                    }
                  />
                </div>
                <div>
                  <MDBBtn color="danger" disabled={!object.isCheckboxed} onClick={!object.isCheckboxed ? () => {} : openDeclineAccountDialog}>
                    <MDBIcon fa="true" icon="ban" className="fa-fw"/> {window.I18N('btn_reject')}
                  </MDBBtn>
                  <MDBBtn color="primary" className="mr-0" disabled={!object.isCheckboxed} onClick={!object.isCheckboxed ? () => {} : openAcceptAccountDialog}>
                    <MDBIcon fa="true" icon="check-circle" className="fa-fw"/> {window.I18N('btn_accept')}
                  </MDBBtn>
                </div>
              </div>
            </li>
            {
              dataList.map((item, i) => <li className="list-group-item" key={i}>
                <div className="d-flex align-items-start w-100">
                  <MDBInput type="checkbox" id={'checkbox-' + item._id} containerClass="pl-2 pt-2" filled checked={item.isChecked} onChange={(event) => handleChange(event, i)}/>
                  <div className="d-flex align-items-center w-100 pl-2 pr-2 cursor-pointer" onClick={() => showItemDetails(item)}>
                    <div className="w-100 mr-auto">
                      <MDBRow>
                        <MDBCol>
                          <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, 'avatar'))} className="image-icon rounded-circle mr-2"/>
                          <span>{window.COMMON.getValueFromAttr(item, 'name')}</span>
                        </MDBCol>
                        <MDBCol>
                          <p className="mb-0"><MDBIcon className="fa-fw mr-1" fa="true" icon="envelope"></MDBIcon>{window.COMMON.getValueFromAttr(item, 'email')}</p>
                          <p className="mb-0"><MDBIcon className="fa-fw mr-1" fa="true" icon="phone"></MDBIcon>{window.COMMON.getValueFromAttr(item, 'phone')}</p>
                          <p className="mb-0"><MDBIcon className="fa-fw mr-1" fa="true" icon="map-marker-alt"></MDBIcon>{window.COMMON.getValueFromAttr(item, 'address')}</p>
                        </MDBCol>
                        <MDBCol>
                          <p className="mb-0"><small className="grey-text">{window.I18N('tax_id')}: </small><small>{window.COMMON.getValueFromAttr(item, 'taxCode')}</small></p>
                          <p className="mb-0"><small className="grey-text">{window.I18N('representative_name')}: </small><small>{window.COMMON.getValueFromAttr(item, 'representative')}</small></p>
                          <p className="mb-0"><small className="grey-text">{window.I18N('job_title')}: </small><small>{window.COMMON.getValueFromAttr(item, 'representativePosition')}</small></p>
                          <p className="mb-0">
                            <MDBIcon className="fa-fw mr-1 grey-text" fa="true" icon="calendar-alt"></MDBIcon><small> {window.I18N('request_date')}: </small><small>{window.COMMON.getValueFromAttr(item, 'createdDate')}</small>
                          </p>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  </div>
                </div>
              </li>)
            }
            <li className="list-group-item">
              <Pagination total={dataSearch.length} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={(index) => loadData(index, dataSearch)}></Pagination>
            </li>
          </ul>
        </MDBCol>
      </MDBRow>

      <Modal id="modal-detail" className="modal-lg" title={window.COMMON.getValueFromAttr(object.item, 'name')} hideSave={true}>
        <MDBRow>
          <MDBCol md="3" sm="4" xs="12" className="text-center">
            <MDBCardImage cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(object.item, 'avatar'))} className="image-logo"/>
          </MDBCol>
          <MDBCol md="9" sm="8" xs="12">
            <table className="table table-view mb-0">
              { object.item === null ? <tbody></tbody> : <tbody>
                <tr>
                  <td width="30%">{window.I18N('organization_name')}</td>
                  <td width="70%">{object.item.name}</td>
                </tr>
                <tr>
                  <td>{window.I18N('email')}</td>
                  <td>{object.item.email}</td>
                </tr>
                <tr>
                  <td>{window.I18N('phone_number')}</td>
                  <td>{object.item.phone}</td>
                </tr>
                <tr>
                  <td>{window.I18N('country')}</td>
                  <td>{window.COMMON.getValueFromAttr(object.item, 'nation.name')}</td>
                </tr>
                <tr>
                  <td>{window.I18N('state_city')}</td>
                  <td>{window.COMMON.getValueFromAttr(object.item, 'state.name')}</td>
                </tr>
                <tr>
                  <td>{window.I18N('city_district')}</td>
                  <td>{window.COMMON.getValueFromAttr(object.item, 'city.name')}</td>
                </tr>
                <tr>
                  <td>{window.I18N('address')}</td>
                  <td>{object.item.address}</td>
                </tr>
                <tr>
                  <td>{window.I18N('tax_id')}</td>
                  <td>{object.item.taxCode}</td>
                </tr>
                <tr>
                  <td>{window.I18N('representative_name')}</td>
                  <td>{object.item.representative}</td>
                </tr>
                <tr>
                  <td>{window.I18N('job_title')}</td>
                  <td>{object.item.representativePosition}</td>
                </tr>
                { 
                  filter.accountType !== window.CONSTANT.INFLUENCER ? <>
                    <tr>
                      <td>{window.I18N('organization_type')}</td>
                      <td>{window.COMMON.getValueFromAttr(object.item, 'companyType.value')}</td>
                    </tr>
                    <tr>
                      <td>{window.I18N('headcounts')}</td>
                      <td>{window.COMMON.getValueFromAttr(object.item, 'companySize.value')}</td>
                    </tr>
                    <tr>
                      <td>{window.I18N('industry')}</td>
                      <td>{window.COMMON.getValueFromAttr(object.item, 'companyIndustry.value')}</td>
                    </tr>
                  </> : <></>
                }
              </tbody>
              }
            </table>
          </MDBCol>
        </MDBRow>
      </Modal>
    </div>
  );
}