import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { Add, CheckCircle, RadioButtonUnchecked, Remove } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
        flexDirection: 'column',
        flexWrap: 'wrap',
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: '#ffffff',
        padding: '12px 24px',
		position: 'relative',
        borderRadius: '8px'
	},
    adjustButtonContainer: {
        border: '1px solid #8457FF',
        borderRadius: '6px'
    },
    adjustButtonItem: {
        minWidth: '20px'
    },
    totalContainer: {
        borderTop: '1px solid #8457FF',
        borderBottom: '1px solid #8457FF'
    },
    paymentTypeContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paymentType: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #8457FF',
        borderRadius: '6px',
        cursor: 'pointer'
    },
    checkedType: {
        marginRight: '10px',
        fontSize: '18px',
        color: '#8457FF'
    }
}));

const GROUPS_GOPIES_TAB = 1
const STEP_GOPIES = 10
const EACH_GOPIES_TAB = 2
const MIN_QUANTITY_COIN = 1
const MAX_QUANTITY_COIN = 100000

const PaymentConfirm = ({data, dataConfigs = {}, language}) => {
    const classes = useStyles()
    const [dataPurchase, setDataPurchase] = useState({...data, indexActive: GROUPS_GOPIES_TAB})

    const handleSelectedInputGopies = (e, index) => {
        e.stopPropagation()
        setDataPurchase((prev) => ({
            ...prev, 
            indexActive: index
        })) 
    }
    
    const handleDecreaseQuantityGopies = (e) => {
        e.stopPropagation()
        setDataPurchase((prev) => {
            const gCoins = parseInt((prev.gCoins - STEP_GOPIES <= 0) ? STEP_GOPIES : prev.gCoins - STEP_GOPIES)
            if(prev.gCoins - STEP_GOPIES <= 0){
                window.COMMON.showMessage('warning', window.I18N('cannot_decrease'), window.I18N('small_gopies'))
            }
            return {
                ...prev, 
                gCoins, 
                price: dataConfigs.EXCHANGE_RATE_GOPIES * gCoins
            }
        }) 
    }

    const handleIncreaseQuantityGopies = (e) => {
        e.stopPropagation()
        setDataPurchase((prev) => {
            const isMaxCoin = (prev.gCoins + STEP_GOPIES) >= MAX_QUANTITY_COIN
            const gCoins = parseInt(isMaxCoin ? MAX_QUANTITY_COIN : prev.gCoins + STEP_GOPIES)
            if(isMaxCoin){
                window.COMMON.showMessage('warning', 'OVER_QUANTITY_GOPIES', window.I18N('over_gopies'))
            }
            return {
                ...prev, 
                gCoins, 
                price: dataConfigs.EXCHANGE_RATE_GOPIES * gCoins
            }
        }) 
    }

    const handleQuantityGopies = (e) => {
        //pattern except special char, included (.)
        const regex = /[^\d]/g
        if(regex.test(e.target.value)){
            return
        }    
        const coins = parseInt(e.target.value)
        if(coins > MAX_QUANTITY_COIN){
            window.COMMON.showMessage('warning', 'OVER_QUANTITY_GOPIES', `${window.I18N('over_gopies')}`);
            return
        } 
        if(!coins || coins < MIN_QUANTITY_COIN){
            setDataPurchase((prev) => ({
                ...prev, 
                gCoins: MIN_QUANTITY_COIN, 
                price: dataConfigs.EXCHANGE_RATE_GOPIES
            })) 
        }else{
            setDataPurchase((prev) => ({
                ...prev, 
                gCoins: coins, 
                price: dataConfigs.EXCHANGE_RATE_GOPIES * coins
            })) 
        }
    }  
    
    const generatePaymentURL = (searchParams) => {
        const searchParamsURL = []
        const listSearchParams = Object.entries(searchParams)
        for(let [key, value] of listSearchParams){
            searchParamsURL.push(`${key}=${value}`)
        }
        return `${process.env.REACT_APP_URL_PAYMENT_API}/checkout?${searchParamsURL.join('&')}`
    }

    const handleRedirectToOnePay = () => {
        window.showLoading()
        const searchParams = {
            orderCoin: dataPurchase.gCoins,
            returnUrl: window.location.origin + '/payment-result',
            locale: language.code === 'VI' ? 'vn' : 'en',
            userId: localStorage.getItem('id')
        }
        const redirectToPaymentGateURL = generatePaymentURL(searchParams)
        setTimeout(() => {
            window.hideLoading()
            window.location.replace(redirectToPaymentGateURL)
        },500) 
    }

    return (
        <div style={{ width: '100%' }}>
            <div className={`${classes.container}`}>
                <Typography className="font-weight-bold" variant="h6">{window.I18N('make_purchase')}</Typography>
                <div className="mt-3" style={{ width: '100%' }}>
                    <Grid container className="flex-center cursor-pointer" onClick={(e) => handleSelectedInputGopies(e, GROUPS_GOPIES_TAB)} style={{opacity: dataPurchase.indexActive === GROUPS_GOPIES_TAB ? 1 : 0.5}}>
                        <Grid item xs={4} md={4} className="flex-center justify-content-start">
                            {dataPurchase.indexActive === GROUPS_GOPIES_TAB ? <CheckCircle className={classes.checkedType}/> : <RadioButtonUnchecked className={classes.checkedType}/>}    
                            <img height="20px" className="mr-2 pointer-events-none" src={window.CONSTANT.G_COIN} />
                            <span className="select-none">10 Gopies</span>    
                        </Grid>
                        <Grid item xs={4} md={4} className="flex-center">
                            <div className={`flex-center ${classes.adjustButtonContainer}`}>
                                <Button className={`font-bold p-2 ${classes.adjustButtonItem}`} onClick={handleDecreaseQuantityGopies} disabled={dataPurchase.indexActive === EACH_GOPIES_TAB}>
                                    <Remove />
                                </Button>
                                <span className="mx-3">{dataPurchase.indexActive !== 1 ? 0 : dataPurchase.gCoins/10}</span>
                                <Button className={`font-bold p-2 ${classes.adjustButtonItem}`} onClick={handleIncreaseQuantityGopies} disabled={dataPurchase.indexActive === EACH_GOPIES_TAB}>
                                    <Add />
                                </Button> 
                            </div>
                        </Grid>
                        <Grid item xs={4} md={4} className="flex-center">
                        {`${dataPurchase.indexActive !== GROUPS_GOPIES_TAB ? 0 : dataPurchase.price?.toLocaleString('vi', {currency : 'VND'})} VND`}    
                        </Grid>
                    </Grid>
                    <Grid container className="flex items-center mt-5 cursor-pointer"  onClick={(e) => handleSelectedInputGopies(e, EACH_GOPIES_TAB)} style={{opacity: dataPurchase.indexActive === EACH_GOPIES_TAB ? 1 : 0.5}}>
                        <Grid item xs={4} md={4} className="flex-center justify-content-start">
                            {dataPurchase.indexActive === EACH_GOPIES_TAB ? <CheckCircle className={classes.checkedType}/> : <RadioButtonUnchecked className={classes.checkedType}/>} 
                            <img height="20px" className="mr-2 pointer-events-none" src={window.CONSTANT.G_COIN} />
                            <span className="select-none">{window.I18N('others_gopies')}</span>    
                        </Grid>
                        <Grid item xs={4} md={4} className="flex-center">
                            <div className={`flex-center`} >
                                <TextField 
                                    value={dataPurchase.indexActive !== EACH_GOPIES_TAB ? 0 : dataPurchase.gCoins}
                                    onChange={handleQuantityGopies}
                                    disabled={dataPurchase.indexActive !== EACH_GOPIES_TAB} 
                                    InputProps={{
                                        inputProps: {
                                            style: { 
                                                textAlign: 'center',
                                                padding: '10px' 
                                            },
                                        }
                                    }}
                                    variant='outlined'
                                    style={{width: '95%'}}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4} md={4} className="d-flex align-items-center justify-content-center">
                            <div className="w-full">
                                {`${dataPurchase.indexActive !== EACH_GOPIES_TAB ? 0 : dataPurchase.price?.toLocaleString('vi', {currency : 'VND'})} VND`}  
                            </div>   
                        </Grid>
                    </Grid>
                </div>
                <div className={`my-4 py-4 ${classes.totalContainer} p-4`} style={{ width: '100%' }}>
                    <Grid container>
                        <Grid item xs={6} md={6} className="flex-center font-weight-bold">
                            {window.I18N('total_gopies')}
                        </Grid>
                        <Grid item xs={6} md={6} className="flex-center font-weight-bold">
                            {`${window.COMMON.formatCurrency(dataPurchase.gCoins, { currency : 'VND' })} Gopies`}
                        </Grid>
                    </Grid>
                    <Grid container className="mt-3">
                        <Grid item xs={6} md={6} className="flex-center font-weight-bold">
                            {window.I18N('total_amount')}
                        </Grid>
                        <Grid item xs={6} md={6} className="flex-center font-weight-bold">
                            {`${window.COMMON.formatCurrency(dataPurchase.price,{ currency : 'VND' })} VND`}
                        </Grid>
                    </Grid> 
                </div>
               <div className="flex-center w-full mt-20">
                    <Button variant="contained" color="primary" onClick={handleRedirectToOnePay}>
                        {window.I18N('confirm_payment')}
                    </Button>
               </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    language: state.language.value
  });
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentConfirm)