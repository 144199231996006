import gql from 'graphql-tag';

const MUTATION_SEND_MAIL = gql`
  mutation SendMail(
    $receiver: [String]
    $email: String!
    $title: String
    $text: String
    $body: String
    $type: String!
  ) {
    sendMail(
      input: {
        receiver: $receiver
        email: $email
        title: $title
        text: $text
        body: $body
        type: $type
      }
    ) {
      _id
      receiver
      title
      text
      body
      createdDate
      createdBy {
        _id
        name
      }
    }
  }
`;

const MUTATION_SEND_GRID_MAIL = gql `
  mutation SendGridMail (
    $input: SendGridEmailInput
  ){
    sendGridEmail(input: $input)
  }
`;

const MailGRAPHQL = {
  MUTATION_SEND_MAIL,
  MUTATION_SEND_GRID_MAIL
};

export default MailGRAPHQL;
