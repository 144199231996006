const Message = {
  MSG_CODE_001: 'Create new record successfully!',
  MSG_CODE_002: 'Update record successfully!',
  MSG_CODE_003: 'Delete record successfully!',
  MSG_CODE_004: 'Create new record unsuccessfully!',
  MSG_CODE_005: 'Update record unsuccessfully!',
  MSG_CODE_006: 'Delete record unsuccessfully!',
  MSG_CODE_007: 'This record already exists in the system!',
  MSG_CODE_008: 'You do not have permission to access or performance this record!',
  MSG_CODE_009: 'Cannot connect to the server. Please try again later or check your internet.',
  MSG_CODE_010: 'Please input required fields to be marked * and then try again.',
  MSG_CODE_011: 'Start Date must be less than End Date.',
  MSG_CODE_012: 'Please select at least an item in list.',
  MSG_CODE_013: 'This schedule was running. Please stop it and try again!',
  MSG_CODE_014: 'Login successfully. Welcome to GOPY system ...',
  MSG_CODE_015: 'Login unsuccessfully. Please input valid Email or Phone Number and password.',
  MSG_CODE_016: 'Import data file successfully!',
  MSG_CODE_017: 'Import data file have error!',
  MSG_CODE_018: 'Please input text to search data ...',
  MSG_CODE_019: 'Data not found in system ...',
  MSG_CODE_020: 'Data have error in below rows : ',
  MSG_CODE_021: 'No data can import in system ... ',
  MSG_CODE_022: 'Please input content to add data.',
  MSG_CODE_023: 'Don\'t have data to export file. Please choose other filter to try again.',
  MSG_CODE_024: 'Please choose at least a option to import data.',
  MSG_CODE_025: 'Not found this file to download. Please check data again!',
  MSG_CODE_026: 'Reset password successfully. Please check your mail inbox and then change password when login successfully!',
  MSG_CODE_027: 'Reset password unsuccessfully. Please input valid email.',
  MSG_CODE_028: 'Your account is not existed in system. Please input valid email.',
  MSG_CODE_029: '* This field is required.',
  MSG_CODE_030: '* This field is required and please input valid email.',
  MSG_CODE_031: 'Re password must be matched with password. Please check data again!',
  MSG_CODE_032: 'Update status successfully!',
  MSG_CODE_033: 'Approve record successfully!',
  MSG_CODE_034: 'Decline record successfully!',
  MSG_CODE_035: 'Update status unsuccessfully!',
  MSG_CODE_036: 'Approve record unsuccessfully!',
  MSG_CODE_037: 'Decline record unsuccessfully!',
  MSG_CODE_038: 'New password must be not matched with current password. Please check data again!',
  MSG_CODE_039: 'This field is required and password must be at least 8 characters.',
  MSG_CODE_040: 'Please fill facility information form and submit again.',
  MSG_CODE_041: 'Total size of documents must be less than 500MB.',
  MSG_CODE_042: 'Please choose file to submit form.',
  MSG_CODE_043: 'You can only search for data within 30 days. Please try again.',
  MSG_CODE_044: 'This account is existed in system. Please input another email.',
  MSG_CODE_045: 'Note*: Please create group QA first and then create content or schedule.',
  MSG_CODE_046: 'You cannot create new post in the past. Please try again.',
  MSG_CODE_047: 'Please choose at least a group QA.',
  MSG_CODE_048: 'Have error when create, please try again.',
  MSG_CODE_049: 'Please choose at least a company',
  MSG_CODE_069: 'Total size of file upload must be less than or equals ',
  MSG_CODE_070: 'Export data file successfully!',
  MSG_CODE_071: 'Export data file unsuccessfully!'
};

export default Message;
