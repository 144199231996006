import React, { useState, useLayoutEffect } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { MDBCol, MDBInput, MDBCardImage, MDBBtn, MDBIcon } from 'mdbreact';

import i18n from '../../i18n/language';
import { setLanguage } from '../../redux/action';

import Logo from '../../styles/images/bg/logo_white.png';

import LanguageGRAPHQL from '../../graphql/Language';
import LoginGRAPHQL from '../../graphql/Login';
import eventName from '../../common/events';
import { useTracking } from 'react-tracking';

function ForgotPasswordPage(props) {
  const { trackEvent } = useTracking();
  const history = useHistory();
  const [dataLanguage, setDataLanguage] = useState([]);
  const [dataSave, setDataSave] = useState({
    username: ''
  });

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataLanguage();
    }, 100);
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line
  }, []);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleChange = (event) => {
    dataSave[event.target.id] = event.target.value;
    setDataSave({ ...dataSave });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      resetPassword(e);
    }
  };

  const getDataLanguage = async () => {
    try {
      const params = {
        status: [true]
      };
      const result = await window.COMMON.query(LanguageGRAPHQL.QUERY_LANGUAGE, params);
      if (result && result.data) {
        const dataLanguage = result.data.getLanguages;
        const cache = localStorage.getItem(window.CONSTANT.LANGUAGE);
        if (window.COMMON.checkValue(cache)) {
          props.setLanguage(JSON.parse(cache));
          i18n.changeLanguage(JSON.parse(cache).code);
        } else {
          const language = window.COMMON.getObjectInArrs(window.CONSTANT.LANGUAGE_DEFAULT, dataLanguage, 'code');
          props.setLanguage(language);
          i18n.changeLanguage(language.code);
        }
        setDataLanguage(dataLanguage);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('Header.getDataLanguage');
    }
  };

  const changeLanguage = (item) => {
    if (item.code === props.language.code) {
      return;
    }
    props.setLanguage(item);
    i18n.changeLanguage(item.code);
    localStorage.setItem(window.CONSTANT.LANGUAGE, JSON.stringify(item));
  };

  const resetPassword = async (event) => {
    const checkForm = window.COMMON.checkFormValidation('form', event);
    if (!checkForm) {
      return;
    }
    const params = {
      username: dataSave.username.toLowerCase()
    };
    const result = await window.COMMON.mutationAuth(LoginGRAPHQL.MUTATION_FORGOT_PASSWORD, params);
    if (result && result.data) {
      if (result.data.forgotPasswordAccount > 0) {
        createTrackingEvent(eventName.FORGOT_PASSWORD);
        window.COMMON.showMessage('success', 'MSG_CODE_026', window.I18N('MSG_CODE_026'));
        setTimeout(() => {
          history.push('/login');
        }, 2000);
      } else if (result.data.forgotPasswordAccount === -1) {
        window.COMMON.showMessage('error', 'MSG_CODE_028', window.I18N('MSG_CODE_028'));
      } else {
        window.COMMON.showMessage('error', 'MSG_CODE_027', window.I18N('MSG_CODE_027'));
      }
    } else {
      window.COMMON.showMessage('error', 'MSG_CODE_027', window.I18N('MSG_CODE_027'));
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="height-100 m-0 container-bg-login">
      <div className="container-title d-flex justify-content-center">
        <MDBCol sm="6" className="container-header-login wow fadeInDown animated pt-5" data-wow-delay="1s">
          <div className="d-flex align-items-center">
            <img src={Logo} alt="" />
            <span className="white-text font-weight-bold ml-2">GOPY</span>
          </div>
          <h5 className="white-text font-weight-bold mt-3">{window.I18N('forgot_password')}</h5>
          <div className="container-language-login">
            <NavLink to="#" id="dropdown-language" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-boundary="viewport">
              <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(props.language && props.language.icon ? props.language.icon : '')} className="image-icon"/>
            </NavLink>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-language" data-boundary="viewport">
              {
                dataLanguage.map((item, i) => <div key={i} className="dropdown-item" onClick={() => changeLanguage(item)}>
                  <img src={window.COMMON.setValueImage(item.icon)} alt="" className="image-icon mr-2"></img> {item.name}
                </div>)
              }
            </div>
          </div>
        </MDBCol>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <MDBCol sm="6" className="container-login wow fadeInRight animated" data-wow-delay="1s">
          <div className="container-login-title d-flex justify-content-center align-items-center text-center">
            <h5 className="m-0 font-weight-bold">{window.I18N('greeting')}</h5>
          </div>
          <form id="form" className="form-login needs-validation" noValidate>
            <MDBInput className='text-lowercase' outline label={window.I18N('email')} icon="envelope" group type="email" id="username" error="wrong" success="right" value={dataSave.username} onChange={handleChange} maxLength="200" pattern="\S(.*\S)?" required>
              <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
            </MDBInput>
            <div className="text-right">
              <NavLink exact={true} to="/login" className="btn Ripple-parent btn-outline">
                <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('go_to_login')}
              </NavLink>
              <MDBBtn outline color="primary" className="mr-0" onClick={resetPassword}>
                <MDBIcon fa="true" icon="unlock" className="fa-fw" /> {window.I18N('reset_password')}
              </MDBBtn>
            </div>
          </form>
        </MDBCol>
        <nav className="navbar-footer-login mt-auto wow fadeInUp animated" data-wow-delay="1s">
          <ul className="">
            <li className="nav-item">
              <a href="https://gopy.io/term-and-condition" className="nav-link"> {window.I18N('terms')} </a>
            </li>
            <li className="nav-item">
              <a href="https://gopy.io/datapolicy" className="nav-link"> {window.I18N('privacy_policy')} </a>
            </li>
            <li className="nav-item">
              <a href="https://gopy.io/cookie-policy" className="nav-link"> Cookies </a>
            </li>
            <li className="nav-item">
              <a href="https://gopy.io" className="nav-link"> © 2019 - {new Date().getFullYear()} <b> CSR TECH JSC </b> </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = { setLanguage };

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);