import React, { useState, useCallback, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, NavLink } from 'react-router-dom';
import Select from 'react-select';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { MDBCol, MDBInput, MDBCardImage, MDBBtn, MDBIcon } from 'mdbreact';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { StepConnector, Stepper, Step, StepLabel, Grid } from '@material-ui/core';
import { Person, Business, DoneAll } from '@material-ui/icons';

import i18n from '../../i18n/language';
import { setLanguage } from '../../redux/action';

import Logo from '../../styles/images/bg/logo_white.png';

import RadioBox from '../components/RadioBox';

import LanguageGRAPHQL from '../../graphql/Language';
import MetadataGRAPHQL from '../../graphql/Metadata';
import LoginGRAPHQL from '../../graphql/Login';
import RoleGRAPHQL from '../../graphql/Role';
import { useTracking } from 'react-tracking';
import eventName from '../../common/events';

import googlePlayBanner from '../../styles/images/gplay-banner.png';
import googlePlayQr from '../../styles/images/gplay-qr.png';
import appstoreBanner from '../../styles/images/appstore-banner.png';
import appstoreQr from '../../styles/images/appstore-qr.png';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 16
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1
  }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 36,
    height: 36,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  active: {
    backgroundImage:'linear-gradient( 136deg, rgb(32 23 169) 0%, rgb(128 13 238) 50%, rgb(18 2 37) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  },
  completed: {
    backgroundImage: 'linear-gradient( 136deg, rgb(32 23 169) 0%, rgb(198 173 222) 50%, rgb(51 4 102) 100%)'
  }
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <Person />,
    2: <Business />,
    3: <DoneAll />
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node
};

function RegisterPage(props) {
  const { trackEvent } = useTracking();

  const history = useHistory();
  const steps = [window.I18N('account_information'), window.I18N('organization_information'), window.I18N('representative_information')];
  const [activeStep, setActiveStep] = React.useState(0);
  const [object, setObject] = useState({ index: -1, accountType: window.CONSTANT.COMPANY, repassword: '' });
  const [dataLanguage, setDataLanguage] = useState([]);
  const [dataRole, setDataRole] = useState([]);
  const [dataSave, setDataSave] = useState({ 
    name: '',
    password: '',
    email: '',
    phone: '',
    website: '', 
    address: '', 
    zipcode: '',
    representative: '', 
    representativePosition: '', 
    representativeEmail: '', 
    representativePhone: ''
  });
  const [select, setSelect] = useState({});
  const [options, setOptions] = useState({
    companyType: [],
    companySize: [],
    companyIndustry: [],
    nation: [],
    state: [],
    city: []
  });

  useLayoutEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    setTimeout(() => {
      getDataLanguage();
      getDataMetadata();
      getDataLocationOptions('-1', '-1');
      getDataRole();
    }, 100);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line
  }, []);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      document.getElementById('btn-next').click();
    }
  };

  const handleNext = async (event) => {
    if (await openSaveAccountDialog(event)) {
      if (activeStep < steps.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (event) => {
    if (event.target.name === 'repassword') {
      object.repassword = event.target.value;
      setObject({ ...object });
    } else {
      dataSave[event.target.name] = event.target.value;
      setDataSave({ ...dataSave });
    }
  };

  const handleRadio = useCallback((value) => {
    object.accountType = value;
    if (value !== window.CONSTANT.INFLUENCER) {
      select.companyType = options.companyType && options.companyType.length > 0 ? options.companyType[0] : null;
      select.companySize = options.companySize && options.companySize.length > 0 ? options.companySize[0] : null;
      select.companyIndustry = options.companyIndustry && options.companyIndustry.length > 0 ? options.companyIndustry[0] : null;
    } else {
      select.companyType = null;
      select.companySize = null;
      select.companyIndustry = null;
    }
    setSelect(select);
    setObject({ ...object });
  }, [select, options, object]);

  const handleSelect = (event, id, attr) => {
    window.COMMON.checkSelectValidation(id, event);
    select[attr] = event;
    if (attr === 'nation' || attr === 'state') {
      getDataLocationOptions(attr, event.code);
    } else {
      setSelect({ ...select }); 
    }
  };

  const getDataLanguage = async () => {
    try {
      const params = {
        status: [true]
      };
      const result = await window.COMMON.query(LanguageGRAPHQL.QUERY_LANGUAGE, params);
      if (result && result.data) {
        const dataLanguage = result.data.getLanguages;
        const cache = localStorage.getItem(window.CONSTANT.LANGUAGE);
        if (window.COMMON.checkValue(cache)) {
          props.setLanguage(JSON.parse(cache));
          i18n.changeLanguage(JSON.parse(cache).code);
        } else {
          const language = window.COMMON.getObjectInArrs(window.CONSTANT.LANGUAGE_DEFAULT, dataLanguage, 'code');
          props.setLanguage(language);
          i18n.changeLanguage(language.code);
        }
        setDataLanguage(dataLanguage);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('Header.getDataLanguage');
    }
  };

  const changeLanguage = (item) => {
    if (item.code === props.language.code) {
      return;
    }
    props.setLanguage(item);
    i18n.changeLanguage(item.code);
    localStorage.setItem(window.CONSTANT.LANGUAGE, JSON.stringify(item));
  };

  const getDataMetadata = async () => {
    try {
      const params = {
        type: ['COMPANY_TYPE', 'COMPANY_SIZE', 'COMPANY_INDUSTRY']
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_SHORT_METADATA, params, false);
      if (result && result.data) {
        const data = result.data.getMetadatas;
        const dataType = window.COMMON.getArrsInArrs('COMPANY_TYPE', data, 'type');
        const dataSize = window.COMMON.getArrsInArrs('COMPANY_SIZE', data, 'type');
        const dataIndustry = window.COMMON.getArrsInArrs('COMPANY_INDUSTRY', data, 'type');
        options.companyType = window.COMMON.createDataSelect(dataType, '_id', 'value');
        options.companySize = window.COMMON.createDataSelect(dataSize, '_id', 'value');
        options.companyIndustry = window.COMMON.createDataSelect(dataIndustry, '_id', 'value');
        select.companyType = options.companyType && options.companyType.length > 0 ? options.companyType[0] : null;
        select.companySize = options.companySize && options.companySize.length > 0 ? options.companySize[0] : null;
        select.companyIndustry = options.companyIndustry && options.companyIndustry.length > 0 ? options.companyIndustry[0] : null;
        setOptions(options);
        setSelect(select);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RegisterPage.getDataMetadata');
    }
  };

  const getDataLocationOptions = async (attr, parent) => {
    if (attr === '-1') {
      options.nation = await window.COMMON.getDataLocation(parent);
      options.state = [];
      options.city = [];
      select.nation = null;
      select.state = null;
      select.city = null;
    } else if (attr === 'nation') {
      options.state = await window.COMMON.getDataLocation(parent);
      options.city = [];
      select.state = null;
      select.city = null;
    } else if (attr === 'state') {
      options.city = await window.COMMON.getDataLocation(parent);
      select.city = null;
    }
    setOptions(options);
    setSelect({ ...select });
  };

  const getDataRole = async () => {
    try {
      const result = await window.COMMON.query(RoleGRAPHQL.QUERY_PUBLIC_ROLE, null);
      const data = [];
      if (result && result.data) {
        result.data.getPublicRoles.forEach(element => {
          data.push({
            _id: element._id,
            value: element.code,
            label: element.name
          });
        });
      }
      data.push({
          value: "INDIVIDUAL",
          label: "individual"
      })
      setDataRole(data);
    } catch (error) {
      window.COMMON.showErrorLogs('RegisterPage.getDataRole');
    }
  };

  const openSaveAccountDialog = async (event) => {
    if (activeStep === 0) {
      const checkForm = window.COMMON.checkFormValidation('form-1', event);
      if (object.accountType === window.CONSTANT.COMPANY || object.accountType === window.CONSTANT.UNION) {
        const checkType = window.COMMON.checkSelectValidation('select-company-type', select.companyType);
        const checkSize = window.COMMON.checkSelectValidation('select-company-size', select.companySize);
        const checkIndustry = window.COMMON.checkSelectValidation('select-company-industry', select.companyIndustry);
        if (!checkForm || !checkType || !checkSize || !checkIndustry) {
          return false;
        } else {
          if (!checkForm) {
            return false;
          }
        }
      }
      if (dataSave.password !== object.repassword) {
        window.COMMON.showMessage('warning', 'MSG_CODE_031', window.I18N('MSG_CODE_031'));
        return false;
      }
      try {
        const params = {
          username: dataSave.email.toLowerCase()
        };
        const result = await window.COMMON.mutationAuth(LoginGRAPHQL.MUTATION_CHECK_ACCOUNT, params);
        if (result && result.data && result.data.checkExistAccount < 0) {
          window.COMMON.showMessage('warning', 'MSG_CODE_044', window.I18N('MSG_CODE_044'));
          return false;
        }
        createTrackingEvent(eventName.CHECK_ACCOUNT);
      } catch (error) {
        window.COMMON.showErrorLogs('RegisterPage.openSaveAccountDialog');
      }
    } else if (activeStep === 1) {
      const checkForm = window.COMMON.checkFormValidation('form-2', event);
      const checkNation = window.COMMON.checkSelectValidation('select-nation', select.nation);
      const checkState = window.COMMON.checkSelectValidation('select-state', select.state);
      const checkCity = window.COMMON.checkSelectValidation('select-city', select.city);
      if (!checkForm || !checkNation || !checkState || !checkCity) {
        return false;
      }
    } else if (activeStep === 2) {
      const checkForm = window.COMMON.checkFormValidation('form-3', event);
      if (!checkForm) {
        return false;
      }
      window.COMMON.showModal('#modal-save');
      window.saveMethod = createAccount;
    }
    return true;
  };

  const createAccount = async () => {
    try {
      const role = window.COMMON.getObjectInArrs(object.accountType, dataRole, 'value');
      const params = {
        input: {
          role: role ? role._id : null,
          accountType: object.accountType
        }
      };
      const keys = Object.keys(select);
      for (let i = 0; i < keys.length; i++) {
        const element = keys[i];
        dataSave[element] = window.COMMON.getDataSelect(select[element], false);
      }
      dataSave.email = dataSave.email.toLowerCase()
      dataSave.representativeEmail = dataSave.representativeEmail?.toLowerCase()
      params.input.company = dataSave;
      const result = await window.COMMON.mutationAuth(LoginGRAPHQL.MUTATION_REGISTER_ACCOUNT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
        createTrackingEvent(eventName.REGISTER_ACCOUNT);
        setTimeout(() => {
          history.push('/login');
        }, 2000);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RegisterPage.createAccount');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="height-100 m-0 container-bg-login">
      <div className="container-title d-flex justify-content-center">
        <MDBCol sm="6" className="container-header-login wow fadeInDown animated pt-5" data-wow-delay="1s">
          <div className="d-flex align-items-center">
            <img src={Logo} alt="" />
            <span className="white-text font-weight-bold ml-2">GOPY</span>
          </div>
          <h5 className="white-text font-weight-bold mt-3">{window.I18N('register_account')}</h5>
          <div className="container-language-login">
            <NavLink to="#" id="dropdown-language" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-boundary="viewport">
              <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(props.language && props.language.icon ? props.language.icon : '')} className="image-icon"/>
            </NavLink>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-language" data-boundary="viewport">
              {
                dataLanguage.map((item, i) => <div key={i} className="dropdown-item" onClick={() => changeLanguage(item)}>
                  <img src={window.COMMON.setValueImage(item.icon)} alt="" className="image-icon mr-2"></img> {item.name}
                </div>)
              }
            </div>
          </div>
        </MDBCol>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <MDBCol sm="6" className="container-login wow fadeInRight animated" data-wow-delay="1s">
          <div className="container-login-title d-flex justify-content-center align-items-center text-center">
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />} className="bg-transparent p-0">
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          <div className="form-login">
            {
              activeStep === 0 ? (
                <form id="form-1" className="needs-validation" noValidate>
                  <p className="mb-2"><small className="font-weight-bold">{window.I18N('sign_up_as')}</small></p>
                  <RadioBox data={dataRole} value={object.accountType} handleChange={handleRadio} language={i18n.language}></RadioBox>
                  {object.accountType === window.CONSTANT.INDIVIDUAL ?
                    <Grid container className='py-3' >
                      <Grid item xs={6} className="text-center"  >
                        <a href='https://play.google.com/store/apps/details?id=com.csr.csr_app&hl=vi&gl=US'>
                          <img width="55%" alt='Get on Google Play' src={googlePlayBanner} />
                        </a>
                      </Grid>
                      <Grid item xs={6} className="text-center" >
                        <a href='https://apps.apple.com/vn/app/gopy/id1489133034'>
                          <img width="55%" alt='Get on App Store' src={appstoreBanner} />
                        </a>
                      </Grid>
                      <Grid item xs={6} className="text-center">
                        <img width="70%" alt='Google Play QR' src={googlePlayQr} />
                      </Grid>
                      <Grid item xs={6} className="text-center" >
                        <img width="70%" alt='App Store QR' src={appstoreQr} />
                      </Grid>
                    </Grid>
                  :<>
                      <MDBInput className='text-lowercase' outline autoComplete="off" value={dataSave.email} name="email" onChange={handleChange} type="email" label={window.I18N('email') + ' *'} maxLength="200" pattern="\S(.*\S)?" required>
                    <div className="invalid-feedback">{window.I18N('MSG_CODE_030')}</div>
                      </MDBInput>
                      <MDBInput outline autoComplete="off" value={dataSave.password} name="password" onChange={handleChange} type="password" label={window.I18N('password') + ' *'} minLength="8" maxLength="50" pattern="\S(.*\S)?" required>
                        <div className="invalid-feedback">{window.I18N('MSG_CODE_039')}</div>
                      </MDBInput>
                      <MDBInput outline containerClass="mb-0" autoComplete="off" value={object.repassword} name="repassword" onChange={handleChange} type="password" label={window.I18N('confirm_password') + ' *'} minLength="8" maxLength="50" pattern="\S(.*\S)?" required>
                        <div className="invalid-feedback">{window.I18N('MSG_CODE_039')}</div>
                      </MDBInput>
                    </>
                  }
                  {
                    object.accountType === window.CONSTANT.INFLUENCER || object.accountType === window.CONSTANT.INDIVIDUAL ? <></> : <>
                      <Select id="select-company-type" value={select.companyType} options={options.companyType} placeholder={window.I18N('organization_type') + ' *'} className="md-form" onChange={(event) => handleSelect(event, 'select-company-type', 'companyType')} isSearchable/>
                      <Select id="select-company-size" value={select.companySize} options={options.companySize} placeholder={window.I18N('headcounts') + ' *'} className="md-form" onChange={(event) => handleSelect(event, 'select-company-size', 'companySize')} isSearchable/>
                      <Select id="select-company-industry" value={select.companyIndustry} options={options.companyIndustry} placeholder={window.I18N('industry') + ' *'} className="md-form" onChange={(event) => handleSelect(event, 'select-company-industry', 'companyIndustry')} isSearchable/>
                    </>
                  }
                </form>
              ) : activeStep === 1 ? (
                <form id="form-2" className="needs-validation" noValidate>
                  <p className="mb-3"><small className="font-weight-bold">{window.I18N('general_information')}</small></p>
                  <MDBInput outline containerClass="mt-0" autoComplete="off" value={dataSave.name} name="name" onChange={handleChange} type="text" label={window.I18N('organization_name') + ' *'} maxLength="200" pattern="\S(.*\S)?" required>
                    <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                  </MDBInput>
                  <MDBInput outline autoComplete="off" value={dataSave.phone} name="phone" onChange={handleChange} type="number" className="input-number" label={window.I18N('organization_phone') + ' *'} maxLength="20" pattern="\S(.*\S)?" required>
                    <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                  </MDBInput>
                  <MDBInput outline autoComplete="off" type="text" label={window.I18N('tax_id')} name="taxCode" value={dataSave.taxCode} onChange={handleChange} maxLength="20"></MDBInput>
                  <MDBInput outline autoComplete="off" value={dataSave.website} name="website" onChange={handleChange} type="text" label={window.I18N('website')} maxLength="200"></MDBInput>
                  <p className="mb-2"><small className="font-weight-bold">{window.I18N('address')}</small></p>
                  <Select id="select-nation" value={select.nation} options={options.nation} placeholder={window.I18N('country') + ' *'} className="md-form mt-0" onChange={(event) => handleSelect(event, 'select-nation', 'nation')} isSearchable/>
                  <Select id="select-state" value={select.state} options={options.state} placeholder={window.I18N('state_city') + ' *'} className="md-form" onChange={(event) => handleSelect(event, 'select-state', 'state')} isSearchable/>
                  <Select id="select-city" value={select.city} options={options.city} placeholder={window.I18N('city_district') + ' *'} className="md-form" onChange={(event) => handleSelect(event, 'select-city', 'city')} isSearchable/>
                  <MDBInput outline autoComplete="off" value={dataSave.address} name="address" onChange={handleChange} type="text" label={window.I18N('address_number') + ' *'} maxLength="200" pattern="\S(.*\S)?" required>
                    <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                  </MDBInput>
                  <MDBInput outline autoComplete="off" type="number" className="input-number" label={window.I18N('zip_code')} name="zipcode" value={dataSave.zipcode} onChange={handleChange} maxLength="20"></MDBInput>
                  <p className="mb-2"><small className="font-weight-bold">IDs</small></p>
                  <MDBInput outline containerClass="mb-0 mt-0" autoComplete="off" type="text" label={window.I18N('goverment_issued_id') + ' *'} name="organizationCode" value={dataSave.organizationCode} onChange={handleChange} maxLength="20" required></MDBInput>
                </form>
              ) : (
                <form id="form-3" className="needs-validation" noValidate>
                  <MDBInput outline autoComplete="off" value={dataSave.representative} name="representative" onChange={handleChange} type="text" label={window.I18N('representative_name')} maxLength="100"></MDBInput>
                  <MDBInput outline autoComplete="off" value={dataSave.representativePosition} name="representativePosition" onChange={handleChange} type="text" label={window.I18N('job_title')} maxLength="100" pattern="\S(.*\S)?"></MDBInput>
                  <MDBInput outline autoComplete="off" value={dataSave.representativePhone} name="representativePhone" onChange={handleChange} type="number" className="input-number" label={window.I18N('phone_number')} maxLength="20" pattern="\S(.*\S)?"></MDBInput>
                  <MDBInput className='text-lowercase' outline containerClass="mb-0" autoComplete="off" value={dataSave.representativeEmail} name="representativeEmail" onChange={handleChange} type="email" label={window.I18N('work_email_address')} maxLength="200"></MDBInput>
                </form>
              )
            }
            <div className="d-flex mt-3">
              <NavLink exact={true} to="/login" className="btn Ripple-parent btn-outline mr-auto ml-0">
                <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('go_to_login')}
              </NavLink>
              <MDBBtn outline color="" onClick={handleBack} disabled={activeStep === 0}>
                <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('back')}
              </MDBBtn>
              <MDBBtn id="btn-next" className="mr-0" outline color="primary" onClick={(event) => handleNext(event)} disabled={activeStep === steps.length || object.accountType === window.CONSTANT.INDIVIDUAL}>
                <MDBIcon fa="true" icon={activeStep === steps.length - 1 ? 'save' : 'arrow-right'} className="fa-fw" /> { activeStep === steps.length - 1 ? window.I18N('submit') : window.I18N('next') }
              </MDBBtn>
            </div>
          </div>
        </MDBCol>
        <nav className="navbar-footer-login mt-auto wow fadeInUp animated" data-wow-delay="1s">
          <ul className="">
            <li className="nav-item">
              <a href="https://gopy.io/term-and-condition" className="nav-link"> {window.I18N('terms')} </a>
            </li>
            <li className="nav-item">
              <a href="https://gopy.io/datapolicy" className="nav-link"> {window.I18N('privacy_policy')} </a>
            </li>
            <li className="nav-item">
              <a href="https://gopy.io/cookie-policy" className="nav-link"> Cookies </a>
            </li>
            <li className="nav-item">
              <a href="https://gopy.io" className="nav-link"> © 2019 - {new Date().getFullYear()} <b> CSR TECH JSC </b> </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}


const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = { setLanguage };

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);