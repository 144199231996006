import { Collapse } from '@material-ui/core'
import { MDBIcon } from 'mdbreact'
import React, { useState } from 'react'

const EventMenu = ({ data, onChange }) => {
    const [collapsed, setCollapsed] = useState(() => {
        const dataCollapsed = data.reduce((acc, parent) => {
            return parent.children ? { ...acc, [parent.type]: false } : acc
        }, {})
        return dataCollapsed
    })

    return (
        <>
            {data.map((item, indexParent) => (
                <div key={indexParent}>
                    <div
                        key={indexParent}
                        className="p-2 mb-1 d-flex align-items-center justify-content-between list-category"
                        style={{
                            gap: '8px',
                            height: '52px',
                            borderRadius: '8px',
                            backgroundColor: item.active ? '#f0f2f5' : '',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            if (item.children) {
                                setCollapsed((prev) => ({ ...prev, [item.type]: !prev[item.type] }))
                                return
                            }
                            if (item.active) {
                                return
                            }
                            onChange(indexParent)
                        }}
                    >
                        <div className="flex-center" style={{ gap: '8px' }}>
                            <div
                                className="flex-center"
                                style={{
                                    width: '36px',
                                    borderRadius: '50%',
                                    backgroundColor: item.active ? '#385898' : '#f0f2f5'
                                }}
                            >
                                <MDBIcon
                                    fas
                                    icon={item.icon}
                                    style={{
                                        fontSize: '18px',
                                        color: item.active ? '#FFFFFF' : '#000000'
                                    }}
                                />
                            </div>
                            <span style={{ fontSize: '15px', fontWeight: 500 }}>{window.I18N(item.title)}</span>
                        </div>
                        {item.children && (
                            <MDBIcon
                                fas
                                icon={collapsed[item.type] ? 'chevron-up' : 'chevron-down'}
                                style={{
                                    fontSize: '18px',
                                    color: '#000000'
                                }}
                            />
                        )}
                    </div>
                    <Collapse in={collapsed[item.type]}>
                        {item.children &&
                            item.children.map((child, indexChild) => (
                                <div
                                    key={indexChild}
                                    className="p-2 ml-4 mb-1 d-flex align-items-center justify-content-start list-category"
                                    style={{
                                        gap: '8px',
                                        height: '52px',
                                        borderRadius: '8px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        if (child.active) {
                                            return
                                        }
                                        onChange(indexParent, indexChild)
                                    }}
                                >
                                    <div
                                        className="flex-center"
                                        style={{
                                            width: '36px',
                                            height: '36px',
                                            borderRadius: '50%',
                                            backgroundColor: child.active ? '#385898' : '#f0f2f5'
                                        }}
                                    >
                                        <MDBIcon
                                            fas
                                            icon={child.icon}
                                            style={{
                                                fontSize: '18px',
                                                color: child.active ? '#FFFFFF' : '#000000'
                                            }}
                                        />
                                    </div>
                                    <span style={{ fontSize: '15px', fontWeight: 500 }}>
                                        {window.I18N(child.title)}
                                    </span>
                                </div>
                            ))}
                    </Collapse>
                </div>
            ))}
        </>
    )
}

export default EventMenu
