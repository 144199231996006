import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBSOIQZJptwxNvYe8-STkpb1K9Lq-dDMCQ',
  authDomain: 'gopy-7f57f.firebaseapp.com',
  databaseURL: 'https://gopy-7f57f.firebaseio.com',
  projectId: 'gopy-7f57f',
  storageBucket: 'gopy-7f57f.appspot.com',
  messagingSenderId: '647040156556',
  appId: '1:647040156556:web:de3e1e6e3ee46a86adcca9',
  measurementId: 'G-FQJ5Q8EC84'
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);