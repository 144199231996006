import React, { useEffect, useLayoutEffect, useState } from 'react';
import Select from 'react-select';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBInput, MDBCardImage, MDBBtn, MDBIcon, MDBCardHeader, MDBCardFooter } from 'mdbreact';

import InputMultiFile from './InputMultiFile';
import Button from './Button';
import { Box, Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'

const CustomRadio = withStyles({
  root: {
    "& .MuiSvgIcon-root": {
      height: 15,
      width: 15,
      color: '#fc9905'
    },
    padding: 3
  },
})(Radio);

const bg = [ 'bg-danger', 'bg-warning' , 'bg-info', '', 'bg-success']
const TYPE_QUESTIONS = [
  { 
    label: 'Text',
    value: 'TEXT'
  },
  { 
    label: 'Radio',
    value: 'RADIO'
  },
  { 
    label: 'Checkbox',
    value: 'CHECKBOX'
  },
  { 
    label: 'Radio Grid',
    value: 'RADIO_GRID'
  },
  { 
    label: 'Checkbox Grid',
    value: 'CHECKBOX_GRID'
  },
]

const ItemQAInfluencer = React.memo(function ItemQAInfluencer(props) {
  const [language, setLanguage] = React.useState();
  const [object, setObject] = React.useState({ index: 0, isDisabledBtn: true, important: false });
  const [data, setData] = React.useState([]);
  const [dataItem, setDataItem] = React.useState({});
  const [dataDeletedFiles, setDataDeletedFiles] = React.useState([]);
  const [dataFeedback, setDataFeedback] = React.useState({});
  const [dataAnswer, setDataAnswer] = React.useState([]);
  const [filters, setFilters] = useState({
    filter: {
      benmark: null,
      type: null
    },
    sort: null,
    search: null
  })

  const [dataFilters, setDataFilters] = useState([])

  useLayoutEffect(() => {
    let dataItem = {};
    setLanguage(props.language);
    if (props.data && props.data.length > 0) {
      const dataAfterBenmarkCalc = handleQuantityHistoryAnswer(props.data, props.company ? ['company', 'worker'] : ['advisor', 'company'])
      const item = dataAfterBenmarkCalc[object.index]?.question;
      if (item) {
        dataItem = JSON.parse(JSON.stringify(item));
        object.important = item.important;
      }
      setData(dataAfterBenmarkCalc);

	  const userId = localStorage.getItem('id');
      const draft = JSON.parse(localStorage.getItem('draftAssessment') || '[]');
      const dataDraft = draft.find(item => item.user === userId && item.assessment === props.assessmentId && item.group === props.group?.group?._id);
      if (props.dataFeedback || dataDraft?.feedback) {
        // const feedbackData = Object.keys(props.dataFeedback).length > 0 ? props.dataFeedback : (dataDraft?.feedback ?? {});
        const feedbackData = dataDraft?.feedback ?? ( props.dataFeedback ?? {});
        object.index = 0;
        if (feedbackData[dataItem._id]) {
          object.isDisabledBtn = false;
        } else {
          object.isDisabledBtn = true;
        }
        setObject(object);
        setDataFeedback(feedbackData);
      }
      setDataAnswer(JSON.parse(JSON.stringify(dataAfterBenmarkCalc[object.index]?.answers)));
      setDataItem({ ...dataItem });
    }
  }, [object, props.data, props.language, props.dataFeedback]);

  function countAnsweredQuestion(paramData) {
    let count= 0
    let answerData = Object.values(paramData) 
    answerData.forEach(feedbackAnswer => {
      if (feedbackAnswer.answerText.trim() !== "") {
        count++
      } else if (feedbackAnswer.answers.length) {
        count++
      } else if ( Object.keys(feedbackAnswer.answers).length) {
        let countChildAnswer = 0 
        const numberOfChildQuestions = Object.keys(feedbackAnswer.answers).length
        Object.values(feedbackAnswer.answers).forEach(answerOfSingleChildQues => {
          if (answerOfSingleChildQues.length > 0) {
            countChildAnswer++
          }
        })
        if (numberOfChildQuestions===countChildAnswer) count++
      }
    })
    return count
  }

  const initItemFeedback = (parentCode) => {
    if (!dataFeedback[dataItem._id]) {
      if (dataItem.type === 'RADIO_GRID' || dataItem.type === 'CHECKBOX_GRID') {
        const parent = dataAnswer.filter(function(item) {
          return item.question === dataItem._id && window.COMMON.getValueFromAttr(item, 'parentCode', null) === null;
        });
        let childQues = parent.reduce((obj, item) => Object.assign(obj, { [item.code]: [] }), {});
        dataFeedback[dataItem._id] = {
          count: parent.length,
          answers: childQues,
          answerText: ''
        };
      } else {
        dataFeedback[dataItem._id] = {
          answers: [],
          answerText: ''
        };
      }
      if (props.isSelfAssessment) {
        dataFeedback[dataItem._id].note = '';
        dataFeedback[dataItem._id].consultantEvaluation = '';
        dataFeedback[dataItem._id].consultantNote = '';
        dataFeedback[dataItem._id].documents = [];
      }
    }
    if (parentCode && !dataFeedback[dataItem._id].answers[parentCode]) {
      dataFeedback[dataItem._id].answers[parentCode] = [];
    }
  };

  const checkStatus = () => {
    object.isDisabledBtn = true;
    if (dataItem.type === 'TEXT' && dataFeedback[dataItem._id]) {
      object.isDisabledBtn = !window.COMMON.checkValue(dataFeedback[dataItem._id].answerText);
    }
    if ((dataItem.type === 'RADIO' || dataItem.type === 'LEVEL') && dataFeedback[dataItem._id]) {
      object.isDisabledBtn = dataFeedback[dataItem._id].answers.length === 0;
    }
    if (dataItem.type === 'CHECKBOX' && dataFeedback[dataItem._id]) {
      object.isDisabledBtn = dataFeedback[dataItem._id].answers.length === 0;
    }
    if (dataItem.type === 'RADIO_GRID' && dataFeedback[dataItem._id]) {
      const keys = Object.keys(dataFeedback[dataItem._id].answers);
      object.isDisabledBtn = keys.length < dataFeedback[dataItem._id].count;
    }
    if (dataItem.type === 'CHECKBOX_GRID' && dataFeedback[dataItem._id]) {
      const keys = Object.keys(dataFeedback[dataItem._id].answers);
      let check = false;
      keys.forEach(key => {
        check = dataFeedback[dataItem._id].answers[key].length === 0;
      });
      if (!check) {
        check = keys.length < dataFeedback[dataItem._id].count;
      }
      object.isDisabledBtn = check;
    }
    setObject(object);
  };

  const handleSubmit = () => {  
    if (props.isSelfAssessment) {
      let totalSize = 0;
      const keys = Object.keys(dataFeedback);
      keys.forEach(key => {
        dataFeedback[key].documents.forEach(document => {
          totalSize += document.size;
        });
      });
      if (totalSize >= window.CONSTANT.TOTAL_SIZE_SELF_ASSESSMENT) {
        window.COMMON.showMessage('warning', 'MSG_CODE_041', window.MSG.MSG_CODE_041);
        return;
      }
    }
    props.handleSubmit(dataFeedback, dataDeletedFiles);
  };

  const handlePage = (index) => {
    object.index = index;
    object.isDisabledBtn = true;
    const item = data[object.index].question;
    object.important = item && item.important;
    if (item) {
      dataItem._id = window.COMMON.getValueFromAttr(item, '_id', null);
      dataItem.question = window.COMMON.getDataWithLanguage(item, 'question');
      dataItem.code = window.COMMON.getValueFromAttr(item, 'code');
      dataItem.introduction = window.COMMON.getDataWithLanguage(item, 'introduction');
      dataItem.type = window.COMMON.getValueFromAttr(item, 'type');
    }
    checkStatus();
    setDataAnswer(JSON.parse(JSON.stringify(data[object.index].answers)));
    setDataItem({ ...dataItem });
  };

  const handlePageWithFilters = (index, data = null) => {
    let dataForFilters = data ? data : dataFilters
    object.index = index;
    object.isDisabledBtn = true;
    const item = dataForFilters[object.index].question;
    object.important = item && item.important;
    if (item) {
      dataItem._id = window.COMMON.getValueFromAttr(item, '_id', null);
      dataItem.question = window.COMMON.getDataWithLanguage(item, 'question');
      dataItem.code = window.COMMON.getValueFromAttr(item, 'code');
      dataItem.introduction = window.COMMON.getDataWithLanguage(item, 'introduction');
      dataItem.type = window.COMMON.getValueFromAttr(item, 'type');
    }
    checkStatus();
    setDataAnswer(JSON.parse(JSON.stringify(dataForFilters[object.index].answers)));
    setDataItem({ ...dataItem });
  };

  const handleChange = (event, id, parentCode) => {
    initItemFeedback(parentCode);
    if (event.target.type === 'checkbox') {
      if (event.target.checked) {
        if (dataItem.type === 'CHECKBOX') {
          dataFeedback[dataItem._id].answers.push(id);
        } else {
          dataFeedback[dataItem._id].answers[parentCode].push(id);
        }
      } else {
        let arrs = [];
        if (dataItem.type === 'CHECKBOX') {
          arrs = dataFeedback[dataItem._id].answers;
        } else {
          arrs = dataFeedback[dataItem._id].answers[parentCode];
        }
        for (let i = 0; i < arrs.length; i++) {
          if (arrs[i] === id) {
            arrs.splice(i, 1);
            break;
          }
        }
      }
    } else {
      dataFeedback[dataItem._id][event.target.name] = event.target.value;
    }
    checkStatus();
    setDataFeedback({ ...dataFeedback });
  };

  const handleRadio = (value, parentCode) => {
    initItemFeedback(parentCode);
    if (dataItem.type === 'RADIO') {
      dataFeedback[dataItem._id].answers = [value];
    } else {
      dataFeedback[dataItem._id].answers[parentCode] = [value];
    }
    checkStatus();
    setDataFeedback({ ...dataFeedback });
  };

  const chooseLevel = (id) => {
    initItemFeedback();
    dataFeedback[dataItem._id].answers = [id];
    checkStatus();
    setDataFeedback({ ...dataFeedback });
  };

  const handleFile = async (files) => {
    initItemFeedback();
    const dataFiles = await window.uploadFile(files);
    for (let i = 0; i < dataFiles.length; i++) {
      dataFeedback[dataItem._id].documents.push({
        id: dataFiles[i]._id,
        name: dataFiles[i].name,
        size: dataFiles[i].size,
        type: dataFiles[i].type,
        ext: dataFiles[i].ext
      });
    }
    setDataFeedback({ ...dataFeedback });
  };

  const handleSaveDraft = (feedback) => {
    //check object is not empty
    if (Object.keys(feedback).length > 0) {
      const userId = localStorage.getItem('id');
      const draft = JSON.parse(localStorage.getItem('draftAssessment') || '[]');
  
      if (userId) {
	    const dataIndex = draft.findIndex(item => item.user === userId && item.assessment === props.assessmentId && item.group === props.group?.group?._id);
        const newDraftData = {
		      assessment: props.assessmentId,
          group: props.group?.group?._id,
          feedback,
          questions: data.map(item => item.question),
          user: userId
        };
	    if (dataIndex > -1) {
          draft[dataIndex] = newDraftData;
	    } else {
          draft.push(newDraftData);
	    }
      }
      localStorage.setItem('draftAssessment', JSON.stringify(draft));
	  window.COMMON.showMessage('success', 'MSG_CODE_002', window.I18N('MSG_CODE_002'));
    }
  };

  const deleteFile = async (index) => {
    const document = dataFeedback[dataItem._id].documents[index];
    if (!document.file) {
      dataDeletedFiles.push(document);
      setDataDeletedFiles(dataDeletedFiles);
    }
    dataFeedback[dataItem._id].documents.splice(index, 1);
    setDataFeedback({ ...dataFeedback });
  };

  const checkRadioOrCheckbox = (item) => {
    if (dataItem.type === 'LEVEL' || dataItem.type === 'RADIO' || dataItem.type === 'CHECKBOX') {
      return window.COMMON.getValueFromAttr(dataFeedback[dataItem._id], 'answers').indexOf(item._id) > -1;
    } else {
      const answers = window.COMMON.getValueFromAttr(dataFeedback[dataItem._id], 'answers');
      if (answers && answers[item.parentCode]) {
        return answers[item.parentCode].indexOf(item._id) > -1;
      }
    }
    return false;
  };

  const generateFormAnswer = (item, i) => {
    if (dataItem.type === 'CHECKBOX' || dataItem.type === 'CHECKBOX_GRID') {
      return <MDBInput key={'preview-' + item.code} id={'preview-' + item.code} type="checkbox" containerClass="pl-0 pb-2 mr-4" label={window.COMMON.getValueWithLanguage(item, 'content', props.language)} filled onChange={(event) => handleChange(event, item._id, item.parentCode)} checked={checkRadioOrCheckbox(item)} disabled={props.isSubmited}/>;
    }
    if (dataItem.type === 'LEVEL') {
      return <div 
        key={'preview-' + item.code} 
        className={'progress-bar progress-bar-striped progress-bar-animated w-100 ' + bg[i % 5] + (checkRadioOrCheckbox(item) ? ' active' : '')} role="progressbar" 
        onClick={props.isSubmited ? () => {} : () => chooseLevel(item._id)}
      >
        {window.COMMON.getValueWithLanguage(item, 'content', props.language)}
      </div>;
    }
    return <MDBInput gap key={'preview-' + item.code} id={'preview-' + item.code} type="radio" containerClass="pl-0 pb-1 mr-4" label={window.COMMON.getValueWithLanguage(item, 'content', props.language)} onClick={() => handleRadio(item._id, item.parentCode)} checked={checkRadioOrCheckbox(item)} disabled={props.isSubmited}/>;
  };

  const generateFormAnswerGrid = () => {
    const parent = dataAnswer.filter(function(item) {
      return window.COMMON.getValueFromAttr(item, 'parentCode', null) === null;
    });
    if (parent.length > 0) {
      return parent.map((item, i) => <div key={'preview-' + item.code}>
       <MDBRow> 
        <MDBCol md="6" xs="12">
            <p className="mb-2 p-2 rounded-lg" style={{
                backgroundColor: '#fff3cd'
              }}>
              <span className="mr-4 font-weight-bold text-muted" style={{ color: '#856404' }}>
                  {window.COMMON.getValueWithLanguage(item, 'content', props.language)}
               </span>
            </p>
        </MDBCol>
       </MDBRow>
        {
          window.COMMON.getArrsInArrs(item.code, dataAnswer, 'parentCode').map((child, j) => (
            <MDBRow key={j}>
              <MDBCol md="6" xs="12">{generateFormAnswer(child, j)}</MDBCol>
              {props.dataHistoryAnswer?.advisor ? 
                <MDBCol 
                  md="2" xs="6" 
                  className='d-flex align-items-center justify-content-center'
                >{getAnswerQuantity('advisor', dataItem?._id, child._id)}</MDBCol>
                : <MDBCol md="2" xs="6"></MDBCol> 
              }
              <MDBCol 
                md="2" xs="6" 
                className='d-flex align-items-center justify-content-center'
              >{getAnswerQuantity('company', dataItem?._id, child._id)}</MDBCol>
              <MDBCol 
                md="2" xs="6" 
                className='d-flex align-items-center justify-content-center'
              >{getAnswerQuantity('worker', dataItem?._id, child._id)}</MDBCol>
            </MDBRow>
		  ))
        }
      </div>);
    }
    return <div></div>;
  };

  //type worker or company
  const getAnswerQuantity = (type, questionId, answerId) => {
    const data = props.dataHistoryAnswer[type];
    if (data.length > 0) {
      let count = 0;
      data.map(d => {
        //find by question id and answer id
        const result = d?.feedback?.find(f => f.question?._id === questionId && f.answers?.findIndex(a => a._id === answerId) > -1 );
        if (result) count += 1;
      });
	  return count;
    };
    return 0;
  };

  //type worker or company
  const getNoteData = (type, questionId) => {
    const data = props.dataHistoryAnswer[type];
    if (data?.length > 0) {
      const result = [];
      data.map(d => {
        //find by question id and have note
        const dataHaveNote = d?.feedback?.find(f => f.question?._id === questionId && f.note );
        if (dataHaveNote) result.push(dataHaveNote.note);
      });
	  return result;
    };
    return [];
  };

  const getAttachmentData = (type, questionId) => {
    const data = props.dataHistoryAnswer[type];
    if (data?.length > 0) {
      let result = [];
      data.map(d => {
        //find by question id and have attachment
        const dataHaveDocument = d?.feedback?.find(f => f.question?._id === questionId && f.documents?.length > 0 );
        if (dataHaveDocument) {
          result = result.concat(dataHaveDocument.documents);
        }
      });
	  return result;
    };
    return [];
  };

  const renderFiles = (item, i, canDelete = false) => {
    return (
      <div key={i} className="media item p-1">
        <div onClick={() => window.COMMON.downloadFile(item.id, item.name)}>
          <MDBCardImage 
            zoom cascade waves 
            src={window.COMMON.getFileType(item.ext)} 
            className="image-icon mr-2"
          />
        </div>
        <div className="media-body">
		  <p className="mb-0">
		    <span className="cursor-pointer font-weight-bold" onClick={() => window.COMMON.downloadFile(item.id, item.name)}>{item.name}</span>
		  </p>
		  <small className="mb-0">{window.COMMON.bytesToSize(item.size)}</small>
        </div>
        {canDelete && (
          <div className="media-right align-self-center cursor-pointer" onClick={() => deleteFile(i)}>
		  <MDBIcon fa="true" icon="times" style={{ color: 'gray' }} className="fa-fw mr-2"></MDBIcon>
          </div>
        )}
	  </div>
    );
  };

  const layoutGridFormAnswer = () => {
    return (
	  <div>
        <MDBRow>
          <MDBCol md="6" xs="12"></MDBCol>
		  <MDBCol md="2" xs="6" className='text-center'>
            {props.dataHistoryAnswer?.advisor && (
              <>
                <MDBIcon fa="true" icon="user-secret" className="fa-fw" />
                <b className='ml-1'>{window.I18N('advisor')}</b>
              </>
            )}
		  </MDBCol>
          <MDBCol md="2" xs="6" className='text-center'>
            <MDBIcon fa="true" icon="building" className="fa-fw" />
            <b className='ml-1'>{window.I18N('company')}</b>
          </MDBCol>
          <MDBCol md="2" xs="6" className='text-center'>
            <MDBIcon fa="true" icon="users" className="fa-fw" />
            <b className='ml-1'>{window.I18N('worker')}</b>
          </MDBCol>
        </MDBRow>
        {generateFormAnswerGrid()}
	  </div>
    );
  };

  const layoutFormAnswer = () => {
    return (
      <div>
        <MDBRow>
          <MDBCol md="6" xs="12"></MDBCol>
		  <MDBCol md="2" xs="6" className='text-center'>
            {props.dataHistoryAnswer?.advisor && (
              <>
                <MDBIcon fa="true" icon="user-secret" className="fa-fw" />
                <b className='ml-1'>{window.I18N('advisor')}</b>
              </>
            )}
		  </MDBCol>
          <MDBCol md="2" xs="6" className='text-center'>
            <MDBIcon fa="true" icon="building" className="fa-fw" />
            <b className='ml-1'>{window.I18N('company')}</b>
          </MDBCol>
          <MDBCol md="2" xs="6" className='text-center'>
            <MDBIcon fa="true" icon="users" className="fa-fw" />
            <b className='ml-1'>{window.I18N('worker')}</b>
          </MDBCol>
        </MDBRow>
        {dataAnswer.map((item, i) => (
          <MDBRow key={i}>
            <MDBCol md="6" xs="12">{generateFormAnswer(item, i)}</MDBCol>
            {props.dataHistoryAnswer?.advisor ? 
              <MDBCol 
                md="2" xs="6" 
                className='d-flex align-items-center justify-content-center'
              >{getAnswerQuantity('advisor', dataItem?._id, item._id)}</MDBCol>
			  : <MDBCol md="2" xs="6"></MDBCol> 
            }
            <MDBCol 
              md="2" xs="6" 
              className='d-flex align-items-center justify-content-center'
            >{getAnswerQuantity('company', dataItem?._id, item._id)}</MDBCol>
            <MDBCol 
              md="2" xs="6" 
              className='d-flex align-items-center justify-content-center'
            >{getAnswerQuantity('worker', dataItem?._id, item._id)}</MDBCol>
          </MDBRow>
        ))}
      </div>
    );
  };

  const layoutLevelFormAnswer = () => {
    return (
      <div>
        <MDBRow className='mb-2'>
          <MDBCol md="12" xs="12">
            <div className="progress mt-3" style={{ height: '40px' }}>
              { dataAnswer.map((item, i) => generateFormAnswer(item, i)) }
            </div> 
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="12" xs="12">
            <MDBRow className='my-2'>
              <MDBCol md="3" xs="12">
                <b>{window.I18N('answer')}</b>
              </MDBCol>
              <MDBCol md="3" xs="6" className='text-center'>
                {props.dataHistoryAnswer?.advisor && (
                  <>
                    <MDBIcon fa="true" icon="user-secret" className="fa-fw" />
                    <b className='ml-1'>{window.I18N('advisor')}</b>
                  </>
                )}
  		        </MDBCol>
              <MDBCol md="3" xs="6" className='text-center'>
                <MDBIcon fa="true" icon="building" className="fa-fw" />
                <b className='ml-1'>{window.I18N('company')}</b>
              </MDBCol>
              <MDBCol md="3" xs="6" className='text-center'>
                <MDBIcon fa="true" icon="users" className="fa-fw" />
                <b className='ml-1'>{window.I18N('worker')}</b>
              </MDBCol>
            </MDBRow>
            {dataAnswer.map((item, i) => (
              <MDBRow key={i}>
                <MDBCol md="3" xs="12" className='d-flex align-items-center justify-content-start'>
                  {window.COMMON.getValueWithLanguage(item, 'content', props.language)}
                </MDBCol>
  			  {props.dataHistoryAnswer?.advisor ? 
                  <MDBCol 
                    md="3" xs="6" 
                    className='d-flex align-items-center justify-content-center my-2'
                  >{getAnswerQuantity('advisor', dataItem?._id, item._id)}</MDBCol>
                  : <MDBCol md="3" xs="6"></MDBCol> 
                }
                <MDBCol 
                  md="3" xs="6" 
                  className='d-flex align-items-center justify-content-center my-2'
                >{getAnswerQuantity('company', dataItem?._id, item._id)}</MDBCol>
                <MDBCol 
                  md="3" xs="6" 
                  className='d-flex align-items-center justify-content-center my-2'
                >{getAnswerQuantity('worker', dataItem?._id, item._id)}</MDBCol>
              </MDBRow>
            ))}
          </MDBCol>
        </MDBRow>
      </div>
    );
  };

  const layoutTextFormAnswer = () => {
    const getAnswerText = (feedbacks) => {
      if (feedbacks && Array.isArray(feedbacks)) {
        const feedback = feedbacks.find(item => item.question?._id === dataItem._id);
        if (feedback) return feedback.answerText; 
      }
      return '';
    }; 

    return (
      <div className='mt-2'>
        <MDBRow>
          <MDBCol md="6" xs="12" className='d-flex align-items-center justify-content-center'> 
            <h6 className="font-weight-bold d-flex justify-content-start align-items-center" style={{ fontSize: "0.8rem", gap: '4px' }}>
                {window.I18N('answer')}
            </h6>
          </MDBCol>
          <MDBCol md="6" xs="12" className='d-flex align-items-center justify-content-center'> 
            <h6 className="font-weight-bold d-flex justify-content-start align-items-center" style={{ fontSize: "0.8rem", gap: '4px' }}>
              {window.I18N('answer_statistics')}
            </h6>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="6" xs="12" className='border-right'>
              <MDBInput 
                className='m-0'
                outline 
                type="textarea" 
                containerClass="mt-1" name="answerText" 
                value={window.COMMON.getValueFromAttr(dataFeedback[dataItem._id], 'answerText')} 
                onChange={handleChange}
                maxLength="250" 
                disabled={props.isSubmited}
              />
          </MDBCol>
          <MDBCol md="6" xs="12">
           
            {props.dataHistoryAnswer?.advisor?.length > 0 && (
              <div 
                style={{
                  maxHeight: '200px',
                  overflowX: 'overlay'
                }}
              >
                <b><MDBIcon fa="true" icon="user-secret" className="fa-fw" /> {window.I18N('advisor_answer')}</b>
  			      <ul style={{ paddingLeft: 20}}>
                  {props.dataHistoryAnswer?.advisor.map((item, i) => (
                    <li key={i} className="mt-1">{getAnswerText(item.feedback)}</li>
                  ))}
                </ul>
              </div>
  		  )}
  		  {props.dataHistoryAnswer?.company?.length > 0 && (
              <div>
                <b><MDBIcon fa="true" icon="building" className="fa-fw" /> {window.I18N('company_answer')}</b>
  			  <ul style={{ paddingLeft: 20}}>
                  {props.dataHistoryAnswer?.company.map((item, i) => (
                    <li key={i} className="mt-1">{getAnswerText(item.feedback)}</li>
                  ))}
                </ul>
              </div>
  		  )}
  		  {props.dataHistoryAnswer?.worker?.length > 0 && (
              <div>
                <b><MDBIcon fa="true" icon="users" className="fa-fw" /> {window.I18N('worker_answer')}</b>
  			  <ul style={{ paddingLeft: 20}}>
                  {props.dataHistoryAnswer?.worker.map((item, i) => (
                    <li key={i} className="mt-1">{getAnswerText(item.feedback)}</li>
                  ))}
                </ul>
              </div>
  		  )}
        {!props.dataHistoryAnswer?.advisor?.length && !props.dataHistoryAnswer?.company?.length && !props.dataHistoryAnswer?.worker?.length && <div
          className='w-full flex-center'
        >
            {window.I18N('empty')}
        </div>}
          </MDBCol>
        </MDBRow>
      </div>
    );
  };

  const groupAnswersForGrid = (data) => {
    const parentAnswer = data.filter((answer) => answer.parentCode === null)
    const answerGroups = []
    parentAnswer.forEach((parent) => {
      const answersGroup = data.filter(item => item.parentCode === parent.code)
      answerGroups.push(answersGroup)
       
    })
    return answerGroups.flat()
  }

  const handleQuantityHistoryAnswer = (data, compareKey = []) => {
    const [ key_1, key_2 ] = compareKey
    const feedbackQuestions = []
    const filterTextQuestions = []
    data.forEach((dataQuestion) => {
      const countPerQuestion = {
        [key_1]: [],
        [key_2]: [],
        mapQuestion: dataQuestion
      }
      const { question, answers } = dataQuestion
      if(question.type === 'TEXT'){
        filterTextQuestions.push(dataQuestion)
      }else{
        let modifiedAnswer = answers
        if(['RADIO_GRID', 'CHECKBOX_GRID'].includes(question.type)) {
          modifiedAnswer = groupAnswersForGrid(answers)
        }
        modifiedAnswer.forEach((answer, i) => {
           const countKey1 = getAnswerQuantity(key_1, question._id, answer._id)
           const countKey2 = getAnswerQuantity(key_2, question._id, answer._id)
           countPerQuestion[key_1].push(countKey1)
           countPerQuestion[key_2].push(countKey2) 
        })
        feedbackQuestions.push(countPerQuestion)
      }
    })

    const benmarkMappingQuestions = window.COMMON.calculateBenchmark(feedbackQuestions, key_1, key_2)
    const mergeQuestions = [...benmarkMappingQuestions, ...filterTextQuestions]
    return mergeQuestions
  }

  const handleFilters = (e) => {
    const { name, value } = e.target
    if(name === 'sort'){
      setFilters((prev) => ({...prev, [name]: value }))
    }else{
      setFilters((prev) => ({...prev, filter: {...prev.filter, [name]: value} }))
    }
  }

  const handleSetDataFilters = (data) => {
    setDataFilters(data)
    if(window.COMMON.isEmpty(data)) {
      object.index = -1
      setDataItem({})
    }else{
      handlePageWithFilters(0, data)
    }
  }

  const checkIsFilters = () => {
    const { filter: { benmark, type }, sort } = filters
    if(((benmark || type) || sort)){
      return true
    }
    return false
  }

  const handleFilterData = (data) => {
    const { filter: { benmark, type }} = filters
      if(benmark && !type){
        return data.filter((item) => item.type === benmark)
      }else if(!benmark && type){
        return data.filter((item) => item.question.type === type)
      }else {
        return data.filter((item) => item.question.type === type && item.type === benmark)
      }
  }

  const handleSortData = (dataSort, sort) => {
    if(dataSort){
      const sorted = dataSort.sort((a, b) =>(sort === 'benmark-asc' ? a.point - b.point : b.point - a.point))
      return sorted
    }
  }

  useEffect(() => {
    const { filter: { benmark, type }, sort } = filters
    const cloneData = JSON.parse(JSON.stringify(data))
    const filteredData = handleFilterData(cloneData)
    if((benmark || type) && sort){
        const dataSorted = handleSortData(filteredData, sort)
        handleSetDataFilters(dataSorted)
    }else if((benmark || type) && !sort){
        handleSetDataFilters(filteredData)
    }else if(sort && !(benmark || type)){
        const dataSorted = handleSortData(cloneData, sort)
        handleSetDataFilters(dataSorted)
    }
  },[filters])
    
  return <React.Fragment>
    <div className="container-btn wow fadeInDown animated" data-wow-delay="0.5s">
      <div className="text-left d-flex">
      <Button iconLeft='arrow-left' onClick={props.handleClose}>
        {window.I18N('btn_back')}
      </Button>
        {/* <MDBBtn color="" onClick={props.handleClose}>
          <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('btn_back')}
        </MDBBtn> */}
        <div style={{ flex: '1 1 0%' }} />
        
        {/* <MDBBtn color="" className="mr-0" onClick={props.handleViewFacility}>
          <MDBIcon fa="true" icon="info-circle" className="fa-fw" /> 
        </MDBBtn> */}
        <Button iconLeft='info-circle' onClick={props.handleViewFacility}>
          {window.I18N('facility_info')}
        </Button>
        {!props.isSubmited && (
          <>
          <Button iconLeft='clipboard' onClick={() => handleSaveDraft(dataFeedback)}>
            {window.I18N('btn_save_draft')}
          </Button>
            {/* <MDBBtn color="" onClick={() => handleSaveDraft(dataFeedback)}>
               <MDBIcon fa="true" icon="clipboard" className="fa-fw" />
            </MDBBtn> */}
            {props.handleSubmit && (
              <Button iconLeft='save' onClick={handleSubmit} disabled={countAnsweredQuestion(dataFeedback) !== data.length}>
                {window.I18N('btn_save')}
              </Button>
         
            )}
          </>
        )}
      </div>
    </div>
    <MDBRow>
      <MDBCol size='3' className='pr-0'>
        <div className="w-full bg-white rounded-lg wow fadeIn" data-wow-delay="1s" style={{ height: '100%' }}>
            {/* Search */}
            <div className='w-full d-flex justify-content-center flex-column p-3'>
              <h6 className="font-weight-bold d-flex justify-content-start align-items-center" style={{ fontSize: "0.8rem", gap: '4px' }}>
                <MDBIcon fa="true" icon="search" className="fa-fw" />
                {window.I18N('search')}
              </h6>          
              <div className='w-full'>
                <Select 
                  id="select-questions" 
                  value={null} 
                  options={checkIsFilters() ? dataFilters.map((item, index) => {
                    return {
                      label: index + 1 + '. ' + window.COMMON.getValueWithLanguage(item.question, 'question', language),
                      value: index
                    }
                  }) : data.map((item, index) => {
                    return {
                      label: index + 1 + '. ' + window.COMMON.getValueWithLanguage(item.question, 'question', language),
                      value: index
                    }
                  })}
                  placeholder={window.I18N('search_question')} 
                  className="m-0 flex-1"
                  onChange={(event) => handlePage(event.value)} 
                  isSearchable 
                />
              </div>
            </div>
            {/* List Questions */}
            <div className='w-full d-flex justify-content-center flex-column px-3 py-2'>
              <h6 className="font-weight-bold d-flex justify-content-start align-items-center" style={{ fontSize: "0.8rem", gap: '4px' }}>
                  <MDBIcon fa="true" icon="list" className="fa-fw" />
                  {window.I18N('question')}
              </h6> 
              <div>
                <ol className='pl-4 py-1 m-0' style={{ maxHeight: '160px', overflowY: 'overlay', overscrollBehavior: 'contain' }}>
                  {(checkIsFilters() && window.COMMON.isEmpty(dataFilters)) && <div>{window.I18N('empty')}</div>}
                  {
                    checkIsFilters() ? dataFilters.map((item, index) => (
                    <li 
                      key={index} 
                      style={{
                        margin: '4px 0', 
                        cursor: 'pointer', 
                        color: item.uuid === dataItem._id ? '#fc9905' : '',
                        fontWeight: item.uuid === dataItem._id ? 500 : '',
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
                      }}
                      onClick={() => handlePageWithFilters(index)}
                    >
                      {item.question.question[0].text}
                    </li>
                  )): 
                    data.map((item, index) => (
                      <li 
                        key={index} 
                        style={{
                          margin: '4px 0', 
                          cursor: 'pointer', 
                          color: item.uuid === dataItem._id ? '#fc9905' : '',
                          fontWeight: item.uuid === dataItem._id ? 500 : '',
                          fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
                        }}
                        onClick={() => handlePage(index)}
                      >
                        {item.question.question[0].text}
                      </li>
                    )) 
                  }
                </ol>
              </div>       
            </div>
            {/* Filters */}
            <div className='w-full d-flex justify-content-center flex-column px-3 py-2'>
              <div className='d-flex justify-content-between align-items-center mb-1'>
                <h6 className="font-weight-bold d-flex justify-content-start align-items-center mb-0" style={{ fontSize: "0.8rem", gap: '4px' }}>
                    <MDBIcon fa="true" icon="sliders-h" className="fa-fw" />
                    {window.I18N('filter')}
                </h6>
                <Tooltip title={window.I18N('btn_clear')} placement="top" arrow>
                  <div 
                    className='d-flex justify-content-end align-items-center' 
                    onClick={() =>{ 
                      setFilters((prev) => ({...prev, filter: { benmark: null, type: null }}))
                      handlePage(0)
                    }}
                    style={{
                      cursor: 'pointer'
                    }}
                  >
                      <MDBIcon fa="true" icon="sync-alt" className="fa-fw" />
                  </div> 
                </Tooltip>
              </div>       
              <div className='ml-2'>
                <FormControl component="fieldset">
                    <Box sx={{
                      "& .MuiFormControlLabel-root": {
                        margin: 0
                      }
                    }}>
                      <h6 className="font-weight-bold d-flex justify-content-start align-items-center my-2" style={{ fontSize: "0.7rem" }}>
                          {window.I18N('Benmark')}
                      </h6>    
                      <RadioGroup
                        name="benmark" 
                        value={filters.filter.benmark} 
                        onChange={handleFilters}
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-start',
                          gap: '6px',
                          marginLeft: '8px'
                        }}
                      >
                      {Object.keys(window.CONSTANT.GROUP_DIFFERENCE_FEEDBACK).map((benmarkRange, index) => {
                        return (
                          <FormControlLabel
                            disabled={filters.filter.type === 'TEXT'} 
                            key={index}
                            value={benmarkRange} 
                            control={<CustomRadio />} 
                            label={<span className="flex-center" style={{ fontSize: '0.8rem' }}>{benmarkRange}</span>}
                          />
                        )
                        
                      })}  
                      </RadioGroup>
                      <h6 className="font-weight-bold d-flex justify-content-start align-items-center my-2" style={{ fontSize: "0.7rem"}}>
                        {window.I18N('type')}
                      </h6>
                      <RadioGroup
                        name="type" 
                        value={filters.filter.type} 
                        onChange={handleFilters}
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-start',
                          gap: '6px',
                          marginLeft: '8px'
                        }}
                      >
                          {
                            TYPE_QUESTIONS.map((type, index) => (
                              <FormControlLabel 
                                key={index}
                                value={type.value} 
                                control={<CustomRadio />} 
                                label={<span className="flex-center" style={{ fontSize: '0.8rem' }}>{type.label}</span>}
                              />

                            ))
                          } 
                      </RadioGroup>
                    </Box>
                  </FormControl>
              </div>

            </div>
          {/* Sort */}
          <div className='w-full d-flex justify-content-center flex-column px-3 py-2'>
              <div className='d-flex justify-content-between align-items-center mb-1'>
                <h6 className="font-weight-bold d-flex justify-content-start align-items-center mb-0" style={{ fontSize: "0.8rem", gap: '4px' }}>
                    <MDBIcon fa="true" icon="sort" className="fa-fw" />
                    {window.I18N('sort')}
                </h6> 
                <Tooltip title={window.I18N('btn_clear')} placement="top" arrow>
                  <div 
                    className='d-flex justify-content-end align-items-center ' 
                    onClick={() => {
                      setFilters((prev) => ({...prev, sort: null}))
                      handlePage(0)
                    }}
                    style={{
                      cursor: 'pointer'
                    }}
                  >
                      <MDBIcon fa="true" icon="sync-alt" className="fa-fw" />
                  </div> 
                </Tooltip>
              </div>
              <div className='ml-3'>
                  <FormControl>
                    <Box sx={{
                      "& .MuiFormControlLabel-root": {
                        margin: 0
                      }
                    }}>
                      <RadioGroup 
                        name="sort"
                        value={filters.sort}
                        onChange={handleFilters} 
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-start',
                          flexDirection: 'column',
                          gap: '6px'
                        }}
                      >
                        <FormControlLabel
                          disabled={filters.filter.type === 'TEXT'}  
                          control={<CustomRadio />} 
                          value={'benmark-asc'}
                          label={<span className="flex-center" style={{ fontSize: '0.8rem' }}>{window.I18N('benmark_increased')}</span>}
                        />
                        <FormControlLabel
                          disabled={filters.filter.type === 'TEXT'}  
                          control={<CustomRadio />} 
                          value={'benmark-desc'}
                          label={<span className="flex-center" style={{ fontSize: '0.8rem' }}>{window.I18N('benmark_decreased')}</span>}
                        />
                        {/* <FormControlLabel 
                          control={<CustomRadio />} 
                          value={}
                          label={<span className="flex-center" style={{ fontSize: '0.8rem' }}>{'Benmark tăng'}</span>}
                        /> */}
                     </RadioGroup>
                    </Box>
                  </FormControl>
              </div> 
          </div>
        </div>  
      </MDBCol>
      <MDBCol size='9' className='pl-2'>
        <div className="item-form-question wow fadeIn animated" data-wow-delay="1s">
          <MDBCard className="card-box-shadow">
            <MDBCardHeader>                
              <div className='d-flex'>
                <div style={{ flex: '1 1 0%' }}>
                  <div><label className={`font-weight-bold label-box label-primary px-3 py-2 m-0`} style={{ fontSize: '1rem', lineHeight: '24px' }}>{window.COMMON.getValueFromAttr(props.group, 'nameLanguage')}</label></div>
                  <small>{window.COMMON.getValueFromAttr(props.group, 'descriptionLanguage')}</small>
                </div>
                <div className="dropdown ml-auto d-flex align-items-center">
                  {
                    object.important ? <span className="red-text btn-sm mx-1">
                      <MDBIcon fa="true" icon="exclamation-circle" className="fa-fw fa-lg"></MDBIcon>
                    </span> : <></>
                  }  
                  <MDBBtn outline className="btn btn-sm btn-transparent m-0" color="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-boundary="viewport">
                    <MDBIcon fa="true" icon="question-circle" className="fa-fw fa-lg" />
                  </MDBBtn>
                  <div className="dropdown-menu dropdown-menu-right" aria-labelledby={'dropdown-' + dataItem._id} data-boundary="viewport">
                    <small>{window.COMMON.getValueWithLanguage(dataItem, 'introduction', language)}</small>
                  </div>
                </div>
              </div>
            </MDBCardHeader>
            {(checkIsFilters() && window.COMMON.isEmpty(dataFilters)) ? <MDBCardBody className='d-flex align-items-center justify-content-center' style={{ minHeight: '565px' }}>{window.I18N('empty')}</MDBCardBody> : <MDBCardBody style={{ minHeight: '565px' }}>
              <MDBRow>
                <MDBCol md="12">
                  <p 
                    className="font-weight-bold mb-2 p-2 rounded-lg" 
                    style={{
                      backgroundColor: '#fff3cd'
                    }}
                  >
                    {object.index + 1 + '. ' + window.COMMON.getValueWithLanguage(dataItem, 'question', language)}
                  </p>
                </MDBCol>
                <MDBCol md="12" xs="12">
                  {
                    (dataItem.type === 'CHECKBOX_GRID' || dataItem.type === 'RADIO_GRID') ? layoutGridFormAnswer()
                      : dataItem.type === 'TEXT' ? layoutTextFormAnswer()
                        : dataItem.type === 'LEVEL' ? layoutLevelFormAnswer()
                          : layoutFormAnswer()
                  }
                  <p className="mb-3"></p>
                </MDBCol>
                {
                  props.isSelfAssessment ? <MDBCol md="12" xs="12" className='mt-4'>
                   <div>
                      <MDBRow>
                        <MDBCol md="6" xs="12" className='d-flex align-items-center justify-content-center'> 
                          <h6 className="font-weight-bold d-flex justify-content-start align-items-center" style={{ fontSize: "0.8rem", gap: '4px' }}>
                              {window.I18N('note')}
                          </h6>
                        </MDBCol>
                        <MDBCol md="6" xs="12" className='d-flex align-items-center justify-content-center'> 
                          <h6 className="font-weight-bold d-flex justify-content-start align-items-center" style={{ fontSize: "0.8rem", gap: '4px' }}>
                            {window.I18N('note_statistics')}
                          </h6>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol md="6" xs="12" className='border-right'>
                          <MDBInput 
  					  	            containerClass="mt-0 mb-2" 
                            outline type="textarea" 
                            name="note" 
                            value={window.COMMON.getValueFromAttr(dataFeedback[dataItem._id], 'note')} 
                            onChange={handleChange} 
                            maxLength="5000" 
                            disabled={props.isSubmited || !props.isCompany}
  					  /> 
                        </MDBCol>
                        <MDBCol md="6" xs="12" style={{ maxHeight: '200px', overflowX: 'overlay' }}>
  					  	          {getNoteData('advisor', dataItem._id).length > 0 && (
                            <div>
                             	<b><MDBIcon fa="true" icon="user-secret" className="fa-fw" /> {window.I18N('advisor_note')}</b>
                              <ul style={{ paddingLeft: 20}}>
                                {getNoteData('advisor', dataItem._id).map((item, i) => (
                                  <li key={i} className="mt-1">{item}</li>
                                ))}
                              </ul>
                            </div>
                          )}
                          {getNoteData('company', dataItem._id).length > 0 && (
                            <div>
                             	<b><MDBIcon fa="true" icon="building" className="fa-fw" /> {window.I18N('company_note')}</b>
                              <ul style={{ paddingLeft: 20}}>
                                {getNoteData('company', dataItem._id).map((item, i) => (
                                  <li key={i} className="mt-1">{item}</li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </MDBCol>
                      </MDBRow>
                   </div>
                    <MDBRow>
                      <MDBCol md="6" xs="12">
                        <p className="mb-1"><small className="text-muted">* {window.I18N('consultant_evaluation')}: <i className="text-danger" dangerouslySetInnerHTML={{__html: window.COMMON.getValueFromAttr(dataFeedback[dataItem._id], 'consultantEvaluation', '').replace(/\n/g, '<br>')}}></i></small></p>
                        <p><small className="text-muted">* {window.I18N('consultant_note')}: <i className="text-danger" dangerouslySetInnerHTML={{__html: window.COMMON.getValueFromAttr(dataFeedback[dataItem._id], 'consultantNote', '').replace(/\n/g, '<br>')}}></i></small></p>
                        <div className="text-center mb-2">
                          <InputMultiFile id={'file-upload'} icon="upload" handleFile={handleFile} description="Attached Documents"></InputMultiFile>
                        </div>
                        <div className="container-list-file wow fadeIn animated" data-wow-delay="0.5s">
                          {window.COMMON.getValueFromAttr(dataFeedback[dataItem._id], 'documents', []).map((item, i) => renderFiles(item, i, true))}
                        </div>
                      </MDBCol>
					  <MDBCol md="6" xs="12">
                        {getAttachmentData('advisor', dataItem._id).length > 0 && (
                          <div>
                            <b><MDBIcon fa="true" icon="user-secret" className="fa-fw" /> {window.I18N('advisor_attachment')}</b>
                            {getAttachmentData('advisor', dataItem._id).map((item, index) => renderFiles(item, index))}
                          </div>
                        )}
                        {getAttachmentData('company', dataItem._id).length > 0 && (
                          <div>
                            <b><MDBIcon fa="true" icon="building" className="fa-fw" /> {window.I18N('company_attachment')}</b>
                            {getAttachmentData('company', dataItem._id).map((item, index) => renderFiles(item, index))}
                          </div>
                        )}
					  </MDBCol>
                    </MDBRow>
                  </MDBCol> : <></>
                }
              </MDBRow>
            </MDBCardBody>}
            <MDBCardFooter>
              <div className="d-flex justify-content-center align-items-center">
                <MDBBtn 
                  outline 
                  className="btn btn-sm" 
                  type="button" 
                  color="" 
                  disabled={object.index === 0} 
                  onClick={object.index === 0 ? () => {} : (event) => {
                    event.stopPropagation();
                    handlePageWithFilters(object.index-1, checkIsFilters() ? dataFilters : data);
                  }} 
                  style={{ minWidth: '150px' }}
                >
                  <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('btn_previous')}
                </MDBBtn>
                <div className="p-1 bd-highlight mr-2 ml-2">
                  <MDBInput 
                    outline 
                    containerClass="m-0" 
                    className="text-center form-control-sm" 
                    type="text" 
                    readOnly={true} 
                    value={ (object.index + 1) + '/' + (checkIsFilters() ? dataFilters.length : data.length) } 
                    maxLength="10" 
                    pattern="\S(.*\S)?" 
                    style={{ width: '80px' }}
                  >
                  </MDBInput>
                </div>
                <MDBBtn 
                  outline 
                  className="btn btn-sm" 
                  type="button" 
                  color="" 
                  disabled={object.index === (checkIsFilters() ? dataFilters.length - 1 : data.length - 1)} 
                  onClick={(props.isSubmited && object.index === data.length - 1) ? () => {} : (event) => {
                    event.stopPropagation();
                    handlePageWithFilters(object.index+1, checkIsFilters() ? dataFilters : data);
                  }} 
                  style={{ minWidth: '150px' }}
                >
                  {window.I18N('btn_next')} <MDBIcon fa="true" icon='arrow-right' className="fa-fw" />
                </MDBBtn>
              </div>
            </MDBCardFooter>
          </MDBCard>
        </div>
      </MDBCol>
    </MDBRow>
  </React.Fragment>;
});

export default ItemQAInfluencer;