import React from 'react';

const SelfAssessmentPDF = React.memo(function SelfAssessmentPDF(props) {

  const generateTemplate = () => {
    // HEADER
    const cols = [
      window.I18N('answer'),
      window.I18N('organization'),
      window.I18N('worker'),
      window.I18N('advisor')
    ];
    if (props.dataExport.selfAssessor && props.dataExport.selfAssessor.length > 0) {
      props.dataExport.selfAssessor.forEach(element => {
        cols.push(window.I18N('assessor') + ' - ' + window.COMMON.getValueFromAttr(element, 'influencer.name'));
      });
    }
    const percentCols = 100 / cols.length + ' %';
    // CONTENT
    const questions = window.COMMON.getValueFromAttr(props.dataExport, 'questions', []);
    if (props.dataExport && questions.length > 0) {
      return <div style={{ padding: '15px', color:'#616161' }}>
        <h5 style={{ textAlign: 'center', width: '100%', paddingTop: '15px', paddingBottom: '15px', fontWeight: 'bold', fontSize:'24px', color:'#616161' }}>{window.I18N('self_summary_report')}</h5>
        {
          questions.map((item, i) => <div key={i}>
            <p style={{ paddingTop: '30px', fontWeight: 'bold' }}>{ (i + 1) + '. ' + window.COMMON.getValueWithLanguage(item, 'question', props.langague) }</p>
            <table style={{ width: '100%', fontSize: '14px' }}>
       
              <tr style={{color:'#616161', fontWeight: 'bold'}}>
                {
                  cols.map((item, i) => <td style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word', fontWeight: 'bold' }} key={i}>{item}</td>)
                }
              </tr>
          
              {generateBodySelfAsssessment(item, cols.length, percentCols)}
            </table>
          </div>)
        }
      </div>;
    }
    return <h5 style={{ textAlign: 'center', width: '100%', padding: '15px', fontWeight: 'bold' }}>{window.I18N('self_summary_report')}</h5>;
  };

  const generateBodySelfAsssessment = (question, cols, percentCols) => {
    const assessor = (props.dataExport && props.dataExport.selfAssessor) || [];
    if (question.type === 'TEXT') {
      return <tbody style={{color:'#616161'}}>
        <tr>
          <td style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word' }}></td>
          <td style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word' }}>{question.answerCompany}</td>
          <td style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word' }}>{question.answerWorker}</td>
          <td style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word' }}>{question.answerInfluencer}</td>
          {
            assessor.map((item, j) => <td key={window.COMMON.genKey()} style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word' }}>{getTextAnswer(item, question)}</td>)
          }
        </tr>
      </tbody>;
    } else if (question.type === 'RADIO_GRID' || question.type === 'CHECKBOX_GRID') {
      const answers = window.COMMON.getArrsInArrs(question._id, props.dataExport.answers, 'question');
      const parent = answers.filter(function(item) { return item.parentCode === null; });
      return <>
        {
          parent.map((answer, i) => <tbody key={window.COMMON.genKey()} style={{color:'#616161'}}>
            <tr>
              <td style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word', borderBottom: '1px solid #eee' }} colSpan={cols}>
                {window.COMMON.getValueWithLanguage(answer, 'content', props.langague)}
              </td>
            </tr>
            {
              window.COMMON.getArrsInArrs(answer.code, answers, 'parentCode').map((child, i) => <tr key={window.COMMON.genKey()}>
                <td style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word', borderBottom: '1px solid #eee' }}>{window.COMMON.getValueWithLanguage(child, 'content', props.language)}</td>
                <td style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word', borderBottom: '1px solid #eee' }}>{child.answerCompany}</td>
                <td style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word', borderBottom: '1px solid #eee' }}>{child.answerWorker}</td>
                <td style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word', borderBottom: '1px solid #eee' }}>{child.answerInfluencer}</td>
                {
                  assessor.map((item, j) => <td key={window.COMMON.genKey()} style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word', borderBottom: '1px solid #eee' }}>{countAnswerAssessor(item, question._id, child._id)}</td>)
                }
              </tr>)
            }
            <tr style={{fontWeight:'bold'}}>
              <td style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word', borderBottom: '1px solid #eee', fontWeight: 'bold' }} colSpan={cols - 1}>{window.I18N('deviation_average')}</td>
              <td style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word', borderBottom: '1px solid #eee', fontWeight: 'bold' }}>{answer.average} %</td>
            </tr>
            <tr style={{fontWeight:'bold'}}>
              <td style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word', borderBottom: '1px solid #eee', fontWeight: 'bold' }} colSpan={cols - 1}>{window.I18N('bench_mark_deviation_average')}</td>
              <td style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word', borderBottom: '1px solid #eee', fontWeight: 'bold', color: 'red' }}>{answer.benmark} %</td>
            </tr>
          </tbody>)
        }
      </>;
    } else {
      const answers = window.COMMON.getArrsInArrs(question._id, props.dataExport.answers, 'question');
      return <tbody style={{color:'#616161'}}>
        {
          answers.map((answer, i) => <tr key={window.COMMON.genKey()}>
            <td style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word', borderBottom: '1px solid #eee' }}>{window.COMMON.getValueWithLanguage(answer, 'content', props.langague)}</td>
            <td style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word', borderBottom: '1px solid #eee' }}>{answer.answerCompany}</td>
            <td style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word', borderBottom: '1px solid #eee' }}>{answer.answerWorker}</td>
            <td style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word', borderBottom: '1px solid #eee' }}>{answer.answerInfluencer}</td>
            {
              assessor.map((item, j) => <td key={window.COMMON.genKey()} style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word', borderBottom: '1px solid #eee' }}>{countAnswerAssessor(item, question._id, answer._id)}</td>)
            }
          </tr>)
        }
        <tr style={{fontWeight:'bold'}}>
          <td style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word', borderBottom: '1px solid #eee', fontWeight: 'bold' }} colSpan={cols - 1}>{window.I18N('deviation_average')}</td>
          <td style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word', borderBottom: '1px solid #eee', fontWeight: 'bold' }}>{question.average} %</td>
        </tr>
        <tr style={{fontWeight:'bold'}}>
          <td style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word', borderBottom: '1px solid #eee', fontWeight: 'bold' }} colSpan={cols - 1}>{window.I18N('bench_mark_deviation_average')}</td>
          <td style={{ width: percentCols, padding: '7.5px', wordBreak: 'break-word', borderBottom: '1px solid #eee', fontWeight: 'bold', color: 'red' }}>{question.benmark} %</td>
        </tr>
      </tbody>;
    }
  };

  const getTextAnswer = (item, question) => {
    const obj = window.COMMON.getObjectInArrs(question._id, item.feedback, 'question._id');
    return (obj && obj.answerText) || '';
  };

  const countAnswerAssessor = (item, questionId, answerId) => {
    const obj = window.COMMON.getObjectInArrs(questionId, item.feedback, 'question._id');
    if (obj) {
      const check = window.COMMON.getObjectInArrs(answerId, obj.answers, '_id');
      if (check) {
        return 1;
      }
    }
    return 0;
  };

  return <>{generateTemplate()}</>;
});

export default SelfAssessmentPDF;