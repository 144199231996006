import React, { useImperativeHandle } from 'react';
import { MDBBtn, MDBIcon } from 'mdbreact';
import { IconButton } from '@material-ui/core';

const InputFile = React.forwardRef((props, ref) => {

  const openImportFile = () => {
    document.getElementById(props.id).value = null;
    document.getElementById(props.id).click();
  };

  const handleChange = async (e) => {
    window.showLoading();
    const files = e.target.files;
    const result = [];
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.match(/image.*/) && files[i].type.indexOf('gif') === -1) {
        const file = await resizeImage(files[i]);
        file.name = files[i].name;
        result.push(file);
      } else {
        result.push(files[i]);
      }
    }
    if (result && result.length > 0) {
      if (props.isMulti) {
        props.handleFile(result);
      } else {
        props.handleFile(result[0]);
      }
    }
    window.hideLoading();
  };

  const resizeImage = async (file) => {
    const dataConfig = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_CONFIG));
    const maxWidth = window.COMMON.getValueFromAttr(window.COMMON.getObjectInArrs("WIDTH_IMAGE",dataConfig,'name'), 'value', 2000);
    const maxHeight = window.COMMON.getValueFromAttr(window.COMMON.getObjectInArrs("HEIGHT_IMAGE", dataConfig, 'name'), 'value', 1500);
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement('canvas');
    const dataURItoBlob = function (dataURI) {
      const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
      const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const max = bytes.length;
      const ia = new Uint8Array(max);
      for (let i = 0; i < max; i++) {
        ia[i] = bytes.charCodeAt(i);
      } 
      return new Blob([ia], {
        type: mime
      });
    };
    const resize = function () {
      let srcWidth = image.width;
      let srcHeight = image.height;
      var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
      canvas.width = srcWidth*ratio;
      canvas.height = srcHeight*ratio;
      canvas.getContext('2d').drawImage(image, 0, 0, srcWidth*ratio, srcHeight*ratio);
      const dataUrl = canvas.toDataURL(file.type || 'image/png');
      return dataURItoBlob(dataUrl);
    };
    return new Promise(function (ok, no) {
      if (!file.type.match(/image.*/)) {
        no(new Error('Not an image'));
        return;
      }
      reader.onload = function (readerEvent) {
        image.onload = function () {
          return ok(resize());
        };
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  useImperativeHandle(ref, () => ({
    openImportFile
  }))

  if (props.children) {
    return <div className="d-inline-block text-center">
      <IconButton aria-label="add picture" disabled={props.disabled} onClick={props.disabled ? () => {} : () => openImportFile()}>
        { props.children }
      </IconButton>
      <input type="file" className="form-control file-upload" id={props.id} accept={props.accept} multiple={props.isMulti} onChange={handleChange} />
      <br></br> {props.description}
    </div>;
  }
  if (typeof window === 'undefined') {
    return null;
  }
  return <div className="d-inline-block text-center">
    <MDBBtn color={window.COMMON.getValue(props.color)} className={'btn-upload ' + props.className} type="button" disabled={props.disabled} onClick={props.disabled ? () => {} : () => openImportFile()}>
      <MDBIcon fa="true" icon={props.icon} className="fa-fw"/> {props.label}
    </MDBBtn>
    <input type="file" className="form-control file-upload" id={props.id} accept={props.accept} multiple={props.isMulti} onChange={handleChange} />
    <br></br> {props.description}
  </div>;
});

export default React.memo(InputFile);