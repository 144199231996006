const sortQuestionByDate = (questions) => {
  const sorQuestionsByDate = questions.sort((a, b) => {
    if (a.question.createdDate < b.question.createdDate) return -1;
    if (a.question.createdDate > b.question.createdDate) return 1;
    return 0;
  });
  return sorQuestionsByDate;
};

const findAnswerHighestScore = (dataAnswer, questionId) => {
  const scoreOfAnswer = dataAnswer.filter(n => n.question === questionId)
    .map(item => item.score);
  const answerHighestScore = Math.max(...scoreOfAnswer);
  return answerHighestScore;
};

const handleCalcAverage = (arr) => {
  const result = arr.reduce((arr, curr) => arr + curr, 0) / arr.length;
  return Number(result.toFixed(2));
};

const handleCalcAverageScoreOfCategory = (historyAnswers) => {
  const sumScores = historyAnswers.map((item) => {
    const { historyAnswer, answerHighestScore } = item;
    // sum of checkbox answer
    const sumOfAnswer = historyAnswer.reduce((arr, curr) => arr + curr.score, 0);
    return (sumOfAnswer / answerHighestScore) * 100;
  });
  return handleCalcAverage(sumScores);
};

export {
  handleCalcAverage,
  handleCalcAverageScoreOfCategory,
  sortQuestionByDate,
  findAnswerHighestScore
};