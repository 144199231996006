import gql from 'graphql-tag';



const QUERY_COUNT_GRIEVANCE = gql`
  query($input: CountGrivanceFilter!) {
    countGrievance(input: $input)
  }
`;

const QUERY_GRIEVANCE_DATA_CHART = gql`
  query($input: CountGrivanceFilter!) {
    grievanceDataChart(input: $input) {
      status
      createdDate
    }
  }
`;

const QUERY_COMPLAINT_DATA_INSIGHT = gql`
  query($input: ComplaintsDataInsightInput){
    queryComplaintDataInsight(input: $input){
        _id
        status
        name
        description
        attachments
        note
        createdDate
        updatedDate
        isDeleted
        email
        type {
          _id
          value
          type
        }
        full_name
        createdBy {
            name
            accountType
            role {
              _id
              name
            }
        }
        influencer {
            information {
                name
            }
        }
    }
  }
`



const GrievanceGRAPHQL = {
  QUERY_COUNT_GRIEVANCE,
  QUERY_GRIEVANCE_DATA_CHART,
  QUERY_COMPLAINT_DATA_INSIGHT
};

export default GrievanceGRAPHQL;