import styled from 'styled-components';


const convertStatusToBackgroundColor = (status) => {
  if (status === 'waiting') {
    return '#E3E5ED';
  }
  if (status === 'processing') {
    return 'linear-gradient(90.24deg, #7AA8FF 3.75%, #E6C9B5 99.82%)';
  }
  return '#28B68333';
};

const convertStatusToTextColor = (status) => {
  if (status === 'waiting') {
    return '#AEB0BD';
  }
  if (status === 'processing') {
    return '#FFFFFF';
  }
  return '#10C986';
};

export const StepCardStyled = styled.div`
    border-radius: 8px;
    padding: 12px 16px;
    cursor: pointer;
    background: ${({status}) => convertStatusToBackgroundColor(status)};
    & .content{
        color: ${({status}) => convertStatusToTextColor(status)};
    }
`;

export const SurveyPagePaperStyled = styled.div`
    background-color: ${({backgroundColor}) => backgroundColor || '#FFFFFF'};
    width: 100%;
    margin-top: 15px;
    border: 8px;
    padding: 15px 15px;
    border-radius: 8px;
`;

export const StepButtonsContainer = styled.div`
  position: fixed;
  bottom: 28px;
  right : ${({isFull}) => isFull === true ? '0px' : '100px'};
  width: auto;
  padding: 0px 20px;
  .btn-back{
    background-color: #FFFFFF !important;
    color: black;
  }
  /* .btn-back:hover{
    background-color: #FFFFFF !important;
    color: black;
  } */
`;