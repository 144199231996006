// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n    --secondary-text: #65676b;\n}\n\n.EventItem_event-item-container__1iMrJ {\n    padding-bottom: 50px;\n    position: relative;\n    width: calc(50% - 8px);\n    height: 440px;\n    cursor: pointer;\n    transition: all 0.2s linear;\n}\n\n.EventItem_event-item-container__1iMrJ:hover {\n    background-color: #f0f2f5;\n}\n\n.EventItem_event-date-time__S4SkX {\n    font-size: 0.8rem;\n    font-weight: 500;\n}\n\n.EventItem_event-name__2lo0D {\n    display: -webkit-box;\n    -webkit-line-clamp: 2;\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n    font-size: 1rem;\n    font-weight: 600;\n}\n\n.EventItem_event-description__3SFYD {\n    display: -webkit-box;\n    -webkit-line-clamp: 2;\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n    font-size: 0.9rem;\n    color: var(--secondary-text);\n    font-weight: 600;\n}\n\n.EventItem_event-members__KFO_H {\n    font-size: 0.7rem;\n    color: var(--secondary-text);\n    font-weight: 400;\n}\n\n.EventItem_button-container__1rUbB {\n    position: absolute;\n    width: 100%;\n    bottom: 8px;\n}\n", ""]);
// Exports
exports.locals = {
	"event-item-container": "EventItem_event-item-container__1iMrJ",
	"event-date-time": "EventItem_event-date-time__S4SkX",
	"event-name": "EventItem_event-name__2lo0D",
	"event-description": "EventItem_event-description__3SFYD",
	"event-members": "EventItem_event-members__KFO_H",
	"button-container": "EventItem_button-container__1rUbB"
};
module.exports = exports;
