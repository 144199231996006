const learningEvent = {
  ADD_MENU_LEARNING: 'ADD_MENU_LEARNING',
  EDIT_MENU_LEARNING: 'EDIT_MENU_LEARNING',
  DEL_MENU_LEARNING: 'DEL_MENU_LEARNING',
  ADD_LEARNING: 'ADD_LEARNING',
  EDIT_LEARNING: 'EDIT_LEARNING',
  DEL_LEARNING: 'DEL_LEARNING',
  DEL_QUESTION_LEARNING: 'DEL_QUESTION_LEARNING',
  CHECK_IMPORT_QUESTION_LEARNING: 'CHECK_IMPORT_QUESTION_LEARNING',
  IMPORT_QUESTION_LEARNING: 'IMPORT_QUESTION_LEARNING'
};

export default learningEvent;