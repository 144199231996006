import React, { useState, useLayoutEffect } from 'react';

const Loading = React.memo(function Loading(props) {
  const [visible, setVisible] = useState(false);

  useLayoutEffect(() => {
    window.showLoading = showLoading;
    window.hideLoading = hideLoading;
  }, []);

  const showLoading = () => {
    setVisible(true);
  };

  const hideLoading = () => {
    setVisible(false);
  };

  return (
    <div>
      <div className={visible ? 'bg_load animated fadeIn' : 'bg_load animated d-none'}></div>
      <div className={visible ? 'wrapper animated fadeIn' : 'wrapper animated d-none'}>
        <div className="inner">
          <span>L</span>
          <span>o</span>
          <span>a</span>
          <span>d</span>
          <span>i</span>
          <span>n</span>
          <span>g</span>
        </div>
      </div>
    </div>
  );
});

export default Loading;