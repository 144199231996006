import React, { useEffect, useImperativeHandle, useState } from "react"
import { MDBCol, MDBRow, MDBInput } from "mdbreact"
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { Box } from "@material-ui/core"
import { withStyles } from '@material-ui/core/styles'

const SEND_BILL_STEP = 2
const MIN_VALUE = 0
const MAX_VALUE = 100
const DEFAULT_VAT = 0
const VALUE_DISCOUNT_TYPE = 'value'
const PERCENT_DISCOUNT_TYPE = 'percent'

const CustomRadio = withStyles({
  root: {
    "& .MuiSvgIcon-root": {
      height: 15,
      width: 15,
    },
    padding: 3
  },
})(Radio);

const SetDiscountAndTaxStep = React.forwardRef(({data = {}, dataEdit = {}, isEdit = false, onNextStep = () => {}}, ref) => {
  const [vat, setVat] = useState(isEdit ? dataEdit.vat : DEFAULT_VAT)
  const [discountType, setDiscountType] = useState(isEdit && dataEdit?.discounts[0]?.percent ? PERCENT_DISCOUNT_TYPE : VALUE_DISCOUNT_TYPE)
  const [discounts, setDisCounts] = useState({
    name: '',
    value: '',
    percent: '',
    ...dataEdit.discounts[0]
  })

  const [amount, setAmount] = useState({
    VAT: 0,
    discount: 0,
    total: 0
  })

  useEffect(()=>{
    const amountAfterTax = handleTotal()
    setAmount(amountAfterTax)
  },[vat, discounts, data, discountType])

  const handleCalculateVAT = (data, percentVAT) => {
    const parseValue = parseFloat(percentVAT)
    return (data * parseValue)/100
  }

  const handleCalculateDiscount = (value, isPercent = false) => {
    const parseValue = parseFloat(value)
    const discount = isPercent ? data.amount * parseValue/100 : parseValue
    return discount
  }
  const handleTotal = () => {
    const { value, percent } = discounts
    const discount = handleCalculateDiscount((value || percent) || 0, discountType === PERCENT_DISCOUNT_TYPE ? true : false)
    const amountAfterDiscount = data.amount - discount
    const VAT = handleCalculateVAT(amountAfterDiscount, vat || 0)
    const total = amountAfterDiscount + VAT
    return {
      VAT,
      discount,
      total
    }
  }

  const handleGetDataOnStep = (event, getDataOnly = false) => {
    if (!window.COMMON.checkFormValidation('form-discount-tax', event)) {
      window.COMMON.showMessage('warning', 'MSG_CODE_010', window.I18N('MSG_CODE_010'))
      return
    }
    const { percent, value, name } = discounts
    const dataBill = {
      vat: parseFloat(vat) || 0,
      discounts: (name && (value || percent)) ? [{
        name,
        ...( discountType === PERCENT_DISCOUNT_TYPE ? { percent: parseFloat(percent)} : { value: parseFloat(value) })
      }] : [],
      isReadySendBill: false
    }
    onNextStep(getDataOnly ? null : SEND_BILL_STEP, dataBill)
  }

  const handleGetVAT = (e) => {
      let VAT = e.target.value.replace(/[^\d.]/g, '')
      const isMultiDot = VAT.split('.').length > 2
      if(isNaN(parseFloat(VAT)) || VAT.startsWith(' ') || isMultiDot){
        VAT = ''
      }
      if(VAT < MIN_VALUE){
        VAT = MIN_VALUE
      }else if(VAT > MAX_VALUE){
        VAT = MAX_VALUE
      }
      setVat(VAT)
  }

  const handleGetDiscounts = (e) => {
      const isPercent = discountType === PERCENT_DISCOUNT_TYPE
      let discountTarget = e.target.value.replace(isPercent ? /[^\d.]/g : /[^\d]/g, '')   
      const isDecimal = discountTarget.split('.').length > 1
      const isMultiDot = discountTarget.split('.').length > 2
      if(isNaN(parseFloat(discountTarget)) || discountTarget.startsWith(' ') || (!isPercent && isDecimal) || isMultiDot){
        discountTarget = ''
      }else{
        if(isPercent){
          const percentage = parseFloat(discountTarget)
          if(percentage < MIN_VALUE){
            discountTarget = MIN_VALUE
          }else if(percentage > MAX_VALUE){
            discountTarget = MAX_VALUE
          }
        }
        else if(discountTarget > data.amount){
          discountTarget = data.amount
        }
      }
      const dataSetDiscount = {
        name: `Discount ${isPercent ? `${discountTarget}%` : `${window.COMMON.formatCurrency(discountTarget)}`}`,
        percent: isPercent ? discountTarget : '',
        value: !isPercent ? discountTarget : ''
      }
      setDisCounts((prev) => ({...prev,...dataSetDiscount}))
  }

  const handleSelectTypeDiscount = (e) => {
    const type = e.target.value
    setDisCounts(() => ({
      name: `Discount ${window.COMMON.formatCurrency(0)}`, 
      value: '', 
      percent: ''
    }))
    setDiscountType(type)
  }

  useImperativeHandle(ref, () => ({
      nextStep: () => {
        const isNextStep = window.COMMON.checkValue(vat)
        handleGetDataOnStep(ref, true)
        return isNextStep
      }
    })
  )

  return (
    <form className="w-full rounded-lg font-weight-bold" id="form-discount-tax">
      <div className="p-5 w-full bg-white rounded-lg flex-center align-items-start flex-column" style={{ gap: '20px' }}>
        <h6
          className="pb-4 border-bottom font-weight-bold"
          style={{ fontSize: "1.2rem", width: '100%' }}
        >
          {window.I18N('bill_summary')}
        </h6>
        <MDBRow className="py-2 w-full" style={{ width: '100%' }}>
          <MDBCol>
            <span 
              className="pb-2"
              style={{ fontSize: "1rem" }}
            >
              {data.packages[0].name}
            </span>
          </MDBCol>
          <MDBCol className="flex-center">
            <span 
              className="pb-2"
              style={{ fontSize: "1rem" }}
            >
              {window.COMMON.formatCurrency(data.amount)}
            </span>
          </MDBCol>
        </MDBRow>
        <MDBRow className="py-2" style={{ width: '100%' }}>
          <MDBCol>
            <label className={`font-weight-bold label-box label-primary p-1 m-0`}>{`${data.packages[0].subscriptionValue} ${data.packages[0].subscriptionType}`}</label>
          </MDBCol>
        </MDBRow>
        <MDBRow className="py-2">
          <MDBCol>
            <span style={{ fontSize: "0.7rem" }}>{`${window.COMMON_DATE.getFormatDate(data.packages[0].from)} - ${window.COMMON_DATE.getFormatDate(data.packages[0].to)}`}</span>
          </MDBCol>
        </MDBRow>

        {/* Package Information */}
        <MDBRow className="py-2 mt-2 border-top" style={{ width: '100%' }}>
          <MDBCol>
            <span>{window.I18N('apply_discount')}</span>
          </MDBCol>
          <MDBCol className="flex-center">
            <div
              className="w-25"
              style={{
                position: 'relative'
              }}
            >
              <MDBInput
                value={discounts.value || discounts.percent}
                onChange={handleGetDiscounts}
                outline
                type="text"
                containerClass="m-0 w-full"
                className="text-center font-weight-bold"
                style={{ fontSize: "0.8rem" }}
              />
                <FormControl component="fieldset" style={{
                    position: 'absolute', 
                    top: '-5px', 
                    right: '-175px',
                    height: '100%'
                }}>
                  <Box sx={{
                    "& .MuiFormControlLabel-root": {
                      margin: 0
                    }
                  }}>
                    <RadioGroup 
                      aria-label="Discount Type" 
                      name="discountType" 
                      value={discountType} 
                      onChange={handleSelectTypeDiscount}
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start'
                      }}
                    >
                      <FormControlLabel 
                        value="value" 
                        control={<CustomRadio color="primary"/>} 
                        label={<span className="font-weight-bold flex-center" style={{ fontSize: '0.7rem' }}>{window.I18N('direct_discount')}</span>}
                      />
                      <FormControlLabel 
                        value="percent" 
                        control={<CustomRadio color="primary"/>} 
                        label={<span className="font-weight-bold flex-center" style={{ fontSize: '0.7rem' }}>{window.I18N('percent_discount')}</span>}
                      />
                   
                    </RadioGroup>
                  </Box>
                </FormControl>
              </div>
          </MDBCol>
        </MDBRow>

        <MDBRow className="py-2 border-bottom" style={{ width: '100%' }}>
          <MDBCol>
            <span>VAT *</span>
          </MDBCol>
          <MDBCol className="flex-center">
            <div 
              className="w-25"
              style={{
                position: 'relative'
              }}>
                <MDBInput
                  value={vat}
                  onChange={handleGetVAT}
                  outline
                  type="text"
                  containerClass="m-0 w-full"
                  className="text-center font-weight-bold"
                  style={{ fontSize: "0.8rem" }}  
                  required     
                />
                <span style={{
                    position: 'absolute', 
                    top: 0, 
                    right: '-115px', 
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '0.7rem'
                  }}>{window.I18N('percentage')}</span>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow className="py-2" style={{ width: '100%' }}>
          <MDBCol>
            <span>{window.I18N('discount')}</span>
          </MDBCol>
          <MDBCol className="flex-center">
            <span>{window.COMMON.formatCurrency(amount.discount)}</span>
          </MDBCol>
        </MDBRow>
        <MDBRow className="py-2 border-bottom" style={{ width: '100%' }}>
          <MDBCol>
            <span>VAT</span>
          </MDBCol>
          <MDBCol className="flex-center">
            <span>{window.COMMON.formatCurrency(amount.VAT)}</span>
          </MDBCol>
        </MDBRow>

        <MDBRow className="py-2" style={{ width: '100%' }}>
          <MDBCol>
            <span style={{ fontSize: "1rem" }}>{window.I18N('total_amount')}</span>
          </MDBCol>
          <MDBCol className="flex-center">
            <span style={{ fontSize: "1rem" }}>{window.COMMON.formatCurrency(amount.total || data.amount)}</span>
          </MDBCol>
        </MDBRow>
      </div>
    </form>
  )
})

export default SetDiscountAndTaxStep;
