import { useState, useEffect, useCallback } from 'react';
import CaseAssessmentGRAPHQL from '../../../../graphql/CaseAssessment';
import Common from '../../../../utils/Common';

const findAssessmentCreatorCompany = async(assessment) => {
  if (!assessment._id) {
    return {};
  }

  if (assessment.createdBy) {
    return assessment.createdBy;
  } else {
    const res = await Common.query(CaseAssessmentGRAPHQL.QUERY_GET_CASE_BY_SELF_ASSESSMENTS, {
      selfAssessmentIds: [assessment._id]
    });

    if (res?.data?.getCaseAssessmentsBySelfAssessmentIds?.length) {
      const caseAssessment = res.data?.getCaseAssessmentsBySelfAssessmentIds[0];

      return caseAssessment.createdBy;
    }
  }
};

export default function useAssessmentCreator(initSelfAssessment) {
  const [assessment, setAssessment] = useState(initSelfAssessment);
  const [ creator, setCreator ] = useState();

  const fetchData = useCallback(async () => {
    try {
      const author = await findAssessmentCreatorCompany(assessment);

      setCreator(author);
      
    } catch (error) {
      console.warn('Hook.useSelfAssessmentCreator.fetchData', error.message || 'Unknow');
    }
  }, [assessment]);

  useEffect(( ) => { 
    fetchData();
  }, [assessment]);

  return {
    creator,
    setAssessment
  };
}