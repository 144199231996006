import React, { useState, useEffect, useRef } from 'react';
import Messages from './Messsages';
import {CustomBodyChatRightSide} from '../../../../../styles/styled-components/Chat/ChatPage';
import socket from '../../../../../utils/socket';
import useSearchDebounce from '../../../../../hooks/useSearchDebounce';

const initFetchData = {
  loading: false,
  isHasMore: true,
  limit: 20,
  init: true
};

const BodyChatRightSide = ({messages, groupChatSelected, onSetMessages, onReply, allStickers}) => {
  const currentUserId = localStorage.getItem(window.CONSTANT.ID);
  const [fetchMessagesData, setFetchMessagesData] = useState(initFetchData);
  // initFetch === true ==> allow fetch
  const [initFetch, setInitFetch] = useState(false);
  const updateDebounce = useSearchDebounce();
  const fetchDataRef = useRef();
  fetchDataRef.current = {fetchMessagesData}; 
  useEffect(() => {
    socket.on('get-messages',handleGetMessagesEvent);
    socket.on('read-last-message');
    setInitFetch(true);
    return () => {
      socket.off('get-messages');
	  socket.off('read-last-message');
      setFetchMessagesData((current) => {
        return {
          ...current,
          ...initFetchData
        };
      });
      onSetMessages([]);
    };
  },[groupChatSelected._id]);

  useEffect(() => {
    if (initFetch === false) return;
    handleFetchMessages();
    setInitFetch(false);
  },[initFetch]);

  //read message
  useEffect(() => {
    if (Array.isArray(messages) && messages.length > 0) {
      readMessages(messages);
    };
    socket.on('read-messages', handleReadMessagesEvent);

    return () => {
      socket.off('read-messages');
    };
  }, [messages]);

    
    
  const handleFetchMessages = () => {
    if (fetchMessagesData.loading === true) return;
    setFetchMessagesData((current) => {
      return {
        ...current,
        loading: true
      };
    });
    getMessages();
  };
  const getMessages = () => {

    const filter = {
      limit: fetchMessagesData.limit,
      groupChatId: groupChatSelected._id
    };
    if (messages.length > 0) {
      const endMessage = messages[messages.length - 1];
      const lastId = endMessage._id || endMessage.id;
      filter.lastMessageId = lastId;
    }
    socket.emit('get-messages',filter,handleGetMessagesEventError);
  };
  const handleGetMessagesEvent = (data) => {
    const {messages, groupChatId} = data;
    if (groupChatSelected._id !== groupChatId) return;
    onSetMessages((current) => {
      //** */
      if (fetchDataRef.current) {
        if (fetchDataRef.current.fetchMessagesData?.init === true) {
          return [
            ...messages
          ];
        }
      }
      return [
        ...current,
        ...messages
      ];
    });


    setFetchMessagesData((current) => {
      const isHasMore = messages.length >= current.limit;
      return {
        ...current,
        loading:false,
        isHasMore,
        init: false
      };
    });


  };

  const handleGetMessagesEventError = (message) => {
    setFetchMessagesData((current) => {
      return {
        ...current,
        loading:false,
        isHasMore:false
      };
    });
  };

  const readLastMessage = (lastMessage) => {
    //if message of user, dont need to check
    if (!lastMessage || lastMessage.sender === currentUserId) return;
	
    const reader = lastMessage.reader;
	
    //case "reader" field is not exist
    if (!reader || !Array.isArray(reader)) {
      const data = {
        groupChatId: groupChatSelected._id,
        reader: [currentUserId]
      };
	  socket.emit('read-last-message',data);
	  return;
    }

    //case user have not read message
    if (!reader.includes(currentUserId)) {
      const data = {
        groupChatId: groupChatSelected._id,
        reader: [...reader, currentUserId]
      };
      socket.emit('read-last-message',data);
	  return;
    }
  };

  const readMessages =  async (messagesData) => {
    updateDebounce(() => { 
    //get current user id
      const currentUserId = localStorage.getItem(window.CONSTANT.ID);

      //get unread messages 
      const unreadMessages = [];
      messagesData.map(item => {
        if ((!Array.isArray(item.reader) || !item.reader.includes(currentUserId)) && item.sender !== currentUserId) {
          unreadMessages.push({
            id: item._id,
            reader: item.reader ? [...item.reader, currentUserId] : [currentUserId]
          });
          return {
            ...item, 
            reader: item.reader ? [...item.reader, currentUserId] : [currentUserId]
          };
        };
        return item;
      });

      if (unreadMessages.length > 0) {
        const data = {
          groupChatId: groupChatSelected._id,
          messages: unreadMessages
        };
        socket.emit('read-messages',data);
      };
    }, 3000);
  };

  const handleReadMessagesEvent = (data) => {
    const {result} = data;
	
    if (!Array.isArray(messages)) return;

    //update unread messages 
    const updateMessages = messages.map(item => {
      const index = result.findIndex(u => u.id === item._id);
      if (index > -1) {
        return {
          ...item,
          ...result[index]
        };
      }
      return item;
    });

    //message update after 3s
    setTimeout(() => {
      onSetMessages(updateMessages);

      readLastMessage(messages[0]);
    }, 1000);
  };

  return <CustomBodyChatRightSide className="beautiful-scroll py-3" id="body-chat-right-side" style={{ display: 'flex', flexDirection: 'column-reverse' }}>
    <Messages 
      parentId = "body-chat-right-side" 
      messages={messages}
      fetchLoading={fetchMessagesData.loading}
      isHasMore={fetchMessagesData.isHasMore}
      onFetchMessages={handleFetchMessages}
	    onReply={onReply}
      allStickers={allStickers}
    />
  </CustomBodyChatRightSide>;
};


export default BodyChatRightSide;