import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';

import translationEN from './en.json';
import translationVI from './vi.json';
import translationBD from './bd.json';
import translationIN from './in.json';
import translationKM from './km.json';
import translationPA from './pa.json';
import translationSR from './sr.json';

// the translations
const resources = {
  EN: {
    translation: translationEN
  },
  VI: {
    translation: translationVI
  },
  KO: {
    translation: translationEN
  },
  BD: {
    translation: translationBD
  },
  IN: {
    translation: translationIN
  },
  KM: {
    translation: translationKM
  },
  PA: {
    translation: translationPA
  },
  SR: {
    translation: translationSR
  }
};
  
i18n
  .use(detector)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'EN', // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
  
export default i18n;