import React from 'react'; 
import { MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';

const GridItemSelfAssessment = React.memo(function GridItemSelfAssessment(props) {

  const handleItemClick = (event, row, col) => {
    event.stopPropagation();
    if (props.handleItemClick) {
      props.handleItemClick(row, col);
    }
  };

  const handleEditClick = (event, row, col) => {
    event.stopPropagation();
    if (props.handleItemEditClick) {
      props.handleItemEditClick(row, col);
    }
  };

  const handlePreviewClick = (event, index) => {
    event.stopPropagation();
    if (props.handlePreviewClick) {
      props.handlePreviewClick(index);
    }
  };

  const handleViewReminder = (event, reminderIds, indexDataTask) => {
    event.stopPropagation();
    if (props.handleViewReminder) {
      props.handleViewReminder(reminderIds, indexDataTask);
    }    
  }

  const getDraftData = (assessmentId, groupId) => {
    const userId = localStorage.getItem('id');
    const draft = JSON.parse(localStorage.getItem('draftAssessment') || '[]');

    const dataDraft = draft.find(item => item.user === userId && item.assessment === assessmentId && item.group === groupId);
    if (dataDraft) {
	  const questions = dataDraft.questions;
      const feedback = Object.keys(dataDraft.feedback).map((k) => dataDraft.feedback[k]);
	  const percentFinished = parseInt(feedback.length / questions.length * 100);
	  return (
        <small className="text-muted">
          <MDBIcon fa="true" icon="clipboard" className="fa-fw" /> {window.I18N('draft')} <b>{percentFinished}% ({feedback.length}/{questions.length})</b>
        </small>
      );
    }
    return <></>;
  };
  
  if (!props.data || props.data.length === 0) {
    return <li className="list-group-item p-3 text-center">{window.I18N('empty_table')}</li>;
  }
  return (
    <>
      {
        props.data.map((item, i) => 
          <li className="list-group-item p-3" key={i}>
            <MDBRow className={(item.status === -2 ? ' disabled' : '')}>
              <MDBCol>
                <p className="font-weight-bold mb-1">
                  {window.COMMON.getValueFromAttr(item, 'selfAssessment.name')}
                </p>
                {
                  !props.showCompany ? <></> : <p className="mb-1">
                    <MDBIcon fa="true" icon="building" className="fa-fw mr-1"></MDBIcon>
                    <small className="text-muted">{window.COMMON.getValueFromAttr(item, 'company.information.name')}</small>
                  </p>
                }
                <p className="mb-1">
                  <MDBIcon fa="true" icon="clock" className="fa-fw mr-1"></MDBIcon>
                  <small className="text-muted">{window.COMMON.getValueFromAttr(item, 'time')}</small>
                </p>
                <p className="mb-3">
                  <MDBIcon fa="true" icon="calendar-alt" className="fa-fw mr-1"></MDBIcon>
                  <small className="text-muted">{window.COMMON.getValueFromAttr(item, 'createdDate')}</small>
                </p>
                {
                  item.status === 1 ? <label className="label-box label-info p-1 m-0">{window.I18N('submited')}</label> 
                    : item.status === -2 ? <label className="label-box label-danger p-1 m-0">{window.I18N('expired')}</label> 
                      : item.status === -1 ? <label className="label-box label-warning p-1 m-0">{window.I18N('redo')}</label> 
                        : item.status === 2 ? <label className="label-box label-success p-1 m-0">{window.I18N('approved')}</label> 
                          : <label className="label-box label-primary p-1 m-0">{window.I18N('in_proccessing')}</label>
                }
              </MDBCol>
              <MDBCol>
                {
                  window.COMMON.getValueFromAttr(item, 'items', []).map((child, j) => <div className={'d-flex align-items-start flex-column bd-highlight' + (j === 0 ? '' : ' mt-4')} key={i *j + j}>
                    <div className="w-100 d-flex align-items-center">
                      <p className="mb-1 mr-auto font-weight-bold text-anchor" onClick={item.status === -2 ? () => {} : (event) => handleItemClick(event, item.indexDataTask||i, j)}>
                        <MDBIcon fa="true" icon="circle" style={{ color: child.isCompleted ? '#00c851' : '#dddddd' }} className="fa-fw mr-1"></MDBIcon>
                        {window.COMMON.getValueFromAttr(child, 'nameLanguage')}
                      </p>
                      {
                        !item.isCompleted ? (
                          <p className="m-0">{getDraftData(item._id, child.group?._id)}</p>
                        ) : (
                          <p className="m-0">
                            <small className="text-muted">{window.I18N('completed_by')} <b>{window.COMMON.getValueFromAttr(child, 'updatedBy.name')}</b></small>
                          </p>
                        )
                      }
                    </div>
                    <div className="bd-highlight w-100">
                      <div className="d-flex align-items-center">
                        <p className="m-0 mr-auto">
                          <MDBIcon fa="true" icon="qrcode" className="fa-fw mr-1"></MDBIcon>
                          <small className="text-muted">{window.COMMON.getValueFromAttr(child, 'group.code')}</small>
                        </p>
                        {
                          !item.isCompleted ? <></> : <p className="m-0"><small className="text-muted">{window.I18N('completed_date')} <b>{window.COMMON_DATE.formatDate(window.COMMON.getValueFromAttr(child, 'updatedDate'))}</b></small></p>
                        }
                      </div>
                    </div>
                    {
                      props.isHidePreview || props.showCompany ? <></> : <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" disabled={item.status === -2} onClick={item.status === -2 ? () => {} : (event) => handleEditClick(event, i, j)}>
                        <small className="text-muted mr-1">{window.COMMON.getValueFromAttr(child, 'users', []).length}</small>
                        <MDBIcon fa="true" icon="users" style={{ color: 'orange' }} className="fa-fw" />
                      </MDBBtn>
                    }
                  </div>)
                }
                <div className="text-right mt-2">
                  {
                    !props.isHidePreview && item.status !== -2 && item.isCompleted ? <MDBBtn outline className="btn btn-sm m-0" type="button" color="" onClick={(event) => handlePreviewClick(event, item.indexDataTask||i)}>
                      <MDBIcon fa="true" icon="eye" className="fa-fw" /> {window.I18N('preview')}
                    </MDBBtn> : <></>
                  }
                  {Number.isInteger(item.indexDataTask) && item.reminderIds?.length && <MDBBtn outline className="btn btn-sm m-0 ml-2" type="button" color="" onClick={(event) => handleViewReminder(event, item.reminderIds, item.indexDataTask)}>
                      <MDBIcon fa="true" icon="stopwatch" className="fa-fw" /> {window.I18N('preview_reminder')}
                    </MDBBtn>}
                </div>
              </MDBCol>
            </MDBRow>
          </li>
        )
      }
    </>
  );
});

export default GridItemSelfAssessment;