import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { MDBBtn, MDBIcon } from 'mdbreact';

const Notification = React.memo(function Notification(props) {

  window.closeSaveMethod = () => {}
  window.closeConfirmMethod = () => {}
  useLayoutEffect(() => {
    window.showNotification = showNotification;
  }, [props.language]);

  const showNotification = (type, title, message) => {
    switch (type) {
      case 'info':
        NotificationManager.info(message, title, 3000);
        break;
      case 'success':
        NotificationManager.success(message, title, 3000);
        break;
      case 'warning':
        NotificationManager.warning(message, title, 3000);
        break;
      case 'error':
        NotificationManager.error(message, title, 3000);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <NotificationContainer />
      <div className="modal fade bottom" id="modal-save" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-frame modal-bottom" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row d-flex justify-content-center align-items-center">
                <p className="pt-3 pr-2">{window.I18N('save_record')}</p>
                <MDBBtn outline color="warning" data-dismiss="modal" onClick={() => window.closeSaveMethod()}>
                  <MDBIcon fa="true" icon="times" className="fa-fw" /> {window.I18N('btn_close')}
                </MDBBtn>
                <MDBBtn outline color="primary" data-dismiss="modal" onClick={() => window.saveMethod()}>
                  <MDBIcon fa="true" icon="save" className="fa-fw" /> {window.I18N('btn_save_changes')}
                </MDBBtn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade bottom" id="modal-delete" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-frame modal-bottom" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row d-flex justify-content-center align-items-center">
                <p className="pt-3 pr-2">{window.I18N('delete_record')}</p>
                <MDBBtn outline color="danger" data-dismiss="modal">
                  <MDBIcon fa="true" icon="times" className="fa-fw" /> {window.I18N('btn_close')}
                </MDBBtn>
                <MDBBtn outline color="success" data-dismiss="modal" onClick={() => window.deleteMethod()}>
                  <MDBIcon fa="true" icon="check" className="fa-fw" /> {window.I18N('btn_yes')}
                </MDBBtn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade bottom" id="modal-confirm" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-frame modal-bottom" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row d-flex justify-content-center align-items-center">
                <p className="pt-3 pr-2">{window.I18N('confirm_action')}</p>
                <MDBBtn outline color="danger" data-dismiss="modal" onClick={() => window.closeConfirmMethod()}>
                  <MDBIcon fa="true" icon="times" className="fa-fw" /> {window.I18N('btn_close')}
                </MDBBtn>
                <MDBBtn outline color="success" data-dismiss="modal" onClick={() => window.confirmMethod()}>
                  <MDBIcon fa="true" icon="check" className="fa-fw" /> {window.I18N('btn_yes')}
                </MDBBtn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const mapStateToProps = state => ({
  language: state.language.value,

});

const mapDispatchToProps = {};

export default  connect(mapStateToProps, mapDispatchToProps)(Notification);