import React, { useState, useCallback, useEffect, useRef } from 'react';
import QuestionGRAPHQL from '../../../graphql/Question';
import { MDBRow, MDBCol, MDBInput, MDBBtn, MDBIcon, MDBInputGroup } from 'mdbreact';
import SingleQuestionTemplate from '../../components/SingleQuestionTemplate';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';
import Modal from '../../components/Modal';
import Select from 'react-select';
import $ from 'jquery';
import Pagination from '../../components/Pagination';


const optionsTypes = [
    { value: 'TEXT', label: 'Text' },
    { value: 'RADIO', label: 'Radio' },
    { value: 'CHECKBOX', label: 'Checkbox' },
    { value: 'LEVEL', label: 'Level' },
    /* { value: 'RADIO_GRID', label: 'Radio Grid' },
    { value: 'CHECKBOX_GRID', label: 'Checkbox Grid' }, */
];
const MAX_NUMBER_OF_ANSWERS = 10;
const bg = ['bg-danger', 'bg-warning', 'bg-info', '', 'bg-success'];

function QuestionBankPage(props) {
    const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
    const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);

    const { trackEvent } = useTracking();
    const [dataQuestion, setDataQuestion] = useState([]);
    const [dataSave, setDataSave] = useState({});
    const [optionsCategory, setOptionsCategory] = useState([]);
    const [optionsFilterCategory, setFilterOptionsCategory] = useState([]);
    const [dataSaveAnswer, setDataSaveAnswer] = useState([]);
    const dataDeletedAnswer = useRef([]);
    const [pagination, setPagination] = useState({ total: 0, pageSize: 10, currentPage: 0 });
    const [filter, setFilter] = useState({text:""})

    const [select, setSelect] = useState({
        category: null,
        categoryModal: null,
        type: optionsTypes[0]
    });

    const createTrackingEvent = (event) => {
        return trackEvent({
            name: event,
            createdAt: new Date().toISOString()
        });
    };

    const getCategoryByLanguage = (multiLanguageText) => {
        if (!multiLanguageText || multiLanguageText.length===0) return ""
        const result = multiLanguageText.find(el => el.code === props.language?.code)?.text || multiLanguageText[0]?.text ||""
        return result
    }

    const getEnText = (multiLanguageText) => {
        if (!multiLanguageText || multiLanguageText.length===0) return ""
        const result = multiLanguageText.find(el => el.code === 'EN')?.text || ""
        return result
    }

    const getQuestionBankData = async () => {
        try {
            if (!select.category?.value) return
            const input = {
                order: 'ASC',
                pagination: {
                    pageSize: pagination.pageSize,
                    page: pagination.currentPage + 1
                },
                question: { categoryId: select.category.value}
            }
            if (filter.text) {
                input.question.name = filter.text
            }
            let data = []
            const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_QUESTION_BANK, {input});
            if (result?.data?.getQuestionBank) {
                data = window.COMMON.formatDate(result?.data?.getQuestionBank?.data, 'createdDate');
                
                data.forEach((item) => {
                    if (item.choices?.length) {
                        item.choices.forEach((answer) => {
                            delete answer['__typename'];
                            if (answer?.content?.length) {
                                answer.content.forEach((content) => {
                                    delete content['__typename']
                                })
                            }
                        })
                    }
                })
                pagination.total = result?.data?.getQuestionBank?.total
                setPagination(pagination)
                setDataQuestion(data);
            }
        } catch (error) {
            console.log(error);
            window.COMMON.showErrorLogs('QuestionBankPage.getQuestionBankData');
        }
    };

    const getCategorydata = async () => {
    try {
        //const allOption = { value: null, label: props.language.code === "EN" ? "All category" : "Tất cả" }
        const params = {
                company: COMPANY_ID,
                companyType: COMPANY_TYPE
            };
        const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_QUESTION_CATEGORY, params);
        if (result?.data?.getAllQuestionCategories && props.language.code) {
            const optionsCategory = result?.data?.getAllQuestionCategories.map(el => ({ value: el._id, label: getCategoryByLanguage(el.title) }))
            setOptionsCategory(optionsCategory);
            setFilterOptionsCategory([...optionsCategory])
            select.category= optionsCategory[0]
            setSelect({ ...select})
            getQuestionBankData();
        }
    } catch (error) {
        window.COMMON.showErrorLogs('QuestionBankPage.getCategorydata');
    }};

    const openSaveDialog = useCallback((questionIndex) => {
        window.COMMON.showModal('#modal-questionBank');
        const dataSave = { };
        dataDeletedAnswer.current = []
        if (questionIndex === -1) {
            dataSave.introduction = '';
            dataSave.question = '';
            
            select.categoryModal = select.category
            select.type = optionsTypes[0]
            
            setDataSaveAnswer([])
        } else {
            dataSave._id = dataQuestion[questionIndex]._id
            dataSave.introduction = dataQuestion[questionIndex].introduction;
            dataSave.question = dataQuestion[questionIndex].question
            
            select.type = optionsTypes.find(el=>el.value === dataQuestion[questionIndex].type)
            select.categoryModal = optionsCategory.find(el => el.value === dataQuestion[questionIndex].questionCategory._id)
                        
            let dataSaveAnswer = dataQuestion[questionIndex].choices
            setDataSaveAnswer([...dataSaveAnswer])
        }
        setSelect({ ...select });
        setDataSave({ ...dataSave });
    }, [dataQuestion, optionsCategory]);

    const openDeleteMenuDialog = useCallback((index) => {
        window.COMMON.showModal('#modal-delete');
        window.deleteMethod = ()=>deleteQuestion(index);
    });

    const handleChange = (event) => {
        if (event.target.className.indexOf('input-number') > -1) {
            dataSave[event.target.name] = parseInt(event.target.value);
        } else {
            dataSave[event.target.name]= event.target.value
        }
        setDataSave({ ...dataSave });
    };

    const handleSelect = (event, id, attr) => {
        window.COMMON.checkSelectValidation(id, event);
        if (JSON.stringify(select[attr])  === JSON.stringify(event)){ return }
        select[attr] = event;
        if (attr === 'type') {
            if (dataSave._id){return}
            let newDataSaveAnswer = []
            if (event?.value === 'TEXT') {
                setDataSaveAnswer(newDataSaveAnswer)
            } else if ((event?.value === 'RADIO' || event?.value === 'CHECKBOX' || event?.value === 'LEVEL') && dataSaveAnswer.length===0) {
                newDataSaveAnswer = [
                    {
                        code: window.COMMON.generateCode('QB-A')+ '-' + 1,
                        content: [],
                        question: dataSave._id,
                        parentCode:  null
                    },
                    {
                        code: window.COMMON.generateCode('QB-A')+ '-' + 2,
                        content: [],
                        question: dataSave._id,
                        parentCode:  null
                    }
                ]
                setDataSaveAnswer(newDataSaveAnswer)
            } else if (event?.value === 'RADIO_GRID' || event?.value === 'CHECKBOX_GRID') {
                
            }
            setDataSave({ ...dataSave });
        } else if (attr === 'category') {
            pagination.currentPage = 0
            getQuestionBankData()
        }
        setSelect({ ...select });
    };

    const addAnswer = (code) => {
        if (dataSaveAnswer.length === MAX_NUMBER_OF_ANSWERS) {
            return
        } else {
            dataSaveAnswer.push({
                code: window.COMMON.generateCode('QB-A'),
                content: [],
                question: dataSave._id,
                parentCode: code || null
            });
            $("#modal-questionBank .modal-body").animate({scrollTop: $('#add-answer-btn').offset().top}, "slow")
            setDataSaveAnswer([...dataSaveAnswer]);
        }
    };

    const deleteAnswer = (code) => {
        if (dataSaveAnswer.length<3) return
        for (let i = 0; i < dataSaveAnswer.length; i++) {
            const item = dataSaveAnswer[i];
            if (item.code === code || item.parentCode === code) {
                if (item._id) {
                    dataDeletedAnswer.current.push(item._id);
                }
                dataSaveAnswer.splice(i, 1);
                i--;
            }
        }
        setDataSaveAnswer([...dataSaveAnswer]);
    };

    const handleChangeAnswer = (event, item) => {
        window.COMMON.setValueWithLanguage(item, event.target.name, 'EN', event.target.value);
        setDataSaveAnswer([...dataSaveAnswer]);
    };

    const handleFilterText = (event) => {
        setFilter({...filter, text: event.target.value})
    }

    const handleSearchText = () => {
        pagination.currentPage = 0
        getQuestionBankData()
    }

    const generateFormAnswer = (i) => {
        if (select.type.value === 'CHECKBOX' || select.type.value === 'CHECKBOX_GRID') {
            return <MDBInput type="checkbox" containerClass="pl-2 pt-1" />;
        }
        if (select.type.value === 'LEVEL') {
            return <div className="progress mr-2" style={{ width: '100px' }}>
                <div className={'progress-bar progress-bar-striped progress-bar-animated w-100 ' + bg[i % 5]} role="progressbar"></div>
            </div>;
        }
        return <MDBInput type="radio" containerClass="pl-2 pt-1" />;
    };

    const saveQuestionData = async (event) => { 
        try {
            if (!window.COMMON.checkFormValidation('form-questionBankData', event)) {
                return;
            }
            const params = {
                input: {
                    question: {
                        ...dataSave,
                        code: window.COMMON.generateCode('QB-Q'),
                        isTemplate: true,
                        questionCategory: select.categoryModal.value,
                        type: select.type.value,
                    },
                    answers: dataSaveAnswer,
                    deletedAnswers: dataDeletedAnswer.current
                }
            }
            window.COMMON.trimData(params);
            if (!dataSave._id) {
                const result = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_ADD_POOL_QUESTION, params);
                if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
                    createTrackingEvent(eventName.ADD_QA_TEMPLATE);
                    window.COMMON.hideModal('#modal-questionBank');
                    getQuestionBankData();
                }
            } else {
                const result = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_EDIT_POOL_QUESTION, params);
                if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
                    createTrackingEvent(eventName.EDIT_QA_TEMPLATE);
                    window.COMMON.hideModal('#modal-questionBank');
                    getQuestionBankData();
                }
            }
        } catch (error) {
            window.COMMON.showErrorLogs('QuestionBankPage.saveQuestionData');
        }
    }

    const deleteQuestion = async (index) => {
        try {
            const params = {
                id: dataQuestion[index]._id
            };
            const result = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_DEL_POOL_QUESTION, params);
            if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
                createTrackingEvent(eventName.DEL_QA_TEMPLATE);
                getQuestionBankData();
            }
        } catch (error) {
            window.COMMON.showErrorLogs('QuestionBankPage.deleteQuestion');
        }
    }

    const changePage = useCallback((index) => {
        pagination.currentPage = index;
        setPagination(pagination);
        getQuestionBankData();
        // eslint-disable-next-line
    }, [ pagination, select.category]);

    useEffect(() => {
        if (props?.language?.code) {
            getCategorydata()
        }
    }, [props.language]);

    /* useEffect(() => {
        getQuestionBankData();
    },[]) */

    return (
        <div className='container-row'>
            <div className="container-tabs-content mb-1 rounded d-flex align-items-center justify-content-end wow fadeInDown animated" data-wow-delay="0.5s" style={{ zIndex: 1 }}>
                <div className="bd-highlight mr-2" style={{ width: '300px' }}>
                    <div className="input-group input-group-transparent">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                            <MDBIcon fa="true" icon="code-branch" className="fa-fw" />
                            </span>
                        </div>
                        <Select id='select-category' className="md-form m-0" placeholder={window.I18N('choose_category')} value={select.category} options={optionsFilterCategory} onChange={(event) => handleSelect(event, 'select-category', 'category')} isSearchable />
                    </div>
                </div>
                <div className="mr-2 ml-auto" style={{ width: '350px' }}>
                    <MDBInputGroup type="text" value={filter.text} hint={window.I18N('search_name')} onChange={handleFilterText} maxLength="100"  required
                        append={
                            <span className="input-group-text cursor-pointer" onClick={handleSearchText}>
                                <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                            </span>
                        }
                    />
                </div>
                <MDBBtn color="primary" onClick={() => openSaveDialog(-1)}>
                    <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
                </MDBBtn>
            </div>
            <div className='container-tabs-content wow fadeIn rounded' >
                        
                <Grid container  >
                    <Grid item xs={7} className='d-flex align-items-center'>
                        <Grid container >
                            <Grid className='p-2 my-auto text-center font-weight-bold' item xs={12}>{window.I18N('question')}</Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={5} className='d-flex align-items-center'>
                        <Grid container>
                            <Grid className='p-2 my-auto font-weight-bold' item xs={4}>{window.I18N('type')}</Grid>
                            <Grid className='p-2 my-auto font-weight-bold' item xs={4}>{window.I18N('created_date')}</Grid>
                            <Grid className='p-2 pl-4 my-auto font-weight-bold' item xs={4}>{window.I18N('action')}</Grid>
                        </Grid>
                    </Grid>  
                </Grid>
                {dataQuestion.map((question, i) =>
                    <SingleQuestionTemplate mode="EDIT" handleEditClick={()=>openSaveDialog(i)} handleDelClick={()=>openDeleteMenuDialog(i)} question={question} index={i} key={i}></SingleQuestionTemplate>)
                }
                <li className="list-group-item pt-4">
                    <Pagination total={pagination.total} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={changePage}></Pagination>
                </li>
                <Modal className="modal-xl" id="modal-questionBank" title={window.I18N('create_update_data')} saveEvent={saveQuestionData}>
                    <form id="form-questionBankData" className="needs-validation" noValidate>
                        <MDBRow >
                            <MDBCol>
                                <Grid container className='flex flex-row-reverse'>
                                    <Grid item xs={5} className='pl-4' >
                                        <Select id="select-type" value={select.type} options={optionsTypes} placeholder={window.I18N('type') + ' *'} className={`md-form mt-0 ${dataSave._id?"disabled":""}`} onChange={(event) => handleSelect(event, 'select-type', 'type')} isSearchable />
                                    </Grid>
                                    <Grid item xs={5} >
                                        <Select id="select-category-modal" value={select.categoryModal} options={optionsCategory} placeholder={window.I18N('choose_category') + ' *'} className="md-form mt-0" onChange={(event) => handleSelect(event, 'select-category-modal', 'categoryModal')} isSearchable />
                                    </Grid>
                                </Grid>
                                <MDBInput outline containerClass="mt-0" value={dataSave.question} name="question" onChange={handleChange} type="textarea" label={window.I18N('question') + ' *'} required>
                                    <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                                </MDBInput>
                                <MDBInput outline value={dataSave.introduction} name="introduction" onChange={handleChange} type="textarea" label={window.I18N('introduction')}>
                                    <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                                </MDBInput>
                                {select.type.value === 'CHECKBOX_GRID' || select.type.value === 'RADIO_GRID' ? ()=>{/* generateFormAnswerGrid() */}
                                : dataSaveAnswer.map((item, i) =>
                                    <div className="d-flex align-items-center mt-3" key={i}>
                                        {generateFormAnswer(i)}
                                        <MDBInput outline type="text" containerClass="m-0" name="content" value={getEnText(item.content)} onChange={(event) => {handleChangeAnswer(event, item)}} maxLength="1000" required />
                                        <MDBIcon fa="true" icon="times" className={`fa-fw m-2 ${dataSaveAnswer.length<3?"disabled":""}`} style={{ color: 'gray' }} onClick={() => { deleteAnswer(item.code) }}/>
                                    </div>
                                )}
                                {select.type.value !== 'TEXT' && dataSaveAnswer.length < MAX_NUMBER_OF_ANSWERS ?
                                    <MDBBtn id="add-answer-btn" outline className="btn btn-sm btn-transparent m-0 mt-3" type="button" color="" onClick={() => addAnswer(null)}>
                                        <MDBIcon fa="true" icon="plus" style={{ color: '#302E90' }} className="fa-fw" /> {window.I18N('add_row')}
                                    </MDBBtn> : <></>
                                }
                            </MDBCol>
                        </MDBRow>
                    </form>
                </Modal>
            </div>
        </div>
    )   
}   

const mapStateToProps = state => ({
    language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionBankPage);
