import styled from 'styled-components';



export const MembersDataStyled = styled.div`
    .list{
        display: flex;
        flex-direction: column;
        min-height: 300px;
        overflow-y: scroll;
        row-gap: 10px;
       
    }
    .item{
        display: flex;
        column-gap: 10px;
        align-items: center;
        .content{
            font-weight: bold;
            
        }
        input[type=checkbox]{
            position: unset;
            opacity: 1;
            pointer-events: auto;
        }
    }
    
`;