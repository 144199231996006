import { MDBBtn, MDBCardImage, MDBIcon } from 'mdbreact'
import ChatGRAPHQL from '../../../../../graphql/Chat';
import React from 'react'



const ProfileSelectUser = ({data, onShowGroupChat}) => {
    const handleClickOnUserResult = async (user) => {
        try {
          //có thể người dùng được chọn chưa có dữ liệu trên firebase
          //nên gọi một api để kiểm tra và thêm người dùng đó
          const userInput = {
            _id: user._id,
            name: user.name,
            avatar: user.avatar,
            email: user.email,
            phone: user.phone,
            nickname: user.nickname
          };
          const addFirebaseUserParams = {
            input: userInput
          };
          const result = await window.COMMON.mutation(ChatGRAPHQL.MUTATION_ADD_FIREBASE_USER, addFirebaseUserParams);
          let status = 1;
          if (result && result.data) {
            status = result.data.addFirebaseUser;
          }
          else {
            status = -1;
          }
          if (status === -1) {
            window.COMMON.showMessage('error', 'MSG_CODE_049', window.I18N('MSG_CODE_049'));
            return;
          }
    
    
    
          const currentUser = window.COMMON.getCurrentUserInformation();
          let groupChatId;
          let createNewGroupChatId = true;
          userInput.id = userInput._id;
    
          //find individual group chat (from last message collection) - if have then use id else create
          const paramsToFindIndividualGroupChat = {
            userId: currentUser._id,
            anotherUserId: userInput._id
          };
          const findResult = await window.COMMON.query(ChatGRAPHQL.QUERY_GET_INDIVIDUAL_GROUP_CHAT, paramsToFindIndividualGroupChat, false);
          if (findResult && findResult.data) {
            const groupChatResult = findResult.data.getIndividualGroupChat;
            if (groupChatResult) {
              groupChatId = groupChatResult.id;
              createNewGroupChatId = false;
            }
          }
    
            //sort ids to become a unique id
            if (currentUser._id <= userInput._id) {
              groupChatId = `${currentUser._id}-${userInput._id}`;
            } else {
              groupChatId = `${userInput._id}-${currentUser._id}`;
            }
    
          
          const groupChatType =  window.CONSTANT.FRIEND;
    
          const groupchat = {
            createdAt: new Date().getTime().toString(),
            createdBy: currentUser._id,
            _id: groupChatId,
            id: groupChatId,
            groupName: '',
            image: '',
            members: [currentUser, userInput],
            type: groupChatType
          };
          onShowGroupChat(groupchat);
    
        }
        catch (error) {
          window.COMMON.showErrorLogs('ProfileSelectUser.handleClickOnUserResult');
        }
      };   
    return (
    <div className='d-flex flex-column align-items-center justify-content-center h-100'>
            <MDBCardImage className="image-logo" src={window.COMMON.setValueImage(data.avatar)} hover overlay="white-slight" style={{ borderRadius: '100%' }}/>
                <div className="mx-auto p-4">
                    <h4 className="font-weight-bold mb-2">{window.I18N('nickname')}: {window.COMMON.getValueFromAttr(data, 'nickname')}</h4>
                    <p className="mb-0">{window.I18N('company')}: {window.COMMON.getValueFromAttr(data, 'company.information.name') || window.COMMON.getValueFromAttr(data, 'influencer.information.name')}</p>
                    <p className="mb-0 mt-2">{window.I18N('bio')}: {window.COMMON.getValueFromAttr(data, 'bio')}</p>
                </div>
                <div className="container-button">
                    <MDBBtn color="primary" type="button" onClick={() => {handleClickOnUserResult(data)}}>
                        <MDBIcon fa="true" icon="comment" className="fa-fw" /> {window.I18N('send_message')}
                    </MDBBtn>
                </div>
    </div>
  )
}

export default ProfileSelectUser