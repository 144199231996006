import gql from 'graphql-tag';

const QUERY_MENU_SURVEY = gql`
  query {
    getMenuSurveys(status: [true, false]) {
      _id
      code
      content {
        code
        text
      }
      description {
        code
        text
      }
      status
      isMulti
      createdDate
      createdBy {
        name
      }
    }
  }
`;

const MUTATION_ADD_MENU_SURVEY = gql`
  mutation($input: MenuSurveyInput!) {
    addMenuSurvey(input: $input)
  }
`;

const MUTATION_EDIT_MENU_SURVEY = gql`
  mutation($input: MenuSurveyInput!) {
    editMenuSurvey(input: $input)
  }
`;

const MUTATION_PUSH_MENU_SURVEY = gql`
  mutation($id: String!, $status: Boolean!) {
    pushMenuSurvey(id: $id, status: $status)
  }
`;

const MUTATION_DEL_MENU_SURVEY = gql`
  mutation($id: String!) {
    deleteMenuSurvey(id: $id)
  }
`;

const QUERY_QUESTION_SURVEY = gql`
  query($parentId: String!) {
    getQuestionSurveys(parentId: $parentId) {
      _id
      code
      content {
        code
        text
      }
      createdDate
    }
  }
`;

const QUERY_ANSWER_SURVEY = gql`
  query($parentId: String!) {
    getAnswerSurveys(parentId: $parentId) {
      _id
      code
      content {
        code
        text
      }
      question {
        _id
        code
      }
      point
    }
  }
`;

const QUERY_RECOMMEND_SURVEY = gql`
  query($parentId: String!) {
    getRecommendSurveys(parentId: $parentId) {
      _id
      code
      content {
        code
        text
      }
      description {
        code
        text
      }
      min
      max
      createdDate
      createdBy {
        name
      }
    }
  }
`;

const MUTATION_DEL_QUESTION_SURVEY = gql`
  mutation($id: String!) {
    deleteQuestionSurvey(id: $id) {
      _id
    }
  }
`;

const MUTATION_DEL_RECOMMEND_SURVEY = gql`
  mutation($id: String!) {
    deleteRecommendSurvey(id: $id) {
      _id
    }
  }
`;

const MUTATION_CHECK_IMPORT_QUESTION_SURVEY = gql`
  mutation($input: [String!]) {
    checkImportQuestionSurvey(input: $input)
  }
`;

const MUTATION_CHECK_IMPORT_ANSWER_SURVEY = gql`
  mutation($input: [String!]) {
    checkImportAnswerSurvey(input: $input)
  }
`;

const MUTATION_CHECK_IMPORT_RECOMMEND_SURVEY = gql`
  mutation($input: [String!]) {
    checkImportRecommendSurvey(input: $input)
  }
`;

const MUTATION_IMPORT_QUESTION_SURVEY = gql`
  mutation($input: [QuestionSurveyInput!]) {
    importQuestionSurvey(input: $input)
  }
`;

const MUTATION_IMPORT_ANSWER_SURVEY = gql`
  mutation($input: [AnswerSurveyInput!]) {
    importAnswerSurvey(input: $input)
  }
`;

const MUTATION_IMPORT_RECOMMEND_SURVEY = gql`
  mutation($input: [RecommendSurveyInput!]) {
    importRecommendSurvey(input: $input)
  }
`;

const QUERY_HISTORY_SURVEY = gql`
  query($input: HistorySurveyFilter!) {
    getHistorySurveys(input: $input) {
      total
      page
      pageSize
      data {
        _id
        menuSurvey {
          code
          content {
            code
            text
          }
        }
        recommendSurvey {
          content {
            code
            text
          }
        }
        point
        total
        createdDate
        company {
          information {
            name
          }
        }
        union {
          information {
            name
          }
        }
        influencer {
          information {
            name
          }
        }
        createdBy {
          avatar
          nickname
        }
      }
    }
  }
`;

const QUERY_EXPORT_HISTORY_SURVEY = gql`
  query($input: HistorySurveyFilter!) {
    getHistorySurveys(input: $input) {
      total
      page
      pageSize
      data {
        _id
        menuSurvey {
          code
          content {
            code
            text
          }
        }
        recommendSurvey {
          content {
            code
            text
          }
        }
        data {
          answer {
            content {
              text
              code
            }
            point
          }
          question {
            content {
              text
              code
            }
          }
        }
        point
        total
        createdDate
        company {
          information {
            name
          }
        }
        union {
          information {
            name
          }
        }
        influencer {
          information {
            name
          }
        }
        createdBy {
          avatar
          nickname
        }
      }
    }
  }
`;

const QUERY_COUNT_MENU_SUREY = gql`
  query($input: CountSurveyFilter!) {
    countSurvey(input: $input)
  }
`;

const QUERY_SURVEY_DATA_CHART = gql`
  query($input: CountSurveyFilter!){
    surveyDataChart(input: $input){
      type
      createdDate
    }
  }
`;


const SurveyGRAPHQL = {
  QUERY_MENU_SURVEY,
  MUTATION_ADD_MENU_SURVEY,
  MUTATION_EDIT_MENU_SURVEY,
  MUTATION_PUSH_MENU_SURVEY,
  MUTATION_DEL_MENU_SURVEY,
  QUERY_QUESTION_SURVEY,
  QUERY_ANSWER_SURVEY,
  QUERY_RECOMMEND_SURVEY,
  MUTATION_DEL_QUESTION_SURVEY,
  MUTATION_DEL_RECOMMEND_SURVEY,
  MUTATION_CHECK_IMPORT_QUESTION_SURVEY,
  MUTATION_CHECK_IMPORT_ANSWER_SURVEY,
  MUTATION_CHECK_IMPORT_RECOMMEND_SURVEY,
  MUTATION_IMPORT_QUESTION_SURVEY,
  MUTATION_IMPORT_ANSWER_SURVEY,
  MUTATION_IMPORT_RECOMMEND_SURVEY,
  QUERY_HISTORY_SURVEY,
  QUERY_EXPORT_HISTORY_SURVEY,
  QUERY_COUNT_MENU_SUREY,
  QUERY_SURVEY_DATA_CHART
};

export default SurveyGRAPHQL;