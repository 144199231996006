import React, { useState, useCallback, useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { MDBRow, MDBCol, MDBCardImage, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';

import Panel from '../../components/Panel';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import InputFile from '../../components/InputFile';

import MetadataGRAPHQL from '../../../graphql/Metadata';
import eventName from '../../../common/events';
import { useTracking } from 'react-tracking';

function AppsConnectPage(props) {
  const { trackEvent } = useTracking();

  const config = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('image'), '15%', '', '', 'image', 'IMAGE'),
    window.COMMON.createCol(window.I18N('name'), '20%', '', '', 'nameLanguage', 'TEXT'),
    window.COMMON.createCol(window.I18N('code'), '10%', '', '', 'code', 'TEXT'),
    window.COMMON.createCol(window.I18N('index'), '5%', '', '', 'index', 'TEXT'),
    window.COMMON.createCol(window.I18N('status'), '10%', '', '', 'isActive', 'STATUS'),
    window.COMMON.createCol(window.I18N('created_by'), '15%', '', '', 'createdBy.nickname', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '10%', '', '', '2', 'BUTTON')
  ];
  const typeOptions =  [
    { value: 'APP', label: window.I18N('apps') },
    { value: 'SLIDE', label: window.I18N('slide_apps') },
    { value: 'PORTAL', label: window.I18N('portal_apps') },
  ];
  const language = useRef();
  const [filter, setFilter] = useState({ type: { value: 'APP', label: window.I18N('apps') } });
  const [object, setObject] = useState({ index: -1 });
  const [data, setData] = useState([]);
  const [dataSave, setDataSave] = useState({});

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {
      getDataAppsConnect();
    }, 100);
    // eslint-disable-next-line
  }, [props.language]);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleChange = (event) => {
    if (event.target.className.indexOf('input-number') > -1) {
      dataSave[event.target.name] = parseInt(event.target.value);
    } else if (event.target.type === 'checkbox') {
      dataSave[event.target.name] = event.target.checked;
    } else {
      dataSave[event.target.name] = event.target.value;
    }
    setDataSave({ ...dataSave });
  };

  const handleChangeWithLanguage = (event) => {
    window.COMMON.setValueWithLanguage(dataSave, event.target.name, language.current, event.target.value);
    setDataSave({ ...dataSave });
  };

  const handleFilterSelect = (event, attr) => {
    filter[attr] = event;
    setFilter({ ...filter });
    getDataAppsConnect();
  };

  const handleFile = (file) => {
    dataSave.file = file;
    dataSave.image = window.COMMON.createBlobImage(file);
    setDataSave({ ...dataSave });
  };

  const getDataAppsConnect = async () => {
    try {
      window.resetDataTable('table-data');
      const params = {
        type: [window.COMMON.getDataSelect(filter.type)],
        isActive: [true, false]
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_APPS_CONNECT, params);
      if (result && result.data) {
        const data = window.COMMON.formatDate(result.data.getApps, 'createdDate');
        data.forEach(item => {
          item.nameLanguage = window.COMMON.getValueWithLanguage(item, 'name', language.current);
          item.descriptionLanguage  = window.COMMON.getValueWithLanguage(item, 'description', language.current);
        });
        setData(data);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('AppsConnectPage.getDataAppsConnect');
    }
    window.initDataTable('table-data');
  };

  const openSaveDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-apps-connect');
    object.index = index;
    const dataSave = { type: window.COMMON.getDataSelect(filter.type), file: null };
    if (index === -1) {
      dataSave.code = window.COMMON.generateCode('APP');
      dataSave.name = [];
      dataSave.description = [];
      dataSave.content = '';
      dataSave.index = 0;
      dataSave.image = '';
      dataSave.isActive = false;
    } else {
      const obj = data[index];
      dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
      dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
      dataSave.name = window.COMMON.getDataWithLanguage(obj, 'name');
      dataSave.description = window.COMMON.getDataWithLanguage(obj, 'description');
      dataSave.content = window.COMMON.getValueFromAttr(obj, 'content');
      dataSave.index = window.COMMON.getValueFromAttr(obj, 'index', 0);
      dataSave.image = window.COMMON.getValueFromAttr(obj, 'image');
      dataSave.isActive = window.COMMON.getValueFromAttr(obj, 'isActive', false);
    }
    document.getElementById('file-app-connect').value = null;
    setObject(object);
    setDataSave({ ...dataSave });
  }, [filter, object, data]);

  // eslint-disable-next-line
  const openDeleteDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteData;
  });

  const saveData = async (event) => {
    try {
      if (!window.COMMON.checkFormValidation('form-apps-connect', event)) {
        return;
      }
      // TODO upload file
      let dataFiles = [];
      if (dataSave.file) {
        dataFiles = await window.uploadFile([dataSave.file]);
        if (dataFiles.length > 0) {
          dataSave.image = window.COMMON.getNewPathFile(dataFiles[0]._id);
        }
      }
      delete dataSave.file;
      const params = {
        input: dataSave
      };
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(MetadataGRAPHQL.MUTATION_ADD_APPS_CONNECT, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataAppsConnect();
		      createTrackingEvent(eventName.ADD_APPS_CONNECT);
        }
      } else {
        const result = await window.COMMON.mutation(MetadataGRAPHQL.MUTATION_EDIT_APPS_CONNECT, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataAppsConnect();
		      createTrackingEvent(eventName.EDIT_APPS_CONNECT);
        }
      }
      window.COMMON.hideModal('#modal-apps-connect');
    } catch (error) {
      window.COMMON.showErrorLogs('AppsConnectPage.saveData');
    }
  };

  const deleteData = async () => {
    try {
      const params = {
        id: data[object.index]._id
      };
      const result = await window.COMMON.mutation(MetadataGRAPHQL.MUTATION_UPDATE_STATUS_APPS_CONNECT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
        getDataAppsConnect();
        createTrackingEvent(eventName.UPDATE_STATUS_APPS_CONNECT);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('AppsConnectPage.deleteData');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" icon="list-alt">
        <div className="container-top d-flex align-items-center justify-content-end wow fadeInDown animated" data-wow-delay="0.5s">
          <div className="bd-highlight mr-2" style={{ width: '300px' }}>
            <div className="input-group input-group-transparent">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <MDBIcon fa="true" icon="filter" className="fa-fw" />
                </span>
              </div>
              <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.type} options={typeOptions} onChange={(event) => handleFilterSelect(event, 'type')} isSearchable/>
            </div>
          </div>
          <MDBBtn color="primary" className="mr-0" onClick={() => openSaveDialog(-1)}>
            <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
          </MDBBtn>
        </div>
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <Table id="table-data" className="table" config={config} data={data} disabledEdit={!window.COMMON.checkRoleIsSystem()} disabledDel={!window.COMMON.checkRoleIsSystem()} handleRowEditClick={openSaveDialog} handleRowDelClick={openDeleteDialog}></Table>
        </div>
      </Panel>

      <Modal id="modal-apps-connect" className="modal-lg" title={window.I18N('create_update_data')} saveEvent={saveData}>
        <form id="form-apps-connect" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <MDBInput outline type="text" label={window.I18N('name') + ' *'} containerClass="mt-0" name="name" value={window.COMMON.getValueWithLanguage(dataSave, 'name', language.current)} onChange={handleChangeWithLanguage} maxLength="200" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="text" label={window.I18N('code') + ' *'} name="code" value={dataSave.code} onChange={handleChange} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline label={window.I18N('index') + ' *'} name="index" value={dataSave.index} type="number" className="input-number" onChange={handleChange} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="text" label="URL" name="content" value={dataSave.content} onChange={handleChange} maxLength="255" pattern="\S(.*\S)?">
              </MDBInput>
              <MDBInput outline type="textarea" label={window.I18N('description')} name="description" value={window.COMMON.getValueWithLanguage(dataSave, 'description', language.current)} onChange={handleChangeWithLanguage} maxLength="5000" pattern="\S(.*\S)?"></MDBInput>
              <div className="d-flex align-items-center">
                <label className="badge badge-info p-2 m-0 mr-5">{filter.type.label}</label>
                <MDBInput outline type="checkbox" id="ck-active-apps-connect" name="isActive" label={window.I18N('btn_active')} filled containerClass="p-2 mt-1" checked={dataSave.isActive} onChange={handleChange}></MDBInput>
              </div>
              <div className="w-100 text-center mb-3">
                <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(dataSave.image)} style={{ height: '90px', width: '160px' }} className="mb-3 img-fluid"/>
              </div>
              <div className="text-center mb-3">
                <InputFile id="file-app-connect" icon="upload" handleFile={handleFile} accept="image/*" description="Image (160 x 90) pixels"></InputFile>
              </div>
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>
    </div>
  );
}


const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};
    
export default connect(mapStateToProps, mapDispatchToProps)(AppsConnectPage);