import React, { useLayoutEffect } from 'react';
import Select from 'react-select';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBInput, MDBCardImage, MDBBtn, MDBIcon, MDBCardHeader, MDBCardFooter } from 'mdbreact';

import InputMultiFile from './InputMultiFile';

const bg = ['bg-danger', 'bg-warning', 'bg-info', '', 'bg-success'];

const ItemQA = React.memo(function ItemQA(props) {
  const [language, setLanguage] = React.useState();
  const [object, setObject] = React.useState({ index: 0, isDisabledBtn: true, important: false });
  const [data, setData] = React.useState([]);
  const [dataItem, setDataItem] = React.useState({});
  const [dataDeletedFiles, setDataDeletedFiles] = React.useState([]);
  const [dataFeedback, setDataFeedback] = React.useState({});
  const [dataAnswer, setDataAnswer] = React.useState([]);

  useLayoutEffect(() => {
    let dataItem = {};
    setLanguage(props.language);
    const questions = [];
    if (props.data && props.data.length > 0) {
      // sort by category
      if (props.groupData?.group && props?.groupData?.group?.type === 2) {
        const sortQuestionByDate = [];
        props.data.forEach((item) => {
          const findCategoryTitle = window.COMMON.getValueWithLanguage(item.question, 'category', props.language);
          const categoryIndex = sortQuestionByDate.findIndex(item => item.categoryTitle === findCategoryTitle);

          if (categoryIndex === -1) {
            sortQuestionByDate.push({
              categoryTitle: findCategoryTitle,
              questions: [item]
            });
          } else {
            sortQuestionByDate[categoryIndex] = {
              ...sortQuestionByDate[categoryIndex],
              questions: [
                ...sortQuestionByDate[categoryIndex].questions,
                item
              ]
            };
          }
        });
        sortQuestionByDate.forEach((item) => {
          questions.push(...item.questions);
        });
      }
      const item = props.data[object.index]?.question;
      if (item) {
        dataItem = JSON.parse(JSON.stringify(item));
        object.important = item.important;
      }
      if (props.groupData?.group && props?.groupData?.group?.type === 2 && questions.length > 0) {
        setData(questions);
      } else {
        setData(props.data);
      }

      const userId = localStorage.getItem('id');
      const draft = JSON.parse(localStorage.getItem('draftAssessment') || '[]');
      const dataDraft = draft.find(item => item.user === userId && item.assessment === props.assessmentId && item.group === props.group?.group?._id);
      if (props.dataFeedback || dataDraft?.feedback) {
        const feedbackData = dataDraft?.feedback ?? (props.dataFeedback ?? {});
        object.index = 0;
        if (feedbackData[dataItem._id]) {
          object.isDisabledBtn = false;
        } else {
          object.isDisabledBtn = true;
        }
        setObject(object);
        setDataFeedback(feedbackData);
      }
      setDataAnswer(JSON.parse(JSON.stringify(props.data[object.index].answers)));
      setDataItem({ ...dataItem });
    }
  }, [object, props.data, props.language, props.dataFeedback]);

  function countAnsweredQuestion(paramData) {
    let count = 0;
    const answerData = Object.values(paramData);
    answerData.forEach(feedbackAnswer => {
      if (feedbackAnswer.answerText.trim() !== '') {
        count++;
      } else if (feedbackAnswer.answers.length) {
        count++;
      } else if (Object.keys(feedbackAnswer.answers).length) {
        let countChildAnswer = 0;
        const numberOfChildQuestions = Object.keys(feedbackAnswer.answers).length;
        Object.values(feedbackAnswer.answers).forEach(answerOfSingleChildQues => {
          if (answerOfSingleChildQues.length > 0) {
            countChildAnswer++;
          }
        });
        if (numberOfChildQuestions === countChildAnswer) count++;
      }
    });
    return count;
  }

  const initItemFeedback = (parentCode) => {
    if (!dataFeedback[dataItem._id]) {
      if (dataItem.type === 'RADIO_GRID' || dataItem.type === 'CHECKBOX_GRID') {
        const parent = dataAnswer.filter(function (item) {
          return item.question === dataItem._id && window.COMMON.getValueFromAttr(item, 'parentCode', null) === null;
        });
        const childQues = parent.reduce((obj, item) => Object.assign(obj, { [item.code]: [] }), {});
        dataFeedback[dataItem._id] = {
          count: parent.length,
          answers: childQues,
          answerText: ''
        };
      } else {
        dataFeedback[dataItem._id] = {
          answers: [],
          answerText: ''
        };
      }
      if (props.isSelfAssessment) {
        dataFeedback[dataItem._id].note = '';
        dataFeedback[dataItem._id].consultantEvaluation = '';
        dataFeedback[dataItem._id].consultantNote = '';
        dataFeedback[dataItem._id].documents = [];
      }
    }
    if (parentCode && !dataFeedback[dataItem._id].answers[parentCode]) {
      dataFeedback[dataItem._id].answers[parentCode] = [];
    }
  };

  const checkStatus = () => {
    object.isDisabledBtn = true;
    if (dataItem.type === 'TEXT' && dataFeedback[dataItem._id]) {
      object.isDisabledBtn = !window.COMMON.checkValue(dataFeedback[dataItem._id].answerText);
    }
    if ((dataItem.type === 'RADIO' || dataItem.type === 'LEVEL') && dataFeedback[dataItem._id]) {
      object.isDisabledBtn = dataFeedback[dataItem._id].answers.length === 0;
    }
    if (dataItem.type === 'CHECKBOX' && dataFeedback[dataItem._id]) {
      object.isDisabledBtn = dataFeedback[dataItem._id].answers.length === 0;
    }
    if (dataItem.type === 'RADIO_GRID' && dataFeedback[dataItem._id]) {
      const keys = Object.keys(dataFeedback[dataItem._id].answers);
      object.isDisabledBtn = keys.length < dataFeedback[dataItem._id].count;
    }
    if (dataItem.type === 'CHECKBOX_GRID' && dataFeedback[dataItem._id]) {
      const keys = Object.keys(dataFeedback[dataItem._id].answers);
      let check = false;
      keys.forEach(key => {
        check = dataFeedback[dataItem._id].answers[key].length === 0;
      });
      if (!check) {
        check = keys.length < dataFeedback[dataItem._id].count;
      }
      object.isDisabledBtn = check;
    }
    setObject(object);
  };

  const handleSubmit = () => {
    if (props.isSelfAssessment) {
      let totalSize = 0;
      const keys = Object.keys(dataFeedback);
      keys.forEach(key => {
        dataFeedback[key].documents.forEach(document => {
          totalSize += document.size;
        });
      });
      if (totalSize >= window.CONSTANT.TOTAL_SIZE_SELF_ASSESSMENT) {
        window.COMMON.showMessage('warning', 'MSG_CODE_041', window.MSG.MSG_CODE_041);
        return;
      }
    }
    props.handleSubmit(dataFeedback, dataDeletedFiles);
  };

  const handlePage = (index) => {
    object.index = index;
    object.isDisabledBtn = true;
    const item = data[object.index].question;
    object.important = item && item.important;
    if (item) {
      dataItem._id = window.COMMON.getValueFromAttr(item, '_id', null);
      dataItem.question = window.COMMON.getDataWithLanguage(item, 'question');
      dataItem.code = window.COMMON.getValueFromAttr(item, 'code');
      dataItem.introduction = window.COMMON.getDataWithLanguage(item, 'introduction');
      dataItem.type = window.COMMON.getValueFromAttr(item, 'type');
    }
    checkStatus();
    setDataAnswer(JSON.parse(JSON.stringify(data[object.index].answers)));
    setDataItem({ ...dataItem });
  };

  const handleChange = (event, id, parentCode) => {
    initItemFeedback(parentCode);
    if (event.target.type === 'checkbox') {
      if (event.target.checked) {
        if (dataItem.type === 'CHECKBOX') {
          dataFeedback[dataItem._id].answers.push(id);
        } else {
          dataFeedback[dataItem._id].answers[parentCode].push(id);
        }
      } else {
        let arrs = [];
        if (dataItem.type === 'CHECKBOX') {
          arrs = dataFeedback[dataItem._id].answers;
        } else {
          arrs = dataFeedback[dataItem._id].answers[parentCode];
        }
        for (let i = 0; i < arrs.length; i++) {
          if (arrs[i] === id) {
            arrs.splice(i, 1);
            break;
          }
        }
      }
    } else {
      dataFeedback[dataItem._id][event.target.name] = event.target.value;
    }
    checkStatus();
    setDataFeedback({ ...dataFeedback });
  };

  const handleRadio = (value, parentCode) => {
    initItemFeedback(parentCode);
    if (dataItem.type === 'RADIO') {
      dataFeedback[dataItem._id].answers = [value];
    } else {
      dataFeedback[dataItem._id].answers[parentCode] = [value];
    }
    checkStatus();
    setDataFeedback({ ...dataFeedback });
  };

  const chooseLevel = (id) => {
    initItemFeedback();
    dataFeedback[dataItem._id].answers = [id];
    checkStatus();
    setDataFeedback({ ...dataFeedback });
  };

  const handleFile = async (files) => {
    initItemFeedback();
    const dataFiles = await window.uploadFile(files);
    for (let i = 0; i < dataFiles.length; i++) {
      dataFeedback[dataItem._id].documents.push({
        id: dataFiles[i]._id,
        name: dataFiles[i].name,
        size: dataFiles[i].size,
        type: dataFiles[i].type,
        ext: dataFiles[i].ext
      });
    }
    setDataFeedback({ ...dataFeedback });
  };

  const handleSaveDraft = (feedback) => {
    //check object is not empty
    if (Object.keys(feedback).length > 0) {
      const userId = localStorage.getItem('id');
      const draft = JSON.parse(localStorage.getItem('draftAssessment') || '[]');

      if (userId) {
        const dataIndex = draft.findIndex(item => item.user === userId && item.assessment === props.assessmentId && item.group === props.group?.group?._id);
        const newDraftData = {
          assessment: props.assessmentId,
          group: props.group?.group?._id,
          feedback,
          questions: data.map(item => item.question),
          user: userId
        };
        if (dataIndex > -1) {
          draft[dataIndex] = newDraftData;
        } else {
          draft.push(newDraftData);
        }
      }
      localStorage.setItem('draftAssessment', JSON.stringify(draft));
      window.COMMON.showMessage('success', 'MSG_CODE_002', window.I18N('MSG_CODE_002'));
    }
  };

  const deleteFile = async (index) => {
    const document = dataFeedback[dataItem._id].documents[index];
    if (!document.file) {
      dataDeletedFiles.push(document);
      setDataDeletedFiles(dataDeletedFiles);
    }
    dataFeedback[dataItem._id].documents.splice(index, 1);
    setDataFeedback({ ...dataFeedback });
  };

  const checkRadioOrCheckbox = (item) => {
    if (dataItem.type === 'LEVEL' || dataItem.type === 'RADIO' || dataItem.type === 'CHECKBOX') {
      return window.COMMON.getValueFromAttr(dataFeedback[dataItem._id], 'answers').indexOf(item._id) > -1;
    } else {
      const answers = window.COMMON.getValueFromAttr(dataFeedback[dataItem._id], 'answers');
      if (answers && answers[item.parentCode]) {
        return answers[item.parentCode].indexOf(item._id) > -1;
      }
    }
    return false;
  };

  const generateFormAnswer = (item, i) => {
    if (dataItem.type === 'CHECKBOX' || dataItem.type === 'CHECKBOX_GRID') {
      return <MDBInput key={'preview-' + item.code} id={'preview-' + item.code} type="checkbox" containerClass="pl-0 pb-2 mr-4 text-wrap" label={window.COMMON.getValueWithLanguage(item, 'content', props.language)} filled onChange={(event) => handleChange(event, item._id, item.parentCode)} checked={checkRadioOrCheckbox(item)} disabled={props.isSubmited} />;
    }
    if (dataItem.type === 'LEVEL') {
      return <div key={'preview-' + item.code} className={'progress-bar progress-bar-striped progress-bar-animated w-100 ' + bg[i % 5] + (checkRadioOrCheckbox(item) ? ' active' : '')} role="progressbar" onClick={props.isSubmited ? () => { } : () => chooseLevel(item._id)}>
        {window.COMMON.getValueWithLanguage(item, 'content', props.language)}
      </div>;
    }
    return <MDBInput gap key={'preview-' + item.code} id={'preview-' + item.code} type="radio" containerClass="pl-0 pb-1 mr-4 text-wrap" label={window.COMMON.getValueWithLanguage(item, 'content', props.language)} onClick={() => handleRadio(item._id, item.parentCode)} checked={checkRadioOrCheckbox(item)} disabled={props.isSubmited} />;
  };

  const generateFormAnswerGrid = () => {
    const parent = dataAnswer.filter(function (item) {
      return window.COMMON.getValueFromAttr(item, 'parentCode', null) === null;
    });
    if (parent.length > 0) {
      return parent.map((item, i) => <div key={'preview-' + item.code}>
        <p className="mb-1"><span className="mr-4 font-weight-bold text-muted">{window.COMMON.getValueWithLanguage(item, 'content', props.language)}</span></p>
        {
          window.COMMON.getArrsInArrs(item.code, dataAnswer, 'parentCode').map((child, j) => generateFormAnswer(child, j))
        }
      </div>);
    }
    return <div></div>;
  };

  return <React.Fragment>
    <div className="container-btn wow fadeInDown animated" data-wow-delay="0.5s">
      <div className="text-left d-flex align-items-center">
        <MDBBtn color="" onClick={props.handleClose}>
          <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('btn_back')}
        </MDBBtn>
        <div style={{ flex: '1 1 0%' }} />
        <Select
          id="select-questions"
          value={null}
          options={data.map((item, index) => {
            return {
              label: index + 1 + '. ' + window.COMMON.getValueWithLanguage(item.question, 'question', language),
              value: index
            };
          })}
          placeholder={window.I18N('search_question')}
          className="w-25"
          onChange={(event) => handlePage(event.value)}
          isSearchable
        />
        {props.handleViewFacility ? <MDBBtn color="" className="mr-0" onClick={props.handleViewFacility}>
          <MDBIcon fa="true" icon="info-circle" className="fa-fw" /> {window.I18N('facility_info')}
        </MDBBtn> : <></>}

        {!props.isSubmited && (
          <>
            <MDBBtn color="" onClick={() => handleSaveDraft(dataFeedback)}>
              {window.I18N('btn_save_draft')} <MDBIcon fa="true" icon="clipboard" className="fa-fw" />
            </MDBBtn>
            {props.handleSubmit && (
              <MDBBtn color="" onClick={handleSubmit} disabled={countAnsweredQuestion(dataFeedback) !== data.length}>
                {window.I18N('btn_save')} <MDBIcon fa="true" icon="save" className="fa-fw" />
              </MDBBtn>
            )}
          </>
        )}
      </div>
    </div>
    <MDBRow>
      <MDBCol>
        <div className="item-form-question mt-2 wow fadeIn animated" data-wow-delay="1s">
          <MDBCard className="card-box-shadow">
            <MDBCardHeader>
              <div className='d-flex'>
                <div style={{ flex: '1 1 0%' }}>
                  <p className="mb-0">{window.COMMON.getValueFromAttr(props.group, 'nameLanguage')}</p>
                  <small>{window.COMMON.getValueFromAttr(props.group, 'descriptionLanguage')}</small>
                </div>
                <div className="dropdown ml-auto d-flex align-items-center">
                  {
                    object.important ? <span className="red-text btn-sm mx-1">
                      <MDBIcon fa="true" icon="exclamation-circle" className="fa-fw fa-lg"></MDBIcon>
                    </span> : <></>
                  }
                  <MDBBtn outline className="btn btn-sm btn-transparent m-0" color="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-boundary="viewport">
                    <MDBIcon fa="true" icon="question-circle" className="fa-fw fa-lg" />
                  </MDBBtn>
                  <div className="dropdown-menu dropdown-menu-right" aria-labelledby={'dropdown-' + dataItem._id} data-boundary="viewport">
                    <small>{window.COMMON.getValueWithLanguage(dataItem, 'introduction', language)}</small>
                  </div>
                </div>
              </div>
            </MDBCardHeader>
            <MDBCardBody style={{ minHeight: '300px' }}>
              <MDBRow>
                <MDBCol md="12">
                  <p className="font-weight-bold mb-2">{object.index + 1 + '. ' + window.COMMON.getValueWithLanguage(dataItem, 'question', language)}</p>
                </MDBCol>
                <MDBCol md={props.isSelfAssessment ? '6' : '12'} xs="12">
                  {
                    (dataItem.type === 'CHECKBOX_GRID' || dataItem.type === 'RADIO_GRID') ? generateFormAnswerGrid()
                      : dataItem.type === 'TEXT' ? <MDBInput outline type="textarea" containerClass="mt-1" name="answerText" value={window.COMMON.getValueFromAttr(dataFeedback[dataItem._id], 'answerText')} onChange={handleChange} maxLength="5000" disabled={props.isSubmited} />
                        : dataItem.type === 'LEVEL' ? <div className="progress mt-3" style={{ height: '40px' }}>
                          {dataAnswer.map((item, i) => generateFormAnswer(item, i))}
                        </div>
                          : dataAnswer.map((item, i) => generateFormAnswer(item, i))
                  }
                  <p className="mb-3"></p>
                </MDBCol>
                {
                  props.isSelfAssessment ? <MDBCol md="6" xs="12">
                    <MDBInput containerClass="mt-0 mb-2" outline type="textarea" label={window.I18N('note')} name="note" value={window.COMMON.getValueFromAttr(dataFeedback[dataItem._id], 'note')} onChange={handleChange} maxLength="5000" disabled={props.isSubmited || !props.isCompany} />
                    <p className="mb-1"><small className="text-muted">* {window.I18N('consultant_evaluation')}: <i className="text-danger" dangerouslySetInnerHTML={{ __html: window.COMMON.getValueFromAttr(dataFeedback[dataItem._id], 'consultantEvaluation', '').replace(/\n/g, '<br>') }}></i></small></p>
                    <p><small className="text-muted">* {window.I18N('consultant_note')}: <i className="text-danger" dangerouslySetInnerHTML={{ __html: window.COMMON.getValueFromAttr(dataFeedback[dataItem._id], 'consultantNote', '').replace(/\n/g, '<br>') }}></i></small></p>
                    <div className="text-center mb-2">
                      <InputMultiFile id={'file-upload'} icon="upload" handleFile={handleFile} description="Attached Documents"></InputMultiFile>
                    </div>
                    <div className="container-list-file wow fadeIn animated" data-wow-delay="0.5s">
                      {window.COMMON.getValueFromAttr(dataFeedback[dataItem._id], 'documents', []).map((item, i) => <div key={i} className="media item p-1">
                        <div onClick={() => window.COMMON.downloadFile(item.id, item.name)}>
                          <MDBCardImage
                            zoom cascade waves
                            src={window.COMMON.getFileType(item.ext)}
                            className="image-icon mr-2"
                          />
                        </div>
                        <div className="media-body">
                          <p className="mb-0">
                            <span className="cursor-pointer font-weight-bold" onClick={() => window.COMMON.downloadFile(item.id, item.name)}>{item.name}</span>
                          </p>
                          <small className="mb-0">{window.COMMON.bytesToSize(item.size)}</small>
                        </div>
                        <div className="media-right align-self-center cursor-pointer" onClick={() => deleteFile(i)}>
                          <MDBIcon fa="true" icon="times" style={{ color: 'gray' }} className="fa-fw mr-2"></MDBIcon>
                        </div>
                      </div>)}
                    </div>
                  </MDBCol> : <></>
                }
              </MDBRow>
            </MDBCardBody>
            <MDBCardFooter>
              <div className="d-flex justify-content-center align-items-center">
                <MDBBtn
                  outline
                  className="btn btn-sm"
                  type="button"
                  color=""
                  disabled={object.index === 0}
                  onClick={object.index === 0 ? () => { } : (event) => {
                    event.stopPropagation();
                    handlePage(object.index - 1);
                  }}
                  style={{ minWidth: '150px' }}
                >
                  <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('btn_previous')}
                </MDBBtn>
                <div className="p-1 bd-highlight mr-2 ml-2">
                  <MDBInput outline containerClass="m-0" className="text-center form-control-sm" type="text" readOnly={true} value={(object.index + 1) + '/' + data.length} maxLength="10" pattern="\S(.*\S)?" style={{ width: '80px' }}></MDBInput>
                </div>
                <MDBBtn
                  outline
                  className="btn btn-sm"
                  type="button"
                  color=""
                  disabled={object.index === data.length - 1}
                  onClick={(props.isSubmited && object.index === data.length - 1) ? () => { } : (event) => {
                    event.stopPropagation();
                    handlePage(object.index + 1);
                  }}
                  style={{ minWidth: '150px' }}
                >
                  {window.I18N('btn_next')} <MDBIcon fa="true" icon='arrow-right' className="fa-fw" />
                </MDBBtn>
              </div>
            </MDBCardFooter>
          </MDBCard>
        </div>
      </MDBCol>
    </MDBRow>
  </React.Fragment>;
});

export default ItemQA;