import gql from 'graphql-tag';

const QUERY_CATEGORY_REWARD = gql`
  query($status: Boolean) {
    getCategoryRewards(status: $status) {
      _id
      name {
        code
        text
      }
      code
      image
      createdDate
      createdBy {
        name
      }
    }
  }
`;

const MUTATION_ADD_CATEGORY_REWARD = gql`
  mutation($input: CategoryRewardInput!) {
    addCategoryReward(input: $input)
  }
`;

const MUTATION_EDIT_CATEGORY_REWARD = gql`
  mutation($input: CategoryRewardInput!) {
    editCategoryReward(input: $input)
  }
`;

const MUTATION_DEL_CATEGORY_REWARD = gql`
  mutation($id: String!) {
    deleteCategoryReward(id: $id)
  }
`;

const QUERY_REWARD = gql`
  query($status: Boolean!) {
    getRewards(status: $status) {
      _id
      name {
        code
        text
      }
      code
      title {
        code
        text
      }
      image
      point
      limit
      description {
        code
        text
      }
      category {
        _id
        name {
          code
          text
        }
      }
      createdDate
    }
  }
`;

const MUTATION_ADD_REWARD = gql`
  mutation($input: RewardInput!) {
    addReward(input: $input)
  }
`;

const MUTATION_EDIT_REWARD = gql`
  mutation($input: RewardInput!) {
    editReward(input: $input)
  }
`;

const MUTATION_DEL_REWARD = gql`
  mutation($id: String!) {
    deleteReward(id: $id)
  }
`;

const MUTATION_ADD_CODE_REWARD = gql`
  mutation($input: [CodeRewardInput!]) {
    addCodeReward(input: $input)
  }
`;

const QUERY_HISTORY_REWARD = gql`
  query($input: HistoryRewardFilter!) {
    getHistoryReward(input: $input) {
      total
      page
      pageSize
      data {
        _id
        code
        seri
        point
        category {
          code
          name {
            code
            text
          }
        }
        reward {
          code
          name {
            code
            text
          }
        }
        company {
          information {
            name
          }
        }
        union {
          information {
            name
          }
        }
        influencer {
          information {
            name
          }
        }
        createdDate
        createdBy {
          _id
          avatar
          nickname
        }
      }
    }
  }
`;

const QUERY_COUNT_HISTORY_REWARD = gql`
  query($input: HistoryRewardFilter!) {
    countHistoryReward(input: $input)
  }
`;

const QUERY_TOP_HISTORY_REWARD = gql`
  query($input: HistoryRewardFilter!) {
    topHistoryReward(input: $input) {
      _id
      count
    }
  }
`;

const QUERY_HISTORY_REWARD_BY_GOVERMENT = gql`
  query($input: HistoryRewardFilter!) {
    getHistoryRewardByGoverment(input: $input) {
      _id
      count
    }
  }
`;

const RewardGRAPHQL = {
  QUERY_CATEGORY_REWARD,
  MUTATION_ADD_CATEGORY_REWARD,
  MUTATION_EDIT_CATEGORY_REWARD,
  MUTATION_DEL_CATEGORY_REWARD,
  QUERY_REWARD,
  MUTATION_ADD_REWARD,
  MUTATION_EDIT_REWARD,
  MUTATION_DEL_REWARD,
  MUTATION_ADD_CODE_REWARD,
  QUERY_HISTORY_REWARD,
  QUERY_COUNT_HISTORY_REWARD,
  QUERY_TOP_HISTORY_REWARD,
  QUERY_HISTORY_REWARD_BY_GOVERMENT
};

export default RewardGRAPHQL;
