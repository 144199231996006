
import React, { useState, useCallback, useLayoutEffect } from 'react';
import { MDBRow, MDBCol, MDBInputGroup, MDBInput, MDBCard, MDBCardImage, MDBBtn, MDBIcon } from 'mdbreact';

import Pagination from '../../components/Pagination';

import AccountGRAPHQL from '../../../graphql/Account';
import CompanyGRAPHQL from '../../../graphql/Company';
import eventName from '../../../common/events';
import { useTracking } from 'react-tracking';

export default function RequestAccountForInfluencerPage(props) {
  const { trackEvent } = useTracking();

  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const [filter, setFilter] = useState({ text: '' });
  const [object, setObject] = useState({ isCheckboxed: false, isCheckAll: false });
  const [count, setCount] = useState({ employee: 0, company: 0, union: 0, request: 0 });
  const [pagination, setPagination] = useState({ pageSize: 30, currentPage: 0 });
  const [data, setData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [dataList, setDataList] = useState([]);

  useLayoutEffect(() => {
    setTimeout(() => {
      countDataAccountSummary();
    }, 100);
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line
  }, []);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      document.getElementById('btn-search').click();
    }
  };

  const handleFilterChange = (event) => {
    filter.text = event.target.value;
    setFilter({ ...filter });
  };

  const handleChange = (event, index) => {
    dataList[index].isChecked = event.target.checked;
    if (!event.target.checked) {
      object.isCheckboxed = false;
      object.isCheckAll = false;
      for (let i = 0; i < data.length; i++) {
        if (data[i].isChecked) {
          object.isCheckboxed = true;
          break;
        }
      }
    } else {
      object.isCheckboxed = true;
      object.isCheckAll = true;
      for (let i = 0; i < data.length; i++) {
        if (!data[i].isChecked) {
          object.isCheckAll = false;
          break;
        }
      }
    }
    setObject(object);
    setDataList([ ...dataList ]);
  };

  const handleCheckAll = (event) => {
    object.isCheckAll = event.target.checked;
    object.isCheckboxed = object.isCheckAll && dataList.length > 0;
    for (let i = 0; i < dataList.length; i++) {
      dataList[i].isChecked = object.isCheckAll;
    }
    setObject(object);
    setDataList([ ...dataList ]);
  };

  // ACCOUNT SUMMARY
  const countDataAccountSummary = async () => {
    try {
      const params = {
        companyType: COMPANY_TYPE
      };
      const result = await window.COMMON.query(AccountGRAPHQL.QUERY_COUNT_ACCOUNT_SUMMARY, params);
      if (result && result.data) {
        const data = result.data.countAccountSummary;
        count.employee = data.count;
        count.company = data.countCompany;
        count.union = data.countUnion;
        setCount({ ...count });
        getDataPendingRequest();
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountForInfluencerPage.countDataAccountSummary');
    }
  };

  // PENDING INDIVIDUAL REQUEST
  const getDataPendingRequest = async () => {
    try {
      const params = {
        companyType: COMPANY_TYPE
      };
      const result = await window.COMMON.query(AccountGRAPHQL.QUERY_PENDING_INDIVIDUAL_REQUEST, params);
      if (result && result.data) {
        const data = result.data.getPendingIndividualRelationships;
        data.forEach(item => {
          item.isChecked = false;
          item.createdDate = window.COMMON_DATE.formatDate(item.createdDate);
          item.birthday = window.COMMON_DATE.formatDate(item.user.birthday);
        });
        count.request = data.length;
        object.isCheckboxed = false;
        object.isCheckAll = false;
        setObject(object);
        setCount(count);
        setData(data);
        searchData(data);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountForInfluencerPage.getDataPendingRequest');
    }
  };

  const searchData = (data) => {
    let dataSearch = [];
    if (filter.text) {
      data.forEach(item => {
        if (window.COMMON.getValueFromAttr(item, 'user.name').toLowerCase().indexOf(filter.text.toLowerCase()) > -1 
        || window.COMMON.getValueFromAttr(item, 'user.email').toLowerCase().indexOf(filter.text.toLowerCase()) > -1 
        || window.COMMON.getValueFromAttr(item, 'user.phone').indexOf(filter.text) > -1 
        || window.COMMON.getValueFromAttr(item, 'company.information.name').toLowerCase().indexOf(filter.text.toLowerCase()) > -1) {
          dataSearch.push(item);
        }
      });
    } else {
      dataSearch = data;
    }
    setDataSearch(dataSearch);
    loadData(0, dataSearch);
  };

  const loadData = useCallback((index, items) => {
    const dataList = [];
    if (items.length > 0) {
      let check = true;
      for (let i = pagination.pageSize * index; i < pagination.pageSize * (index + 1); i++) {
        if (i > items.length - 1) {
          break;
        }
        const obj = items[i];
        if (obj) {
          dataList.push(obj);
        }
        if (!obj.isChecked) {
          check = false;
        }
      }
      object.isCheckAll = check;
    }
    pagination.currentPage = index;
    setObject(object);
    setDataList(dataList);
    setPagination({ ...pagination });
  }, [object, pagination]);

  const openAcceptAccountDialog = () => {
    window.COMMON.showModal('#modal-save');
    window.saveMethod = acceptAccount;
  };

  const openDeclineAccountDialog = () => {
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = declineAccount;
  };

  const getInputData = () => {
    const input = [];
    data.forEach(item => {
      if (item.isChecked) {
        const obj = window.COMMON.getObjectInArrs(item.company._id, input, 'company');
        if (obj) {
          obj.users.push(item.user._id);
        } else {
          input.push({
            company: item.company._id,
            companyName: item.company.information.name,
            companyType: item.company.type,
            users: [item.user._id]
          });
        }
      }
    });
    return input;
  };

  const acceptAccount = async () => {
    try {
      const params = { input: getInputData() };
      const result = await window.COMMON.mutation(CompanyGRAPHQL.MUTATION_ACCEPT_COMPANY_RELATIONSHIP_FOR_INFLUENCER, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_APPROVE)) {
        getDataPendingRequest();
        createTrackingEvent(eventName.ACCEPT_COMPANY_RELATIONSHIP_FOR_INFLUENCER);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountForInfluencerPage.acceptAccount');
    }
  };

  const declineAccount = async () => {
    try {
      const params = { input: getInputData() };
      const result = await window.COMMON.mutation(CompanyGRAPHQL.MUTATION_DECLINE_COMPANY_RELATIONSHIP_FOR_INFLUENCER, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DECLINE)) {
        getDataPendingRequest();
        createTrackingEvent(eventName.DECLINE_COMPANY_RELATIONSHIP_FOR_INFLUENCER);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountForInfluencerPage.declineAccount');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <MDBCard className="mt-4 mb-2 wow fadeInDown animated" data-wow-delay="0.5s">
        <MDBRow>
          <MDBCol md="3" size="6">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage cascade waves src={window.CONSTANT.ICON_EMPLOYEE} className="icon mr-1"/>
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">{count.employee}</h5>
                <p className="text-muted mb-0">{window.I18N('employees')}</p>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="3" size="6">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage cascade waves src={window.CONSTANT.ICON_ORGANIZATION} className="icon mr-1"/>
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">{count.company}</h5>
                <p className="text-muted mb-0">{window.I18N('companies')}</p>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="3" size="6">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage cascade waves src={window.CONSTANT.ICON_ORGANIZATION} className="icon mr-1"/>
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">{count.union}</h5>
                <p className="text-muted mb-0">{window.I18N('unions')}</p>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="3" size="6">
            <div className="d-flex align-items-center p-4 w-100">
              <MDBCardImage cascade waves src={window.CONSTANT.ICON_RESPONSE} className="icon mr-1"/>
              <div className="data ml-3">
                <h5 className="font-weight-bold mb-1">{count.request}</h5>
                <p className="text-muted mb-0">{window.I18N('request')}</p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBCard>
      
      <MDBRow className="wow fadeIn animated" data-wow-delay="1s">
        <MDBCol>
          <ul className="list-group">
            <li className="list-group-item">
              <div className="d-flex align-items-center w-100">
                <MDBInput type="checkbox" id="checkbox-all" containerClass="pl-2 pt-2" filled checked={object.isCheckAll} onChange={handleCheckAll}/>
                <div className="ml-auto mr-2" style={{ width: '350px' }}>
                  <MDBInputGroup type="text" value={filter.text} hint={window.I18N('search_name_email_organization')} onChange={handleFilterChange} maxLength="100" pattern="\S(.*\S)?" required
                    append={
                      <span className="input-group-text cursor-pointer" id="btn-search" onClick={() => searchData(data)}>
                        <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                      </span>
                    }
                  />
                </div>
                <div>
                  <MDBBtn color="danger" disabled={!object.isCheckboxed} onClick={!object.isCheckboxed ? () => {} : openDeclineAccountDialog}>
                    <MDBIcon fa="true" icon="ban" className="fa-fw"/> {window.I18N('btn_reject')}
                  </MDBBtn>
                  <MDBBtn color="primary" className="mr-0" disabled={!object.isCheckboxed} onClick={!object.isCheckboxed ? () => {} : openAcceptAccountDialog}>
                    <MDBIcon fa="true" icon="check-circle" className="fa-fw"/> {window.I18N('btn_accept')}
                  </MDBBtn>
                </div>
              </div>
            </li>
            {
              dataList.map((item, i) => <li className="list-group-item" key={i}>
                <div className="d-flex align-items-center w-100">
                  <MDBInput type="checkbox" id={'checkbox-' + item._id} containerClass="pl-2 pt-2" filled checked={item.isChecked} onChange={(event) => handleChange(event, i)}/>
                  <div className="d-flex align-items-center w-100 pl-2 pr-2">
                    <div className="w-100 mr-auto">
                      <MDBRow>
                        <MDBCol>
                          <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, 'user.avatar'))} className="image-icon rounded-circle mr-2"/>
                          <span>{window.COMMON.getValueFromAttr(item, 'user.name')}</span>
                        </MDBCol>
                        <MDBCol>
                          {
                            window.COMMON.checkValue(window.COMMON.getValueFromAttr(item, 'user.email')) ? 
                              <p className="mt-2 mb-0"><MDBIcon className="fa-fw mr-1" fa="true" icon="envelope"></MDBIcon>{window.COMMON.getValueFromAttr(item, 'user.email')}</p>
                              : <p className="mt-2 mb-0"><MDBIcon className="fa-fw mr-1" fa="true" icon="phone"></MDBIcon>{window.COMMON.getValueFromAttr(item, 'user.phone')}</p>
                          }
                        </MDBCol>
                        <MDBCol>
                          <span><MDBIcon className="fa-fw mr-1" fa="true" icon="transgender-alt"></MDBIcon>{window.COMMON.getValueFromAttr(item, 'user.gender') === 0 ? window.I18N('female') : window.I18N('male')}</span><br></br>
                          <span><MDBIcon className="fa-fw mr-1" fa="true" icon="calendar"></MDBIcon>{window.COMMON.getValueFromAttr(item, 'birthday')}</span>
                        </MDBCol>
                        <MDBCol>
                          <p className="mt-2 mb-0"><i>{window.COMMON.getValueFromAttr(item, 'company.information.name')}</i></p>
                        </MDBCol>
                      </MDBRow>
                    </div>
                    <span className="text-muted"><small>{window.COMMON.getValueFromAttr(item, 'createdDate')}</small></span>
                  </div>
                </div>
              </li>)
            }
            <li className="list-group-item">
              <Pagination total={dataSearch.length} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={(index) => loadData(index, dataSearch)}></Pagination>
            </li>
          </ul>
        </MDBCol>
      </MDBRow>
    </div>
  );
}