import React from 'react';
import { MDBBtn, MDBIcon } from 'mdbreact';

const FormModal = React.memo(function Modal(props) {
  return (
    <div className="modal fade" id={props.id} tabIndex="-1" role="dialog" aria-hidden="true">
      <div className={'modal-dialog modal-notify modal-info modal-dialog-centered' + (props.isNotScroll ? ' ' : ' modal-dialog-scrollable ') + props.className} role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title w-100 font-weight-bold">{props.title}</h5>
            <button type="button" className="close" aria-label="Close" onClick={props.onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={props.styleBody}>
            {props.children}
          </div>
          <div className="modal-footer text-center">
            <MDBBtn outline color="" onClick={props.onClose}>
              <MDBIcon fa="true" icon="times" className="fa-fw" /> {window.I18N('btn_close')}
            </MDBBtn>
            {
              props.hideSave ? <></> : <MDBBtn outline color="primary" onClick={props.saveEvent}>
                <MDBIcon fa="true" icon={props.icon || 'save'} className="fa-fw" /> {props.saveBtn ? props.saveBtn : window.I18N('btn_save')}
              </MDBBtn>
            }
          </div>
        </div>
      </div>
    </div>
  );
});

export default FormModal;