import React, { useEffect, useImperativeHandle, useState, useRef } from 'react'
import EventItem from '../EventItem/EventItem'
import EventGRAPHQL from '../../../../../graphql/Event'
import EventDetail from '../EventDetail/EventDetail'
import EventItemLoading from '../Loading/EventItemLoading'
import Image from '../../../../components/Image'
import InfiniteScroll from 'react-infinite-scroll-component'

const DISCOVERY_EVENT_TYPE = 'DISCOVERY_TYPE'
const MY_EVENT_TYPE = 'MY_EVENT_TYPE'
const PAGE_SIZE = 10
const DEFAULT_PAGE = 1

const DEFAULT_PAGINATION = {
    total: 0,
    pageSize: PAGE_SIZE,
    currentPage: DEFAULT_PAGE,
    isLoadingMore: false,
    stillMore: false
}

const EventList = React.forwardRef(
    ({ listType = { type: DISCOVERY_EVENT_TYPE, role: null }, onGetListRecommendEvent = () => {} }, ref) => {
        const { type, role } = listType
        const handleSetSearchParamsURL = (key, value) => {
            let currentUrl = new URL(window.location.href)
            let params = new URLSearchParams(currentUrl.search)

            params.set(key, value)

            currentUrl.search = params.toString()

            window.history.pushState({}, '', currentUrl)
        }

        const handleGetSearchParamsURL = () => {
            let currentUrl = new URL(window.location.href)
            let params = new URLSearchParams(currentUrl.search)

            let paramObject = {}

            for (let [key, value] of params.entries()) {
                paramObject[key] = value
            }

            return paramObject
        }

        const [dataEvents, setDataEvents] = useState(null)
        const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
        const [eventIdForDetail, setEventIdForDetail] = useState(() => {
            const { eventId } = handleGetSearchParamsURL()
            return eventId
        })

        const renderListRef = useRef()

        const handleResetSearchParamsURL = () => {
            let currentUrl = new URL(window.location.href)
            currentUrl.search = ``
            window.history.pushState({}, '', currentUrl)
        }

        const handleRedirectToDetailEvent = (data) => {
            const eventId = data.fanpageEvent._id
            handleSetSearchParamsURL('eventId', eventId)
            setEventIdForDetail(eventId)
        }

        const getFanPageEventsByUser = async (isLoading = false, filter = role) => {
            try {
                const params = {
                    page: DEFAULT_PAGE,
                    pageSize: PAGE_SIZE,
                    role: filter
                }

                const result = await window.COMMON.query(EventGRAPHQL.QUERY_FANPAGE_EVENT_BY_USER, params, isLoading)
                const totalEvents = result.data.findFanpageEventByUser.total
                const events = result.data.findFanpageEventByUser.data
                const detailEventParams = handleGetSearchParamsURL()
                if (!window.COMMON.isEmpty(detailEventParams)) {
                    setEventIdForDetail(detailEventParams.eventId)
                }
                setPagination((prev) => ({
                    ...prev,
                    total: totalEvents,
                    stillMore: events.length < totalEvents,
                    pageSize: PAGE_SIZE
                }))
                setDataEvents(events)
                return events
            } catch (error) {
                window.COMMON.showErrorLogs(`EventList.getFanPageEventsByUser ::: ${error}`)
            }
        }

        const getDiscoverFanPageEvents = async (isLoading = false) => {
            try {
                const params = {
                    page: DEFAULT_PAGE,
                    pageSize: PAGE_SIZE
                }

                const result = await window.COMMON.query(EventGRAPHQL.QUERY_DISCOVERY_FANPAGE_EVENT, params, isLoading)
                const totalEvents = result.data.getFanpageEventsCanJoinByUser.total
                const events = result.data.getFanpageEventsCanJoinByUser.data
                const detailEventParams = handleGetSearchParamsURL()
                if (!window.COMMON.isEmpty(detailEventParams)) {
                    setEventIdForDetail(detailEventParams.eventId)
                }
                setPagination((prev) => ({
                    ...prev,
                    total: totalEvents,
                    stillMore: events.length < totalEvents,
                    pageSize: PAGE_SIZE
                }))
                const modifiedEvents = events.map((event) => ({ _id: event._id, fanpageEvent: event, role: null }))
                //Sort with eventDate and slice 3 events nearest
                const listRecommendEvents = modifiedEvents
                    .sort((a, b) => a.fanpageEvent.eventDate - b.fanpageEvent.eventDate)
                    .slice(0, 5)
                if (!window.COMMON.isEmpty(listRecommendEvents)) {
                    onGetListRecommendEvent(listRecommendEvents)
                }
                setDataEvents(modifiedEvents)
                return modifiedEvents
            } catch (error) {
                window.COMMON.showErrorLogs(`EventList.getDiscoverFanPageEvents ::: ${error}`)
            }
        }

        const handleGetMoreEvents = async () => {
            const nextPage = pagination.currentPage + 1
            const params = {
                page: nextPage,
                pageSize: pagination.pageSize
            }

            const getMoreWithType = {
                [MY_EVENT_TYPE]: {
                    dataKey: 'findFanpageEventByUser',
                    getData: async () => {
                        return await window.COMMON.query(
                            EventGRAPHQL.QUERY_FANPAGE_EVENT_BY_USER,
                            { ...params, role },
                            false
                        )
                    },
                    setData: (events) => {
                        setDataEvents((prev) => [...prev, ...events])
                    }
                },
                [DISCOVERY_EVENT_TYPE]: {
                    dataKey: 'getFanpageEventsCanJoinByUser',
                    getData: async () => {
                        return await window.COMMON.query(EventGRAPHQL.QUERY_DISCOVERY_FANPAGE_EVENT, params, false)
                    },
                    setData: (events) => {
                        setDataEvents((prev) => [
                            ...prev,
                            ...events.map((event) => ({ _id: event._id, fanpageEvent: event, role: null }))
                        ])
                    }
                }
            }
            setPagination((prev) => ({ ...prev, isLoadingMore: true }))
            try {
                const { dataKey, getData, setData } = getMoreWithType[type]
                const result = await getData()
                if (result) {
                    const totalEvents = result.data[dataKey].total
                    const events = result.data[dataKey].data
                    setData(events)
                    setPagination((prev) => ({
                        ...prev,
                        total: totalEvents,
                        currentPage: prev.currentPage + 1,
                        stillMore: dataEvents.length + events.length < totalEvents,
                        isLoadingMore: false
                    }))
                }
            } catch (error) {
                window.COMMON.showErrorLogs(`EventList.handleGetMoreEvents ::: ${error}`)
            }
        }

        const getListEventsWithType = async () => {
            return type === MY_EVENT_TYPE ? await getFanPageEventsByUser() : await getDiscoverFanPageEvents()
        }

        const handleRefetchDataForEventDetail = async () => {
            const events = await getListEventsWithType()
            const dataDetailUpdated = events.find((item) => item.fanpageEvent._id === eventIdForDetail)
            if (!dataDetailUpdated) {
                handleBackEventList()
                return
            }
            setEventIdForDetail(dataDetailUpdated.fanpageEvent._id)
        }

        const handleBackEventList = () => {
            handleResetSearchParamsURL()
            setEventIdForDetail('')
            getListEventsWithType()
        }

        useEffect(() => {
            type === MY_EVENT_TYPE ? getFanPageEventsByUser() : getDiscoverFanPageEvents()
        }, [type, role])

        useImperativeHandle(ref, () => ({
            refetchListEvents: () => {
                if (type === MY_EVENT_TYPE) {
                    getFanPageEventsByUser()
                }
            },
            resetDataWhenChangeMenu: () => {
                if (eventIdForDetail) {
                    handleBackEventList()
                }
                setDataEvents(null)
                setPagination(DEFAULT_PAGINATION)
            },
            redirectToDetailEvent: handleRedirectToDetailEvent
        }))

        return (
            <div>
                {eventIdForDetail ? (
                    <EventDetail
                        eventId={eventIdForDetail}
                        onBackEventList={handleBackEventList}
                        refetchData={handleRefetchDataForEventDetail}
                        refetchDataForJoinOrLeave={
                            type === MY_EVENT_TYPE ? getFanPageEventsByUser : getDiscoverFanPageEvents
                        }
                    />
                ) : (
                    <div className="p-3 bg-white rounded-lg">
                        <h6 className="font-weight-bold mb-2" style={{ fontSize: '1.2rem' }}>
                            {type === MY_EVENT_TYPE ? window.I18N('my_event') : window.I18N('discovery_event')}
                        </h6>
                        <InfiniteScroll
                            dataLength={dataEvents ? dataEvents.length : 0}
                            next={handleGetMoreEvents}
                            hasMore={pagination.stillMore}
                        >
                            <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ flexWrap: 'wrap', gap: '8px', flexBasis: '50%' }}
                                ref={renderListRef}
                            >
                                {dataEvents === null ? (
                                    Array.from({ length: 10 }, (_, i) => i).map((_, index) => (
                                        <EventItemLoading key={index} />
                                    ))
                                ) : window.COMMON.isEmpty(dataEvents) ? (
                                    /* Render when data events is empty */
                                    <div className="w-100 flex-center" style={{ height: '90vh' }}>
                                        <div className="d-flex align-items-center flex-column justify-content-center">
                                            <Image src={window.CONSTANT.CALENDAR} alt="" loading="lazy" height={120} />
                                            <span style={{ fontSize: '1rem', fontWeight: 400, fontStyle: 'italic' }}>
                                                {window.I18N('none_event')}
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {dataEvents.map((event) => (
                                            <EventItem
                                                key={event._id}
                                                data={event}
                                                onClickDetail={handleRedirectToDetailEvent}
                                            />
                                        ))}
                                        {pagination.isLoadingMore &&
                                            Array.from({ length: 3 }, (_, i) => i).map((_, index) => (
                                                <EventItemLoading key={index} />
                                            ))}
                                    </>
                                )}
                            </div>
                        </InfiniteScroll>
                    </div>
                )}
            </div>
        )
    }
)

export default EventList
