import accountEvent from './Account';
import commentEvent from './Comment';
import companyEvent from './Company';
import groupChatEvent from './GroupChat';
import helpCenterEvent from './HelpCenter';
import languageEvent from './Language';
import learningEvent from './Learning';
import locationEvent from './Location';
import loginEvent from './Login';
import mailEvent from './Mail';
import medicalEvent from './Medical';
import metadataEvent from './Metadata';
import postEvent from './Post';
import questionEvent from './Question';
import reasonEvent from './Reason';
import rewardEvent from './Reward';
import roleEvent from './Role';
import selfAssessmentEvent from './SelfAssessment';
import surveyEvent from './Survey';
import taskScheduleEvent from './TaskSchedule';
import organizationDriveEvent from './OrganizationDrive';
import powerDashboardEvents from './PowerDashboardBI';
import fanpageEvents from './Fanpage';

const eventName = {
  ...accountEvent,
  ...commentEvent,
  ...companyEvent,
  ...groupChatEvent,
  ...helpCenterEvent,
  ...languageEvent,
  ...learningEvent,
  ...locationEvent,
  ...loginEvent,
  ...mailEvent,
  ...medicalEvent,
  ...metadataEvent,
  ...postEvent,
  ...questionEvent,
  ...reasonEvent,
  ...rewardEvent,
  ...roleEvent,
  ...selfAssessmentEvent,
  ...surveyEvent,
  ...taskScheduleEvent,
  ...organizationDriveEvent,
  ...powerDashboardEvents,
  ...fanpageEvents
};

export default eventName;