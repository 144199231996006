import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

const EventDetailLoading = () => {
    return (
        <div
            className="mb-2 border rounded-lg wow fadeIn"
            style={{ position: 'relative', width: '100%', height: '100%' }}
        >
            <Skeleton animation="wave" className="rounded-lg" variant="rect" width="100%" height={300} />
            <div className="p-3" style={{ height: '140px' }}>
                <Skeleton animation="wave" className="rounded-lg" variant="text" />
                <Skeleton animation="wave" className="rounded-lg" variant="text" width="60%" />
                <Skeleton animation="wave" className="rounded-lg" variant="text" width="50%" />
            </div>
            <div className="p-3 d-flex align-items-center justify-content-between" style={{ height: '70px' }}>
                <Skeleton animation="wave" className="rounded-lg" variant="rect" width="20%" height={40} />
                <Skeleton animation="wave" className="rounded-lg" variant="rect" width="20%" height={40} />
            </div>
        </div>
    )
}

export default EventDetailLoading
