import React from 'react';
import { MDBRow, MDBCol, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';
import Panel from '../../components/Panel';
import Table from '../../components/Table';

import AdviceGRAPHQL from '../../../graphql/Advice';

class SupportPage extends React.Component {
  constructor(props) {
    super(props);
    window.SCREEN = this;
    this.state = {
      config : [
        window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
        window.COMMON.createCol(window.I18N('sender'), '22.5%', 'p-0 pl-1', '', 'createdBy.avatar,createdBy.nickname', 'IMAGE_TEXT'),
        window.COMMON.createCol(window.I18N('email'), '15%', '', '', 'email', 'TEXT'),
        window.COMMON.createCol(window.I18N('title'), '40%', '', '', 'title', 'TEXT'),
        window.COMMON.createCol(window.I18N('created_date'), '7.5%', '', '', 'createdDate', 'TEXT'),
        window.COMMON.createCol(window.I18N('status'), '5%', '', '', 'status', 'STATUS'),
        window.COMMON.createCol(window.I18N('action'), '5%', '', '', '0', 'BUTTON')
      ],
      data: [],
      index: -1,
      dataSave: {},
      status: true,
      radio: 'ADVICE'
    };
  }

  componentDidMount() {
    this.getDataAdvice();
  }

  handleChange(event) {
    const dataSave = this.state.dataSave;
    dataSave[event.target.name] = event.target.value;
    this.setState({ dataSave });
  }

  handleSelect(attr, event) {
    window.COMMON.checkSelectValidation(attr, event);
    const select = this.state.select;
    select[attr] = event;
    const dataSave = this.state.dataSave;
    dataSave[attr] = event.value;
    this.setState({ select, dataSave });
  }

  handleRadio(value) {
    this.setState({ radio: value }, () => this.getDataAdvice());
  }

  handleFile(file) {
    const dataSave = this.state.dataSave;
    dataSave.avatar = window.COMMON.createBlobImage(file);
    this.setState({ dataSave, file });
  }

  async getDataAdvice() {
    try {
      window.resetDataTable('table-data');
      const params = {
        type: this.state.radio
      };
      const result = await window.COMMON.query(AdviceGRAPHQL.QUERY_ADVICE, params);
      if (result && result.data) {
        const data = window.COMMON.formatDate(result.data.getAdvices, 'createdDate');
        this.setState({ data });
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SupportPage.getDataAdvice');
    }
    window.initDataTable('table-data');
  }

  showContainerSave(index) {
    window.COMMON.showContainerSave();
    this.setState({ index });
    const obj = this.state.data[index];
    const status = window.COMMON.getValueFromAttr(obj, 'status');
    this.setState({ 
      dataSave : {
        _id: window.COMMON.getValueIdFromAttr(obj, '_id'),
        name: window.COMMON.getValueFromAttr(obj, 'createdBy.nickname'),
        email: window.COMMON.getValueFromAttr(obj, 'email'),
        title: window.COMMON.getValueFromAttr(obj, 'title'),
        content: window.COMMON.getValueFromAttr(obj, 'content'),
        answer: status ? window.COMMON.getValueFromAttr(obj, 'answer') : ''
      },
      status
    });
  }

  openSaveDataDialog(event) {
    if (!window.COMMON.checkFormValidation('form', event)) {
      return;
    }
    window.COMMON.showModal('#modal-save');
    window.saveMethod = this.saveData.bind(this);
  }

  async saveData() {
    try {
      const params = {
        input: this.state.dataSave
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(AdviceGRAPHQL.MUTATION_ANSWER_ADVIDE, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
        this.getDataAdvice();
        window.COMMON.backContainerData();
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SupportPage.saveData');
    }
  }

  render() {
    return (
      <div className="container-row">
        <Panel id="container-data" icon="list-alt">
          <div className="container-btn wow fadeInDown animated" data-wow-delay="0.5s">
            <MDBInput gap label={window.I18N('advice')} type="radio" id="radio-support-1" containerClass="d-inline-block" onClick={this.handleRadio.bind(this, 'ADVICE')} checked={this.state.radio === 'ADVICE' ? true : false}/>
            <MDBInput gap label={window.I18N('complaint')} type="radio" id="radio-support-2" containerClass="d-inline-block" onClick={this.handleRadio.bind(this, 'COMPLAINT')} checked={this.state.radio === 'COMPLAINT' ? true : false}/>
          </div>
          <div className="container-table wow fadeIn animated" data-wow-delay="0.5s">
            <Table id="table-data" className="table" config={this.state.config} data={this.state.data} handleRowDetailClick={this.showContainerSave.bind(this)}></Table>
          </div>
        </Panel>
        <Panel id="container-save" icon="file" style={{ display: 'none' }}>
          <div className="container-btn wow fadeInDown animated" data-wow-delay="0.5s">
            <MDBBtn color="" onClick={window.COMMON.backContainerData}>
              <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('btn_back')}
            </MDBBtn>
            <MDBBtn color="primary" type="button" onClick={this.openSaveDataDialog.bind(this)} disabled={this.state.status}>
              <MDBIcon fa="true" icon="paper-plane" className="fa-fw" /> {window.I18N('btn_send')}
            </MDBBtn>
          </div>
          
          <div className="container-top wow fadeInUp animated" data-wow-delay="0.5s">
            <form id="form" className="needs-validation" noValidate>
              <MDBRow>
                <MDBCol sm="12">
                  <MDBInput value={this.state.dataSave.email} name="email" onChange={this.handleChange.bind(this)} type="text" label={window.I18N('email')} disabled={true}></MDBInput>
                  <MDBInput value={this.state.dataSave.title} name="title" onChange={this.handleChange.bind(this)} type="text" label={window.I18N('title')} disabled={true}></MDBInput>
                </MDBCol>
                <MDBCol sm="6">
                  <MDBInput value={this.state.dataSave.content} name="content" onChange={this.handleChange.bind(this)} type="textarea" label={window.I18N('content')} disabled={true} style={{ height: '300px' }}></MDBInput>
                </MDBCol>
                <MDBCol sm="6">
                  <MDBInput value={this.state.dataSave.answer} name="answer" onChange={this.handleChange.bind(this)} type="textarea" label={window.I18N('answer') + ' *'} maxLength="5000" style={{ height: '300px' }} required disabled={this.state.status}></MDBInput>
                </MDBCol>
              </MDBRow>
            </form>
          </div>
        </Panel>
      </div>
    );
  }
}

export default SupportPage;