import gql from 'graphql-tag'

const QUERY_FANPAGE_EVENT_BY_USER = gql`
    query ($role: String, $page: Int, $pageSize: Int) {
        findFanpageEventByUser(role: $role, page: $page, pageSize: $pageSize) {
            data {
                _id
                fanpageEvent {
                    _id
                    fanpage {
                        _id
                        name
                        image
                        description
                        countMember
                        active
                        isHide
                        gopy
                        type
                        createdDate
                    }
                    name
                    images
                    description
                    limitMember
                    countMember
                    reminders
                    isApproveMember
                    status
                    gopy
                    eventDate
                    createdDate
                    createdBy {
                        _id
                        email
                        name
                        nickname
                    }
                    updatedDate
                    updatedBy {
                        _id
                        email
                        name
                        nickname
                    }
                }
                user {
                    _id
                    email
                    name
                }
                role
                status
                gopy
                createdDate
                createdBy {
                    _id
                    email
                    name
                }
                updatedDate
                updatedBy {
                    _id
                    email
                    name
                }
            }
            total
            page
            pageSize
        }
    }
`

const QUERY_USER_IN_FANPAGE_EVENT = gql`
    query ($id: String!, $status: String, $page: Int, $pageSize: Int) {
        findMembersInFanpageEvent(id: $id, status: $status, page: $page, pageSize: $pageSize) {
            total
            page
            pageSize
            data {
                user {
                    _id
                    name
                    nickname
                    gopy
                    avatar
                    email
                    phone
                    accountType
                    establishedDate
                    address
                    bio
                    birthday
                    gender
                    status
                    friendStatus
                    receiver
                    createdDate
                    createdBy
                    active
                    maritalStatus
                }
            }
        }
    }
`

const QUERY_DETAIL_FANPAGE_EVENT = gql`
    query ($id: String!) {
        getFanpageEventDetail(id: $id) {
            _id
            name
            images
            description
            limitMember
            countMember
            reminders
            isApproveMember
            status
            gopy
            eventDate
            createdDate
            updatedDate
            fanpage {
                _id
                name
                image
                description
                countMember
                active
                isHide
                gopy
                type
                createdDate
            }
            createdBy {
                _id
                email
                name
                nickname
            }
        }
    }
`

const QUERY_DETAIL_RELATIONSHIP_FANPAGE_EVENT = gql`
    query ($id: String!) {
        findFanpageEventRelationshipDetail(id: $id) {
            _id
            role
            status
            gopy
            createdDate
            updatedDate
        }
    }
`

const QUERY_DISCOVERY_FANPAGE_EVENT = gql`
    query ($page: Int, $pageSize: Int) {
        getFanpageEventsCanJoinByUser(page: $page, pageSize: $pageSize) {
            total
            page
            pageSize
            data {
                _id
                name
                images
                description
                limitMember
                countMember
                reminders
                isApproveMember
                status
                gopy
                eventDate
                createdDate
                updatedDate
                updatedBy {
                    _id
                    name
                    nickname
                    avatar
                    email
                }
                fanpage {
                    _id
                    name
                    image
                    description
                    active
                    type
                }
            }
        }
    }
`
const QUERY_FANPAGE_EVENTS = gql`
    query($fanpage: String!, $page: Int, $pageSize: Int, $sortType: String) {
        getFanpageEvents(fanpage: $fanpage, page: $page, pageSize: $pageSize, sortType: $sortType) {
            data{
                _id
                name
                images
                description
                limitMember
                countMember
                reminders
                isApproveMember
                status
                gopy
                createdDate
                updatedDate
                eventDate
                createdBy{
                    _id
                    avatar
                    name
                }
            }
            total
        }
    }
`

const FIND_FANPAGE_EVENTS_RELATIONSHIP_BY_USER = gql`
    query($fanpage: String!, $page: Int, $pageSize: Int) {
        findFanpageEventRelationshipByUser(fanpage: $fanpage, page: $page, pageSize: $pageSize) {
        data{
            _id
            fanpageEvent{
                _id
            }
            role
            status
            createdDate
        }
        total
        }
    }
`

const QUERY_CHECK_PERMISSION_FANPAGE_EVENT = gql`
    query ($eventId: String!) {
        checkPermissionFanpageEvent(eventId: $eventId)
    }
`

const MUTATION_ADD_FANPAGE_EVENT = gql`
    mutation ($input: FanpageEventInput!) {
        addFanpageEvent(input: $input)
    }
`

const MUTATION_EDIT_FANPAGE_EVENT = gql`
    mutation ($id: String!, $name: String!, $description: String, $images: [String], $limitMember: Int, $gopy: Int) {
        editInfoFanpageEvent(
            id: $id
            name: $name
            description: $description
            images: $images
            limitMember: $limitMember
            gopy: $gopy
        )
    }
`

const MUTATION_DELETE_FANPAGE_EVENT = gql`
    mutation ($id: String!) {
        deleteFanpageEvent(id: $id)
    }
`
const MUTATION_REQUEST_JOIN_FANPAGE_EVENT = gql`
    mutation ($id: String!) {
        requestFanpageEventRelationship(id: $id)
    }
`
const MUTATION_LEAVE_FANPAGE_EVENT = gql`
    mutation ($id: String!) {
        leaveFanpageEventRelationship(id: $id)
    }
`

const EventGRAPHQL = {
    QUERY_FANPAGE_EVENT_BY_USER,
    QUERY_USER_IN_FANPAGE_EVENT,
    QUERY_DETAIL_FANPAGE_EVENT,
    QUERY_DETAIL_RELATIONSHIP_FANPAGE_EVENT,
    QUERY_DISCOVERY_FANPAGE_EVENT,
    QUERY_CHECK_PERMISSION_FANPAGE_EVENT,
    QUERY_FANPAGE_EVENTS,
    FIND_FANPAGE_EVENTS_RELATIONSHIP_BY_USER,
    MUTATION_ADD_FANPAGE_EVENT,
    MUTATION_EDIT_FANPAGE_EVENT,
    MUTATION_DELETE_FANPAGE_EVENT,
    MUTATION_REQUEST_JOIN_FANPAGE_EVENT,
    MUTATION_LEAVE_FANPAGE_EVENT,
}

export default EventGRAPHQL
