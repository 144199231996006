import React from 'react';
import { MDBBtn, MDBIcon } from 'mdbreact';

const Modal = React.memo(function Modal(props) {
  return (
    <div className="modal fade" id={props.id} tabIndex="-1" role="dialog" aria-hidden="true">
      <div className={'modal-dialog modal-notify modal-info modal-dialog-centered' + (props.isNotScroll ? ' ' : ' modal-dialog-scrollable ') + props.className} role="document">
        <div className={'modal-content ' + props.classModalContent}>
          <div className="modal-header">
            <h5 className="modal-title w-100 font-weight-bold">{props.title}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.handleCloseModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={props.styleBody}>
            {props.children}
          </div>
          {
            props.hideFooter ? <></> : <div className="modal-footer text-center">
              {props.footerActions}
             {props.hideCloseBtn === false && <MDBBtn outline color="" data-dismiss="modal" onClick={props.handleCloseModal}>
                <MDBIcon fa="true" icon={props.iconClose || 'times'} className="fa-fw" /> {props.closeBtn ? props.closeBtn : window.I18N('btn_close')}
              </MDBBtn>}
			        {
                props.hideReject === false && <MDBBtn outline color="red" onClick={props.rejectEvent}>
                  <MDBIcon fa="true" icon={props.rejectIcon || 'ban'} className="fa-fw" /> {props.rejectBtn ? props.rejectBtn : window.I18N('btn_reject')}
                </MDBBtn>
              }
              {
                props.hideSave ? <></> : <MDBBtn outline color="primary" onClick={props.saveEvent} {...props.saveBtnProps || {}}>
                  <MDBIcon fa="true" icon={props.icon || 'save'} className="fa-fw" /> {props.saveBtn ? props.saveBtn : window.I18N('btn_save')}
                </MDBBtn>
              }
              {
                props.actionTemplate ? <div className={`w-full flex-center ${props.hideSaveTemplate ? "justify-content-end" : "justify-content-between"} `} style={{flex: 1}}>
                    {props.hideSaveTemplate === false && <MDBBtn {...props.saveBtnProps || {}} outline color="primary" onClick={props.saveTemplateEvent}>
                      <MDBIcon fa="true" icon={props.icon || 'save'} color='primary' className="fa-fw" /> {props.saveBtn ? props.saveBtn : window.I18N('btn_save')}
                    </MDBBtn>}
                    <MDBBtn {...props.exportBtnProps || {}} color={props.statusExportBtn || 'primary'} outline onClick={props.exportEvent}>
                        <MDBIcon fa="true" icon={props.iconExportBtn || 'download'} className="fa-fw" /> {props.exportBtn ? props.exportBtn : window.I18N('export')}
                    </MDBBtn>
                </div> : <></>
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
});

export default Modal;