import React from 'react';
import { MDBCol, MDBIcon, MDBInput, MDBRow } from 'mdbreact';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

import Common from '../../../../utils/Common';


const ItemPreviewCase = (props) => {
  const { formData, options, language } = props || {};
 
  return (
    <div className="wow fadeIn animated" data-wow-delay="0.5s">
      <form id="preview-case-form" className="needs-validation mt-2" noValidate>
        <MDBRow>
          <MDBCol sm="6">
            <MDBInput outline containerClass="mt-0" type="text" label={window.I18N('name') + ' *'} name="name" value={formData.name} maxLength="100" pattern="\S(.*\S)?" required>
              <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
            </MDBInput>
            <MDBRow>
              <MDBCol>
                <MDBInput outline containerClass="m-0" type="text" label={window.I18N('code') + ' *'} name="code" value={formData.code} maxLength="50" pattern="\S(.*\S)?" required>
                  <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                </MDBInput>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mt-3'>
              <MDBCol sm="7">
                <div className="input-group input-group-transparent">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                    </span>
                    <span className="input-group-text">{window.I18N('case_due_date')}</span>
                  </div>
                  <DatePicker 
                    placeholderText={window.I18N('case_due_date')} 
                    className="form-control" 
                    dateFormat="dd/MM/yyyy" 
                    selected={formData.approvalReminderDate} 
                    startDate={formData.approvalReminderDate}
                    disabled
                  />
                </div>
              </MDBCol>
            </MDBRow>
            {Common.checkRoleIsSystem() && (
              <Select
                id="select-influencer"
                value={formData.influencer}
                options={options.influencers}
                getOptionLabel={(option) => option.information.name}
                getOptionValue={(option) => option._id}
                placeholder={window.I18N('influencer')}
                className="md-form"
                isDisabled
              />
            )}
          </MDBCol>
          <MDBCol sm="6">
            <Select
              id="select-industry"
              value={formData.companyIndustry}
              options={options.industries}
              getOptionValue={(option) => option._id}
              getOptionLabel={(option) => option.value}
              placeholder={window.I18N('industry')}
              className="md-form mt-0"
              isDisabled
            />
            <Select
              id="select-nation"
              value={formData.nation}
              options={options.nations}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              placeholder={window.I18N('country')}
              className="md-form mt-0"
              isDisabled
            />
            <MDBRow>
              <MDBCol sm="6">
                <Select
                  id="select-city"
                  value={formData.state}
                  options={options.states}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  placeholder={window.I18N('state_city')}
                  className="md-form mt-0"
                  isDisabled
                />
              </MDBCol>
              <MDBCol sm="6">
                <Select
                  id="select-state"
                  value={formData.city}
                  options={options.cities}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  placeholder={window.I18N('city_district')}
                  className="md-form mt-0"
                  isDisabled
                />
              </MDBCol>
            </MDBRow>
            <Select
              id="select-companies"
              value={formData.companies}
              getOptionLabel={(option) => option.information.name}
              getOptionValue={(option) => option._id}
              placeholder={`${window.I18N('companies')} *`}
              className="md-form mt-0"
              isMulti 
              isDisabled
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="6">
            <MDBInput
              outline
              containerClass="mt-0"
              type="text"
              label={window.I18N('name') + ' *'}
              name="assessmentName"
              value={formData.assessmentName}
              maxLength="100"
              pattern="\S(.*\S)?"
              required
            >
              <div className="invalid-feedback">
                {window.I18N('MSG_CODE_029')}
              </div>
            </MDBInput>
            <MDBRow>
              <MDBCol>
                <MDBInput
                  outline
                  containerClass="m-0"
                  type="text"
                  label={window.I18N('code') + ' *'}
                  name="assessmentCode"
                  value={formData.assessmentCode}
                  maxLength="50"
                  pattern="\S(.*\S)?"
                  required
                >
                  <div className="invalid-feedback">
                    {window.I18N('MSG_CODE_029')}
                  </div>
                </MDBInput>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mt-3'>
              <MDBCol>
                <div className="input-group input-group-transparent">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon
                        fa="true"
                        icon="calendar-alt"
                        className="fa-fw"
                      />
                    </span>
                  </div>
                  <DatePicker
                    placeholderText={window.I18N('start_date') + ' *'}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    selected={formData.assessmentStartDate}
                    startDate={formData.assessmentStartDate}
                    endDate={formData.assessmentEndDate}
                    disabled
                  />
                </div>
              </MDBCol>
              <MDBCol>
                <div className="input-group input-group-transparent">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon
                        fa="true"
                        icon="calendar-alt"
                        className="fa-fw"
                      />
                    </span>
                  </div>
                  <DatePicker
                    placeholderText={window.I18N('end_date') + ' *'}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    selected={formData.assessmentEndDate}
                    startDate={formData.assessmentStartDate}
                    endDate={formData.assessmentEndDate}
                    disabled
                  />
                </div>
              </MDBCol>
            </MDBRow>
            <MDBInput
              outline
              containerClass="mb-0"
              type="textarea"
              label={window.I18N('description')}
              name="assessmentDescription"
              value={formData.assessmentDescription}
              maxLength="5000"
            ></MDBInput>
          </MDBCol>
          <MDBCol sm="6">
            <Select
              id="select-advisors"
              value={formData.advisors}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option._id}
              options={options.advisors}
              placeholder={window.I18N('assign_influencer')}
              className="md-form mt-0"
              isMulti
              isDisabled
            />
            <Select
              id="select-type"
              className="md-form mt-0"
              value={formData.assessmentType}
              placeholder={window.I18N('type') + ' *'}
              options={options.types}
              isDisabled
            />
            <Select
              id="select-period"
              className="md-form"
              value={formData.assessmentPeriod}
              placeholder={window.I18N('period') + ' *'}
              options={options.periods}
              isDisabled
              isSearchable
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12">
            <MDBInput outline containerClass="mb-0" type="textarea" label={window.I18N('reason')} name="reason" value={formData.reason} maxLength="5000"></MDBInput>
            <MDBInput outline containerClass="mb-0" type="textarea" label={window.I18N('goal')} name="goal" value={formData.goal} maxLength="5000"></MDBInput>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12">
            <h4 className='text-bold mt-5 mb-2'>{window.I18N('selected_group_qa')}</h4>
          </MDBCol>
          <MDBCol sm="9">
            <Select
              id="select-group-qa"
              value={formData.assessmentGroupQA}
              options={options.groupQA}
              getOptionValue={(option) => option._id}
              getOptionLabel={(option) => option.name ? Common.getValueWithLanguage(option, 'name', language) : option.code }
              placeholder={window.I18N('selected_group_qa')}
              className="md-form"
              isMulti
              isDisabled
            />
          </MDBCol>
        </MDBRow>
      </form>
    </div>
  );
};

export default ItemPreviewCase;
