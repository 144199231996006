import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper
  },
  imageList: {
    width: '100%',
    flexWrap: 'nowrap',
    transform: 'translateZ(0)'
  },
  title: {
    color: theme.palette.primary.light
  },
  titleBar: {
    background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  }
}));

const ListImage = React.memo(function ListImage(props) {
  const classes = useStyles();

  const handleDeleteItemClick = (event, index) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.handleDeleteItem) {
      props.handleDeleteItem(index);
    }
  };

  return (
    <div className={classes.root}>
      <ImageList className={classes.imageList} cols={3.5}>
        {props.data.map((item, i) => (
          <ImageListItem key={item}>
            <img src={item} alt="" />
            <ImageListItemBar
              actionIcon={
                <IconButton aria-label="close" onClick={(event) => handleDeleteItemClick(event, i)}>
                  <CloseIcon className={classes.title} style={{ color: '#dddddd' }} />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
});

export default ListImage;