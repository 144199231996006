import React, { useState, useEffect, useRef, useMemo } from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import { useHistory } from 'react-router-dom';
import { CustomChatPageLayout } from '../../../styles/styled-components/Chat/ChatPage';
import ChatLeftSide from './ChatLeftSide/ChatLeftSide';
import ChatRightSide from './ChatRightSide/ChatRightSide';
import socket from '../../../utils/socket';
import EmptyDataText from '../../components/EmptyDataText';
import RelationshipGRAPHQL from '../../../graphql/Relationship';
import AccountGRAPHQL from '../../../graphql/Account';
import ChatGRAPHQL from '../../../graphql/Chat';
import ChatGroupFormModal from './ChatGroupFormModal';
import GroupChatGRAPHQL from '../../../graphql/GroupChat';
import InviteMembersFormModal from './InviteMembersFormModal';
import JoinRequestsFormModal from './JoinRequestsFormModal';
import MembersFormModal from './MembersFormModal';
import MetadataGRAPHQL from '../../../graphql/Metadata';
import {fetchRelationships, getRelationshipItem} from "../../../utils/Relationships";
import ProfileSelectUser from './ChatRightSide/BodyChatRightSide/ProfileSelectUser';
const ChatPage = () => {
  const NEW_GROUP_CHAT_MODAL_ID = 'new-group-chat';
  const INVITE_MEMBERS_MODAL_ID = 'invite-members';
  const JOIN_REQUEST_MODAL_ID = 'join-requests';
  const MEMBERS_MODAL_ID = 'member-list';
  const history = useHistory();

  const [individualGroupChatList, setIndividualGroupChatList] = useState([]);
  const [manyGroupChatList, setManyGroupChatList] = useState([]);
  const [groupChatSelected, setGroupChatSelected] = useState({});
  const [userProfileSelect, setUserProfileSelect] = useState(null)
  const [messages, setMessages] = useState([]);
  const [reply, setReply] = useState(null);
  const [friends, setFriends] = useState({
    ids: [],
    data: []
  });
  const [options, setOptions] = useState({ companies: [] });
  const [groupChatFormDataSave, setGroupChatFormDataSave] = useState({});
  const [groupChatMenuItems, setgroupChatMenuItems] = useState([]);
  const [showJoinRequestModal, setShowJoinRequestModal] = useState(false);
  const [showMembersModal, setShowMembersModal] = useState(false);
  const [blockedGroupsIds, setBlockedGroupsIds] = useState([]);
  const [initFetchBlockedGroups, setInitFetchBlockedGroup] = useState(false);
  const [usersOnOff, setUsersOnOff] = useState([]);
  const [initMemberCreateGroupForm, setInitMemberCreateGroupForm] = useState(null);
  const chatLeftSideRef = useRef();
  const dataRef = useRef({});
  dataRef.current = { groupChatSelected, blockedGroupsIds, usersOnOff };

  // store category name and stickers
  const [dataStickers, setDataStickers] = useState([]);
  // store all stickers to check in message
  const [allStickers, setAllStickers] = useState([]);


  useEffect(() => {
    if (!checkIsValidGroupChat(groupChatSelected)) {
      return;
    }
    createGroupChatMenuItems(groupChatSelected);
    return () => {
      setgroupChatMenuItems([]);
    };
  }, [groupChatSelected]);

  //socket
  useEffect(() => {
    // get friends
    (async () => {
      await getDataFriends();
    })();
    getStickersByParent('-1');
    
    getBlockedGroups();
    socket.emit('user-list');

    socket.on('new-message', handleNewMessage);
    socket.on('accept-friend', handleAcceptFriendEvent);
    socket.on('add-friend', handleAddFriendEvent);
    socket.on('decline-friend', handleDeclineFriendEvent);
    socket.on('remove-friend', handleRemoveFriendEvent);
    socket.on('cancel-friend', handleCancelFriendEvent);
    socket.on('update-group-chat', handleUpdateGroupChatEvent);
    socket.on('join-group-request', handleJoinGroupRequestEvent);
    socket.on('remove-from-group', handleRemoveFromGroupEvent);

    socket.on('user-list', handleUserListEvent);
    socket.on('user-online', handleUserOnOffEvent);
    socket.on('user-offline', handleUserOnOffEvent);
    return () => {
      socket.off('new-message');
      socket.off('accept-friend');
      socket.off('add-friend');
      socket.off('decline-friend');
      socket.off('remove-friend');
      socket.off('cancel-friend');
      socket.off('update-group-chat');
      socket.off('join-group-request');
      socket.off('remove-from-group');

      socket.off('user-list');
      socket.off('user-online');
      socket.off('user-offline');
    };
  }, []);

  useEffect(() => {
    const result = dataStickers
      .map(item => item.stickers)
      .flat();
    setAllStickers(result);
  }, [dataStickers]);

  const getDataFriends = async () => {
    try {
      //get relationship
      const result = await window.COMMON.query(RelationshipGRAPHQL.QUERY_FIND_FRIENDS_RELATIONSHIP_BY_USER, {}, true);
      const friends = [];
      let friendIds = [];
      const currentUserId = localStorage.getItem(window.CONSTANT.ID);
      if (result && result.data) {
        const relationships = result.data.findFriendsByUser;
        relationships.forEach((item) => {
          let friend;
          if (item.createdBy && item.createdBy._id !== currentUserId) {
            friend = item.createdBy;
          } else if (item.receiver && item.receiver._id !== currentUserId) {
            friend = item.receiver;
          }
          if (friend){
            friends.push({
              ...friend,
              relationshipType: window.CONSTANT.FRIEND
            });
          }
        });
      }
      friendIds = friends.map((item) => item._id);
      setFriends(prev => ({
        ids: friendIds,
        data: friends
      }));

    }
    catch (error) {
      window.COMMON.showErrorLogs('ChatPage.getDataFriends');
    }
  };
  const getFriendsFromParamId = async (userId) => {
    const params = {
      id: userId
    };
    try {
      await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_BY_ID, params).then((result) => {
        if (result && result.data) {
          const user = result.data.findAccountById;
          if (user._id) {
            setFriends((current) => {
              const currentFriendId = current.ids.find((item) => item === user._id);
              if (!currentFriendId) {
                current.data.push({
                  ...user
                });
                current.ids.push(user._id);
              }
              return { ...current };
            }
            );
          } else {
            window.showNotification('error', window.I18N('user_not_found'));
            history.replace('/chat');
          }
        }
      }
      );  
    } catch (error) {
      window.COMMON.showErrorLogs('ChatPage.getFriendsFromParamId');
    }
  };
  const getBlockedGroups = async () => {
    let blockedGroupsIdsData = [];
    try {
      const result = await window.COMMON.query(ChatGRAPHQL.QUERY_GET_BLOCKED_GROUP_RELATIONSHIP);
      if (result && result.data) {
        const rawData = result.data.getBlockedGroupRelationship;
        blockedGroupsIdsData = rawData.map((item) => item.group._id);

      }
    }
    catch (error) {
      window.COMMON.showErrorLogs('ChatPage.getBlockedGroups');
    }

    setBlockedGroupsIds(blockedGroupsIdsData);
    setInitFetchBlockedGroup(true);
  };


  const handleShowGroupChat = async (groupChat) => {
    if (groupChat._id === groupChatSelected._id) return;
    //check in friend list to set type of individual group chat again
    if (window.COMMON.checkIndividualChatType(groupChat.type)) {
      groupChat.type = window.CONSTANT.FRIEND;

    }
    else {
      const isBlocked = checkGroupIsBlocked(groupChat);
      if (isBlocked === true) {
        window.COMMON.showMessage('warning', 'MSG_CODE_063', window.I18N('MSG_CODE_063'));
        return;
      }

      const joinRequestCount = await getJoinRequestCount(groupChat._id);
      groupChat.joinRequestCount = joinRequestCount;

    }

    setGroupChatSelected(groupChat);
    setUserProfileSelect(null)
  };
  const handleCloseGroupChat = () => {
    setGroupChatSelected({});
  };

  const handleSelectUserProfile = (data)=>{
    setUserProfileSelect(data)
    setGroupChatSelected({});
  }

  const getJoinRequestCount = async (groupId) => {
    let count = 0;
    try {
      const params = {
        status: 'PENDING',
        groupId
      };

      const result = await window.COMMON.query(ChatGRAPHQL.QUERY_GET_COUNT_GROUP_RELATIONSHIP, params);
      if (result && result.data) {
        count = result.data.getCountGroupRelationShip;
      }
    }
    catch (error) {
      window.COMMON.showErrorLogs('ChatPage.getJoinRequestCount');
    }
    return count;

  };

  //handler of new-message socket event
  const handleNewMessage = (data) => {
    const { newMessage, groupChat } = data;
    if (checkGroupIsBlocked(groupChat)) return;
    updateChatList(newMessage, groupChat);
    if (!dataRef.current || !dataRef.current.groupChatSelected) return;


    //if have new message from group chat which was selected
    // then add it to message array
    if (dataRef.current.groupChatSelected._id === groupChat.id) {
      handleAddMessage(newMessage);
    }
    else {

    }



  };

  const handleAddMessage = (newMessage) => {
    setMessages((current) => {

      //remove draft message
      const draftIndex = current.findIndex((item) => {
        return (item.code === newMessage.code && item.isDraft === true);
      });
      if (draftIndex >= 0) {
        current.splice(draftIndex, 1);
      }
      if (current.length > 0) {
        const topMessage = current[0];
        if (topMessage.timestamp === newMessage.timestamp && topMessage.type === newMessage.type) {
          current.splice(0, 1);
        }
      }
      current.unshift({
        ...newMessage,
        isCurrentUser: newMessage.createdBy._id === localStorage.getItem(window.CONSTANT.ID)
      });
      return [...current];
    });
  };

  const updateChatList = (newMessage, groupChat) => {
    if (window.COMMON.checkIndividualChatType(groupChat.type)) {
      updateIndividualGroupChatList(newMessage, groupChat);
      /* if (chatLeftSideRef.current) {
        chatLeftSideRef.current.changeFilterRadio(0);
      } */
    }
    else {
      updateManyGroupChatList(newMessage, groupChat);
      /* if (chatLeftSideRef.current) {
        chatLeftSideRef.current.changeFilterRadio(1);
      } */
    }
  };

  const updateIndividualGroupChatList = (newMessage, groupChat) => {
    setIndividualGroupChatList((current) => {
      const index = current.findIndex((item) => item._id === groupChat._id);
      let newGroupChat;
      if (index < 0) {
        newGroupChat = {
          ...groupChat,
          lastMessage: newMessage
        };

      }
      else {
        const currentGroupChat = current[index];
        current.splice(index, 1);
        newGroupChat = {
          ...currentGroupChat,
          lastMessage: newMessage
        };
      }
      if (newGroupChat) {
        current.unshift(newGroupChat);
      }
      return [...current];
    });
  };
  const updateManyGroupChatList = (newMessage, groupChat) => {
    setManyGroupChatList((current) => {
      const index = current.findIndex((item) => item._id === groupChat._id);
      let newGroupChat;
      if (index < 0) {
        newGroupChat = {
          ...groupChat,
          lastMessage: newMessage
        };
      }
      else {
        const currentGroupChat = current[index];
        current.splice(index, 1);
        newGroupChat = {
          ...currentGroupChat,
          lastMessage: newMessage
        };
      }
      if (newGroupChat) {
        current.unshift(newGroupChat);
      }
      return [...current];
    });
  };



  const getReceiver = (groupChat) => {
    let receiver;
    let memberIds = [];
    const currentUser = window.COMMON.getCurrentUserInformation();
    if (groupChat.members) {
      memberIds = groupChat.members.map((item) => {
        if (item._id) return item._id;
        if (item.id) return item.id;
      });
    }
    if (window.COMMON.checkIndividualChatType(groupChat.type)) {
      const userGiveMessageId = memberIds.find(item => item !== currentUser._id);
      receiver = userGiveMessageId;
    }
    else {
      receiver = groupChat._id;
    }
    return receiver;
  };

  const handleSendMessage = (message, groupChat, type = 0, moreInfor = {}) => {
    if (!message) return;
    const currentUser = window.COMMON.getCurrentUserInformation();
    const messageCode = window.COMMON.generateCode('MS');
    // let memberIds = [];
    // if (groupChat.members) {
    //   memberIds = groupChat.members.map((item) => {
    //     if (item._id) return item._id;
    //     if (item.id) return item.id;
    //     if (typeof item === 'string') return item; //is ID
    //   });
    // }

    const receiver = getReceiver(groupChat);

    const members = groupChat.members;
    let memberIds = [];

    const isGroup = (groupChat.type === window.CONSTANT.GROUP || groupChat.type === window.CONSTANT.PRIVATE_GROUP) ? 1 : 0

    if(isGroup===1){
      memberIds=[...members]
    }
    else{
      memberIds=members?.map((item) => item.id || item._id);
    }

    //make sure members ids array contain only string values
    if(memberIds[0] && typeof memberIds[0] !== "string"){
      memberIds=[]
    }

 



    const newMessage = {
      content: message,
      receiver,
      code: messageCode,
      sender: currentUser._id,
      timestamp: new Date().getTime().toString(),
      chat_type: groupChat.type,
      type,
      members: groupChat.members,
      memberIds,
      groupChatId: groupChat._id,
      isGroup: isGroup,
	    reply,
	    title: (groupChat.type === window.CONSTANT.GROUP || groupChat.type === window.CONSTANT.PRIVATE_GROUP) ? groupChat.groupName : (currentUser.nickname || currentUser.name),
      image: (groupChat.type === window.CONSTANT.GROUP || groupChat.type === window.CONSTANT.PRIVATE_GROUP) ? groupChat.image : currentUser.avatar,
      moreInfor
    };

    if (type === 0) {
      const draftMessage = {
        ...newMessage,
        isDraft: true,
        createdBy: currentUser,
        isCurrentUser: true
      };
      setMessages((current) => {
        current.unshift(draftMessage);
        return [...current];
      });
    }
    socket.emit(
      'send-message',
      newMessage,
      (errorMessage) => {
        handleSendMessageError(errorMessage, messageCode);
      }
    );

    //clear reply (if exist)
    setReply(null);
  };

  const handleSendMessageError = (errorMessage, messageCode) => {
    setMessages((current) => {

      //remove draft message
      const draftIndex = current.findIndex((item) => item.code === messageCode);
      if (draftIndex >= 0) {
        current.splice(draftIndex, 1);
      }

      return [...current];
    });

    window.COMMON.showMessage('error', 'MSG_CODE_050', errorMessage);
  };

  const handleFetchIndividualGroupChatListSuccess = (groupchats) => {
    updateGroupChatListOnOff(groupchats, dataRef.current?.usersOnOff);

    setIndividualGroupChatList((current) => {
      return [
        ...current,
        ...groupchats
      ];
    });
  };

  const handleFetchManyGroupChatListSuccess = (groupchats) => {

    const newGroupChats = groupchats.filter((item) => {
      if (checkGroupIsBlocked(item)) {
        return false;
      }
      return true;
    });


    setManyGroupChatList((current) => {
      const groupChatsToAdd = [
        ...current,
        ...newGroupChats
      ]
      groupChatsToAdd.sort((a,b)=>{
        const firstTimestamp = Number(a?.lastMessage?.timestamp);
        const secondTimestamp = Number(b?.lastMessage?.timestamp);
        const firstTimestampNumber = Number.isNaN(firstTimestamp) ? 0 : firstTimestamp;
        const secondTimestampNumber = Number.isNaN(secondTimestamp) ? 0 : secondTimestamp;

       return firstTimestampNumber > secondTimestampNumber ? -1 : 1

      })
      return groupChatsToAdd;
    });
  };

  // STICKER
  const getStickersByParent = async (rootCode) => {
    const data = await getDataSticker(rootCode);
    if (data && data.length>0) {
      for (const item of data) {
        const dataSticker = await getDataSticker(item.code);
        if (dataSticker && dataSticker.length > 0) {
          setDataStickers(prev => ([
            ...prev,
            {
              categoryName: item.name,
              stickers: dataSticker
            }
          ]));
        }
      }
    }
  };
  const getDataSticker = async (parentCode) => {
    try {
      const params = {
        parentCode
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_STICKERS, params);
      let data = [];
      if (result && result.data) {
        data = window.COMMON.formatDate(result.data.getStickers, 'createdDate');
        return data;
      }
    } catch (error) {
      window.COMMON.showErrorLogs('EmotionPage.getDataSticker');
    }
  };

  const checkIsValidGroupChat = (groupChatSelected) => {
    return Object.keys(groupChatSelected).length > 0;
  };


  const createGroupChatMenuItems = async (groupChat) => {
    let newgroupChatMenuItems = [];

    if(groupChat.type===window.CONSTANT.FRIEND){

      //get relationships
      const {relationships} = await fetchRelationships([window.CONSTANT.PENDING,window.CONSTANT.ACCEPTED]);
      const pendingRelationships = relationships.filter((item)=>item.status===window.CONSTANT.PENDING);
      const acceptedRelationships = relationships.filter((item)=>item.status===window.CONSTANT.ACCEPTED);
      const { anotherUser } = window.COMMON.splitMembersFromIndividualGroupChat(groupChat);
      let friendRelationship;
      if(anotherUser){
        friendRelationship = getRelationshipItem(acceptedRelationships,anotherUser?.id,window.COMMON.ACCEPTED)
      }

      if(friendRelationship){
        //check and add menu items for group chat that has users have accepted relationship status
        const friendChatGroupChatMenuItems = createGroupChatMenuItemsForFriendChat(groupChat);
        newgroupChatMenuItems = newgroupChatMenuItems.concat(friendChatGroupChatMenuItems);
      }
      else{
         //check and add menu items for group chat that has users have pending relationship status or don't have relationship
        const privateChatgroupChatMenuItems = await createGroupChatMenuItemsForPrivateChat(groupChat,pendingRelationships);
        newgroupChatMenuItems = newgroupChatMenuItems.concat(privateChatgroupChatMenuItems);
      }
    }

    if (groupChat.type === window.CONSTANT.GROUP || groupChat.type === window.CONSTANT.PRIVATE_GROUP) {
      const manyChatGroupMenuItems = createGroupChatMenuItemsForManyChat(groupChat);
      newgroupChatMenuItems = newgroupChatMenuItems.concat(manyChatGroupMenuItems);
    }




    setgroupChatMenuItems((current) => {
      return newgroupChatMenuItems;
    });

  };

  const createGroupChatMenuItemsForPrivateChat = async (groupChat,relationships) => {
    const mewgroupChatMenuItems = [];
    try {
      const { currentUser, anotherUser } = window.COMMON.splitMembersFromIndividualGroupChat(groupChat);
      const pendingItem = await getFriendPendingData(relationships,currentUser, anotherUser);
      //kiểm tra xem có dữ liệu pending không ?
      //nếu có thì kiểm tra
      if (pendingItem) {

        const params = {
          createdBy: { ...pendingItem.createdBy, id: pendingItem.createdBy._id },
          receiver: { ...pendingItem.receiver, id: pendingItem.receiver._id },
          groupChatId: groupChat.id
        };
        //người nhận là mình thì hiển nút chấp nhận và nút từ chối kết bạn
        if (pendingItem.type === window.CONSTANT.RECEIVER) {
          mewgroupChatMenuItems.push(...[
            {
              _id: 'accept_friend',
              icon: 'user-check',
              title: 'accept_friend',
              onClickKey: 'accept_friend',
              params
            },
            {
              _id: 'decline_friend',
              icon: 'user-times',
              title: 'decline_friend',
              onClickKey: 'decline_friend',
              params
            }
          ]);
        }
        else if (pendingItem.type === window.CONSTANT.CREATE) {
          //người tạo là mình thì hiển thị nút Hủy kết bạn
          mewgroupChatMenuItems.push(
            {
              _id: 'cancel_friend',
              icon: 'user-times',
              title: 'cancel_friend',
              onClickKey: 'cancel_friend',
              params
            },
          );
        }


      }
      else {
        //không có thì hiển thị nút kết bạn

        mewgroupChatMenuItems.push(
          {
            _id: 'send_friend',
            icon: 'user-plus',
            title: 'send_friend_request',
            onClickKey: 'send_friend',
            params: {
              currentUser,
              anotherUser: { ...anotherUser, _id: anotherUser.id },
              groupChatId: groupChat.id
            }
          },
        );
      }
      mewgroupChatMenuItems.push({
        _id: 'invite_members_in_individual_group',
        icon: 'user-plus',
        title: 'invite_members',
        onClickKey: 'invite_members_in_individual_group',
        params: { anotherUser: { ...anotherUser, _id: anotherUser.id } }
      });
    }
    catch (error) {
      window.COMMON.showErrorLogs('ChatPage.createGroupChatMenuItemsForPrivateChat');
    }



    return mewgroupChatMenuItems;


  };
  const createGroupChatMenuItemsForFriendChat = (groupChat) => {
    const newGroupChatMenuItems = [];
    try {
      const { currentUser, anotherUser } = window.COMMON.splitMembersFromIndividualGroupChat(groupChat);
      if(currentUser && anotherUser){
        const params = {
          currentUser,
          anotherUser: { ...anotherUser, _id: anotherUser.id },
          groupChatId: groupChat.id
        };
        newGroupChatMenuItems.push(...[
          {
            _id: 'remove_friend',
            icon: 'user-times',
            title: 'remove_friend',
            onClickKey: 'remove_friend',
            params
          },
          {
            _id: 'invite_members_in_individual_group',
            icon: 'user-plus',
            title: 'invite_members',
            onClickKey: 'invite_members_in_individual_group',
            params
          }
        ]);
      }
    }
    catch (error) {
      window.COMMON.showErrorLogs('ChatPage.createGroupChatMenuItemsForFriendChat');
    }
    return newGroupChatMenuItems;
  };
  const createGroupChatMenuItemsForManyChat = (groupChat) => {
    const newGroupChatMenuItems = [];
    try {
      newGroupChatMenuItems.push(...[
        {
          _id: 'update_group_chat',
          icon: 'edit',
          title: 'update_group_chat',
          onClickKey: 'update_group_chat',
          params: { groupChat }
        },
        {
          _id: 'members',
          icon: 'users',
          title: 'members',
          onClickKey: 'members',
          params: {}
        },
        {
          _id: 'invite_members',
          icon: 'user-plus',
          title: 'invite_members',
          onClickKey: 'invite_members',
          params: {}
        },
        {
          _id: 'join_requests',
          icon: 'users',
          title: 'join_requests',
          onClickKey: 'join_requests',
          params: {}
        },
        {
          _id: 'leave_group',
          icon: 'sign-out-alt',
          title: 'leave_group',
          onClickKey: 'leave_group',
          params: {}
        }
      ]);
    }
    catch (error) {
      window.COMMON.showErrorLogs('ChatPage.createGroupChatMenuItemsForManyChat');
    }
    return newGroupChatMenuItems;
  };

  const getFriendPendingData = async (relationships,currentUser,anotherUser) => {
    let pendingItem;
    
    const pendingData = relationships;
    let type;
    let isFound = false;
    if (currentUser && anotherUser) {
      for (let i = 0; i < pendingData.length; i++) {
        const item = pendingData[i];

        if (item.createdBy._id === anotherUser.id && item.receiver._id === currentUser.id) {
          type = window.CONSTANT.RECEIVER;
          isFound = true;
        }
        else if (item.createdBy._id === currentUser.id && item.receiver._id === anotherUser.id) {
          type = window.CONSTANT.CREATE;
          isFound = true;
        }

        if (isFound === true) {
          pendingItem = {
            ...item,
            type
          };
          break;
        }
      }

    }
    
    return pendingItem;
  };



  const handleAcceptFriend = async (params) => {
    try {

      //createdBy: sender
      //receiver: receiver (current user)
      const { createdBy, receiver, groupChatId } = params;
      const paramsToSend = {
        input: {
          id: createdBy._id,
          name: (receiver.nickname || receiver.name)
        }
      };
      const result = await window.COMMON.mutation(RelationshipGRAPHQL.MUTATION_ACCEPT_FRIEND, paramsToSend, true);
      let status = 0;
      if (result && result.data) {
        status = result.data.acceptFriend;
      }
      if (status === 1) {

        socket.emit('accept-friend', { to: createdBy, friend: receiver, groupChatId });

        updateAfterAddFriend(createdBy, groupChatId);


        window.COMMON.showMessage('success', 'MSG_CODE_051', window.I18N('MSG_CODE_051'));
      }

    }
    catch (error) {
      window.COMMON.showErrorLogs('ChatPage.handleAcceptFriend');
    }
  };
  const handleDeclineFriend = (params) => {

    window.COMMON.showModal('#modal-save');
    window.saveMethod = async () => {
      try {
        const { createdBy, groupChatId } = params;
        const paramsToDecline = {
          accountId: createdBy._id
        };

        const result = await window.COMMON.mutation(RelationshipGRAPHQL.MUTATION_DECLINE_FRIEND, paramsToDecline);
        let status = -1;
        if (result && result.data) {
          status = result.data.declineFriend;
        }
        if (status !== 1) return;

        if (dataRef.current && dataRef.current.groupChatSelected.id === groupChatId) {
          await createGroupChatMenuItems({ ...dataRef.current.groupChatSelected, type: window.CONSTANT.FRIEND });
        }


        socket.emit('decline-friend', { to: createdBy, groupChatId });

        window.COMMON.showMessage('success', 'MSG_CODE_055', window.I18N('MSG_CODE_055'));
      }
      catch (error) {
        window.COMMON.showErrorLogs('ChatPage.handleDeclineFriend');
      }
    };



  };

  const handleCancelFriend = (params) => {
    window.COMMON.showModal('#modal-save');
    window.saveMethod = async () => {
      try {
        const { receiver, groupChatId } = params;


        const paramsToCancelFriend = {
          accountId: receiver._id
        };
        const result = await window.COMMON.mutation(RelationshipGRAPHQL.MUTATION_CANCEL_FRIEND_REQUEST, paramsToCancelFriend);
        let status = -1;
        if (result && result.data) {
          status = result.data.cancelRequestFriend;
        }
        if (status !== 1) return;




        if (dataRef.current && dataRef.current.groupChatSelected.id === groupChatId) {
          await createGroupChatMenuItems({ ...dataRef.current.groupChatSelected, type: window.CONSTANT.FRIEND });
        }


        socket.emit('cancel-friend', { to: receiver, groupChatId });


        window.COMMON.showMessage('success', 'MSG_CODE_057', window.I18N('MSG_CODE_057'));

      }
      catch (error) {
        window.COMMON.showErrorLogs('ChatPage.handleCancelFriend');
      }
    };
  };

  const handleSendFriendRequest = async (params) => {
    try {
      const { anotherUser, currentUser, groupChatId } = params;
      //gửi dữ liệu lên api
      const paramsToAddFriend = {
        input: {
          name: (currentUser.nickname || currentUser.name),
          id: anotherUser.id
        }
      };
      const result = await window.COMMON.mutation(RelationshipGRAPHQL.MUTATION_ADD_FRIEND, paramsToAddFriend);
      let status = -1;
      if (result && result.data) {
        status = result.data.addFriend;
      }
      if (status !== 1) return;

      //gọi hàm createGroupChatMenuItems cập nhật lại menu
      if (groupChatId === groupChatSelected.id) {
        await createGroupChatMenuItems(groupChatSelected);
      }
      //bắn socket đến người còn lại
      socket.emit('add-friend', { to: anotherUser, from: currentUser, groupChatId });
      //hiển thị thông báo
      window.COMMON.showMessage('success', 'MSG_CODE_053', window.I18N('MSG_CODE_053') + ' ' + (anotherUser.nickname || anotherUser.name));

      //người còn lại khi nhận được dữ liệu qua socket, sẽ
      //hiển thị thông báo
      //nếu group đang mở === group chat id được gửi đến thì gọi createGroupChatMenuItems cập nhật lại menu
    }
    catch (error) {
      window.COMMON.showErrorLogs('ChatPage.handleSendFriendRequest');
    }
  };

  const handleRemoveFriend = async (params) => {
    window.COMMON.showModal('#modal-save');
    window.saveMethod = async () => {
      try {
        const { anotherUser, currentUser, groupChatId } = params;

        const paramsToRemoveFriend = {
          accountId: anotherUser.id
        };
        let status = -1;

        const result = await window.COMMON.mutation(RelationshipGRAPHQL.MUTATION_REMOVE_FRIEND, paramsToRemoveFriend);
        if (result && result.data) {
          status = result.data.removeFriend;

        }
        if (status !== 1) return;

        updateAfterRemoveFriend(anotherUser, groupChatId);

        //bắn socket
        socket.emit('remove-friend', { to: anotherUser, friend: currentUser, groupChatId });

        window.COMMON.showMessage('success', 'MSG_CODE_056', window.I18N('MSG_CODE_056'));
      }
      catch (error) {
        window.COMMON.showErrorLogs('ChatPage.handleRemoveFriend');
      }
    };
  };

  const handeUpdateGroupChat = async (params) => {
    const { groupChat: groupChatFromFirebase } = params;
    //get group chat from mongodb - because it have fully information to update
    const paramsToGetGroupChat = {
      id: groupChatFromFirebase._id
    };
    const result = await window.COMMON.query(GroupChatGRAPHQL.QUERY_GROUP_CHAT_BY_ID, paramsToGetGroupChat);
    if (result && result.data) {
      //show form update
      openGroupChatDialog(0, result.data.findGroupById);
    }


  };
  const handleInviteMembers = () => {
    openInviteMembersDialog();
  };
  const handleLeaveGroup = () => {
    window.COMMON.showModal('#modal-confirm');
    window.confirmMethod = async () => {
      try {
        // gọi api xóa
        const groupId = groupChatSelected._id;
        await deleteGroupRelationship(groupId);

        //xóa ra khỏi dữ liệu nhóm trên firebase (chính là người dùng hiện tại)
        const members = [localStorage.getItem(window.CONSTANT.ID)];
        const deleteMembersFromFirebaseResult = await deleteMembersFromFirebase(groupId, members);
        if (deleteMembersFromFirebaseResult !== 1) return;


        // xóa nhóm hiện tại tại ra khỏi danh sách nhóm
        removeGroupChatFromManyGroupChatList(groupChatSelected.id);
        // xóa nhóm hiện tại
        setGroupChatSelected({});
        // gửi tin nhắn
        const currentUser = window.COMMON.getCurrentUserInformation();
        //type 8 is array so create an array
        const message = [currentUser.nickname || currentUser.name];
        const newGroupChatType = window.COMMON.convertGroupChatTypeFirebase(groupChatSelected.type);
        const groupChat = {
          _id: groupChatSelected.id,
          id: groupChatSelected.id,
          type: newGroupChatType,
		  members: groupChatSelected.members
        };
        handleSendMessage(message, groupChat, 8);
        //hiển thị thông báo thành công
        window.COMMON.showMessage('success', 'MSG_CODE_058', window.I18N('MSG_CODE_058'));
      }
      catch (error) {

      }
    };
  };
  const handleJoinRequests = () => {
    window.COMMON.showModal(`#${JOIN_REQUEST_MODAL_ID}`);
    setShowJoinRequestModal(true);
  };
  const handleMembersRequest = () => {
    window.COMMON.showModal(`#${MEMBERS_MODAL_ID}`);
    setShowMembersModal(true);
  };
  const handleInviteMembersInIndividualGroup = (params) => {
    const { anotherUser } = params;
    setInitMemberCreateGroupForm(anotherUser);
    openGroupChatDialog(-1);
  };

  const groupChatMenuFunctions = useMemo(() => {
    return {
      accept_friend: handleAcceptFriend,
      decline_friend: handleDeclineFriend,
      cancel_friend: handleCancelFriend,
      send_friend: handleSendFriendRequest,
      remove_friend: handleRemoveFriend,
      update_group_chat: handeUpdateGroupChat,
      invite_members: handleInviteMembers,
      leave_group: handleLeaveGroup,
      join_requests: handleJoinRequests,
      members: handleMembersRequest,
      invite_members_in_individual_group: handleInviteMembersInIndividualGroup
    };
  }, [groupChatSelected]);


  const updateAfterAddFriend = (newFriend, groupChatId) => {
    addFriendToFriendList(newFriend);
    updateGroupChatSelected(groupChatId, { type: window.CONSTANT.FRIEND });
    updateInformationIndividualGroupChatList(groupChatId, { type: window.CONSTANT.FRIEND });
  };

  const updateAfterRemoveFriend = (friend, groupChatId) => {

    updateGroupChatSelected(groupChatId, { type: window.CONSTANT.FRIEND });
    updateInformationIndividualGroupChatList(groupChatId, { type: window.CONSTANT.FRIEND });
    removeFriendFromFriendList(friend);
  };


  const updateGroupChatSelected = (groupChatId, newData) => {
    setGroupChatSelected((current) => {
      let newGroupChat = current;
      if (current.id === groupChatId) {
        newGroupChat = {
          ...current,
          ...newData
        };
      }
      return { ...newGroupChat };
    });
  };

  const updateInformationIndividualGroupChatList = (groupChatId, newData) => {
    setIndividualGroupChatList((current) => {
      const index = current.findIndex((item) => item.id === groupChatId);
      if (index >= 0) {
        current[index] = {
          ...current[index],
          ...newData
        };
      }
      return [...current];
    });
  };
  const updateInformationManyGroupChatList = (groupChatId, newData) => {
    setManyGroupChatList((current) => {
      const index = current.findIndex((item) => item.id === groupChatId);
      if (index >= 0) {
        current[index] = {
          ...current[index],
          ...newData
        };
      }
      return [...current];
    });
  };

  const addFriendToFriendList = (newFriend) => {
    setFriends((current) => {
      const currentFriendId = current.ids.find((item) => item === newFriend.id);
      if (!currentFriendId) {
        current.data.push({
          ...newFriend,
          _id: newFriend.id
        });
        current.ids.push(newFriend.id);
      }
      return { ...current };
    });
  };
  const removeFriendFromFriendList = (friend) => {
    setFriends((current) => {
      const newData = current.data.filter((item) => item._id !== friend.id);
      const newIds = current.ids.filter((item) => item !== friend.id);
      return {
        data: newData,
        ids: newIds
      };
    });
  };

  const removeGroupChatFromManyGroupChatList = (groupId) => {
    setManyGroupChatList((current) => {
      const newGroups = current.filter((item) => item.id !== groupId);
      return [...newGroups];
    });
  };




  const handleAcceptFriendEvent = (data) => {
    const { friend, groupChatId } = data;
    window.COMMON.showMessage('success', 'MSG_CODE_052', (friend.nickname || friend.name) + ' ' + window.I18N('MSG_CODE_052'));
    updateAfterAddFriend(friend, groupChatId);
  };

  //have new friend request
  const handleAddFriendEvent = async (data) => {
    const { groupChatId, from } = data;
    //update menu items
    if (dataRef.current && groupChatId === dataRef.current.groupChatSelected.id) {
      await createGroupChatMenuItems({ ...dataRef.current.groupChatSelected, type: window.CONSTANT.FRIEND });
    }
    window.COMMON.showMessage('success', 'MSG_CODE_054', (from.nickname || from.name) + ' ' + window.I18N('MSG_CODE_054'));
  };

  const handleDeclineFriendEvent = async (data) => {
    const { groupChatId } = data;
    if (dataRef.current && dataRef.current.groupChatSelected.id === groupChatId) {
      await createGroupChatMenuItems({ ...dataRef.current.groupChatSelected, type: window.CONSTANT.FRIEND });
    }
  };

  const handleRemoveFriendEvent = async (data) => {
    const { friend, groupChatId } = data;
    updateAfterRemoveFriend(friend, groupChatId);
  };
  const handleCancelFriendEvent = async (data) => {
    const { groupChatId } = data;
    if (dataRef.current && dataRef.current.groupChatSelected.id === groupChatId) {
      await createGroupChatMenuItems({ ...dataRef.current.groupChatSelected, type: window.CONSTANT.FRIEND });
    }
  };
  const handleUpdateGroupChatEvent = (data) => {
    const { groupChat } = data;
    updateGroupChatSelected(groupChat.id, groupChat);
    updateInformationManyGroupChatList(groupChat.id, groupChat);
  };
  const handleUserOnOffEvent = (data) => {
    //_id of user
    const { _id, status } = data;
    //update group chat selected (individual)
    const currentGroupChatSelected = dataRef.current?.groupChatSelected;
    if (currentGroupChatSelected._id && window.COMMON.checkIndividualChatType(currentGroupChatSelected.type)) {
      //if another member (not current user) === _id then update status of group chat
      const { anotherUser } = window.COMMON.splitMembersFromIndividualGroupChat(currentGroupChatSelected);
      if (anotherUser?.id === _id) {
        updateGroupChatSelected(currentGroupChatSelected._id, { isOnline: status });
      }

    }
    //update individual group chat list
    setIndividualGroupChatList((current) => {
      updateGroupChatListOnOff(current, [{ _id, status }]);
      return [...current];
    });
  };

  const updateGroupChatListOnOff = (groupList, users) => {
    groupList.forEach((item) => {
      const { anotherUser } = window.COMMON.splitMembersFromIndividualGroupChat(item);
      const user = users.find((item) => item?._id === anotherUser?.id);
      if (user) {
        item.isOnline = user.status;
      }

    });
  };

  const handleUserListEvent = (data) => {
    const { users } = data;
    setUsersOnOff(users);
  };
  const handleJoinGroupRequestEvent = (data) => {
    const { groupId } = data;
    //increase join request count
    changeJoinRequestCount(groupId, 1);
  };

  const handleRemoveFromGroupEvent = (data) => {
    const { groupId } = data;



   
    removeGroupChatFromManyGroupChatList(groupId);

    if (groupId === dataRef.current?.groupChatSelected._id) {
      setGroupChatSelected({});
      window.COMMON.showMessage('warning', 'MSG_CODE_066', window.I18N('MSG_CODE_066'));
    }



  };


  const handleFetchCompany = (companyOptions) => {
    setOptions((current) => {
      return {
        ...current,
        companies: companyOptions
      };
    });
  };

  const handleOnNewClick = () => {
    openGroupChatDialog(-1);
  };
  const handleCloseGroupFormModal = () => {
    window.COMMON.hideModal(`#${NEW_GROUP_CHAT_MODAL_ID}`);
    setInitMemberCreateGroupForm(null);
  };
  const handleCloseInvitemembersFormModal = () => {
    window.COMMON.hideModal(`#${INVITE_MEMBERS_MODAL_ID}`);
  };

  const openGroupChatDialog = (index, currentGroupChat) => {

    const dataSave = { file: null };
    if (index === -1) {
      dataSave.type = 'PUBLIC';
      dataSave.name = '';
      dataSave.code = window.COMMON.generateCode('GC');
      dataSave.image = '';
      dataSave.description = '';
    } else {
      dataSave._id = window.COMMON.getValueFromAttr(currentGroupChat, '_id', null);
      dataSave.name = window.COMMON.getValueFromAttr(currentGroupChat, 'name');
      dataSave.code = window.COMMON.getValueFromAttr(currentGroupChat, 'code');
      dataSave.image = window.COMMON.getValueFromAttr(currentGroupChat, 'image');
      dataSave.description = window.COMMON.getValueFromAttr(currentGroupChat, 'description');
      dataSave.type = window.COMMON.getValueFromAttr(currentGroupChat, 'type');
    }
    document.getElementById('file-group-form').value = null;
    setGroupChatFormDataSave({ ...dataSave });
    window.COMMON.showModal(`#${NEW_GROUP_CHAT_MODAL_ID}`);
  };
  const openInviteMembersDialog = () => {
    window.COMMON.showModal(`#${INVITE_MEMBERS_MODAL_ID}`);
  };
  const handleFormChange = (keys, values) => {
    setGroupChatFormDataSave((current) => {
      keys.forEach((key, index) => {
        current[key] = values[index];
      });
      return {
        ...current
      };
    });
  };

  const handleSaveGroupChat = async (chosenUsers) => {
    try {
      const params = {
        input: groupChatFormDataSave
      };
      window.COMMON.trimData(params);
      if (!groupChatFormDataSave._id) {
        const result = await window.COMMON.mutation(ChatGRAPHQL.MUTATION_ADD_GROUP_CHAT_WITH_FIREBASE, params);
  
        if (result && result.data) {
          const groupChatId = result.data.addGroupChatWithFirebase._id;
          if (!groupChatId) {
            throw new Error('Create Group Chat Failed, Please Check Information Again');
          }
          await handleCreateNewGroupChatSuccessFully(groupChatId, groupChatFormDataSave.type, chosenUsers);
        }
      } else {
        const result = await window.COMMON.mutation(GroupChatGRAPHQL.MUTATION_EDIT_GROUP_CHAT, params);
    
        if (result && result.data) {
          const groupChatId = result.data.editGroupChat._id;
          await handleUpdateGroupChatSuccessFully(groupChatId, groupChatFormDataSave);
        }

      }
      handleCloseGroupFormModal();
    }
    catch (error) {
  
      window.COMMON.showErrorLogs('ChatPage.handleSaveGroupChat');
    }
  };

  const handleCreateNewGroupChatSuccessFully = async (groupChatId, groupChatType, chosenUsers) => {
    const currentUser = window.COMMON.getCurrentUserInformation();
    const newMembers = [currentUser, ...chosenUsers];
    const newMembersIds = newMembers.map((item) => item._id);
    //create relationship with members (include currentuser)
    const addGroupRelationshipResult = await addGroupRelationship(groupChatId, newMembersIds);
    if (addGroupRelationshipResult !== 1) return;

    //apply relationship with members (include currentUser)
    const acceptGroupRelationshipResult = await acceptGroupRelationship(groupChatId, newMembersIds);
    if (acceptGroupRelationshipResult !== 1) return;

    //add members to firebase (because api accept group relationship does not add member)
    const addMembersFromRelationshipResult = await addMembersToFirebase(groupChatId);
    if (addMembersFromRelationshipResult !== 1) return;

    //send message

    sendAddMemberMessage(groupChatId, groupChatType, newMembers, currentUser, newMembersIds);

  };
  const addGroupRelationship = async (groupChatId, users) => {
    const params = {
      users,
      groupId: groupChatId
    };
    const result = await window.COMMON.mutation(ChatGRAPHQL.MUTATION_ADD_GROUP_RELATIONSHIP, params);
    let status = -1;
    if (result && result.data) {
      status = result.data.addGroupRelationship;
    }
    return status;
  };
  const acceptGroupRelationship = async (groupChatId, usersIds) => {
    const params = {
      users: usersIds,
      groupId: groupChatId
    };
    const result = await window.COMMON.mutation(GroupChatGRAPHQL.MUTATION_ACCEPT_GROUP_RELATIONSHIP, params);
    let status = -1;
    if (result && result.data) {
      status = result.data.acceptGroupRelationship;
    }
    return status;
  };
  const addMembersToFirebase = async (groupChatId) => {
    const params = {
      groupId: groupChatId
    };
    let status = -1;
    const result = await window.COMMON.mutation(ChatGRAPHQL.MUTATION_ADD_MEMBERS_FROM_RELATIONSHIP_TO_FIREBASE, params);
    if (result && result.data) {
      status = result.data.addMembersFromRelationshipToFirebase;
    }
    return status;
  };
  const sendAddMemberMessage = (groupChatId, groupChatType, members, currentUser, updateMembers) => {
    const message = members.map((item) => item.nickname || item.name);
    const newGroupChatType = window.COMMON.convertGroupChatTypeFirebase(groupChatType);
    const groupChat = {
      members: updateMembers ?? members,
      _id: groupChatId,
      id: groupChatId,
      // type: groupChatType === "PUBLIC" ? "GROUP" : groupChatType
      type: newGroupChatType
    };
    const moreInfor = {
      user: currentUser.nickname || currentUser.name
    };
    handleSendMessage(message, groupChat, 6, moreInfor);
  };

  const handleUpdateGroupChatSuccessFully = async (groupChatId, groupChatInformation) => {
    const currentUser = window.COMMON.getCurrentUserInformation();
    const message = groupChatInformation.name;
    const newGroupChatType = window.COMMON.convertGroupChatTypeFirebase(groupChatInformation.type);
    const groupChat = {
      _id: groupChatId,
      id: groupChatId,
      type: newGroupChatType,
      members: groupChatSelected.members
    };
    const moreInfor = {
      user: currentUser.nickname || currentUser.name
    };
    handleSendMessage(message, groupChat, 7, moreInfor);


    window.COMMON.showMessage('success', 'MSG_CODE_002', window.I18N('MSG_CODE_002'));
  };

  const handleSaveInviteMembersForm = async (chosenUsers) => {
    if (chosenUsers.length > 0) {
      const currentUser = window.COMMON.getCurrentUserInformation();
      const newMembers = [...chosenUsers];
      const newMembersIds = newMembers.map((item) => item._id);
      const groupChatId = groupChatSelected.id;
      const groupChatType = groupChatSelected.type;
      //create relationship with members
      const addGroupRelationshipResult = await addGroupRelationship(groupChatId, newMembersIds);
      if (addGroupRelationshipResult !== 1) return;

      //apply relationship with members
      const acceptGroupRelationshipResult = await acceptGroupRelationship(groupChatId, newMembersIds);
      if (acceptGroupRelationshipResult !== 1) return;

      //add members to firebase (because api accept group relationship does not add member)
      const addMembersFromRelationshipResult = await addMembersToFirebase(groupChatId);
      if (addMembersFromRelationshipResult !== 1) return;

	  const totalMembers = [...newMembersIds, ...groupChatSelected.members];
      //send message

      sendAddMemberMessage(groupChatId, groupChatType, newMembers, currentUser, totalMembers);
    }
    handleCloseInvitemembersFormModal();
  };
  const deleteGroupRelationship = async (groupId) => {
    const params = {
      groupId
    };
    const result = await window.COMMON.mutation(GroupChatGRAPHQL.MUTATION_LEAVE_GROUP_RELATIONSHIP, params);
    let status = -1;
    if (result && result.data) {
      status = result.data.leaveGroupRelationship;
    }
    return status;
  };
  const deleteGroupRelationships = async (groupId, users) => {
    const params = {
      groupId,
      users
    };
    const result = await window.COMMON.mutation(ChatGRAPHQL.MUTATION_DELETE_GROUP_RELATIONSHIPS, params);
    let status = -1;
    if (result && result.data) {
      status = result.data.deleteGroupRelationships;
    }
    return status;
  };
  const deleteMembersFromFirebase = async (groupId, members) => {
    const params = {
      groupId,
      //id
      members
    };

    const result = await window.COMMON.mutation(ChatGRAPHQL.MUTATION_DELETE_MEMBERS_FROM_FIREBASE, params);
    let status = -1;
    if (result && result.data) {
      status = result.data.deleteMembersFromFirebase;
    }
    return status;

  };

  const handleRequestToPublicGroupSuccess = (group, user) => {
    //gửi tin nhắn
    const message = [user.nickname || user.name];
    const groupChatType = group.type;
    const groupChatId = group._id;
    const newGroupChatType = window.COMMON.convertGroupChatTypeFirebase(groupChatType);
    const groupChat = {
      _id: groupChatId,
      id: groupChatId,
      type: newGroupChatType
    };
    const moreInfor = {
      // user: currentUser.name
    };
    handleSendMessage(message, groupChat, 6, moreInfor);
    //set group chat selected
    setGroupChatSelected(group);
  };

  const handleCloseJoinRequestForm = () => {
    window.COMMON.hideModal(`#${JOIN_REQUEST_MODAL_ID}`);
    setShowJoinRequestModal(false);
  };

  const handleAcceptRequests = async (group, users, usersIds) => {
    let status = -1;
    try {
      const currentUser = window.COMMON.getCurrentUserInformation();
      const groupChatId = group.id;
      const groupChatType = group.type;
      const acceptResult = await acceptGroupRelationship(groupChatId, usersIds);
      if (acceptResult !== 1) return;
      const addMembersResult = await addMembersToFirebase(groupChatId);
      if (addMembersResult !== 1) return;
	  const userIds = users.map(item => item._id);
      sendAddMemberMessage(groupChatId, groupChatType, users, currentUser, [...userIds, ...groupChatSelected.members]);
      status = 1;

    }
    catch (error) {
      status = -1;
      window.COMMON.showErrorLogs('ChatPage.handleAcceptRequests');
    }

    if (status === 1) {
      changeJoinRequestCount(group.id, -usersIds.length);
    }

    return status;
  };
  const handleDeclineRequests = async (group, usersIds) => {
    let status = -1;
    try {
      const params = {
        users: usersIds,
        groupId: group._id
      };
      const result = await window.COMMON.mutation(GroupChatGRAPHQL.MUTATION_DECLINE_GROUP_RELATIONSHIP, params);
      if (result && result.data) {
        status = result.data.declineGroupRelationship;
      }
    }
    catch (error) {
      status = -1;
      window.COMMON.showErrorLogs('ChatPage.handleDeclineRequests');
    }
    if (status === 1) {
      changeJoinRequestCount(group.id, -usersIds.length);
    }
    return status;
  };

  const handleCloseMembersForm = () => {
    window.COMMON.hideModal(`#${MEMBERS_MODAL_ID}`);
    setShowMembersModal(false);
  };

  const handleBlockMembers = async (groupChat, usersIds) => {
    let status = -1;
    try {
      const groupId = groupChat._id;
      const params = {
        groupId,
        users: usersIds
      };
      const result = await window.COMMON.mutation(GroupChatGRAPHQL.MUTATION_BLOCK_GROUP_RELATIONSHIP, params);
      if (result && result.data) {
        status = result.data.blockGroupRelationship;
      }

    }
    catch (error) {
      status = -1;
      window.COMMON.showErrorLogs('ChatPage.handleBlockMembers');
    }
    return status;
  };

  const handleRemoveMembers = async (groupChat, users, usersIds) => {
    let status = -1;
    try {
      const groupId = groupChat._id;
      const deleteGroupRelationshipsStatus = await deleteGroupRelationships(groupId, usersIds);
      if (deleteGroupRelationshipsStatus !== 1) return;

      const deleteMembersOnFirebaseStatus = await deleteMembersFromFirebase(groupId, usersIds);
      if (deleteMembersOnFirebaseStatus !== 1) return;

      const message = users.map((item) => item.nickname || item.name);
      const newGroupChatType = window.COMMON.convertGroupChatTypeFirebase(groupChat.type);
      const newGroupChat = {
        _id: groupId,
        id: groupId,
        type: newGroupChatType,
      	members: groupChatSelected.members?.filter(item => !usersIds.includes(item))
      };
      handleSendMessage(message, newGroupChat, 8);

      socket.emit('remove-from-group', { members: usersIds, groupId });

      status = 1;
    }
    catch (error) {
   
      window.COMMON.showErrorLogs('ChatPage.handleRemoveMembers');
    }
    return status;
  };

  const handleHeaderBadgeClick = () => {
    if (!window.COMMON.checkIndividualChatType(groupChatSelected.type)) {
      handleJoinRequests();
    }
  };

  const checkGroupIsBlocked = (group) => {

    if (dataRef.current && dataRef.current.blockedGroupsIds.includes(group._id)) {
      return true;
    }
    return false;
  };

  //increase or decrease
  const changeJoinRequestCount = (groupId, number) => {

    const currentCount = dataRef.current?.groupChatSelected.joinRequestCount || 0;
    let newCount = currentCount + number;
    if (newCount < 0) {
      newCount = 0;
    }
    updateGroupChatSelected(groupId, { joinRequestCount: newCount });



  };

  //handle open reply
  const handleReply = (messageId) => {
    if (!messageId) {
      return setReply(null);
    }
    return setReply(messageId);
  };

  return <CustomChatPageLayout>
    <MDBRow className="m-0">
      <MDBCol className="p-0" xs="12" lg="4">
        <ChatLeftSide
          onShowGroupChat={handleShowGroupChat}
          individualGroupChatList={individualGroupChatList}
          onFetchIndividualGroupChatListSuccess={handleFetchIndividualGroupChatListSuccess}
          onFetchManyGroupChatListSuccess={handleFetchManyGroupChatListSuccess}
          groupChatSelected={groupChatSelected}
          friends={friends}
          onFetchCompany={handleFetchCompany}
          options={options}
          onNewGroupChat={handleOnNewClick}
          manyGroupChatList={manyGroupChatList}
          onRequestToPublicGroupSuccess={handleRequestToPublicGroupSuccess}
          ref={chatLeftSideRef}
          initFetchBlockedGroups={initFetchBlockedGroups}
          fetchDataFriend={getDataFriends}
          handleSelectUserProfile={handleSelectUserProfile}
          userProfileSelect={userProfileSelect}
        />
      </MDBCol>
      <MDBCol className="p-0" xs="12" lg="8" style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.1)' }}>
        {checkIsValidGroupChat(groupChatSelected) === true && !userProfileSelect &&
          <ChatRightSide
            groupChatSelected={groupChatSelected}
            groupChatMenuItems={groupChatMenuItems}
            groupChatMenuFunctions={groupChatMenuFunctions}
            onClose={handleCloseGroupChat}
            onSendMessage={handleSendMessage}
            messages={messages}
            reply={reply}
            onSetMessages={setMessages}
            onHeaderBadgeClick={handleHeaderBadgeClick}
            onFormChange={handleFormChange}
            onReply={handleReply}
            friends={friends}
            dataStickers={dataStickers}
            allStickers={allStickers}
          />
        }
        {!checkIsValidGroupChat(groupChatSelected) && !userProfileSelect && <div className="d-flex flex-column justify-content-center align-items-center h-100"><EmptyDataText content={window.I18N('select_group_chat')} /></div>}
        {userProfileSelect && !checkIsValidGroupChat(groupChatSelected) && <ProfileSelectUser onShowGroupChat={handleShowGroupChat} data={userProfileSelect}></ProfileSelectUser>}
      </MDBCol>
    </MDBRow>


    < ChatGroupFormModal
      modalId={NEW_GROUP_CHAT_MODAL_ID}
      companies={options.companies}
      friends={friends}
      onClose={handleCloseGroupFormModal}
      dataGroupChat={groupChatFormDataSave}
      onFormChange={handleFormChange}
      onSaveForm={handleSaveGroupChat}
      initMember={initMemberCreateGroupForm}
    />
    <InviteMembersFormModal
      modalId={INVITE_MEMBERS_MODAL_ID}
      companies={options.companies}
      onClose={handleCloseInvitemembersFormModal}
      onSaveForm={handleSaveInviteMembersForm}
      groupId={groupChatSelected.id}
    />

    <JoinRequestsFormModal
      modalId={JOIN_REQUEST_MODAL_ID}
      visible={showJoinRequestModal}
      onClose={handleCloseJoinRequestForm}
      onAcceptClick={handleAcceptRequests}
      onDeclineClick={handleDeclineRequests}
      groupChatSelected={groupChatSelected}
    />
    <MembersFormModal
      modalId={MEMBERS_MODAL_ID}
      visible={showMembersModal}
      onClose={handleCloseMembersForm}
      onBlockClick={handleBlockMembers}
      onRemoveClick={handleRemoveMembers}
      groupChatSelected={groupChatSelected}
    />

  </CustomChatPageLayout>;
};
export default ChatPage;
