import styled from 'styled-components';
import { getHeightAvailable } from '../styled-utils';
import { mainColor, lightMainColor, veryLightMainColor } from '../styled-contants';
const headerHeight = 80;



export const CustomChatPageLayout = styled.div`
    height: ${getHeightAvailable([headerHeight])};
    background-color: #FFFFFF;
    border-radius: 5px;
   
`;

export const CustomChatLeftSide = styled.div`
    width: 100%;
    height: ${getHeightAvailable([headerHeight])};
    
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    padding-right: 10px;
    padding-top: 5px;
`;

export const CustomHeaderChatLeftSide = styled.div`
    width: 100%;
   padding-bottom: 10px;
   border-bottom: 1px solid rgba(0,0,0,0.1);
`;
export const CustomBodyChatLeftSide = styled.div`
    width: 100%;
    flex-grow: 1;
    overflow: auto;
    overflow-y: scroll;
`;


export const CustomGroupChatItem = styled.div`
    display: flex;
    cursor: pointer;
    width: 100%;
    padding: 5px 5px;
    border-radius: 5px;
    position: relative;
    column-gap: 5px;
    & .content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 5px 5px;
        flex-grow: 1;
        min-width: 0;
        width: 100%;
        .top-content{
            display: flex;
            column-gap: 10px;
            .first{
                font-weight: bold;
            }
            .second.message-count{
                border: 1px solid transparent;
                border-radius: 5px;
                background-color: ${mainColor};
                color: #FFFFFF;
                padding: 0px 5px;
            }
            
        }
        .bottom-content{
            display: flex;
            column-gap: 5px;
            .first{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
			.time {
				min-width: 120px;
			}
        }

    }
    & .menu{
        position: absolute;
        top: 25%;
        bottom:25%;
        right: 10px;
    }
    &:not(.active):hover{
      background-color  : ${veryLightMainColor};
    }
    &.active{
        background-color: ${lightMainColor};
    }
`;

export const CustomChatRightSide = styled.div`
    width: 100%;
    height: ${getHeightAvailable([headerHeight])};
    display: flex;
    flex-direction: column;
`;

export const CustomHeaderChatRightSide = styled.div`
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 4px;
    padding: 0px 5px;
    .information-wrapper{
        display: flex;
        align-items: center;
        column-gap: 10px;
        .name{
            font-size: 14px;
            font-weight: bold;
            flex-grow: 1;
        }
        
        .action{
            display: flex;
            align-items: center;
            .action-btn{
                cursor: pointer;
                padding: 0px 10px;
            }
        }
    }
`;

export const CustomBodyChatRightSide = styled.div`
    width: 100%;
    flex-grow: 1;
    overflow: none;
    overflow-y: scroll;
`;

export const CustomBottomChatRightSide = styled.div`
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    .input-wrapper{
        display: flex;
        column-gap: 10px;
        .btn-upload{
            margin: 0px;
        }
    }
`;

export const CustomMessage = styled.div`
    padding: 10px 10px;
    display: flex;
    align-items: flex-start;
    column-gap: 10px;
    &.me{
        flex-direction: row-reverse;
    }
    .content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .top-content{
            display: flex;
            column-gap: 10px;
            .first{
                font-size: 14px;
                font-weight: bold;
            }
    }
        .bottom-content{
            display: flex;
            flex-direction: column;

			.reply-container{
				padding: 8px 12px;
				border-radius: 18px;
				border-bottom-right-radius: 0;
				margin-bottom: -8px; 
				max-width: 580px; 
				background-color: #F6F9FA;

				.reply-content{
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2; 
							line-clamp: 2; 
					-webkit-box-orient: vertical;
				}
			}
        }
        .bottom-content.me{
            align-items: flex-end;
        }
    }
    .content.me{
        align-items: flex-end;
    }
    

`;

export const MessageContent = styled.div` 
	max-width: 580px;
	.message-content{
		display: inline-block;
		padding: 5px;
		border: 1px solid transparent;
		border-radius: 5px;
		background-color: #3c38381c;
		max-width: 580px;
		text-align: justify;
	}
	.message-content.me{
		background-color: ${mainColor};
		color: #FFFFFF;
	}
	.message-content.draft-message{
		opacity: 0.3;
	}
	.message-content.icon{
		background-color: transparent;
        img{
			width:80px;
			height:80px;
		}
        padding: 0;
		margin: 0px;
	}
	.message-content.file{
		cursor: pointer;
		display: flex;
		column-gap: 5px;
		align-items: center;
	}
	.message-image{
		display: inline-block;
		justify-content: flex-start;
		img{
			max-width:280px;
			height: auto;
		}
	}

	.message-image.me{
		justify-content: flex-end;
		
	}
`;

export const MessageReply = styled.div`
	cursor: pointer;
	opacity: 0;
	padding: 6px;
	margin: 0 2px;
	${MessageContent}:hover & {
		opacity: 1;
	};
	&:hover{
		background-color: #3c38381c;
		border-radius: 50%;
	}
`;

export const CustomArrayMessage = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;
`;

export const CustomChatResultSearchItem = styled.div`
    display: flex;
    column-gap: 10px;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    &:hover{
      background-color  : ${veryLightMainColor};
    }
    &.active{
        background-color: ${lightMainColor};
    }
`;

export const CustomListFriends = styled.div`
	display: flex;
	margin: 4px 0;
	padding: 2px 0;
	overflow: auto;
	&::-webkit-scrollbar
	{
		height: 10px;
		background-color: #F5F5F5;
	};
	&::-webkit-scrollbar-thumb
	{
		border-radius: 10px;
		background-color: #E0E0E0;
	}
`;

