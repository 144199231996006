import React, { useState, useCallback, useLayoutEffect } from 'react';
import Select from 'react-select';
import { MDBRow, MDBCol, MDBInputGroup, MDBBtn, MDBIcon } from 'mdbreact';

import Panel from '../../components/Panel';
import Modal from '../../components/Modal';
import ImportExcel from '../../components/ImportExcel';
import ExportExcel from '../../components/ExportExcel';
import TableCustomize from '../../components/TableCustomize';
import Pagination from '../../components/Pagination';
import Filter from '../../components/Filter';
import AddAccount from '../../components/AddAccount';

import AccountGRAPHQL from '../../../graphql/Account';
import CompanyGRAPHQL from '../../../graphql/Company';
import MetadataGRAPHQL from '../../../graphql/Metadata';
import RoleGRAPHQL from '../../../graphql/Role';

import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';

export default function AccountPage(props) {
  const ACCOUNT_TYPE = localStorage.getItem(window.CONSTANT.ACCOUNT_TYPE);
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const gendersFilter = [
    { value: null, label: window.I18N('all_gender') },
    { value: 0, label: window.I18N('female') },
    { value: 1, label: window.I18N('male') }
  ];
  const statusFilter = [
    { value: null, label: window.I18N('all_status') },
    { value: true, label: window.I18N('active') },
    { value: false, label: window.I18N('inactive') }
  ];
  const config = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('name'), '30%', 'p-0 pl-1', '', 'avatar,name', 'IMAGE_TEXT'),
    window.COMMON.createCol(window.I18N('role'), '20%', '', '', 'roleText', 'TEXT'),
    window.COMMON.createCol(window.I18N('gender'), '15%', '', '', 'genderText', 'TEXT'),
    window.COMMON.createCol(window.I18N('birthday'), '10%', '', '', 'birthday', 'TEXT'),
    window.COMMON.createCol(window.I18N('status'), '10%', '', '', 'status', 'STATUS'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '10%', '', '', '0', 'BUTTON')
  ];

  const [filter, setFilter] = useState({company: null, gender: { value: null, label: window.I18N('all_gender') }, status: { value: null, label: window.I18N('all_status') }});
  const [pagination, setPagination] = useState({ total: 0, pageSize: 10, currentPage: 0 });
  const [object, setObject] = useState({ index: -1 });
  const [data, setData] = useState([]);
  const [dataImport, setDataImport] = useState({});
  const [options, setOptions] = useState({
    nationFilter: [],
    stateFilter: [],
    nation: [],
    city: [],
    state: [],
    company: [],
    companies: [],
    unions: []
  });
  const [influencerImportRole, setInfluencerImportRole] = useState(null)

  const { trackEvent } = useTracking();

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataLocationFilterOptions('-1', '-1');
      getDataMetadata();
      getDataCompany();
    }, 100);
    // eslint-disable-next-line
  }, []);

  // create tracking event
  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleFilterChange = (event) => {
    filter.text = event.target.value;
    setFilter({ ...filter });
  };

  const handleFilterSelect = (event, attr) => {
    filter[attr] = event;
    if (attr === 'company') {
      pagination.currentPage = 0;
      setPagination(pagination);
    }
    if (attr === 'nation') {
      getDataLocationFilterOptions(attr, event.code);
    } else {
      setFilter({ ...filter });
    }
    if (attr === 'company') {
      getDataRole();
    }
  };

  const handleRoleImportSelect = (event) =>{
    setInfluencerImportRole(event)
  }

  const handleFileImport = (dataImport) => {
    checkImportData(dataImport);
    window.COMMON.showModal('#modal-import');
  };

  const getDataLocationFilterOptions = async (attr, parent) => {
    if (attr === '-1') {
      options.nationFilter = await window.COMMON.getDataLocation(parent);
      options.nation = JSON.parse(JSON.stringify(options.nationFilter));
      options.nationFilter.unshift({ value: null, label: window.I18N('all_countries') });
      options.stateFilter = [{ value: null, label: window.I18N('all_states') }];
      filter.nation = { value: null, label: window.I18N('all_countries') };
      filter.state = { value: null, label: window.I18N('all_states') };
    } else if (attr === 'nation') {
      options.stateFilter = await window.COMMON.getDataLocation(parent);
      options.stateFilter.unshift({ value: null, label: window.I18N('all_states') });
      filter.state = { value: null, label: window.I18N('all_states') };
    }
    setOptions(options);
    setFilter({ ...filter });
  };

  // METADATA
  const getDataMetadata = async () => {
    try {
      const params = {
        type: ['INDIVIDUAL_EDUCATION_BACKGROUND', 'INDIVIDUAL_INCOME']
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_SHORT_METADATA, params, false);
      if (result && result.data) {
        const dataEducationBackground = window.COMMON.getArrsInArrs('INDIVIDUAL_EDUCATION_BACKGROUND', result.data.getMetadatas, 'type');
        options.educationBackground = window.COMMON.createDataSelect(dataEducationBackground, '_id', 'value');
        const dataIncome = window.COMMON.getArrsInArrs('INDIVIDUAL_INCOME', result.data.getMetadatas, 'type');
        options.income = window.COMMON.createDataSelect(dataIncome, '_id', 'value');
        setOptions(options);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MyPostPage.getDataMetadata');
    }
  };

  const getDataCompany = async () => {
    try {
      if (COMPANY_TYPE === window.CONSTANT.COMPANY || COMPANY_TYPE === window.CONSTANT.UNION) {
        filter.company = { value: COMPANY_ID, label: '', code: COMPANY_TYPE };
        setFilter({ ...filter }); 
        getDataRole();
        return;
      }
      if (COMPANY_TYPE === window.CONSTANT.INFLUENCER) {
        await window.COMMON.getDataCompany();
        const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_COMPANY));
        options.company = window.COMMON.createDataSelectWithGroup(dataCompany, 'id', 'name', 'accountType', 'accountType');
        options.company.unshift({ value: COMPANY_ID, label: window.I18N('my_organization'), code: COMPANY_TYPE });
        filter.company = window.COMMON.getValueFromAttr(options.company, '0', null);  
        setOptions(options);
        setFilter({ ...filter });
        getDataRole();
        return;
      }
      await window.COMMON.getDataAllCompany();
      const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));
      if (dataCompany && dataCompany.length > 0) {
        options.company = window.COMMON.createDataSelectWithGroup(dataCompany, '_id', 'information.name', 'type', 'type');
        filter.company = window.COMMON.getValueFromAttr(options.company, '0.options.0', null);  
      }
      setOptions(options);
      setFilter({ ...filter });
      getDataRole();
    } catch (error) {
      window.COMMON.showErrorLogs('AccountPage.getDataCompany');
    }
  };
  
  const getDataRole = async () => {
    try {
      if (filter.company !== null) {
        const params = {
          company: filter.company.value
        };
        const result = await window.COMMON.query(RoleGRAPHQL.QUERY_ROLE, params);
        if (result && result.data) {
          options.role = window.COMMON.createDataSelect(result.data.getRoles, 'code', 'name', 'code', '_id');
          if (filter.company && filter.company.code !== window.CONSTANT.INFLUENCER) {
            options.role.push({ value: window.CONSTANT.INDIVIDUAL, label: window.I18N('individual'), code: window.CONSTANT.INDIVIDUAL });
            options.role.reverse();
          }
          setOptions(options);
        } else {
          options.role = null
          setOptions(options)
        }
        setInfluencerImportRole(null)
        getDataAccount(options.role);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('AccountPage.getDataRole');
    }
  };

  const getDataAccount = async (role) => {
    try {
      if (filter.company !== null) {
        const params = {
          input: {
            organization: filter.company.value,
            organizationType: filter.company.code,
            nation: window.COMMON.getDataSelect(filter.nation, false),
            state: window.COMMON.getDataSelect(filter.state, false),
            gender: window.COMMON.getDataSelect(filter.gender, false),
            status: window.COMMON.getDataSelect(filter.status, false),
            name: filter.text,
            pageSize: pagination.pageSize,
            page: pagination.currentPage + 1
          }
        };
        const result = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_WITH_FILTER, params);
        let data = [];
        if (result && result.data && result.data.getAccountsWithFilter) {
          const dataRole = role || options.role;
          data = window.COMMON.formatMultiDate(result.data.getAccountsWithFilter.data, 'createdDate,birthday');
          data = data.filter(function (item) {
            return item.accountType !== filter.company.code;
          });
          data.forEach(item => {
            item.genderText = item.gender === 0 ? window.I18N('female') : window.I18N('male');
            item.roleText = window.COMMON.getValueFromAttr(window.COMMON.getObjectInArrs(item.accountType, dataRole, 'code'), 'label');
          });
          pagination.total = result.data.getAccountsWithFilter.total - 1;
        }
        setPagination(pagination);
        setData(data);
        if (filter.company && filter.company.code === window.CONSTANT.INFLUENCER) {
          getDataRelation();
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('AccountPage.getDataAccount');
    }
  };

  const getDataRelation = async () => {
    try {
      if (filter.company !== null) {
        const params = {
          user: filter.company.value,
          type: window.CONSTANT.INFLUENCER,
          status: ['ACCEPTED']
        };
        const result = await window.COMMON.query(CompanyGRAPHQL.QUERY_RELATION_COMPANY_BY_ACCOUNT, params);
        if (result && result.data) {
          const dataCompany = window.COMMON.getArrsInArrs(window.CONSTANT.COMPANY, result.data.findRelationshipCompanyByAccount, 'company.type');
          const dataUnion = window.COMMON.getArrsInArrs(window.CONSTANT.UNION, result.data.findRelationshipCompanyByAccount, 'company.type');
          options.companies = window.COMMON.createDataSelect(dataCompany, 'company._id', 'company.information.name');
          options.unions = window.COMMON.createDataSelect(dataUnion, 'company._id', 'company.information.name');
          setOptions(options);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('AccountPage.getDataRelation');
    }
  };

  const changePage = useCallback((index) => {
    pagination.currentPage = index;
    setPagination({ ...pagination });
    getDataAccount();
    // eslint-disable-next-line
  }, [object, pagination, filter]);

  const doSearchData = () => {
    pagination.currentPage = 0;
    window.COMMON.hideFilter();
    getDataAccount();
  };

  const checkDisableAdd = () => {
    return COMPANY_TYPE === window.CONSTANT.INFLUENCER && filter.company && filter.company.code !== window.CONSTANT.INFLUENCER;
  };

  const showContainerAccount = useCallback((index) => {
    window.COMMON.showContainerSave();
    object.index = index;
    object.item = data[index];
    setObject({ ...object });
    // eslint-disable-next-line
  }, [object, data]);
  
  // IMPORT
  const deleteImportRow = useCallback((index) => {
    if (dataImport.data.length > 0) {
      dataImport.data.splice(index, 1);
    }
    setDataImport({ ...dataImport });
  }, [dataImport]);

  const checkImportData = async (dataImport) => {
    try {
      if (dataImport.data.length === 0) {
        return;
      }
      const data = [];
      dataImport.data.forEach(item => {
        item.dateBirthday = item.birthday ? new Date(Math.round((item.birthday - 25569) * 86400 * 1000)) : new Date();
        item.birthday = window.COMMON_DATE.getFormatDate(item.dateBirthday);
        const obj = {
          email: item.email,
          phone: item.phone
        };
        data.push({
          accountType: window.CONSTANT.INDIVIDUAL,
          individual: obj
        });
      });
      const params = {
        input: data
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_CHECK_IMPORT_ACCOUNT, params);
      if (result.data && result.data.checkImportAccount) {
        for (let i = 0; i < result.data.checkImportAccount.length; i++) {
          const arrs = result.data.checkImportAccount[i].split('-.-');
          if (Number(arrs[1]) === 1) {
            const obj = window.COMMON.getObjectInArrs(arrs[0], dataImport.data, 'phone');
            obj.isExisted = true;
          }
        }
      }
      dataImport.data.forEach(el => {
        if (!el.phone) {
          el.isExisted = true;
        }
        if (el.dateBirthday) {
          const timestamp = Date.parse(el.dateBirthday);
          if (isNaN(timestamp)) {
            el.isExisted = true;
          }
        }
        if(!el.password || el.password.length < 6){
          el.isExisted = true
        }
      });
      setDataImport(dataImport);
	    createTrackingEvent(eventName.CHECK_IMPORT_ACCOUNT);
    } catch (error) {
      window.COMMON.showErrorLogs('AccountPage.checkImportData');
    }
  };

  const importData = async () => {
    if (dataImport.data.length === 0) {
      window.COMMON.showMessage('warning', 'MSG_CODE_021', window.MSG.MSG_CODE_021);
      return;
    }
    try {
      const dataImported = [];
      let importAccountType = window.CONSTANT.INDIVIDUAL
      let companyType = 'company'
      let packageId = null
      if (ACCOUNT_TYPE === "SUPER_ADMIN" && influencerImportRole){
        importAccountType = influencerImportRole.value
        companyType = 'influencer'
      }
      if (window.COMMON.checkRoleIsSystem()) {
        const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));
        const selectedCompany = dataCompany.find(el=>el._id === window.COMMON.getValueFromAttr(filter.company, 'value'))
        packageId = selectedCompany?.information?.package?._id
      } else {
        packageId = localStorage.getItem(window.CONSTANT.PACKAGE_ID);
      }

      if(!window.COMMON.validMultiplePasswords(dataImport.data)){
        return
      }

      dataImport.data.forEach(item => {
        const obj = JSON.parse(JSON.stringify(item));
        obj.birthday = item.dateBirthday.toString();
        obj.gender = item.gender === 'Nam' ? 1 : 0;
        obj[companyType] = window.COMMON.getValueFromAttr(filter.company, 'value');
        if (packageId ){
          obj.package = packageId
        }
        if (!obj.isExisted) {
          dataImported.push({
            accountType: importAccountType,
            individual: obj
          });
        }
        delete obj.isExisted;
        delete obj.dateBirthday;
      });
      const params = {
        input: dataImported
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_IMPORT_ACCOUNT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_IMPORT)) {
        getDataAccount();
        window.COMMON.hideModal('#modal-import');
      }
    } catch (error) {
      window.COMMON.showErrorLogs('AccountPage.importData');
    }
  };

  const getDataExport = async () => {
    const dataExport = [];
    try {
      const params = {
        input: {
          organization: filter.company.value,
          organizationType: filter.company.code,
          nation: window.COMMON.getDataSelect(filter.nation, false),
          state: window.COMMON.getDataSelect(filter.state, false),
          gender: window.COMMON.getDataSelect(filter.gender, false),
          status: window.COMMON.getDataSelect(filter.status, false),
          name: filter.text,
          pageSize: pagination.pageSize,
          page: pagination.currentPage + 1
        }
      };
      const result = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_WITH_FILTER, params);
      window.showLoading();
      if (result && result.data && result.data.getAccountsWithFilter) {
        generateDataExport(result.data.getAccountsWithFilter.data, dataExport);
        const total = result.data.getAccountsWithFilter.total;
        const page = total < pagination.pageSize ? 1 : Math.ceil(total / pagination.pageSize);
        for (let i = 1; i < page; i++) {
          await new Promise(resolve => setTimeout(resolve, 2000));
          params.input.page = i + 1;
          const resultItem = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_WITH_FILTER, params, false);
          if (resultItem && resultItem.data && resultItem.data.getAccountsWithFilter) {
            generateDataExport(resultItem.data.getAccountsWithFilter.data, dataExport);
          }
        }
        window.hideLoading();
      }
      window.COMMON.showMessage('success', 'MSG_CODE_070', window.MSG.MSG_CODE_070)
    } catch (error) {
      window.COMMON.showMessage('error', 'MSG_CODE_071', window.MSG.MSG_CODE_071)
      window.COMMON.showErrorLogs('AccountPage.getDataExport');
    }
    return dataExport;
  };

  const generateDataExport = async (data, dataExport) => {
    data.forEach(item => {
      item.createdDate = window.COMMON_DATE.formatDate(item.createdDate, 'DD/MM/YYYY hh:mm');
      item.birthday = window.COMMON_DATE.formatDate(item.birthday);
      item.genderText = item.gender === 0 ? window.I18N('female') : window.I18N('male');
      item.statusText = item.status ? window.I18N('active') : window.I18N('inactive');
      dataExport.push(item);
    });    
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" icon="list-alt">
        <div className="container-top d-flex align-items-center justify-content-end wow fadeInDown animated" data-wow-delay="0.5s" style={{ zIndex: 1 }}>
          {
            COMPANY_TYPE === window.CONSTANT.COMPANY || COMPANY_TYPE === window.CONSTANT.UNION ? <div></div> : <div className="bd-highlight mr-2" style={{  width: '20%' }}>
              <div className="input-group input-group-transparent">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="building" className="fa-fw" />
                  </span>
                </div>
                <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.company} options={options.company} onChange={(event) => handleFilterSelect(event, 'company')} isSearchable />
              </div>
            </div>
          }
          <div className="mr-2 ml-auto" style={{ width: '18%', flex: 1 }}>
            <MDBInputGroup type="text" value={filter.text} hint={window.I18N('search_name')} onChange={handleFilterChange} maxLength="100" pattern="\S(.*\S)?" required
              append={
                <span className="input-group-text cursor-pointer" onClick={() => getDataAccount()}>
                  <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                </span>
              }
            />
          </div>
          <Filter id="container-filter" className="col-md-8">
            <MDBRow>
              <MDBCol sm="6" className="mb-2 pr-sm-2">
                <strong>{window.I18N('gender')}</strong>
                <div className="input-group input-group-transparent mt-1">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="filter" className="fa-fw" />
                    </span>
                  </div>
                  <Select value={filter.gender} options={gendersFilter} className="md-form m-0" onChange={(event) => handleFilterSelect(event, 'gender')} isSearchable/>       
                </div>
              </MDBCol>
              <MDBCol sm="6" className="mb-2 pl-sm-2">
                <strong>{window.I18N('status')}</strong>
                <div className="input-group input-group-transparent mt-1">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="filter" className="fa-fw" />
                    </span>
                  </div>
                  <Select value={filter.status} options={statusFilter} className="md-form m-0" onChange={(event) => handleFilterSelect(event, 'status')} isSearchable/>       
                </div>
              </MDBCol>
              <MDBCol sm="6" className="mb-2 pr-sm-2">
                <strong>{window.I18N('country')}</strong>
                <div className="input-group input-group-transparent mt-1">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="filter" className="fa-fw" />
                    </span>
                  </div>
                  <Select value={filter.nation} options={options.nationFilter} className="md-form m-0" onChange={(event) => handleFilterSelect(event, 'nation')} isSearchable/>       
                </div>
              </MDBCol>
              <MDBCol sm="6" className="mb-2 pl-sm-2">
                <strong>{window.I18N('state_city')}</strong>
                <div className="input-group input-group-transparent mt-1">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="filter" className="fa-fw" />
                    </span>
                  </div>
                  <Select value={filter.state} options={options.stateFilter} className="md-form m-0" onChange={(event) => handleFilterSelect(event, 'state')} isSearchable/>       
                </div>
              </MDBCol>
              <MDBCol sm="12 text-right">
                <MDBBtn color="primary" onClick={doSearchData}>
                  <MDBIcon fa="true" icon="sync-alt" className="fa-fw" /> {window.I18N('apply')}
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </Filter>
          {
            COMPANY_TYPE === window.CONSTANT.INFLUENCER ? <></> : <>
              <ExportExcel label={window.I18N('btn_template')} isTemplate={true} dataKey="USER_TEMPLATE"></ExportExcel>
              <ExportExcel label={window.I18N('export')} isTemplate={false} dataKey="EXPORT_ACCOUNT" companyName={filter.company && filter.company.label} handleData={getDataExport}></ExportExcel>
              <ImportExcel label={window.I18N('btn_import')} id="file-import" dataKey="IMPORT_USER" handleFile={handleFileImport}></ImportExcel>
            </>
          }
          <MDBBtn color="primary" className="mr-0" disabled={checkDisableAdd()} onClick={checkDisableAdd() ? () => {} : () => showContainerAccount(-1)}>
            <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
          </MDBBtn>
        </div>
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <TableCustomize id="table-data" className="table" config={config} data={data} handleRowDetailClick={showContainerAccount}></TableCustomize>
          <Pagination total={pagination.total} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={changePage}></Pagination>
        </div>
      </Panel>

      <AddAccount options={options} index={object.index} item={object.item} company={filter.company} callback={getDataAccount}></AddAccount>

      <Modal saveBtnProps={filter.company?.code===window.CONSTANT.INFLUENCER&&influencerImportRole===null&&{className:"disabled"}} id="modal-import" className="modal-xl" title={window.I18N('import_data')} saveEvent={importData}>
        {ACCOUNT_TYPE==='SUPER_ADMIN'&&filter.company?.code===window.CONSTANT.INFLUENCER&&<Select
            className="md-form m-0 w-25"
            isSearchable
            options={options.role}
            value={influencerImportRole}
            onChange= {handleRoleImportSelect}
          >
          </Select>}
        <TableCustomize id="table-import" className="table" config={dataImport.config} data={dataImport.data} handleRowDelClick={deleteImportRow} isImported={true}></TableCustomize>
      </Modal>
    </div>
  );
}