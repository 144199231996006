import React, { useState, useLayoutEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { MDBCol, MDBRow, MDBBtn, MDBIcon } from 'mdbreact';
import DatePicker from 'react-datepicker';

import Panel from '../../components/Panel';
import Filter from '../../components/Filter';
import ExportExcel from '../../components/ExportExcel';
import TableCustomize from '../../components/TableCustomize';
import Pagination from '../../components/Pagination';

import QuestionGRAPHQL from '../../../graphql/Question';

function HistoryQAPage(props) {
  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const config = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('created_date'), '12.5%', '', '', 'updatedDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('organization'), '20%', '', '', 'organizationName', 'TEXT'),
    window.COMMON.createCol(window.I18N('union'), '20%', '', '', 'union.information.name', 'TEXT'),
    window.COMMON.createCol(window.I18N('user'), '20%', 'p-0 pl-1', '', 'createdBy.avatar,createdBy.nickname', 'IMAGE_TEXT'),
    window.COMMON.createCol(window.I18N('group_qa'), '27.5%', '', '', 'groupLanguage', 'TEXT')
  ];
  const language = useRef();
  const [filter, setFilter] = useState({
    companyIds: [],
    group: null,
    startDate: window.COMMON_DATE.addDaysFromNow(-30),
    endDate: new Date()
  });
  const [pagination, setPagination] = useState({ total: 0, pageSize: 10, currentPage: 0 });
  const [data, setData] = useState([]);
  const [options, setOptions] = useState({
    company: [],
    group: []
  });

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {
      getDataGroupQuestion();
    }, 100);
    // eslint-disable-next-line
  }, [props.language]);

  const handleFilterSelect = (event, attr) => {
    filter[attr] = event;
    setFilter({ ...filter });
  };

  const handleDatePicker = (event, attr) => {
    filter[attr] = event;
    setFilter({ ...filter });
  };

  const getDataGroupQuestion = async () => {
    try {
      const params = {
        company: window.COMMON.checkRoleIsSystem() ? null : COMPANY_ID
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_ALL_GROUP_QUESTION, params, false);
      if (result && result.data) {
        options.group = window.COMMON.createDataSelect(result.data.getAllGroupQuestions, '_id', 'name.0.text');
        filter.group = options.group && options.group.length > 0 ? options.group[0] : null;
        setOptions(options);
        setFilter(filter);
      }
      getDataCompany();
    } catch (error) {
      window.COMMON.showErrorLogs('HistoryQAPage.getDataGroupQuestion');
    }
  };

  const getDataCompany = async () => {
    try {
      if (!window.COMMON.checkRoleIsSystem()) {
        if (COMPANY_TYPE === window.CONSTANT.INFLUENCER) {
          await window.COMMON.getDataCompany();
          const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_COMPANY));
          if (dataCompany && dataCompany.length > 0) {
            options.company = window.COMMON.createDataSelectWithGroup(dataCompany, 'id', 'name', 'accountType', 'accountType');
          }
          setOptions({ ...options });
        } else {
          filter.companyIds = [{ value: COMPANY_ID, label: '', code: COMPANY_TYPE }];
          setFilter({ ...filter });
        }
        getDataHistoryQA();
        return;
      }
      await window.COMMON.getDataAllCompany();
      const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));
      if (dataCompany && dataCompany.length > 0) {
        options.company = window.COMMON.createDataSelectWithGroup(dataCompany, '_id', 'information.name', 'type', 'type');
      }
      setOptions({ ...options });
      getDataHistoryQA();
    } catch (error) {
      window.COMMON.showErrorLogs('HistoryQAPage.getDataCompany');
    }
  };

  const getDataHistoryQA = async () => {
    try {
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_DATA_HISTORY_ANSWER, generateParams());
      let data = [];
      if (result && result.data && result.data.getHistoryAnswer) {
        data = window.COMMON.formatDate(result.data.getHistoryAnswer.data, 'updatedDate', 'DD/MM/YYYY hh:mm');
        data.forEach(item => {
          item.groupLanguage = window.COMMON.getValueWithLanguage(item, 'group.name', language.current);
          item.organizationName = item.company ? window.COMMON.getValueFromAttr(item.company, 'information.name') : (item.influencer ? window.COMMON.getValueFromAttr(item.influencer, 'information.name') : '');
        });
        pagination.total = result.data.getHistoryAnswer.total;
      }
      setPagination(pagination);
      setData(data);
    } catch (error) {
      window.COMMON.showErrorLogs('HistoryQAPage.getDataHistoryQA');
    }
  };

  const generateParams = () => {
    const companyIds = [];
    const unionIds = [];
    const influencerIds = [];
    if (filter.companyIds && filter.companyIds.length > 0) {
      filter.companyIds.forEach(company => {
        if (company.code === window.CONSTANT.COMPANY) {
          companyIds.push(company.value);
        } else if (company.code === window.CONSTANT.UNION) {
          unionIds.push(company.value);
        } else {
          influencerIds.push(company.value);
        }
      });
    }
    // TODO set all company for influencer
    if ((!filter.companyIds || filter.companyIds.length === 0) && COMPANY_TYPE === window.CONSTANT.INFLUENCER) {
      influencerIds.push(COMPANY_ID);
      options.company.forEach(element => {
        element.options.forEach(company => {
          if (company.code === window.CONSTANT.COMPANY) {
            companyIds.push(company.value);
          } else {
            unionIds.push(company.value);
          }
        });
      });
    }
    const params = {
      input: {
        companyIds,
        unionIds,
        influencerIds,
        group: window.COMMON.getDataSelect(filter.group, false),
        startDate: window.COMMON_DATE.getStrDateFilter(filter.startDate, -1),
        endDate: window.COMMON_DATE.getStrDateFilter(filter.endDate, 1),
        pageSize: pagination.pageSize,
        page: pagination.currentPage + 1
      }
    };
    return params;
  };

  const getDataExport = async () => {
    window.showLoading();
    const questions = [];
    const dataExport = [];
    try {
      const params = generateParams();
      params.input.page = 1;
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_EXPORT_HISTORY_ANSWER, params, false);
      if (result && result.data && result.data.getHistoryAnswer) {
        generateDataExport(result.data.getHistoryAnswer.data, dataExport, questions);
        const total = result.data.getHistoryAnswer.total;
        const page = total < pagination.pageSize ? 1 : Math.ceil(total / pagination.pageSize);
        for (let i = 1; i < page; i++) {
          await new Promise(resolve => setTimeout(resolve, 2000));
          params.input.page = i + 1;
          const resultItem = await window.COMMON.query(QuestionGRAPHQL.QUERY_EXPORT_HISTORY_ANSWER, params, false);
          if (resultItem && resultItem.data && resultItem.data.getHistoryAnswer) {
            generateDataExport(resultItem.data.getHistoryAnswer.data, dataExport);
          }
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('HistorySurveyPage.getDataExport');
    }
    window.hideLoading();
    return { questions, data: dataExport};
  };

  const generateDataExport = async (data, dataExport, questions) => {
    let index = 0;
    data.forEach(item => {
      index++;
      item.updatedDate = window.COMMON_DATE.formatDate(item.updatedDate, 'DD/MM/YYYY hh:mm');
      item.groupLanguage = window.COMMON.getValueWithLanguage(item, 'group.name', language.current);
      item.organizationName = item.company ? window.COMMON.getValueFromAttr(item.company, 'information.name') : (item.influencer ? window.COMMON.getValueFromAttr(item.influencer, 'information.name') : '');
      item.answers = [];
      item.feedback.forEach(child => {
        if (index === 1 && questions) {
          const questionLanguage = window.COMMON.getValueWithLanguage(child, 'question.question', language.current);
          questions.push(questionLanguage);
        }
        let answerLanguage = '';
        if (child.question) {
          if (child.question.type === 'TEXT') {
            answerLanguage = child.answerText;
          } else if (child.question.type === 'RADIO_GRID' || child.question.type === 'CHECKBOX_GRID') {
            const parent = child.answers.filter(function(item) {
              return window.COMMON.getValueFromAttr(item, 'parentCode', null) === null;
            });
            parent.forEach(element => {
              answerLanguage += window.COMMON.getValueWithLanguage(element, 'content', language.current) + ': ';
              child.answers.forEach(answer => {
                if (answer.parentCode === element.code) {
                  answerLanguage += window.COMMON.getValueWithLanguage(answer, 'content', language.current) + ', ';
                }
              });
              answerLanguage = answerLanguage.trim().replace(/,$/, '');
              answerLanguage += ' - ';
            });
            answerLanguage = answerLanguage.trim().replace(/-$/, '');
          } else {
            child.answers.forEach(answer => {
              answerLanguage += window.COMMON.getValueWithLanguage(answer, 'content', language.current) + ', ';
            });
            answerLanguage = answerLanguage.trim().replace(/,$/, '');
          }
        }
        item.answers.push(answerLanguage);
      });
      dataExport.push(item);
    });    
  };

  const changePage = useCallback((index) => {
    pagination.currentPage = index;
    setPagination({ ...pagination });
    getDataHistoryQA();
    // eslint-disable-next-line
  }, [filter, pagination]);

  const doSearchData = () => {
    pagination.currentPage = 0;
    window.COMMON.hideFilter();
    getDataHistoryQA();
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" title={window.I18N('history_qa')} icon="list-alt">
        <div className="container-btn wow fadeInDown animated" data-wow-delay="0.5s">
          <Filter id="container-filter" className="col-md-8">
            <MDBRow>
              {
                window.COMMON.checkRoleIsSystemOrInfluencer() ? 
                  <MDBCol sm="12" className="mb-2">
                    <strong>{window.I18N('organization')}</strong>
                    <div className="input-group input-group-transparent mt-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <MDBIcon fa="true" icon="building" className="fa-fw" />
                        </span>
                      </div>
                      <Select className="md-form m-0" value={filter.companyIds} placeholder={window.I18N('all_organizations')} options={options.company} onChange={(event) => handleFilterSelect(event, 'companyIds')} isSearchable isMulti closeMenuOnSelect={false}/>
                    </div>
                  </MDBCol> : null
              }
              <MDBCol sm="12" className="mb-2">
                <strong>{window.I18N('group_qa')}</strong>
                <div className="input-group input-group-transparent mt-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="question-circle" className="fa-fw" />
                    </span>
                  </div>
                  <Select className="md-form m-0" value={filter.group} placeholder={window.I18N('choose_option')} options={options.group} onChange={(event) => handleFilterSelect(event, 'group')} isSearchable />
                </div>
              </MDBCol>
              <MDBCol sm="6" className="mb-2">
                <strong>{window.I18N('from_date')}</strong>
                <div className="input-group input-group-transparent mt-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                    </span>
                  </div>
                  <DatePicker className="form-control" dateFormat="dd/MM/yyyy" selected={filter.startDate} onChange={(event) => handleDatePicker(event, 'startDate')} showYearDropdown showMonthDropdown/>
                </div>              
              </MDBCol>
              <MDBCol sm="6" className="mb-2">
                <strong>{window.I18N('to_date')}</strong>
                <div className="input-group input-group-transparent mt-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                    </span>
                  </div>
                  <DatePicker className="form-control" dateFormat="dd/MM/yyyy" selected={filter.endDate} onChange={(event) => handleDatePicker(event, 'endDate')} showYearDropdown showMonthDropdown/>
                </div>
              </MDBCol>
              <MDBCol sm="12 text-right">
                <MDBBtn color="primary" onClick={doSearchData}>
                  <MDBIcon fa="true" icon="sync-alt" className="fa-fw" /> {window.I18N('apply')}
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </Filter>
          <ExportExcel label={window.I18N('export')} isTemplate={false} dataKey="QA_REPORT" handleData={getDataExport}></ExportExcel>
        </div>
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <TableCustomize id="table-data" className="table" config={config} data={data}></TableCustomize>
          <Pagination total={pagination.total} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={changePage}></Pagination>
        </div>
      </Panel>
    </div>
  );
}

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryQAPage);
