import React, { useState, useCallback, useLayoutEffect, useEffect } from 'react';
import { MDBRow, MDBCol, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';
import CheckboxTree from 'react-checkbox-tree';
import Table from '../../components/Table';
import Modal from '../../components/Modal';

import RoleGRAPHQL from '../../../graphql/Role';
import eventName from '../../../common/events';
import { useTracking } from 'react-tracking';
import Tab from '../../components/Tab';
import TableTree from '../../components/TableTree';

export default function RolePage(props) {
  const { trackEvent } = useTracking();
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const PACKAGE_ID = localStorage.getItem(window.CONSTANT.PACKAGE_ID);
  const dataTabs =  COMPANY_TYPE === 'ADMIN' ?[
    { icon: 'fa-th', name: window.I18N('role') },
    { icon: 'fa-th', name: window.I18N('package') }
  ]:[{ icon: 'fa-th', name: window.I18N('role') }];
  const COMPANY_ID = window.COMMON.checkValue(localStorage.getItem(window.CONSTANT.COMPANY_ID)) ? localStorage.getItem(window.CONSTANT.COMPANY_ID) : null;
  const config = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('name'), '25%', '', '', 'name', 'TEXT'),
    window.COMMON.createCol(window.I18N('code'), '15%', '', '', 'code', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_by'), '25%', '', '', 'createdBy.name', 'TEXT'),
    window.COMMON.createCol(window.I18N('is_public'), '10%', '', '', 'isPublic', 'STATUS'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '10%', '', '', '3', 'BUTTON')
  ];
  const configPackage = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('name'), '27.5%', '', '', 'name', 'COLLAPSE'),
    window.COMMON.createCol(window.I18N('code'), '20%', '', '', 'code', 'TEXT'),
    window.COMMON.createCol(window.I18N('default'), '12.5%', '', '', 'default', 'CHECK'),
    window.COMMON.createCol(window.I18N('created_date'), '15%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '20%', '', '', '1-2', 'BUTTON')
  ];
  const [object, setObject] = useState({ index: -1, status: {} });
  const [data, setData] = useState([]);
  const [dataPackage, setDataPackage] = useState([]);
  const [dataSave, setDataSave] = useState({ isPublic: false, isDefault: false });
  const [treeData, setTreeData] = useState([]);
  const [treeSelected, setTreeSelected] = useState([]);
  const [treeExpanded, setTreeExpanded] = useState([]);

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataRole();
      if (!COMPANY_ID) {
        getDataMenu();
      } else {
        getDataRoleMenu();
      }
    }, 100);
    // eslint-disable-next-line
  }, []);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };
  const changeTab = (index) => {
    if (index === 0) {
      setTimeout(() => {
        getDataRole();
        if (!COMPANY_ID) {
          getDataMenu();
        } else {
          getDataRoleMenu();
        }
      }, 500);
    } else {
      setTimeout(() => getDataPackage(), 500);
    }
  };

  const handleChange = (event) => {
    if (event.target.className.indexOf('input-number') > -1) {
      dataSave[event.target.name] = parseInt(event.target.value);
    } else if (event.target.type === 'checkbox') {
      if (event.target.name === 'isDefault') {
        // chi duoc sua package ko phai default
        if (dataSave._id && !dataPackage.find(el => el._id === dataSave._id).default) {   
          dataSave[event.target.name] = event.target.checked;
        } 
      } else {
        dataSave[event.target.name] = event.target.checked;
      }
    } else {
      dataSave[event.target.name] = event.target.value;
    }
    setDataSave({ ...dataSave });
  };

  function handleMenus(paramRoleMenus, paramPackageMenus) {
    /* if (paramPackageMenus.length ===0) {
      return paramRoleMenus
    } */
    const showMenus = [];
    paramRoleMenus.forEach(childSubMenu => {
      // kiem tra tung menu trong sub role co trong menu packge khong
      const idChildSubMenu = childSubMenu.menu._id;
      const sameMenuInPackage = paramPackageMenus.find(packageMenu => packageMenu.menu._id === idChildSubMenu);
      // check lai cac rights trong tung sub menu
      if (sameMenuInPackage) {
        const rights = childSubMenu.rights.filter(childRight => window.COMMON.getObjectInArrs(childRight._id, sameMenuInPackage.rights, '_id'));
        showMenus.push({menu: childSubMenu.menu, rights, __typename: 'MenuRight'});
      }
    });
    return showMenus;
  }

  const modifyTree = (paramTreeData, treeSelected, dataSave, isParent) => {
    if (isParent) {
      object.index = -1;
    }
    const data = paramTreeData;
    let dataTree = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i].menu;
      const obj = {};
      if (item.parent) {
        const parent = item.parent;
        const check = window.COMMON.getObjectInArrs(parent._id, dataTree, 'value');
        if (check) {
          obj.icon = <MDBIcon fa="true" icon={item.icon} className="fa-fw" />;
          obj.value = item._id;
          obj.label = item.name;
          obj.index = item.index;
          generateRights(obj, data[i]);
          check.children.push(obj);
        } else {
          obj.icon = <MDBIcon fa="true" icon={parent.icon} className="fa-fw" />;
          obj.value = parent._id;
          obj.label = parent.name;
          obj.index = parent.index;
          obj.children = [];
          dataTree.push(obj);
          const child = {};
          child.icon = <MDBIcon fa="true" icon={item.icon} className="fa-fw" />;
          child.value = item._id;
          child.label = item.name;
          child.index = item.index;
          generateRights(child, data[i]);
          obj.children.push(child);
        }
      } else {
        obj.icon = <MDBIcon fa="true" icon={item.icon} className="fa-fw" />;
        obj.value = item._id;
        obj.label = item.name;
        obj.index = item.index;
        generateRights(obj, data[i]);
        dataTree.push(obj);
      }
    }
    dataTree = window.COMMON.sortData(dataTree, 'index');
    setObject(object);
    setTreeData(dataTree);
    setTreeSelected(treeSelected);
    setTreeExpanded([]);
    setDataSave({ ...dataSave });
  };
  const getDataMenu = async() => {
    try {
      const result = await window.COMMON.query(RoleGRAPHQL.QUERY_MENU, null);
      if (result && result.data) {
        const data = result.data.getMenus;
        window.COMMON.createDataTableTree(data, data, 'parent._id', 1);
        const dataTree = [];
        for (let i = 0; i < data.length; i++) {
          const obj = JSON.parse(JSON.stringify(data[i]));
          if (obj.isParent) {
            obj.icon = <MDBIcon fa="true" icon={obj.icon} className="fa-fw"/>;
            obj.value = window.COMMON.getValueIdFromAttr(obj, '_id');
            obj.label = window.COMMON.getValueFromAttr(obj, 'name');
            if (obj.children.length > 0) {
              obj.children.forEach(child => {
                child.icon = <MDBIcon fa="true" icon={child.icon} className="fa-fw"/>;
                child.value = window.COMMON.getValueIdFromAttr(child, '_id');
                child.label = window.COMMON.getValueFromAttr(child, 'name');
                generateRights(child, child);
              });
            } else {
              obj.children = null;
            }
            dataTree.push(obj);
          }
        }
        setTreeData(dataTree);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RolePage.getDataMenu');
    }
  };

  const getDataRoleMenu = async() => {
    try {
      //query role menus
      const params = {
        code: COMPANY_TYPE,
        company: null
      }
      const result = await window.COMMON.query(RoleGRAPHQL.QUERY_ROLE_BY_CODE, params)
      //query package menus
      const packageParam = PACKAGE_ID ? { filter: { _id: PACKAGE_ID } } : { filter: { role: COMPANY_TYPE } };
      const packages = await window.COMMON.query(RoleGRAPHQL.QUERY_PACKAGE, packageParam);

      if (packages && packages.data && result && result.data ) {
        const packageMenus = PACKAGE_ID ? packages.data.queryPackage[0].menus : window.COMMON.getObjectInArrs(true, packages.data.queryPackage, 'default').menus;
        const data = handleMenus(result.data.findRoleByCode.menus, packageMenus); 
        let dataTree = [];
        for (let i = 0; i < data.length; i++) {
          const item = data[i].menu;
          const obj = {};
          if (item.parent) {
            const parent = item.parent;
            const check = window.COMMON.getObjectInArrs(parent._id, dataTree, 'value');
            if (check) {
              obj.icon = <MDBIcon fa="true" icon={item.icon} className="fa-fw"/>;
              obj.value = item._id;
              obj.label = item.name;
              obj.index = item.index;
              generateRights(obj, data[i]);
              check.children.push(obj);
            } else {
              obj.icon = <MDBIcon fa="true" icon={parent.icon} className="fa-fw"/>;
              obj.value = parent._id;
              obj.label = parent.name;
              obj.index = parent.index;
              obj.children = [];
              dataTree.push(obj);
              const child = {};
              child.icon = <MDBIcon fa="true" icon={item.icon} className="fa-fw"/>;
              child.value = item._id;
              child.label = item.name;
              child.index = item.index;
              generateRights(child, data[i]);
              obj.children.push(child);
            }
          } else {
            obj.icon = <MDBIcon fa="true" icon={item.icon} className="fa-fw"/>;
            obj.value = item._id;
            obj.label = item.name;
            obj.index = item.index;
            generateRights(obj, data[i]);
            dataTree.push(obj);
          }
        }
        dataTree = window.COMMON.sortData(dataTree, 'index');
        setTreeData(dataTree);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RolePage.getDataRoleMenu');
    }
  };

  // ROLE
  const getDataRole = async () => {
    try {
      window.resetDataTable('table-data');
      const params = {
        company: COMPANY_ID
      };
      const result = await window.COMMON.query(RoleGRAPHQL.QUERY_ROLE, params);
      if (result && result.data) {
        const data = window.COMMON.formatDate(result.data.getRoles, 'createdDate');
        setData(data);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RolePage.getDataRole');
    }
    window.initDataTable('table-data');
  };

  const generateRights = (child, item) => {
    if (item.rights && item.rights.length > 0) {
      child.children = [];
      item.rights.forEach(right => {
        child.children.push({
          value: child.value + '-right-' + right._id,
          label: window.COMMON.getValueFromAttr(right, 'name')
        });
      });
    } else {
      child.children = null;
    }
  };

  const openRoleDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-role');
    object.index = index;
    const treeSelected = [];
    const dataSave = { company: COMPANY_ID};
    if (index === -1) {
      dataSave.name = '';
      dataSave.code = '';
      dataSave.description = '';
      dataSave.isPublic = false;
    } else {
      const obj = data[index];
      obj.menus.forEach(item => {
        if (item.menu) {
          treeSelected.push(item.menu._id);
          if (item.rights && item.rights.length > 0) {
            item.rights.forEach(right => {
              treeSelected.push(item.menu._id + '-right-' + right._id);
            });
          }
        }
      });
      dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
      dataSave.name = window.COMMON.getValueFromAttr(obj, 'name');
      dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
      dataSave.description = window.COMMON.getValueFromAttr(obj, 'description');
      dataSave.isPublic = window.COMMON.getValueFromAttr(obj, 'isPublic');
    }
    setObject(object);
    setTreeSelected(treeSelected);
    setTreeExpanded([]);
    setDataSave({ ...dataSave });
  }, [object, COMPANY_ID, data]);

  // eslint-disable-next-line
  const openDeleteRoleDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteRole;
  });

  const findNodeByValue = (data, value) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].value === value) {
        return data[i];
      } else if (data[i].children && data[i].children.length > 0) {
        const nodeInChild = findNodeByValue(data[i].children, value);
        if (nodeInChild) {
          return nodeInChild;
        }
      }
    }
    return;
  };

  const getDataFromTree = () => {
    const menus = [];
    treeSelected.forEach(id => {
      const obj = {
        menu: null,
        rights: []
      };
      if (id.indexOf('-right-') > -1) {
        const arrs = id.split('-right-');
        const check = window.COMMON.getObjectInArrs(arrs[0], menus, 'menu');
        if (check) {
          check.rights.push(arrs[1]);
        } else {
          obj.menu = arrs[0];
          obj.rights.push(arrs[1]);
          menus.push(obj);
        }
      } else {
        const itemTree = findNodeByValue(treeData, id);
        if (itemTree && !itemTree.children) {
          obj.menu = id;
          menus.push(obj);
        }
      }
    });
    return menus;
  }; 

  const saveRole = async (event) => {
    try {
      if (!window.COMMON.checkFormValidation('form-role', event)) {
        return;
      }
      dataSave.menus = getDataFromTree();
      const params = {
        input: dataSave
      };
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(RoleGRAPHQL.MUTATION_ADD_ROLE, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataRole();
		  createTrackingEvent(eventName.ADD_ROLE);
        }
      } else {
        const result = await window.COMMON.mutation(RoleGRAPHQL.MUTATION_EDIT_ROLE, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataRole();
		  createTrackingEvent(eventName.EDIT_ROLE);
        }
      }
      window.COMMON.hideModal('#modal-role');
    } catch (error) {
      window.COMMON.showErrorLogs('RolePage.saveRole');
    }
  };

  const deleteRole = async () => {
    try {
      const params = {
        id: data[object.index]._id
      };
      const result = await window.COMMON.mutation(RoleGRAPHQL.MUTATION_DEL_ROLE, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataRole();
        createTrackingEvent(eventName.DEL_ROLE);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RolePage.deleteRole');
    }
  };

  // PACKAGE
  const getDataPackage = async () => {
    try {
      object.status = {};
      setObject({ ...object });
      window.resetDataTableTree('table-package');
      const result = await window.COMMON.query(RoleGRAPHQL.QUERY_ROLE, null);
      const resCompanyPackages = await window.COMMON.query(RoleGRAPHQL.QUERY_PACKAGE, { filter: { role: 'COMPANY' } });
      const resOrganizationPackages = await window.COMMON.query(RoleGRAPHQL.QUERY_PACKAGE, { filter: { role: 'INFLUENCER' } });
      const resGopyOrganizationPackages = await window.COMMON.query(RoleGRAPHQL.QUERY_PACKAGE, { filter: { role: 'GOPY_INFLUENCER' } });


      if (resCompanyPackages.data.queryPackage?.length) {        
        resCompanyPackages.data.queryPackage.forEach(paramPackage => 
          paramPackage.parent = data.find(el => el.code === 'COMPANY')
        );
      }

      if (resOrganizationPackages.data.queryPackage?.length) {
        resOrganizationPackages.data.queryPackage.forEach(paramPackage => 
          paramPackage.parent = data.find(el => el.code === 'INFLUENCER')
        );
      }

      if (resGopyOrganizationPackages.data.queryPackage?.length) {
        resGopyOrganizationPackages.data.queryPackage.forEach(paramPackage => 
          paramPackage.parent = data.find(el => el.code === 'GOPY_INFLUENCER')
        );
      }

      const allPackages = window.COMMON.formatDate(
        [...resCompanyPackages.data.queryPackage, ...resOrganizationPackages.data.queryPackage,...resGopyOrganizationPackages.data.queryPackage], 'createdDate');
      if (result && result.data) {
        let data = window.COMMON.formatDate(result.data.getRoles, 'createdDate');
        data = data.filter(role => role.code !== 'SUPER_ADMIN' && role.code !== 'UNION');
        // trộn roles + packages để show lên tabletree
        data = [...data, ...allPackages];
        for (let i = 0; i < data.length; i++) {
          data[i].idx = i;
        }
        window.COMMON.createDataTableTree(data, data, 'parent._id', 1);
        setDataPackage([...data]);
        window.initDataTableTree('table-package');
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RightMenuPage.getDataMenu');
    }
  };
  const openPackageDialog = useCallback(async (index) => {
    const data = dataPackage[index];
    const params = {
      code: data.isParent ? data.code : data.parent.code,
      company: null
    };
    const result = await window.COMMON.query(RoleGRAPHQL.QUERY_ROLE_BY_CODE, params);
    let checkMenusPackage = [];
    if (result && result.data) {
      checkMenusPackage = handleMenus(result.data.findRoleByCode.menus, data.menus);
    } else {
      checkMenusPackage = data.menus;
    }
    
    window.COMMON.showModal('#modal-package');
    const dataSave = {company: COMPANY_ID};
    const treeSelected = [];
    if (data.isParent) {
      dataSave.role = data.code;
      dataSave.name = '';
      dataSave.code = '';
      dataSave.description = '';
      dataSave.isDefault = !(dataPackage.filter(el => el.role &&el.role===data.code).length>0);
      object.method = 'add';
    } else {
      checkMenusPackage.forEach(item => {
        if (item.menu) {
          treeSelected.push(item.menu._id);
          if (item.rights && item.rights.length > 0) {
            item.rights.forEach(right => {
              treeSelected.push(item.menu._id + '-right-' + right._id);
            });
          }
        }
      });
      dataSave.isDefault = data.default;
      dataSave._id = data._id;
      dataSave.role = data.parent.code;
      dataSave.name = data.name;
      dataSave.code = data.code;
      dataSave.description = data.description;
      object.method = 'modify';
    }
    modifyTree(result.data.findRoleByCode.menus, treeSelected, dataSave, data.isParent);
    setObject(object);
  }, [dataPackage]);

  const openDeletePackageDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deletePackage;
  });

  const savePackage =  async (event) => {
    try {
      if (!window.COMMON.checkFormValidation('form-package', event)) {
        return;
      }
      dataSave.menus = getDataFromTree();
      const { isDefault, ...rest } = { ...dataSave };
      const params = {
        input: rest
      };
      window.COMMON.trimData(params);
      if (object.method === 'add') {
        /* Gọi API ADD package*/
        const result = await window.COMMON.mutation(RoleGRAPHQL.MUTATION_ADD_PACKAGE, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataPackage();
          createTrackingEvent(eventName.ADD_PACKAGE);
        } 
      } else {
      /* Gọi API EDIT package */  
        const result = await window.COMMON.mutation(RoleGRAPHQL.MUTATION_EDIT_PACKAGE, params);
        if (isDefault) {
          const resultSetDefault = await window.COMMON.mutation(RoleGRAPHQL.SET_DEFAULT_PACKAGE, { id: dataSave._id, role: dataSave.role });
          if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE) && window.COMMON.checkResultData(resultSetDefault, window.CONSTANT.MODE_UPDATE)) {
            getDataPackage();
            createTrackingEvent(eventName.EDIT_PACKAGE);
          } 
        } else if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataPackage();
          createTrackingEvent(eventName.EDIT_PACKAGE);
        } 
      }
      window.COMMON.hideModal('#modal-package');
    } catch (error) {
      window.COMMON.showErrorLogs('RolePage.savePackage');
    }
  };
  const deletePackage = async () => {
    try {
      const params = {
        id: dataPackage[object.index]._id
      };
      /* Gọi API DEL package */
      const result = await window.COMMON.mutation(RoleGRAPHQL.MUTATION_DEL_PACKAGE, params);
      if (result.data.deletePackage.status === 'FAILURE') {
        window.COMMON.showErrorLogs('RolePage.deletePackage.package-is-using-or-not-found');
      } else if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataPackage();
        createTrackingEvent(eventName.DEL_PACKAGE);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RolePage.deletePackage');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }

  const isEmptySelectedMenus = getDataFromTree(treeSelected).length === 0;

  return (
    <div className="container-row">
      <Tab data={dataTabs} id="container-data" icon="list-alt" changeTab={changeTab}>
        <div id="tab-1" className="tab-pane fade active show">
          <div className="container-btn wow fadeInDown animated" data-wow-delay="0.5s">
            <MDBBtn color="primary" onClick={() => openRoleDialog(-1)}>
              <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
            </MDBBtn>
          </div>
          <div className="container-table wow fadeIn animated" data-wow-delay="1s">
            <Table id="table-data" className="table" config={config} data={data} handleRowEditClick={openRoleDialog} handleRowDelClick={openDeleteRoleDialog}></Table>
          </div>
        </div>
        <div id="tab-2" className="tab-pane fade">
          <div className="container-table wow fadeIn animated" data-wow-delay="1s">
            <TableTree id="table-package" className="table" config={configPackage} data={dataPackage} status={object.status} itemId="_id" handleRowAddClick={openPackageDialog} handleRowEditClick={openPackageDialog} handleRowDelClick={openDeletePackageDialog}></TableTree>
          </div>
        </div>
              
      </Tab>

      <Modal id="modal-role" className="modal-xl" title={window.I18N('create_update_data')} saveEvent={saveRole}>
        <form id="form-role" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <MDBInput outline containerClass="mt-0" value={dataSave.name} name="name" onChange={handleChange} type="text" label={window.I18N('name') + ' *'} maxLength="100" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline value={dataSave.code} name="code" onChange={handleChange} type="text" label={window.I18N('code') + ' *'} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline value={dataSave.description} type="textarea" label={window.I18N('description')} name="description" onChange={handleChange} maxLength="5000" pattern="\S(.*\S)?"></MDBInput>
              { 
                window.COMMON.checkRoleIsSystem() ? <MDBInput type="checkbox" id="ck-public-role" name="isPublic" label={window.I18N('is_public')} containerClass="p-0" filled checked={dataSave.isPublic||false} onChange={handleChange}></MDBInput> : <></>
              }
            </MDBCol>
            <MDBCol>
              <CheckboxTree 
                disabled={false}
                nodes={treeData}
                checked={treeSelected}
                expanded={treeExpanded}
                onCheck={treeSelected => setTreeSelected([ ...treeSelected ])}
                onExpand={treeExpanded => setTreeExpanded([ ...treeExpanded ])}
                showNodeIcon={true} showExpandAll={true}
                icons={{
                  check: <MDBIcon far={true} className="blue-strong-text" icon="check-square"/>,
                  uncheck: <MDBIcon far={true} className="blue-strong-text" icon="square"/>,
                  halfCheck: <MDBIcon fas={true} className="light-blue-text" icon="square"/>,
                  expandClose: <MDBIcon far={true} className="blue-strong-text" icon="plus-square"/>,
                  expandOpen: <MDBIcon far={true} className="blue-strong-text" icon="minus-square"/>,
                  expandAll: <MDBIcon far={true} className="blue-strong-text" icon="plus-square"/>,
                  collapseAll: <MDBIcon far={true} className="blue-strong-text" icon="minus-square"/>,
                  parentClose: <MDBIcon fa={true} className="yellow-text" icon="folder"/>,
                  parentOpen: <MDBIcon fa={true} className="yellow-text" icon="folder-open"/>,
                  leaf: <MDBIcon far={true} className="blue-strong-text" icon="file"/>
                }}
              />
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>

      <Modal 
        id="modal-package" 
        className="modal-xl"
        title={window.I18N('create_update_data')} 
        saveEvent={savePackage}
        saveBtnProps={{
          disabled: isEmptySelectedMenus
        }}
      >
        <form id="form-package" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <MDBInput outline type="text" disabled label="Role" containerClass="mt-0" value={dataSave.role} filled ></MDBInput>
              <MDBInput outline  value={dataSave.name} name="name" onChange={handleChange} type="text" label={window.I18N('package')+ ' '+ window.I18N('name') + ' *'} maxLength="100" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline value={dataSave.code} name="code" onChange={handleChange} type="text" label={window.I18N('package')+ ' '+ window.I18N('code') + ' *'} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline value={dataSave.description} type="textarea" label={window.I18N('package') + ' ' + window.I18N('description')} name="description" onChange={handleChange} maxLength="5000" pattern="\S(.*\S)?"></MDBInput>
              {
                window.COMMON.checkRoleIsSystem() && object.method === 'modify' ?
                  <MDBInput type="checkbox" id="ck-default-package" name="isDefault" label={window.I18N('default')}containerClass="p-0" filled checked={dataSave.isDefault||false} onChange={handleChange}></MDBInput> : <></>
              }
            </MDBCol>
            <MDBCol>
              <CheckboxTree 
                disabled={false}
                nodes={treeData}
                checked={treeSelected}
                expanded={treeExpanded}
                onCheck={treeSelected => setTreeSelected([ ...treeSelected ])}
                onExpand={treeExpanded => setTreeExpanded([ ...treeExpanded ])}
                showNodeIcon={true} showExpandAll={true}
                icons={{
                  check: <MDBIcon far={true} className="blue-strong-text" icon="check-square"/>,
                  uncheck: <MDBIcon far={true} className="blue-strong-text" icon="square"/>,
                  halfCheck: <MDBIcon fas={true} className="light-blue-text" icon="square"/>,
                  expandClose: <MDBIcon far={true} className="blue-strong-text" icon="plus-square"/>,
                  expandOpen: <MDBIcon far={true} className="blue-strong-text" icon="minus-square"/>,
                  expandAll: <MDBIcon far={true} className="blue-strong-text" icon="plus-square"/>,
                  collapseAll: <MDBIcon far={true} className="blue-strong-text" icon="minus-square"/>,
                  parentClose: <MDBIcon fa={true} className="yellow-text" icon="folder"/>,
                  parentOpen: <MDBIcon fa={true} className="yellow-text" icon="folder-open"/>,
                  leaf: <MDBIcon far={true} className="blue-strong-text" icon="file"/>
                }}
              />
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>
    </div>
  );
}