import React, { useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar, Paper } from '@material-ui/core';
import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { MDBCardImage } from 'mdbreact';

import CommentGRAPHQL from '../../graphql/Comment';
import eventName from '../../common/events';
import { useTracking } from 'react-tracking';

const ItemComment = React.memo(function ItemComment(props) {
  const { trackEvent } = useTracking();

  let timeout = null;
  const history = useHistory();
  const USER_ID = localStorage.getItem(window.CONSTANT.ID);
  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  
  const [like, setLike] = React.useState(false);
  const [dislike, setDislike] = React.useState(false);

  useLayoutEffect(() => {
    setLike(checkLikeOrDislike('like'));
    setDislike(checkLikeOrDislike('dislike'));
    // eslint-disable-next-line
  }, []);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const checkLikeOrDislike = (attr) => {
    const arrs = window.COMMON.getValueFromAttr(props.item, attr);
    if (arrs && arrs.length > 0) {
      const obj = window.COMMON.getObjectInArrs(USER_ID, arrs, 'createdBy._id');
      if (obj) {
        return true;
      }
    }
    return false;
  };

  const doReply = () => {
    if (props.handleReply) {
      props.item.parentId = props.parentId;
      props.handleReply(props.item);
    }
  };

  const doHideComment = () => {
    if (props.handleHideComment) {
      props.handleHideComment(props.item);
    }
  };

  const handleLikeOrDislike = (attr) => {
    const check = checkLikeOrDislike(attr);
    const arrs = window.COMMON.getValueFromAttr(props.item, attr);
    if (check) {
      window.COMMON.removeObjectInArrs(USER_ID, arrs,'createdBy._id');
    } else {
      const obj = {
        createdBy: { _id: USER_ID }
      };
      arrs.push(obj);
    }
    if (attr === 'like') {
      const dislikes = window.COMMON.getValueFromAttr(props.item, 'dislike');
      window.COMMON.removeObjectInArrs(USER_ID, dislikes,'createdBy._id');
      setLike(!check);
      setDislike(false);
    } else {
      const likes = window.COMMON.getValueFromAttr(props.item, 'like');
      window.COMMON.removeObjectInArrs(USER_ID, likes,'createdBy._id');
      setDislike(!check);
      setLike(false);
    }
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => {
      if (attr === 'like') {
        likePostComment(!check);
      } else {
        dislikePostComment(!check);
      }
    }, 500);
  };

  const likePostComment = async (check) =>  {
    try {
      const params = {
        input: {
          postId: props.postId,
          company: COMPANY_TYPE === window.CONSTANT.COMPANY ? window.COMMON.getValue(COMPANY_ID) : null,
          union: COMPANY_TYPE === window.CONSTANT.UNION ? window.COMMON.getValue(COMPANY_ID) : null,
          influencer: COMPANY_TYPE === window.CONSTANT.INFLUENCER ? window.COMMON.getValue(COMPANY_ID) : null,
          commentId: props.item._id,
          mode: check ? 1 : -1
        }
      };
      await window.COMMON.mutation(CommentGRAPHQL.MUTATION_LIKE_COMMENT, params, false);

	  createTrackingEvent(eventName.LIKE_COMMENT);
    } catch (error) {
      window.COMMON.showErrorLogs('ItemComment.likePostComment');
    }
  };

  const dislikePostComment = async (check) =>  {
    try {
      const params = {
        input: {
          postId: props.postId,
          company: COMPANY_TYPE === window.CONSTANT.COMPANY ? window.COMMON.getValue(COMPANY_ID) : null,
          union: COMPANY_TYPE === window.CONSTANT.UNION ? window.COMMON.getValue(COMPANY_ID) : null,
          influencer: COMPANY_TYPE === window.CONSTANT.INFLUENCER ? window.COMMON.getValue(COMPANY_ID) : null,
          commentId: props.item._id,
          mode: check ? 1 : -1
        }
      };
      await window.COMMON.mutation(CommentGRAPHQL.MUTATION_DISLIKE_COMMENT, params, false);
	  
	  createTrackingEvent(eventName.DISLIKE_COMMENT);
    } catch (error) {
      window.COMMON.showErrorLogs('ItemComment.dislikePostComment');
    }
  };

  const goToAccountDetail = (item) => {
    const creator = window.COMMON.getValueFromAttr(item, 'createdBy._id');
    if (USER_ID !== creator) {
      history.push('/profile?id=' + creator);
    }
  }

  const generateContentComment = (item) => {
    if (item.image) {
      return <div>
        <div className="w-100">
          <MDBCardImage fade="true" cascade waves src={window.COMMON.setValueImage(item.image)} className="image-comment mt-2"/>
        </div>
        <span>{window.COMMON.getValueFromAttr(item, 'text')}</span>
      </div>;
    }
    if (item.sticker) {
      return <div>
        <div className="w-100">
          <MDBCardImage fade="true" cascade waves src={window.COMMON.setValueImage(item.sticker)} className="icon-sticker mt-2"/>
        </div>
        <span>{window.COMMON.getValueFromAttr(item, 'text')}</span>
      </div>;
    }
    return <span>{window.COMMON.getValueFromAttr(item, 'text')}</span>;
  };

  return <>
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar alt="" src={window.COMMON.getValueFromAttr(props.item, 'createdBy.avatar')} className="icon-avatar"/>
      </ListItemAvatar>
      <Paper className="container-content pl-2 pr-2">
        <ListItemText
          primary={
            <p className="font-weight-bold mb-0 cursor-pointer" onClick={() => goToAccountDetail(props.item)}>
              {window.COMMON.getValueFromAttr(props.item, 'createdBy.nickname')}
            </p>
          }
          secondary={ generateContentComment(props.item) }
        />
      </Paper>
    </ListItem>
    <div className="d-flex flex-row align-items-center pr-3 mb-2" style={{ paddingLeft: '64px', marginTop: '-6px' }}>
      <span className="grey-text mr-3"><small>{window.COMMON.getValueFromAttr(props.item, 'createdDate')}</small></span>
      <span className="mr-3 cursor-pointer" onClick={() => handleLikeOrDislike('like')}>
        <small className="grey-text">{props.item.like.length + ' '}</small>
        <small className="font-weight-bold" style={{ color: like ? '#8457FF' : 'grey' }}>{window.I18N('like')}</small>
      </span>
      <span className="mr-3 cursor-pointer" onClick={() => handleLikeOrDislike('dislike')}>
        <small className="grey-text">{props.item.dislike.length + ' '}</small>
        <small className="font-weight-bold" style={{ color: dislike ? '#8457FF' : 'grey' }}>{window.I18N('dislike')}</small>
      </span>
      <span className="text-reply" onClick={() => doReply()}><small>{window.I18N('reply')}</small></span>
      {
        USER_ID === window.COMMON.getValueFromAttr(props.item, 'createdBy._id')
          ? <span className="text-reply grey-text cursor-pointer ml-3" onClick={() => doHideComment()}><small>{window.I18N('hide_comment')}</small></span> : <></>        
      }  
    </div>
    { props.children }
  </>;
});

export default ItemComment;