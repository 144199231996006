import React from 'react'; 
import { NavLink } from 'react-router-dom';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardFooter, MDBCardImage, MDBBtn, MDBIcon } from 'mdbreact';

const GridItem = React.memo(function GridItem(props) {
  const USER_ID = localStorage.getItem(window.CONSTANT.ID);


  const handleItemClick = (event, index) => {
    event.stopPropagation();
    if (props.handleItemClick) {
      props.handleItemClick(index);
    }
  };

  const handleEditClick = (event, index) => {
    event.stopPropagation();
    if (props.handleItemEditClick) {
      props.handleItemEditClick(index);
    }
  };

  const handleDelClick = (event, index) => {
    event.stopPropagation();
    if (props.handleItemDelClick) {
      props.handleItemDelClick(index);
    }
  };

  const handlePreviewClick = (event) => {
    event.stopPropagation();
    if (props.handlePreviewClick) {
      props.handlePreviewClick();
    }
  };

  const hasDraftData = (assessmentId, groupId) => {
    const userId = localStorage.getItem('id');
    const draft = JSON.parse(localStorage.getItem('draftAssessment') || '[]');

    const dataDraft = draft.find(item => item.user === userId && item.assessment === assessmentId && item.group === groupId);
    if (dataDraft) return true;
    return false;
  };

  let html = '';
  if (props.isSurvey) {
    html = props.data.map((item, i) => 
      <MDBCol key={i} className={props.className ? props.className : 'col-md-3 col-sm-4 col-xs-12 p-2 pb-3'}>
        <MDBCard style={{ height: '400px', cursor: 'pointer' }} onClick={(event) => handleItemClick(event, i)}>
          <img src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, 'group.image'))} alt="" className="image-survey border-radius-left-right-top"></img>
          <MDBCardBody className="p-2" style={{ overflow: 'auto' }}>
            <div className="d-flex align-items-start flex-column bd-highlight h-100">
              <div className="mb-auto bd-highlight">
                <p className="mb-2" style={{ fontWeight: 'bold', color : 'black' }}>
                  {window.COMMON.getValueFromAttr(item, 'nameLanguage')}
                </p>
                <p className="mb-2">
                  <MDBIcon fa="true" icon="qrcode" className="fa-fw mr-1"></MDBIcon>
                  <small className="text-muted">{window.COMMON.getValueFromAttr(item, 'group.code')}</small>
                </p>
                {
                  window.COMMON.getValueFromAttr(item, 'group.hashtag', []).length === 0 ? <div></div> : <div>
                    <span className="mr-1 d-inline-block">Hashtag: </span>
                    {
                      window.COMMON.getValueFromAttr(item, 'group.hashtag', []).map((hashtag, j) => <span key={i * j + j} className="text-primary mr-1 d-inline-block">#{hashtag}</span>)
                    }
                  </div>
                }
              </div>
              <div className="bd-highlight w-100 mt-2">
                {
                  props.isShare ? <div className="bd-highlight w-100 mt-2">
                    <div className="d-flex align-items-center m-0">
                      <MDBCardImage cascade waves src={window.COMMON.getValueFromAttr(item, 'company.information.avatar')} className="image-icon rounded-circle mr-2"/>
                      <small className="text-muted">{window.COMMON.getValueFromAttr(item, 'company.information.name')}</small>
                    </div>
                  </div> : <></>
                }
              </div>
            </div>
          </MDBCardBody>
          <MDBCardFooter className="p-2 bg-white" style={{ border: 'none' }}>
            <div className="d-flex align-items-center justify-content-between">
              {
                item.status ? <label className="label-box label-success m-0">{window.I18N('completed')}</label> 
                  : hasDraftData(item.selfAssessment?._id, item.group?._id) ? <label className="label-box label-warning m-0">{window.I18N('draft')}</label>
                    : <label className="label-box label-danger m-0">{window.I18N('new')}</label>
              }
              <p className="card-text m-0">
                <MDBIcon fa="true" icon="calendar-alt" className="fa-fw mr-1"></MDBIcon>
                <small className="text-muted">{window.COMMON.getValueFromAttr(item, 'createdDate')}</small>
              </p>
			  { (props.isDisableEdit && item.leader?._id !== USER_ID) ? <></> :
                <div>
                  <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" disabled={item.status} onClick={item.status ? () => {} : (event) => handleEditClick(event, i)}>
                    <small className="text-muted mr-1">{window.COMMON.getValueFromAttr(item, 'users', []).length}</small>
                    <MDBIcon fa="true" icon="users" style={{ color: 'orange' }} className="fa-fw" />
                  </MDBBtn>
                </div>
              }
            </div>
          </MDBCardFooter>
        </MDBCard>
      </MDBCol>
    );
  } else if (props.isTemplate) {
    html = props.data.map((item, i) => 
      <MDBCol key={i} className={props.className ? props.className : 'col-md-3 col-sm-4 col-xs-12 p-2 pb-3'}>
        <MDBCard style={{ height: '360px', cursor: 'pointer' }} onClick={(event) => handleItemClick(event, i)}>
          <img src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, 'image'))} alt="" className="image-survey border-radius-left-right-top"></img>
          <MDBCardBody className="p-2" style={{ overflow: 'auto' }}>
            <div className="d-flex align-items-start flex-column bd-highlight h-100">
              <div className="mb-auto bd-highlight">
                <p className="mb-1" style={{ fontWeight: 'bold', color : 'black' }}>
                  {window.COMMON.getValueFromAttr(item, 'nameLanguage')}
                </p>
                <p className="mb-1">
                  <small className="text-muted">{window.COMMON.getValueFromAttr(item, 'descriptionLanguage')}</small>
                </p>
                {
                  window.COMMON.getValueFromAttr(item, 'hashtag', []).length === 0 ? <div></div> : <div>
                    <span className="mr-1 d-inline-block">Hashtag: </span>
                    {
                      window.COMMON.getValueFromAttr(item, 'hashtag', []).map((hashtag, j) => <span key={i * j + j} className="text-primary mr-1 d-inline-block">#{hashtag}</span>)
                    }
                  </div>
                }
              </div>
            </div>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    );
  } else {
    html = props.data.map((item, i) => 
      <MDBCol key={i} className={props.className ? props.className : 'col-md-3 col-sm-4 col-xs-12 p-2 pb-3'}>
        <MDBCard style={{ height: '400px', cursor: 'pointer' }}>
          <img src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, 'image'))} alt="" className="image-survey border-radius-left-right-top" onClick= {(event) => handleItemClick(event, i)}></img>
          <MDBCardBody className="p-2" style={{ overflow: 'auto' }} onClick= {(event) => handleItemClick(event, i)}>
            <div className="d-flex align-items-start flex-column bd-highlight h-100">
              <div className="mb-auto bd-highlight">
                <p className="mb-2" style={{ fontWeight: 'bold', color : 'black' }}>
                  {window.COMMON.getValueFromAttr(item, 'nameLanguage')}
                </p>
                <p className="mb-2">
                  <MDBIcon fa="true" icon="qrcode" className="fa-fw mr-1"></MDBIcon>
                  <small className="text-muted">{window.COMMON.getValueFromAttr(item, 'code')}</small>
                </p>
                {
                  item.isDraft&&<p className="mb-2">
                    <small className="text-muted text-primary">{window.I18N('draft')}</small>
                </p>
                }
                {
                  window.COMMON.getValueFromAttr(item, 'hashtag', []).length === 0 ? <div></div> : <div>
                    <span className="mr-1 d-inline-block">Hashtag: </span>
                    {
                      window.COMMON.getValueFromAttr(item, 'hashtag', []).map((hashtag, j) => <span key={i * j + j} className="text-primary mr-1 d-inline-block">#{hashtag}</span>)
                    }
                  </div>
                }
              </div>
              <div className="bd-highlight w-100 mt-2">
                <div className="d-flex align-items-center m-0">
                  <MDBCardImage cascade waves src={window.COMMON.getValueFromAttr(item, 'createdBy.avatar')} className="image-icon rounded-circle mr-2"/>
                  <small className="text-muted">{window.COMMON.getValueFromAttr(item, 'createdBy.name')}</small>
                  {
                    item.isShared ? <MDBIcon fa="true" icon="globe-asia" className="fa-fw fa-lg ml-auto" style={{ color: 'green' }}></MDBIcon> : <></>
                  }
                </div>
              </div>
            </div>
          </MDBCardBody>
          <MDBCardFooter className="p-2 bg-white" style={{ border: 'none' }}>
            <div className="d-flex align-items-center justify-content-between">
              {
                item.countSchedule > 0 ? <small className="label-box label-success p-1">{window.I18N('schedule_running')}: {item.countSchedule}</small> 
                  : <small className="label-box label-danger p-1">{window.I18N('none')}</small>
              }
              <p className="card-text m-0">
                <MDBIcon fa="true" icon="calendar-alt" className="fa-fw mr-1"></MDBIcon>
                <small className="text-muted">{window.COMMON.getValueFromAttr(item, 'createdDate')}</small>
              </p>
              {props.showMenu !== false && <div className="dropdown">
                <NavLink to="#" className="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-boundary="viewport">
                  <MDBIcon fa="true" icon="ellipsis-v" className="fa-fw black-text"></MDBIcon>
                </NavLink>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" data-boundary="viewport">
                  <li className='dropdown-item' onClick= {(event) => handleItemClick(event, i)}>
                    <MDBIcon fa="true" icon="edit" className="fa-fw"></MDBIcon> {window.I18N('btn_edit')}
                  </li>
                  <li className={'dropdown-item red-text' + (item.countSchedule > 0 ? ' disabled' : '')} onClick={item.countSchedule > 0 ? () => {} : (event) => handleDelClick(event, i)}>
                    <MDBIcon fa="true" icon="trash-alt" className="fa-fw"></MDBIcon> {window.I18N('btn_delete')}
                  </li>
                </ul>
              </div>}
            </div>
          </MDBCardFooter>
        </MDBCard>
      </MDBCol>
    );
  }

  return (
    <div className={'container-grid'}>
      <MDBRow className="m-0">
        { props.isSelfAssessment ? <MDBCol sm="12" className="mt-3">
          <div className="d-flex align-items-center" style={{ opacity: props.isExpired ? '0.5' : '1' }}>
            <h5 className="font-weight-bold m-0">{window.COMMON.getValueFromAttr(props.item, 'selfAssessment.name') + (props.showCompany ? ' - ' + window.COMMON.getValueFromAttr(props.item, 'company.information.name') : '') + ' (' + window.COMMON.getValueFromAttr(props.item, 'time') + ')'}</h5>
            {
              props.status === 1 ? <label className="label-box label-info p-2 ml-2 m-0">{window.I18N('submited')}</label> 
                : props.status === -2 ? <label className="label-box label-danger p-2 ml-2 m-0">{window.I18N('expired')}</label> 
                  : props.status === -1 ? <label className="label-box label-warning p-2 ml-2 m-0">{window.I18N('redo')}</label> 
                    : props.status === 2 ? <label className="label-box label-success p-2 ml-2 m-0">{window.I18N('approved')}</label> 
                      : <label className="label-box label-primary p-2 ml-2 m-0">{window.I18N('in_proccessing')}</label>
            }
            {
              props.status !== -2 && props.item && props.item.isCompleted ? <MDBBtn outline className="btn btn-sm ml-2" type="button" color="" onClick={(event) => handlePreviewClick(event)}>
                <MDBIcon fa="true" icon="eye" className="fa-fw" /> {window.I18N('preview')}
              </MDBBtn> : <></>
            }
          </div>
        </MDBCol>: <></> }
        {html}
      </MDBRow>
    </div>
  );
});

export default GridItem;