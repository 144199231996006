import React, { PureComponent } from 'react'; 
import { MDBCardImage } from 'mdbreact';
import LazyLoad from 'react-lazyload';

const Spinner = () => (
  <div className="post loading">
    <svg
      width="40"
      height="40"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid">
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#49d1e0"
        strokeWidth="10"
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138"
        transform="rotate(275.845 50 50)">
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="linear"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"/>
      </circle>
    </svg>
  </div>
);

class ListItem extends PureComponent {

  componentDidMount() {
    this.setState({ index : 0 });
  }

  clickItem(index) {
    this.props.handleItemClick(index);
  }

  render() {
    let html = '';
    if (this.props.isListView) {
      html = this.props.data.map((item, i) => 
        <div className="list-group-item list-group-item-action p-2" key={i}>
          <div className="media">
            <div className="media-left align-self-center p-1">
              <strong>{ i + 1 }. </strong>
              { window.COMMON.getValueFromAttr(item, this.props.attr_image) === '' ? null 
                : <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, this.props.attr_image))} className="image-icon rounded-circle mr-1"/> }
            </div>
            <div className="media-body align-self-center">
              <p className="m-0">{window.COMMON.getValueFromAttr(item, this.props.attr_content)}</p>
            </div>
            <div className="media-right align-self-center">
              <span className="badge badge-primary badge-pill">{window.COMMON.getValueFromAttr(item, this.props.attr_count)}</span>
            </div>
          </div>
        </div>
      );
    } else {
      html = this.props.data.map((item, i) => 
        <div className={this.props.index === i ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action'} key={i} data-id={item[this.props.attr_id]} onClick={this.clickItem.bind(this, i)}>
          <div className="d-flex w-100 justify-content-between align-items-center">
            <p className="m-0 font-weight-bold txt-ellipsis" style={{ maxWidth: '100%' }}><strong>{ i + 1 }. </strong> {window.COMMON.getValueFromAttr(item, this.props.attr_content)}</p>
            <small>{window.COMMON.getValueFromAttr(item, this.props.attr_sub)}</small>
          </div>
          <p className="m-0 txt-ellipsis" style={{ maxWidth: '100%' }}><small>{window.COMMON.getValueFromAttr(item, this.props.attr_desc)}</small></p>
        </div>
      );
    }

    return (
      <div className={'list-group container-list-item ' + window.COMMON.getValue(this.props.className)}>
        {html}
      </div>
    );
  }
}

export default ListItem;