import React from 'react';
import 'datatables.net-bs4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import { MDBCardImage, MDBBtn, MDBIcon } from 'mdbreact';

const TableCustomize = React.memo(function TableCustomize(props) {

  const clickRowDetail = (event, index) => {
    event.stopPropagation();
    if (props.handleRowDetailClick) {
      props.handleRowDetailClick(index);
    }
  };

  const clickRowAdd = (event, index) => {
    event.stopPropagation();
    if (props.handleRowAddClick) {
      props.handleRowAddClick(index);
    }
  };

  const clickRowEdit = (event, index) => {
    event.stopPropagation();
    if (props.handleRowEditClick) {
      props.handleRowEditClick(index);
    }
  };

  const clickRowDel = (event, index) => {
    event.stopPropagation();
    if (props.handleRowDelClick) {
      props.handleRowDelClick(index);
    }
  };

  const clickRowExcel = (event, index) => {
    event.stopPropagation();
    if (props.handleRowExcelClick) {
      props.handleRowExcelClick(index);
    }
  };

  const clickRowStart = (event, index) => {
    event.stopPropagation();
    if (props.handleRowStartClick) {
      props.handleRowStartClick(index);
    }
  };

  const clickRowStop = (event, index) => {
    event.stopPropagation();
    if (props.handleRowStopClick) {
      props.handleRowStopClick(index);
    }
  };

  if (props.isRefresh !== undefined && !props.isRefresh) {
    return null;
  }
  const generateTitle = (index, config, item) => {
    const value = window.COMMON.getValueFromAttr(item, config.attr);
    if (config.type === 'INDEX') {
      return index + 1;
    }
    if (config.type === 'IMAGE') {
      return window.I18N('image');
    }
    if (config.type === 'IMAGE_TEXT') {
      const arrs = config.attr.split(',');
      return window.COMMON.getValueFromAttr(item, arrs[1]);
    }
    const types = ['BUTTON', 'STATUS', 'PERCENT'];
    if (types.includes(config.type)) {
      return '';
    }
    if (config.type === 'LANGUAGE') {
      if (value !== null && value.length > 0) {
        let text = '';
        value.forEach(item => {
          text += item.code + ': ' + item.text + '\n';
        });
        return text;
      }
      return '';
    }
    if (config.type === 'ARRAY') {
      if (value !== null && value.length > 0) {
        let text = '';
        value.forEach(item => {
          text += item.name + '\n';
        });
        return text;
      }
      return '';
    }
    if (config.type === 'ARRAY_ANSWER') {
      if (value !== null && value.length > 0) {
        let text = '';
        value.forEach(item => {
          text += window.COMMON.getValueFromAttr(item, 'content.0.text') + '\n';
        });
        return text;
      }
      return '';
    }
    if (config.type === 'BUTTON_TASK') {
      return '';
    }
    return value;
  };

  const generateContent = (index, config, item) => {
    const value = window.COMMON.getValueFromAttr(item, config.attr);
    if (config.type === 'INDEX') {
      return index + 1;
    }

    if (config.type === 'ICON') {
      return <MDBIcon fa="true" icon={value} className="fa-fw fa-lg"/>;
    }

    if (config.type === 'IMAGE') {
      return <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(value)} className="image-icon rounded-circle"/>;
    }

    if (config.type === 'STATUS') {
      if (value) {
        return <MDBIcon fa="true" icon="check-circle" style={{ color: 'green' }}  className="fa-fw fa-lg"/>;
      }
      return <MDBIcon fa="true" icon="ban" style={{ color: 'red' }}  className="fa-fw fa-lg"/>;
    }

    if (config.type === 'STATUS_TEXT') {
      if (value === 0) {
        return <label className="label-box label-info p-1 m-0">{window.I18N('open')}</label>;
      }
      if (value === 1) {
        return <label className="label-box label-primary p-1 m-0">{window.I18N('running')}</label>;
      }
      return <label className="label-box label-danger p-1 m-0">{window.I18N('expired')}</label>;
    }

    if (config.type === 'IMAGE_TEXT') {
      const arrs = config.attr.split(',');
      const image = window.COMMON.getValueFromAttr(item, arrs[0]);
      const text = window.COMMON.getValueFromAttr(item, arrs[1]);
      return <div>
        <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(image)} className="image-icon rounded-circle mr-2"/>
        <span>{text}</span>
      </div>;
    }

    if (config.type === 'ARRAY') {
      if (value !== null && value.length > 0) {
        return <ul className="container-text-language">
          { value.map((item, i) => (
            <li key={i}>
              <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(item.avatar)} className="image-icon rounded-circle mr-2"/>
              <span>{item.name}</span>
            </li>
          ))}
        </ul>;
      }
      return '';
    }

    if (config.type === 'ARRAY_ANSWER') {
      if (value !== null && value.length > 0) {
        return <ul className="container-text-language">
          { value.map((item, i) => (
            <li key={i}>{window.COMMON.getValueFromAttr(item, 'content.0.text')}</li>
          ))}
        </ul>;
      } else {
        return <div></div>;
      }
    }

    if (config.type === 'LANGUAGE') {
      if (value !== null && value.length > 0) {
        return <ul className="container-text-language">
          { value.map((item, i) => (
            <li key={i}>
              <p className="txt-code">{item.code}:</p>
              <pre className="txt-text">{item.text}</pre>
            </li>
          ))}
        </ul>;
      }
      return '';
    }

    if (config.type === 'TEXT_PRE') {
      if (value !== null && value.length > 0) {
        return <pre className="txt-text">{value}</pre>;
      }
      return '';
    }

    if (config.type === 'VACCINE') {
      if (value && value.vaccine) {
        return <div>
          <label className={'label-box p-1 mb-0 mr-1 ' + (value.vaccine < 2 ? 'label-warning' : 'label-success')}>
            {value.vaccine + ' doze(s) - ' + value.vaccineType}
            <MDBIcon fa="true" icon="caret-down" className="fa-fw" />
          </label>
          <label className={'label-box p-1 mb-0 ' + (!value.status ? 'label-warning' : 'label-success')}>
            {value.status ? window.I18N('approved') : window.I18N('pending')}
            <MDBIcon fa="true" icon="caret-down" className="fa-fw" />
          </label>
        </div>;
      }
      return <label className="label-box label-light p-1 mb-0 mr-1">{window.I18N('none')} <MDBIcon fa="true" icon="caret-down" className="fa-fw" /></label>;
    }

    if (config.type === 'MEDICAL') {
      if (value) {
        return <div>
          <label className="label-box label-light p-1 mb-0 mr-1">{value.typeName} <MDBIcon fa="true" icon="caret-down" className="fa-fw" /></label>
          <label className={'label-box p-1 mb-0 mr-1 ' + (value.isPositive ? 'label-danger' : 'label-success')}>
            {value.isPositive ? <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> : <MDBIcon fa="true" icon="minus-circle" className="fa-fw" /> }
            {(value.isPositive ? window.I18N('positive') : (window.I18N('negative') + ' - ' + value.expiredDate))}
            <MDBIcon fa="true" icon="caret-down" className="fa-fw" />
          </label>
          <label className={'label-box p-1 mb-0 ' + (!value.status ? 'label-warning' : 'label-success')}>
            {value.status ? window.I18N('approved') : window.I18N('pending')}
            <MDBIcon fa="true" icon="caret-down" className="fa-fw" />
          </label>
        </div>;
      }
      return <label className="label-box label-light p-1 mb-0 mr-1">{window.I18N('none')} <MDBIcon fa="true" icon="caret-down" className="fa-fw" /></label>;
    }

    if (config.type === 'BUTTON_TASK') {
      if (value === 0 || value === -1 || value === 2) {
        return <>
          <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" disabled>
            <MDBIcon fa="true" icon="stop" style={{ color: 'red' }} className="fa-fw" />
          </MDBBtn>
          <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowStart(event, index)}>
            <MDBIcon fa="true" icon="play" style={{ color: 'green' }} className="fa-fw" />
          </MDBBtn>
        </>;
      } else if (value === 1) {
        return <>
          <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowStop(event, index)}>
            <MDBIcon fa="true" icon="stop" style={{ color: 'red' }} className="fa-fw" />
          </MDBBtn>
          <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" disabled>
            <MDBIcon fa="true" icon="play" style={{ color: 'green' }} className="fa-fw" />
          </MDBBtn>
        </>;
      } else {
        return <>
          <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" disabled>
            <MDBIcon fa="true" icon="stop" style={{ color: 'red' }} className="fa-fw" />
          </MDBBtn>
          <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" disabled>
            <MDBIcon fa="true" icon="play" style={{ color: 'green' }} className="fa-fw" />
          </MDBBtn>
        </>;
      }
    }

    if (config.type === 'BUTTON_SELF_ASSESSMENT') {
      return <div className="dropdown">
        <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" data-toggle="dropdown" data-boundary="viewport" aria-haspopup="true">
          <MDBIcon fa="true" icon="ellipsis-v" className="fa-fw black-text"></MDBIcon>
        </MDBBtn>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" data-boundary="viewport">
          <li className="dropdown-item" onClick={(event) => clickRowDetail(event, index)}>
            <MDBIcon fa="true" icon="user-plus" className="fa-fw"></MDBIcon> {window.I18N('assign_influencer')}
          </li>
          <li className="dropdown-item" onClick={(event) => clickRowAdd(event, index)}>
            <MDBIcon fa="true" icon="share" className="fa-fw" style={{ color: '#007bff' }}></MDBIcon> {window.I18N('share_influencer')}
          </li>
          <li className="dropdown-divider"></li>
          <li className="dropdown-item" onClick={(event) => clickRowEdit(event, index)}>
            <MDBIcon fa="true" icon="edit" className="fa-fw" style={{ color: 'orange' }}></MDBIcon> {window.I18N('btn_edit')}
          </li>
          <li className={'dropdown-item' + (value === 1 ? ' disabled' : '')} onClick={value === 1 ? () => {} : (event) => clickRowDel(event, index)}>
            <MDBIcon fa="true" icon="trash-alt" className="fa-fw" style={{ color: 'red' }}></MDBIcon> {window.I18N('btn_delete')}
          </li>
        </ul>
      </div>;
    }

    if (config.type === 'BUTTON') {
      if (config.attr === '0') {
        return <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowDetail(event, index)}>
          <MDBIcon fa="true" icon="info-circle" className="fa-fw" />
        </MDBBtn>;
      } else if (config.attr === '1') {
        return <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowAdd(event, index)}>
          <MDBIcon fa="true" icon="plus-circle" className="fa-fw" />
        </MDBBtn>;
      } else if (config.attr === '2') {
        return <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowEdit(event, index)}>
          <MDBIcon fa="true" icon="edit" className="fa-fw" />
        </MDBBtn>;
      } else if (config.attr === '-1') {
        return <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowDel(event, index)}>
          <MDBIcon fa="true" icon="trash-alt" className="fa-fw" />
        </MDBBtn>;
      } else if (config.attr === '3') {
        return <>
          <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowEdit(event, index)}>
            <MDBIcon fa="true" icon="edit" className="fa-fw" />
          </MDBBtn>
          <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowDel(event, index)}>
            <MDBIcon fa="true" icon="trash-alt" className="fa-fw" />
          </MDBBtn>
        </>;
      } else if (config.attr === '4') {
        return <>
          <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowAdd(event, index)}>
            <MDBIcon fa="true" icon="plus-circle" className="fa-fw" />
          </MDBBtn>
          <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowEdit(event, index)}>
            <MDBIcon fa="true" icon="edit" className="fa-fw" />
          </MDBBtn>
          <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowDel(event, index)}>
            <MDBIcon fa="true" icon="trash-alt" className="fa-fw" />
          </MDBBtn>
        </>;
      } else if (config.attr === '5') {
        return <>
          <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowDetail(event, index)}>
            <MDBIcon fa="true" icon="info-circle" className="fa-fw" />
          </MDBBtn>
          <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowEdit(event, index)}>
            <MDBIcon fa="true" icon="edit" className="fa-fw" />
          </MDBBtn>
          <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowDel(event, index)}>
            <MDBIcon fa="true" icon="trash-alt" className="fa-fw" />
          </MDBBtn>
        </>;
      } else if (config.attr === '6') {
        return <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowExcel(event, index)}>
          <MDBIcon fa="true" icon="file-excel" className="fa-fw" />
        </MDBBtn>;
      } else if (config.attr === '7') {
        return <>
          <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowEdit(event, index)}>
            <MDBIcon fa="true" icon="check-circle" className="fa-fw" />
          </MDBBtn>
          <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={(event) => clickRowDel(event, index)}>
            <MDBIcon fa="true" icon="ban" className="fa-fw" />
          </MDBBtn>
        </>;
      }
    }
    return value;
  };
    
  let header = null;
  if (props.config !== undefined && props.config.length > 0) {
    header = (
      <tr>
        {props.config.map((item, i) => (
          <th key={i} width={item.width}> {item.colName} </th>
        ))}
      </tr>
    );
  }

  let body = null;
  if (props.data !== undefined && props.data.length > 0) {
    body = props.data.map((item, i) => (
      <tr className={props.isImported && item.isExisted ? 'tr-is-existed' : ''} style={{ cursor: props.clickRow ? 'pointer' : 'default' }} data-id={window.COMMON.getValue(item[props.itemId])} key={i} onClick={ props.clickRow ? () => props.clickRow(i) : () => {} }>
        {props.config.map((config, j) => (
          <td key={j} className={'txt-ellipsis ' + config.className} align={config.alignment} style={{width: config.width + ' !important', maxWidth: config.width + ' !important'}} title={generateTitle(i, config, item)}> 
            {generateContent(i, config, item)}
          </td>
        ))}
      </tr>
    ));
  } else {
    body = <tr>
      <td colSpan={(props.config && props.config.length) || 1} className="text-center"> 
        {window.I18N('empty_table')}
      </td>
    </tr>;
  }

  return (
    <table className={window.COMMON.getValue(props.className) + ' wow fadeIn animated'} data-wow-delay="0.2s" id={props.id}>
      <thead>{header}</thead>
      <tbody>{body}</tbody>
    </table>
  );
});

export default TableCustomize;