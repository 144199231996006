import React, { useImperativeHandle, useMemo, useRef, useState } from 'react'
import { Bar, HorizontalBar, Line } from 'react-chartjs-2'

const LINE_CHART = 'Line'
const BAR_CHART = 'Bar'
const HORIZONTALBAR_CHART = 'HorizontalBar'

const CHART = {
    [BAR_CHART]: Bar,
    [HORIZONTALBAR_CHART]: HorizontalBar,
    [LINE_CHART]: Line
}

const CustomChart = React.forwardRef(
    (
        {
            typeChart = BAR_CHART,
            dataChart,
            createInnerHTMLPopup = () => {},
            onDataHover = () => {},
            height = 500,
            displayLegend = false,
            options = {}
        },
        ref
    ) => {
        const Chart = useMemo(() => CHART[typeChart], [typeChart])
        const [focusedIndex, setFocusedIndex] = useState(null)
        const [popupPosition, setPopupPosition] = useState({
            left: null,
            top: null
        })
        const [hoverLabel, setHoverLabel] = useState(null)
        const [hoverDatasetIndex, setHoverDatasetIndex] = useState(null)

        const [dataPopup, setDataPopup] = useState(null)

        const hoverPopup = useRef(false)
        const chartRef = useRef()
        const popupRef = useRef()

        const handleChartHover = (event, chartElements) => {
            const position = chartRef.current?.chartInstance.canvas.getBoundingClientRect()
            if (chartElements && chartElements.length > 0 && !hoverPopup.current) {
                let minDistance = 999
                let chartElHover = null
                let datasetIndexHover = []
                chartElements.forEach((el, index) => {
                    let distance = 0
                    if (typeChart === LINE_CHART) {
                        distance = Math.abs(event.y - (el.tooltipPosition().y + position.top))
                    } else {
                        distance = Math.abs(event.x - (el.tooltipPosition().x + position.left))
                    }
                    if (distance < minDistance) {
                        minDistance = distance
                        typeChart === LINE_CHART ? (datasetIndexHover = [index]) : (chartElHover = el)
                    } else if (distance == minDistance) {
                        datasetIndexHover.push(index)
                    }
                })

                const { x, y } =
                    typeChart === LINE_CHART ? chartElements[0].tooltipPosition() : chartElHover.tooltipPosition()
                let left = parseInt(x)
                let top = parseInt(
                    typeChart === LINE_CHART ? chartElements[datasetIndexHover[0]].tooltipPosition().y : y
                )

                const datasetIshover =
                    typeChart === LINE_CHART
                        ? datasetIndexHover.map((el) => chartElements[el]._datasetIndex)
                        : chartElHover._datasetIndex
                if (popupPosition.left !== left || popupPosition.top !== top) {
                    setPopupPosition({ left, top })

                    const data = onDataHover(chartElHover, chartElements)
                    setDataPopup(data)
                }

                setFocusedIndex(chartElements[0]._index)
                setHoverLabel(typeChart === LINE_CHART ? datasetIshover[0] : chartElements[0]._model.label)
                setHoverDatasetIndex(datasetIshover)
            } else {
                // Reset the focused index when the mouse leaves the chart
                setTimeout(() => {
                    if (!hoverPopup.current) {
                        setFocusedIndex(null)
                        setHoverLabel(null)
                        setHoverDatasetIndex(null)
                    }
                }, 0)
            }
        }

        const handleHoverPopup = () => {
            if (!hoverPopup.current) {
                hoverPopup.current = true
            }
        }
        const handleLeavePopup = (e) => {
            const isMouseOutCanvas =
                chartRef.current?.chartInstance.canvas.getBoundingClientRect().right < e.clientX ||
                chartRef.current?.chartInstance.canvas.getBoundingClientRect().bottom < e.clientY
            if (isMouseOutCanvas) {
                setFocusedIndex(null)
                setHoverLabel(null)
                setHoverDatasetIndex(null)
            }
            if (hoverPopup.current) {
                hoverPopup.current = false
            }
        }

        const handleSetIndexDatasetPopup = (params) => {
            setHoverLabel(params)
        }

        useImperativeHandle(ref, () => ({
            handleSetIndexDatasetPopup
        }))

        return (
            <>
                <Chart
                    datasetKeyProvider={() => Math.random()}
                    data={dataChart}
                    ref={chartRef}
                    height={height}
                    options={{
                        onHover: handleChartHover,
                        legend: {
                            display: true,
                            position: 'bottom',
                            display: displayLegend
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                        stepSize: 1,
                                        suggestedMin: 0,
                                        suggestedMax: 10
                                    }
                                }
                            ],
                            xAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                        stepSize: 1
                                    }
                                }
                            ]
                        },
                        tooltips: {
                            enabled: false
                        },
                        ...options
                    }}
                ></Chart>
                {(hoverPopup.current || focusedIndex !== null) &&
                    dataChart?.datasets[hoverLabel]?.data[focusedIndex] !== 0 && (
                        <div
                            onMouseEnter={handleHoverPopup}
                            onMouseLeave={handleLeavePopup}
                            id="popup-chart"
                            className="wow fadeIn custom-tooltip"
                            style={{
                                position: 'absolute',
                                left: `${popupPosition.left - popupRef?.current?.clientWidth}px`, // Adjust as needed
                                top: `${popupPosition.top - popupRef?.current?.clientHeight / 2}px`, // Adjust as needed
                                background: 'white',
                                border: '0.5px solid #ccc',
                                borderRadius: '4px',
                                boxShadow: '0 0 6px rgba(0, 0, 0, 0.1)',
                                padding: '5px 8px 8px 8px',
                                zIndex: 9999
                            }}
                            ref={popupRef}
                        >
                            {/* Render your tooltip content here */}
                            {hoverLabel !== null &&
                                createInnerHTMLPopup(
                                    typeChart === LINE_CHART
                                        ? {
                                              hoverLabelIndex: focusedIndex,
                                              indexDatasetPopup: hoverLabel,
                                              hoverDatasetIndex
                                          }
                                        : dataPopup || hoverLabel
                                )}
                        </div>
                    )}
            </>
        )
    }
)

export default CustomChart
