import React, { useState, useLayoutEffect, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { MDBCol, MDBIcon, MDBRow, MDBCardImage, MDBInput} from 'mdbreact';
import Common from '../../utils/Common';
import CaseAssessmentGRAPHQL from '../../graphql/CaseAssessment';

const ListSelfAssessment = React.memo(function ListSelfAssessment(props) {
  const [checked, setChecked] = useState({});
  const [data, setData] = useState([]);
  const [dataTaskInfluencer, setDataTaskInfluencer] = useState([]);
  const [dataConClusionStatus, setDataConClusionStatus] = useState([]);

  useLayoutEffect(() => {
    setChecked({});
    setData(props.data);
    setDataTaskInfluencer(props.dataTaskInfluencer);
    if (props.dataSelected && props.dataSelected.length > 0) {
      const checked = {};
      props.dataSelected.forEach(item => {
        checked[item] = true;
      });
      setChecked(checked);
    }
  }, [props.data, props.dataTaskInfluencer, props.dataSelected]);

  const makeAssessmentReportConclusion = (event, index) => {
    event.stopPropagation();
    if (props.makeAssessmentReportConclusion !== undefined) {
      props.makeAssessmentReportConclusion(index);
    }
  };

  const handleExport = (event, index) => {
    event.stopPropagation();
    if (props.handleExport !== undefined) {
      props.handleExport(index);
    }
  };

  const handleChange = (event, id) => {
    event.stopPropagation();
    checked[id] = !checked[id];
    setChecked({ ...checked });
    if (props.handleCheckChange) {
      props.handleCheckChange(checked);
    }
  };

  const handleAccept = (event, index, type) => {
    event.stopPropagation();
    if (props.handleAccept) {
      props.handleAccept(index, type);
    }
  };

  const handleReject = (event, index, type) => {
    event.stopPropagation();
    if (props.handleReject) {
      props.handleReject(index, type);
    }
  };

  // const getItemTaskInfluencer = (item) => {
  //   if (dataTaskInfluencer && dataTaskInfluencer.length > 0) {
  //     return window.COMMON.getObjectInArrs(item.company._id, props.dataTaskInfluencer, 'company._id');
  //   }
  //   return null;
  // };

  const getIndexTaskInfluencer = (companyTaskItem) => {
    if (dataTaskInfluencer && dataTaskInfluencer.length > 0) {
      return dataTaskInfluencer.findIndex(task => task.company && task.company._id === companyTaskItem.company._id);
    }

    return -1;
  };

  const disableReassignInfluencers = (taskInfluencerItem, conclusion) => {
    // disable re-assign if task have conclusion
    if (conclusion) {
      return true
    }
    // Allow re-assign when at redo state
    if (!taskInfluencerItem || taskInfluencerItem.status === -1) {
      return false;
    }

    let influencers = null;
    if (props.dataSelfAssessmentInfluencer) {
      props.dataSelfAssessmentInfluencer.forEach(element => {
        if (element.companies && element.companies.length > 0) {
          const obj = window.COMMON.getObjectInArrs(taskInfluencerItem.company._id, element.companies, '_id');
          if (obj) {
            influencers = element.influencers;
          }
        }
      });
    }
    if (influencers && influencers.length > 0) {
      return true;
    }
    return false;
  };

  const generateStatus = (item) => {
    if (!item) {
      return <small className="text-muted">{window.I18N('none')}</small>;
    }
    return item.status === 1 ? <label className="label-box label-info p-1 m-0">{window.I18N('submited')}</label> 
      : item.status === -2 ? <label className="label-box label-danger p-1 m-0">{window.I18N('expired')}</label> 
        : item.status === -1 ? <label className="label-box label-warning p-1 m-0">{window.I18N('redo')}</label> 
          : item.status === 2 ? <label className="label-box label-success p-1 m-0">{window.I18N('approved')}</label> 
            : <label className="label-box label-primary p-1 m-0">{window.I18N('in_proccessing')}</label>;
  };

  const generateProgress = (data) => {
    const length = data.length;
    let total = 0;
    if (length > 0) {
      data.forEach(element => {
        if (element.isCompleted) {
          total += 1;
        }
      });
      return Math.round(parseFloat(total / length * 100) * 100) / 100;
    }
    return 0;
  };

  const generateInfluencer = (itemTaskInf, itemTaskCom) => {
    let influencers = null;
    if (itemTaskInf?.influencers){
      influencers= itemTaskInf?.influencers
    } else if (props.dataSelfAssessmentInfluencer) {
      props.dataSelfAssessmentInfluencer.forEach(element => {
        if (element.companies && element.companies.length > 0) {
          const obj = window.COMMON.getObjectInArrs(itemTaskCom.company._id, element.companies, '_id');
          if (obj) {
            influencers = element.influencers;
          }
        }
      });
    }
    if (influencers && influencers.length > 0) {
      return <>
        {
          influencers.map((element, i) => <p key={itemTaskCom?._id + '-' + element._id} className="card-text m-0">
            <MDBIcon fa="true" icon="user" className="fa-fw mr-1"></MDBIcon>
            <span>{window.COMMON.getValueFromAttr(element, 'name')}</span>
          </p>)
        }
        <small className="font-weight-bold text-muted">{window.I18N('advisor')}</small>
      </>;
    }
    return <><p className="card-text m-0">{window.I18N('none')}</p>
      <small className="font-weight-bold text-muted">{window.I18N('advisor')}</small>
    </>;
  };

  const getAllConclusionStatus = async() => {
    const tasksConclusion = data.map(task => Common.query(CaseAssessmentGRAPHQL.QUERY_ASSESSMENT_CONCLUSION_BY_COMPANY_TASK_ID, { companyTask: task._id }));
    Promise.allSettled(tasksConclusion)
      .then((results) => results.map(result => result.value))
      .then(resValues => resValues.map(i => !!i?.data?.getAssessmentConclusionByCompanyTaskId))
      .then(statusArr => setDataConClusionStatus(statusArr));
  };

  useEffect(() => {
    getAllConclusionStatus();
  }, [data]);

  const html = data.map((item, i) => {
    const dataTaskInfluencerIndex = getIndexTaskInfluencer(item);
    const taskInfluencerItem = dataTaskInfluencer && dataTaskInfluencer.length > 0 ? dataTaskInfluencer[dataTaskInfluencerIndex] : null;

    return (
      <li className="list-group-item p-2 wow fadeInUp animated" key={i}>
        <MDBRow>
          <MDBCol className='d-flex flex-column justify-content-center'>
            <div className="d-flex align-items-center">
              <MDBInput type="checkbox" id={'checkbox-' + item._id} containerClass="pl-2 pt-2" filled checked={checked[item.company._id] || false} disabled={disableReassignInfluencers(taskInfluencerItem,dataConClusionStatus[i])} onChange={disableReassignInfluencers(taskInfluencerItem,dataConClusionStatus[i]) ? () => {} : (event) => handleChange(event, item.company._id)}/>
              <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, 'company.information.avatar'))} className="image-icon rounded-circle mr-2"/>
              <p className="font-weight-bold m-0">{window.COMMON.getValueFromAttr(item, 'company.information.name')}</p>
            </div>
            {dataConClusionStatus[i] ? <div className="font-weight-bold text-muted ml-5 mt-2">{window.I18N('concluded') }</div>:<></>}
          </MDBCol>
          <MDBCol>
            <p className="m-0 mb-2">{generateStatus(item)}</p>
            {
              window.COMMON.getValueFromAttr(item, 'items', []).map((child, j) => <p key={i * j + j} className="card-text mb-1">
                <MDBIcon fa="true" icon="circle" className="fa-fw mr-1" style={{ color: child.isCompleted ? '#00c851' : '#dddddd' }}></MDBIcon>
                {window.COMMON.getValueWithLanguage(child, 'group.name', props.language)}
              </p>)
            }
            <div className="progress">
              <div className="progress-bar progress-bar-success progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100" style={{ width: generateProgress(window.COMMON.getValueFromAttr(item, 'items', [])) + '%' }}>
                <span>{generateProgress(window.COMMON.getValueFromAttr(item, 'items', [])) + '%'}</span>
              </div>
            </div>
            <hr className="vertical light mt-0"></hr>
          </MDBCol>
          <MDBCol>
            {
              generateInfluencer(taskInfluencerItem, item)
            }
          </MDBCol>
          <MDBCol>
            <p className="m-0 mb-2">{generateStatus(taskInfluencerItem)}</p>
            {
              window.COMMON.getValueFromAttr(taskInfluencerItem, 'items', []).map((child, j) => <p key={i * j + j} className="card-text mb-1">
                <MDBIcon fa="true" icon="circle" className="fa-fw mr-1" style={{ color: child.isCompleted ? '#00c851' : '#dddddd' }}></MDBIcon>
                {window.COMMON.getValueWithLanguage(child, 'group.name', props.language)}
              </p>)
            }
            {
              window.COMMON.getValueFromAttr(taskInfluencerItem, 'items', []).length > 0 ? <div className="progress">
                <div className="progress-bar progress-bar-success progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100" style={{ width: generateProgress(window.COMMON.getValueFromAttr(taskInfluencerItem, 'items', [])) + '%' }}>
                  <span>{generateProgress(window.COMMON.getValueFromAttr(taskInfluencerItem, 'items', [])) + '%'}</span>
                </div>
              </div> : <div></div>
            }
          </MDBCol>
          <MDBCol xs="2" sm="1" md="1" className="text-right">
            <div className="dropdown">
              <NavLink to="#" className="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" disabled={props.disabled} data-boundary="viewport">
                <MDBIcon fa="true" icon="ellipsis-v" className="fa-fw black-text"></MDBIcon>
              </NavLink>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" data-boundary="viewport">
                <li 
                  className={'dropdown-item'} 
                  onClick={(event) => makeAssessmentReportConclusion(event, i)}
                >
                  <MDBIcon fa="true" icon="eye" className="fa-fw"></MDBIcon> {window.I18N('btn_preview_conclusion')}
                </li>
                <li 
                  className={'dropdown-item' + ((item.status < 1) ? ' disabled' : '')} 
                  onClick={ev => handleExport(ev, i)}
                >
                  <MDBIcon fa="true" icon="download" className="fa-fw"/> {window.I18N('export_pdf')}
                </li>
                <h6 className="dropdown-header">{window.I18N('company')}</h6>
                <li className={'dropdown-item' + (item.status !== 1 || dataConClusionStatus[i] ? ' disabled' : '')} onClick= {item.status !== 1 || dataConClusionStatus[i]  ? () => {} : (event) => handleReject(event, i, 'COMPANY')}>
                  <MDBIcon fa="true" icon="sync-alt" className="fa-fw"></MDBIcon> {window.I18N('redo')}
                </li>
                <li className={'dropdown-item' + (item.status !== 1 ? ' disabled' : '')} onClick= {item.status !== 1 ? () => {} : (event) => handleAccept(event, i, 'COMPANY')}>
                  <MDBIcon fa="true" icon="check" className="fa-fw"></MDBIcon> {window.I18N('btn_approve')}
                </li>
                <li className="dropdown-divider"></li>
                <h6 className="dropdown-header">{window.I18N('advisor')}</h6>
                <li 
                  className={'dropdown-item' + (window.COMMON.getValueFromAttr(taskInfluencerItem, 'status', 0) !== 1 || dataConClusionStatus[i]  ? ' disabled' : '')}
                  onClick={window.COMMON.getValueFromAttr(taskInfluencerItem, 'status', 0) !== 1 || dataConClusionStatus[i]
                    ? () => {}
                    : (event) => handleReject(event, getIndexTaskInfluencer(item), 'INFLUENCER')}
                >
                  <MDBIcon fa="true" icon="sync-alt" className="fa-fw"></MDBIcon> {window.I18N('redo')}
                </li>
                <li
                  className={'dropdown-item' + (window.COMMON.getValueFromAttr(taskInfluencerItem, 'status', 0) !== 1  ? ' disabled' : '')}
                  onClick={window.COMMON.getValueFromAttr(taskInfluencerItem, 'status', 0) !== 1
                    ? () => {}
                    : (event) => handleAccept(event, getIndexTaskInfluencer(item), 'INFLUENCER')}
                >
                  <MDBIcon fa="true" icon="check" className="fa-fw"></MDBIcon> {window.I18N('btn_approve')}
                </li>
              </ul>
            </div>
          </MDBCol>
        </MDBRow>
      </li>
    );
  });

  return (
    <div className={'container-list-account ' + window.COMMON.getValue(props.className)}>
      <ul className="list-group">
        {html}
      </ul>
    </div>
  );
});

export default ListSelfAssessment;