import React, { useEffect, useRef, useState } from "react"
import { MDBBtn, MDBRow, MDBCol, MDBIcon } from "mdbreact"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { connect } from "react-redux"

import BillSteps, {
  NewSurveyPageStepStatus as billStatus,
} from "../../views/survey/NewSurveyPageSteps"

import SetPackageStep from "./components/CreateBillSteps/SetPackageStep"
import SetDiscountAndTaxStep from "./components/CreateBillSteps/SetDiscountAndTaxStep"
import SendBillStep from "./components/CreateBillSteps/SendBillStep"
import useUnSaveChangesWarning from "../../../hooks/useUnSaveChangesWarning"

const SET_PACKAGE_STEP = 0
const SET_DISCOUNTS_AND_TAX_STEP = 1
const SEND_BILL_STEP = 2

const INIT_DATABILL = {
  refId: '',
  receiver: {
    name: '',
    email: '',
    phone: '',
    country: '',
    district: '',
    province: '',
    address: ''
  },
  packages: [{
    name: '',
    code: '',
    subscriptionType: '',
    from: '', 
    to: '',
    price: 0, 
  }],
  createdUser: {
    _id: "611fe1b08f46e7446655038a",
    name: "Admin",
    nickname: "Gopy Admin",
    email: "admin@csrvietnam.org",
    phone: "08881234567",
    address: "117 - Ly Chinh Thang - Q3 - TP.HCM"
  },
  discounts: [],
  vat: '',
  amount: null,
  isReadySendBill: false
}

const CREATE_MODE = {
  isEdit: false,
  data: INIT_DATABILL
}

const CreateBillPage = () => {
  const ACCOUNT_TYPE = localStorage.getItem(window.CONSTANT.ACCOUNT_TYPE)
  const DATA_EDIT = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_EDIT_BILL)) || CREATE_MODE
  const { isEdit, data: dataEdit } = DATA_EDIT 

  const TABS = [
    {
      id: "0",
      name: isEdit ? "update_package" : "choose_package",
      status: billStatus.PROCESSING
    },
    {
      id: "1",
      name: isEdit ? "update_discount_and_tax" : "discount_and_tax",
      status: billStatus.WAITING
    },
    {
      id: "2",
      name: isEdit ? "update_bill" : "send_bill",
      status: billStatus.WAITING
    }
  ]

  const navigate = useHistory()

  const [tabs, setTabs] = useState(TABS)
  const [currentStep, setCurrentStep] = useState(0);
  const [dataBill, setDataBill] = useState(INIT_DATABILL)

  const stepsRef = useRef({})

  const removeDataEdit = () => {
    localStorage.removeItem(window.CONSTANT.DATA_EDIT_BILL)
  }

  const handleRedirect = () => {
    navigate.push('/payment-management')
  }

  const [WarningPrompt, setUsePrompt, setCancelUsePrompt, setCancelPromptlBeforeSubmit] = useUnSaveChangesWarning({
    message: 'Changes you made may not be saved.',
    defaultIsUsePrompt: true,
    callbackWhenRedirect: removeDataEdit,
    callbackWhenReload: isEdit ? removeDataEdit : () => {},
    currentPath: window.location.pathname,
    isActive: true
  });

  const handleGetDataOnStep = (indexStep, data) => {
    indexStep !== null && handleNextStep(indexStep)
    setDataBill((prev) => ({...prev, ...data}))
  }


  const handleChangeStep = (indexClick) => {
    if (indexClick === currentStep) {
      return
    }
    setCurrentStep(indexClick)
    setTabs((current) => {
      const { PROCESSING, WAITING, COMPLETED } = billStatus;
      const newSteps = current.map((item, index) => {
        let status = COMPLETED
        if (indexClick === index) {
          status = PROCESSING
        } else if (indexClick < index) {
          status = WAITING
        }
        return {
          ...item,
          status,
        }
      })
      return [...newSteps]
    })
  }

  const handleNextStep = (index) => {
    handleChangeStep(index)
  }
  
  const handleNextStepOnClickStep = (indexClick) => {
    if(indexClick === SET_DISCOUNTS_AND_TAX_STEP && currentStep === SET_PACKAGE_STEP){
      const isNextStep = stepsRef.current[SET_PACKAGE_STEP].nextStep()
      isNextStep && handleChangeStep(SET_DISCOUNTS_AND_TAX_STEP)
    }
    else if(indexClick === SEND_BILL_STEP && currentStep === SET_DISCOUNTS_AND_TAX_STEP){
      const isNextStep = stepsRef.current[SET_DISCOUNTS_AND_TAX_STEP].nextStep()
      isNextStep && handleChangeStep(SEND_BILL_STEP)
    }
    else if(indexClick === SET_PACKAGE_STEP && currentStep === SET_DISCOUNTS_AND_TAX_STEP){
      handleChangeStep(SET_PACKAGE_STEP)
    }
    else if(indexClick === SET_DISCOUNTS_AND_TAX_STEP && currentStep === SEND_BILL_STEP){
      handleChangeStep(SET_DISCOUNTS_AND_TAX_STEP)
    }
  }
  
  useEffect(()=>{
    if(dataBill.isReadySendBill){
      isEdit ? handleUpdateBill() : handleCreateBill()
    } 
  },[dataBill])

  const handleCreateBill = async () => {
    try {
      const { isReadySendBill, packagePrice, ...restDataBill } = dataBill
      const result = await window.COMMON.httpRequest({
          url: `${process.env.REACT_APP_URL_PAYMENT_API}/bills`,
          method: 'POST',
          input: {
            data: {...restDataBill}
          },
          requireToken: true,
          handleError: () => {
              window.COMMON.showErrorLogs('CreateBill.createBill');
          }
      })
      //check result isn't empty and show notify
      if(!window.COMMON.isEmpty(result)){
        window.COMMON.showMessage('success', 'MSG_CODE_001', window.I18N('MSG_CODE_001'));
        handleRedirect()
      }
    } catch (error) {
      window.COMMON.showMessage('error', 'MSG_CODE_004', window.I18N('MSG_CODE_004'));
      window.COMMON.showErrorLogs('CreateBill.createBill')
    }
  }

  const handleUpdateBill = async () => {
    setCancelPromptlBeforeSubmit(async () => {
      try {
        const { isReadySendBill, packagePrice, ...restDataBill } = dataBill
        const result = await window.COMMON.httpRequest({
            url: `${process.env.REACT_APP_URL_PAYMENT_API}/bills/${dataEdit._id}`,
            method: 'PUT',
            input: {
              data: {...restDataBill}
            },
            requireToken: true,
            handleError: () => {
                window.COMMON.showErrorLogs('CreateBill.updateBill');
            }
        })
        //check result isn't empty and show notify
        if(!window.COMMON.isEmpty(result)){
          window.COMMON.showMessage('success', 'MSG_CODE_002', window.I18N('MSG_CODE_002'))
          removeDataEdit()
          handleRedirect()
        }
      } catch (error) {
        window.COMMON.showMessage('error', 'MSG_CODE_005', window.I18N('MSG_CODE_005'))
        window.COMMON.showErrorLogs('CreateBill.updateBill')
      }
    })
  }
  

  const handleBackOrNextStep = (type) => {
    let step = type === 'backStep' ? currentStep - 1 : currentStep + 1
    if(step < SET_PACKAGE_STEP){
      step = SET_PACKAGE_STEP
    }else if(step > SEND_BILL_STEP){
      step = SEND_BILL_STEP
    }
    handleNextStepOnClickStep(step)
  }

  if(![window.CONSTANT.SUPER_ADMIN, window.CONSTANT.GOPY_INFLUENCER].includes(ACCOUNT_TYPE)){
    navigate.push('/404')
    return null
  }

  return (
    <div className="w-full" style={{ position: 'relative' }}>
      <BillSteps steps={tabs} onStepClick={handleNextStepOnClickStep} />
      {/* Steps */}
        <div
          className="w-full wow fadeInRight animated mt-3"
          data-wow-delay="0.2s"
          style={{
            display:
              tabs[SET_PACKAGE_STEP].status === billStatus.PROCESSING ? "block" : "none",
          }}
        >
            <SetPackageStep isEdit={isEdit} dataEdit={dataEdit} onNextStep={handleGetDataOnStep} ref={el => stepsRef.current[SET_PACKAGE_STEP] = el}/>
        </div>

        <div
          className="w-full wow fadeInRight animated mt-3"
          data-wow-delay="0.2s"
          style={{
            display:
              tabs[SET_DISCOUNTS_AND_TAX_STEP].status === billStatus.PROCESSING ? "block" : "none",
          }}
        >
            <SetDiscountAndTaxStep isEdit={isEdit} data={dataBill} dataEdit={dataEdit} onNextStep={handleGetDataOnStep} ref={el => stepsRef.current[SET_DISCOUNTS_AND_TAX_STEP] = el}/>
        </div>

        <div
          className="w-full wow fadeInRight animated mt-3"
          data-wow-delay="0.2s"
          style={{
            display:
              tabs[SEND_BILL_STEP].status === billStatus.PROCESSING ? "block" : "none",
          }}
        >
            <SendBillStep dataBill={dataBill} onSendBill={handleGetDataOnStep}/>
        </div>
       {currentStep !== SET_PACKAGE_STEP && <MDBRow
          style={{
            position: 'fixed',
            bottom: 0,
            left: '260px'
          }}
        >
          <MDBCol className="flex-center bd-highlight h-auto justify-content-start">
            <MDBBtn color="primary" className="mr-0" onClick={() => handleBackOrNextStep('backStep')}>
              <MDBIcon fa="true" icon="arrow-left" className="fa-fw mr-2" />
              <span>{window.I18N('back')}</span>
            </MDBBtn>
          </MDBCol>
        </MDBRow>}
       {currentStep !== SEND_BILL_STEP && <MDBRow
          style={{
            position: 'fixed',
            bottom: 0,
            right: '20px'
          }}
        >
          <MDBCol className="flex-center bd-highlight h-auto justify-content-end">
            <MDBBtn color="primary" className="mr-0" onClick={() => handleBackOrNextStep('nextStep')}>
              <span>{window.I18N('next')}</span>
              <MDBIcon fa="true" icon="arrow-right" className="fa-fw ml-2" />
            </MDBBtn>
          </MDBCol>
        </MDBRow>}
        {isEdit && WarningPrompt}
    </div>
  )
}

const mapStateToProps = state => ({
  language: state.language.value
})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBillPage)
