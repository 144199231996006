import React, { useEffect, useMemo, useRef, useState } from 'react'
import { MDBCol, MDBIcon, MDBRow } from 'mdbreact'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import Avatar from '@material-ui/core/Avatar'
import AvatarGroup from '@material-ui/lab/AvatarGroup'

import EventGRAPHQL from '../../../../../graphql/Event'

import Carousel from '../../../../components/Carousel'
import styles from './EventDetail.module.css'
import Button from '../../../../components/Button'
import Modal from '../../../../components/Modal'
import EventForm from '../EventForm'
import ReminderView from '../ReminderView'
import JoinOrLeaveEvent from '../JoinOrLeaveEvent'
import EventDetailLoading from '../Loading/EventDetailLoading'
import RejectAccessEvent from '../RejectAccessEvent/RejectAccessEvent'
import Image from '../../../../components/Image'

const PAGE_SIZE = 10
const DEFAULT_PAGE = 1
const PAGE_REJECTED_ACCESS = -1

const EventDetail = ({
    eventId,
    onBackEventList = () => {},
    refetchData = () => {},
    refetchDataForJoinOrLeave = () => {},
    language
}) => {
    const [dataEventDetail, setDataEventDetail] = useState({
        fanpageEvent: {
            _id: '',
            images: [],
            eventDate: '',
            name: '',
            description: '',
            countMember: 0,
            gopy: 0,
            createdBy: { nickname: '' },
            limitMember: 0,
            fanpage: '',
            reminders: [],
            status: 0
        },
        role: ''
    })
    const {
        fanpageEvent: {
            _id,
            images,
            eventDate,
            name,
            description,
            countMember,
            gopy,
            createdBy,
            limitMember,
            fanpage,
            reminders,
            status
        },
        role
    } = dataEventDetail
    const [dataMembers, setDataMembers] = useState({})
    const [isStillMore, setIsStillMore] = useState(false)
    const [isRejectedAccessEvent, setIsRejectedAccess] = useState(false)
    const dataEditEvent = useMemo(() => {
        if (!window.COMMON.isEmpty(dataEventDetail)) {
            return {
                _id,
                fanpage,
                images,
                eventDate,
                name,
                description,
                gopy,
                limitMember,
                reminders
            }
        }
    }, [dataEventDetail])

    const editEventFormRef = useRef()

    const checkPermissionFanpageEvent = async () => {
        try {
            const params = {
                eventId
            }
            const result = await window.COMMON.query(EventGRAPHQL.QUERY_CHECK_PERMISSION_FANPAGE_EVENT, params, false)
            setIsRejectedAccess(result.data.checkPermissionFanpageEvent)
        } catch (error) {
            window.COMMON.showErrorLogs(`EventDetail.checkPermissionFanpageEvent ::: ${error}`)
        }
    }

    const getDataDetailEvent = async () => {
        try {
            const params = {
                id: eventId
            }

            const [event, relationshipEvent, members] = await Promise.all([
                window.COMMON.query(EventGRAPHQL.QUERY_DETAIL_FANPAGE_EVENT, params, false),
                window.COMMON.query(EventGRAPHQL.QUERY_DETAIL_RELATIONSHIP_FANPAGE_EVENT, params, false),
                window.COMMON.query(
                    EventGRAPHQL.QUERY_USER_IN_FANPAGE_EVENT,
                    { ...params, page: DEFAULT_PAGE, pageSize: PAGE_SIZE },
                    false
                )
            ])
            if (event && relationshipEvent && members) {
                const dataEvent = event.data.getFanpageEventDetail
                const relationshipDetailEvent = relationshipEvent.data.findFanpageEventRelationshipDetail
                const membersEvent = members.data.findMembersInFanpageEvent.data
                const totalMembers = members.data.findMembersInFanpageEvent.total
                membersEvent.length >= totalMembers ? setIsStillMore(false) : setIsStillMore(true)
                setDataEventDetail({ fanpageEvent: dataEvent, ...relationshipDetailEvent })
                setDataMembers(membersEvent)
            }
        } catch (error) {
            window.COMMON.showErrorLogs(`EventDetail.getDataDetailEvent ::: ${error}`)
        }
    }

    const getMoreMembers = async () => {
        try {
            const params = {
                id: eventId,
                page: parseInt(dataMembers.length / PAGE_SIZE) + DEFAULT_PAGE,
                pageSize: PAGE_SIZE
            }
            const members = await window.COMMON.query(EventGRAPHQL.QUERY_USER_IN_FANPAGE_EVENT, params, false)
            const totalMembers = members.data.findMembersInFanpageEvent.total
            const membersEvent = members.data.findMembersInFanpageEvent.data
            if (!window.COMMON.isEmpty(membersEvent)) {
                setDataMembers((prev) => [...prev, ...membersEvent])
            }
            dataMembers.length + membersEvent.length >= totalMembers ? setIsStillMore(false) : setIsStillMore(true)
        } catch (error) {
            window.COMMON.showErrorLogs(`EventDetail.getMoreMembers ::: ${error}`)
        }
    }

    const openEditEventPopup = () => {
        window.COMMON.showModal('#modal-edit-event')
    }

    const closeEditEventPopup = () => {
        window.COMMON.hideModal('#modal-edit-event')
    }

    const handleCloseCreateEventPopup = () => {
        window.COMMON.showModal('#modal-confirm')
        window.confirmMethod = () => {
            closeEditEventPopup()
            editEventFormRef.current.resetFormForEdit()
        }
        window.closeConfirmMethod = () => {
            openEditEventPopup()
        }
    }

    const handleOpenListMemberPopup = () => {
        window.COMMON.showModal('#modal-list-member')
    }

    const handleCloseListMemberPopup = () => {
        window.COMMON.hideModal('#modal-list-member')
    }

    const editEventFanpage = () => {
        editEventFormRef.current.submitForm('edit', () => {
            getDataDetailEvent()
            closeEditEventPopup()
        })
    }

    const deleteEventFanpage = async () => {
        try {
            const params = {
                id: eventId
            }
            const result = await window.COMMON.mutation(EventGRAPHQL.MUTATION_DELETE_FANPAGE_EVENT, params)
            if (window.COMMON.checkResultData(result, window.CONSTANT.SUCCESSFULLY)) {
                refetchData()
            }
        } catch (error) {
            window.COMMON.showErrorLogs(`EventDetail.deleteEventFanpage ::: ${error}`)
        }
    }

    const handleEditEvent = () => {
        const isValidForm = editEventFormRef.current.validateForm()
        if (isValidForm) {
            window.COMMON.showModal('#modal-confirm')
            window.confirmMethod = editEventFanpage
        }
    }

    const handleDeleteEvent = () => {
        window.COMMON.showModal('#modal-delete')
        window.deleteMethod = deleteEventFanpage
    }

    useEffect(() => {
        checkPermissionFanpageEvent()
        getDataDetailEvent()
    }, [eventId])

    if (isRejectedAccessEvent === PAGE_REJECTED_ACCESS) {
        return <RejectAccessEvent onBackEventList={onBackEventList} />
    }

    return (
        <>
            {window.COMMON.isEmpty(dataMembers) || window.COMMON.isEmpty(dataEventDetail) ? (
                <div className="bg-white rounded-lg wow fadeIn" style={{ height: '100vh' }}>
                    <EventDetailLoading />
                </div>
            ) : (
                <div className="wow fadeIn">
                    <div style={{ height: '50vh' }}>
                        <Carousel
                            data={images.map((image) => ({ imageUrl: image }))}
                            imageVariant="rounded-top"
                            showControls={images.length > 1}
                        />
                    </div>
                    <div className="bg-white border-bottom">
                        {/* Day in calendar */}
                        <div className={`${styles['calendar-box']}`}>
                            <div className={`${styles['calendar-header']}`}></div>
                            <div className={`${styles['calendar-body']}`}>{`${new Date(+eventDate).getDate()}`}</div>
                        </div>
                        <div className={`${styles['event-date-time']}`}>
                            {window.COMMON.formatDateForEvent(eventDate, language.code)}
                        </div>
                        <div className={`${styles['event-name']}`}>{name}</div>
                    </div>
                    <div className="p-3 rounded-bottom bg-white d-flex align-items-center justify-content-between">
                        <div>
                            <Button variant="light" iconLeft="long-arrow-alt-left" onClick={onBackEventList}>
                                <span style={{ fontSize: '0.9rem', fontWeight: 500 }}>{window.I18N('btn_back')}</span>
                            </Button>
                        </div>
                        <div
                            className="d-flex align-items-center justify-content-between"
                            style={{ flexBasis: '40%', gap: '8px' }}
                        >
                            {role === 'OWNER' && (
                                <Button
                                    variant="light"
                                    iconLeft="pencil-alt"
                                    onClick={openEditEventPopup}
                                    disabled={
                                        status === 1 ||
                                        window.COMMON_DATE.compareWithNow(new Date(+eventDate), true) === -1
                                    }
                                >
                                    <span style={{ fontSize: '0.9rem', fontWeight: 500 }}>
                                        {window.I18N('btn_edit')}
                                    </span>
                                </Button>
                            )}
                            {role === 'OWNER' && (
                                <Button variant="light" iconLeft="trash-alt" onClick={handleDeleteEvent}>
                                    <span style={{ fontSize: '0.9rem', fontWeight: 500 }}>
                                        {window.I18N('delete_event')}
                                    </span>
                                </Button>
                            )}
                            {(role === null || role === 'MEMBER') && (
                                <JoinOrLeaveEvent
                                    eventDate={eventDate}
                                    eventId={eventId}
                                    status={role}
                                    refetchData={() => {
                                        getDataDetailEvent()
                                        refetchDataForJoinOrLeave()
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <MDBRow>
                        <MDBCol className="h-full mb-3" md="7">
                            <div className="h-100 mt-3 p-3 bg-white rounded-lg">
                                <h6 style={{ fontSize: '1rem', fontWeight: 700 }}>{window.I18N('detail')}</h6>
                                <div
                                    className="w-100 d-flex align-items-start justify-content-start"
                                    style={{ gap: '8px' }}
                                >
                                    <MDBIcon fas icon="users" style={{ fontSize: '20px', color: '#c7c8ca' }} />
                                    <div className="w-100">
                                        <div className="w-100 d-flex align-items-center justify-content-between">
                                            <span
                                                style={{ fontSize: '15px', fontWeight: 500 }}
                                            >{`${countMember} ${window.I18N('will_participate')}`}</span>
                                            <span
                                                style={{ color: '#0064d1', fontWeight: 500, cursor: 'pointer' }}
                                                onClick={handleOpenListMemberPopup}
                                            >
                                                {window.I18N('view_details')}
                                            </span>
                                        </div>
                                        {/* Avatar members */}
                                        <AvatarGroup className="mt-2" max={8}>
                                            {dataMembers?.map((item) => (
                                                <Avatar
                                                    key={item.user._id}
                                                    alt={item.user.nickname}
                                                    src={item.user.avatar}
                                                />
                                            ))}
                                        </AvatarGroup>
                                    </div>
                                </div>

                                <div
                                    className="mt-3 d-flex align-items-center justify-content-start"
                                    style={{ gap: '8px' }}
                                >
                                    <MDBIcon
                                        fas
                                        icon="bell"
                                        style={{ fontSize: '20px', width: '25px', color: '#c7c8ca' }}
                                    />
                                    <div
                                        className="d-flex align-items-start justify-content-start"
                                        style={{ gap: '4px' }}
                                    >
                                        <span style={{ fontSize: '15px', fontWeight: 500 }}>{`${
                                            status === 1
                                                ? window.I18N('event_has_started')
                                                : window.I18N('event_coming_soon')
                                        }`}</span>
                                    </div>
                                </div>

                                <div
                                    className="mt-3 d-flex align-items-center justify-content-start"
                                    style={{ gap: '8px' }}
                                >
                                    <MDBIcon
                                        fas
                                        icon="user-alt"
                                        style={{ fontSize: '20px', width: '25px', color: '#c7c8ca' }}
                                    />
                                    <div
                                        className="d-flex align-items-start justify-content-start"
                                        style={{ gap: '4px' }}
                                    >
                                        <span style={{ fontSize: '15px', fontWeight: 500 }}>{`${window.I18N(
                                            'organized_by'
                                        )} - ${createdBy.nickname}`}</span>
                                    </div>
                                </div>

                                {/* Fanpage */}
                                <div
                                    className="mt-3 d-flex align-items-start justify-content-start"
                                    style={{ gap: '8px' }}
                                >
                                    <MDBIcon
                                        fab
                                        icon="pagelines"
                                        style={{ fontSize: '20px', width: '25px', color: '#c7c8ca' }}
                                    />

                                    <div>
                                        <span style={{ fontSize: '15px', fontWeight: 500 }}>{`${window.I18N(
                                            'event_belongs'
                                        )} fanpage`}</span>
                                        <div className="mt-2 d-flex align-items-center" style={{ gap: '8px' }}>
                                            <Avatar
                                                alt={fanpage.name}
                                                src={fanpage.image}
                                                style={{ width: '28px', height: '28px' }}
                                            />
                                            <div className="d-flex flex-column align-items-start justify-content-start">
                                                <span className={`${styles['fanpage-name']} text-center`}>
                                                    {fanpage.name}
                                                </span>
                                                <span className={`${styles['fanpage-type']} text-center`}>
                                                    {fanpage.type}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ================================================================= */}
                                <div
                                    className="mt-3 d-flex align-items-center justify-content-start"
                                    style={{ gap: '8px' }}
                                >
                                    <div className={styles['event-description']}>{description}</div>
                                </div>
                            </div>
                        </MDBCol>
                        <MDBCol className="pl-0" md="5">
                            <div style={{ position: 'sticky', top: '80px' }}>
                                <div className="mt-3 bg-white p-3 rounded-lg">
                                    <h6 style={{ fontSize: '1rem', fontWeight: 700 }}>
                                        {window.I18N('limit_and_cost')}
                                    </h6>
                                    <div className="flex-center" style={{ gap: '12px', flexBasis: '50%' }}>
                                        <div className="p-3 d-flex align-items-center justify-content-center flex-column rounded-lg">
                                            <span style={{ fontSize: '1rem', fontWeight: 700 }}>{limitMember}</span>
                                            <span className="d-flex align-items-center justify-content-center text-center">
                                                {window.I18N('people_maximum')}
                                            </span>
                                        </div>
                                        <div className="p-3 d-flex align-items-center justify-content-center flex-column rounded-lg">
                                            <span style={{ fontSize: '1rem', fontWeight: 700 }}>
                                                {gopy === 0 ? window.I18N('free') : gopy}
                                            </span>
                                            <span className="d-flex align-items-center justify-content-center text-center">
                                                {window.I18N('gopy_needed')}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3 bg-white p-3 rounded-lg" style={{ minHeight: '200px' }}>
                                    <h6 style={{ fontSize: '1rem', fontWeight: 700 }}>
                                        {window.I18N('schedule_reminder')}
                                    </h6>
                                    {window.COMMON.isEmpty(reminders) ? (
                                        <div
                                            className="d-flex align-items-center flex-column justify-content-center"
                                            style={{ height: '130px' }}
                                        >
                                            <Image src={window.CONSTANT.CALENDAR} alt="" loading="lazy" height={40} />
                                            <span>{window.I18N('none_reminder')}</span>
                                        </div>
                                    ) : (
                                        <ReminderView
                                            eventDate={eventDate}
                                            reminders={reminders.sort((a, b) => b - a)}
                                        />
                                    )}
                                </div>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    {/* Edit event form */}
                    <Modal
                        id="modal-edit-event"
                        title={window.I18N('update_event')}
                        icon="pencil-alt"
                        saveBtn={window.I18N('btn_edit')}
                        saveBtnProps={{
                            style: {
                                width: '100%'
                            },
                            outline: false
                        }}
                        saveEvent={handleEditEvent}
                        styleBody={{
                            padding: '0px',
                            overflow: 'overlay',
                            overscollBehavior: 'contain'
                        }}
                        handleCloseModal={handleCloseCreateEventPopup}
                    >
                        {role === 'OWNER' && status === 0 && (
                            <EventForm
                                ref={editEventFormRef}
                                allowEdit
                                dataEdit={dataEditEvent}
                                fanpageId={fanpage._id}
                            />
                        )}
                    </Modal>

                    {/* List member */}
                    <Modal
                        id="modal-list-member"
                        title={window.I18N('list_member')}
                        hideFooter
                        styleBody={{
                            overflow: 'overlay',
                            overscollBehavior: 'contain'
                        }}
                        handleCloseModal={handleCloseListMemberPopup}
                    >
                        <InfiniteScroll
                            dataLength={dataMembers.length}
                            next={getMoreMembers}
                            hasMore={isStillMore}
                            loader={<div className="text-center py-2 ">Loading....</div>}
                        >
                            <div style={{ minHeight: '250px' }}>
                                {dataMembers?.map((item) => (
                                    <div key={item.user._id} className="mb-3 d-flex" style={{ gap: '8px' }}>
                                        <Avatar alt={item.user.nickname} src={item.user.avatar} />
                                        <div className="d-flex flex-column align-items-start justify-content-start">
                                            <span className="text-center">{item.user.nickname}</span>
                                            <span className="text-center">{item.user.email}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </InfiniteScroll>
                    </Modal>
                </div>
            )}
        </>
    )
}

const mapStateToProps = (state) => ({
    language: state.language.value
})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(EventDetail)
