import { MDBBtn, MDBCol, MDBIcon, MDBInputGroup, MDBRow } from 'mdbreact';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import CaseAssessmentGRAPHQL from '../../../../graphql/CaseAssessment';
import Common from '../../../../utils/Common';
import Constant from '../../../../utils/Constant';
import Pagination from '../../../components/Pagination';
import CaseAssessmentListItems from './CaseAssessmentListItems';
import Select from 'react-select';
import Filter from '../../../components/Filter';
import Panel from '../../../components/Panel';
import EditCaseForm from './EditCaseForm';
import MetadataGRAPHQL from '../../../../graphql/Metadata';
import AccountGRAPHQL from '../../../../graphql/Account';
import QuestionGRAPHQL from '../../../../graphql/Question';
import { connect } from 'react-redux';
import moment from 'moment';
import Modal from '../../../components/Modal';
import ItemPreviewCase from './ItemPreviewCase';
import mailTemlates from './mail-templates';
import MailGRAPHQL from '../../../../graphql/Mail';

const CaseAssessmentPage = (props) => {
  const CURRENT_USER_NAME = localStorage.getItem(Constant.FULL_NAME);

  const typeOptions = [
    { value: '1', label: window.I18N('periodic_assessment') },
    { value: '2', label: window.I18N('requested_assessment') }
  ];
  const periodOptions = [
    { value: '1', label: window.I18N('annually') },
    { value: '2', label: window.I18N('half_year') }
  ];
  const typeFilterOptions = [
    { value: null, label: window.I18N('all') },
    ...typeOptions
  ];
  const periodFilterOptions = [
    { value: null, label: window.I18N('all') },
    ...periodOptions
  ];
  const statusFilter = [
    { value: null, label: window.I18N('all_status') },
    { value: Constant.CASE_ASSESSMENT_STATUS.PENDING, label: window.I18N('case_pending') },
    { value: Constant.CASE_ASSESSMENT_STATUS.APPROVED, label: window.I18N('case_approved') },
    { value: Constant.CASE_ASSESSMENT_STATUS.REJECTED, label: window.I18N('case_rejected') },
    { value: Constant.CASE_ASSESSMENT_STATUS.RUNNING, label: window.I18N('case_running') },
    { value: Constant.CASE_ASSESSMENT_STATUS.CLOSED, label: window.I18N('case_closed') }
  ];

  const COMPANY_ID = localStorage.getItem(Constant.COMPANY_ID);

  const formRef = useRef();

  const [selectedCase, setSelectedCase] = useState({
    _id: '',
    name: '',
    code: '',
    goal: '',
    reason: '',
    approvalReminderDate: new Date(),

    influencer: null,
    nation: null,
    state: null,
    city: null,
    companyIndustry: null,

    companies: [],

    assessmentId: '',
    assessmentName: '',
    assessmentCode: '',
    assessmentDescription: '',
    assessmentStartDate: new Date(),
    assessmentEndDate: new Date(),
    assessmentType: '',
    assessmentPeriod: '',
    assessmentGroupQA: []
    // asssessmentAdvisors: []
  });

  const [inActionCase, setInActionCase] = useState({});
  const [pagination, setPagination] = useState({ total: 0, currentPage: 0, pageSize: 10 });
  const [data, setData] = useState([]);
  const history = useHistory();

  const [isInitialized, setIsInitialized] = useState(false);
  const [filter, setFilter] = useState({
    influencerCompany: null,
    type: { value: null, label: window.I18N('all') },
    period: { value: null, label: window.I18N('all') },
    status: { value: null, label: window.I18N('all_status') },
    text: ''
  });
  const [options, setOptions] = useState({
    industries: [],
    assessments: [],
    companies: [],
    nations: [],
    cities: [],
    states: [],
    companyTypes: [],
    influencers: [],
    groupQA: [],
    advisors: [],
    types: typeOptions,
    periods: periodOptions
  });

  const [assignedCompanyAdvisors, setAssignedCompanyAdvisors] = useState([]);

  const initializeData = async () => {
    const [metadataResult, locationResult] = await Promise.all([
      // Common.query(
      //   SelfAssessmentGRAPHQL.QUERY_RUNNING_SELF_ASSESSMENTS,
      //   { company: COMPANY_ID },
      //   true
      // ),
      Common.query(
        MetadataGRAPHQL.QUERY_SHORT_METADATA,
        { type: [Constant.METADATA_TYPE.COMPANY_INDUSTRY, Constant.METADATA_TYPE.COMPANY_TYPE] },
        true
      ),
      Common.getDataLocation()
    ]);
    
    // for some account don't have companyId(string): super_admin,...
    const [advisorResult, groupQAResult] = COMPANY_ID !== 'null' ? await Promise.all([ 
      Common.query(
        AccountGRAPHQL.QUERY_ACCOUNT_BY_INFLUENCER,
        { influencerIds: [COMPANY_ID]},
        true
      ),
      Common.query(
        QuestionGRAPHQL.QUERY_GROUP_QUESTION,
        { company: COMPANY_ID },
        true
      )
    ]) : [];

    await Common.getDataAllCompany();

    // let assessmentOptions = [];
    // if (Common.checkResultData(selfAssessmentResult)) {
    //   assessmentOptions = selfAssessmentResult.data.getRunningSelfAssessments;
    // }
    let companyIndustryOptions = [];
    let companyTypeOptions = [];
    if (Common.checkResultData(metadataResult)) {
      companyIndustryOptions = metadataResult.data.getMetadatas.filter(item => item.type === Constant.METADATA_TYPE.COMPANY_INDUSTRY);
      companyTypeOptions = metadataResult.data.getMetadatas.filter(item => item.type === Constant.METADATA_TYPE.COMPANY_TYPE);
    }
    let advisorOptions = [];
    if (advisorResult && Common.checkResultData(advisorResult)) {
      advisorOptions = advisorResult.data.getAccountByInfluencers;
    }
    let groupQAOptions = [];
    if (groupQAResult && Common.checkResultData(groupQAResult)) {
      const language = props.language.code || null;
      groupQAOptions = groupQAResult.data.getGroupQuestions.map((item, index) => ({
        ...item,
        createdDate: Common.formatDateSingleData(item, 'createdDate'),
        nameLanguage: Common.getValueWithLanguage(item, 'name', language),
        descriptionLanguage: Common.getValueWithLanguage(item, 'description', language),
        index
      }));
    }
    
    const nationOptions = locationResult || [];

    const dataAllCompanies = JSON.parse(localStorage.getItem(Constant.DATA_ALL_COMPANY)) || [];
    const companyOptions = dataAllCompanies.filter(item => item.type === Constant.COMPANY);
    const influencerOptions = dataAllCompanies.filter(item => item.type === Constant.INFLUENCER);
    
    setOptions(options => ({
      ...options,
      assessments: [],
      industries: companyIndustryOptions,
      companyTypes: companyTypeOptions,
      nations: nationOptions,
      cities: [],
      states: [],
      companies: companyOptions,
      influencers: influencerOptions,
      groupQA: groupQAOptions,
      advisors: advisorOptions
    }));
    
    if (Common.checkRoleIsSystem() && influencerOptions.length > 0) {
      filter.influencerCompany = influencerOptions[0];
      setFilter(filter);
    }
    
    // Fetch options
    await fetchCaseDataWithFilter(filter, pagination);
    setIsInitialized(true);
  };

  const fetchCaseDataWithFilter = async (filter, pagination) => {
    const userCaseResult = await Common.query(CaseAssessmentGRAPHQL.QUERY_CASE_ASSESSMENT_WITH_FILTER, {
      input: {
        companyId: filter.influencerCompany?._id?filter.influencerCompany._id:COMPANY_ID,
        searchText: filter.text,
        status: filter.status.value,
        assessmentType: filter.type.value,
        assessmentPeriod: filter.period.value,
        pageSize: pagination.pageSize,
        pageIndex: pagination.currentPage
      }
    }, true);

    if (Common.checkResultData(userCaseResult)) {
      const { data, total } = userCaseResult.data.queryCaseAssessments;
      setData(data);
      
      setPagination(pagination => ({
        ...pagination,
        total
      }));
    }
  };

  const getSelectedCaseCompaniesAdvisors = useCallback(async() => {
    try {
      const influencerIds = selectedCase.companies?.map(company => company?._id).filter(id => !!id);
      const result = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_BY_INFLUENCER, {
        influencerIds
      });
      const advisors = result.data.getAccountByInfluencers;
      setAssignedCompanyAdvisors(advisors);
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.getSelectedCompaniesAdvisors');
    }
  },[selectedCase.companies]);

  useEffect(() => {
    initializeData();
  }, []);
  useEffect(() => {
    if (isInitialized) {
      fetchCaseDataWithFilter(filter, pagination);
    }
  }, [pagination.currentPage, isInitialized]);
  useEffect(() => {
    if (isInitialized) {
      fetchCaseDataWithFilter(filter, pagination);
    }
  }, [filter.influencerCompany, isInitialized]);

  const changePage = (index) => {
    setPagination(pagination => ({
      ...pagination,
      currentPage: index
    }));
  };

  const handleDeleteCase = async (item) => {
    Common.showModal('#modal-delete');
    window.deleteMethod = async () => {
      if (item && item._id) {
        const result = await Common.mutation(CaseAssessmentGRAPHQL.MUTATION_DELETE_CASE_ASSESSMENT, { id: item._id });
        if (Common.checkResultData(result, Constant.MODE_DELETE)) {
          initializeData();
        }
      }
    };
  };

  const handleApproveCase = async (item) => {
    if (item && item._id) {
      const result = await Common.mutation(CaseAssessmentGRAPHQL.MUTATION_APPROVE_CASE_ASSESSMENT, { id: item._id });
      if (Common.checkResultData(result, Constant.MODE_APPROVE)) {
        initializeData();
        sendApprovedNotifyEmail(item);
      }
    }
  };

  const showModalRejectCase = (item) => {
    setInActionCase(item);
    Common.showModal('#modal-reject-case');
  };

  const handleRejectCase = async (caseId, rejectReason) => {
    if (caseId) {
      const result = await Common.mutation(CaseAssessmentGRAPHQL.MUTATION_REJECT_CASE_ASSESSMENT, { id: caseId, reason: rejectReason });
      if (Common.checkResultData(result, Constant.MODE_DECLINE)) {
        // reload data
        initializeData();
        // close modal
        Common.hideModal('#modal-reject-case');
        // send notification email
        sendRejectedNotifyEmail({
          ...inActionCase,
          rejectReason
        });
      }
    }
  };

  const handleSetSelectedCase = (item) => {
    setSelectedCase({
      _id: item._id,
      name: item.name,
      code: item.code,
      goal: item.goal,
      reason: item.reason,
      approvalReminderDate: moment(+item.approvalReminderDate).toDate(),
      status: item.status,

      influencer: item.influencerCompany,
      nation: item.nation ? { label: item.nation.name, value: item.nation._id, code: item.nation.code } : null,
      state: item.state ? { label: item.state.name, value: item.state._id, code: item.state.code } : null,
      city: item.city ? { label: item.city.name, value: item.city._id, code: item.city.code } : null,
      companyIndustry: item.companyIndustry,

      companies: item.assessment?.companies || [],
      advisors: item.advisors,

      assessmentId: item.assessment._id,
      assessmentName: item.assessment.name,
      assessmentCode: item.assessment.code,
      assessmentDescription: item.assessment.description,
      assessmentType: typeOptions.find(t => t.value === item.assessment.type),
      assessmentPeriod: periodOptions.find(p => p.value === item.assessment.period),
      assessmentStartDate: moment(+item.assessment.startDate).toDate(),
      assessmentEndDate: moment(+item.assessment.endDate).toDate(),
      assessmentGroupQA: item.assessment.groups
    });
  };

  const handleEditCase = (item) => {
    handleSetSelectedCase({
      ...item,
      status: Constant.CASE_ASSESSMENT_STATUS.PENDING
    });
    Common.showContainerSave();
  };

  const handlePreviewCase = (item) => {
    if (item._id !== selectedCase._id) {
      handleSetSelectedCase(item);
    }
    
    Common.showModal('#modal-preview-case');
  };

  const openRejectReasonModal = (item) => {
    setInActionCase(item);
    Common.showModal('#modal-reject-reason');
  };

  const handleSelectedCaseDataChange = (fieldName, value) => {
    if (fieldName) {
      setSelectedCase(data => ({
        ...data,
        [fieldName]: value
      }));
    }
  };

  const handleSelectedCaseDataSelectChange = (fieldName, value, isMultiple = false) => {
    if (fieldName) {
      setSelectedCase(data => ({
        ...data,
        [fieldName]: isMultiple ? value || [] : value
      }));
    }
  };

  const handleFilterChange = (filterField, value) => {
    setFilter(filter => ({
      ...filter,
      [filterField]: value
    }));
  };

  const onApplyFilter = () => {
    Common.hideFilter();
    fetchCaseDataWithFilter(filter, pagination);
  };

  const renderToolbarContainer = () => {
    return (
      <div className="container-btn mb-2 wow fadeInDown animated" data-wow-delay="0.5s">
        <div className="d-flex align-items-center justify-content-end">
          {!window.COMMON.checkRoleIsSystem()
            ? <div></div>
            : (
              <div className="bd-highlight mr-2" style={{ width: '300px' }}>
                <div className="input-group input-group-transparent">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="building" className="fa-fw" />
                    </span>
                  </div>
                  <Select
                    className="md-form m-0"
                    placeholder={window.I18N('choose_option')}
                    value={filter.influencerCompany}
                    options={options.influencers}
                    getOptionLabel={(opt) => opt.information?.name}
                    getOptionValue={(opt) => opt._id}
                    onChange={(event) => handleFilterChange('influencerCompany', event)} isSearchable
                  />
                </div>
              </div>
            )}
          <Filter id="container-filter" className="col-md-8">
            <MDBRow>
              <MDBCol sm="12" className="mb-2">
                <div className="mr-2 w-100">
                  <MDBInputGroup
                    type="text"
                    value={filter.text}
                    hint={window.I18N('search_name_code')}
                    onChange={(event) => handleFilterChange('text', event.target.value)}
                    maxLength="100" pattern="\S(.*\S)?" required containerClassName="mt-2"
                    append={
                      <span className="input-group-text cursor-pointer" id="btn-search">
                        <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                      </span>
                    }
                  />
                </div>
              </MDBCol>
              <MDBCol sm="4" className="mb-2 pr-sm-2">
                <strong>{window.I18N('status')}</strong>
                <div className="input-group input-group-transparent mt-1">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="filter" className="fa-fw" />
                    </span>
                  </div>
                  <Select
                    value={filter.status}
                    options={statusFilter}
                    className="md-form m-0"
                    onChange={(event) => handleFilterChange('status', event)} 
                    isSearchable
                  />
                </div>
              </MDBCol>
              <MDBCol sm="4" className="mb-2 pl-sm-2 pr-sm-2">
                <strong>{window.I18N('type')}</strong>
                <div className="input-group input-group-transparent mt-1">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="filter" className="fa-fw" />
                    </span>
                  </div>
                  <Select
                    value={filter.type}
                    options={typeFilterOptions}
                    className="md-form m-0"
                    onChange={(event) => handleFilterChange('type', event)}
                    isSearchable
                  />
                </div>
              </MDBCol>
              <MDBCol sm="4" className="mb-2 pl-sm-2">
                <strong>{window.I18N('period')}</strong>
                <div className="input-group input-group-transparent mt-1">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="filter" className="fa-fw" />
                    </span>
                  </div>
                  <Select
                    value={filter.period}
                    options={periodFilterOptions}
                    className="md-form m-0"
                    onChange={(event) => handleFilterChange('period', event)}
                    isSearchable
                  />
                </div>
              </MDBCol>
              <MDBCol sm="12 text-right">
                <MDBBtn color="primary" onClick={onApplyFilter}>
                  <MDBIcon fa="true" icon="sync-alt" className="fa-fw" /> {window.I18N('apply')}
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </Filter>
          <MDBBtn color="primary" className="mr-0" onClick={() => {
            history.push('/new-case');
          }}>
            <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
          </MDBBtn>
        </div>
      </div>
    );
  };

  const handleSubmitEditForm = async () => {
    if (!formRef.current.validateForm(selectedCase)) {
      return;
    }

    const caseInput = {
      _id: selectedCase._id,
      name: selectedCase.name,
      code: selectedCase.code,
      goal: selectedCase.goal,
      reason: selectedCase.reason,
      approvalReminderDate: selectedCase.approvalReminderDate.toISOString(),
      companyIndustry: selectedCase.companyIndustry ? selectedCase.companyIndustry._id : null,
      nation: selectedCase.nation ? selectedCase.nation.value : null,
      state: selectedCase.state ? selectedCase.state.value : null,
      city: selectedCase.city ? selectedCase.city.value : null,

      advisors: selectedCase.advisors ? selectedCase.advisors.map(item => item._id) : [],
      influencerCompany: selectedCase.influencer ? selectedCase.influencer._id : COMPANY_ID,
      status: selectedCase.status
    };

    const assessmentInput = {
      _id: selectedCase.assessmentId,
      name: selectedCase.assessmentName,
      code: selectedCase.assessmentCode,
      description: selectedCase.assessmentDescription,
      type: selectedCase.assessmentType.value,
      period: selectedCase.assessmentPeriod.value,
      companies: selectedCase.companies ? selectedCase.companies.map(item => item._id) : [],
      groups: selectedCase.assessmentGroupQA ? selectedCase.assessmentGroupQA.map(item => item._id) : [],
      company: selectedCase.influencer ? selectedCase.influencer._id : COMPANY_ID,
      startDate: selectedCase.assessmentStartDate.toISOString(),
      endDate: selectedCase.assessmentEndDate.toISOString()
    };

    if (selectedCase.rejectReason) {
      caseInput.rejectReason = null;
    }

    const result = await Common.mutation(CaseAssessmentGRAPHQL.MUTATION_EDIT_CASE_ASSESSMENT, { caseInput, assessmentInput }, true);
    if (Common.checkResultData(result, Constant.MODE_UPDATE)) {
      Common.backContainerData();
      initializeData();
    }
  };

  const renderEditContainer = () => {
    return (
      <Panel id="container-save" style={{ display: 'none' }} title={window.I18N('create_edit_data')} icon="question-circle">
        <div className="container-btn wow fadeInDown animated" data-wow-delay="0.5s">
          <MDBBtn color="" onClick={Common.backContainerData}>
            <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('btn_back')}
          </MDBBtn>
          <MDBBtn color="primary" type="button" onClick={handleSubmitEditForm}>
            <MDBIcon fa="true" icon="save" className="fa-fw" /> {window.I18N('btn_save')}
          </MDBBtn>
        </div>
        <div className="container-top wow fadeInUp animated" data-wow-delay="0.5s">
          <EditCaseForm
            language={props.language}
            ref={formRef}
            formData={selectedCase}
            options={{
              ...options,
              advisors: [...options.advisors, ...assignedCompanyAdvisors]
            }}
            handleChange={handleSelectedCaseDataChange}
            handleSelect={handleSelectedCaseDataSelectChange}
          />
        </div>
      </Panel>
    );
  };

  const generateMailBody = (template, variables) => {
    let mailBody = template;
    for (const [key, val] of Object.entries(variables)) {
      const varTemplate = `{{${key}}}`;
      mailBody = mailBody.replaceAll(varTemplate, val);
    }

    return mailBody.replace(/\r?\n|\r/, '');
  };

  const sendApprovedNotifyEmail = useCallback(async (caseInfo) => {
    const { name, createdBy } = caseInfo;
    const caseOwnerEmail = createdBy?.email;

    if (!caseOwnerEmail) {
      return ;
    }

    const body = generateMailBody(mailTemlates.approvedCase, {
      name,
      approvedBy: CURRENT_USER_NAME
    });

    const input = {
      receiver: [caseOwnerEmail],
      subject: window.I18N('case_approved_email_subject'),
      body
    };
    await Common.mutation(MailGRAPHQL.MUTATION_SEND_GRID_MAIL, { input }, false);
  }, []);

  const sendRejectedNotifyEmail = useCallback(async (caseInfo) => {
    const { name, createdBy} = caseInfo;
    const caseOwnerEmail = createdBy?.email;

    if (!caseOwnerEmail) {
      return ;
    }

    const body = generateMailBody(mailTemlates.rejectCase, {
      name,
      rejectedBy: CURRENT_USER_NAME,
      reason: caseInfo.rejectReason
    });

    const input = {
      receiver: [caseOwnerEmail],
      subject: window.I18N('case_rejected_email_subject'),
      body
    };

    await Common.mutation(MailGRAPHQL.MUTATION_SEND_GRID_MAIL, { input }, false);
  }, []);

  useEffect(() => {
    getSelectedCaseCompaniesAdvisors();
  }, [selectedCase.companies]);

  const renderPreviewModal = () => {
    return (
      <Modal className="modal-xl" id="modal-preview-case" title={window.I18N('preview_case_assessment_title')} hideFooter>
        <MDBRow>
          <MDBCol>
            <ItemPreviewCase language={props.language}  formData={selectedCase}  options={options} />
          </MDBCol>
        </MDBRow>
      </Modal>
    );
  };

  return (
    <div className="container-row">
      <MDBRow id="container-data" className="wow fadeIn animated" data-wow-delays="1s">
        <MDBCol>
          <ul className="list-group">
            {/* Top bar */}
            <li className="list-group-item">
              {renderToolbarContainer()}
            </li>
            {/* Case items */}
            <CaseAssessmentListItems
              data={data}
              options={options}
              handleDelete={handleDeleteCase}
              handleApprove={handleApproveCase}
              handleReject={showModalRejectCase}
              handleEdit={handleEditCase}
              handlePreview={handlePreviewCase}
              handleViewRejectReason={openRejectReasonModal}
            />
            <li className='list-group-item'>
              <Pagination total={pagination.total} currentPage={pagination.currentPage} pageSize={pagination.pageSize} changePage={changePage} />
            </li>
          </ul>
        </MDBCol>
      </MDBRow>
      {renderEditContainer()}
      {renderPreviewModal()}
      <ModalRejectCase data={inActionCase} onReject={handleRejectCase} />
      <ModalRejectReason data={inActionCase}  />
    </div>
  );
};

const ModalRejectCase = ({ data, onReject }) => {
  const { name, _id } = data || {};
  const [reason, setReason] = useState('');

  const handleReject = () => {
    if (typeof onReject === 'function') {
      onReject(_id, reason);
    };
  };
  
  return (
    <Modal className="modal-xl" id="modal-reject-case" title={name} saveEvent={handleReject} >
      <MDBRow>
        <MDBCol>
          <div className="wow fadeIn animated" data-wow-delay="0.5s">
            <div className="form-group">
              <label htmlFor="rejectReason">Reject Reason:</label>
              <textarea value={reason} onChange={ev => setReason(ev.target.value)} className="form-control rounded-0" id="rejectReason" rows="5"></textarea>
            </div>
          </div>
        </MDBCol>
      </MDBRow>
    </Modal> 
  );
};

const ModalRejectReason = ({ data }) => {
  const { rejectReason, name } = data || {};
  
  return (
    <Modal className="modal-xl" id="modal-reject-reason" title={name} hideFooter>
      <MDBRow>
        <MDBCol>
          <div className="wow fadeIn animated" data-wow-delay="0.5s">
            <div className="form-group">
              <label htmlFor="rejectReason">Reject Reason:</label>
              <textarea value={rejectReason} disabled className="form-control rounded-0" id="rejectReason" rows="5"></textarea>
            </div>
          </div>
        </MDBCol>
      </MDBRow>
    </Modal> 
  );
};

const mapStateToProps = (state) => ({
  language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CaseAssessmentPage);
