import React, { useEffect, useRef, useState } from 'react'

import CustomChart from '../../../components/InsightChart/components/CustomChart'
import TimesFrameStatisticPopup from './TimesFrameStatisticPopup'

const paletteColor = [
    'rgba(255, 99, 132, 0.6)',
    'rgba(54, 162, 235, 0.6)',
    'rgba(75, 192, 192, 0.6)',
    'rgba(153, 102, 255, 0.6)',
    'rgba(255, 159, 64, 0.6)',
    'rgba(255, 206, 86, 0.6)',
    'rgba(201, 203, 207, 0.6)',
    'rgba(231, 233, 237, 0.6)',
    'rgba(255, 99, 71, 0.6)',
    'rgba(144, 238, 144, 0.6)',
    'rgba(173, 216, 230, 0.6)',
    'rgba(255, 20, 147, 0.6)',
    'rgba(0, 191, 255, 0.6)',
    'rgba(138, 43, 226, 0.6)',
    'rgba(34, 139, 34, 0.6)',
    'rgba(210, 105, 30, 0.6)',
    'rgba(218, 165, 32, 0.6)',
    'rgba(127, 255, 212, 0.6)',
    'rgba(0, 128, 128, 0.6)',
    'rgba(220, 20, 60, 0.6)',
    'rgba(255, 182, 193, 0.6)',
    'rgba(0, 255, 127, 0.6)',
    'rgba(233, 150, 122, 0.6)',
    'rgba(70, 130, 180, 0.6)',
    'rgba(255, 140, 0, 0.6)',
    'rgba(240, 128, 128, 0.6)',
    'rgba(176, 224, 230, 0.6)',
    'rgba(124, 252, 0, 0.6)',
    'rgba(255, 228, 181, 0.6)',
    'rgba(135, 206, 235, 0.6)',
    'rgba(255, 248, 220, 0.6)',
    'rgba(72, 61, 139, 0.6)',
    'rgba(60, 179, 113, 0.6)',
    'rgba(255, 215, 0, 0.6)',
    'rgba(199, 21, 133, 0.6)',
    'rgba(30, 144, 255, 0.6)',
    'rgba(100, 149, 237, 0.6)',
    'rgba(32, 178, 170, 0.6)',
    'rgba(255, 105, 180, 0.6)',
    'rgba(186, 85, 211, 0.6)',
    'rgba(0, 0, 255, 0.6)',
    'rgba(139, 69, 19, 0.6)',
    'rgba(85, 107, 47, 0.6)',
    'rgba(46, 139, 87, 0.6)',
    'rgba(0, 206, 209, 0.6)',
    'rgba(128, 128, 128, 0.6)',
    'rgba(255, 228, 225, 0.6)',
    'rgba(240, 255, 240, 0.6)',
    'rgba(250, 128, 114, 0.6)',
    'rgba(152, 251, 152, 0.6)'
]
const TimesFrameStatisticChart = ({ data = {}, dataPopup = {} }) => {
    const [dataChart, setDataChart] = useState({ dataSet: [], labels: [] })
    const chartRef = useRef()

    const sortByTime = (data) =>
        Object.fromEntries(
            Object.entries(data).sort((a, b) => a[0].split(' - ')[1].localeCompare(b[0].split(' - ')[1]))
        )

    const sortDates = (dates) =>
        dates
            .map((date) => new Date(date.split('/').reverse().join('-')))
            .sort((a, b) => a - b)
            .map((date) => date.toISOString().slice(0, 10).split('-').reverse().join('/'))

    const countTimeSlotsByDay = (groupedData, slots) => {
        const result = {}

        for (const key in groupedData) {
            // Split the key into date and time slot
            const [date, slot] = key.split(' - ')

            // Extract the time slot from the string using regex
            const timeSlot = slot.match(/\((.*?)\)/)[1]

            // If the date does not exist in the result, initialize an object with the time slots and set their initial values to 0
            if (!result[date]) {
                result[date] = slots.reduce((acc, slot) => {
                    acc[slot] = 0
                    return acc
                }, {})
            }

            // If the time slot exists in the sub-object for the date, increment the count by the value from groupedData
            if (result[date].hasOwnProperty(`(${timeSlot})`)) {
                result[date][`(${timeSlot})`] += groupedData[key]
            }
        }

        return result
    }

    const handleDataForStatisticsChart = (dataTimesFrame) => {
        const sortDataTimesFrame = sortByTime(dataTimesFrame)
        const dataLabelsForChart = Object.keys(sortDataTimesFrame).reduce(
            (acc, el) => {
                const [date, timesFrame] = el.split(' - ')
                return {
                    datasetsLabel: [...acc.datasetsLabel, date],
                    labels: [...acc.labels, timesFrame]
                }
            },
            { datasetsLabel: [], labels: [] }
        )

        const removeDuplicateLabelsForChart = {
            datasetsLabel: window.COMMON.removeDuplicateElements(dataLabelsForChart.datasetsLabel),
            labels: window.COMMON.removeDuplicateElements(dataLabelsForChart.labels)
        }

        const dataGroupTimesWithDays = countTimeSlotsByDay(sortDataTimesFrame, removeDuplicateLabelsForChart.labels)
        const data = {
            labels: removeDuplicateLabelsForChart.labels,
            datasets: sortDates(removeDuplicateLabelsForChart.datasetsLabel).map((day, index) => {
                return {
                    label: day,
                    data: Object.values(dataGroupTimesWithDays[day]),
                    borderColor: paletteColor[index],
                    backgroundColor: 'transparent',
                    lineTension: 0.2
                }
            })
        }
        return data
    }

    const createInnerHTMLPopup = ({ hoverLabelIndex, indexDatasetPopup, hoverDatasetIndex }) => {
        return (
            <TimesFrameStatisticPopup
                dataPopup={dataPopup}
                dataChart={dataChart}
                hoverLabelIndex={hoverLabelIndex}
                indexDatasetPopup={indexDatasetPopup}
                hoverDatasetIndex={hoverDatasetIndex}
                onSetIndexDatasetPopup={chartRef?.current?.handleSetIndexDatasetPopup}
            />
        )
    }

    useEffect(() => {
        if (!window.COMMON.isEmpty(data)) {
            const dataForStatisticTimesChart = handleDataForStatisticsChart(data)
            setDataChart(dataForStatisticTimesChart)
        }
    }, [data])

    return (
        <div
            className="mt-3 d-flex align-items-center justify-content-center rounded-lg"
            style={{ position: 'relative' }}
        >
            <CustomChart
                typeChart="Line"
                dataChart={dataChart}
                createInnerHTMLPopup={createInnerHTMLPopup}
                displayLegend={true}
                ref={chartRef}
                options={{
                    title: {
                        display: true,
                        text: window.I18N('times_frame_chart')
                    }
                }}
            />
        </div>
    )
}

export default TimesFrameStatisticChart
