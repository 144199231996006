import React, { useState, useCallback, useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { MDBRow, MDBCol, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';

import Panel from '../../components/Panel';
import Table from '../../components/Table';
import Modal from '../../components/Modal';

import ReasonGRAPHQL from '../../../graphql/Reason';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';

function ReasonPage(props) {
  const { trackEvent } = useTracking();

  const config = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('code'), '10%', '', '', 'code', 'TEXT'),
    window.COMMON.createCol(window.I18N('content'), '25%', '', '', 'contentLanguage', 'TEXT'),
    window.COMMON.createCol(window.I18N('index'), '10%', '', '', 'index', 'TEXT'),
    window.COMMON.createCol(window.I18N('btn_active'), '10%', '', '', 'isActive', 'STATUS'),
    window.COMMON.createCol(window.I18N('created_by'), '20%', '', '', 'createdBy.name', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '10%', '', '', '3', 'BUTTON')
  ];
  const type = [
    { value: 'REPORT_POST', label: window.I18N('report_post') }
  ];
  const language = useRef();
  const [filter, setFilter] = useState({ type: { value: 'REPORT_POST', label: window.I18N('report_post') } });
  const [object, setObject] = useState({ index: -1 });
  const [data, setData] = useState([]);
  const [dataSave, setDataSave] = useState({ isActive: true });

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {
      getDataReason();
    }, 100);
    // eslint-disable-next-line
  }, [props.language]);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleFilterSelect = (event) => {
    filter.type = event;
    setFilter({ ...filter });
    getDataReason();
  };

  const handleChange = (event) => {
    if (event.target.className.indexOf('input-number') > -1) {
      dataSave[event.target.name] = parseInt(event.target.value);
    } else if (event.target.type === 'checkbox') {
      dataSave[event.target.name] = event.target.checked;
    } else {
      dataSave[event.target.name] = event.target.value;
    }
    setDataSave({ ...dataSave });
  };

  const handleChangeWithLanguage = (event) => {
    window.COMMON.setValueWithLanguage(dataSave, event.target.name, language.current, event.target.value);
    setDataSave({ ...dataSave });
  };

  const getDataReason = async () => {
    try {
      window.resetDataTable('table-data');
      const params = {
        type: filter.type.value,
        isActive: [true, false]
      };
      const result = await window.COMMON.query(ReasonGRAPHQL.QUERY_REASON, params);
      if (result && result.data) {
        const data = window.COMMON.formatDate(result.data.getReasons, 'createdDate');
        data.forEach(item => {
          item.contentLanguage = window.COMMON.getValueWithLanguage(item, 'content', language.current);
        });
        setData(data);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('ReasonPage.getDataReason');
    }
    window.initDataTable('table-data');
  };

  const openReasonDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-reason');
    object.index = index;
    const dataSave = { type: filter.type.value };
    if (index === -1) {
      dataSave.content = [];
      dataSave.code = window.COMMON.generateCode('R');
      dataSave.index = 0;
      dataSave.isActive = true;
    } else {
      const obj = data[index];
      dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
      dataSave.content = window.COMMON.getDataWithLanguage(obj, 'content');
      dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
      dataSave.index = window.COMMON.getValueFromAttr(obj, 'index');
      dataSave.isActive = window.COMMON.getValueFromAttr(obj, 'isActive');
    }
    setObject(object);
    setDataSave({ ...dataSave });
  }, [object, data, filter]);

  // eslint-disable-next-line
  const openDeleteReasonDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteReason;
  });

  const saveReason = async (event) => {
    try {
      if (!window.COMMON.checkFormValidation('form-reason', event)) {
        return;
      }
      const params = {
        input: dataSave
      };
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(ReasonGRAPHQL.MUTATION_ADD_REASON, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataReason();
		  createTrackingEvent(eventName.ADD_REASON);
        }
      } else {
        const result = await window.COMMON.mutation(ReasonGRAPHQL.MUTATION_EDIT_REASON, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataReason();
		  createTrackingEvent(eventName.EDIT_REASON);
        }
      }
      window.COMMON.hideModal('#modal-reason');
    } catch (error) {
      window.COMMON.showErrorLogs('ReasonPage.saveReason');
    }
  };

  const deleteReason = async () => {
    try {
      const params = {
        id: data[object.index]._id
      };
      const result = await window.COMMON.mutation(ReasonGRAPHQL.MUTATION_DEL_REASON, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataReason();
        createTrackingEvent(eventName.DEL_REASON);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('ReasonPage.deleteReason');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" icon="list-alt">
        <div className="container-top d-flex align-items-center justify-content-end wow fadeInDown animated" data-wow-delay="0.5s">
          <div className="bd-highlight mr-2" style={{ width: '300px' }}>
            <div className="input-group input-group-transparent">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <MDBIcon fa="true" icon="filter" className="fa-fw" />
                </span>
              </div>
              <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.type} options={type} onChange={handleFilterSelect} isSearchable/>
            </div>
          </div>
          <MDBBtn color="primary" className="mr-0" onClick={() => openReasonDialog(-1)}>
            <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
          </MDBBtn>
        </div>
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <Table id="table-data" className="table" config={config} data={data} handleRowEditClick={openReasonDialog} handleRowDelClick={openDeleteReasonDialog}></Table>
        </div>
      </Panel>

      <Modal id="modal-reason" title={window.I18N('create_update_data')} saveEvent={saveReason}>
        <form id="form-reason" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <MDBInput outline containerClass="mt-0" type="text" label={window.I18N('code') + ' *'} name="code" value={dataSave.code} onChange={handleChange} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="text" label={window.I18N('content') + ' *'} name="content" value={window.COMMON.getValueWithLanguage(dataSave, 'content', language.current)} onChange={handleChangeWithLanguage} maxLength="200" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="number" className="input-number" label={window.I18N('index') + ' *'} value={dataSave.index} name="index" onChange={handleChange} maxLength="5" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput type="checkbox" id="ck-active-reason" name="isActive" label={window.I18N('btn_active')} containerClass="p-0" filled checked={dataSave.isActive} onChange={handleChange}></MDBInput>
              <label className="badge badge-info p-2 mt-3">{type.label}</label>
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};
    
export default connect(mapStateToProps, mapDispatchToProps)(ReasonPage);