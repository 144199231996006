import styled from 'styled-components';



export const CustomCircleAvatar = styled.div`
    min-width: ${({size}) => size || '32px'};
    min-height: ${({size}) => size || '32px'};
    max-width: ${({size}) => size || '32px'};
    max-height: ${({size}) => size || '32px'};
    border: 1px solid transparent;
    border-radius: 100%;
    position: relative;
    & img{
        width: ${({size}) => size || '32px'};
        height: ${({size}) => size || '32px'};
        border: 1px solid transparent;
        border-radius: 100%;
    }
    & .dot{
        width: ${({dotSize}) => dotSize || '12px'};
        height: ${({dotSize}) => dotSize || '12px'};
        background-color: green;
        border-radius: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
    }
    
`;