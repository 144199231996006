import gql from 'graphql-tag';

const QUERY_ADVICE = gql`
  query($type: String!) {
    getAdvices(type: $type) {
      _id
      email
      title
      content
      answer
      status
      createdBy {
        avatar
        nickname
      }
      updatedBy {
        avatar
        nickname
      }
      createdDate
      updatedDate
    }
  }
`;

const MUTATION_ANSWER_ADVIDE = gql`
  mutation($input: AdvisorInput!) {
    answerAdvice(input: $input)
  }
`;

const AdviceGRAPHQL = {
  QUERY_ADVICE,
  MUTATION_ANSWER_ADVIDE
};

export default AdviceGRAPHQL;