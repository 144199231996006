import React, { useState, useEffect } from 'react'
import BillHistoryHeader from './BillHistoryHeader'
import BillItem from '../BillItem'
import Pagination from '../../../../components/Pagination'

const DEFAULT_PAGE = 1

const BillHistory = ({ dataCoin, refechDataCoin = () => {} }) => {
  const [dataBills, setDataBills] = useState([])
  const [pagination, setPagination] = useState({total: 1, pageSize: 10, currentPage: 0})
  const [filters, setFilters] = useState({})

  const getListBills = async (passParams = {}) => {
    try {
      window.showLoading()
      const result = await window.COMMON.httpRequest({
          url: `${process.env.REACT_APP_URL_PAYMENT_API}/bills`,
          method: 'GET',
          input: {
            params: {
              page: DEFAULT_PAGE,
              pageSize: pagination.pageSize,
              sort: 'desc',
              ...passParams        
            }
          },
          requireToken: true,
          handleError: () => {
              window.COMMON.showErrorLogs('BillHistory.getBills');
          }
      })
      window.hideLoading()
      setDataBills(result.elements.filter(bill => !bill.isDeleted))
      setPagination((prev) => ({...prev, total: result.total }) )
      } catch (error) {
        window.COMMON.showErrorLogs('BillHistory.getBills');
      }
  }

  useEffect(() => {
    if(!window.COMMON.isEmpty(filters)){
      handleFilterBills(DEFAULT_PAGE)
    }
  },[filters])

  const handleChangePage = (index) => {
    if(index === pagination.currentPage){
      return
    }
    handleFilterBills(index + 1)
    setPagination((prev) => ({...prev, currentPage: index}))
  }

  const handleFilterBills = (page = null) => {
    let dataFilters = {}
    for(let key in filters){
      if(filters[key] !== window.I18N('all') && filters[key] !== ''){
        dataFilters[key] = filters[key]
      }
    }
    getListBills({...dataFilters, ...(page ? { page } : {})})
    page && setPagination((prev) => ({...prev, currentPage: page - 1}))
  }

  const handleGetFilters = (dataFilters) => {
    setFilters(dataFilters)
  }

  return (
    <div className="p-3 bg-white rounded-lg">
       <BillHistoryHeader onFilters={handleGetFilters}/>
       { window.COMMON.isEmpty(dataBills) ? 
          <div className='w-full text-center'>No Bills</div>
        : <div className='mt-2'>
          {
            dataBills.map((bill) => (
              <div key={bill._id} className="wow fadeIn">
                <BillItem data={bill} refetchDataBills={() => handleFilterBills(DEFAULT_PAGE)} />
              </div>
            ))
          }
          <Pagination 
              total={pagination.total} 
              pageSize={pagination.pageSize} 
              currentPage={pagination.currentPage} 
              changePage={handleChangePage} 
            />
       </div>}
    </div>
  )
}

export default BillHistory
