import QuestionGRAPHQL from '../../../../graphql/Question';

export default function useGetSurveyQAData({ groupQA }) {
  const getQAData = async (groupId, callback) => {
    try {
      const params = {
        groupId
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_QUESTION, params);
      const data = [];
      if (result && result.data) {
        result.data.getQuestions.forEach((obj, index) => {
          let newCode = window.COMMON.generateCode('Q');
          newCode = newCode.substring(0, newCode.length - 1) + `${(index + 1)}`;
          data.push({
            uuid: window.COMMON.getValueFromAttr(obj, '_id', null),
            group: groupQA._id,
            question: {
              _id: null,
              question: window.COMMON.getDataWithLanguage(obj, 'question'),
              introduction: window.COMMON.getDataWithLanguageWithDefaultValue(obj, 'introduction', null),
              code: newCode,
              important: window.COMMON.getValueFromAttr(obj, 'important', false),
              category: window.COMMON.getDataWithLanguageWithDefaultValue(obj, 'category', null),
              standardLowOfCategory: window.COMMON.getValueFromAttr(obj, 'standardLowOfCategory', null),
              standardOfCategory: window.COMMON.getValueFromAttr(obj, 'standardOfCategory', null),
              recommend: window.COMMON.getValueFromAttr(obj, 'recommend', null),
              createdDate: window.COMMON.getValueFromAttr(obj, 'createdDate', null),
              type: window.COMMON.getValueFromAttr(obj, 'type')
            },
            answers: []
          });
        });
      }

      getDataAnswerQuestion(groupId, data, callback);
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionPage.getDataQuestion');
    }
  };

  const getDataAnswerQuestion = async (groupId, dataQuestion, callback) => {

    try {
      const params = {
        groupId
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_ANSWER_QUESTION, params);

      if (result && result.data) {
        const newDataQuestion = dataQuestion.map((qa, qaIndex) => {
          let answers = result.data.getAnswerQuestions.filter((item) => item.question === qa.uuid);
          answers = answers.map((item, index) => {
            let newCode = window.COMMON.generateCode('A');
            newCode = `${(index + 1)}` + newCode.substring(0, newCode.length - 2) + `${(qaIndex + 1)}` + `${(index + 1)}`;
            return {
              content: window.COMMON.getDataWithLanguage(item, 'content'),
              score: window.COMMON.getValueFromAttr(item, 'score', null),
              code: newCode,
              parentCode: null,
              question: null
            };
          });
          return {
            ...qa,
            answers
          };
        });

        callback(newDataQuestion);
      }
    } catch (error) {
      console.log(error);
      window.COMMON.showErrorLogs('QuestionPage.getDataAnswerQuestion');
    }
  };
  return {
    getQAData
  };
}