import gql from 'graphql-tag';

const QUERY_ACCOUNT_BALANCE_BY_USERID = gql`
  query($userId: String!) {
    getAccountBalanceByUserId(userId: $userId) {
      _id
      content
      point
      type
      company
      union
      influencer
      createdDate
    }
  }
`;

const QUERY_ACTIVITY = gql`
  query($input: HistoryAccountBalanceFilter!) {
    getActivity(input: $input) {
      _id
      type
      company
      union
      influencer
      createdDate
    }
  }
`;

const QUERY_ACTIVITY_BY_GOVERMENT = gql`
  query($input: HistoryAccountBalanceFilter!) {
    getActivityByGoverment(input: $input) {
      _id
      count
    }
  }
`;

const QUERY_COUNT_ACCOUNT_BALANCE = gql`
  query($input: HistoryAccountBalanceFilter!) {
    countHistoryAccountBalance(input: $input)
  }
`;

const QUERY_ACTIVITY_INSIGHT = gql`
  query($input: HistoryAccountBalanceFilter!) {
    getActivityDataInsight(input: $input) {
      _id
      type
      company
      union
      influencer
      createdDate
      createdBy {
        _id
        name
        accountType
        birthday
        gender
        state{
            name
        }
        city{
            name
        }
      }
    }
  }
`;

const QUERY_TOTAL_GOPY = gql`
  query {
    countTotalGopy
  }
`;

const QUERY_ADDED_GOPY_BALANCE = gql`
  query($input: HistoryGopyFilter!) {
    getAddedGopyBalance(input: $input) {
      totalGopy
      dataTotal {
        _id
        count
      }
    }
  }
`;

const QUERY_SUBTRACTED_GOPY_BALANCE = gql`
  query($input: HistoryGopyFilter!) {
    getSubtractedGopyBalance(input: $input) {
      totalSubtractGopy
      dataSubtractTotal {
        _id
        count
      }
    }
  }
`;

const QUERY_GOPY_BALANCE_WITH_TYPES = gql`
  query($input: HistoryGopyFilter!) {
    getGopyBalanceWithTypes(input: $input) {
      _id
      count
    }
  }
`;

const AccountBalanceGRAPHQL = {
  QUERY_ACCOUNT_BALANCE_BY_USERID,
  QUERY_ACTIVITY,
  QUERY_ACTIVITY_BY_GOVERMENT,
  QUERY_COUNT_ACCOUNT_BALANCE,
  QUERY_ACTIVITY_INSIGHT,
  QUERY_TOTAL_GOPY,
  QUERY_ADDED_GOPY_BALANCE,
  QUERY_SUBTRACTED_GOPY_BALANCE,
  QUERY_GOPY_BALANCE_WITH_TYPES
};


export default AccountBalanceGRAPHQL;
