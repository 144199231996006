import React, { useState, useLayoutEffect } from 'react';
import { MDBIcon } from 'mdbreact';

const RadioBox = React.memo(function RadioBox (props) {
  const [value, setValue] = useState(null);

  useLayoutEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleChange = (value) => {
    setValue(value);
    if (props.handleChange) {
      props.handleChange(value);
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className={props.className ? props.className : 'mb-4'}>
      {
        props.data.map((item, i) => <div key={i} className={'radio-custom d-inline-block p-2 mr-2' + (props.isTab ? ' radio-tab-custom' : '') + (item.value === value ? ' active' : '') + (props.disabled || item.disabled ? ' radio-disabled' : '')} onClick={props.disabled || item.disabled ? () => {} : () => handleChange(item.value)}>
          <div className="d-flex align-items-center">
            { props.isTab ? <></> : <MDBIcon fa="true" icon={item.icon || 'check-circle'} className="fa-fw mr-1" /> }
            <span>{props.language?window.I18N(item.label.toLowerCase()):item.label}</span>
          </div>
        </div>)
      }
    </div>
  );
});

export default RadioBox;