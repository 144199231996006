import React, { useState, useLayoutEffect, useEffect } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FolderIcon  from '@material-ui/icons/Folder';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import InsertChartOutlinedOutlinedIcon from '@material-ui/icons/InsertChartOutlinedOutlined';
import ImageIcon from '@material-ui/icons/Image';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import MetadataGRAPHQL from '../../graphql/Metadata';
import ExportPDF from './ExportPDF';
import { MDBInputGroup, MDBInput, MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';
import Constant from '../../utils/Constant';

const AttributeCompany = React.memo(function AttributeCompany(props) {
  const [dataSave, setDataSave] = useState({});
  const [select, setSelect] = useState({});
  const [date, setDate] = useState({});
  const [showFile, setShowFile] = useState(true);
  const [dataSubfolder, setDataSubfolder] = useState({})
  const COMPANY_ID = props.companyId

  const USER_ID = localStorage.getItem(Constant.ID);
  const ACCOUNT_TYPE = localStorage.getItem(Constant.ACCOUNT_TYPE);

  const isAllowEdit = (ACCOUNT_TYPE === Constant.COMPANY && props.company?._id === USER_ID) || (ACCOUNT_TYPE === Constant.SUPER_ADMIN);
  const subFolder = {
        _id: 'testId',
        attribute: "text",
        code: "A_005",
        color: "black",
        index: 1,
        indexText: "IV",
        isBold: true,
        parentCode: "-1",
        title: "SUB FOLDER",
        type: "LABEL"
      }
  useLayoutEffect(() => {
    const select = {};
    const date = {};
    const dataSave = {};
    if (props.dataCompany && props.dataCompany.length > 0) {
      props.dataCompany.forEach(element => {
        const type = element.attribute && element.attribute.type;
        if (type) {
          if (type === 'DATE_TIME') {
            if ((new Date(element.value)) != 'Invalid Date') {
              date[element.attribute._id] = new Date(element.value);
            }
          } else if (type === 'SELECT') {
            const options = window.COMMON.parseStringToObject(element.attribute.value);
            select[element.attribute._id] = window.COMMON.setDataSelect(options, element.value);
          } else {
            dataSave[element.attribute._id] = element.value;
          }
        }
      });
    }
    setSelect(select);
    setDate(date);
    setDataSave({ ...dataSave });
  }, [props.company, props.dataCompany]);
  useEffect(() => {
    COMPANY_ID ? getDataDocumentOrganization() : void (0)
  }, [COMPANY_ID])

  const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));


  function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const {
    isFile,
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props;
  return (
    <TreeItem
      onClick={(e)=>handleClickFile(e,props.nodeId,labelText,isFile)}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit"  />
          <Typography  variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
  }
  StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};



  function handleClickFile(event,paramId, paramName, isFile) {
    event.preventDefault()
    if (isFile) {
      window.COMMON.downloadFile(paramId, paramName)
    }else {void(0)}
    
  }
  const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
    overflowY: 'auto'
  },
  });
  const classes = useStyles();



  function createTreeView(data) {
    const icon = (isFile, name) => {
      if (!isFile) {
        return FolderIcon
      } else {
        let type = name.split('.')
        switch (type[type.length - 1]) {
          case 'pdf':
            return PictureAsPdfIcon 
          case 'xlsx':
          case 'xls':
            return InsertChartOutlinedOutlinedIcon
          case 'doc':
          case 'docx':
            return DescriptionOutlinedIcon 
          case 'jpg':
          case 'jpeg':
          case 'png':
            return ImageIcon 
          default:
            return InsertDriveFileIcon
        }
      }
    } 
    if (data.length){
      return data.map((el, index)=>el.children&&el.children.length>0?
        <StyledTreeItem
          key={index}
          nodeId={el.isFile ? el.dataFile.id : el.id ? el.id : index}
          isFile={el.isFile}
          labelIcon={icon(el.isFile, el.name)}
          labelText={el.name}>{
            createTreeView(el.children)}
        </StyledTreeItem> :
        <StyledTreeItem
          key={index}
          nodeId={el.isFile ? el.dataFile.id : el.id ? el.id : index}
          isFile={el.isFile}
          labelIcon={icon(el.isFile, el.name)}
          labelText={el.name}></StyledTreeItem>
      )
    } else {
      return data.children&&data.children.length>0?
        <StyledTreeItem
          nodeId={data.isFile ? data.dataFile.id : data.id ? data.id : '1'}
          isFile={data.isFile}
          labelIcon={icon(data.isFile, data.name)}
          labelText={data.name||""}>
          {createTreeView(data.children)}
        </StyledTreeItem> :
        <StyledTreeItem
          nodeId={data.isFile ? data.dataFile.id : data.id ? data.id : '1'}
          isFile={data.isFile}
          labelIcon={icon(data.isFile, data.name)}
          labelText={data.name||""}></StyledTreeItem>
    }
  }

  const getDataDocumentOrganization = async () => {
    try {
      const params = {
        organization: COMPANY_ID
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_ORGANIZATION_DRIVE, params);
      const data = {
        name: window.I18N('my_organization'),
        id: null,
        checked: 0,
        isOpen: true,
        parent: null,
        isFile: false,
        file: null,
        children: []
      };
      if (result && result.data) {
        const items = result.data.getAllOrganizationDrives;
        setDataTree(data, null, items);
      }
      setDataSubfolder({...data});
    } catch (error) {
      window.COMMON.showErrorLogs('DocumentOrganizationPage.getDataDocumentOrganization');
    }
  };
  const setDataTree = (item, parent, items) => {
    let arrs = items.filter(child => child.parent === parent);
    arrs = window.COMMON.sortData(arrs, 'isFile');
    arrs.forEach(element => {
      const obj = {};
      obj.id = window.COMMON.getValueIdFromAttr(element, '_id');
      obj.name = window.COMMON.getValueFromAttr(element, 'name');
      obj.parent = window.COMMON.getValueFromAttr(element, 'parent');
      obj.isFile = window.COMMON.getValueFromAttr(element, 'isFile', false);
      obj.dataFile = !obj.isFile ? null : {
        id: window.COMMON.getValueFromAttr(element, 'dataFile.id'),
        name: window.COMMON.getValueFromAttr(element, 'dataFile.name'),
        size: window.COMMON.getValueFromAttr(element, 'dataFile.size'),
        type: window.COMMON.getValueFromAttr(element, 'dataFile.type'),
        ext: window.COMMON.getValueFromAttr(element, 'dataFile.ext')
      };
      obj.checked = 0;
      obj.isOpen = false;
      if (!element.isFile) {
        obj.children = [];
        setDataTree(obj, obj.id, items);
      }
      item.children.push(obj);
    });
  };
  
  const handleChange = (event, id) => {
    if(event.target.type === 'number' && event.target.value < 0){
      return;
    }
    if (event.target.className.indexOf('input-number') > -1) {
      dataSave[id] = parseInt(event.target.value);
    } else if (event.target.type === 'checkbox') {
      dataSave[id] = event.target.checked;
    } else {
      dataSave[id] = event.target.value;
    }
    setDataSave({ ...dataSave });
  };

  const handleSelect = (event, id) => {
    select[id] = event;
    setSelect({ ...select });
  };

  const handleRadio = (value, id) => {
    dataSave[id] = value;
    setDataSave({ ...dataSave });
  };

  const handleDatePicker = (event, id) => {
    date[id] = event;
    setDate({ ...date });
  };

  const handleSave = () => {
    if (props.handleSave) {
      const data = [];
      let keys = Object.keys(dataSave);
      for (let i = 0; i < keys.length; i++) {
        const element = keys[i];
        data.push({ attribute: element, value: dataSave[element].toString() });
      }
      keys = Object.keys(select);
      for (let i = 0; i < keys.length; i++) {
        const element = keys[i];
        data.push({ attribute: element, value: window.COMMON.getDataSelect(select[element], false) });
      }
      keys = Object.keys(date);
      for (let i = 0; i < keys.length; i++) {
        const element = keys[i];
        data.push({ attribute: element, value: date[element].toString() });
      }
      props.handleSave(data);
    }
  };

  const getStyle = (item) => {
    return { color: (item.color ? item.color : ''), fontWeight: (item.isBold ? 'bold' : '') };
  };

  const getLabel = (item) => {
    const label = window.COMMON.checkValue(item.indexText) ? item.indexText + '. ' + item.title : item.title;
    return <span className="text-title" style={getStyle(item)}>{label}</span>;
  };

  const generateStep = () => {
    let html = '';
    const arrs = window.COMMON.getArrsInArrs('-1', props.data, 'parentCode');
    if (showFile) { arrs.push(subFolder) }
    if (arrs.length > 0) {
      html = arrs.map((item, i) =>
        <div key={i} id={'step-' + (i + 2)} className="step-detail">
          <div className="container-list-tree only-view">
            <div className="item-list-tree" data-level="1">
              {generateControl(item._id, item.type, getLabel(item))}
            </div>
            {item.type === 'QUESTION' ? props.children : generateItem(item.code, 1)}
          </div>
        </div>
      );
    }
    return html;
  };

  const generateItem = (code, level) => {
    let html = '';
    const dataLevel = level + 1;
    const arrs = window.COMMON.getArrsInArrs(code, props.data, 'parentCode');
    if (arrs.length > 0) {
      html = arrs.map((item, i) =>
        <React.Fragment key={i}>
          <div className="item-list-tree" data-level={dataLevel}>
            {generateControl(item._id, item.type, getLabel(item), item.value, item.unit, window.COMMON.parseStringToObject(item.value))}
          </div>
          {generateItem(item.code, dataLevel)}
        </React.Fragment>
      );
      return html;
    }
    if (code === 'A_005') {
      return <TreeView
        className={classes.root}
        aria-label="file system navigator"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {createTreeView(dataSubfolder)}
      </TreeView>
      
    }
    return html;
  };

  const generateControl = (id, type, label, value, unit, data) => {
    if (type === 'COMPANY') {
      return <MDBRow>
        <MDBCol size='7'>{label}</MDBCol>
        <MDBCol>
          <MDBInputGroup containerClassName="input-group-custom m-0" className="disabled" append={unit} value={window.COMMON.getValueFromAttr(props.company, value)} />
        </MDBCol>
      </MDBRow>;
    }
    if (type === 'TEXT' || type === 'NUMBER' || type === 'EMAIL') {
      return <MDBRow>
        <MDBCol size='7'>{label}</MDBCol>
        <MDBCol>
          <MDBInputGroup className={(!isAllowEdit || props.readonly) && 'disabled'} type={type === 'TEXT' ? 'text' : type === 'NUMBER' ? 'number' : 'email'} containerClassName="input-group-custom m-0" append={unit} value={dataSave[id] ? dataSave[id] : ''} onChange={(event) => handleChange(event, id)} />
        </MDBCol>
      </MDBRow>;
    }
    if (type === 'TEXTAREA') {
      return <MDBRow>
        <MDBCol size='7'>{label}</MDBCol>
        <MDBCol>
          <MDBInput disabled={!isAllowEdit || props.readonly} outline containerClass="m-0" type="textarea" name="description" value={dataSave[id] ? dataSave[id] : ''} onChange={(event) => handleChange(event, id)} maxLength="5000"></MDBInput>
        </MDBCol>
      </MDBRow>;
    }
    if (type === 'SELECT') {
      return <MDBRow>
        <MDBCol size='7'>{label}</MDBCol>
        <MDBCol>
          <Select isDisabled={!isAllowEdit || props.readonly} className="md-form group-select m-0" placeholder={window.I18N('choose_option')} value={select[id] ? select[id] : null} options={data} onChange={(event) => handleSelect(event, id)} isSearchable />
        </MDBCol>
      </MDBRow>;
    }
    if (type === 'DATE_TIME') {
      return <MDBRow>
        <MDBCol size='7'>{label}</MDBCol>
        <MDBCol>
          <div className="input-group input-group-transparent">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <MDBIcon fa="true" icon="calendar-alt" className="fa-fw"/>
              </span>
            </div>
            <DatePicker disabled={!isAllowEdit || props.readonly} calendarClassName="12" className="form-control" dateFormat="dd/MM/yyyy"  selected={date[id]?date[id]:null}  onChange={(event) => handleDatePicker(event, id)} showYearDropdown showMonthDropdown />
          </div>
        </MDBCol>
      </MDBRow>;
    }
    if (type === 'RADIO' && Array.isArray(data)) {
      return <MDBRow>
        <MDBCol size='7'>{label}</MDBCol>
        <MDBCol>
          {
            data.map((item, i) => <MDBInput disabled={!isAllowEdit || props.readonly} gap key={i} label={item.label} id={id + '-' + i} type="radio" containerClass="d-inline-block pl-0 mr-3" onClick={() => handleRadio(item.value, id)} checked={dataSave[id] === item.value ? true : false} />)
          }
        </MDBCol>
      </MDBRow>;
    }
    return <p className="mt-2 m-0">{label}</p>;
  };

  const handleBack = () => {
    if (props.handleClose) {
      return props.handleClose();
    }
    return window.COMMON.backContainerData;
  };
  //pass data generateStep()  to component ExportPDF
  const handlePreView = () => {
    showFile ? setShowFile(false) : setShowFile(true);
  };

  return <>
    {showFile ?
      <div>
        <div className="container-btn">
          <MDBBtn color="" onClick={handleBack}>
            <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('btn_back')}
          </MDBBtn>
          <MDBBtn color="primary" type="button" onClick={handleSave} disabled={(!isAllowEdit || props.readonly)}>
            <MDBIcon fa="true" icon="save" className="fa-fw" /> {window.I18N('btn_save')}
          </MDBBtn>
          <MDBBtn color="primary" type="button" onClick={handlePreView}>
            <MDBIcon fa="true" icon="download" className="fa-fw" />{window.I18N('export_pdf')}
          </MDBBtn>
        </div>
        <div className="container-top">
          <MDBRow>
            <MDBCol>
              {generateStep()}
            </MDBCol>
          </MDBRow>
        </div>
      </div>
      :
      <div>
        <ExportPDF data={generateStep()} onClick={handlePreView} />
      </div>
    }
  </>;
});

export default AttributeCompany;