import gifList from '../styles/images/icons_sticker/icons_sticker';
const convertSticker = (allStickers, sticker) => {
  const oldSticker = gifList.find(({ name }) => name === sticker);
  if (oldSticker) return oldSticker.file;

  const matchedStickerName = allStickers.find(({ name }) => name === sticker);
  if (matchedStickerName) return matchedStickerName.icon;

  return sticker;
};
export { convertSticker };