import React from 'react'
import Image from '../../../../components/Image'
import Button from '../../../../components/Button'

const RejectAccessEvent = ({ onBackEventList = () => {} }) => {
    return (
        <div
            className="bg-white rounded-lg d-flex align-items-center justify-content-center"
            style={{ height: '600px' }}
        >
            <div className="d-flex align-items-center justify-content-center flex-column" style={{ gap: '8px' }}>
                <Image src={window.CONSTANT.REJECT} alt="" loading="lazy" height={120} />
                <span style={{ fontSize: '1rem', fontWeight: 400, fontStyle: 'italic' }}>
                    Xin lỗi bạn không có quyền truy cập sự kiện này
                </span>
                <Button variant="light" iconLeft="long-arrow-alt-left" onClick={onBackEventList}>
                    <span style={{ fontSize: '0.9rem', fontWeight: 500 }}>Trở lại trang chủ</span>
                </Button>
            </div>
        </div>
    )
}

export default RejectAccessEvent
