import React, { useState } from 'react'
import Button from '../../../../components/Button'
import EventGRAPHQL from '../../../../../graphql/Event'

const JoinOrLeaveEvent = ({ eventDate, eventId, status = null, refetchData = () => {} }) => {
    const [isJoined, setIsJoined] = useState(status)
    const handleJoinEventFanpage = async () => {
        try {
            const params = {
                id: eventId
            }
            const result = await window.COMMON.mutation(EventGRAPHQL.MUTATION_REQUEST_JOIN_FANPAGE_EVENT, params)
            if (window.COMMON.checkResultData(result, window.CONSTANT.SUCCESSFULLY)) {
                refetchData(false)
                setIsJoined(true)
            }
        } catch (error) {
            window.COMMON.showErrorLogs(`EventDetail.handleJoinEventFanpage ::: ${error}`)
        }
    }

    const handleLeaveEventFanpage = async () => {
        try {
            const params = {
                id: eventId
            }
            const result = await window.COMMON.mutation(EventGRAPHQL.MUTATION_LEAVE_FANPAGE_EVENT, params)
            if (window.COMMON.checkResultData(result, window.CONSTANT.SUCCESSFULLY)) {
                refetchData(false)
                setIsJoined(null)
            }
        } catch (error) {
            window.COMMON.showErrorLogs(`EventDetail.handleJoinEventFanpage ::: ${error}`)
        }
    }

    const handleRequestEventFanpage = (event) => {
        event.stopPropagation()
        isJoined === null ? handleJoinEventFanpage() : handleLeaveEventFanpage()
    }

    return (
        <>
            {window.COMMON_DATE.compareWithNow(new Date(+eventDate), true) === -1 ? (
                <Button variant="light" iconLeft="star" disabled>
                    <span style={{ fontSize: '0.9rem', fontWeight: 500 }}>{window.I18N('event_has_started')}</span>
                </Button>
            ) : (
                <Button variant="light" iconLeft="star" onClick={handleRequestEventFanpage}>
                    <span style={{ fontSize: '0.9rem', fontWeight: 500 }}>
                        {isJoined === null ? window.I18N('join') : window.I18N('cancel_join')}
                    </span>
                </Button>
            )}
        </>
    )
}

export default JoinOrLeaveEvent
