import React from 'react';
import { MDBIcon } from 'mdbreact';
import { CustomBadge } from '../../styles/styled-components/Components/Badge';
const Badge = ({ icon, count = 0, onClick }) => {
  return <CustomBadge onClick={onClick ? onClick : () => {}}>
    <MDBIcon fa="true" icon={icon || 'bell'} className="fa-fw fa-lg"></MDBIcon>
    {(count > 0) && <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger count">{count}</span>}
  </CustomBadge>;
};

export default Badge;