import React, { useRef, useState, useCallback, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';

import Panel from '../../components/Panel';
import Modal from '../../components/Modal';
import GridItemSelfAssessment from '../../components/GridItemSelfAssessment';
import ItemQA from '../../components/ItemQA';
import ItemPreviewSelfAssessment from '../../components/ItemPreviewSelfAssessment';
import AttributeCompany from '../../components/AttributeCompany';

import AccountGRAPHQL from '../../../graphql/Account';
import QuestionGRAPHQL from '../../../graphql/Question';

import SelfAssessmentGRAPHQL from '../../../graphql/SelfAssessment';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';
import moment from 'moment/moment';
import { useEffect } from 'react';
import { useAdminInfluencerInformation, useSelfAssessmentCreator } from './hooks';


function MySelfAssessmentPage(props) {
  const { trackEvent } = useTracking();

  const USER_ID = localStorage.getItem(window.CONSTANT.ID);
  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);

  const language = useRef();
  const [filter, setFilter] = useState({ company: null });
  const [object, setObject] = useState({ row: -1, col: -1, item: null, title: '', id: null, checkId: null, isSubmited: false, company: null, idAttribute: null });
  const [data, setData] = useState([]);
  const [dataAttributeReport, setDataAttributeReport] = useState([]);
  const [dataAttributeReportCompany, setDataAttributeReportCompany] = useState({});
  const [dataQA, setDataQA] = useState([]);
  const [dataFeedback, setDataFeedback] = useState({});
  const [dataCompletedFeedback, setDataCompletedFeedback] = useState([]);
  const [select, setSelect] = useState({});
  const [options, setOptions] = useState({
    users: [],
    company: []
  });
  const [userData, setUserData] = useState([]);

  const { creator: currentAssessmentCreator, setAssessment: setCurrentAssessment } = useSelfAssessmentCreator({});
  const { adminInfluencer: adminInfluencerOfCurrentTask, setId: setInfluencerIdOfCurrentTask } = useAdminInfluencerInformation(null);

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {
      getDataCompany();
      getDataAttributeReport();
    }, 100);
    // eslint-disable-next-line
  }, [props.language]);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleFilterSelect = (event) => {
    filter.company = event;
    setFilter({ ...filter });
    getData();
  };

  const handleSelect = (event, id, attr) => {
    window.COMMON.checkSelectValidation(id, event);
    select[attr] = event;
    setSelect({ ...select });
  };

  // COMPANY
  const getDataCompany = async () => {
    try {
      if (!window.COMMON.checkRoleIsSystem()) {
        filter.company = { value: COMPANY_ID, label: '', code: COMPANY_TYPE };
        setFilter({ ...filter });
        getData();
        return;
      }
      await window.COMMON.getDataAllCompany();
      const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));
      if (dataCompany && dataCompany.length > 0) {
        options.company = window.COMMON.createDataSelectWithGroup(dataCompany, '_id', 'information.name', 'type', 'type');
      }
      filter.company = window.COMMON.getValueFromAttr(options.company, '0.options.0', null);  
      setOptions(options);
      setFilter({ ...filter });
      getData();
    } catch (error) {
      window.COMMON.showErrorLogs('MySelfAssessmentPage.getDataCompany');
    }
  };

  const getData = () => {
    getDataAccount();
    getDataCompanyInfo();
    getDataAttributeReportCompany();
    getDataTaskSelfAssessment();
  };

  // ACCOUNT
  const getDataAccount = async () => {
    try {
      if (filter.company !== null) {
        const params = {
          company: filter.company.value,
          companyType: filter.company.code
        };
        const result = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT, params);
        if (result && result.data) {
          const data = result.data.getAccounts.filter(function(item) {
            return item.accountType !== window.CONSTANT.INDIVIDUAL;
          });
          options.users = window.COMMON.createDataSelectWithGroup(data, '_id', 'nickname', 'accountType', 'accountType');
		  setUserData(data);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MySelfAssessmentPage.getDataAccount');
    }
  };

  const getDataCompanyInfo = async () => {
    try {
      const params = { company: filter.company.value, companyType: filter.company.code };
      const result = await window.COMMON.query(AccountGRAPHQL.QUERY_COMPANY_INFO, params);
      if (result && result.data) {
        object.company = result.data.getCompanyAccountInfo;
        setObject(object);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('AttributeCompany.getDataCompanyInfo');
    }
  };

  // ATTRIBUTE REPORT
  const getDataAttributeReport = async () => {
    try {
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_ATTRIBUTE_REPORT, null);
      if (result !=null) {
        let index = 0;
        result.data.getAttributeReports.forEach(item => {
          item.idx = index;
          index++;
        });
        setDataAttributeReport(result.data.getAttributeReports);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MySelfAssessmentPage.getDataAttributeReport');
    }
  };

  // ATTRIBUTE REPORT COMPANY
  const getDataAttributeReportCompany = async () => {
    try {
      const params = { company: filter.company.value };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_ATTRIBUTE_REPORT_COMPANY, params);
      if (result && result.data && result.data.findAttributeReportCompany) {
        const data = result.data.findAttributeReportCompany;
        object.idAttribute = data._id;
        setDataAttributeReportCompany(data);
      } else {
        object.idAttribute = null;
        setDataAttributeReportCompany({});
      }
      setObject(object);
    } catch (error) {
      window.COMMON.showErrorLogs('MySelfAssessmentPage.getDataAttributeReportCompany');
    }
  };

  // TASK SELF ASSESSMENT
  const getDataTaskSelfAssessment = async () => {
    try {
      const params = {
        company: filter.company.value
      };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_TASK_SELF_ASSESSMENT, params);
      if (result && result.data) {
        const dataTask = window.COMMON.formatDate(result.data.getTaskSelfAssessments, 'createdDate');
        const isRoleSystem = window.COMMON.checkRoleIsSystemOrOrganization();
        for (let i = 0; i < dataTask.length; i++) {
          const item = dataTask[i];
          item.isCompleted = true;
          item.time = window.COMMON_DATE.formatDate(item.selfAssessment.startDate) + ' - ' + window.COMMON_DATE.formatDate(item.selfAssessment.endDate);
          let check = false;
          for (let j = 0; j < item.items.length; j++) {
            const element = item.items[j];
            if (element.users.indexOf(USER_ID) === -1 && !isRoleSystem) {
              item.items.splice(j, 1);
              j--;
            } else {
              check = true;
              if (!element.isCompleted) {
                item.isCompleted = false;
              }
              element.nameLanguage = window.COMMON.getValueWithLanguage(element, 'group.name', language.current);
              element.descriptionLanguage = window.COMMON.getValueWithLanguage(element, 'group.description', language.current);
            }
          }
          if (!check) {
            dataTask.splice(i, 1);
            i--;
          }
        }
        object.id = null;
        setObject(object);
        setDataCompletedFeedback([]);
        setData(dataTask);
      } else {
        setData([]);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MySelfAssessmentPage.getDataTaskSelfAssessment');
    }
  };

  // QUESTION & ANSWER
  const getDataQuestion = async () => {
    try {
      const groupId = data[object.row].items[object.col].group._id;
      const params = {
        groupId
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_QUESTION, params);
      const dataQA = [];
      if (result && result.data) {
        result.data.getQuestions.forEach(obj => {
          dataQA.push({
            uuid: window.COMMON.getValueFromAttr(obj, '_id', null),
            group: groupId,
            question: {
              _id: window.COMMON.getValueFromAttr(obj, '_id', null),
              question: window.COMMON.getDataWithLanguage(obj, 'question'),
              introduction: window.COMMON.getDataWithLanguage(obj, 'introduction'),
              type: window.COMMON.getValueFromAttr(obj, 'type'),
              code: window.COMMON.getValueFromAttr(obj, 'code'),
              important: window.COMMON.getValueFromAttr(obj, 'important')
            },
            answers: []
          });
        });
      }
      getDataAnswerQuestion(groupId, dataQA);
    } catch (error) {
      window.COMMON.showErrorLogs('MySelfAssessmentPage.getDataQuestion');
    }
  };

  const getDataAnswerQuestion = async (groupId, dataQA) => {
    try {
      const params = {
        groupId
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_ANSWER_QUESTION, params);
      if (result && result.data) {
        result.data.getAnswerQuestions.forEach(obj => {
          const item = window.COMMON.getObjectInArrs(obj.question, dataQA, 'uuid');
          item.answers.push({
            _id: window.COMMON.getValueFromAttr(obj, '_id', null),
            content: window.COMMON.getDataWithLanguage(obj, 'content'),
            code: window.COMMON.getValueFromAttr(obj, 'code'),
            parentCode: window.COMMON.getValueFromAttr(obj, 'parentCode', null),
            question: window.COMMON.getValueFromAttr(obj, 'question')
          });
        });
      }
      setDataQA(dataQA);
      getDataFeedback();
    } catch (error) {
      window.COMMON.showErrorLogs('MySelfAssessmentPage.getDataQuestion');
    }
  };

  const getDataFeedback = async () => {
    try {
    //   const dataFeedback = {};
    //   if (!object.item.isCompleted) {
    //     setDataFeedback({ ...dataFeedback });
    //     return;
    //   }
      if (object.checkId === data[object.row]._id && dataCompletedFeedback.length > 0) {
        const obj = window.COMMON.getObjectInArrs(data[object.row].items[object.col].group._id, dataCompletedFeedback, 'group._id');
        generateFeedback(obj);
        return;
      }
      object.checkId = data[object.row]._id;
      setObject(object);
      const params = {
        id: data[object.row]._id
      };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_FIND_TASK_SELF_ASSESSMENT_BY_ID, params);
      if (result && result.data && result.data.findTaskSelfAssessment && result.data.findTaskSelfAssessment.items) {
        const obj = window.COMMON.getObjectInArrs(data[object.row].items[object.col].group._id, result.data.findTaskSelfAssessment.items, 'group._id');
        setDataCompletedFeedback(result.data.findTaskSelfAssessment.items);
        generateFeedback(obj);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MySurveyPage.getDataFeedback');
    }
  };

  const generateFeedback = (obj) => {
    const dataFeedback = {};
    obj.feedback && obj.feedback.forEach(item => {
      // TODO init data feedback
      const obj = {
        answerText: item.answerText,
        note: item.note,
        consultantEvaluation: item.consultantEvaluation,
        consultantNote: item.consultantNote,
        documents: item.documents
      };
      if (item.question.type === 'RADIO_GRID' || item.question.type === 'CHECKBOX_GRID') {
        obj.count = 0;
        obj.answers = {};
        item.answers.forEach(element => {
          if (element.parentCode !== null) {
            if (!obj.answers[element.parentCode]) {
              obj.answers[element.parentCode] = [];
              obj.count++;
            }
            obj.answers[element.parentCode].push(element._id);
          }
        });
      } else {
        obj.answers = [];
        item.answers.forEach(element => {
          obj.answers.push(element._id);
        });
      }
      dataFeedback[item.question._id] = obj;
    });
    setDataFeedback({ ...dataFeedback });
  };

  const showAttributeReportCompany = () => {
    window.COMMON.showContainerSave(false, true);
  };

  // eslint-disable-next-line
  const showContainerGroup = useCallback((row, col) => {
    window.COMMON.showContainerSave();
    object.row = row;
    object.col = col;
    object.item = data[row].items[col];
    object.isSubmited = data[row].status === 1 || data[row].status === 2;
    object.title = window.COMMON.getValueFromAttr(data[row], 'selfAssessment.name') + ' (' + window.COMMON.getValueFromAttr(data[row], 'time') + ')';
    setObject(object);
    getDataQuestion();
  });

  // eslint-disable-next-line
  const openSaveGroup = useCallback((dataSave, deletedFiles) => {
    const feedback = [];
    const files = [];
    const keys = Object.keys(dataSave);
    let index = 0;
    keys.forEach(key => {
      const obj = window.COMMON.getObjectInArrs(key, dataQA, 'question._id');
      const item = {};
      item.question = key;
      item.answers = [];
      item.answerText = dataSave[key].answerText;
      item.note = dataSave[key].note;
      item.consultantEvaluation = dataSave[key].consultantEvaluation;
      item.consultantNote = dataSave[key].consultantNote;
      item.documents = [];
      dataSave[key].documents.forEach(document => {
        item.documents.push({
          id: document.file ? index : document.id,
          name: document.name,
          size: document.size,
          type: document.type,
          ext: document.ext
        });
        if (document.file) {
          index++;
          files.push(document.file);
        }
      });
      if (obj.question.type === 'RADIO_GRID' || obj.question.type === 'CHECKBOX_GRID') {
        const answerKeys = Object.keys(dataSave[key].answers);
        answerKeys.forEach(answerKey => {
          const answer = window.COMMON.getObjectInArrs(answerKey, obj.answers, 'code');
          if (answer) {
            item.answers.push(answer._id);
          }
          item.answers = item.answers.concat(dataSave[key].answers[answerKey]);
        });
      } else {
        item.answers = dataSave[key].answers;
      }
      feedback.push(item);
    });
    window.COMMON.showModal('#modal-save');
    window.saveMethod = () => sendFeedback(files, feedback, deletedFiles);
  });

  const openPreviewSelfAssessment = useCallback((row) => {
    object.row = row;
    object.id = data[row]._id;
    object.isSubmited = data[row].status === 1 || data[row].status === 2;
    window.COMMON.showModal('#modal-preview');
    setObject({ ...object });
  }, [object, data]);

  // eslint-disable-next-line
  const openSaveAttributeCompany = useCallback((data) => {
    window.COMMON.showModal('#modal-save');
    window.saveMethod = () => saveAttributeCompany(data);
  });

  const closeAttributeCompany = () => {
    window.COMMON.backContainerData();
    //close view facility info, check is view ItemQA or not 
    if (object.row > -1 && object.col > -1) {
      window.COMMON.showContainerSave();
    }
  };

  const openGrantAccount = useCallback((row, col) => {
    object.row = row;
    object.col = col;
    object.item = data[row].items[col];
    select.users = window.COMMON.setDataMultiSelectWithGroup(options.users, window.COMMON.getValueFromAttr(object.item, 'users', []));
    setObject(object);
    setSelect({ ...select });
    window.COMMON.showModal('#modal-grant');
  }, [options, select, object, data]);

  const saveAttributeCompany = async (data) => {
    try {
      const params = {
        input: {
          company: filter.company.value,
          items: data
        }
      };
      window.COMMON.trimData(params);
      if (!object.idAttribute) {
        const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_ADD_ATTRIBUTE_REPORT_COMPANY, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataAttributeReportCompany();
          window.COMMON.backContainerData();
		  createTrackingEvent(eventName.ADD_ATTRIBUTE_REPORT_COMPANY);
        }
      } else {
        params.input._id = object.idAttribute;
        const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_EDIT_ATTRIBUTE_REPORT_COMPANY, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataAttributeReportCompany();
          window.COMMON.backContainerData();
		  createTrackingEvent(eventName.EDIT_ATTRIBUTE_REPORT_COMPANY);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RewardPage.saveReward');
    }
  };

  const  sendFeedback = async (files, feedback, deletedFiles) => {
    try {
      // TODO delete file
      if (deletedFiles && deletedFiles.length > 0) {
        deletedFiles.forEach(file => {
          window.COMMON.deleteFile(file.id);
        });
      }
      // TODO upload documents for self assessment
      if (files.length > 0) {
        const dataFiles = await window.uploadFile(files);
        if (dataFiles && dataFiles.length > 0) {
          for (let i = 0; i < dataFiles.length; i++) {
            const file = dataFiles[i];
            for (let j = 0; j < feedback.length; j++) {
              const element = feedback[j];
              const obj = window.COMMON.getObjectInArrs(i, element.documents, 'id');
              if (obj) {
                obj.id = file._id;
                obj.type = file.mime;
                break;
              }
            }
          }
        }
      }
      const params = {
        input: {
          _id: data[object.row]._id,
          group: data[object.row].items[object.col].group._id,
          feedback
        }
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_SEND_FEEDBACK_TASK_SELF_ASSESSMENT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
        window.COMMON.backContainerData();
        getDataTaskSelfAssessment();
        createTrackingEvent(eventName.SEND_FEEDBACK_TASK_SELF_ASSESSMENT);

        // send mail notify
        sendFinishNotify(data[object.row]._id);

        // clear user draft data
        clearDraftData(data[object.row]._id, data[object.row].items[object.col].group._id);
      }
      window.COMMON.hideModal('#modal-group');
    } catch (error) {
      window.COMMON.showErrorLogs('MySelfAssessmentPage.sendFeedback');
    }
  };

  const assignSelfAssessment = async () => {
    try {
      const checkUsers = window.COMMON.checkSelectValidation('select-users', select.users);
      if (!checkUsers) {
        window.COMMON.showMessage('warning', 'MSG_CODE_012', window.MSG.MSG_CODE_012);
        return;
      }
      const params = {
        id: data[object.row]._id,
        group: window.COMMON.getValueFromAttr(object.item, 'group._id'),
        users: window.COMMON.getDataSelect(select.users, true)
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_ASSIGN_TASK_SELF_ASSESSMENT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
        getDataTaskSelfAssessment();
        createTrackingEvent(eventName.ASSIGN_TASK_SELF_ASSESSMENT);
		
        //send mail notify to company manager
        const selectedUsers = userData.filter(item => params.users?.includes(item._id));
        if (selectedUsers.length > 0) {
          let email = '';
          selectedUsers.map((item, index) => {
            if (index > 0) return email += `,${item.email}`;
            return email += item.email;
          });
          const result = await window.COMMON.sendMailNotify({
            email,
            title: window.I18N('self_assessment_assigned_title'),
            body: `
            <p>Tự đánh giá <b>${data[object.row]?.selfAssessment?.name}</b> <b>${moment().format('[ngày] DD [tháng] MM')}</b> vừa được giao cho bạn. Vui lòng kiểm tra chi tiết trên GOPY.</p>
            <br></br>
            <p>Assessment <b>${data[object.row]?.selfAssessment?.name}</b> <b>${moment().format('MMMM DD')}</b> is assigned to you. Please check it out.</p>`
          });
          if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
            createTrackingEvent(eventName.SEND_MAIL);
          }
        }
      }
      window.COMMON.hideModal('#modal-grant');
      closeModal();
    } catch (error) {
      window.COMMON.showErrorLogs('MySelfAssessmentPage.assignSelfAssessment');
    }
  };
  
  const closeModal = () => {
    object.row=-1;
    object.col=-1;
    setObject(object);
  };

  const submitTaskSelfAssessment = async () => {
    try {
      if (!dataAttributeReportCompany || !dataAttributeReportCompany._id) {
        window.COMMON.showMessage('warning', 'MSG_CODE_040', window.MSG.MSG_CODE_040);
        return;
      }
      const params = {
        id: data[object.row]._id,
        company: filter.company.value
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_SUBMIT_TASK_SELF_ASSESSMENT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
        getDataTaskSelfAssessment();
        window.COMMON.hideModal('#modal-preview');
        createTrackingEvent(eventName.SUBMIT_TASK_SELF_ASSESSMENT);

        //send mail notify to admin influencer
        const adminInfluencerEmail = adminInfluencerOfCurrentTask.information?.email;
        const adminInfluencerName = adminInfluencerOfCurrentTask.information?.name;
        const result = await window.COMMON.sendMailNotify({
          email:  adminInfluencerEmail || '',
          title: window.I18N('submitted_self_assessment_title'),
          body: `
         <p>Gửi ${adminInfluencerName}!</p>
         <p>Tổ chức <b>${data[object.row].company?.information?.name}</b> vừa gửi đánh giá về <b>${data[object.row].selfAssessment?.name ?? ''}</b> vào <b>${moment().format('[ngày] DD [tháng] MM')}</b>. Vui lòng giúp chúng tôi xem xét và xử lý nó.</p>
          <p>Cảm ơn bạn,</p>
          <p>GOPY TEAM</p>
          <br></br>
          <p>Dear ${adminInfluencerName}!</p>
          <p>Organization <b>${data[object.row].company?.information?.name}</b> just submitted a self assessment <b>${data[object.row].selfAssessment?.name ?? ''}</b> on <b>${moment().format('MMMM DD')}</b>. Please help us review and process it.</p>
          <p>Thank you,</p>
          <p>THE GOPY TEAM</p>`
        });
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
          createTrackingEvent(eventName.SEND_MAIL);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MySelfAssessmentPage.submitTaskSelfAssessment');
    }
  };

  const rejectTaskSelfAssessment = async () => {
    try {
	  const assessment = data.find(item => item._id === object.id);
	  if (assessment) {
        const params = {
          input: {
            _id: assessment._id
          }
        };
        const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_REJECT_TASK_SELF_ASSESSMENT, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          window.COMMON.backContainerData();
          getDataTaskSelfAssessment();
          window.COMMON.hideModal('#modal-preview');
          createTrackingEvent(eventName.REJECT_TASK_SELF_ASSESSMENT);
        }
		
        // send reject email
        sendRejectNotify(assessment);
	  } 
    } catch (error) {
      window.COMMON.showErrorLogs('MySelfAssessmentPage.errorTaskSelfAssessment');
    }
  };

  const sendFinishNotify = async (id) => {
    try {
      const paramQuery = {
        id
	  };
	  const assessment = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_FIND_TASK_SELF_ASSESSMENT_BY_ID, paramQuery);
	  if (assessment?.data?.findTaskSelfAssessment) {
        const objData = assessment.data.findTaskSelfAssessment;
        if (objData.items) {
          //condition to send mail
          let isCompleted = true;
          objData.items.map(item => {
            if (!item.isCompleted) return isCompleted = false;
          });
		  if (isCompleted) {
            const result = await window.COMMON.sendMailNotify({
              email: objData.company?.information?.email ?? '',
              title: window.I18N('self_assessment_finished_title'),
              body: `
              <p>Tự đánh giá <b>${objData.selfAssessment?.name}</b> vào <b>${moment().format('[ngày] DD [tháng] MM')}</b> đã hoàn thành. Vui lòng kiểm tra chi tiết trên GOPY.</p>
              <br></br>
              <p>Assessment <b>${objData.selfAssessment?.name}</b> on <b>${moment().format('MMMM DD')}</b> is completed. Please check it out.</p>`
            });
            if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
              createTrackingEvent(eventName.SEND_MAIL);
            }
		  }
        }
	  }
    } catch (error) {
      window.COMMON.showErrorLogs('MySelfAssessmentPage.errorTaskSelfAssessment');
    }
  };

  const sendRejectNotify = async (assessment) => {
    try {
      const userIds = [];
      assessment.items.forEach(item => {
        item.users.forEach(userid => {
          if (!userIds.includes(userid)) userIds.push(userid);
          return;
        });
      });

      const resUsers = await window.COMMON.mutation(AccountGRAPHQL.QUERY_ACCOUNT_BY_IDS, { ids: userIds });
	  if (resUsers.data?.findAccountByIds) {
        let email = '';
        resUsers.data.findAccountByIds.map((item, index) => {
          if (index > 0) return email += `,${item.email}`;
          return email += item.email;
        });
		
        if (email) {
		  const result = await window.COMMON.sendMailNotify({
            email,
            title: window.I18N('self_assessment_rejected'),
            body: `
            <p>Tự đánh giá <b>${assessment?.selfAssessment?.name}</b> vào <b>${moment().format('[ngày] DD [tháng] MM')}</b> đã bị từ chối. Vui lòng kiểm tra chi tiết trên GOPY.</p>
            <br></br>
            <p>Assessment <b>${assessment?.selfAssessment?.name}</b> on <b>${moment().format('MMMM DD')}</b> is rejected. Please check it out.</p>`
		  });
          if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
            createTrackingEvent(eventName.SEND_MAIL);
          }
        }
	  }
    } catch (error) {
      window.COMMON.showErrorLogs('MySelfAssessmentPage.errorTaskSelfAssessment');
    }
  };

  const closeGroupContainer = async () => {
    window.COMMON.backContainerData();
    await getDataTaskSelfAssessment();
    clearObjectData();
  };

  const clearObjectData = () => {
    setObject({ row: -1, col: -1, item: null, title: '', id: null, checkId: null, isSubmited: false, company: object.company, idAttribute: null });
    setDataQA([]);
    setDataFeedback([]);
    setDataCompletedFeedback([]);
  };

  const clearDraftData = (assessmentId, groupId) => {
    const userId = localStorage.getItem('id');
    const draft = JSON.parse(localStorage.getItem('draftAssessment') || '[]');

    const updateDataDraft = draft.filter(item => !(item.user === userId && item.assessment === assessmentId && item.group === groupId));
    localStorage.setItem('draftAssessment', JSON.stringify(updateDataDraft));
  };


  useEffect(() => {
    if (object.row > -1) {
      const task = data[object.row];
      setCurrentAssessment(task.selfAssessment);
    }
  }, [object.row, data]);

  useEffect(() => {
    const influencerId = currentAssessmentCreator?.influencer?._id || '';
    if (influencerId) {
      setInfluencerIdOfCurrentTask(influencerId);
    }
  }, [currentAssessmentCreator]);

  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <div className="container-row">
      <div id="container-data">
        <ul className="list-group">
          <li className="list-group-item" style={{ zIndex: 999 }}>
            <div className="d-flex align-items-center justify-content-end fadeInDown animated"  data-wow-delay="0.5s">
              {
                !window.COMMON.checkRoleIsSystem() ? <div></div> : <div className="bd-highlight mr-2" style={{ width: '300px' }}>
                  <div className="input-group input-group-transparent">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <MDBIcon fa="true" icon="building" className="fa-fw" />
                      </span>
                    </div>
                    <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.company} options={options.company} onChange={handleFilterSelect} isSearchable/>
                  </div>
                </div>
              }
              <MDBBtn color="" className="mr-0" onClick={() => showAttributeReportCompany()}>
                <MDBIcon fa="true" icon="info-circle" className="fa-fw" /> {window.I18N('facility_info')}
              </MDBBtn>
            </div>
          </li>
          <GridItemSelfAssessment data={data} isHidePreview={!window.COMMON.checkRoleIsSystemOrOrganization()} handleItemClick={showContainerGroup} handlePreviewClick={openPreviewSelfAssessment} handleItemEditClick={openGrantAccount}></GridItemSelfAssessment>
        </ul>
      </div>
      
      <Panel id="container-save" title={object.title} icon="question-circle" style={{ display: 'none' }}>
        <ItemQA 
          assessmentId={data[object.row]?._id}
          group={object.item} 
          dataFeedback={dataFeedback} 
          isSubmited={object.isSubmited} 
          data={dataQA} 
          language={language.current} 
          isSelfAssessment={true} 
          isCompany={true} 
          handleSubmit={openSaveGroup}
		      handleClose={closeGroupContainer}
		      handleViewFacility={showAttributeReportCompany}
        />
      </Panel>

      <Panel id="container-attribute-report" title={window.I18N('self_organization')} icon="info-circle" style={{ display: 'none' }}>
        <AttributeCompany 
          data={dataAttributeReport} 
          company={object.company} 
          dataCompany={dataAttributeReportCompany.items || []} 
          handleSave={openSaveAttributeCompany} 
          handleClose={closeAttributeCompany}
          companyId= {filter.company?.value}
        />
      </Panel>

      <Modal id="modal-grant" className="modal-lg" isNotScroll={true} title={window.I18N('assign_self')} saveEvent={assignSelfAssessment} handleCloseModal={closeModal} >
        <Select id="select-users" className="md-form" placeholder={window.I18N('select_accounts')} value={select.users} options={options.users} onChange={(event) => handleSelect(event, 'select-users', 'users')} isSearchable isMulti closeMenuOnSelect={false}/>
      </Modal>
      
      <Modal id="modal-preview" className="modal-xl" title={window.I18N('preview_self')} hideSave={object.isSubmited} hideReject={object.isSubmited} saveEvent={submitTaskSelfAssessment} saveBtn={window.I18N('btn_approve')} rejectEvent={rejectTaskSelfAssessment} icon="check-circle">
        <MDBRow>
          <MDBCol>
            <ItemPreviewSelfAssessment type={0} id={object.id} dataAttributeReport={dataAttributeReport} company={object.company} dataCompany={dataAttributeReportCompany.items || []}/>
		  </MDBCol>
        </MDBRow>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MySelfAssessmentPage);