import React from 'react';

const Panel = React.memo(function Panel(props) {
  return (
    <div id={props.id} className={'container-panel wow fadeIn animated ' + window.COMMON.getValue(props.className)} style={props.style} data-wow-delay="0.2s">
      <div className="container-content" style={props.styleContent}>
        {props.children}
      </div>
    </div>
  );
});

export default Panel;