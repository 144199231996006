const fanpageEvents = {
    APPROVE_POST: "APPROVE_POST",
    REJECT_POST: "REJECT_POST",
    REPORT_POST: "REPORT_POST",
    EDIT_FANPAGE: "EDIT_FANPAGE",
    INVITE_FANPAGE: "INVITE_FANPAGE",
    JOIN_FANPAGE: "JOIN_FANPAGE",
    ACCEPT_REQUEST_FANPAGE: "ACCEPT_REQUEST_FANPAGE",
    DECLINE_REQUEST_FANPAGE: "DECLINE_REQUEST_FANPAGE",
    REMOVE_MEMBER: "REMOVE_MEMBER",
    LEAVE_FANPAGE: "LEAVE_FANPAGE",
    CREATE_FANPAGE: "CREATE_FANPAGE",
    JOIN_EVENT: "JOIN_EVENT",
    LEAVE_EVENT: "LEAVE_EVENT",
    CREATE_EVENT: "CREATE_EVENT"
}

export default fanpageEvents