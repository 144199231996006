import React, { Fragment } from 'react';
import { CustomChatResultSearchItem } from '../../../../../styles/styled-components/Chat/ChatPage';
import CircleAvatar from '../../../../components/CircleAvatar';
import EmptyDataText from '../../../../components/EmptyDataText';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { Heading4Styled } from '../../../../../styles/styled-components/Text/TextSTyled';



const ResultSearchList = ({ visible, searchResult, onSearchResultClick, searchUserLoading }) => {
  const checkSearchResultIsEmpty = (searchResult) => {
    let countEmpty = 0;
    const searchResultKeys = Object.keys(searchResult);
    searchResultKeys.forEach((key) => {
      if (searchResult[key].length === 0) {
        countEmpty += 1;
      }
    });
    if (countEmpty === searchResultKeys.length) {
      return true;
    }
    return false;
  };
  
  return <div style={{ display: `${visible === true ? 'block' : 'none'}` }}>
    {searchUserLoading === true && <div className="d-flex justify-content-center">
      <LoadingSpinner />
    </div>}
    {Object.keys(searchResult).map((rsKey, keyIndex) => {
      const result = searchResult[rsKey];
      return <Fragment key={`${keyIndex}-group-result`}>
        {result.length > 0 && <div className='result-title mt-2' style={{padding:'0 10px'}}>
          <Heading4Styled>
            {window.I18N(window.CONSTANT.SEARCH_RESULT_TITLE_OBJ[rsKey])}
          </Heading4Styled>
        </div>}
        {result.map((item, index) => <CustomChatResultSearchItem onClick={() => onSearchResultClick(item)} key={`search-user-${index}-${keyIndex}`}>
          <CircleAvatar src={item.avatar} />
          <div className="name">{item.nickname || item.groupName}</div>
        </CustomChatResultSearchItem>)}
      </Fragment>;
    })}
    {/* {searchResult.map((item, index) => <CustomChatResultSearchItem onClick={() => onSearchResultClick(item)} key={`search-user-${index}`}>
      <CircleAvatar src={item.avatar} />
      <div className="name">{item.name}</div>
    </CustomChatResultSearchItem>)} */}
    {(searchUserLoading === false && checkSearchResultIsEmpty(searchResult)) && <div className="d-flex justify-content-center pt-3"><EmptyDataText /></div>}

  </div>;
};

export default ResultSearchList;