import React from 'react';
import Routes from './Routes';
import Header from './Header';
import Menu from './Menu';
import ChangePassword from '../components/ChangePassword';
import useSocket from '../../hooks/useSocket';
import { ChatDataProvider } from '../../context/ChatDataContext';
function Layout(props) {
  useSocket();
  return (
    <div className="flexible-content min-height-100">
      <header>
        <Header />
        <Menu />
      </header>
      <main id="main">
        <ChatDataProvider>
          <Routes />
        </ChatDataProvider>
      </main>
      <ChangePassword></ChangePassword>
    </div>
  );
}

export default Layout;
