import React from 'react';
import { MDBBtn, MDBIcon } from 'mdbreact';

const InputMultiFile = React.memo(function ItemPost(props) {

  const openImportFile = () => {
    document.getElementById(props.id).value = null;
    document.getElementById(props.id).click();
  };

  const handleChange = async (e) => {
    window.showLoading();
    const files = e.target.files;
    if (files && files.length > 0) {
      props.handleFile(files);
    }
    window.hideLoading();
  };

  return <div className="d-inline-block text-center">
    <MDBBtn color={window.COMMON.getValue(props.color)} className={'btn-upload ' + props.className} type="button" onClick={openImportFile}>
      <MDBIcon fa="true" icon={props.icon} className="fa-fw"/> {props.label}
    </MDBBtn>
    <input type="file" className="form-control file-upload" id={props.id} multiple onChange={handleChange} />
    <br></br> {props.description}
  </div>;
});

export default InputMultiFile;