import React, { useState, useEffect } from 'react';
import { MDBCol, MDBIcon, MDBBtn } from 'mdbreact';
import FormModal from '../../components/FormModal';
import CircleAvatar from '../../components/CircleAvatar';
import EmptyDataText from '../../components/EmptyDataText';
import { MembersDataStyled } from '../../../styles/styled-components/Chat/MembersFormModal';
import GroupChatGRAPHQL from '../../../graphql/GroupChat';
const MembersFormModal = ({ modalId, onClose, groupChatSelected, visible, onBlockClick, onRemoveClick }) => {
  const CONFIRM_MODAL = 'modal-confirm';
  const [members, setMembers] = useState([]);
  const [fetchMembersLoading, setFetchMembersLoading] = useState(false);
  useEffect(() => {
    if (visible !== true) return;
    fetchMembers();
    return () => {

    };
  }, [visible]);


  const fetchMembers = async () => {
    try {
      const groupId = groupChatSelected._id;
      if (fetchMembersLoading === true || !groupId) return;
      setFetchMembersLoading(true);
      const params = {
        groupId
      };
      const result = await window.COMMON.mutation(GroupChatGRAPHQL.QUERY_FIND_MEMBERS_IN_GROUP, params);
      let membersData = [];
      if (result && result.data) {
        const rawmembers = result.data.findMembersInGroup;
        membersData = rawmembers.map((item) => item.user);
      }
      setMembers(membersData);
    }
    catch (error) {
      window.COMMON.showErrorLogs('ChatPage.fetchMembers');
    }
    setFetchMembersLoading(false);
  };


  const handleClickCheckbox = (event, _id) => {
    setMembers((current) => {
      const index = current.findIndex((item) => item._id === _id);
      if (index >= 0) {
        current[index].isChecked = !current[index].isChecked;
      }
      return [...current];
    });
  };


  const getcheckedMembers = (members) => {
    const checkedMembers = members.filter((item) => item.isChecked === true);
    return checkedMembers;
  };

  const removecheckedMembers = (checkedMembersIds) => {
    setMembers((current) => {
      const newMembers = current.filter((item) => !checkedMembersIds.includes(item._id));
      return [...newMembers];
    });
  };

  const handleActionClick = (type) => {
    window.COMMON.showModal(`#${CONFIRM_MODAL}`);
    window.confirmMethod = async () => {
      const checkedMembers = getcheckedMembers(members);
      const checkedMembersIds = checkedMembers.map((item) => item._id);

      if (type === 'BLOCK') {
        const result = await onBlockClick(groupChatSelected, checkedMembersIds);
        if (result === 1) {
          removecheckedMembers(checkedMembersIds);
          window.COMMON.showMessage('success', 'MSG_CODE_062', window.I18N('MSG_CODE_062'));
        }
      }
      if (type === 'REMOVE') {
        const result = await onRemoveClick(groupChatSelected,checkedMembers,checkedMembersIds);
        if (result === 1) {
          removecheckedMembers(checkedMembersIds);
          window.COMMON.showMessage('success', 'MSG_CODE_065', window.I18N('MSG_CODE_065'));
        }
      }
           
    };
  };




  return <FormModal id={modalId} title={window.I18N('members')} hideSave={true} onClose={onClose}>
    <form id="form" className="needs-validation" noValidate>

      <MDBCol xs='12'>

        <MembersDataStyled>
          {(members.length <= 0 && fetchMembersLoading === false) && <div className="d-flex justify-content-center pt-3"><EmptyDataText /></div>}
          {members.length > 0 && <div className="list">
            {members.map((item, index) => {
              return <div className="item" key={`${index}-request-${item._id}`}>
                <CircleAvatar src={item.avatar} />
                <div className="content">{item.nickname}</div>
                {item._id !== localStorage.getItem(window.CONSTANT.ID) && <input type={'checkbox'} checked={item.isChecked} onChange={(event) => handleClickCheckbox(event, item._id)} />}
              </div>;
            })}

          </div>}
        </MembersDataStyled>
        <div className="container-btn mt-5 d-flex justify-content-end">
          {/* <MDBBtn color="danger" onClick={() => handleActionClick("BLOCK")} disabled={getcheckedMembers(members).length > 0 ? false : true}>
                        <MDBIcon fa="true" icon="ban" className="fa-fw" /> {window.I18N('btn_block')}
                    </MDBBtn> */}
          <MDBBtn color="danger" onClick={() => handleActionClick('REMOVE')} disabled={getcheckedMembers(members).length > 0 ? false : true}>
            <MDBIcon fa="true" icon="ban" className="fa-fw" /> {window.I18N('btn_remove')}
          </MDBBtn>
          {/* <MDBBtn color="primary" onClick={() => handleActionClick("ACCEPT")} disabled={getcheckedMembers(members).length > 0 ? false : true}>
                        <MDBIcon fa="true" icon="check-circle" className="fa-fw" /> {window.I18N('btn_accept')}
                    </MDBBtn> */}
        </div>



      </MDBCol>


    </form>
  </FormModal >;
};

export default MembersFormModal;