import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import SelfAssessmentGRAPHQL from '../../../graphql/SelfAssessment';
import { connect } from 'react-redux';
import ReactSelect from 'react-select';
import { MDBIcon } from 'mdbreact';
import CustomChart from './components/CustomChart';
import { data } from 'jquery';


const paletteColor = ["rgba(0, 159, 109, 0.2)","rgba(190, 0, 0, 0.2)","rgba(61, 0, 189, 0.2)","rgba(0, 65, 175, 0.2)","rgba(54, 162, 235, 0.2)","rgba(153, 102, 255, 0.2)","rgba(201, 203, 207, 0.2)", "rgba(74, 147, 124, 0.2)", "rgba(147, 147, 124, 0.2)", "rgba(147, 147, 235, 0.2)", " rgba(147, 196, 235, 0.2)", "rgba(0, 171, 184, 0.2)", "rgba(139, 171, 184, 0.2)", "rgba(139, 255, 184, 0.2)", "rgba(165, 197, 97, 0.2)", "rgba(165, 163, 152, 0.2)", "rgba(0, 0, 0, 0.2)", "rgba(0, 47, 0, 0.2)", "rgba(26, 47, 43, 0.2)", "rgba(255, 38, 214, 0.2)"]

const InsightChartAssessment = ({ companyType, companyId, language, companies }) => {
    const assStatus = [
        window.I18N("running"),
        window.I18N("expired"),
        window.I18N("completed")
    ]
    const assType = [
        window.I18N("periodic"),
        window.I18N("requested")
    ]
    const assPeriod = [
        window.I18N("annually"),
        window.I18N("half_year")
    ]
    const taskStatus = {
        "-2": window.I18N("expired"),
        "-1": window.I18N("redo"),
        "0": window.I18N("in_proccessing"),
        "1": window.I18N("submited"),
        "2": window.I18N("approved")
    }

    const dataMonthsSelect = [
        {
            label: window.I18N('last_3_month'),
            value: "92",
        },
        {
            label: window.I18N('last_6_month'),
            value: "183",
        }
    ]
    const [dataDaysOfMonth, setDataDaysMonth] = useState(dataMonthsSelect[0])

    const dataAssessmentRaw = useRef([])
    const [dataAssessment, setDataAssessment] = useState([])
    const [dataChart, setDataChart] = useState({ labels: [], datasets: [] })
  
    const handleSelectMonths = (value) => {
        setDataDaysMonth(value)
    }

    const handleOnHover = (chartElHover, chartElements) => {
        const data = []
        dataAssessment.forEach(el => {
            if (el.createdDate === dataChart.labels[chartElements[0]._index] && el.status === dataChart.datasets[chartElHover._datasetIndex].label) {
                const assessment = {
                    groups: el.groups.map(group => group.name[0].text),
                    period: el.period,
                    type: el.type,
                    focusedIndex: chartElements[0]._index
                }
                if (companyType === "COMPANY") {
                    assessment.task = el.task.filter(elTask => elTask.company._id === companyId).map(elTask2 => taskStatus[elTask2.status])
                } else {
                    if (companyId) {
                        assessment.task = el.task.map(elTask => elTask.company.information.name)
                    } else {
                        // khi chon theo location chi hien ra company thuoc location do
                        assessment.task = el.task.filter(elTask => companies.companyIds.includes(elTask.company._id)).map(elTask => elTask.company.information.name)
                    }
                    
                }
                data.push(assessment)
            }
        })
       return data
    }

    

    const groupDataforChart = (paramData) => {
        let groupDataChart = window.COMMON.getDataChartInPastYear({
            data: paramData,
            numberMonths: Math.floor(+dataDaysOfMonth.value/30),
            types: assStatus,
            itemKey: 'status',
            dateKey: 'createdDate',
            formatDateLabel: "MM/YYYY"
        });
        dataChart.labels = groupDataChart.labels
        dataChart.datasets = assStatus.map((el, index) => ({
            label: el,
            data: groupDataChart.data[index],
            backgroundColor: paletteColor[index],
            borderColor: paletteColor[index].replace('0.5', '1'),
            borderWidth: 2,
            barPercentage: 0.8,
        }))
        setDataChart({ ...dataChart })
    }

    const InnerHTMLPopup = (data) => {
        const dataType = {
            datasets: [{
                data: [0, 0],
                backgroundColor: paletteColor.slice(3)
            }],
            labels: assType
        }
        const dataPeriod = {
            datasets: [{
                data: [0, 0],
                backgroundColor: paletteColor.slice(6)
            }],
            labels: assPeriod
        }
        const dataQA = []
        const dataCompany = []
        const dataTask = {}
        let totalTask = 0

        data.forEach(element => {
            //groupData for Type chart
            dataType.datasets[0].data[assType.indexOf(element.type)]++
            //groupData for Period chart
            dataPeriod.datasets[0].data[assPeriod.indexOf(element.period)]++;
            //groupData for QA list
            element.groups.forEach(group => {
                if (!dataQA.includes(group)) {
                    dataQA.push(group)
                }
            })
            //groupData for company list
            element.task.forEach(el => {
                if (companyType === 'COMPANY') {
                    dataTask[el] = (dataTask[el] || 0) + 1
                    totalTask++
                } else {
                    if (!dataCompany.includes(el)) {
                        dataCompany.push(el)
                    }
                }
            })
        });
        return <div>
            <div className='d-flex align-items-center justify-content-around'>
                <Pie data={dataType} redraw={true} height={100} width={170} options={{
                    legend: {
                        position: 'bottom',
                        labels: {
                            boxWidth: 12,
                            fontSize: 10
                        }
                    },
                    responsive: false,
                    maintainAspectRatio: false
                }}
                />
                <Pie data={dataPeriod} redraw={true} height={100} width={170} options={{
                    legend: {
                        position: 'bottom',
                        labels: {
                            boxWidth: 12,
                            fontSize: 10
                        }
                    },
                    responsive: false,
                    maintainAspectRatio: false
                }} />
            </div>
            <div className='d-flex justify-content-around' >
                <div style={{ backgroundColor: "#F1F2F8", padding: "5px 15px", maxHeight: "200px", minHeight: "100px", width: "150px", overflow: "scroll", marginLeft: " 3px" }}>
                    <div style={{ fontWeight: 500 }}>{dataQA.length} {window.I18N("group_qa")}</div>
                    {dataQA.map((group, i) =>
                        <li key={i} style={{ fontSize: "11px", }}>{group}</li>
                    )}
                </div>
                <div style={{ backgroundColor: "#F1F2F8", padding: "5px 15px", maxHeight: "200px", minHeight: "100px", width: "150px", overflow: "scroll", marginLeft: " 3px" }}>
                    {companyType === 'COMPANY' ?
                        <div>
                            <div style={{ fontWeight: 500 }}>{totalTask} {window.I18N("task")}</div>
                            {Object.keys(dataTask).map((status, i) =>
                                <li key={i}>{status} : {dataTask[status]}</li>)}
                        </div>
                        : <div >
                            <div style={{ fontWeight: 500 }}>{dataCompany.length} {window.I18N("company")}</div>
                            {dataCompany.map((company, i) => <li key={i} style={{ fontSize: "11px", }}>
                                {company}
                            </li>)}
                        </div>
                    }
                </div>
            </div>
            <div className=" text-center mt-2" style={{ fontWeight: 500, fontSize: 14 }}>{language.code==='VI'&&window.I18N('assessment')} {language.code==='EN'&&window.I18N('assessment')} {window.I18N('in-month')} {dataChart.labels[data[0].focusedIndex]} : {data.length}</div>
        </div>
    }

    const formatDatawithLanguage = (data) => {
        const cloneData = JSON.parse(JSON.stringify(data));
        if (cloneData?.length) {
            cloneData.forEach(el => {
                el.status = assStatus[parseInt(el.status) - 1]
                el.type = assType[parseInt(el.type) - 1]
                el.period = assPeriod[parseInt(el.period) - 1]
                el.task = el.task.filter(el => el.type === "COMPANY")
            })
        }
        setDataAssessment(cloneData)
        groupDataforChart(cloneData)

    }

    const getDataAssessment = async (pastDays = '180') => {
        try {
            const startDate = window.COMMON_DATE.getDateFollowPastDate({ pastDays, mode: -1 });
            const params = {
                input: {
                    startDate,
                    endDate: window.COMMON_DATE.getStrDateFilter(new Date(), 1)
                }
            };
            if (companyId) {
                params.input[`${companyType.toLowerCase()}Ids`] = [companyId]
            } else {
                params.input.companyIds = companies.companyIds
            }
            const result = await window.COMMON.query(
                SelfAssessmentGRAPHQL.QUERY_ASSESSMENT_DATA_INSIGHT,
                params
            );
            if (result?.data?.assessmentDataInsightChart) {
                const data = window.COMMON.formatDate(result.data.assessmentDataInsightChart, 'createdDate', "MM/YYYY")
                dataAssessmentRaw.current = data
                formatDatawithLanguage(data)
            }
        } catch (err) {
            console.log(err)
        }
    }


    useLayoutEffect(() => {
        setTimeout(() => {
            if (companyId || companies.companyIds?.length) {
                getDataAssessment(dataDaysOfMonth.value)
            }
        }, 100);
        // eslint-disable-next-line
    }, [companies, companyId, dataDaysOfMonth]);

    useEffect(() => {
        if (dataAssessmentRaw.current?.length) {
            formatDatawithLanguage(dataAssessmentRaw.current)
        }
    }, [language])

    
    return <div className='mt-2 mx-auto '>
                <div className='w-full py-2 d-flex align-items-center justify-content-between'>
                    <h6 className="m-0 font-weight-bold" style={{ fontSize: "1rem" }}>{`Assessment Chart Insight`}</h6>     
                    <div className='mb-2 d-flex align-items-center justify-content-start' style={{width: '30%', marginLeft: '14%'}}>
                        <div className="input-group input-group-transparent">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <MDBIcon fa="true" icon="calendar" className="fa-fw" />
                                </span>
                            </div>
                            <ReactSelect
                                value={dataDaysOfMonth}
                                options={dataMonthsSelect}
                                className="md-form m-0"
                                placeholder={window.I18N("choose_option")}
                                onChange={handleSelectMonths}
                                styles={{ menuPortal: (base) => ({ ...base, fontSize: '14px'}) }}
                            />
                        </div>
                    </div>
                </div>
        <div className='d-flex align-items-center justify-content-center' style={{ position: 'relative' }}>
           <CustomChart dataChart={dataChart} createInnerHTMLPopup={InnerHTMLPopup} onDataHover={handleOnHover} displayLegend={true}/>
        </div>
        <div className='chart-title'>{`${window.I18N('assessment-chart')} ${dataDaysOfMonth.label}`}</div>
    </div>
}

const mapStateToProps = state => ({
    language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InsightChartAssessment);
