import React, { PureComponent } from 'react';
import $ from 'jquery';

class Slide extends PureComponent {

  componentDidMount() {
    $('#' + this.props.id).find('.carousel-indicators').find('li').first().addClass('active');
    $('#' + this.props.id).find('.carousel-inner').find('div.carousel-item').first().addClass('active');
  }

  render() {
    if (this.props.data.length === 0) {
      return <div></div>;
    }
    return (
      <div id={this.props.id} className="carousel slide carousel-fade" data-ride="carousel" style={this.props.style}>
        <ol className="carousel-indicators">
          {
            this.props.data.map((item, i) => <li key={i} data-target={'#' + this.props.id} data-slide-to={i} className={i === 0 ? 'active' : ''}></li>)
          }
        </ol>
        <div className="carousel-inner" role="listbox">
          {
            this.props.data.map((item, i) => <div key={i} className={i === 0 ? 'carousel-item active' : 'carousel-item'}>
              <div className="view w-100">
                <img className="d-block w-100 m-0" src={window.COMMON.setValueImage(item)} alt=""/>
                <div className="mask rgba-black-light"></div>
              </div>
            </div>)
          }
        </div>
        <a className="carousel-control-prev" href={'#' + this.props.id} role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href={'#' + this.props.id} role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    );
  }
}

export default Slide;