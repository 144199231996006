import React, { useState, useCallback, useLayoutEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { Grid } from '@material-ui/core';
import { MDBBtn, MDBIcon, MDBCardImage } from 'mdbreact';
import { useChatDataProvider } from '../../../context/ChatDataContext';

import ItemPost from '../../components/ItemPost';

import AccountGRAPHQL from '../../../graphql/Account';
import MetadataGRAPHQL from '../../../graphql/Metadata';
import RelationshipGRAPHQL from '../../../graphql/Relationship';
import PostGRAPHQL from '../../../graphql/Post';

export default function ProfilePage(props) {
  const history = useHistory();
  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const [object, setObject] = useState({ index: -1, isLoad: false, itemShare: null, isLoadMore: false, item: null, relationships: null });
  const [pagination, setPagination] = useState({ pageSize: 10, currentPage: 1 });
  const [data, setData] = useState([]);
  const [dataCategorySticker, setDataCategorySticker] = useState([]);
  const [dataShare, setDataShare] = useState({});
  const { setSelectedUserToChat } = useChatDataProvider();

  useLayoutEffect(() => {
    const params = queryString.parse(props.location.search);
    setTimeout(() => {
      if (params && params.id) {
        getDataProfile(params.id);
        checkRelationship(params.id);
        getDataPost(0, params.id);
        getDataSticker();
      }
    }, 100);
    // eslint-disable-next-line
  }, []);

  const getDataSticker = async () => {
    try {
      const params = {
        parentCode: '-1'
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_STICKERS, params, false);
      let data = [];
      if (result && result.data) {
        data = result.data.getStickers;
      }
      setDataCategorySticker(data);
    } catch (error) {
      window.COMMON.showErrorLogs('ProfilePage.getDataSticker');
    }
  };

  const getDataProfile = async (id) => {
    try {
      const params = {
        id
      };
      const result = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_BY_ID, params);
      if (result && result.data) {
        object.item = result.data.findAccountById;
        setObject(object);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('ProfilePage.getDataProfile');
    }
  };

  const checkRelationship = async (id) => {
    try {
      const params = {
        anyone: id
      };
      const result = await window.COMMON.query(RelationshipGRAPHQL.QUERY_CHECK_RELATIONSHIP_ACCOUNT, params);
      if (result && result.data) {
        object.status = window.COMMON.getValueFromAttr(result.data.checkRelationship, 'status');
      } else {
        object.status = 'VIEWER';
      }
      setObject(object);
    } catch (error) {
      window.COMMON.showErrorLogs('ProfilePage.checkRelationship');
    }
  };

  const getDataPost = async (mode, id) => {
    try {
      const params = {
        user: id || object.id,
        page: pagination.currentPage,
        pageSize: pagination.pageSize
      };
      const result = await window.COMMON.query(PostGRAPHQL.QUERY_MY_POSTS, params);
      if (result && result.data) {
        const dataPost = result.data.getMyPosts;
        let arrs = data;
        if (mode === 0) {
          arrs = [];
        }
        dataPost.forEach(item => {
          arrs.push(item);
        });
        setData(arrs);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('ProfilePage.getDataPost');
    }
    if (id) {
      object.id = id;
    }
    object.isLoad = true;
    object.isLoadMore = false;
    setObject({ ...object });
  };

  const loadMore = () => {
    object.isLoadMore = true;
    pagination.currentPage += 1;
    setObject(object);
    setPagination({ ...pagination });
    getDataPost(1);
  };

  const openSharePost = useCallback((item) => {
    object.itemShare = item;
    dataShare.text = '';
    dataShare.postId = item._id;
    dataShare.company = COMPANY_TYPE === window.CONSTANT.COMPANY ? window.COMMON.getValue(COMPANY_ID) : null;
    dataShare.union = COMPANY_TYPE === window.CONSTANT.UNION ? window.COMMON.getValue(COMPANY_ID) : null;
    dataShare.influencer = COMPANY_TYPE === window.CONSTANT.INFLUENCER ? window.COMMON.getValue(COMPANY_ID) : null;
    setObject(object);
    setDataShare({...dataShare});
    window.COMMON.showModal('#modal-share');
  }, [COMPANY_TYPE, COMPANY_ID, object, dataShare]);

  const doAddFriend = async (item) => {
    try {
      const params = {
        input: {
          id: item._id,
          name: item.nickname
        }
      };
      const result = await window.COMMON.mutation(RelationshipGRAPHQL.MUTATION_ADD_FRIEND, params);
      let status = -1;
      if (result && result.data && result.data.addFriend > 0) {
        object.status = 'PENDING';
        setObject({ ...object });
      }
    } catch (error) {
      window.COMMON.showErrorLogs('ProfilePage.doAddFriend');
    }
  };

  const doSendMessage = (object) => {
    setSelectedUserToChat(object);
    history.push('/chat');
  };

  if (!object.isLoad) {
    return <div></div>;
  }

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row" style={{ overflow: 'hidden' }}>
      <Grid container className="mt-3">
        <Grid item lg={3} md={2} xs={1}></Grid>
        <Grid item lg={6} md={8} xs={10}>
          <div className="container-form mb-4 wow zoomIn animated" data-wow-delay="0.5s" style={{ overflow: 'inherit', zIndex: 1 }}>
            <Grid container className="p-3">
              <Grid item xs={12} className="mb-2">
                { object.item ? <>
                  <div className="d-flex align-items-end">
                    <MDBCardImage className="image-logo" src={window.COMMON.setValueImage(object.item.avatar)} hover overlay="white-slight" style={{ borderRadius: '100%' }}/>
                    <div className="mr-auto ml-2">
                      <h4 className="font-weight-bold mb-1">{window.COMMON.getValueFromAttr(object.item, 'nickname')}</h4>
                      <p className="mb-0">{window.COMMON.getValueFromAttr(object.item, 'company.information.name') || window.COMMON.getValueFromAttr(object.item, 'influencer.information.name')}</p>
                      <p className="mb-0">{window.COMMON.getValueFromAttr(object.item, 'bio')}</p>
                    </div>
                    <div className="container-button">
                      <MDBBtn color="primary" type="button" onClick={() => doSendMessage(object.item)}>
                        <MDBIcon fa="true" icon="comment" className="fa-fw" /> {window.I18N('send_message')}
                      </MDBBtn>
                      {
                        object.status === 'ACCEPTED' ? <MDBBtn color="" type="button">
                          <MDBIcon fa="true" icon="user-plus" className="fa-fw" /> {window.I18N('friend')}
                        </MDBBtn>
                          : object.status === 'PENDING' ? <MDBBtn color="" type="button">
                          <MDBIcon fa="true" icon="user-plus" className="fa-fw" /> {window.I18N('friend_sent')}
                        </MDBBtn>
                          : <MDBBtn color="primary" type="button" onClick={() => doAddFriend(object.item)}>
                          <MDBIcon fa="true" icon="user-plus" className="fa-fw" /> {window.I18N('add_friend')}
                        </MDBBtn>
                      }
                    </div>
                  </div>
                </>: <></> }
              </Grid>
            </Grid>
          </div>
          <div className="container-post wow fadeIn animated" data-wow-delay="1s">
            {
              data.map((item, i) => (
                <ItemPost item={item} index={i} key={item._id} isView={true} className="col-md-12 p-0 mb-4" attr_id="_id" attr_content="text" attr_image="image" attr_avatar="createdBy.avatar" attr_name="createdBy.nickname" attr_time="createdDate"
                  handleSharePost={openSharePost} categorySticker={dataCategorySticker} ></ItemPost>
              ))
            }
            <div className="text-center">
              <MDBBtn color="" disabled={data.length < (pagination.currentPage * pagination.pageSize)} onClick={loadMore}>
                {window.I18N('load_more')}
                {
                  !object.isLoadMore ? <></> : <>
                    <span className="spinner-grow spinner-grow-sm ml-1" role="status" aria-hidden="true"></span>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  </>
                }
              </MDBBtn>
            </div>
          </div>
        </Grid>
        <Grid item lg={3} md={2} xs={1}></Grid>
      </Grid>
    </div>
  );
}