
import React, { useState, useCallback, useLayoutEffect } from 'react';
import Select from 'react-select';
import { MDBInputGroup, MDBCol, MDBRow, MDBCardImage, MDBIcon, MDBBtn, MDBInput } from 'mdbreact';

import Modal from '../../components/Modal';
import RadioBox from '../../components/RadioBox';
import AttributeCompany from '../../components/AttributeCompany';
import Pagination from '../../components/Pagination';

import CompanyGRAPHQL from '../../../graphql/Company';
import SelfAssessmentGRAPHQL from '../../../graphql/SelfAssessment';
import AccountGRAPHQL from '../../../graphql/Account';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';


export default function RequestInfluencerPage(props) {
  const { trackEvent } = useTracking();

  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);

  const types = [
    { value: 'COMPANY', label: window.I18N('company') },
    { value: 'UNION', label: window.I18N('union') },
    { value: 'FRIEND', label: window.I18N('friends') }
  ];
  const status = [
    { value: 'OPEN', label: window.I18N('open') },
    { value: 'ACCEPTED', label: window.I18N('accepted') },
    { value: 'PENDING', label: window.I18N('pending') }
  ];
  const [filter, setFilter] = useState({ text: '', company: null, type: window.CONSTANT.COMPANY, status: { value: 'OPEN', label: window.I18N('open') } });
  const [object, setObject] = useState({ index: -1, item: null, company: null });
  const [pagination, setPagination] = useState({ total: 0, pageSize: 10, currentPage: 0 });
  const [influencer, setInfluencer] = useState({});
  const [data, setData] = useState([]);
  const [dataFriend, setDataFriend] = useState([]);
  const [dataSave, setDataSave] = useState({});
  const [options, setOptions] = useState({ company: [] });
  const [dataAttributeReport, setDataAttributeReport] = useState([]);
  const [dataAttributeReportCompany, setDataAttributeReportCompany] = useState({});

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataInfluencer();
      getDataAttributeReport();
    }, 100);
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line
  }, []);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      document.getElementById('btn-search').click();
    }
  };

  const handleFilterChange = (event) => {
    filter.text = event.target.value;
    setFilter({ ...filter });
  };

  const handleChange = (event) => {
    if (event.target.className.indexOf('input-number') > -1) {
      dataSave[event.target.name] = parseInt(event.target.value);
    } else {
      dataSave[event.target.name] = event.target.value;
    }
    setDataSave({ ...dataSave });
  };

  const handleRadio = useCallback((value) => {
    filter.type = value;
    filter.text = '';
    pagination.currentPage = 0;
    pagination.total = 0;
    setPagination(pagination);
    setFilter({ ...filter });
    if (filter.type === 'FRIEND') {
      getDataFriend();
    } else {
      getDataCompanyAndRelationship();
    }
    // eslint-disable-next-line
  }, [filter]);

  const handleFilterSelect = (event, attr) => {
    filter[attr] = event;
    setFilter({ ...filter });
    if (filter.type !== 'FRIEND') {
      getDataCompanyAndRelationship();
    }
    if (attr === 'company') {
      pagination.currentPage = 0;
      pagination.total = 0;
      setPagination({ ...pagination });
      getDataCompanyDetail();
    }
  };

  const getDataInfluencer = async () => {
    try {
      if (!window.COMMON.checkRoleIsSystem()) {
        filter.company = { value: COMPANY_ID, label: '' };
        setFilter({ ...filter });
        getDataCompanyAndRelationship();
        getDataCompanyDetail();
        return;
      }
      const params = {
        type: window.CONSTANT.INFLUENCER
      };
      const result = await window.COMMON.query(CompanyGRAPHQL.QUERY_COMPANY, params);
      if (result && result.data) {
        options.company = window.COMMON.createDataSelect(result.data.getCompanies, '_id', 'information.name', 'information._id');
        filter.company = options.company && options.company.length > 0 ? options.company[0] : null;  
        setOptions(options);
        setFilter({ ...filter });
      } 
      getDataCompanyAndRelationship();
      getDataCompanyDetail();
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountPage.getDataInfluencer');
    }
  };

  const getDataCompanyAndRelationship = async (dataRequest) => {
    try {
      const params = {
        influencer: window.COMMON.getDataSelect(filter.company, false),
        accountType: filter.type,
        status: window.COMMON.getDataSelect(filter.status, false),
        text: filter.text,
        pageSize: pagination.pageSize,
        page: pagination.currentPage + 1
      };
      const result = await window.COMMON.query(CompanyGRAPHQL.QUERY_COMPANY_AND_REALTIONSHIP, params);
      let data = [];
      if (result && result.data && result.data.getCompaniesAndRelationshipInfluencer) {
        data = window.COMMON.formatMultiDate(result.data.getCompaniesAndRelationshipInfluencer.data, 'createdDate');
        pagination.total = result.data.getCompaniesAndRelationshipInfluencer.total;
      }
      setPagination(pagination);
      setData(data);
    } catch (error) {
      window.COMMON.showErrorLogs(`RequestInfluencerPage.getDataCompanyAndRelationship ${error}`);
    }
  };

  const changePage = useCallback((index) => {
    pagination.currentPage = index;
    setPagination({ ...pagination });
    getDataCompanyAndRelationship();
    // eslint-disable-next-line
  }, [object, pagination, filter]);

  const showItemDetails = useCallback((event, item) => {
    event.stopPropagation();
    object.item = item;
    setObject({ ...object });
    getDataCompanyInfo();
    getDataAttributeReportCompany();
    if (filter.type === 'FRIEND') {
      window.COMMON.showModal('#modal-friend');
    } else {
      window.COMMON.showModal('#modal-detail');
    }
  }, [object, filter]);

  const openSendRequestDialog = (event, index) => {
    event.stopPropagation();
    object.index = index;
    setObject(object);
    const obj = data[index];
    window.COMMON.showModal('#modal-save');
    if (obj.status === 'PENDING' && obj.isInfluencer) {
      window.saveMethod = acceptRequestCompanyJoinInfluencer;
    } else {
      window.saveMethod = sendRequestInfluencerJoinCompany;
    }
  };

  const openDeleteRequestDialog = (event, index) => {
    event.stopPropagation();
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteInfluencerJoinCompany;
  };

  const sendRequestInfluencerJoinCompany = async () => {
    try {
      const params = {
        influencer: window.COMMON.getDataSelect(filter.company, false),
        company: data[object.index].companyId
      };
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_REQUEST_INFLUENCER_JOIN_COMPANY, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_APPROVE)) {
        getDataCompanyAndRelationship();
        createTrackingEvent(eventName.REQUEST_INFLUENCER_JOIN_COMPANY);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestInfluencerPage.acceptAccount');
    }
  };

  const deleteInfluencerJoinCompany = async () => {
    try {
      const params = {
        id: data[object.index].relationId,
        companyType: filter.type
      };
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_DELETE_INFLUENCER_JOIN_COMPANY, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DECLINE)) {
        getDataCompanyAndRelationship();
        createTrackingEvent(eventName.DELETE_INFLUENCER_JOIN_COMPANY);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestInfluencerPage.declineAccount');
    }
  };

  // REQUEST FRIEND
  const getDataFriend = async () => {
    try {
      const params = {
        pageSize: pagination.pageSize,
        page: pagination.currentPage + 1
      };
      const result = await window.COMMON.query(AccountGRAPHQL.QUERY_PENDING_FRIEND_BY_USER_WITH_FILTER, params);
      let data = [];
      if (result && result.data && result.data.getCompaniesAndRelationshipInfluencer) {
        data = window.COMMON.formatMultiDate(result.data.getCompaniesAndRelationshipInfluencer.data, 'createdDate');
        data.forEach(item => {
          item.createdBy.createdDate = window.COMMON_DATE.formatDate(item.createdDate);
          item.createdBy.birthday = window.COMMON_DATE.formatDate(item.createdBy.birthday);
        });
        pagination.total = result.data.getCompaniesAndRelationshipInfluencer.total;
      }
      setPagination(pagination);
      setDataFriend(data);
    } catch (error) {
      window.COMMON.showErrorLogs('RequestInfluencerPage.getDataFriend');
    }
  };

  const acceptFriend = async (event, index) => {
    try {
      event.stopPropagation();
      const params = {
        input: {
          id: window.COMMON.getValueIdFromAttr(data[index], '_id'),
          name: localStorage.getItem(window.CONSTANT.FULL_NAME)
        }
      };
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_ACCEPT_FRIEND, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
        getDataFriend();
        createTrackingEvent(eventName.ACCEPT_FRIEND);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestInfluencerPage.acceptFriend');
    }
  };

  const declineFriend = async (event, index) => {
    try {
      event.stopPropagation();
      const params = {
        accountId: window.COMMON.getValueIdFromAttr(data[index], '_id')
      };
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_DECLINE_FRIEND, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataFriend();
        createTrackingEvent(eventName.DECLINE_FRIEND);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestInfluencerPage.declineFriend');
    }
  };

  // FACILITY INFO
  const getDataCompanyInfo = async () => {
    try {
      const params = { 
        company: object.item.companyId, 
        companyType: filter.type 
      };
      const result = await window.COMMON.query(AccountGRAPHQL.QUERY_COMPANY_INFO, params);
      if (result && result.data) {
        object.company = result.data.getCompanyAccountInfo;
        setObject(object);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('AttributeCompany.getDataCompanyInfo');
    }
  };

  const saveAttributeCompany = async (data) => {
    try {
      const params = {
        input: {
          company: object.item.companyId,
          items: data
        }
      };
      window.COMMON.trimData(params);
      if (!object.idAttribute) {
        const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_ADD_ATTRIBUTE_REPORT_COMPANY, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataAttributeReportCompany();
          window.COMMON.backContainerData();
		      createTrackingEvent(eventName.ADD_ATTRIBUTE_REPORT_COMPANY);
        }
      } else {
        params.input._id = object.idAttribute;
        const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_EDIT_ATTRIBUTE_REPORT_COMPANY, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataAttributeReportCompany();
          window.COMMON.backContainerData();
		      createTrackingEvent(eventName.EDIT_ATTRIBUTE_REPORT_COMPANY);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RewardPage.saveReward');
    }
  };

      const openSaveAttributeCompany = useCallback((data) => {
        window.COMMON.showModal('#modal-save');
        window.saveMethod = () => saveAttributeCompany(data);
      });
      
      const closeAttributeCompany = () => {
        window.COMMON.showModal('#modal-detail');
        window.COMMON.hideModal('#modal-companying');
  };

  const getDataAttributeReportCompany = async () => {
    try {
      const params = {
        company: object.item.companyId
      };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_ATTRIBUTE_REPORT_COMPANY, params);
      if (result && result.data && result.data.findAttributeReportCompany) {
        const data = result.data.findAttributeReportCompany;
        object.idAttribute = data._id;
        setDataAttributeReportCompany(data);
      } else {
        object.idAttribute = null;
        setDataAttributeReportCompany({});
      }
      setObject(object);
    } catch (error) {
      window.COMMON.showErrorLogs('RequestInfluencerPage.getDataAttributeReportCompany');
    }
  };

  const getDataAttributeReport = async () => {
    try {
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_ATTRIBUTE_REPORT, null);
      if (result !=null) {
        let index = 0;
        result.data.getAttributeReports.forEach(item => {
          item.idx = index;
          index++;
        });
        setDataAttributeReport(result.data.getAttributeReports);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestInfluencerPage.getDataAttributeReport');
    }
  };

  const showAttributeReportCompany = () => {
    window.COMMON.hideModal('#modal-detail');
    window.COMMON.showModal('#modal-companying');
  };

  // Gopies and sub question
  const getDataCompanyDetail = async () => {
    try {
      const params = {
        companyId: window.COMMON.getDataSelect(filter.company, false)
      };
      const result = await window.COMMON.query(CompanyGRAPHQL.QUERY_COMPANY_BY_ID, params, false);
      if (result && result.data) {
        setInfluencer(result.data.getCompaniesById);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestInfluencerPage.getDataCompanyDetail');
    }
  };

  const openGopyAndSubQuestion = useCallback(() => {
    window.COMMON.showModal('#modal-update-gopy-and-sub-question');
    const dataSave = {};
    dataSave._id = window.COMMON.getValueFromAttr(influencer, '_id');
    dataSave.gopy = window.COMMON.getValueFromAttr(influencer, 'gopy', 0);
    dataSave.subQuestion1 = window.COMMON.getValueFromAttr(influencer, 'subQuestion1', '');
    dataSave.subQuestion2 = window.COMMON.getValueFromAttr(influencer, 'subQuestion2', '');
    dataSave.subQuestion3 = window.COMMON.getValueFromAttr(influencer, 'subQuestion3', '');
    setDataSave({ ...dataSave });
  }, [influencer]);

  const saveGopyAndSubQuestion = async () => {
    try {
      const params = {
        input: dataSave
      }
      const result = await window.COMMON.mutation(CompanyGRAPHQL.MUTATION_UPDATE_GOPY_AND_SUB_QUESTION, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
        getDataCompanyDetail();
        createTrackingEvent(eventName.UPDATE_GOPY_AND_SUB_QUESTION_RELATIONSHIP_FOR_INFLUENCER);
        window.COMMON.hideModal('#modal-update-gopy-and-sub-question');
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestInfluencerPage.getDataAttributeReport');
    }
  };

  const acceptRequestCompanyJoinInfluencer = async () => {
    try {
      const obj = data[object.index];
      const params = {
        influencer: filter.company && filter.company.value,
        company: window.COMMON.getValueFromAttr(obj, 'companyId'),
        companyUser: window.COMMON.getValueFromAttr(obj, '_id'),
      };
      const result = await window.COMMON.mutation(CompanyGRAPHQL.MUTATION_ACCEPT_INFLUENCER_RELATIONSHIP_FOR_COMPANY, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_APPROVE)) {
        getDataCompanyAndRelationship();
        createTrackingEvent(eventName.ACCEPT_COMPANY_RELATIONSHIP_FOR_INFLUENCER);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestInfluencerPage.acceptRequestCompany');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">  
      <MDBRow className="wow fadeIn animated" data-wow-delay="1s">
        <MDBCol>
          <ul className="list-group">
            <li className="list-group-item">
              <div className="d-flex align-items-center justify-content-end">
                {
                  !window.COMMON.checkRoleIsSystem() ? <div></div> : <div className="bd-highlight" style={{ width: '20%' }}>
                    <div className="input-group input-group-transparent" style={{ flexWrap: 'nowrap' }}>
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <MDBIcon fa="true" icon="building" className="fa-fw" />
                        </span>
                      </div>
                      <Select isDisabled={filter.type && filter.type === 'FRIEND'} className="md-form m-0" value={filter.company} options={options.company} onChange={(event) => handleFilterSelect(event, 'company')} isSearchable/>
                    </div>
                  </div>
                }
                {
                  filter.type === 'FRIEND' ? <div className="ml-auto"></div> : <div className="bd-highlight ml-auto mr-3" style={{ width: '20%' }}>
                    <MDBInputGroup type="text" value={filter.text} hint={filter.type === 'FRIEND' ? window.I18N('search_nickname') : window.I18N('search_organization_email_phone')} onChange={handleFilterChange} maxLength="100" pattern="\S(.*\S)?" required
                      append={
                        <span className="input-group-text cursor-pointer" id="btn-search" onClick={() => getDataCompanyAndRelationship()}>
                          <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                        </span>
                      }
                    />
                  </div>
                }
                {
                  filter.type === 'FRIEND' ? <></> : <div className="mr-3" style={{ width: '20%' }}>
                    <div className="input-group input-group-transparent" style={{ flexWrap: 'nowrap' }}>
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <MDBIcon fa="true" icon="filter" className="fa-fw" />
                        </span>
                      </div>
                      <Select isDisabled={filter.type && filter.type === 'FRIEND'} className="md-form m-0" value={filter.status} options={status} onChange={(event) => handleFilterSelect(event, 'status')} isSearchable/>
                    </div>
                  </div>
                }
                <div className="d-inline-block mr-0">
                  <RadioBox data={types} value={filter.type} className="m-0" handleChange={handleRadio}></RadioBox>
                </div>
                <div>
                  <MDBBtn color="" type="button" className="btn ml-3 mr-0" onClick={openGopyAndSubQuestion}>
                    <MDBIcon fa="true" icon="info-circle" className="fa-fw" /> {window.I18N('info')}
                  </MDBBtn>
                </div>
              </div>
            </li>
            {
              filter.type === 'FRIEND' ? <></> : data.map((item, i) => <li className="list-group-item" key={i}>
                <div className="d-flex align-items-center w-100">
                  <div className="w-100 pl-2 pr-2" onClick={(event) => showItemDetails(event, item)}>
                    <MDBRow>
                      <MDBCol className="col-3">
                        <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, 'avatar'))} className="image-icon rounded-circle mr-2"/>
                        <span>{window.COMMON.getValueFromAttr(item, 'name')}</span>
                      </MDBCol>
                      <MDBCol className="col-3">
                        <p className="mt-2 mb-0"><MDBIcon className="fa-fw mr-1" fa="true" icon="envelope"></MDBIcon>{window.COMMON.getValueFromAttr(item, 'email')}</p>
                        <p className="mt-2 mb-2"><MDBIcon className="fa-fw mr-1" fa="true" icon="phone"></MDBIcon>{window.COMMON.getValueFromAttr(item, 'phone')}</p>
                      </MDBCol>
                      <MDBCol className="col-4">
                        <p className="mt-2 mb-0"><i>{window.COMMON.getValueFromAttr(item, 'address')}</i></p>
                        <p className="mt-2 mb-2 text-muted">{window.I18N('start_date')}: {window.COMMON.getValueFromAttr(item, 'createdDate')}</p>
                      </MDBCol>
                      <MDBCol className="col-2 text-right">
                      {
                        window.COMMON.getDataSelect(filter.status, false) === 'PENDING' ?
                          <MDBBtn className="btn btn-sm mt-2 mb-0 mr-0 btn-list" type="button" color="warning" onClick= {(event) => openDeleteRequestDialog(event, i)}>
                            <MDBIcon fa="true" icon="ban" className="fa-fw"></MDBIcon> {window.I18N('cancel')}
                          </MDBBtn> 
                        : window.COMMON.getDataSelect(filter.status, false) === 'ACCEPTED' 
                          ? <MDBBtn className="btn btn-sm mt-2 mb-0 mr-0 btn-list" type="button" color="danger" onClick= {(event) => openDeleteRequestDialog(event, i)}>
                            <MDBIcon fa="true" icon="trash-alt" className="fa-fw"></MDBIcon> {window.I18N('delete')}
                          </MDBBtn>
                          : <MDBBtn className="btn btn-sm mt-2 mb-0 mr-0 btn-list" type="button" color="primary" onClick= {(event) => openSendRequestDialog(event, i)}>
                            <MDBIcon fa="true" icon="paper-plane" className="fa-fw"></MDBIcon> {window.I18N('request')}
                          </MDBBtn>
                      }
                      </MDBCol>
                    </MDBRow>
                  </div>
                </div>
              </li>)
            }
            {
              filter.type !== 'FRIEND' ? <></> : dataFriend.map((item, i) => <li className="list-group-item" key={i}>
                <div className="d-flex align-items-center w-100">
                {
                  <div className="d-flex align-items-center w-100 pl-2 pr-2" onClick={(event) => showItemDetails(event, item)}>
                    <div className="w-100 mr-auto">
                      <MDBRow>
                        <MDBCol className="col-4">
                          <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, 'avatar'))} className="image-icon rounded-circle mr-2"/>
                          <span>{window.COMMON.getValueFromAttr(item, 'nickname')}</span>
                        </MDBCol>
                        <MDBCol className="col-4">
                          <span><MDBIcon className="fa-fw mr-1" fa="true" icon="transgender-alt"></MDBIcon>{window.COMMON.getValueFromAttr(item, 'gender') === 0 ? window.I18N('female') : window.I18N('male')}</span><br></br>
                          <span><MDBIcon className="fa-fw mr-1" fa="true" icon="birthday-cake"></MDBIcon>{window.COMMON.getValueFromAttr(item, 'birthday')}</span><br></br>
                          <span className="text-muted">{window.I18N('request_date')}: {window.COMMON.getValueFromAttr(item, 'createdDate')}</span>
                        </MDBCol>
                        <MDBCol className="col-4">
                          <MDBBtn className="btn btn-sm mt-2 mb-0 mr-0 btn-list" type="button" color="danger" onClick= {(event) => declineFriend(event, i)}>
                            <MDBIcon fa="true" icon="times" className="fa-fw"></MDBIcon> {window.I18N('cancel')}
                          </MDBBtn>
                          <MDBBtn className="btn btn-sm mt-2 mb-0 mr-0 btn-list" type="button" color="success" onClick= {(event) => acceptFriend(event, i)}>
                            <MDBIcon fa="true" icon="check" className="fa-fw"></MDBIcon> {window.I18N('accept')}
                          </MDBBtn> 
                        </MDBCol>
                      </MDBRow>
                    </div>
                    <span className="text-muted"><small>{window.COMMON.getValueFromAttr(item, 'createdDate')}</small></span>
                  </div>
                }
                </div>
              </li>)
            }
            <li className="list-group-item pt-3">
              <Pagination total={pagination.total} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={changePage}></Pagination>
            </li>
          </ul>
        </MDBCol>
      </MDBRow>

      <Modal id="modal-detail" className="modal-lg" title={window.COMMON.getValueFromAttr(object.item, 'name')} hideSave={true}>
        <MDBRow>
          <MDBCol md="3" sm="4" xs="12" className="text-center">
            <MDBCardImage cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(object.item, 'avatar'))} className="image-logo"/>
          </MDBCol>
          <MDBCol md="9" sm="8" xs="12">
            <table className="table table-view mb-0">
              { !object.item ? <tbody></tbody> : <tbody>
                <tr>
                  <td width="30%">{window.I18N('organization_name')}</td>
                  <td width="70%">{object.item.name}</td>
                </tr>
                <tr>
                  <td>{window.I18N('email')}</td>
                  <td>{object.item.email}</td>
                </tr>
                <tr>
                  <td>{window.I18N('phone_number')}</td>
                  <td>{object.item.phone}</td>
                </tr>
                <tr>
                  <td>{window.I18N('country')}</td>
                  <td>{window.COMMON.getValueFromAttr(object.item, 'nation.name')}</td>
                </tr>
                <tr>
                  <td>{window.I18N('state_city')}</td>
                  <td>{window.COMMON.getValueFromAttr(object.item, 'state.name')}</td>
                </tr>
                <tr>
                  <td>{window.I18N('city_district')}</td>
                  <td>{window.COMMON.getValueFromAttr(object.item, 'city.name')}</td>
                </tr>
                <tr>
                  <td>{window.I18N('address')}</td>
                  <td>{object.item.address}</td>
                </tr>
                <tr>
                  <td>{window.I18N('organization_type')}</td>
                  <td>{window.COMMON.getValueFromAttr(object.item, 'companyType.value')}</td>
                </tr>
                <tr>
                  <td>{window.I18N('headcounts')}</td>
                  <td>{window.COMMON.getValueFromAttr(object.item, 'companySize.value')}</td>
                </tr>
                <tr>
                  <td>{window.I18N('industry')}</td>
                  <td>{window.COMMON.getValueFromAttr(object.item, 'companyIndustry.value')}</td>
                </tr>
              </tbody>
              }
            </table>
            {
              object.item && object.item.hasGopyAndSubQuestion ? <div className="p-2">
                <h5 className="mb-2 font-weight-bold">{window.I18N('request_info')}</h5>
                <p className="mb-2"><strong>{window.I18N("need_gopies")}: </strong> <span className="font-weight-bold">{window.COMMON.getValueFromAttr(object.item, 'gopy', 0)}</span></p>
                <p className="mb-2">{window.I18N("question_privacy")}</p>
                {
                  object.item && object.item.subQuestion1 ? <>
                    <p className="mb-2"><strong>{window.I18N('question')}: </strong> {window.COMMON.getValueFromAttr(object.item, 'subQuestion1', '')}</p>
                    <p className="mb-2"><strong>{window.I18N('answer')}: </strong> {window.COMMON.getValueFromAttr(object.item, 'subAnswer1', '')}</p>
                  </> : <></>
                }
                {
                  object.item && object.item.subQuestion2 ? <>
                    <p className="mb-2"><strong>{window.I18N('question')}: </strong> {window.COMMON.getValueFromAttr(object.item, 'subQuestion2', '')}</p>
                    <p className="mb-2"><strong>{window.I18N('answer')}: </strong> {window.COMMON.getValueFromAttr(object.item, 'subAnswer2', '')}</p>
                  </> : <></>
                }
                {
                  object.item && object.item.subQuestion3 ? <>
                    <p className="mb-2"><strong>{window.I18N('question')}: </strong> {window.COMMON.getValueFromAttr(object.item, 'subQuestion3', '')}</p>
                    <p className="mb-2"><strong>{window.I18N('answer')}: </strong> {window.COMMON.getValueFromAttr(object.item, 'subAnswer3', '')}</p>
                  </> : <></>
                }
              </div> : <></>
            }
            {
              object.item && object.item.status === "ACCEPTED" ? <MDBBtn color="" className="mt-2" onClick={showAttributeReportCompany}>
                <MDBIcon fa="true" icon="info-circle" className="fa-fw" /> {window.I18N('facility_info')}
              </MDBBtn> : <></>
            }
          </MDBCol>
        </MDBRow>
      </Modal>

      <Modal id="modal-friend" className="modal-lg" title={window.COMMON.getValueFromAttr(object.item, 'name')} hideSave={true}>
        <MDBRow>
          <MDBCol md="3" sm="4" xs="12" className="text-center">
            <MDBCardImage cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(object.item, 'avatar'))} className="image-logo"/>
          </MDBCol>
          <MDBCol md="9" sm="8" xs="12">
            <table className="table table-view mb-0">
              {
                !object.item ? <tbody></tbody> : <tbody>
                  <tr>
                    <td width="30%">{window.I18N('name')}</td>
                    <td width="70%">{object.item.name}</td>
                  </tr>
                  <tr>
                    <td>{window.I18N('nickname')}</td>
                    <td>{object.item.nickname}</td>
                  </tr>
                  <tr>
                    <td>{window.I18N('email')}</td>
                    <td>{object.item.email}</td>
                  </tr>
                  <tr>
                    <td>{window.I18N('phone_number')}</td>
                    <td>{object.item.phone}</td>
                  </tr>
                  <tr>
                    <td>{window.I18N('gender')}</td>
                    <td>{object.item.gender === 0 ? window.I18N('female') : window.I18N('male')}</td>
                  </tr>
                  <tr>
                    <td>{window.I18N('birthday')}</td>
                    <td>{window.COMMON_DATE.formatDate(object.item.birthday, 'DD-MM-YYYY')}</td>
                  </tr>
                </tbody>
              }
            </table>
          </MDBCol>
        </MDBRow>
      </Modal>

      <Modal id="modal-companying" className="modal-lg" title={window.COMMON.getValueFromAttr(object.item, 'name')} hideSave={true}>
        {object.item && <AttributeCompany 
          companyId={object.item.companyId}
          data={dataAttributeReport} 
          company={object.company} 
          dataCompany={dataAttributeReportCompany.items || []} 
          handleSave={openSaveAttributeCompany} 
          handleClose={closeAttributeCompany}
        />}      
      </Modal>

      <Modal id="modal-update-gopy-and-sub-question" className="modal-lg" title={window.I18N('create_update_data')} saveEvent={saveGopyAndSubQuestion}>
        <form id="form-update-gopy-and-sub-question" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <MDBInput outline containerClass="mt-0" value={dataSave.gopy} name="gopy" onChange={handleChange} type="number" className="input-number" label={window.I18N("need_gopies")} maxLength="4" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <p className="mb-4">{window.I18N("question_privacy")}</p>
              <MDBInput outline containerClass="mt-0" value={dataSave.subQuestion1} name="subQuestion1" onChange={handleChange} type="textarea" label={window.I18N('question') + ' 1'} maxLength="1000" pattern="\S(.*\S)?">
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline containerClass="mt-0" value={dataSave.subQuestion2} name="subQuestion2" onChange={handleChange} type="textarea" label={window.I18N('question') + ' 2'} maxLength="1000" pattern="\S(.*\S)?">
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline containerClass="mt-0 mb-0" value={dataSave.subQuestion3} name="subQuestion3" onChange={handleChange} type="textarea" label={window.I18N('question') + ' 3'} maxLength="1000" pattern="\S(.*\S)?">
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>

    </div>
  );
}