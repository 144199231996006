import React, { useImperativeHandle, useRef, useMemo, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { MDBCol, MDBIcon, MDBInput, MDBRow } from 'mdbreact';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { differenceInCalendarDays } from 'date-fns';

import Common from '../../../../utils/Common';
import Constant from '../../../../utils/Constant';

const EditCaseForm = React.forwardRef((props, ref) => {
  const { formData, handleChange, options, handleSelect, language } = props;

  const [accountCompanies, setAccountCompanies] = useState([]);

  const formRef = useRef();
  useImperativeHandle(ref, () => {
    return {
      validateForm
    };
  }, [formData]);

  const validateForm = (formData) => {
    if (!isValidDueDate()) {
      Common.showMessage('error', 'MSG_CODE_068', window.I18N('MSG_CODE_068'));
      return false;
    }
    const isValid = [
      Common.checkFormValidation('edit-case-form', formRef.current),
      // Common.checkSelectValidation('select-assessment', formData.assessment)
      Common.checkSelectValidation('select-companies', formData.companies)
    ];
    console.log(isValid);
    return isValid.every(v => v);
  };

  const onFieldChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (handleChange) {
      handleChange(name, value);
    }
  };

  const onDatePickerChange = (fieldName, value) => {
    if (handleChange) {
      handleChange(fieldName, value);
    }
  };

  const onSelectChange = (fieldName, value, isMultiple = false) => {
    if (handleSelect) {
      handleSelect(fieldName, value, isMultiple);
    }
  };
  
  const isValidDueDate = useCallback(() => {
    return differenceInCalendarDays(new Date(formData.approvalReminderDate), new Date()) >= 0;
  }, [formData.approvalReminderDate]);

  const filterCompanyOptions = useMemo(
    () => options.companies.filter(item => {
      const andConditions = [];

      andConditions.push(accountCompanies.includes(item._id));

      if (formData.nation) {
        andConditions.push(item.information.nation && item.information.nation._id === formData.nation.value);
      }
      if (formData.state) {
        andConditions.push(item.information.city && item.information.state._id === formData.state.value);
      }
      if (formData.city) {
        andConditions.push(item.information.state && item.information.city._id === formData.city.value);
      }
      if (formData.companyIndustry) {
        andConditions.push(item.information.companyIndustry && item.information.companyIndustry._id === formData.companyIndustry._id);
      }

      return andConditions.every(v => v);
    }),
    [options.companies, formData.nation, formData.city, formData.state, formData.companyIndustry]
  );

  useEffect(() => {
    // get current user companies
    const companies = localStorage.getItem(Constant.ACCOUNT_COMPANIES);
    if (companies) {
      const companiesArr = JSON.parse(companies);
      if (companiesArr?.length)
      {
        setAccountCompanies(companiesArr);
      }
    }
  }, []);

  return (
    <div className="container-top wow fadeInUp animated" data-wow-delay="0.5s">
      <form id="edit-case-form" className="mt-2" noValidate ref={formRef}>
        <MDBRow>
          <MDBCol sm="6">
            <MDBInput outline containerClass="mt-0" type="text" label={window.I18N('name') + ' *'} name="name" value={formData.name} onChange={onFieldChange} maxLength="100" pattern="\S(.*\S)?" required>
              <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
            </MDBInput>
            <MDBRow>
              <MDBCol>
                <MDBInput disabled outline containerClass="m-0" type="text" label={window.I18N('code') + ' *'} name="code" value={formData.code} onChange={onFieldChange} maxLength="50" pattern="\S(.*\S)?" required>
                  <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                </MDBInput>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className='mt-4'>
                <div className='form-group'>
                  <div className="input-group input-group-transparent">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                      </span>
                      <span className="input-group-text">{window.I18N('case_due_date')}</span>
                    </div>
                    <DatePicker 
                      title={window.I18N('case_due_date')}
                      placeholderText={window.I18N('case_due_date')}
                      className={`form-control ${isValidDueDate() ? 'is-valid' : 'is-invalid'}`}
                      dateFormat="dd/MM/yyyy" 
                      selected={formData.approvalReminderDate}
                      minDate={new Date()}
                      onChange={(date) => onDatePickerChange('approvalReminderDate', date)} 
                      showYearDropdown 
                      showMonthDropdown 
                      required 
                    />
                    {!isValidDueDate() && (
                      <div className="invalid-feedback">{window.I18N('MSG_CODE_068')}</div>
                    )}
                  </div>
                </div >
              </MDBCol>
            </MDBRow>
            {Common.checkRoleIsSystem() && (
              <Select
                id="select-influencer"
                value={formData.influencer}
                options={options.influencers}
                getOptionLabel={(option) => option.information.name}
                getOptionValue={(option) => option._id}
                placeholder={window.I18N('influencer')}
                className="md-form"
                onChange={(value) => onSelectChange('influencer', value)}
                isSearchable
              />
            )}
          </MDBCol>
          <MDBCol sm="6">
            <Select
              id="select-industry"
              value={formData.companyIndustry}
              options={options.industries}
              getOptionValue={(option) => option._id}
              getOptionLabel={(option) => option.value}
              placeholder={window.I18N('industry')}
              className="md-form mt-0"
              onChange={(value) => onSelectChange('companyIndustry', value)}
              isSearchable isClearable
            />
            <Select
              id="select-nation"
              value={formData.nation}
              options={options.nations}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              placeholder={window.I18N('country')}
              className="md-form mt-0"
              onChange={(value) => onSelectChange('nation', value)}
              isSearchable isClearable
            />
            <MDBRow>
              <MDBCol sm="6">
                <Select
                  id="select-city"
                  value={formData.state}
                  options={options.states}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  placeholder={window.I18N('state_city')}
                  className="md-form mt-0"
                  onChange={(value) => onSelectChange('state', value)}
                  isSearchable isClearable
                />
              </MDBCol>
              <MDBCol sm="6">
                <Select
                  id="select-state"
                  value={formData.city}
                  options={options.cities}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  placeholder={window.I18N('city_district')}
                  className="md-form mt-0"
                  onChange={(value) => onSelectChange('city', value)}
                  isSearchable isClearable
                />
              </MDBCol>
            </MDBRow>
            <Select
              id="select-companies"
              value={formData.companies}
              options={filterCompanyOptions}
              getOptionLabel={(option) => option.information.name}
              getOptionValue={(option) => option._id}
              placeholder={`${window.I18N('companies')} *`}
              className="md-form mt-0"
              onChange={(values) => onSelectChange('companies', values, true)}
              isSearchable isMulti closeMenuOnSelect={false}
            />
            {/* <Select id="select-approver" value={formData.approver} options={options.approvers} placeholder={window.I18N('approver') + ' *'} className="md-form" onChange={(event) => handleSelect.(event, 'select-approver', 'approver')} isSearchable /> */}
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="6">
            <MDBInput
              outline
              containerClass="mt-0"
              type="text"
              label={window.I18N('name') + ' *'}
              name="assessmentName"
              value={formData.assessmentName}
              onChange={onFieldChange}
              maxLength="100"
              pattern="\S(.*\S)?"
              required
            >
              <div className="invalid-feedback">
                {window.I18N('MSG_CODE_029')}
              </div>
            </MDBInput>
            <MDBRow>
              <MDBCol>
                <MDBInput
                  outline
                  containerClass="m-0"
                  type="text"
                  label={window.I18N('code') + ' *'}
                  name="assessmentCode"
                  value={formData.assessmentCode}
                  onChange={onFieldChange}
                  maxLength="50"
                  pattern="\S(.*\S)?"
                  required
                >
                  <div className="invalid-feedback">
                    {window.I18N('MSG_CODE_029')}
                  </div>
                </MDBInput>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mt-4'>
              <MDBCol>
                <div className="input-group input-group-transparent">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon
                        fa="true"
                        icon="calendar-alt"
                        className="fa-fw"
                      />
                    </span>
                    <span className="input-group-text">{window.I18N('start_date')}</span>
                  </div>
                  <DatePicker
                    placeholderText={window.I18N('start_date') + ' *'}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    selected={formData.assessmentStartDate}
                    startDate={formData.assessmentStartDate}
                    endDate={formData.assessmentEndDate}
                    minDate={new Date()}
                    onChange={(value) => onDatePickerChange('assessmentEndDate', value)}
                    showYearDropdown
                    showMonthDropdown
                    selectsStart
                    required
                  />
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mt-4'>
              <MDBCol>
                <div className="input-group input-group-transparent">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon
                        fa="true"
                        icon="calendar-alt"
                        className="fa-fw"
                      />
                    </span>
                    <span className="input-group-text">{window.I18N('end_date')}</span>
                  </div>
                  <DatePicker
                    placeholderText={window.I18N('end_date') + ' *'}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    selected={formData.assessmentEndDate}
                    startDate={formData.assessmentStartDate}
                    endDate={formData.assessmentEndDate}
                    minDate={new Date()}
                    onChange={(value) => onFieldChange('assessmentEndDate', value)}
                    showYearDropdown
                    showMonthDropdown
                    selectsEnd
                    required
                  />
                </div>
              </MDBCol>
            </MDBRow>
            <MDBInput
              outline
              containerClass="mb-0"
              type="textarea"
              label={window.I18N('description')}
              name="assessmentDescription"
              value={formData.assessmentDescription}
              onChange={onFieldChange}
              maxLength="5000"
            ></MDBInput>
          </MDBCol>
          <MDBCol sm="6">
            <Select
              id="select-advisors"
              value={formData.advisors}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option._id}
              options={options.advisors}
              placeholder={window.I18N('assign_influencer')}
              className="md-form mt-0"
              onChange={(values) =>
                onSelectChange('advisors', values, true)
              }
              isSearchable
              isMulti
              closeMenuOnSelect={false}
            />
            <Select
              id="select-type"
              className="md-form mt-0"
              value={formData.assessmentType}
              placeholder={window.I18N('type') + ' *'}
              options={options.types}
              onChange={(value) => onSelectChange('assessmentType', value)}
              isSearchable
            />
            <Select
              id="select-period"
              className="md-form"
              value={formData.assessmentPeriod}
              placeholder={window.I18N('period') + ' *'}
              options={options.periods}
              onChange={(value) =>
                onSelectChange('assessmentPeriod', value)
              }
              isSearchable
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12">
            <MDBInput outline containerClass="mb-0" type="textarea" label={window.I18N('reason')} name="reason" value={formData.reason} onChange={onFieldChange} maxLength="5000"></MDBInput>
            <MDBInput outline containerClass="mb-0" type="textarea" label={window.I18N('goal')} name="goal" value={formData.goal} onChange={onFieldChange} maxLength="5000"></MDBInput>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12">
            <h4 className='text-bold mt-5 mb-2'>{window.I18N('selected_group_qa')}</h4>
          </MDBCol>
          <MDBCol sm="9">
            <Select
              id="select-group-qa"
              value={formData.assessmentGroupQA}
              options={options.groupQA}
              getOptionValue={(option) => option._id}
              getOptionLabel={(option) => option.name ? Common.getValueWithLanguage(option, 'name', language) : option.code }
              placeholder={window.I18N('selected_group_qa')}
              className="md-form"
              onChange={(values) => onSelectChange('assessmentGroupQA', values, true)}
              isMulti
              isSearchable 
              isClearable
              closeMenuOnSelect={false}
              menuPlacement='top'
            />
          </MDBCol>
        </MDBRow>
      </form>
    </div>
  );
});
EditCaseForm.propTypes = {
  formData: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
  handleSelect: PropTypes.func,
  options: PropTypes.shape({
    industries: PropTypes.array,
    advisors: PropTypes.array,
    assessments: PropTypes.array
  })
};

export default EditCaseForm;