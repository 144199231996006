import React, { useEffect, useState } from 'react'
import TransactionHistory from './components/TransactionHistory';
import BillHistory from './components/BillHistory/BillHistory';
import RadioBox from '../../components/RadioBox';
import AccountGRAPHQL from '../../../graphql/Account';
import MetadataGRAPHQL from '../../../graphql/Metadata';

const BILL_TAB = 0
const TRANSACTION_TAB = 1


const PaymentManagementPage = () => {

  const [tab, setTab] = useState(BILL_TAB)
  const [dataCoin, setDataCoin] = useState({})

  const DATA_TABS =  [
    { value: BILL_TAB, label: window.I18N('bill_history'), icon: "money-check"},
    { value: TRANSACTION_TAB, label: window.I18N('transaction_history'), icon: "history"}
  ]

  const changeTab  = (index) => {
    if(index === tab){
      return
    }
    setTab(index)
  }

  const getInfoCoinUser = async () => {
    try {
      const [info, config] = await Promise.all([
        window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_INFO),
        window.COMMON.query(MetadataGRAPHQL.QUERY_CONFIG)
      ])
      if (info && config) {
        const dataInfo = info.data.getAccountInfo;
        const dataConfig = config.data.getConfigs
        const dataSet = {
          userCoin: dataInfo.gopy,
          coinRate: dataConfig.find(data => data.name === 'EXCHANGE_RATE_GOPIES').value
        }
        setDataCoin(dataSet)
      }
    } catch (error) {
      window.COMMON.showErrorLogs('TransactionHistoryPage.getInfoCoin');
    }
  }

  useEffect(() => {
    getInfoCoinUser()
  },[])

  return (
    <div className="wow fadeIn" data-wow-delay="0.2s">
        <div className="w-full bg-white p-3 rounded-lg flex-center justify-content-between">
          <RadioBox data={DATA_TABS} value={tab} handleChange={changeTab} className={'m-0'}/>
          <div className="flex-center" style={{ gap: '5px' }}>
              <span className="font-weight-bold">{`Gopies: ${ window.COMMON.formatCurrency(dataCoin.userCoin, { currency: 'VND' })}`}</span>
              <div className="flex-center">
                <img src={window.CONSTANT.G_COIN} alt="G-Coin" height="20px"/>
              </div>
          </div>
        </div>
        <div className="w-full mt-2 rounded-lg">
            {
              tab === BILL_TAB ? 
                <BillHistory dataCoin={dataCoin} refechDataCoin={getInfoCoinUser}/> 
              : 
                <TransactionHistory />
            }
        </div>
    </div>
    )
}

export default PaymentManagementPage
