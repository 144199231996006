import React, {createContext, useState, useContext} from "react"
const defaultState = {
    selectedUserToChat:undefined
}

const ChatDataContext = createContext({
    state:{...defaultState},
    updateState:(_data) => {},
    setSelectedUserToChat:(_data) => {}
})


const ChatDataProvider = ({children}) => {
    const [state,setState] = useState({...defaultState})

    const updateState = (newData) => {
        setState((current)=>{
            return {
                ...current,
                ...newData,
            }
        })
    }

    const setSelectedUserToChat = (user) => {
        setState((current) => {
            return {
                ...current,
                selectedUserToChat:user
            }
        })
    }

    return <ChatDataContext.Provider value={{state,updateState,setSelectedUserToChat}}>
        {children}
    </ChatDataContext.Provider>
}

const useChatDataProvider = () => useContext(ChatDataContext);

export { ChatDataProvider, useChatDataProvider };