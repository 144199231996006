import React from 'react';
import XLSX from 'xlsx';
import { MDBBtn, MDBIcon } from 'mdbreact';
import { useImperativeHandle } from 'react';

const ExportExcel = React.forwardRef((props, ref) => {
  const data = [
    {
      key: 'USER_TEMPLATE',
      name: 'Import User Template',
      url: 'https://csrvietnam.org/assets/template/import/Import_User.xlsx'
    },
    {
      key: 'EXPORT_ACCOUNT',
      name: 'Export_Account.xlsx',
      title: 'USER LIST EXPORT REPORT',
      startRow: 2,
      startCol: 1,
      sheetName: 'Export Account',
      labelCompany: window.I18N('organization_name'),
      attrCompany: 'companyName',
      header: [window.I18N('no'), window.I18N('name'), window.I18N('phone_number'), window.I18N('email'), window.I18N('gender'), 'DOB', window.I18N('bio'), window.I18N('address'), window.I18N('tax_id'), window.I18N('start_date'), window.I18N('role'), window.I18N('status')],
      attrs: ['INDEX', 'name', 'phone', 'email', 'genderText', 'birthday', 'bio', 'address', 'taxCode', 'createdDate', 'accountType', 'statusText']
    },
    {
      key: 'REWARD_TEMPLATE',
      name: 'Import Reward Template',
      url: 'https://csrvietnam.org/assets/template/import/Import_Reward.xlsx'
    },
    {
      key: 'QUESTION_TEMPLATE',
      name: 'Import Question Template',
      url: 'https://csrvietnam.org/assets/template/import/Import_Question.xlsx'
    },
    {
      key: 'ANSWER_QUESTION_TEMPLATE',
      name: 'Import Answer Question Template',
      url: 'https://csrvietnam.org/assets/template/import/Import_Answer_Question.xlsx'
    },
    {
      key: 'LANGUAGE_TEMPLATE',
      name: 'Import Language Template',
      url: 'https://csrvietnam.org/assets/template/import/Import_Language.xlsx'
    },
    {
      key: 'EXPORT_SUB_CATEGORY',
      name: 'Import Sub Metadata Template',
      url: 'https://csrvietnam.org/assets/template/import/Import_Sub_Metadata.xlsx'
    },
    {
      key: 'LOCATION_TEMPLATE',
      name: 'Import Location Template',
      url: 'https://csrvietnam.org/assets/template/import/Import_Location.xlsx'
    },
    {
      key: 'QUESTION_LEARNING_TEMPLATE',
      name: 'Import Question Learning Template',
      url: 'https://csrvietnam.org/assets/template/import/Import_Question_Learning.xlsx'
    },
    {
      key: 'QUESTION_SURVEY_TEMPLATE',
      name: 'Import Question Survey Template',
      url: 'https://csrvietnam.org/assets/template/import/Import_Question_Survey.xlsx'
    },
    {
      key: 'ANSWER_SURVEY_TEMPLATE',
      name: 'Import Answer Survey Template',
      url: 'https://csrvietnam.org/assets/template/import/Import_Answer_Survey.xlsx'
    },
    {
      key: 'RECOMMEND_SURVEY_TEMPLATE',
      name: 'Import Recommend Survey Template',
      url: 'https://csrvietnam.org/assets/template/import/Import_Recommend_Survey.xlsx'
    },
    {
      key: 'REWARD_REPORT',
      name: 'Reward_Report.xlsx',
      title: window.I18N('reward_report'),
      startRow: 1,
      startCol: 1,
      sheetName: 'Reward Report',
      header: [window.I18N('no'), window.I18N('created_date'), window.I18N('company'), window.I18N('union'), window.I18N('influencer'), window.I18N('user'), window.I18N('category_code'), window.I18N('category'), window.I18N('reward_code'), window.I18N('reward'), 'Seri', window.I18N('code'), window.I18N('point')],
      attrs: ['INDEX', 'createdDate', 'company.information.name', 'union.information.name', 'influencer.information.name', 'createdBy.nickname', 'category.code', 'categoryName', 'reward.code', 'rewardName', 'seri', 'code', 'point']
    },
    {
      key: 'SURVEY_REPORT',
      name: 'Survey_Report.xlsx',
      title: window.I18N('survey_report'),
      startRow: 1,
      startCol: 1,
      sheetName: 'Survey Report',
      header: [window.I18N('no'), window.I18N('created_date'), window.I18N('company'), window.I18N('union'), window.I18N('influencer'), window.I18N('user'), window.I18N('survey_code'), window.I18N('survey'), window.I18N('recommend'), window.I18N('rate')],
      attrs: ['INDEX', 'createdDate', 'company.information.name', 'union.information.name', 'influencer.information.name', 'createdBy.nickname', 'menuSurvey.code', 'menuSurveyLanguage', 'recommendSurveyLanguage', 'rate']
    },
    {
      key: 'QA_REPORT',
      name: 'QA_Report.xlsx',
      title: window.I18N('qa_report'),
      startRow: 1,
      startCol: 1,
      sheetName: 'QA Report',
      header: [window.I18N('no'), window.I18N('completed_date'), window.I18N('company'), window.I18N('union'), window.I18N('influencer'), window.I18N('user'), window.I18N('group_qa_code'), window.I18N('group_qa')],
      attrs: ['INDEX', 'updatedDate', 'company.information.name', 'union.information.name', 'influencer.information.name', 'createdBy.nickname', 'group.code', 'groupLanguage']
    },
    {
      key: 'SOFT_SKILLS_QA_REPORT',
      name: 'Soft_skills_QA_Report.xlsx',
      title: window.I18N('soft_skills_question'),
      startRow: 1,
      startCol: 1,
      sheetName: 'QA Report',
      header: [window.I18N('no'), window.I18N('completed_date'), window.I18N('company'), window.I18N('union'), window.I18N('influencer'), window.I18N('user'), window.I18N('group_qa_code'), window.I18N('group_qa')],
      attrs: ['INDEX', 'updatedDate', 'company.information.name', 'union.information.name', 'influencer.information.name', 'createdBy.nickname', 'group.code', 'groupLanguage']
    },
    {
      key: 'QA_REPORT_DATA',
      name: 'QA_Report.xlsx',
      title: window.I18N('qa_summary_report'),
      startRow: 1,
      startCol: 1,
      sheetName: 'Summary',
      header: [window.I18N('survey'), window.I18N('from_date'), window.I18N('to_date'), window.I18N('number_companies'), window.I18N('number_employees'), window.I18N('number_responses')],
      attrs: ['survey', 'startDate', 'endDate', 'companies', 'employees', 'responses'],
      headerCompany: [window.I18N('organization'), window.I18N('industry'), window.I18N('headcounts'), window.I18N('organization_type'), window.I18N('number_employees'), window.I18N('number_responses')],
      attrsCompany: ['name', 'industry', 'size', 'type', 'employees', 'responses']
    },
    {
      key: 'SELF_REPORT_DATA',
      name: 'SelfAssessment_Report.xlsx',
      title: window.I18N('self_summary_report'),
      startRow: 1,
      startCol: 1,
      sheetName: 'Summary'
    },
    {
      key: 'ORGANIZATION_SUMMARY_REPORT',
      name: 'Organization_Summary_Report.xlsx',
      title: window.I18N('list_organizations'),
      startRow: 1,
      startCol: 1,
      sheetName: 'Summary',
      header: [
        window.I18N('no'), 
        window.I18N('organization'), 
        window.I18N('influencers'), 
        window.I18N('industry'), 
        window.I18N('organization_type'), 
        window.I18N('headcounts'), 
        window.I18N('country'), 
        window.I18N('state_city'), 
        window.I18N('city_district'),
        window.I18N('bio'),
        window.I18N('Phone'),
        window.I18N('email'), 
        window.I18N('number_accounts'), 
        window.I18N('number_user_rating'), 
        window.I18N('average_user_rating'), 
        window.I18N('start_date')
      ],
      attrs: [
        'INDEX', 
        'name', 
        'influencersName',
        'companyIndustry.value', 
        'companyType.value', 
        'companySize.value', 
        'nation.name', 
        'state.name', 
        'city.name', 
        'bio', 
        'phone', 
        'email',
        'numberAccount', 
        'total', 
        'average', 
        'createdDate'
      ],
      auto_col_width: true
    },
    {
      key: 'ACTIVITIES_SUMMARY_REPORT',
      name: 'Activities_Summary_Report.xlsx',
      title: window.I18N('organization_activities_summary'),
      startRow: 1,
      startCol: 1,
      hasSummary: true,
      sheetName: 'Summary',
      header: [window.I18N('no'), window.I18N('organization'), window.I18N('influencer'), window.I18N('industry'), window.I18N('organization_type'), window.I18N('headcounts'), window.I18N('country'), window.I18N('state_city'), window.I18N('city_district'), window.I18N('post'), window.I18N('like'), window.I18N('dislike'), window.I18N('comment'), window.I18N('share'), window.I18N('complete_qa'), window.I18N('total')],
      attrs: ['INDEX', 'name', 'influencersName', 'companyIndustry.value', 'companyType.value', 'companySize.value', 'nation.name', 'state.name', 'city.name', 'numberPost', 'numberLike', 'numberDislike', 'numberComment', 'numberShare', 'numberCompleteQA', 'numberActivities'],
      summaryAttrs: ['INDEX', 'TOTAL', '', '', '', '', '', '', '', '0.count', '1.count', '2.count', '3.count', '4.count', '5.count', 'COUNT_TOTAL'],
      auto_col_width: true
    },
    {
      key: 'QA_SUMMARY_REPORT',
      name: 'QA_Summary_Report.xlsx',
      title: window.I18N('organization_qa_summary'),
      startRow: 1,
      startCol: 1,
      sheetName: 'Summary',
      header: [window.I18N('no'), window.I18N('organization'), window.I18N('industry'), window.I18N('organization_type'), window.I18N('headcounts'), window.I18N('country'), window.I18N('state_city'), window.I18N('city_district'), window.I18N('number_qa_assigned'), window.I18N('number_qa_answered')],
      attrs: ['INDEX', 'name', 'companyIndustry.value', 'companyType.value', 'companySize.value', 'nation.name', 'state.name', 'city.name', 'assginedQA', 'answeredQA']
    },
    {
      key: 'ORGINAZATION_RATING_SUMMARY',
      name: 'Organization_Rating_Summary.xlsx',
      title: window.I18N('organization_rating_summary'),
      startRow: 1,
      startCol: 1,
      sheetName: 'Rating Summary',
      header: [
        window.I18N('organization_id'),
        window.I18N('organization_name'),
        window.I18N('rating_total'),
        window.I18N('average')
      ],
      attrs: ['value_id', 'name', 'total', 'average'],
      auto_col_width: true
    },
    {
      key: 'ORGINAZATION_LIST',
      name: 'Organization_List.xlsx',
      title: window.I18N('list_organizations'),
      startRow: 1,
      startCol: 1,
      sheetName: 'Organization List',
      header: [
        window.I18N('no'),
        window.I18N('organization'),
        window.I18N('industry'),
        window.I18N('country'),
        window.I18N('state_city'),
        window.I18N('city_district'),
        window.I18N('number_accounts'),
        window.I18N('start_date')
      ],
      attrs: ['index', 'name', 'companyIndustry.value', 'nation.name', 'state.name', 'city.name', 'numberAccount', 'createdDate'],
      auto_col_width: true
    },
    {
      key: 'ORGINAZATION_REWARD_SUMMARY',
      name: 'Organization_Reward_Summary.xlsx',
      title: window.I18N('organization_reward_summary'),
      startRow: 1,
      startCol: 1,
      sheetName: 'Reward Summary',
      header: [
        window.I18N('no'),
        window.I18N('name'),
        window.I18N('reward_count')

      ],
      attrs: ['index', 'name', 'count'],
      auto_col_width: true
    },
    {
      key: 'ORGINAZATION_ACTIVITIES_SUMMARY',
      name: 'Organization_Activities_Summary.xlsx',
      title: window.I18N('organization_activities_summary'),
      startRow: 1,
      startCol: 1,
      sheetName: 'Activities Summary',
      header: [
        window.I18N('no'),
        window.I18N('name'),
        window.I18N('activity_count')

      ],
      attrs: ['index', 'name', 'count'],
      auto_col_width: true
    },
    {
      key: 'ORGINAZATION_QA_SUMMARY',
      name: 'Organization_QA_Summary.xlsx',
      title: window.I18N('organization_qa_summary'),
      startRow: 1,
      startCol: 1,
      sheetName: 'QA Summary',
      header: [
        window.I18N('no'),
        window.I18N('name'),
        window.I18N('qa_count')

      ],
      attrs: ['index', 'name', 'count'],
      auto_col_width: true
    },
    {
      key: 'ORGINAZATION_ANSWER_SUMMARY',
      name: 'Organization_Answer_Summary.xlsx',
      title: window.I18N('organization_answer_summary'),
      startRow: 1,
      startCol: 1,
      sheetName: 'Answer Summary',
      header: [
        window.I18N('no'),
        window.I18N('name'),
        window.I18N('answer_count')

      ],
      attrs: ['index', 'name', 'count'],
      auto_col_width: true
    },
    {
      key: 'ORGINAZATION_SELF_SUMMARY',
      name: 'Organization_Self_Summary.xlsx',
      title: window.I18N('organization_self_summary'),
      startRow: 1,
      startCol: 1,
      sheetName: 'Self Assessment Summary',
      header: [
        window.I18N('no'),
        window.I18N('name'),
        window.I18N('self_assessment_count')

      ],
      attrs: ['index', 'name', 'count'],
      auto_col_width: true
    },
    {
      key: 'SELF_CHART_WEEK',
      name: 'Self_Chart_Week.xlsx',
      title: window.I18N('self_chart_week'),
      startRow: 1,
      startCol: 1,
      sheetName: 'Self Chart Week',
      header: [
        window.I18N('date_chart'),
        window.I18N('value')

      ],
      attrs: ['label', 'value'],
      auto_col_width: true
    },
    {
      key: 'SELF_CHART_YEAR',
      name: 'Self_Chart_Year.xlsx',
      title: window.I18N('self_chart_year'),
      startRow: 1,
      startCol: 1,
      sheetName: 'Self Chart Year',
      header: [
        window.I18N('month_chart'),
        window.I18N('value')

      ],
      attrs: ['label', 'value'],
      auto_col_width: true
    },
    {
      key: 'DATA_TEMPLATE',
      name: 'Data_Template.xlsx',
      title: 'Data_Template',
      startRow: 1,
      startCol: 1,
      sheetName: 'Data Template',
      header: props.configDataTemplate ? props.configDataTemplate.header : [],
      attrs: props.configDataTemplate ? props.configDataTemplate.attrs : [],
      auto_col_width: true
    }
  ];

  const handleClick = async () => {
    const obj = window.COMMON.getObjectInArrs(props.dataKey, data, 'key');
    if (props.isTemplate) {
      if (props.code === undefined) {
        const a = document.createElement('a');
        a.href = obj.url + '?v=' + new Date().getTime();
        a.download = obj.name;
        a.click();
      } else if (window.COMMON.checkValue(props.code)) {
        const a = document.createElement('a');
        let url = obj.url;
        url = url.replace('[CODE]', props.code);
        a.href = url + '?v=' + new Date().getTime();
        a.download = obj.name;
        a.click();
      } else {
        window.COMMON.showMessage('warning', 'MSG_CODE_025', window.MSG.MSG_CODE_025);
        return;
      }
    } else {
      let data = props.data;
      let companyName = '';
      if (props.handleData) {
        data = await props.handleData();
      }
      if (props.companyName) {
        companyName = props.companyName;
      }
      if (obj.key === 'QA_REPORT_DATA') {
        exportQAFile(obj, data);
      } else if (obj.key === 'SURVEY_REPORT' || obj.key === 'QA_REPORT' || obj.key === 'SOFT_SKILLS_QA_REPORT') {
        exportSurveyFile(obj, data);
      } else if (obj.key === 'SELF_REPORT_DATA') {
        exportSelfAssessmentFile(obj, data);
      } else {
        exportFile(obj, data, companyName);
      }
    }
  };

  function fitToColumn(arrayOfArray, startCol) {
    // get maximum character of each column
    const result = arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? a2[i].toString().length : 0)) }));
    for (let i = 0; i < startCol; i++) {
      result.unshift({ wch: 10 });
    }
    return result;
  }

  const exportFile = (obj, dataExport, companyName) => {
    const name = obj.name.replace('.xlsx', '_' + window.COMMON_DATE.formatDate(new Date(), 'YYYYMMDD_HHmmss') + '.xlsx');
    const workbook = XLSX.utils.book_new();
    workbook.Props = {
      Title: obj.title,
      Subject: obj.title,
      Author: 'Gopy',
      CreatedDate: new Date()
    };
    workbook.SheetNames.push(obj.sheetName);
    const worksheetData = [];
    if (obj.labelCompany && companyName) {
      worksheetData.push([obj.labelCompany, companyName]);
      worksheetData.push([]);
      worksheetData.push([]);
    }
    worksheetData.push(obj.header);
    for (let i = 0; i < dataExport.length; i++) {
      const item = dataExport[i];
      const items = [];
      for (let j = 0; j < obj.attrs.length; j++) {
        const attr = obj.attrs[j];
        if (attr === 'INDEX') {
          items.push(i + 1);
        } else {
          let value = attr === 'phone' ? window.COMMON.getValueFromAttr(item, attr) : setDataCell(window.COMMON.getValueFromAttr(item, attr));
          if (attr === 'bio') {
            value = {
              t: 's',
              s: {alignment: { wrapText: true }}
            };
          }
          items.push(value);
        }
      }
      worksheetData.push(items);
    }
    // TOTAL
    if (obj.hasSummary) {
      let total = 0;
      const items = [];
      for (let j = 0; j < obj.summaryAttrs.length; j++) {
        const attr = obj.summaryAttrs[j];
        if (attr === 'INDEX') {
          items.push('');
        } else if (attr === 'TOTAL') {
          items.push(window.I18N('total'));
        } else if (attr === 'COUNT_TOTAL') {
          items.push(total);
        } else if (attr === '') {
          items.push('');
        } else {
          const value = setDataCell(window.COMMON.getValueFromAttr(props.sumData, attr));
          items.push(value);
          total += value;
        }
      }
      worksheetData.push(items);
    }

    const cellRef = XLSX.utils.encode_cell({ c: obj.startCol, r: obj.startRow });
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData, {
      origin: cellRef
    });
    if (obj.auto_col_width === true) {
      worksheet['!cols'] = fitToColumn(worksheetData, obj.startCol);
    }
    workbook.Sheets[obj.sheetName] = worksheet;
    const workbookOut = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    const url = window.URL.createObjectURL(
      new Blob([s2ab(workbookOut)], { type: 'application/octet-stream' })
    );
    const link = document.createElement('a');
    link.download = name;
    link.href = url;
    link.click();
  };

  const exportQAFile = (obj, dataExport) => {
    const name = obj.name.replace('.xlsx', '_' + window.COMMON_DATE.formatDate(new Date(), 'YYYYMMDD_HHmmss') + '.xlsx');
    const workbook = XLSX.utils.book_new();
    workbook.Props = {
      Title: obj.title,
      Subject: obj.title,
      Author: 'Gopy',
      CreatedDate: new Date()
    };
    workbook.SheetNames.push(obj.sheetName);
    // TODO summary
    const summary = window.COMMON.getValueFromAttr(dataExport, 'summary');
    if (summary) {
      const worksheetData = [];
      worksheetData.push(obj.header);
      const items = [];
      for (let i = 0; i < obj.attrs.length; i++) {
        const attr = obj.attrs[i];
        items.push(setDataCell(window.COMMON.getValueFromAttr(summary, attr)));
      }
      worksheetData.push(items);
      worksheetData.push([]);
      worksheetData.push([]);
      const data = window.COMMON.getValueFromAttr(dataExport, 'data', []);
      if (data && data.length > 0) {
        data.forEach(item => {
          worksheetData.push(setDataCell(item));
        });
      }
      const cellRef = XLSX.utils.encode_cell({ c: obj.startCol, r: obj.startRow });
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData, {
        origin: cellRef
      });
      workbook.Sheets[obj.sheetName] = worksheet;
    }
    // TODO company
    const dataCompanies = window.COMMON.getValueFromAttr(dataExport, 'dataCompanies');
    if (dataCompanies && dataCompanies.length > 0) {
      dataCompanies.forEach(company => {
        workbook.SheetNames.push(company.id);
        const worksheetData = [];
        worksheetData.push(obj.headerCompany);
        const items = [];
        for (let i = 0; i < obj.attrsCompany.length; i++) {
          const attr = obj.attrsCompany[i];
          items.push(setDataCell(window.COMMON.getValueFromAttr(company, attr)));
        }
        worksheetData.push(items);
        worksheetData.push([]);
        worksheetData.push([]);
        const data = window.COMMON.getValueFromAttr(company, 'data', []);
        if (data && data.length > 0) {
          data.forEach(item => {
            if (Array.isArray(item)) {
              worksheetData.push(item);
            } else {
              console.info(`WorkSheetData row is not an array:: ${company.name} - ${item}`);
              worksheetData.push([setDataCell(item)]);
            }
          });
        }
        const cellRef = XLSX.utils.encode_cell({ c: obj.startCol, r: obj.startRow });
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData, {
          origin: cellRef
        });
        workbook.Sheets[company.id] = worksheet;
      });
    }

    const workbookOut = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    const url = window.URL.createObjectURL(
      new Blob([s2ab(workbookOut)], { type: 'application/octet-stream' })
    );

    const link = document.createElement('a');
    link.download = name;
    link.href = url;
    link.click();
  };

  const exportSurveyFile = (obj, dataExport) => {
    const name = obj.name.replace('.xlsx', '_' + window.COMMON_DATE.formatDate(new Date(), 'YYYYMMDD_HHmmss') + '.xlsx');
    const workbook = XLSX.utils.book_new();
    workbook.Props = {
      Title: obj.title,
      Subject: obj.title,
      Author: 'Gopy',
      CreatedDate: new Date()
    };
    workbook.SheetNames.push(obj.sheetName);

    const worksheetData = [];
    const questions = window.COMMON.getValueFromAttr(dataExport, 'questions', []);
    let headers = obj.header.concat(questions);
    if (obj.key === 'SOFT_SKILLS_QA_REPORT') {
      const categories = window.COMMON.getValueFromAttr(dataExport, 'categories', []);
      const categoryHeaders = categories.map((category, index) => {
        return [window.I18N('category_name') + ` ${index+1}`, window.I18N('STD') + ` ${index+1}`];
      });
      headers = headers.concat(categoryHeaders.flat(), [window.I18N('average_score'), window.I18N('final_conclusion')]);
    }

    worksheetData.push(headers);
    const finalConclusion = window.COMMON.getValueFromAttr(dataExport, 'finalConclusion', []);
    const data = window.COMMON.getValueFromAttr(dataExport, 'data', []);
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const cols = [];
      for (let j = 0; j < obj.attrs.length; j++) {
        const attr = obj.attrs[j];
        if (attr === 'INDEX') {
          cols.push(i + 1);
        } else {
          cols.push(setDataCell(window.COMMON.getValueFromAttr(item, attr)));
        }
      }
      const answers = window.COMMON.getValueFromAttr(item, 'answers', []);
      answers.forEach(answer => {
        cols.push(setDataCell(answer));
      });
      if (obj.key === 'SOFT_SKILLS_QA_REPORT') {
        const answerResult = window.COMMON.getValueFromAttr(item, 'answerResult', []);
        let finalAverage = 0;
        answerResult.forEach(result => {
          cols.push(setDataCell(result.categoryTitle));
          cols.push(setDataCell(result.averageAnswers));
          finalAverage += result.averageAnswers;
        });
        finalAverage = finalAverage / answerResult.length;
        cols.push(setDataCell(Number(finalAverage.toFixed(2))));
        const { min: standardMinConclusion, max: standardMaxConclusion } = dataExport.standardOfConclusion
        let finalConclusionText = window.COMMON.getValueWithLanguage(finalConclusion, 'high', props.language) || 'Good'; 
        if (finalAverage < (standardMaxConclusion || (dataExport.categoriesAverage || 80))) finalConclusionText = window.COMMON.getValueWithLanguage(finalConclusion, 'medium', props.language) || 'Medium';
        if (finalAverage <= (standardMinConclusion || 50)) finalConclusionText = window.COMMON.getValueWithLanguage(finalConclusion, 'low', props.language) || 'Bad';
        cols.push(setDataCell(finalConclusionText));
      }
      worksheetData.push(cols);
    }
    const cellRef = XLSX.utils.encode_cell({ c: obj.startCol, r: obj.startRow });
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData, {
      origin: cellRef
    });
    workbook.Sheets[obj.sheetName] = worksheet;
    const workbookOut = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    const url = window.URL.createObjectURL(
      new Blob([s2ab(workbookOut)], { type: 'application/octet-stream' })
    );

    const link = document.createElement('a');
    link.download = name;
    link.href = url;
    link.click();
  };

  const exportSelfAssessmentFile = (obj, dataExport) => {
    const name = obj.name.replace('.xlsx', '_' + window.COMMON_DATE.formatDate(new Date(), 'YYYYMMDD_HHmmss') + '.xlsx');
    const workbook = XLSX.utils.book_new();
    workbook.Props = {
      Title: obj.title,
      Subject: obj.title,
      Author: 'Gopy',
      CreatedDate: new Date()
    };
    workbook.SheetNames.push(obj.sheetName);

    const worksheetData = [];
    for (let i = 0; i < dataExport.length; i++) {
      const item = dataExport[i];
      worksheetData.push(item);
    }
    const cellRef = XLSX.utils.encode_cell({ c: obj.startCol, r: obj.startRow });
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData, {
      origin: cellRef
    });
    workbook.Sheets[obj.sheetName] = worksheet;
    const workbookOut = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    const url = window.URL.createObjectURL(
      new Blob([s2ab(workbookOut)], { type: 'application/octet-stream' })
    );

    const link = document.createElement('a');
    link.download = name;
    link.href = url;
    link.click();
  };

  const s2ab = (s) => {
    const buffer = new ArrayBuffer(s.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buffer;
  };

  const setDataCell = (value) => {
    if (window.COMMON.checkValue(value) && (typeof (value) === 'number' || !isNaN(value))) {
      return Number(value);
    }
    return value;
  };
  useImperativeHandle(ref, () => ({
    handleClick
}))

  return (
    <MDBBtn color="primary" className={props.className} onClick={handleClick} style={props.style}>
      <MDBIcon fa="true" icon="download" className="fa-fw" /> {window.COMMON.getValue(props.label)}
    </MDBBtn>
  );
});

export default React.memo(ExportExcel);
