import { MDBIcon } from 'mdbreact'
import React from 'react'

import styles from './Button.module.css'

const Button = ({
    children,
    variant = 'outlined',
    iconLeft = null,
    iconRight = null,
    onClick = () => {},
    disabled = false,
    style = {},
    passProps = {}
}) => {
    return (
        <button
            className={`${styles[variant]}`}
            onClick={onClick}
            disabled={disabled}
            style={{ ...style }}
            {...passProps}
        >
            {iconLeft && <MDBIcon fa="true" icon={iconLeft} className="fa-fw" />}
            {children}
            {iconRight && <MDBIcon fa="true" icon={iconRight} className="fa-fw" />}
        </button>
    )
}

export default Button
