import { useState, useEffect, useCallback } from 'react';
import CompanyGRAPHQL from '../../../../graphql/Company';
import Common from '../../../../utils/Common';

export default function useAdminInfluencerInformation(influencerCompanyId) {
  const [ id, setId ] = useState(influencerCompanyId);
  const [adminInfluencer, setAdminInfluencer] = useState({});

  const fetchInfluencerAdmin = useCallback(async() => {
    try {
      if (!id) {
        setAdminInfluencer({});
        return ;
      }

      const res = await Common.query( CompanyGRAPHQL.QUERY_COMPANY_BY_IDS, {companyIds: [id]});
      if (res?.data?.getCompaniesByIds?.length) {
        setAdminInfluencer(res.data.getCompaniesByIds[0]);
      }
    } catch (error) {
      console.warn('Hook.useAdminInfluencerInformation.fetchInfluencerAdmin', error.message || 'Unknow error');
    }
  }, [id]);

  useEffect(( ) => { 
    fetchInfluencerAdmin();
  }, [id]);

  return {
    adminInfluencer,
    setId
  };
}