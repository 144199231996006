import React, { useState, useEffect, useRef } from 'react';
import { MDBInput, MDBBtn, MDBIcon } from 'mdbreact';
import { CustomBottomChatRightSide } from '../../../../styles/styled-components/Chat/ChatPage';
import Picker from 'emoji-picker-react';
import useClickOutSide from '../../../../hooks/useClickOutSide';
import InputFile from '../../../components/InputFile';
import axios from 'axios';
import { checkMessageIsCurrentUser } from './BodyChatRightSide/Message';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder';
import ReactAudioPlayer from 'react-audio-player';
import moment from 'moment/moment';



const ReplyMessageTypeLookup = {
  1 : "Image",
  3 : "File",
  4 : "Audio"
}

const BottomChatRightSide = ({ onSendMessage, groupChatSelected, onFormChange, messages, reply, onReply, dataStickers = [] }) => {
  const [message, setMessage] = useState('');
  const [displaySticker, setDisplaySticker] = useState(false);
  const [displayEmoji, setDisplayEmoji] = useState(false);
  const [replyMessage, setReplyMessage] = useState(null);
  const [isRecord, setIsRecord] = useState(false);
  const emojiRef = useRef();
  const stickRef = useRef();
  //record
  const [recordstate, setrecordstate] = useState('none');
  const [blob, setblob] = useState(null);
  const [duration, setDuration] = useState(0);


  useClickOutSide({
    ref: emojiRef,
    handleOutside: () => setDisplayEmoji(false)
  });
  useClickOutSide({
    ref: stickRef,
    handleOutside:() => setDisplaySticker(false)
  });

  useEffect(() => {
    setMessage('');
  }, [groupChatSelected._id]);


  const isAudioFile = (ext) => {  
    if (window.CONSTANT.AUDIO_EXTENSION.includes(ext)) {
	  return true;
    }
    return false;
  };
  const handleOnEnterInput = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };
  const handleMessageInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSendMessage = () => {
    onSendMessage(message, 0);
    setMessage('');
  };
  const onEmojiClick = (event, emojiObject) => {
    // setChosenEmoji(emojiObject);
    const emojiMessage = emojiObject.emoji;
    onSendMessage(emojiMessage, 0);
    setDisplayEmoji(false);
  };

  const onStickClick = (stickerUrl) => {
    onSendMessage(stickerUrl,2);
    setDisplaySticker(false);
  };



  const checkFileIsValid = (file) => {
    let check = true;
    if (file instanceof File) {
      const nameArr = file.name.split('.');
      const extension = nameArr[1];
      if (!window.CONSTANT.DOCUMENT_EXTENSION.includes(extension.toLowerCase())) {
        check = false; 
      }
    }
    
    return check;
  };
  const handleFile = (file) => {
    

    const checkFile = checkFileIsValid(file);
    if (checkFile === false) {
      window.COMMON.showMessage('warning', 'MSG_CODE_064', window.I18N('MSG_CODE_064'));
      return;
    }
    uploadFile(file);
   

  };

  const uploadFile = async (fileToUpload) => {
    try {
      const data = new FormData();
      data.append('', fileToUpload);
      data.append('public', JSON.stringify({ permission: 'viewer', status: 'true' }));

      const token = localStorage.getItem(window.CONSTANT.AUTHEN_TOKEN);


      const config = {
        method: 'post',
        url: process.env.REACT_APP_URL_GDRIVE + '/upload',
        headers: {
          Authorization: `Bearer ${token}`
        },
        data
      };

      const response = await axios(config);
      const status = response.status;
      if (status >= 200 && status < 400 && response.data) {
        const files = response.data.files;
        const file = files[0];
        const ext = file.ext;
        const name = file.name + '.' + ext;
        const type = ext === 'blob' ? 1 : ( isAudioFile(ext) ? 4 : 3 );
        const currentUser = window.COMMON.getCurrentUserInformation();

        //upload firebase
        await window.uploadFirebase(fileToUpload, (downloadURL) => {
          const url = downloadURL;
          const content = url;
		  const moreInfo = {
            fileName: name,
            user:currentUser.name
		  };

		  if (duration) {
            moreInfo.duration = duration;
		  }

          onSendMessage(
            content,
            type,
            moreInfo
          );
        });
      }

    }
    catch (error) {
      window.COMMON.showMessage('error', 'MSG_CODE_017', window.I18N('MSG_CODE_017'));
    }
  };

  const onStartRecord = () => {
    setblob(null);
    setrecordstate(RecordState.START);
  };
  const onStopRecord = () => {
    setrecordstate(RecordState.STOP);
  };
  const onStop = (audioData) => {
    setblob(audioData);
  };
  const handleCloseRecord = () => {
    setblob(null);
    setIsRecord(false);
    setrecordstate(RecordState.NONE);
    setDuration(0);
  };
  const handleSendRecord = () => {
    if (blob) {
      const file = new File([blob.blob], `record-${moment().valueOf()}.mp3`, { type: blob.type });
      handleFile(file);
      handleCloseRecord();
    }
  };
  //get file duration
  const handleLoadMetadata = (meta) => {
    const { duration } = meta.target;
    setDuration(parseInt(duration));
  };

  const handleMicroButtonClick = () => {
    navigator.mediaDevices
    .getUserMedia({ video: false, audio: true })
    .then(() => {
      setIsRecord(true)
    })
    .catch(() => {
      window.COMMON.showMessage('error', 'MSG_CODE_067', window.I18N('MSG_CODE_067'));
    });
  }

  useEffect(() => {
    if (reply && Array.isArray(messages)) {
      const messageContent = messages.find(item => item.timestamp === reply);
	  if (!messageContent) {
        return onReply(null);
	  }
	  return setReplyMessage(messageContent);
    } else {
	  setReplyMessage(null);
    }
  }, [reply]);

  return <CustomBottomChatRightSide>
    <div className='border-top'>
      {replyMessage && (
	    <div className="p-2 px-3">
		  <div className='d-flex align-items-center'>
  		  	<div className="font-weight-bold flex-1">{window.I18N('replying_to')} {checkMessageIsCurrentUser(replyMessage) ? `${window.I18N('yourself')}` : replyMessage.createdBy?.name}</div>
            <MDBIcon fa="true" icon="times" className="fa-fw cursor-pointer" onClick={() => onReply(null)}/>
		  </div>
		  <div className='text-justify mt-1 text-truncate'>
            {ReplyMessageTypeLookup[replyMessage?.type || -1] || replyMessage.content}
		  </div>
	    </div>
	  )}
	  {isRecord ? (
        <div className="py-2 px-2 input-wrapper align-items-center">
          <div style={{ position: 'relative' }}>
            <MDBBtn className="m-2" color="primary" onClick={handleCloseRecord}>
              <MDBIcon fa="true" icon="times-circle" className="fa-fw" />
            </MDBBtn>
          </div>
		  <div className="flex-1 text-center" style={{ fontSize: 20 }}>
            {recordstate === RecordState.START && 'Recording'}
            {recordstate === RecordState.STOP && 'Recorded'}
		  </div>
          <AudioReactRecorder state={recordstate} onStop={onStop} canvasWidth={0} canvasHeight={0}/>
          <ReactAudioPlayer src={blob?.url} controls onLoadedMetadata={handleLoadMetadata}/>
          <div style={{ position: 'relative' }}>
            {(recordstate === RecordState.NONE || recordstate === RecordState.STOP) ? (
              <MDBBtn className="m-2" color="primary" onClick={onStartRecord}>
                <MDBIcon fa="true" icon="play-circle" className="fa-fw"/>
              
              </MDBBtn>
            ) : (
              <MDBBtn className="m-2" color="primary" onClick={onStopRecord}>
                <MDBIcon fa="true" icon="pause-circle" className="fa-fw"/>
              
              </MDBBtn>
            )}
          </div>
		  <div style={{ position: 'relative' }}>
            <MDBBtn className="m-2" color="primary" onClick={handleSendRecord} disabled={!blob}>
              <MDBIcon fa="true" icon="paper-plane" className="fa-fw" />
            </MDBBtn>
          </div>
        </div>
	  ) : (
        <div className="py-2 px-2 input-wrapper">
          <MDBInput outline={true}
            label={false}
            id={'username'}
            type={'text'}
            hint={window.I18N('type_message')}
            containerClass="m-0"
            onKeyPress={handleOnEnterInput}
            value={message}
            onChange={handleMessageInputChange}
          />
          <MDBBtn className="m-0 send-btn" color="primary" onClick={handleSendMessage}>
            <MDBIcon fa="true" icon="paper-plane" className="fa-fw" />
          </MDBBtn>
          <div style={{ position: 'relative' }} ref={emojiRef}>
            <MDBBtn className="m-0 h-100" color="primary" onClick={() => setDisplayEmoji(!displayEmoji)}>
              <MDBIcon fa="true" icon="smile" className="fa-fw"/>
              
            </MDBBtn>
            {displayEmoji && <Picker pickerStyle={{
              bottom: '100%',
              top: 'unset',
              right: 0,
              position: 'absolute'
            }} onEmojiClick={onEmojiClick} />}
          </div>
          <div style={{ position: 'relative' }} ref={stickRef}>
            <MDBBtn className="m-0 h-100" color="primary" onClick={() => setDisplaySticker(!displaySticker)}>
              <MDBIcon fa="true" icon="grin-tongue-wink" className="fa-fw"/>
              
            </MDBBtn>
            {(displaySticker && dataStickers.length > 0) && <div style={{bottom: '100%',right:'0%',position: 'absolute'}}>
              <div className='dropdown-menu show' style={{position: 'relative', right: '10px', minWidth: '15rem'}} >
                <div className='py-2 px-2 input-wrapper flex-wrap' style={{
                  boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
                  borderRadius: '5px'
                }}>
                  {dataStickers.map((item, i) => {
                    const {categoryName, stickers} = item;
                    return (
                      <div key={i} style={{
                        marginBottom: '10px'
                      }}>
                        <div 
                          className='font-weight-bold' 
                          style={{
                            color: '#444'
                          }}
                        >{categoryName}</div>
                        <div className='d-flex flex-wrap'>
                          {stickers.map(sticker => {
                            return (
                              <div key={sticker.code} className='p-1 cursor-pointer'>
                                <img src={sticker.icon} style={{height: '60px',width: '60px'}} onClick={() => onStickClick(sticker.icon)}/>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>}
          </div>

          <div style={{ position: 'relative' }}>
            <MDBBtn className="m-0 h-100" color="primary" onClick={handleMicroButtonClick}>
              <MDBIcon fa="true" icon="microphone" className="fa-fw"/>
              
            </MDBBtn>
          </div>

          <InputFile className={'h-100'} id="file-group-bot" icon="upload" handleFile={handleFile}></InputFile>

        </div>
	  )}
    </div>
  </CustomBottomChatRightSide>;
};
export default BottomChatRightSide;