import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Message from './Message';
import LoadingSpinner from '../../../../components/LoadingSpinner';
const Messages = ({ parentId, messages, isHasMore, onFetchMessages, fetchLoading, onReply, allStickers }) => {
 

  const getReplyMessage = (timestamp) => {
    if (!timestamp) return null;
	
    const replyMessage = messages.find(item => item.timestamp === timestamp);
    return replyMessage;
  };
  
  //group message if messages were arranged side by side and same creator (createdBy)
  const convertMessageDataToGroupMessage = (messages) => {
    const tmpMessages = [ ...messages];
    const newMessages = [];
    tmpMessages.forEach((item) => {
      const currentMessageInfor = {
        _id: item._id,
        content: item.content,
        createdAt: Number(item.timestamp),
        isDraft: item.isDraft,
        type: item.type,
        isGroup: item.isGroup,
        fileName:item.fileName,
        reply: getReplyMessage(item.reply),
        reader: item.reader,
        timestamp: item.timestamp,
        createdBy: item.createdBy
      };
      if (newMessages.length === 0) {
        newMessages.push({
          ...item,
          createdAt: Number(item.timestamp),
          groupsMessage: [
            currentMessageInfor
          ]
        });

      }
      else {
        const lastMessageInArr = newMessages[newMessages.length - 1];
        const typeMessage = window.COMMON.convertMessageDataType(item.type);
        const lastTypeMessage = window.COMMON.convertMessageDataType(lastMessageInArr.type);
        //7 is rename group message - but it need not show with user
        const isTypeCombine = item.type !== 7 && lastMessageInArr.type !==7 && lastTypeMessage === window.CONSTANT.TEXT && typeMessage === window.CONSTANT.TEXT;
        if (lastMessageInArr.createdBy._id === item.createdBy._id && isTypeCombine) {
          lastMessageInArr.groupsMessage.unshift(
            currentMessageInfor
          );
        }
        else {
          newMessages.push({
            ...item,
            createdAt: Number(item.timestamp),
            groupsMessage: [
              currentMessageInfor
            ]
          });
        }
      }
    });

    return newMessages;
  };

  const messgesGrouped = convertMessageDataToGroupMessage(messages);

  return <>
         
    <InfiniteScroll
      dataLength={messages.length}
      next={onFetchMessages}
      hasMore={isHasMore}
      scrollableTarget={parentId}
      inverse={true}
      style={{ display: 'flex', flexDirection: 'column-reverse' }}
    >
      {messgesGrouped.map((item, index) => {
        return (
          <Message 
            key={`${index} - ${item._id}`} 
            style={{ height: '100px' }} 
            message={item} 
            onReply={onReply}
            allStickers={allStickers}
          />);

      })}
    </InfiniteScroll>
    {fetchLoading === true && <div className="d-flex justify-content-center">
      <LoadingSpinner />
    </div>}
  </>;

};



export default Messages;