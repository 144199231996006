import React, { useLayoutEffect, useImperativeHandle, useRef, useState, forwardRef } from 'react';
import Select from 'react-select';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardFooter, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';

import QuestionGRAPHQL from '../../../../graphql/Question';

const options = [
  // { value: 'TEXT', label: 'Text' },
  { value: 'RADIO', label: 'Radio' }
  // { value: 'CHECKBOX', label: 'Checkbox' },
  // { value: 'RADIO_GRID', label: 'Radio Grid' },
  // { value: 'CHECKBOX_GRID', label: 'Checkbox Grid' },
  // { value: 'LEVEL', label: 'Level' }
];
const MAX_NUMBER_OF_ANSWERS = 10;
const DEFAULT_SCORE_OF_ANSWER = 10;
const bg = ['bg-danger', 'bg-warning', 'bg-info', '', 'bg-success'];

const FormQuestionItemWithScore = forwardRef((props, ref) => {

  const [status, setStatus] = useState(true);
  const [language, setLanguage] = useState();
  const [select, setSelect] = useState({});
  const [dataAnswer, setDataAnswer] = useState([]);
  const [dataDeletedAnswer, setDataDeletedAnswer] = useState([]);
  const [dataSave, setDataSave] = useState({
    important: false
  });

  const formRef = useRef();

  const validQuestion = () => {
    let checkQuestion = true;
    switch (dataSave.type) {
      case 'RADIO_GRID':
      case 'CHECKBOX_GRID':
        const childQuestion = dataAnswer.filter(el => el.parentCode === null);
        if (childQuestion.length < 2) { checkQuestion = false; break; }
        childQuestion.forEach(singleChilQues => {
          if (dataAnswer.filter(el => el.parentCode === singleChilQues.code).length < 2) { checkQuestion = false; return; }
        });
        break;
      case 'TEXT':
        break;
      default:
        checkQuestion = !(dataAnswer.length < 2);
    }
    const checkForm = window.COMMON.checkFormValidation('form-' + props.id, formRef.current);
    if (!checkForm || !checkQuestion) {
      window.COMMON.showMessage('warning', 'Check number of answer per question');
      return false;
    }
    return true;
  };
  const saveQuestionAndAnswer = async (groupQA) => {
    try {
      const newDataAnswers = [];
      if (dataSave.type !== 'TEXT') {
        dataAnswer.forEach((item) => {
          newDataAnswers.push({
            ...item,
            score: Number(item.score),
            group: groupQA._id
          });
        });
      }
      const params = {
        input: {
          group: groupQA._id,
          question: {
            ...dataSave,
            group: groupQA._id,
            standardLowOfCategory: props.standardLowOfCategory,
            standardOfCategory: props.standardOfCategory,
            recommend: {
              low: dataSave.recommend?.low.map(item => ({ text: item.text, code: item.code })),
              medium: dataSave.recommend?.medium.map(item => ({ text: item.text, code: item.code })),
              high: dataSave.recommend?.high.map(item => ({ text: item.text, code: item.code }))
            }
          },
          answers: newDataAnswers,
          deletedAnswers: dataDeletedAnswer
        }
      };
      if (props.dataFromOrigin) {
        const isSameOrigin = checkIsSameOrigin(params.input, props.dataFromOrigin)
        if (isSameOrigin) {
          params.input.question.cloneType = 1
        } else { 
          params.input.question.cloneType = 2
        }
      }

      window.COMMON.trimData(params);
      if (!dataSave._id) {
        await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_ADD_QA, params);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('FormQuestionItemWithScore.saveQuestionAndAnswer');
    }
  };

  const checkIsSameOrigin = (saveQA, originQuestion) => {
    //check câu hỏi có thay đổi hay không
    if (saveQA.question.question[0].text !== originQuestion.question[0].text) { return false }
    return true
  }
  
  const getCurrentQuestionAndAnswerData = () => {
    return {
      question: dataSave,
      answers: dataAnswer
    };
  };

  //when type of question change
  const handleSelect = (event) => {
    select.type = event;
    dataSave.type = event.value;
    setSelect(select);
    setDataSave({ ...dataSave });
  };

  const handleChange = (event, attr) => {
    if (event.target.type === 'checkbox') {
      dataSave[attr] = event.target.checked;
    }
    setDataSave({ ...dataSave });
  };

  //when content and introduction change
  const handleChangeWithLanguage = (event) => {
    window.COMMON.setValueWithLanguage(dataSave, event.target.name, language, event.target.value);
    setDataSave({ ...dataSave });
  };
  //
  const handleChangeAnswer = (event, item) => {
    window.COMMON.setValueWithLanguage(item, event.target.name, language, event.target.value);
    setDataAnswer([...dataAnswer]);
  };
  const handleChangeAnswerScore = (event, item) => {
    const value = Number(event.target.value);
    if (value > 0 && value <= 10) {
      item.score = value;
      setDataAnswer([...dataAnswer]);
    }
  };

  const clearItem = () => {
    if (props.item) {
      dataSave._id = window.COMMON.getValueFromAttr(props.item, '_id', null);
      dataSave.question = window.COMMON.getDataWithLanguage(props.item, 'question');
      dataSave.code = window.COMMON.getValueFromAttr(props.item, 'code');
      dataSave.introduction = window.COMMON.getDataWithLanguage(props.item, 'introduction');
      dataSave.type = window.COMMON.getValueFromAttr(props.item, 'type');
      dataSave.score = window.COMMON.getValueFromAttr(props.item, 'score');
    }
    select.type = window.COMMON.setDataSelect(options, window.COMMON.getValueFromAttr(props.item, 'type'));
    if (dataSave._id) {
      setStatus(false);
    }
    setSelect(select);
    setDataAnswer(JSON.parse(JSON.stringify(props.answers)));
    setDataDeletedAnswer([]);
    setDataSave({ ...dataSave });
  };

  const editItem = () => {
    setStatus(true);
  };

  const deleteItem = () => {
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteQA;
  };

  const deleteAnswer = (code) => {
    for (let i = 0; i < dataAnswer.length; i++) {
      const item = dataAnswer[i];
      if (item.code === code || item.parentCode === code) {
        if (item._id) {
          dataDeletedAnswer.push(item._id);
        }
        dataAnswer.splice(i, 1);
        i--;
      }
    }
    setDataAnswer([...dataAnswer]);
  };

  const addAnswer = (code) => {
    if (dataAnswer.filter(el => el.parentCode === code).length === MAX_NUMBER_OF_ANSWERS) {
      return;
    } else {
      dataAnswer.push({
        code: window.COMMON.generateCode('A'),
        content: [],
        question: dataSave._id,
        parentCode: code || null,
        score: DEFAULT_SCORE_OF_ANSWER
      });
      setDataAnswer([...dataAnswer]);
    }
  };

  const deleteQA = async () => {
    try {
      if (!dataSave._id) {
        if (props.handleRemoveItem) {
          props.handleRemoveItem(props.id);
        }
        return;
      }
      const params = {
        id: dataSave._id
      };
      const result = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_DEL_QA, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        if (props.handleRemoveItem) {
          props.handleRemoveItem(props.id);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('FormQuestionItemWithScore.deleteQA');
    }
  };

  const generateFormAnswer = (i) => {
    if (dataSave.type === 'CHECKBOX' || dataSave.type === 'CHECKBOX_GRID') {
      return <MDBInput type="checkbox" containerClass="pl-2 pt-1" />;
    }
    if (dataSave.type === 'LEVEL') {
      return <div className="progress mr-2" style={{ width: '100px' }}>
        <div className={'progress-bar progress-bar-striped progress-bar-animated w-100 ' + bg[i % 5]} role="progressbar"></div>
      </div>;
    }
    return (
      <MDBInput type="radio" containerClass="pl-2 pt-1" />
    );
  };

  const generateFormAnswerGrid = () => {
    const parent = dataAnswer.filter(function (item) {
      return window.COMMON.getValueFromAttr(item, 'parentCode', null) === null;
    });
    if (parent.length > 0) {
      return parent.map((item, i) => <div className="mt-3" key={`${item.code}-${i}`}>
        <div className="d-flex align-items-center">
          <MDBInput required outline type="text" containerClass="m-0" name="content" readOnly={!status} value={window.COMMON.getValueWithLanguage(item, 'content', props.language)} onChange={(event) => handleChangeAnswer(event, item)} maxLength="1000" />
          {status ? <MDBIcon fa="true" icon="times" className="fa-fw m-2" style={{ color: 'gray' }} onClick={() => deleteAnswer(item.code)}></MDBIcon> : <></>}
          {status ? <MDBIcon fa="true" icon="plus" className="fa-fw mr-2" style={{ color: '#302E90' }} onClick={() => addAnswer(item.code)}></MDBIcon> : <></>}
        </div>
        <MDBRow>
          {
            window.COMMON.getArrsInArrs(item.code, dataAnswer, 'parentCode').map((child, j) => <MDBCol sm="6" key={`${child.code}-${j}`}><div className="d-flex align-items-center mt-3">
              {generateFormAnswer(j)}
              <MDBInput
                outline
                type="text"
                containerClass="m-0 answer-input"
                name="content"
                readOnly={!status}
                value={window.COMMON.getValueWithLanguage(child, 'content', props.language)}
                onChange={(event) => handleChangeAnswer(event, child)}
                maxLength="1000"
                required
              />
              {status ? <MDBIcon fa="true" icon="times" className="fa-fw m-2" style={{ color: 'gray' }} onClick={() => deleteAnswer(child.code)}></MDBIcon> : <></>}
            </div></MDBCol>)
          }
        </MDBRow>
      </div>);
    }
    return <div></div>;
  };

  useLayoutEffect(() => {
    const dataSave = { important: false };
    setLanguage(props.language);
    if (props.item) {
      dataSave._id = window.COMMON.getValueFromAttr(props.item, '_id', null);
      dataSave.question = window.COMMON.getDataWithLanguage(props.item, 'question');
      dataSave.code = window.COMMON.getValueFromAttr(props.item, 'code');
      dataSave.introduction = window.COMMON.getDataWithLanguageWithDefaultValue(props.item, 'introduction', null);
      dataSave.type = window.COMMON.getValueFromAttr(props.item, 'type');
      dataSave.important = window.COMMON.getValueFromAttr(props.item, 'important', false);
      dataSave.category = window.COMMON.getValueFromAttr(props.item, 'category');
      dataSave.recommend = window.COMMON.getValueFromAttr(props.item, 'recommend');
      dataSave.standardLowOfCategory = props.standardLowOfCategory
      dataSave.standardOfCategory = props.standardOfCategory
      if(props.item.origin){
          dataSave.origin = window.COMMON.getValueFromAttr(props.item, 'origin');
      } 
    }
    select.type = window.COMMON.setDataSelect(options, window.COMMON.getValueFromAttr(props.item, 'type'));
    if (dataSave._id) {
      setStatus(false);
    }
    setSelect(select);
    setDataAnswer(JSON.parse(JSON.stringify(props.answers)));
    setDataDeletedAnswer([]);
    setDataSave({ ...dataSave });
  }, [props.language, props.item, props.answers, props.standardLowOfCategory, props.standardOfCategory, select]);


  useImperativeHandle(ref, () => ({
    validQuestion,
    saveQuestionAndAnswer,
    getCurrentQuestionAndAnswerData
  }));

  return <div className="item-form-question mb-3 wow fadeIn animated" data-wow-delay="0.3s">
    <MDBCard className="form-question p-0">
      <MDBCardBody className="p-3">
        <form id={'form-' + props.id} className="needs-validation mt-2" noValidate ref={formRef}>
          <MDBRow>
            <MDBCol sm="12">
              <div className="d-flex align-items-center">
                <span className="badge badge-info badge-number mr-2 p-2">{props.index + 1}</span>
                <MDBInput id={'checkbox-' + props.id} filled label={window.I18N('is_important')} type="checkbox" containerClass="is_important_box mr-3 pl-2 pt-1" disabled={!status} checked={dataSave.important} onChange={(event) => handleChange(event, 'important')} />
                <Select id={'select-type-' + props.id} value={select.type} isDisabled={!status} options={options} placeholder={window.I18N('type') + ' *'} className="md-form m-0" onChange={(event) => handleSelect(event, 'select-type', 'type')} isSearchable />
              </div>
            </MDBCol>
            <MDBCol sm="12">
              <MDBInput outline style={{minHeight: '60px'}} type="textarea" readOnly={!status} label={window.I18N('content') + ' *'} name="question" value={window.COMMON.getValueWithLanguage(dataSave, 'question', language)} onChange={handleChangeWithLanguage} maxLength="1000" required></MDBInput>
            </MDBCol>
            <MDBCol sm="12">
              <MDBInput outline containerClass="mb-0 mt-0" type="text" readOnly={!status} label={window.I18N('introduction')} name="introduction" value={window.COMMON.getValueWithLanguage(dataSave, 'introduction', language)} onChange={handleChangeWithLanguage} maxLength="5000"></MDBInput>
            </MDBCol>
            {dataSave.type !== 'TEXT' && <MDBCol sm="12">
              {
                dataSave.type === 'CHECKBOX_GRID' || dataSave.type === 'RADIO_GRID' ? generateFormAnswerGrid()
                  : dataAnswer.map((item, i) => <div className="d-flex align-items-center mt-3" key={`${item.code}-${i}`}>
                    {generateFormAnswer(i)}
                    <div className='answer-score-group'>
                      <MDBInput outline type="text" containerClass="m-0" name="content" readOnly={!status} value={window.COMMON.getValueWithLanguage(item, 'content', props.language)} onChange={(event) => handleChangeAnswer(event, item)} maxLength="1000" required />
                      <MDBInput
                        outline type="number" name="score"
                        min={1} max={10} step={1}
                        containerClass="m-0 score-input"
                        value={item?.score || 1}
                        onChange={e => handleChangeAnswerScore(e, item)}
                      />
                    </div>
                    {status ? <MDBIcon fa="true" icon="times" className="fa-fw m-2" style={{ color: 'gray' }} onClick={() => deleteAnswer(item.code)}></MDBIcon> : <></>}
                  </div>)
              }
            </MDBCol>}
          </MDBRow>
        </form>
      </MDBCardBody>
      <MDBCardFooter className="p-2 d-flex align-items-center">
        {
          status ? <>
            {
              dataSave.type !== 'TEXT' && dataAnswer.filter(item => item.parentCode === null).length < MAX_NUMBER_OF_ANSWERS ?
                <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={() => addAnswer(null)}>
                  <MDBIcon fa="true" icon="plus" style={{ color: '#302E90' }} className="fa-fw" /> {window.I18N('add_row')}
                </MDBBtn> : <></>
            }
            <MDBBtn outline className="btn btn-sm btn-transparent ml-auto m-0" type="button" color="" disabled={props.btnStatus} onClick={props.btnStatus ? () => { } : deleteItem}>
              <MDBIcon fa="true" icon="trash-alt" style={{ color: 'red' }} className="fa-fw" />
            </MDBBtn>
            <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={clearItem}>
              <MDBIcon fa="true" icon="redo" style={{ color: 'gray' }} className="fa-fw" />
            </MDBBtn>

          </> : <>
            <MDBBtn outline className="btn btn-sm btn-transparent ml-auto m-0" type="button" color="" disabled={props.btnStatus} onClick={props.btnStatus ? () => { } : deleteItem}>
              <MDBIcon fa="true" icon="trash-alt" style={{ color: 'red' }} className="fa-fw" />
            </MDBBtn>
            <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" disabled={props.btnStatus} onClick={props.btnStatus ? () => { } : editItem}>
              <MDBIcon fa="true" icon="edit" style={{ color: 'orange' }} className="fa-fw" />
            </MDBBtn>
          </>
        }
      </MDBCardFooter>
    </MDBCard>
  </div>;
});

export default React.memo(FormQuestionItemWithScore);