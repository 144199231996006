import React from 'react';
import { MDBBtn, MDBIcon } from 'mdbreact';

const AttributeView = React.memo(function AttributeView(props) {

  const handleAdd = (event, index) => {
    event.stopPropagation();
    if (props.handleAdd) {
      props.handleAdd(index);
    }
  };

  const handleEdit = (event, index) => {
    event.stopPropagation();
    if (props.handleEdit) {
      props.handleEdit(index);
    }
  };

  const handleDelete = (event, index) => {
    event.stopPropagation();
    if (props.handleDelete) {
      props.handleDelete(index);
    }
  };

  const getColorStyle = (item) => {
    return { color: (item.color ? item.color : '') };
  };

  const getBoldStyle = (item) => {
    return { fontWeight: (item.isBold ? 'bold' : '') };
  };

  const getTextValue = (item) => {
    if (item.type === 'COMPANY') {
      return '<span>' + window.COMMON.getValueFromAttr(props.company, item.value) + ' ' + item.unit + '</span>';
    }
    const obj = window.COMMON.getObjectInArrs(item._id, props.dataCompany, 'attribute._id');
    if (obj) {
      if (item.type === 'DATE_TIME') {
        return '<span>' + window.COMMON_DATE.formatStringToDate(obj.value) + ' ' + item.unit + '</span>';
      }
      if (item.type === 'SELECT' || item.type === 'RADIO' || item.type === 'CHECKBOX') {
        const options = window.COMMON.parseStringToObject(item.value);
        const select = window.COMMON.setDataSelect(options, obj.value);
        return '<span>' + (select && select.label) + ' ' + item.unit + '</span>';
      }
      return '<span>' + obj.value + ' ' + item.unit + '</span>';
    }
    return '';
  };

  const generateItem = (code, level) => {
    let html = '';
    const dataLevel = level + 1;
    const arrs = window.COMMON.getArrsInArrs(code, props.data, 'parentCode'); 
    if (arrs.length > 0) {
      if (!props.isView) {
        html = arrs.map((item, i) => 
          <React.Fragment key={i}>
            <div className="item-list-tree" data-level={dataLevel}>
              <div className="media">
                <div className="media-body align-self-center">
                  <p className="m-0" style={getBoldStyle(item)}>
                    { window.COMMON.checkValue(item.indexText) ? <span className="mr-1" style={getColorStyle(item)}>{item.indexText}. </span>: <span></span> } 
                    <span className="mr-3" style={getColorStyle(item)}>{item.title}</span>
                    <label className="badge badge-info p-1 mb-0 mr-1">{item.type}</label>
                  </p>
                </div>
                <div className="media-right align-self-center">
                  <MDBBtn outline className="btn-sm mt-0 mb-0 btn-row" type="button" color="" onClick= {(event) => handleAdd(event, item.idx)}>
                    <MDBIcon fa="true" icon="plus-circle" style= {{ color: '#007bff' }} className="fa-fw" />
                  </MDBBtn>
                  <MDBBtn outline className="btn-sm mt-0 mb-0 btn-row" type="button" color="" onClick= {(event) => handleEdit(event, item.idx)}>
                    <MDBIcon fa="true" icon="edit" style= {{ color: 'orange' }} className="fa-fw" />
                  </MDBBtn>
                  <MDBBtn outline className="btn-sm mt-0 mb-0 btn-row" type="button" color="" onClick= {(event) => handleDelete(event, item.idx)}>
                    <MDBIcon fa="true" icon="trash-alt" style= {{ color: 'red' }} className="fa-fw" />
                  </MDBBtn>
                </div>
              </div>
            </div>
            {generateItem(item.code, dataLevel)}
          </React.Fragment>
        );
      } else {
        html = arrs.map((item, i) => 
          <React.Fragment key={i}>
            <div className="item-list-tree item-not-hover" data-level={dataLevel}>
              <div className="media">
                <div className="media-body align-self-center">
                  <p className="m-0" style={getBoldStyle(item)}>
                    { window.COMMON.checkValue(item.indexText) ? <span className="mr-1" style={getColorStyle(item)}>{item.indexText}. </span>: <span></span> } 
                    <span style={getColorStyle(item)}>{item.title}</span>
                    <span className="ml-1" dangerouslySetInnerHTML={{__html: getTextValue(item)}}></span>
                  </p>
                </div>
              </div>
            </div>
            {generateItem(item.code, dataLevel)}
          </React.Fragment>
        );
      }
      return html;
    }
    return html;
  };

  return (
    <div className="container-list-tree">{generateItem('-1', 0)}</div>
  );
});

export default AttributeView;