import React from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';
import { MDBContainer, MDBCol, MDBRow, MDBBtn } from 'mdbreact';

const ExportPDF = React.memo(function ExportPDF(props) {
  const pdf = React.useRef(null);
  const handleExport = () => {
    pdf.current.save();
  };


  return (
    <div>
      <PDFExport ref={pdf} paperSize='A4' landscape='true' margin={'1cm'} fileName={'Facility_Info'} >
        <MDBContainer >
          <MDBRow className="d-flex">
            <MDBCol start className="text-center font-weight-bold mr-1">{window.I18N('Management_agencies')}</MDBCol>
            <MDBCol end className="text-center font-weight-bold mr-1">{window.I18N('National_brand')}</MDBCol>
          </MDBRow>
          <MDBRow className="d-flex">
            <MDBCol start className="text-center font-weight-bold mr-1">----------------</MDBCol>
            <MDBCol end className="text-center font-weight-bold mr-1">{window.I18N('Slogans')}</MDBCol>
          </MDBRow>
          <MDBRow className="d-flex">
            <MDBCol start className="text-center font-weight-bold mr-1">{window.I18N('CompanyName')}</MDBCol>
            <MDBCol end className="text-center font-weight-bold mr-1">----------------</MDBCol>
          </MDBRow>
          <MDBRow className="d-flex">
            <MDBCol ></MDBCol>
            <MDBCol end className="text-center font-weight-bold mr-1">{window.I18N('Date')}</MDBCol>
          </MDBRow>
        </MDBContainer>
        <MDBContainer >
          <MDBRow>
            <MDBCol className="" ></MDBCol>
          </MDBRow>
        </MDBContainer>
        <br/>
        <div className='Container'>
          <div className='row'>
            <div className='col text-center'>
              <h2 className='font-weight-bold'>{window.I18N('self-assessment-sheet')}</h2>
              <h3 className='font-weight-bold'>{window.I18N('title_Export')}</h3>
              <h3 className='font-weight-bold'>{window.I18N('title_Export1')}</h3>
              <p>{window.I18N('report_cycle')}</p>
            </div>
            <div className='px-3 mx-2' >
              <div class="border border-dark">
                <span style={{margin: '20px'}}>
                  <p style={{fontWeight:'bold',margin: '10px'}}>{window.I18N('ticket_number')}</p>
                  <p style={{margin: '10px'}}>{window.I18N('warning_ban')}</p>
                </span>
              </div>
            </div>
          </div>
        </div>
        {props.data}
        <br/>
        <div>
          <MDBContainer>
            <MDBRow className="d-flex">
              <MDBCol start className="text-center font-weight-bold mr-1">{window.I18N('union_president')}</MDBCol>
              <MDBCol end className="text-center font-weight-bold mr-1">{window.I18N('business_representative')}</MDBCol>
            </MDBRow>
            <MDBRow className="d-flex">
              <MDBCol start className="text-center mr-1">{window.I18N('sign_affix')}</MDBCol>
              <MDBCol end className="text-center mr-1">{window.I18N('sign_affix')}</MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </PDFExport>
      <div class="d-flex justify-content-center">
        <MDBBtn onClick={(event) => {handleExport();props.onClick(event);}}>{window.I18N('export_pdf')}</MDBBtn>
      </div>
    </div>
  );
});

export default ExportPDF;