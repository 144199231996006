import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import React from 'react';
import { useHistory } from 'react-router-dom';


const FanpageDiv = ({ data, active }) => {
    const history = useHistory();
    return (
        <Grid container className={`flex p-2 item ${active?" active":""}`}>
            <Grid item style={{width:"22%"}} className='my-auto'><img src={data.image} width="52px" height="52px"></img></Grid>
            <div onClick={() => { history.push(`/my-fanpage?${data._id}`) }} style={{width:"78%", height:"60px"}} className=' pl-2 overflow-hidden ' >
                <Grid container style={{fontWeight:500}}>{data.name} </Grid>
                <Grid container style={{color:"#0000008A", fontWeight:400}}>{(data.type==="PUBLIC"?window.I18N("public_fanpage"): window.I18N("private_fanpage")) + ` - ${data.countMember} ` + window.I18N("member")}</Grid>
                <Grid container >{data.description }</Grid>
            </div>
        </Grid>
    )
}
const mapStateToProps = state => ({
    language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(FanpageDiv));