import React, { useState, useCallback, useLayoutEffect } from 'react';
import { MDBBtn, MDBInput, MDBIcon } from 'mdbreact';
import { Grid } from '@material-ui/core';
import { AddPhotoAlternate } from '@material-ui/icons';
import DatePicker from 'react-datepicker';

import ScheduleCalendar from '../../components/ScheduleCalendar';
import InputFile from '../../components/InputFile';
import ListOption from '../../components/ListOption';
import ListImage from '../../components/ListImage';
import SelectAccount from '../../components/SelectAccount';
import RadioBox from '../../components/RadioBox';
import Modal from '../../components/Modal';
import ColorPicker from '../../components/ColorPicker';

import AccountGRAPHQL from '../../../graphql/Account';
import PostGRAPHQL from '../../../graphql/Post';

export default function SchedulePostPage(props) {
  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const DAYS_IN_WEEK = [window.I18N('sun'), window.I18N('mon'), window.I18N('tue'), window.I18N('wed'), window.I18N('thu'), window.I18N('fri'), window.I18N('sat')];

  const types = [
    { value: 'MONTH', label: window.I18N('month') },
    { value: 'WEEK', label: window.I18N('week') },
    { value: 'DAY', label: window.I18N('day') },
    { value: 'LIST', label: window.I18N('list') }
  ];
  const postTypes = [
    { value: 'POST', label: window.I18N('post')},
    { value: 'SURVEY_POST', label: window.I18N('survey_post')}
  ];

  const [object, setObject] = useState({ item: null, isUpdate: false, isPast: false, view: { value: 'MONTH', label: window.I18N('month') } });
  const [date, setDate] = useState({ currentDate: new Date(), scheduleDate: new Date() });
  const [data, setData] = useState([]);
  const [dataSchedule, setDataSchedule] = useState([]);
  const [dataImage, setDataImage] = useState([]);
  const [dataAnswer, setDataAnswer] = useState([]);
  const [select, setSelect] = useState({ account: [] });
  const [options, setOptions] = useState({ account: [] });
  const [dataSave, setDataSave] = useState({
    type: 'POST', answer: []
  });

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataAccount();
      generateCalendar(date.currentDate, object.view);
    }, 100);
    // eslint-disable-next-line
  }, []);

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const handleDatePicker = (event, attr) => {
    date[attr] = event;
    setDate({ ...date });
    if (attr === 'currentDate') {
      generateCalendar(date.currentDate, object.view);
    }
  };

  const handleRadio = useCallback((value) => {
    dataSave.type = value;
    setDataSave({ ...dataSave });
  }, [dataSave]);

  const handleChange = (event, attr) => {
    dataSave[attr] = event.target.value;
    setDataSave({...dataSave});
  };

  const handleColor = (color) => {
    object.color = color;
    setObject({ ...object });
  };

  const handleFile = (files) => {
    dataSave.files = files;
    const dataImage = files.map(file => window.COMMON.createBlobImage(file));
    setDataSave(dataSave);
    setDataImage([ ...dataImage ]);
  };

  const handleSelectAccount = useCallback((select) => {
    select.account = select;
    setSelect({ ...select });
  }, []);

  const changeView = (value) => {
    object.view = value;
    setObject({ ...object });
    generateCalendar(date.currentDate, object.view);
  };

  const changeDate = (mode, dateValue) => {
    let value = null;
    if (mode === 0) {
      value = dateValue;
    } else if (mode === -1) {
      if (object.view.value === 'MONTH') {
        value = window.COMMON_DATE.addMonths(dateValue.setDate(1), -1);
      } else if (object.view.value === 'WEEK' || object.view.value === 'LIST') {
        value = window.COMMON_DATE.addDays(dateValue, -7);
      } else {
        value = window.COMMON_DATE.addDays(dateValue, -1);
      }
    } else {
      if (object.view.value === 'MONTH') {
        value = window.COMMON_DATE.addMonths(dateValue.setDate(1), 1);
      } else if (object.view.value === 'WEEK' || object.view.value === 'LIST') {
        value = window.COMMON_DATE.addDays(dateValue, 7);
      } else {
        value = window.COMMON_DATE.addDays(dateValue, 1);
      }
    }
    date.currentDate = value;
    setDate({ ...date });
    generateCalendar(date.currentDate, object.view);
  };

  const getDataAccount = async () => {
    try {
      const params = {
        companyType: COMPANY_TYPE
      };
      const result = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_FOR_POST, params, false);
      if (result && result.data) {
        options.account = window.COMMON.createDataSelect(result.data.findAccountsForPost, '_id', 'nickname', null, 'avatar');
        setOptions({ ...options });
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SchedulePostPage.getDataAccount');
    }
  };

  const getDataSchedulePost = async () => {
    try {
      const params = {
        input: {
          page: 1,
          pageSize: 9999,
          startDate: window.COMMON_DATE.getStrDateFilter(date.startDate, -1),
          endDate: window.COMMON_DATE.getStrDateFilter(date.endDate, 1)
        }
      };
      const result = await window.COMMON.query(PostGRAPHQL.QUERY_SCHEDULE_POST, params);
      let data = [];
      if (result && result.data && result.data.getSchedulePosts) {
        data = result.data.getSchedulePosts.data;
        data = window.COMMON.sortData(data, 'date');
        data.forEach(item => {
          item.textDate = window.COMMON_DATE.formatDate(item.date);
          item.textTime = window.COMMON_DATE.formatDate(item.date, 'HH:mm');
          item.hours = window.COMMON_DATE.formatDate(item.date, 'HH');
          item.minutes = window.COMMON_DATE.formatDate(item.date, 'mm');
        });
      }
      setDataSchedule(data);
    } catch (error) {
      window.COMMON.showErrorLogs('SchedulePostPage.getDataSchedulePost');
    }
  };

  const generateCalendar = (value, view) => {
    if (view.value === 'MONTH') {
      generateDataMonth(value);
    } else if (view.value === 'WEEK' || view.value === 'LIST') {
      generateDataWeek(value);
    } else {
      generateDataDay(value);
    }
    getDataSchedulePost();
  };

  const generateDataMonth = (dateValue) => {
    const month = dateValue.getMonth();
    const days = window.COMMON_DATE.daysInMonth(dateValue);
    const dataDate = [];
    for (let i = 0; i < days; i++) {
      const itemDate = new Date(dateValue.getFullYear(), month, i + 1);
      if (i === 0) {
        const daysInWeek = itemDate.getDay();
        if (daysInWeek > 0) {
          const last = window.COMMON_DATE.addMonths(itemDate, -1);
          const daysInLastMonth = window.COMMON_DATE.daysInMonth(last);
          for (let j = 0; j < daysInWeek; j++) {
            const itemLastDate = new Date(last.getFullYear(), last.getMonth(), daysInLastMonth - j);
            dataDate.unshift({
              type: -1,
              day: itemLastDate.getDate(),
              daysInWeek: DAYS_IN_WEEK[itemLastDate.getDay()],
              date: itemLastDate,
              events: []
            });
          }   
        }
      }
      dataDate.push({
        type: 0,
        day: itemDate.getDate(),
        daysInWeek: DAYS_IN_WEEK[itemDate.getDay()],
        date: itemDate,
        events: []
      });
      if (i === days - 1) {
        const daysInWeek = itemDate.getDay();
        if (daysInWeek < 6) {
          const next = window.COMMON_DATE.addDays(itemDate, 1);
          for (let j = 0; j < 6 - daysInWeek; j++) {
            const itemNextDate = new Date(next.getFullYear(), next.getMonth(), j + 1);
            dataDate.push({
              type: 1,
              day: itemNextDate.getDate(),
              daysInWeek: DAYS_IN_WEEK[itemNextDate.getDay()],
              date: itemNextDate,
              events: []
            });       
          }
        }
      }
    }
    const data = [[], [], [], [], [], []];
    let index = 0;
    for (let i = 0; i < dataDate.length; i++) {
      const item = dataDate[i];
      if (i % 7 === 0 && i > 0) {
        index++;
      }
      data[index].push(item);
    }
    date.startDate = dataDate[0].date;
    date.endDate = dataDate[dataDate.length - 1].date;
    setData(data);
    setDate({ ...date });
  };

  const generateDataWeek = (dateValue) => {
    const data = [];
    const daysInWeek = dateValue.getDay();
    if (daysInWeek > 0) {
      for (let i = 0; i < daysInWeek; i++) {
        const itemLastDate = window.COMMON_DATE.addDays(dateValue, 0 - (i + 1));
        data.unshift({
          day: itemLastDate.getDate(),
          daysInWeek: DAYS_IN_WEEK[itemLastDate.getDay()],
          date: itemLastDate,
          displayDate: window.COMMON_DATE.formatDate(itemLastDate, 'DD/MM'),
          events: []
        });
      }   
    }
    data.push({
      day: dateValue.getDate(),
      daysInWeek: DAYS_IN_WEEK[daysInWeek],
      displayDate: window.COMMON_DATE.formatDate(dateValue, 'DD/MM'),
      date: dateValue,
      events: []
    });
    if (daysInWeek < 6) {
      for (let i = 0; i < 6 - daysInWeek; i++) {
        const itemNextDate = window.COMMON_DATE.addDays(dateValue, i + 1);
        data.push({
          day: itemNextDate.getDate(),
          daysInWeek: DAYS_IN_WEEK[itemNextDate.getDay()],
          date: itemNextDate,
          displayDate: window.COMMON_DATE.formatDate(itemNextDate, 'DD/MM'),
          events: []
        });
      }   
    }
    date.startDate = data[0].date;
    date.endDate = data[data.length - 1].date;
    setData(data);
    setDate({ ...date });
  };

  const generateDataDay = (dateValue) => {
    const data = [];
    data.push({
      day: dateValue.getDate(),
      daysInWeek: DAYS_IN_WEEK[dateValue.getDay()],
      date: dateValue,
      events: []
    });
    date.startDate = data[0].date;
    date.endDate = data[data.length - 1].date;
    setData(data);
    setDate({ ...date });
  };

  const handleDateClick = (item, time) => {
    const dateValue = item.date;
    if (time) {
      const arrs = time.value.split(':');
      dateValue.setHours(Number(arrs[0]));
      dateValue.setMinutes(Number(arrs[1]));
    }
    const compareDate = window.COMMON_DATE.compareWithNow(dateValue, time ? true : false, time ? true : false);
    if (compareDate === -1) {
      window.COMMON.showMessage('warning', 'MSG_CODE_046', window.I18N('MSG_CODE_046'));
      return false;
    }
    date.scheduleDate = new Date(dateValue.toString());
    if (compareDate === 0) {
      const now = new Date();
      const minutes = now.getMinutes();
      if (minutes % 15 > 0) {
        now.setMinutes(minutes + (15 - minutes % 15));
      }
      date.scheduleDate = now;
    }
    object.color = '#EEEEEE';
    object.isUpdate = false;
    setDate(date);
    setObject(object);
    resetForm();
    window.COMMON.showModal('#modal-post');
  };

  const handleItemClick = (item) => {
    date.scheduleDate = new Date(+item.date);
    object.item = item;
    object.color = item.color;
    object.isUpdate = true;
    object.isPast = false;
    const compareDate = window.COMMON_DATE.compareWithNow(date.scheduleDate, true, false);
    if (compareDate === -1) {
      object.isPast = true;
    }
    const answers = window.COMMON.getValueFromAttr(item, 'post.answer', []);
    const arrs = [];
    answers.forEach(answer => {
      arrs.push(answer.text);
    });
    const dataSave = {
      text: window.COMMON.getValueFromAttr(item, 'post.text'),
      type: window.COMMON.getValueFromAttr(item, 'post.type'),
      company: window.COMMON.getValue(COMPANY_ID),
      answer: arrs,
      files: null
    };
    select.account = window.COMMON.setDataMultiSelectWithGroup(options.account, window.COMMON.getValueFromAttr(item, 'post.people'), '_id');
    document.getElementById('files').value = null;
    setDate(date);
    setObject(object);
    setSelect(select);
    setDataImage([]);
    setDataAnswer(arrs);
    setDataSave({ ...dataSave });
    window.COMMON.showModal('#modal-post');
  };

  const deleteImage = (index) => {
    dataImage.splice(index, 1);
    setDataImage([ ...dataImage ]);
  };

  const resetForm = () => {
    const dataSave = {
      text: '',
      type: 'POST',
      company: window.COMMON.getValue(COMPANY_ID),
      answer: [],
      files: null
    };
    select.account = [];
    document.getElementById('files').value = null;
    setSelect(select);
    setDataImage([]);
    setDataAnswer([]);
    setDataSave({ ...dataSave });
  };

  const receiveAnswer = useCallback((data) => {
    setDataAnswer([ ...data ]);
  }, []);

  const openDeleteDialog = () => {
    window.COMMON.hideModal('#modal-post');
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteData;
  };

  const addSchedulePost = async (event) => {
    try {
      if (window.COMMON_DATE.compareWithNow(date.scheduleDate) === -1) {
        window.COMMON.showMessage('warning', 'MSG_CODE_046', window.I18N('MSG_CODE_046'));
        return false;
      }
      if (!window.COMMON.checkFormValidation('form', event)) {
        return;
      }
      window.showLoading();
      const people = [];
      select.account.forEach(item => {
        people.push(item.value);
      });
      dataSave.company = COMPANY_TYPE === window.CONSTANT.COMPANY ? window.COMMON.getValue(COMPANY_ID) : null;
      dataSave.union = COMPANY_TYPE === window.CONSTANT.UNION ? window.COMMON.getValue(COMPANY_ID) : null;
      dataSave.influencer = COMPANY_TYPE === window.CONSTANT.INFLUENCER ? window.COMMON.getValue(COMPANY_ID) : null;
      dataSave.people = people;
      dataSave.answer = dataAnswer;
      dataSave.isPublic = dataSave.people.length > 0 ? false : true;
      dataSave.isAdmin = COMPANY_TYPE === window.CONSTANT.ADMIN;
      dataSave.isScheduled = true;
      // TODO upload file
      let dataFiles = [];
      const image = [];
      if (dataSave.files) {
        dataFiles = await window.uploadFile(dataSave.files);
        if (dataFiles.length > 0) {
          dataFiles.forEach(element => {
            image.push(window.COMMON.getNewPathFile(element._id));
          });
        }
      }
      dataSave.image = image;
      dataSave.files = null;
      // TODO save data
      const params = {
        input: dataSave
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(PostGRAPHQL.MUTATION_ADD_POST, params);
      if (result && result.data && result.data.addPost && result.data.addPost._id) {
        const paramsSchedule = {
          input: {
            post: result.data.addPost._id,
            color: object.color,
            date: date.scheduleDate.toString()
          }
        };
        const resultSchedule = await window.COMMON.mutation(PostGRAPHQL.MUTATION_ADD_SCHEDULE_POST, paramsSchedule);
        if (window.COMMON.checkResultData(resultSchedule, window.CONSTANT.MODE_INSERT)) {
          getDataSchedulePost();
        }
      }
      window.COMMON.hideModal('#modal-post');
    } catch (error) {
      window.COMMON.showErrorLogs('SchedulePostPage.addSchedulePost');
    }
    window.hideLoading();
  };

  const editSchedulePost = async (event) => {
    try {
      if (window.COMMON_DATE.compareWithNow(date.scheduleDate) === -1) {
        window.COMMON.showMessage('warning', 'MSG_CODE_046', window.I18N('MSG_CODE_046'));
        return false;
      }
      if (!window.COMMON.checkFormValidation('form', event)) {
        return;
      }
      const params = {
        input: {
          _id: object.item._id,
          post: object.item.post._id,
          color: object.color,
          date: date.scheduleDate.toString()
        }
      };
      const result = await window.COMMON.mutation(PostGRAPHQL.MUTATION_EDIT_SCHEDULE_POST, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
        getDataSchedulePost();
      }
      window.COMMON.hideModal('#modal-post');
    } catch (error) {
      window.COMMON.showErrorLogs('SchedulePostPage.editSchedulePost');
    }
  };

  const deleteData = async () => {
    try {
      const params = {
        id: object.item._id
      };
      const result = await window.COMMON.mutation(PostGRAPHQL.MUTATION_DEL_SCHEDULE_POST, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataSchedulePost();
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SchedulePostPage.deleteData');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <ScheduleCalendar currentDate={date.currentDate} view={object.view} data={data} dataSchedule={dataSchedule} handleDateClick={handleDateClick} handleItemClick={handleItemClick}>
        <div className="container-header bg-white d-flex align-items-center z-index-100 position-relative p-2 wow fadeInDown animated" data-wow-delay="0.5s">
          <MDBBtn color="" className="btn-sm mr-3" onClick={() => changeDate(0, new Date())}>{window.I18N('today')}</MDBBtn>
          <MDBBtn color="" className="btn-sm" onClick={() => changeDate(-1, date.currentDate)}><MDBIcon fa="true" icon="angle-left" className="fa-fw" /></MDBBtn>
          <div className="input-group input-group-transparent" style={{ width: '175px' }}>
            <div className="input-group-prepend">
              <span className="input-group-text">
                <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
              </span>
            </div>
            <DatePicker className="form-control" dateFormat="dd/MM/yyyy" selected={date.currentDate} onChange={(event) => handleDatePicker(event, 'currentDate')} showYearDropdown showMonthDropdown/>
          </div>
          <MDBBtn color="" className="btn-sm" onClick={() => changeDate(1, date.currentDate)}><MDBIcon fa="true" icon="angle-right" className="fa-fw" /></MDBBtn>
          <div className="dropdown ml-auto">
            <MDBBtn color="" className="btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-boundary="viewport">
              {object.view.label} <MDBIcon fa="true" icon="caret-down" className="fa-fw" />
            </MDBBtn>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" data-boundary="viewport">
              {
                types.map((item, i) => 
                  <li className={object.view.value === item.value ? 'dropdown-item active' : 'dropdown-item'} key={i} onClick={() => changeView(item)}>
                    {item.label}
                  </li>
                )
              }
            </ul>
          </div>
        </div>
      </ScheduleCalendar>

      <Modal id="modal-post" title={window.I18N('create_new_post')} isNotScroll={true} className="modal-lg" hideFooter={true}>
        <form id="form" className="needs-validation" noValidate>
          <Grid container>
            <Grid item xs={12} className="mb-2">
              <div className="mb-3 d-flex align-items-center">
                <RadioBox data={postTypes} className="mr-auto" value={dataSave.type} disabled={object.isUpdate} handleChange={handleRadio}></RadioBox>
                <ColorPicker handleChange= {handleColor} color={object.color} disabled={object.isUpdate && object.isPast}></ColorPicker>
                <div className="input-group input-group-transparent ml-1" style={{ width: '240px' }}>
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="clock" className="fa-fw" />
                    </span>
                  </div>
                  <DatePicker className="form-control" selected={date.scheduleDate} showTimeSelect showTimeSelectOnly timeIntervals={15} dateFormat="dd/MM/yyyy hh:mm aa" disabled={object.isUpdate && object.isPast} filterTime={(time) => filterPassedTime(time)} onChange={(event) => handleDatePicker(event, 'scheduleDate')}/>
                </div>
              </div>
              <MDBInput outline id="input-text" type="textarea" containerClass="m-0" hint={window.I18N('share_what_you_thinging')} value={dataSave.text} onChange={(event) => handleChange(event, 'text')} maxLength="5000" pattern="\S(.*\S)?"  disabled={object.isUpdate} required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
            </Grid>
            {
              dataImage.length > 0 ? <Grid item xs={12} className="mb-2">
                <p className="mb-2"><small className="font-weight-bold">{window.I18N('images')}</small></p>
                <ListImage data={dataImage} handleDeleteItem={deleteImage}></ListImage>
              </Grid> : <></>
            }
            <Grid item xs={12} container className="mt-2">
              <Grid item xs container spacing={2}>
                <InputFile id="files" icon="upload" handleFile={handleFile} accept="image/*" isMulti={true} disabled={object.isUpdate}>
                  <AddPhotoAlternate color="primary"/>
                </InputFile>
                <SelectAccount options={options.account} selected={select.account} handleClose={handleSelectAccount} disabled={object.isUpdate}></SelectAccount>
              </Grid>
              <Grid item>
                {
                  object.isUpdate ? <MDBBtn color="danger" onClick={openDeleteDialog}>
                    <MDBIcon fa="true" icon="trash" className="fa-fw" /> {window.I18N('btn_delete')}
                  </MDBBtn> : <MDBBtn color="" onClick={resetForm}>
                    <MDBIcon fa="true" icon="redo-alt" className="fa-fw" /> {window.I18N('btn_clear')}
                  </MDBBtn>
                }
                <MDBBtn color="primary" disabled={object.isUpdate && object.isPast} onClick={object.isUpdate ? editSchedulePost : addSchedulePost} className="mr-0">
                  <MDBIcon fa="true" icon="paper-plane" className="fa-fw" /> {window.I18N('btn_save')}
                </MDBBtn>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {
                dataSave.type === 'POST' ? null : <ListOption data={dataAnswer} title={window.I18N('survey_answers')} disabled={object.isUpdate} handleReceiveData={receiveAnswer}></ListOption>
              }
            </Grid>
          </Grid>
        </form>
      </Modal>
    </div>
  );
}