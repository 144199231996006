import React, { useEffect, useState } from 'react';
import { HorizontalBar, Bar, Doughnut, Pie, Polar, Radar, Line } from 'react-chartjs-2';
import { MDBCard, MDBCardBody, MDBIcon } from 'mdbreact';

const dataSelect = [
  {
    value: 0,
    label: 'Bar Chart (Horizontal)'
  },
  {
    value: 1,
    label: 'Bar Chart (Vertical)'
  },
  {
    value: 2,
    label: 'Pie Chart'
  },
  {
    value: 3,
    label: 'Donut Chart'
  },
  {
    value: 4,
    label: 'Polar Chart'
  },
  {
    value: 5,
    label: 'Radar Chart'
  },
  {
    value: 6,
    label: 'Line Chart'
  }
];

const ItemQuestionChart = React.memo(function ItemQuestionChart(props) {
  const [chart, setChart] = useState({
    value: 0,
    label: 'Bar Chart (Horizontal)'
  });

  const [dataForChart, setDataForChart] = useState([])

  const changeChart = (chart) => {
    setChart(chart);
  };

  useEffect(() => {
    if(!window.COMMON.isEmpty(props.dataChart)){
      // TODO: deep copy to avoid having properties changed inside the object
      const cloneDataChart = window.COMMON.deepCopyArray(props.dataChart)
      const dataChart = cloneDataChart.find(data => data.questionId === props.item._id)
      setDataForChart(dataChart?.chart || [])
    }
  },[props.item, props.dataChart])

  const scalesY = {
    yAxes: [{
      ticks: {
        beginAtZero: true,
        suggestedMin: 0,
        suggestedMax: 10
      }
    }]
  };

  const scalesX = {
    xAxes: [{
      ticks: {
        beginAtZero: true,
        suggestedMin: 0,
        suggestedMax: 10
      }
    }]
  };

  if (!props.item) {
    return <div></div>;
  }
  if (props.item.type === 'TEXT') {
    return <MDBCard className="h-100 p-3 wow fadeInUp animated text-left" data-wow-delay="0.5s">
      <MDBCardBody className="p-3">
        <div className="alert alert-warning" role="alert">
          <p className="mb-1 font-weight-bold" style={{ color: '#FC9905' }}>
            <span>{window.I18N('question')}</span>
            { window.COMMON.getValueFromAttr(props.item, 'important') ? <MDBIcon fa="true" icon="exclamation-circle" className="ml-1 fa-fw"></MDBIcon> : <></> }
          </p>
          <p className="mb-0">
            {(window.COMMON.getValueFromAttr(props.item, 'index') + 1) + '. ' + window.COMMON.getValueWithLanguage(props.item, 'question', props.language)}
          </p>
        </div>
        <div className="w-100">
          {!window.COMMON.isEmpty(props.dataChart) && props.dataChart.find(data => data.type === props.item.type)?.chart?.filter(item => item)?.map((item, i) => <label key={props.item._id + i} color="" className="badge-customize p-2 mr-2 mb-2">{item}</label>)}
        </div>
      </MDBCardBody>
    </MDBCard>;
  }    
  return <MDBCard className="p-3 wow fadeInUp animated text-left" data-wow-delay="0.5s">
    <MDBCardBody className="p-3">
      <div className="alert alert-warning" role="alert">
        <p className="mb-1 font-weight-bold" style={{ color: '#FC9905' }}>
          <span>{window.I18N('question')}</span>
          { window.COMMON.getValueFromAttr(props.item, 'important') ? <MDBIcon fa="true" icon="exclamation-circle" className="ml-1 fa-fw"></MDBIcon> : <></> }
        </p>
        <p className="mb-0">
          {(window.COMMON.getValueFromAttr(props.item, 'index') + 1) + '. ' + window.COMMON.getValueWithLanguage(props.item, 'question', props.language)}
        </p>
      </div>
      <div className="d-flex justify-content-end w-100">
        <div className="dropleft p-2">
          <button type="button" className="btn dropdown-toggle m-0" data-toggle="dropdown" data-boundary="viewport">
            <MDBIcon fa="true" icon="chart-bar" className="fa-fw mr-1"></MDBIcon> {chart.label}
          </button>
          <div className="dropdown-menu" data-boundary="viewport">
            {
              dataSelect.map((item, i) => <div key={window.COMMON.genKey()}>
                <div className="pl-2 pr-2 w-100">
                  <button className={item.value === chart.value ? 'dropdown-item pl-2 active' : 'dropdown-item'} type="button" onClick={changeChart.bind(this, item)}>
                    { item.value === chart.value ? <MDBIcon fa="true" icon="check-circle" style={{ color: 'white' }} className="fa-fw mr-1"></MDBIcon> : <div></div> }
                    {item.label}
                  </button>
                </div>
                <div className={i === dataSelect.length - 1 ? 'dropdown-divider mt-1 mb-1 d-none' : 'dropdown-divider mt-1 mb-1'}></div>
              </div>)
            }
          </div>
        </div>
      </div>
      <div className="w-100 p-3">
        {
          window.COMMON.isEmpty(props.dataChart) ? <div></div> :
            chart.value === 0 ? 
              <HorizontalBar data={props.dataChart ? dataForChart : []} options={{ scales: scalesX, legend: { display: false }, responsive: true, maintainAspectRatio: false }} height={300}/>
              : chart.value === 1 ? 
                <Bar data={props.dataChart ? dataForChart : []} options={{ scales: scalesY, legend: { display: false }, responsive: true, maintainAspectRatio: false }} height={300}></Bar>
                : chart.value === 2 ? 
                  <Pie data={props.dataChart ? dataForChart : []} options={{ legend: { position: 'bottom' }, responsive: true, maintainAspectRatio: false }} height={300}/>
                  : chart.value === 3 ? 
                    <Doughnut data={props.dataChart ? dataForChart : []} options={{ legend: { position: 'bottom' }, responsive: true, maintainAspectRatio: false }} height={300}/>
                    : chart.value === 4 ? 
                      <Polar data={props.dataChart ? dataForChart : []} options={{ scales: scalesY, legend: { position: 'bottom' }, responsive: true, maintainAspectRatio: false }} height={300}/>
                      : chart.value === 5 ?
                        <Radar data={props.dataChart ? dataForChart : []} options={{ scales: scalesY, legend: { display: false }, responsive: true, maintainAspectRatio: false }} height={300}/>
                        : <Line data={props.dataChart ? dataForChart : []} options={{ scales: scalesY, legend: { display: false }, responsive: true, maintainAspectRatio: false }} height={300}/>
        }
      </div>
    </MDBCardBody>
  </MDBCard>;
});

export default ItemQuestionChart;