import { Fab, Tooltip } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { MDBBtn, MDBCol, MDBIcon, MDBRow } from 'mdbreact';
import React, { Fragment, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import QuestionGRAPHQL from '../../../graphql/Question';
import ExportExcel from '../../components/ExportExcel';
import ImportExcel from '../../components/ImportExcel';
import ItemPreviewQuestion from '../../components/ItemPreviewQuestion';
import Modal from '../../components/Modal';
import RadioBox from '../../components/RadioBox';
import Table from '../../components/Table';
import useGetSurveyQAData from '../assessment/hooks/useGetSurveyQAData';
import FormQuestionItem from './FormQuestionItem';
import QuestionBank from '../../components/QuestionBank';


const QuestionAndAnswerForm = React.forwardRef((props, ref) => {
  //dataSaveQuestion: array save question data
  // handleSetDataSaveQuestion: function to handle new or delete question data;
  const { groupQA, dataSaveQuestion, setDataSaveQuestion, groupQACloneData } = props;
  const types = [
    { value: 'QUESTION', label: window.I18N('question') },
    { value: 'ANSWER_QUESTION', label: window.I18N('answer') }
  ];
  const { getQAData } = useGetSurveyQAData({
    groupQA
  });
  const [object, setObject] = useState({ index: -1, indexSchedule: -1, radio: 'QUESTION', status: 1, btnStatus: false, isCreated: false, isLoadSchema: false }); const handleRadio = useCallback((value) => {
    object.radio = value;
    setObject({ ...object });
  }, [object]);
  const [dataImport, setDataImport] = useState({});
  const [previewData, setPreviewData] = useState([]);
  const formQuestionItemsRef = useRef({});


  useImperativeHandle(ref, () => ({
    handleValidForm,
    handleSaveForm,
    getQuestionAndAnswerFormData
  }));

  useEffect(() => {
    if (!groupQACloneData) return;
    const groupId = groupQACloneData.groupId;
    if (!groupId) return;
    getQAData(groupId, setDataSaveQuestion);

  }, [groupQACloneData]);

  const handleValidForm = () => {
    if (formQuestionItemsRef.current) {
      const n = dataSaveQuestion.length;
      if (n < 1) return false
      for (let i = 0; i < n; i++) {
        const item = dataSaveQuestion[i];
        const validResult = formQuestionItemsRef.current[item.uuid].validQuestion();
        if (validResult === false) {
          return false;
        }
      }

    }
    return true;

  };
  const handleSaveForm = async (groupQA) => {
    if (formQuestionItemsRef.current) {
      const n = dataSaveQuestion.length;
      for (let i = 0; i < n; i++) {
        const item = dataSaveQuestion[i];
        await formQuestionItemsRef.current[item.uuid].saveQuestionAndAnswer(groupQA);
      }

    }


  };

  const getQuestionAndAnswerFormData = () => {
    const questionAndAnswerFormData = [];
    if (formQuestionItemsRef.current) {
      const n = dataSaveQuestion.length;

      for (let i = 0; i < n; i++) {
        const item = dataSaveQuestion[i];
        const data = formQuestionItemsRef.current[item.uuid].getCurrentQuestionAndAnswerData();
        if (data) {
          const { question, answers } = data;
          questionAndAnswerFormData.push({
            ...item,
            question,
            answers
          });
        }

      }

    }
    return questionAndAnswerFormData;
  };


  const handleFileImport = (dataImport) => {
    checkImportData(dataImport);
    window.COMMON.showModal('#modal-import');
  };

  const removeItemQuestion = useCallback((index) => {
    const newDataSaveQuestion = [];
    dataSaveQuestion.forEach((item) => {
      const { question, answers } = formQuestionItemsRef.current[item.uuid].getCurrentQuestionAndAnswerData();
      newDataSaveQuestion.push({...item, question, answers})
    })
    newDataSaveQuestion.splice(index, 1);
    setDataSaveQuestion([...newDataSaveQuestion]);
  }, [dataSaveQuestion]);


  // IMPORT QUESTION & ANSWER
  const deleteImportRow = useCallback((index) => {
    if (dataImport.data.length > 0) {
      dataImport.data.splice(index, 1);
    }
    setDataImport({ ...dataImport });
  }, [dataImport]);

  const checkImportData = async (dataImport) => {
    try {
      if (dataImport.data.length === 0) {
        return;
      }
      const data = [];
      dataImport.data.forEach(item => {
        if (!data.includes(item.code)) {
          data.push(item.code);
        }
      });
      const params = {
        input: data
      };
      window.COMMON.trimData(params);
      let result = null;
      let dataCheck = [];
      if (object.radio === 'QUESTION') {
        result = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_CHECK_IMPORT_QUESTION, params);
      } else {
        result = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_CHECK_IMPORT_ANSWER_QUESTION, params);
      }
      if (object.radio === 'QUESTION' && result.data && result.data.checkImportQuestion) {
        dataCheck = result.data.checkImportQuestion;
      } else {
        if (result.data && result.data.checkImportAnswerQuestion) {
          dataCheck = result.data.checkImportAnswerQuestion;
        }
      }
      for (let i = 0; i < dataCheck.length; i++) {
        const arrs = dataCheck[i].split('-.-');
        if (Number(arrs[1]) === 1) {
          const array = window.COMMON.getArrsInArrs(arrs[0], dataImport.data, 'code');
          if (array && array.length > 0) {
            array.forEach(element => {
              element.isExisted = true;
            });
          }
        }
      }
      setDataImport(dataImport);
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionAndAnswerForm.checkImportData');
    }
  };

  const openPreview = () => {
    const newPreviewData = dataSaveQuestion.map((item) => {
      const { question, answers } = formQuestionItemsRef.current[item.uuid].getCurrentQuestionAndAnswerData();
      return {
        ...item,
        answers,
        question
      };
    });
    setPreviewData(newPreviewData);
    window.COMMON.showModal('#modal-preview');
  };

  const importData = () => {
    if (dataImport.data.length === 0) {
      window.COMMON.showMessage('warning', 'MSG_CODE_021', window.MSG.MSG_CODE_021);
      return;
    }
    if (object.radio === 'QUESTION') {
      importQuestion();
    } else {
      importAnswerQuestion();
    }
  };

  const importQuestion = async () => {
    try {
      const dataImported = [];
      // dataImport.data.forEach(item => {
      //   const check = window.COMMON.getObjectInArrs(item.code, dataImported, 'code');
      //   if (!check) {
      //     const obj = {};
      //     obj.code = item.code;
      //     obj.type = item.type;
      //     obj.group = groupQA._id;
      //     obj.introduction = [];
      //     obj.question = [];
      //     const arrs = window.COMMON.getArrsInArrs(item.code, dataImport.data, 'code');
      //     arrs.forEach(child => {
      //       obj.introduction.push({ code: child.languageCode, text: child.introduction });
      //       obj.question.push({ code: child.languageCode, text: child.question });
      //     });
      //     dataImported.push(obj);
      //   }
      // });
      dataImport.data.forEach(item => {
        const check = window.COMMON.getObjectInArrs(item.code, dataImported, 'code');
        if (!check) {
          const obj = {};
          obj.code = item.code;
          obj.type = item.type;
          obj.introduction = [];
          obj.question = [];
          const arrs = window.COMMON.getArrsInArrs(item.code, dataImport.data, 'code');
          arrs.forEach(child => {
            obj.introduction.push({ code: child.languageCode, text: child.introduction });
            obj.question.push({ code: child.languageCode, text: child.question });
          });
          dataImported.push({
            uuid: window.COMMON.genKey(),
            group: groupQA._id,
            question: {
              ...obj
            },
            answers: []
          });
        }
      });
      //handle set dataImported into data in form
      setDataSaveQuestion([...dataSaveQuestion, ...dataImported]);

      window.COMMON.hideModal('#modal-import');
      // const params = {
      //   input: dataImported
      // };
      // window.COMMON.trimData(params);
      // const result = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_IMPORT_QUESTION, params);
      // if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_IMPORT)) {
      //   // getDataQuestion();
      //   window.COMMON.hideModal('#modal-import');
      // }
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionAndAnswerForm.importQuestion');
    }
  };

  const importAnswerQuestion = async () => {
    try {
      const dataImported = [];
      dataImport.data.forEach(item => {
        const check = window.COMMON.getObjectInArrs(item.code, dataImported, 'code');
        const question = window.COMMON.getObjectInArrs(item.questionCode, dataSaveQuestion, 'question.code');
        if (!check && question) {
          const obj = {};
          obj.code = item.code;
          obj.group = groupQA._id;
          obj.parentCode = item.parentCode || null;
          obj.question = question.question._id;
          obj.content = [];
          const arrs = window.COMMON.getArrsInArrs(item.code, dataImport.data, 'code');
          arrs.forEach(child => {
            obj.content.push({ code: child.languageCode, text: child.content });
          });
          dataImported.push(obj);
        }

        //find index of question follow question code
        const index = dataSaveQuestion.findIndex((item) => item.question.code === question.code);
        if (index >= 0) {
          //update new answers
          const currentData = dataSaveQuestion[index];
          currentData.answers = [
            ...currentData.answers,
            ...dataImported
          ];
          dataSaveQuestion[index] = currentData;
          setDataSaveQuestion([...dataSaveQuestion]);
        }

      });
      window.COMMON.hideModal('#modal-import');
      // const params = {
      //   input: dataImported
      // };
      // window.COMMON.trimData(params);
      // const result = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_IMPORT_ANSWER_QUESTION, params);
      // if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_IMPORT)) {
      //   // getDataQuestion();
      //   window.COMMON.hideModal('#modal-import');
      // }
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionAndAnswerForm.importAnswerQuestion');
    }
  };


  // QUESTION & ANSWER




  const saveItemQuestion = () => {
    // getDataQuestion();
  };


  const addFormQuestion = () => {

    dataSaveQuestion.push({
      uuid: window.COMMON.genKey(),
      group: groupQA._id,
      question: {
        _id: null,
        question: [],
        introduction: [],
        type: 'TEXT',
        code: window.COMMON.generateCode('Q')
      },
      answers: []
    });
    setDataSaveQuestion([...dataSaveQuestion]);
    setTimeout(()=>{window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })},500)
    
  };


  //when question object of a element in datasave array change
  const handleFormQuestionItemChange = (questionDataChange) => {








    setDataSaveQuestion([...dataSaveQuestion]);

    setDataSaveQuestion((current) => {
      const index = current.findIndex((item) => item.question.code === questionDataChange.code);
      if (index < 0) return [...current];
      const currentData = current[index];
      const oldQuestionData = currentData.question;
      currentData.question = {
        ...oldQuestionData,
        ...questionDataChange
      };
      current[index] = currentData;
      return [...current];


    });

  };

  const getQuestionsFromBank = (arrayCloneQuestion) => {
    arrayCloneQuestion.forEach((question,index) => {
      dataSaveQuestion.push({
        uuid: window.COMMON.genKey(),
        group: groupQA._id,
        question: question.question,
        answers: question.answers,
        dataFromOrigin: question.dataFromOrigin
      });
    })
    setDataSaveQuestion([...dataSaveQuestion]);
  }

  return <Fragment>
    <div className="d-flex align-items-center justify-content-end mb-3 wow fadeInDown animated" data-wow-delay="0.5s">
      <div className="block mr-auto">
        <p className="mb-0 mr-auto font-weight-bold">{window.I18N('question_list')}</p>
      </div>
      {window.COMMON.checkRoleIsSystem() && <>
        <div className="d-inline-block">
          <RadioBox data={types} value={object.radio} className="m-0" handleChange={handleRadio}></RadioBox>
        </div>
        <ExportExcel label={window.I18N('btn_template')} isTemplate={true} dataKey={object.radio + '_TEMPLATE'}></ExportExcel>
        <ImportExcel label={window.I18N('btn_import')} id="file-import" dataKey={'IMPORT_' + object.radio} handleFile={handleFileImport}></ImportExcel>
      </>}

      <MDBBtn color="" type="button" onClick={openPreview}>
        <MDBIcon fa="true" icon="eye" className="fa-fw" /> {window.I18N('preview')}
      </MDBBtn>

    </div>
    <MDBRow >
      <MDBCol size="3">
        <div style={{position:"fixed", width:"20%"}}>
          <QuestionBank language={props.language.code} getQuestionsFromBank={getQuestionsFromBank}></QuestionBank>
        </div>
      </MDBCol>
      <MDBCol>
        {
          dataSaveQuestion.map((item, i) => <FormQuestionItem key={item.uuid} ref={(el) => formQuestionItemsRef.current[item.uuid] = el} index={i} id={item.uuid} item={item.question} btnStatus={object.btnStatus} answers={item.answers} group={item.group} dataFromOrigin={item.dataFromOrigin} handleSaveItem={saveItemQuestion} handleRemoveItem={removeItemQuestion} onQuestionChange={handleFormQuestionItemChange} />)
        }
      </MDBCol>
    </MDBRow>

    <div className="float-button wow fadeInDown animated" data-wow-delay="1s">
      <Tooltip title={window.I18N('add_question')}>
        <Fab color="primary" aria-label="add" size="medium" onClick={addFormQuestion}>
          <Add />
        </Fab>
      </Tooltip>
    </div>


    <Modal id="modal-import" className="modal-xl" title={window.I18N('import_data')} hideSave={object.btnStatus || !object.isCreated} saveEvent={importData}>
      <Table id="table-import" className="table" config={dataImport.config} data={dataImport.data} handleRowDelClick={deleteImportRow} isImported={true}></Table>
    </Modal>
    <Modal id="modal-preview" title={window.I18N('preview')} className="modal-xl" hideSave={true}>
      <MDBRow>
        <MDBCol>
          {
            previewData.map((item, i) => <ItemPreviewQuestion key={i} index={i} id={item.uuid} item={item.question} answers={item.answers} group={item.group} />)
          }
        </MDBCol>
      </MDBRow>
    </Modal>
  </Fragment>;
});
export default QuestionAndAnswerForm;
