import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

const RecommendEventItemLoading = () => {
    return (
        <div className={`mb-2 d-flex align-items-center justify-content-start`} style={{ gap: '8px' }}>
            <div
                style={{
                    width: '120px',
                    height: '60px',
                    flexShrink: 0
                }}
            >
                <Skeleton animation="wave" className="rounded-lg" variant="rect" width="100%" height="100%" />
            </div>
            <div className="w-100 d-flex flex-column align-items-start justify-content-center">
                <Skeleton animation="wave" className="rounded-lg" variant="text" width="100%" />
                <Skeleton animation="wave" className="rounded-lg" variant="text" width="60%" />
            </div>
        </div>
    )
}

export default RecommendEventItemLoading
