import React, { useLayoutEffect, useState, useRef } from 'react';
import Select from 'react-select';
import MetadataGRAPHQL from '../../../graphql/Metadata';
import AccountGRAPHQL from '../../../graphql/Account';
import InsightChartProfile from '../../components/InsightChart/InsightChartProfile';
import InsightChartEngagement from '../../components/InsightChart/InsightChartEngagement';
import InsightChartAssessment from '../../components/InsightChart/InsightChartAssessment';
import InsightChartTaskSchedule from '../../components/InsightChart/InsightChartTaskSchedule';
import { Chip, Divider } from '@material-ui/core';
import { MDBBtn, MDBCol, MDBIcon, MDBRow } from 'mdbreact';
import InsightChartComplaint from '../../components/InsightChart/InsightChartComplaint';
import BookmarksOutlinedIcon from '@material-ui/icons/BookmarksOutlined';
import InsightChartELearning from '../../components/InsightChart/InsightChartELearning';
import InsightChartReward from '../../components/InsightChart/InsightChartReward';
import UsersBehaviorPage from '../users-behavior/UsersBehaviorPage';

const InsightChartPage = () => {
    const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
    // const [tab, setTab] = useState(0)
    const [options, setOptions] = useState({
        companies: [],
        nation: [],
        city: [],
        state: [],
        companyIndustry: [],
    })
    const [select, setSelect] = useState({ 
        company: { value: null, code: null, label: null }, 
        city: null, 
        state: null, 
        nation: null, 
        companyIndustry: null 
    })
    const [applyLocation, setApplyLocation] = useState({ companyIndustry: null, nation: null, state: null, city: null })
    const [applyCompanies, setApplyCompanies] = useState({
        companyIds: [], 
        influencerIds: [], 
        unionIds: [], 
        companyInfo: []
    })
    const companiesAccount = useRef([])

    const [listCategory, setListCateGory] = useState([
        {
            title: 'Assessment',
            component: InsightChartAssessment,
            active: true
        },
        {
            title: 'Complaint',
            component: InsightChartComplaint,
            active: false
        },
        {
            title: 'Engagement',
            component: InsightChartEngagement,
            active: false
        },
        {
            title: 'Profile',
            component: InsightChartProfile,
            active: false
        },
        {
            title: 'Task Schedule',
            component: InsightChartTaskSchedule,
            active: false
        },
        {
            title: 'E-Learning',
            component: InsightChartELearning,
            active: false
        },
        {
            title: 'Reward',
            component: InsightChartReward,
            active: false
        },
        {
            title: 'Users Behavior',
            component: UsersBehaviorPage,
            active: false
        }
    ])

    const [ filters, setFilters ] = useState({})

    const changeListCategory = (index) => {
        const cloneListCateGory = [...listCategory]
        const updateListCategory = cloneListCateGory.map((item, pos) => ({...item, active: pos === index}))
        setListCateGory(updateListCategory)
    }

    const handleSelect = (event, id, attr) => {
        select[attr] = event;
        //setSelect({ ...select })
        /* if (attr === 'nation' || attr === 'state' || attr === 'city' || attr === 'companyIndustry') {
            filterCompanyFollowSelect();
        } */
        if (attr === 'company') {
            setApplyLocation({ companyIndustry: null, nation: null, state: null, city: null })
            setFilters({})
        };
        if (attr === 'nation' || attr === 'state') {
            getDataLocationOptions(attr, event.code);
        } else {
            setSelect({ ...select });
        }
    };
    
    const onSearchByLocation = () => {
        applyCompanies.companyIds = []
        applyCompanies.unionIds = []
        applyCompanies.influencerIds= []
        applyLocation.companyIndustry = select.companyIndustry
        applyLocation.nation = select.nation
        applyLocation.state = select.state
        applyLocation.city = select.city
        companiesAccount.current.forEach(companyInfo => {
            let checkLocation = true
            if (applyLocation.companyIndustry?.value && companyInfo.companyIndustry?._id !== applyLocation.companyIndustry.value) {
                checkLocation = false
            }
            if (applyLocation.nation?.value && companyInfo.nation?._id !== applyLocation.nation.value) {
                checkLocation = false
            }
            if (applyLocation.state?.value && companyInfo.state?._id !== applyLocation.state.value) {
                checkLocation = false
            }
            if (applyLocation.city?.value && companyInfo.city?._id !== applyLocation.city.value) {
                checkLocation = false
            }
            if (checkLocation) {
                if (companyInfo.accountType === window.CONSTANT.COMPANY) {
                    applyCompanies.companyIds.push(companyInfo.company._id) 
                } else if (companyInfo.accountType === window.CONSTANT.INFLUENCER) {
                    applyCompanies.influencerIds.push(companyInfo.influencer._id)
                } else if (companyInfo.accountType === window.CONSTANT.UNION) {
                    applyCompanies.unionIds.push(companyInfo.union._id)
                }
            }
            applyCompanies.companyInfo.push(companyInfo)
        })
        setFilters((prev) => ({
            ...prev, 
            city: select.city?.label, 
            nation: select.nation?.label, 
            state: select.state?.label, 
            companyIndustry: select.companyIndustry?.label
        }))
        setApplyCompanies({...applyCompanies})
        options.city = []
        options.state = []
        setApplyLocation({...applyLocation})
        //reset cac select
        setSelect({
            company: { value: null, code: null, label: null },
            city: null, companyIndustry: null, nation: null, state: null
        })
        setOptions(options)
    }

    // const handleChangeTab = (paramTab) => {
    //     if (tab !== paramTab) {
    //         setTab(paramTab)
    //         setSelect({ company: select.company })
    //     }
    // }

    const getDataMetadata = async () => {
        try {
            const params = {
                type: ['COMPANY_INDUSTRY']
            };
            const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_SHORT_METADATA, params, false);
            if (result && result.data) {
                const dataIndustry = window.COMMON.getArrsInArrs('COMPANY_INDUSTRY', result.data.getMetadatas, 'type');
                options.companyIndustry = window.COMMON.createDataSelect(dataIndustry, '_id', 'value');
                options.companyIndustry.unshift({ value: null, label: window.I18N('all_industries') });
                setOptions(options);
            }
        } catch (error) {
            window.COMMON.showErrorLogs('InsightChartPage.getDataMetadata');
        }
    }
    const getDataLocationOptions = async (attr, parent) => {
        if (attr === '-1') {
            options.nation = await window.COMMON.getDataLocation(parent);
            options.nation.unshift({ value: null, label: window.I18N('all_countries') });
            options.state = [];
            options.city = [];
            select.nation = null;
            select.state = null;
            select.city = null;
        } else if (attr === 'nation' && parent) {
            options.state = await window.COMMON.getDataLocation(parent);
            options.state.unshift({ value: null, label: window.I18N('all_states') });
            options.city = [];
            select.state = null;
            select.city = null;
        } else if (attr === 'state' && parent) {
            options.city = await window.COMMON.getDataLocation(parent);
            options.city.unshift({ value: null, label: window.I18N('all_cities') });
            select.city = null;
        }
        setOptions(options);
        setSelect({ ...select });
    };
    const getDataCompany = async () => {
        try {
            window.showLoading();
            let result;
            if (!window.COMMON.checkRoleIsSystemOrInfluencer()) {
                select.company.code = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
                select.company.value = COMPANY_ID
            } else {
                result = await window.COMMON.query(AccountGRAPHQL.QUERY_COMPANY_BY_GOVERMENT, { input: {} }, true);
                if (result?.data?.getCompanyAccountsByGoverment) {
                    const formatData = result?.data?.getCompanyAccountsByGoverment.map(account => ({
                        _id: account.company?._id || account.influencer?._id || account.union?._id,
                        name: account.name,
                        accountType: account.accountType
                    }))
                    companiesAccount.current = result?.data?.getCompanyAccountsByGoverment
                    const group_companies = window.COMMON.createDataSelectWithGroup(formatData, '_id', 'name', 'accountType', 'accountType');
                    options.companies = group_companies
                    const initCompany = formatData.find(company => company._id === COMPANY_ID)
                    if (initCompany) {
                        select.company = { value: initCompany._id, label: initCompany.name, code: initCompany.accountType }
                    } else {
                        select.company = group_companies[0].options[0]
                    }
                    setOptions({ ...options })
                }
            }
            setSelect({ ...select })
        } catch (error) {
            console.log(error)
        }
    }

    useLayoutEffect(() => {
        setTimeout(() => {
            getDataCompany();
            getDataLocationOptions('-1', '-1');
            getDataMetadata()
        }, 100);
        // eslint-disable-next-line
    }, []);
   
    return (
        <div className='ml-3 wow fadeIn' data-wow-delay='0.2s'>
            <MDBRow>
            {/* Select company - chart */}
                <MDBCol className='h-full bg-white rounded-lg' md='3'>
                    <div className='d-flex align-items-start justify-content-start flex-column mt-3'>
                        {window.COMMON.checkRoleIsSystemOrInfluencer()&&<>
                            <h6 className="font-weight-bold" style={{ fontSize: "0.8rem" }}>{window.I18N('company')}</h6>    
                            <div className="input-group input-group-transparent">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <MDBIcon fa="true" icon="building" className="fa-fw" />
                                    </span>
                                </div>
                                <Select 
                                    id="select-company" 
                                    value={select.company.value ? select.company : null} 
                                    options={options.companies} 
                                    onChange={(event) => handleSelect(event, 'select-company', 'company')} 
                                    className="md-form m-0" 
                                    placeholder={window.I18N('choose_option')} 
                                    isSearchable      
                                />
                            </div>
                            {Object.keys(filters).length > 0 && 
                                <>
                                    <div className='my-3 flex-center' style={{ width: '100%'}}>
                                        <Divider variant="middle" light={true} style={{ width: '100%'}}/>
                                    </div>
                                    <div>
                                        <h6 className="font-weight-bold m-0" style={{ fontSize: "0.8rem" }}>
                                            {`${window.I18N('filter')} ${window.I18N('apply').toLowerCase()}`}
                                        </h6>    
                                        <div 
                                            className='mt-2 w-full d-flex align-items-center justify-content-start rounded-lg' 
                                            style={{
                                                maxWidth: '100%',
                                                gap: '8px',
                                                flexWrap: 'wrap'
                                            }}
                                        >
                                            {
                                                Object.values(filters).map((filter, index) =>{
                                                    if(filter){
                                                        return <Chip 
                                                            key={index} 
                                                            variant="outlined"
                                                            color='primary' 
                                                            label={filter} 
                                                            size="small" 
                                                            icon={<BookmarksOutlinedIcon />}
                                                            clickable
                                                        />
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                            <div className='mt-3 flex-center' style={{ width: '100%'}}>
                                <Divider variant="middle" light={true} style={{ width: '100%'}}/>
                            </div>
                        </>}
                        {/* Chart category */}
                        <h6 className="font-weight-bold py-2 mt-1" style={{ fontSize: "0.8rem" }}>{window.I18N('category_name')}</h6>    
                        <div className='mt-2 d-flex align-items-start justify-content-start flex-column' style={{ gap: '8px', width: '100%' }}>
                            {listCategory.map((item, i) => (
                                <div 
                                    key={i} 
                                    className={'w-full item d-flex align-items-center rounded-lg p-2 list-category' + (item.active ? ' category-active' : '')} 
                                    style={{ width: '100%', cursor: 'pointer' }} 
                                    onClick={() => changeListCategory(i)}
                                >
                                    <MDBIcon fa="true" icon="chart-line" className="fa-fw mr-2" style={{ color: '#fc9905' }}/>
                                    <span className="font-weight-bold" style={{ fontSize: "0.8rem" }}>{item.title}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </MDBCol>
                
                <MDBCol className='px-2' md='9'>
                    {/* Filter */}
                    {window.COMMON.checkRoleIsSystemOrInfluencer()&&<div className='bg-white rounded-lg p-3 mb-2'>
                        <div className='d-flex align-items-center justify-content-start' style={{ gap: '12px' }}>
                
                            <div className='w-50'>
                                <h6 className="font-weight-bold" style={{ fontSize: "0.8rem" }}>{window.I18N('country')}</h6>     
                                <div className="input-group input-group-transparent">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                        <MDBIcon fa="true" icon="filter" className="fa-fw" />
                                        </span>
                                    </div>
                                    <Select 
                                        className="md-form m-0" 
                                        id="select-nation" 
                                        value={select.nation} 
                                        options={options.nation} 
                                        placeholder={window.I18N('choose_option')} 
                                        onChange={(event) => handleSelect(event, 'select-nation', 'nation')} 
                                        isSearchable 
                                    />
                                </div>
                            </div>
    
                            <div className='w-50'>
                                <h6 className="font-weight-bold" style={{ fontSize: "0.8rem" }}>{window.I18N('state')}</h6>     
                                <div className="input-group input-group-transparent">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                        <MDBIcon fa="true" icon="filter" className="fa-fw" />
                                        </span>
                                    </div>
                                    <Select 
                                        className="md-form m-0" 
                                        id="select-state" 
                                        value={select.state} 
                                        options={options.state} 
                                        placeholder={window.I18N('choose_option')}
                                        onChange={(event) => handleSelect(event, 'select-state', 'state')} 
                                        isSearchable 
                                    />
                                </div>
                            </div>

                            <div className='w-50'>
                                <h6 className="font-weight-bold" style={{ fontSize: "0.8rem" }}>{window.I18N('city')}</h6>     
                                <div className="input-group input-group-transparent">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                        <MDBIcon fa="true" icon="filter" className="fa-fw" />
                                        </span>
                                    </div> 
                                    <Select 
                                        className="md-form m-0" 
                                        id="select-city" 
                                        value={select.city} 
                                        options={options.city} 
                                        placeholder={window.I18N('choose_option')} 
                                        onChange={(event) => handleSelect(event, 'select-city', 'city')} 
                                        isSearchable 
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='mt-2 w-full d-flex align-items-end justify-content-between'>
                            <div style={{ width: '32.3%' }}>
                                <h6 className="font-weight-bold" style={{ fontSize: "0.8rem" }}>{window.I18N('industry')}</h6>     
                                <div className="input-group input-group-transparent">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                        <MDBIcon fa="true" icon="filter" className="fa-fw" />
                                        </span>
                                    </div>
                                    <Select 
                                        id="select-company-industry" 
                                        value={select.companyIndustry} 
                                        options={options.companyIndustry}
                                        className="md-form m-0" 
                                        placeholder={window.I18N('choose_option')} 
                                        onChange={(event) => handleSelect(event, 'select-company-industry', 'companyIndustry')} 
                                        isSearchable 
                                    />
                                </div>
                            </div>
                            <MDBBtn 
                                size='sm' 
                                className="h-50 m-0 mt-2" 
                                color="secondary" 
                                onClick={onSearchByLocation}
                                disabled={
                                    [select.city, select.nation, select.state, select.companyIndustry].every(item => item === null)
                                }
                            >
                                <MDBIcon fa="true" icon="search" className="fa-fw" />
                                {window.I18N('search')}
                            </MDBBtn>
                        </div>
                    </div>}
                    {/* Render chart */}
                    {listCategory.filter(item => item.active).map((item, index) =>{
                        const ChartInsight = item.component
                        return (
                            <div 
                                key={`${item.title}-${index}`} 
                                className='w-full rounded-lg bg-white' 
                                style={{ 
                                    padding: '1% 2% 15% 2%'
                                }}
                            >
                                <ChartInsight 
                                    companyType={select.company.code} 
                                    companyId={select.company.value} 
                                    companyInfo={applyCompanies.companyInfo} 
                                    companies={applyCompanies}
                                />
                            </div>
                        )
                    })}
                </MDBCol>
            </MDBRow>
        </div>

    )
}
export default InsightChartPage