import React, { useLayoutEffect } from 'react';
import { MDBRow, MDBCol, MDBBtn, MDBInput, MDBIcon } from 'mdbreact';

const ItemPreviewQuestion = React.memo(function ItemPreviewQuestion(props) {
  const bg = [ 'bg-danger', 'bg-warning' , 'bg-info', '', 'bg-success'];
  const [language, setLanguage] = React.useState();
  const [dataItem, setDataItem] = React.useState({});
  const [dataAnswer, setDataAnswer] = React.useState([]);

  useLayoutEffect(() => {
    const dataItem = {};
    setLanguage(props.language);
    if (props.item) {
      dataItem._id = window.COMMON.getValueFromAttr(props.item, '_id', null);
      dataItem.question = window.COMMON.getDataWithLanguage(props.item, 'question');
      dataItem.code = window.COMMON.getValueFromAttr(props.item, 'code');
      dataItem.introduction = window.COMMON.getDataWithLanguageWithDefaultValue(props.item, 'introduction', null);
      dataItem.type = window.COMMON.getValueFromAttr(props.item, 'type');
    }
    setDataAnswer(JSON.parse(JSON.stringify(props.answers)));
    setDataItem({ ...dataItem });
  }, [props.isPreview, props.language, props.item, props.answers, props.data]);

  const generateFormAnswer = (item, i) => {
    if (dataItem.type === 'CHECKBOX' || dataItem.type === 'CHECKBOX_GRID') {
      return <MDBInput key={'preview-' + item.code} id={'preview-' + item.code} type="checkbox" containerClass="d-inline-block pl-0 pt-2 mr-4" label={window.COMMON.getValueWithLanguage(item, 'content', props.language)} filled/>;
    }
    if (dataItem.type === 'LEVEL') {
      return <div key={'preview-' + item.code} className={'progress-bar progress-bar-striped progress-bar-animated w-100 ' + bg[i % 5]} role="progressbar">
        {window.COMMON.getValueWithLanguage(item, 'content', props.language)}
      </div>;
    }
    return <MDBInput gap key={'preview-' + item.code} id={'preview-' + item.code} type="radio" containerClass="d-inline-block pl-0 pt-2 mr-4" label={window.COMMON.getValueWithLanguage(item, 'content', props.language)}/>;
  };

  const generateFormAnswerGrid = () => {
    const parent = dataAnswer.filter(function(item) {
      return window.COMMON.getValueFromAttr(item, 'parentCode', null) === null;
    });
    if (parent.length > 0) {
      return parent.map((item, i) => <div key={'preview-' + item.code}>
        <span className="mr-4 font-weight-bold">{window.COMMON.getValueWithLanguage(item, 'content', props.language)}</span>
        {
          window.COMMON.getArrsInArrs(item.code, dataAnswer, 'parentCode').map((child, j) => generateFormAnswer(child, j))
        }
      </div>);
    }
    return <div></div>;
  };

  return <div className="item-preview-question mb-3 wow fadeIn animated" data-wow-delay="0.5s">
    <MDBRow>
      <MDBCol sm="12">
        <div className="d-flex align-items-center">
          <span className="badge badge-info badge-number mr-2 p-2">{props.index + 1}</span>
          {window.COMMON.getValueWithLanguage(dataItem, 'question', language)}
          <div className="nav-item dropdown ml-auto">    
            <MDBBtn outline className="btn btn-sm btn-transparent m-0" color="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-boundary="viewport">
              <MDBIcon fa="true" icon="question-circle" className="fa-fw fa-lg" />
            </MDBBtn>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby={'dropdown-' + dataItem._id} data-boundary="viewport">
              <span>{window.COMMON.getValueWithLanguage(dataItem, 'introduction', language)}</span>
            </div>
          </div>
        </div>
      </MDBCol>
      <MDBCol sm="12 mt-3">
        {
          (dataItem.type === 'CHECKBOX_GRID' || dataItem.type === 'RADIO_GRID') ? generateFormAnswerGrid()
            : dataItem.type === 'TEXT' ? <MDBInput outline type="textarea" containerClass="m-0"/> 
              : dataItem.type === 'LEVEL' ? <div className="progress mt-3" style={{ height: '40px' }}>
                { dataAnswer.map((item, i) => generateFormAnswer(item, i)) }
              </div> 
                : dataAnswer.map((item, i) => generateFormAnswer(item, i))
        }
      </MDBCol>
    </MDBRow>
  </div>;
});

export default ItemPreviewQuestion;