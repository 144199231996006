import React, { useState, useCallback, useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { MDBRow, MDBCol, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';

import Table from '../../components/Table';
import Tab from '../../components/Tab';
import Modal from '../../components/Modal';
import ImportExcel from '../../components/ImportExcel';
import ExportExcel from '../../components/ExportExcel';
import RadioBox from '../../components/RadioBox';

import SurveyGRAPHQL from '../../../graphql/Survey';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';

function SurveyPage(props) {
  const { trackEvent } = useTracking();

  const dataTabs = [
    { icon: 'fa-th', name: window.I18N('menu_survey') },
    { icon: 'fa-th', name: window.I18N('survey') }
  ];
  const types = [
    { value: 'QUESTION_SURVEY', label: window.I18N('question') },
    { value: 'ANSWER_SURVEY', label: window.I18N('answer') },
    { value: 'RECOMMEND_SURVEY', label: window.I18N('recommend') }
  ];
  const configMenu = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('code'), '15%', '', '', 'code', 'TEXT'),
    window.COMMON.createCol(window.I18N('content'), '35%', '', '', 'contentLanguage', 'TEXT'),
    window.COMMON.createCol(window.I18N('status'), '10%', '', '', 'status', 'STATUS'),
    window.COMMON.createCol(window.I18N('created_by'), '15%', '', '', 'createdBy.name', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '10%', '', '', '3', 'BUTTON')
  ];
  const configQuestion = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('code'), '10%', '', '', 'code', 'TEXT'),
    window.COMMON.createCol(window.I18N('question'), '40%', '', '', 'contentLanguage', 'TEXT'),
    window.COMMON.createCol(window.I18N('answer'), '30%', '', '', 'answers', 'TEXT_PRE'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '5%', '', '', '-1', 'BUTTON')
  ];
  const configRecommend = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('code'), '15%', '', '', 'code', 'TEXT'),
    window.COMMON.createCol(window.I18N('content'), '25%', '', '', 'contentLanguage', 'TEXT'),
    window.COMMON.createCol(window.I18N('min'), '10%', '', 'right', 'min', 'TEXT'),
    window.COMMON.createCol(window.I18N('max'), '10%', '', 'right', 'max', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_by'), '20%', '', '', 'createdBy.name', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '5%', '', '', '-1', 'BUTTON')
  ];
  const language = useRef();
  const [filter, setFilter] = useState({ menu: null, radio: 'QUESTION_SURVEY' });
  const [object, setObject] = useState({ index: -1, indexTab: 0, status: false });
  const [dataMenu, setDataMenu] = useState([]);
  const [dataQuestion, setDataQuestion] = useState([]);
  const [dataRecommend, setDataRecommend] = useState([]);
  const [dataSave, setDataSave] = useState({ isMulti:false });
  const [dataImport, setDataImport] = useState({});
  const [options, setOptions] = useState({
    menu: []
  });

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {
      if (object.indexTab === 0) {
        getDataMenu();
      } else {
        getDataQuestion();
        getDataRecommend();
      }
    }, 100);
    // eslint-disable-next-line
  }, [props.language]);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const changeTab = (index) => {
    if (index === 0) {
      setTimeout(() => getDataMenu(), 500);
    } else {
      setTimeout(() => {
        filter.radio = 'QUESTION_SURVEY';
        setFilter({ ...filter });
        getDataQuestion();
        getDataRecommend();
      }, 500);
    }
    object.indexTab = index;
    setObject(object);
  };

  const handleChange = (event) => {
    if (event.target.className.indexOf('input-number') > -1) {
      dataSave[event.target.name] = parseInt(event.target.value);
    } else if (event.target.type === 'checkbox') {
      dataSave[event.target.name] = event.target.checked;
    } else {
      dataSave[event.target.name] = event.target.value;
    }
    setDataSave({ ...dataSave });
  };

  const handleChangeWithLanguage = (event) => {
    window.COMMON.setValueWithLanguage(dataSave, event.target.name, language.current, event.target.value);
    setDataSave({ ...dataSave });
  };

  const handleRadio = useCallback((value) => {
    filter.radio = value;
    setFilter({ ...filter });
  }, [filter]);

  const handleFilterSelect = (event) => {
    filter.menu = event;
    const obj = window.COMMON.getObjectInArrs(event.value, dataMenu, '_id');
    object.status = false;
    if (obj !== null) {
      object.status = obj.status;
    }
    setObject(object);
    setFilter({ ...filter });
    getDataRecommend();
    getDataQuestion();
  };

  const handleFileImport = (dataImport) => {
    checkImportData(dataImport);
    window.COMMON.showModal('#modal-import');
  };

  // MENU SURVEY
  const getDataMenu = async () => {
    try {
      window.resetDataTable('table-menu');
      const result = await window.COMMON.query(SurveyGRAPHQL.QUERY_MENU_SURVEY, null);
      if (result && result.data) {
        const data = window.COMMON.formatDate(result.data.getMenuSurveys, 'createdDate');
        data.forEach(item => {
          item.contentLanguage = window.COMMON.getValueWithLanguage(item, 'content', language.current);
        });
        object.status = false;
        if (data.length > 0) {
          object.status = data[0].status;
          options.menu = window.COMMON.createDataSelectWithLanguage(data, '_id', 'content', language.current, true);
          filter.menu = options.menu && options.menu.length > 0 ? options.menu[0] : null;
        }
        setFilter(filter);
        setOptions(options);
        setObject(object);
        setDataMenu(data);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SurveyPage.getDataMenu');
    }
    window.initDataTable('table-menu');
  };

  const openMenuDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-menu');
    object.index = index;
    const dataSave = {};
    if (index === -1) {
      dataSave.content = [];
      dataSave.description = [];
      dataSave.code = window.COMMON.generateCode('MS');
      dataSave.isMulti = false;
    } else {
      const obj = dataMenu[index];
      dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
      dataSave.content = window.COMMON.getDataWithLanguage(obj, 'content');
      dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
      dataSave.description = window.COMMON.getDataWithLanguage(obj, 'description');
      dataSave.isMulti = window.COMMON.getValueFromAttr(obj, 'isMulti');
    }
    setObject(object);
    setDataSave({ ...dataSave });
  }, [object, dataMenu]);

  // eslint-disable-next-line
  const openDeleteMenuDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteMenu;
  });

  const saveMenu = async (event) => {
    try {
      if (!window.COMMON.checkFormValidation('form-menu', event)) {
        return;
      }
      const params = {
        input: dataSave
      };
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(SurveyGRAPHQL.MUTATION_ADD_MENU_SURVEY, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataMenu();
		  createTrackingEvent(eventName.ADD_MENU_SURVEY);
        }
      } else {
        const result = await window.COMMON.mutation(SurveyGRAPHQL.MUTATION_EDIT_MENU_SURVEY, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataMenu();
		  createTrackingEvent(eventName.EDIT_MENU_SURVEY);
        }
      }
      window.COMMON.hideModal('#modal-menu');
    } catch (error) {
      window.COMMON.showErrorLogs('SurveyPage.saveMenu');
    }
  };

  const deleteMenu = async () => {
    try {
      const params = {
        id: dataMenu[object.index]._id
      };
      const result = await window.COMMON.mutation(SurveyGRAPHQL.MUTATION_DEL_MENU_SURVEY, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataMenu();
        createTrackingEvent(eventName.DEL_MENU_SURVEY);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SurveyPage.deleteMenu');
    }
  };

  // QUESTION - RECOMMEND SURVEY
  const getDataQuestion = async () => {
    try {
      window.resetDataTable('table-question');
      if (window.COMMON.checkValue(filter.menu)) {
        const params = {
          parentId: filter.menu.value
        };
        const result = await window.COMMON.query(SurveyGRAPHQL.QUERY_QUESTION_SURVEY, params);
        const resultAnswer = await window.COMMON.query(SurveyGRAPHQL.QUERY_ANSWER_SURVEY, params);
        const dataAnswer = resultAnswer.data.getAnswerSurveys;
        if (result && result.data) {
          const data = window.COMMON.formatDate(result.data.getQuestionSurveys, 'createdDate');
          data.forEach(item => {
            item.contentLanguage = window.COMMON.getValueWithLanguage(item, 'content', language.current);
          });
          data.forEach(item => {
            const arrs = window.COMMON.getArrsInArrs(item._id, dataAnswer, 'question._id');
            let text = '';
            arrs.forEach(child => {
              const str = window.COMMON.getValueWithLanguage(child, 'content', language.current) + ' (' + child.point + ' points)';
              if (str !== '') {
                text += '- ' + str + '\n';
              }
            });
            item.answers = text;
          });
          setDataQuestion(data);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SurveyPage.getDataQuestion');
    }
    window.initDataTable('table-question');
  };

  const getDataRecommend = async () => {
    try {
      window.resetDataTable('table-recommend');
      if (window.COMMON.checkValue(filter.menu)) {
        const params = {
          parentId: filter.menu.value
        };
        const result = await window.COMMON.query(SurveyGRAPHQL.QUERY_RECOMMEND_SURVEY, params);
        if (result && result.data) {
          const data = window.COMMON.formatDate(result.data.getRecommendSurveys, 'createdDate');
          data.forEach(item => {
            item.contentLanguage = window.COMMON.getValueWithLanguage(item, 'content', language.current);
          });
          setDataRecommend(data);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SurveyPage.getDataRecommend');
    }
    window.initDataTable('table-recommend');
  };

  // eslint-disable-next-line
  const openDeleteQuestionDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteQuestion;
  });

  // eslint-disable-next-line
  const openDeleteRecommendDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteRecommend;
  });

  const deleteQuestion = async () => {
    try {
      const params = {
        id: dataQuestion[object.index]._id
      };
      const result = await window.COMMON.mutation(SurveyGRAPHQL.MUTATION_DEL_QUESTION_SURVEY, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataQuestion();
        createTrackingEvent(eventName.DEL_QUESTION_SURVEY);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SurveyPage.deleteQuestion');
    }
  };

  const deleteRecommend = async () => {
    try {
      const params = {
        id: dataRecommend[object.index]._id
      };
      const result = await window.COMMON.mutation(SurveyGRAPHQL.MUTATION_DEL_RECOMMEND_SURVEY, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataRecommend();
        createTrackingEvent(eventName.DEL_RECOMMEND_SURVEY);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SurveyPage.deleteRecommend');
    }
  };

  const updateStatus = async () => {
    try {
      const params = {
        id: filter.menu.value,
        status: !object.status
      };
      const result = await window.COMMON.mutation(SurveyGRAPHQL.MUTATION_PUSH_MENU_SURVEY, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
        dataMenu[object.index].status = !object.status;
        object.status = !object.status;
        setObject({ ...object });
        createTrackingEvent(eventName.PUSH_MENU_SURVEY);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SurveyPage.updateStatus');
    }
  };

  // IMPORT
  const deleteImportRow = useCallback((index) => {
    if (dataImport.data.length > 0) {
      dataImport.data.splice(index, 1);
    }
    setDataImport({ ...dataImport });
  }, [dataImport]);

  const checkImportData = async (dataImport) => {
    try {
      if (dataImport.data.length === 0) {
        return;
      }
      const data = [];
      dataImport.data.forEach(item => {
        if (!data.includes(item.code)) {
          if (!data.includes(item.code)) {
            data.push(item.code);
          }
        }
      });
      const params = {
        input: data
      };
      window.COMMON.trimData(params);
      let result = null;
      let dataCheck = [];
      if (filter.radio === 'QUESTION_SURVEY') {
        result = await window.COMMON.mutation(SurveyGRAPHQL.MUTATION_CHECK_IMPORT_QUESTION_SURVEY, params);
        createTrackingEvent(eventName.CHECK_IMPORT_QUESTION_SURVEY);
      } else if (filter.radio === 'ANSWER_SURVEY') {
        result = await window.COMMON.mutation(SurveyGRAPHQL.MUTATION_CHECK_IMPORT_ANSWER_SURVEY, params);
        createTrackingEvent(eventName.CHECK_IMPORT_ANSWER_SURVEY);
      } else {
        result = await window.COMMON.mutation(SurveyGRAPHQL.MUTATION_CHECK_IMPORT_RECOMMEND_SURVEY, params);
        createTrackingEvent(eventName.CHECK_IMPORT_RECOMMEND_SURVEY);
      }
      if (filter.radio === 'QUESTION_SURVEY' && result.data && result.data.checkImportQuestionSurvey) {
        dataCheck = result.data.checkImportQuestionSurvey;
      } else if (filter.radio === 'ANSWER_SURVEY' && result.data && result.data.checkImportAnswerSurvey) {
        dataCheck = result.data.checkImportAnswerSurvey;
      } else {
        if (result.data && result.data.checkImportRecommendSurvey) {
          dataCheck = result.data.checkImportRecommendSurvey;
        }
      }
      for (let i = 0; i < dataCheck.length; i++) {
        const arrs = dataCheck[i].split('-.-');
        if (Number(arrs[1]) === 1) {
          const array = window.COMMON.getArrsInArrs(arrs[0], dataImport.data, 'code');
          if (array && array.length > 0) {
            array.forEach(element => {
              element.isExisted = true;
            });
          }
        }
      }
      setDataImport(dataImport);
    } catch (error) {
      window.COMMON.showErrorLogs('SurveyPage.checkImportData');
    }
  };

  const importData = () => {
    if (filter.menu === null) {
      window.COMMON.showMessage('warning', 'MSG_CODE_024', window.MSG.MSG_CODE_024);
      return;
    }
    if (dataImport.data.length === 0) {
      window.COMMON.showMessage('warning', 'MSG_CODE_021', window.MSG.MSG_CODE_021);
      return;
    }
    if (filter.radio === 'QUESTION_SURVEY') {
      importQuestion();
    } else if (filter.radio === 'ANSWER_SURVEY') {
      importAnswer();
    } else {
      importRecommend();
    }
  };

  const importQuestion = async () => {
    try {
      const dataImported = [];
      dataImport.data.forEach(item => {
        const check = window.COMMON.getObjectInArrs(item.code, dataImported, 'code');
        if (check === null) {
          const arrs = window.COMMON.getArrsInArrs(item.code, dataImport.data, 'code');
          const obj = {};
          obj.code = item.code;
          obj.content = [];
          obj.menuSurvey = filter.menu.value;
          arrs.forEach(child => {
            obj.content.push({ code: child.languageCode, text: child.content });
          });
          dataImported.push(obj);
        }
      });
      const params = {
        input: dataImported
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(SurveyGRAPHQL.MUTATION_IMPORT_QUESTION_SURVEY, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_IMPORT)) {
        window.COMMON.hideModal('#modal-import');
        getDataQuestion();
        createTrackingEvent(eventName.IMPORT_QUESTION_SURVEY);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SurveyPage.importQuestion');
    }
  };

  const importAnswer = async () => {
    try {
      const dataImported = [];
      dataImport.data.forEach(item => {
        const check = window.COMMON.getObjectInArrs(item.code, dataImported, 'code');
        const question = window.COMMON.getObjectInArrs(item.questionCode, dataQuestion, 'code');
        if (check === null && question !== null) {
          const arrs = window.COMMON.getArrsInArrs(item.code, dataImport.data, 'code');
          const obj = {};
          obj.code = item.code;
          obj.point = Number(item.point);
          obj.content = [];
          obj.question = question._id;
          obj.menuSurvey = filter.menu.value;
          arrs.forEach(child => {
            obj.content.push({ code: child.languageCode, text: child.content });
          });
          dataImported.push(obj);
        }
      });
      const params = {
        input: dataImported
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(SurveyGRAPHQL.MUTATION_IMPORT_ANSWER_SURVEY, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_IMPORT)) {
        window.COMMON.hideModal('#modal-import');
        getDataQuestion();
        createTrackingEvent(eventName.IMPORT_ANSWER_SURVEY);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SurveyPage.importAnswer');
    }
  };

  const importRecommend = async () => {
    try {
      const dataImported = [];
      dataImport.data.forEach(item => {
        const check = window.COMMON.getObjectInArrs(item.code, dataImported, 'code');
        if (check === null) {
          const arrs = window.COMMON.getArrsInArrs(item.code, dataImport.data, 'code');
          const obj = {};
          obj.code = item.code;
          obj.min = Number(item.min);
          obj.max = Number(item.max);
          obj.content = [];
          obj.description = [];
          obj.menuSurvey = filter.menu.value;
          arrs.forEach(child => {
            obj.content.push({ code: child.languageCode, text: child.content });
            obj.description.push({ code: child.languageCode, text: child.description });
          });
          dataImported.push(obj);
        }
      });
      const params = {
        input: dataImported
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(SurveyGRAPHQL.MUTATION_IMPORT_RECOMMEND_SURVEY, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_IMPORT)) {
        window.COMMON.hideModal('#modal-import');
        getDataRecommend();
        createTrackingEvent(eventName.IMPORT_RECOMMEND_SURVEY);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('SurveyPage.importRecommend');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Tab data={dataTabs} id="container-data" changeTab={changeTab}>
        <div id="tab-1" className="tab-pane fade active show">
          <div className="container-btn mb-2 wow fadeInDown animated" data-wow-delay="0.5s">
            <MDBBtn color="primary" onClick={() => openMenuDialog(-1)}>
              <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
            </MDBBtn>
          </div>
          <div className="container-table wow fadeIn animated" data-wow-delay="1s">
            <Table id="table-menu" className="table" config={configMenu} data={dataMenu} handleRowEditClick={openMenuDialog}  handleRowDelClick={openDeleteMenuDialog}></Table>
          </div>
        </div>
        <div id="tab-2" className="tab-pane fade">
          <div className="container-btn mb-2 wow fadeInDown animated" data-wow-delay="0.5s">
            <div className="d-flex align-items-center">
              <div className="bd-highlight" style={{ width: '450px' }}>
                <div className="input-group input-group-transparent">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="poll" className="fa-fw" />
                    </span>
                  </div>
                  <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.menu} options={options.menu} onChange={handleFilterSelect} isSearchable/>
                </div>
              </div>
              <div className="bd-highlight ml-auto mr-2">
                <RadioBox data={types} value={filter.radio} className="m-0" handleChange={handleRadio}></RadioBox>
              </div>
              <div className="bd-highlight">
                <ExportExcel label={window.I18N('btn_template')} isTemplate={true} dataKey={filter.radio + '_TEMPLATE'}></ExportExcel>
                <ImportExcel label={window.I18N('btn_import')} id="file-import" dataKey={'IMPORT_' + filter.radio} handleFile={handleFileImport}></ImportExcel>
                <MDBBtn color={object.status ? 'warning' : 'success'} type="button" data-toggle="modal" data-backdrop="static" data-target="#modal-save" onClick={() => { window.saveMethod = updateStatus; }}>
                  <MDBIcon fa="true" icon="check" className="fa-fw" /> {object.status ? window.I18N('btn_inactive') : window.I18N('btn_active')}
                </MDBBtn>
              </div>
            </div>
          </div>
          <div className={filter.radio !== 'RECOMMEND_SURVEY' ? 'mt-2 wow fadeIn animated' : 'd-none'} data-wow-delay="0.5s">
            <Table id="table-question" className="table" config={configQuestion} data={dataQuestion} handleRowDelClick={openDeleteQuestionDialog}></Table>
          </div>
          <div className={filter.radio === 'RECOMMEND_SURVEY' ? 'mt-2 wow fadeIn animated' : 'd-none'} data-wow-delay="0.5s">
            <Table id="table-recommend" className="table" config={configRecommend} data={dataRecommend} handleRowDelClick={openDeleteRecommendDialog}></Table>
          </div>
        </div>
      </Tab>

      <Modal id="modal-menu" className="modal-lg" title={window.I18N('create_update_data')} saveEvent={saveMenu}>
        <form id="form-menu" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <MDBInput outline containerClass="mt-0" type="text" label={window.I18N('code') + ' *'} value={dataSave.code} name="code" onChange={handleChange} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="text" label={window.I18N('content') + ' *'} name="content" value={window.COMMON.getValueWithLanguage(dataSave, 'content', language.current)} onChange={handleChangeWithLanguage} maxLength="200" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="textarea" label={window.I18N('description')} name="description" value={window.COMMON.getValueWithLanguage(dataSave, 'description', language.current)} onChange={handleChangeWithLanguage} maxLength="5000"></MDBInput>
              <MDBInput outline type="checkbox" id="ck-multi-survey" name="isMulti" label={window.I18N('multiple_answers')} containerClass="p-0" filled checked={dataSave.isMulti} onChange={handleChange}></MDBInput>
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>

      <Modal id="modal-import" className="modal-xl" title={window.I18N('import_data')} saveEvent={importData}>
        <Table id="table-import" className="table" config={dataImport.config} data={dataImport.data} handleRowDelClick={deleteImportRow} isImported={true}></Table>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};
  
export default connect(mapStateToProps, mapDispatchToProps)(SurveyPage);