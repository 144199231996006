import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
//isActive: want to use this hook
const useUnSaveChangesWarning = ({ message, defaultIsUsePrompt = false, callbackWhenRedirect = null, currentPath = null, callbackWhenReload = null, isActive = true }) => {
  const [isUsePrompt, setIsUsePrompt] = useState(defaultIsUsePrompt);
  const [isCancelPromptBeforeSubmit, setIsCancelPromptBeforeSubmit] = useState({
    status: false,
    callback: null
  });
  useEffect(() => {
    if (isActive === false) return;
    window.onbeforeunload = isUsePrompt && (() => {
      if (callbackWhenReload) {
        callbackWhenReload();
      }
      return message;
    });
    return () => {
      if (isActive === false) return;
      window.onbeforeunload = null;
    };
  }, [isUsePrompt]);

  useEffect(() => {
    const { status, callback } = isCancelPromptBeforeSubmit;
    if (status === true && callback) {
      callback();
    }
    return () => {
      // setIsCancelPromptBeforeSubmit({
      //     status:false,
      //     callback:null,
      // });
    };
  }, [isCancelPromptBeforeSubmit]);

  const routerPrompt = isActive === true ? <Prompt when={isUsePrompt} message={(location, action) => {
    if (location.pathname === currentPath) {
      return true;
    }

    if (callbackWhenRedirect) {
      callbackWhenRedirect();
    }
    return message;
  }} /> : <></>;

  const setCancelPromptlBeforeSubmit = (callback) => {
    setIsUsePrompt(false);
    setIsCancelPromptBeforeSubmit({
      status: true,
      callback
    });


  };

  return [routerPrompt, () => setIsUsePrompt(true), () => setIsUsePrompt(false), setCancelPromptlBeforeSubmit];
};
export default useUnSaveChangesWarning;