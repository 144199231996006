import React from 'react';
import BG from '../../styles/images/404/bg.png';
import Banner from '../../styles/images/404/banner.png';
import '../../styles/css/error.css';

const NotFoundPage = function () {
  return (
    <div className="error-page wow zoomIn animated" data-wow-delay="1s" style={{background: 'url(' + BG + ')'}}>
      <h1>Not Found</h1>
      <div className="banner">
        <img src={Banner} alt="" />
      </div>
      <div className="page">
        <h2>Oh ,we can&apost find that page!</h2>
      </div>
    </div>
  );
};

export default NotFoundPage;