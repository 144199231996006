import React, { useState } from 'react';
import { Grid, Collapse, Tooltip, FormControlLabel, Checkbox } from "@material-ui/core"
import { MDBBtn, MDBIcon } from 'mdbreact';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const SingleQuestionTemplate = ({ mode, question, handleEditClick, handleDelClick, handleSelectQuestion }) => {
    const USER_ID = localStorage.getItem(window.CONSTANT.ID);
    const expandRow = () => {setExpand(expand => !expand) }
    const [expand, setExpand] = useState(false)
    const [isCheck, setIsCheck]= useState(question.isCheck)
    const getEnText = (multiLanguageText) => {
        return multiLanguageText.find(el=>el.code === 'EN')?.text || ""
    }
    const handleCheck = () => {
        setIsCheck(isCheck => !isCheck)
        handleSelectQuestion(question._id)
    }

    return (
        <>
            <Grid container className='mt-2 rounded' style={{ background:'rgba(95, 93, 93, 0.15)'}}>
                <Grid item xs={mode==="EDIT"?7:8} className='d-flex align-items-center'>
                    <Grid container >
                        <Grid className='p-4 my-auto' item xs={12}>{question.question}</Grid>
                    </Grid>
                </Grid>
                <Grid item xs={mode==="EDIT"?5:4} className='d-flex align-items-center'>
                    <Grid container className='justify-content-center'>
                        {mode!=="PREVIEW"&&<Grid className='p-3 my-auto' item xs={4}>{question?.type}</Grid>}
                        {mode === "EDIT"&&<Grid className='p-3 my-auto' item xs={4}>{question?.createdDate}</Grid>}
                        <Grid className='p-3 my-auto' item xs={4}>
                            <MDBBtn outline className={`btn btn-sm btn-transparent m-0 ${question?.type==="TEXT"?'invisible':''}`} type="button" color="" onClick={expandRow}>
                                <MDBIcon fa="true" icon="angle-down" className={`fa-fw ${expand?'rotated-icon':''}`} />
                            </MDBBtn>
                            {mode === "EDIT"&&<MDBBtn outline disabled={USER_ID!==question.createdBy._id} className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={USER_ID===question.createdBy._id?handleEditClick:()=>{}}>
                                <MDBIcon fa="true" icon="edit" className="fa-fw" />
                            </MDBBtn>}
                            {mode === "EDIT"&&<MDBBtn outline disabled={USER_ID!==question.createdBy._id} className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={USER_ID===question.createdBy._id?handleDelClick:()=>{}}>
                                <MDBIcon fa="true" icon="trash-alt" className="fa-fw" />
                            </MDBBtn>}
                        </Grid>
                        {mode ==="TEMPLATE" && <Grid className='p-3 my-auto' item xs={4}>
                            <Tooltip title={isCheck?window.I18N('uncheck_question'):window.I18N('select_question')}>
                                <FormControlLabel className='my-auto' control={ <Checkbox checked={isCheck} onChange={handleCheck} color="primary"></Checkbox>}></FormControlLabel>
                            </Tooltip>
                            
                        </Grid>}
                        {mode ==="PREVIEW" && <Grid className='p-3 my-auto' item xs={4}>
                            <Tooltip title={window.I18N('uncheck_question')}>
                                <RemoveCircleOutlineIcon onClick={()=>handleSelectQuestion(question._id)} color="primary"></RemoveCircleOutlineIcon>
                            </Tooltip>
                            
                        </Grid>}
                        
                    </Grid>
                </Grid>  
            </Grid>
            <Collapse in={expand}>
                {question.choices?.map((answer, index) => 
                    <Grid key={index} className='pl-4 my-2'>
                        {index+1}. {getEnText(answer.content)}
                    </Grid>
                )}
                
            </Collapse>
        </>
        
    )
}
export default SingleQuestionTemplate