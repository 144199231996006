import React, { useState, useLayoutEffect, useRef } from 'react';
import { MDBInputGroup, MDBIcon } from 'mdbreact';
import { Tooltip } from '@material-ui/core';

const ListOption = React.memo(function ListOption(props) {
  const [content, setContent] = useState('');
  const [dataItem, setDataItem] = useState([]);

  const textRef = useRef({})

  useLayoutEffect(() => {
    setDataItem(props.data ? JSON.parse(JSON.stringify(props.data)) : []);
  }, [props.data]);

  const handleChange = (event) => {
    setContent(event.target.value);
  };

  const handleAddClick = () => {
    if (!window.COMMON.checkValue(content)) {
      window.COMMON.showMessage('warning', 'MSG_CODE_022', window.MSG.MSG_CODE_022);
      return;
    }
    dataItem.push(content);
    setContent('');
    setDataItem([ ...dataItem ]);
    if (props.handleReceiveData) {
      props.handleReceiveData(dataItem);
    }
  };

  const handleDelClick = (index) => {
    dataItem.splice(index, 1);
    setDataItem([ ...dataItem ]);
    if (props.handleReceiveData) {
      props.handleReceiveData(dataItem);
    }
  };
  
  const generateItem = () => {
    let html = '';
    if (dataItem.length > 0) {
      html = dataItem.map((item, i) => 
        <Tooltip title={textRef?.current[i]?.offsetWidth > 220 ? item : ''} placement='top' arrow key={i}>
          <div className={'list-option-item d-inline-block mr-2 my-2' + (props.disabled ? ' disabled' : '')} >
            <div className="media justity-content-between">
              <div className="media-body media-left pr-2" style={{maxWidth: '15rem', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                <span ref={(el) => textRef.current[i] = el}>{item}</span>
              </div>
              <div className="media-right" onClick={props.disabled ? () => {} : () => handleDelClick(i)}>
                <MDBIcon fa="true" icon="times"></MDBIcon>
              </div>
            </div>
          </div>
        </Tooltip>
      );
    }
    return html;
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className={'list-option container-row ' + window.COMMON.getValue(props.className)}>
      {
        props.title ? <p className="mb-2"><small className="font-weight-bold">{props.title}</small></p> : <div></div>
      }
      {
        props.isFullWidth ? <div className="w-100">
          <div className="w-100">
            <MDBInputGroup type="text" className={'m-0' + (props.disabled ? ' disabled' : '')} value={content} hint={props.placeholder || window.I18N('input_content')} onChange={handleChange} maxLength="100" pattern="\S(.*\S)?" required
              append={
                <span className={'input-group-text' + (props.disabled ? ' disabled' : '')} onClick={props.disabled ? () => {} : () => handleAddClick()}>
                  <MDBIcon fa="true" icon="plus-circle" className="fa-fw" style={{ color: '#8457FF' }}></MDBIcon>
                </span>
              }
            />
          </div>
          <div className="d-flex flex-wrap w-100 mt-2">{generateItem()}</div>
        </div> : <div className="w-100 d-flex align-items-start flex-column">
          <div className="mr-3" style={{ width: '300px' }}>
            <MDBInputGroup type="text" className={'m-0' + (props.disabled ? ' disabled' : '')} value={content} hint={props.placeholder || window.I18N('input_content')} onChange={handleChange} maxLength="100" pattern="\S(.*\S)?" required
              append={
                <span className={'input-group-text' + (props.disabled ? ' disabled' : '')} onClick={props.disabled ? () => {} : () => handleAddClick()}>
                  <MDBIcon fa="true" icon="plus-circle" className="fa-fw" style={{ color: '#8457FF' }}></MDBIcon>
                </span>
              }
            />
          </div>
          <div className="d-flex flex-wrap" style={{ width: 'calc(100% - 300px)' }}>{generateItem()}</div>
        </div>
      }
    </div>
  );
});

export default ListOption;