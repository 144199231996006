import React, { useState, useLayoutEffect } from 'react';
import { MDBCard, MDBCardBody, MDBCardImage, MDBIcon } from 'mdbreact';

const ItemQuestionData = React.memo(function ItemQuestionData(props) {
  const [average, setAverage] = useState(0);
  const [multiAverage, setMultiAverage] = useState({});

  useLayoutEffect(() => {
    let average = 0;
    const multiAverage = {};
    if (props.item && props.answers && props.answers.length > 0) {
      if (props.item.type === 'RADIO_GRID' || props.item.type === 'CHECKBOX_GRID') {
        const parent = props.answers.filter(function(item) { return item.parentCode === null; });
        if (parent && parent.length > 0) {
          parent.forEach(element => {
            const answers = window.COMMON.getArrsInArrs(element.code, props.answers, 'parentCode');
            answers.forEach(answer => {
              const percentWorker = element && element.total > 0 ? parseFloat(answer.answerWorker / element.total * 100) : 0;
              const percentCompany = parseFloat(answer.answerCompany * 100);
              let value = Math.round((percentWorker - (percentWorker + percentCompany) / 2) * 100) / 100;
              value = value < 0 ? value * -1 : value;
              multiAverage[element.code] = multiAverage[element.code] ? multiAverage[element.code] + value : value;
            });
          });
        }
      } else {
        const totalCompany  = props.answers.reduce((acc,curr)=>acc + curr.answerCompany,0) 
        props.answers.forEach(answer => {
          const percentWorker = props.item.total > 0 ? parseFloat(answer.answerWorker / props.item.total * 100) : 0;
          const percentCompany = parseFloat(answer.answerCompany/totalCompany * 100) || 0;
          let value = Math.round((percentWorker - (percentWorker + percentCompany) / 2) * 100) / 100;
          value = value < 0 ? value * -1 : value;
          average += value;
        });
      }
    }
    setAverage(average);
    setMultiAverage(multiAverage);
  }, [props.item, props.answers]);

  const calcAverage = (parentCode) => {
    let length = 0;
    let averageAll = 0;
    if (props.item.type === 'RADIO_GRID' || props.item.type === 'CHECKBOX_GRID') {
      props.answers.forEach(answer => {
        if (answer.parentCode === parentCode) {
          length += 1;
        }
      });
      averageAll = multiAverage[parentCode];
    } else {
      length = props.answers.length; 
      averageAll = average;
    }
    return <span style={{ color: 'black' }}>{ (length > 0 ? (Math.round(averageAll / length * 100) / 100) : 0) + '%' }</span>;
  };

  const calcBenmark = (parentCode) => {
    const averageAll = props.item.type === 'RADIO_GRID' || props.item.type === 'CHECKBOX_GRID' ? multiAverage[parentCode] : average;
    return <span style={{ color: 'red' }}>{Math.round(averageAll * 100) / 100 + '%'}</span>;
  };

  const countAnswer = (item, answerId) => {
    const obj = window.COMMON.getObjectInArrs(props.item._id, item.feedback, 'question._id');
    if (obj) {
      const check = window.COMMON.getObjectInArrs(answerId, obj.answers, '_id');
      if (check) {
        return 1;
      }
    }
    return 0;
  };

  const getTextAnswer = (item) => {
    const obj = window.COMMON.getObjectInArrs(props.item._id, item.feedback, 'question._id');
    if (obj && obj.answerText) {
      return obj.answerText;
    }
    return '';
  };

  const checkNote = (item) => {
    const note = window.COMMON.getValueFromAttr(window.COMMON.getObjectInArrs(props.item._id, item.item, 'question._id'), 'note');
    const documents = window.COMMON.getValueFromAttr(window.COMMON.getObjectInArrs(props.item._id, item.item, 'question._id'), 'documents', []);
    return note || documents.length > 0;
  };

  const getNote = (item) => {
    return window.COMMON.getValueFromAttr(window.COMMON.getObjectInArrs(props.item._id, item.item, 'question._id'), 'note', '').replace(/\n/g, '<br>');
  };

  const getDocuments = (item) => {
    return window.COMMON.getValueFromAttr(window.COMMON.getObjectInArrs(props.item._id, item.item, 'question._id'), 'documents', []);
  };

  if (!props.item) {
    return <div></div>;
  }
  if (props.item.type === 'TEXT') {
    return <MDBCard className="w-100 p-3 wow fadeInUp animated text-left" data-wow-delay="0.5s" style={{ height: 'auto' }}>
      <MDBCardBody className="p-0">
        <div className="alert alert-warning mb-2" role="alert">
          <p className="mb-1 font-weight-bold" style={{ color: '#FC9905' }}>
            <span>{window.I18N('question')}</span>
            { window.COMMON.getValueFromAttr(props.item, 'important') ? <MDBIcon fa="true" icon="exclamation-circle" className="ml-1 fa-fw"></MDBIcon> : <></> }
          </p>
          <p className="mb-0">
            {(window.COMMON.getValueFromAttr(props.item, 'index') + 1) + '. ' + window.COMMON.getValueWithLanguage(props.item, 'question', props.language)}
          </p>
        </div>
        <div className="w-100 p-2">
          <table className="table wow fadeIn animated" data-wow-delay="0.2s">
            <thead>
              <tr>
                <th width={100 / (3 + props.dataAssessor.length) + '%'}>{window.I18N('organization')}</th>
                <th width={100 / (3 + props.dataAssessor.length) + '%'}>{window.I18N('worker')}</th>
                <th width={100 / (3 + props.dataAssessor.length) + '%'}>{window.I18N('advisor')}</th>
                {
                  props.dataAssessor.map((element, i) => <th key={i} width={100 / (3 + props.dataAssessor.length) + '%'}>{window.I18N('assessor')}<br></br><small className="text-muted">{window.COMMON.getValueFromAttr(element, 'influencer.name')}</small></th>)
                }
              </tr>
            </thead>
            <tbody>
              <tr>
                <td dangerouslySetInnerHTML={{__html: props.item.answerCompany}}></td>
                <td dangerouslySetInnerHTML={{__html: props.item.answerWorker ? props.item.answerWorker : ''}}></td>
                <td dangerouslySetInnerHTML={{__html: props.item.answerInfluencer}}></td>
                {
                  props.dataAssessor.map((element, i) => <td key={i} dangerouslySetInnerHTML={{__html: getTextAnswer(element)}}></td>)
                }
              </tr>
            </tbody>
          </table>
        </div>
      </MDBCardBody>
    </MDBCard>;
  }    
  return <MDBCard className="w-100 p-3 wow fadeInUp animated text-left" data-wow-delay="0.5s" style={{ height: 'auto' }}>
    <MDBCardBody className="p-0">
      <div className="alert alert-warning mb-2" role="alert">
        <p className="mb-1 font-weight-bold" style={{ color: '#FC9905' }}>
          <span>{window.I18N('question')}</span>
          { window.COMMON.getValueFromAttr(props.item, 'important') ? <MDBIcon fa="true" icon="exclamation-circle" className="ml-1 fa-fw"></MDBIcon> : <></> }
        </p>
        <p className="mb-0">
          {(window.COMMON.getValueFromAttr(props.item, 'index') + 1) + '. ' + window.COMMON.getValueWithLanguage(props.item, 'question', props.language)}
        </p>
      </div>
      <div className="w-100 p-2">
        <table className="table-view wow fadeIn animated" data-wow-delay="0.2s">
          <thead>
            <tr>
              <th width={67.5 - (props.dataAssessor.length * 12.5) + '%'}>{window.I18N('answer')}</th>
              <th width="12.5%">{window.I18N('company')}</th>
              <th width="12.5%">{window.I18N('worker')}</th>
              <th width="12.5%">{window.I18N('advisor')}</th>
              {
                props.dataAssessor.map((element, i) => <th key={i} width="12.5%">{window.I18N('assessor')}<br></br><small className="text-muted">{window.COMMON.getValueFromAttr(element, 'influencer.name')}</small></th>)
              }
            </tr>
          </thead>
          {
            props.item.type === 'RADIO_GRID' || props.item.type === 'CHECKBOX_GRID' ? 
              <>
                {
                  props.answers.filter(function(item) { return item.parentCode === null; }).map((item, i) => !item.parentCode ? <tbody key={item._id}>
                    <tr className="parent">
                      <td>{window.COMMON.getValueWithLanguage(item, 'content', props.language)}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {
                        props.dataAssessor.map((element, i) => <td key={i}></td>)
                      }
                    </tr>
                    {
                      window.COMMON.getArrsInArrs(item.code, props.answers, 'parentCode').map((child, i) => <tr key={child._id}>
                        <td>{window.COMMON.getValueWithLanguage(child, 'content', props.language)}</td>
                        <td className="text-right">{child.answerCompany}</td>
                        <td className="text-right">{child.answerWorker}</td>
                        <td className="text-right">{child.answerInfluencer}</td>
                        {
                          props.dataAssessor.map((element, j) => <td key={child._id + (i * j + j)} className="text-right">{countAnswer(element, child._id)}</td>)
                        }
                      </tr>)
                    }
                    <tr>
                      <td colSpan={3 + props.dataAssessor.length} className="font-weight-bold">{window.I18N('deviation_average')}</td>
                      <td className="text-right font-weight-bold">{calcAverage(item.code)}</td>
                    </tr>
                    <tr>
                      <td colSpan={3 + props.dataAssessor.length} className="font-weight-bold">{window.I18N('bench_mark_deviation_average')}</td>
                      <td className="text-right font-weight-bold">{calcBenmark(item.code)}</td>
                    </tr>
                  </tbody>: <></>) 
                }
              </> : <tbody>
                {
                  props.answers.map((item, i) => <tr key={item._id}>
                    <td>{window.COMMON.getValueWithLanguage(item, 'content', props.language)}</td>
                    <td className="text-right">{item.answerCompany}</td>
                    <td className="text-right">{item.answerWorker}</td>
                    <td className="text-right">{item.answerInfluencer}</td>
                    {
                      props.dataAssessor.map((element, j) => <td key={item._id + j} className="text-right">{countAnswer(element, item._id)}</td>)
                    }
                  </tr>)
                }
                <tr>
                  <td colSpan={3 + props.dataAssessor.length} className="font-weight-bold">{window.I18N('deviation_average')}</td>
                  <td className="text-right font-weight-bold">{calcAverage()}</td>
                </tr>
                <tr>
                  <td colSpan={3 + props.dataAssessor.length} className="font-weight-bold">{window.I18N('bench_mark_deviation_average')}</td>
                  <td className="text-right font-weight-bold">{calcBenmark()}</td>
                </tr>
              </tbody>
          }
        </table>
        <div className="mt-4">
          <p className="mb-2"><b>Public comment</b></p>
          {
            props.dataNote && props.dataNote.map((element, i) => 
              <div key={i}>
                {
                  checkNote(element) ? <div className="container-comment-influencer">
                    <div className="d-flex align-items-center mb-3">
                      <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(element, 'updatedBy.avatar'))} className="image-icon mr-2"/>
                      <div>
                        <p className="m-0">{window.COMMON.getValueFromAttr(element, 'updatedBy.name')}</p>
                        <small className="text-muted">{window.COMMON.getValueFromAttr(element, 'updatedDate')}</small>
                      </div>
                    </div>
                    <p className="mb-2 text-muted" dangerouslySetInnerHTML={{__html: getNote(element)}}></p>
                    <div className="container-list-file">
                      {
                        getDocuments(element).map((item, j) => <div key={i * j + j} className="media item p-1">
                          <MDBCardImage zoom cascade waves src={window.COMMON.getFileType(item.ext)} className="image-icon mr-2"/>
                          <div className="media-body">
                            <p className="mb-0 text-link" onDoubleClick={() => window.COMMON.downloadFile(item.id, item.name)}>{item.name}</p>
                            <small className="mb-0">{window.COMMON.bytesToSize(item.size)}</small>
                          </div>
                        </div>)
                      }
                    </div>
                  </div> : <></>
                }
              </div>
            )
          }
        </div>
      </div>
    </MDBCardBody>
  </MDBCard>;
});

export default ItemQuestionData;