import gql from 'graphql-tag';

const QUERY_LANGUAGE = gql`
  query($status: [Boolean!]) {
    getLanguages(status: $status) {
      _id
      name
      code
      icon
      status
      createdDate
      createdBy {
        name
      }
    }
  }
`;

const MUTATION_ADD_LANGUAGE = gql`
  mutation($input: LanguageInput!) {
    addLanguage(input: $input)
  }
`;

const MUTATION_EDIT_LANGUAGE = gql`
  mutation($input: LanguageInput!) {
    editLanguage(input: $input)
  }
`;

const MUTATION_UPDATE_STATUS_LANGUAGE = gql`
  mutation($id: String!, $status: Boolean!) {
    statusLanguage(id: $id, status: $status)
  }
`;

const QUERY_TEXT_LANGUAGE = gql`
  query($type: TextLanguageEnum!) {
    getTextLanguages(type: $type) {
      _id
      key
      language {
        code
        text
      }
      createdDate
      createdBy {
        name
      }
    }
  }
`;

const MUTATION_ADD_TEXT_LANGUAGE = gql`
  mutation($input: TextLanguageInput!) {
    addTextLanguage(input: $input)
  }
`;

const MUTATION_EDIT_TEXT_LANGUAGE = gql`
  mutation($input: TextLanguageInput!) {
    editTextLanguage(input: $input)
  }
`;

const MUTATION_DEL_TEXT_LANGUAGE = gql`
  mutation($id: String!) {
    deleteTextLanguage(id: $id)
  }
`;

const MUTATION_CHECK_IMPORT_TEXT_LANGUAGE = gql`
  mutation($input: [String!]) {
    checkImportTextLanguage(input: $input)
  }
`;

const MUTATION_IMPORT_TEXT_LANGUAGE = gql`
  mutation($input: [TextLanguageInput!]) {
    importTextLanguage(input: $input)
  }
`;

const LanguageGRAPHQL = {
  QUERY_LANGUAGE,
  MUTATION_ADD_LANGUAGE,
  MUTATION_EDIT_LANGUAGE,
  MUTATION_UPDATE_STATUS_LANGUAGE,
  QUERY_TEXT_LANGUAGE,
  MUTATION_ADD_TEXT_LANGUAGE,
  MUTATION_EDIT_TEXT_LANGUAGE,
  MUTATION_DEL_TEXT_LANGUAGE,
  MUTATION_CHECK_IMPORT_TEXT_LANGUAGE,
  MUTATION_IMPORT_TEXT_LANGUAGE
};

export default LanguageGRAPHQL;
