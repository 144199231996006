import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { HorizontalBar, Bar, Pie } from 'react-chartjs-2';
import { MDBIcon } from 'mdbreact';
import HighLineChart from "./HighLineChart";

const MAX_LENGTH_TO_RESIZE = 10
const DEFAULT_PERCENTAGE_COLUMN = 1
const RESIZE_PERCENTAGE_COLUMN = 0.3
const BAR_CHART_HORIZONTAL = 0
const BAR_CHART_VERTICAL = 1
const PIE_CHART = 2
const LINE_CHART = 3
const DATA_TYPE_CHART = [
    {
      value: BAR_CHART_HORIZONTAL,
      label: 'Bar Chart (Horizontal)',
      chartKey: 'barChart'
    },
    {
      value: BAR_CHART_VERTICAL,
      label: 'Bar Chart (Vertical)',
      chartKey: 'barChart'
    },
    {
      value: PIE_CHART,
      label: 'Pie Chart',
      chartKey: 'pieChart'
    },
    {
      value: LINE_CHART,
      label: 'Line Chart',
      chartKey: 'lineChart'
    }
  ];

const DATA_CHART = {
  chart: {
      datasets: [
          {
              backgroundColor: [],
              borderColor: [],
              data: [],
          }
      ],
      labels: []
    }
}

function DataChartDashboardBI({title = 'Chart Name', data = {}, index, custom = {}}) {
    const [dataChart, setDataChart] = useState(DATA_CHART)
    const [dataTypeChart, setDataTypeChart] = useState(DATA_TYPE_CHART)
    const [typeChart, setTypeChart] = useState(DATA_TYPE_CHART[BAR_CHART_VERTICAL])

    // define a reusable function
    const randomRgbColor = (alpha) => {
      let r = Math.floor(Math.random() * 256); // Random between 0-255
      let g = Math.floor(Math.random() * 256); // Random between 0-255
      let b = Math.floor(Math.random() * 256); // Random between 0-255
      return `rgba(${r},${g},${b},${alpha})`
    };

    const createColorChart = (quantity, alpha) => {
      const colors = [];
      for(let i = 0; i < quantity; i++){
        const colorRGBA = randomRgbColor(alpha)
        colors.push(colorRGBA)
      }
      return colors
    }

    const colorChart = useMemo(()=>{
      const backgroundColor = createColorChart(100, 0.5)
      const borderColor = backgroundColor.map(color => 'rgba(255,255,255,1)')
      return {
        backgroundColor,
        borderColor
      }
    },[])

    useEffect(()=>{
      if(Object.keys(data).length > 0){
        const cloneDataTypeChart = [...dataTypeChart]
        const indexExceptData = cloneDataTypeChart.findIndex(item => data[item.chartKey] === null)
        indexExceptData !== -1 && cloneDataTypeChart.splice(indexExceptData, 1)
        setDataTypeChart(cloneDataTypeChart)
      }
    },[data])

    useEffect(()=>{
      const dataTypeChart = data[typeChart.chartKey]
      const labels = dataTypeChart.x
      if(typeChart.value === LINE_CHART && dataTypeChart !== null){
        const seriesName = Array.from(new Set(dataTypeChart.line.map((data, index) => Object.keys(data[labels[index]])).flat()))
        const dataLineChart = [] 
        seriesName.forEach((label) => {
          const dataLabel = dataTypeChart.line.map((data, index) => data[labels[index]][label] ?? 0)
          dataLineChart.push(dataLabel)
        })
        const newDataChart = {
          dataLabels: {
                data: dataLineChart,
                labels
          },
          seriesName
        }
        setDataChart(newDataChart)
        return
      }
      if(Object.keys(data).length > 0){
          if(dataTypeChart === null){
            return
          }
          
          const newDataChart = {
            chart: {
              datasets: [
                {
                  backgroundColor: colorChart.backgroundColor,
                  borderColor: colorChart.borderColor,
                  data: typeChart.chartKey === 'pieChart' ? dataTypeChart.values : dataTypeChart.y,
                }
              ],
              labels: typeChart.chartKey === 'pieChart' ? dataTypeChart.labels.map((label, index) => `${label} (${handlePercentForPieChart(dataTypeChart.values)[index]})`) : dataTypeChart.x
            }
          }
          if(typeChart.chartKey !== 'pieChart'){
            newDataChart.chart.datasets[0].barPercentage = dataTypeChart.y.length < MAX_LENGTH_TO_RESIZE ? RESIZE_PERCENTAGE_COLUMN : DEFAULT_PERCENTAGE_COLUMN
          } 
          setDataChart(newDataChart)
      }
    },[data, typeChart])

    const handlePercentForPieChart = (data) => {
      const totalValue = data.reduce((acc,value) => acc += value , 0)
      const percentEachElement = data.map(item => `${((item/totalValue)*100).toFixed(2)}%`)
      return percentEachElement
    }

    const changeChart = (chart) => {
        setTypeChart(chart);
      };
    
    const scalesY = {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: 10
          }
        }]
      };
    
    const scalesX = {
        xAxes: [{
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: 10
          }
        }]
      };

    return ( 
        <div>
            <h6 className="font-weight-bold" style={{fontSize: '0.8rem'}}>{`${index + 1}. ${title}`}</h6>
            <div className="d-flex justify-content-end w-100">
                    <div className="dropleft p-2">
                        <button type="button" className="btn dropdown-toggle m-0" data-toggle="dropdown" data-boundary="viewport">
                            <MDBIcon fa="true" icon="chart-bar" className="fa-fw mr-1"></MDBIcon> {typeChart.label}
                        </button>
                        <div className="dropdown-menu" data-boundary="viewport">
                            {
                            dataTypeChart.map((item, i) => <div key={window.COMMON.genKey()}>
                                <div className="pl-2 pr-2 w-100">
                                  <button className={item.value === typeChart.value ? 'dropdown-item pl-2 active' : 'dropdown-item'} type="button" onClick={() => changeChart(item)}>
                                      { item.value === typeChart.value ? <MDBIcon fa="true" icon="check-circle" style={{ color: 'white' }} className="fa-fw mr-1"></MDBIcon> : <div></div> }
                                      {item.label}
                                  </button>
                                </div>
                                <div className={i === dataTypeChart.length - 1 ? 'dropdown-divider mt-1 mb-1 d-none' : 'dropdown-divider mt-1 mb-1'}></div>
                            </div>)
                            }
                        </div>
                    </div>
            </div>
            <div className="w-100 flex-center p-3">
              <div style={{ width: custom.containerSize ? `${custom.containerSize}%` : '75%' }}>
              {
                  typeChart.value === BAR_CHART_HORIZONTAL ? 
                  <HorizontalBar data={dataChart?.chart ?? {}} redraw={true} options={{ scales: scalesX, legend: { display: false }, responsive: true, maintainAspectRatio: false }} height={custom.horizontalBarSize ?? 400}/>
                  : typeChart.value === BAR_CHART_VERTICAL ? 
                      <Bar data={dataChart?.chart ?? {}} redraw={true} options={{ scales: scalesY, legend: { display: false }, responsive: true, maintainAspectRatio: false }} height={custom.barSize ?? 400}></Bar>
                      : typeChart.value === PIE_CHART ? 
                        <Pie data={dataChart?.chart ?? {}} redraw={true} options={{ legend: { position: 'right' }, responsive: true, maintainAspectRatio: false }} height={custom.pieSize ?? (data[typeChart.chartKey]?.values?.length < MAX_LENGTH_TO_RESIZE ? 300 : 500)}/> 
                        : 
                          <HighLineChart hideTitle dataLabels={dataChart?.dataLabels ?? {labels: [], data: []}} seriesName={dataChart.seriesName} />              
              }
              </div>
            </div>
        </div>
     );
}

export default React.memo(DataChartDashboardBI);